import React, {Component} from 'react';
import {connect} from "react-redux";
import {showModal} from "../../../../../actions/modal.actions";
import moment from "moment";
import Select from "react-select";
import {groupStyles} from "../../../../../utils/SelectStyles";
import isEmpty from "../../../../../utils/helpers";

export class Appointment extends Component {

    render() {

        const {
            appointment,
            showDropdownById,
            accountAccessModal,
            handleAllTasksAssignee,
            handleShowAssignDropdownById,
            usernameOptions,
            isAssigning,
        } = this.props;

        return (
            <div className="appointment">
                <div className="row">
                    <div className="col col-12 col-md-3 col-xl-2">

                        <div className="appointment-source">{appointment.source}</div>

                        <div className="appointment-info">
							<span className="font-weight-bold">
                                <i className="fas fa-fw fa-hashtag" /> {appointment.type === 'order' ? "O-" : "T-"}{appointment.id}
                            </span>
                            <span title={appointment.appointmentDetails.createdBy}>
                                <i className="fas fa-fw fa-user" /> {appointment.appointmentDetails.createdBy}
                            </span>
                            <span>
                                <i className="fas fa-fw fa-clock" /> {moment(appointment.created).fromNow()}
                            </span>
                        </div>

                    </div>
                    <div className="col col-12 col-md-5 col-xl-auto">

                        <div className="appointment-title">
                            {appointment.address.address && appointment.address.state &&
                            <span className="appointment-title-address">
                                  <button className="appointment-address" style={{
                                      textDecoration: "underline", background: "transparent",
                                      cursor: "pointer", border: "none", padding: 0
                                  }}
                                          onClick={() => this.props.showModal('SERVICE_ADDRESS_MODAL', {
                                              title: "Service Address Information",
                                              service: {
                                                  address1: appointment.address.address || '',
                                                  address2: appointment.address.address2 || '',
                                                  address3: appointment.address.address3 || '',
                                                  city: appointment.address.city || '',
                                                  latitude: appointment.address.latitude || '',
                                                  longitude: appointment.address.longitude || '',
                                                  mappingAddressId: appointment.address.mappingAddressId || '',
                                                  mappingAreaId: appointment.address.mappingAreaId || '',
                                                  mappingRefArea1: appointment.address.mappingRefArea1 || '',
                                                  mappingNote: appointment.address.mappingNote || '',
                                                  state: {
                                                      name: appointment.address.state || '',
                                                      id: appointment.address.stateId || '',
                                                      countryCode: appointment.address.country || ''
                                                  },
                                                  zipcode: appointment.address.zipcode || '',
                                                  externalUrl: appointment.address.externalUrl || ''
                                              },
                                              canViewCSIGeocode: false,
                                              canEditCSIGeocode: false,
                                              isDisabled: true
                                          })}
                                          disabled={isAssigning}
                                  >
                                         <i className="fas fa-map"/>
                                      {appointment.address.address}, {appointment.address.city}, {appointment.address.state}, {appointment.address.zipcode}
                                  </button>
                                     <span className="appointment-title-date">
                                    <i className="fas fa-clock"/> {moment(appointment.appointmentDetails.date).format('MM/DD/YYYY')} {appointment.appointmentDetails.timeslot}
                                </span>
                            </span>
                            }


                            <span className="appointment-title-name">
                                <span className="font-weight-bold">{appointment.customerName}</span>
                                {' | '}
                                {appointment.accountId
                                    ?
                                    <button
                                        onClick={() => accountAccessModal(appointment.accountId)}
                                        disabled={isAssigning}
                                    >
                                        Acct #: {appointment.accountNumber}
                                    </button>
                                    :
                                    <span>Acct #: {appointment.accountNumber}</span>
                                }
                            </span>

                            {appointment.serviceOrders && appointment.serviceOrders.map(serviceOrder => (
                            <p className="appointment-title-service">
                                {serviceOrder.icon && <><i className={serviceOrder.icon} />&nbsp;{serviceOrder.description}</>}
                            </p>
                            ))}

                        </div>

                    </div>
                    <div className="col col-12 col-md-3 col-xl-2 col-xxl-3">

                        <div className="appointment-details">
                            {!isEmpty(appointment.appointmentDetails.areaDescription) &&
                            <span className="appointment-details-area" title={appointment.appointmentDetails.areaDescription}>
                                <i className="fas fa-fw fa-paper-plane" /> {appointment.appointmentDetails.areaDescription}
                            </span>
                            }
                            {!isEmpty(appointment.taskIds) &&
                            <span className="appointment-details-assignees">
                                <i className="fas fa-fw fa-user" />
                                {appointment.taskIds && appointment.taskIds.length > 1
                                    ? "Assignees: "
                                    : "Assignee: "
                                }
                                {!isEmpty(appointment.taskAssignees)
                                    ? appointment.taskAssignees.map(taskAssignee => <span>{taskAssignee}</span>)
                                    : "unassigned"
                                }
                            </span>
                            }
                        </div>

                    </div>
                    <div className="col col-12 col-md-auto">

                        <div className="appointment-controls">

                            <div className="dropdown">

                                <button
                                    className="btn btn-circle"
                                    type="button"
                                    id="dropdownMenuButton"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded={showDropdownById === appointment.id}
                                    onClick={() => handleShowAssignDropdownById(appointment.appointmentDetails.id)}
                                    disabled={isEmpty(appointment.taskIds) || isAssigning}
                                >
                                    {isAssigning === appointment.appointmentDetails.id
                                        ? <i className="fas fa-spinner fa-spin"/>
                                        : <i className="fas fa-user-plus"/>
                                    }
                                </button>

                                {showDropdownById === appointment.appointmentDetails.id &&
                                <div className="dropdown-menu dropdown-menu-with-select dropdown-menu-right show"
                                     aria-labelledby="dropdownMenuButton">
                                    <button
                                        onClick={() => handleAllTasksAssignee('claim', appointment.taskIds)}
                                        className="dropdown-item"
                                    >
                                        Claim
                                    </button>
                                    <button
                                        onClick={() => handleAllTasksAssignee('unassign', appointment.taskIds)}
                                        className="dropdown-item"
                                    >
                                        Unassign
                                    </button>
                                    <div className="dropdown-divider"/>
                                    <h6 className="dropdown-header">Assign To User</h6>
                                    <div className="dropdown-item">
                                        <Select
                                            placeholder="Select"
                                            styles={groupStyles}
                                            options={usernameOptions}
                                            onChange={(value) => handleAllTasksAssignee('username', appointment.taskIds, value)}
                                        />
                                    </div>
                                </div>
                                }

                            </div>

                        </div>

                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {

    return {
    };
};

const mapDispatchToProps = {
    showModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(Appointment);
