import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {getAccountDetails, getAdmin} from '../../../../../../selectors/index';
import {getMyAutoPayInfo, emptyMyAutoPayInfo} from "../../../../../../actions/accountDetailsActions/accountDetails.actions";
import { getBillingBadjes, emptyBillingBadjes } from '../../../../../../actions/accountDetailsActions/billing.actions';
import { showModal } from '../../../../../../actions/modal.actions';
import {getPrimaryContacts} from "../../../../../../actions/admin.actions";
import Balances from './Balances';
import Adjustments from './Adjustments';
import Invoices from './Invoices';
import Payments from './Payments';
import InstallmentPlan from './InstallmentPlans';
import Discounts from './Discounts';
import ReccuringCredits from './ReccuringCredits';
import TaxExemptions from './TaxExemptions';
import PaymentPromises from './PaymentPromises';
import {
	emptyAccountTypeDistributionMethods,
	getAccountDistributionMethod,
	getAccountTypeDistributionMethods
} from "../../../../../../actions/admin.actions";
import {statusColor} from "../../../../../../utils/ui";
import isEmpty from "../../../../../../utils/helpers";
import StatusHistory from "./StatusHistory";

export class Billing extends Component {

	state = {
		collapsedMenuItem: 'balances',
		isPaperlessActive: false,
		myAutoPayInfoLoaded: false,
		myDistributionMethodsLoaded: false,
		customerPortalStatusLoaded: false,
		customerPortalStatus: '',
	};

	componentDidMount() {
		const { accountInfo, history } = this.props;

		this.props.getBillingBadjes(accountInfo.id);
		this.checkIsPaperlessActive();
		this.props.getAccountTypeDistributionMethods(accountInfo.accountType.name).then(response => {
			if (response.status === 200) {
				this.setState({myDistributionMethodsLoaded: true})
			}
		});
		this.checkIsAutoPayActive();
		this.loadCustomerPortalStatus();

		if (history.location.state && !isEmpty(history.location.state.billingTabActiveItem)) {
			this.setCollapsedMenuItem(history.location.state.billingTabActiveItem);
			history.replace({state: undefined});
		}
	}

	componentWillUnmount() {
		this.props.emptyBillingBadjes();
		this.props.emptyAccountTypeDistributionMethods();
		this.props.emptyMyAutoPayInfo();
	}

	checkIsPaperlessActive = () => {
		this.props.getAccountDistributionMethod(this.props.accountInfo.id).then(response => {

			this.setState({
				isPaperlessActive: response.data.distributionMethods[0]
					? response.data.distributionMethods[0].name !== 'paper'
					: false
			});
		});
	}

	checkIsAutoPayActive = () => {
		this.setState({myAutoPayInfoLoaded: false});
		this.props.getMyAutoPayInfo(this.props.accountInfo.id).then(response =>
			this.setState({myAutoPayInfoLoaded: true})
		);
	}

	setCollapsedMenuItem = (menuItem) => {
		this.setState({
			collapsedMenuItem: this.state.collapsedMenuItem === menuItem ? '' : menuItem
		});
	};

	setCustomerPortalColor = (userAccess) => {

		// Secondary - gray
		let color = 0;

		if (!isEmpty(userAccess)) {
			let customerPortalActive = userAccess.isActive;
			let loginAttempts = userAccess.invalidLoginAttempts;

			if (customerPortalActive) {
				// Success - green
				color = 4;
			}
			else {
				if (loginAttempts > 1) {
					// Danger - red
					color = 1;
				}
				else {
					// Warning - yellow
					color = 3;
				}
			}
		}

		this.setState({
			customerPortalStatus: color,
			customerPortalStatusLoaded: true,
		})
	}

	loadCustomerPortalStatus = () => {
		this.setState({
			customerPortalStatus: 0,
			customerPortalStatusLoaded: false,
		});

		this.props.getPrimaryContacts(this.props.accountInfo.id).then((response) => {
			this.setCustomerPortalColor(response.data.contacts[0].userAccess);
		});
	}

	render() {
		const { collapsedMenuItem, isPaperlessActive, myAutoPayInfoLoaded, myDistributionMethodsLoaded, customerPortalStatusLoaded } = this.state;
		const { accountInfo, billingBadjesData, myDistributionMethods, changeTabHandler, primaryContacts } = this.props;

		return (
			<div className="tab-pane card-body fade active show">
				<div className="tab-pane-toolbar">
					<div className="btn-toolbar mr-auto">

						<button
							className={"btn btn-icon" + (isPaperlessActive ? " btn-icon-success" : "")}
							onClick={() => this.props.showModal('PAPERLESS_MODAL', {
								myDistributionMethods,
								onCloseCallback: () => this.checkIsPaperlessActive()
							}
							)}
							title="Paperless"
							type="button"
							disabled={!myDistributionMethodsLoaded}
							style={!myDistributionMethodsLoaded ? {cursor: "progress"} : {}}
						>
							{myDistributionMethodsLoaded
							?
							<i className="fab fa-fw fa-envira" />
							:
							<i className="fas fa-fw fa-spin fa-spinner"/>
							}
						</button>

						<button className={"btn btn-icon" +
							((this.props.myAutoPayInfo && this.props.myAutoPayInfo.length !== 0)
								? " btn-icon-success" : ""
							)}
							onClick={() => {
								this.props.emptyMyAutoPayInfo();
								this.props.showModal('AUTOPAY_MODAL', {onCloseCallback: () => {this.checkIsAutoPayActive()}})
							}}
							title="AutoPay"
							type="button"
							disabled={!myAutoPayInfoLoaded}
							style={!myAutoPayInfoLoaded ? {cursor: "progress"} : {}}
						>
							{myAutoPayInfoLoaded
							?
							<span className="fa-stack fa-1x">
								<i className="fas fa-fw fa-sync-alt fa-stack-2x" />
								<i className="fas fa-fw fa-dollar-sign fa-stack-1x" />
							</span>
							:
							<i className="fas fa-fw fa-spin fa-spinner"/>
							}
						</button>

						<button
							className={`btn btn-customer-portal btn-icon btn-icon-${statusColor(this.state.customerPortalStatus)}`}
							onClick={() => {
								this.props.showModal('CUSTOMER_PORTAL_MODAL', {
									primaryContact: primaryContacts[0],
									onCloseCallback: () => {this.loadCustomerPortalStatus()}
								})
							}}
							title="Customer Portal"
							type="button"
							disabled={!customerPortalStatusLoaded}
							style={!customerPortalStatusLoaded ? {cursor: "progress"} : {}}
						>
							{customerPortalStatusLoaded
							?
							<span className="fa-stack">
								<i className="fas fa-user fa-stack-2x" />
								<i className="fas fa-desktop fa-stack-1x" />
							</span>
							:
							<i className="fas fa-fw fa-spin fa-spinner"/>
							}
						</button>

					</div>

					<button
						onClick={() => this.props.showModal('PAY_NOW_MODAL', {onCloseCallback: () => changeTabHandler('billing')})}
						type="button"
						className="btn btn-xl btn-outline-success"
					>
						<i className="fas fa-money-bill-alt" /> Pay Now
					</button>

				</div>
				<div className="accordion">
					<div className="container-accordion-content container-client-balance">
						<h3
							onClick={() => this.setCollapsedMenuItem('balances')}
							className={collapsedMenuItem === 'balances' ? '' : 'collapsed'}
							data-toggle="collapse"
						>
							Balances
						</h3>
						<div className={'collapse-with-table collapse' + (collapsedMenuItem === 'balances' ? ' show': '')}>
							{collapsedMenuItem === 'balances' && <Balances accountInfo={accountInfo} />}
						</div>
					</div>
					<div className="container-accordion-content container-client-adjustemnts">
						<h3
							onClick={() => this.setCollapsedMenuItem('adjustments')}
							className={collapsedMenuItem === 'adjustments' ? '' : 'collapsed'}
							data-toggle="collapse"
						>
							Other Charges and Credits
							{billingBadjesData.pendingAdjustments > 0 && (
								<span className="float-right badge badge-warning">
									{billingBadjesData.pendingAdjustments} Pending
								</span>
							)}
						</h3>
						<div className={'collapse-with-table collapse' + (collapsedMenuItem === 'adjustments' ? ' show': '')}>
							{collapsedMenuItem === 'adjustments' && <Adjustments accountInfo={accountInfo} />}
						</div>
					</div>
					<div className="container-accordion-content container-client-adjustemnts">
						<h3
							onClick={() => this.setCollapsedMenuItem('invoices')}
							className={collapsedMenuItem === 'invoices' ? '' : 'collapsed'}
							data-toggle="collapse"
						>
							Invoices
							{billingBadjesData.unpaidInvoices > 0 && (
								<span className="float-right badge badge-warning">
									{billingBadjesData.unpaidInvoices} Unpaid
								</span>
							)}
						</h3>
						<div className={'collapse-with-table collapse' + (collapsedMenuItem === 'invoices' ? ' show': '')}>
							{collapsedMenuItem === 'invoices' && <Invoices accountInfo={accountInfo} />}
						</div>
					</div>
					<div className="container-accordion-content container-client-adjustemnts">
						<h3
							onClick={() => this.setCollapsedMenuItem('installmentplans')}
							className={collapsedMenuItem === 'installmentplans' ? '' : 'collapsed'}
							data-toggle="collapse"
						>
							Installment Plans
							{billingBadjesData.activeInstallmentPlans > 0 && (
								<span className="float-right badge badge-warning">
									{billingBadjesData.activeInstallmentPlans} Pending
								</span>
							)}
						</h3>
						<div className={'collapse-with-table collapse' + (collapsedMenuItem === 'installmentplans' ? ' show': '')}>
							{collapsedMenuItem === 'installmentplans' && <InstallmentPlan accountInfo={accountInfo} />}
						</div>
					</div>
					<div className="container-accordion-content container-client-adjustemnts">
						<h3
							onClick={() => this.setCollapsedMenuItem('payments')}
							className={collapsedMenuItem === 'payments' ? '' : 'collapsed'}
							data-toggle="collapse"
						>
							Payments
						</h3>
						<div className={'collapse-with-table collapse' + (collapsedMenuItem === 'payments' ? ' show': '')}>
							{collapsedMenuItem === 'payments' && <Payments accountInfo={accountInfo} />}
						</div>
					</div>
					<div className="container-accordion-content container-client-adjustemnts">
						<h3
							onClick={() => this.setCollapsedMenuItem('reccuringCredits')}
							className={collapsedMenuItem === 'reccuringCredits' ? '' : 'collapsed'}
							data-toggle="collapse"
						>
							Reccuring Credits
							{billingBadjesData.activeRecurringCredits > 0 && (
								<span className="float-right badge badge-warning">
									{billingBadjesData.activeRecurringCredits} Approved
								</span>
							)}
						</h3>
						<div className={'collapse-with-table collapse' + (collapsedMenuItem === 'reccuringCredits' ? ' show': '')}>
							{collapsedMenuItem === 'reccuringCredits' && <ReccuringCredits accountInfo={accountInfo} />}
						</div>
					</div>
					<div className="container-accordion-content container-client-adjustemnts">
						<h3
							onClick={() => this.setCollapsedMenuItem('discounts')}
							className={collapsedMenuItem === 'discounts' ? '' : 'collapsed'}
							data-toggle="collapse"
						>
							Discounts
							{billingBadjesData.activeDiscounts > 0 && (
								<span className="float-right badge badge-warning">
									{billingBadjesData.activeDiscounts} Active
								</span>
							)}
						</h3>
						<div className={'collapse-with-table collapse' + (collapsedMenuItem === 'discounts' ? ' show': '')}>
							{collapsedMenuItem === 'discounts' && <Discounts accountInfo={accountInfo} />}
						</div>
					</div>
					<div className="container-accordion-content container-client-adjustemnts">
						<h3
							onClick={() => this.setCollapsedMenuItem('paymentPromises')}
							className={collapsedMenuItem === 'paymentPromises' ? '' : 'collapsed'}
							data-toggle="collapse"
						>
							Payment Promises
							{billingBadjesData.activePaymentPromises > 0 &&
							<span className="float-right badge badge-warning">
								{billingBadjesData.activePaymentPromises} Active
							</span>
							}
						</h3>
						<div className={'collapse-with-table collapse' + (collapsedMenuItem === 'paymentPromises' ? ' show': '')}>
							{collapsedMenuItem === 'paymentPromises' && <PaymentPromises accountInfo={accountInfo} />}
						</div>
					</div>
					<div className="container-accordion-content container-client-adjustemnts">
						<h3
							onClick={() => this.setCollapsedMenuItem('taxExemptions')}
							className={collapsedMenuItem === 'taxExemptions' ? '' : 'collapsed'}
							data-toggle="collapse"
						>
							Tax Exemptions
							{billingBadjesData.activeTaxExemptions > 0 &&
							<span className="float-right badge badge-warning">
								{billingBadjesData.activeTaxExemptions} Active
							</span>
							}
						</h3>
						<div className={'collapse-with-table collapse' + (collapsedMenuItem === 'taxExemptions' ? ' show': '')}>
							{collapsedMenuItem === 'taxExemptions' && <TaxExemptions accountInfo={accountInfo} />}
						</div>
					</div>
					<div className="container-accordion-content container-client-adjustemnts">
						<h3
							onClick={() => this.setCollapsedMenuItem('statusHistory')}
							className={collapsedMenuItem === 'statusHistory' ? '' : 'collapsed'}
							data-toggle="collapse"
						>
							Historical Changes
						</h3>
						<div className={'collapse-with-table collapse' + (collapsedMenuItem === 'statusHistory' ? ' show': '')}>
							{collapsedMenuItem === 'statusHistory' && <StatusHistory accountInfo={accountInfo} />}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

Billing.propTypes = {
	accountInfo: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
	const billingBadjesData = getAccountDetails(state).billing.billingBadjes;
	const myAutoPayInfo = getAccountDetails(state).accountDetails.myAutoPayInfo;
	const myDistributionMethods = getAccountDetails(state).billing.distributionMethods;
	const primaryContacts = getAdmin(state).primaryContacts;

	return {
		billingBadjesData,
		myAutoPayInfo,
		myDistributionMethods,
		primaryContacts,
	};
};

const mapDispatchToProps = {
	getBillingBadjes,
	emptyBillingBadjes,
	getAccountDistributionMethod,
	getMyAutoPayInfo,
	emptyMyAutoPayInfo,
	showModal,
	getAccountTypeDistributionMethods,
	emptyAccountTypeDistributionMethods,
	getPrimaryContacts,
};

export default connect(mapStateToProps, mapDispatchToProps)(Billing);
