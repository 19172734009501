import {
    GET_ACCOUNT_INFORMATION,
    UPDATE_ACCOUNT_INFORMATION
} from '../../actions/accountDetailsActions/accountInformation.actions';

let initialState = {
    accountInformation: {}
};

export const accountInformation = (state = initialState, action) => {
    switch (action.type) {
        case GET_ACCOUNT_INFORMATION.SUCCESS:          
            let accountInformation = {
                currentAccountManager: action.payload.accountManagerResponse,
                accountManagerEnabled: action.payload.accountManagerEnabled
            }

            return {
                ...state,
                accountInformation: accountInformation
            };
        default:
            return state;
    }
};
