import {
    GET_ACCOUNT_DOCUMENTS,
    CREATE_ACCOUNT_DOCUMENT,
} from '../../actions/accountDetailsActions/documents.actions';

let initialState = {
    documents: [],
    documentsCreateDocument: [],
};

export const accountDocuments = (state = initialState, action) => {
    switch (action.type) {
        case GET_ACCOUNT_DOCUMENTS.SUCCESS:
            return {
                ...state,
                documents: action.payload
            }
        case GET_ACCOUNT_DOCUMENTS.EMPTY:
            return {
                ...state,
                documents: []
            }
        case CREATE_ACCOUNT_DOCUMENT.SUCCESS:
            return {
                ...state,
                documentsCreateDocument: []
            }
        default:
            return state;
    }
};
