import React from 'react';

const VariableQuantityInput = ({
    feature,
    value,
    disabled,
    handleVariableFeatureQuantity,
    planIndex = 0,
    lineIndex = 0,
    featureIndex,
}) => {
    return (
        <div className="form-number"
             onClick={(e) => e.stopPropagation()}
        >
            <div className="input-group input-group-sm">
                <div className="input-group-prepend">
                    <button
                        className="btn btn-secondary"
                        type="button"
                        disabled={disabled}
                        onClick={() => handleVariableFeatureQuantity(feature, (value - 1), planIndex, lineIndex, featureIndex)}
                    >
                        <i className="fas fa-minus" />
                    </button>
                </div>
                <input
                    className="form-control"
                    type="number"
                    value={value}
                    disabled={disabled}
                    onChange={(e) => handleVariableFeatureQuantity(feature, e.target.value, planIndex, lineIndex, featureIndex)}
                />
                <div className="input-group-append">
                    <button
                        className="btn btn-secondary"
                        type="button"
                        disabled={disabled}
                        onClick={() => handleVariableFeatureQuantity(feature, (value + 1), planIndex, lineIndex, featureIndex)}
                    >
                        <i className="fas fa-plus" />
                    </button>
                </div>
            </div>
        </div>
    );
};

export default VariableQuantityInput;