import {
    CSI_PROVIDER_TYPES,
    CSI_SERVICE_TYPES,
    CSI_PRODUCT_TYPES, TAXATION_ITEM_TAX_INFO
} from '../actions/taxation.actions';

let initialState = {
    csiProviderTypes: [],
    csiServiceTypes: [],
    csiProductTypes: [],
    taxItem: ''
};

export const taxationReducer = (state = initialState, action) => {
    switch (action.type) {
        case CSI_PROVIDER_TYPES.SUCCESS:
            return {
                ...state,
                csiProviderTypes: action.payload.data
            }
        case CSI_PRODUCT_TYPES.SUCCESS:
            return {
                ...state,
                csiProductTypes: action.payload.data
            }
        case CSI_SERVICE_TYPES.SUCCESS:
            return {
                ...state,
                csiServiceTypes: action.payload.data
            }
        case TAXATION_ITEM_TAX_INFO.SUCCESS:
            return {
                ...state,
                taxItem: action.payload
            }
        default:
            return state;
    }
};
