import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';

import isEmpty from "../../../../utils/helpers";
import FormSelect from "../../../../components/UI/FormSelect";
import TextInput from "../../../../components/UI/TextInput";

import { hideModal } from '../../../../actions/modal.actions';
import {getModalData, getAdmin, getUser} from '../../../../selectors';
import { getSystemLocations, addPartner, updatePartner } from '../../../../actions/admin.actions';
import {getUserSummary} from "../../../../actions/user.actions";

class AddPartnerModal extends Component {
    state = {
        alertMessage: ''
    };

    componentDidMount() {
        this.props.getSystemLocations();
    }

    componentWillUnmount() {
    }

    partnerSchema = () => {
        let validationSchema = {
            name: Yup.string().required('Required'),
            description: Yup.string().required('Required'),
            locations: Yup.array().min(1, 'Minimum one location is required'),
            accountNumber: Yup.number().nullable()
                    .typeError("Invalid value")
                    .min(1, "Invalid value"),
        };

        return Yup.object().shape(validationSchema);
    }

    formSubmit = (values, actions) => {
        const { currentUser, getUserSummary } = this.props;

        this.setState({alertMessage: ''}, () => {
            const method = this.props.modalProps.type === 'create' ? this.props.addPartner : this.props.updatePartner;

            const postData = {...values};
            postData.locationIds = postData.locations.map(location => location.id);
            delete postData['locations'];

            method(postData).then((response) => {
                if (!response.data) {
                    actions.setSubmitting(false);
                    this.setState({alertMessage: 'Unknown error. Please try again later'});
                    return;
                }

                if (!response.data.success) {
                    actions.setSubmitting(false);
                    if (response.data.error.code === "C00226") {
                        this.setState({alertMessage: "More than one account found in the system. Provide a different Account #"})
                        return;
                    }

                    this.setState({alertMessage: response.data.error.message});
                    return;
                }

                this.props.hideModal();
                this.props.modalProps.reloadPartners();

                if (currentUser.partner && currentUser.partner.id === values.id) {
                    getUserSummary();
                }
            });
        });
    }

    scrollDropdownIntoView = (e) => {
        const elementClicked = e.target.nodeName;
        const boundingElement = e.currentTarget;
        const modalBody = document.getElementsByClassName('modal-body')[0];

        if (elementClicked !== 'LABEL') {

            setTimeout(function () {

                // Scroll down if the bottom is hidden...
                if (boundingElement.getBoundingClientRect().bottom > modalBody.getBoundingClientRect().bottom) {

                    // ... and the top won't end up hidden by scrolling down
                    if (boundingElement.getBoundingClientRect().height < modalBody.getBoundingClientRect().height) {

                        // Scroll down till bottom of element reaches bottom of screen
                        boundingElement.scrollIntoView({block: "end"});
                    }
                }
            }, 200);
        }
    }

    render() {
        const { alertMessage } = this.state;
        const { modalProps, systemLocations } = this.props;

        const locationOptions = [];
        if (!isEmpty(systemLocations)) {
            systemLocations.map((location) => {
                locationOptions.push({label: location.description, value: location});
            })
        }

        return (
            <Fragment>
                <div className="modal" style={{display: 'block'}} tabIndex="-1" role="dialog">
                    <Formik
                        initialValues={{
                            id: (modalProps.row && modalProps.row.id) || null,
                            name: (modalProps.row && modalProps.row.name) || '',
                            description: (modalProps.row && modalProps.row.description) || '',
                            active: modalProps.row ? modalProps.row.active : true,
                            partnerProperties: (modalProps.row && modalProps.row.partnerProperties) || [],
                            locations: (modalProps.row && modalProps.row.locations) || [],
                            accountNumber: (modalProps.row && modalProps.row.accountNumber) || null
                        }}
                        validationSchema = { this.partnerSchema() }
                        onSubmit = { this.formSubmit }
                        render = {({
                                 handleChange,
                                 handleSubmit,
                                 handleBlur,
                                 values,
                                 errors,
                                 touched,
                                 isSubmitting,
                                 setFieldValue}) => (

                            <form onSubmit={handleSubmit}>
                                <div className="modal-dialog">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title">
                                                {modalProps.type === 'create' ? 'Add Partner' : 'Edit Partner'}
                                            </h5>
                                            <button onClick={this.props.hideModal} type="button" className="close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div className="modal-body form-horizontal">
                                            {!isEmpty(alertMessage) && (
                                                <div className="alert alert-inline alert-danger alert-dismissible">
                                                    <p className="mb-0">{alertMessage}</p>
                                                </div>
                                            )}
                                            <div className="form-group">
												<span className="h-check">
													<label>Active&nbsp;</label>
													<div className="form-check checkbox-slider checkbox-slider--b-flat">
														<label>
															<input
                                                                name="active"
                                                                type="checkbox"
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                checked={values.active}
                                                            />
															<span>&nbsp;</span>
														</label>
													</div>
												</span>
                                            </div>
                                            <div className="form-group">
                                                <TextInput
                                                    label="Name"
                                                    name="name"
                                                    value={values.name}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    errors={errors}
                                                    touched={touched}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <TextInput
                                                    label="Description"
                                                    name="description"
                                                    value={values.description}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    errors={errors}
                                                    touched={touched}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <div onClick={this.scrollDropdownIntoView}>
                                                    <FormSelect
                                                        title="Locations"
                                                        fieldName="locations"
                                                        options={locationOptions}
                                                        setFieldValue={setFieldValue}
                                                        value={values.locations}
                                                        isMulti={true}
                                                        onBlur={handleBlur}
                                                        errors={errors}
                                                        touched={touched}
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <TextInput
                                                    label="Account # (optional)"
                                                    name="accountNumber"
                                                    value={values.accountNumber}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    errors={errors}
                                                    touched={touched}
                                                />
                                            </div>
                                        </div>
                                        <div className="modal-footer">
                                            <button onClick={this.props.hideModal} className="btn" type="button">
                                                Cancel
                                            </button>
                                            <button disabled={isSubmitting} type="submit" className="btn btn-primary">
                                                {modalProps.type === 'create' ? 'Add Partner' : 'Edit Partner'}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        )}
                    />
                </div>
                <div className="modal-backdrop show" tabIndex="1"/>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    const systemLocations = getAdmin(state).systemLocations,
        modalProps = getModalData(state).modalProps,
        currentUser = getUser(state).user;

    return {
        systemLocations,
        modalProps,
        currentUser
    };
};

const mapDispatchToProps = {
    getSystemLocations,
    hideModal,
    addPartner,
    updatePartner,
    getUserSummary,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddPartnerModal);