import {Component} from "react";

class FormikValuesManager extends Component {

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { values, formProps } = this.props;

        Object.getOwnPropertyNames(values).forEach(propName => {
            const propValue = values[propName];
            if (propValue !== prevProps.values[propName] && propValue !== formProps.values[propName]) {
                formProps.setFieldValue(propName, propValue);
            }
        });
    }

    render() {
        return null;
    }
}


export default FormikValuesManager;