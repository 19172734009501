import React, {Component} from "react";
import {connect} from 'react-redux';
import Wizard from "../../../camvio-wizard/Wizard";
import Step from "../../../camvio-wizard/Step";

import {
    Lines,
    Confirmation,
} from "./Steps";

const STEPS = {
    LINES: 'lines-step',
    CONFIRMATION: 'confirmation-step',
}

class RemoveServiceLines extends Component {

    componentDidMount() {
        document.title = 'Remove Service Lines - camvio.cloud';
        this.props.changeNavBarType('lock', 'removeServiceLines', 'Remove Service Lines');
    }

    componentWillUnmount() {
        const {changeNavBarType, hideAccountDetailsWizard, documentTitle, navbarTitle} = this.props;

        // Change navbar type and color
        document.title = documentTitle;
        changeNavBarType('account', 'accountDetails', navbarTitle);

        hideAccountDetailsWizard();
    }

    render() {
        const { accountDetailsWizardData, history, hideAccountDetailsWizard, changeActiveLeftPanelHandler } = this.props;

        return (
            <Wizard name="RemoveServiceLinesWizard">

                <Step
                    wizardData={accountDetailsWizardData.wizardData}
                    name={STEPS.LINES}
                    title="Service Lines"
                    component={Lines}
                    next={STEPS.CONFIRMATION}
                    history={history}
                />

                <Step
                    wizardData={accountDetailsWizardData.wizardData}
                    name={STEPS.CONFIRMATION}
                    title="Confirmation"
                    component={Confirmation}
                    history={history}
                    hideAccountDetailsWizard={hideAccountDetailsWizard}
                    changeActiveLeftPanelHandler={changeActiveLeftPanelHandler}
                />

            </Wizard>
        )
    }
}

const mapStateToProps = (state, props) => {
    return {
    };
};

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(RemoveServiceLines);