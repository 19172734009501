import React, {Component, Fragment} from 'react';
import TableRows from "./TableRows";
import TableHeaders from "./TableHeaders";



class CamvioTable extends Component {
    state = {
    };

    render() {
        const {
            headers,
            rows,
            reloadFunction,
            noteFunction,
            sortFunction,
            sortColumn,
            sortDirection
        } = this.props;

        return (
            <div className="cmv-container-table">
                <table className="table cmv-table cmv-table-mobile cmv-table-solid">
                    <TableHeaders headers={headers} sortFunction={sortFunction} sortColumn={sortColumn} sortDirection={sortDirection}/>
                    <TableRows rows={rows} headers={headers} reloadFunction={reloadFunction} noteFunction={noteFunction}/>
                </table>
            </div>
        );
    }
}


export default CamvioTable;
