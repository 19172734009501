import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {newOrder} from "../../../../reducers/AccountDetailsReducer/newOrder.reducer";

class Confirmation extends Component {

	componentDidMount() {

		// Clear history state if user refresh - the wizard will start from the beginning
		this.props.history.replace({
			state: {}
		});
	}

	startCurrentOrder = () => {
		const { newOrderInfo, startServiceOrder } = this.props;

		startServiceOrder(newOrderInfo.id);
	}

	redirectToTaskLists = () => {
		const { newOrderInfo } = this.props;

		this.props.history.push({
			pathname: '/task-list',
			search: `?orderId=${newOrderInfo.id}`
		});
	}

	redirectToOrders = (accountId) => {
		this.props.showAccessModal(false);
		this.props.history.push(`/account-details/${accountId}`, { activeTab: 'ordersAndQuotes' });
	}

	displayCurrentOrder = (accountId) => {
		const { newOrderInfo, history } = this.props;

		// Indicate Order Id
		history.push(`/account-details/${accountId}`, {
			activeTab: 'ordersAndQuotes',
			orderId: newOrderInfo.id
		});
	}

	render() {
		const { newOrderInfo, values, isOrderStartedData, tasksCountData } = this.props;
		var orderTypeText = 'New quote';

		switch (newOrderInfo.type) {
            case 'prospect':
                orderTypeText = 'New prospect';
                break;
            case 'order':
                orderTypeText = 'New order';
                break;
            case 'survey':
                orderTypeText = 'New survey';
                break;
            case 'signup':
                orderTypeText = 'New signup';
                break;
        }

		return (
			<div className="tab-pane active">
				<p className="text-center text-success">
					<i className="far fa-4x fa-check-circle" />
				</p>
				<p className="text-center text-success lead">
					{orderTypeText}{' '}
					<span className="font-weight-bold">#{newOrderInfo.id}</span> created succesfully.
				</p>
				{newOrderInfo.type === 'order' ? (
					<Fragment>
						<p className="text-center">
							{/* <button
								onClick={() => resetWizard(resetForm, setFieldTouched)}
								className="btn btn-outline-success btn-lg mb-1"
								type="button"
							>
								+SERVICE REQUEST
							</button> */}
							{/* <button
								onClick={() => showNewOrderWizardHandler(false)}
								className="btn btn-outline-success btn-lg mb-1"
								style={{ margin: '0px 5px' }}
								type="button"
							>
								View Order
							</button> */}
							<button
								onClick={() => this.redirectToOrders(values.accountId)}
								className="btn btn-outline-success btn-lg mb-1"
								type="button"
							>
								View Order
							</button>
							<button
								onClick={this.startCurrentOrder}
								className={
									isOrderStartedData === false ? (
										'btn btn-outline-success btn-lg mb-1 btn-order-view btn-outline-danger'
									) : (
										'btn btn-success btn-lg mb-1'
									)
								}
								type="button"
								disabled={isOrderStartedData !== null}
								style={{ margin: '0px 5px' }}
							>
								{isOrderStartedData === null && 'Execute Order'}
								{isOrderStartedData === true && (
									<Fragment>
										<i className="fas fa-check" /> Order started successfully
									</Fragment>
								)}
								{isOrderStartedData === false && (
									<Fragment>
										<i className="fas fa-exclamation-triangle" /> Order did not start
									</Fragment>
								)}
							</button>
							{tasksCountData > 0 && (
								<button
									onClick={() => this.displayCurrentOrder(values.accountId)}
									className="btn btn-warning btn-lg mb-1"
									style={{ margin: '0px 5px' }}
									type="button"
								>
									<i className="fas fa-tools" />&nbsp;{tasksCountData} {tasksCountData > 1 ? 'Tasks' : 'Task'} Pending
								</button>
							)}
						</p>
					</Fragment>
				) : newOrderInfo.type === 'quote' && (
					<p className="text-center">
						{/* <button
							onClick={() => resetWizard(resetForm, setFieldTouched)}
							className="btn btn-outline-success btn-lg mb-1"
							type="button"
						>
							+SERVICE REQUEST
						</button> */}
						<button
							onClick={() => this.redirectToOrders(newOrderInfo.accountId)}
							className="btn btn-outline-success btn-lg mb-1"
							style={{ margin: '0px 5px' }}
							type="button"
						>
							View Quote
						</button>
						<button className="btn btn-success btn-lg mb-1" type="button">
							Convert to Order
						</button>
					</p>
				)}
			</div>
		);
	}
}

Confirmation.propTypes = {
	newOrderInfo: PropTypes.object.isRequired,
	values: PropTypes.object.isRequired,
	isOrderStartedData: PropTypes.bool,
	tasksCountData: PropTypes.number
};

export default Confirmation;
