import axiosInstance from '../utils/axios-instance';

export const getTasksByServiceOrder = async (serviceOrderId) => {
	try {
		const response = await axiosInstance.get(`/order/serviceorder/${serviceOrderId}/tasks`);
		return response.data;
	} catch (err) {
		return err.response;
	}
};

export const getTasksByOrderId = async (orderId) => {
	try {
		const response = await axiosInstance.get(`/order/${orderId}/tasks`);
		return response.data;
	} catch (err) {
		return err.response;
	}
};



export const getReportUrl = async (reportId) => {
	try {
		const response = await axiosInstance.get(`/report/url/${reportId}`);
		return response.data;
	} catch (err) {
		return err.response.data;
	}
};