import {
	SET_UPDATE_CHANGE_SERVICE,
	SET_UPDATE_REQUEST_PLAN_DETAILS,
	SET_UPDATE_REQUEST_FEATURES
} from '../../actions/accountDetailsActions/updateServiceRequest.actions';

let initialState = {
	planDetails: {},
	features: {},
	updatedServiceInfo: {}
};

export const updateRequestWizard = (state = initialState, action) => {
	switch (action.type) {
		case SET_UPDATE_REQUEST_PLAN_DETAILS.SUCCESS:
			return {
				...state,
				planDetails: action.payload
			};
		case SET_UPDATE_REQUEST_PLAN_DETAILS.EMPTY:
			return {
				...state,
				planDetails: {}
			};
		case SET_UPDATE_CHANGE_SERVICE.SUCCESS:
			return {
				...state,
				updatedServiceInfo: action.payload
			}
		case SET_UPDATE_CHANGE_SERVICE.EMPTY:
			return {
				...state,
				updatedServiceInfo: {}
			}
		case SET_UPDATE_REQUEST_FEATURES.SUCCESS:
			return {
				...state,
				features: action.payload
			};
		case SET_UPDATE_REQUEST_FEATURES.EMPTY:
			return {
				...state,
				features: {}
			};

		default:
			return state;
	}
};
