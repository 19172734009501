import React, {Component, Fragment} from 'react';
import moment from 'moment';
import {isEmpty} from '../../../../../../utils/helpers';
import NumberInput from './Inputs/NumberInput';
import AddressInput from './Inputs/AddressInput';
import ContactInput from './Inputs/ContactInput';
import EmailInput from './Inputs/EmailInput';
import {toastr} from "react-redux-toastr";

export class AccountForm extends Component {

    constructor(props) {
        super(props);

        this.inputElement = React.createRef();

        this.state = {
            isEditable: '',
            isToggled: true,
            isInputDisabled: false,
            errors: {},
        };
    }

    handleEditField = (fieldName) => {
        let updatedErrors = this.state.errors;
        updatedErrors[fieldName] = "";

        this.setState({
            isEditable: fieldName,
            errors: updatedErrors,
        });
    };

    handleCloseInput = () => {
        this.setState({
            isEditable: '',
            errors: {},
        });
    };

    handleToggle = () => {
        this.setState((prevState) => ({
            isToggled: !prevState.isToggled
        }));
    };

    handleChangeCurrentInput = (fieldName) => {
        let updatedInitialValues = {...this.props.initialValues};
        updatedInitialValues[fieldName] = this.refs[fieldName].value;

        // this.setState({
        // 	initialValues: updatedInitialValues,
        // 	isEditable: ''
        // });

        this.props.handleChangeInput(updatedInitialValues);
    };

    handleUpdateContact = (fieldName, contactId) => {
        const {accountId} = this.props;

        this.setState({
            isInputDisabled: true
        });

        let updatedInitialValues = {...this.props.primaryContacts};
        // updatedInitialValues[fieldName] = this.refs[fieldName].value;
        updatedInitialValues[fieldName] = this.inputElement.value;

        let data = {
            company: updatedInitialValues.company && updatedInitialValues.company,
            dateOfBirth: updatedInitialValues.dateOfBirth && moment(updatedInitialValues.dateOfBirth).format('YYYY-MM-DD'),
            driverLicense: updatedInitialValues.driverLicense && updatedInitialValues.driverLicense,
            firstName: updatedInitialValues.firstName && updatedInitialValues.firstName,
            lastName: updatedInitialValues.lastName && updatedInitialValues.lastName,
            middleName: updatedInitialValues.middleName && updatedInitialValues.middleName,
            ssn: updatedInitialValues.ssn && updatedInitialValues.ssn,
            taxId: updatedInitialValues.taxId && updatedInitialValues.taxId,
            title: updatedInitialValues.title && updatedInitialValues.title,
            authorized: true
        };

        this.props.updateAccountContact('primary', accountId, contactId, data).then((response) => {
            if (response.success) {
                this.setState({
                    isInputDisabled: false
                });
                this.handleCloseInput();
            } else {
                this.setState({
                    isInputDisabled: false
                });
            }
        });
    };

    handleUpdatePrimaryNumbers = (fieldValue, fieldName, numbersField) => {
        const {accountId, primaryContacts} = this.props;
        let data = {};

        this.setState({
            isInputDisabled: true
        });

        if (numbersField) {
            data = {
                numberType: fieldValue,
                number: this.inputElement.value,
                verified: numbersField.verified
            };
        } else {
            data = {
                numberType: fieldValue,
                number: this.inputElement.value,
                verified: false
            };
        }

        this.props.updateContactNumber('primary', accountId, primaryContacts.id, data).then((response) => {
            if (response.success) {
                this.setState({
                    isInputDisabled: false
                });
                this.handleCloseInput();
            } else {
                this.setState({
                    isInputDisabled: false
                });
            }
        });
    };

    handleUpdatePrimaryAddress = (fieldName, contactId, addressFields) => {
        const {accountId, countryStatesData} = this.props;

        this.setState({
            isInputDisabled: true
        });

        let updatedInitialValues = {...addressFields};
        updatedInitialValues[fieldName] = this.inputElement.value;
        updatedInitialValues['contactAddressId'] = updatedInitialValues['id'];

        if (updatedInitialValues['state']) {
            updatedInitialValues['stateId'] = countryStatesData.find(
                (x) => x.name === updatedInitialValues['state']
            ).id;
            updatedInitialValues['countryId'] = countryStatesData.find(
                (x) => x.name === updatedInitialValues['state']
            ).id;
        } else {
            updatedInitialValues['stateId'] = '';
            updatedInitialValues['countryId'] = '';
        }

        let data = {
            address1: updatedInitialValues.address1 || '',
            contactAddressId: updatedInitialValues.id,
            address2: updatedInitialValues.address2 || '',
            address3: updatedInitialValues.address3 || '',
            type: updatedInitialValues.type || 'MAILING',
            zipcode: updatedInitialValues.zipcode || '',
            city: updatedInitialValues.city || '',
            stateId: updatedInitialValues.stateId || '',
            countryId: updatedInitialValues.countryId || ''
        };

        this.props.updateContactAddress('primary', accountId, contactId, data).then((response) => {
            if (response.success) {
                this.setState({
                    isInputDisabled: false
                });
                this.handleCloseInput();
            } else {
                this.setState({
                    isInputDisabled: false
                });
            }
        });
    };

    handleUpdateEmail = (fieldValue, fieldName, emailObject) => {
        const {accountId, primaryContacts, updateContactEmail} = this.props;
        let data = {};

        let updatedErrors = this.state.errors;
        updatedErrors[fieldName] = "";

        this.setState({
            isInputDisabled: true,
            errors: updatedErrors,
        });

        if (emailObject) {
            data = {
                type: fieldValue,
                email: this.inputElement.value,
                validated: emailObject.validated
            };
        }
        else {
            data = {
                type: fieldValue,
                email: this.inputElement.value,
                validated: false
            };
        }

        updateContactEmail('primary', accountId, primaryContacts.id, data).then((response) => {
            if (response.success) {
                this.setState({
                    isInputDisabled: false
                });
                this.handleCloseInput();
            } else {

                let updatedErrors = this.state.errors;

                if (response && response.error && response.error.message) {
                    updatedErrors[fieldName] = response.error.message || "There was an error.";
                }

                this.setState({
                    isInputDisabled: false,
                    errors: updatedErrors,
                });
            }
        });
    };

    handleEmailVerify = (emailObject) => {
        const {accountId, sendContactEmailVerification} = this.props;
        let data = {};

        this.setState({
            isInputDisabled: true
        });

        if (emailObject) {
            data = {
                accountId: accountId,
                email: emailObject.email,
            };
        }

        sendContactEmailVerification(data).then((response) => {
            if (response.success) {
                this.setState({
                    isInputDisabled: false
                });
            } else {
                this.setState({
                    isInputDisabled: false
                });
            }
        });
    };

    handleUpdateSecurityPhrase = () => {
        const {accountId} = this.props;

        this.setState({
            isInputDisabled: true
        });

        let securityPhraseValue = this.refs.securityPhrase.value;

        let data = {
            securityPhrase: securityPhraseValue
        };

        this.props.updateSecurityPhrase(accountId, data).then((response) => {
            if (response.success) {
                this.setState({
                    isInputDisabled: false
                });
                this.handleCloseInput();
            } else {
                this.setState({
                    isInputDisabled: false
                });
            }
        });
    };

    getNumberType = (numbers, type) => {
        if (!numbers) {
            return undefined;
        }
        return numbers.find((x) => x.numberType === type);
    };

    getType = (object, type) => {
        if (!object) {
            return undefined;
        }

        return object.find((x) => x.type === type);
    };

    render() {

        const {
            isEditable,
            isToggled,
            isInputDisabled,
        } = this.state;

        const {
            primaryContacts,
            securityPhraseData,
            countryStatesData,
        } = this.props;

        let mailingAddress = this.getType(primaryContacts.addresses, 'MAILING');
        let billingAddress = this.getType(primaryContacts.addresses, 'BILLING');
        let serviceAddress = this.getType(primaryContacts.addresses, 'SERVICE');

        let homeNumber = this.getNumberType(primaryContacts.numbers, 'HOME');
        let mobileNumber = this.getNumberType(primaryContacts.numbers, 'MOBILE');
        let businessNumber = this.getNumberType(primaryContacts.numbers, 'BUSINESS');
        let workNumber = this.getNumberType(primaryContacts.numbers, 'WORK');
        let faxNumber = this.getNumberType(primaryContacts.numbers, 'FAX');

        let personalEmail = this.getType(primaryContacts.emails, 'PERSONAL');
        let businessEmail = this.getType(primaryContacts.emails, 'BUSINESS');

        let camPermissions =
            this.props.userPermission.find((x) => x.name === 'CAM') &&
            this.props.userPermission.find((x) => x.name === 'CAM').permissions;

        let canViewSsn = camPermissions && camPermissions.includes("CAM_ACCOUNT_CONTACTS_TAB_SSN_VIEW");
        let canEditSsn = camPermissions && camPermissions.includes("CAM_ACCOUNT_CONTACTS_TAB_SSN_EDIT");
        let canEditBillingAddress = camPermissions && camPermissions.includes("CAM_ACC_EDIT_BILLING_ADDRESS");

        return (
            <div className="form-section card-toggable">
                <div className="card-toggable-header">
					<span onClick={this.handleToggle} className={isToggled ? 'h-title toggled' : 'h-title'}>
						Account
					</span>
                </div>
                <div className={isToggled ? 'card-toggable-body collapse show' : 'card-toggable-body collapse'}>
                    <div className="form-subsection">
                        <div className="form-group form-row">
                            <div className="col-sm-5 col-xl-4">Security Phrase:</div>
                            <div className="col-sm-7 col-xl-8">
                                {isEditable !== 'securityPhrase' ? (
                                    <a
                                        onClick={() => this.handleEditField('securityPhrase')}
                                        data-type="text"
                                        className="editable-generic-text"
                                    >
                                        {securityPhraseData || 'Not provided'}
                                    </a>
                                ) : (
                                    <div className="input-group input-group-editable">
                                        <input
                                            className="form-control"
                                            defaultValue={securityPhraseData}
                                            ref="securityPhrase"
                                            disabled={isInputDisabled}
                                        />
                                        <div className="input-group-append">
                                            <button
                                                type="button"
                                                onClick={() => this.handleUpdateSecurityPhrase('securityPhrase')}
                                                className="btn btn-primary btn-editable-apply"
                                                disabled={isInputDisabled}
                                            >
                                                {!isInputDisabled ? (
                                                    <i className="fas fa-check"/>
                                                ) : (
                                                    <i className="fas fa-spin fa-circle-notch"/>
                                                )}
                                            </button>
                                            <button
                                                type="button"
                                                onClick={this.handleCloseInput}
                                                className="btn btn-outline-secondary btn-editable-cancel"
                                                disabled={isInputDisabled}
                                            >
                                                <i className="fas fa-times"/>
                                            </button>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="form-subsection">
                        <h6>Account Holder</h6>

                        <ContactInput
                            header="First Name"
                            isEditable={isEditable}
                            contactId={primaryContacts.id}
                            fieldName="firstName"
                            currentObject={primaryContacts}
                            handleCloseInput={this.handleCloseInput}
                            handleEditField={this.handleEditField}
                            handleUpdate={this.handleUpdateContact}
                            inputRef={(el) => (this.inputElement = el)}
                            isDisabled={isInputDisabled}
                        />

                        <ContactInput
                            header="Last Name"
                            isEditable={isEditable}
                            contactId={primaryContacts.id}
                            fieldName="lastName"
                            currentObject={primaryContacts}
                            handleCloseInput={this.handleCloseInput}
                            handleEditField={this.handleEditField}
                            handleUpdate={this.handleUpdateContact}
                            inputRef={(el) => (this.inputElement = el)}
                            isDisabled={isInputDisabled}
                        />

                        <ContactInput
                            header="Company"
                            isEditable={isEditable}
                            contactId={primaryContacts.id}
                            fieldName="company"
                            currentObject={primaryContacts}
                            handleCloseInput={this.handleCloseInput}
                            handleEditField={this.handleEditField}
                            handleUpdate={this.handleUpdateContact}
                            inputRef={(el) => (this.inputElement = el)}
                            isDisabled={isInputDisabled}
                        />

                        <ContactInput
                            header="Driver's License"
                            isEditable={isEditable}
                            contactId={primaryContacts.id}
                            fieldName="driverLicense"
                            currentObject={primaryContacts}
                            handleCloseInput={this.handleCloseInput}
                            handleEditField={this.handleEditField}
                            handleUpdate={this.handleUpdateContact}
                            inputRef={(el) => (this.inputElement = el)}
                            isDisabled={isInputDisabled}
                        />

                        {canViewSsn &&
                        <ContactInput
                            header="SSN"
                            isEditable={isEditable}
                            contactId={primaryContacts.id}
                            fieldName="ssn"
                            currentObject={primaryContacts}
                            handleCloseInput={this.handleCloseInput}
                            handleEditField={this.handleEditField}
                            handleUpdate={this.handleUpdateContact}
                            inputRef={(el) => (this.inputElement = el)}
                            isDisabled={isInputDisabled || !canEditSsn}
                        />
                        }

                        <ContactInput
							header="TaxId"
							isEditable={isEditable}
							contactId={primaryContacts.id}
							fieldName="taxId"
							currentObject={primaryContacts}
							handleCloseInput={this.handleCloseInput}
							handleEditField={this.handleEditField}
							handleUpdate={this.handleUpdateContact}
							inputRef={(el) => (this.inputElement = el)}
							isDisabled={isInputDisabled}
						/>

                    </div>

                    <div className="form-subsection has-separators">

                        <h6>Contact Numbers</h6>

                        <NumberInput
                            fieldName="numbers.home"
                            fieldType="HOME"
                            contactId={primaryContacts.id}
                            numberObject={homeNumber}
                            handleEditField={this.handleEditField}
                            isEditable={isEditable}
                            isDisabled={isInputDisabled}
                            showSendButton={false}
                            handleCloseInput={this.handleCloseInput}
                            handleUpdatePrimaryNumbers={this.handleUpdatePrimaryNumbers}
                            inputRef={(el) => (this.inputElement = el)}
                        />

                        <NumberInput
                            fieldName="numbers.mobile"
                            fieldType="MOBILE"
                            contactId={primaryContacts.id}
                            numberObject={mobileNumber}
                            handleEditField={this.handleEditField}
                            isEditable={isEditable}
                            isDisabled={isInputDisabled}
                            showSendButton={true}
                            handleCloseInput={this.handleCloseInput}
                            handleUpdatePrimaryNumbers={this.handleUpdatePrimaryNumbers}
                            inputRef={(el) => (this.inputElement = el)}
                        />

                        <NumberInput
                            fieldName="numbers.business"
                            fieldType="BUSINESS"
                            contactId={primaryContacts.id}
                            numberObject={businessNumber}
                            handleEditField={this.handleEditField}
                            isEditable={isEditable}
                            isDisabled={isInputDisabled}
                            showSendButton={false}
                            handleCloseInput={this.handleCloseInput}
                            handleUpdatePrimaryNumbers={this.handleUpdatePrimaryNumbers}
                            inputRef={(el) => (this.inputElement = el)}
                        />
                        <NumberInput
                            fieldName="numbers.work"
                            fieldType="WORK"
                            contactId={primaryContacts.id}
                            numberObject={workNumber}
                            handleEditField={this.handleEditField}
                            isEditable={isEditable}
                            isDisabled={isInputDisabled}
                            showSendButton={false}
                            handleCloseInput={this.handleCloseInput}
                            handleUpdatePrimaryNumbers={this.handleUpdatePrimaryNumbers}
                            inputRef={(el) => (this.inputElement = el)}
                        />

                        <NumberInput
                            fieldName="numbers.fax"
                            fieldType="FAX"
                            numberObject={faxNumber}
                            handleEditField={this.handleEditField}
                            isEditable={isEditable}
                            isDisabled={isInputDisabled}
                            showSendButton={false}
                            handleCloseInput={this.handleCloseInput}
                            handleUpdatePrimaryNumbers={this.handleUpdatePrimaryNumbers}
                            inputRef={(el) => (this.inputElement = el)}
                        />
                    </div>

                    <div className="form-subsection">

                        <div className="form-header">
							<span className="form-header-title">
                                <div style={{display: "flex"}}>
						        <h6 style={{marginRight: "0.5rem"}}>Billing Address</h6>
                                    {billingAddress && !isEmpty(billingAddress.verified) && billingAddress.verified ?
                                        <span className="badge badge-outline-primary" style={{
                                            color: "#95ba55",
                                            border: "1px solid #95ba55"
                                        }}>
                                            Verified
                                         </span>
                                        :
                                        <span className="badge badge-outline-primary" style={{
                                            color: "#6c757d",
                                            border: "1px solid #6c757d"
                                        }}>
                                            Not Verified
                                         </span>
                                    }
                                    </div>
							</span>

                            <span className="form-header-toolbar">
								{canEditBillingAddress &&
                                <button
                                    className="btn btn-xs btn-outline-secondary btn-cn-edit"
                                    onClick={() => this.props.showModal('ACCOUNT_MAILING_ADDRESS_MODAL', {primaryContacts, ...{type: 'BILLING',
                                            verified: billingAddress.verified }})}
                                    type="button"
                                >
                                    Edit
                                </button>
								}
							</span>
                        </div>

                    {!isEmpty(billingAddress) && <>

                        {!isEmpty(billingAddress.address1) &&
                        <AddressInput
                            header="Line 1"
                            fieldName="address1.billing"
                            fieldType="address1"
                            currentObject={billingAddress}
                            contactId={primaryContacts.id}
                            handleEditField={this.handleEditField}
                            isEditable={isEditable}
                            isDisabled={isInputDisabled}
                            handleCloseInput={this.handleCloseInput}
                            handleUpdate={this.handleUpdatePrimaryAddress}
                            inputRef={(el) => (this.inputElement = el)}
                        />
                        }

                        {!isEmpty(billingAddress.address2) &&
                        <AddressInput
                            header="Line 2"
                            fieldName="address2.billing"
                            fieldType="address2"
                            currentObject={billingAddress}
                            contactId={primaryContacts.id}
                            handleEditField={this.handleEditField}
                            isEditable={isEditable}
                            isDisabled={isInputDisabled}
                            handleCloseInput={this.handleCloseInput}
                            handleUpdate={this.handleUpdatePrimaryAddress}
                            inputRef={(el) => (this.inputElement = el)}
                        />
                        }

                        {!isEmpty(billingAddress.address3) &&
                        <AddressInput
                            header="Line 3"
                            fieldName="address3.billing"
                            fieldType="address3"
                            currentObject={billingAddress}
                            contactId={primaryContacts.id}
                            handleEditField={this.handleEditField}
                            isEditable={isEditable}
                            isDisabled={isInputDisabled}
                            handleCloseInput={this.handleCloseInput}
                            handleUpdate={this.handleUpdatePrimaryAddress}
                            inputRef={(el) => (this.inputElement = el)}
                        />
                        }

                        {!isEmpty(billingAddress.city) &&
                        <AddressInput
                            header="City"
                            fieldName="city.billing"
                            fieldType="city"
                            currentObject={billingAddress}
                            contactId={primaryContacts.id}
                            handleEditField={this.handleEditField}
                            isEditable={isEditable}
                            isDisabled={isInputDisabled}
                            handleCloseInput={this.handleCloseInput}
                            handleUpdate={this.handleUpdatePrimaryAddress}
                            inputRef={(el) => (this.inputElement = el)}
                        />
                        }

                        {!isEmpty(billingAddress.state) &&
                        <AddressInput
                            header="State"
                            type="select"
                            optionsData={countryStatesData}
                            fieldName="state.billing"
                            fieldType="state"
                            currentObject={billingAddress}
                            contactId={primaryContacts.id}
                            handleEditField={this.handleEditField}
                            isEditable={isEditable}
                            isDisabled={isInputDisabled}
                            handleCloseInput={this.handleCloseInput}
                            handleUpdate={this.handleUpdatePrimaryAddress}
                            inputRef={(el) => (this.inputElement = el)}
                        />
                        }

                        {!isEmpty(billingAddress.zipcode) &&
                        <AddressInput
                            header="Zipcode"
                            fieldName="zipcode.billing"
                            fieldType="zipcode"
                            currentObject={billingAddress}
                            contactId={primaryContacts.id}
                            handleEditField={this.handleEditField}
                            isEditable={isEditable}
                            isDisabled={isInputDisabled}
                            handleCloseInput={this.handleCloseInput}
                            handleUpdate={this.handleUpdatePrimaryAddress}
                            inputRef={(el) => (this.inputElement = el)}
                        />
                        }

                    </> }

                    </div>

                    {!isEmpty(serviceAddress) && (
                    <div className="form-subsection">

                        <h6>Service Address</h6>

                        <AddressInput
                            header="Line 1"
                            fieldName="address1.service"
                            fieldType="address1"
                            currentObject={serviceAddress}
                            contactId={primaryContacts.id}
                            handleEditField={this.handleEditField}
                            isEditable={isEditable}
                            isDisabled={isInputDisabled}
                            handleCloseInput={this.handleCloseInput}
                            handleUpdate={this.handleUpdatePrimaryAddress}
                            inputRef={(el) => (this.inputElement = el)}
                        />

                        <AddressInput
                            header="Line 2"
                            fieldName="address2.service"
                            fieldType="address2"
                            currentObject={serviceAddress}
                            contactId={primaryContacts.id}
                            handleEditField={this.handleEditField}
                            isEditable={isEditable}
                            isDisabled={isInputDisabled}
                            handleCloseInput={this.handleCloseInput}
                            handleUpdate={this.handleUpdatePrimaryAddress}
                            inputRef={(el) => (this.inputElement = el)}
                        />

                        <AddressInput
                            header="Line 3"
                            fieldName="address3.service"
                            fieldType="address3"
                            currentObject={serviceAddress}
                            contactId={primaryContacts.id}
                            handleEditField={this.handleEditField}
                            isEditable={isEditable}
                            isDisabled={isInputDisabled}
                            handleCloseInput={this.handleCloseInput}
                            handleUpdate={this.handleUpdatePrimaryAddress}
                            inputRef={(el) => (this.inputElement = el)}
                        />

                        <AddressInput
                            header="City"
                            fieldName="city.service"
                            fieldType="city"
                            currentObject={serviceAddress}
                            contactId={primaryContacts.id}
                            handleEditField={this.handleEditField}
                            isEditable={isEditable}
                            isDisabled={isInputDisabled}
                            handleCloseInput={this.handleCloseInput}
                            handleUpdate={this.handleUpdatePrimaryAddress}
                            inputRef={(el) => (this.inputElement = el)}
                        />

                        <AddressInput
                            header="State"
                            type="select"
                            optionsData={countryStatesData}
                            fieldName="state.service"
                            fieldType="state"
                            currentObject={serviceAddress}
                            contactId={primaryContacts.id}
                            handleEditField={this.handleEditField}
                            isEditable={isEditable}
                            isDisabled={isInputDisabled}
                            handleCloseInput={this.handleCloseInput}
                            handleUpdate={this.handleUpdatePrimaryAddress}
                            inputRef={(el) => (this.inputElement = el)}
                        />

                        <AddressInput
                            header="zipcode"
                            fieldName="zipcode.service"
                            fieldType="zipcode"
                            currentObject={serviceAddress}
                            contactId={primaryContacts.id}
                            handleEditField={this.handleEditField}
                            isEditable={isEditable}
                            isDisabled={isInputDisabled}
                            handleCloseInput={this.handleCloseInput}
                            handleUpdate={this.handleUpdatePrimaryAddress}
                            inputRef={(el) => (this.inputElement = el)}
                        />

                    </div>
                    )}

                    <div className="form-subsection">

                        <h6>Emails</h6>

                        <EmailInput
                            fieldName="emails.personal"
                            fieldType="PERSONAL"
                            fieldError={this.state.errors['emails.personal']}
                            contactId={primaryContacts.id}
                            currentObject={personalEmail}
                            handleEditField={this.handleEditField}
                            isEditable={isEditable}
                            isDisabled={isInputDisabled}
                            handleCloseInput={this.handleCloseInput}
                            handleUpdate={this.handleUpdateEmail}
                            handleEmailVerify={this.handleEmailVerify}
                            inputRef={(el) => (this.inputElement = el)}
                        />

                        <EmailInput
                            fieldName="emails.business"
                            fieldType="BUSINESS"
                            fieldError={this.state.errors['emails.business']}
                            contactId={primaryContacts.id}
                            currentObject={businessEmail}
                            handleEditField={this.handleEditField}
                            isEditable={isEditable}
                            isDisabled={isInputDisabled}
                            handleCloseInput={this.handleCloseInput}
                            handleUpdate={this.handleUpdateEmail}
                            handleEmailVerify={this.handleEmailVerify}
                            inputRef={(el) => (this.inputElement = el)}
                        />

                    </div>
                </div>
            </div>
        );
    }
}

export default AccountForm;
