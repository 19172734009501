import {createLoadingSelector, getPaymentProviders, getUser} from "../../../selectors";
import React, {Component} from "react";
import {connect} from 'react-redux';
import { toastr } from 'react-redux-toastr';
import Loader from "../../../components/Loader";
import {
    getCurrentPaymentProviderId,
    findProviderIds,
    getPaymentProviderById,
    PAYMENT_PROVIDER_FIND_CURRENT_PROVIDER_ID,
    PAYMENT_PROVIDER_FIND_PROVIDER,
    PAYMENT_PROVIDER_FIND_ALL_IDS,
    patchPaymentProvider,
    clearCurrentPaymentProviderId,
    clearPaymentProviders, clearProviderIds, setCurrentProviderById
} from "../../../actions/paymentProvider.actions";
import {Formik} from "formik";
import FormSelect from "../../../components/UI/FormSelect";
import TextInput from "../../../components/UI/TextInput";
import {getErrorMessage} from "../../../utils/helpers";
import {showModal} from "../../../actions/modal.actions";

class PaymentProviderSettings extends Component {

    componentDidMount() {
        document.title = 'Payment Provider - camvio.cloud';
        this.props.changeNavBarType('default', 'Payment Provider');

        this.props.getCurrentPaymentProviderId().then(resp => {
            this.props.getPaymentProviderById(resp.data.id);
        });

        this.props.findProviderIds();
    }

    componentWillUnmount() {
        this.props.clearCurrentPaymentProviderId();
        this.props.clearPaymentProviders();
        this.props.clearProviderIds();
    }

    handleSubmit = (values, actions) => {
        actions.setSubmitting(false);
    }

    onCurrentProviderChangeHandler = (name, value, formProps) => {
        formProps.setFieldValue(name, value);
        if (value) {
            this.props.getPaymentProviderById(value).then(resp => {
                formProps.setFieldValue('providerConfiguration', resp.data);
            });
        } else {
            formProps.setFieldValue('providerConfiguration', null);
        }
    }

    setCurrentProvider = ({setSubmitting, values}) => {
        setSubmitting(true);

        this.props.setCurrentProviderById(values.providerId)
            .then((response) => {
                if (response.success === false) {
                    console.error(response);
                    toastr.error(getErrorMessage(response).message, { position: 'top-center' });
                } else {
                    const message = response.data && response.data.message || response.data || 'Payment provider successfully set as current';
                    toastr.success(message, { timeOut: 3000, position: 'top-center' });
                }

                setSubmitting(false);
            }).catch(e => {
            console.error(e);
            toastr.error("Something went wrong", { timeOut: 5000, position: 'top-center' });

            setSubmitting(false);
        });
    }

    onSetCurrentProviderBtnClick = (formProps) => {
        const selectedProviderId = formProps.values.providerId;
        const providerId = selectedProviderId && selectedProviderId !== 'null' && selectedProviderId || 'NONE';

        toastr.confirm("Are you sure you want to set the current provider to " + providerId, {
            cancelText: "Cancel",
            okText: "Confirm",
            onOk: () => this.setCurrentProvider(formProps)
        })
    }

    saveProviderConfiguration = ({setSubmitting, values}) => {
        setSubmitting(true);

        this.props.patchPaymentProvider(values.providerId, values.providerConfiguration)
            .then((response) => {
                if (response.success === false) {
                    console.error(response);
                    toastr.error(getErrorMessage(response).message, { position: 'top-center' });
                } else {
                    const message = response.data && response.data.message || response.data || 'Payment provider updated successfully';

                    toastr.success(message, { timeOut: 3000, position: 'top-center' });

                    this.props.getPaymentProviderById(values.providerId)
                        .then(resp => setSubmitting(false))
                        .catch(error => {
                            console.error(error);
                            setSubmitting(false)
                        });
                }
            }).catch(e => {
            console.error(e);
            toastr.error("Something went wrong", { timeOut: 5000, position: 'top-center' });

           setSubmitting(false);
        });
    }

    onConfigurationSaveBtnClick = (updatedProps, formProps) => {

        this.props.showModal('GENERIC_MODAL', {
            title: "Save Configuration",
            text: "Are you sure you want to make these changes?",
            size: 'lg',
            cancelText: "Cancel",
            okText: "Save",
            onOk: () => this.saveProviderConfiguration(formProps),
            component: () => (
                <table className="table">
                    <thead>
                        <th>name</th>
                        <th>old value</th>
                        <th>new value</th>
                    </thead>
                    <tbody>
                    {updatedProps.map(prop =>
                        <tr>
                            <td>{prop.key}</td>
                            <td>{prop.oldVal}</td>
                            <td>{prop.newVal}</td>
                        </tr>
                    )}
                    </tbody>
                </table>
            )
        });
    }

    render() {
        const {
            isLoadingCurrentProviderId, currentProviderId,
            isLoadingProviderConfiguration, providers,
            isLoadingProviderIds, providerIds
        } = this.props;

        const providerIdOptions = [{label: 'NONE', value: null}];

        if (providerIds) {
            providerIds.forEach(pid => providerIdOptions.push({label: pid, value: pid }));
        }

        return (
            <div className="cmv-container cmv-container-dashboard cmv-container-client-selected">
                <Formik
                    initialValues={{
                        providerId: currentProviderId,
                        providerConfiguration: currentProviderId && providers && providers[currentProviderId]
                    }}
                    enableReinitialize={true}
                    onSubmit={this.handleSubmit}
                    render={(formProps) => {
                        const {handleChange, handleSubmit, handleBlur, values, errors, touched, isSubmitting, setFieldValue, validateForm} = formProps;

                        const originalProviderConfiguration = providers && providers[values.providerId];
                        const updatedProps = [];
                        originalProviderConfiguration && values.providerConfiguration &&
                        originalProviderConfiguration.properties.forEach(origProp => {
                            const currProp = values.providerConfiguration.properties.find(currProp => currProp.key === origProp.key);
                            if (currProp && currProp.value !== origProp.value) {
                                updatedProps.push({ key: currProp.key, oldVal: origProp.value, newVal: currProp.value });
                            }
                        });
                        return (
                    <>
                        <form onSubmit={handleSubmit}>
                            <div className="form-vertical">
                                <div className="form-group">
                                    <FormSelect
                                        title="Current Provider"
                                        fieldName="providerId"
                                        placeholder="Select Provider"
                                        options={providerIdOptions}
                                        setFieldValue={setFieldValue}
                                        value={values.providerId || null}
                                        onBlur={handleBlur}
                                        errors={errors}
                                        touched={touched}
                                        isDisabled={isLoadingCurrentProviderId || isLoadingProviderIds || isSubmitting}
                                        isLoading={isLoadingCurrentProviderId}
                                        onChange={(name, value) => this.onCurrentProviderChangeHandler(name, value, {values, setFieldValue})}
                                    />
                                </div>
                                <div style={{textAlign: 'right'}}>
                                    <button
                                        onClick={() => this.onSetCurrentProviderBtnClick(formProps)}
                                        disabled={isLoadingCurrentProviderId || isLoadingProviderIds || isSubmitting || (currentProviderId === values.providerId)}
                                        type="button"
                                        className="btn btn-primary">
                                        {isSubmitting &&
                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" style={{marginRight: '5px'}}/>}
                                        Set As Current
                                    </button>
                                </div>
                                {(isLoadingCurrentProviderId || isLoadingProviderIds || isLoadingProviderConfiguration) && <Loader />
                                    || values.providerConfiguration &&
                                    <div className='card-vertical card-plane'>
                                        <div className='card-body'>
                                            <div className='plan-header plan-header-centered'>
                                                <span className="plan-title"><i className="fas fa-fw fa-cogs"/> Configuration</span>
                                            </div>
                                            <div className='plan-body'>
                                                <div className="form-group service-wrapper">
                                                    {values.providerConfiguration && values.providerConfiguration.properties.map((prop, idx) => {
                                                        const fieldName = `providerConfiguration.properties[${idx}].value`;

                                                        return (
                                                            <TextInput
                                                                label={prop.key}
                                                                type="text"
                                                                name={fieldName}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                value={prop.value || ''}
                                                                disabled={isSubmitting}
                                                                autocomplete={false}
                                                            />
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                            <div style={{textAlign: 'right'}}>
                                                <button
                                                    onClick={() => this.onConfigurationSaveBtnClick(updatedProps, formProps)}
                                                    disabled={isLoadingCurrentProviderId || isLoadingProviderIds || isSubmitting || updatedProps.length < 1}
                                                    type="button"
                                                    className="btn btn-primary">
                                                    {isSubmitting &&
                                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" style={{marginRight: '5px'}}/>}
                                                    Save
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </form>
                    </>
                )}}/>
            </div>
        );
    }
}

const selectCurrentProviderIdLoading = createLoadingSelector(['PAYMENT_PROVIDER_FIND_CURRENT_PROVIDER_ID']);
const selectLoadingProviderConfiguration = createLoadingSelector(['PAYMENT_PROVIDER_FIND_PROVIDER']);
const selectLoadingProviderIds = createLoadingSelector(['PAYMENT_PROVIDER_FIND_ALL_IDS']);

const mapStateToProps = (state) => {
    const currentProviderId = getPaymentProviders(state).currentProviderId,
        providers = getPaymentProviders(state).providers,
        providerIds = getPaymentProviders(state).providerIds,
        isLoadingCurrentProviderId = selectCurrentProviderIdLoading(state),
        isLoadingProviderConfiguration = selectLoadingProviderConfiguration(state),
        isLoadingProviderIds = selectLoadingProviderIds(state);

    return {
        currentProviderId,
        providers,
        providerIds,
        isLoadingCurrentProviderId,
        isLoadingProviderConfiguration,
        isLoadingProviderIds
    };
};

const mapDispatchToProps = {
    showModal,
    getCurrentPaymentProviderId,
    setCurrentProviderById,
    getPaymentProviderById,
    findProviderIds,
    patchPaymentProvider,
    clearCurrentPaymentProviderId,
    clearPaymentProviders,
    clearProviderIds,
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentProviderSettings);