import React, {Component} from 'react';
import {connect} from "react-redux";
import {searchSerializedItem} from "../../../actions/provisioning.actions";
import {getServiceEquipment, clearServiceEquipment} from "../../../actions/createServiceRequest.actions";
import {showModal} from "../../../actions/modal.actions";
import {createLoadingSelector, getCreateServiceRequest, getProvisioning} from "../../../selectors";
import isEmpty from "../../../utils/helpers";
import Select from "react-select";
import {groupStyles} from "../../../utils/SelectStyles";
// import Creatable from "react-select/creatable/dist/react-select.esm";
import Loader from "../../../components/Loader";

export class ServiceLineDevices extends Component {

    state = {
        toggledLines: {},
        serializedItemsSelectedIndex: -1,
        initialToggle: true,
        deviceProfile: [],
        serializedItems: [],
        createdOptions: {},
        scanningBarcode: null,
        barcodeValue: null,
        barcodeFetched: null,
        barcodeFound: null,
        barcodeSet: null,
    }

    componentDidMount() {
        this.props.clearServiceEquipment();
        if (!isEmpty(this.props.serviceorderInfo.serviceLines)) {
            this.props.getServiceEquipment(null, null, this.props.serviceorderInfo.serviceLines[0].id);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if ( this.props.serviceorderInfo !== prevProps.serviceorderInfo && this.props.serviceorderInfo.serviceLines) {
            this.setState({
                deviceProfile: Array(this.props.serviceorderInfo.serviceLines.length),
                serializedItems: Array(this.props.serviceorderInfo.serviceLines.length),
            });
            this.props.getServiceEquipment(null, null, this.props.serviceorderInfo.serviceLines[0].id);
        }
    }

    toggleServiceLine = (serviceLineId) => {
        this.setState({
            toggledLines: {
                ...this.state.toggledLines,
                [serviceLineId]: !this.state.toggledLines[serviceLineId]
            }
        });
    }

    onEquipmentChangeHandler = (option, serviceLineIndex, {setFieldValue, values}) => {
        const {serviceEquipment} = this.props;
        const deviceGroupId = values._camvio_serviceline_devices[serviceLineIndex].deviceGroupId;
        const selectedDeviceGroupId = option.value;

        if (deviceGroupId === selectedDeviceGroupId) {
            return;
        }

        // reset device profiles
        const deviceGroup = serviceEquipment.find(dg => dg.deviceGroupId === selectedDeviceGroupId);

        setFieldValue(`_camvio_serviceline_devices[${serviceLineIndex}].deviceGroupId`, selectedDeviceGroupId);
        setFieldValue(`_camvio_serviceline_devices[${serviceLineIndex}].itemId`, null);
        setFieldValue(`_camvio_serviceline_devices[${serviceLineIndex}].numbers`, {});
        setFieldValue(`_camvio_serviceline_devices[${serviceLineIndex}].deviceProfileId`, deviceGroup.deviceProfiles[0].id);

        const deviceProfiles = this.state.deviceProfile;
        deviceProfiles[serviceLineIndex] = deviceGroup.deviceProfiles[0]
        this.setState({
            deviceProfile: deviceProfiles,
        });
    }

    onItemChangeHandler = (option, serviceLineIndex, {setFieldValue, setTouched, values}) => {
        const itemId = values._camvio_serviceline_devices[serviceLineIndex].itemId;
        const selectedItemId = option.value;

        if (itemId === selectedItemId) {
            return;
        }

        setFieldValue(`_camvio_serviceline_devices[${serviceLineIndex}].itemId`, selectedItemId);
        setFieldValue(`_camvio_serviceline_devices[${serviceLineIndex}].numbers`, {});
        setTouched(`_camvio_serviceline_devices[${serviceLineIndex}].numbers`, false);

        const serializedItems = this.state.serializedItems;
        serializedItems[serviceLineIndex] = [];
        this.setState({serializedItems: serializedItems});

        this.itemNumSearch(null, selectedItemId, null, setFieldValue, values, serviceLineIndex);
    }

    onInputChangeHandler = (event, serviceLineIndex, {setFieldValue, values}) => {
        const {deviceProfile} = this.state;

        let fieldId = event.target.name.replace('_field', '');
        let numbers = values._camvio_serviceline_devices[serviceLineIndex].numbers;

        // Update the changed field
        numbers[fieldId] = event.target.value;

        // Get any missing fields from deviceProfile
        {deviceProfile[serviceLineIndex].numTypes.map(numType => {
            if (Object.keys(numbers).includes(numType.id.toString()) === false) {
                numbers[numType.id] = undefined;
            }
        })}

        // Store number values
        setFieldValue(`_camvio_serviceline_devices[${serviceLineIndex}].numbers`, numbers);
    }

    onDropdownChangeHandler = (serviceLineIndex, {setFieldValue, values}) => (option, action) => {
        const {deviceProfile} = this.state;

        let selectedItem = this.state.serializedItems[serviceLineIndex][
            option
                ? option.value
                : this.refs[this.state.scanningBarcode].props.options.filter(option => option.label === this.state.barcodeValue)[0].value
            ];
        let numbers = values._camvio_serviceline_devices[serviceLineIndex].numbers;

        // If we selected a preexisting option or entered a barcode
        if (isEmpty(action) || (action.action === "select-option" && !isEmpty(selectedItem))) {
            let fieldId = action ? action.name.replace('_field', '') : this.state.scanningBarcode.replace('_field', '');

            // Update all fields with this item's data
            selectedItem.numbers.forEach(number => {
                numbers[number.systemNumType.id] = number.number;
            });
        }

        // If we created an option
        if (action && action.action === "create-option" && option.__isNew__) {
            let fieldId = action.name.replace('_field', '');

            // Update only the changed field
            numbers[fieldId] = option.value;

            // Store the created option
            let updatedCreatedOptions = this.state.createdOptions;
            if (!updatedCreatedOptions.hasOwnProperty(fieldId)) {
                updatedCreatedOptions[fieldId] = [];
            }
            updatedCreatedOptions[fieldId].push(option);

            this.setState({createdOptions: updatedCreatedOptions});
        }

        // If we selected a newly created option
        if (action && action.action === "select-option" && option.__isNew__) {
            let fieldId = action.name.replace('_field', '');

            // Update only the changed field
            numbers[fieldId] = option.value;
        }

        // Get any missing fields from deviceProfile
        {deviceProfile[serviceLineIndex].numTypes.map(numType => {
            if (Object.keys(numbers).includes(numType.id.toString()) === false) {
                numbers[numType.id] = undefined;
            }
        })}

        // Store number values
        setFieldValue(`_camvio_serviceline_devices[${serviceLineIndex}].numbers`, numbers);

        // If we're setting a barcode
        if (this.state.scanningBarcode) {
            this.setState({barcodeSet: true})
        }
    }

    renderEquipmentSelect = (formProps, serviceLineIndex) => {
        const {values, touched, errors} = formProps;
        const deviceGroupId = values._camvio_serviceline_devices[serviceLineIndex].deviceGroupId;

        const {serviceEquipment} = this.props;
        const options = [];

        if (serviceEquipment) {
            serviceEquipment.forEach(dg => {
                options.push({
                    // label: dg.name + " (" + dg.description + ")",
                    label: dg.description,
                    value: dg.deviceGroupId
                });
            });
        }

        const selectedDeviceGroup = options.find(opt => opt.value === deviceGroupId);

        return (
            <div className="form-group">
                <label>Select Equipment</label>
                <Select
                    name="deviceGroupId"
                    value={selectedDeviceGroup}
                    onChange={(option) => this.onEquipmentChangeHandler(option, serviceLineIndex, formProps)}
                    options={options}
                    placeholder="Select Equipment"
                    styles={groupStyles}
                    isDisabled={this.isSubmitting()}
                    className={
                        touched._camvio_serviceline_devices && touched._camvio_serviceline_devices[serviceLineIndex] && touched._camvio_serviceline_devices[serviceLineIndex].deviceGroupId &&
                        errors._camvio_serviceline_devices && errors._camvio_serviceline_devices[serviceLineIndex] && errors._camvio_serviceline_devices[serviceLineIndex].deviceGroupId
                            ? "is-invalid"
                            : "" }
                />
                {touched._camvio_serviceline_devices && touched._camvio_serviceline_devices[serviceLineIndex] && touched._camvio_serviceline_devices[serviceLineIndex].deviceGroupId &&
                errors._camvio_serviceline_devices && errors._camvio_serviceline_devices[serviceLineIndex] && errors._camvio_serviceline_devices[serviceLineIndex].deviceGroupId &&
                <div className="invalid-feedback">Please choose equipment.</div>
                }
            </div>
        );
    }

    renderItemSelect = (formProps, serviceLineIndex) => {
        const {values, touched, errors} = formProps;
        const itemId = values._camvio_serviceline_devices[serviceLineIndex].itemId;

        const {deviceProfile} = this.state;

        if (isEmpty(deviceProfile[serviceLineIndex])) {
            return null;
        }

        const options = [];

        if (deviceProfile[serviceLineIndex].possibleItems) {
            deviceProfile[serviceLineIndex].possibleItems.forEach(item => {
                options.push({
                    label: item.description,
                    value: item.id
                });
            });
        }

        let selectedItem = options.find(opt => opt.value === itemId);

        if (isEmpty(selectedItem)) {
            selectedItem = null;
        }

        return (
            <div className="form-group">
                <label>Select Device</label>
                <Select
                    name="itemId"
                    value={selectedItem}
                    onChange={(option) => this.onItemChangeHandler(option, serviceLineIndex, formProps)}
                    options={options}
                    placeholder="Select Device"
                    styles={groupStyles}
                    isDisabled={this.isSubmitting()}
                    className={
                        touched._camvio_serviceline_devices && touched._camvio_serviceline_devices[serviceLineIndex] && touched._camvio_serviceline_devices[serviceLineIndex].itemId &&
                        errors._camvio_serviceline_devices && errors._camvio_serviceline_devices[serviceLineIndex] && errors._camvio_serviceline_devices[serviceLineIndex].itemId
                            ? "is-invalid"
                            : "" }
                />
                {touched._camvio_serviceline_devices && touched._camvio_serviceline_devices[serviceLineIndex] && touched._camvio_serviceline_devices[serviceLineIndex].itemId &&
                errors._camvio_serviceline_devices && errors._camvio_serviceline_devices[serviceLineIndex] && errors._camvio_serviceline_devices[serviceLineIndex].itemId &&
                <div className="invalid-feedback">Please choose device.</div>
                }
            </div>
        );
    }

    renderItemNumbers = (formProps, serviceLineIndex) => {
        const {values, touched, errors} = formProps;
        const itemId = values._camvio_serviceline_devices[serviceLineIndex].itemId;

        const {deviceProfile} = this.state;

        if (isEmpty(itemId)) {
            return null;
        }

        return (
            <>
                <fieldset
                    // className={(
                    // touched._camvio_serviceline_devices && touched._camvio_serviceline_devices[0].numbers &&
                    // errors._camvio_serviceline_devices && errors._camvio_serviceline_devices[0].numbers)
                    // ? "is-invalid"
                    // : "" }
                >
                    <legend>Device Numbers</legend>
                    {deviceProfile[serviceLineIndex].numTypes.map(numType => (
                        <div className="form-group row">
                            {this.renderItemNumber(formProps, numType, serviceLineIndex)}
                        </div>
                    ))}
                </fieldset>
                {/*{touched._camvio_serviceline_devices && touched._camvio_serviceline_devices[0].numbers && */}
                {/*errors._camvio_serviceline_devices && errors._camvio_serviceline_devices[0].numbers &&*/}
                {/*<div className="invalid-feedback">Please fill out all fields.</div>*/}
                {/*}*/}
            </>
        );
    }

    renderItemNumber = (formProps, numType, serviceLineIndex) => {
        const {values, touched, errors} = formProps;
        const itemId = values._camvio_serviceline_devices[serviceLineIndex].itemId;
        const {deviceProfile, createdOptions} = this.state;
        const {serializedItemsLoader} = this.props;

        if (isEmpty(deviceProfile[serviceLineIndex])) {
            return null;
        }

        const options = [];

        let selectedItem = null;

        // Gather dropdown options from loaded serialised items
        this.state.serializedItems[serviceLineIndex].forEach((item, index) => {

            const itemNumber = item.numbers.find((curNum) => curNum.systemNumType.id === numType.id);

            const option = {label: itemNumber ? itemNumber.number : '', value: index};

            // Only add option if there's data
            if (!isEmpty(itemNumber)) {
                options.push(option);
            }

            // Indicate selected item
            if (
                !isEmpty(values._camvio_serviceline_devices[serviceLineIndex].numbers[numType.id]) &&
                !isEmpty(itemNumber) &&
                itemNumber.number === values._camvio_serviceline_devices[serviceLineIndex].numbers[numType.id]
            ) {
                selectedItem = option;
            }
        });

        // If we have created new options
        if (createdOptions.hasOwnProperty(numType.id)) {

            // Gather created dropdown options
            createdOptions[numType.id].map(createdOption => {
                options.push(createdOption);

                // Indicate selected item
                if (
                    !isEmpty(values._camvio_serviceline_devices[serviceLineIndex].numbers[numType.id]) &&
                    createdOption.value === values._camvio_serviceline_devices[serviceLineIndex].numbers[numType.id]
                ) {
                    selectedItem = createdOption;
                }
            });
        }

        return (
            <>
                <div className="col-lg-4">
                    <label className="small">
                        {numType.description}
                    </label>
                </div>
                <div className="col-lg-8">
                    <div className="barcode">
                        {numType.defaultSource === "number_inventory" || numType.defaultSource === "assign_mapping_note"
                            ?
                            <input
                                id={`${numType.id}_field`}
                                name={`${numType.id}_field`}
                                autoComplete='off'
                                value={values._camvio_serviceline_devices[serviceLineIndex].numbers[`${numType.id}`] || ""}
                                disabled={this.isSubmitting() || numType.defaultSource === "assign_mapping_note"}
                                onChange={(e) => this.onInputChangeHandler(e, serviceLineIndex, formProps)}
                                className={"form-control" +
                                (touched._camvio_serviceline_devices && touched._camvio_serviceline_devices[serviceLineIndex].numbers &&
                                isEmpty(values._camvio_serviceline_devices[serviceLineIndex].numbers[`${numType.id}`])
                                    ? " is-invalid" : "")
                                }
                            />
                            :
                            <Select
                                ref={`${numType.id}_field`}
                                id={`${numType.id}_field`}
                                name={`${numType.id}_field`}
                                value={selectedItem}
                                placeholder=""
                                options={options}
                                isDisabled={this.isSubmitting()}
                                isLoading={serializedItemsLoader}
                                onChange={this.onDropdownChangeHandler(serviceLineIndex, formProps)}
                                onInputChange={this.itemNumSearchChangeHandler(numType, formProps, serviceLineIndex)}
                                styles={groupStyles}
                                className={
                                    touched._camvio_serviceline_devices && touched._camvio_serviceline_devices[serviceLineIndex].numbers &&
                                    isEmpty(values._camvio_serviceline_devices[serviceLineIndex].numbers[`${numType.id}`])
                                        ? "is-invalid"
                                        : "" }
                            />
                        }
                        {/*
                        <button
                            type="button"
                            className="btn btn-link"
                            onClick={() => this.launchBarcodeScanner(
                                `${numType.id}_field`,
                                this.itemNumSearchChangeHandler(numType, formProps, serviceLineIndex),
                                this.onDropdownChangeHandler(serviceLineIndex, formProps),
                            )}
                        >
                            <i className="fas fa-barcode" />
                        </button>
                        */}
                    </div>
                    {touched._camvio_serviceline_devices && touched._camvio_serviceline_devices[serviceLineIndex].numbers &&
                    isEmpty(values._camvio_serviceline_devices[serviceLineIndex].numbers[`${numType.id}`]) &&
                    <div className="invalid-feedback">Required</div>
                    }
                </div>
            </>
        );
    }

    itemNumSearchChangeHandler = (numType, formProps, serviceLineIndex) => (value, option) => {

        if (option) {

            if (option.action === 'input-change') {

                const itemTimeoutName = `${numType.id}_timeout`;
                const itemNumSearchTimeOut = this[itemTimeoutName];

                if (itemNumSearchTimeOut) {
                    clearTimeout(itemNumSearchTimeOut);
                }

                this[itemTimeoutName] = setTimeout(() => {
                    this.itemNumSearch(numType, formProps.values._camvio_serviceline_devices[serviceLineIndex].itemId, value, formProps.setFieldValue, formProps.values, serviceLineIndex);
                }, 300);
            }
        }
        else {
            this.itemNumSearch(numType, formProps.values._camvio_serviceline_devices[serviceLineIndex].itemId, this.state.barcodeValue, formProps.setFieldValue, formProps.values, serviceLineIndex, true);
        }
    }

    itemNumSearch = (numType, itemId, value, setFieldValue, values, serviceLineIndex, autoSelect) => {
        const {deviceProfile} = this.state;
        const {accountInfo, searchSerializedItem, serviceorderInfo} = this.props;

        searchSerializedItem(accountInfo.id, itemId, numType ? numType.id : null, value, null, serviceorderInfo.serviceLines[0].id).then(response => {

            let numbers = values ? values._camvio_serviceline_devices[serviceLineIndex].numbers : {};

            // If only one item found - directly select it
            if (response.serializedItems.length === 1) {

                // Get the numbers of the loaded item
                response.serializedItems[0].numbers.forEach(number => {
                    numbers[number.systemNumType.id] = number.number;
                });
            }

            const serializedItems = this.state.serializedItems;
            serializedItems[serviceLineIndex] = response.serializedItems;
            this.setState({serializedItems: serializedItems});

            // Get any missing fields from deviceProfile
            {deviceProfile[serviceLineIndex].numTypes.map(numType => {

                // if (Object.keys(numbers).includes(numType.id.toString()) === false) {
                if (numType.defaultSource === "number_inventory") {
                    numbers[numType.id] = values ? values._camvio_serviceline_devices[serviceLineIndex].numbers[numType.id] : undefined;
                }
                else {
                    numbers[numType.id] = undefined;
                }
                // }
            })}

            setFieldValue(`_camvio_serviceline_devices[0][${serviceLineIndex}].numbers`, numbers);

            if (autoSelect) {
                this.setState({
                    barcodeFetched: true,
                    barcodeFound: response.serializedItems.length > 0,
                });
            }
        });
    }

    launchBarcodeScanner = (fieldName, searchCallback, selectCallback) => {
        this.props.showModal('BARCODE_SCANNER_MODAL', {
            onCloseCallback: (value) => this.setBarcodeValue(value, searchCallback, selectCallback)
        });

        this.setState({scanningBarcode: fieldName});
    }

    setBarcodeValue = (value, searchCallback, selectCallback) => {
        this.setState({barcodeValue: value}, () => {
            searchCallback();

            let barcodeInterval = setInterval(() => {

                if (this.state.barcodeFetched) {
                    clearInterval(barcodeInterval);

                    if (this.state.barcodeFound) {
                        selectCallback();
                    }
                }
            },50);
        });
    }

    isSubmitting = () => {
        return this.props.formProps.isSubmitting === true;
    }

    render() {

        const {
            serviceorderInfo,
            serviceEquipmentLoader,
            serviceEquipment,
            field,
            formProps,
            scanningBarcode,
            barcodeValue,
        } = this.props;

        if (!isEmpty(serviceorderInfo.serviceLines)) {

            if (this.state.initialToggle) {

                this.setState({
                    initialToggle: false,
                    toggledLines: {[serviceorderInfo.serviceLines[0].id]: true}
                })
            }
        }

        return (
            <div className="form-group">

                <label>{field.name}</label>

                <hr/>

                {isEmpty(serviceEquipment) && serviceEquipmentLoader &&
                <Loader />
                }

                {isEmpty(serviceEquipment) && !serviceEquipmentLoader &&
                <p>No data found</p>
                }

                {!isEmpty(serviceEquipment) && serviceorderInfo.serviceLines && serviceorderInfo.serviceLines.map((serviceLine, index) =>
                <div className="card-toggable form-group">

                    <div className="card-toggable-header">
                        <span
                            className={"h2 h-title" + (this.state.toggledLines[serviceLine.id] ? ' toggled' : '')}
                            onClick={() => this.toggleServiceLine(serviceLine.id)}
                        >
                            {serviceLine.number || serviceorderInfo.serviceAddress}
                        </span>
                    </div>

                    <div className={"card-toggable-body collapse" + (this.state.toggledLines[serviceLine.id] ? ' show' : '')}>

                        {!isEmpty(formProps.values._camvio_serviceline_devices) &&
                        <>
                            {this.renderEquipmentSelect(formProps, index)}
                            {this.renderItemSelect(formProps, index)}
                            {this.renderItemNumbers(formProps, index)}
                        </>
                        }

                    </div>

                </div>
                )}

            </div>
        );
    }
}

const serviceEquipmentLoadingSelector = createLoadingSelector(['SERVICE_EQUIPMENT']);
const serializedItemsSelector = createLoadingSelector(['PROVISIONING_SEARCH_SERIALIZED_ITEM']);

const mapStateToProps = (state) => {

    const serviceEquipmentLoader = serviceEquipmentLoadingSelector(state);
    const serviceEquipment = getCreateServiceRequest(state).serviceEquipment;
    const serializedItemsLoader = serializedItemsSelector(state);

    return {
        serviceEquipmentLoader,
        serviceEquipment,
        serializedItemsLoader,
    }
};

const mapDispatchToProps = {
    getServiceEquipment,
    clearServiceEquipment,
    searchSerializedItem,
    showModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(ServiceLineDevices);
