import {
	SET_SERVICES_AND_EQUIPMENT,
	SET_SERVICES_ICONS
} from '../../actions/accountDetailsActions/servicesAndEquipment.actions';

let initialState = {
	servicesAndEquipment: [],
	servicesIcons: []
};

export const servicesAndEquipment = (state = initialState, action) => {
	switch (action.type) {
		// case SET_SERVICES_AND_EQUIPMENT.SUCCESS:
		// 	return {
		// 		...state,
		// 		account360Badjes: action.payload
		// 	}
		// case SET_SERVICES_AND_EQUIPMENT.EMPTY:
		// 	return {
		// 		...state,
		// 		account360Badjes: {}
		// 	}
		case SET_SERVICES_AND_EQUIPMENT.SUCCESS:
			return {
				...state,
				servicesAndEquipment: action.payload
			};
		case SET_SERVICES_AND_EQUIPMENT.EMPTY:
			return {
				...state,
				servicesAndEquipment: []
			};
		case SET_SERVICES_ICONS.SUCCESS:
			return {
				...state,
				servicesIcons: action.payload
			};
		case SET_SERVICES_ICONS.EMPTY:
			return {
				...state,
				servicesIcons: []
			};

		default:
			return state;
	}
};
