import React, { Component } from 'react';
import Loader from "../../components/Loader";
import TableRow from "./TableRow";
import isEmpty from "../../utils/helpers";

class CapacityResults extends Component {

    componentDidMount() {

        // Load capacities initially
        this.props.fetchAppointmentCapacities(this.props.setFieldValue)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        // Reload capacities on area/date change
        if (prevProps.areaId !== this.props.areaId || prevProps.selectedDates !== this.props.selectedDates) {
            this.props.fetchAppointmentCapacities(this.props.setFieldValue)
        }
    }

    render() {

        const {
            appointmentTimeslots,
            capacityLoader,
            appointmentCapacity,
            selectedDates,
            rowClasses,
            handleChange,
            isSubmitting,
            values,
        } = this.props;

        return (
            <>
                {capacityLoader &&
                <Loader/>
                }

                {!capacityLoader && isEmpty(appointmentCapacity) &&
                <div className="container">
                    <p>No data found</p>
                </div>
                }

                {!capacityLoader && !isEmpty(selectedDates) && (
                    <div className="cmv-container-table">
                        <div className="table-responsive">
                            <table className="table cmv-table cmv-table-solid cmv-table-capacity">

                                <thead>
                                    <tr>
                                        <th>Times</th>
                                        {selectedDates.map(selectedDate => (
                                            <th>{selectedDate}</th>
                                        ))}
                                    </tr>
                                </thead>

                                <tbody>
                                    {appointmentTimeslots.map((time, i) => <>
                                        <TableRow
                                            id={time.id}
                                            fromTime={time.fromTime}
                                            toTime={time.toTime}
                                            timeslotCapacity={time.timeslotCapacity}
                                            rowClass={rowClasses ? rowClasses[i] : i % 2 ? '' : 'tr-dark'}
                                            {...this.props}
                                        />
                                    </>)}
                                </tbody>

                            </table>
                        </div>
                    </div>
                )}
            </>
        );
    }
}

export default CapacityResults;
