import React, {Component, Fragment} from 'react';
import {isEmpty} from '../../../utils/helpers';

class WizardButtons extends Component {
    render() {
        const {
            activeTab,
            values,
            showNewOrderWizardHandler,
            accountStatus,
            showModal,
            saveOrderInProgress,
            validatedAddress,
            prevStep,
            loaderMapLinkLocation,
            errors
        } = this.props;

        return (
            <div className="wizard-footer d-flex justify-content-between">
                {activeTab !== 0 &&
                activeTab !== 2 && (
                    <button onClick={prevStep} className="btn btn-outline-primary btn-prev-tab mr-1" type="button">
                        Previous
                    </button>
                )}
                {activeTab < 2 && (
                    <button
                        className="btn btn-outline-primary btn-next-tab ml-auto"
                        type="submit"
                        disabled={loaderMapLinkLocation || !isEmpty(errors)}
                    >
                        Next
                    </button>
                )}
                {activeTab === 2 && (
                    <button
                        className="btn btn-outline-primary btn-next-tab ml-auto"
                        type="button"
                        onClick={() => showNewOrderWizardHandler(false)}
                    >
                        Finish
                    </button>
                )}
            </div>
        );
    }
}

export default WizardButtons;
