import axios from 'axios';
import { API_ROOT } from '../utils/api-config';
import isEmpty, { actionCreator, createRequestTypes, setCurrentUser } from '../utils/helpers';
import axiosInstance from "../utils/axios-instance";
import { clearViewAs } from "./viewAs.actions";
import _ from 'lodash';
// import history from '../utils/history';

export const AUTH = createRequestTypes('AUTH');
export const AUTH_LOGOUT = createRequestTypes('AUTH_LOGOUT');
export const FORGOTTEN_PASSWORD = createRequestTypes('FORGOTTEN_PASSWORD');
export const RESET_FORGOTEN_PASSWORD_TOKEN = createRequestTypes('RESET_FORGOTEN_PASSWORD_TOKEN');
export const RESET_PASSWORD = createRequestTypes('RESET_PASSWORD');

export const login = (username, password, history) => async (dispatch) => {
	await clearViewAs()(dispatch);

	dispatch(actionCreator.request(AUTH));

	let data = {
		j_username: username,
		j_password: password
	};

	const headers = {
		headers: {
			'Content-Type': 'application/json'
		},
		method: 'GET',
		url: `${API_ROOT}/auth/login`,
		params: data
	};

	await axios(headers).then(async response => {
			setCurrentUser(response.data);

			const summaryResponse = await axiosInstance.get(`/user/summary`);
			const landingUrl = _.get(summaryResponse, 'data.user.systemUserRole.landingUrl');

			await dispatch(actionCreator.success(AUTH, response.data));

			if (!_.isEmpty(landingUrl) && landingUrl !== '/' && landingUrl !== '/home') {
				history.push(landingUrl);
			}
		}
	).catch(error => {
		let message = error && error.response && error.response.data && error.response.data.error
			&& error.response.data.error.message;

		if (isEmpty(message)) {
			message = 'Not enough permissions to access.';
		}

		const mockError = {
			message: message
		};

		dispatch(actionCreator.failure(AUTH, mockError));
	});
};

export const forgottenPassword = (email) => async (dispatch) => {
	dispatch(actionCreator.request(FORGOTTEN_PASSWORD));

	let data = {
		email: email
	};

	const headers = {
		headers: {
			'Content-Type': 'application/json'
		},
		method: 'GET',
		url: `${API_ROOT}/auth/password/forgotten`,
		params: data
	};

	try {
		const response = await axios(headers);
		dispatch(actionCreator.success(FORGOTTEN_PASSWORD, response.data));
		return response.data;
	} catch (err) {
		let mockError = {
			message: err.response.data.message
		};
		dispatch(actionCreator.failure(FORGOTTEN_PASSWORD, mockError));
		return err.response.data;
	}
};

export const resetTokenValidation = (token) => async (dispatch) => {
	dispatch(actionCreator.request(RESET_FORGOTEN_PASSWORD_TOKEN));

	let data = {
		token: token
	};

	const headers = {
		headers: {
			'Content-Type': 'application/json'
		},
		method: 'GET',
		url: `${API_ROOT}/auth/password/resettokenvalidation`,
		params: data
	};

	try {
		const response = await axios(headers);
		dispatch(actionCreator.success(RESET_FORGOTEN_PASSWORD_TOKEN, response.data));
		return response.data;
	} catch (err) {
		let mockError = {
			message: (err.response && err.response.data && err.response.data.message) || ''
		};
		dispatch(actionCreator.failure(RESET_FORGOTEN_PASSWORD_TOKEN, mockError));
		return err.response.data;
	}
};

export const resetPassword = (newPassword, verificationToken) => async (dispatch) => {
	dispatch(actionCreator.request(RESET_PASSWORD));

	let data = {
		newPassword,
		verificationToken
	};

	const headers = {
		headers: {
			'Content-Type': 'application/json'
		},
		method: 'POST',
		url: `${API_ROOT}/auth/password/reset`,
		data: data
	};

	try {
		const response = await axios(headers);
		dispatch(actionCreator.success(RESET_PASSWORD, response.data));
		return response.data;
	} catch (err) {
		let mockError = {
			message: err.response.data.message
		};
		dispatch(actionCreator.failure(RESET_PASSWORD, mockError));
		return err.response.data;
	}
};

export const verifyEmail = (verificationToken) => async (dispatch) => {
	let data = {
		verificationToken: verificationToken
	};

	try {
		const response = await axiosInstance.post('/verification/email/tokenverify', data);
		return response.data;
	} catch (err) {
		return err.response.data;
	}
};

export const checkAuthStatus = (history) => {
	return (dispatch) => {
		const user = localStorage.getItem('currentUser');
		if (!user) {
			localStorage.removeItem('currentUser');
			localStorage.removeItem('location');
			dispatch(actionCreator.success(AUTH_LOGOUT));
			// history.push('/')
		} else {
			dispatch(actionCreator.success(AUTH, JSON.parse(user)));
		}
	};
};

export const logout = (history) => {
	return (dispatch) => {
		localStorage.removeItem('currentUser');
		localStorage.removeItem('location');
		dispatch(actionCreator.success(AUTH_LOGOUT));
	
		if(history){
			history.push('/login')
		}
	};
};
