import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

const Note = ({noteInfo, showNoteModal}) => {
	const initials = noteInfo.createdBy.split(' ');
	const firstInitial = initials[0]  ? initials[0][0].toUpperCase() : '';
	const secondInitial = initials[1] ? initials[1][0].toUpperCase() : '';

	return (
		<li className="client-note" data-cn-type="cn-type-001">
			<span className="client-note-img">{firstInitial}{secondInitial}</span>
			<div className="client-note-body">
				<div className="header">
					<span className="client-note-title">
						<i className="far fa-clock" />&nbsp;{moment(noteInfo.createdDateTime).format('MM/DD/YYYY')}&nbsp; 
						<i className="fas fa-tag" />&nbsp;<strong>{noteInfo.noteCategory && noteInfo.noteCategory.description}</strong>
					</span>
					{noteInfo.noteCategory && noteInfo.noteCategory.editable && <span className="client-note-toolbar">
						<button onClick={() => showNoteModal('editNote', noteInfo.id, noteInfo.noteCategory && noteInfo.noteCategory.id, noteInfo.note)} className="btn btn-xs btn-outline-secondary btn-cn-edit">Edit</button>
					</span>}
					
				</div>
				<p>{noteInfo.note}</p>
				<div className="footer">
					<small className="text-muted">
						<i className="fas fa-user" />&nbsp;{noteInfo.createdBy}
					</small>
				</div>
			</div>
		</li>
	);
};

Note.propTypes = {
	noteInfo: PropTypes.object.isRequired,
	showNoteModal: PropTypes.func
};

export default Note;
