import { combineReducers } from 'redux';

import { accountDetails } from './accountDetails.reducer';
import { accountNotes } from './accountNotes.reducer';
import { newOrder } from './newOrder.reducer';
import { billing } from './billing.reducer';
import { ordersAndQuotes } from './ordersAndQuotes.reducer';
import { account360 } from './account360.reducer';
import { servicesAndEquipment } from './servicesAndEquipment.reducer';
import { updateRequestWizard } from './updateRequestWizard.reducer';
import { notifications } from './notification.reducer';
import { payNow } from './payNow.reducer';
import { security } from './security.reducer'; 
import { accountStatus } from './accountStatus.reducer'
import { editServiceRequest } from './editServiceRequest.reducer';
import { capitalCredit } from "./capitalCredit.reducer";
import { accountInformation } from './accountInformation.reducer';
import { troubleTickets } from './troubleTickets.reducer';
import { accountDocuments } from './documents.reducer';

export const accountDetailsReducer = combineReducers({
	accountDetails,
	accountNotes,
	newOrder,
	billing,
	ordersAndQuotes,
	account360,
	servicesAndEquipment,
	updateRequestWizard,
	notifications,
	payNow,
	security,
	accountStatus,
	editServiceRequest,
	capitalCredit,
	accountInformation,
	troubleTickets,
	accountDocuments,
});
