import React, { Component } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getAccountDetails, createLoadingSelector } from '../../../../../../selectors';
import { getPaymentDetails } from '../../../../../../actions/accountDetailsActions/billing.actions';
import { isEmpty } from '../../../../../../utils/helpers';
import Loader from '../../../../../../components/Loader';

class PaymentDetails extends Component {
	state = {
		paymentDetails: {},
		isLoading: true
	};
	componentDidMount() {
		const { accountInfo, paymentId } = this.props;
		getPaymentDetails(accountInfo.id, paymentId).then((response) => {
			
			if (response.data.success) {
				this.setState({
					paymentDetails: response.data.paymentDetails,
					isLoading: false
				});
			} else {
				this.setState({
					isLoading: false
				});
			}
		});
	}

	render() {
		const { paymentDetails, isLoading } = this.state;

		if (isLoading) {
			return <Loader />;
		}

		if (isEmpty(paymentDetails)) {
			return <p>No data found</p>;
		}

		return (
			<div class="cmv-datarow-container">
				{!isEmpty(paymentDetails.invoices) && (
					<div class="cmv-datarow-wrapper">
						<div class="row cmv-datarow-header">
							<div class="col">Invoice #</div>
							<div class="col">Invoice Date</div>
							<div class="col text-right">Invoice Amount</div>
							<div class="col text-right">Payment Amount</div>
						</div>
						{paymentDetails.invoices.map((invoice, index) => (
							<div className="row cmv-datarow" key={index}>
								<div className="col">{invoice.invoiceNumber}</div>
								<div className="col">{moment(invoice.invoiceDate).format('MM/DD/YYYY')}</div>
								<div className="col cmv-datarow-col-number">
									${invoice.invoiceAmount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
								</div>
								<div className="col cmv-datarow-col-number">
									${invoice.paymentAmount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
								</div>
							</div>
						))}
					</div>
				)}

				<div class="row cmv-datarow-container-footer">
					<div class="col offset-4 text-right">
						<span class="key">Credit</span>
						<br />
						<span class="value">
							${paymentDetails.credits &&
								paymentDetails.credits.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
						</span>
					</div>
					<div class="col text-right">
						<span class="key">Deposits</span>
						<br />
						<span class="value">
							${paymentDetails.deposits &&
								paymentDetails.deposits.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
						</span>
					</div>
				</div>
			</div>
		);
	}
}

// const mapStateToProps = (state) => {
// 	const paymentDetailsData = getAccountDetails(state).billing.paymentDetailsData;

// 	return {
// 		paymentDetailsData
// 	};
// };

// const mapDispatchToProps = {
// 	getPaymentDetails,
// 	emptyPaymentDetails
// };

export default PaymentDetails;
