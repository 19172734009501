import React, { Component } from 'react';
import Customers from './Customers';
import Tasks from './Tasks';
import Orders from './Orders';
import Tickets from './Tickets';
import Appointments from './Appointments';

class Content extends Component {

	render() {

		const { activeTile } = this.props;

		return (
			<div className="tab-content" id="home-tiles-content">

				<div className={`tab-pane tab-pane-${activeTile}` + (activeTile === 'customers' ? ' active' : '')}>
					{activeTile === 'customers' && <Customers {...this.props} />}
				</div>

				<div className={`tab-pane tab-pane-${activeTile}` + (activeTile === 'tasks' ? ' active' : '')}>
					{activeTile === 'tasks' && <Tasks {...this.props} />}
				</div>

				<div className={`tab-pane tab-pane-${activeTile}` + (activeTile === 'orders' ? ' active' : '')}>
					{activeTile === 'orders' && <Orders {...this.props} />}
				</div>

				<div className={`tab-pane tab-pane-${activeTile}` + (activeTile === 'tickets' ? ' active' : '')}>
					{activeTile === 'tickets' && <Tickets {...this.props} /> }
				</div>

				<div className={`tab-pane tab-pane-${activeTile}` + (activeTile === 'appointments' ? ' active' : '')}>
					{activeTile === 'appointments' && <Appointments {...this.props} />}
				</div>

			</div>
		);
	}
}

export default Content;
