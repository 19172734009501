import React from "react";
import PropTypes from 'prop-types';

const NavTabs = ({currentStepName, steps = {}, stack = []}) => {
    if (stack.length === 0) {
        return null;
    }

    const stepFlow = [];
    stack.forEach(st => {
        const stepConfig = steps[st.name];
        stepFlow.push(stepConfig);
    });

    let navStep = steps[stepFlow[stepFlow.length - 1].name];
    while (navStep && navStep.next) {
        const next = navStep.next;

        let nextStepName = null;
        if (typeof next === 'string') {
            nextStepName = next;
        } else if (navStep.nextHint) {
            nextStepName = navStep.nextHint;
        }

        if (nextStepName) {
            navStep = steps[nextStepName];
            if (navStep) {
                stepFlow.push(navStep);
            }
        } else {
            navStep = null;
        }
    }

    return (
        <div className="container">
            <div className="wizard-navigation">
                <ul className="wizard-navigation-nav">
                    {stepFlow.map(step =>
                        <li className={"wizard-navigation-item" + (step.name === currentStepName ? ' active' : '')} key={step.name}>
                            <a className={'wizard-navigation-link'}>
                                {step.title}
                            </a>
                        </li>
                    )}
                </ul>
            </div>
        </div>
    );
}

NavTabs.propTypes = {
    currentStepName: PropTypes.string.isRequired,
    steps: PropTypes.object.isRequired,
    stack: PropTypes.array.isRequired,
};

export default NavTabs;