import React, {Component} from 'react'
import {connect} from "react-redux";
import Appointment from "./Appointment";
import isEmpty from "../../../../../utils/helpers";

class Calendar extends Component {

    state = {
        appointmentsGrouped: undefined,
    }

    componentDidMount() {
        this.groupAppointmentsByAssignee(this.props.appointments);
    }

    componentDidUpdate(prevProps) {

        if (this.props.appointments !== prevProps.appointments) {
            this.groupAppointmentsByAssignee(this.props.appointments);
        }
    }

    groupAppointmentsByAssignee = () => {
        let appointmentsGrouped = {};
        let appointmentTasks;

        this.props.appointments.map(appointment => {
            appointmentTasks = [];

            // Collect tasks form order appointment
            if (appointment.type === 'order') {

                appointment.serviceOrders.map(serviceOrder => {
                    if (!isEmpty(serviceOrder.task)) {
                        appointmentTasks.push(serviceOrder.task);
                    }
                })
            }
            // Collect tasks form ticket appointment
            else if (appointment.type === 'troubleTicket') {

                if (!isEmpty(appointment.tasks)) {
                    appointment.tasks.map(task => {
                        appointmentTasks.push(task);
                    })
                }
            }

            // If there are tasks found
            if (!isEmpty(appointmentTasks)) {

                // Go over all tasks
                appointmentTasks.map(task => {

                    // If there is an assignee
                    if (task.assignee) {

                        // Add an array for this user if not added
                        if (!appointmentsGrouped.hasOwnProperty(task.assignee)) {
                            appointmentsGrouped[task.assignee] = [];
                        }

                        // Add appointment to this user if not added
                        if (appointmentsGrouped[task.assignee]
                            .filter(a => a.appointmentDetails.id === appointment.appointmentDetails.id).length === 0) {
                            appointmentsGrouped[task.assignee].push(appointment);
                        }
                    }
                    // If unassigned
                    else {

                        // Add an array for unassigned if not added
                        if (!appointmentsGrouped.hasOwnProperty('Unassigned')) {
                            appointmentsGrouped['Unassigned'] = [];
                        }

                        // Add appointment to unassigned if not added
                        if (appointmentsGrouped['Unassigned']
                            .filter(a => a.appointmentDetails.id === appointment.appointmentDetails.id).length === 0) {
                            appointmentsGrouped['Unassigned'].push(appointment);
                        }
                    }
                });
            }
            // If there are no tasks found
            else {

                // Add an array for unassigned if not added
                if (!appointmentsGrouped.hasOwnProperty('Unassigned')) {
                    appointmentsGrouped['Unassigned'] = [];
                }

                // Add appointment to unassigned
                appointmentsGrouped['Unassigned'].push(appointment);
            }
        });

        // Store appointments grouped by assignee
        this.setState({appointmentsGrouped: appointmentsGrouped});
    }

    render() {

        const {
            appointmentsGrouped,
        } = this.state;

        return (
            <div className="cmv-container-appointment-calendar-search-results">

                {isEmpty(appointmentsGrouped)
                ?
                <p>No data found</p>
                :
                <div className="appointment-calendar">

                    <div className="appointment-calendar-headings">
                        <div className="container">
                            <div className="row">
                                <div className="col"><span className="appointment-calendar-heading"></span></div>
                                <div className="col"><span className="appointment-calendar-heading">8am</span></div>
                                <div className="col"><span className="appointment-calendar-heading">9am</span></div>
                                <div className="col"><span className="appointment-calendar-heading">10am</span></div>
                                <div className="col"><span className="appointment-calendar-heading">11am</span></div>
                                <div className="col"><span className="appointment-calendar-heading">12pm</span></div>
                                <div className="col"><span className="appointment-calendar-heading">1pm</span></div>
                                <div className="col"><span className="appointment-calendar-heading">2pm</span></div>
                                <div className="col"><span className="appointment-calendar-heading">3pm</span></div>
                                <div className="col"><span className="appointment-calendar-heading">4pm</span></div>
                                <div className="col"><span className="appointment-calendar-heading">5pm</span></div>
                                <div className="col"><span className="appointment-calendar-heading">6pm</span></div>
                            </div>
                        </div>
                    </div>

                    {appointmentsGrouped && appointmentsGrouped.hasOwnProperty('Unassigned') &&
                    <div className="appointment-calendar-row">
                        <div className="container">
                            <div className="row">

                                <div className="col">
                                    <span className="appointment-calendar-heading appointment-calendar-heading-bold">Unassigned</span>
                                </div>

                                <div className="col">
                                    {appointmentsGrouped['Unassigned'].map(appointment =>
                                    <Appointment
                                        key={(appointment.id + "-Unassigned")}
                                        appointmentKey={(appointment.id + "-Unassigned")}
                                        appointment={appointment}
                                        {...this.props}
                                    />
                                    )}
                                </div>

                            </div>
                        </div>
                    </div>
                    }

                    {appointmentsGrouped && Object.keys(appointmentsGrouped).filter(assignee => assignee !== "Unassigned").map(assignee =>
                    <div className="appointment-calendar-row">
                        <div className="container">
                            <div className="row">

                                <div className="col">
                                    <span className="appointment-calendar-heading" title={assignee}>{assignee}</span>
                                </div>

                                <div className="col">
                                    {appointmentsGrouped[assignee].map(appointment =>
                                    <Appointment
                                        key={(appointment.id + "-" + assignee)}
                                        appointmentKey={(appointment.id + "-" + assignee)}
                                        appointment={appointment}
                                        {...this.props}
                                    />
                                    )}
                                </div>

                            </div>
                        </div>
                    </div>
                    )}

                </div>
                }

            </div>
        )
    }
}

const mapStateToProps = (state) => {

    return {
    };
};

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(Calendar);
