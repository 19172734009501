import React from "react";
import TextInput from "../../../../../components/UI/TextInput";
import FormSelect from "../../../../../components/UI/FormSelect";
import LocationSearchInput from "./LocationSearchInput";
import {groupStyles} from "../../../../../utils/SelectStyles";
import Select from "react-select";
import isEmpty from "../../../../../utils/helpers";


class ManualAddressInput extends React.Component {
    state = {
        address: {
            fullAddress: '',
            address1: '',
            address2: '',
            address3: '',
            city: '',
            stateId: this.props.defaultState && this.props.defaultState.id || '',
            state: this.props.defaultState && this.props.defaultState.name || '',
            zip: '',
            zip4: '',
            countryId: '',
            country: this.props.defaultState && this.props.defaultState.countryCode || '',
            latitude: '',
            longitude: '',
            addressId: '',
            isManualEntry: true
        }
    }

    onManualAddressUpdate = (fieldName, value) => {
            let address = this.state.address;
            if(fieldName == "state"){
                let state = this.props.countryStatesData.find((x) => x.id === value)
                    ? this.props.countryStatesData.find((x) => x.id === value)
                    : "";
                address["stateId"] = state.id;
                address["state"] = state.abbreviation;
                address["country"] = state.countryCode;
            }else if(fieldName == "fullAddress.address1"){
                address["address1"] = value;
            }else if(fieldName == "fullAddress.city"){
                address["city"] = value;
            }else if(fieldName == "fullAddress.zip"){
                address["zip"] = value;
            }else{
                address[fieldName] = value;
            }

        let combinedAddress = this.state.address.address1 + ", " + this.state.address.city
            + ", " + this.state.address.state + ", " + this.state.address.zip
            + ", " + this.state.address.country;
            address["fullAddress"] = combinedAddress;

            this.setState({
                address: address
            });

        this.props.onAddressHandleChange(this.state.address, this.state.unitNumber, this.props.setFieldValue);

    }

    render() {
        return (
            <div className="form-group mb-5">
                <div className="input-group input-group-sm">
                    <TextInput
                        fieldName="address1"
                        type="text"
                        divClass="col-md-8"
                        placeholder={'Address Line 1'}
                        name={'fullAddress.address1'}
                        id={'address1'}
                        value={this.state.address.address1}
                        onChange={(e) => this.onManualAddressUpdate(e.target.name, e.target.value)}
                        onBlur={this.props.handleBlur}
                        errors={this.props.errors}
                        touched={this.props.touched}
                    />
                    <TextInput
                        fieldName="address2"
                        type="text"
                        divClass="col-md-2"
                        placeholder={'Unit #'}
                        name={'address2'}
                        id={'address2'}
                        value={this.state.address.unitNumber}
                        onChange={(e) => this.onManualAddressUpdate(e.target.name, e.target.value)}
                        onBlur={this.props.handleBlur}
                        errors={this.props.errors}
                        touched={this.props.touched}
                    />
                    <TextInput
                        fieldName="city"
                        type="text"
                        divClass="col-md-3"
                        placeholder={'City'}
                        name={'fullAddress.city'}
                        id={'city'}
                        value={this.state.address.city}
                        onChange={(e) => this.onManualAddressUpdate(e.target.name, e.target.value)}
                        onBlur={this.props.handleBlur}
                        errors={this.props.errors}
                        touched={this.props.touched}
                    />
                    <div className="col-md-2">
                        <FormSelect
                            fieldName="state"
                            options={this.props.stateOptions}
                            value={this.state.address.stateId}
                            onChange={(fieldName, value) => {
                                this.onManualAddressUpdate(fieldName, value);
                            }}
                            onBlur={this.props.handleBlur}
                            placeholder={'State'}
                        />
                    </div>
                    <TextInput
                        fieldName="zip"
                        type="text"
                        divClass="col-md-3"
                        placeholder={'Zip/Postal'}
                        name={'fullAddress.zip'}
                        id={'zip'}
                        value={this.state.address.zip}
                        onChange={(e) => this.onManualAddressUpdate(e.target.name, e.target.value)}
                        onBlur={this.props.handleBlur}
                        errors={this.props.errors}
                        touched={this.props.touched}
                    />
                    <TextInput
                        fieldName="country"
                        type="text"
                        divClass="col-md-2"
                        placeholder={'Country'}
                        name={'country'}
                        id={'country'}
                        value={this.state.address.country}
                       // onChange={(e) => this.onManualAddressUpdate(e,)}
                        disabled={true}
                        errors={this.props.errors}
                        touched={this.props.touched}
                    />
                </div>
            </div>
        );
    }
}

export default ManualAddressInput;

