import moment from "moment";

export const defaultTargetDateTimeFormat = 'MM/DD/YYYY @ hh:mma';
export const defaultDateFormat = "MM/DD/YYYY";
export const apiTargetDateTimeFormat = 'MM-DD-YYYY hh:mm a';

export const parseDateTimeWithFormat = (date, format) => {
        return format? moment(date).format(format) : moment(date).format(defaultTargetDateTimeFormat);
}

export const parseDateWithFormat = (date, format) => {
        return date? format? moment(date).format(format) : moment(date).format(defaultDateFormat) : null;
}