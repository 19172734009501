import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { hideModal } from '../../actions/modal.actions';
import { getModalData } from '../../selectors/index';

class GenericModal extends Component {

	cancel = () => {
		this.props.hideModal();

		this.props.modalProps.onCancel && this.props.modalProps.onCancel();
	};

	ok = () => {
		this.props.hideModal();

		this.props.modalProps.onOk && this.props.modalProps.onOk();
	};

	render() {
		const { modalProps } = this.props;
		return (
			<Fragment>
				<div className="modal" style={{ display: 'block' }} tabIndex="-1" role="dialog">
					<div className={'modal-dialog' + (modalProps.size? (' modal-' + modalProps.size) : '')} role="document">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">{modalProps.title}</h5>
								<button type="button" className="close" onClick={this.cancel}>
									<span aria-hidden="true">&times;</span>
								</button>
							</div>
							<div className="modal-body">
								{modalProps.text && <p className="lead">{modalProps.text}</p>}
								{modalProps.component && modalProps.component()}
							</div>
							<div className="modal-footer">
								<button onClick={this.cancel} className="btn">
									{modalProps.cancelText? modalProps.cancelText : 'Cancel'}
								</button>
								<button onClick={this.ok} className="btn btn-primary">
									{modalProps.okText? modalProps.okText : 'Ok'}
								</button>
							</div>
						</div>
					</div>
				</div>
				<div className="modal-backdrop show" tabIndex="1" />
			</Fragment>
		);
	}
}

GenericModal.propTypes = {
	title: PropTypes.string.isRequired,
	text: PropTypes.string,
	component: PropTypes.object,
	okText: PropTypes.string,
	cancelText: PropTypes.string,
	onOk: PropTypes.func,
	onCancel: PropTypes.func,
	size: PropTypes.string,
};

const mapStateToProps = (state) => {
	const modalProps = getModalData(state).modalProps;
	return {
		modalProps
	};
};

const mapDispatchToProps = {
	hideModal
};

export default connect(mapStateToProps, mapDispatchToProps)(GenericModal);
