import React, {Component} from 'react';
import moment from 'moment';
import DatePicker from "react-datepicker";
import {applyOnEnum} from "../../../../../utils/helpers";

class SummaryContainer extends Component {
    state = {
        isApplyOnVisible: false,
		startDate: this.props.accountInfo.billCycle? this.convertDay(this.props.accountInfo.billCycle): this.convertDay((new Date()).getDate()),
    }
    handleApplyOnDropdown = () => {
        this.setState((prevState) => ({
            isApplyOnVisible: !prevState.isApplyOnVisible
        }));
    }

	 convertDay(startDayBillCycle){
		let localdate = new Date();
		let currentMonth = localdate.getMonth();
		let currentYear = localdate.getFullYear();
		let currentDay = localdate.getDate();
		if( startDayBillCycle <= currentDay ) {
			return new Date(currentYear,currentMonth,startDayBillCycle);
		}else{
			return new Date(currentYear,currentMonth-1,startDayBillCycle);
		}
	}
	handleChangeApplyOn = (value) => {
		if (value) {
			this.props.values.applyOn = value;
		}
	}

	setDate = () => {
		this.openDatepicker();
	};
	openDatepicker = () => this._calendar.setOpen(true);

	handlChangeStartDateBillCycle = (value) => {
		if (value instanceof Date) {
			value = moment(value).format('YYYY-MM-DD');
		}

		if(value) {
			this.props.setFieldValue('applyDate', value);
		}
	};

    render() {

        const {
            isApplyOnVisible,
            startDate,
        } = this.state;

        const {
            values,
            isSummaryFullWith,
            handleFullWithSummary,
            isSummaryCollapsed,
            handleSummaryCollapsed,
            plansDetails,
            tab
        } = this.props;

        return (
            <div
                className={
                    isSummaryFullWith === true ? (
                        'col-lg-3 col-xl-4 panel-summary col-full-width'
                    ) : (
                        'col-lg-3 col-xl-4 panel-summary'
                    )
                }
            >
                <div className="services-summary">

                    <div className="services-summary-header">
                        Summary
                        <span className="ml-auto">
							<a className="btn-summary btn-summary-toggle" onClick={handleFullWithSummary}>
								<i className="fas fa-arrows-alt-h"/>&nbsp;View
							</a>
                            &nbsp;
                            <a className="btn-summary btn-summary-collapse" onClick={handleSummaryCollapsed}>
								<i className="fas fa-angle-double-up"/>&nbsp;Collapse
							</a>
						</span>
                    </div>

					{(tab === 'Products' || tab === 'Services') &&
                    <div className="services-summary-applyon">
                        <h4>Apply On:</h4>
                        <div className={"dropdown" + (isApplyOnVisible ? ' show' : '')}>
                            <button
                                type="button"
                                onClick={this.handleApplyOnDropdown}
                                className="btn btn-outline-secondary btn-sm dropdown-toggle"
                                role="button"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                            >
                                {applyOnEnum.valueOf()[values.applyOn]}
                            </button>
                            <div className={"dropdown-menu dropdown-menu-right" + (isApplyOnVisible ? ' show' : '')}
                                 aria-labelledby="dropdownMenuLink"
                            >

                                <button type="button" className="dropdown-item"
                                        onClick={() => {
                                            this.handleApplyOnDropdown();
											this.handleChangeApplyOn(Object.keys(applyOnEnum)[0]);
										}}>
									{applyOnEnum.ORDER_COMPLETION}
								</button>

								<button type="button" className="dropdown-item"
										onClick={() => {
											this.handleApplyOnDropdown();
											this.handleChangeApplyOn(Object.keys(applyOnEnum)[1]);
                                        }}>
									{applyOnEnum.START_OF_BILLCYCLE}
								</button>

								<button type="button" className="dropdown-item"
										onClick={() => {
											this.handleApplyOnDropdown();
											this.handleChangeApplyOn(Object.keys(applyOnEnum)[2]);

										}}>
									{applyOnEnum.NEXT_BILLCYCLE}
                                </button>
                                <button type="button" className="dropdown-item"
                                        onClick={() => {
                                            this.handleApplyOnDropdown();
											this.handlChangeStartDateBillCycle(startDate)
											this.handleChangeApplyOn(Object.keys(applyOnEnum)[3]);
                                        }}>
									{applyOnEnum.APPLY_ON_DATE}
                                </button>
                            </div>
                        </div>
                    </div>
					}

					{values.applyOn && values.applyOn === Object.keys(applyOnEnum)[3] && (tab === 'Products' || tab === 'Services') ?
						<div className="form-group d-flex">
							<div className='dropdown dropdown-cn-expiration ml-1 show'>
								<h6 className="dropdown-header">Choose a Date: </h6>
								<div className="form-group col">
									<div
										className="input-group input-group-sm date"
										id="datetimepicker-cn-exp"
										data-target-input="nearest"
									>
										<DatePicker
											className="form-control datetimepicker-input"
											selected={moment(this.props.values.applyDate, 'YYYY-MM-DD').toDate()}
											minDate={startDate}
											onChange={(date) => {
												this.handlChangeStartDateBillCycle(date);
												this.setDate() ;}}
											placeholderText="MM/DD/YYYY"
											name="expirationDate"
											popperPlacement="bottom-end"
											ref={(c) => this._calendar = c}
										/>
									</div>
								</div>
								{/*        <div className="dropdown-item">
                                <button type="button"
                                        className="btn btn-outline-secondary btn-block btn-cn-exp-date-clear"
                                        onClick={this.handleClearExpirationDate}>Save
                                </button>
                            </div> */}
							</div>
						</div>

						: ''
					}

                    <div className="services-summary-body">
                        <div className="plan-wrapper" data-plan-type="wireless">
                            <h4>{plansDetails.description}</h4>

                            <div className="serviceline-wrapper">
                                <h5 className="clearfix">
									<span className="badge badge-line-type">
										{plansDetails.planRelation}
									</span>
                                    {/* <span className="float-right">{serviceline.number}</span> */}
                                </h5>

                                <div className="plan-section" data-opt-pmnt-type="pmnt-recurring">
                                    <h5>Recurring</h5>
                                    {values.features
                                        .filter((x) => x.featureType !== 'ACTIVATION' && x.description)
                                        .map((feature, inx, arr) => (
                                            <div className="package-wrapper" key={inx}>
                                                <div
                                                    className={
                                                        isSummaryCollapsed === false ? (
                                                            'package-option just-added'
                                                        ) : (
                                                            'package-option d-none'
                                                        )
                                                    }
                                                >
													<span className="po-title">
													{feature.featurePackageDescription ? (

                                                        `${feature.featurePackageDescription.replace(/\s<br><(\w+)\b[^<>]*>[\s\S]*?<\/\1>/g, '')}: ${feature.description}`
                                                    ) : (
                                                        `${feature.description}`
                                                    )}
													</span>
                                                    <span className="po-price">
                                                        {feature.featureVariabledQuantity
                                                            ? feature.recurringMonthlyPrice &&
                                                                ("$" + (feature.featureVariabledQuantity * Number(feature.recurringMonthlyPrice)).toFixed(2).replace(/\d(?=(\d{3}) +\.)/g, '$&,') + "/mo")
                                                            : feature.recurringMonthlyPrice &&
                                                                ("$" + Number(feature.recurringMonthlyPrice).toFixed(2).replace(/\d(?=(\d{3}) +\.)/g, '$&,') + "/mo")
                                                        }
													</span>
                                                </div>
                                            </div>
                                        ))}

                                    <p className="services-price-subtotal">
                                        Subtotal:&nbsp;<span className="service-price">
											${values.features.reduce((acc, currentValue, index) => {
                                                if (currentValue.featureType !== 'ACTIVATION') {
                                                    if (currentValue.featureVariabledQuantity) {
                                                        if (currentValue.recurringMonthlyPrice) {
                                                            acc += currentValue.featureVariabledQuantity * currentValue.recurringMonthlyPrice;
                                                        }
                                                    }
                                                    else if (currentValue.recurringMonthlyPrice) {
                                                        acc += Number(currentValue.recurringMonthlyPrice);
                                                    }
                                                }
                                                return acc;
                                            }, 0).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}/mo
										</span>
                                    </p>
                                </div>

                                <div className="plan-section" data-opt-pmnt-type="pmnt-onetime">
                                    <h5>Onetime</h5>
                                    {values.features
                                        .filter((x) => x.featureType === 'ACTIVATION' && x.description)
                                        .map((feature, inx, arr) => (
                                            <div className="package-wrapper" key={inx}>
                                                <div
                                                    className={
                                                        isSummaryCollapsed === false ? (
                                                            'package-option just-added'
                                                        ) : (
                                                            'package-option d-none'
                                                        )
                                                    }
                                                >
													<span className="po-title">
														{feature.featurePackageNote &&
                                                        `${feature.featurePackageNote}:`}{' '}
                                                        {feature.description}
													</span>
                                                    <span className="po-price">
														${feature.oneTimePrice
                                                        .toFixed(2)
                                                        .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
													</span>
                                                </div>
                                            </div>
                                        ))}

                                    <p className="services-price-subtotal">
                                        Subtotal:&nbsp;<span className="service-price">
											${values.features
                                        .reduce((acc, currentValue, index) => {
                                            if (currentValue.featureType === 'ACTIVATION' && currentValue.oneTimePrice) {
                                                acc += currentValue.oneTimePrice;
                                            }
                                            return acc;
                                        }, 0)
                                        .toFixed(2)
                                        .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
										</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="services-summary-footer">
                        <p className="services-price-total">
                            Recurring:{' '}
                            <span className="service-price">
								${values.features.reduce((acc, currentValue, index) => {
                                    if (currentValue.featureType !== 'ACTIVATION') {
                                        if (currentValue.featureVariabledQuantity) {
                                            if (currentValue.recurringMonthlyPrice) {
                                                acc += currentValue.featureVariabledQuantity * currentValue.recurringMonthlyPrice;
                                            }
                                        }
                                        else if (currentValue.recurringMonthlyPrice) {
                                            acc += Number(currentValue.recurringMonthlyPrice);
                                        }
                                    }
                                    return acc;
                                }, 0).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}/mo
							</span>
                        </p>
                        <p className="services-price-total">
                            OneTime:{' '}
                            <span className="service-price">
								${values.features
                                .reduce((acc, currentValue, index) => {
                                    if (currentValue.featureType === 'ACTIVATION' && currentValue.oneTimePrice) {
                                        acc += currentValue.oneTimePrice;
                                    }

                                    return acc;
                                }, 0)
                                .toFixed(2)
                                .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
							</span>
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}

export default SummaryContainer;
