import React from 'react';
import PropTypes from 'prop-types';

const Tile = ({ tile, activeTile, handleTileChange, cardTitle, cardText, cardIcon, cardNumber, loading }) => {

	return (
		<button
			data-card={tile}
			className={`card card-${tile}` + (activeTile === tile ? ' active' : '')}
			onClick={() => handleTileChange(tile)}
		>

			{loading &&
				<div className={`card-indicator card-indicator-${tile} spinner-grow spinner-grow-sm`} role="status">
					<span className="sr-only">Loading...</span>
				</div>
			}

			<div className={`card-header card-header-${tile}`}>
				<i className={cardIcon} />
			</div>

			<div className="card-body">
				<h5 className="card-title">{cardTitle}</h5>
				<p className="card-text">{cardText}</p>
			</div>

		</button>
	);
};

Tile.propTypes = {
	tile: PropTypes.string,
	activeTile: PropTypes.string,
	handleTileChange: PropTypes.func,
	cardTitle: PropTypes.string,
	cardText: PropTypes.string,
	cardIcon: PropTypes.string,
	cardNumber: PropTypes.string
};

export default Tile;
