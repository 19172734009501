import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {clearAutoPayJobs, getAutoPayJobStatusList} from "../../actions/autoPayJobs-actions";
import isEmpty from "../../utils/helpers";
import {createLoadingSelector, getAdmin, getUser} from "../../selectors";
import { showModal } from '../../actions/modal.actions';
import CamvioTable from "../../components/UI/CamvioTable/CamvioTable";
import { getAutoPayJobsUi } from "../../actions/uiController.actions";
import { downloadAutoPayJobs } from "../../actions/uiController.actions";
import Button from "../../components/Common/Buttons/Button";
import Select from "react-select";
import {groupStyles} from "../../utils/SelectStyles";
import {debounce} from "lodash";
import DatePicker from "react-datepicker";
import moment from "moment";
import {getUserPermission} from "../../selectors";

class AutoPayJobsDisplay extends Component {

    state = {
        loadingSearch: true,
        isLoading: false,
        isDownloading: false,
        searchTerm: '',
        oldSearchTerm: '',
        showDownloadDropdown: false,
        search: {
            searchFrom: '', 	// yyyy-MM-dd hh:mm:ss
            searchTo: '', 		// yyyy-MM-dd hh:mm:ss
        },
        tableRequest: {
            filterList: [],
            pagination: {
                limit: 20,
                offset: 0
            },
            sorting: {
                columnName: "apj.scheduled_datetime",
                sortParam: "desc"
            }
        },
        resultsNumber: 20,
        autoPayJobsList: [],
        autoPayHeadersList: [],
        sortColumn: '',
        sortDirection: '',
        noMoreResults: false,
        showAdditionalSearchOptions: false,
        autoPayJobStatusList: [],
        autoPayJobStatusFilter: [{name: "apj.status", value: "All", label: "All Statuses"}],
    };

    componentDidMount() {
        // Load AutoPay Jobs
        this.reloadAutoPayJobs();
        this.props.getAutoPayJobStatusList().then(response => {
            if (response && response.statusList) {
                this.setState({autoPayJobStatusList: response.statusList})
            }
        });

    }

    componentWillUnmount() {
        this.props.clearAutoPayJobs();
    }

    setAutoPayJobStatusSearch = (value) => {
        this.setState({
            autoPayJobStatusFilter: [{
                name: "apj.status",
                value: value.value,
                label: value.label
            }]
        })
        this.debounceHandleInputChange(value);
    };

    searchAutoPayJobs = () => {
        let tableRequest = this.state.tableRequest;
        let pagination = tableRequest.pagination;
        pagination.offset = 0;

        this.setState({
            tableRequest: tableRequest,
            autoPayJobsList: [],
            autoPayHeadersList: [],
            loadingSearch: true
        });
        this.reloadAutoPayJobs();
    }

    toggleFilter = () => {
        this.setState({showAdditionalSearchOptions: !this.state.showAdditionalSearchOptions});
    }
    reloadAutoPayJobs = (clearList) => {
        if (clearList) {
            this.setState({
                autoPayJobsList: [],
                autoPayHeadersList: []
            })
        }

        this.setState({isLoading: true, loadingSearch: true}, () => {
            this.props.getAutoPayJobsUi(this.state.tableRequest).then(response => {
                if(response.rows && !isEmpty(response.rows)){
                    response.rows.map(autoPayJobs => {
                        this.state.autoPayJobsList.push(autoPayJobs);
                    })
                    //this.checkResultsLength();
                }
                if (response.headers && !isEmpty(response.headers)) {
                    response.headers.map(autoPayHeaders => {
                        this.state.autoPayHeadersList.push(autoPayHeaders);
                    })
                }
                this.setState({isLoading: false, loadingSearch: false});
            });
        });
    }

    toggleDownloadDropdown = () => {
        this.setState({showDownloadDropdown: !this.state.showDownloadDropdown});
    }

    columnSorting = (field, order) => {
        if(this.state.sortDirection != order ||
            this.state.sortColumn != field) {
            let tableRequest = this.state.tableRequest;
            let sorting = tableRequest.sorting;
            let columnName = ""
            if (field === 'Scheduled') {
                columnName = 'apj.scheduled_datetime';
            } else if (field === 'Notes') {
                columnName = 'apj.notes';
            } else if (field === 'Status') {
                columnName = 'apj.status';
            }

            sorting.columnName = columnName;
            sorting.sortParam = order;
            this.setState({
                tableRequest: tableRequest,
                loadingSearch: true,
                autoPayJobsList: [],
                autoPayHeadersList: [],
                sortColumn: field,
                sortDirection: order
            });
            this.reloadAutoPayJobs();
        }
    }

    handleDateChange = (fieldName,date) => {
        let name = fieldName;
        let value = null;
        if (date === null) {
            value = null;
        }
        switch(fieldName) {
            case 'searchFrom':
                this.state.search.searchFrom = date ? moment(date).format("YYYY-MM-DD") : null;
                if (date !== null) {
                    value = moment(date).format("YYYY-MM-DD");
                }
                break;
            case 'searchTo':
                this.state.search.searchTo = date ? moment(date).format("YYYY-MM-DD") : null;
                if (date !== null) {
                    value = moment(date).add(1, 'days').format("YYYY-MM-DD");
                }
                break;
        }
        this.handleInputChange(name,value);
    };

    debounceHandleInputChange = event => {
        let name = event.name || event.target.name;
        let value = event.value || event.target.value;
        this.handleInputChange(name,value);
    }

    handleInputChange = debounce((fieldName, value) => {
        let tableRequest = this.state.tableRequest;
        let filterList = tableRequest.filterList;
        let containsFilter = true;

        if(filterList.length === 0 && value.replace(/\s/g, '').length !== 0){
            if(value !== "All") {
                filterList.push({
                    columnName: fieldName,
                    value: value
                })
            }
        }else{
            for(let index = 0; index < filterList.length; index++) {
                if (filterList[index].columnName === fieldName) {
                    containsFilter = true;
                    if ((value) && (value.length === 0 || value === "All")) {
                        filterList.splice(index, 1);
                    }else if (value) {
                        filterList[index].value = value;
                    } else {
                        // If value is null, remove it from the filter list.
                        filterList.splice(index, 1);
                    }
                    break;
                }else{
                    containsFilter = false;
                }
            }
        }
        if(!containsFilter){
            filterList.push({
                columnName: fieldName,
                value: value
            })
        }
        tableRequest.filterList = filterList;

        this.setState({tableRequest: tableRequest});

    });

    getJobStatusList = () => {
        let statusList = [{
            name: "apj.status",
            value: "All",
            label: "All"
        }]

        this.state.autoPayJobStatusList && this.state.autoPayJobStatusList.map((status) => {
            statusList.push({
                name: "apj.status",
                value: status,
                label: status
            });
        });
        return statusList;
    }

    downloadTable = (value) => {
        let tableRequest = this.state.tableRequest;
        tableRequest.pagination = {};

        this.setState({
            tableRequest: tableRequest,
            isDownloading: true
        });

        this.props.downloadAutoPayJobs(value, this.state.tableRequest).then((response) => {
            if (response && response.status === 201 && response.data) {
                let name = "";
                let link = document.createElement('a');
                let fileUrl = URL.createObjectURL(new Blob([response.data]));
                link.setAttribute('href', fileUrl);
                if(response.headers["content-disposition"] != undefined) {
                    name = response.headers["content-disposition"].split("=")[1];
                }else{
                    let today = new Date()
                    name = "autopay-jobs-" +  today.toISOString().split('T')[0] + "." + value;
                }
                link.setAttribute('download',name);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                this.setState({
                    isDownloading: false,
                    showDownloadDropdown: false
                });
            }
        });
    };

    render() {

        const {
            autoPayJobsList,
            autoPayHeadersList,
            isLoading,
            isDownloading,
            showAdditionalSearchOptions,
            showDownloadDropdown,
            sortColumn,
            sortDirection,
        } = this.state;

        const {
            autoPayJobsLoader,
            canManageAutoPayJobs,
        } = this.props;

        return (
            <Fragment>
                <div className="tab-pane camvio-table-search" style={{borderRadius: '0.5rem'}}>
                    <div className="cmv-container-dashboard-search">
                        <div className="cmv-container-dashboard-filter">
                            <div className="container">
                                <div className="form-row">
                                    <div className="col-md-12">
                                        <div className="input-group">
                                            <div className="input-group input-group-datepicker">
                                                <DatePicker
                                                    type="date"
                                                    className={"form-control"}
                                                    // className="form-control"
                                                    placeholderText="From MM/DD/YYYY"
                                                    id="searchFrom"
                                                    name="searchFrom"
                                                    isClearable={!isEmpty(this.state.search.searchFrom)}
                                                    selected={this.state.search.searchFrom && moment(this.state.search.searchFrom).toDate()}
                                                    // minDate={moment().toDate()}
                                                    onChange={(date) => this.handleDateChange('searchFrom', date)}
                                                    style={groupStyles}
                                                    onKeyPress={(event) => {
                                                        if (event.key === 'Enter') {
                                                            this.searchAutoPayJobs()
                                                        }
                                                    }}
                                                />
                                                <DatePicker
                                                    type="date"
                                                    // className="form-control"
                                                    className={"form-control"}
                                                    placeholderText="To MM/DD/YYYY"
                                                    id="searchTo"
                                                    name="searchTo"
                                                    isClearable={!isEmpty(this.state.search.searchTo)}
                                                    selected={this.state.search.searchTo && moment(this.state.search.searchTo).toDate()}
                                                    onChange={(date) => this.handleDateChange('searchTo', date)}
                                                    style={groupStyles}
                                                    onKeyPress={(event) => {
                                                        if (event.key === 'Enter') {
                                                            this.searchAutoPayJobs()
                                                        }
                                                    }}
                                                />
                                                <div className="input-group-append">
                                                    <Button
                                                        type="button"
                                                        className="btn btn-primary btn-show-cam-search-results"
                                                        disabled={this.state.loadingSearch || isDownloading}
                                                        onClick={() => {
                                                            this.searchAutoPayJobs();
                                                        }}
                                                    >
                                                        <i className="fas fa-search"/>
                                                        <span>&nbsp;Search</span>
                                                    </Button>
                                                </div>

                                                <div className="input-group-append">
                                                    <div className="btn-group">
                                                        <Button
                                                            type="button"
                                                            onClick={this.toggleFilter}
                                                            className="btn btn-outline-secondary"
                                                        >
                                                            {showAdditionalSearchOptions ?
                                                                <i className="fas fa-solid fa-ban"/>
                                                                :
                                                                <i className="fas fa-solid fa-filter"/>
                                                            }
                                                            <span>&nbsp;Filters</span>
                                                        </Button>

                                                        <Button
                                                            type="button"
                                                            className="btn btn-outline-secondary btn-new-account-wizard"
                                                            onClick={() => this.props.showModal('ADD_AUTOPAY_JOBS_MODAL', {
                                                                type: 'create',
                                                                reloadFunction: this.reloadAutoPayJobs
                                                            })}
                                                            disabled={!canManageAutoPayJobs || autoPayJobsLoader || isDownloading}
                                                        >
                                                            <i className="fas fa-plus"/>
                                                            <span>&nbsp;New AutoPay Job</span>
                                                        </Button>
                                                        <div className="dropdown">
                                                            <Button
                                                                type="button"
                                                                className="btn btn-outline-secondary dropdown-toggle"
                                                                type="button"
                                                                onClick={this.toggleDownloadDropdown}
                                                                disabled={this.state.loadingSearch || isDownloading}
                                                            >
                                                                <i className="fas fa-download"/>
                                                                <span
                                                                    className="camvio-table-search-download">&nbsp;Download</span>
                                                            </Button>
                                                            <div
                                                                className={"dropdown-menu dropdown-menu-right" + (showDownloadDropdown && !isDownloading ? " show" : "")}>
                                                                <button
                                                                    type="button"
                                                                    value={"XLS"}
                                                                    className="dropdown-item"
                                                                    onClick={(e) => {
                                                                        this.downloadTable("XLS")
                                                                    }}
                                                                >
                                                                    As XLS
                                                                </button>
                                                                <button
                                                                    type="button"
                                                                    className="dropdown-item"
                                                                    onClick={(e) => {
                                                                        this.downloadTable("CSV")
                                                                    }}
                                                                >
                                                                    As CSV
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {showAdditionalSearchOptions &&
                        <div className="cmv-container-dashboard-filter">
                            <div className="container">
                                <div className="camvioTable-plan-extra-filters">
                                    <div className="search">
                                        <Select
                                            className="extra-filter-select"
                                            id="status"
                                            name="apj.status"
                                            onChange={this.setAutoPayJobStatusSearch}
                                            styles={groupStyles}
                                            options={this.getJobStatusList()}
                                            value={this.state.systemUserRoleFilter}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        }
                    </div>
                    {!isLoading && isEmpty(autoPayJobsList) &&
                    <div><p>No data found</p></div>
                    }

                    {!isEmpty(autoPayJobsList) &&
                    <CamvioTable
                        headers={autoPayHeadersList}
                        rows={autoPayJobsList}
                        loader={isLoading}
                        reloadFunction={this.reloadAutoPayJobs}
                        sortFunction={this.columnSorting}
                        canEdit={true}
                        canDelete={true}
                        canManageTokens={false}
                        showModal={this.props.showModal}
                        sortColumn={sortColumn}
                        sortDirection={sortDirection}
                    />
                    }
                </div>
            </Fragment>
        );
    }
}

const loaderJobs = createLoadingSelector(['LOAD_AUTOPAY_JOBS']);

const mapStateToProps = (state) => {

    const autoPayJobsLoader = loaderJobs(state),
        userPermission = getUser(state).userPermission,
        canManageAutoPayJobs = getUserPermission(state, 'ADMN', 'ADMN_MANAGE_AUTOPAY_JOBS');

    return {
        autoPayJobsLoader,
        userPermission,
        canManageAutoPayJobs,
    };
};

const mapDispatchToProps = {
    clearAutoPayJobs,
    showModal,
    getAutoPayJobsUi,
    downloadAutoPayJobs,
    getAutoPayJobStatusList,
};

export default connect(mapStateToProps, mapDispatchToProps)(AutoPayJobsDisplay);
