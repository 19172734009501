import React, { Component, Fragment } from 'react';
import { isEmpty } from '../../../utils/helpers';
import {isEqual} from "lodash";

class WizardButtons extends Component {

	changeApplyOnValue(value) {
		if( value === 'ORDER COMPLETION' ){
			this.props.values.applyOn = 'ORDER_COMPLETION'
		} else if ( value === 'CURRENT BILL CYCLE' ) {
			this.props.values.applyOn = 'CURRENT_BILL_CYCLE'
		} else if ( value === 'NEXT BILL CYCLE' ) {
			this.props.values.applyOn = 'NEXT_BILL_CYCLE'
		} else if ( value === 'SELECT DATE') {
			this.props.values.applyOn = 'SELECT_DATE'
		}
	}

	render() {
		const {
			activeTab,
			values,
			showNewOrderWizardHandler,
			accountStatus,
			showModal,
			saveOrderInProgress,
            validatedAddress,
			prevStep,
			loaderMapLinkLocation,
			addressType,
			errors
		} = this.props;

		return (
			<div className="wizard-footer d-flex justify-content-between">
				{activeTab !== 0 &&
				activeTab !== 4 && (
					<button onClick={prevStep} className="btn btn-outline-primary btn-prev-tab mr-1" type="button">
						Previous
					</button>
				)}
				{activeTab < 3 &&
				activeTab !== 1 && (
					<button
						className="btn btn-outline-primary btn-next-tab ml-auto"
						type="submit"
						disabled={(isEmpty(validatedAddress) || isEmpty(validatedAddress.allowedServiceOrderType) || loaderMapLinkLocation)
						&& !isEqual(addressType, "manualAddress")}
					>
						Next
					</button>
				)}
				{activeTab === 3 && validatedAddress && validatedAddress.allowedServiceOrderType === 'NEW_SERVICE' ? (
					<Fragment>
						<button
							className="btn btn-outline-primary btn-next-tab ml-auto"
							type="button"
							disabled={!isEmpty(errors)}
							onClick={() => {
					this.changeApplyOnValue(this.props.values.applyOn)
					showModal('QUOTE_MODAL', {
								...values,
								validatedAddress: validatedAddress
							})}}
						>
							Save as Quote
						</button>
						{accountStatus !== 'Prospect' && (
							<button
								className="btn btn-primary btn-next-tab-alt ml-1"
								type="button"
								disabled={!isEmpty(errors)}
								onClick={() => {
					this.changeApplyOnValue(this.props.values.applyOn)
					showModal('ORDER_MODAL', {
									...values,
									validatedAddress: validatedAddress
								})}}
							>
								Save as Order
							</button>
						)}
					</Fragment>
				) : (
					activeTab === 3 && (
						<Fragment>
							<button
								className="btn btn-outline-primary btn-next-tab ml-auto"
								type="submit"
								disabled={saveOrderInProgress}
							>
								Save Signup
							</button>
						</Fragment>
					)
				)}
				{activeTab === 4 && (
					<button
						className="btn btn-outline-primary btn-next-tab ml-auto"
						type="button"
						onClick={() => showNewOrderWizardHandler(false)}
					>
						Finish
					</button>
				)}
			</div>
		);
	}
}

export default WizardButtons;
