import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser';
import VariableQuantityInput from "./VariableQuantityInput";
import VariablePriceInput from "./VariablePriceInput";
import {get} from "lodash";

class OptionalFeature extends Component {

	state = {
		isDescriptionToggled: false,
	};

	isPriceRendered = () => {
		const { accountInitialStatus, originalFeature } = this.props;

		if (accountInitialStatus === 'NEW_SIGNUP') {
			if (originalFeature.hideSignupPrice) {
				return false;
			}
		} else if (accountInitialStatus === 'NEW_SURVEY') {
			if (originalFeature.hideSurveyPrice) {
				return false;
			}
		}
		return true;
	};

	toggleDescription = (e) => {
		e.stopPropagation();

		this.setState((prevState) => ({
			isDescriptionToggled: !prevState.isDescriptionToggled,
		}));
	};

	render() {

		const {
			isDescriptionToggled,
		} = this.state;

		const {
			planIndex,
			lineIndex,
			featureIndex,
			lineInfo,
			originalFeature,
			lineFeature,
			handleOptionalFeature,
			handleVariableFeatureQuantity,
			handleVariableFeaturePrice,
			errors,
		} = this.props;

		return (
			<div
				className={
					"package-option package-option-selectable" +
					(featureIndex !== -1 ? ' package-option-selected' : '') +
					(originalFeature.variableQuantity ? ' package-option-variable' : '')
				}
				onClick={() => handleOptionalFeature(originalFeature.id, lineIndex, originalFeature)}
			>

				<span className="po-title">{originalFeature.description}</span>

				<span className="package-toolbar">

					{originalFeature.variableQuantity &&
						<span className="po-quantity">
							<VariableQuantityInput
								feature={lineFeature}
								planIndex={planIndex}
								lineIndex={lineIndex}
								featureIndex={featureIndex}
								value={featureIndex !== -1
									? lineInfo.features[featureIndex].featureVariabledQuantity
									: 1
								}
								disabled={!(featureIndex !== -1)}
								handleVariableFeatureQuantity={handleVariableFeatureQuantity}
							/>
						</span>
					}

					{originalFeature.variablePrice
					?
						<span className="po-price">
							<VariablePriceInput
								feature={lineFeature}
								planIndex={planIndex}
								lineIndex={lineIndex}
								featureIndex={featureIndex}
								value={originalFeature.featureType === 'ACTIVATION'
									? (featureIndex !== -1
										? (lineInfo.features[featureIndex].featureVariabledPrice || lineInfo.features[featureIndex].price)
										: originalFeature.price)
									: (featureIndex !== -1
										? (lineInfo.features[featureIndex].featureVariabledPrice || lineInfo.features[featureIndex].price)
										: originalFeature.price)
								}
								disabled={featureIndex !== -1 ? !(lineInfo.features.findIndex((x) => x.id === originalFeature.id) !== -1) : true}
								handleVariableFeaturePrice={handleVariableFeaturePrice}
								errors={errors}
							/>
						</span>
					:
						<span className="po-price">
							{this.isPriceRendered() === true ? originalFeature.featureType === 'ACTIVATION' ? (
								`$${originalFeature.price.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`
							) : (
								`$${originalFeature.price.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}` + '/mo'
							) : null}
						</span>
					}

					<button onClick={this.toggleDescription} type="button" className="btn btn-xs btn-desc-toggler">
						<i className="fas fa-info-circle" />
					</button>

					{get(errors, `plans[0].servicelines[0].features.${featureIndex}.featureVariabledPrice`) && (
						<small className="form-text text-danger text-left">
							{get(errors, `plans[0].servicelines[0].features.${featureIndex}.featureVariabledPrice`)}
						</small>
					)}

				</span>

				<div className={isDescriptionToggled ? 'po-description collapse show' : 'po-description collapse'}>
					{ReactHtmlParser(originalFeature.note)}
				</div>

			</div>
		);
	}
}

export default OptionalFeature;
