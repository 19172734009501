import {
	SET_USER_TASK_ATRRIBUTES,
	SET_SERVICE_ORDER_TASKS,
	SET_TASK_DETAILS_ACCOUNT_INFO,
	SET_TASK_DETAILS_SERVICEORDER_INFO
} from '../actions/taskDetails.actions';

const initialState = {
	taskAtrributes: {},
	serviceOrderTasks: [],
	accountInfo: {},
	serviceorderInfo: {}
};

export const taskDetailsReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_USER_TASK_ATRRIBUTES.SUCCESS:
			return {
				...state,
				taskAtrributes: action.payload
			};
		case SET_USER_TASK_ATRRIBUTES.EMPTY:
			return {
				...state,
				taskAtrributes: {}
			};
		case SET_SERVICE_ORDER_TASKS.SUCCESS:
			return {
				...state,
				serviceOrderTasks: action.payload
			};
		case SET_SERVICE_ORDER_TASKS.EMPTY:
			return {
				...state,
				serviceOrderTasks: []
			};
		case SET_TASK_DETAILS_ACCOUNT_INFO.SUCCESS:
			return {
				...state,
				accountInfo: action.payload
			};
		case SET_TASK_DETAILS_ACCOUNT_INFO.EMPTY:
			return {
				...state,
				accountInfo: {}
			};
		case SET_TASK_DETAILS_SERVICEORDER_INFO.SUCCESS:
			return {
				...state,
				serviceorderInfo: action.payload
			}
		case SET_TASK_DETAILS_SERVICEORDER_INFO.EMPTY:
			return {
				...state,
				serviceorderInfo: {}
			}
		default:
			return state;
	}
};
