import React, { Component } from 'react';
import NavTabs from './NavTabs';
import { Account360, ServiceAndEquipment, Billing, OrdersAndQuotes, TroubleTickets } from './Tabs';
import {connect} from "react-redux";
import {createLoadingSelector, getAccountDetails, getGlobalFlags, getUser} from "../../../../selectors";
import {
	emptyAccountAmounts,
	emptyUnpaidInvoices,
	getAccountAmounts,
	getUnpaidInvoices, paymentByCash, paymentByCheck, paymentByCredit
} from "../../../../actions/accountDetailsActions/payNow.actions";
import {
	emptyAccountCreditBalance,
	getAccountCreditBalance
} from "../../../../actions/accountDetailsActions/billing.actions";
import {hideModal} from "../../../../actions/modal.actions";

export class LeftPanel extends Component {

	state = {
		activeTab: 'account360',
		hideTabs: false,
	}

	componentDidMount() {
		if (this.props.activeLeftPanelTab !== '') {
			this.setState({
				activeTab: this.props.activeLeftPanelTab
			});
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.paymentSubmittedFlag !== this.props.paymentSubmittedFlag
			|| prevProps.servicesUpdatedFlag !== this.props.servicesUpdatedFlag) {

			this.setState({hideTabs: true}, () => this.setState({hideTabs: false}));
		}

		// Open specific tab
		if (prevProps.activeLeftPanelTab !== this.props.activeLeftPanelTab && this.props.activeLeftPanelTab !== '') {
			this.setState({
				activeTab: this.props.activeLeftPanelTab
			})
		}
	}

	changeTabHandler = (tab) => {
		this.setState({
			activeTab: tab
		});
	}

	renderTabContent = (activeTab, userPermission) => {

		if (this.state.hideTabs) {
			return null;
		}

		let tabContent;

		switch (activeTab) {
			case 'account360':
				tabContent = <Account360 {...this.props} changeTabHandler={this.changeTabHandler} />;
				break;
			case 'serviceAndEquipment':
				tabContent = <ServiceAndEquipment {...this.props} changeTabHandler={this.changeTabHandler} />;
				break;
			case 'billing':
				tabContent = <Billing {...this.props} changeTabHandler={this.changeTabHandler} />;
				break;
			case 'ordersAndQuotes':
				tabContent = <OrdersAndQuotes {...this.props} changeTabHandler={this.changeTabHandler} />;
				break;
			case 'troubleTickets':
				tabContent = <TroubleTickets {...this.props} changeTabHandler={this.changeTabHandler} />;
				break;
			default:
				tabContent = <Billing {...this.props} changeTabHandler={this.changeTabHandler} />;
		}

		return tabContent;
	}

	render() {
		const { activeTab } = this.state;
		const { handleExpandPanel, expandedPanel, userPermission } = this.props;

		return (
			<div id="leftPanel" className={expandedPanel === '' ? 'col-xl-8' : expandedPanel === 'leftPanel' ? 'col' : 'd-none'}>
				<div className="panel-left">

					<NavTabs
						activeTab={activeTab}
						changeTabHandler={this.changeTabHandler}
						handleExpandPanel={handleExpandPanel}
					/>

					<div className={"tab-content" + (activeTab === "account360" ? " tab-content-full-size" : "")}>
						{this.renderTabContent(activeTab, userPermission)}
					</div>

				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {

	const paymentSubmittedFlag = getGlobalFlags(state).paymentSubmittedFlag;
	const servicesUpdatedFlag = getGlobalFlags(state).servicesUpdatedFlag;

	return {
		paymentSubmittedFlag,
		servicesUpdatedFlag,
	};
};

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(LeftPanel);
