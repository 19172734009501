import {
	SET_UNPAID_INVOICES,
	SET_ACCOUNT_AMOUNTS,
	GET_PAYMENT_FORM,
	GET_PAYMENT_STATUS,
} from '../../actions/accountDetailsActions/payNow.actions';

let initialState = {
	unpaidInvoices: [],
	amounts: {},
	formData: {},
	paymentStatus:{},
	paymentStatusCount: 0,
};

export const payNow = (state = initialState, action) => {
	switch (action.type) {
		case SET_UNPAID_INVOICES.SUCCESS:
			return {
				...state,
				unpaidInvoices: action.payload
			}
		case SET_UNPAID_INVOICES.EMPTY: {
			return {
				...state,
				unpaidInvoices: []
			}
		}
		case SET_ACCOUNT_AMOUNTS.SUCCESS:
			return {
				...state,
				amounts: action.payload
			}
		case SET_ACCOUNT_AMOUNTS.EMPTY:
			return {
				...state,
				amounts: {}
			}
		case GET_PAYMENT_FORM.SUCCESS:
			return {
				...state,
				formData: action.payload
			}
		case GET_PAYMENT_FORM.EMPTY:
			return {
				...state,
				formData: {}
			}
		case GET_PAYMENT_STATUS.SUCCESS:
			return {
				...state,
				paymentStatus: action.payload,
				paymentStatusCount: state.paymentStatusCount + 1
			}
		case GET_PAYMENT_STATUS.EMPTY:
			return {
				...state,
				paymentStatus: {},
				paymentStatusCount: 0
			}
		default:
			return state;
	}
};
