import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {hideModal} from '../../../actions/modal.actions';
import {getAccountDetails, getModalData} from '../../../selectors';
import Loader from "../../../components/Loader";
import {getPrimaryContacts} from "../../../actions/admin.actions";
import isEmpty from "../../../utils/helpers";

class SendRegistrationLinkModal extends Component {

    state = {
        isSubmitting: false,
        sendResult: false,
        sendMessage: false,
        isLoaded: false,
        contactEmails: [],
    };

    componentDidMount() {
        this.getCurrentEmail();
    }

    getCurrentEmail = async () => {
        const {accountId} = this.props;
        if (accountId) {
            await this.props.getPrimaryContacts(accountId).then(r =>{
                this.setState({
                    isLoaded: true,
                    contactEmails: r.data.contacts[0].emails
                })
            });
        }

    }

    handleSendLink = (accountId, destinationEmail) => {

        this.setState({
            isSubmitting: true,
            sendResult: false,
            sendMessage: false
        });

        this.props.sendRegistrationLink({accountId : accountId, email: destinationEmail}).then((response) => {

            if (response.success) {
                this.setState({
                    isSubmitting: false,
                    sendResult: 'success',
                    sendMessage: response.message
                });
            }
            else {
                this.setState({
                    isSubmitting: false,
                    sendResult: 'error',
                    sendMessage: response.error.message
                });
            }
        });
    }

    closeModal = () => {
        this.props.hideModal();
    };

    render() {

        const {isSubmitting, sendResult, sendMessage, isLoaded, contactEmails} = this.state;

        const {accountId} = this.props;

        let destinationEmail = false;

        if (!isEmpty(contactEmails)) {
            contactEmails.forEach((email) => {
                if (email.type === "BUSINESS" || destinationEmail === false) {
                    destinationEmail = email.email;
                }
            });
        }

        return (
            <Fragment>
                <div className="modal" style={{display: 'block'}} tabIndex="-1" role="dialog">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">

                                <h5 className="modal-title">Customer Portal Access</h5>

                                <button onClick={this.closeModal} type="button" className="close">
                                    <span aria-hidden="true">&times;</span>
                                </button>

                            </div>
                            {!isLoaded
                                ?
                                <div className="modal-body">
                                    <Loader/>
                                </div>
                                :
                                <div className="modal-body">

                                    {destinationEmail ? <>
                                        <div className="form-group">
                                            <label>Send registration link to:</label>
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">@</span>
                                                </div>
                                                <input type="text" className="form-control" value={destinationEmail} disabled/>
                                            </div>
                                        </div>
                                    </> : <>
                                        No email address found.
                                    </>}

                                    {isSubmitting && <Loader/>}

                                    {sendResult &&
                                    <div className="form-section-customer-payment-management-message">
                                        {sendResult === 'success'
                                            ? <i className="far fa-check-circle text-success"/>
                                            : <i className="far fa-times-circle text-danger"/>
                                        }
                                        <p>{sendMessage}</p>
                                    </div>
                                    }

                                </div>
                            }
                            <div className="modal-footer">

                                <button onClick={this.closeModal} className="btn" type="button">
                                    Close
                                </button>

                                {isLoaded &&
                                <button className="btn btn-primary"
                                        disabled={isSubmitting || !destinationEmail || sendResult === 'success'}
                                        onClick={() => this.handleSendLink(accountId, destinationEmail)}
                                >
                                    Send Link
                                </button>
                                }

                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-backdrop show" tabIndex="1"/>
            </Fragment>
        );
    }
}

const mapDispatchToProps = {
    hideModal,
    getPrimaryContacts
};

const mapStateToProps = (state) => {
    const accountId = getAccountDetails(state).accountDetails.accountInfo.id;
    const sendRegistrationLink = getModalData(state).modalProps.sendRegistrationLink;

    return {
        accountId,
        sendRegistrationLink,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SendRegistrationLinkModal);
