import { toastr } from 'react-redux-toastr';


export const showToastrError500 = (globalError) => {
    const toastrOptions = {
        timeOut: 500000, // by setting to 0 it will prevent the auto close
        position: 'top-center',
        showCloseButton: true, // false by default
        attention: true,
        removeOnHover: false,
        onAttentionClick: (id) => {}
        // onToastrClick: () => console.log('Toastr was clicked'),
        // onCloseButtonClick: () => console.log('Close button was clicked'),
        // component: <ToastErrorMessage history={this.props.history} />
    };
    
    toastr.error(
        'Unexpected Server Error',
        globalError.errorMessage + ` [${globalError.errorCode}]`,
        toastrOptions
    );
}
