import React, {Component, Fragment} from 'react';
import {connect} from "react-redux";
import PropTypes from 'prop-types';
import Card from './Card';
import { generateAddressNew } from '../../utils/generateAddresses';
import { statusColor } from '../../utils/ui';
import { isEmpty } from '../../utils/helpers';
import moment from 'moment';
import {createLoadingSelector, getAccountDetails, getGlobalFlags} from "../../selectors";
import {emptyAccountBalance, emptyDueDetails, getAccountBalance, getDueDetails} from "../../actions/accountDetailsActions/billing.actions";
import {getAllContacts, emptyAllContacts} from "../../actions/accountDetailsActions/security.actions";

const disabledBtnStyle = {
	opacity: '.5',
	cursor: 'not-allowed',
}

class AccountInfoBox extends Component {

	state = {
		isContactsExpanded: false,
	}

	componentDidMount() {
		this.init();
	}

	init = () => {
		const { accountInfo, hideCardsContainer, hideCardsTaskDetails } = this.props;

		if (accountInfo && !hideCardsContainer && !hideCardsTaskDetails) {
			this.props.getDueDetails(accountInfo.id);
			this.props.getAccountBalance(accountInfo.id);
		}

		if (!isEmpty(accountInfo)) {
			this.props.getAllContacts(accountInfo.id);
		}
	}

	toggleContacts = () => {
		this.setState({
			isContactsExpanded: !this.state.isContactsExpanded,
		})
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		const wasTaskDetailsVisible = !prevProps.hideCardsContainer && !prevProps.hideCardsTaskDetails;
		const taskDetailsVisibleNow = !this.props.hideCardsContainer && !this.props.hideCardsTaskDetails;
		const currentAccountId = this.props.accountInfo && this.props.accountInfo.id;

		if (!wasTaskDetailsVisible && taskDetailsVisibleNow && currentAccountId) {
			const prevAccountId = prevProps.accountInfo && prevProps.accountInfo.id;

			if (currentAccountId !== prevAccountId || (!prevProps.dueDate || !prevProps.accountBalance)) {
				this.props.getDueDetails(this.props.accountInfo.id);
				this.props.getAccountBalance(this.props.accountInfo.id);
			}
		}

		if (prevProps.paymentSubmittedFlag !== this.props.paymentSubmittedFlag) {
			this.init();
		}
	}

	componentWillUnmount() {
		this.props.emptyDueDetails();
		this.props.emptyDueDetails();
		this.props.emptyAccountBalance();
		this.props.emptyAllContacts();
	}

	render() {

		const {
			isContactsExpanded,
		} = this.state;

		const {
			accountInfo,
			hideCardsContainer,
			hideCardsTaskDetails,
			showStatusDropdown,
			accountPromises,
			handleStatusDropDown,
			isStatusDropDownOpen,
			showModal,
			accountAllowedStatusTransitions,
			accountAllowedStatusTransitionsLoading,
			width,
			dueDate,
			dueDateLoading,
			accountBalance,
			accountBalanceLoading,
			accountContacts,
			accountContactsLoading,
		} = this.props;

		if (isEmpty(accountInfo)) {
			return null;
		}

		const address =
			accountInfo.primaryContact &&
			accountInfo.primaryContact.contactAddresses &&
			accountInfo.primaryContact.contactAddresses.find((x) => x.type === 'BILLING');

		let addressInfo = '';

		const maxWidthScreen = 767; //px

		if (address) {
			addressInfo = generateAddressNew(address);
		}

		return (
			<div className="cmv-container-client cmv-container-client-modern">

				<div className="cmv-client-data-basic col-sm-5 col-md-4 col-lg-5 col-xl-4">
					<div className="container">
						<div className="cmv-client">

							<div className="cmv-client-header">

								{/*<div className="cmv-client-avatar">*/}
								{/*	<i className="fas fa-user"/>*/}
								{/*</div>*/}

								<div className="cmv-client-details">

									<h2 className="cmv-client-name">
										{accountInfo.primaryContact && accountInfo.primaryContact.company ? (
											`${accountInfo.primaryContact.company}`
										) : (
											`${accountInfo.primaryContact.firstName} ${accountInfo.primaryContact.lastName}`
										)}
									</h2>

									{addressInfo && (
										<p className="cmv-client-address">
											{/*<i className="fas fa-file-invoice-dollar"/>*/}
											{addressInfo}
										</p>
									)}

									<button
										className={'cmv-client-status cmv-client-status-' + statusColor(accountInfo.tierLevel) + (hideCardsContainer || !showStatusDropdown ? '' : ' dropdown')}
										onClick={() => handleStatusDropDown(false)}
										onBlur={() => handleStatusDropDown(true)}
									>

										{accountAllowedStatusTransitionsLoading &&
										<i className="fas fa-fw fa-spin fa-spinner" style={{marginLeft: '5px'}}/>
										}

										{!accountAllowedStatusTransitionsLoading &&
										<span className="status-text">{accountInfo.status} {accountInfo.wfStatus ? '(' + accountInfo.wfStatus + ')' : null}</span>
										}

										<div className={`status-bar status-bar-${accountInfo.tierLevel * 20}`}></div>

										{!accountAllowedStatusTransitionsLoading &&
										<div
											className={isStatusDropDownOpen && !hideCardsContainer && !isEmpty(accountAllowedStatusTransitions) ? ('dropdown-menu dropdown-menu-right show') : ('dropdown-menu dropdown-menu')}
											style={width <= maxWidthScreen ? {
												position: 'absolute',
												willChange: 'transform',
												top: '0px',
												left: '0px',
												transform: 'translate3d(-14px, 18px, 0px)',
												right: 'auto'
											} : {}}>
											{accountAllowedStatusTransitions && accountAllowedStatusTransitions
												.sort((a, b) => {
													let compRes = a.order - b.order;

													if (compRes === 0) {
														compRes = a.description.localeCompare(b.description);
													}

													return compRes;
												})
												.map(statusTransition =>
													<a onClick={() => !statusTransition.disabled && showModal(statusTransition)}
													   key={statusTransition.name}
													   disabled={statusTransition.disabled}
													   style={statusTransition.disabled && disabledBtnStyle}
													   className="dropdown-item">{statusTransition.description}</a>
												)}
										</div>
										}
									</button>

								</div>

							</div>

						</div>
					</div>
				</div>

				<div className="cmv-client-data-extended col-sm-7 col-md-8 col-lg-7 col-xl-8">

					{/*{addressInfo && (*/}
					{/*<div className="container">*/}
					{/*	<h6>Billing Address</h6>*/}
					{/*	<p className="cmv-client-address">{addressInfo}</p>*/}
					{/*</div>*/}
					{/*)}*/}

					{!hideCardsContainer && (
						<>
							<div className="container">

								{/*<h6>Account</h6>*/}

								<Card
									header="Account #"
									body={accountInfo.number ? accountInfo.number : ''}
									icon="fas fa-fw fa-hashtag"
								/>

								<Card
									header="Since"
									body={accountInfo.createdDateTime
										? moment(accountInfo.createdDateTime).format('MM/DD/YYYY')
										: ''
									}
									icon="fas fa-fw fa-clock"
								/>

								<Card
									header="Account type"
									body={accountInfo.accountType ? accountInfo.accountType.description : ''}
									icon="fas fa-fw fa-list-alt"
								/>

							</div>
							<div className="container">

								{/*<h6>Billing</h6>*/}

								<Card
									header="Bill Cycle"
									body={accountInfo.billCycle ? accountInfo.billCycle : ''}
									icon="fas fa-fw fa-sync-alt"
								/>

								{!hideCardsTaskDetails && accountBalanceLoading && (
									<p>
										Balance:
										<i className="fas fa-fw fa-spin fa-spinner"/>
									</p>
								)}

								{!hideCardsTaskDetails && !accountBalanceLoading && (
									<Card
										header="Balance"
										body={accountBalance
											? ('$' + accountBalance.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'))
											: ''
										}
										icon="fas fa-fw fa-money-bill"
										redText={new Date() > new Date(dueDate)}
									/>
								)}

								{!hideCardsTaskDetails && dueDateLoading && (
									<p>
										Due Date:
										<i className="fas fa-fw fa-spin fa-spinner"/>
									</p>
								)}

								{!hideCardsTaskDetails && !dueDateLoading && (
									<Card
										header="Due Date"
										body={dueDate? moment(dueDate).format('MM/DD/YYYY') : ''}
										icon="fas fa-fw fa-calendar-alt"
										redText={new Date() > new Date(dueDate)}
										showPromiseIcon={
											accountPromises &&
											accountPromises.some((x) => moment() < moment(x.promisedUntil))
										}
									/>
								)}

							</div>

						</>
					)}

				</div>

				{hideCardsTaskDetails && <>

					<div className={"cmv-client-data-contact cmv-client-data-contact-primary" + (isContactsExpanded ? " visible" : "")}>

							<div className="cmv-client-data-basic col-sm-4 col-md-4 col-lg-3 col-xl-4">
								<div className="container">
									<h5>
										<strong>{accountInfo.primaryContact.firstName} {accountInfo.primaryContact.lastName}</strong>
										{" (Primary)"}
									</h5>
								</div>
							</div>

							<div className="cmv-client-data-extended col-sm-8 col-md-8 col-lg-9 col-xl-8">
								<div className="container">
									{!isEmpty(accountInfo.primaryContact.contactNumbers)
									? accountInfo.primaryContact.contactNumbers.map(number => <p>{number.numberType}: {number.number}</p>)
									: <p>No numbers found.</p>
									}
								</div>
							</div>

					</div>

					{accountContacts.filter(contact => contact.id !== accountInfo.primaryContact.id && contact.authorized).map(contact =>
					<div className={"cmv-client-data-contact cmv-client-data-contact-authorized" + (isContactsExpanded ? " visible" : "")}>

						<div className="cmv-client-data-basic col-sm-4 col-md-4 col-lg-3 col-xl-4">
							<div className="container">
								<h5>
									<strong>{contact.firstName} {contact.lastName}</strong>
									{contact.authorized ? " (Authorized)" : ""}
								</h5>
							</div>
						</div>

						<div className="cmv-client-data-extended col-sm-8 col-md-8 col-lg-9 col-xl-8">
							<div className="container">
								{!isEmpty(contact.numbers)
								? contact.numbers.map(number => <p>{number.numberType}: {number.number}</p>)
								: <p>No numbers found.</p>
								}
							</div>
						</div>

					</div>
					)}

					{accountContacts.filter(contact => contact.id !== accountInfo.primaryContact.id && contact.authorized).length > 0 &&
						<div
							className={"cmv-client-data-toggle" + (isContactsExpanded ? " cmv-client-data-toggle-expanded" : "")}
							onClick={this.toggleContacts}
						>
							Show All Contacts
							<i className="fas fa-angle-double-down"/>
						</div>
					}

				</> }

			</div>
		);
	}
}

AccountInfoBox.propTypes = {
	accountInfo: PropTypes.object.isRequired,
	hideCardsContainer: PropTypes.bool,
	showStatusDropdown: PropTypes.bool,
	handleStatusDropDown: PropTypes.func,
	isStatusDropDownOpen: PropTypes.bool,
	showModal: PropTypes.func,
	accountStatus: PropTypes.object,
	accountAllowedStatusTransitions: PropTypes.array,
	accountAllowedStatusTransitionsLoading: PropTypes.bool,
};

const accountBalanceLoadingSelector = createLoadingSelector([ 'GET_ACCOUNT_BALANCE' ]);
const dueDateLoadingSelector = createLoadingSelector([ 'GET_DUE_DATE' ]);
const accountContactsLoadingSelector = createLoadingSelector(['SET_ALL_CONTACTS'])

const mapStateToProps = (state) => {

	const dueDate = getAccountDetails(state).billing.dueDate,
		dueDateLoading = dueDateLoadingSelector(state),
		accountBalance = getAccountDetails(state).billing.balance,
		accountBalanceLoading = accountBalanceLoadingSelector(state),
		accountContacts = getAccountDetails(state).security.allContacts,
		accountContactsLoading = accountContactsLoadingSelector(state),
		paymentSubmittedFlag = getGlobalFlags(state).paymentSubmittedFlag;

	return {
		dueDate,
		dueDateLoading,
		accountBalance,
		accountBalanceLoading,
		accountContacts,
		accountContactsLoading,
		paymentSubmittedFlag,
	};
};

const mapDispatchToProps = {
	getAccountBalance,
	emptyAccountBalance,
	getDueDetails,
	emptyDueDetails,
	getAllContacts,
	emptyAllContacts,
};
export default connect(mapStateToProps, mapDispatchToProps)(AccountInfoBox);
