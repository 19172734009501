import React, {Component} from 'react';
import {connect} from 'react-redux';
import AppointmentMapping from "./AppointmentMapping";
import {getUser} from "../../selectors";

class AppointmentManagement extends Component {

	componentDidMount() {
		document.title = 'Appointment Areas - camvio.cloud';
		this.props.changeNavBarType('default', 'Appointment Areas');
	}

	render() {

		return (
			<div className="cmv-container cmv-container-dashboard cmv-container-client-selected">
				<AppointmentMapping />
			</div>
		);
	}
}

const mapStateToProps = (state) => {

};

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(AppointmentManagement);
