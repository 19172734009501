import axiosInstance from '../../utils/axios-instance';
import { actionCreator, createRequestTypes } from '../../utils/helpers';
import {HIDE_MODAL} from '../modal.actions';

export const SET_ACCOUNT_NOTES = createRequestTypes('SET_ACCOUNT_NOTES');
export const SET_NOTE_CATEGORIES = createRequestTypes('SET_NOTE_CATEGORIES');
export const CREATE_NOTE = createRequestTypes('CREATE_NOTE');
export const EDIT_NOTE = createRequestTypes('EDIT_NOTE');

export const EMPTY_ACCOUNT_NOTES = 'EMPTY_ACCOUNT_NOTES';

export const getAccountNotes = (accountId, filters) => async (dispatch) => {
	dispatch(actionCreator.request(SET_ACCOUNT_NOTES));
	try {
		const response = await axiosInstance.get(`/account/${accountId}/notes`, {params: filters});
		dispatch(actionCreator.success(SET_ACCOUNT_NOTES, response.data));
	} catch (err) {
		dispatch(actionCreator.failure(SET_ACCOUNT_NOTES));
	}
};

export const createNote = (accountId, note, filters) => async (dispatch) => {
	try {
		const response = await axiosInstance.post(`/account/${accountId}/note/`, note);
		dispatch(actionCreator.success(CREATE_NOTE, response.data));
		dispatch(getAccountNotes(accountId, filters));
		dispatch({type: HIDE_MODAL})
	} catch (err) {
		dispatch(actionCreator.failure(CREATE_NOTE));
	}
}

export const editNote = (accountId, note, filters) => async (dispatch) => {
	try {
		const response = await axiosInstance.put(`/account/${accountId}/note/`, note);
		dispatch(actionCreator.success(EDIT_NOTE, response.data));
		dispatch(getAccountNotes(accountId, filters));
		dispatch({type: HIDE_MODAL})
	} catch (err) {
		dispatch(actionCreator.failure(EDIT_NOTE));
	}
}


export const getNoteCategories = (filters) => async (dispatch) => {
	dispatch(actionCreator.request(SET_NOTE_CATEGORIES));
	try {
		const response = await axiosInstance.get(`/domain/notecategories`, {params: filters});
		dispatch(actionCreator.success(SET_NOTE_CATEGORIES, response.data));
		return response.data
	} catch (err) {
		dispatch(actionCreator.failure(SET_NOTE_CATEGORIES));
	}
};


export const emptyAccountNotes = () => {
	return (dispatch) => {
		dispatch({type: EMPTY_ACCOUNT_NOTES});
	};
};