import React, { Component } from 'react';
import { Formik, Form } from 'formik';
import { connect } from 'react-redux';
import { verifyEmail } from '../../actions/auth.actions';

class VerifyEmail extends Component {
	state = {
		message: 'Email verification in progress',
		requestStarted: false
	};

	startVerification = () => {
		const verificationToken = this.props.location.search.substring(7);
		this.props.verifyEmail(verificationToken).then((response) => {
			this.setState({
				message: response.message,
			})
		});
	}

	render() {
		const { message, requestStarted } = this.state;

		if (!requestStarted) {
			this.setState({requestStarted: true}, (state) => {
				this.startVerification();
			});
		}

		return (
			<div className="cmv-page cmv-page-login">
				<div className="page-wrapper">
					<div className="page-content">
						<main>
							<div className="col col-sm-6 col-md-5 col-xl-3">
								<div className="card-login">
									<div className="card-login-header">
										<div className="brand-container" />
										<h3>{message}</h3>
										<div className="lds-dual-ring" />
									</div>
								</div>
							</div>
						</main>
					</div>
				</div>
			</div>
		);
	}
}

VerifyEmail.propTypes = {};

const mapStateToProps = (state) => {
	return {};
};

const mapDispatchToProps = {
	verifyEmail
};

export default connect(mapStateToProps, mapDispatchToProps)(VerifyEmail);
