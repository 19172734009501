import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {showModal, hideModal} from '../../../actions/modal.actions';
import {getProducts, getModalData, getAccountDetails, getTaxation} from '../../../selectors';
import {getTaxCodes} from '../../../actions/accountDetailsActions/billing.actions';
import {getCSIProviderTypes, getCSIProductTypes, getCSIServiceTypes, getItemTaxInfo, updateTaxInfo} from "../../../actions/taxation.actions";
import {Formik} from 'formik';
import {isEmpty,generateId} from "../../../utils/helpers";
import FormSelect from "../../../components/UI/FormSelect";
import Loader from "../../../components/Loader";
import {toastr} from "react-redux-toastr";
import TextInput from "../../../components/UI/TextInput";

const asOptions = (source, mapper) => {
    if (!Array.isArray(source)) {
        return [];
    }

    return source.map(mapper);
}

const taxCodesAsOptions = (taxCodes) => asOptions(taxCodes, taxCode => {
    if(taxCode != undefined) {
        return {
            label: taxCode.description,
            value: taxCode.id
        };
    }
});

const csiTypesAsOptions = (csiTypes) => asOptions(csiTypes, csiType => {
    if(csiType != undefined) {
        return {
            label: csiType.description + " (" + csiType.code + ")",
            value: csiType.code.toString()
        };
    }
});

const valuesAsOptions = (values) => asOptions(values, value => {
    return {
        label: value,
        value: value
    }
});

class EditTaxesModal extends Component {

    state = {
        alertMessage: '',
        isTaxInfoLoading: true
    };

    componentDidMount() {
        const {modalProps} = this.props;
        this.props.getTaxCodes();
        this.props.getCSIProviderTypes();
        this.props.getCSIProductTypes();
        this.props.getCSIServiceTypes();

        if(modalProps.value) {
            this.props.getItemTaxInfo(modalProps.value).then((response) => {
                    this.setState({
                        isTaxInfoLoading: false
                    })
                })
        }else{
            this.setState({
                isTaxInfoLoading: false
            })
        }

    }

    componentWillUnmount() {
    }


    formSubmit = (values, actions) => {
        if(values != undefined){
            this.props.updateTaxInfo(values).then((response) => {
                if (!response) {
                    return;
                }
                if (!response.success) {;
                    toastr.error(response.data.error.message, {timeOut: 8000, position: 'top-center'});
                    return response.data.error;
                } else {
                    this.props.hideModal();
                    this.props.modalProps.reloadFunction(true);
                    toastr.success(response.message, {timeOut: 4000, position: 'top-center'});
                }
            });
        }
    }


    scrollDropdownIntoView = (e) => {
        const elementClicked = e.target.nodeName;
        const boundingElement = e.currentTarget;
        const modalBody = document.getElementsByClassName('modal-body')[0];

        if (elementClicked !== 'LABEL') {

            setTimeout(function () {

                // Scroll down if the bottom is hidden...
                if (boundingElement.getBoundingClientRect().bottom > modalBody.getBoundingClientRect().bottom) {

                    // ... and the top won't end up hidden by scrolling down
                    if (boundingElement.getBoundingClientRect().height < modalBody.getBoundingClientRect().height) {

                        // Scroll down till bottom of element reaches bottom of screen
                        boundingElement.scrollIntoView({block: "end"});
                    }
                }
            }, 200);
        }
    }


    render() {
        const { alertMessage, isTaxInfoLoading, showPackageOptions, showFeatureAndPackages, showOverWritePrice } = this.state;
        const { modalProps, taxInfo, productFeature, taxCodesData, csiProviderTypes, csiServiceTypes, csiProductTypes} = this.props;
        const recordTypeOptions = [
            {description: "Private Line", code: "P"},
            {description: "Service", code: "S"},
            {description: "Call", code: "C"}];

        return (
            <Fragment>
                <div className="modal" style={{display: 'block'}} tabIndex="-1" role="dialog">
                    {isTaxInfoLoading &&
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">
                                    {modalProps.type === 'create' ? 'Add Feature Package' : 'Edit Feature Package'}
                                </h5>
                                <button onClick={this.props.hideModal} type="button" className="close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <Loader/>
                            <div className="modal-footer">
                                <button onClick={this.props.hideModal} className="btn" type="button">
                                    Cancel
                                </button>
                                <button type="submit" className="btn btn-primary">
                                    {modalProps.type === 'create' ? 'Add Feature Package' : 'Edit Feature Package'}
                                </button>
                            </div>
                        </div>
                    </div>
                    }
                    {!isTaxInfoLoading &&
                    <Formik
                        initialValues={{
                            id: taxInfo && taxInfo.id || '',
                            csiRecordType: taxInfo && taxInfo.csiRecordType || '',
                            csiProviderTypeCode: taxInfo && taxInfo.csiProviderTypeCode || '',
                            csiProductCode: taxInfo && taxInfo.csiProductCode || '',
                            csiServiceCode: taxInfo && taxInfo.csiServiceCode || '',
                            csiSafeHarborOverridePct: taxInfo && taxInfo.csiSafeHarborOverridePct || '',
                            csiTaxIncluded: taxInfo && taxInfo.csiTaxIncluded || false,
                            taxCodeId: taxInfo && taxInfo.taxCodeId
                        }}
                        //                        validationSchema={this.featurePackageSchema()}
                        onSubmit={this.formSubmit}
                        render={(props) => {

                            const {
                                setFieldValue,
                                handleChange,
                                handleSubmit,
                                handleBlur,
                                values,
                                errors,
                                touched,
                                isSubmitting,
                            } = props;

                            return (
                                <form className="cmv-form" onSubmit={handleSubmit}>
                                    <div className="modal-dialog modal-md">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h5 className="modal-title">
                                                    Tax Configuration
                                                </h5>
                                                <button onClick={this.props.hideModal} type="button" className="close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div className="modal-body">
                                                {!isEmpty(alertMessage) && (
                                                    <div className="alert alert-inline alert-danger alert-dismissible">
                                                        <p className="mb-0">{alertMessage}</p>
                                                    </div>
                                                )}
                                                <div className="order-service-order-wrapper">
                                                    <div><h4><i className={taxInfo.serviceModelIcon}/>&nbsp;{taxInfo.name}</h4>
                                                        <p className="billing-job-id">{taxInfo.description}</p>
                                                    </div>
                                                </div>
                                                <div className="form-horizontal">
                                                    {/* Main ServiceLine */}
                                                    <fieldset>
                                                        <legend className="as-label">CSI</legend>
                                                        <div onClick={this.scrollDropdownIntoView.bind(this)}>
                                                            <FormSelect
                                                                title="Provider Type Code"
                                                                fieldName={"csiProviderTypeCode"}
                                                                options={csiTypesAsOptions(csiProviderTypes)}
                                                                setFieldValue={setFieldValue}
                                                                value={values.csiProviderTypeCode}
                                                                errors={errors}
                                                                touched={touched}
                                                                onBlur={handleBlur}
                                                            />
                                                        </div>
                                                        <span>&nbsp;</span>
                                                        <div onClick={this.scrollDropdownIntoView.bind(this)}>
                                                            <FormSelect
                                                                title="Product Code"
                                                                fieldName={"csiProductCode"}
                                                                options={csiTypesAsOptions(csiProductTypes)}
                                                                setFieldValue={setFieldValue}
                                                                value={values.csiProductCode}
                                                                errors={errors}
                                                                touched={touched}
                                                                onBlur={handleBlur}
                                                            />
                                                        </div>
                                                        <span>&nbsp;</span>
                                                        <div onClick={this.scrollDropdownIntoView.bind(this)}>
                                                            <FormSelect
                                                                title="Service Code"
                                                                fieldName={"csiServiceCode"}
                                                                options={csiTypesAsOptions(csiServiceTypes)}
                                                                setFieldValue={setFieldValue}
                                                                value={values.csiServiceCode}
                                                                errors={errors}
                                                                touched={touched}
                                                                onBlur={handleBlur}
                                                            />
                                                        </div>
                                                        <span>&nbsp;</span>
                                                        <div onClick={this.scrollDropdownIntoView.bind(this)}>
                                                            <FormSelect
                                                                title="Record Type"
                                                                fieldName={"csiRecordType"}
                                                                options={csiTypesAsOptions(recordTypeOptions)}
                                                                setFieldValue={setFieldValue}
                                                                value={values.csiRecordType}
                                                                errors={errors}
                                                                touched={touched}
                                                                onBlur={handleBlur}
                                                            />
                                                        </div>
                                                        <span>&nbsp;</span>
                                                        <div>
                                                            <label>Safe Harbor Override Percent (optional)</label>
                                                            <TextInput
                                                                name="csiSafeHarborOverridePct"
                                                                value={values.csiSafeHarborOverridePct}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                errors={errors}
                                                                touched={touched}
                                                                style={{
                                                                    marginLeft: '-8px'
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="form-check checkbox-slider checkbox-slider--b-flat">
                                                            <label>
                                                                <input
                                                                    name="csiTaxIncluded"
                                                                    type="checkbox"
                                                                    onBlur={handleBlur}
                                                                    onChange={handleChange}
                                                                    checked={values.csiTaxIncluded}
                                                                />
                                                                <span>&nbsp;</span>
                                                            </label>
                                                            <label>Tax included in price</label>
                                                        </div>
                                                        <span>&nbsp;</span>
                                                    </fieldset>
                                                    <fieldset>
                                                        <legend className="as-label">Internal</legend>
                                                        <div className="form-group">
                                                            <div onClick={this.scrollDropdownIntoView.bind(this)}>
                                                                <FormSelect
                                                                    title="Tax Codes"
                                                                    fieldName={`taxCodeId`}
                                                                    options={taxCodesAsOptions(taxCodesData)}
                                                                    setFieldValue={setFieldValue}
                                                                    value={values.taxCodeId}
                                                                    isMulti={false}
                                                                    onBlur={handleBlur}
                                                                    errors={errors}
                                                                    touched={touched}
                                                                />
                                                            </div>
                                                        </div>
                                                    </fieldset>
                                                </div>
                                            </div>
                                            <div className="modal-footer">
                                                <button onClick={this.props.hideModal} className="btn" type="button">
                                                    Cancel
                                                </button>
                                                <button disabled={this.state.submitForm} type="submit"
                                                        className="btn btn-primary">
                                                    Save
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            )
                        }}
                    />
                    }
                </div>
                <div className="modal-backdrop show" tabIndex="1"/>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    const modalProps = getModalData(state).modalProps,
        taxCodesData = getAccountDetails(state).billing.taxCodes,
        csiProviderTypes = getTaxation(state).csiProviderTypes,
        csiServiceTypes = getTaxation(state).csiServiceTypes,
        csiProductTypes = getTaxation(state).csiProductTypes,
        taxInfo = getTaxation(state).taxItem,
        productFeature = getProducts(state).feature


    return {
        modalProps,
        taxCodesData,
        csiProviderTypes,
        csiServiceTypes,
        csiProductTypes,
        taxInfo,
        productFeature
    };
};

const mapDispatchToProps = {
    getTaxCodes,
    getCSIProviderTypes,
    getCSIProductTypes,
    getCSIServiceTypes,
    updateTaxInfo,
    getItemTaxInfo,
    hideModal,
    showModal
};

export default connect(mapStateToProps, mapDispatchToProps)(EditTaxesModal);

