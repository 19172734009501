import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { hideModal } from '../../../actions/modal.actions';
import { logout } from '../../../actions/auth.actions';

class SignoutModal extends Component {
	closeModal = () => {
		this.props.hideModal();
	};

	logout = () => {
		this.props.hideModal();
		this.props.logout(this.props.history);
	};

	render() {
		return (
			<Fragment>
				<div className="modal show" tabIndex="-1" style={{ display: 'block' }}>
					<div className="modal-dialog" role="document">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">Confirm Sign Out</h5>
								<button type="button" className="close">
									<span onClick={this.closeModal}>&times;</span>
								</button>
							</div>
							<div className="modal-body form-horizontal">
								<p className="lead">Are you sure you want to sign out?</p>
							</div>
							<div className="modal-footer">
								<button onClick={this.closeModal} className="btn">
									Cancel
								</button>
								<button className="btn btn-primary" onClick={this.logout}>
									Yes
								</button>
							</div>
						</div>
					</div>
				</div>
				<div className="modal-backdrop show" tabIndex="1" />
			</Fragment>
		);
	}
}

const mapDispatchToProps = {
	hideModal,
	logout
};
export default connect(null, mapDispatchToProps)(SignoutModal);
