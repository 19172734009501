import React, { Component } from 'react';
import ServiceOrder from './ServiceOrder';
import { connect } from 'react-redux';
import { isEmpty } from '../../../../utils/helpers';
import Loader from '../../../../components/Loader';
import { getOrderById } from '../../../../actions/dashboardActions/orders.actions';

class Services extends Component {
	state = {
		order: {},
		isLoaded: false,
		time: 10000
		// second: 1000
	};

	getOrderData = () => {
		const { orderId } = this.props;
		const { isLoaded } = this.state;

		if (isLoaded === false) {
			this.setState(
				{
					isLoaded: true
				},
				() =>
					getOrderById(orderId).then((response) => {
						if (response.success) {
							this.setState({
								order: response.order
							});
						} else {
							this.setState({
								isLoaded: false
							});
						}
					})
			);
		} else {
			getOrderById(orderId).then((response) => {
				if (response.success) {
					this.setState({
						order: response.order
					});
				} else {
					this.setState({
						isLoaded: false
					});
				}
			});
		}
	};
	componentDidMount() {
		this.getOrderData();
		// this.interval = setInterval(() => this.getOrderData(), this.state.time);
	}

	componentWillUnmount() {
		clearInterval(this.interval);
	}

	render() {
		const { order, isLoaded } = this.state;

		if (isEmpty(order) && isLoaded) {
			return <Loader />;
		}

		return (
			<div className="order-body collapse show">
				<div className="order-body-content">
					{order.serviceOrders &&
						order.serviceOrders.map((service) => (
							<ServiceOrder serviceInfo={service} key={service.id} {...this.props}/>
						))}
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {};
};

const mapDispatchToProps = {
	// getOrderById
};

export default connect(mapStateToProps, mapDispatchToProps)(Services);
