import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getDashboardMenuCount, emptyDashboardMenuCount } from '../../actions/dashboardActions/customers.actions';
import {getDashboardData, getUser, getAuthData, createLoadingSelector} from '../../selectors';
import Tiles from './Tiles';
import Content from './Content';
import isEmpty from "../../utils/helpers";

class Dashboard extends Component {

	state = {
		activeTile: 'customers'
	};

	componentDidMount() {

		// Change navbar type and color
		document.title = 'Home - camvio.cloud';
		this.props.changeNavBarType('default', 'Home');

		// Load dashboard item counts
		// if (isEmpty(this.props.dashboardMenuCount)) {
		// 	this.props.getDashboardMenuCount();
		// }

		// Load previously selected tab
		if (this.props.history.location.state && this.props.history.location.state.activeTile) {
			this.setState({
				activeTile: this.props.history.location.state.activeTile.tile
			});
		}
	}

	componentWillUnmount() {
		clearInterval(this.interval);
	}

	handleTileChange = (tile) => {
		this.setState({
			activeTile: tile
		});

		window.location.hash = tile;
	};

	render() {

		const { activeTile } = this.state;
		const { dashboardMenuCount, dashboardMenuCountLoading } = this.props;

		return (
			<div className="cmv-dashboard-modern">
				<div className="cmv-container cmv-container-dashboard cmv-container-dashboard-tiles">
					<Tiles
						activeTile={activeTile}
						handleTileChange={this.handleTileChange}
						dashboardMenuCount={dashboardMenuCount}
						dashboardMenuCountLoading={dashboardMenuCountLoading}
					/>
					<Content
						activeTile={activeTile}
						dashboardMenuCount={dashboardMenuCount}
						{...this.props}
					/>
				</div>
			</div>
		);
	}
}

Dashboard.propTypes = {
	customersServedCount: PropTypes.number,
	getCustomersServedCount: PropTypes.func
};


const dashboardMenuCountLoadingSelector = createLoadingSelector([ 'SET_DASHBOARD_MENU_COUNT' ]);

const mapStateToProps = (state) => {
	const dashboardMenuCount = getDashboardData(state).dashboardMenuCount,
		dashboardMenuCountLoading = dashboardMenuCountLoadingSelector(state),
		user = getUser(state).user,
		username = getAuthData(state).username;

	return {
		dashboardMenuCount,
		dashboardMenuCountLoading,
		user,
		username
	};
};

const mapDispatchToProps = {
	getDashboardMenuCount,
	emptyDashboardMenuCount
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
