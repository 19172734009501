import {
	CREATE_ORDER,
	PLANS_AND_BUNDLES,
	PLANS_DETAILS,
	EMPTY_NEW_ORDER,
	QUOTES_ACCOUNT_TYPES,
	QUOTES_ACCOUNT_TYPE_DETAILS,
	SERVICE_REQUEST_START_ORDER,
	SERVICE_REQUEST_PENDING_TASKS_COUNT,
	MAP_LINK_LOCATION, CREATE_ACCOUNT,
	SERVICE_EQUIPMENT
} from '../actions/createServiceRequest.actions';

let initialState = {
	newOrderInfo: {},
	planBundles: [],
	plans: [],
	plansDetails: [],
	accountTypes: [],
	accountTerms: [],
	billCycles: '',
	defaultAccountId: '',
	isOrderStarted: null,
	tasksCount: 0,
	validatedAddress: null,
	serviceEquipment: [],
};

export const createServiceRequestReducer = (state = initialState, action) => {
	switch (action.type) {
		case CREATE_ORDER.SUCCESS:
			return {
				...state,
				newOrderInfo: action.payload
			};
		case PLANS_AND_BUNDLES.SUCCESS:
			return {
				...state,
				planBundles: action.payload.planBundles,
				plans: action.payload.plans,
				validatedAddress: action.payload.validatedAddress,
			};
		case PLANS_DETAILS.SUCCESS:
			return {
				...state,
				plansDetails: [ ...state.plansDetails, action.payload ]
			};
		case PLANS_DETAILS.EMPTY:
			return {
				...state,
				plansDetails: []
			};
	
		case 'SET_PLANS_DETAILS':
			return {
				...state,
				plansDetails: [ ...state.plansDetails, ...action.payload ]
			};
		case QUOTES_ACCOUNT_TYPES.SUCCESS:
			return {
				...state,
				accountTypes: action.payload
			};
		case QUOTES_ACCOUNT_TYPES.EMPTY:
			return {
				...state,
				accountTypes: []
			};
		case QUOTES_ACCOUNT_TYPE_DETAILS.SUCCESS:
			return {
				...state,
				accountTerms: action.payload.accountTerms,
				billCycles: action.payload.allowedBillCycles,
				defaultAccountId: action.payload.id
			};
		case QUOTES_ACCOUNT_TYPE_DETAILS.EMPTY:
			return {
				...state,
				accountTerms: [],
				billCycles: '',
				defaultAccountId: ''
			};
		case SERVICE_REQUEST_START_ORDER.SUCCESS:
			return {
				...state,
				isOrderStarted: action.payload
			}
		case SERVICE_REQUEST_START_ORDER.EMPTY:
			return {
				...state,
				isOrderStarted: null
			}
		case SERVICE_REQUEST_PENDING_TASKS_COUNT.SUCCESS:
			return {
				...state,
				tasksCount: action.payload
			}
		case SERVICE_REQUEST_PENDING_TASKS_COUNT.SUCCESS:
			return {
				...state,
				tasksCount: 0
			}
		case 'EMPTY_NEW_ORDER':
				return {
					...initialState
				};
		case MAP_LINK_LOCATION.SUCCESS:
			return {
				...state,
				validatedAddress: action.payload.validatedAddress
			};
		case MAP_LINK_LOCATION.FAILURE:
			return {
				...state,
				validatedAddress: action.payload
			};
		case MAP_LINK_LOCATION.EMPTY:
			return {
				...state,
				validatedAddress: null
			}
		case CREATE_ACCOUNT.SUCCESS:
			return {
				...state,
				newAccountInfo: action.payload
			};
		case SERVICE_EQUIPMENT.SUCCESS:
			return {
				...state,
				serviceEquipment: action.payload
			};
		case SERVICE_EQUIPMENT.CLEAR:
			return {
				...state,
				serviceEqupment: action.payload
			};

		default:
			return state;
	}
};
