import React, {Component} from 'react';
import {connect} from "react-redux";
import PropTypes from 'prop-types';
import {getAppInfo} from "../../../../actions/sideBar.actions";
import {getSideBar} from "../../../../selectors";
import isEmpty from "../../../../utils/helpers";
import {Link} from 'react-router-dom';

class SettingModuleMenu extends Component {

    state = {
        activeSubMenuItem: '',
    }

    componentDidMount() {
        this.props.getAppInfo();
    }

    changeSubMenuItem = (subMenuItem) => {
        if (this.state.activeSubMenuItem === subMenuItem) {
            this.setState({activeSubMenuItem: ''});
        } else {
            this.setState({activeSubMenuItem: subMenuItem});
        }
    }

    render() {

        const {activeSubMenuItem} = this.state;
        const {activeMenu, changeActiveMenu, permissions, appInfo,userPermission} = this.props;

        return (
            <ul>
                <li className="header-menu">
                    <span>Settings</span>
                </li>
                {(!isEmpty(permissions) && permissions.find(m => m === 'ADMN_MANAGE_PAYMENT_PROVIDERS')) &&
                    <li className="sidebar-nav-link">
                        <Link to={{ pathname: '/provider-settings' }}><i className="fa fa-credit-card" />Payment Provider</Link>
                    </li>
                }
                {userPermission&&(!isEmpty(userPermission.permissions) && userPermission.permissions.find(m => m === 'SHOW_PORTAL_CONFIG')) &&<li className="sidebar-nav-link">
                        <Link to={{ pathname: '/portal-configuration' }}><i className="fas fa-fw fa-cogs"/>Portal Configuration</Link>
                </li>}
                {userPermission&&(!isEmpty(userPermission.permissions) && userPermission.permissions.find(m => m === 'SHOW_NOTIFICATIONS_HANDLER_PROPERTIES')) &&<li className="sidebar-nav-link">
                        <Link to={{ pathname: '/notification-properties' }}><i className="fas fa-fw fa-cogs"/>Notification Properties</Link>
                </li>}
                <li className={activeMenu === 'SETTING' ? 'sidebar-dropdown active' : 'sidebar-dropdown'}>
                    <a onClick={() => changeActiveMenu('SETTING')}>
                        <i className="fa fa-info-circle"/>
                        <span>About</span>
                    </a>
                    <div
                        className="sidebar-submenu sidebar-submenu-info"
                        style={activeMenu === 'SETTING' ? {display: 'block'} : {display: 'none'}}
                    >
                        <ul>
                            {!isEmpty(appInfo) &&
                            <>
                                <li className="sidebar-nav-link sidebar-nav-link-info">
                                    <a>
                                        <span className="sidebar-nav-link-info-text">Home Name</span>
                                        <span className="badge badge-pill badge-blue">{appInfo.homeName}</span>
                                    </a>
                                </li>
                                <li className="sidebar-nav-link sidebar-nav-link-info">
                                    <a>
                                        <span className="sidebar-nav-link-info-text">Database</span>
                                        <span className="badge badge-pill badge-blue">{appInfo.databaseName}</span>
                                    </a>
                                </li>
                                <li className="sidebar-nav-link sidebar-nav-link-info">
                                    <a>
                                        <span className="sidebar-nav-link-info-text">API Name</span>
                                        <span className="badge badge-pill badge-blue">{appInfo.apiName}</span>
                                    </a>
                                </li>
                                <li className="sidebar-nav-link sidebar-nav-link-info">
                                    <a>
                                        <span className="sidebar-nav-link-info-text">API Version</span>
                                        <span className="badge badge-pill badge-blue">{appInfo.apiVersion}</span>
                                    </a>
                                </li>
                                <li className="sidebar-nav-link sidebar-nav-link-info">
                                    <a>
                                        <span className="sidebar-nav-link-info-text">App Version</span>
                                        <span className="badge badge-pill badge-blue">6.38.10</span>
                                    </a>
                                </li>
                            </>
                            }
                        </ul>
                    </div>
                </li>
            </ul>
        );
    }
}

SettingModuleMenu.propTypes = {
    activeMenu: PropTypes.string.isRequired,
    changeActiveMenu: PropTypes.func.isRequired,
    permissions: PropTypes.array.isRequired,
};


const mapStateToProps = (state) => {

    const appInfo = getSideBar(state).appInfo;
    return {
        appInfo,
    };
};

const mapDispatchToProps = {
    getAppInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(SettingModuleMenu);
