import React, { Component } from 'react';
import FormikInput from '../../../../../components/Common/Inputs/FormikInput';

export class Check extends Component {
	render() {
		const { values, errors, touched, handleChange, handleBlur, isSubmitting } = this.props;

		return (
			<FormikInput
				wrapperClass="form-group"
				label="Check Number"
				type="number"
				name="checkNumber"
				onChange={handleChange}
				onBlur={handleBlur}
				value={values.checkNumber}
				placeholder="Check Number"
				errors={errors}
				touched={touched}
				disabled={isSubmitting}
			/>
			// <div className="form-group">
			// 	<label>Check Number</label>
			// 	<input
			// 		type="number"
			// 		name="checkNumber"
			// 		onChange={handleChange}
			// 		onBlur={handleBlur}
			// 		value={values.checkNumber}
			// 		className={errors.checkNumber && touched.checkNumber ? 'form-control is-invalid' : 'form-control'}
			// 		placeholder="Check Number"
			// 	/>
			// 	{errors.checkNumber && touched.checkNumber && <div className="invalid-feedback">{errors.checkNumber}</div>}

			// </div>
		);
	}
}

export default Check;
