import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import isEmpty from "../../../utils/helpers";
import {hideModal} from '../../../actions/modal.actions';
import DatePicker from "react-datepicker";
import moment from "moment";
import Loader from "../../../components/Loader";
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import setSeconds from "date-fns/setSeconds";
import {Formik} from "formik";
import {
    getOrderAvailability, getTroubleTicketAvailability,
    getOrderAppointments, getTroubleTicketAppointments,
    emptyOrderAppointments, emptyTroubleTicketAppointments,
    createOrderAppointment, createTroubleTicketAppointment,
    clearOrderAvailability, clearTroubleTicketAvailability
} from "../../../actions/technicianAppointment.actions";
import {
    createLoadingSelector,
    getTechnicianAppointment,
    getModalData,
    getUserPermission
} from '../../../selectors';
import {toastr} from "react-redux-toastr";

/*const techniciansAsOptions = (technicians) => asOptions(technicians, technician => {
    return {
        label: technician.firstName + " " + technician.lastName,
        value: JSON.stringify(technician)
    }
});*/

/*const asOptions = (source, mapper) => {
    if (!Array.isArray(source)) {
        return [];
    }

    return source.map(mapper);
}*/

/*const findTechnicianById = (technicians, id) => {
    return technicians && technicians.find(technician => technician.id === id);
}*/

class TechnicianAppointmentModal extends Component {

    state = {
        isOverbookAllowed: false,
        selectedDate: undefined,
        appointmentDate: undefined,
        includedDates: undefined,
        includedTimes: undefined,
    };

    componentDidMount() {
        const {
            getOrderAvailability,
            getTroubleTicketAvailability,
            getOrderAppointments,
            getTroubleTicketAppointments,
        } = this.props;

        const {
            appointment_type,
            id
        } = this.props.modalProps;

        switch (appointment_type) {
            case "TROUBLE_TICKET":
                getTroubleTicketAvailability(id);
                getTroubleTicketAppointments(id);
                break;
            case "ORDER":
                getOrderAvailability(id);
                getOrderAppointments(id);
                break;
        }
    }

    componentDidUpdate(prevProps) {
        const {
            appointmentsLoading,
            technicianAppointments,
            availabilityLoading,
            technicianAvailability,
            geocodeError
        } = this.props;

        if (!appointmentsLoading && !availabilityLoading && !isEmpty(technicianAppointments) && this.state.appointmentDate === undefined) {
            this.getAppointment();
            if(geocodeError != undefined){
                toastr.error(geocodeError, {timeOut: 0, position: 'top-center'});
            }
        }

        if (!appointmentsLoading && !availabilityLoading && !isEmpty(technicianAvailability) && this.state.includedDates === undefined) {
            this.getIncludedDates();
        }
    }

    componentWillUnmount() {
        const { emptyOrderAppointments, emptyTroubleTicketAppointments, clearOrderAvailability, clearTroubleTicketAvailability } = this.props;
        const { appointment_type } = this.props.modalProps;

        switch (appointment_type) {
            case "TROUBLE_TICKET":
                emptyTroubleTicketAppointments();
                clearTroubleTicketAvailability();
                break;
            case "ORDER":
                emptyOrderAppointments();
                clearOrderAvailability();
                break;
        }
    }

    getAppointment = () => {
        let appointments;

        if (this.props.modalProps.appointment_type === "TROUBLE_TICKET") {
            appointments = this.props.technicianAppointments.troubleTicketAppointments;
        }
        else {
            appointments = this.props.technicianAppointments.orderAppointments;
        }

        let appointment = null;

        if (appointments && appointments.length > 0) {
            const formattedDateTime = moment().year(appointments[0].date.year).dayOfYear(appointments[0].date.dayOfYear).format("YYYY-MM-DD") + 'T' + appointments[0].timeslot.fromTime;

            appointment = moment(formattedDateTime).toDate();
        }

        this.setState({
            appointmentDate: appointment,
            selectedDate: appointment,
        }, () => {
            this.getIncludedTimes();
        });
    }

    getIncludedDates = () => {
        const {isOverbookAllowed} = this.state;

        let includedDates = [];
        let filteredIncludedDates = [];

        if (isOverbookAllowed) {

            // Show all future dates including today
            filteredIncludedDates = null;
        }
        else {

            // Show dates with availability
            Object.keys(this.props.technicianAvailability).map((date) => {
                includedDates.push(moment(date).toDate())
            });

            // Include dates with non-zero availability
            includedDates.map(includedDate => {

                let includeDate = false;

                // If there is availability data for this date
                if (this.props.technicianAvailability[moment(includedDate).format("YYYY-MM-DD")]) {

                    // Get all slots
                    this.props.technicianAvailability[moment(includedDate).format("YYYY-MM-DD")].map(slot => {

                        // Include date if it has some availability
                        if (slot.availability > 0) {

                            // Include today ...
                            if (moment(includedDate).format("YYYY-MM-DD") === moment().format("YYYY-MM-DD")) {

                                // ... only if it has upcoming availability
                                if (moment.duration(slot.fromTime).asHours() > parseInt(moment().format('H'))) {
                                    includeDate = true;
                                }
                            }

                            // Include all future days
                            else {
                                includeDate = true;
                            }
                        }
                    })
                }

                // Push date
                if (includeDate) {
                    filteredIncludedDates.push(includedDate);
                }
            });
        }

        this.setState({
            includedDates: filteredIncludedDates
        })
    }

    getIncludedTimes = () => {
        const {isOverbookAllowed, selectedDate, appointmentDate} = this.state;

        let includedTimes = [];
        let resetTime = true;

        // If overbooking is allowed
        if (isOverbookAllowed) {

            // Add all time slots
            includedTimes = [
                setHours(setMinutes(setSeconds(new Date(), 0), 0), 8),
                setHours(setMinutes(setSeconds(new Date(), 0), 0), 10),
                setHours(setMinutes(setSeconds(new Date(), 0), 0), 13),
                setHours(setMinutes(setSeconds(new Date(), 0), 0), 15),
                setHours(setMinutes(setSeconds(new Date(), 0), 0), 17),
            ];
        }
        else {

            // If there is availability for this date
            if (this.props.technicianAvailability[moment(selectedDate).format("YYYY-MM-DD")]) {

                // Get available slots
                this.props.technicianAvailability[moment(selectedDate).format("YYYY-MM-DD")].map(slot => {

                    // Don't reset hours if selected date is the appointment (like on modal open)
                    if (selectedDate === appointmentDate) {
                        resetTime = false;
                    }

                    // Don't add timeslot it if has no availability
                    if (slot.availability <= 0) {
                        return;
                    }

                    // Add timeslot
                    includedTimes.push(setHours(setMinutes(setSeconds(new Date(), 0), 0), moment.duration(slot.fromTime).asHours()));

                    // Don't reset hours if this timeslot is selected
                    if (slot.fromTime === moment(selectedDate).format("HH:mm:ss")) {
                        resetTime = false;
                    }
                });

                // If selected time is not available
                if (resetTime) {
                    this.setState({selectedDate: setHours(selectedDate, 0)});
                }
            }

            // Otherwise if it's not the same as the appointment date
            else if (selectedDate !== appointmentDate) {

                // Deselect the date
                this.setState({selectedDate: undefined});
            }
        }

        // If selected day is today
        if (moment(selectedDate).format("YYYY-MM-DD") === moment().format("YYYY-MM-DD")) {
            let updatedIncludedTimes = [];

            // Check all time slots
            includedTimes.map(includedTime => {

                // Show only upcoming time slots
                if (parseInt(moment(includedTime).format('H')) > parseInt(moment().format('H'))) {
                    updatedIncludedTimes.push(includedTime);
                }
            });

            includedTimes = updatedIncludedTimes;
        }

        this.setState({includedTimes: includedTimes})
    }

    handleDateTimeChange = (newDateTime) => {

        // If selecting a different day
        if (moment(this.state.selectedDate).format("YYYY-MM-DD") !== moment(newDateTime).format("YYYY-MM-DD")) {

            // Reset hours
            this.setState({
                selectedDate: setHours(newDateTime, 0)
            }, () => {
                this.getIncludedTimes();
            });
        }
        else {
            this.setState({selectedDate: newDateTime});
        }
    }

    handleHiddenHours = (time) => {
        if (time.getHours() < 8 || time.getHours() > 18)
            return "react-datepicker__time-list-item--hidden";
    }

    handleOverbookChange = () => {

        this.setState((prevState) => ({
            isOverbookAllowed: !prevState.isOverbookAllowed
        }), () => {
            this.getIncludedDates();
            this.getIncludedTimes();
        })
    }

    formSubmit = (values, {setSubmitting}) => {
        const { appointment_type } = this.props.modalProps;
        // Values are {"id":51, "dateTime":"2021-01-20T11:00:00.716Z"}

        const data = {};

        if (values.dateTime) {
            data.overbook = this.state.isOverbookAllowed;
            data.dateTime = moment(values.dateTime).format("YYYY-MM-DDTHH:mm:ss");
        }

        if (appointment_type === "TROUBLE_TICKET") {
            this.props.createTroubleTicketAppointment(values.id, data).then(response => {
                setSubmitting(false);

                if (response.data.success) {
                    toastr.success(response.data.message, { timeOut: 2000, position: 'top-center' });
                    this.closeModal();
                }

                if (!response.data) {
                    toastr.error('Unknown error. Please try again later', { timeOut: 2000, position: 'top-center' });
                    return;
                }

                if (!response.data.success) {
                    if (response.data.error) {
                        toastr.error(response.data.error.message, { timeOut: 2000, position: 'top-center' });
                    } else {
                        toastr.error('Unknown error. Please try again later', { timeOut: 2000, position: 'top-center' });
                    }
                }
            }).catch(ex => {
                setSubmitting(false);
                toastr.error('Unknown error. Please try again later', { timeOut: 2000, position: 'top-center' });
            });
        }

        else if (appointment_type === "ORDER") {
            this.props.createOrderAppointment(values.id, data).then(response => {
                setSubmitting(false);

                if (response.data.success) {
                    toastr.success(response.data.message, { timeOut: 2000, position: 'top-center' });
                    this.closeModal();
                }

                if (!response.data) {
                    toastr.error('Unknown error. Please try again later', { timeOut: 2000, position: 'top-center' });
                    return;
                }

                if (!response.data.success) {
                    if (response.data.error) {
                        toastr.error(response.data.error.message, { timeOut: 2000, position: 'top-center' });
                    } else {
                        toastr.error('Unknown error. Please try again later', { timeOut: 2000, position: 'top-center' });
                    }
                }
            }).catch(ex => {
                setSubmitting(false);
                toastr.error('Unknown error. Please try again later', { timeOut: 2000, position: 'top-center' });
            });
        }
    }

    getAppointments = () => {
        const { technicianAppointments } = this.props;
        const { appointment_type } = this.props.modalProps;

        switch (appointment_type) {
            case "TROUBLE_TICKET":
                return technicianAppointments.troubleTicketAppointments;
            case "ORDER":
                return technicianAppointments.orderAppointments;
        }
    }

    getAppointmentDateTime = () => {
        const appointments = this.getAppointments();

        if (appointments && appointments.length > 0) {
            const appointment = appointments[0];
            const aptDate = appointment.date;
            const formattedDateTime = moment().year(aptDate.year).dayOfYear(aptDate.dayOfYear).format("YYYY-MM-DD") + 'T' + appointment.timeslot.fromTime;

            return moment(formattedDateTime).toDate();
        }

        return "";
    }

    closeModal = () => {
        this.props.hideModal();

        if (this.props.modalProps.onCloseCallback) {
            this.props.modalProps.onCloseCallback();
        }
    };

    render() {

        const {
            isOverbookAllowed,
            selectedDate,
            includedDates,
            includedTimes,
        } = this.state;

        const {
            technicianAppointments,
            appointmentsLoading,
            technicianAvailability,
            availabilityLoading,
            canOverbookPermission,
        } = this.props;

        return (
            <Fragment>
                <div className="modal" style={{ display: 'block' }} tabIndex="-1" role="dialog">
                    <div className="modal-dialog">
                        <Formik
                            enableReinitialize
                            initialValues={{
                                id: this.props.modalProps.id,
                                dateTime: selectedDate,
                            }}
                            onSubmit={this.formSubmit}
                            render={({ handleChange, handleSubmit, handleBlur, values, errors, touched, isSubmitting, setFieldValue }) => (
                                <form onSubmit={handleSubmit}>
                                    <div className="modal-content">

                                        <div className="modal-header">
                                            <h5 className="modal-title">
                                                Manage Appointments
                                            </h5>
                                            <button
                                                type="button"
                                                className="close"
                                                disabled={isSubmitting}
                                                onClick={this.props.hideModal}
                                            >
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>

                                        <div className="modal-body form-horizontal">

                                            {(appointmentsLoading || availabilityLoading)
                                                ?
                                                <Loader/>
                                                :
                                                <>

                                                    {canOverbookPermission &&
                                                    <div className="form-group">
                                                    <span className="h-check">
                                                        <label>Overbook&nbsp;</label>
                                                        <div className="form-check checkbox-slider checkbox-slider--b-flat">
                                                            <label>
                                                                <input
                                                                    name="overbook"
                                                                    type="checkbox"
                                                                    checked={isOverbookAllowed}
                                                                    onChange={() => this.handleOverbookChange()}
                                                                    disabled={isSubmitting}
                                                                />
                                                                <span>&nbsp;</span>
                                                            </label>
                                                        </div>
                                                    </span>
                                                    </div>
                                                    }

                                                    <div className="form-group form-group-appointment">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder={selectedDate ? moment(selectedDate).format(selectedDate.getHours() === 0 ? "MMMM D, YYYY" : "MMMM D, YYYY @ h:00 A") : "Select Appointment Date and Timeslot"}
                                                            disabled
                                                        />
                                                        <button
                                                            type="button"
                                                            className="form-group-appointment-clear"
                                                            onClick={() => this.setState({selectedDate: undefined})}
                                                            disabled={isSubmitting}
                                                        >
                                                            &times;
                                                        </button>
                                                    </div>

                                                    <div className="form-group d-flex justify-content-center">
                                                        <div className="customDatePickerWidth">
                                                            <DatePicker
                                                                inline
                                                                autoFocus
                                                                popperPlacement={'auto'}
                                                                isClearable
                                                                disabledKeyboardNavigation
                                                                showTimeSelect
                                                                shouldCloseOnSelect={false}
                                                                placeholderText={"Select Appointment Date and Timeslot"}
                                                                selected={values.dateTime}
                                                                onChange={(date) => !isSubmitting ? this.handleDateTimeChange(date) : null}
                                                                includeDates={isOverbookAllowed ? null : includedDates || []}
                                                                includeTimes={includedTimes}
                                                                timeClassName={time => this.handleHiddenHours(time)}
                                                                timeIntervals={60}
                                                                minDate={moment().toDate()}
                                                                dateFormat="MMMM d, yyyy h:mm aa"
                                                            />
                                                        </div>
                                                    </div>
                                                </>
                                            }

                                        </div>

                                        <div className="modal-footer">
                                            <button
                                                type="button"
                                                className="btn"
                                                disabled={isSubmitting}
                                                onClick={this.props.hideModal}
                                            >
                                                Cancel
                                            </button>
                                            <button
                                                type="submit"
                                                className="btn btn-primary"
                                                disabled={
                                                    isSubmitting ||
                                                    appointmentsLoading ||
                                                    availabilityLoading ||
                                                    (selectedDate && selectedDate.getHours() === 0)
                                                }
                                            >
                                                Save Appointment
                                            </button>
                                        </div>

                                    </div>
                                </form>
                            )}
                        />
                    </div>
                </div>
                <div className="modal-backdrop show" tabIndex="1" />
            </Fragment>
        );
    }
}


const getAppointmentsLoadingSelector = createLoadingSelector(['GET_ORDER_APPOINTMENTS', 'GET_TROUBLE_TICKET_APPOINTMENTS']);
const getAvailabilityLoadingSelector = createLoadingSelector(['GET_ORDER_AVAILABILITY', 'GET_TROUBLE_TICKET_AVAILABILITY']);

const mapStateToProps = (state) => {

    const modalProps = getModalData(state).modalProps;
    const technicianAppointments = getTechnicianAppointment(state);
    const appointmentsLoading = getAppointmentsLoadingSelector(state);
    const availabilityLoading = getAvailabilityLoadingSelector(state);
    const technicianAvailability = getTechnicianAppointment(state).availability;
    const geocodeError = getTechnicianAppointment(state).geocodeError;
    const canOverbookPermission = getUserPermission(state, 'OPER', 'OPER_OVERBOOK_CAPACITY');

    return {
        modalProps,
        technicianAppointments,
        appointmentsLoading,
        technicianAvailability,
        geocodeError,
        availabilityLoading,
        canOverbookPermission,
    };
};

const mapDispatchToProps = {
    hideModal,
    getOrderAvailability,
    getTroubleTicketAvailability,
    getOrderAppointments,
    getTroubleTicketAppointments,
    emptyOrderAppointments,
    emptyTroubleTicketAppointments,
    createOrderAppointment,
    createTroubleTicketAppointment,
    clearOrderAvailability,
    clearTroubleTicketAvailability
};

export default connect(mapStateToProps, mapDispatchToProps)(TechnicianAppointmentModal);