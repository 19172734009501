import React from 'react';
import PropTypes from 'prop-types';

const Button = ({ type, className, onClick, children, disabled }) => {
	return (
		<button onClick={onClick} type={type} className={className} disabled={disabled}>
			{children}
		</button>
	);
};

Button.propTypes = {
	type: PropTypes.string.isRequired,
	className: PropTypes.string.isRequired,
	onClick: PropTypes.func,
	disabled: PropTypes.bool
};

export default Button;
