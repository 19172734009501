import React from 'react';
import PropTypes from 'prop-types';

const StepActions = ({previous, next, previousBtnName, nextBtnName}) => {

    return (
        <div className="wizard-footer d-flex justify-content-between">
            {previous?
                <button onClick={previous} className="btn btn-outline-primary btn-prev-tab mr-1" type="button">
                    {previousBtnName? previousBtnName : "Previous"}
                </button>
                : null
            }
            {next?
                <button onClick={next} className="btn btn-outline-primary btn-next-tab ml-auto" type="button">
                    {nextBtnName? nextBtnName : "Next"}
                </button>
                : null
            }
        </div>
    );
}

StepActions.propTypes = {
    previous: PropTypes.func,
    next: PropTypes.any,
    previousBtnName: PropTypes.string,
    nextBtnName: PropTypes.string,
};

export default StepActions;