import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import Services from './Services';
import {parseDateTimeFormat, getformatedFilters} from './utils';
import TargetDateTime from "../../../../components/UI/TargetDateTime";
import moment from "moment";
import isEmpty from "../../../../utils/helpers";

export class Order extends Component {

	state = {
		isCancelled: false,
		isCompleted: false,
		isServiceExpanded: false,
		isExecuteButtonClicked: false,
	};

	componentDidMount() {
		this.setState({
			isCancelled: this.props.orderInfo.status === 'CANCELLED',
			isCompleted: this.props.orderInfo.progressPercentage === 100,
		});
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.orderInfo !== this.props.orderInfo) {

			// Update isCancelled and isCompleted state
			this.setState({
				isCancelled: this.props.orderInfo.status === 'CANCELLED',
				isCompleted: this.props.orderInfo.progressPercentage === 100,
			});

			if (this.props.orderInfo.isStarted === true && this.state.isExecuteButtonClicked === true) {
				this.setState({isExecuteButtonClicked: false});
			}
		}
	}

	handlerServiceExpand = () => {
		this.setState((prevState) => ({
			isServiceExpanded: !prevState.isServiceExpanded
		}));
	};

	handleExecute = (orderId) => {
		const { user, filters, searchType, searchServiceOrdersType } = this.props;

		let wasServiceExpanded = this.state.isServiceExpanded;

		this.setState({isExecuteButtonClicked: true}, () => {
			this.props.executeOrder(orderId, getformatedFilters(filters, searchType, user.id, searchServiceOrdersType)).then((response) => {

				if (response.success) {

					// Close order details so we can reload them
					this.setState({
						isServiceExpanded: false,
						isExecuteButtonClicked: false,
					}, () => {

						// Reopen and reload order details if they were previously open
						this.setState({isServiceExpanded: wasServiceExpanded})
					});
				}
				else {
					this.setState({isExecuteButtonClicked: false})
				}
			});
		});
	};

	renderColorStatus = (hasErrorStatus, percentage) => {
		if (hasErrorStatus) {
			if (percentage === 100) {
				return 'progress-bar bg-danger';
			} else {
				return 'progress-bar bg-danger bg-danger-with-pulse';
			}
		} else {
			if (percentage === 100) {
				return 'progress-bar bg-success';
			} else {
				return 'progress-bar bg-success bg-success-with-pulse';
			}
		}
	};

	getAppointmentDateTime = (appointment) => {
		return moment().year(appointment.date.year).dayOfYear(appointment.date.dayOfYear).format("MM/DD/YYYY")
			+ ' @ ' +
			moment().hour(appointment.timeslot.fromTime.substring(0, 2)).minutes(appointment.timeslot.fromTime.substring(3, 5)).format('h:mmA')
			+ '-' +
			moment().hour(appointment.timeslot.toTime.substring(0, 2)).minutes(appointment.timeslot.fromTime.substring(3, 5)).format('h:mmA')
	};

	render() {

		const {
			isCancelled,
			isCompleted,
			isServiceExpanded,
			isExecuteButtonClicked,
		} = this.state;

		const {
			orderInfo,
			hasCollapsedIcon,
			accountAccessModal,
			isBulkSelectionEnabled,
			bulkSelectionOrders,
		} = this.props;

		const appointment = orderInfo.appointments && orderInfo.appointments.length > 0 && orderInfo.appointments[0];

		return (
			<div
				className={
					"order-wrapper" +
					(isBulkSelectionEnabled ? " order-wrapper-bulk-selection" : "") +
					(bulkSelectionOrders.includes(orderInfo.id) ? " order-wrapper-bulk-selection-selected" : "") +
					((isCompleted || orderInfo.isStarted || !orderInfo.canStart) ? " order-wrapper-bulk-selection-disabled" : "")
				}
				onClick={() => {
					if (isBulkSelectionEnabled && !isCompleted && !orderInfo.isStarted && orderInfo.canStart) {
						this.props.handleBulkSelectionAddRemove(orderInfo.id)}
					}
				}
			>

				<div className="order-header row">
					<div className="col-12 col-sm-5 col-md-3">

						{isBulkSelectionEnabled && !isCompleted && !orderInfo.isStarted && orderInfo.canStart && <>
							{bulkSelectionOrders.includes(orderInfo.id)
								? <i className="order-select fas fa-check-square text-success"/>
								: <i className="order-select far fa-square"/>
							}
						</>
						}

						<div className="order-source">{orderInfo.source}</div>

						<div className="order-info">
							<span className="font-weight-bold">
								<i className="fas fa-fw fa-hashtag" />
								{orderInfo.id}
							</span>
							<span title={orderInfo.createdBy}>
								<i className="fas fa-fw fa-user" />
								{orderInfo.createdBy}
							</span>
							<span title={"Created: " + parseDateTimeFormat(orderInfo.createdAt)}>
								<i className="far fa-fw fa-clock" />
								Created: <span className="font-weight-bold">{parseDateTimeFormat(orderInfo.createdAt)}</span>
							</span>
						</div>

					</div>
					<div className="col-12 col-sm-7 col-md-5">

						<div className="order-title">

							<span className="order-title-name text-muted d-block">
								<span className="font-weight-bold">{orderInfo.contactName}</span>
								{' | '}
								{orderInfo.accountId ?
								<button onClick={() => accountAccessModal(orderInfo.accountId)}>
									Acct #: {orderInfo.accountNumber}
								</button>
								:
								<span>Acct #: {orderInfo.accountNumber}</span>
								}
							</span>

							{(orderInfo.serviceAddress || appointment) &&
							<span className="order-title-address text-muted d-block mb-1">
								{orderInfo.serviceAddress && <><i className="fas fa-map-marker-alt"/> {orderInfo.serviceAddress}</>}
								{appointment && <span>&nbsp; | <i className="fas fa-clock"/> {this.getAppointmentDateTime(appointment)} </span>}
							</span>
							}

							{orderInfo.serviceOrdersInfo.map((serviceOrder, index) => (
							<div className="order-title-service">
								<p className="d-block" key={index}>
									<i className={serviceOrder.icon} />&nbsp;{serviceOrder.description}
								</p>
								<span className="order-title-date">
									<TargetDateTime targetDateTime={serviceOrder.targetDateTime} />
								</span>
							</div>
							))}

						</div>

					</div>
					<div className="col-7 col-md-3">

						<div className="order-status">

							<div className="progress">
								{isCancelled
								?
								<div
									className="progress-bar bg-danger"
									role="progressbar"
									style={{ width: '100%' }}
									aria-valuenow="100"
									aria-valuemin="0"
									aria-valuemax="100"
								>
									{orderInfo.status}
								</div>
								:
								orderInfo.isStarted === true ?
								<div
									// className={orderInfo.hasServiceOrdersInErrorStatus === true
									// 	? 'progress-bar bg-danger bg-danger-with-pulse'
									//  : 'progress-bar bg-success bg-success-with-pulse'
									// }
									className={this.renderColorStatus(
										orderInfo.hasServiceOrdersInErrorStatus,
										orderInfo.progressPercentage
									)}
									role="progressbar"
									style={{ width: orderInfo.progressPercentage + '%' }}
									aria-valuenow={orderInfo.progressPercentage}
									aria-valuemin="0"
									aria-valuemax="100"
								>
									{orderInfo.status}
								</div>
								:
								<div
									className="progress-bar"
									role="progressbar"
									style={{ width: '100%' }}
									aria-valuenow="100"
									aria-valuemin="0"
									aria-valuemax="100"
								>
									<button
										onClick={() => this.handleExecute(orderInfo.id)}
										className="btn btn-outline-primary btn-order-start-wf"
										disabled={isExecuteButtonClicked || !orderInfo.canStart}
									>
										<i className="fas fa-play-circle" />&nbsp;Execute
									</button>
								</div>
								}
							</div>

							{isCancelled
							?
							<span className="text-muted">
								<i className="fas fa-fw fa-ban text-danger" /> 0% Complete
							</span>
							:
							orderInfo.isStarted === true
							?
							<span className="text-muted">
								<i
									className={
										orderInfo.hasServiceOrdersInErrorStatus === true ? (
											'fas fa-fw fa-exclamation-triangle text-danger'
										) : orderInfo.progressPercentage !== 100 ? (
											'fas fa-fw fa-spin fa-cog text-success'
										) : null
									}
								/>&nbsp;{orderInfo.progressPercentage !== 100 ? (
									orderInfo.progressPercentage + '%'
								) : (
									<i className="fas fa-fw fa-check text-success" />
								)}{' '}
								{!isEmpty(orderInfo.modifiedDateTime) && (
									<small>{moment(orderInfo.modifiedDateTime).format('MM/DD/YYYY @ h:mmA')}&nbsp;&nbsp;</small>
								)}
								{!isEmpty(orderInfo.modifiedBy) && (
									<><i className="fas fa-user"/>&nbsp;<small>{orderInfo.modifiedBy}</small></>
								)}
							</span>
                            :
							orderInfo.canStart
							?
							<span className="text-muted">
								<i className="fas fa-fw fa-spin fa-spinner"/>&nbsp;Not yet started
							</span>
                            :
							<span className="text-muted">
                                <i className="fas fa-fw fa-ban"/>&nbsp;Cannot start - missing configuration
							</span>
                            }

						</div>

					</div>
					<div className="col-5 col-md-1">

						<div className="order-controls">

							<button
								className={"btn btn-circle" + (appointment ? " active" : "")}
								disabled={isExecuteButtonClicked || isCancelled || isCompleted}
								onClick={() =>
									this.props.showModal('TECHNICIAN_APPOINTMENT_MODAL', {
										appointment_type: "ORDER",
										id: orderInfo.id,
										onCloseCallback: this.props.parentCallback
									})}
							>
								<i className="far fa-clock"/>
							</button>

							{hasCollapsedIcon && (
								<button
									onClick={this.handlerServiceExpand}
									className="btn btn-circle btn-order-collapse-toggle"
								>
									<i className={isServiceExpanded === true ? 'fas fa-angle-double-up' : 'fas fa-angle-double-down'} />
								</button>
							)}

						</div>

					</div>
				</div>

				{isServiceExpanded && hasCollapsedIcon &&
				<Services
					orderId={orderInfo.id}
					isCompleted={isCompleted}
					isCancelled={isCancelled}
					isExecuteButtonClicked={isExecuteButtonClicked}
					{...this.props}
				/>
				}

			</div>
		);
	}
}

Order.propTypes = {
	orderInfo: PropTypes.object.isRequired,
	hasCollapsedIcon: PropTypes.bool.isRequired
};

export default Order;
