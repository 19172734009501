import React, { Component } from 'react';
import Service from './Service';
import { connect } from 'react-redux';
import { getOrderById } from '../../../../../../../actions/accountDetailsActions/ordersAndQuotes.actions';
import { isEmpty } from '../../../../../../../utils/helpers';
import Loader from '../../../../../../../components/Loader';

class Services extends Component {

	state = {
		order: {},
		isLoaded: false,
		time: 10000,
	}

	componentDidMount() {
		this.getOrderData();
		// this.interval = setInterval(() => this.getOrderData(), this.state.time);
	}

	componentWillUnmount() {
		clearInterval(this.interval);
	}

	getOrderData = () => {
		const {orderId} = this.props;

		if (this.state.isLoaded === false) {
			this.setState(
				{
					isLoaded: true
				},
				() =>
					this.props.getOrderById(orderId).then((response) => {
						if (response.success) {
							this.setState({
								order: response.order,
							});
						}else{
							this.setState(({
								isLoaded: false
							}))
						}
					})
			);
		}
		else {
			this.props.getOrderById(orderId).then((response) => {
				if (response.success) {
					this.setState({
						order: response.order
					});
				}else {
					this.setState(({
						isLoaded: false
					}))
				}
			})
		}
	}

	render() {

		const {
			order,
			isLoaded,
		} = this.state;

		const {
			isStarted,
			isCompleted,
			isExecuteButtonClicked,
			showServiceUpdateRequestWizardHandler,
			parentCallback,
			canCancelServiceOrders,
		} = this.props;

		if (isEmpty(order) && isLoaded) {
			return <Loader />;
		}

		return (
			<div className="order-body collapse show">
				<div className="order-body-content">
					{order.serviceOrders && order.serviceOrders.map((service) =>
						<Service
							key={service.id}
							order={order}
							serviceInfo={service}
							isStarted={isStarted}
							isCompleted={isCompleted}
							isExecuteButtonClicked={isExecuteButtonClicked}
							canCancelServiceOrders={canCancelServiceOrders}
							cancelServiceOrder={this.props.cancelServiceOrder}
							showServiceUpdateRequestWizardHandler={showServiceUpdateRequestWizardHandler}
							parentCallback={parentCallback}
							history={this.props.history}
						/>
					)}
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {};
};

const mapDispatchToProps = {
	getOrderById,
};

export default connect(mapStateToProps, mapDispatchToProps)(Services);
