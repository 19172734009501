import React, { Component } from 'react'
import Select from 'react-select';
import PropTypes from 'prop-types';
import { groupStyles } from '../../utils/SelectStyles';

class GenericSelect extends Component {
    state = {
        selectedEntities: []
    }

    componentDidUpdate(prevProps){
        if(prevProps.defaultValues !== this.props.defaultValues){
            this.setState({
                selectedEntities: this.props.defaultValues
            })
        }
    }
 
    complexDataChange = (value) => {
        let { filters, type } = this.props;

        let oldFilters = {...filters};
		let selectValue = value.map((el) => el.value);
        oldFilters[type] = selectValue;
        
        this.props.updateFilter(oldFilters);
        this.setState({
            selectedEntities: value
        })
    }

    render() {
        let { options, isMulti, placeholder} = this.props,
            { selectedEntities } = this.state;
        return (
            <Select
                value={selectedEntities}
                onChange={this.complexDataChange}
                options={options}
                styles={groupStyles}
                placeholder={placeholder}
                isMulti={isMulti}
            />
        )
    }
}

GenericSelect.propTypes = {
    options: PropTypes.array.isRequired,
    isMulti: PropTypes.bool,
    placeholder: PropTypes.string,
    updateFilter: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired
};

export default GenericSelect;