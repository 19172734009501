import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { Formik, Form } from 'formik';
import {
	getNewOrder,
	createLoadingSelector,
	getAccountDetails,
	getCreateAccount,
	getUserPermission
} from '../../../selectors';

import { connect } from 'react-redux';
import {
	createNewOrder,
	getPlansAndBundles,
	getPlansDetails,
	emptyNewOrder,
	emptyPlanDetails,
	startServiceOrder,
	getMapLinkLocation,
	emptyMapLinkLocation
} from '../../../actions/accountDetailsActions/newOrder.actions';
import { hideAccountDetailsWizard } from '../../../actions/accountDetailsActions/accountDetails.actions';
import { showModal, hideModal } from '../../../actions/modal.actions';
import { getCountryStates } from "../../../actions/createAccount.actions";

import {isEmpty, generateId, applyOnEnum} from '../../../utils/helpers';
import { validationSchema } from './validation';

import WizardButtons from './WizardButtons';
import NavTabs from './NavTabs';
import { Addresses, BundlesAndPlans, Services, Products, Confirmation } from './Tabs';
class NewOrderWizard extends Component {
	state = {
		initialValues: {
			accountId: this.props.accountInfo.id,
			createdBy: this.props.userSummary.id,
			name: 'NEW_ORDER_' + generateId(),
			note: 'note',
			address: '',
			fullAddress: {
				address1: '',
				city: '',
				state: '',
				zip: ''
			},
			plans: [],
			source: 'CAMVIO-WEB',
			applyOn: Object.keys(applyOnEnum)[0],
			// type: 'NEW_SERVICE'
		},
		activeTab: 0,
		addressType: 'validateAddress',
		selectedAddress: '',
		selectedUnit: '',
		isSummaryFullWith: false,
		isSummaryCollapsed: false,
		collapsedMenuItem: 'planBundles',
		selectedItem: '',
		selectedType: '',
		isPlanAndBundlesExecuted: false,
		saveOrderInProgress: false,
	};

	componentDidMount() {
		const { changeNavBarType } = this.props;
		this.props.getCountryStates();

		// Change navbar type and color
		document.title = 'Service Request - camvio.cloud';
		changeNavBarType('lock', 'newOrder', 'Service Request');
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.newOrderInfo !== this.props.newOrderInfo && !isEmpty(this.props.newOrderInfo)) {
			this.setState((prevState) => ({
				activeTab: prevState.activeTab + 1
			}));
			this.props.hideModal();
		}

		//if user click on prev button and he is on active tab === 2 - clear redux plan details

		// if(prevState.activeTab !== this.state.activeTab && prevState.activeTab === 2){
		// 	this.props.emptyPlanDetails();
		// }
	}

	componentWillUnmount() {
		const {changeNavBarType, emptyNewOrder, hideAccountDetailsWizard, documentTitle, navbarTitle} = this.props;

		// Change navbar type and color
		document.title = documentTitle;
		changeNavBarType('account', 'accountDetails', navbarTitle);

		emptyNewOrder();
		hideAccountDetailsWizard();
	}

	renderTabContent = (formProps) => {
		const {
			contactAddresses,
			planBundles,
			plans,
			accountInfo,
			currentLocation,
			plansDetails,
			newOrderInfo,
			startServiceOrder,
			isOrderStartedData,
			tasksCountData,
			validatedAddress,
			loaderPlansAndBundles,
			loaderMapLinkLocation,
			userConfiguration,
			serviceOrderAddresses,
			values,
			history,
			countryStatesData,
			canEnterManualAddress,
			showModal
		} = this.props;
		const {
			addressType,
			activeTab,
			isSummaryFullWith,
			isSummaryCollapsed,
			selectedAddress,
			selectedUnit,
		} = this.state;

		let tabContent;
		switch (activeTab) {
			case 0:
				tabContent = (
					<Addresses
						contactAddresses={contactAddresses}
						onAddressHandleChange={this.onAddressHandleChange}
						serviceOrderAddresses={serviceOrderAddresses}
						validatedAddress={validatedAddress}
						onSubmitAddresses={this.onSubmitAddresses}
						addressType={addressType}
						onChangeAddressType={this.onChangeAddressType}
						loaderMapLinkLocation={loaderMapLinkLocation}
						selectedAddress={selectedAddress}
						selectedUnit={selectedUnit}
						userConfiguration={userConfiguration}
						countryStatesData={countryStatesData}
						canEnterManualAddress={canEnterManualAddress}
						showModal={showModal}
						{...formProps}
					/>
				);
				break;
			case 1:
				tabContent = (
					<BundlesAndPlans
						collapsedMenuItem={this.state.collapsedMenuItem}
						setCollapsedMenuItem={this.setCollapsedMenuItem}
						selectedItem={this.state.selectedItem}
						selectedType={this.state.selectedType}
						selectPlansItems={this.selectPlansItems}
						planBundles={planBundles}
						plans={plans}
						validatedAddress={validatedAddress}
						addressType={addressType}
						selectedAddress={selectedAddress}
						loaderPlansAndBundles={loaderPlansAndBundles}
						currentLocation={currentLocation}
						{...formProps}
					/>
				);
				break;
			case 2:
				tabContent = (
					<Services
						plansDetails={plansDetails}
						currentLocation={currentLocation}
						accountInfo={accountInfo}
						getPlansDetails={this.props.getPlansDetails}
						isSummaryFullWith={isSummaryFullWith}
						isSummaryCollapsed={isSummaryCollapsed}
						handleFullWithSummary={this.handleFullWithSummary}
						handleSummaryCollapsed={this.handleSummaryCollapsed}
						{...formProps}
					/>
				);
				break;
			case 3:
				tabContent = (
					<Products
						plansDetails={plansDetails}
						accountInfo={accountInfo}
						isSummaryFullWith={isSummaryFullWith}
						isSummaryCollapsed={isSummaryCollapsed}
						handleFullWithSummary={this.handleFullWithSummary}
						handleSummaryCollapsed={this.handleSummaryCollapsed}
						validatedAddress={validatedAddress}
						{...formProps}
					/>
				);
				break;
			case 4:
				var orderType = newOrderInfo.type;

				if (this.props.validatedAddress) {
					switch (this.props.validatedAddress.allowedServiceOrderType) {
						case 'NEW_SURVEY':
							orderType = 'survey';
							break;
						case 'NEW_SIGNUP':
							orderType = 'signup';
							break;
					}
				}

				tabContent = (
					<Confirmation
						newOrderInfo={{ ...newOrderInfo, type: orderType }}
						showNewOrderWizardHandler={this.props.showNewOrderWizardHandler}
						resetWizard={this.resetWizard}
						startServiceOrder={startServiceOrder}
						isOrderStartedData={isOrderStartedData}
						tasksCountData={tasksCountData}
						history={history}
						{...formProps}
					/>
				);
				break;
			default:
				tabContent = <Addresses />;
				break;
		}

		return tabContent;
	};

	setCollapsedMenuItem = (menuItem) => {
		this.setState({
			collapsedMenuItem: this.state.collapsedMenuItem === menuItem ? '' : menuItem
		});
	};

	selectPlansItems = (item, type) => {
		if (this.state.isPlanAndBundlesExecuted === true) {
			this.props.emptyPlanDetails();
		}
		this.setState((prevState) => ({
			selectedItem: this.state.selectedItem === item.id ? '' : item.id,
			selectedType: this.state.selectedItem === item.id ? '' : type,
			isPlanAndBundlesExecuted: prevState.isPlanAndBundlesExecuted === true && false
		}));
	};

	populateServiceAndLines = (values, actions) => {
		const { currentLocation, accountInfo, getPlansDetails } = this.props;

		values.plans.forEach((element) => {
			getPlansDetails(accountInfo.id, currentLocation.id, element.planId).then((response) => {
				let findIndex = values.plans.findIndex((x) => x.planId === response.id);
				let servicelines = [];

				for (let index = 0; index < response.requiredLines; index++) {
					let currentServiceLine = {};
					if (
						get(response, 'serviceModel.directorySystemNumType.defaultSource') &&
						response.serviceModel.directorySystemNumType.defaultSource === 'number_inventory'
					) {
						currentServiceLine = {
							features: [],
							// devices: [],
							number: '',
							serviceLineRelation: index === 0 ? 'Main' : 'Required',
							index: index
						};
					} else {
						currentServiceLine = {
							features: [],
							// devices: [],
							serviceLineRelation: index === 0 ? 'Main' : 'Required',
							index: index
						};
					}

					// ADD DEVICES TO THE LINES
					if (!isEmpty(response.serviceModel.defaultEquipment)) {
						response.serviceModel.defaultEquipment.deviceProfiles.forEach((element) => {
							if (isEmpty(element.possibleItems)) {
								return;
							}
							let currentDevice = {
								deviceGroupId: response.serviceModel.defaultEquipment.deviceGroupId,
								deviceProfileId: element.id,
								itemId: element.possibleItems[0].id,
								itemDescription: element.possibleItems[0].description,
								itemPrice: element.possibleItems[0].itemProperties[0].listPrice
							};
							currentServiceLine['devices'] = [];
							currentServiceLine['devices'].push(currentDevice);
						});
					}

					// ADD FEATURES TO THE LINES
					if (currentServiceLine.serviceLineRelation === 'Main') {
						if (!isEmpty(response.mainIncludedFeatures)) {
							response.mainIncludedFeatures.forEach((element) => {
								let feature = {
									...element
								};
								currentServiceLine.features.push(feature);
							});
						}
						if (!isEmpty(response.mainIncludedFeaturePackages)) {
							response.mainIncludedFeaturePackages.forEach((element) => {
								let feature = {
									featurePackageId: element.featurePackageId,
									featurePackageDescription: element.description,
									featurePackageNote: element.note,
									featurePackageName: element.name,
									...element.featurePackageOptions[0]
								};

								currentServiceLine.features.push(feature);
							});
						}
						if (!isEmpty(response.mainMandatoryFeatures)) {
							response.mainMandatoryFeatures.forEach((element) => {
								let feature = {
									...element
								};
								currentServiceLine.features.push(feature);
							});
						}
						if (!isEmpty(response.mainMandatoryFeaturePackages)) {
							response.mainMandatoryFeaturePackages.forEach((element) => {
								let feature = {
									featurePackageId: element.featurePackageId,
									featurePackageDescription: element.description,
									featurePackageNote: element.note,
									featurePackageName: element.name,
									...element.featurePackageOptions[0]
								};

								currentServiceLine.features.push(feature);
							});
						}
					} else if (currentServiceLine.serviceLineRelation === 'Required') {
						if (!isEmpty(response.requiredIncludedFeatures)) {
							response.requiredIncludedFeatures.forEach((element) => {
								let feature = {
									...element
								};
								currentServiceLine.features.push(feature);
							});
						}
						if (!isEmpty(response.requiredIncludedFeaturePackages)) {
							response.requiredIncludedFeaturePackages.forEach((element) => {
								let feature = {
									featurePackageId: element.featurePackageId,
									featurePackageDescription: element.description,
									featurePackageNote: element.note,
									featurePackageName: element.name,
									...element.featurePackageOptions[0]
								};

								currentServiceLine.features.push(feature);
							});
						}
						if (!isEmpty(response.requiredMandatoryFeatures)) {
							response.requiredMandatoryFeatures.forEach((element) => {
								let feature = {
									...element
								};
								currentServiceLine.features.push(feature);
							});
						}
						if (!isEmpty(response.requiredMandatoryFeaturePackages)) {
							response.requiredMandatoryFeaturePackages.forEach((element) => {
								let feature = {
									featurePackageId: element.featurePackageId,
									featurePackageDescription: element.description,
									featurePackageNote: element.note,
									featurePackageName: element.name,
									...element.featurePackageOptions[0]
								};

								currentServiceLine.features.push(feature);
							});
						}
					}
					servicelines.push(currentServiceLine);
				}

				actions.setFieldValue(`plans.${findIndex}.index`, findIndex);
				actions.setFieldValue(`plans.${findIndex}.servicelines`, servicelines);
				// actions.setFieldValue(`plans.${findIndex}.defaultSource`, response.serviceModel.directorySystemNumType.defaultSource)
				actions.setFieldValue(`plans.${findIndex}.requiredLines`, response.requiredLines);
				actions.setFieldValue(`plans.${findIndex}.additionalLines`, response.additionalLines);
			});
		});

		this.setState((prevState) => ({
			activeTab: prevState.activeTab + 1,
			isPlanAndBundlesExecuted: true
		}));
	};

	// ADDRESSES TAB <== START ==>

	onChangeAddressType = (addressType) => {
		if (addressType !== this.state.addressType) {
			if (addressType === 'noAddress') {
			} else {
				this.props.emptyMapLinkLocation();
				this.setState({
					addressType: addressType,
					selectedAddress: '',
					selectedUnit: ''
				});
			}
		}
	};


	onAddressHandleChange = (address, unit, setFieldValue) => {
		if (address === 'error') {
			this.setState({
				selectedAddress: '',
				selectedUnit: ''
			});
			this.props.emptyMapLinkLocation();
		} else {
			if (!isEmpty(unit)) {
				address.address2 = unit;
			}
			const addressRequest = {
				fullAddress: address
			};

			this.setState({
				selectedAddress: address.fullAddress,
				selectedUnit: unit
			});
			setFieldValue('fullAddress', address);

			if("manualAddress" != this.state.addressType) {
				this.props.getMapLinkLocation(this.props.currentLocation.id, addressRequest);
			}
		}
	};

	handleFullWithSummary = () => {
		this.setState((prevState) => ({
			isSummaryFullWith: !prevState.isSummaryFullWith
		}));
	};

	handleSummaryCollapsed = () => {
		this.setState((prevState) => ({
			isSummaryCollapsed: !prevState.isSummaryCollapsed
		}));
	};

	onSubmitAddresses = async (setFieldValue) => {

		this.setState({
			activeTab: 1
		});
	};

	resetWizard = (resetForm, setTouched) => {
		this.props.emptyNewOrder();
		resetForm(this.state.initialValues);
		setTouched({});

		this.setState({
			activeTab: 0,
			addressType: 'validateAddress',
			selectedAddress: '',
			selectedUnit: '',
			isSummaryFullWith: false,
			isSummaryCollapsed: false,
			collapsedMenuItem: 'planBundles',
			selectedItem: '',
			selectedType: '',
			isPlanAndBundlesExecuted: false
		});
	};

	prevStep = () => {
		// this.props.emptyMapLinkLocation();
		this.setState((prevState) => ({
			activeTab: prevState.activeTab - 1
		}));
	};

	next = (values, actions) => {
		const { activeTab, addressType } = this.state;
		if (activeTab === 0) {
			if(addressType == "manualAddress"){
				const addressRequest = {
					fullAddress: values.fullAddress
				};
				this.props.getMapLinkLocation(this.props.currentLocation.id, addressRequest);
			}
			actions.setSubmitting(false);
			this.onSubmitAddresses(actions.setFieldValue);
		} else if (activeTab === 1) {
			actions.setSubmitting(false);
			if (this.state.isPlanAndBundlesExecuted === true) {
				this.setState((prevState) => ({
					activeTab: prevState.activeTab + 1
				}));
			} else {
				this.populateServiceAndLines(values, actions);
			}
			// this.populateServiceAndLines(values, actions);
		} else if (activeTab === 2) {
			actions.setSubmitting(false);
			this.setState((prevState) => ({
				activeTab: prevState.activeTab + 1
			}));
		} else if (activeTab === 3) {
			values['address'] = this.props.validatedAddress.addressSearchFormated;
			values['type'] = this.props.validatedAddress.allowedServiceOrderType;
			let addressZoneValuesToSave = [];
			if(this.props.validatedAddress.addressValuesToSave){
				Object.keys(this.props.validatedAddress.addressValuesToSave).map(key => {
					addressZoneValuesToSave.push({
						key: key,
						value: this.props.validatedAddress.addressValuesToSave[key]
					})
				})
			}
			if(this.props.validatedAddress.zoneValuesToSave){
				Object.keys(this.props.validatedAddress.zoneValuesToSave).map(key => {
					addressZoneValuesToSave.push({
						key: key,
						value: this.props.validatedAddress.zoneValuesToSave[key]
					})
				})
			}
			values['addressZoneValuesToSave'] = addressZoneValuesToSave;
			this.props.createNewOrder(values);
			this.setState((prevState) => ({
				saveOrderInProgress: true
			}));
		}
	};

	// ADDRESSES <== END ==>

	render() {
		const { activeTab, saveOrderInProgress, errors, touched, formProps, addressType } = this.state;
		const { showNewOrderWizardHandler, accountStatus, validatedAddress, showModal, loaderMapLinkLocation } = this.props;

		return (
			<div className="cmv-wizard">
				<div className="wizard-container">
					<div className="container">
						<NavTabs activeTab={activeTab} />
						<Formik
							initialValues={this.state.initialValues}
							validationSchema={validationSchema(activeTab, addressType)}
							onSubmit={this.next}
							render={(props) => (
								<Form onSubmit={props.handleSubmit} className="cmv-form" autoComplete="off">
									<div className="tab-content wizard-content">{this.renderTabContent(props)}</div>
									<WizardButtons
										values={props.values}
										activeTab={activeTab}
										accountStatus={accountStatus}
										showNewOrderWizardHandler={showNewOrderWizardHandler}
										validatedAddress={validatedAddress}
										addressType={addressType}
										showModal={showModal}
										saveOrderInProgress={saveOrderInProgress}
										loaderMapLinkLocation={loaderMapLinkLocation}
										prevStep={this.prevStep}
										errors={props.errors}
									/>
								</Form>
							)}
						/>
					</div>
				</div>
			</div>
		);
	}
}

NewOrderWizard.propTypes = {
	currentLocation: PropTypes.object,
	changeNavBarType: PropTypes.func,
	planBundles: PropTypes.array,
	plans: PropTypes.array
};
const getLoaderPlansAndBundles = createLoadingSelector([ 'PLANS_AND_BUNDLES' ]);
const getLoaderMapLinkLocation = createLoadingSelector([ 'MAP_LINK_LOCATION' ]);

const mapStateToProps = (state) => {
	const planBundles = getNewOrder(state).planBundles,
		plans = getNewOrder(state).plans,
		plansDetails = getNewOrder(state).plansDetails,
		newOrderInfo = getNewOrder(state).newOrderInfo,
		isOrderStartedData = getNewOrder(state).isOrderStarted,
		tasksCountData = getNewOrder(state).tasksCount,
		validatedAddress = getNewOrder(state).validatedAddress,
		countryStatesData = getCreateAccount(state).countryStates,
		loaderPlansAndBundles = getLoaderPlansAndBundles(state),
		loaderMapLinkLocation = getLoaderMapLinkLocation(state),
		canEnterManualAddress = getUserPermission(state, 'ADMN', 'ADMN_MANUAL_ADDRESS_ENTRY');

	return {
		planBundles,
		plans,
		plansDetails,
		newOrderInfo,
		isOrderStartedData,
		tasksCountData,
		validatedAddress,
		countryStatesData,
		loaderPlansAndBundles,
		loaderMapLinkLocation,
		canEnterManualAddress
	};
};

const mapDispatchToProps = {
	createNewOrder,
	getPlansAndBundles,
	getPlansDetails,
	emptyNewOrder,
	emptyPlanDetails,
	showModal,
	hideModal,
	startServiceOrder,
	hideAccountDetailsWizard,
	getMapLinkLocation,
	emptyMapLinkLocation,
	getCountryStates
};

export default connect(mapStateToProps, mapDispatchToProps)(NewOrderWizard);
