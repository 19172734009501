import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {hideModal} from '../../../actions/modal.actions';
import {
    getUser,
    getModalData,
    getAdmin,
    createLoadingSelector,
    getCreateAccount
} from '../../../selectors';
import {Formik} from 'formik';
import {
    getSystemLocations,
    getPartners
} from '../../../actions/admin.actions';
import * as Yup from 'yup';
import isEmpty from "../../../utils/helpers";
import FormSelect from "../../../components/UI/FormSelect";
import {getAccountTermDetails, getAccountTypeDetails, getAccountTypes} from "../../../actions/createAccount.actions";
import {updateBillingSettings} from "../../../actions/accountDetailsActions/billing.actions";
import { getAccountInformation } from "../../../actions/accountDetailsActions/accountInformation.actions";
import {get} from 'lodash';

const PARTNERS_SEARCH_LIMIT = 10;

const PARTNERS_SEARCH_INFO = "Maximum of " + PARTNERS_SEARCH_LIMIT + " results are given as options and it searches by the following criteria on typing:\n" +
    "* Empty - no filtering is applied.\n" +
    "* ID (integer) - searching by partner id.\n" +
    "* Text - searching by a partial match in name and description.\n";

const asOptions = (source, mapper) => {
    if (!Array.isArray(source)) {
        return [];
    }

    return source.map(mapper);
}

const locationsAsOptions = (locations) => asOptions(locations, location => {
    return {
        label: location.description,
        value: location.id
    }
});

const partnersAsOptions = (partners) => asOptions(partners, partner => {
    return {
        label: partner.name,
        value: partner.id,
    };
});

const typesAsOptions = (types) => asOptions(types, type => {
    return {
        label: type.description,
        value: type.id,
    };
});

const billCyclesAsOptions = billCycles => {
    if (isEmpty(billCycles)) {
        return [];
    }

    return billCycles.split(',')
        .map(billCycle => billCycle.replace("*", ""))
        .map(billCycle => billCycle.trim())
        .map(billCycle => {
           return {
               label: billCycle,
               value: billCycle
           }
    });
}

const termsAsOptions = (terms) => asOptions(terms, term => {
    return {
        label: term.description,
        value: term.id,
    };
});

const tiersAsOptions = (tiers) => asOptions(tiers, tier => {
    return {
        label: tier.description,
        value: tier.id,
    };
});

const findPartnerById = (partners, partnerId) => {
    return partners && partners.find(partner => partner.id === partnerId);
}

class AddSystemUserModal extends Component {

    state = {
        alertMessage: '',
        partnerSearchTem: null,
        belongsToPartner: false,
        selectedPartner: null,
        partnerOptions: [],
        locationOptions: [],
    };

    billingSettingsSchema = () => {
        let validationSchema = {
            accountId: Yup.number()
                .typeError("Required")
                .min(1, "Invalid accountId"),
            partnerId: Yup.number().nullable()
                .typeError("Invalid partner")
                .min(1, "Invalid partner"),
            revenueLocationId: Yup.number().required("Required")
                .typeError("Required")
                .min(1, "Invalid revenue location"),
            typeId: Yup.number()
                .typeError("Required")
                .min(1, "Invalid type"),
            billCycle: Yup.number()
                .typeError("Required")
                .min(1, "Invalid bill cycle"),
            termsId: Yup.number()
                .typeError("Required")
                .min(1, "Invalid terms"),
            accountTierId: Yup.number().nullable()
                .typeError("Invalid risk level")
                .min(1, "Invalid risk level"),
        };

        return Yup.object().shape(validationSchema);
    }

    componentDidMount() {
        const {accountBillingSettings} = this.props.modalProps;
        const {partner, type, terms} = accountBillingSettings;

        if (partner) {
            this.setState({selectedPartner: partner, belongsToPartner: true}, () => this.reloadPartners());
        } else {
            this.reloadLocations();
        }

        this.props.getAccountTypes().then(() => {
            if (type) {
                this.props.getAccountTypeDetails(type.id).then(() => {
                    if (terms) {
                        this.props.getAccountTermDetails(terms.id);
                    }
                })
            }
        })
    }

    reloadPartners = () => {
        this.props.getPartners(PARTNERS_SEARCH_LIMIT, this.state.partnerSearchTem).then(() => {
            const {selectedPartner} = this.state;
            const partnerResults = [...this.props.partners];

            if (selectedPartner) {
                const partner = partnerResults.find(partner => partner.id === selectedPartner.id);
                if (!partner) {
                    partnerResults.push(selectedPartner);
                }
            }

            this.setState({partnerOptions: partnerResults}, () => this.reloadLocations());
        });
    }

    handlePartnerSearchChange = (value) => {
        if (this.state.partnerSearchTem === value) {
            return;
        }

        this.setState({partnerSearchTem: value}, () => {
            if (this.searchChangeTimeout) {
                clearTimeout(this.searchChangeTimeout);
            }

            this.searchChangeTimeout = setTimeout(() => {
                this.reloadPartners();
            }, 300);
        });
    };

    handleOnPartnerChange = setFieldValue => (options, newValue) => {
        const { partnerOptions } = this.state;

        setFieldValue("revenueLocationId", null);

        const selectedPartner = findPartnerById(partnerOptions, newValue);
        this.setState({selectedPartner}, () => this.reloadLocations());
    }

    handleOnBelongsToPartnerChange = (belongsToPartner, setFieldValue) => {
        setFieldValue("partnerId", null);
        setFieldValue("revenueLocationId", null);

        this.setState({belongsToPartner: belongsToPartner, selectedPartner: null}, () => {
            if (belongsToPartner) {
                this.reloadPartners();
            } else {
                this.reloadLocations();
            }
        });
    }

    reloadLocations = () => {
        const { systemLocations, getSystemLocations } = this.props;
        const { partnerOptions, selectedPartner, belongsToPartner } = this.state;

        if (belongsToPartner) {
            if (selectedPartner) {
                const partner = findPartnerById(partnerOptions, selectedPartner.id);
                if (partner) {
                    this.setState({locationOptions: partner.locations})
                } else {
                    this.setState({locationOptions: []});
                }
            } else {
                this.setState({locationOptions: []});
            }
        } else if (!isEmpty(systemLocations)) {
            this.setState({locationOptions: systemLocations});
        } else {
            getSystemLocations().then(() => this.setState({locationOptions: this.props.systemLocations}));
        }
    }

    handleOnTypeChange = setFieldValue => (options, newValue) => {
        setFieldValue('billCycle', null);
        setFieldValue('termsId', null);
        setFieldValue('accountTierId', null);

        this.props.getAccountTypeDetails(newValue);
    }

    handleOnTermChange = setFieldValue => (options, newValue) => {
        setFieldValue('accountTierId', null);

        this.props.getAccountTermDetails(newValue);
    }

    formSubmit = (values, actions) => {
        const submitData = {...values};

        // prepare partner
        if (!submitData.belongsToPartner) {
            submitData.partnerId = null;
        }
        delete submitData.belongsToPartner;

        this.setState({alertMessage: ''}, () => {
            const method = this.props.modalProps.type === 'create' ? this.props.addSystemUser : this.props.updateSystemUser;

            this.props.updateBillingSettings(submitData.accountId, submitData).then((response) => {
                if (!response.data) {
                    actions.setSubmitting(false);
                    this.setState({alertMessage: 'Unknown error. Please try again later'});
                    return;
                }

                if (!response.data.success) {
                    actions.setSubmitting(false);
                    this.setState({alertMessage: response.data.error.message});
                    return;
                }

                this.props.getAccountInformation(submitData.accountId);

                this.props.hideModal();
                if (this.props.modalProps.onCloseCallback) {
                    this.props.modalProps.onCloseCallback();
                }
            });
        });
    }

    scrollDropdownIntoView = (e) => {
        const elementClicked = e.target.nodeName;
        const boundingElement = e.currentTarget;
        const modalBody = document.getElementsByClassName('modal-body')[0];

        if (elementClicked !== 'LABEL') {

            setTimeout(function () {

                // Scroll down if the bottom is hidden...
                if (boundingElement.getBoundingClientRect().bottom > modalBody.getBoundingClientRect().bottom) {

                    // ... and the top won't end up hidden by scrolling down
                    if (boundingElement.getBoundingClientRect().height < modalBody.getBoundingClientRect().height) {

                        // Scroll down till bottom of element reaches bottom of screen
                        boundingElement.scrollIntoView({block: "end"});
                    }
                }
            }, 200);
        }
    }

    render() {
        const { alertMessage, partnerOptions, locationOptions } = this.state;
        const {
            modalProps,
            partnersLoader,
            accountTypes, accountTypesLoader,
            billCycles, accountBillCyclesLoader,
            accountTerms, accountTermsLoader,
            accountTiers, accountTiersLoader,
            systemLocationsLoader
        } = this.props;

        const {accountBillingSettings} = modalProps;

        return (
            <Fragment>
                <div className="modal" style={{display: 'block'}} tabIndex="-1" role="dialog">

                    <Formik
                        initialValues={{
                            accountId: get(accountBillingSettings, 'accountId'),
                            belongsToPartner: get(accountBillingSettings, 'partner') && true || false,
                            partnerId: get(accountBillingSettings, 'partner.id'),
                            revenueLocationId: get(accountBillingSettings, 'revenueLocation.id'),
                            typeId: get(accountBillingSettings, 'type.id'),
                            billCycle: get(accountBillingSettings, 'billCycle.billCycle') + "",
                            termsId: get(accountBillingSettings, 'terms.id'),
                            accountTierId: get(accountBillingSettings, 'accountTier.id'),
                        }}
                        validationSchema={this.billingSettingsSchema()}
                        onSubmit={this.formSubmit}
                        render={({handleChange, handleSubmit, handleBlur, values, errors, touched, isSubmitting, setFieldValue}) => (
                            <form onSubmit={handleSubmit}>
                                <div className="modal-dialog">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title">
                                                EDIT BILLING SETTINGS
                                            </h5>
                                            <button onClick={this.props.hideModal} type="button" className="close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div className="modal-body">
                                            <div className="form-horizontal">

                                                {!isEmpty(alertMessage) && (
                                                    <div className="alert alert-inline alert-danger alert-dismissible">
                                                        <p className="mb-0">{alertMessage}</p>
                                                    </div>
                                                )}

                                                <div className="form-group">
                                                    <span className="h-check">
                                                        <label>Belongs to Partner&nbsp;</label>
                                                        <div className="form-check checkbox-slider checkbox-slider--b-flat">
                                                            <label>
                                                                <input
                                                                    name="belongsToPartner"
                                                                    type="checkbox"
                                                                    onBlur={handleBlur}
                                                                    onChange={event => {
                                                                        handleChange(event);
                                                                        this.handleOnBelongsToPartnerChange(event.target.checked, setFieldValue);
                                                                    }}
                                                                    checked={values.belongsToPartner}
                                                                />
                                                                <span>&nbsp;</span>
                                                            </label>
                                                        </div>
                                                    </span>
                                                </div>

                                                {values.belongsToPartner &&
                                                    <div className="form-group position-relative" >
                                                        <div onClick={this.scrollDropdownIntoView}>
                                                            <FormSelect
                                                            title="Partner"
                                                            // info={PARTNERS_SEARCH_INFO}
                                                            fieldName="partnerId"
                                                            setFieldValue={setFieldValue}
                                                            options={partnersAsOptions(partnerOptions)}
                                                            value={values.partnerId}
                                                            onBlur={handleBlur}
                                                            errors={errors}
                                                            touched={touched}
                                                            isLoading={partnersLoader}
                                                            onInputChange={this.handlePartnerSearchChange}
                                                            onPostChange={this.handleOnPartnerChange(setFieldValue)}
                                                            />
                                                        </div>
                                                    </div>
                                                }

                                                <div className="form-group position-relative" >
                                                    <div onClick={this.scrollDropdownIntoView}>
                                                        <FormSelect
                                                            title="Type"
                                                            fieldName="typeId"
                                                            setFieldValue={setFieldValue}
                                                            options={typesAsOptions(accountTypes)}
                                                            value={values.typeId}
                                                            onBlur={handleBlur}
                                                            errors={errors}
                                                            touched={touched}
                                                            isLoading={accountTypesLoader}
                                                            onPostChange={this.handleOnTypeChange(setFieldValue)}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="form-group position-relative" >
                                                    <div onClick={this.scrollDropdownIntoView}>
                                                        <FormSelect
                                                            title="Bill Cycle"
                                                            fieldName="billCycle"
                                                            setFieldValue={setFieldValue}
                                                            options={billCyclesAsOptions(billCycles)}
                                                            value={values.billCycle}
                                                            onBlur={handleBlur}
                                                            errors={errors}
                                                            touched={touched}
                                                            isLoading={accountBillCyclesLoader}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="form-group position-relative" >
                                                    <div onClick={this.scrollDropdownIntoView}>
                                                        <FormSelect
                                                            title="Terms"
                                                            fieldName="termsId"
                                                            setFieldValue={setFieldValue}
                                                            options={termsAsOptions(accountTerms)}
                                                            value={values.termsId}
                                                            onBlur={handleBlur}
                                                            errors={errors}
                                                            touched={touched}
                                                            isLoading={accountTermsLoader}
                                                            onPostChange={this.handleOnTermChange(setFieldValue)}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="form-group position-relative" >
                                                    <div onClick={this.scrollDropdownIntoView}>
                                                        <FormSelect
                                                            title="Risk Level"
                                                            fieldName="accountTierId"
                                                            setFieldValue={setFieldValue}
                                                            options={tiersAsOptions(accountTiers)}
                                                            value={values.accountTierId}
                                                            onBlur={handleBlur}
                                                            errors={errors}
                                                            touched={touched}
                                                            isLoading={accountTiersLoader}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="form-group">
                                                    <div onClick={this.scrollDropdownIntoView}>
                                                        <FormSelect
                                                            title="Revenue Location"
                                                            fieldName="revenueLocationId"
                                                            options={locationsAsOptions(locationOptions)}
                                                            setFieldValue={setFieldValue}
                                                            value={values.revenueLocationId}
                                                            onBlur={handleBlur}
                                                            errors={errors}
                                                            touched={touched}
                                                            isLoading={systemLocationsLoader}
                                                        />
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="modal-footer">
                                            <button onClick={this.props.hideModal} className="btn" type="button">
                                                CANCEL
                                            </button>
                                            <button disabled={isSubmitting} type="submit" className="btn btn-primary">
                                                EDIT ACCOUNT
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        )}
                    />
                </div>
                <div className="modal-backdrop show" tabIndex="1"/>
            </Fragment>
        );
    }
}


const partnerLoadingSelector = createLoadingSelector([ 'GET_PARTNERS' ]);
const systemLocationLoadingSelector = createLoadingSelector([ 'GET_PARTNERS', 'GET_SYSTEM_LOCATIONS' ]);
const accountTypesLoaderSelector = createLoadingSelector(['ACCOUNT_TYPES']);
const accountBillCyclesLoaderSelector = createLoadingSelector(['ACCOUNT_TYPES', 'ACCOUNT_TYPE_DETAILS']);
const accountTermsLoaderSelector = createLoadingSelector(['ACCOUNT_TYPES', 'ACCOUNT_TYPE_DETAILS']);
const accountTiersLoaderSelector = createLoadingSelector(['ACCOUNT_TYPES', 'ACCOUNT_TYPE_DETAILS', 'ACCOUNT_TERM_DETAILS']);

const mapStateToProps = (state) => {
    const systemLocations = getAdmin(state).systemLocations,
        systemLocationsLoader = systemLocationLoadingSelector(state),
        modalProps = getModalData(state).modalProps,
        partners = getAdmin(state).partners,
        partnersLoader = partnerLoadingSelector(state),
        accountTypes = getCreateAccount(state).accountTypes,
        accountTypesLoader = accountTypesLoaderSelector(state),
        accountTerms = getCreateAccount(state).accountTerms,
        accountTermsLoader = accountTermsLoaderSelector(state),
        billCycles = getCreateAccount(state).billCycles,
        accountBillCyclesLoader = accountBillCyclesLoaderSelector(state),
        accountTiers = getCreateAccount(state).accountTiers,
        accountTiersLoader = accountTiersLoaderSelector(state);

    return {
        modalProps,
        systemLocations,
        systemLocationsLoader,
        partners,
        partnersLoader,
        accountTypes,
        accountTypesLoader,
        billCycles,
        accountBillCyclesLoader,
        accountTerms,
        accountTermsLoader,
        accountTiers,
        accountTiersLoader,
    };
};

const mapDispatchToProps = {
    getSystemLocations,
    hideModal,
    getPartners,
    getAccountTypes,
    getAccountTypeDetails,
    getAccountTermDetails,
    updateBillingSettings,
    getAccountInformation
};

export default connect(mapStateToProps, mapDispatchToProps)(AddSystemUserModal);

