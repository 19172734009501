import React, { Component } from 'react';
import PropTypes from 'prop-types';
import NavTabs from './NavTabs';
import { Notes, Security, Notifications, Administration, Documents } from './Tabs';
import isEmpty from "../../../../utils/helpers";

export class RightPanel extends Component {

	state = {
		 activeTab: 'notes',
		// activeTab: 'security',
		// activeTab: 'notifications',
		// activeTab: 'administration',
		// activeTab: 'documents',
	}

	componentDidUpdate(prevProps, prevState, snapshot) {

		if (prevProps.switchTabInOtherPanel !== this.props.switchTabInOtherPanel && !isEmpty(this.props.switchTabInOtherPanel)) {
			this.changeTabHandler(this.props.switchTabInOtherPanel);
			this.props.handleSwitchTabInOtherPanel('');
		}
	}

	changeTabHandler = (tab) => {

		this.setState({
			activeTab: tab
		});
	}

	renderTabContent = (activeTab,userPermission) => {
		let tabContent;

		switch (activeTab) {
			case 'notes':
				tabContent = <Notes accountId={this.props.accountInfo.id} />;
				break;
			case 'security':
				tabContent = <Security userPermission={userPermission}/>;
				break;
			case 'notifications':
				tabContent = <Notifications accountId={this.props.accountInfo.id}/>;
				break;
			case 'administration':
				tabContent = <Administration accountId={this.props.accountInfo.id} userPermission={userPermission}/>;
				break;
			case 'documents':
				tabContent = <Documents accountId={this.props.accountInfo.id}/>;
				break;
			default:
				tabContent = '';
		}

		return tabContent;
	}

	render() {
		const { activeTab } = this.state;
		const { handleExpandPanel, expandedPanel, userPermission } = this.props;

		return (
			<div id="rightPanel" className={expandedPanel === '' ? ('col-xl-4') : expandedPanel === 'rightPanel' ? ('col') : ('d-none')}>
				<div className="panel-right">

					<NavTabs
						activeTab={activeTab}
						changeTabHandler={this.changeTabHandler}
						handleExpandPanel={handleExpandPanel}
						userPermission={userPermission}
					/>

					<div className="tab-content">
						{this.renderTabContent(activeTab,userPermission)}
					</div>

				</div>
			</div>
		);
	}
}

RightPanel.propTypes = {
	accountInfo: PropTypes.object
};

export default RightPanel;
