import React, { Component } from 'react';
import TaskForm from './TaskForm';
import Loader from '../../components/Loader';
import AlertBox from '../../components/UI/AlertBox';
import { connect } from 'react-redux';
import {
	getTaskAtrributes,
	emptyTaskAtrributes,
	setUserTask,
	getTaskDetailsAccountInfo,
	emptyTaskDetailsAccountInfo,
	getTaskDetailsServiceOrderInfo,
	emptyTaskDetailsServiceOrderInfo,
	emptyServiceOrderTasks
} from '../../actions/taskDetails.actions';
import {getTaskDetails, createLoadingSelector, getUser} from '../../selectors/index';
import { isEmpty } from '../../utils/helpers';
import AccountInfoBox from '../../components/UI/AccountInfoBox';
import TroubleTicketInfoBox from '../../components/UI/TroubleTicketInfoBox';
import ServiceOrder from '../Dashboard/Content/Orders/ServiceOrder';

class TaskDetails extends Component {

	state = {
		tasksIsOver: false,
		isAlertBoxShowing: true
	};

	componentDidMount() {

		// Change navbar type and color
		document.title = 'Task Details - camvio.cloud';
		this.props.changeNavBarType('tasks', 'taskDetails', 'Task Details');

		const taskId = Number(this.props.match.params.id);
		this.props.getTaskAtrributes(taskId);
	}

	componentDidUpdate(prevProps) {
		if (prevProps.taskAtrributesData !== this.props.taskAtrributesData) {
			const { taskAtrributesData } = this.props;

			if (taskAtrributesData.serviceOrderInfo) {
				if (taskAtrributesData.serviceOrderInfo.accountId) {
					this.props.getTaskDetailsAccountInfo(taskAtrributesData.serviceOrderInfo.accountId);
				}

				if (taskAtrributesData.serviceOrderInfo.serviceOrderId) {
					this.props.getTaskDetailsServiceOrderInfo(taskAtrributesData.serviceOrderInfo.serviceOrderId,
						this.props.user.currentLocation.id);
				}
			}

			if (taskAtrributesData.accountDetails) {
				if (taskAtrributesData.accountDetails.accountId) {
					this.props.getTaskDetailsAccountInfo(taskAtrributesData.accountDetails.accountId);
				}
			}
		}

		if (prevProps.serviceOrderTasks !== this.props.serviceOrderTasks) {
			if (!isEmpty(this.props.serviceOrderTasks)) {
				let firstOrderTaskId = this.props.serviceOrderTasks[0].id;
				this.props.getTaskAtrributes(firstOrderTaskId);
			} else {
				if (this.props.taskAtrributesData.serviceOrderInfo) {
					this.props.getTaskDetailsServiceOrderInfo(
						this.props.taskAtrributesData.serviceOrderInfo.serviceOrderId
					);
				}
				this.setState({
					tasksIsOver: true
				});
				this.props.emptyTaskAtrributes();
			}
		}
	}

	componentWillUnmount() {
		this.props.emptyTaskDetailsAccountInfo();
		this.props.emptyTaskDetailsServiceOrderInfo();
		this.props.emptyServiceOrderTasks()
	}

	handleShowAlert = () => {
		this.setState({
			isAlertBoxShowing: false
		});
	};

	handleServiceInfo = (serviceInfo) => {
		if(this.props.accountInfo.serviceOrderAddressesList &&
			this.props.accountInfo.serviceOrderAddressesList.length > 0) {
			let serviceOrderAddress = this.props.accountInfo.serviceOrderAddressesList[0];
			serviceInfo["address1"] = serviceOrderAddress.address1;
			serviceInfo["address2"] = serviceOrderAddress.address2;
			serviceInfo["address3"] = serviceOrderAddress.address3;
			serviceInfo["city"] = serviceOrderAddress.city;
			serviceInfo["latitude"] = serviceOrderAddress.latitude;
			serviceInfo["longitude"] = serviceOrderAddress.longitude;
			serviceInfo["mappingAddressId"] = serviceOrderAddress.mappingAddressId;
			serviceInfo["mappingAreaId"] = serviceOrderAddress.mappingAreaId;
			serviceInfo["mappingRefArea1"] = serviceOrderAddress.mappingRefArea1;
			serviceInfo["mappingNote"] = serviceOrderAddress.mappingNote;
			serviceInfo["state"] = serviceOrderAddress.state;
			serviceInfo["zipcode"] = serviceOrderAddress.zipcode;
		}
		return serviceInfo;
	};

	render() {
		const { isAlertBoxShowing } = this.state;
		const { taskAtrributesData, taskLoader, accountInfo, serviceorderInfo } = this.props;

		if(isEmpty(accountInfo)){
			return <Loader />
		}

		return (
			<div className="cmv-container-client-selected cmv-container-client-selected-modern cmv-container-client-selected-task-details">

				{isAlertBoxShowing &&
				accountInfo.alert &&
				accountInfo.type !== 'NONE' &&
				accountInfo.alert.message && (
				<AlertBox
					handleShowAlert={this.handleShowAlert}
					message={accountInfo.alert.message}
				/>
				)}

				<AccountInfoBox
					accountInfo={accountInfo}
					hideCardsContainer={false}
					hideCardsTaskDetails={true}
				/>
				
				{taskAtrributesData.troubleTicketDetails &&
				<TroubleTicketInfoBox
					troubleTicketInfo={taskAtrributesData.troubleTicketDetails}
					hideCardsContainer={false}
					hideCardsTaskDetails={true}
				/>
				}

				{!isEmpty(serviceorderInfo) && (
				<ServiceOrder
					serviceInfo={this.handleServiceInfo(serviceorderInfo)}
					serviceOrderType="taskDetails"
				/>
				)}

				<TaskForm
					formAtrributes={taskAtrributesData}
					emptyTaskAtrributes={this.props.emptyTaskAtrributes}
					taskId={this.props.match.params.id}
					serviceOrderId={taskAtrributesData.serviceOrderInfo && taskAtrributesData.serviceOrderInfo.serviceOrderId}
					setUserTask={this.props.setUserTask}
					tasksIsOver={this.state.tasksIsOver}
					processInstanceId={taskAtrributesData.processInstanceId}
					{...this.props}
				/>

			</div>
		);
	}
}

const taskAtrributesLoader = createLoadingSelector([ 'SET_USER_TASK_ATRRIBUTES' ]);

const mapStateToProps = (state) => {
	const taskAtrributesData = getTaskDetails(state).taskAtrributes,
		serviceOrderTasks = getTaskDetails(state).serviceOrderTasks,
		taskLoader = taskAtrributesLoader(state),
		accountInfo = getTaskDetails(state).accountInfo,
		serviceorderInfo = getTaskDetails(state).serviceorderInfo,
		user = getUser(state);

	return {
		taskAtrributesData,
		taskLoader,
		serviceOrderTasks,
		accountInfo,
		serviceorderInfo,
		user
	};
};

const mapDispatchToProps = {
	getTaskAtrributes,
	getTaskDetailsAccountInfo,
	getTaskDetailsServiceOrderInfo,
	emptyTaskAtrributes,
	emptyTaskDetailsAccountInfo,
	emptyTaskDetailsServiceOrderInfo,
	setUserTask,
	emptyServiceOrderTasks
};

export default connect(mapStateToProps, mapDispatchToProps)(TaskDetails);
