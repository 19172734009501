import React, { Component } from 'react';
import MandatoryFeature from './Options/MandatoryFeature';

class MandatoryFeatures extends Component {
	render() {
		const { planFeature, accountInitialStatus } = this.props;
		return (
			<div className="plan-section">
				<h5>Mandatory Features</h5>
				<div className="package-wrapper" data-package-wrapper-id="pl001-ifeat001">
					{planFeature.map((feature, index) => <MandatoryFeature feature={feature} key={index} accountInitialStatus={accountInitialStatus}/>)}
				</div>
			</div>
		);
	}
}

export default MandatoryFeatures;
