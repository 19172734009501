import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TableRow from './TableRow';
import Loader from '../../../../components/Loader';
import { isEmpty } from '../../../../utils/helpers';

class TasksResults extends Component {

	render() {

		const {
			searchType,
			userTasksData,
			taskLoader,
			isFetched,
			rowClasses,
			showOrderDetails,
		} = this.props;

		const tableHeadings = [
			'Description',
			(searchType === 'complete' ? '' : 'Priority'),
			'Assignee',
			'Action'
		];

		// Add Details Column only if we're not in Account Details Orders
		if (showOrderDetails !== false) {
			tableHeadings.splice(1, 0, 'Details')
		}

		return (
			<>

				{taskLoader &&
				<Loader />
				}

				{!taskLoader && isFetched && isEmpty(userTasksData) &&
				<div className="container">
					<p>No data found</p>
				</div>
				}

				{!taskLoader && !isEmpty(userTasksData) && (
				<div className="cmv-container-table">
					<table className="table cmv-table cmv-table-mobile cmv-table-solid">
						<thead>
							<tr>
								{tableHeadings.map((item, i) => (
									<th key={i} className="">{item}</th>
								))}
							</tr>
						</thead>
						<tbody>
							{userTasksData.map((task, i) =>
								<TableRow
									key={task.id}
									tableHeadings={tableHeadings}
									taskInfo={task}
									rowClass={rowClasses ? rowClasses[i] : i % 2 ? '' : 'tr-dark'}
									{...this.props}
								/>
							)}
						</tbody>
					</table>
				</div>
				)}

			</>
		);
	}
}

TasksResults.propTypes = {
	tableHeadings: PropTypes.array,
	userTasksData: PropTypes.array.isRequired,
	taskLoader: PropTypes.bool,
	isFetched: PropTypes.bool
};

export default TasksResults;
