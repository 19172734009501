import { DASHBOARD_ACCOUNT_SEARCH, SET_DASHBOARD_MENU_COUNT } from '../actions/dashboardActions/customers.actions'
import {SET_TASKS, SET_SYSTEM_USERS} from '../actions/dashboardActions/tasks.actions';
import {SET_DASHBOARD_ORDERS} from '../actions/dashboardActions/orders.actions';

let initialState = {
	accounts: [],
	userTasks: [],
	systemUsers: [],
	userOrders: [],
	dashboardMenuCount: {}
};

export const dashboardReducer = (state = initialState, action) => {
	switch (action.type) {
		case DASHBOARD_ACCOUNT_SEARCH.SUCCESS:
			return {
				...state,
				accounts: action.payload
			};
		case DASHBOARD_ACCOUNT_SEARCH.EMPTY:
			return {
				...state,
				accounts: []
			};
		case SET_DASHBOARD_MENU_COUNT.SUCCESS:
			return {
				...state,
				dashboardMenuCount: action.payload
			}
		case SET_DASHBOARD_MENU_COUNT.EMPTY:
			return {
				...state,
				dashboardMenuCount: {}
			}
		case SET_TASKS.SUCCESS:
			return {
				...state,
				userTasks: action.payload
			}
		case SET_TASKS.REQUEST:
			return {
				...state,
				userTasks: []
			}
		case SET_TASKS.EMPTY:
			return {
				...state,
				userTasks: []
			}
		case SET_SYSTEM_USERS.SUCCESS:
			return {
				...state,
				systemUsers: action.payload
			}
		case SET_SYSTEM_USERS.EMPTY:
			return {
				...state,
				systemUsers: []
			}
		case SET_DASHBOARD_ORDERS.SUCCESS:
			return {
				...state,
				userOrders: action.payload
			}
		case SET_DASHBOARD_ORDERS.EMPTY:
			return {
				...state,
				userOrders: []
			}
		default:
			return state;
	}
};
