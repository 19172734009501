import React, { Component, Fragment } from 'react';
import { isEmpty } from '../../../utils/helpers';
import {isEqual} from "lodash";

class WizardButtons extends Component {
	render() {
		const {
			activeTab,
			values,
			validatedAddress,
			prevStep,
			isServiceRequestStarted,
			accountStatus,
			showModal,
			history,
			redirectToCreateAccount,
			loaderMapLinkLocation,
			errors,
			addressType
		} = this.props;

		return (
			<div className="wizard-footer d-flex justify-content-between">
				{activeTab !== 0 &&
				activeTab !== 4 &&
				!isServiceRequestStarted && (
					<button onClick={prevStep} className="btn btn-outline-primary btn-prev-tab mr-1" type="button">
						Previous
					</button>
				)}
				{activeTab === 3 &&
					(isServiceRequestStarted === true ? (
						<Fragment>
							<button
								className="btn btn-outline-primary btn-next-tab ml-auto"
								type="button"
								onClick={() =>
									showModal('QUOTE_MODAL', {
										...values,
										validatedAddress: validatedAddress
									})}
							>
								Save as Quote
							</button>
							{accountStatus !== 'Prospect' && (
								<button
									className="btn btn-primary btn-next-tab-alt ml-1"
									type="button"
									onClick={() =>
										showModal('ORDER_MODAL', {
											...values,
											validatedAddress: validatedAddress
										})}
								>
									Save as Order
								</button>
							)}
						</Fragment>
					) : validatedAddress && validatedAddress.allowedServiceOrderType === 'NEW_SERVICE' ? (
						<Fragment>
							<button
								className="btn btn-outline-primary btn-next-tab ml-auto"
								type="button"
								disabled={!isEmpty(errors)}
								onClick={() =>
									showModal('QUOTE_AND_ACCOUNT_MODAL', {
										...values,
										validatedAddress: validatedAddress
									})}
							>
								Save as Quote
							</button>
							<button
								className="btn btn-primary btn-next-tab-alt ml-1"
								type="button"
								onClick={() => redirectToCreateAccount(values)}
								disabled={!isEmpty(errors)}
							>
								Add Customer
							</button>
						</Fragment>
					) : (
						<Fragment>
							<button
								className="btn btn-outline-primary btn-next-tab ml-auto"
								type="button"
								onClick={() =>
									showModal('ACCOUNT_MODAL', {
										...values,
										validatedAddress: validatedAddress
									})}
							>
								{validatedAddress.allowedServiceOrderType === 'NEW_SURVEY' ? (
									'Save Survey'
								) : (
									'Save Signup'
								)}
							</button>
						</Fragment>
					))}
				{activeTab < 3 &&
					activeTab !== 1 &&
					(activeTab === 0 &&
					validatedAddress &&
					!validatedAddress.error &&
					!validatedAddress.allowedServiceOrderType ? (
						<button
							className="btn btn-outline-primary btn-next-tab ml-auto"
							type="button"
							onClick={() =>
								showModal('ACCOUNT_MODAL', {
									...values,
									validatedAddress: validatedAddress,
									initialStatus: 'PROSPECT'
								})}
						>
							Save as Prospect
						</button>
					) : (
						<button
							className="btn btn-outline-primary btn-next-tab ml-auto"
							type="submit"
							disabled={((!validatedAddress || validatedAddress.error || loaderMapLinkLocation)
							&& addressType != "manualAddress") || !isEmpty(errors)}
						>
							Next
						</button>
					))}
				{activeTab === 4 && (
					<button
						className="btn btn-outline-primary btn-next-tab ml-auto"
						type="button"
						onClick={() => history.push('/')}
					>
						Finish
					</button>
				)}
			</div>
		);
	}
}

export default WizardButtons;
