import React, { Component } from 'react';
import { isEqual } from 'lodash';
import { connect } from 'react-redux';
import LocationSearchInput from "../../../CreateServiceRequest/ServiceRequest/Tabs/Addresses/LocationSearchInput";
import { generateAddressNew, generateFormatedAddress } from '../../../../utils/generateAddresses';
import Iframe from 'react-iframe';
import isEmpty from '../../../../utils/helpers';
import LoaderPulse from '../../../../components/Loader/LoaderPulse';
import {createLoadingSelector, getProvisioning, getUser} from "../../../../selectors";
import {clearMapLinkLocation, getMapLinkLocation, populateMapLocation} from "../../../../actions/provisioning.actions";
import {Form, Formik} from "formik";
import StepActions from "../../../../camvio-wizard/StepActions";

const initialValues = {
	addressType: 'noAddress',
}

class Addresses extends Component {

	onChangeAddressType = (addressType, {values, setFieldValue}) => {
		if (addressType === values.addressType) {
			return;
		}

		if (addressType === 'noAddress') {
			setFieldValue('selectedAddress', '');
			setFieldValue('selectedUnit', '');
		}

		setFieldValue('addressType', addressType);
	};

	onAddressHandleChange = async (address, unit, {setFieldValue}) => {

		if (address === 'error') {
			setFieldValue('selectedAddress', '');
			setFieldValue('selectedUnit', '');

			this.props.clearMapLinkLocation();
		} else {
			let addressForSend = '';
			if (unit) {
				addressForSend = unit + '/' + address;
			} else {
				addressForSend = address;
			}
			const addressRequest = {
				address: addressForSend
			};

			setFieldValue('selectedAddress', address);
			setFieldValue('selectedUnit', unit);

			this.props.getMapLinkLocation(this.props.currentLocation.id, addressRequest);
		}
	};

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (!isEqual(prevProps.validatedAddress, this.props.validatedAddress)) {
			this.props.setStepState({
				validatedAddress: this.props.validatedAddress
			});
		}
	}

	createValuesBundle = (values) => ({
		selectedAddress: values.selectedAddress,
		selectedUnit: values.selectedUnit,
		addressType: values.addressType,
		validatedAddress: this.props.stepState.validatedAddress,
	})

	createFormValuesBundle = (values) => ({
		...values,
	})

	handleNext = (values) => {
		const { next } = this.props;
		if (!next) {
			return;
		}

		next({
			values: this.createValuesBundle(values),
			formValues: this.createFormValuesBundle(values),
		})
	}

	handlePrevious = (values) => {
		const { previous } = this.props;
		if (!previous) {
			return;
		}

		previous({
			formValues: this.createFormValuesBundle(values),
		});
	}

	handleOnSubmit = (values, actions) => {
		this.handleNext(values);
	}

	renderContent = (formProps) => {
		const {
			validatedAddress
		} = this.props.stepState;

		const {
			contactAddresses,
			loaderMapLinkLocation,
		} = this.props;

		const {
			addressType,
			selectedAddress,
			selectedUnit,
		} = formProps.values;


		let formatedAddresses = [];
		if (contactAddresses) {
			formatedAddresses = contactAddresses.map((address) => {
				return {
					value: generateFormatedAddress(address),
					label: `Use ${address.typeName} address - ${generateAddressNew(address)}`
				};
			});
		}


		return (<>
			<div className="form-check checkbox-slider checkbox-slider--b-flat">
				<label>
					<input
						type="checkbox"
						name="manualAddress"
						checked={addressType === 'manualAddress'}
						onChange={() => this.onChangeAddressType('manualAddress', formProps)}
					/>
					<span>Enter manually</span>
				</label>
			</div>
			{addressType === 'manualAddress' && (
				<LocationSearchInput
					selectedAddress={selectedAddress}
					selectedUnit={selectedUnit}
					onAddressHandleChange={(address, unit) => this.onAddressHandleChange(address, unit, formProps)}
					{...this.props}
				/>
			)}

			<div className="form-check checkbox-slider checkbox-slider--b-flat">
				<label>
					<input
						type="checkbox"
						name="noAddress"
						checked={addressType === 'noAddress'}
						onChange={() => this.onChangeAddressType('noAddress', formProps)}
					/>
					<span>No address required</span>
				</label>
			</div>

			{loaderMapLinkLocation && <LoaderPulse />}
			{!loaderMapLinkLocation &&
				!isEmpty(validatedAddress) &&
				isEmpty(validatedAddress.error) &&
				addressType !== 'noAddress' && (
				<div className="customer-new-service-location-map">
					<Iframe
						url={validatedAddress.embedUrl}
						width="100%"
						height="100%"
						id="myId"
						className="location-map"
						display="initial"
						position="relative"
						style={{ height: `30vh` }}
					/>
				</div>
			)}
		</>);
	}

	render() {
		const { stepFormValues, previous, next } = this.props;

		return (
			<Formik
				onSubmit={this.handleOnSubmit}
				initialValues={isEmpty(stepFormValues)? initialValues : stepFormValues}
				render={(formProps) => (
					<Form onSubmit={formProps.handleSubmit} className="cmv-form" autoComplete="off">

						{this.renderContent(formProps)}

						<StepActions
							previous={previous && (() => this.handlePrevious(formProps.values))}
							next={next && formProps.submitForm} />
					</Form>
				)}
			/>
		);
	}
}

const selectMapLinkLocationLoading = createLoadingSelector(['@provisioning/MAP_LINK_LOCATION']);

const mapStateToProps = (state) => {
	const validatedAddress = getProvisioning(state).validatedAddress,
		loaderMapLinkLocation = selectMapLinkLocationLoading(state),
		currentLocation = getUser(state).currentLocation,
		userConfiguration = getUser(state).configuration;

	return {
		validatedAddress,
		loaderMapLinkLocation,
		currentLocation,
		userConfiguration,
	};
};

const mapDispatchToProps = {
	getMapLinkLocation,
	populateMapLocation,
	clearMapLinkLocation,
};

export default connect(mapStateToProps, mapDispatchToProps)(Addresses);
