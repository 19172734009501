import React, { Component } from 'react';
import NavPilsItem from '../../../../components/Common/NavPilsItem/NavPilsItem';

class NavTabs extends Component {
	render() {
		const { activeTab, changeTabHandler, handleExpandPanel, userPermission } = this.props;

		return (
			<ul className="nav nav-pills nav-scrollable nav-pills-warning" id="clientProfileTab" role="tablist">
				<NavPilsItem
					pillName="notes"
					pillHeader="Notes"
					activeTab={activeTab}
					changeTabHandler={changeTabHandler}
				/>
				<NavPilsItem
					pillName="security"
					pillHeader="Profile"
					activeTab={activeTab}
					changeTabHandler={changeTabHandler}
					userPermission={userPermission}
				/>
				<NavPilsItem
					pillName="notifications"
					pillHeader="Notifications"
					activeTab={activeTab}
					changeTabHandler={changeTabHandler}
				/>
				<NavPilsItem
					pillName="administration"
					pillHeader="Administration"
					activeTab={activeTab}
					changeTabHandler={changeTabHandler}
					userPermission={userPermission}
				/>
				<NavPilsItem
					pillName="documents"
					pillHeader="Documents"
					activeTab={activeTab}
					changeTabHandler={changeTabHandler}
					userPermission={userPermission}
				/>
				<li className="ml-auto">
					<button onClick={() => handleExpandPanel('rightPanel')} className="nav-link btn btn-link btn-panel-right-toggler d-none d-xl-block" type="button">
						<i className="fas fa-arrows-alt-h" />
					</button>
				</li>
			</ul>
		);
	}
}

export default NavTabs;
