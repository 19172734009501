import React, { Component } from 'react';
import {connect} from 'react-redux';
import {hideModal} from '../../../actions/modal.actions';
import {
    getMyAutoPayInfo,
    emptyMyAutoPayInfo,
    enableAutoPayACH,
    enableAutoPayCC,
    disableAutoPay,
    getAutoPayAvailableCC,
    enableAutoPayTokenPaymentMethod,
} from "../../../actions/accountDetailsActions/accountDetails.actions";
import {
    getAccountDetails,
    createLoadingSelector,
    getModalData,
    getPaymentProviders,
    getUserPermission
} from '../../../selectors';
import {Form, Formik} from 'formik';
import * as Yup from 'yup';
import {toastr} from "react-redux-toastr";
import isEmpty from "../../../utils/helpers";
import Loader from "react-spinners/BeatLoader";
import {getCurrentPaymentProviderId, getCurrentPaymentProviderSupportsPaymentMethodStorage} from "../../../actions/paymentProvider.actions";
import {Checking, Credit, PaymentForm} from "./PayNowModal/PaymentMethod";
import FormikInput from "../../../components/Common/Inputs/FormikInput";

function noEmptyString() {
    return this.transform((value, originalValue) => originalValue.trim().length === 0 ? NaN : value);
}

function noSpaces() {
    return this.transform((value, originalValue) => (/\s/.test(originalValue) ? NaN : value));
}

Yup.addMethod(Yup.string, 'noEmptyString', noEmptyString);
Yup.addMethod(Yup.number, 'noSpaces', noSpaces);

const ccSchema = Yup.object().shape({
    cc: Yup.object().required('Required')
});

const achSchema = Yup.object().shape({
    cardHolderName: Yup.string().noEmptyString().typeError('Required').required('Required'),
    routingNumber: Yup.number().noSpaces().typeError('Please enter correct number.').positive('Please enter correct number.').required('Required'),
    accountNumber: Yup.number().noSpaces().typeError('Please enter correct number.').positive('Please enter correct number.').required('Required'),
    accountType: Yup.string().required('Required')
});

const ACCOUNT_TYPE = {
    CHECKING: 'CHECKING',
    SAVINGS: 'SAVINGS',
    BUSINESS_CHECK: 'BUSINESS_CHECK'
}

class AutopayModal extends Component {

    state = {
        myAutoPayInfoLoading: false,
        autopayUpdated: false,
        autopayActive: !isEmpty(this.props.myAutoPayInfo),
        ccActive: (this.props.myAutoPayInfo && this.props.myAutoPayInfo.apiAccountAutoPayCC) || false,
        achActive: (this.props.myAutoPayInfo && this.props.myAutoPayInfo.apiAccountAutoPayACH) || false,
        payProviderSupportsPaymentMethodStorage: false,
        defaultSubmitButtonText: "SAVE CHANGES",
        currentSubmitButtonText: "SAVE CHANGES",
        paymentMethodsLoading: false,
        paymentMethodSelected: false
    }

    constructor(props) {
        super(props);

        this.paymentFormRef = React.createRef();
    }

    componentDidMount() {
        this.props.getCurrentPaymentProviderId().then(resp => {
            if (resp.data.id) {
                this.handleGetAutoPayInfo();
                this.props.getAutoPayAvailableCC(this.props.accountId);
            }
        })
        this.props.getCurrentPaymentProviderSupportsPaymentMethodStorage().then(resp => {
            this.setState({payProviderSupportsPaymentMethodStorage: resp.data.result});
        });
    }
    componentWillUnmount() {
        this.props.emptyMyAutoPayInfo();
    }

    handleGetAutoPayInfo = () => {
        this.setState({myAutoPayInfoLoading: 'info'});

        this.props.getMyAutoPayInfo(this.props.accountId).then(response => {
            this.setState({
                myAutoPayInfoLoading: false,
                autopayActive: !isEmpty(response.appliesOn),
                ccActive: response.apiAccountAutoPayCC || false,
                achActive: response.apiAccountAutoPayACH || false,
            });
        });
    }

    handleEnableAutoPay = (props) => {
        if (this.props.myAutoPayInfo && Object.keys(this.props.myAutoPayInfo).length !== 0) {

            toastr.confirm("Are you sure you want to disable AutoPay?", {
                cancelText: "Cancel",
                okText: "Confirm",
                onOk: () => {
                    this.setState({
                        myAutoPayInfoLoading: 'enable',
                        autopayUpdated: false
                    });

                    this.props.disableAutoPay(props.values).then(response => {
                        this.handleGetAutoPayInfo();
                    });
                },
                onCancel: () => {
                }
            });
        }
        else {
            if (this.state.payProviderSupportsPaymentMethodStorage) {
                this.setState((prevState) => ({
                    autopayActive: !prevState.autopayActive,
                    ccActive: false,
                    achActive: false,
                }));
            } else {
                this.setState((prevState) => ({
                    autopayActive: !prevState.autopayActive,
                    ccActive: prevState.autopayActive === true ? false : true,
                    achActive: false,
                }));
            }

        }
    }

    handleCCSubmit = (values) => {
        this.setState({myAutoPayInfoLoading: 'card'});

        this.props.enableAutoPayCC(values).then(response => {
            this.setState({autopayUpdated: true, myAutoPayInfoLoading: 'info'}, () => {
                this.handleGetAutoPayInfo();
            });
        });
    }

    handleACHSubmit = (values) => {
        this.setState({myAutoPayInfoLoading: 'ach'});

        this.props.enableAutoPayACH(values).then(response => {
            this.setState({autopayUpdated: true, myAutoPayInfoLoading: 'info'}, () => {
                this.handleGetAutoPayInfo();
            });
        });
    }

    handleTokenPayMethodSubmit = (values) => {
        const {accountInfo} = this.props;

        if (values.token !== 'MANAGE_PAYMENT_METHODS') {
            this.setState({myAutoPayInfoLoading: 'paymentMethod'});
            this.props.enableAutoPayTokenPaymentMethod(values).then(response =>{
                this.setState({autopayUpdated: true, myAutoPayInfoLoading: 'info'}, () => {
                    this.handleGetAutoPayInfo();
                });
            });
        } else {
            const data = {submitType: 'managePaymentMethods'}
            this.paymentFormRef.current.renderManagePaymentMethodForm(accountInfo, data);
            //setSubmitting(false);
        }
    }

    handlePaymentMethodChange = (e, values, setFieldValue, myAutoPayAvailableCC) => {
        const selectedPaymentMethod = e.target.value;

        if (selectedPaymentMethod !== 'MANAGE_PAYMENT_METHODS') {
            setFieldValue('token', myAutoPayAvailableCC[parseInt(e.target.value)].cardToken)
            this.setState({currentSubmitButtonText: this.state.defaultSubmitButtonText});
        } else {
            setFieldValue('token', 'MANAGE_PAYMENT_METHODS')
            this.setState({currentSubmitButtonText: "MANAGE PAYMENT METHODS"});
            this.renderPaymentMethodForm();
        }
        this.setState({paymentMethodSelected: true})
    };

    renderPaymentMethodForm = () => {
        const {accountInfo} = this.props;

        return <PaymentForm ref={this.paymentFormRef}
                            accountInfo={accountInfo}
                            paymentMethod='MANAGE_PAYMENT_METHODS'
                            refreshPayProviderStoredPaymentMethods={() => this.refreshPayProviderStoredPaymentMethods()}
        />
    };

    refreshPayProviderStoredPaymentMethods() {
        if (this.state.payProviderSupportsPaymentMethodStorage) {
            this.setState({paymentMethodsLoading: true})
            this.props.getAutoPayAvailableCC(this.props.accountId)
                .then((response) => {
                    this.setState({paymentMethodsLoading: false})
                });
        }
    }


    closeModal = () => {
        this.props.hideModal();

        if (this.props.modalProps.onCloseCallback) {
            this.props.modalProps.onCloseCallback();
        }
    };

    dateconverter =(date)=>{
        const datearr=date.split('-');
        const monthlist={
            '01':'Jan',
            '02':'Feb',
            '03':'Mar',
            '04':'Apr',
            '05':'May',
            '06':'Jun',
            '07':'Jul',
            '08':'Aug',
            '09':'Sep',
            '10':'Act',
            '11':'Nov',
            '12':'Dec'
        }

        return `${monthlist[datearr[1]]} ${datearr[2]}, ${datearr[0]}`
    }

    renderBody = () => {
        const {
            myAutoPayInfo,
            myAutoPayAvailableCC,
            accountId,
            currentProviderId,
            canManagePaymentMethods,
        } = this.props;

        const {
            autopayActive,
            myAutoPayInfoLoading,
            autopayUpdated,
            achActive,
            ccActive,
            paymentMethodSelected
        } = this.state;

        if (currentProviderId == null) {
            return <h6>AutoPay is not available</h6>;
        }

        return <>
        <h6>            
            {myAutoPayInfoLoading === 'info'
                ? <Loader size={5} color={'#6ab3c1'}/>
                : this.props.myAutoPayInfo && this.props.myAutoPayInfo && Object.keys(this.props.myAutoPayInfo).length !== 0
                    ? `AutoPay is enabled${this.props.balancesData.accountBalance>0?`, and scheduled for ${this.dateconverter(myAutoPayInfo.appliesOn)}`:''}`
                    : "AutoPay is disabled."
            }
        </h6>

        <hr/>

        <Formik
            initialValues={{
                accountId: accountId
            }}
            onSubmit={this.handleSubmit}
        >
            {props => (
                <Form>
                    <div className="form-check checkbox-slider checkbox-slider--b-flat">
                        <label className="w-100">
                            <input
                                type="checkbox"
                                name="autopayActive"
                                id="autopayActive"
                                disabled={myAutoPayInfoLoading ? 'disabled' : false}
                                checked={autopayActive}
                                onClick={() => this.handleEnableAutoPay(props)}
                            />
                            <span>
                                Enable AutoPay
                            </span>
                            {myAutoPayInfoLoading === 'enable' &&
                            <span className="float-right"><Loader size={5} color={'#6ab3c1'}/></span>}
                        </label>
                    </div>
                </Form>
            )}
        </Formik>

        {autopayActive &&
        <hr/>
        }

        {autopayActive && this.state.payProviderSupportsPaymentMethodStorage &&
            <div>
                <div className="form-check checkbox-slider checkbox-slider--b-flat">
                    <label className="w-100">
                        <span>
                            Payment Methods
                            <p className="text-muted text-xs mb-0">
                            Choose from existing payment methods.
                            </p>
                        </span>
                        {myAutoPayInfoLoading === 'card'  &&
                        <span className="float-right"><Loader size={5} color={'#6ab3c1'}/></span>}
                    </label>
                </div>

            </div>
        }

        {autopayActive && !this.state.payProviderSupportsPaymentMethodStorage &&
        <div className="form-check checkbox-slider checkbox-slider--b-flat">
            <label className="w-100">
                <input
                    type="checkbox"
                    name="card"
                    id="card"
                    disabled={myAutoPayInfoLoading ? 'disabled' : false}
                    checked={ccActive}
                    value={ccActive}
                    onChange={() => {!ccActive &&
                    this.setState({ccActive: true, achActive: false, autopayUpdated: false});
                    }}
                />
                <span>
                    Debit / Credit Card
                    <p className="text-muted text-xs mb-0">
                        Choose from existing debit / credit cards.
                    </p>
                </span>
                {myAutoPayInfoLoading === 'card'  &&
                <span className="float-right"><Loader size={5} color={'#6ab3c1'}/></span>}
            </label>
        </div>
        }

        {autopayActive && this.state.payProviderSupportsPaymentMethodStorage &&
            <div className="form-group">
                <Formik
                    initialValues={{
                        accountId: accountId,
                        token: ''
                    }}
                    validateOnChange={true}
                    onSubmit={(values) => {
                        this.handleTokenPayMethodSubmit(values);
                    }}
                    render={(props) => {
                        const {values, touched, errors, setFieldValue, handleBlur} = props;

                        return (
                            <Form>
                                <div className={"form-section form-section-expanded form-section-customer-payment-invoices" + (myAutoPayInfoLoading ? " form-section-customer-payment-invoices-disabled" : "")}>
                                    <div className="container">

                                        <br/>

                                        {myAutoPayInfoLoading === 'info' && <Loader size={5} color={'#258C9F'}/>}

                                        {myAutoPayInfo && myAutoPayInfo.apiAccountAutoPayToken && myAutoPayInfo.apiAccountAutoPayToken.paymentInformation && <>
                                            <div className="mb-1 clearfix">
                                                <h6 className="float-left">Current Payment Method</h6>
                                            </div>
                                            {myAutoPayInfo.apiAccountAutoPayToken.paymentInformation && !myAutoPayInfo.apiAccountAutoPayToken.paymentInformation.includes("null") &&
                                            <div className="mb-1 clearfix">
                                                <h6 className="float-left">{myAutoPayInfo.apiAccountAutoPayToken.paymentInformation}</h6>
                                            </div>
                                            }
                                            {!autopayUpdated &&
                                            <hr/>
                                            }
                                        </>}

                                        {(!autopayUpdated || (myAutoPayInfo && !myAutoPayInfo.apiAccountAutoPayToken)) && <>
                                            {this.state.paymentMethodsLoading &&
                                            <i className="fas fa-fw fa-spin fa-spinner"
                                               style={{marginLeft: '5px'}}/>
                                            }
                                            {!this.state.paymentMethodsLoading &&
                                            <>
                                                <label>Select Payment Method</label>
                                                <div className="form-group">
                                                    <select
                                                        id="token"
                                                        name="token"
                                                        className={"form-control" + (errors.token ? " is-invalid" : "")}
                                                        disabled={myAutoPayInfoLoading && 'disabled'}
                                                        onBlur={handleBlur}
                                                        onChange={(e) =>
                                                            this.handlePaymentMethodChange(
                                                                e,
                                                                values,
                                                                setFieldValue,
                                                                myAutoPayAvailableCC
                                                            )
                                                        }
                                                    >
                                                        <option selected disabled>Select payment method...</option>
                                                        {myAutoPayAvailableCC.map((payMethod, index) => (
                                                            <option
                                                                value={index}>{payMethod.paymentInformation}{payMethod.autoPay ? ' (AutoPay)' : ''}</option>
                                                        ))};
                                                        <option
                                                            value="MANAGE_PAYMENT_METHODS"
                                                            disabled={!canManagePaymentMethods}>
                                                            Manage Payment Methods
                                                        </option>
                                                    </select>
                                                </div>
                                            </>
                                            }
                                        </> }

                                    </div>
                                </div>

                                {!autopayUpdated &&
                                <div className="form-group text-right">
                                    <button
                                        type="submit"
                                        className="btn btn-primary"
                                        disabled={myAutoPayInfoLoading || !paymentMethodSelected ? 'disabled' : false}
                                    >
                                        {this.state.currentSubmitButtonText}
                                    </button>
                                </div>
                                }

                            </Form>
                        )
                    }}
                />
                {this.renderPaymentMethodForm()}
            </div>
        }

        {ccActive && !this.state.payProviderSupportsPaymentMethodStorage &&
        <div className="form-group">
            <Formik
                initialValues={{
                    accountId: accountId,
                    cc: '',
                    type: '',
                    cardToken: '',
                    cardExpirationDate: '',
                    displayCardNumber: ''
                }}
                validationSchema={ccSchema}
                validateOnChange={true}
                onSubmit={(values) => {
                    this.handleCCSubmit(values);
                }}
                render={(props) => {
                    const {values, touched, errors, setFieldValue, handleBlur} = props;

                    return (
                        <Form>
                            <div className={"form-section form-section-expanded form-section-customer-payment-invoices" + (myAutoPayInfoLoading ? " form-section-customer-payment-invoices-disabled" : "")}>
                                <div className="container">

                                    <br/>

                                    {myAutoPayInfoLoading === 'info' && <Loader size={5} color={'#258C9F'}/>}

                                    {myAutoPayInfo && myAutoPayInfo.apiAccountAutoPayCC && myAutoPayInfo.apiAccountAutoPayCC.displayCardNumber && <>
                                        <div className="mb-1 clearfix">
                                            <h6 className="float-left">Current Card</h6>
                                            <div className="float-right">{myAutoPayInfo.apiAccountAutoPayCC.displayCardNumber}</div>
                                        </div>
                                        {myAutoPayInfo.apiAccountAutoPayCC.cardExpirationDate && !myAutoPayInfo.apiAccountAutoPayCC.cardExpirationDate.includes("null") &&
                                        <div className="mb-1 clearfix">
                                            <h6 className="float-left">Expiration Date</h6>
                                            <div className="float-right">{myAutoPayInfo.apiAccountAutoPayCC.cardExpirationDate}</div>
                                        </div>
                                        }
                                        {!autopayUpdated &&
                                        <hr/>
                                        }
                                    </> }

                                    {(!autopayUpdated || (myAutoPayInfo && !myAutoPayInfo.apiAccountAutoPayCC)) && <>
                                        <label>Select Card</label>
                                        <div className="form-group">
                                            <select
                                                id="cc"
                                                name="cc"
                                                className={"form-control" + (errors.cc ? " is-invalid" : "")}
                                                disabled={myAutoPayInfoLoading && 'disabled'}
                                                onBlur={handleBlur}
                                                onChange={(e) => {
                                                    setFieldValue('cc', myAutoPayAvailableCC[parseInt(e.target.value)]);
                                                    setFieldValue('type', myAutoPayAvailableCC[parseInt(e.target.value)].type);
                                                    setFieldValue('cardToken', myAutoPayAvailableCC[parseInt(e.target.value)].cardToken)
                                                    setFieldValue('cardExpirationDate', myAutoPayAvailableCC[parseInt(e.target.value)].cardExpirationDate);
                                                    setFieldValue('displayCardNumber', myAutoPayAvailableCC[parseInt(e.target.value)].displayCardNumber);
                                                }}
                                            >
                                                <option selected disabled>Select card...</option>
                                                {myAutoPayAvailableCC.map((cc, index) => ((cc.cardExpirationDate  && !cc.cardExpirationDate.includes("null"))
                                                        ? <option value={index}>{cc.displayCardNumber}  - expires {cc.cardExpirationDate}</option>
                                                        : <option value={index}>{cc.displayCardNumber}</option>
                                                ))};
                                            </select>
                                            {touched.cc && errors.cc &&
                                            <div className="invalid-feedback">{errors.cc}</div>
                                            }
                                        </div>
                                    </> }

                                </div>
                            </div>

                            {!autopayUpdated &&
                            <div className="form-group text-right">
                                <button
                                    type="submit"
                                    className="btn btn-primary"
                                    disabled={(myAutoPayInfoLoading) && 'disabled'}
                                >
                                    Save Changes
                                </button>
                            </div>
                            }

                        </Form>
                    )
                }}
            />
        </div>
        }

        {autopayActive && !this.state.payProviderSupportsPaymentMethodStorage &&
        <div className="form-check checkbox-slider checkbox-slider--b-flat">
            <label className="w-100">
                <input
                    type="checkbox"
                    name="ach"
                    id="ach"
                    disabled={myAutoPayInfoLoading ? 'disabled' : false}
                    checked={achActive}
                    value={achActive}
                    onChange={() => {!achActive &&
                    this.setState({ccActive: false, achActive: true, autopayUpdated: false});
                    }}
                />
                <span>
                    New Checking / Saving Account
                    <p className="text-muted text-xs mb-0">
                        Add new checking / saving account to use.
                    </p>
                </span>
                {myAutoPayInfoLoading === 'ach' &&
                <span className="float-right"><Loader size={5} color={'#6ab3c1'}/></span>
                }
            </label>
        </div>
        }

        {achActive && !this.state.payProviderSupportsPaymentMethodStorage &&
        <div className="form-group">
            <Formik
                initialValues={{
                    accountId: accountId,
                    cardHolderName: '',
                    routingNumber: '',
                    accountNumber: '',
                    accountType: ''
                }}
                validationSchema={achSchema}
                onSubmit={(values) => {
                    this.handleACHSubmit(values);
                }}
                render={(props) => {
                    const {values, touched, errors, handleChange, handleBlur, setFieldValue} = props;

                    return (
                        <Form>
                            <div className={"form-section form-section-expanded form-section-customer-payment-invoices" + (myAutoPayInfoLoading ? " form-section-customer-payment-invoices-disabled" : "")}>
                                <div className="container">

                                    <br/>

                                    {myAutoPayInfoLoading === 'info' && <Loader size={5} color={'#258C9F'}/>}

                                    {myAutoPayInfo && myAutoPayInfo.apiAccountAutoPayACH && <>
                                        <div className="mb-1 clearfix">
                                            <h6 className="float-left">Account Holder Name</h6>
                                            <div className="float-right">{myAutoPayInfo.apiAccountAutoPayACH.holder_name}</div>
                                        </div>
                                        <div className="mb-1 clearfix">
                                            <h6 className="float-left">Current Routing #</h6>
                                            <div className="float-right">{myAutoPayInfo.apiAccountAutoPayACH.routing_number}</div>
                                        </div>
                                        <div className="mb-1 clearfix">
                                            <h6 className="float-left">Current Bank Account #</h6>
                                            <div className="float-right">{myAutoPayInfo.apiAccountAutoPayACH.account_number}</div>
                                        </div>
                                        <div className="mb-1 clearfix">
                                            <h6 className="float-left">Account Type</h6>
                                            <div className="float-right">{myAutoPayInfo.apiAccountAutoPayACH.account_type}</div>
                                        </div>
                                        {!autopayUpdated &&
                                        <hr/>
                                        }
                                    </> }

                                    {(!autopayUpdated || (this.props.myAutoPayInfo && !this.props.myAutoPayInfo.apiAccountAutoPayACH)) && <>
                                        <div className="form-group">
                                            <label>Account Holder Name</label>
                                            <input
                                                type="text"
                                                name="cardHolderName"
                                                id="cardHolderName"
                                                placeholder="Enter holder name"
                                                value={values.cardHolderName}
                                                className={"form-control" + (touched.cardHolderName && errors.cardHolderName ? " is-invalid" : "")}
                                                disabled={myAutoPayInfoLoading && 'disabled'}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            {touched.cardHolderName && errors.cardHolderName &&
                                            <div className="invalid-feedback">{errors.cardHolderName}</div>
                                            }
                                        </div>

                                        <div className="form-group">
                                            <label>Routing Number</label>
                                            <input
                                                type="text"
                                                name="routingNumber"
                                                id="routingNumber"
                                                placeholder="Enter routing number"
                                                value={values.routingNumber}
                                                className={"form-control" + (touched.routingNumber && errors.routingNumber ? " is-invalid" : "")}
                                                disabled={myAutoPayInfoLoading && 'disabled'}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            {touched.routingNumber && errors.routingNumber &&
                                            <div className="invalid-feedback">{errors.routingNumber}</div>
                                            }
                                        </div>

                                        <div className="form-group">
                                            <label>Account Number</label>
                                            <input
                                                type="text"
                                                name="accountNumber"
                                                id="accountNumber"
                                                placeholder="Enter account number"
                                                value={values.accountNumber}
                                                className={"form-control" + (touched.accountNumber && errors.accountNumber ? " is-invalid" : "")}
                                                disabled={myAutoPayInfoLoading && 'disabled'}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            {touched.accountNumber && errors.accountNumber &&
                                            <div className="invalid-feedback">{errors.accountNumber}</div>
                                            }
                                        </div>

                                        <div className="form-group">
                                            <label>Account Type</label>
                                            <select
                                                id="AchAccountType"
                                                name="accountType"
                                                onChange={(e) => setFieldValue('accountType', e.target.value)}
                                                onBlur={handleBlur}
                                                className={"form-control" + (touched.accountType && errors.accountType ? " is-invalid" : "")}
                                                disabled={myAutoPayInfoLoading && 'disabled'}
                                            >
                                                <option value="" selected disabled>Select account type...</option>
                                                <option value={ACCOUNT_TYPE.CHECKING}>Checking</option>
                                                <option value={ACCOUNT_TYPE.SAVINGS}>Savings</option>
                                                {currentProviderId === 'IPPAY' && <option value={ACCOUNT_TYPE.BUSINESS_CHECK}>Business Check</option>}
                                            </select>
                                            {touched.accountType && errors.accountType &&
                                            <div className="invalid-feedback">{errors.accountType}</div>
                                            }
                                        </div>
                                    </> }

                                </div>
                            </div>

                            {!autopayUpdated &&
                            <div className="form-group text-right">
                                <button
                                    type="submit"
                                    className="btn btn-primary"
                                    disabled={myAutoPayInfoLoading && 'disabled'}
                                >
                                    Save Changes
                                </button>
                            </div>
                            }

                        </Form>
                    )
                }}
            />
        </div>
        }
        </>
    }

    render() {

        return (
            <>
                <div className="modal" style={{display: 'block'}} tabIndex="-1" role="dialog">
                    <div className="modal-dialog">
                        <div className="modal-content">

                            <div className="modal-header">
                                <h5 className="modal-title">Autopay</h5>
                                <button onClick={this.closeModal} type="button" className="close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>

                            <div className="modal-body">
                                {this.renderBody()}
                            </div>

                            <div className="modal-footer">
                                <button onClick={this.closeModal} className="btn" type="button">
                                    Close
                                </button>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="modal-backdrop show" tabIndex="1"/>
            </>
        );
    }
}

const getMyAutoPayInfoLoader = createLoadingSelector(['AUTOPAY']);
const getMyAutoPayAvailableCCLoader = createLoadingSelector(['AUTOPAY_AVAILABLE_CC']);

const mapStateToProps = (state) => {
    const
        modalProps = getModalData(state).modalProps,
        myAutoPayInfoLoader = getMyAutoPayInfoLoader(state),
        myAutoPayAvailableCCLoader = getMyAutoPayAvailableCCLoader(state),
        myAutoPayInfo = getAccountDetails(state).accountDetails.myAutoPayInfo,
        myAutoPayAvailableCC = getAccountDetails(state).accountDetails.myAutoPayAvailableCC,
        accountInfo = getAccountDetails(state).accountDetails.accountInfo,
        accountId = getAccountDetails(state).accountDetails.accountInfo.id,
        canManagePaymentMethods = getUserPermission(state, 'API', 'API_MANAGE_PAYMENT_METHODS'),
        currentProviderId = getPaymentProviders(state).currentProviderId,
        balancesData = getAccountDetails(state).billing.balancesInfo
    return {
        modalProps,
        myAutoPayInfoLoader,
        myAutoPayAvailableCCLoader,
        myAutoPayInfo,
        myAutoPayAvailableCC,
        accountInfo,
        accountId,
        canManagePaymentMethods,
        currentProviderId,
        balancesData
    };
};

const mapDispatchToProps = {
    hideModal,
    getMyAutoPayInfo,
    emptyMyAutoPayInfo,
    enableAutoPayACH,
    enableAutoPayCC,
    disableAutoPay,
    getAutoPayAvailableCC,
    getCurrentPaymentProviderId,
    enableAutoPayTokenPaymentMethod,
    getCurrentPaymentProviderSupportsPaymentMethodStorage: getCurrentPaymentProviderSupportsPaymentMethodStorage,
};

export default connect(mapStateToProps, mapDispatchToProps)(AutopayModal);
