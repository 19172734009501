import React, {Component} from 'react'
import {connect} from "react-redux";

class Map extends Component {

    render() {

        const {
            appointments,
        } = this.props;

        return (
            <div className="cmv-container-appointment-map-search-results">

                <div className="appointment-map">
                    Map
                </div>

            </div>
        )
    }
}

const mapStateToProps = (state) => {

    return {
    };
};

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(Map);
