import {
	GET_WORKFLOW_ORDERS,
	GET_WORKFLOW_EVENTS,
	GET_WORKFLOW_TRANSITIONS,
	GET_WORKFLOW_LIST,
	GET_WORKFLOW_STATUSES,
} from '../actions/workflow.actions';

let initialState = {
	workflowOrders: [],
	workflowEvents: [],
	workflowTransitions: [],
	workflowList: [],
	workflowStatuses: [],
};

export const workflowReducer = (state = initialState, action) => {
	switch (action.type) {
		case GET_WORKFLOW_ORDERS.SUCCESS:
			return {
				...state,
				workflowOrders: action.payload.workflows
			}
		case GET_WORKFLOW_ORDERS.CLEAR:
			return {
				...state,
				workflowOrders: []
			}
		case GET_WORKFLOW_EVENTS.SUCCESS:
			return {
				...state,
				workflowEvents: action.payload.events
			}
		case GET_WORKFLOW_EVENTS.CLEAR:
			return {
				...state,
				workflowEvents: []
			}
		case GET_WORKFLOW_TRANSITIONS.SUCCESS:
			return {
				...state,
				workflowTransitions: action.payload.statusTransitions
			}
		case GET_WORKFLOW_TRANSITIONS.CLEAR:
			return {
				...state,
				workflowTransitions: []
			}
		case GET_WORKFLOW_LIST.SUCCESS:
			return {
				...state,
				workflowList: action.payload.processDefinitions
			}
		case GET_WORKFLOW_LIST.CLEAR:
			return {
				...state,
				workflowList: []
			}
		case GET_WORKFLOW_STATUSES.SUCCESS:
			return {
				...state,
				workflowStatuses: action.payload.workflowDefinitionStatuses
			}
		case GET_WORKFLOW_STATUSES.CLEAR:
			return {
				...state,
				workflowStatuses: []
			}
		default:
			return state;
	}
};
