import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import { get } from 'lodash';

import {getCreateServiceRequest, createLoadingSelector, getCreateAccount, getUserPermission} from '../../../selectors';

import { connect } from 'react-redux';
import {
	createNewOrder,
	getPlansAndBundles,
	getPlansDetails,
	emptyNewOrder,
	emptyPlanDetails,
	startServiceOrder,
	addPlans,
	getMapLinkLocation,
	populateMapLocation,
	emptyMapLinkLocation
} from '../../../actions/createServiceRequest.actions';
import { getTasksByOrderId } from '../../../actions/tasksList.actions';
import { showModal, hideModal } from '../../../actions/modal.actions';

import {isEmpty, generateId, applyOnEnum} from '../../../utils/helpers';
import { validationSchema } from './validation';

import WizardButtons from './WizardButtons';
import NavTabs from './NavTabs';
import { Addresses, BundlesAndPlans, Services, Products, Confirmation } from './Tabs';
import {getCountryStates} from "../../../actions/createAccount.actions";
class ServiceRequest extends Component {
	state = {
		initialValues: {
			// accountId: this.props.accountInfo.id,
			// createdBy: this.props.userSummary.id,
			name: 'NEW_ORDER_' + generateId(),
			note: '',
			address: '',
			fullAddress: {
				address1: '',
				city: '',
				state: '',
				zip: ''
			},
			plans: [],
			source: 'CAMVIO-WEB',
			applyOn: Object.keys(applyOnEnum)[0],
			type: ''
		},
		activeTab: 0,
		addressType: 'validateAddress',
		selectedAddress: '',
		selectedUnit: '',
		addressIframeLoader: false,
		isSummaryFullWith: false,
		isSummaryCollapsed: false,
		collapsedMenuItem: 'planBundles',
		selectedItem: '',
		selectedType: '',
		isPlanAndBundlesExecuted: false,
		isServiceRequestStarted: false
	};

	componentDidMount() {
		const { history } = this.props;
		this.props.getCountryStates();

		//If user return from create account wizard - after service request is started
		if (
			history.location.state &&
			history.location.state.serviceOrderData &&
			history.location.state.serviceOrderData.isServiceOrderStarted
		) {
			this.props.addPlans(history.location.state.serviceOrderData.planValues);
			this.props.populateMapLocation(history.location.state.serviceOrderData.validatedAddress);

			this.setState({
				activeTab: 3,
				initialValues: history.location.state.serviceOrderData.formValues,
				isServiceRequestStarted: true
			});
		}
	}

	componentDidUpdate(prevProps) {
		if (
			(prevProps.newOrderInfo !== this.props.newOrderInfo && !isEmpty(this.props.newOrderInfo)) ||
			(!isEmpty(this.props.validatedAddress) &&
				isEmpty(this.props.validatedAddress.allowedServiceOrderType) &&
				prevProps.newAccountInfo !== this.props.newAccountInfo &&
				!isEmpty(this.props.newAccountInfo))
		) {
			this.setState((prevState) => ({
				activeTab: 4
			}));
			this.props.hideModal();
		}

	}

	componentWillUnmount() {
		this.props.emptyNewOrder();
	}

	renderTabContent = (formProps) => {
		const {
			contactAddresses,
			planBundles,
			plans,
			accountInfo,
			currentLocation,
			plansDetails,
			getPlansDetails,
			newOrderInfo,
			isOrderStartedData,
			tasksCountData,
			history,
			showNewOrderWizardHandler,
			showAccessModal,
			getTasksByOrderId,
			startServiceOrder,
			validatedAddress,
			newAccountInfo,
			loaderPlansAndBundles,
			loaderMapLinkLocation,
			userConfiguration,
			values,
			countryStatesData,
			canEnterManualAddress,
			showModal
		} = this.props;
		const {
			addressType,
			activeTab,
			isSummaryFullWith,
			isSummaryCollapsed,
			collapsedMenuItem,
			selectedItem,
			selectedType,
			selectedAddress,
			selectedUnit,
			addressIframeLoader
		} = this.state;

		let tabContent;
		switch (activeTab) {
			case 0:
				tabContent = (
					<Addresses
						contactAddresses={contactAddresses}
						onAddressHandleChange={this.onAddressHandleChange}
						validatedAddress={validatedAddress}
						onSubmitAddresses={this.onSubmitAddresses}
						addressType={addressType}
						onChangeAddressType={this.onChangeAddressType}
						selectedAddress={selectedAddress}
						selectedUnit={selectedUnit}
						loaderMapLinkLocation={loaderMapLinkLocation}
						addressIframeLoader={addressIframeLoader}
						handleIframeLoader={this.handleIframeLoader}
						userConfiguration={userConfiguration}
						countryStatesData={countryStatesData}
						canEnterManualAddress={canEnterManualAddress}
						showModal={showModal}
						{...formProps}
					/>
				);
				break;
			case 1:
				tabContent = (
					<BundlesAndPlans
						collapsedMenuItem={collapsedMenuItem}
						setCollapsedMenuItem={this.setCollapsedMenuItem}
						selectedItem={selectedItem}
						selectedType={selectedType}
						selectPlansItems={this.selectPlansItems}
						planBundles={planBundles}
						loaderPlansAndBundles={loaderPlansAndBundles}
						plans={plans}
						validatedAddress={validatedAddress}
						addressType={addressType}
						selectedAddress={selectedAddress}
						currentLocation={currentLocation}
						{...formProps}
					/>
				);
				break;
			case 2:
				tabContent = (
					<Services
						plansDetails={plansDetails}
						currentLocation={currentLocation}
						accountInfo={accountInfo}
						getPlansDetails={getPlansDetails}
						isSummaryFullWith={isSummaryFullWith}
						isSummaryCollapsed={isSummaryCollapsed}
						handleFullWithSummary={this.handleFullWithSummary}
						handleSummaryCollapsed={this.handleSummaryCollapsed}
						{...formProps}
					/>
				);
				break;
			case 3:
				tabContent = (
					<Products
						plansDetails={plansDetails}
						accountInfo={accountInfo}
						isSummaryFullWith={isSummaryFullWith}
						isSummaryCollapsed={isSummaryCollapsed}
						handleFullWithSummary={this.handleFullWithSummary}
						handleSummaryCollapsed={this.handleSummaryCollapsed}
						validatedAddress={this.props.validatedAddress}
						{...formProps}
					/>
				);
				break;
			case 4:
				if (!isEmpty(newAccountInfo) && isEmpty(newOrderInfo)) {
					return (
						<Confirmation
							newOrderInfo={{ ...newAccountInfo, type: 'prospect' }}
							showNewOrderWizardHandler={false}
							// resetWizard={this.resetWizard}
							history={history}
							// showAccessModal={showAccessModal}
							// getTasksByOrderId={getTasksByOrderId}
							// startServiceOrder={startServiceOrder}
							// isOrderStartedData={isOrderStartedData}
							// tasksCountData={tasksCountData}
							{...formProps}
						/>
					);
				}
				var orderType = newOrderInfo.type;

				if (this.props.validatedAddress) {
					switch (this.props.validatedAddress.allowedServiceOrderType) {
						case 'NEW_SURVEY':
							orderType = 'survey';
							break;
						case 'NEW_SIGNUP':
							orderType = 'signup';
							break;
					}
				}

				tabContent = (
					<Confirmation
						newOrderInfo={{ ...newOrderInfo, type: orderType }}
						showNewOrderWizardHandler={showNewOrderWizardHandler}
						resetWizard={this.resetWizard}
						history={history}
						showAccessModal={showAccessModal}
						getTasksByOrderId={getTasksByOrderId}
						startServiceOrder={startServiceOrder}
						isOrderStartedData={isOrderStartedData}
						tasksCountData={tasksCountData}
						{...formProps}
					/>
				);
				break;
			default:
				tabContent = <Addresses />;
				break;
		}

		return tabContent;
	};

	redirectToCreateAccount = (values) => {
		const { history, plansDetails, validatedAddress } = this.props;

		const serviceOrderData = {
			formValues: values,
			planValues: plansDetails,
			isServiceOrderStarted: true,
			validatedAddress: validatedAddress
		};
		history.push('/create-account', { serviceOrderData });
	};

	setCollapsedMenuItem = (menuItem) => {
		this.setState({
			collapsedMenuItem: this.state.collapsedMenuItem === menuItem ? '' : menuItem
		});
	};

	selectPlansItems = (item, type) => {
		if (this.state.isPlanAndBundlesExecuted === true) {
			this.props.emptyPlanDetails();
		}
		this.setState((prevState) => ({
			selectedItem: this.state.selectedItem === item.id ? '' : item.id,
			selectedType: this.state.selectedItem === item.id ? '' : type,
			isPlanAndBundlesExecuted: prevState.isPlanAndBundlesExecuted === true && false
		}));
	};

	populateServiceAndLines = (values, actions) => {
		const { currentLocation, getPlansDetails, emptyPlanDetails } = this.props;

		// emptyPlanDetails();
		// here change
		values.plans.forEach((element) => {
			getPlansDetails('', currentLocation.id, element.planId).then((response) => {
				let findIndex = values.plans.findIndex((x) => x.planId === response.id);
				let servicelines = [];

				for (let index = 0; index < response.requiredLines; index++) {
					let currentServiceLine = {};
					if (
						get(response, 'serviceModel.directorySystemNumType.defaultSource') &&
						response.serviceModel.directorySystemNumType.defaultSource === 'number_inventory'
					) {
						currentServiceLine = {
							features: [],
							// devices: [],
							number: '',
							serviceLineRelation: index === 0 ? 'Main' : 'Required',
							index: index
						};
					} else {
						currentServiceLine = {
							features: [],
							// devices: [],
							serviceLineRelation: index === 0 ? 'Main' : 'Required',
							index: index
						};
					}

					// ADD DEVICES TO THE LINES
					if (!isEmpty(response.serviceModel.defaultEquipment)) {
						response.serviceModel.defaultEquipment.deviceProfiles.forEach((element) => {
							if (isEmpty(element.possibleItems)) {
								return;
							}
							let currentDevice = {
								deviceGroupId: response.serviceModel.defaultEquipment.deviceGroupId,
								deviceProfileId: element.id,
								itemId: element.possibleItems[0].id,
								itemDescription: element.possibleItems[0].description,
								itemPrice: element.possibleItems[0].itemProperties[0].listPrice
							};
							currentServiceLine['devices'] = [];
							currentServiceLine['devices'].push(currentDevice);
						});
					}

					// OLD APROACH //

					// if (!isEmpty(response.equipment)) {
					// 	response.equipment[0].deviceProfiles.forEach((element) => {
					// 		let currentDevice = {
					// 			deviceGroupId: response.equipment[0].deviceGroupId,
					// 			deviceProfileId: element.id,
					// 			itemId: element.possibleItems[0].id,
					// 			itemDescription: element.possibleItems[0].description,
					// 			itemPrice: element.possibleItems[0].itemProperties[0].listPrice
					// 		};
					// 		currentServiceLine['devices'] = [];
					// 		currentServiceLine['devices'].push(currentDevice);
					// 	});
					// }

					// ADD FEATURES TO THE LINES
					if (currentServiceLine.serviceLineRelation === 'Main') {
						if (!isEmpty(response.mainIncludedFeatures)) {
							response.mainIncludedFeatures.forEach((element) => {
								let feature = {
									...element
								};
								currentServiceLine.features.push(feature);
							});
						}
						if (!isEmpty(response.mainIncludedFeaturePackages)) {
							response.mainIncludedFeaturePackages.forEach((element) => {
								let feature;

								if (isEmpty(element.featurePackageOptions)) {
									feature = {
										featurePackageId: element.featurePackageId,
										featurePackageDescription: element.description,
										featurePackageName: element.name,
										featurePackageNote: element.note,
									};
								} else {
									feature = {
										featurePackageId: element.featurePackageId,
										featurePackageDescription: element.description,
										featurePackageName: element.name,
										featurePackageNote: element.note,
										...element.featurePackageOptions[0]
									};
								}

								currentServiceLine.features.push(feature);
							});
						}
						if (!isEmpty(response.mainMandatoryFeatures)) {
							response.mainMandatoryFeatures.forEach((element) => {
								let feature = {
									...element
								};
								currentServiceLine.features.push(feature);
							});
						}
						if (!isEmpty(response.mainMandatoryFeaturePackages)) {
							response.mainMandatoryFeaturePackages.forEach((element) => {
								let feature = {
									featurePackageId: element.featurePackageId,
									featurePackageDescription: element.description,
									featurePackageName: element.name,
									featurePackageNote: element.note,
									...element.featurePackageOptions[0]
								};

								currentServiceLine.features.push(feature);
							});
						}
					} else if (currentServiceLine.serviceLineRelation === 'Required') {
						if (!isEmpty(response.requiredIncludedFeatures)) {
							response.requiredIncludedFeatures.forEach((element) => {
								let feature = {
									...element
								};
								currentServiceLine.features.push(feature);
							});
						}
						if (!isEmpty(response.requiredIncludedFeaturePackages)) {
							response.requiredIncludedFeaturePackages.forEach((element) => {
								let feature = {
									featurePackageId: element.featurePackageId,
									featurePackageDescription: element.description,
									featurePackageName: element.name,
									featurePackageNote: element.note,
									...element.featurePackageOptions[0]
								};

								currentServiceLine.features.push(feature);
							});
						}
						if (!isEmpty(response.requiredMandatoryFeatures)) {
							response.requiredMandatoryFeatures.forEach((element) => {
								let feature = {
									...element
								};
								currentServiceLine.features.push(feature);
							});
						}
						if (!isEmpty(response.requiredMandatoryFeaturePackages)) {
							response.requiredMandatoryFeaturePackages.forEach((element) => {
								let feature = {
									featurePackageId: element.featurePackageId,
									featurePackageDescription: element.description,
									featurePackageName: element.name,
									featurePackageNote: element.note,
									...element.featurePackageOptions[0]
								};

								currentServiceLine.features.push(feature);
							});
						}
					}
					servicelines.push(currentServiceLine);
				}

				actions.setFieldValue(`plans.${findIndex}.index`, findIndex);
				actions.setFieldValue(`plans.${findIndex}.servicelines`, servicelines);
				// actions.setFieldValue(`plans.${findIndex}.defaultSource`, response.serviceModel.directorySystemNumType.defaultSource)
				actions.setFieldValue(`plans.${findIndex}.requiredLines`, response.requiredLines);
				actions.setFieldValue(`plans.${findIndex}.additionalLines`, response.additionalLines);
			});
		});

		this.setState((prevState) => ({
			activeTab: prevState.activeTab + 1,
			isPlanAndBundlesExecuted: true
		}));
	};

	// ADDRESSES TAB <== START ==>

	onChangeAddressType = (addressType) => {
		if (addressType !== this.state.addressType) {
			if (addressType === 'noAddress') {
				this.setState({
					selectedAddress: '',
					selectedUnit: ''
				})
			} else {
			}

			this.setState({
				addressType: addressType
				// selectedAddress: ''
			});
		}
	};

	onAddressHandleChange = (address, unit, setFieldValue) => {
		if (address === 'error') {
			this.setState({
				selectedAddress: '',
				selectedUnit: ''
			});
			this.props.emptyMapLinkLocation();
		} else {
			if (!isEmpty(unit)) {
				address.address2 = unit;
			}
			const addressRequest = {
				fullAddress: address
			};

			this.setState({
				selectedAddress: address.fullAddress,
				selectedUnit: unit
			});
			setFieldValue('fullAddress', address);

			if("manualAddress" != this.state.addressType) {
				this.props.getMapLinkLocation(this.props.currentLocation.id, addressRequest);
			}
		}
	};


	handleIframeLoader = (isLoading) => {
		this.setState({
			addressIframeLoader: isLoading
		})
	}

	handleFullWithSummary = () => {
		this.setState((prevState) => ({
			isSummaryFullWith: !prevState.isSummaryFullWith
		}));
	};

	handleSummaryCollapsed = () => {
		this.setState((prevState) => ({
			isSummaryCollapsed: !prevState.isSummaryCollapsed
		}));
	};

	onSubmitAddresses = async (setFieldValue) => {

		this.setState({
			activeTab: 1
		});

	};

	searchPlansAndBundles = (planCategoryId) => {
		const { validatedAddress } = this.props;

		const address = {
			address: validatedAddress.addressSearchFormated
		};

		this.props.getPlansAndBundles(this.props.currentLocation.id, address, planCategoryId);
	};

	resetWizard = (resetForm, setTouched) => {
		this.props.emptyNewOrder();
		resetForm(this.state.initialValues);
		setTouched({});

		this.setState({
			activeTab: 0,
			addressType: 'manualAddress',
			isSummaryFullWith: false,
			isSummaryCollapsed: false,
			collapsedMenuItem: 'planBundles',
			selectedItem: '',
			selectedType: '',
			isPlanAndBundlesExecuted: false
		});
	};

	prevStep = () => {
		this.setState((prevState) => ({
			activeTab: prevState.activeTab - 1
		}));
	};

	next = (values, actions) => {
		const { activeTab } = this.state;
		if (activeTab === 0) {
			if(this.state.addressType == "manualAddress"){
				const addressRequest = {
					fullAddress: values.fullAddress
				};
				this.props.getMapLinkLocation(this.props.currentLocation.id, addressRequest);
			}
			actions.setSubmitting(false);
			this.onSubmitAddresses(actions.setFieldValue);
		} else if (activeTab === 1) {
			actions.setSubmitting(false);
			if (this.state.isPlanAndBundlesExecuted === true) {
				this.setState((prevState) => ({
					activeTab: prevState.activeTab + 1
				}));
			} else {
				this.populateServiceAndLines(values, actions);
			}
		} else if (activeTab === 2) {
			actions.setSubmitting(false);
			this.setState((prevState) => ({
				activeTab: prevState.activeTab + 1
			}));
		} else if (activeTab === 3) {
			this.props.createNewOrder(values);
			this.setState((prevState) => ({
				activeTab: prevState.activeTab + 1
			}));
		}
	};

	// ADDRESSES <== END ==>

	render() {
		const { activeTab, isServiceRequestStarted, errors, addressType}  = this.state;
		const { accountStatus, validatedAddress, showModal, history } = this.props;

		return (
			<div className="cmv-wizard">
				<div className="wizard-container">
					<div className="container">
						<NavTabs activeTab={activeTab} />
						<Formik
							enableReinitialize
							initialValues={this.state.initialValues}
							validationSchema={validationSchema(activeTab, addressType)}
							onSubmit={this.next}
							errors={errors}
							render={(props) => (
								<Form onSubmit={props.handleSubmit} className="cmv-form" autoComplete="off">
									<div className="tab-content wizard-content h-100">{this.renderTabContent(props)}</div>
									<WizardButtons
										activeTab={activeTab}
										prevStep={this.prevStep}
										isServiceRequestStarted={isServiceRequestStarted}
										accountStatus={accountStatus}
										validatedAddress={validatedAddress}
										addressType={addressType}
										values={props.values}
										showModal={showModal}
										history={history}
										errors={props.errors}
										redirectToCreateAccount={this.redirectToCreateAccount}
										{...this.props}
									/>
								</Form>
							)}
						/>
					</div>
				</div>
			</div>
		);
	}
}

ServiceRequest.propTypes = {
	currentLocation: PropTypes.object,
	changeNavBarType: PropTypes.func,
	planBundles: PropTypes.array,
	plans: PropTypes.array
};

const getLoaderPlansAndBundles = createLoadingSelector(['PLANS_AND_BUNDLES']);
const getLoaderMapLinkLocation = createLoadingSelector(['MAP_LINK_LOCATION']);

const mapStateToProps = (state) => {
	const planBundles = getCreateServiceRequest(state).planBundles,
		plans = getCreateServiceRequest(state).plans,
		plansDetails = getCreateServiceRequest(state).plansDetails,
		newOrderInfo = getCreateServiceRequest(state).newOrderInfo,
		isOrderStartedData = getCreateServiceRequest(state).isOrderStarted,
		tasksCountData = getCreateServiceRequest(state).tasksCount,
		validatedAddress = getCreateServiceRequest(state).validatedAddress,
		newAccountInfo = getCreateServiceRequest(state).newAccountInfo,
		countryStatesData = getCreateAccount(state).countryStates,
		loaderPlansAndBundles = getLoaderPlansAndBundles(state),
		loaderMapLinkLocation = getLoaderMapLinkLocation(state),
		canEnterManualAddress = getUserPermission(state, 'ADMN', 'ADMN_MANUAL_ADDRESS_ENTRY');

	return {
		planBundles,
		plans,
		plansDetails,
		newOrderInfo,
		isOrderStartedData,
		tasksCountData,
		validatedAddress,
		newAccountInfo,
		loaderPlansAndBundles,
		loaderMapLinkLocation,
		countryStatesData,
		canEnterManualAddress
	};
};

const mapDispatchToProps = {
	createNewOrder,
	getPlansAndBundles,
	getPlansDetails,
	emptyNewOrder,
	emptyPlanDetails,
	showModal,
	hideModal,
	addPlans,
	getTasksByOrderId,
	startServiceOrder,
	getMapLinkLocation,
	populateMapLocation,
	emptyMapLinkLocation,
	getCountryStates
};

export default connect(mapStateToProps, mapDispatchToProps)(ServiceRequest);
