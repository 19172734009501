import {
	GET_APPOINTMENTS,
} from '../actions/appointments.actions';

let initialState = {
	appointments: [],
};

export const appointmentsReducer = (state = initialState, action) => {
	switch (action.type) {
		case GET_APPOINTMENTS.SUCCESS:
			return {
				...state,
				appointments: action.payload.troubleTicketOrderAppointments
			}
		case GET_APPOINTMENTS.CLEAR:
			return {
				...state,
				appointments: []
			}
		default:
			return state;
	}
}
