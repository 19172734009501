import axiosInstance from '../utils/axios-instance';
import { actionCreator, createRequestTypes } from '../utils/helpers';

export const GET_APPOINTMENT_AREAS = createRequestTypes('GET_APPOINTMENT_AREAS');
export const GET_MAPPING_ZONES = createRequestTypes('GET_MAPPING_ZONES');
export const ADD_MAPPING_AREAS_TO_APPOINTMENT_AREA = createRequestTypes('ADD_MAPPING_AREAS_TO_APPOINTMENT_AREA');
export const ADD_APPOINTMENT_AREA = createRequestTypes('ADD_APPOINTMENT_AREA');

export const getAppointmentAreas = (limit) => async (dispatch) => {
	dispatch(actionCreator.request(GET_APPOINTMENT_AREAS));
	const getParams = {limit : limit ? limit : 0};
	try {
        const response = await axiosInstance.get(`/appointment-mapping-areas`,{params : getParams});
		dispatch(actionCreator.success(GET_APPOINTMENT_AREAS, response.data));
		return response.data;
	} catch (err) {
		let mockError = {message: 'Something goes wrong'}
		dispatch(actionCreator.failure(GET_APPOINTMENT_AREAS, mockError));
		return err.response.data;
	}
}

export const clearAppointmentAreas = () => async (dispatch) => {
	dispatch(actionCreator.clear(GET_APPOINTMENT_AREAS))
}

export const editAppointmentArea = (data) => async (dispatch) => {
	dispatch(actionCreator.request(ADD_MAPPING_AREAS_TO_APPOINTMENT_AREA));
	try {
		const response = await axiosInstance.post(`/edit-appointment-mapping-areas`, data);
		dispatch(actionCreator.success(ADD_MAPPING_AREAS_TO_APPOINTMENT_AREA, response.data));
		return response.data;
	} catch (err) {
		let mockError = {message: 'Something goes wrong'}
		dispatch(actionCreator.failure(ADD_MAPPING_AREAS_TO_APPOINTMENT_AREA), mockError);
		return err.response.data;
	}
}

export const createAppointmentArea = (data) => async (dispatch) => {
	dispatch(actionCreator.request(ADD_APPOINTMENT_AREA));
	try {
		const response = await axiosInstance.post(`/add-appointment-area`,data);
		dispatch(actionCreator.success(ADD_APPOINTMENT_AREA, response.data));
		return response.data;
	} catch (err) {
		let mockError = {message: 'Something goes wrong'}
		dispatch(actionCreator.failure(ADD_APPOINTMENT_AREA, mockError));
		return err.response.data;
	}
}

export const getMappingZones = () => async (dispatch) => {
	dispatch(actionCreator.request(GET_MAPPING_ZONES));
	try {
		const response = await axiosInstance.get(`/appointment-mapping-zones`);
		dispatch(actionCreator.success(GET_MAPPING_ZONES, response.data));
		return response.data;
	} catch (err) {
		let mockError = {message: 'Something goes wrong'}
		dispatch(actionCreator.failure(GET_MAPPING_ZONES, mockError));
		return err.response.data;
	}
}
