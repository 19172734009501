import React, { Component, Fragment } from 'react';
import { get } from "lodash";
import { createLoadingSelector } from "../../../../../../../selectors";
import { sendAuthCode, verifyAuthCode } from "../../../../../../../actions/createAccount.actions";
import { connect } from "react-redux";

export class NumberInput extends Component {
    state = {
        authCode: '',
        showVerificationMobileNumber: false,
        isAuthCodeValid: null,
        authCodeLoader: false,
        authCodeVerifyLoader: false
    }

    onSubmitAuthCode = (number) => {
        let data = {
            phoneNumber: number,
            requester: 'CAMVIO-WEB'
        };

        this.setState({ authCodeLoader: true }, () => {
            this.props.sendAuthCode(data).then((response) => {
                this.setState({
                    showVerificationMobileNumber: true,
                    isAuthCodeValid: null,
                    authCode: '',
                    authCodeLoader: false
                });
            });
        });
    };

    onChangeAuthCodeHandler = (e) => {
        this.setState({
            authCode: e.target.value
        });
    };

    onSubmitVerifyAuthCode = () => {
        let { numberObject } = this.props;

        let data = {
            authcode: this.state.authCode,
            phoneNumber: numberObject.number,
            requester: 'CAMVIO-WEB',
            updateContactPhone: true,
            contactNumberId: numberObject.id
        };

        this.setState({ authCodeVerifyLoader: true }, () => {
            this.props.verifyAuthCode(data).then((response) => {
                if (response.data.code === 200) {
                    this.setState({ isAuthCodeValid: true, authCodeVerifyLoader: false });
                }
                if (response.data.error) {
                    this.setState({ isAuthCodeValid: false, authCodeVerifyLoader: false });
                }
            });
        });
    };


    render() {
        const {
            fieldName,
            fieldType,
            numberObject,
            contactId,
            isEditable,
            isDisabled,
            showSendButton,
            handleCloseInput,
            handleEditField,
            handleUpdatePrimaryNumbers,
            inputRef,
        } = this.props;

        const { isAuthCodeValid, showVerificationMobileNumber, authCode, authCodeLoader, authCodeVerifyLoader } = this.state;

        return (
            <div className="form-group form-row">

                <div className="col-sm-3 col-xl-4">
                    {fieldType}
                </div>

                <div className="col-sm-9 col-xl-8">
                    <div className="row no-gutters verification-row">
                        {isEditable !== fieldName ? (
                            <Fragment>

                                {numberObject && numberObject.verified && (
                                <button
                                    type="button"
                                    className="btn btn-xs btn-verify-value"
                                    disabled={true}
                                >
                                    <i className="fas fa-check-circle text-success" />
                                </button>
                                )}

                                <div className="col-sm-5">
                                    <a
                                        data-type="text"
                                        className="editable-generic-text"
                                        onClick={() => handleEditField(fieldName)}
                                    >
                                        {numberObject ? numberObject.number ? numberObject.number : 'Not provided' : 'Not provided'}
                                    </a>
                                </div>

                                <div className="input-authcode-status">

                                    {authCodeLoader && (
                                    <span className="input-group-text">
                                        <i className="fas fa-circle-notch fa-spin ml-1 mr-1" />&nbsp;Requesting&hellip;
                                    </span>
                                    )}

                                    {authCodeVerifyLoader && (
                                    <span className="input-group-text">
                                        <i className="fas fa-circle-notch fa-spin ml-1 mr-1" />&nbsp;Verifying&hellip;
                                    </span>
                                    )}


                                    {isAuthCodeValid === true && !authCodeLoader && (
                                    <span className="input-group-text text-success">
                                        <i className="fas fa-check" />&nbsp;Valid
                                    </span>
                                    )}

                                </div>

                                {showSendButton && (numberObject ? !numberObject.verified : true) &&
                                <div className={'input-group-append input-authcode-send' + (
                                    !showVerificationMobileNumber && !authCodeLoader && !authCodeVerifyLoader
                                        ? '' : ' d-none')}
                                >
                                    <button
                                        className="btn btn-xs btn-outline-secondary btn-authcode-send"
                                        type="button"
                                        disabled={!numberObject || !numberObject.number || numberObject.number.length === 0}
                                        onClick={() =>
                                            this.onSubmitAuthCode(
                                                numberObject.number
                                            )}
                                    >
                                        <i className="fas fa-paper-plane" /> Send
                                    </button>
                                </div>
                                }

                                <div className={'input-group-append input-authcode-send' + (
                                    isAuthCodeValid === false && !authCodeLoader
                                        ? '' : ' d-none')}
                                >
                                    <button
                                        className="btn btn-xs btn-outline-secondary btn-authcode-send"
                                        type="button"
                                        disabled={!numberObject || !numberObject.number || numberObject.number.length === 0}
                                        onClick={() =>
                                            this.onSubmitAuthCode(
                                                numberObject.number
                                            )}
                                    >
                                        <i className="fas fa-paper-plane" /> Resend
                                    </button>
                                </div>

                                <div className={"row no-gutters w-100" + (
                                    showVerificationMobileNumber && !authCodeLoader && !authCodeVerifyLoader && isAuthCodeValid === null
                                        ? '' : ' d-none' )}
                                >

                                    <div className="col-sm-6 input-authcode-input">
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text">
                                                    <i className="fas fa-key" />
                                                </span>
                                            </div>
                                            <input
                                                type="text"
                                                name="authCode"
                                                value={authCode}
                                                onChange={this.onChangeAuthCodeHandler}
                                                className="form-control w-120px authcode-key-phone"
                                                placeholder="AuthCode"
                                            />
                                        </div>
                                    </div>

                                    <div className="col-sm-6">
                                        <div className={'input-group-append input-authcode-validate' + (
                                            showVerificationMobileNumber && !authCodeLoader && !authCodeVerifyLoader && isAuthCodeValid === null
                                                ? '' : ' d-none')}
                                        >

                                            <button
                                                className="btn btn-xs btn-outline-secondary btn-authcode-validate"
                                                type="button"
                                                disabled={!authCode || authCode.length === 0}
                                                onClick={() => this.onSubmitVerifyAuthCode()}
                                            >
                                                <i className="fas fa-check" /> Verify
                                            </button>

                                            <button
                                                className="btn btn-xs btn-outline-secondary btn-authcode-send"
                                                type="button"
                                                disabled={!numberObject || !numberObject.number || numberObject.number.length === 0}
                                                onClick={() =>
                                                    this.onSubmitAuthCode(
                                                        numberObject.number
                                                    )}
                                            >
                                                <i className="fas fa-paper-plane" /> Resend
                                            </button>

                                        </div>
                                    </div>

                                </div>

                                <div className="row no-gutters w-100">
                                    <div className="input-authcode-status">

                                        {isAuthCodeValid === false && !authCodeLoader && (
                                        <span className="status-text text-danger">
                                            <i className="fas fa-exclamation-triangle" />&nbsp;Invalid
                                        </span>
                                        )}

                                    </div>
                                </div>

                            </Fragment>
                        ) : (
                                <div className="input-group input-group-editable">
                                    <input
                                        name={fieldName}
                                        className="form-control"
                                        defaultValue={numberObject ? numberObject.number : ''}
                                        ref={inputRef}
                                        disabled={isDisabled}
                                        autoComplete={'off'}
                                    />
                                    <div className="input-group-append">
                                        {/* <button type="button" className="btn btn-editable-clear">
								<i className="fas fa-backspace" />
							</button> */}
                                        <button
                                            type="button"
                                            onClick={() => handleUpdatePrimaryNumbers(fieldType, fieldName, numberObject, contactId)}
                                            className="btn btn-primary btn-editable-apply"
                                            disabled={isDisabled}
                                        >
                                            {!isDisabled ? (
                                                <i className="fas fa-check" />
                                            ) : (
                                                    <i className="fas fa-spin fa-circle-notch" />
                                                )}
                                        </button>
                                        <button
                                            type="button"
                                            onClick={handleCloseInput}
                                            className="btn btn-outline-secondary btn-editable-cancel"
                                            disabled={isDisabled}
                                        >
                                            <i className="fas fa-times" />
                                        </button>
                                    </div>
                                </div>
                            )}

                    </div>

                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = {
    sendAuthCode,
    verifyAuthCode
};

export default connect(mapStateToProps, mapDispatchToProps)(NumberInput);
