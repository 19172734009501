import React, { Component, Fragment } from 'react';

export class Checking extends Component {
	render() {
		return (
			<Fragment>
				<div className="form-check checkbox-slider checkbox-slider--b-flat">
					<label>
						<input type="radio" name="customer-pmnt-acc-type" id="" checked="" />
						<span>Checking Account</span>
					</label>
				</div>
				<div className="form-check checkbox-slider checkbox-slider--b-flat">
					<label>
						<input type="radio" name="customer-pmnt-acc-type" id="" />
						<span>Savings Account</span>
					</label>
				</div>
				<div className="row">
					<div className="form-group col-sm-6">
						<label>Routing Number</label>
						<input type="number" className="form-control" placeholder="Routing Number" />
					</div>
					<div className="form-group col-sm-6">
						<label>Bank Account Number</label>
						<input type="number" className="form-control" placeholder="Bank Account Number" />
					</div>
				</div>
			</Fragment>
		);
	}
}

export default Checking;
