import React from 'react';

const NavTabs = ({ activeTab, changeTabHandler }) => {
	return (
		<div className="wizard-navigation">
			<ul className="wizard-navigation-nav">
				<li className={"wizard-navigation-item" + (activeTab === 0 ? ' active' : '')}>
					<a className={'wizard-navigation-link'}>
                         Physical Address
					</a>
				</li>
				<li className={"wizard-navigation-item" + (activeTab === 1 ? ' active' : '')}>
					<a className={'wizard-navigation-link'}>
                        Bundles &amp; Plans
					</a>
				</li>
				<li className={"wizard-navigation-item" + (activeTab === 2 ? ' active' : '')}>
					<a className={'wizard-navigation-link'}>
                        Services
					</a>
				</li>
				<li className={"wizard-navigation-item" + (activeTab === 3 ? ' active' : '')}>
					<a className={'wizard-navigation-link'}>
                        Products
					</a>
				</li>
				<li className={"wizard-navigation-item" + (activeTab === 4 ? ' active' : '')}>
					<a className={'wizard-navigation-link'}>
						Confirmation
					</a>
				</li>
			</ul>
		</div>
	);
};

export default NavTabs;
