import React from "react";
import ApiAutoComplete from "./ApiAutoComplete";

class LocationSearchInput extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <ApiAutoComplete
                selectedAddress={this.props.selectedAddress}
                selectedUnit={this.props.selectedUnit}
                onAddressHandleChange={this.props.onAddressHandleChange}
                {...this.props}
            />
        );
    }
}

export default LocationSearchInput;
