import {SET_PAYMENT_SUBMITTED, SET_SERVICES_UPDATED} from "../actions/globalFlag.actions";

let initialState = {
	paymentSubmittedFlag: false,
	servicesUpdatedFlag: false,
};

export const globalFlagsReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_SERVICES_UPDATED.SUCCESS:
			return {
				...state,
				servicesUpdatedFlag: !state.servicesUpdatedFlag
			}
		case SET_PAYMENT_SUBMITTED.SUCCESS:
			return {
				...state,
				paymentSubmittedFlag: !state.paymentSubmittedFlag
			}

		default:
			return state;
	}
};
