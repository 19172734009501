import React, {Component} from 'react';
import {connect} from "react-redux";
import {getServiceLineHistory} from '../../../../../../actions/accountDetailsActions/servicesAndEquipment.actions';
import ServiceSummary from './ServiceSummary';
import moment from 'moment';
import isEmpty from "../../../../../../utils/helpers";
import Loader from "../../../../../../components/Loader";

export class ServiceWrapper extends Component {

    state = {
        isLoading: true,
        serviceLine: {},
    }

    componentDidMount(prevProps, prevState, snapshot) {

        // Load historical data if end date
        if (this.props.serviceLine.endDate) {

            this.props.getServiceLineHistory(this.props.serviceLine.serviceLineId).then(response => {
                this.setState({
                    isLoading: false,
                    serviceLine: response,
                });
            });
        }
        else {

            this.setState({
                isLoading: false,
                serviceLine: this.props.serviceLine,
            });
        }
    }

    render() {

        const {
            serviceLine,
        } = this.state;

        const {
            showHideLineById,
            expandedLineId,
        } = this.props;

        let serviceAgreementPeriodPercents = 0;

        if (serviceLine.serviceLineAgreement) {
        	const totalPeriod = moment(serviceLine.serviceLineAgreement.startDate).add(serviceLine.serviceLineAgreement.length, 'months').diff(moment(serviceLine.serviceLineAgreement.startDate), 'weeks');
        	const fromStartPeriod = moment().diff(moment(serviceLine.serviceLineAgreement.startDate), 'weeks');
        	serviceAgreementPeriodPercents = (fromStartPeriod / totalPeriod) * 100;

        	if (serviceAgreementPeriodPercents === 0) {
        		serviceAgreementPeriodPercents = 1;
			}
		}

        if (isEmpty(serviceLine)) {
            return <Loader/>;
        }

        return (
            <div className="service-wrapper">

                <div className="service-header">

					<span
                        onClick={() => showHideLineById(serviceLine.serviceLineId)}
                        className={"service-title service-body-toggler" + (expandedLineId === serviceLine.serviceLineId ? '' : ' service-body-closed')}
                    >
						{serviceLine.number || "Service Line"}
                        {" "}
                        {"("}
                        {serviceLine.startDate && moment(serviceLine.startDate).format('MM/DD/YYYY')}
                        {" - "}
                        {serviceLine.endDate ? moment(serviceLine.endDate).format('MM/DD/YYYY') : "Now"}
                        {")"}
					</span>

                    <span className="service-toolbar">
						<span className="badge badge-outline-secondary">{serviceLine.planRelation}</span>
						<span className={"badge" + (
                            serviceLine.status === "Active" ? " badge-success"
                                : serviceLine.status === "Deactivated" ? " badge-danger"
                                    : " badge-secondary"
                        )}>
                            {serviceLine.status}
					    </span>
					</span>

                </div>

                <div className={"service-body" + (expandedLineId === serviceLine.serviceLineId ? '' : ' collapse')}>

                    {serviceLine.address &&
                    <div className="services-summary mt-0">
                        <div className="services-summary-body">
                            <div className="plan-wrapper">
                                <div className="serviceline-wrapper">
                                    <div className="plan-section">
                                        <h5>Address</h5>
                                        <div className="package-wrapper">
                                            <div className="package-option">
                                                <span className="po-title">{serviceLine.address}
                                                    {serviceLine.address2 ? ", " + serviceLine.address2 : ''}
                                                    {serviceLine.city ? ", " + serviceLine.city : ''}
                                                    {serviceLine.state ? (serviceLine.state.abbreviation ? ", " + serviceLine.state.abbreviation : '') : ''}
                                                    {serviceLine.zipcode ? ", " + serviceLine.zipcode : ''}
                                                    {serviceLine.zipcode && serviceLine.zip4 ? "-" + serviceLine.zip4 : ''}
                                                </span>
                                                <span className="po-price"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    }

                    <div className="service-usage">
                        <div className="row">
                            <div className="col-lg">
                                <div className="cmv-progress-bar">

                                    <div className="progress-header">
										<span className="ph-left">
											<i className="far fa-fw fa-calendar-alt"/> Service Agreement
										</span>
                                        {serviceLine.serviceLineAgreement ?
                                        <span className="ph-right">
                                            {serviceLine.serviceLineAgreement.description},&nbsp;
                                            {moment(serviceLine.serviceLineAgreement.startDate).format('MM/DD/YYYY')} &mdash;
                                            {moment(serviceLine.serviceLineAgreement.startDate).add(serviceLine.serviceLineAgreement.length, 'months').format('MM/DD/YYYY')}
                                        </span>
                                        :
                                        <span className="ph-right">N/A</span>
                                        }
                                    </div>

                                    <div className="progress">
                                        {serviceLine.serviceLineAgreement &&
                                        <div
                                            className="progress-bar bg-info"
                                            role="progressbar"
                                            style={{ width: serviceAgreementPeriodPercents + '%' }}
                                            aria-valuenow="4"
                                            aria-valuemin="0"
                                            aria-valuemax="100"
                                        />
                                        }
                                    </div>

                                    <div className="progress-footer">
                                        {serviceLine.serviceLineAgreement &&
                                        <p className="mb-0 text-right">{moment(serviceLine.serviceLineAgreement.startDate).add(serviceLine.serviceLineAgreement.length, 'months').diff(moment(), 'weeks')} weeks remaining</p>
                                        }
                                    </div>

                                </div>
                            </div>
                            <div className="col-lg">
                                <div className="cmv-progress-bar">
                                    <div className="progress-header">
										<span className="ph-left">
											<i className="far fa-fw fa-calendar-alt"/> Service Period
										</span>
                                        <span
                                            className="ph-right">Started {moment(serviceLine.startDate).format('MM/DD/YYYY')}</span>
                                    </div>
                                    <div className="progress">
                                        <div
                                            className="progress-bar bg-info"
                                            role="progressbar"
                                            style={{width: '100%'}}
                                            aria-valuenow="100"
                                            aria-valuemin="0"
                                            aria-valuemax="100"
                                        />
                                    </div>
                                    <div className="progress-footer">
                                        <p className="mb-0 text-right">{moment().diff(moment(serviceLine.startDate), 'weeks')} weeks
                                            ago</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <ServiceSummary service={serviceLine} />

                </div>

            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
    };
};

const mapDispatchToProps = {
    getServiceLineHistory,
};

export default connect(mapStateToProps, mapDispatchToProps)(ServiceWrapper);
