let initialState = {
	error: {
		errorType: '',
		errorCode: '',
		errorMessage: ''
	}
};

export const globalErrorReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'ERROR_500':
			return {
				...state,
				error: action.payload
			};
		case 'ERROR_400':
			return {
				...state,
				errorType: 'ERROR_400'
			};
		default:
			return state;
	}
};
