import {getAdmin} from "../../../selectors";
import React, {Component} from "react";
import Select from 'react-select';
import {connect} from 'react-redux';
import { toastr } from 'react-redux-toastr';
import Loader from "../../../components/Loader";
import { getSystemLocations } from "../../../actions/admin.actions";
import {Formik} from "formik";
import TextInput from "../../../components/UI/TextInput";
import axiosInstance from "../../../utils/axios-instance";
import { getUserPermission } from "../../../selectors";
import {showModal} from "../../../actions/modal.actions";
import { formSelectStyles } from "../../../utils/SelectStyles";
const asOptions = (source, mapper) => {
    if (!Array.isArray(source)) {
        return [];
    }

    return source.map(mapper);
}

const serviceModelsAsOptions = (serviceModels) => asOptions(serviceModels, serviceModel => {
    return {
        label: serviceModel.name,
        value: serviceModel.id
    };
});
class PortalConfiguration extends Component {
    state={
        locations:this.props.getSystemLocations(),
        location:{
            label: "",
            value: 0
        },
        portalConfig:[],
        isLoading:false,
        dataLoaded:false,
        data:{},
        saveLoading:false
    }

    componentDidMount() {
        document.title = 'Portal Configuration - camvio.cloud';
        this.props.changeNavBarType('default', 'Portal Configuration');
    }

    onSetCurrentLocation = (formProps) => {
        const d={};
        if(formProps.value!==0){
            this.setState({isLoading:true})
            axiosInstance(`/portal/portalConfiguration/${formProps.value}`, {
                headers: {
                    accept: '*/*'
                }
            }).then((res)=>{
                this.setState({portalConfig:res.data.portalConfigurations,isLoading:false})
                res.data.portalConfigurations.length!==0&&res.data.portalConfigurations.map((respo)=>d[respo.id]=respo.value)
                d&&this.setState({data:d});
            })
        }else{
            toastr.error('Please select one option', { position: 'top-center' });
        }
    }

    onConfigurationSaveBtnClick = (values,updatedProps) => {

        this.props.showModal('GENERIC_MODAL', {
            title: "Save Configuration",
            text: "Are you sure you want to make these changes?",
            size: 'lg',
            cancelText: "Cancel",
            okText: "Save",
            onOk: () => this.savePortalConfiguration(values),
            component: () => (
                <table className="table">
                    <thead>
                    <th>name</th>
                    <th>old value</th>
                    <th>new value</th>
                    </thead>
                    <tbody>
                    {updatedProps.map(prop =>
                        <tr>
                            <td>{prop.key}</td>
                            <td>{prop.oldVal}</td>
                            <td>{prop.newVal}</td>
                        </tr>
                    )}
                    </tbody>
                </table>
            )
        });
    }

    savePortalConfiguration = (values) => {
        let flag=false;
        this.state.portalConfig.map((data)=>{
            if(data.value!==values[data.id]){
                this.setState({saveLoading:true,isLoading:true})
                const obj={
                    "description":data.description ,
                    "id": data.id,
                    "key": data.key,
                    "locationId": data.locationId,
                    "value":values[data.id]
                }
                axiosInstance.patch(`/portal/portalConfiguration`, [obj]).then((res)=>{
                    if(res.status===200){
                        const d={};
                        !flag&&toastr.success('Your Data is Updated', { timeOut: 3000, position: 'top-center' });
                        flag=true;
                        axiosInstance(`/portal/portalConfiguration/${this.state.location.value}`, {
                            headers: {
                                accept: '*/*'
                            }
                        }).then((res)=>{
                            this.setState({portalConfig:res.data.portalConfigurations,isLoading:false})
                            res.data.portalConfigurations.length!==0&&res.data.portalConfigurations.map((respo)=>d[respo.id]=respo.value)
                            d&&this.setState({data:d});
                        })
                    }
                    this.setState({saveLoading:false})
                });
            }
        })
    }

    render() {
        const {
            showConfig,editConfig,adminShowConfig
        } = this.props;

        return (
            <div className="cmv-container cmv-container-dashboard cmv-container-client-selected">
                {showConfig&&!!adminShowConfig ? <Formik
                    initialValues={this.state.data}
                    enableReinitialize={true}
                    onSubmit={this.handleSubmit}
                    render={(formProps) => {
                        const {handleChange, handleSubmit, handleBlur, values, errors, touched, isSubmitting, setFieldValue, validateForm} = formProps;

                        const portalConfig = this.state.portalConfig;
                        const updatedProps = [];
                        portalConfig &&
                        portalConfig.forEach(origProp => {
                            const currProp = values[origProp.id];
                            if (currProp && currProp !== origProp.value) {
                                updatedProps.push({ key: origProp.key, oldVal: origProp.value, newVal: currProp });
                            }
                        });

                        return (
                            <>
                                <form onSubmit={handleSubmit}>
                                    <div className="form-vertical">
                                        <div className="form-group">
                                            <label htmlFor={"location"}>Location</label>
                                            <Select
                                                styles={formSelectStyles}
                                                name={"location"}
                                                options={serviceModelsAsOptions(this.props.systemLocations)}
                                                isMulti={false}
                                                value={values.location}
                                                // isDisabled={this.state.dataLoaded}
                                                onChange={(event)=>{this.setState({location:event});this.onSetCurrentLocation(event)}}
                                                placeholder={"Select one..."}
                                            />
                                        </div>
                                        {((this.state.isLoading && <Loader />)
                                            || (!this.state.isLoading&&(this.state.portalConfig.length!==0?
                                                <div className='card-vertical card-plane'>
                                                    <div className='card-body'>
                                                        <div className='plan-header plan-header-centered'>
                                                            <span className="plan-title"><i className="fas fa-fw fa-cogs"/>Portal Configuration</span>
                                                        </div>
                                                        <div className='plan-body'>
                                                            <div className="form-group service-wrapper">
                                                                {this.state.portalConfig && this.state.portalConfig.map((prop, idx) => {

                                                                    return (
                                                                        <TextInput
                                                                            label={prop.description}
                                                                            type="text"
                                                                            name={prop.id}
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            value={values[prop.id]||''}
                                                                            disabled={!editConfig}
                                                                            autocomplete={false}
                                                                        />
                                                                    );
                                                                })}
                                                            </div>
                                                        </div>
                                                        <div style={{textAlign: 'center'}}>
                                                            <h3 style={{color:'lightgreen'}}>{this.state.conformation}</h3>
                                                        </div>
                                                        <div style={{textAlign: 'right'}}>
                                                            <button
                                                                onClick={() => {this.onConfigurationSaveBtnClick(values,updatedProps)}}
                                                                disabled={editConfig&&(this.state.saveLoading||updatedProps.length==0)}
                                                                type="button"
                                                                className="btn btn-primary">
                                                                {this.state.saveLoading&&
                                                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" style={{marginRight: '5px'}}/>}
                                                                Save
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>:
                                                <div className='card-vertical card-plane' style={{color:'#212529',textAlign:'center',marginTop:'30px'}}>
                                                    <h2 style={{fontSize:'1.2em',color: '#343a40',fontWeight:'500'}}>No Data Found</h2>
                                                </div>)))
                                        }
                                    </div>
                                </form>
                            </>
                        )}}/>:<h2 style={{textAlign:'center'}}>You Don't have Permision to Access this Page</h2>
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const systemLocations = getAdmin(state).systemLocations,
        showConfig=getUserPermission(state, 'WEB_MENU', 'SHOW_PORTAL_CONFIG'),
        editConfig=getUserPermission(state, 'ADMN', 'ADMN_EDIT_PORTAL_CONFIG'),
        adminShowConfig=getUserPermission(state, 'ADMN', 'ADMN_VIEW_PORTAL_CONFIG')

    return {
        systemLocations,
        showConfig,
        editConfig,
        adminShowConfig
    };
};

const mapDispatchToProps = {
    getSystemLocations,
    showModal
};

export default connect(mapStateToProps, mapDispatchToProps)(PortalConfiguration);
