import {
	CREATE_ORDER,
	PLANS_AND_BUNDLES,
	PLANS_DETAILS,
	EMPTY_NEW_ORDER,
	SERVICE_REQUEST_START_ORDER,
	SERVICE_REQUEST_PENDING_TASKS_COUNT,
	MAP_LINK_LOCATION
} from '../../actions/accountDetailsActions/newOrder.actions';

let initialState = {
	newOrderInfo: {},
	planBundles: [],
	plans: [],
	plansDetails: [],
	isOrderStarted: null,
	tasksCount: 0,
	validatedAddress: null
};

export const newOrder = (state = initialState, action) => {
	switch (action.type) {
		case CREATE_ORDER.SUCCESS:
			return {
				...state,
				newOrderInfo: action.payload
			};
		case PLANS_AND_BUNDLES.SUCCESS:
			return {
				...state,
				planBundles: action.payload.planBundles,
				plans: action.payload.plans
			};
		case PLANS_DETAILS.SUCCESS:
			return {
				...state,
				plansDetails: [ ...state.plansDetails, action.payload ]
			};
		case PLANS_DETAILS.EMPTY:
			return {
				...state,
				plansDetails: []
			};
		case SERVICE_REQUEST_START_ORDER.SUCCESS:
			return {
				...state,
				isOrderStarted: action.payload
			};
		case SERVICE_REQUEST_START_ORDER.EMPTY:
			return {
				...state,
				isOrderStarted: null
			};
		case SERVICE_REQUEST_PENDING_TASKS_COUNT.SUCCESS:
			return {
				...state,
				tasksCount: action.payload
			};
		case 'EMPTY_NEW_ORDER':
			return {
				...initialState
			};
		case MAP_LINK_LOCATION.SUCCESS:
			return {
				...state,
				validatedAddress: action.payload.validatedAddress
			};
		case MAP_LINK_LOCATION.FAILURE:
			return {
				...state,
				validatedAddress: action.payload
			};
		case MAP_LINK_LOCATION.EMPTY:
			return {
				...state,
				validatedAddress: null
			}
		default:
			return state;
	}
};
