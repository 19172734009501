import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Chart } from 'react-chartjs-2';
import { connect } from 'react-redux';
import { getBalancesInfo, emptyBalancesInfo,getInstallmentPlans } from '../../../../../../actions/accountDetailsActions/billing.actions';
import { getAccountDetails, createLoadingSelector } from '../../../../../../selectors';
import { checkBalanceChart, checkDepositChart, checkInvoicesChart, checkInvoicesCenter,checkInstallmentBalanceChart,checkInstallmentCenter,InstallmentBalanceChartLabels } from './Chart/utils';
import { isEmpty } from '../../../../../../utils/helpers';

import BalanceChart from './Chart/GenericChart';
import Loader from '../../../../../../components/Loader';

Chart.pluginService.register({
	beforeDraw: function(chart) {
		if (chart.config.options.elements.center) {
			//Get ctx from string
			var ctx = chart.chart.ctx;

			//Get options from the center object in options
			var centerConfig = chart.config.options.elements.center;
			var fontStyle = centerConfig.fontStyle || 'Fira Sans';
			var txt = centerConfig.text;
			// var txtSubtitle = centerConfig.textSubtitle;
			var color = centerConfig.color || '#000';
			var sidePadding = centerConfig.sidePadding || 20;
			var sidePaddingCalculated = sidePadding / 100 * (chart.innerRadius * 2);
			//Start with a base font of 30px
			ctx.font = '24px ' + fontStyle;

			//Get the width of the string and also the width of the element minus 10 to give it 5px side padding
			var stringWidth = ctx.measureText(txt).width;
			var elementWidth = chart.innerRadius * 2 - sidePaddingCalculated;

			// Find out how much the font can grow in width.
			var widthRatio = elementWidth / stringWidth;
			var newFontSize = Math.floor(30 * widthRatio);
			var elementHeight = chart.innerRadius * 2;

			// Pick a new font size so it will not be larger than the height of label.
			var fontSizeToUse = Math.min(newFontSize, elementHeight);

			//Set font settings to draw it correctly.
			ctx.textAlign = 'center';
			ctx.textBaseline = 'middle';
			var centerX = (chart.chartArea.left + chart.chartArea.right) / 2;
			var centerY = (chart.chartArea.top + chart.chartArea.bottom) / 2;
			// ctx.font = fontSizeToUse+"px " + fontStyle;
			ctx.font = fontStyle;
			ctx.fillStyle = color;

			//Draw text in center
			ctx.fillText(txt, centerX, centerY);
			// ctx.fillText(txtSubtitle, centerX, centerY + 25);
		}
	}
});

class Balances extends Component {
	componentDidMount() {
		this.props.getBalancesInfo(this.props.accountInfo.id);
		this.props.getInstallmentPlans(this.props.accountInfo.id);
	}
	componentWillUnmount() {
		this.props.emptyBalancesInfo();
	}

	render() {
		const { balancesData } = this.props;

		if (isEmpty(balancesData)) {
			return <Loader />;
		}

		const balanceChart = {
			labels: [ 'Default', 'Unpaid Invoices', 'Account Credit' ],
			data: checkBalanceChart(balancesData),
			backgroundColor: [ '#ccc', '#bf433d', '#95ba55' ],
			center: balancesData.accountBalance
		};

		const depositsChart = {
			labels: [ 'Default', 'Account Deposit' ],
			data: checkDepositChart(balancesData.depositBalance),
			backgroundColor: [ '#ccc', '#95ba55', '#f6e954', '#e5b93b', '#bf433d', '#564484' ],
			center: balancesData.depositBalance
		};

		const installmentChart = {
			labels: InstallmentBalanceChartLabels(this.props.installmentplans),
			data: checkInstallmentBalanceChart(this.props.installmentplans),
			backgroundColor: ['#95ba55', '#f6e954', '#e5b93b', '#bf433d', '#564484' ],
			center: checkInstallmentCenter(this.props.installmentplans)
		};

		const invoicesChart = {
			labels: [ 'Default', 'Current', '30-59 Days Old', '60-89 Days Old', '90-119 Days Old', '120+ Days Old' ],
			data: checkInvoicesChart(balancesData.invoicesAging),
			backgroundColor: [ '#ccc', '#95ba55', '#f6e954', '#e5b93b', '#bf433d', '#564484' ],
			center: checkInvoicesCenter(balancesData.invoicesAging)
		};

		return (
			<Fragment>
				<div className="row">
					<div className="col-sm-4">
						<div className="card card-sm">
							<div className="card-header">
								Balance
								{/* <span>Due 1/12/2019</span> */}
							</div>
							<div className="card-body">
								<div className="cmv-container-chart cnv-container-chart-sm">
									<BalanceChart info={balanceChart} />
								</div>
							</div>
						</div>
					</div>

					<div className="col-sm-4">
						<div className="card card-sm">
							<div className="card-header">Unpaid Invoices</div>
							<div className="card-body">
								<div className="cmv-container-chart cnv-container-chart-sm">
									<BalanceChart info={invoicesChart} />
								</div>
							</div>
						</div>
					</div>
					<div className="col-sm-4">
						<div className="card card-sm">
							<div className="card-header">
								Deposits
								{/* <span>1/1/2017</span> */}
							</div>
							<div className="card-body">
								<div className="cmv-container-chart cnv-container-chart-sm">
									<BalanceChart info={depositsChart} />
								</div>
							</div>
						</div>
					</div>
						{checkInstallmentCenter(this.props.installmentplans)>0&&<div className="col-sm-4">
						<div className="card card-sm">
							<div className="card-header">
								InstallmentPlan
								{/* <span>1/1/2017</span> */}
							</div>
							<div className="card-body">
								<div className="cmv-container-chart cnv-container-chart-sm">
									<BalanceChart info={installmentChart} />
								</div>
							</div>
						</div>
					</div>}
				</div>
			</Fragment>
		);
	}
}

Balances.propTypes = {
	accountInfo: PropTypes.object.isRequired,
	balancesData: PropTypes.object.isRequired
};
const loader = createLoadingSelector([ 'SET_BALANCES_INFO' ]);

const mapStateToProps = (state) => {
	const balancesData = getAccountDetails(state).billing.balancesInfo,
		  installmentplans = getAccountDetails(state).billing.installmentplans,
		balancesLoader = loader(state);

	return {
		balancesData,
		balancesLoader,
		installmentplans
	};
};

const mapDispatchToProps = {
	getBalancesInfo,
	emptyBalancesInfo,
	getInstallmentPlans
};
export default connect(mapStateToProps, mapDispatchToProps)(Balances);
