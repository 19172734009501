import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
	getTasksByOrderId,
	getTasksByServiceOrder,
	getTasksByTroubleTicketId,
	getTasksByUsername,
	emptyTasksList
} from '../../actions/tasksList.actions';
import { getSystemUsers, emptySystemUsers } from '../../actions/dashboardActions/tasks.actions';
import { assignTask, unassignTask } from '../../actions/tasksList.actions';
import { getUser, getAuthData, getTasksList, getDashboardData, createLoadingSelector } from '../../selectors';
import TasksResults from '../Dashboard/Content/Tasks/TasksResults';
import isEmpty from '../../utils/helpers';

export class TasksList extends Component {

	state = {
		showTaskDropDownById: '',
		isFetched: false
	};

	componentDidMount() {

		const { user, username, location, getTasksByOrderId, getTasksByServiceOrder, getTasksByTroubleTicketId, getTasksByUsername } = this.props;

		// Change navbar type and color
		document.title = 'Task List - camvio.cloud';
		this.props.changeNavBarType('default', 'Task List');

		if (location.search) {
			let query = location.search.substring(location.search.indexOf('?') + 1);

			let params = query.split('=');
			if (params[0] === 'orderId') {
				getTasksByOrderId(params[1]);
			} else if (params[0] === 'serviceOrderId') {
				getTasksByServiceOrder(params[1]);
			} else if (params[0] === 'troubleTicketId') {
				getTasksByTroubleTicketId(params[1]);
			}
		} else {
			getTasksByUsername(user.username);
		}

		// Get first name and last name for the assign in dropdown
		if (!isEmpty(user.userGroups)) {
			let userGroups = user.userGroups.map((userGroup) => userGroup.name).join(',');

			let systemUsersGroups = {
				userGroups: userGroups
			};

			this.props.getSystemUsers(systemUsersGroups);
		}
	}

	componentDidUpdate(prevProps) {
		if (prevProps.location !== this.props.location) {
			const { location, user, getTasksByOrderId, getTasksByServiceOrder,getTasksByTroubleTicketId } = this.props;
			this.setState({
				isFetched: false
			});

			if (location.search) {
				let query = location.search.substring(location.search.indexOf('?') + 1);

				let params = query.split('=');
				if (params[0] === 'orderId') {
					getTasksByOrderId(params[1]);
				} else if (params[0] === 'serviceOrderId') {
					getTasksByServiceOrder(params[1]);
				} else if (params[0] === 'troubleTicketId') {
					getTasksByTroubleTicketId(params[1]);
				}
			} else {
				this.props.getTasksByUsername(user.username);
			}
		}
		if (prevProps.tasksListData !== this.props.tasksListData) {
			this.setState({
				isFetched: true
			});
		}
	}

	componentWillUnmount() {
		this.props.emptySystemUsers();
		this.props.emptyTasksList();
	}

	handleShowHideDropdown = (id) => {
		this.setState({
			showTaskDropDownById: this.state.showTaskDropDownById === id ? '' : id
		});
	};

	handleAssignTaskToUsername = (type, taskId, dropdownValue) => {
		const { location, user, assignTask, unassignTask } = this.props;

		let apiCallTypeBy = 'username';
		let apiCallParams = user.username;

		if (location.search) {
			let query = location.search.substring(location.search.indexOf('?') + 1);

			let params = query.split('=');
			if (params[0] === 'orderId') {
				apiCallTypeBy = 'orderId'
				apiCallParams= params[1];
			} else if (params[0] === 'serviceOrderId') {
				apiCallTypeBy ='serviceOrderId'
				apiCallParams= params[1];
			}
		}

		if (type === 'claim') {
			const data = {
				taskId,
				username: user.username
			};

			assignTask(data, apiCallParams, apiCallTypeBy);
		} else if (type === 'unassign') {
			const data = {
				taskId
			};

			unassignTask(data, apiCallParams, apiCallTypeBy);
		} else if (type === 'username') {
			const data = {
				taskId,
				username: dropdownValue.value
			};

			assignTask(data, apiCallParams, apiCallTypeBy);
		}

		this.setState({
			showTaskDropDownById: ''
		});
	};

	render() {
		const { showTaskDropDownById, isFetched } = this.state;
		const { tasksListData, systemUsersData, taskLoader } = this.props;


		return (
			<div className="cmv-container cmv-container-user-tasks">
				<TasksResults
					userTasksData={tasksListData}
					systemUsersData={systemUsersData}
					taskLoader={taskLoader}
					isFetched={isFetched}
					showTaskDropDownById={showTaskDropDownById}
					handleShowHideDropdown={this.handleShowHideDropdown}
					handleAssignTaskToUsername={this.handleAssignTaskToUsername}
				/>
			</div>
		);
	}
}

const tasksListLoader = createLoadingSelector([ 'SET_TASKS_LIST' ]);

const mapStateToProps = (state) => {
	const tasksListData = getTasksList(state).tasksList,
		systemUsersData = getDashboardData(state).systemUsers,
		user = getUser(state).user,
		taskLoader = tasksListLoader(state);

	return {
		tasksListData,
		systemUsersData,
		user,
		taskLoader
	};
};

const mapDispatchToProps = {
	getTasksByUsername,
	getTasksByOrderId,
	getTasksByServiceOrder,
	getTasksByTroubleTicketId,
	getSystemUsers,
	assignTask,
	unassignTask,
	emptyTasksList,
	emptySystemUsers
};

export default connect(mapStateToProps, mapDispatchToProps)(TasksList);
