import React from 'react';
import PropTypes from 'prop-types';

const NavPilsItem = ({ pillName, pillHeader, activeTab, changeTabHandler }) => {
	return (
		<li onClick={() => changeTabHandler(pillName)} className="nav-item">
			<a className={activeTab === pillName ? 'nav-link active' : 'nav-link'} role="tab">
				{pillHeader}
			</a>
		</li>
	);
};

NavPilsItem.propTypes = {
	pillName: PropTypes.string,
	pillHeader: PropTypes.string,
	activeTab: PropTypes.string,
	changeTabHandler: PropTypes.func
};

export default NavPilsItem;
