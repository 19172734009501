export const statusColor = (tier) => {
    switch (tier) {
        case 0:
            return 'secondary';
        case 1:
            return 'danger';
        case 2:
            return 'warning';
        case 3:
            return 'warning';
        case 4:
            return 'success';
        case 5:
            return 'success';
        default:
            return 'secondary';
    }
};