import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {hideModal} from '../../../actions/modal.actions';
import FormikInput from "../../../components/Common/Inputs/FormikInput";
import {getAccountDetails, getModalData} from '../../../selectors';
import {Formik} from 'formik';
import * as Yup from "yup";
import {updateServiceLineAddress, getCountryStates} from "../../../actions/accountDetailsActions/security.actions";
import {updateServiceOrderAddress} from "../../../actions/accountDetailsActions/editServiceRequest.actions"
import { getServiceOrderAddressInformation, getServiceLineAddressInformation,
    validateServiceLineAddressInformation} from "../../../actions/accountDetailsActions/accountDetails.actions";
import FormSelect from "../../../components/UI/FormSelect";
import isEmpty from "../../../utils/helpers";
import Loader from "../../../components/Loader";
import {getServicesAndEquipment} from "../../../actions/accountDetailsActions/servicesAndEquipment.actions";
import {toastr} from "react-redux-toastr";
import {getUserPermission} from "../../../actions/user.actions";
import AccountHolderLoader from "../../../components/Loader/AccountHolderLoader";
import {generateFullZip} from "../../CreateAccount/utils";
import AddressInput from "../../AccountDetails/Panels/RightPanel/Tabs/Security/Inputs/AddressInput";

class ServiceAddressModal extends Component {

    state = {
        stateOptions: {},
        qualifiedServices: {},
        addressInformation: '',
        isAddressLoading: true,
        showSuggestedAddress: false,
        addressValidationLoader: false,
        validatedMappingInformation: '',
        suggestedAddressError: '',
    }

    componentDidMount() {
        if(this.props.modalProps.type === "SERVICE_ORDER") {
            this.props.getServiceOrderAddressInformation(this.props.modalProps.id).then((response) => {
                console.log(response)
                if(!isEmpty(response.serviceOrderAddress)) {
                    this.setState({
                        addressInformation: this.props.addressInformation.serviceOrderAddress,
                        isAddressLoading: false,
                    })
                }else{
                    this.setState({
                        addressInformation: this.props.modalProps.service,
                        isAddressLoading: false
                    })
                }
            });
        }else if(this.props.modalProps.type === "SERVICE_LINE") {
            this.props.getServiceLineAddressInformation(parseInt(this.props.modalProps.id)).then((response) => {
                if(!isEmpty(response.servicelineAddress)) {
                    this.setState({
                        addressInformation: this.props.addressInformation.servicelineAddress,
                        isAddressLoading: false
                    })
                }else{
                    this.setState({
                        addressInformation: this.props.modalProps.service,
                        isAddressLoading: false
                    })
                }
            });
        }else{
            this.setState({
                addressInformation: this.props.modalProps.service,
                isAddressLoading: false
            })
        }
        this.props.getCountryStates();
    }

    componentDidUpdate(prevProps, prevState) {

        const {
            modalProps,
            countryStates
        } = this.props;

        let addressInformation = {...this.state.addressInformation}

        if (countryStates !== prevProps.countryStates && !isEmpty(countryStates)) {

            let stateOptions = [];
            let stateId = "";

            // Generate dropdown options
            countryStates.map((state) => {
                stateOptions.push({label: state.name, value: state.id, abbreviation: state.abbreviation});
            });

            // Get state Id
            if (modalProps && modalProps.service.state && modalProps.service.state.name != "") {
                stateId = countryStates.find((state) => state.name === modalProps.service.state.name).id;
            }


            addressInformation.stateId = stateId;

            // Store dropdown options & state Id
            this.setState({
                stateOptions: stateOptions,
                stateId: stateId,
            });
        }
    }

    closeModal = () => {
        this.props.hideModal();
        if (this.props.modalProps.onCloseCallback) {
            this.props.modalProps.onCloseCallback();
        }
    };

    goBack = () => {
        this.props.hideModal();
    };

    getNumberType = (numbers, type) => {
        if (!numbers) {
            return undefined;
        }
        return numbers.find((x) => x.numberType === type);
    };

    getType = (object, type) => {
        if (!object) {
            return undefined;
        }

        return object.find((x) => x.type === type);
    };

    formSubmit = (values, actions) => {
        const {modalProps, countryStates} = this.props;

        let id = modalProps.id;
        let addressId = modalProps.addressId;

        let updatedValues = {...values};

        if (updatedValues['stateId']) {
            updatedValues['state'] = countryStates.find((state) => state.id === updatedValues['stateId']).name;
        }

        if (updatedValues['state']) {
            updatedValues['stateId'] = countryStates.find((state) => state.name === updatedValues['state']).id;
            updatedValues['countryCode'] = countryStates.find((state) => state.name === updatedValues['state']).countryCode;
        }
        else {
            updatedValues['stateId'] = '';
            updatedValues['countryCode'] = '';
        }

        let data = {
            address1: updatedValues.address1 || '',
            address2: updatedValues.address2 || '',
            address3: updatedValues.address3 || '',
            zipcode: updatedValues.zipcode || '',
            zip4: updatedValues.zip4 || '',
            city: updatedValues.city || '',
            stateId: updatedValues.stateId || '',
            countryCode: updatedValues.countryCode || '',
            latitude: updatedValues.latitude || '',
            longitude: updatedValues.longitude || '',
            mappingRefArea1: updatedValues.mappingRefArea1 || '',
            mappingAreaId: updatedValues.mappingAreaId || '',
            mappingAddressId: updatedValues.mappingAddressId || '',
            mappingNote: updatedValues.mappingNote || '',
            csiGeocode: updatedValues.csiGeocode || ''
        };
        const method = this.props.modalProps.type === 'SERVICE_ORDER' ? this.props.updateServiceOrderAddress :
            this.props.updateServiceLineAddress;

        method(id, addressId, data).then(response => {
            actions.setSubmitting(false)

            if (response.success) {
                this.closeModal();
                toastr.success(response.message, {timeOut: 2000, position: 'top-center'});
                this.props.getServicesAndEquipment(this.props.accountId);
            }
            else {
                toastr.error(response.error.message, {timeOut: 0, position: 'top-center'});
            }
        });
    }

    validateAddress = (values) => {
        const {modalProps, countryStates} = this.props;
        this.setState({
            addressValidationLoader: true,
            showSuggestedAddress: false
        });

        let state = countryStates.find((state) => state.id === values['stateId']).name;
        let stateAbbreviation = countryStates.find((state) => state.id === values['stateId']).abbreviation;

        let address = {
            fullAddress: (values.address1 ? values.address1 : '') + (values.city ? ("," + values.city) : '')
            + (stateAbbreviation ? ( "," + stateAbbreviation) : ''),
            address1: values.address1,
            address2: values.address2,
            address3: values.address3,
            city: values.city,
            country: values.countryCode,
            state: state,
            stateId: values.stateId,
            stateAbbreviation: stateAbbreviation,
            zip: values.zipcode
        }
        this.props.validateServiceLineAddressInformation(address).then(response => {
            this.setState({
                addressValidationLoader: false,
                showSuggestedAddress: true,
                validatedMappingInformation: response.validatedMappingInformation
            })
        });
    }


    scrollDropdownIntoView = (e) => {
        const elementClicked = e.target.nodeName;
        const boundingElement = e.currentTarget;
        const modalBody = document.getElementsByClassName('modal-body')[0];

        if (elementClicked !== 'LABEL') {

            setTimeout(function () {

                // Scroll down if the bottom is hidden...
                if (boundingElement.getBoundingClientRect().bottom > modalBody.getBoundingClientRect().bottom) {

                    // ... and the top won't end up hidden by scrolling down
                    if (boundingElement.getBoundingClientRect().height < modalBody.getBoundingClientRect().height) {

                        // Scroll down till bottom of element reaches bottom of screen
                        boundingElement.scrollIntoView({block: "end"});
                    }
                }
            }, 200);
        }
    }

    closeAddressSuggestion = () => {
        this.setState({
            addressValidationLoader: false,
            showSuggestedAddress: false
        });
    };

    useSuggestedInformation = (setFieldValue, stateOptions) => {
        Object.keys(this.state.validatedMappingInformation).forEach((element) => {
            setFieldValue(`${element}`, `${this.state.validatedMappingInformation[element]}`.trim());
        });
        // Hide suggested address and Verify Address
        this.setState({showSuggestedAddress: false,
            verified: true});


    };

    render() {

        const {
            stateOptions,
            stateId,
            addressInformation,
            isAddressLoading,
            showSuggestedAddress,
            addressValidationLoader,
            suggestedAddressError,
            validatedMappingInformation
        } = this.state;

        const {
            modalProps,
            countryStates
        } = this.props;

        return (
            <Fragment>
                {isAddressLoading &&
                <div className="modal" style={{display: 'block'}} tabIndex="-1" role="dialog">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">{modalProps.title}</h5>
                                <button onClick={this.closeModal} type="button" className="close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <Loader/>
                            </div>
                            <div className="modal-footer">
                                <button
                                    onClick={this.closeModal}
                                    className="btn btn-primary">
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                    )}
                    />
                </div>
                }
                {!isAddressLoading &&
                <div className="modal" style={{display: 'block'}} tabIndex="-1" role="dialog">
                    <Formik
                        initialValues={{
                            address1: addressInformation.address1 || '',
                            address2: addressInformation.address2 || '',
                            address3: addressInformation.address3 || '',
                            city: addressInformation.city || '' ,
                            zipcode: addressInformation.zipcode || '',
                            zip4: addressInformation.zip4 || '',
                            state: addressInformation.state.name || '',
                            countryCode: addressInformation.state.countryCode || '',
                            stateId: addressInformation.state.id || '',
                            latitude:addressInformation.latitude || '',
                            longitude: addressInformation.longitude || '',
                            mappingRefArea1: addressInformation.mappingRefArea1 || '',
                            mappingAreaId: addressInformation.mappingAreaId || '',
                            mappingAddressId: addressInformation.mappingAddressId || '',
                            mappingNote: addressInformation.mappingNote || '',
                            csiGeocode: addressInformation.csiGeocode || '',
                            externalUrl: addressInformation.externalUrl || '',
                            qualifiedServices: addressInformation.qualifiedServices || '',
                            savedAddressZoneValues: addressInformation.savedAddressZoneValues || ''
                        }}
                        onSubmit={this.formSubmit}
                        render={({
                                     handleChange,
                                     handleSubmit,
                                     handleBlur,
                                     values,
                                     errors,
                                     touched,
                                     isSubmitting,
                                     setFieldValue
                                 }) => (
                            <form onSubmit={handleSubmit} className="cmv-form">
                                <div className="modal-dialog">
                                    <div className="modal-content">

                                        <div className="modal-header">
                                            <h5 className="modal-title">{modalProps.title}</h5>
                                            <button onClick={this.closeModal} type="button" className="close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>

                                        <div className="modal-body">

                                            {isEmpty(countryStates)
                                                ?
                                                <Loader/>
                                                :
                                                <div className="form-horizontal">

                                                    <fieldset>

                                                        <legend className="as-label">Service Address</legend>

                                                        <FormikInput
                                                            wrapperClass="form-group"
                                                            label="Line 1"
                                                            type="text"
                                                            name={`address1`}
                                                            onBlur={handleBlur}
                                                            value={values.address1}
                                                            placeholder="Not provided"
                                                            errors={errors}
                                                            touched={touched}
                                                            onChange={handleChange}
                                                            disabled={isSubmitting || modalProps.isDisabled}
                                                        />

                                                        <FormikInput
                                                            wrapperClass="form-group"
                                                            label="Line 2"
                                                            type="text"
                                                            name={`address2`}
                                                            onBlur={handleBlur}
                                                            value={values.address2}
                                                            placeholder="Not provided"
                                                            errors={errors}
                                                            touched={touched}
                                                            onChange={handleChange}
                                                            disabled={isSubmitting || modalProps.isDisabled}
                                                        />

                                                        <FormikInput
                                                            wrapperClass="form-group"
                                                            label="Line 3"
                                                            type="text"
                                                            name={`address3`}
                                                            onBlur={handleBlur}
                                                            value={values.address3}
                                                            placeholder="Not provided"
                                                            errors={errors}
                                                            touched={touched}
                                                            onChange={handleChange}
                                                            disabled={isSubmitting || modalProps.isDisabled}
                                                        />

                                                        <FormikInput
                                                            wrapperClass="form-group"
                                                            label="City"
                                                            type="text"
                                                            name={`city`}
                                                            onBlur={handleBlur}
                                                            value={values.city}
                                                            placeholder="Not provided"
                                                            errors={errors}
                                                            touched={touched}
                                                            onChange={handleChange}
                                                            disabled={isSubmitting || modalProps.isDisabled}
                                                        />

                                                        <div className="form-group">
                                                            <div onClick={this.scrollDropdownIntoView}>
                                                                <FormSelect
                                                                    title="State"
                                                                    fieldName="stateId"
                                                                    placeholder="Select one..."
                                                                    options={stateOptions}
                                                                    setFieldValue={setFieldValue}
                                                                    value={values.stateId}
                                                                    onBlur={handleBlur}
                                                                    errors={errors}
                                                                    touched={touched}
                                                                    isDisabled={isSubmitting || modalProps.isDisabled}
                                                                />
                                                            </div>
                                                        </div>

                                                        <FormikInput
                                                            wrapperClass="form-group"
                                                            label="Zipcode"
                                                            type="text"
                                                            name={`zipcode`}
                                                            onBlur={handleBlur}
                                                            value={values.zipcode}
                                                            placeholder="Not provided"
                                                            errors={errors}
                                                            touched={touched}
                                                            onChange={handleChange}
                                                            disabled={isSubmitting || modalProps.isDisabled}
                                                        />

                                                        <FormikInput
                                                            wrapperClass="form-group"
                                                            label="Zip+4 code"
                                                            type="text"
                                                            name={`zip4`}
                                                            onBlur={handleBlur}
                                                            value={values.zip4}
                                                            placeholder="Not provided"
                                                            errors={errors}
                                                            touched={touched}
                                                            onChange={handleChange}
                                                            disabled={isSubmitting || modalProps.isDisabled}
                                                        />

                                                        <FormikInput
                                                            wrapperClass="form-group"
                                                            label="Country"
                                                            type="text"
                                                            name={`countryCode`}
                                                            onBlur={handleBlur}
                                                            value={values.countryCode}
                                                            placeholder="Not provided"
                                                            errors={errors}
                                                            touched={touched}
                                                            disabled={true}
                                                        />

                                                    </fieldset>

                                                    <fieldset>

                                                        <legend className="as-label">Mapping Information</legend>

                                                        <FormikInput
                                                            wrapperClass="form-group"
                                                            label="Latitude"
                                                            type="text"
                                                            name={`latitude`}
                                                            onBlur={handleBlur}
                                                            value={values.latitude}
                                                            placeholder="Not provided"
                                                            errors={errors}
                                                            touched={touched}
                                                            onChange={handleChange}
                                                            disabled={isSubmitting || modalProps.isDisabled}
                                                        />

                                                        <FormikInput
                                                            wrapperClass="form-group"
                                                            label="Longitude"
                                                            type="text"
                                                            name={`longitude`}
                                                            onBlur={handleBlur}
                                                            value={values.longitude}
                                                            placeholder="Not provided"
                                                            errors={errors}
                                                            touched={touched}
                                                            onChange={handleChange}
                                                            disabled={isSubmitting || modalProps.isDisabled}
                                                        />

                                                        <FormikInput
                                                            wrapperClass="form-group"
                                                            label="Reference Area 1"
                                                            type="text"
                                                            name={`mappingRefArea1`}
                                                            onBlur={handleBlur}
                                                            value={values.mappingRefArea1}
                                                            placeholder="Not provided"
                                                            errors={errors}
                                                            touched={touched}
                                                            onChange={handleChange}
                                                            disabled={isSubmitting || modalProps.isDisabled}
                                                        />

                                                        <FormikInput
                                                            wrapperClass="form-group"
                                                            label="Area ID"
                                                            type="text"
                                                            name={`mappingAreaId`}
                                                            onBlur={handleBlur}
                                                            value={values.mappingAreaId}
                                                            placeholder="Not provided"
                                                            errors={errors}
                                                            touched={touched}
                                                            onChange={handleChange}
                                                            disabled={isSubmitting || modalProps.isDisabled}
                                                        />


                                                        <FormikInput
                                                            wrapperClass="form-group"
                                                            label="Address ID"
                                                            type="text"
                                                            name={`mappingAddressId`}
                                                            onBlur={handleBlur}
                                                            value={values.mappingAddressId}
                                                            placeholder="Not provided"
                                                            errors={errors}
                                                            touched={touched}
                                                            onChange={handleChange}
                                                            disabled={isSubmitting || modalProps.isDisabled}
                                                        />

                                                        {values.qualifiedServices && values.qualifiedServices.length > 0 &&
                                                            <div className="form-group">
                                                                <div onClick={this.scrollDropdownIntoView}>
                                                                    <FormSelect
                                                                        title="Qualified Services"
                                                                        placeholder="View"
                                                                        options={values.qualifiedServices && values.qualifiedServices.map((service) => {
                                                                            return {label: service, value: service}
                                                                        })}
                                                                        setFieldValue={setFieldValue}
                                                                        onBlur={handleBlur}
                                                                        errors={errors}
                                                                        touched={touched}
                                                                        //isDisabled={isSubmitting || modalProps.isDisabled}
                                                                    />
                                                                </div>
                                                            </div>
                                                        }

                                                        <FormikInput
                                                            wrapperClass="form-group"
                                                            label="Note"
                                                            type="text"
                                                            name={`mappingNote`}
                                                            onBlur={handleBlur}
                                                            value={values.mappingNote}
                                                            placeholder="Not provided"
                                                            errors={errors}
                                                            touched={touched}
                                                            onChange={handleChange}
                                                            disabled={isSubmitting || modalProps.isDisabled}
                                                        />

                                                        {values.externalUrl &&
                                                        <div className="form-group">
                                                            <a href={values.externalUrl} target="_blank">External
                                                                Url</a>
                                                        </div>
                                                        }


                                                    </fieldset>
                                                    {modalProps.canViewCSIGeocode &&
                                                    <fieldset>
                                                        <legend className="as-label">CSI</legend>
                                                        <FormikInput
                                                            wrapperClass="form-group"
                                                            label="GeoCode"
                                                            type="text"
                                                            name={`csiGeocode`}
                                                            onBlur={handleBlur}
                                                            value={values.csiGeocode}
                                                            placeholder="Not provided"
                                                            errors={errors}
                                                            touched={touched}
                                                            onChange={handleChange}
                                                            disabled={!modalProps.canEditCSIGeocode}
                                                        />
                                                    </fieldset>
                                                    }
                                                    {(modalProps.service.zoneValues || modalProps.service.addressValues || values.savedAddressZoneValues) &&
                                                    <fieldset>
                                                        <legend className="as-label">Configured Attributes</legend>
                                                        {values.savedAddressZoneValues ?
                                                            <>
                                                                {values.savedAddressZoneValues.map((savedAddressZoneValue) => {
                                                                    return(
                                                                        <FormikInput
                                                                            wrapperClass="form-group"
                                                                            label={savedAddressZoneValue.key}
                                                                            type="text"
                                                                            name={savedAddressZoneValue.key}
                                                                            onBlur={handleBlur}
                                                                            value={savedAddressZoneValue.value}
                                                                            placeholder="Not provided"
                                                                            errors={errors}
                                                                            touched={touched}
                                                                            onChange={handleChange}
                                                                            disabled={true}
                                                                        />
                                                                    )
                                                                })}
                                                            </>
                                                            :
                                                            ''
                                                        }
                                                        {modalProps.service.zoneValues ?
                                                            <>
                                                                {Object.keys(modalProps.service.zoneValues).map((key, i) => {
                                                                    return(
                                                                        <FormikInput
                                                                            wrapperClass="form-group"
                                                                            label={key}
                                                                            type="text"
                                                                            name={key}
                                                                            onBlur={handleBlur}
                                                                            value={modalProps.service.zoneValues[key]}
                                                                            placeholder="Not provided"
                                                                            errors={errors}
                                                                            touched={touched}
                                                                            onChange={handleChange}
                                                                            disabled={true}
                                                                        />
                                                                    )
                                                                })}
                                                            </>
                                                            :
                                                            ''
                                                        }
                                                        {modalProps.service.addressValues ?
                                                            <>
                                                                {Object.keys(modalProps.service.addressValues).map((key, i) => {
                                                                    return(
                                                                        <FormikInput
                                                                            wrapperClass="form-group"
                                                                            label={key}
                                                                            type="text"
                                                                            name={key}
                                                                            onBlur={handleBlur}
                                                                            value={modalProps.service.addressValues[key]}
                                                                            placeholder="Not provided"
                                                                            errors={errors}
                                                                            touched={touched}
                                                                            onChange={handleChange}
                                                                            disabled={true}
                                                                        />
                                                                    )
                                                                })}
                                                            </>
                                                            :
                                                            ''
                                                        }
                                                    </fieldset>
                                                    }

                                                </div>
                                            }
                                            {addressValidationLoader ? (
                                                <AccountHolderLoader/>
                                            ) : (
                                                showSuggestedAddress && (
                                                    <Fragment>
                                                        &nbsp;
                                                        {suggestedAddressError ? (
                                                            <div className="cmv-container-subpanel cmv-container-subpanel-suggested-address">
                                                                <h5>Address Error</h5>
                                                                <p>{suggestedAddressError}</p>{' '}
                                                                <div className="d-flex justify-content-end mt-1">
                                                                    <button
                                                                        onClick={this.closeAddressSuggestion}
                                                                        className="btn btn-outline-primary mr-1 btn-address-keep"
                                                                        type="button"
                                                                    >
                                                                        Keep Entered
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <div className="cmv-container-subpanel cmv-container-subpanel-suggested-address">
                                                                <h5>
                                                                    <i className="fas fa-map"/> Validated Mapping Information
                                                                </h5>

                                                                {isEmpty(validatedMappingInformation) &&
                                                                <address>
                                                                    <div className="form-group form-row">
                                                                        <div>No mapping information found</div>
                                                                    </div>
                                                                </address>
                                                                }
                                                                {!isEmpty(validatedMappingInformation) &&
                                                                <address>
                                                                    {validatedMappingInformation.zipcode &&
                                                                    <div className="form-group form-row">
                                                                        <div className="col-sm-5"><h6>Zipcode:</h6></div>
                                                                        <div className="col-sm-7">{validatedMappingInformation.zipcode}</div>
                                                                    </div>
                                                                    }
                                                                    {validatedMappingInformation.latitude &&
                                                                    <div className="form-group form-row">
                                                                        <div className="col-sm-5"><h6>Latitude:</h6></div>
                                                                        <div className="col-sm-7">{validatedMappingInformation.latitude}</div>
                                                                    </div>
                                                                    }
                                                                    {validatedMappingInformation.longitude &&
                                                                    <div className="form-group form-row">
                                                                        <div className="col-sm-5"><h6>Longitude:</h6>
                                                                        </div>
                                                                        <div
                                                                            className="col-sm-7">{validatedMappingInformation.longitude}</div>
                                                                    </div>
                                                                    }
                                                                    {validatedMappingInformation.mappingRefArea1 &&
                                                                    <div className="form-group form-row">
                                                                        <div className="col-sm-5"><h6>Reference Area 1:</h6></div>
                                                                        <div
                                                                            className="col-sm-7">{validatedMappingInformation.mappingRefArea1}</div>
                                                                    </div>
                                                                    }
                                                                    {validatedMappingInformation.mappingAreaId &&
                                                                    <div className="form-group form-row">
                                                                        <div className="col-sm-5"><h6>Area Id:</h6>
                                                                        </div>
                                                                        <div
                                                                            className="col-sm-7">{validatedMappingInformation.mappingAreaId}</div>
                                                                    </div>
                                                                    }
                                                                    {validatedMappingInformation.mappingAddressId &&
                                                                    <div className="form-group form-row">
                                                                        <div className="col-sm-5"><h6>Address Id:</h6>
                                                                        </div>
                                                                        <div
                                                                            className="col-sm-7">{validatedMappingInformation.mappingAddressId}</div>
                                                                    </div>
                                                                    }
                                                                    {validatedMappingInformation.mappingNote &&
                                                                    <div className="form-group form-row">
                                                                        <div className="col-sm-5"><h6>Note:</h6></div>
                                                                        <div
                                                                            className="col-sm-7">{validatedMappingInformation.mappingNote}</div>
                                                                    </div>
                                                                    }
                                                                    <br/>
                                                                </address>
                                                                    }
                                                                <div className="d-flex justify-content-end mt-1">
                                                                    <button
                                                                        onClick={this.closeAddressSuggestion}
                                                                        className="btn btn-outline-primary mr-1 btn-address-keep"
                                                                        type="button"
                                                                    >
                                                                        Keep Entered
                                                                    </button>
                                                                    {!isEmpty(validatedMappingInformation) &&
                                                                    <button
                                                                        type="button"
                                                                        onClick={this.useSuggestedInformation.bind(this, setFieldValue)}
                                                                        className="btn btn-primary btn-address-use-suggested"
                                                                    >
                                                                        Use Suggested
                                                                    </button>
                                                                    }
                                                                </div>
                                                            </div>
                                                        )}
                                                    </Fragment>
                                                )
                                            )}


                                        </div>
                                            {modalProps.isDisabled ?
                                                <div className="modal-footer">
                                                    <button
                                                        onClick={this.closeModal}
                                                        className="btn btn-primary">
                                                        Close
                                                    </button>
                                                </div>
                                                :
                                                <div className="modal-footer">
                                                    <button onClick={this.closeModal} className="btn" type="button">
                                                        Cancel
                                                    </button>
                                                    <button onClick={() => this.validateAddress(values)} className="btn btn-outline-secondary"  type="button">
                                                        Validate
                                                    </button>
                                                    <button
                                                        disabled={isSubmitting || isEmpty(countryStates) || !isEmpty(errors)}
                                                        className="btn btn-primary" type="submit">
                                                        Save
                                                    </button>
                                                </div>
                                            }
                                    </div>
                                </div>
                            </form>
                        )}
                    />
                </div>
                }

                <div className="modal-backdrop show" tabIndex="1"/>

            </Fragment>
        );
    }
}

const mapStateToProps = (state) => {

    const modalProps = getModalData(state).modalProps;
    const accountId = getAccountDetails(state).accountDetails.accountInfo.id;
    const countryStates = getAccountDetails(state).security.countryStates;
    const addressInformation = getAccountDetails(state).accountDetails.addressInformation;

    return {
        modalProps,
        accountId,
        countryStates,
        addressInformation
    };
};

const mapDispatchToProps = {
    hideModal,
    updateServiceLineAddress,
    updateServiceOrderAddress,
    getCountryStates,
    getServiceOrderAddressInformation,
    getServiceLineAddressInformation,
    validateServiceLineAddressInformation,
    getServicesAndEquipment,
};

export default connect(mapStateToProps, mapDispatchToProps)(ServiceAddressModal);
