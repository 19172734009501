import React, { Component } from 'react';
// import SummaryContainer from './SummaryContainer';
import SummaryContainer from '../../../../components/UI/NewOrder/SummaryContainer';
import ProductsPlan from '../../../../components/UI/NewOrder/ProductsPlan';

class Products extends Component {
	render() {
		const { plansDetails, values, isSummaryFullWith,accountInfo } = this.props;
		
		return (
			<div className="tab-pane active">
				<div className="row">
					<div
						className={
							isSummaryFullWith === true ? (
								'col-lg-9 col-xl-8 services-available d-none'
							) : (
								'col-lg-9 col-xl-8 services-available'
							)
						}
					>
						<div className="accordion accordion-services-available">
							{plansDetails.map((plan) => (
								<ProductsPlan
									key={plan.id}
									planInfo={plan}
									planIndex={values.plans.findIndex((x) => x.planId === plan.id)}
									{...this.props}
								/>
							))}
						</div>
					</div>
					<SummaryContainer {...this.props} accountInfo= {accountInfo} tab='Products' />
				</div>
			</div>
		);
	}
}

export default Products;
