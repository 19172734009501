import {
	PAYMENT_PROVIDER_FIND_PROVIDER, PAYMENT_PROVIDER_FIND_ALL,
	PAYMENT_PROVIDER_FIND_CURRENT_PROVIDER_ID, PAYMENT_PROVIDER_FIND_ALL_IDS

} from "../actions/paymentProvider.actions";

const initialState = {
	currentProviderId: undefined,
	providerIds: undefined,
	providers: undefined,
};

export const paymentProviderReducer = (state = initialState, action) => {
	switch (action.type) {
		case PAYMENT_PROVIDER_FIND_CURRENT_PROVIDER_ID.SUCCESS:
			return {
				...state,
				currentProviderId: action.payload
			};
		case PAYMENT_PROVIDER_FIND_CURRENT_PROVIDER_ID.EMPTY:
			return {
				...state,
				currentProviderId: undefined
			};
		case PAYMENT_PROVIDER_FIND_ALL_IDS.SUCCESS:
			return {
				...state,
				providerIds: action.payload
			};
		case PAYMENT_PROVIDER_FIND_ALL_IDS.EMPTY:
			return {
				...state,
				providerIds: undefined
			};
		case PAYMENT_PROVIDER_FIND_PROVIDER.SUCCESS:
			return {
				...state,
				providers: {
					...state.providers,
					[action.payload.id]: action.payload
				}
			};
		case PAYMENT_PROVIDER_FIND_PROVIDER.EMPTY:
			if (!state.providers) {
				return state;
			}

			const providers = { ...state.providers };
			delete providers[action.payload];

			return {
				...state,
				providers
			};
		case PAYMENT_PROVIDER_FIND_ALL.EMPTY:
			return {
				...state,
				providers: undefined
			}
		default:
			return state;
	}
};
