import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { hideModal } from '../../../actions/modal.actions';
import {
	getTaxCodes,
	createTaxExemption,
	updateTaxExemption,
	emptyTaxCodes
} from '../../../actions/accountDetailsActions/billing.actions';
import { getAccountDetails, getModalData } from '../../../selectors';
import { Formik } from 'formik';
import * as Yup from 'yup';

const AdjustmentSchema = Yup.object().shape({
	taxCodeId: Yup.string().required('Required'),
	type: Yup.string().required('Required')
});

class TaxExemptionsModal extends Component {
	state = {
		showAlertMessage: false,
		alertMessage: ''
	};

	componentDidMount() {
		this.props.getTaxCodes();
	}

	componentWillUnmount() {
		this.props.emptyTaxCodes();
	}

	formSubmit = (values, actions) => {
     
		if (this.props.modalProps.type === 'create') {
			this.props.createTaxExemption(this.props.accountId, values).then((response) => {
				if (response.data.success) {
					this.props.hideModal();
				} else {
					actions.setSubmitting(false);
					this.setState({
						showAlertMessage: true,
						alertMessage: response.data.error && response.data.error.message
					});
				}
			});
		} else {
			
			this.props.updateTaxExemption(this.props.accountId, this.props.modalProps.row.id, values).then((response) => {
				if (response.data.success) {
					this.props.hideModal();
				} else {
					actions.setSubmitting(false);
					this.setState({
						showAlertMessage: true,
						alertMessage: response.data.error && response.data.error.message
					});
				}
			});
		}
	};

	render() {
		const { showAlertMessage, alertMessage } = this.state;
		const { modalProps, taxCodesData } = this.props;
		return (
			<Fragment>
				<div className="modal" style={{ display: 'block' }} tabIndex="-1" role="dialog">
					<Formik
						initialValues={{
							taxCodeId: (modalProps.row && modalProps.row.taxCodeId) || '',
							type: (modalProps.row && modalProps.row.type.toUpperCase()) || ''
						}}
						enableReinitialize
						validationSchema={AdjustmentSchema}
						onSubmit={this.formSubmit}
						render={({
							handleChange,
							handleSubmit,
							handleBlur,
							values,
							errors,
							touched,
							isSubmitting,
							setFieldValue,
							setValues
						}) => (
							<form onSubmit={handleSubmit}>
								<div className="modal-dialog" role="document">
									<div className="modal-content">
										<div className="modal-header">
											<h5 className="modal-title">
												{modalProps.type === 'create' ? (
													'Add Tax Exemption'
												) : (
													'Edit Tax Exemption'
												)}
											</h5>
											<button onClick={this.props.hideModal} type="button" className="close">
												<span aria-hidden="true">&times;</span>
											</button>
										</div>
										<div className="modal-body cmv-form">
											{showAlertMessage === true && (
												<div className="alert alert-inline alert-danger alert-dismissible">
													<p className="mb-0">{alertMessage}</p>
												</div>
											)}
											<div className="form-group">
												<label>Tax code</label>
												<select
													className={
														errors.taxCodeId && touched.taxCodeId ? (
															'form-control selector-reccredit is-invalid'
														) : (
															'form-control selector-reccredit'
														)
													}
													placeholder="Please select serviceline"
													value={values.taxCodeId}
													onChange={handleChange}
													onBlur={handleBlur}
													name="taxCodeId"
												>
													<option value="">Please select Item</option>
													{taxCodesData.map((code) => (
														<option value={code.id} key={code.id}>
															{code.description}
														</option>
													))}
												</select>
												{errors.taxCodeId &&
												touched.taxCodeId && (
													<div className="invalid-feedback">{errors.taxCodeId}</div>
												)}
											</div>
											<div className="form-row">
												<div className="form-group col">
													<div className="form-check checkbox-slider checkbox-slider--b-flat">
														<label>
															<input
																type="checkbox"
																checked={values.type === 'SERVICE'}
																onChange={() => setFieldValue(`type`, 'SERVICE')}
															/>
															<span>Service</span>
														</label>
													</div>
												</div>
												<div className="form-group col">
													<div className="form-check checkbox-slider checkbox-slider--b-flat">
														<label>
															<input
																type="checkbox"
																checked={values.type === 'RETAIL'}
																onChange={() => setFieldValue(`type`, 'RETAIL')}
															/>
															<span>Retail</span>
														</label>
													</div>
												</div>
											</div>
										</div>
										<div className="modal-footer">
											<button onClick={this.props.hideModal} className="btn" type="button">
												Cancel
											</button>
											<button disabled={isSubmitting || values.type === ''} type="submit" className="btn btn-primary">
												{modalProps.type === 'create' ? (
													'Add Tax Exemption'
												) : (
													'Edit Tax Exemption'
												)}
											</button>
										</div>
									</div>
								</div>
							</form>
						)}
					/>
				</div>
				<div className="modal-backdrop show" tabIndex="1" />
			</Fragment>
		);
	}
}

const mapStateToProps = (state) => {
	const taxCodesData = getAccountDetails(state).billing.taxCodes,
		accountId = getAccountDetails(state).accountDetails.accountInfo.id,
		modalProps = getModalData(state).modalProps;

	return {
		taxCodesData,
		accountId,
		modalProps
	};
};

const mapDispatchToProps = {
	getTaxCodes,
	createTaxExemption,
	updateTaxExemption,
	emptyTaxCodes,
	hideModal
};

export default connect(mapStateToProps, mapDispatchToProps)(TaxExemptionsModal);
