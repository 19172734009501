import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
	getOrders,
	emptyOrders,
	executeOrder,
	cancelServiceOrder,
} from '../../../../../../../actions/accountDetailsActions/ordersAndQuotes.actions';
import { getAccountDetails, createLoadingSelector, getUserPermission } from '../../../../../../../selectors';
import { Order } from './Order';
import Loader from '../../../../../../../components/Loader';
import { isEmpty } from '../../../../../../../utils/helpers';

class Orders extends Component {

	state = {
		isLoaded: false,
	}

	componentDidMount() {
		this.props.emptyOrders();
		this.props.getOrders(this.props.accountInfo.id).then(response => {
			this.setState({isLoaded: true})
		});
	}

	componentWillUnmount() {
		this.props.emptyOrders();
	}

	reloadOrders = () => {
		this.props.emptyOrders();
		this.props.getOrders(this.props.accountInfo.id);
	}

	render() {

		const {
			isLoaded,
		} = this.state;

		const {
			ordersData,
			ordersLoader,
			executeOrder,
			cancelServiceOrder,
			accountInfo,
			canCancelServiceOrders,
			showServiceUpdateRequestWizardHandler,
		} = this.props;

		if (!isLoaded || ordersLoader) {
			return <Loader />;
		}

		if (isLoaded && isEmpty(ordersData)) {
			return <p className="tab-pane-message-empty">No data found</p>;
		}

		return (
			<div>

				{isLoaded && ordersData.map((order) => (
					<Order
						key={order.id}
						accountInfo={accountInfo}
						orderInfo={order}
						hasCollapsedIcon={true}
						canCancelServiceOrders={canCancelServiceOrders}
						getOrders={this.props.getOrders}
						executeOrder={executeOrder}
						cancelServiceOrder={cancelServiceOrder}
						showModal={this.props.showModal}
						showServiceUpdateRequestWizardHandler={showServiceUpdateRequestWizardHandler}
                        parentCallback={this.reloadOrders}
						history={this.props.history}
					/>
				))}

			</div>
		);
	}
}

const loader = createLoadingSelector(['SET_ORDERS']);

const mapStateToProps = (state) => {

	const canCancelServiceOrders = getUserPermission(state, 'OPER', 'OPER_CANCEL_SERVICE_ORDER');
	const ordersData = getAccountDetails(state).ordersAndQuotes.orders;
	const ordersLoader = loader(state);

	return {
		canCancelServiceOrders,
		ordersData,
		ordersLoader,
	};
};

const mapDispatchToProps = {
	getOrders,
	emptyOrders,
	executeOrder,
	cancelServiceOrder,
};

export default connect(mapStateToProps, mapDispatchToProps)(Orders);
