import React, { Component } from 'react';
import ReactHtmlParser from "react-html-parser";
import VariableQuantityInput from "./VariableQuantityInput";
import VariablePriceInput from "./VariablePriceInput";
import {get} from "lodash";

class OptionFeature extends Component {

	state = {
		isDescriptionToggled: false,
	};

	toggleDescription = (e) => {
		e.stopPropagation();
		this.setState((prevState) => ({
			isDescriptionToggled: !prevState.isDescriptionToggled
		}));
	};

	render() {
		const { isDescriptionToggled } = this.state;
		const { features, feature, lineIndex, handleOptionalFeature, handleVariableFeatureQuantity, handleVariableFeaturePrice, errors,
			featureIndex} = this.props;

		return (
			<div
				className={
					"package-option package-option-selectable" +
					(features.findIndex((x) => x.id === feature.id) !== -1 ? ' package-option-selected' : '') +
					(feature.variableQuantity ? ' package-option-variable' : '')
				}
				onClick={() => handleOptionalFeature(feature.id, lineIndex, feature)}
			>

				<span className="po-title">{feature.description}</span>
				{/*<span className="po-title">{feature.description}</span>*/}

				<span className="package-toolbar">
					{feature.variableQuantity &&
						<span className="po-quantity">
							<VariableQuantityInput
								feature={feature}
								value={feature.featureVariabledQuantity || 1}
								disabled={!(features.findIndex((x) => x.id === feature.id) !== -1)}
								handleVariableFeatureQuantity={handleVariableFeatureQuantity}
							/>
						</span>
					}

					{feature.variablePrice
					?
						<span className="po-price">
							<VariablePriceInput
								feature={feature}
								value={feature.featureType === 'ACTIVATION'
									? feature.oneTimePrice && feature.oneTimePrice
									: feature.recurringMonthlyPrice && feature.recurringMonthlyPrice
								}
								disabled={!(features.findIndex((x) => x.id === feature.id) !== -1)}
								handleVariableFeaturePrice={handleVariableFeaturePrice}
							/>
						</span>
					:
						<span className="po-price">
							${feature.featureType === 'ACTIVATION' ? (
								feature.oneTimePrice.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
							) : (
								feature.recurringMonthlyPrice.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') + '/mo'
							)}
						</span>
					}

					<button onClick={this.toggleDescription} type="button" className="btn btn-xs btn-desc-toggler">
						<i className="fas fa-info-circle" />
					</button>

					{get(errors, `features.${featureIndex}.featureVariabledPrice`) && (
						<small className="form-text text-danger text-left">
							{get(errors, `features.${featureIndex}.featureVariabledPrice`)}
						</small>
					)}

				</span>

				<div className={isDescriptionToggled ? 'po-description collapse show' : 'po-description collapse'}>
					{ReactHtmlParser(feature.note)}
				</div>

			</div>
		);
	}
}

export default OptionFeature;
