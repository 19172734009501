import React, { Component } from 'react';
import OptFeature from './Options/OptionFeature';

class OptionalFeatures extends Component {

	render() {
		const { planFeature, features } = this.props;

		return (
			<div className="plan-section">
				<h5>Optional Features</h5>
				<div className="package-wrapper" data-package-wrapper-id="pl001-of001">
					{planFeature.map((feature) => <OptFeature feature={feature} key={feature.id} {...this.props}
															  featureIndex={features.findIndex((x) => x.id == feature.id)}/>)}
				</div>
			</div>
		);
	}
}

export default OptionalFeatures;
