import React, { Component, Fragment } from 'react';
import FormSelect from '../../../components/UI/FormSelect';
import AccountHolderLoader from '../../../components/Loader/AccountHolderLoader';
import moment from 'moment';
import { get } from 'lodash';
import { Field, FieldArray } from 'formik';
import {generateFullZip, isEmpty} from "../utils";

class BillingDetails extends Component {
	componentDidMount() {
		this.props.getDiscounts(this.props.values.accountHolder.accountType);
		this.props.getRecurringCredits();
		this.props.getTaxCodes();
	}

	showDiscounts = (e) => {
		const { setFieldValue, values } = this.props;
		if (e.target.checked === true) {
			let discounts = [
				{
					id: '',
					validFrom: '',
					validTo: '',
					percentage: 0,
					validMonths: 0,
					amount: 0,
					retail: false,
					service: false
				}
			];
			setFieldValue('billingDetails.showDiscounts', true);
			setFieldValue('billingDetails.discounts', discounts);
		} else {
			setFieldValue('billingDetails.showDiscounts', false);
			delete values.billingDetails.discounts;
			// setTouched({});
		}
	};

	showRecurringCredits = (e) => {
		const { setFieldValue, values } = this.props;
		if (e.target.checked === true) {
			let recurringCredits = [
				{
					id: '',
					validFrom: '',
					validMonths: 0,
					validTo: '',
					percentage: 0,
					amount: 0,
					isActive: null
				}
			];
			setFieldValue('billingDetails.showRecurringCredits', true);
			setFieldValue('billingDetails.recurringCredits', recurringCredits);
		} else {
			setFieldValue('billingDetails.showRecurringCredits', false);
			delete values.billingDetails.recurringCredits;
			// setTouched({});
		}
	};

	handleShowTaxExemptions = (e) => {
		const { setFieldValue, values } = this.props;
		if (e.target.checked === true) {
			let taxExemptions = [
				{
					taxCodeId: '',
					type: ''
				}
			];
			setFieldValue('billingDetails.showTaxExemptions', true);
			setFieldValue('billingDetails.taxExemptions', taxExemptions);
		} else {
			setFieldValue('billingDetails.showTaxExemptions', false);
			delete values.billingDetails.taxExemptions;
			// setTouched({});
		}
	};

	handleDiscountChange = (e, index, replace) => {
		if (e.target.value !== '') {
			let selectedDiscountId = Number(e.target.value);

			const { discounts } = this.props;

			let currentDiscount = discounts.find((x) => x.id === selectedDiscountId);
			currentDiscount['validTo'] = moment()
				.add(currentDiscount.validDays ? currentDiscount.validDays : 0)
				.add(currentDiscount.validMonths, 'months')
				.format('YYYY-MM-DD');
			currentDiscount['validFrom'] = moment().format('YYYY-MM-DD');

			replace(index, currentDiscount);
		} else {
			let currentDiscount = {
				id: '',
				validFrom: '',
				validTo: '',
				percentage: 0,
				validMonths: 0,
				validDays: 0,
				amount: 0,
				retail: false,
				service: false
			};

			replace(index, currentDiscount);
		}
	};

	handleCreditsChange = (e, index, replace) => {
		if (e.target.value !== '') {
			let selectedCredit = Number(e.target.value);

			const { recurringCredits } = this.props;

			let currentCredit = recurringCredits.find((x) => x.id === selectedCredit);
			currentCredit['isActive'] = false; //attention
			currentCredit['validTo'] = moment()
				.add(currentCredit.validDays ? currentCredit.validDays : 0, 'days')
				.add(currentCredit.validMonths, 'months')
				.format('YYYY-MM-DD');
			currentCredit['validFrom'] = moment().format('YYYY-MM-DD');

			replace(index, currentCredit);
		} else {
			let currentCredit = {
				id: '',
				validFrom: '',
				validMonths: 0,
				validDays: 0,
				validTo: '',
				percentage: 0,
				amount: 0,
				isActive: null
			};

			replace(index, currentCredit);
		}
	};

	handleTaxExemptionsChange = (e, index, replace) => {
		if (e.target.value !== '') {
			let taxExemption = {
				taxCodeId: e.target.value,
				type: ''
			};

			replace(index, taxExemption);
		} else {
			let taxExemption = {
				taxCodeId: '',
				type: ''
			};

			replace(index, taxExemption);
		}
	};

	render() {
		const {
			values,
			handleChange,
			handleBlur,
			errors,
			touched,
			setTouched,
			setFieldTouched,
			setFieldValue,
			countryStatesOptions,
			showBillingAddress,
			billingAddressValidation,
			billingAddressLoader,
			showSuggestedBillingAddress,
			suggestedBillingAddressError,
			suggestedBillingAddress,
			useSuggestedBillingAddress,
			closeSuggestion,
			discounts,
			recurringCredits,
			billCycles,
			taxCodesData
		} = this.props;

		return (
			<div className="tab-pane show active">
				<h4 className="h-with-check">
					<span className="h-witch-check-title">
						<i className="fas fa-fw fa-sync" />&nbsp;Billing Cycle
					</span>
					<span className="h-check">
						{values.billingDetails.showBillingCycle ? <span>Enabled</span> : <span>Disabled </span>}
						<div className="form-check checkbox-slider checkbox-slider--b-flat">
							<label>
								<input
									type="checkbox"
									checked={values.billingDetails.showBillingCycle}
									disabled={true}
								/>
								<span>&nbsp;</span>
							</label>
						</div>
					</span>
				</h4>
				{values.billingDetails.showBillingCycle && (
					<div className="form-new-account-billing-cycle">
						<div className="form-group col">
							{/* <label for="new-acc-billcycle">Billing Cycle</label> */}
							<FormSelect
								title="Billing Cycle"
								fieldName="accountTermsInfo.billCycle"
								value={values.accountTermsInfo.billCycle}
								setFieldValue={setFieldValue}
								onBlur={setFieldTouched}
								errors={errors}
								touched={touched}
								isMulti={false}
								options={billCycles}
							/>
						</div>
					</div>
				)}

{/*
				<hr />

				<h4 className="h-with-check">
					<span className="h-witch-check-title">
						<i className="fas fa-fw fa-file-invoice-dollar" />&nbsp;Billing Address
					</span>
					<span className="h-check">
						{values.billingDetails.showBillingAddress ? (
							<span>Enable - use the following address for billing:</span>
						) : (
							<span>Disabled - same as mailing address</span>
						)}
						<div className="form-check checkbox-slider checkbox-slider--b-flat">
							<label>
								<input
									onChange={(e) => showBillingAddress(e, setFieldValue, values, setTouched)}
									type="checkbox"
									checked={values.billingDetails.showBillingAddress}
									onBlur={handleBlur}
									name="billingDetails.showBillingAddress"
								/>
								<span>&nbsp;</span>
							</label>
						</div>
					</span>
				</h4>

				{values.billingDetails.showBillingAddress && (
					<div className="form-new-account-billing-address">
						<fieldset>
							<legend className="as-label">Billing Address</legend>
							<div className="form-row">
								<div className="col-lg-6">
									<div className="form-group">
										<input
											className={
												get(errors, `billingDetails.billingAddress.address1`) &&
												get(touched, `billingDetails.billingAddress.address1`) ? (
													'form-control is-invalid'
												) : (
													'form-control'
												)
											}
											name="billingDetails.billingAddress.address1"
											placeholder="Line 1"
											type="text"
											autoComplete="new-password"
											value={values.billingDetails.billingAddress.address1}
											onBlur={handleBlur}
											onChange={(e) => {
												setFieldValue('billingDetails.billingAddress.zip4', null);
												setFieldValue(e.target.name, e.target.value);
												setTimeout(() => billingAddressValidation(this.props), 0);
											}}
										/>
										{get(errors, `billingDetails.billingAddress.address1`) &&
										get(touched, `billingDetails.billingAddress.address1`) && (
											<div className="invalid-feedback">
												{get(errors, `billingDetails.billingAddress.address1`)}
											</div>
										)}
									</div>
									<div className="form-group">
										<input
											className={
												get(errors, `billingDetails.billingAddress.address2`) &&
												get(touched, `billingDetails.billingAddress.address2`) ? (
													'form-control is-invalid'
												) : (
													'form-control'
												)
											}
											name="billingDetails.billingAddress.address2"
											placeholder="Line 2 (optional)"
											type="text"
											autoComplete="new-password"
											value={values.billingDetails.billingAddress.address2}
											onBlur={handleBlur}
											onChange={(e) => {
												setFieldValue(e.target.name, e.target.value);
												setTimeout(() => billingAddressValidation(this.props), 0);
											}}
										/>
										{get(errors, `billingDetails.billingAddress.address2`) &&
										get(touched, `billingDetails.billingAddress.address2`) && (
											<div className="invalid-feedback">
												{get(errors, `billingDetails.billingAddress.address2`)}
											</div>
										)}
									</div>
									<div className="form-group">
										<input
											className={
												get(errors, `billingDetails.billingAddress.address3`) &&
												get(touched, `billingDetails.billingAddress.address3`) ? (
													'form-control is-invalid'
												) : (
													'form-control'
												)
											}
											name="billingDetails.billingAddress.address3"
											placeholder="Line 3 (optional)"
											type="text"
											autoComplete="new-password"
											value={values.billingDetails.billingAddress.address3}
											onBlur={handleBlur}
											onChange={(e) => {
												setFieldValue(e.target.name, e.target.value);
												setTimeout(() => billingAddressValidation(this.props), 0);
											}}
										/>
										{get(errors, `billingDetails.billingAddress.address3`) &&
										get(touched, `billingDetails.billingAddress.address3`) && (
											<div className="invalid-feedback">
												{get(errors, `billingDetails.billingAddress.address3`)}
											</div>
										)}
									</div>
								</div>
								<div className="col-lg-6">
									<div className="form-group">
										<input
											className={
												get(errors, `billingDetails.billingAddress.city`) &&
												get(touched, `billingDetails.billingAddress.city`) ? (
													'form-control is-invalid'
												) : (
													'form-control'
												)
											}
											name="billingDetails.billingAddress.city"
											placeholder="City"
											type="text"
											autoComplete="new-password"
											value={values.billingDetails.billingAddress.city}
											onBlur={handleBlur}
											onChange={(e) => {
												setFieldValue(e.target.name, e.target.value);
												setTimeout(() => billingAddressValidation(this.props), 0);
											}}
										/>
										{get(errors, `billingDetails.billingAddress.city`) &&
										get(touched, `billingDetails.billingAddress.city`) && (
											<div className="invalid-feedback">
												{get(errors, `billingDetails.billingAddress.city`)}
											</div>
										)}
									</div>
									<div className="form-group">
										<FormSelect
											fieldName="billingDetails.billingAddress.state"
											value={values.billingDetails.billingAddress.state}
											onChange={(fieldName, value) => {
												setFieldValue(fieldName, value);
												setTimeout(() => billingAddressValidation(this.props), 0);
											}}
											onBlur={setFieldTouched}
											errors={errors}
											touched={touched}
											isMulti={false}
											options={countryStatesOptions}
											placeholder="State (optional)"
										/>
									</div>
									<div className="form-group">
										<input
											className={
												get(errors, `billingDetails.billingAddress.zip`) &&
												get(touched, `billingDetails.billingAddress.zip`) ? (
													'form-control is-invalid'
												) : (
													'form-control'
												)
											}
											name="billingDetails.billingAddress.zip"
											placeholder="Zip"
											type="text"
											autoComplete="new-password"
											value={values.billingDetails.billingAddress.zip}
											onBlur={handleBlur}
											onChange={(e) => {
												setFieldValue('billingDetails.billingAddress.zip4', null);
												setFieldValue(e.target.name, e.target.value);
												setTimeout(() => billingAddressValidation(this.props), 0);
											}}
										/>
										{get(errors, `billingDetails.billingAddress.zip`) &&
										get(touched, `billingDetails.billingAddress.zip`) && (
											<div className="invalid-feedback">
												{get(errors, `billingDetails.billingAddress.zip`)}
											</div>
										)}
									</div>
								</div>
							</div>
							{billingAddressLoader ? (
								<AccountHolderLoader />
							) : (
								showSuggestedBillingAddress && (
									<Fragment>
										{suggestedBillingAddressError ? (
											<div className="cmv-container-subpanel cmv-container-subpanel-suggested-address">
												<h5>Address Error</h5>
												<p>{suggestedBillingAddressError}</p>{' '}
												<div className="d-flex justify-content-end mt-1">
													<button
														onClick={() => closeSuggestion('showSuggestedBillingAddress')}
														className="btn btn-outline-primary mr-1 btn-address-keep"
														type="button"
													>
														Keep Entered
													</button>
												</div>
											</div>
										) : (
											<div className="cmv-container-subpanel cmv-container-subpanel-suggested-address">
												<h5>
													<i className="fas fa-database" /> Address Match
												</h5>

												<p>The following address was found in our database.</p>
												<address>
													{suggestedBillingAddress.address1}{' '}
													{suggestedBillingAddress.address2}
													<br />
													{suggestedBillingAddress.city}, {suggestedBillingAddress.state}{' '}
													{generateFullZip(suggestedBillingAddress)}
												</address>
												<div className="d-flex justify-content-end mt-1">
													<button
														onClick={() => closeSuggestion('showSuggestedBillingAddress')}
														className="btn btn-outline-primary mr-1 btn-address-keep"
														type="button"
													>
														Keep Entered
													</button>
													<button
														type="button"
														onClick={() => useSuggestedBillingAddress(setFieldValue)}
														className="btn btn-primary btn-address-use-suggested"
													>
														Use Suggested
													</button>
												</div>
											</div>
										)}
									</Fragment>
								)
							)}
						</fieldset>
					</div>
				)}
*/}

				<hr />

				<h4 className="h-with-check">
					<span className="h-witch-check-title">
						<i className="fas fa-fw fa-credit-card" />&nbsp;Auto Pay
					</span>
					<span className="h-check">
						<span>Disabled</span>
						<div className="form-check checkbox-slider checkbox-slider--b-flat">
							<label>
								<input id="enable-autopay" type="checkbox" disabled={true} readOnly={true} />
								<span>&nbsp;</span>
							</label>
						</div>
					</span>
				</h4>

				<div className="form-new-account-autopay" style={{ display: 'none' }}>
					<div className="form-group has-success">
						<label htmlFor="cc-name" className="control-label">
							Name on card
						</label>
						<input
							id="cc-name"
							name="cc-name"
							type="text"
							className="form-control cc-name valid"
							data-val="true"
							data-val-required="Please enter the name on card"
							autoComplete="cc-name"
							aria-required="true"
							aria-invalid="false"
							aria-describedby="cc-name-error"
							readOnly
						/>
						<span
							className="help-block field-validation-valid"
							data-valmsg-for="cc-name"
							data-valmsg-replace="true"
						/>
					</div>
					<div className="form-group">
						<label htmlFor="cc-number" className="control-label">
							Card number
						</label>
						<input
							id="cc-number"
							name="cc-number"
							type="tel"
							className="form-control cc-number identified visa"
							value=""
							data-val="true"
							data-val-required="Please enter the card number"
							data-val-cc-number="Please enter a valid card number"
							autoComplete="cc-number"
							readOnly
						/>
						<span className="help-block" data-valmsg-for="cc-number" data-valmsg-replace="true" />
					</div>
					<div className="form-row">
						<div className="col-6">
							<div className="form-group">
								<label htmlFor="cc-exp" className="control-label">
									Expiration
								</label>
								<input
									id="cc-exp"
									name="cc-exp"
									type="date"
									className="form-control cc-exp"
									value=""
									data-val="true"
									data-val-required="Please enter the card expiration"
									data-val-cc-exp="Please enter a valid month and year"
									placeholder="MM / YY"
									autoComplete="cc-exp"
									readOnly
								/>
								<span className="help-block" data-valmsg-for="cc-exp" data-valmsg-replace="true" />
							</div>
						</div>
						<div className="col-6">
							<label htmlFor="x_card_code" className="control-label">
								Security code
							</label>
							<div className="input-group">
								<input
									id="x_card_code"
									name="x_card_code"
									type="tel"
									className="form-control cc-cvc"
									value=""
									data-val="true"
									data-val-required="Please enter the security code"
									data-val-cc-cvc="Please enter a valid security code"
									autoComplete="off"
									readOnly
								/>
								<div className="input-group-append">
									<i
										className="fa fa-question-circle fa-lg"
										data-toggle="popover"
										data-container="body"
										data-html="true"
										data-title="Security Code"
										data-content="<div className='text-center one-card'>The 3 digit code on back of the card..<div className='visa-mc-cvc-preview'></div></div>"
										data-trigger="hover"
									/>
								</div>
							</div>
						</div>
					</div>
					<p>
						<i className="fas fa-info" /> This card will be charged every 20 days.
					</p>
				</div>

				<hr />

				<h4 className="h-with-check">
					<span className="h-witch-check-title">
						<i className="fas fa-fw fa-percentage" />&nbsp;Discounts
					</span>
					<span className="h-check">
						{values.billingDetails.showDiscounts ? <span>Applied</span> : <span>Not Applied</span>}
						<div className="form-check checkbox-slider checkbox-slider--b-flat">
							<label>
								<input
									onChange={(e) => this.showDiscounts(e)}
									type="checkbox"
									checked={values.billingDetails.showDiscounts}
									onBlur={handleBlur}
									name="billingDetails.showDiscounts"
								/>
								<span>&nbsp;</span>
							</label>
						</div>
					</span>
				</h4>
				{values.billingDetails.showDiscounts && (
					<div className="form-new-account-discounts">
						<FieldArray
							name="billingDetails.discounts"
							render={({ insert, remove, push, replace }) => (
								<Fragment>
									{values.billingDetails.discounts.map((discount, index) => (
										<div className="form-row form-row-double form-row-discount" key={index}>
											<div className="form-group col-md-4 col-lg-2">
												<label>Discount</label>
												<select
													name={`billingDetails.discounts.${index}.id`}
													onChange={(e) => this.handleDiscountChange(e, index, replace)}
													onBlur={handleBlur}
													value={values.billingDetails.discounts[index].id}
													className={
														get(errors, `billingDetails.discounts.${index}.id`) &&
														get(touched, `billingDetails.discounts.${index}.id`) ? (
															'form-control selector-discounts is-invalid'
														) : (
															'form-control selector-discounts'
														)
													}
												>
													<option value="">Please choose</option>
													{discounts.map((x) => (
														<option value={x.id} key={x.id}>
															{x.description}
														</option>
													))}
												</select>

												{get(errors, `billingDetails.discounts.${index}.id`) &&
												get(touched, `billingDetails.discounts.${index}.id`) && (
													<div
														style={{ position: 'absolute' }}
														className="invalid-feedback pos-absolute"
													>
														{get(errors, `billingDetails.discounts.${index}.id`)}
													</div>
												)}
											</div>

											<div className="form-group col-md-4 col-lg-2">
												<label>Valid from</label>
												<input
													type="date"
													className="form-control discount-from"
													placeholder="Valid from"
													value={values.billingDetails.discounts[index].validFrom}
													onChange={handleChange}
													name={`billingDetails.discounts.${index}.validFrom`}
												/>

												{values.billingDetails.discounts[index].validFrom !== '' &&
												values.billingDetails.discounts[index].validTo !== '' &&
												values.billingDetails.discounts[index].validMonths > 0 &&
												Math.abs(
													moment(values.billingDetails.discounts[index].validTo).diff(
														values.billingDetails.discounts[index].validFrom,
														'months'
													)
												) > values.billingDetails.discounts[index].validMonths && (
													<div
														className="feedback-container"
														style={{ position: 'relative' }}
													>
														<div
															className="text-warning"
															style={{
																position: 'absolute',
																whiteSpace: 'nowrap',
																fontSize: '0.8rem',
																margin: '2px'
															}}
														>
															{`WARNING: The dates are outside the valid values ${values
																.billingDetails.discounts[index].validMonths} months`}
														</div>
													</div>
												)}
											</div>
											<div className="form-group col-md-4 col-lg-2">
												<label>Valid to</label>
												<input
												type="date"
												className="form-control discount-to"
												placeholder="Valid to"
												value={values.billingDetails.discounts[index].validTo}
												onChange={handleChange}
												name={`accountHolder.BillingDetails.discounts.${index}.validTo`}
											/>
												<Field
													name={`billingDetails.discounts.${index}.validTo`}
													type="date"
													className="form-control discount-to"
												/>
											</div>

											<div className="form-group col-6 col-sm-3 col-lg-1">
												<label>Percentage</label>
												<p className="form-control-plaintext font-weight-bold discount-perc">
													{values.billingDetails.discounts[index].percentage ? (
														`${values.billingDetails.discounts[index].percentage}%`
													) : (
														'--%'
													)}
												</p>
											</div>
											<div className="form-group col-6 col-sm-3 col-lg-1">
												<label>Amount</label>
												<p className="form-control-plaintext font-weight-bold discount-amo">
													{values.billingDetails.discounts[index].amount ? (
														`$${values.billingDetails.discounts[index].amount
															.toFixed(2)
															.replace(/\d(?=(\d{3})+\.)/g, '$&,')}`
													) : (
														'$0.00'
													)}
												</p>
											</div>
											<div className="form-group col-sm-3 col-lg-2 d-flex align-items-end">
												<div className="form-check checkbox-slider checkbox-slider--b-flat">
													<label>
														<input
															type="checkbox"
															readOnly={true}
															checked={values.billingDetails.discounts[index].service}
														/>
														<span>Service</span>
													</label>
												</div>
											</div>
											<div className="form-group col-sm-3 col-lg-2 d-flex align-items-end">
												<div className="form-check checkbox-slider checkbox-slider--b-flat">
													<label>
														<input
															type="checkbox"
															readOnly={true}
															checked={values.billingDetails.discounts[index].retail}
														/>
														<span>Retail</span>
													</label>
												</div>
											</div>
											<div className="w-100" />
											<div className="form-group col">
												<div className="btn-group btn-group-sm float-right">
													{index !== 0 && (
														<button
															onClick={() => remove(index)}
															className="btn btn-danger btn-discount-remove"
															type="button"
														>
															Remove
														</button>
													)}
													{index === 0 && (
														<button
															onClick={() =>
																push({
																	id: '',
																	validFrom: '',
																	validTo: '',
																	percentage: '',
																	amount: '',
																	retail: false,
																	service: false,
																	validMonths: 0,
																	validDays: 0
																})}
															className="btn btn-outline-secondary btn-discount-add"
															type="button"
														>
															Add Another
														</button>
													)}
												</div>
											</div>
										</div>
									))}
								</Fragment>
							)}
						/>
					</div>
				)}

				<hr />

				<h4 className="h-with-check">
					<span className="h-witch-check-title">
						<i className="far fa-fw fa-credit-card" />&nbsp;Recurring Credits
					</span>
					<span className="h-check">
						{values.billingDetails.showRecurringCredits ? <span>Applied</span> : <span>Not Applied</span>}
						<div className="form-check checkbox-slider checkbox-slider--b-flat">
							<label>
								<input
									onChange={(e) => this.showRecurringCredits(e)}
									type="checkbox"
									checked={values.billingDetails.showRecurringCredits}
									onBlur={handleBlur}
									name="billingDetails.showRecurringCredits"
								/>
								<span>&nbsp;</span>
							</label>
						</div>
					</span>
				</h4>
				{values.billingDetails.showRecurringCredits && (
					<div className="form-new-account-reccredits">
						<FieldArray
							name="billingDetails.recurringCredits"
							render={({ insert, remove, push, replace }) => (
								<Fragment>
									{values.billingDetails.recurringCredits.map((credit, index) => (
										<div className="form-row form-row-double form-row-reccredits" key={index}>
											<div className="form-group col-md-4 col-lg-2">
												<label>Credit</label>
												<select
													onChange={(e) => this.handleCreditsChange(e, index, replace)}
													onBlur={handleBlur}
													value={values.billingDetails.recurringCredits[index].id}
													name={`billingDetails.recurringCredits.${index}.id`}
													className={
														get(errors, `billingDetails.recurringCredits.${index}.id`) &&
														get(touched, `billingDetails.recurringCredits.${index}.id`) ? (
															'form-control selector-reccredit is-invalid'
														) : (
															'form-control selector-reccredit'
														)
													}
												>
													<option value="">Please choose</option>
													{recurringCredits.map((x) => (
														<option value={x.id} key={x.id}>
															{x.description}
														</option>
													))}
												</select>
												{get(errors, `billingDetails.recurringCredits.${index}.id`) &&
												get(touched, `billingDetails.recurringCredits.${index}.id`) && (
													<div
														className="invalid-feedback pos-absolute"
														style={{ position: 'absolute' }}
													>
														{get(errors, `billingDetails.recurringCredits.${index}.id`)}
													</div>
												)}
											</div>

											<div className="form-group col-md-4 col-lg-2">
												<label>Valid from</label>
												<input
													type="date"
													className="form-control reccredit-from"
													placeholder="Valid from"
													value={values.billingDetails.recurringCredits[index].validFrom}
													onChange={handleChange}
													name={`billingDetails.recurringCredits.${index}.validFrom`}
												/>
												{values.billingDetails.recurringCredits[index].validFrom !== '' &&
												values.billingDetails.recurringCredits[index].validTo !== '' &&
												values.billingDetails.recurringCredits[index].validMonths > 0 &&
												Math.abs(
													moment(values.billingDetails.recurringCredits[index].validTo).diff(
														values.billingDetails.recurringCredits[index].validFrom,
														'months'
													)
												) > values.billingDetails.recurringCredits[index].validMonths && (
													<div
														className="feedback-container"
														style={{ position: 'relative' }}
													>
														<div
															className="text-warning"
															style={{
																position: 'absolute',
																whiteSpace: 'nowrap',
																fontSize: '0.8rem',
																margin: '2px'
															}}
														>
															{`WARNING: The dates are outside the valid values ${values
																.billingDetails.recurringCredits[index]
																.validMonths} months`}
														</div>
													</div>
												)}
											</div>
											<div className="form-group col-md-4 col-lg-2">
												<label>Valid to</label>
												<input
													type="date"
													className="form-control reccredit-to"
													placeholder="Valid to"
													value={values.billingDetails.recurringCredits[index].validTo}
													onChange={handleChange}
													name={`billingDetails.recurringCredits.${index}.validTo`}
												/>
											</div>
											<div className="form-group col-12 col-sm-6 col-lg-2">
												<label>Amount</label>
												<p className="form-control-plaintext font-weight-bold reccredit-amo">
													{values.billingDetails.recurringCredits[index].amount ? (
														`$${values.billingDetails.recurringCredits[index].amount
															.toFixed(2)
															.replace(/\d(?=(\d{3})+\.)/g, '$&,')}`
													) : (
														'$0.00'
													)}
												</p>
											</div>
											<div className="form-group col-sm-3 col-lg-2 d-flex align-items-end">
												<div className="form-check checkbox-slider checkbox-slider--b-flat">
													<label>
														<input
															name={`billingDetails.recurringCredits.${index}.isActive`}
															type="checkbox"
															checked={
																values.billingDetails.recurringCredits[index]
																	.isActive === false
															}
															onChange={() =>
																setFieldValue(
																	`billingDetails.recurringCredits.${index}.isActive`,
																	false
																)}
														/>
														<span>Pending</span>
													</label>
												</div>
											</div>
											<div className="form-group col-sm-3 col-lg-2 d-flex align-items-end">
												<div className="form-check checkbox-slider checkbox-slider--b-flat">
													<label>
														<input
															type="checkbox"
															name={`billingDetails.recurringCredits.${index}.isActive`}
															checked={
																values.billingDetails.recurringCredits[index]
																	.isActive === true
															}
															onChange={() =>
																setFieldValue(
																	`billingDetails.recurringCredits.${index}.isActive`,
																	true
																)}
														/>
														<span>Approved</span>
													</label>
												</div>
											</div>
											<div className="w-100" />
											<div className="form-group col">
												<div className="btn-group btn-group-sm float-right">
													{index !== 0 && (
														<button
															onClick={() => remove(index)}
															className="btn btn-danger btn-discount-remove"
															type="button"
														>
															Remove
														</button>
													)}
													{index === 0 && (
														<button
															onClick={() =>
																push({
																	id: '',
																	isActive: null,
																	validFrom: '',
																	validTo: '',
																	validMonths: 0,
																	validDays: 0,
																	amount: ''
																})}
															className="btn btn-outline-secondary btn-discount-add"
															type="button"
														>
															Add Another
														</button>
													)}
												</div>
											</div>
										</div>
									))}
								</Fragment>
							)}
						/>
					</div>
				)}

				<hr />

				<h4 className="h-with-check">
					<span className="h-witch-check-title">
						<i className="far fa-fw fa-credit-card" />&nbsp;Tax Exemptions
					</span>
					<span className="h-check">
						{values.billingDetails.showTaxExemptions ? <span>Applied</span> : <span>Not Applied</span>}
						<div className="form-check checkbox-slider checkbox-slider--b-flat">
							<label>
								<input
									onChange={(e) => this.handleShowTaxExemptions(e)}
									type="checkbox"
									checked={values.billingDetails.showTaxExemptions}
									onBlur={handleBlur}
									name="billingDetails.showTaxExemptions"
								/>
								<span>&nbsp;</span>
							</label>
						</div>
					</span>
				</h4>
				{values.billingDetails.showTaxExemptions && (
					<div className="form-new-account-reccredits">
						<FieldArray
							name="billingDetails.taxExemptions"
							render={({ insert, remove, push, replace }) => (
								<Fragment>
									{values.billingDetails.taxExemptions.map((credit, index) => (
										<div className="form-row form-row-double form-row-reccredits" key={index}>
											<div className="form-group col-md-4 col-lg-2">
												<label>Tax Code</label>
												<select
													onChange={(e) => this.handleTaxExemptionsChange(e, index, replace)}
													onBlur={handleBlur}
													value={values.billingDetails.taxExemptions[index].taxCodeId}
													name={`billingDetails.taxExemptions.${index}.taxCodeId`}
													className={
														get(
															errors,
															`billingDetails.taxExemptions.${index}.taxCodeId`
														) &&
														get(
															touched,
															`billingDetails.taxExemptions.${index}.taxCodeId`
														) ? (
															'form-control selector-reccredit is-invalid'
														) : (
															'form-control selector-reccredit'
														)
													}
												>
													<option value="">Please choose</option>
													{taxCodesData.map((code) => (
														<option value={code.id} key={code.id}>
															{code.description}
														</option>
													))}
												</select>
												{get(errors, `billingDetails.taxExemptions.${index}.taxCodeId`) &&
												get(touched, `billingDetails.taxExemptions.${index}.taxCodeId`) && (
													<div
														className="invalid-feedback pos-absolute"
														style={{ position: 'absolute' }}
													>
														{get(errors, `billingDetails.taxExemptions.${index}.taxCodeId`)}
													</div>
												)}
											</div>

											<div className="form-group col-sm-3 col-lg-2 form-group-check ml-auto">
												<div className="form-check checkbox-slider checkbox-slider--b-flat">
													<label>
														<input
															name={`billingDetails.taxExemptions.${index}.type`}
															type="checkbox"
															checked={
																values.billingDetails.taxExemptions[index].type ===
																'SERVICE'
															}
															onBlur={handleBlur}
															onChange={() =>
																setFieldValue(
																	`billingDetails.taxExemptions.${index}.type`,
																	'SERVICE'
																)}
														/>
														<span>Service</span>
														
													</label>
												
												</div>
												{get(errors, `billingDetails.taxExemptions.${index}.type`) &&
														get(touched, `billingDetails.taxExemptions.${index}.type`) && (
															<div
																className="feedback-container"
																style={{ position: 'relative' }}
															>
																<div
																	className="text-danger"
																	style={{
																		position: 'absolute',
																		whiteSpace: 'nowrap',
																		fontSize: '0.8rem',
																		margin: '2px'
																	}}
																>
																	{`Select one from Service or Retail`}
																</div>
															</div>
														)}
											</div>
											<div className="form-group col-sm-3 col-lg-2 form-group-check">
												<div className="form-check checkbox-slider checkbox-slider--b-flat">
													<label>
														<input
															type="checkbox"
															name={`billingDetails.taxExemptions.${index}.type`}
															checked={
																values.billingDetails.taxExemptions[index].type ===
																'RETAIL'
															}
															onBlur={handleBlur}
															onChange={() =>
																setFieldValue(
																	`billingDetails.taxExemptions.${index}.type`,
																	'RETAIL'
																)}
														/>
														<span>Retail</span>
													</label>
												</div>
											</div>
											<div className="w-100" />
											<div className="form-group col">
												<div className="btn-group btn-group-sm float-right">
													{index !== 0 && (
														<button
															onClick={() => remove(index)}
															className="btn btn-danger btn-discount-remove"
															type="button"
														>
															Remove
														</button>
													)}
													{index === 0 && (
														<button
															onClick={() =>
																push({
																	id: '',
																	type: ''
																})}
															className="btn btn-outline-secondary btn-discount-add"
															type="button"
														>
															Add Another
														</button>
													)}
												</div>
											</div>
										</div>
									))}
								</Fragment>
							)}
						/>
					</div>
				)}
			</div>
		);
	}
}

export default BillingDetails;
