import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import { get } from 'lodash';
import { getModalData, getCreateServiceRequest, createLoadingSelector } from '../../../selectors';
import { hideModal } from '../../../actions/modal.actions';
import { createNewQuote } from '../../../actions/createServiceRequest.actions';
import {
	getAccountTypes,
	getAccountTypeDetails,
	verifyAuthCode,
	sendAuthCode,
	createNewAccount
} from '../../../actions/createServiceRequest.actions';
import isEmpty from "../../../utils/helpers";
import axiosInstance from "../../../utils/axios-instance";

const QuoteSchema = Yup.object().shape({
	accountHolder: Yup.object().shape({
		// accountType: Yup.number().typeError('Required').required('Required'),
		accountType: Yup.string().min(1, 'Required').required('Required'),
		firstName: Yup.string().min(1, 'Too Short!').max(50, 'Too Long!').required('Required'),
		lastName: Yup.string().min(1, 'Too Short!').max(50, 'Too Long!').required('Required'),
		personalEmail: Yup.string().email('Invalid email format').required('Required')
		// mobileNumber: Yup.object().shape({
		// 	number: Yup.string().min(2, 'Min 2 char').required('Required'),
		// 	verified: Yup.boolean()
		// })
	}),
	accountTermsInfo: Yup.object().shape(
		{
			// accountTerm: Yup.string().required('Required')
		}
	)
	// quoteInfo: Yup.object().shape({
	// 	quoteName: Yup.string().required('Required'),
	// 	quoteNote: Yup.string().required('Required')
	// })
});

class QuoteAndAccountModal extends Component {
	state = {
		initialValues: {
			accountHolder: {
				accountType: '',
				firstName: '',
				lastName: '',
				mobileNumber: {
					number: '',
					verified: false
				},
				personalEmail: ''
			},
			accountTermsInfo: {
				accountTerm: '',
				accountTier: '',
				billCycle: ''
			},
			initialStatus: 'PROSPECT',
			locationId: this.props.currentLocation.id,
			quoteInfo: {
				quoteNote: '',
				quoteName: ''
			}
		},
		authCode: '',
		showVerificationMobileNumber: false,

		isAuthCodeValid: null,
		quoteName: '',
		quoteNote: ''
	};

	componentDidMount() {
		this.props.getAccountTypes();
	}

	componentDidUpdate(prevProps) {}

	onChangeHandler = (e) => {
		this.setState({
			[e.target.name]: e.target.value
		});
	};

	accountTypeHandler = (accountTypeId, setFieldValue) => {
		if (accountTypeId === '') {
			return null;
		}

		setFieldValue('accountHolder.accountType', accountTypeId);
		this.props.getAccountTypeDetails(accountTypeId).then((response) => {
			if (response) {
				let billCycles = response.allowedBillCycles.split(',');
				let defaultBillCycle = Number(
					billCycles
						.find((x) => {
							return x.includes('*');
						})
						.replace('*', '')
				);
				setFieldValue('accountTermsInfo.billCycle', defaultBillCycle);
			}
		});
	};

	accountTermHandler = (accountTermId, setFieldValue) => {};

	resetAuthCodeState = (setFieldValue) => {
		this.setState({
			showVerificationMobileNumber: false,
			isAuthCodeValid: null,
			authCode: ''
		});
		setFieldValue('accountHolder.mobileNumber.verified', false);
	};

	onSubmitAuthCode = (number) => {
		let data = {
			phoneNumber: number,
			requester: 'alex'
		};
		// if there is no error - show field for verification
		this.props.sendAuthCode(data).then((response) => {
			// To Do - need to catch exeption - error
			this.setState({
				showVerificationMobileNumber: true,
				isAuthCodeValid: null,
				authCode: ''
			});
		});
	};

	onChangeAuthCodeHandler = (e) => {
		this.setState({
			authCode: e.target.value
		});
	};

	onSubmitVerifyAuthCode = (number, setFieldValue) => {
		let data = {
			authcode: this.state.authCode,
			phoneNumber: number,
			requester: 'alex'
		};
		this.props.verifyAuthCode(data).then((response) => {
			if (response.data.code === 200) {
				this.setState({ isAuthCodeValid: true });
				setFieldValue('accountHolder.mobileNumber.verified', true);
			}
			if (response.data.error) {
				this.setState({ isAuthCodeValid: false });
				setFieldValue('accountHolder.mobileNumber.verified', false);
			}
		});
	};

	formSubmit = (values, actions) => {
		const data = Object.keys(values).reduce((object, key) => {
			if (key !== 'quoteInfo') {
				object[key] = values[key];
			}
			return object;
		}, {});

		data.accountHolder.serviceAddress = this.props.modalProps.validatedAddress.addressSearchFormated;
		
		this.props.createNewAccount(data).then((response) => {
			if (response.status === 201 || response.status === 200) {
				let quotesData = { ...this.props.modalProps };
				quotesData['accountId'] = response.data.id;

				quotesData['address'] = this.props.modalProps.validatedAddress.addressSearchFormated;
				quotesData['type'] = this.props.modalProps.validatedAddress.allowedServiceOrderType;
				quotesData['validatedAddress'] = null;
				quotesData['name'] = values.quoteInfo.quoteName;
				quotesData['note'] = values.quoteInfo.quoteNote;
				this.props.createNewQuote(quotesData);
			} else {
				actions.setSubmitting(false);
			}
		});
	};

	checkEmail = (status, setStatus, field, value) => {
		var resetStatus = !isEmpty(status)? status: {};

		delete resetStatus[field];

		setStatus(resetStatus);

		axiosInstance.get('/verification/email/valid', {params: {email: value}}).catch((error) => {
			const newStatus = {
				[field]: error.response.data.error.message,
				...resetStatus
			};
			setStatus(newStatus);
		});

	}

	render() {
		const { initialValues, authCode, isAuthCodeValid, showVerificationMobileNumber } = this.state;
		const {
			modalProps,
			accountTypesData,
			accountTermsData,
			authCodeLoader,
			authCodeVerifyLoader,
			hideModal
		} = this.props;

		return (
			<Fragment>
				<div className="modal show" tabIndex="-1" style={{ display: 'block' }}>
					<Formik
						initialValues={initialValues}
						enableReinitialize
						validationSchema={QuoteSchema}
						onSubmit={this.formSubmit}
						render={({
							handleChange,
							handleSubmit,
							handleBlur,
							values,
							errors,
							touched,
							isSubmitting,
							setFieldValue,
							status,
							setStatus
						}) => (
							<form onSubmit={handleSubmit}>
								<div className="modal-dialog modal-lg" role="document">
									<div className="modal-content">
										<div className="modal-header">
											<h5 className="modal-title">Save Quotes</h5>
											<button type="button" className="close" onClick={hideModal}>
												<span aria-hidden="true">&times;</span>
											</button>
										</div>
										<div className="modal-body cmv-form">
											<div className="form-row">
												<div className="form-group col-md-6">
													<label>Account Type</label>
													<select
														name="accountHolder.accountType"
														className={
															get(errors, `accountHolder.accountType`) &&
															get(touched, `accountHolder.accountType`) ? (
																'form-control is-invalid'
															) : (
																'form-control'
															)
														}
														placeholder="Account Type"
														value={values.accountHolder.accountType}
														onChange={(e) =>
															this.accountTypeHandler(e.target.value, setFieldValue)}
														onBlur={handleBlur}
													>
														<option value="" disabled>
															Select
														</option>
														{accountTypesData.map((type) => (
															<option value={type.id} key={type.id}>
																{type.description}
															</option>
														))}
													</select>
													{get(errors, `accountHolder.accountType`) &&
													get(touched, `accountHolder.accountType`) && (
														<div className="invalid-feedback">
															{get(errors, `accountHolder.accountType`)}
														</div>
													)}
												</div>
												<div className="form-group col-md-6">
													<label>Account Term</label>
													<select
														name="accountTermsInfo.accountTerm"
														className={
															get(errors, `accountTermsInfo.accountTerm`) &&
															get(touched, `accountTermsInfo.accountTerm`) ? (
																'form-control is-invalid'
															) : (
																'form-control'
															)
														}
														placeholder="Account Term"
														value={values.accountTermsInfo.accountTerm}
														// onChange={(e) =>
														// 	this.accountTypeHandler(e.target.value, setFieldValue)}
														onChange={handleChange}
														onBlur={handleBlur}
													>
														<option value="">Select</option>
														{accountTermsData.map((type) => (
															<option value={type.id} key={type.id}>
																{type.description}
															</option>
														))}
													</select>
													{get(errors, `accountTermsInfo.accountTerm`) &&
													get(touched, `accountTermsInfo.accountTerm`) && (
														<div className="invalid-feedback">
															{get(errors, `accountTermsInfo.accountTerm`)}
														</div>
													)}
												</div>
											</div>
											<div className="form-row">
												<div className="form-group col-md-6">
													<label htmlFor="firstName">First Name</label>
													<input
														className={
															get(errors, `accountHolder.firstName`) &&
															get(touched, `accountHolder.firstName`) ? (
																'form-control is-invalid'
															) : (
																'form-control'
															)
														}
														name="accountHolder.firstName"
														placeholder="First Name"
														type="text"
														autoComplete="off"
														value={values.accountHolder.firstName}
														onChange={handleChange}
														onBlur={handleBlur}
													/>
													{get(errors, `accountHolder.firstName`) &&
													get(touched, `accountHolder.firstName`) && (
														<div className="invalid-feedback">
															{get(errors, `accountHolder.firstName`)}
														</div>
													)}
												</div>
												<div className="form-group col-md-6">
													<label htmlFor="lastName">Last Name</label>
													<input
														className={
															get(errors, `accountHolder.lastName`) &&
															get(touched, `accountHolder.lastName`) ? (
																'form-control is-invalid'
															) : (
																'form-control'
															)
														}
														name="accountHolder.lastName"
														placeholder="Last Name"
														type="text"
														autoComplete="off"
														value={values.accountHolder.lastName}
														onChange={handleChange}
														onBlur={handleBlur}
													/>
													{get(errors, `accountHolder.lastName`) &&
													get(touched, `accountHolder.lastName`) && (
														<div className="invalid-feedback">
															{get(errors, `accountHolder.lastName`)}
														</div>
													)}
												</div>
											</div>
											<div className="form-row">
												<div className="form-group col-md-6">
													<label htmlFor="personalEmail">Email address</label>
													<input
														className={
															get(errors, `accountHolder.personalEmail`) &&
															get(touched, `accountHolder.personalEmail`) ? (
																'form-control is-invalid'
															) : (
																'form-control'
															)
														}
														name="accountHolder.personalEmail"
														placeholder="Email"
														type="text"
														autoComplete="off"
														value={values.accountHolder.personalEmail}
														onChange={handleChange}
														onBlur={(e) => {
															handleBlur(e);
															this.checkEmail(status, setStatus,'accountHolder.personalEmail', e.target.value);
														}}
													/>
													{get(errors, `accountHolder.personalEmail`) &&
													get(touched, `accountHolder.personalEmail`) && (
														<div className="invalid-feedback">
															{get(errors, `accountHolder.personalEmail`)}
														</div>
													)}
													{get(status, `accountHolder.personalEmail`) && (
														<div className="invalid-feedback">
															{get(status, `accountHolder.personalEmail`)}
														</div>
													)}
												</div>
												<div className="form-group col-md-6">
													<label htmlFor="new-acc-phonemobile">Mobile Phone</label>
													<div className="input-group">
														<input
															type="text"
															name="accountHolder.mobileNumber.number"
															value={values.accountHolder.mobileNumber.number}
															className={
																get(errors, `accountHolder.mobileNumber.number`) &&
																get(touched, `accountHolder.mobileNumber.number`) ? (
																	'form-control is-invalid'
																) : (
																	'form-control'
																)
															}
															autoComplete="new-password"
															placeholder="Mobile Phone (optional)"
															onChange={(values) => {
																handleChange(values);
																this.resetAuthCodeState(setFieldValue);
															}}
															onBlur={handleBlur}
														/>
														{get(errors, `accountHolder.mobileNumber.number`) &&
														get(touched, `accountHolder.mobileNumber.number`) && (
															<div className="invalid-feedback">
																{get(errors, `accountHolder.mobileNumber.number`)}
															</div>
														)}

														<div
															className={
																showVerificationMobileNumber &&
																!authCodeLoader &&
																!authCodeVerifyLoader &&
																isAuthCodeValid === null ? (
																	'input-group-append input-authcode-input'
																) : (
																	'input-group-append input-authcode-input d-none'
																)
															}
														>
															<div className="input-group">
																<div className="input-group-prepend">
																	<span className="input-group-text">
																		<i className="fas fa-key" />
																	</span>
																</div>
																<input
																	type="text"
																	name="authCode"
																	value={authCode}
																	onChange={this.onChangeAuthCodeHandler}
																	className="form-control w-120px authcode-key-phone"
																	placeholder="AuthCode"
																/>
															</div>
														</div>

														<div
															className={
																showVerificationMobileNumber &&
																!authCodeLoader &&
																!authCodeVerifyLoader &&
																isAuthCodeValid === null ? (
																	'input-group-append input-authcode-validate'
																) : (
																	'input-group-append input-authcode-validate d-none'
																)
															}
														>
															<button
																className="btn btn-outline-secondary btn-authcode-validate"
																type="button"
																onClick={() =>
																	this.onSubmitVerifyAuthCode(
																		values.accountHolder.mobileNumber.number,
																		setFieldValue
																	)}
															>
																<i className="fas fa-check" /> Verify
															</button>
														</div>

														<div className="input-group-append input-authcode-status">
															{authCodeLoader && (
																<span className="input-group-text">
																	<i className="fas fa-circle-notch fa-spin ml-1 mr-1" />&nbsp;Requesting
																</span>
															)}
															{authCodeVerifyLoader && (
																<span className="input-group-text">
																	<i className="fas fa-circle-notch fa-spin ml-1 mr-1" />&nbsp;Verifying...
																</span>
															)}
															{isAuthCodeValid === true &&
															!authCodeLoader && (
																<span className="input-group-text text-success">
																	<i className="fas fa-check" />&nbsp;Valid
																</span>
															)}

															{isAuthCodeValid === false &&
															!authCodeLoader && (
																<span className="input-group-text text-danger">
																	<i className="fas fa-exclamation-triangle" />&nbsp;Invalid
																</span>
															)}
														</div>

														<div
															className={
																!showVerificationMobileNumber &&
																!authCodeLoader &&
																!authCodeVerifyLoader ? (
																	'input-group-append input-authcode-send'
																) : (
																	'input-group-append input-authcode-send d-none'
																)
															}
														>
															<button
																className="btn btn-outline-secondary btn-authcode-send"
																type="button"
																disabled={
																	values.accountHolder.mobileNumber.number.length ===
																	0
																}
																onClick={() =>
																	this.onSubmitAuthCode(
																		values.accountHolder.mobileNumber.number
																	)}
															>
																<i className="fas fa-paper-plane" /> Send
															</button>
														</div>

														<div
															className={
																showVerificationMobileNumber &&
																!authCodeLoader &&
																!authCodeVerifyLoader &&
																isAuthCodeValid !== true ? (
																	'input-group-append input-authcode-send'
																) : (
																	'input-group-append input-authcode-send d-none'
																)
															}
														>
															<button
																className="btn btn-outline-secondary btn-authcode-send"
																type="button"
																disabled={
																	values.accountHolder.mobileNumber.number.length ===
																	0
																}
																onClick={() =>
																	this.onSubmitAuthCode(
																		values.accountHolder.mobileNumber.number
																	)}
															>
																<i className="fas fa-paper-plane" /> Resend
															</button>
														</div>
													</div>
												</div>
											</div>

											<div className="form-group">
												<label>Quote Name</label>
												<input
													className={
														get(errors, `quoteInfo.quoteName`) &&
														get(touched, `quoteInfo.quoteName`) ? (
															'form-control is-invalid'
														) : (
															'form-control'
														)
													}
													name="quoteInfo.quoteName"
													placeholder="Quote Name (optional)"
													type="text"
													autoComplete="off"
													value={values.quoteInfo.quoteName}
													onChange={handleChange}
													onBlur={handleBlur}
												/>
												{get(errors, `quoteInfo.quoteName`) &&
												get(touched, `quoteInfo.quoteName`) && (
													<div className="invalid-feedback">
														{get(errors, `quoteInfo.quoteName`)}
													</div>
												)}
											</div>
											<div className="form-group">
												<label>Quote Note</label>
												<textarea
													className={
														get(errors, `quoteInfo.quoteNote`) &&
														get(touched, `quoteInfo.quoteNote`) ? (
															'form-control is-invalid'
														) : (
															'form-control'
														)
													}
													name="quoteInfo.quoteNote"
													placeholder="Quote Note (optional)"
													type="text"
													autoComplete="off"
													value={values.quoteInfo.quoteNote}
													onChange={handleChange}
													onBlur={handleBlur}
												/>
												{get(errors, `quoteInfo.quoteNote`) &&
												get(touched, `quoteInfo.quoteNote`) && (
													<div className="invalid-feedback">
														{get(errors, `quoteInfo.quoteNote`)}
													</div>
												)}
											</div>
										</div>
										<div className="modal-footer">
											<button className="btn" onClick={hideModal}>
												Cancel
											</button>
											<button className="btn btn-primary" type="submit" disabled={isSubmitting}>
												Save Quote {isSubmitting && <i class="fas fa-spin fa-circle-notch" />}
											</button>
										</div>
									</div>
								</div>
							</form>
						)}
					/>
				</div>
				<div className="modal-backdrop show" tabIndex="1" />
			</Fragment>
		);
	}
}

QuoteAndAccountModal.propTypes = {
	modalProps: PropTypes.object.isRequired,
	accountTypesData: PropTypes.array.isRequired,
	accountTermsData: PropTypes.array.isRequired,
	newOrderLoader: PropTypes.bool,
	authCodeLoader: PropTypes.bool,
	authCodeVerifyLoader: PropTypes.bool,
	hideModal: PropTypes.func,
	createNewQuote: PropTypes.func,
	getAccountTypes: PropTypes.func,
	getAccountTypeDetails: PropTypes.func,
	verifyAuthCode: PropTypes.func,
	sendAuthCode: PropTypes.func,
	createNewAccount: PropTypes.func
};

const setNewOrderLoader = createLoadingSelector([ 'CREATE_ORDER' ]);
const setAuthCodeLoader = createLoadingSelector([ 'SEND_AUTH_CODE' ]);
const setAuthCodeVerify = createLoadingSelector([ 'VERIFY_AUTH_CODE' ]);

const mapStateToProps = (state) => {
	const modalProps = getModalData(state).modalProps,
		newOrderLoader = setNewOrderLoader(state),
		accountTypesData = getCreateServiceRequest(state).accountTypes,
		accountTermsData = getCreateServiceRequest(state).accountTerms,
		billCyclesData = getCreateServiceRequest(state).billCycles,
		authCodeLoader = setAuthCodeLoader(state),
		authCodeVerifyLoader = setAuthCodeVerify(state);

	return {
		modalProps,
		newOrderLoader,
		accountTypesData,
		accountTermsData,
		authCodeLoader,
		authCodeVerifyLoader,
		billCyclesData
	};
};
const mapDispatchToProps = {
	hideModal,
	createNewQuote,
	getAccountTypes,
	getAccountTypeDetails,
	verifyAuthCode,
	sendAuthCode,
	createNewAccount
};

export default connect(mapStateToProps, mapDispatchToProps)(QuoteAndAccountModal);
