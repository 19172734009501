import React, {Component} from 'react';
import AccountInfoBox from '../../../components/UI/AccountInfoBox';
import {connect} from 'react-redux';
import {hideModal, showModal} from '../../../actions/modal.actions';
import {createLoadingSelector} from '../../../selectors';
import Features from "./Features";
import FeaturePackages from "../FeaturePackages/FeaturePackages";

class FeatureManagement extends Component {

    componentDidMount() {

        // Change navbar type and color
        document.title = 'Features - camvio.cloud';
        this.props.changeNavBarType('default', 'Features');
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isShowAccessModal !== this.props.isShowAccessModal && this.props.isShowAccessModal === true) {
        }
    }

    componentWillUnmount() {
        this.props.hideModal();
    }

    render() {
        const { accountInfo, history, accountInfoLoader, isShowAccessModal } = this.props;

        //If user is redirected from create-account page - show cpni pop-up
        if (
            isShowAccessModal === false &&
            history.location.state &&
            history.location.state.serviceOrderData
        ) {
            return null;
        }

        return (
            <div className="cmv-container cmv-container-dashboard cmv-container-client-selected">
                <Features/>
            </div>
        );
    }
}

const loader = createLoadingSelector([ 'SET_ACCOUNT_DETAILS' ]);

const mapStateToProps = (state) => {
    // const accountInfo = getAccountDetails(state).accountDetails.accountInfo,
    // 	showNewOrderWizard = getAccountDetails(state).accountDetails.showNewOrderWizard,
    // 	isShowAccessModal = getModalData(state).showAccessModal,
    // 	accountInfoLoader = loader(state);

    return {
    };
};

const mapDispatchToProps = {
    showModal,
    hideModal
};

export default connect(mapStateToProps, mapDispatchToProps)(FeatureManagement);