import React, {Component} from 'react';
import Loader from "../../components/Loader";
import isEmpty from "../../utils/helpers";

export class WorkflowStatuses extends Component {

    state = {
        isLoading: true,
        statuses: {},
    }

    compareStatuses = (a, b) => {
        if (a.order > b.order)
            return 1;
        else if (a.order < b.order)
            return -1;
        else
            return 0;
    }

    componentDidMount() {

        // Sort statuses by order
        let allStatuses = this.props.workflowStatuses
            .filter(status => status.workflowName === this.props.workflow.workflowName)
            .sort(this.compareStatuses);

        // Gather status groups
        let groupedStatuses = {};
        allStatuses.map((status) => {
            if (!groupedStatuses.hasOwnProperty(status.group)) {
                groupedStatuses[status.group] = [];
            }
        });

        // Add statuses in the corresponding groups
        Object.keys(groupedStatuses).map((group) => {
            groupedStatuses[group] = allStatuses.filter(status => status.group === group);
        });

        // Store statuses
        this.setState({
            isLoading: false,
            statuses: groupedStatuses,
        });
    }

    render() {

        const {
            isLoading,
            statuses,
        } = this.state;

        return (
            <div className="workflow-statuses">

                {isLoading
                ?
                <Loader />
                :
                isEmpty(statuses)
                ?
                "No data found"
                :
                <div className="row">
                    {Object.keys(statuses).map((group) =>
                        <div className="col">
                            <h6>{group}</h6>
                            {statuses[group].map((status) =>
                                <span title={status.description}>{status.name}</span>
                            )}
                        </div>
                    )}
                </div>
                }

            </div>
        );
    }
}

export default WorkflowStatuses;