import axiosInstance from '../../utils/axios-instance';
import { actionCreator, createRequestTypes } from '../../utils/helpers';

export const DASHBOARD_ACCOUNT_SEARCH = createRequestTypes('DASHBOARD_ACCOUNT_SEARCH');
export const SET_DASHBOARD_MENU_COUNT = createRequestTypes('SET_DASHBOARD_MENU_COUNT');

// CUSTOMERS //
export const getAccounts = (searchType, term) => async (dispatch) => {
	dispatch(actionCreator.request(DASHBOARD_ACCOUNT_SEARCH));
	try {
		const response = await axiosInstance.get(`/accounts`, { params: { searchType, term } });
		dispatch(actionCreator.success(DASHBOARD_ACCOUNT_SEARCH, response.data));
	} catch (err) {
		let mockError = {
			message: 'Not found'
		};
		dispatch(actionCreator.failure(DASHBOARD_ACCOUNT_SEARCH, mockError));
	}
};

export const getCustomersServedHistory = () => async (dispatch) => {
	dispatch(actionCreator.request(DASHBOARD_ACCOUNT_SEARCH));
	try {
		const response = await axiosInstance.get(`/user/servedaccounts`);
		dispatch(actionCreator.success(DASHBOARD_ACCOUNT_SEARCH, response.data));
	} catch (err) {
		let mockError = {
			message: 'Not found'
		};
		dispatch(actionCreator.failure(DASHBOARD_ACCOUNT_SEARCH, mockError));
	}
};

export const getDashboardMenuCount = () => async (dispatch) => {
	dispatch(actionCreator.request(SET_DASHBOARD_MENU_COUNT));
	try {
		const response = await axiosInstance.get(`/user/counts`);
		dispatch(actionCreator.success(SET_DASHBOARD_MENU_COUNT, response.data.userCounts));
	} catch (err) {
		let mockError = {
			message: 'Not found'
		};
		dispatch(actionCreator.failure(SET_DASHBOARD_MENU_COUNT, mockError));
	}
};

export const emptyAccounts = () => (dispatch) => {
	dispatch(actionCreator.empty(DASHBOARD_ACCOUNT_SEARCH));
};

export const emptyDashboardMenuCount = () => (dispatch) => {
	dispatch(actionCreator.empty(SET_DASHBOARD_MENU_COUNT));
};
