import React, {Component} from 'react';
import {connect} from "react-redux";
import DatePicker from "react-datepicker";
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";

export class ClearTime extends Component {

    render() {

        const {
            field,
            errors,
            touched,
            values,
            setFieldValue,
        } = this.props;
        
        return (
                <div className="form-group" >
                    <label>{field.name}</label> 
                    
                    <div className="customDatePickerWidth">
                        <DatePicker
                            className={"form-control text-left" + (touched.clear_time && errors.clear_time ? " is-invalid" : "")}
                            fieldName="clear_time"
                            placeholderText="Enter time"
                            autoFocus={false}
                            shouldCloseOnSelect={true}
                            popperPlacement={'top'}
                            selected={values.clear_time !== '' ? values.clear_time : null}
                            value={values.clear_time ? values.clear_time : ''}
                            showTimeSelect
                            showTimeSelectOnly
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                            timeIntervals={30}
                            minTime={setHours(setMinutes(new Date(), 0), 9)}
                            maxTime={setHours(setMinutes(new Date(), 0), 18)}
                            onChange={clear_time => setFieldValue('clear_time', clear_time)}
                        />
                    </div>
                    {errors.clear_time && touched.clear_time &&
                    <div className="invalid-feedback">{errors.clear_time}</div>
                    }
                </div>
                );
            }
        }

const mapStateToProps = (state) => {

    return {
        
    };
};

const mapDispatchToProps = {
    
};

export default connect(mapStateToProps, mapDispatchToProps)(ClearTime);