import React, { Component } from 'react';
import { isEmpty } from '../../../../../utils/helpers';

import SummaryContainer from './SummaryContainer';
import ProductsPlan from './ProductsPlan';
import Loader from '../../../../../components/Loader';

class Products extends Component {
	render() {
		const {
			loadingError,
			plansDetails,
			values,
			isSummaryFullWith,
			handleFullWithSummary,
			isSummaryCollapsed,
			handleSummaryCollapsed,
			handleChangeApplyOn,
			setFieldValue,
			accountInfo,
			errors
		} = this.props;

		if (loadingError) {
			return loadingError;
		}

		if (isEmpty(plansDetails)) {
			return <Loader />;
		}

		return (
			<div className="tab-pane active">
				<div className="row">
					<div
						className={
							isSummaryFullWith === true ? (
								'col-lg-9 col-xl-8 services-available d-none'
							) : (
								'col-lg-9 col-xl-8 services-available'
							)
						}
					>
						<div className="accordion accordion-services-available">
							<ProductsPlan planInfo={plansDetails} values={values} setFieldValue={setFieldValue} errors={errors}/>
						</div>
					</div>
					<SummaryContainer {...this.props} tab="Products"/>
				</div>
			</div>
		);
	}
}

export default Products;
