import React, { Component } from 'react'

export class Credit extends Component {
    render() {
        const { values, accountCredit } = this.props;

        return (
            <>
            {accountCredit < values.amountPaid
                ? (<p className="cmv-form-error mb-0">Payment Amount cannot be higher than available Account Credit. Choose lower Payment Amount.</p>)
                : ('')
            }
            </>
        )
    }
}

export default Credit
