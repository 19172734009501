import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

class TableHeaders extends Component {

    state = {
        showLogs: false,
    };

    componentDidMount() {
        this.sortStyling()
    }

    sortStyling = () => {
        const {sortColumn, sortDirection} = this.props;
        if(sortColumn != undefined &&
            sortDirection != undefined){
            const asc = document.getElementById(sortColumn + ".asc");
            const desc = document.getElementById(sortColumn + ".desc");
            if(asc != undefined && desc != undefined){
                if(sortDirection == "asc"){
                    asc.style.opacity = "1";
                    desc.style.opacity = "0.4";
                }else{
                    asc.style.opacity = "0.4";
                    desc.style.opacity = "1";
                }
            }
        }
    }

    triggerSortFunction = (e, sortFunction) => {
        let targetId = e.target.getAttribute('id');
        let targetName = e.target.getAttribute('name');
        if(targetId && targetId.includes("asc")){
            sortFunction(targetName, "asc")
        }else{
            sortFunction(targetName, "desc")
        }
    }

    render() {

        const {
            headers,
            sortFunction,
            sortColumn,
            sortDirection
        } = this.props;

        return (
            <thead>
            <tr>
                {headers.map((header, i) => (
                    <th key={i} className="">
                        <div>
                                        <span className="">
                                            {header.name}
                                        </span>
                            &nbsp;
                            {header.sortable ?
                                <a style={{color: 'grey'}} onClick={(e) => this.triggerSortFunction(e, sortFunction, sortColumn, sortDirection)}>
                                    <i style={{opacity: '0.4'}} id={header.tableName + ".asc"} name={header.tableName} className="fa fa-fw fa-arrow-up"></i>
                                    <i style={{opacity: '0.4'}} id={header.tableName + ".desc"} name={header.tableName}className="fa fa-fw fa-arrow-down"></i>
                                </a>
                                :
                                ''
                            }
                        </div>
                    </th>
                ))}
            </tr>
            </thead>
        );
    }
}

export default TableHeaders;
