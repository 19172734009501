import React, {Component} from 'react';
import {connect} from 'react-redux';
import {createLoadingSelector, getCreateServiceRequest, getProvisioning} from '../../../../selectors';
import {getServiceEquipment, clearServiceEquipment} from "../../../../actions/createServiceRequest.actions";
import {showModal} from "../../../../actions/modal.actions";
import Loader from "../../../../components/Loader";
import Select from "react-select";
import Creatable from "react-select/creatable";
import {groupStyles} from "../../../../utils/SelectStyles";
import {Form, Formik} from "formik";
import isEmpty, {generateId} from "../../../../utils/helpers";
import {searchSerializedItem, clearSerializedItems} from "../../../../actions/provisioning.actions";
import {createOrderChangeDevice} from "../../../../actions/dashboardActions/orders.actions";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
    devices: Yup.array().of(Yup.object().shape({
        deviceGroupId:
            Yup.number().typeError('Required').required('Required'),
        deviceProfileId:
            Yup.number().typeError('Required').required('Required'),
        itemId:
            Yup.number().typeError('Required').required('Required'),
        numbers:
            Yup.object().test('numbers', 'Required', function (numbers) {
                if (isEmpty(numbers)) {
                    return false;
                }
                else {
                    return isEmpty(Object.keys(numbers).filter(key => isEmpty(numbers[key])));
                }
            }),
    }))
});

export class Provisioning extends Component {

    constructor() {
        super();

        this.state = {
            deviceProfile: null,
            createdOptions: {},
            scanningBarcode: null,
            barcodeValue: null,
            barcodeFetched: null,
            barcodeFound: null,
            barcodeSet: null,
        }

        this.fields = {};
    }

    componentDidMount() {
        this.props.clearServiceEquipment();
        this.props.getServiceEquipment(null, this.props.wizardData.serviceLineId);
    }

    onEquipmentChangeHandler = (option, {setFieldValue, values}) => {
        const {serviceEquipment} = this.props;
        const deviceGroupId = values.devices[0].deviceGroupId;
        const selectedDeviceGroupId = option.value;

        if (deviceGroupId === selectedDeviceGroupId) {
            return;
        }

        // reset device profiles
        const deviceGroup = serviceEquipment.find(dg => dg.deviceGroupId === selectedDeviceGroupId);

        setFieldValue(`devices[0].deviceGroupId`, selectedDeviceGroupId);
        setFieldValue(`devices[0].itemId`, null);
        setFieldValue(`devices[0].numbers`, {});
        setFieldValue(`devices[0].deviceProfileId`, deviceGroup.deviceProfiles[0].id);

        this.setState({
            deviceProfile: deviceGroup.deviceProfiles[0],
        });
    }

    onItemChangeHandler = (option, {setFieldValue, setTouched, values}) => {
        const {clearSerializedItems} = this.props;
        const itemId = values.devices[0].itemId;
        const selectedItemId = option.value;

        if (itemId === selectedItemId) {
            return;
        }

        setFieldValue(`devices[0].itemId`, selectedItemId);
        setFieldValue(`devices[0].numbers`, {});
        setTouched(`devices[0].numbers`, false);
        clearSerializedItems();

        this.itemNumSearch(null, selectedItemId, null, setFieldValue);
    }

    onInputChangeHandler = (event, {setFieldValue, values}) => {
        const {deviceProfile} = this.state;

        let fieldId = event.target.name.replace('_field', '');
        let numbers = values.devices[0].numbers;

        // Update the changed field
        numbers[fieldId] = event.target.value;

        // Get any missing fields from deviceProfile
        {deviceProfile.numTypes.map(numType => {
            if (Object.keys(numbers).includes(numType.id.toString()) === false) {
                numbers[numType.id] = undefined;
            }
        })}

        // Store number values
        setFieldValue(`devices[0].numbers`, numbers);
    }

    onDropdownChangeHandler = ({setFieldValue, values}) => (option, action) => {
        const {deviceProfile} = this.state;
        const {serializedItems} = this.props;

        let selectedItem = serializedItems[
            option
                ? option.value
                : this.fields[this.state.scanningBarcode].props.options.filter(option => option.label === this.state.barcodeValue)[0].value
            ];
        let numbers = values.devices[0].numbers;

        // If we selected a preexisting option or entered a barcode
        if (isEmpty(action) || (action.action === "select-option" && !isEmpty(selectedItem))) {
            let fieldId = action ? action.name.replace('_field', '') : this.state.scanningBarcode.replace('_field', '');

            // Update all fields with this item's data
            selectedItem.numbers.forEach(number => {
                numbers[number.systemNumType.id] = number.number;
            });
        }

        // If we created an option
        if (action && action.action === "create-option" && option.__isNew__) {
            let fieldId = action.name.replace('_field', '');

            // Update only the changed field
            numbers[fieldId] = option.value;

            // Store the created option
            let updatedCreatedOptions = this.state.createdOptions;
            if (!updatedCreatedOptions.hasOwnProperty(fieldId)) {
                updatedCreatedOptions[fieldId] = [];
            }
            updatedCreatedOptions[fieldId].push(option);

            this.setState({createdOptions: updatedCreatedOptions});
        }

        // If we selected a newly created option
        if (action && action.action === "select-option" && option.__isNew__) {
            let fieldId = action.name.replace('_field', '');

            // Update only the changed field
            numbers[fieldId] = option.value;
        }

        // Get any missing fields from deviceProfile
        {deviceProfile.numTypes.map(numType => {
            if (Object.keys(numbers).includes(numType.id.toString()) === false) {
                numbers[numType.id] = undefined;
            }
        })}

        // Store number values
        setFieldValue(`devices[0].numbers`, numbers);

        // If we're setting a barcode
        if (this.state.scanningBarcode) {
            this.setState({barcodeSet: true})
        }
    }

    renderEquipmentSelect = (formProps) => {
        const {values, touched, errors} = formProps;
        const deviceGroupId = values.devices[0].deviceGroupId;

        const {serviceEquipment} = this.props;
        const options = [];

        if (serviceEquipment) {
            serviceEquipment.forEach(dg => {
                options.push({
                    // label: dg.name + " (" + dg.description + ")",
                    label: dg.description,
                    value: dg.deviceGroupId
                });
            });
        }

        const selectedDeviceGroup = options.find(opt => opt.value === deviceGroupId);

        return (
            <div className="form-group">
                <label>Select Equipment</label>
                <Select
                    name="deviceGroupId"
                    value={selectedDeviceGroup}
                    onChange={(option) => this.onEquipmentChangeHandler(option, formProps)}
                    options={options}
                    placeholder="Select Equipment"
                    styles={groupStyles}
                    isDisabled={this.isSubmitting()}
                    className={
                        touched.devices && touched.devices[0].deviceGroupId &&
                        errors.devices && errors.devices[0].deviceGroupId
                            ? "is-invalid"
                            : "" }
                />
                {touched.devices && touched.devices[0].deviceGroupId &&
                errors.devices && errors.devices[0].deviceGroupId &&
                <div className="invalid-feedback">Please choose equipment.</div>
                }
            </div>
        );
    }

    renderItemSelect = (formProps) => {
        const {values, touched, errors} = formProps;
        const itemId = values.devices[0].itemId;

        const {deviceProfile} = this.state;

        if (isEmpty(deviceProfile)) {
            return null;
        }

        const options = [];

        if (deviceProfile.possibleItems) {
            deviceProfile.possibleItems.forEach(item => {
                options.push({
                    label: item.description,
                    value: item.id
                });
            });
        }

        let selectedItem = options.find(opt => opt.value === itemId);

        if (isEmpty(selectedItem)) {
            selectedItem = null;
        }

        return (
            <div className="form-group">
                <label>Select Device</label>
                <Select
                    name="itemId"
                    value={selectedItem}
                    onChange={(option) => this.onItemChangeHandler(option, formProps)}
                    options={options}
                    placeholder="Select Device"
                    styles={groupStyles}
                    isDisabled={this.isSubmitting()}
                    className={
                        touched.devices && touched.devices[0].itemId &&
                        errors.devices && errors.devices[0].itemId
                            ? "is-invalid"
                            : "" }
                />
                {touched.devices && touched.devices[0].itemId &&
                errors.devices && errors.devices[0].itemId &&
                <div className="invalid-feedback">Please choose device.</div>
                }
            </div>
        );
    }

    renderItemNumbers = (formProps) => {
        const {values, touched, errors} = formProps;
        const itemId = values.devices[0].itemId;

        const {deviceProfile} = this.state;

        if (isEmpty(itemId)) {
            return null;
        }

        return (
            <>
                <fieldset
                    // className={(
                        // touched.devices && touched.devices[0].numbers &&
                        // errors.devices && errors.devices[0].numbers)
                            // ? "is-invalid"
                            // : "" }
                >
                    <legend>Device Numbers</legend>
                    {deviceProfile.numTypes.map(numType => (
                        <div className="form-group row">
                            {this.renderItemNumber(formProps, numType)}
                        </div>
                    ))}
                </fieldset>
                {/*{touched.devices && touched.devices[0].numbers && */}
                {/*errors.devices && errors.devices[0].numbers &&*/}
                {/*<div className="invalid-feedback">Please fill out all fields.</div>*/}
                {/*}*/}
            </>
        );
    }

    renderItemNumber = (formProps, numType) => {
        const {values, touched, errors} = formProps;
        const itemId = values.devices[0].itemId;
        const {deviceProfile, createdOptions} = this.state;
        const {serializedItemsLoader, serializedItems} = this.props;

        if (isEmpty(deviceProfile)) {
            return null;
        }

        const options = [];

        let selectedItem = null;

        // Gather dropdown options from loaded serialised items
        serializedItems.forEach((item, index) => {

            const itemNumber = item.numbers.find((curNum) => curNum.systemNumType.id === numType.id);

            const option = {label: itemNumber ? itemNumber.number : '', value: index};

            // Only add option if there's data
            if (!isEmpty(itemNumber)) {
                options.push(option);
            }

            // Indicate selected item
            if (
                !isEmpty(values.devices[0].numbers[numType.id]) &&
                !isEmpty(itemNumber) &&
                itemNumber.number === values.devices[0].numbers[numType.id]
            ) {
                selectedItem = option;
            }
        });

        // If we have created new options
        if (createdOptions.hasOwnProperty(numType.id)) {

            // Gather created dropdown options
            createdOptions[numType.id].map(createdOption => {
                options.push(createdOption);

                // Indicate selected item
                if (
                    !isEmpty(values.devices[0].numbers[numType.id]) &&
                    createdOption.value === values.devices[0].numbers[numType.id]
                ) {
                    selectedItem = createdOption;
                }
            });
        }

        return (
            <>
                <div className="col-lg-4">
                    <label className="small">
                        {numType.description}
                    </label>
                </div>
                <div className="col-lg-8">
                    <div className="barcode">
                        {numType.defaultSource === "number_inventory" || numType.defaultSource === "assign_mapping_note"
                        ?
                            <input
                                id={`${numType.id}_field`}
                                name={`${numType.id}_field`}
                                autoComplete='off'
                                value={values.devices[0].numbers[`${numType.id}`] || ""}
                                disabled={this.isSubmitting() || numType.defaultSource === "assign_mapping_note"}
                                onChange={(e) => this.onInputChangeHandler(e, formProps)}
                                className={"form-control" +
                                (touched.devices && touched.devices[0].numbers &&
                                isEmpty(values.devices[0].numbers[`${numType.id}`])
                                    ? " is-invalid" : "")
                                }
                            />
                        :
                            <Select
                                ref={element => (this.fields[`${numType.id}_field`] = element)}
                                id={`${numType.id}_field`}
                                name={`${numType.id}_field`}
                                value={selectedItem}
                                placeholder=""
                                options={options}
                                isDisabled={this.isSubmitting()}
                                isLoading={serializedItemsLoader}
                                onChange={this.onDropdownChangeHandler(formProps)}
                                onInputChange={this.itemNumSearchChangeHandler(numType, formProps)}
                                styles={groupStyles}
                                className={
                                    touched.devices && touched.devices[0].numbers &&
                                    isEmpty(values.devices[0].numbers[`${numType.id}`])
                                        ? "is-invalid"
                                        : "" }
                            />
                        }
                        {/*
                        <button
                            type="button"
                            className="btn btn-link"
                            onClick={() => this.launchBarcodeScanner(
                                `${numType.id}_field`,
                                this.itemNumSearchChangeHandler(numType, formProps),
                                this.onDropdownChangeHandler(formProps),
                            )}
                        >
                            <i className="fas fa-barcode" />
                        </button>
                        */}
                    </div>
                    {touched.devices && touched.devices[0].numbers &&
                    isEmpty(values.devices[0].numbers[`${numType.id}`]) &&
                    <div className="invalid-feedback">Required</div>
                    }
                </div>
            </>
        );
    }

    itemNumSearchChangeHandler = (numType, formProps) => (value, option) => {

        if (option) {

            if (option.action === 'input-change') {

                const itemTimeoutName = `${numType.id}_timeout`;
                const itemNumSearchTimeOut = this[itemTimeoutName];

                if (itemNumSearchTimeOut) {
                    clearTimeout(itemNumSearchTimeOut);
                }

                this[itemTimeoutName] = setTimeout(() => {
                    this.itemNumSearch(numType, formProps.values.devices[0].itemId, value, formProps.setFieldValue, formProps.values);
                }, 300);
            }
        }
        else {
            this.itemNumSearch(numType, formProps.values.devices[0].itemId, this.state.barcodeValue, formProps.setFieldValue, formProps.values, true);
        }
    }

    itemNumSearch = (numType, itemId, value, setFieldValue, values, autoSelect) => {
        const {deviceProfile} = this.state;
        const {wizardData, searchSerializedItem} = this.props;

        searchSerializedItem(wizardData.accountId, itemId, numType ? numType.id : null, value, wizardData.serviceLineId).then(response => {

            let numbers = values ? values.devices[0].numbers : {};

            // If only one item found - directly select it
            if (response.serializedItems.length === 1) {

                // Get the numbers of the loaded item
                response.serializedItems[0].numbers.forEach(number => {
                    numbers[number.systemNumType.id] = number.number;
                });
            }

            // Get any missing fields from deviceProfile
            {deviceProfile.numTypes.map(numType => {

                // if (Object.keys(numbers).includes(numType.id.toString()) === false) {
                    if (numType.defaultSource === "number_inventory") {
                        numbers[numType.id] = values ? values.devices[0].numbers[numType.id] : undefined;
                    }
                    else {
                        numbers[numType.id] = undefined;
                    }
                // }
            })}

            setFieldValue(`devices[0].numbers`, numbers);

            if (autoSelect) {
                this.setState({
                    barcodeFetched: true,
                    barcodeFound: response.serializedItems.length > 0,
                });
            }
        });
    }

    launchBarcodeScanner = (fieldName, searchCallback, selectCallback) => {
        this.props.showModal('BARCODE_SCANNER_MODAL', {
            onCloseCallback: (value) => this.setBarcodeValue(value, searchCallback, selectCallback)
        });

        this.setState({scanningBarcode: fieldName});
    }

    setBarcodeValue = (value, searchCallback, selectCallback) => {
        this.setState({barcodeValue: value}, () => {
            searchCallback();

            let barcodeInterval = setInterval(() => {

                if (this.state.barcodeFetched) {
                    clearInterval(barcodeInterval);

                    if (this.state.barcodeFound) {
                        selectCallback();
                    }
                }
            },50);
        });
    }

    handleOnSubmit = (values, actions) => {

        this.props.createOrderChangeDevice(values).then(response => {
            if (response && response.success) {
                this.handleNext({...values});
            }
        });
    }

    handleNext = (values) => {
        const { next } = this.props;

        if (!next) {
            return;
        }

        next({
            values: values,
        });
    }

    isSubmitting = () => {
        return this.props.orderCreationLoader === true;
    }

    render() {

        const {
            serviceEquipmentLoader,
            orderCreationLoader,
            wizardData,
        } = this.props;

        return (
            <>

                {serviceEquipmentLoader
                ?
                <Loader />
                :
                <Formik
                    onSubmit={this.handleOnSubmit}
                    validationSchema={validationSchema}
                    initialValues={{
                        id: null,
                        name: 'SERVICE_CHANGE_DEVICE_' + generateId(),
                        accountId: wizardData.accountId,
                        existingServiceLineId: wizardData.serviceLineId,
                        source: 'CAMVIO-WEB',
                        type: 'ADD_DEVICES',
                        devices: [{
                            deviceGroupId: null,
                            deviceProfileId: null,
                            itemId: null,
                            action: 'ADD',
                            numbers: {}
                        }]
                    }}
                    render={(formProps) => (
                        <Form onSubmit={formProps.handleSubmit} className="cmv-form" autoComplete="off">

                            {this.renderEquipmentSelect(formProps)}
                            {this.renderItemSelect(formProps)}
                            {this.renderItemNumbers(formProps)}

                            <div className="wizard-footer d-flex justify-content-between">
                                <button
                                    type="submit"
                                    className="btn btn-primary btn-next-tab ml-auto"
                                    onClick={formProps.handleSubmit}
                                    disabled={this.isSubmitting()}
                                >
                                    {orderCreationLoader
                                        ? <i className="fas fa-fw fa-spin fa-spinner" style={{marginLeft: '5px'}} />
                                        : "ADD"
                                    }
                                </button>
                            </div>

                        </Form>
                    )}
                />
                }

            </>
        )
    }
}

const serviceEquipmentLoadingSelector = createLoadingSelector(['SERVICE_EQUIPMENT']);
const serializedItemsSelector = createLoadingSelector(['PROVISIONING_SEARCH_SERIALIZED_ITEM']);
const orderCreationSelector = createLoadingSelector(['CREATE_SERVICE_CHANGE_DEVICE']);

const mapStateToProps = (state) => {

    const serviceEquipmentLoader = serviceEquipmentLoadingSelector(state);
    const serviceEquipment = getCreateServiceRequest(state).serviceEquipment;
    const serializedItemsLoader = serializedItemsSelector(state);
    const serializedItems = getProvisioning(state).serializedItems;
    const orderCreationLoader = orderCreationSelector(state);

    return {
        serviceEquipmentLoader,
        serviceEquipment,
        serializedItemsLoader,
        serializedItems,
        orderCreationLoader,
    }
};

const mapDispatchToProps = {
    getServiceEquipment,
    clearServiceEquipment,
    searchSerializedItem,
    clearSerializedItems,
    createOrderChangeDevice,
    showModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(Provisioning);