import React, {Component} from 'react';
import {isEmpty} from '../../../../../../utils/helpers';
import moment from "moment";
import {showModal} from "../../../../../../actions/modal.actions";
import {connect} from "react-redux";
import {getUserPermission} from "../../../../../../selectors";

export class ServiceSummary extends Component {

    state = {
        isExpanded: null,
    }

    showPackageFeatures = () => {
        this.setState({isExpanded: !this.state.isExpanded});
    }

    render() {

        const {isExpanded} = this.state;

        const {service, showHistoricalData, canEditEquipmentAttributes} = this.props;

        if (isEmpty(service)) {
            return null;
        }

        let recurringFeaturePackageFees = null;
        let recurringFeatureFees = null;
        let oneTimeFeaturePackageFees = null;
        let oneTimeFeatureFees = null;

        if (!showHistoricalData) {
            recurringFeaturePackageFees = !isEmpty(service.activeFeaturePackages) ?
                service.activeFeaturePackages.filter((x) => x.featureType !== 'ACTIVATION') : null;

            recurringFeatureFees = !isEmpty(service.servicelineFeatures) ?
                service.servicelineFeatures.filter((x) => x.featureType !== 'ACTIVATION') : null;

            oneTimeFeaturePackageFees = !isEmpty(service.activeFeaturePackages) ?
                service.activeFeaturePackages.filter((x) => x.featureType === 'ACTIVATION') : null;

            oneTimeFeatureFees = !isEmpty(service.servicelineFeatures) ?
                service.servicelineFeatures.filter((x) => x.featureType === 'ACTIVATION') : null;
        }
        else {

            recurringFeatureFees = !isEmpty(service.servicelineFeatures) ?
                service.servicelineFeatures.filter((x) => x.featureType !== 'ACTIVATION') : null;

            oneTimeFeatureFees = !isEmpty(service.servicelineFeatures) ?
                service.servicelineFeatures.filter((x) => x.featureType === 'ACTIVATION') : null;
        }

        return (
            <div className="services-summary mt-0">
                <div className="services-summary-body">
                    <div className="plan-wrapper">
                        <div className="serviceline-wrapper">

                            {service.servicelineNums &&
                            <div className="plan-section">
                                <h5>Numbers</h5>
                                {!isEmpty(service.servicelineNums) && service.servicelineNums.map((num) =>
                                    <div className="package-wrapper">
                                        <div className="package-option">
                                            <span className="po-title">{num.number}</span>
                                            <span className="po-price">{num.type}</span>
                                            {showHistoricalData &&
                                            <div className="po-date">
                                                {num.startDate && moment(num.startDate).format("MM/DD/YYYY")}
                                                {" - "}
                                                {num.endDate ? moment(num.endDate).format("MM/DD/YYYY") : "Now"}
                                            </div>
                                            }
                                        </div>
                                    </div>
                                )}
                            </div>
                            }

                            {!isEmpty(recurringFeaturePackageFees) || !isEmpty(recurringFeatureFees) ?
                            <div className="plan-section">

                                <h5>
                                    Recurring
                                    <button
                                        className="btn btn-xs btn-secondary"
                                        onClick={this.showPackageFeatures}
                                    >
                                        <i className="fa fa-info" />
                                    </button>
                                </h5>

                                {!isEmpty(recurringFeaturePackageFees) && recurringFeaturePackageFees.map((feature, index) => (
                                <div className="package-wrapper" key={index}>
                                    <div className="package-option">
                                        <span className="po-title">
                                            {feature.description &&
                                            `${feature.description.replace(/\s<br><(\w+)\b[^<>]*>[\s\S]*?<\/\1>/g, '')}:`}{' '}
                                            {feature.featurePackageOptions.map((featureAndPackageOptions) => {
                                                return featureAndPackageOptions.description
                                            })}
                                        </span>
                                        <span className="po-price">
                                            ${feature.featurePackageOptions[0].recurringMonthlyPrice
                                                .toFixed(2)
                                                .replace(/\d(?=(\d{3})+\.)/g, '$&,')}/mo
                                        </span>

                                        {isExpanded &&
                                        <div className="container-list-bubble container-list-bubble-features">
                                            {feature.featurePackageOptions.map((options) => options.features.map((f) =>
                                            <dl>
                                                <dt>{f.featureDescription}</dt>
                                                <dd>{f.qty} &times; ${f.recurringMonthlyPrice}/mo</dd>
                                            </dl>
                                            ))}
                                        </div>
                                        }

                                    </div>
                                </div>
                                ))}

                                {!isEmpty(recurringFeatureFees) && recurringFeatureFees.map((feature, index) => (
                                <div className="package-wrapper" key={index}>
                                    <div className="package-option">
                                        <span className="po-title">
                                            {feature.featureDescription && feature.featureDescription}
                                        </span>
                                        <span className="po-price">
                                            {feature.qty > 1
                                                ? <>Qty: {feature.qty}<span className="text-muted"> | </span></>
                                                : ""}
                                            ${feature.recurringMonthlyPrice
                                                .toFixed(2)
                                                .replace(/\d(?=(\d{3})+\.)/g, '$&,')}/mo
                                        </span>
                                        {showHistoricalData &&
                                        <div className="po-date">
                                            {feature.startDate && moment(feature.startDate).format("MM/DD/YYYY")}
                                            {" - "}
                                            {feature.endDate ? moment(feature.endDate).format("MM/DD/YYYY") : "Now"}
                                        </div>
                                        }
                                    </div>
                                </div>
                                ))}

                            </div>
                            :
                            null
                            }

                            {!isEmpty(oneTimeFeaturePackageFees) || !isEmpty(oneTimeFeatureFees) ? (
                            <div className="plan-section">

                                <h5>Onetime</h5>

                                {!isEmpty(oneTimeFeaturePackageFees) && oneTimeFeaturePackageFees .map((feature, index) => (
                                <div className="package-wrapper" key={index}>
                                    <div className="package-option package-option-mandatory">
                                        <span className="po-title">
                                            {feature.description && `${feature.description}:`}{' '}
                                            {feature.note}
                                        </span>
                                        <span className="po-price">
                                            ${feature.featurePackageOptions[0].oneTimePrice
                                                .toFixed(2)
                                                .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                                        </span>
                                    </div>
                                </div>
                                ))}

                                {!isEmpty(oneTimeFeatureFees) && oneTimeFeatureFees.map((feature, index) => (
                                <div className="package-wrapper" key={index}>
                                    <div className="package-option package-option-mandatory">
                                        <span className="po-title">
                                            {feature.featureDescription && feature.featureDescription}
                                        </span>
                                        <span className="po-price">
                                            {feature.qty > 1
                                                ? <>Qty: {feature.qty}<span className="text-muted"> | </span></>
                                                : ""}
                                            ${feature.oneTimePrice
                                                .toFixed(2)
                                                .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                                        </span>
                                        {showHistoricalData &&
                                        <div className="po-date">
                                            {feature.startDate && moment(feature.startDate).format("MM/DD/YYYY")}
                                            {" - "}
                                            {feature.endDate ? moment(feature.endDate).format("MM/DD/YYYY") : "Now"}
                                        </div>
                                        }
                                    </div>
                                </div>
                                ))}

                            </div>
                            ) : null }

                            {!isEmpty(service.serviceDevices) &&
                            <div className="plan-section">

                                <h5>Equipment</h5>

                                {service.serviceDevices.map((device, index) =>
                                <div className="package-option" key={index}>
                                    <span className="po-title">{device.deviceItemDescription ? device.deviceItemDescription : null}</span>
                                    {canEditEquipmentAttributes &&
                                        <button
                                            title="Edit"
                                            onClick={() =>
                                                this.props.showModal('EDIT_EQUIPMENT_MODAL', {
                                                    device: device,
                                                    service: service,
                                                    showHistoricalData: showHistoricalData
                                                })
                                            }
                                            className="btn btn-xs btn-outline-secondary btn-cn-edit"
                                        >
                                            Edit
                                        </button>
                                    }
                                    {showHistoricalData &&
                                    <div className="po-date">
                                        {device.startDate && moment(device.startDate).format("MM/DD/YYYY")}
                                        {" - "}
                                        {device.endDate ? moment(device.endDate).format("MM/DD/YYYY") : "Now"}
                                    </div>
                                    }
                                    <div className="container-list-bubble container-list-bubble-equipment">
                                        {device.apiServiceDeviceNums && device.apiServiceDeviceNums.map((num, index) =>
                                        <dl key={index}>
                                            <dt>{num.type}</dt>
                                            <dd>{num.number}</dd>
                                        </dl>
                                        )}
                                    </div>
                                </div>
                                )}

                            </div>
                            }

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const canEditEquipmentAttributes = getUserPermission(state, 'ADMN', 'ADMN_EDIT_SERVICELINE_EQUIP_ATTRIBUTES');
    return {
        canEditEquipmentAttributes,
    };
};

const mapDispatchToProps = {
    showModal,
};


export default connect(mapStateToProps, mapDispatchToProps)(ServiceSummary);
