import React, { Component, Fragment } from 'react';
import {
	IncludedFeatures,
	IncludedPackages,
	MandatoryFeatures,
	MandatoryPackages,
	OptionalFeatures,
	OptionalPackages
} from './ProductsItems';

class ProductsServiceline extends Component {

	state = {
		isColapsed: true
	};

	showHideColaps = () => {
		this.setState((prevState) => ({
			isColapsed: !prevState.isColapsed
		}));
	};

	handleMandatoryPackages = (packageId, lineIndex, item) => {
		const { planIndex, values, setFieldValue } = this.props;

		let updateFeatures = values.plans[planIndex].servicelines[lineIndex].features.filter(
			(feature) => feature.featurePackageId !== item.featurePackageId
		);

		updateFeatures = [ ...updateFeatures, item ];
		setFieldValue(`plans.${planIndex}.servicelines.${lineIndex}.features`, updateFeatures);
	};

	handleOptionalPackagesCheckbox = (packageId, lineIndex, item) => {
		const { planIndex, values, setFieldValue } = this.props;

		let updateFeatures;

		if (
			values.plans[planIndex].servicelines[lineIndex].features.findIndex(
				(feature) => feature.featurePackageId === packageId
			) !== -1
		) {
			updateFeatures = values.plans[planIndex].servicelines[lineIndex].features.filter(
				(feature) => feature.featurePackageId !== item.featurePackageId
			);
		} else {
			updateFeatures = [ ...values.plans[planIndex].servicelines[lineIndex].features ];
			updateFeatures = [ ...updateFeatures, item ];
		}

		//Set featureVariabledPrice
		updateFeatures.map(feature => {
			if(feature.variablePrice){
				feature.featureVariabledPrice = feature.recurringMonthlyPrice;
			}
		})

		setFieldValue(`plans.${planIndex}.servicelines.${lineIndex}.features`, updateFeatures);
	};

	handleOptionalFeature = (featureId, lineIndex, item) => {
		const { planIndex, values, setFieldValue } = this.props;

		let updatedFeatures = [];
		let updatedItem = {...item};

		updatedItem.featureVariabledPrice = updatedItem.price;
		updatedItem.recurringMonthlyPrice = updatedItem.price;

		if (
			values.plans[planIndex].servicelines[lineIndex].features &&
			values.plans[planIndex].servicelines[lineIndex].features.findIndex((feature) => feature.id === featureId) !== -1
		) {
			updatedFeatures = values.plans[planIndex].servicelines[lineIndex].features.filter((feature) => feature.id !== item.id);
		}
		else {
			if (values.plans[planIndex].servicelines[lineIndex].features) {
				updatedFeatures = [...values.plans[planIndex].servicelines[lineIndex].features];
			}
			updatedFeatures = [ ...updatedFeatures, updatedItem ];
		}

		//Set featureVariabledPrice
		updatedFeatures.map(feature => {
			if (feature.variablePrice) feature.featureVariabledPrice = feature.recurringMonthlyPrice;
		})

		setFieldValue(`plans.${planIndex}.servicelines.${lineIndex}.features`, updatedFeatures);
	};

	handleVariableFeatureQuantity = (item, quantity, planIndex = 0, lineIndex = 0) => {
		const { values, setFieldValue } = this.props;

		// If quantity is positive
		if (parseInt(quantity) > 0) {

			// Pick current feature
			let updatedFeature = {...item};
			updatedFeature['action'] = values.plans[planIndex].servicelines[lineIndex].features.filter((feature) => feature.id === updatedFeature.id)[0].action;

			// Update quantity
			updatedFeature.featureVariabledQuantity = parseInt(quantity);

			// Pick all features except current
			let otherFeatures = [...values.plans[planIndex].servicelines[lineIndex].features.filter((feature) => feature.id !== updatedFeature.id)];

			// Add updated feature
			let updatedFeatures = [...otherFeatures, updatedFeature];

			// Update form
			setFieldValue(`plans[${planIndex}].servicelines[${lineIndex}].features`, updatedFeatures);
		}
	};

	handleVariableFeaturePrice = (item, price, planIndex = 0, lineIndex = 0) => {
		const { values, setFieldValue } = this.props;

		let newPrice = price;

		// If price is a valid number
		if (!isNaN(Number(newPrice))) {

			// If price includes decimal
			if (newPrice.includes('.')) {

				// Get cents
				let newPriceCents = price.split('.').pop();

				// Round to max two characters after the decimal point
				if (newPriceCents.length > 2) {
					newPrice = newPrice.slice(0, (price.indexOf(".")) + 3);
				}
			}

			// Pick current feature
			let updatedFeature = {...item};

			// Update activation price...
			if (updatedFeature.featureType === 'ACTIVATION') {
				updatedFeature.oneTimePrice = newPrice;
				updatedFeature.featureVariabledPrice = newPrice;
			}
			// ... or monthly price
			else {
				updatedFeature.recurringMonthlyPrice = newPrice;
				updatedFeature.featureVariabledPrice = newPrice;
			}

			// Pick all features except current
			let otherFeatures = [...values.plans[planIndex].servicelines[lineIndex].features.filter((feature) => feature.id !== updatedFeature.id)];

			// Add updated feature
			let updatedFeatures = [...otherFeatures, updatedFeature];

			// Update form
			setFieldValue(`plans[${planIndex}].servicelines[${lineIndex}].features`, updatedFeatures);
		}
	};

	handleOptionalPackages = (packageId, lineIndex, item) => {
		const { planIndex, values, setFieldValue } = this.props;

		let updateFeatures = values.plans[planIndex].servicelines[lineIndex].features.filter(
			(feature) => feature.featurePackageId !== item.featurePackageId
		);

		updateFeatures = [ ...updateFeatures, item ];
		//Set featureVariabledPrice
		updateFeatures.map(feature => {
			if(feature.variablePrice){
				feature.featureVariabledPrice = feature.recurringMonthlyPrice;
			}
		})
		setFieldValue(`plans.${planIndex}.servicelines.${lineIndex}.features`, updateFeatures);
	};

	linePlans = () => {
		const { lineInfo, planInfo, lineIndex, planIndex, values, validatedAddress, errors, touched } = this.props;
		let line = [];

		if (lineInfo.serviceLineRelation === 'Main' || lineInfo.serviceLineRelation === 'MAIN') {
			if (planInfo.mainIncludedFeatures) {
				line.push(
					<IncludedFeatures
						lineInfo={lineInfo}
						lineIndex={lineIndex}
						planFeature={planInfo.mainIncludedFeatures}
						// accountInitialStatus={values.type}
						accountInitialStatus={
							validatedAddress && validatedAddress.allowedServiceOrderType ? validatedAddress.allowedServiceOrderType : ''
						}
					/>
				);
			}
			if (planInfo.mainIncludedFeaturePackages) {
				line.push(
					<IncludedPackages
						lineInfo={lineInfo}
						lineIndex={lineIndex}
						planFeature={planInfo.mainIncludedFeaturePackages}
						// accountInitialStatus={values.type}
						accountInitialStatus={
							validatedAddress && validatedAddress.allowedServiceOrderType ? validatedAddress.allowedServiceOrderType : ''
						}
					/>
				);
			}
			if (planInfo.mainMandatoryFeatures) {
				line.push(
					<MandatoryFeatures
						lineInfo={lineInfo}
						lineIndex={lineIndex}
						planFeature={planInfo.mainMandatoryFeatures}
						// accountInitialStatus={values.type}
						accountInitialStatus={
							validatedAddress && validatedAddress.allowedServiceOrderType ? validatedAddress.allowedServiceOrderType : ''
						}
					/>
				);
			}
			if (planInfo.mainMandatoryFeaturePackages) {
				line.push(
					<MandatoryPackages
						lineInfo={lineInfo}
						lineIndex={lineIndex}
						planFeature={planInfo.mainMandatoryFeaturePackages}
						handleMandatoryPackages={this.handleMandatoryPackages}
						// accountInitialStatus={values.type}
						accountInitialStatus={
							validatedAddress && validatedAddress.allowedServiceOrderType ? validatedAddress.allowedServiceOrderType : ''
						}
					/>
				);
			}
			if (planInfo.mainOptionalFeatures) {
				line.push(
					<OptionalFeatures
						planIndex={planIndex}
						lineIndex={lineIndex}
						lineInfo={lineInfo}
						errors={errors}
						touched={touched}
						planFeature={planInfo.mainOptionalFeatures}
						handleOptionalFeature={this.handleOptionalFeature}
						handleVariableFeatureQuantity={this.handleVariableFeatureQuantity}
						handleVariableFeaturePrice={this.handleVariableFeaturePrice}
						// accountInitialStatus={values.type}
						accountInitialStatus={
							validatedAddress && validatedAddress.allowedServiceOrderType ? validatedAddress.allowedServiceOrderType : ''
						}
					/>
				);
			}
			if (planInfo.mainOptionalFeaturePackages) {
				line.push(
					<OptionalPackages
						lineInfo={lineInfo}
						lineIndex={lineIndex}
						errors={errors}
						touched={touched}
						planFeature={planInfo.mainOptionalFeaturePackages}
						handleOptionalPackages={this.handleOptionalPackages}
						handleOptionalPackagesCheckbox={this.handleOptionalPackagesCheckbox}
						// accountInitialStatus={values.type}
						accountInitialStatus={
							validatedAddress && validatedAddress.allowedServiceOrderType ? validatedAddress.allowedServiceOrderType : ''
						}
					/>
				);
			}
		} else if (lineInfo.serviceLineRelation === 'Required' || lineInfo.serviceLineRelation === 'REQUIRED') {
			if (planInfo.requiredIncludedFeatures) {
				line.push(
					<IncludedFeatures
						lineInfo={lineInfo}
						lineIndex={lineIndex}
						errors={errors}
						touched={touched}
						planFeature={planInfo.requiredIncludedFeatures}
						// accountInitialStatus={values.type}
						accountInitialStatus={
							validatedAddress && validatedAddress.allowedServiceOrderType ? validatedAddress.allowedServiceOrderType : ''
						}
					/>
				);
			}
			if (planInfo.requiredIncludedFeaturePackages) {
				line.push(
					<IncludedPackages
						lineInfo={lineInfo}
						lineIndex={lineIndex}
						errors={errors}
						touched={touched}
						planFeature={planInfo.requiredIncludedFeaturePackages}
						// accountInitialStatus={values.type}
						accountInitialStatus={
							validatedAddress && validatedAddress.allowedServiceOrderType ? validatedAddress.allowedServiceOrderType : ''
						}
					/>
				);
			}
			if (planInfo.requiredMandatoryFeatures) {
				line.push(
					<MandatoryFeatures
						lineInfo={lineInfo}
						lineIndex={lineIndex}
						errors={errors}
						touched={touched}
						planFeature={planInfo.requiredMandatoryFeatures}
						// accountInitialStatus={values.type}
						accountInitialStatus={
							validatedAddress && validatedAddress.allowedServiceOrderType ? validatedAddress.allowedServiceOrderType : ''
						}
					/>
				);
			}
			if (planInfo.requiredMandatoryFeaturePackages) {
				line.push(
					<MandatoryPackages
						lineInfo={lineInfo}
						lineIndex={lineIndex}
						errors={errors}
						touched={touched}
						planFeature={planInfo.requiredMandatoryFeaturePackages}
						handleMandatoryPackages={this.handleMandatoryPackages}
						// accountInitialStatus={values.type}
						accountInitialStatus={
							validatedAddress && validatedAddress.allowedServiceOrderType ? validatedAddress.allowedServiceOrderType : ''
						}
					/>
				);
			}

			if (planInfo.requiredOptionalFeatures) {
				line.push(
					<OptionalFeatures
						planIndex={planIndex}
						lineIndex={lineIndex}
						lineInfo={lineInfo}
						errors={errors}
						touched={touched}
						planFeature={planInfo.requiredOptionalFeatures}
						handleOptionalFeature={this.handleOptionalFeature}
						handleVariableFeatureQuantity={this.handleVariableFeatureQuantity}
						handleVariableFeaturePrice={this.handleVariableFeaturePrice}
						// accountInitialStatus={values.type}
						accountInitialStatus={
							validatedAddress && validatedAddress.allowedServiceOrderType ? validatedAddress.allowedServiceOrderType : ''
						}
					/>
				);
			}
			if (planInfo.requiredOptionalFeaturePackages) {
				line.push(
					<OptionalPackages
						lineInfo={lineInfo}
						lineIndex={lineIndex}
						errors={errors}
						touched={touched}
						planFeature={planInfo.requiredOptionalFeaturePackages}
						handleOptionalPackages={this.handleOptionalPackages}
						handleOptionalPackagesCheckbox={this.handleOptionalPackagesCheckbox}
						// accountInitialStatus={values.type}
						accountInitialStatus={
							validatedAddress && validatedAddress.allowedServiceOrderType ? validatedAddress.allowedServiceOrderType : ''
						}
					/>
				);
			}
		} else if (lineInfo.serviceLineRelation === 'Additional' || lineInfo.serviceLineRelation === 'ADDITIONAL') {
			if (planInfo.additionalIncludedFeatures) {
				line.push(
					<IncludedFeatures
						lineInfo={lineInfo}
						lineIndex={lineIndex}
						errors={errors}
						touched={touched}
						planFeature={planInfo.additionalIncludedFeatures}
						// accountInitialStatus={values.type}
						accountInitialStatus={
							validatedAddress && validatedAddress.allowedServiceOrderType ? validatedAddress.allowedServiceOrderType : ''
						}
					/>
				);
			}
			if (planInfo.additionalIncludedFeaturePackages) {
				line.push(
					<IncludedPackages
						lineInfo={lineInfo}
						lineIndex={lineIndex}
						errors={errors}
						touched={touched}
						planFeature={planInfo.additionalIncludedFeaturePackages}
						// accountInitialStatus={values.type}
						accountInitialStatus={
							validatedAddress && validatedAddress.allowedServiceOrderType ? validatedAddress.allowedServiceOrderType : ''
						}
					/>
				);
			}
			if (planInfo.additionalMandatoryFeatures) {
				line.push(
					<MandatoryFeatures
						lineInfo={lineInfo}
						lineIndex={lineIndex}
						errors={errors}
						touched={touched}
						planFeature={planInfo.additionalMandatoryFeatures}
						// accountInitialStatus={values.type}
						accountInitialStatus={
							validatedAddress && validatedAddress.allowedServiceOrderType ? validatedAddress.allowedServiceOrderType : ''
						}
					/>
				);
			}
			if (planInfo.additionalMandatoryFeaturePackages) {
				line.push(
					<MandatoryPackages
						lineInfo={lineInfo}
						lineIndex={lineIndex}
						errors={errors}
						touched={touched}
						planFeature={planInfo.additionalMandatoryFeaturePackages}
						handleMandatoryPackages={this.handleMandatoryPackages}
						// accountInitialStatus={values.type}
						accountInitialStatus={
							validatedAddress && validatedAddress.allowedServiceOrderType ? validatedAddress.allowedServiceOrderType : ''
						}
					/>
				);
			}
			if (planInfo.additionalOptionalFeatures) {
				line.push(
					<OptionalFeatures
						planIndex={planIndex}
						lineIndex={lineIndex}
						lineInfo={lineInfo}
						errors={errors}
						touched={touched}
						planFeature={planInfo.additionalOptionalFeatures}
						handleOptionalFeature={this.handleOptionalFeature}
						handleVariableFeatureQuantity={this.handleVariableFeatureQuantity}
						handleVariableFeaturePrice={this.handleVariableFeaturePrice}
						// accountInitialStatus={values.type}
						accountInitialStatus={
							validatedAddress && validatedAddress.allowedServiceOrderType ? validatedAddress.allowedServiceOrderType : ''
						}
					/>
				);
			}
			if (planInfo.additionalOptionalFeaturePackages) {
				line.push(
					<OptionalPackages
						lineInfo={lineInfo}
						lineIndex={lineIndex}
						errors={errors}
						touched={touched}
						planFeature={planInfo.additionalOptionalFeaturePackages}
						handleOptionalPackages={this.handleOptionalPackages}
						handleOptionalPackagesCheckbox={this.handleOptionalPackagesCheckbox}
						// accountInitialStatus={values.type}
						accountInitialStatus={
							validatedAddress && validatedAddress.allowedServiceOrderType ? validatedAddress.allowedServiceOrderType : ''
						}
					/>
				);
			}
		}

		return line.map((item, index) => <Fragment key={index}>{item}</Fragment>);
	};

	render() {
		const { isColapsed } = this.state;

		const { lineInfo } = this.props;

		return (
			<div className="serviceline-wrapper" data-servline-wrapper-id="sl001">
				<div className="serviceline-header">
					<h4
						className={isColapsed === true ? '' : 'collapsed'}
						onClick={this.showHideColaps}
						data-toggle="collapse"
					>
						<span className="badge badge-line-type">{lineInfo.serviceLineRelation}</span>
						<span className="ml-auto">{lineInfo.number}</span>
					</h4>
				</div>
				<div className={isColapsed === true ? 'serviceline-body collapse show' : 'serviceline-body collapse'}>
					{this.linePlans()}
				</div>
			</div>
		);
	}
}

export default ProductsServiceline;
