import axiosInstance from '../utils/axios-instance';
import {actionCreator, createRequestTypes, getErrorMessage} from '../utils/helpers';

export const GET_ACCOUNT_PLAN_SUMMARY = createRequestTypes('GET_ACCOUNT_PLAN_SUMMARY');

export const getAccountPlanSummary = (accountPlanId) => async (dispatch) => {
	dispatch(actionCreator.request(GET_ACCOUNT_PLAN_SUMMARY));

	try {
		const response = await axiosInstance.get(`/wrapper/account_plan/${accountPlanId}/summary`);
		dispatch(actionCreator.success(GET_ACCOUNT_PLAN_SUMMARY, response.data));

		return response;
	} catch(err) {
		dispatch(actionCreator.failure(GET_ACCOUNT_PLAN_SUMMARY, getErrorMessage(err)));

		return err;
	}
};

export const clearAccountPlanSummary = () => async (dispatch) => {
	dispatch(actionCreator.empty(GET_ACCOUNT_PLAN_SUMMARY));
};
