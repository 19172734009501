import {
    PRODUCT_PLAN,
    INSTALLMENT_PLAN,
    PRODUCT_FEATURE,
    PRODUCT_PLAN_NOTE,
    INSTALLMENT_PLAN_NOTE,
    PRODUCT_ALL_FEATURE_PACKAGES,
    PRODUCT_ALL_FEATURES,
    PRODUCT_FEATURE_PACKAGE,
    PRODUCT_FEATURE_TYPES,
    PRODUCT_GENERAL_LEDGERS,
    PRODUCT_RATE_CODES,
    PRODUCT_ADJUSTMENT
} from "../actions/products.action";

const initialState = {
    featurePackage: '',
    featurePackages: '',
    features: '',
    feature: '',
    featureTypes: '',
    generalLedgers:'',
    plan: '',
    installmentplan: '',
    planNote: '',
    installmentplanNote:'',
    rateCodes: '',
    adjustment: ''
};

export const productsReducer = (state = initialState, action) => {
    switch (action.type) {
        case PRODUCT_PLAN.SUCCESS:
            return {
                ...state,
                plan: action.payload
            };
            case PRODUCT_PLAN.CLEAR:
                return {
                    ...state,
                    plan: undefined
                };
        case INSTALLMENT_PLAN.SUCCESS:
            return {
                ...state,
                installmentplan: action.payload
            };
        case INSTALLMENT_PLAN.CLEAR:
            return {
                ...state,
                installmentplan: undefined
            };
        case PRODUCT_FEATURE.SUCCESS:
            return {
                ...state,
                feature: action.payload
            };
        case PRODUCT_FEATURE.CLEAR:
            return {
                ...state,
                feature: undefined
            };
        case PRODUCT_PLAN_NOTE.SUCCESS:
            return {
                ...state,
                planNote: action.payload
            };
        case PRODUCT_PLAN_NOTE.CLEAR:
            return {
                ...state,
                planNote: undefined
            };
        case INSTALLMENT_PLAN_NOTE.SUCCESS:
            return {
                ...state,
                installmentplanNote: action.payload
            };
        case INSTALLMENT_PLAN_NOTE.CLEAR:
            return {
                ...state,
                installmentplanNote: undefined
            };
        case PRODUCT_FEATURE_PACKAGE.SUCCESS:
            return {
                ...state,
                featurePackage: action.payload
            };
        case PRODUCT_FEATURE_PACKAGE.CLEAR:
            return {
                ...state,
                featurePackage: undefined
            };
        case PRODUCT_ALL_FEATURES.SUCCESS:
            return {
                ...state,
                features: action.payload
            };
        case PRODUCT_ALL_FEATURES.CLEAR:
            return {
                ...state,
                features: undefined
            };
        case PRODUCT_ALL_FEATURE_PACKAGES.SUCCESS:
            return {
                ...state,
                featurePackages: action.payload
            };
        case PRODUCT_ALL_FEATURE_PACKAGES.CLEAR:
            return {
                ...state,
                featurePackages: undefined
            };
        case PRODUCT_FEATURE_TYPES.SUCCESS:
            return {
                ...state,
                featureTypes: action.payload
            };
        case PRODUCT_GENERAL_LEDGERS.SUCCESS:
            return {
                ...state,
                generalLedgers: action.payload
            };
        case PRODUCT_RATE_CODES.SUCCESS:
            return {
                ...state,
                rateCodes: action.payload
            };
        case PRODUCT_ADJUSTMENT.SUCCESS:
            return {
                ...state,
                adjustment: action.payload
            };
        default:
            return state;
    }
};
