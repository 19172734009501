import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { hideModal } from '../../../actions/modal.actions';
import {
	getReccuringCreditsTypes,
	createReccuringCredit,
	updateReccuringCredit,
	emptyReccuringCreditsTypes
} from '../../../actions/accountDetailsActions/billing.actions';
import { getAccountDetails, getUser, getModalData } from '../../../selectors';
import { Formik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';

const AdjustmentSchema = Yup.object().shape({
	recurringCreditId: Yup.string().required('Required'),
	validFrom: Yup.date().required('Required'),
	validTo: Yup.date().required('Required'),
	isActive: Yup.boolean().required('Required')
});

class ReccuringCreditsModal extends Component {
	state = {
		showAlertMessage: false,
		alertMessage: ''
	};

	componentDidMount() {
		this.props.getReccuringCreditsTypes();
	}

	componentWillUnmount() {
		this.props.emptyReccuringCreditsTypes()
	}

	handleCreditTypeChange = (e, setValues, setFieldValue) => {
		const value = e.target.value;
		const { reccuringCreditTypesData } = this.props;

		if (value !== '') {
			let currentCredit = reccuringCreditTypesData.find((discount) => discount.id === Number(e.target.value));
		

			let datePeriod = {};
			datePeriod['validTo'] = moment()
				.add(currentCredit.validDays ? currentCredit.validDays : 0, 'days')
				.add(currentCredit.validMonths, 'months')
				.format('YYYY-MM-DD');
				datePeriod['validFrom'] = moment().format('YYYY-MM-DD');

			let data = {
				recurringCreditId: currentCredit.id,
				validFrom: datePeriod.validFrom,
				validTo: datePeriod.validTo,
				amount: currentCredit.amount,
				isActive: false,
				validMonths: currentCredit.validMonths
			};
			setValues(data);
		} else {
			let data = {
				recurringCreditId: null,
				validFrom: '',
				validTo: '',
				validMonths: 0,
				amount: null,
				isActive: null
			};
			
			setValues({ data });
		}
	};

	formSubmit = (values, actions) => {
		if (this.props.modalProps.type === 'create') {
			const data = {
				isActive: values.isActive,
				recurringCreditId: values.recurringCreditId,
				validFrom: values.validFrom,
				validTo: values.validTo
			};
			this.props.createReccuringCredit(this.props.accountId, data)
				.then((response) => {
					if (response.data.success) {
						this.props.hideModal();
					} else {
						actions.setSubmitting(false);
						this.setState({
							showAlertMessage: true,
							alertMessage: response.data.error && response.data.error.message
						});
					}
				})
		} else {
			const data = {
				isActive: values.isActive,
				recurringCreditId: values.recurringCreditId,
				validFrom: values.validFrom,
				validTo: values.validTo
			};
			this.props.updateReccuringCredit(this.props.accountId, this.props.modalProps.row.id, data)
				.then((response) => {
					if (response.data.success) {
						this.props.hideModal();
					} else {
						actions.setSubmitting(false);
						this.setState({
							showAlertMessage: true,
							alertMessage: response.data.error && response.data.error.message
						});
					}
				})
		}
	};

	render() {
		const { showAlertMessage, alertMessage } = this.state;
		const { modalProps, reccuringCreditTypesData } = this.props;
		
		return (
			<Fragment>
				<div className="modal" style={{ display: 'block' }} tabIndex="-1" role="dialog">
					<Formik
						initialValues={{
							recurringCreditId: (modalProps.row && modalProps.row.recurringCreditId) || '',
							validFrom: (modalProps.row && moment(modalProps.row.validFrom).format('YYYY-MM-DD')) || '',
							validTo: (modalProps.row && moment(modalProps.row.validTo).format('YYYY-MM-DD')) || '',
							amount: (modalProps.row && modalProps.row.amount) || '',
							isActive: modalProps.row && modalProps.row.isActive,
							validMonths: (modalProps.row && modalProps.row.validMonths) || '',
							validDays: (modalProps.row && modalProps.row.validDays) || ''
						}}
						validationSchema={AdjustmentSchema}
						onSubmit={this.formSubmit}
						render={({
							handleChange,
							handleSubmit,
							handleBlur,
							values,
							errors,
							touched,
							isSubmitting,
							setFieldValue,
							setValues
						}) => (
							<form onSubmit={handleSubmit}>
								<div className="modal-dialog" role="document">
									<div className="modal-content">
										<div className="modal-header">
											<h5 className="modal-title">
												{modalProps.type === 'create' ? (
													'Add Recurring Credit'
												) : (
													'Edit Recurring Credit'
												)}
											</h5>
											<button onClick={this.props.hideModal} type="button" className="close">
												<span aria-hidden="true">&times;</span>
											</button>
										</div>
										<div className="modal-body cmv-form">
											{showAlertMessage === true && (
												<div className="alert alert-inline alert-danger alert-dismissible">
													<p className="mb-0">{alertMessage}</p>
												</div>
											)}
											<div className="form-group">
												<label>Recurring Credit</label>
												<select
													className={
														errors.recurringCreditId && touched.recurringCreditId ? (
															'form-control selector-reccredit is-invalid'
														) : (
															'form-control selector-reccredit'
														)
													}
													placeholder="Please select serviceline"
													value={values.recurringCreditId}
													onChange={(e) => this.handleCreditTypeChange(e, setValues, setFieldValue)}
													onBlur={handleBlur}
													name="recurringCreditId"
												>
													<option value="">Please select Item</option>
													{reccuringCreditTypesData.map((type) => (
														<option value={type.id} key={type.id}>
															{type.description}
														</option>
													))}
												</select>
												{errors.recurringCreditId &&
												touched.recurringCreditId && (
													<div className="invalid-feedback">{errors.recurringCreditId}</div>
												)}
											</div>
											<div className="form-row">
												<div className="form-group col">
													<label>Valid From</label>
													<input
														type="date"
														placeholder="Valid from"
														className={
															errors.validFrom && touched.validFrom ? (
																'form-control discount-from is-invalid'
															) : (
																'form-control discount-from'
															)
														}
														value={values.validFrom}
														onChange={handleChange}
														onBlur={handleBlur}
														name="validFrom"
													/>
													{errors.validFrom &&
													touched.validFrom && (
														<div className="invalid-feedback">{errors.validFrom}</div>
													)}
													{values.validFrom !== '' &&
													values.validTo !== '' &&
													values.validMonths > 0 &&
													Math.abs(moment(values.validTo).diff(values.validFrom, 'months')) >
														values.validMonths && (
														<div
															className="feedback-container"
															style={{ position: 'relative' }}
														>
															<div
																className="text-warning"
																style={{
																	position: 'absolute',
																	whiteSpace: 'nowrap',
																	fontSize: '0.8rem',
																	margin: '2px'
																}}
															>
																{`WARNING: The dates are outside the valid values ${values.validMonths} months`}
															</div>
														</div>
													)}
												</div>
												<div className="form-group col">
													<label>Valid To</label>
													<input
														type="date"
														placeholder="Valid to"
														className={
															errors.validTo && touched.validTo ? (
																'form-control discount-from is-invalid'
															) : (
																'form-control discount-from'
															)
														}
														value={values.validTo}
														onChange={handleChange}
														onBlur={handleBlur}
														name="validTo"
													/>
													{errors.validTo &&
													touched.validTo && (
														<div className="invalid-feedback">{errors.validTo}</div>
													)}
												</div>
											</div>
											<div className="form-row">
												<div className="form-group col-12 col-md-4">
													<label>Amount</label>
													<p className="form-control-plaintext font-weight-bold reccredit-amo">
														${values.amount && values.amount && values.amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
													</p>
												</div>
												<div className="form-group col-4 d-flex align-items-end">
													<div className="form-check checkbox-slider checkbox-slider--b-flat">
														<label>
															<input
																type="checkbox"
																checked={values.isActive === false}
																onChange={() => setFieldValue(`isActive`, false)}
															/>
															<span>Pending</span>
														</label>
													</div>
												</div>
												<div className="form-group col-6 col-md-4 d-flex align-items-end">
													<div className="form-check checkbox-slider checkbox-slider--b-flat">
														<label>
															<input
																type="checkbox"
																checked={values.isActive === true}
																onChange={() => setFieldValue(`isActive`, true)}
															/>
															<span>Approved</span>
											
														</label>
													</div>
												</div>
											</div>
										</div>
										<div className="modal-footer">
											<button onClick={this.props.hideModal} className="btn" type="button">
												Cancel
											</button>
											<button disabled={isSubmitting} type="submit" className="btn btn-primary">
												{modalProps.type === 'create' ? 'Add CREDIT' : 'Edit CREDIT'}
											</button>
										</div>
									</div>
								</div>
							</form>
						)}
					/>
				</div>
				<div className="modal-backdrop show" tabIndex="1" />
			</Fragment>
		);
	}
}

const mapStateToProps = (state) => {
	const reccuringCreditTypesData = getAccountDetails(state).billing.reccuringCreditTypes,
		currentLocationId = getUser(state).currentLocation.id,
		accountId =  getAccountDetails(state).accountDetails.accountInfo.id,
		modalProps = getModalData(state).modalProps;

	return {
		reccuringCreditTypesData,
		currentLocationId,
		accountId,
		modalProps
	};
};

const mapDispatchToProps = {
	getReccuringCreditsTypes,
	emptyReccuringCreditsTypes,
	createReccuringCredit,
	updateReccuringCredit,
	hideModal
};

export default connect(mapStateToProps, mapDispatchToProps)(ReccuringCreditsModal);
