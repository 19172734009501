import axiosInstance from '../utils/axios-instance';
import { actionCreator, createRequestTypes } from '../utils/helpers';

export const GET_APPOINTMENTS = createRequestTypes('GET_APPOINTMENTS');

export const loadAppointments = (params) => async (dispatch) => {
	dispatch(actionCreator.request(GET_APPOINTMENTS));
	try {
        const response = await axiosInstance.get(`/appointments/list`, {params: params});
		dispatch(actionCreator.success(GET_APPOINTMENTS, response.data));
		return response.data;
	} catch (err) {
		let mockError = {message: 'Something goes wrong'}
		dispatch(actionCreator.failure(GET_APPOINTMENTS, mockError));
		return err.response.data;
	}
}

export const clearAppointments = () => async (dispatch) => {
	dispatch(actionCreator.clear(GET_APPOINTMENTS))
}
