import React, { Component } from 'react';
import MandatoryPackage from './Options/MandatoryPackage';
import ReactHtmlParser from 'react-html-parser';

class MandatoryPackages extends Component {

	render() {
		const { planFeature, accountInitialStatus } = this.props;

		return (
			<div className="plan-section">
				<h5>
					Mandatory Packages <span className="text-muted">(choose one of each)</span>
				</h5>
				{planFeature.map((featurePackage, index) => (
					<div className="package-wrapper" key={featurePackage.featurePackageId}>
						<div className="row">
							<div className="col col-md-5">
								<h6>{featurePackage.description}</h6>
								<div className="package-description">{ReactHtmlParser(featurePackage.note)}</div>
							</div>
							<div className="col col-md-7">
								{featurePackage.featurePackageOptions.map((packageOption) => (
									<MandatoryPackage
										featurePackage={featurePackage}
										packageOption={packageOption}
										key={packageOption.featurePackageOptionId}
										accountInitialStatus={accountInitialStatus}
										{...this.props}
									/>
								))}
							</div>
						</div>
					</div>
				))}
			</div>
		);
	}
}

export default MandatoryPackages;
