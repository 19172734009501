export const groupStyles = {
	control: (styles, { isFocused }) => {
		return {
			...styles,
			backgroundColor: '#fff',
			// border: '1px solid',
			borderColor: isFocused ? '#6ab3c1 !important' : '#adb5bd !important',
			boxShadow: isFocused ? '#6ab3c1 !important' : '#6ab3c1 !important',
			lineHeight: '1rem',
			borderRadius: '4px',
			cursor: 'pointer'
		};
    },
	menu: (styles) => ({
		...styles,
		// backgroundColor: '#6ab3c1',
		border: '2px solid #6ab3c1',
		borderRadius: '4px',
		zIndex: '1051',
        position: 'absolute',
        fontSize: '1rem',
        fontWeight: 'bold'
	}),
	option: (styles, { isFocused }) => {
		return {
			...styles,
			backgroundColor: isFocused ? '#6ab3c1 !important' : '#f7f7f7 !important',
			color: isFocused ? 'white' : 'black',
            cursor: 'pointer',
            fontSize: '0.9rem',
            fontWeight: 'normal'
		};
    },
    dropdownIndicator: (styles) => ({
        ...styles,
        fontSize: '5rem'
    })
};


export const formSelectStyles = {
	control: (styles, { isFocused }) => {
		return {
			...styles,
			backgroundColor: '#fff',
			// border: '1px solid',
			borderColor: isFocused ? '#6ab3c1 !important' : '#adb5bd !important',
			boxShadow: isFocused ? '#6ab3c1 !important' : '#6ab3c1 !important',
			lineHeight: '1rem',
			borderRadius: '4px',
			cursor: 'pointer'
		};
    },
	menu: (styles) => ({
		...styles,
		// backgroundColor: '#6ab3c1',
		border: '2px solid #6ab3c1',
		borderRadius: '4px',
		zIndex: '1051',
        position: 'absolute',
        fontSize: '1rem',
        fontWeight: 'bold'
	}),
	option: (styles, { isFocused }) => {
		return {
			...styles,
			backgroundColor: isFocused ? '#6ab3c1 !important' : '#f7f7f7 !important',
			color: isFocused ? 'white' : 'black',
            cursor: 'pointer',
            fontSize: '0.9rem',
            fontWeight: 'normal'
		};
    },
    dropdownIndicator: (styles) => ({
        ...styles,
        fontSize: '5rem'
    })
};
