import React, { Component, Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import {getTimeslots} from '../../actions/accountDetailsActions/troubleTickets.actions'
import {getAppointmentAreas, getAppointmentCapacities, createAppointmentCapacity} from '../../actions/technicianAppointment.actions'
import {createLoadingSelector, getUser, getAccountDetails, getTechnicianAppointment} from "../../selectors";
import {getFormattedFilters} from "../Dashboard/Content/Tasks/utils";
import isEmpty from "../../utils/helpers";
import {toastr} from "react-redux-toastr";
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import { Formik } from 'formik';
import DatePicker from "react-datepicker";
import CapacityResults from "./CapacityResults";
import Loader from "../../components/Loader";

class Schedule extends Component {

    state = {
        appointmentAreas: [],
        capacityValues: {
            fromDate: moment().startOf('week').toDate(),
            toDate: moment().endOf('week').toDate(),
            areaId: 1
        },
        initialValues: {
            areaId: 1,
            capacity: {
                slot1: {day1: '', day2: '', day3: '', day4: '', day5: '', day6: '', day7: ''},
                slot2: {day1: '', day2: '', day3: '', day4: '', day5: '', day6: '', day7: ''},
                slot3: {day1: '', day2: '', day3: '', day4: '', day5: '', day6: '', day7: ''},
                slot4: {day1: '', day2: '', day3: '', day4: '', day5: '', day6: '', day7: ''},
                slot5: {day1: '', day2: '', day3: '', day4: '', day5: '', day6: '', day7: ''},
            },
        },
        selectedDates: [],
        setAll: 0,
        datepickerText: '',
        changedArea: false,
        initialLoad: true,
    }

    componentDidMount() {

        // Set default selected date range
        this.handleDateChange(new Date());

        // Load areas
        this.props.getAppointmentAreas();

        // Load timeslots
        this.props.getTimeslots();
    }

    fetchAppointmentCapacities = (setFieldValue) => {
        if(this.state.initialLoad) {
            if (this.props.appointmentAreas != undefined && !isEmpty(this.props.appointmentAreas)) {
                let defaultAppointmentArea = this.props.appointmentAreas[0];
                let capacityValues = this.state.capacityValues;
                capacityValues['areaId'] = defaultAppointmentArea[0];
                this.setState({
                    capacityValues: capacityValues
                })
            }
        }
        this.props.getAppointmentCapacities(getFormattedFilters(this.state.capacityValues)).then((response) => {
            if (response && response.data.capacities.length) {
                this.setCapacities(setFieldValue, response.data.capacities);
                this.setState({
                    initialLoad: false
                });
            } else {
                let zeroArray = new Array(28);
                for (let i = 0; i < 28; ++i) zeroArray[i] = 0;
                this.setCapacities(setFieldValue, zeroArray);
            }
        });
    }

    handleAreaChange = (event) => {
        let updatedCapacityValues = this.state.capacityValues;

        updatedCapacityValues['areaId'] = event.target.value;

        this.setState({
            capacityValues: updatedCapacityValues,
            changedArea: true
        });
    }

    handleDateChange = (date) => {

        // Update only if new date is not one of selected dates
        if (!this.state.selectedDates.includes(moment(date).format("MM/DD/YYYY"))) {

            let updatedCapacityValues = this.state.capacityValues;
            updatedCapacityValues['fromDate'] = moment(date).startOf('week').toDate();
            updatedCapacityValues['toDate'] = moment(date).endOf('week').toDate();
            let updatedDatepickerText = moment(updatedCapacityValues['fromDate']).format("MM/DD/YYYY") + " - "
                + moment(updatedCapacityValues['toDate']).format("MM/DD/YYYY");

            this.setState({
                    capacityValues: updatedCapacityValues,
                    datepickerText: updatedDatepickerText,
                }, () => {
                    let updatedSelectedDates = this.getDateRange(updatedCapacityValues['fromDate'], updatedCapacityValues['toDate']);

                    this.setState({
                        selectedDates: updatedSelectedDates,
                    });
                }
            );
        }
    }

    copyFromPreviousWeek = (setFieldValue) => {
        let previousWeek = {
            fromDate: moment(this.state.capacityValues.fromDate).format('YYYY-MM-DD'),
            toDate: moment(this.state.capacityValues.toDate).format('YYYY-MM-DD'),
            areaId: this.state.capacityValues.areaId,
        };

        previousWeek.fromDate = moment(previousWeek.fromDate).subtract(7, 'days').toDate();
        previousWeek.toDate = moment(previousWeek.toDate).subtract(7, 'days').toDate();

        // Load previous week capacity
        this.props.getAppointmentCapacities(getFormattedFilters(previousWeek)).then((response) => {
            if (response && response.data.capacities.length % 7 === 0) {
                this.setCapacities(setFieldValue, response.data.capacities);
            }
        });
    }

    setCapacities = (setFieldValue, capacities) => {
        let updatedCapacity = this.state.initialValues.capacity;
        let index = 0;

        this.props.appointmentTimeslots.map((time, i) => {
            updatedCapacity[("slot"+(i+1))] = {
                day1: capacities[index] && capacities[index].capacity || 0,
                day2: capacities[index + 1] && capacities[index + 1].capacity || 0,
                day3: capacities[index + 2] && capacities[index + 2].capacity || 0,
                day4: capacities[index + 3] && capacities[index + 3].capacity || 0,
                day5: capacities[index + 4] && capacities[index + 4].capacity || 0,
                day6: capacities[index + 5] && capacities[index + 5].capacity || 0,
                day7: capacities[index + 6] && capacities[index + 6].capacity || 0,
            };
            index += 7;
        });

        setFieldValue("capacity", updatedCapacity);
    }

    setAllValues = (setFieldValue) => {
        let value = 5;
        let setAllArray = new Array(28); for (let i=0; i < 28; ++i) setAllArray[i] = value;

        this.setCapacities(setFieldValue, setAllArray);
    }

    handleFormSubmit = (values, actions) => {
        if(!this.state.changedArea){
            values["areaId"] = this.props.appointmentAreas[0][0] || '';
        }else{
            values["areaId"] = this.state.capacityValues.areaId;
        }
        values["dates"] = this.state.selectedDates;

        this.props.createAppointmentCapacity(values).then((response) => {
            if (response.data === "success") {
                toastr.success("Successfully updated capacities", { timeOut: 2000, position: 'top-center' });
            }
            else {
                toastr.error("Failed to update capacities", { timeOut: 2000, position: 'top-center' });
            }

            actions.setSubmitting(false)
        });
    }

    getDateRange(startDate, endDate) {
        let currentDate = startDate;
        let dateArray = [];

        Date.prototype.addDays = function (days) {
            let date = new Date(this.valueOf());
            date.setDate(date.getDate() + days);
            return date;
        }

        while (currentDate <= endDate) {
            new Date(currentDate);
            dateArray.push(moment(currentDate).format('MM/DD/YYYY'));
            currentDate = currentDate.addDays(1);
        }

        return dateArray;
    }

    render() {

        const {
            selectedDates,
            capacityValues,
            initialValues
        } = this.state;

        const {
            areasLoader,
            appointmentAreas,
            timeslotsLoader,
            appointmentTimeslots,
            capacityLoader,
            appointmentCapacity,
        } = this.props;

        const DateRangeInput = ({ value, onClick, isSubmitting }) => (
            <button
                type="button"
                className="form-control"
                disabled={capacityLoader || isSubmitting}
                onClick={onClick}
            >
                {this.state.datepickerText}
            </button>
        );

        return (
            <Fragment>

                {(areasLoader || timeslotsLoader) &&
                <Loader/>
                }

                {!areasLoader && isEmpty(appointmentAreas) &&
                <p>Areas Not Found</p>
                }

                {!areasLoader && !timeslotsLoader &&
                !isEmpty(appointmentAreas) && !isEmpty(appointmentTimeslots) && !isEmpty(selectedDates) && !isEmpty(initialValues) &&
                <div className="cmv-container-table">
                    <Formik
                        initialValues={initialValues}
                        onSubmit={this.handleFormSubmit}
                        render={({handleChange, handleSubmit, values, errors, touched, isSubmitting, setFieldValue}) => (
                            <form onSubmit={handleSubmit} ref={this.FormikElement}>

                                <ToolkitProvider
                                    setFieldValue={setFieldValue}
                                    handleChange={handleChange}
                                    isSubmitting={isSubmitting}
                                    values={values}
                                    bootstrap4
                                    keyField="id"
                                    search
                                >
                                    {(props) => <>

                                        <div className="row">

                                            <div className="col-sm-6 col-md-3">
                                                <div className="form-group">
                                                    <select
                                                        className={'form-control customer-notes-cat'}
                                                        onChange={( event) => this.handleAreaChange(event)}
                                                        disabled={capacityLoader || isSubmitting}
                                                    >
                                                        {appointmentAreas.map((area) =>
                                                            <option key={area[0]} value={area[0]}>{area[2]}</option>
                                                        )}
                                                    </select>
                                                </div>
                                            </div>

                                            <div className="col-sm-6 col-md-4 col-xl-3">
                                                <div className="form-group">
                                                    <div className="customDatePickerWidth">
                                                        <DatePicker
                                                            className={"form-control text-left"}
                                                            fieldName="date"
                                                            dateFormat="MM/dd/yyyy"
                                                            placeholderText="Choose Date"
                                                            shouldCloseOnSelect={true}
                                                            popperPlacement={"bottom-center"}
                                                            customInput={<DateRangeInput />}
                                                            selected={capacityValues.fromDate}
                                                            onChange={(date) => this.handleDateChange(date)}
                                                            disabledKeyboardNavigation
                                                            showDisabledMonthNavigation
                                                            highlightDates={capacityValues.fromDate ? [
                                                                moment(capacityValues.fromDate).startOf('week').toDate(),
                                                                moment(capacityValues.fromDate).startOf('week').add(1, 'days').toDate(),
                                                                moment(capacityValues.fromDate).startOf('week').add(2, 'days').toDate(),
                                                                moment(capacityValues.fromDate).startOf('week').add(3, 'days').toDate(),
                                                                moment(capacityValues.fromDate).startOf('week').add(4, 'days').toDate(),
                                                                moment(capacityValues.fromDate).startOf('week').add(5, 'days').toDate(),
                                                                moment(capacityValues.fromDate).startOf('week').add(6, 'days').toDate(),
                                                            ] : [] }
                                                        />
                                                    </div>
                                                    {errors.date && touched.date &&
                                                    <div className="invalid-feedback">{errors.date}</div>
                                                    }
                                                </div>
                                            </div>

                                            <div className="col-md-5 col-lg-5 col-xl-6">
                                                <div className="search">
                                                    <button
                                                        className="btn btn-secondary"
                                                        type="button"
                                                        disabled={capacityLoader || isSubmitting}
                                                        onClick={() => this.copyFromPreviousWeek(setFieldValue)}
                                                    >
                                                        <i className="far fa-clone"/>
                                                        <span> Copy From Previous Week</span>
                                                    </button>
                                                </div>
                                            </div>

                                            {/*
                                            <div className="col-sm-2">
                                                <div className="input-group">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder={0}
                                                        onChange={this.updateAllValue}
                                                    />
                                                    <div className="input-group-append">
                                                        <button
                                                            className="btn btn-outline-secondary"
                                                            type="button"
                                                            disabled={isSubmitting}
                                                            onClick={() => this.setAllValues(setFieldValue)}
                                                        >
                                                            <span> Set All </span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            */}

                                        </div>

                                        <CapacityResults
                                            fetchAppointmentCapacities={this.fetchAppointmentCapacities}
                                            appointmentTimeslots={appointmentTimeslots}
                                            capacityLoader={capacityLoader}
                                            appointmentCapacity={appointmentCapacity}
                                            areaId={capacityValues.areaId}
                                            selectedDates={selectedDates}
                                            setFieldValue={setFieldValue}
                                            handleChange={handleChange}
                                            isSubmitting={isSubmitting}
                                            values={values}
                                        />

                                    </> }
                                </ToolkitProvider>

                                <div className="d-flex justify-content-center">

                                    <button
                                        type="submit"
                                        className="btn btn-primary float-right"
                                        disabled={capacityLoader || isSubmitting}
                                    >
                                        Submit {isSubmitting && <i className="fas fa-fw fa-spin fa-spinner" />}
                                    </button>

                                </div>

                            </form>
                        )}
                    />
                </div>
                }

            </Fragment>
        );
    }
}

const getLoaderAreas = createLoadingSelector(['GET_APPOINTMENT_AREAS']);
const getLoaderTimeslots = createLoadingSelector(['GET_TROUBLE_TICKET_TIMESLOTS']);
const getLoaderCapacities = createLoadingSelector(['GET_APPOINTMENT_CAPACITIES']);

const mapStateToProps = (state) => {

    const areasLoader = getLoaderAreas(state);
    const timeslotsLoader = getLoaderTimeslots(state);
    const capacityLoader = getLoaderCapacities(state);

    const appointmentAreas = getTechnicianAppointment(state).appointmentAreas;
    // Reverse mutates original array, so we slice first to create a copy
    const appointmentTimeslots = getAccountDetails(state).troubleTickets.timeslots.slice().reverse();
    const appointmentCapacity = getTechnicianAppointment(state).appointmentCapacity;

    const userPermission = getUser(state).userPermission;

    const settingsPermissions =
        userPermission.find((x) => x.name === 'ADMN') &&
        userPermission.find((x) => x.name === 'ADMN').permissions;
    const canEdit = settingsPermissions && settingsPermissions.includes("ADMN_USER_EDIT");
    const canDelete = settingsPermissions && settingsPermissions.includes("ADMN_USER_DEL");
    const canAdd = settingsPermissions && settingsPermissions.includes("ADMN_USER_ADD");
    const canManageTokens = settingsPermissions && settingsPermissions.includes("CREATE_API_TOKEN");
    const canViewPartners = settingsPermissions && settingsPermissions.includes("ADMN_PARTNER_DETAIL");

    return {
        areasLoader,
        timeslotsLoader,
        appointmentAreas,
        appointmentTimeslots,
        capacityLoader,
        appointmentCapacity,
        canEdit,
        canDelete,
        canAdd,
        canManageTokens,
        canViewPartners
    };
};

const mapDispatchToProps = {
    getAppointmentAreas,
    getTimeslots,
    getAppointmentCapacities,
    createAppointmentCapacity,
};

export default connect(mapStateToProps, mapDispatchToProps)(Schedule);