import React, { Component } from 'react';
import { connect } from 'react-redux';
import {getServicesIcons, emptyServicesIcons, getServicesAndEquipment, emptyServicesAndEquipment
} from '../../../../../../actions/accountDetailsActions/servicesAndEquipment.actions';
import {createLoadingSelector, getAccountDetails} from '../../../../../../selectors/index';
import Services from './Services';
import ButtonIcon from '../../../../../../components/Common/Buttons/ButtonIcon';
import {isEmpty} from "../../../../../../utils/helpers";
import {showAccountDetailsWizard} from "../../../../../../actions/accountDetailsActions/accountDetails.actions";

class ServiceAndEquipment extends Component {

	state = {
		collapsedMenuItem: 'servicesAndEquipment',
		showHistoricalData: false,
	};

	componentDidMount() {
		const {showHistoricalData} = this.state;
		const {accountInfo} = this.props;

		this.props.emptyServicesIcons();
		this.props.getServicesIcons(accountInfo.id);

		this.props.emptyServicesAndEquipment();
		this.props.getServicesAndEquipment(accountInfo.id, showHistoricalData).then(response => {
			if (isEmpty(response.services)) {
				this.showHideHistoricalData();
			}
		});
	}

	componentWillUnmount() {
		this.props.emptyServicesIcons();
		this.props.emptyServicesAndEquipment();
	}

	setCollapsedMenuItem = (menuItem) => {
		this.setState({
			collapsedMenuItem: this.state.collapsedMenuItem === menuItem ? '' : menuItem
		});
	}

	showHideHistoricalData = () => {
		const {showHistoricalData} = this.state;
		const {accountInfo} = this.props;

		// Clean data
		this.props.emptyServicesAndEquipment();

		// Reload data
		this.props.getServicesAndEquipment(accountInfo.id, !showHistoricalData);

		this.setState({showHistoricalData: !this.state.showHistoricalData});
	}

	render() {

		const {
			collapsedMenuItem,
			showHistoricalData,
		} = this.state;

		const {
			accountInfo,
			servicesIconsLoading,
			servicesIconsData,
			servicesLoading,
			servicesData,
			showNewOrderWizardHandler,
		} = this.props;

		const activeServiceIcons = [];

		const map = new Map();

		for (const item of servicesIconsData.sort((a, b) => b.hasService - a.hasService)) {
			if (!map.has(item.icon)) {
				map.set(item.icon, true); // set any value to Map
				activeServiceIcons.push({
					icon: item.icon,
					description: item.description,
					hasService: item.hasService,
					id: item.id
				});
			}
		}

		return (
			<div className="tab-pane card-body fade active show">

				<div className="tab-pane-toolbar">

					{!servicesIconsLoading &&
					<div className="btn-toolbar mr-auto">
						{activeServiceIcons.map((iconInfo) => iconInfo.icon &&
							<ButtonIcon
								buttonClass={'btn btn-icon btn-icon-inactive' + (iconInfo.hasService ? ' btn-icon-success' : '')}
								iconClass={iconInfo.icon}
								description={iconInfo.description}
								key={iconInfo.id}
							/>
						)}
					</div>
					}

					<button
						type="button"
						title="Show Historical Data"
						className={"btn btn-xl" + (showHistoricalData ? " btn-success" : " btn-outline-secondary")}
						onClick={() => this.showHideHistoricalData()}
						disabled={servicesLoading}
					>
						<i className="fas fa-history" />
					</button>

					<button
						type="button"
						className="btn btn-outline-success btn-xl btn-start-oq-wizard"
						onClick={() => showNewOrderWizardHandler(true)}
					>
						<i className="fas fa-plus" /> Service Request
					</button>

				</div>

				<div className="accordion" id="accordion-services">
					<div className="container-accordion-content container-client-services-overview">

						<div
							id="container-client-services-overview-content"
							className={'collapse-with-table collapse' + (collapsedMenuItem === 'servicesAndEquipment' ? ' show' : '')}
						>
							{collapsedMenuItem === 'servicesAndEquipment' && (
							<Services
								accountInfo={accountInfo}
								showHistoricalData={showHistoricalData}
								showHideHistoricalData={this.showHideHistoricalData}
								{...this.props}
							/>
							)}
						</div>

					</div>

				</div>

			</div>
		);
	}
}

const servicesIconsLoader = createLoadingSelector(['SET_SERVICES_AND_EQUIPMENT']);
const servicesLoader = createLoadingSelector(['SET_SERVICES_AND_EQUIPMENT']);

const mapStateToProps = (state) => {

	const servicesIconsLoading = servicesIconsLoader(state);
	const servicesIconsData = getAccountDetails(state).servicesAndEquipment.servicesIcons;
	const servicesLoading = servicesLoader(state);
	const servicesData = getAccountDetails(state).servicesAndEquipment.servicesAndEquipment;

	return {
		servicesIconsLoading,
		servicesIconsData,
		servicesLoading,
		servicesData,
	};
};

const mapDispatchToProps = {
	getServicesIcons,
	emptyServicesIcons,
	getServicesAndEquipment,
	emptyServicesAndEquipment,
	showAccountDetailsWizard,
};

export default connect(mapStateToProps, mapDispatchToProps)(ServiceAndEquipment);
