import axiosInstance from '../utils/axios-instance';
import { actionCreator, createRequestTypes } from '../utils/helpers';

export const LOAD_BILLING_JOBS = createRequestTypes('LOAD_BILLING_JOBS');
export const LOAD_BILLING_JOB = createRequestTypes('LOAD_BILLING_JOB');
export const LOAD_BILLING_JOB_STATUS_SUMMARY = createRequestTypes('LOAD_BILLING_JOB_STATUS_SUMMARY');
export const LOAD_BILLING_JOB_STATUS_DETAILS = createRequestTypes('LOAD_BILLING_JOB_STATUS_DETAILS');

export const loadBillingJobs = () => async (dispatch) => {
	dispatch(actionCreator.request(LOAD_BILLING_JOBS));
	try {
        const response = await axiosInstance.get(`/billing-jobs/list`);
		dispatch(actionCreator.success(LOAD_BILLING_JOBS, response.data));
		return response.data;
	} catch (err) {
		let mockError = {message: 'Something went wrong'}
		dispatch(actionCreator.failure(LOAD_BILLING_JOBS, mockError));
	}
}

export const clearBillingJobs = () => async (dispatch) => {
	dispatch(actionCreator.clear(LOAD_BILLING_JOBS))
}

export const loadBillingJob = (billingJobId) => async (dispatch) => {
	dispatch(actionCreator.request(LOAD_BILLING_JOB));
	try {
		const response = await axiosInstance.get(`/billing-jobs/${billingJobId}`);
		dispatch(actionCreator.success(LOAD_BILLING_JOB, response.data));
		return response.data;
	} catch (err) {
		let mockError = {message: 'Something went wrong'}
		dispatch(actionCreator.failure(LOAD_BILLING_JOB, mockError));
	}
}

export const loadBillingJobStatusSummary = (billingJobId) => async (dispatch) => {
	dispatch(actionCreator.request(LOAD_BILLING_JOB_STATUS_SUMMARY));
	try {
		const response = await axiosInstance.get(`/billing-jobs/${billingJobId}/status-summary`);
		dispatch(actionCreator.success(LOAD_BILLING_JOB_STATUS_SUMMARY, response.data));
		return response.data;
	} catch (err) {
		let mockError = {message: 'Something went wrong'}
		dispatch(actionCreator.failure(LOAD_BILLING_JOB_STATUS_SUMMARY, mockError));
	}
}

export const loadBillingJobStatusDetails = (billingJobId, billingStep) => async (dispatch) => {
	dispatch(actionCreator.request(LOAD_BILLING_JOB_STATUS_DETAILS));
	try {
		const response = await axiosInstance.get(`/billing-jobs/${billingJobId}/status-details/${billingStep}`);
		dispatch(actionCreator.success(LOAD_BILLING_JOB_STATUS_DETAILS, response.data));
		return response.data;
	} catch (err) {
		let mockError = {message: 'Something went wrong'}
		dispatch(actionCreator.failure(LOAD_BILLING_JOB_STATUS_DETAILS, mockError));
	}
}