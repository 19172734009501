import React, { Component, Fragment } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory, {
    PaginationListStandalone,
    SizePerPageDropdownStandalone,
    PaginationProvider,
    PaginationTotalStandalone
} from 'react-bootstrap-table2-paginator';
import moment from 'moment';
import Loader from "../../../../components/Loader";
import isEmpty from "../../../../utils/helpers";

const { SearchBar } = Search;

function onColumnMatch({searchText, value, column, row}) {
    if (typeof value !== 'undefined') {
        if(value.description.toLowerCase().includes(searchText)){
            return true;
        }
    }
    return false;
}

export class PayInstallmentPlanTable extends Component {
    state = {
        checked: [],
    };

    handleChecked = (cell,row, id, formatExtraData) => {
        let installmentPlan = this.props.installmentPlans.filter((installmentPlan) => installmentPlan.id == id);
        this.props.setFieldValue('selectedInstallmentPlan', installmentPlan);
        this.props.setFieldValue('paymentAmount', installmentPlan[0].balance.toFixed(2));
    };

    checkFormatter = (cell, row, rowIndex, formatExtraData, installmentPlans) => {
        if (row) {
            return (
                <div className="form-check checkbox-slider checkbox-slider--b-flat">
                    <label>
                        <input
                            disabled={this.props.isSubmitting}
                            type="checkbox"
                            onChange={() => this.handleChecked(cell, row, row.id, formatExtraData)}
                            checked={formatExtraData.checked.includes(row)}
                        />
                        <span />
                    </label>
                </div>
            );
        }
    };

    typeFormatter = (cell, row, rowIndex) => {
        if (row) {
            return (
                <Fragment>
                    {cell.description}
                    <span className="td-subtitle">
							{row.invoicedItem.description}
						</span>
                </Fragment>
            );
        }
    };

    invoiceNumberFormatter = (cell, row, rowIndex) => {
        if (row) {
            return (
                <Fragment>
                    {row.invoiceNumber}
                    <span className="td-subtitle">
							{row.servicelineNumber}
						</span>
                </Fragment>
            );
        }
    };


    invoiceDateFormatter = (cell, row, rowIndex) => {
        if (row) {
            return (
                <Fragment>
                    {moment(cell).format('MM/DD/YYYY')}{' '}
                    {row.type === 'SERVICE' && (
                        <span className="td-subtitle">
							{moment(row.periodFrom).format('MM/DD')}-{moment(row.periodTo).format('MM/DD')}
						</span>
                    )}
                </Fragment>
            );
        }
    };

    amountFormatter = (cell, row, rowIndex) => {
        if (row) {
            return <Fragment>
                ${row.balance.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                <span className="td-subtitle">
                    of ${row.amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                </span>
            </Fragment>;
        }
    };

    dueDateFormatter = (cell, row, rowIndex) => {
        if (row) {
            return <Fragment>
                {moment(cell).format('MM/DD/YYYY')}
            </Fragment>;
        }
    };

    lastPaymentFormatter = (cell, row, rowIndex) => {
        if (row) {
            return (
                <Fragment>
                    {moment(cell).format('MM/DD/YYYY')}
                    <span className="td-subtitle">
						{row.lastPaymentAmount &&
                        `$${row.lastPaymentAmount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`}
					</span>
                </Fragment>
            );
        }
    };

    balanceFormatter = (cell, row) => {
        if (row) {
            if (cell === 0) {
                return <span className="badge badge-success">Paid</span>;
            } else if (cell < row.amount) {
                return (
                    <Fragment>
                        <span className="badge badge-warning">Partially unpaid</span>
                        <span className="td-subtitle">
							Balance: ${cell.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
						</span>
                    </Fragment>
                );
            } else {
                return (
                    <Fragment>
                        <span className="badge badge-warning">Unpaid</span>
                        <span className="td-subtitle">
							Balance: ${cell.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
						</span>
                    </Fragment>
                );
            }
        }
    };

    customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total">
			Showing {from} to {to} of {size} entries
		</span>
    );

    render() {
        const { isSubmitting, installmentPlans, loadingInstallmentPlans } = this.props;

        if (loadingInstallmentPlans) {
            return <Loader/>;
        }

        const options = {
            paginationSize: 4,
            custom: true,
            totalSize: this.props.installmentPlans && this.props.installmentPlans.length,
            pageStartIndex: 1,
            // alwaysShowAllBtns: true, // Always show next and previous button
            // withFirstAndLast: false, // Hide the going to First and Last page button
            // hideSizePerPage: true, // Hide the sizePerPage dropdown always
            hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
            firstPageText: false,
            prePageText: 'Previous',
            nextPageText: 'Next',
            lastPageText: false,
            // nextPageTitle: 'First page',
            // prePageTitle: 'Pre page',
            // firstPageTitle: 'Next page',
            // lastPageTitle: 'Last page',
            showTotal: true,
            paginationTotalRenderer: this.customTotal,
            sizePerPageList: [
                {
                    text: '2',
                    value: 2
                },
                {
                    text: '5',
                    value: 5
                }
            ] // A numeric array is also available. the purpose of above example is custom the text
        };

        const columns = [
            {
                dataField: '',
                text: '',
                formatter: this.checkFormatter,
                classes: 'td-with-check',
                formatExtraData: {
                    checked: this.props.values.selectedInstallmentPlan
                },
            },
            {
                dataField: 'dueDate',
                text: 'Date',
                sort: true,
                formatter: this.dueDateFormatter
            },
            {
                // dataField: 'installmentPlanItem.name',
                dataField: 'installmentPlanItem',
                text: 'Description',
                sort: true,
                formatter: this.typeFormatter,
                hidden: false,
            },
            {
                //dataField: 'servicelineNumber',
                //dataField: 'invoiceNumber',
                text: 'Invoice',
                formatter: this.invoiceNumberFormatter,
                hidden: false
            },
            // {
            //     //dataField: 'servicelineNumber',
            //     dataField: 'invoiceNumber',
            //     text: 'Invoice',
            //     hidden: false
            // },
            {
                text: 'Balance',
                sort: true,
                formatter: this.amountFormatter,
                classes: 'h-align-r'
            }
        ];

        const defaultSorted = [
            {
                dataField: 'invoiceDate',
                order: 'desc'
            }
        ];

        return (

            <div className={"form-section form-section-expanded form-section-customer-payment-invoices" + (isSubmitting ? " form-section-customer-payment-invoices-disabled" : "")}>
                <div className="cmv-container-table table-responsive">
                    <div className="container">
                        <PaginationProvider pagination={paginationFactory(options)}>
                            {({ paginationProps, paginationTableProps }) => (
                                <Fragment>
                                    <ToolkitProvider
                                        bootstrap4
                                        keyField="id"
                                        data={installmentPlans}
                                        columns={columns}
                                        defaultSorted={defaultSorted}
                                        search={{onColumnMatch}}
                                    >
                                        {(props) => (
                                            <Fragment>
                                                <div className="row">
                                                    <div className="col-sm-12 col-md-6">
                                                        <label>
                                                            Show <SizePerPageDropdownStandalone {...paginationProps} />{' '}
                                                            entries
                                                        </label>
                                                    </div>
                                                    <div
                                                        className="col-sm-12 col-md-6"
                                                        style={{
                                                            textAlign: 'right'
                                                        }}
                                                    >
                                                        <label>
                                                            Search:{' '}
                                                            <SearchBar
                                                                className="form-control form-control-sm"
                                                                {...props.searchProps}
                                                            />
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <BootstrapTable
                                                        wrapperClasses="table-responsive react-table-layout"
                                                        classes="table table-striped cmv-table"
                                                        bordered={false}
                                                        noDataIndication="No data found"
                                                        {...paginationTableProps}
                                                        {...props.baseProps}
                                                    />
                                                </div>
                                                <div className="row" style={{ marginTop: '10px' }}>
                                                    <div className="col-sm-12 col-md-5">
                                                        <PaginationTotalStandalone {...paginationProps} />
                                                    </div>
                                                    <div className="col-sm-12 col-md-7">
                                                        <PaginationListStandalone {...paginationProps} />
                                                    </div>
                                                </div>
                                            </Fragment>
                                        )}
                                    </ToolkitProvider>
                                </Fragment>
                            )}
                        </PaginationProvider>
                    </div>
                </div>
            </div>
        );
    }
}

export default PayInstallmentPlanTable;
