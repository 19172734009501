import axiosInstance from '../utils/axios-instance';
import isEmpty, {actionCreator, createRequestTypes, getErrorMessage} from '../utils/helpers';

export const PROVISIONING_ACTIONS = createRequestTypes('@provisioning/ACTIONS');
export const PROVISIONING_SERVICE_MODELS = createRequestTypes('@provisioning/SERVICE_MODELS');
export const PROVISIONING_SERVICE_MODEL_DEVICE_GROUPS = createRequestTypes('@provisioning/SERVICE_MODEL_DEVICE_GROUPS');
export const PROVISIONING_PLANS = createRequestTypes('@provisioning/PLANS');
export const PROVISIONING_MAP_LINK_LOCATION = createRequestTypes('@provisioning/MAP_LINK_LOCATION');
export const PROVISIONING_PLAN_DETAILS = createRequestTypes('@provisioning/PLAN_DETAILS');
export const PROVISIONING_DEVICE_PROFILE_NUMS = createRequestTypes('@provisioning/DEVICE_PROFILE_NUMS');
export const PROVISIONING_SERIALIZED_ITEM_NUMS = createRequestTypes('@provisioning/DEVICE_PROFILE_NUMS');
export const PROVISIONING_INTERFACES = createRequestTypes('@provisioning/INTERFACES');
export const PROVISIONING_SUBMIT = createRequestTypes('@provisioning/SUBMIT');
export const PROVISIONING_QUEUE_GROUPS = createRequestTypes('@provisioning/QUEUE_GROUPS');
export const PROVISIONING_QUEUE_GROUP = createRequestTypes('@provisioning/QUEUE_GROUP');
export const PROVISIONING_SEARCH_SERIALIZED_ITEM = createRequestTypes('PROVISIONING_SEARCH_SERIALIZED_ITEM');

export const getProvisioningActions = () => async (dispatch) => {
	dispatch(actionCreator.request(PROVISIONING_ACTIONS));
	try {
		const response = await axiosInstance.get(`/provisioning/actions`);

		dispatch(actionCreator.success(PROVISIONING_ACTIONS, response.data.provisioningActions));

		return response;
	} catch (err) {
		dispatch(actionCreator.failure(PROVISIONING_ACTIONS, getErrorMessage(err)));

		throw err;
	}
}

export const clearProvisioningActions = () => (dispatch) => {
	dispatch(actionCreator.empty(PROVISIONING_ACTIONS))
}

export const getServiceModels = () => async (dispatch) => {
	dispatch(actionCreator.request(PROVISIONING_SERVICE_MODELS));
	try {
		const response = await axiosInstance.get(`/provisioning/service-models`);

		dispatch(actionCreator.success(PROVISIONING_SERVICE_MODELS, response.data.serviceModels));

		return response;
	} catch (err) {
		dispatch(actionCreator.failure(PROVISIONING_SERVICE_MODELS, getErrorMessage(err)));
		throw err;
	}
}

export const clearServiceModels = () => (dispatch) => {
	dispatch(actionCreator.empty(PROVISIONING_SERVICE_MODELS))
}

export const getServiceModelDeviceGroups = (serviceModelId) => async (dispatch) => {
	dispatch(actionCreator.request(PROVISIONING_SERVICE_MODEL_DEVICE_GROUPS));
	try {
		const response = await axiosInstance.get(`/provisioning/serviceequipment`, { params: { serviceModelId } });

		dispatch(actionCreator.success(PROVISIONING_SERVICE_MODEL_DEVICE_GROUPS, response.data));

		return response;
	} catch (err) {
		dispatch(actionCreator.failure(PROVISIONING_SERVICE_MODEL_DEVICE_GROUPS, getErrorMessage(err)));
		throw err;
	}
};

export const clearServiceModelDeviceGroups = () => (dispatch) => {
	dispatch(actionCreator.empty(PROVISIONING_SERVICE_MODEL_DEVICE_GROUPS));
}

export const getPlans = (serviceModelId, locationId, address) => async (dispatch) => {
	dispatch(actionCreator.request(PROVISIONING_PLANS));
	try {
		const params = { locationId, address };
		const response = await axiosInstance.get(`/provisioning/${serviceModelId}/plans`, { params });

		dispatch(actionCreator.success(PROVISIONING_PLANS, response.data));

		return response;
	} catch (err) {
		dispatch(actionCreator.failure(PROVISIONING_PLANS, getErrorMessage(err)));
		throw err;
	}
}

export const clearPlans = () => (dispatch) => {
	dispatch(actionCreator.empty(PROVISIONING_PLANS))
}

export const getPlanDetails = (accountId, locationId, planId) => async (dispatch) => {

	const params = {
		accountId,
		locationId,
		planId,
		fromCustomerPortal: false
	};

	dispatch(actionCreator.request(PROVISIONING_PLAN_DETAILS));
	try {
		const response = await axiosInstance.get(`/domain/plan`, { params });
		let planDetails = { ...response.data };

		let serviceModelId = {
			serviceModelId: response.data.serviceModel.id
		};

		let numberCategoriesData = {
			serviceModelId: response.data.serviceModel.id,
			locationId: locationId
		};

		const equipment = await axiosInstance.get(`/provisioning/serviceequipment`, { params: serviceModelId });
		const numberCategories = await axiosInstance.get(`/domain/numbercategories`, { params: numberCategoriesData });

		planDetails.equipment = equipment.data;
		planDetails.numberCategories = numberCategories.data;

		dispatch(actionCreator.success(PROVISIONING_PLAN_DETAILS, planDetails));

		return planDetails;
	} catch (err) {
		dispatch(actionCreator.failure(PROVISIONING_PLAN_DETAILS, getErrorMessage(err)));
		throw err;
	}
};

export const clearPlanDetails = () => (dispatch) => {
	dispatch(actionCreator.empty(PROVISIONING_PLAN_DETAILS));
}

export const getDeviceProfileNums = (deviceProfile, actionType) => async (dispatch) => {
	const params = {
		actionType
	};

	dispatch(actionCreator.request(PROVISIONING_DEVICE_PROFILE_NUMS));
	try {
		const response = await axiosInstance.get(`/provisioning/device-profile/${deviceProfile}/nums`, { params });
		let nums = response.data;

		dispatch(actionCreator.success(PROVISIONING_DEVICE_PROFILE_NUMS, nums));

		return nums;
	} catch (err) {
		dispatch(actionCreator.failure(PROVISIONING_DEVICE_PROFILE_NUMS, getErrorMessage(err)));
		throw err;
	}
};

export const getSerializedItemNumbers = (number, systemNumType, locationId, limit) => async (dispatch) => {
	const params = {
		number: number,
		systemNumType: systemNumType,
		locationId: locationId,
		limit: limit,
	};

	dispatch(actionCreator.request(PROVISIONING_SERIALIZED_ITEM_NUMS));
	try {
		const response = await axiosInstance.get(`/domain/serialized-item-numbers/unassigned`, { params });

		for (const itemNum of response.data.serializedItemNumbers) {
			const suggestionsResponse = await axiosInstance.get(`/domain/serialized-item/${itemNum.serializedItemId}/numbers`);
			console.log("suggestionResponse", suggestionsResponse);
			if (!suggestionsResponse.data.success) {
				throw new Error(`Failed to load numbers for serialized item with id ${itemNum.serializedItemId}`);
			}

			itemNum.suggestions = suggestionsResponse.data.serializedItemNumbers;
		}

		dispatch(actionCreator.success(PROVISIONING_SERIALIZED_ITEM_NUMS, response.data.serializedItemNumbers));

		return response;
	} catch (err) {
		let mockError = {
			message: 'Not found'
		};
		dispatch(actionCreator.failure(PROVISIONING_SERIALIZED_ITEM_NUMS, mockError));

		return err;
	}
}

export const getInterfaces = (actionType, deviceProfiles, features) => async (dispatch) => {
	const requestBody = {
		actionType,
		deviceProfiles,
		features
	};

	dispatch(actionCreator.request(PROVISIONING_INTERFACES));
	try {
		const response = await axiosInstance.post(`/provisioning/interfaces/search`, requestBody);

		dispatch(actionCreator.success(PROVISIONING_INTERFACES, response.data.provisioningInterfaces));

		return response;
	} catch (err) {
		dispatch(actionCreator.failure(PROVISIONING_INTERFACES, getErrorMessage(err)));
		throw err;
	}
};

export const getMapLinkLocation = (locationId, address) => async (dispatch) => {
	dispatch(actionCreator.request(PROVISIONING_MAP_LINK_LOCATION));
	try {
		const response = await axiosInstance.post(`/order/map/embed/${locationId}`, address);

		dispatch(actionCreator.success(PROVISIONING_MAP_LINK_LOCATION, response.data));

		return response.data;
	} catch (err) {
		dispatch(actionCreator.failure(PROVISIONING_MAP_LINK_LOCATION, getErrorMessage(err)));
		throw err;
	}
}

export const submitProvisioning = (dn, provisioningAction, data) => async (dispatch) => {
	dispatch(actionCreator.request(PROVISIONING_SUBMIT));
	try {
		const response = await axiosInstance.post(`/provisioning/dn/${dn}/${provisioningAction}`, data);
		dispatch(actionCreator.success(PROVISIONING_SUBMIT, response.data));
		return response;
	} catch (err) {
		dispatch(actionCreator.failure(PROVISIONING_SUBMIT, getErrorMessage(err)));
		throw err;
	}
}

export const getProvisioningQueueGroups = (search) => async (dispatch) => {
	dispatch(actionCreator.request(PROVISIONING_QUEUE_GROUPS));

	const params = {}

	if (search.limit) {params.limit = search.limit;}
	if (search.actionType) {params.actionType = search.actionType;}
	if (search.createdFrom) {params.createdFrom = search.createdFrom;}
	if (search.createdTo) {params.createdTo = search.createdTo;}
	if (search.status) {params.status = search.status;}
	if (search.userName) {params.userName = search.userName;}
	if (search.number) {params.number = search.number;}

	try {
		const response = await axiosInstance.get(`/provisioning/queue-groups`, {params});
		dispatch(actionCreator.success(PROVISIONING_QUEUE_GROUPS, response.data));
		return response.data;
	} catch (err) {
		dispatch(actionCreator.failure(PROVISIONING_QUEUE_GROUPS, getErrorMessage(err)));
		return err.response.data;
	}
}

export const getProvisioningQueueGroup = (queueGroupId) => async (dispatch) => {
	dispatch(actionCreator.request(PROVISIONING_QUEUE_GROUP));
	try {
		const response = await axiosInstance.get(`/provisioning/queue-group/${queueGroupId}`);

		dispatch(actionCreator.success(PROVISIONING_QUEUE_GROUP, response.data.provisioningQueueGroup));

		return response;
	} catch (err) {
		dispatch(actionCreator.failure(PROVISIONING_QUEUE_GROUP, getErrorMessage(err)));
		throw err;
	}
}

export const populateMapLocation = (data) => {
	let obj = {
		validatedAddress: { ...data }
	};

	return (dispatch) => {
		dispatch(actionCreator.success(PROVISIONING_MAP_LINK_LOCATION, obj));
	};
}

export const clearMapLinkLocation = () => {
	return (dispatch) => {
		dispatch(actionCreator.empty(PROVISIONING_MAP_LINK_LOCATION));
	};
}

export const clearSerializedItems = () => {
	return (dispatch) => {
		dispatch(actionCreator.empty(PROVISIONING_SEARCH_SERIALIZED_ITEM));
	};
}

export const searchSerializedItem = (accountId, itemId, systemNumTypeId, term, serviceLineId, serviceOrderLineId) => async (dispatch) => {
	dispatch(actionCreator.request(PROVISIONING_SEARCH_SERIALIZED_ITEM));
	try {
		let params = { accountId, itemId};
		if (!isEmpty(systemNumTypeId)) {
			params = {...params, systemNumTypeId};
		}
		if (!isEmpty(term)) {
			params = {...params, term};
		}
		if (!isEmpty(serviceLineId)) {
			params = {...params, serviceLineId};
		}
		if (!isEmpty(serviceOrderLineId)) {
			params = {...params, serviceOrderLineId};
		}
		const response = await axiosInstance.get(`/provisioning/inventory/search`, { params });

		dispatch(actionCreator.success(PROVISIONING_SEARCH_SERIALIZED_ITEM, response.data));

		return response.data;
	} catch (err) {
		dispatch(actionCreator.failure(PROVISIONING_SEARCH_SERIALIZED_ITEM, getErrorMessage(err)));
		throw err;
	}
}
