import React, { Component } from 'react';
import { applyOnEnum, isEmpty} from '../../../utils/helpers';
import ReactHtmlParser from 'react-html-parser';
import Loader from '../../Loader';
import moment from 'moment';
import DatePicker from "react-datepicker";

class SummaryContainer extends Component {
	state = {
		isApplyOnVisible: false,
		startDate: this.props.accountInfo.billCycle? this.convertDay(this.props.accountInfo.billCycle): this.convertDay((new Date()).getDate()),
	}
	handleApplyOnDropdown = () => {
		this.setState((prevState) => ({
			isApplyOnVisible: !prevState.isApplyOnVisible
		}));
	}

	 convertDay(startDayBillCycle){
		let localdate = new Date();
		let currentMonth = localdate.getMonth();
		let currentYear = localdate.getFullYear();
		let currentDay = localdate.getDate();
		if( startDayBillCycle <= currentDay ) {
			return new Date(currentYear,currentMonth,startDayBillCycle);
		}else{
			return new Date(currentYear,currentMonth-1,startDayBillCycle);
		}
	}
	handleChangeApplyOn = (value) => {
		if (value) {
			this.props.values.applyOn = value;
		}
	}

	setDate = (value) => {
		this.openDatepicker();
	};
	openDatepicker = () => this._calendar.setOpen(true);

	handlChangeStartDateBillCycle = (value) => {
		if (value instanceof Date) {
			value = moment(value).format('YYYY-MM-DD');
		}

		if(value) {
			this.props.setFieldValue('applyDate', value);
		}
	};

	render() {

		const {
			isApplyOnVisible,
			startDate,
		} = this.state;

		const {
			values,
			isSummaryFullWith,
			handleFullWithSummary,
			isSummaryCollapsed,
			handleSummaryCollapsed,
			tab,
		} = this.props;

		if (isEmpty(values.plans)) {
			return <Loader />;
		}

		return (
			<div className={"col-lg-3 col-xl-4 panel-summary" + (isSummaryFullWith === true ? ' col-full-width' : '')}>
				<div className="services-summary">
					<div className="services-summary-header">

						Summary

						<span className="ml-auto">
							<a className="btn-summary btn-summary-toggle" onClick={handleFullWithSummary}>
								<i className="fas fa-arrows-alt-h" />&nbsp;View
							</a>
							<a className="btn-summary btn-summary-collapse" onClick={handleSummaryCollapsed}>
								<i className="fas fa-angle-double-up" />&nbsp;Collapse
							</a>
						</span>

					</div>

					{(tab === 'Products' || tab === 'Services') &&
					<div className="services-summary-applyon">
						<h4>Apply On:</h4>
						<div className={"dropdown" + (isApplyOnVisible ? ' show' : '')}>
							<button
								type="button"
								onClick={this.handleApplyOnDropdown}
								className="btn btn-outline-secondary btn-sm dropdown-toggle"
								role="button"
								data-toggle="dropdown"
								aria-haspopup="true"
								aria-expanded="false"
							>
								{applyOnEnum.valueOf()[values.applyOn]}
							</button>
							<div className={"dropdown-menu dropdown-menu-right" + (isApplyOnVisible ? ' show' : '')}
								 aria-labelledby="dropdownMenuLink"
							>

								<button type="button" className="dropdown-item"
										onClick={() => {
											this.handleApplyOnDropdown();
											this.handleChangeApplyOn(Object.keys(applyOnEnum)[0]);
										}}>
									{applyOnEnum.ORDER_COMPLETION}
								</button>

								<button type="button" className="dropdown-item"
										onClick={() => {
											this.handleApplyOnDropdown();
											this.handleChangeApplyOn(Object.keys(applyOnEnum)[1]);
										}}>
									{applyOnEnum.START_OF_BILLCYCLE}
								</button>

								<button type="button" className="dropdown-item"
										onClick={() => {
											this.handleApplyOnDropdown();
											this.handleChangeApplyOn(Object.keys(applyOnEnum)[2]);

										}}>
									{applyOnEnum.NEXT_BILLCYCLE}
								</button>
								<button type="button" className="dropdown-item"
										onClick={() => {
											this.handleApplyOnDropdown();
											this.handlChangeStartDateBillCycle(startDate)
											this.handleChangeApplyOn(Object.keys(applyOnEnum)[3]);
										}}>
									{applyOnEnum.APPLY_ON_DATE}
								</button>
							</div>
						</div>
					</div>
					}

					{values.applyOn && values.applyOn === Object.keys(applyOnEnum)[3] && (tab === 'Products' || tab === 'Services') ?
						<div className="form-group d-flex">
							<div className='dropdown dropdown-cn-expiration ml-1 show'>
								<h6 className="dropdown-header">Choose a Date: </h6>
								<div className="form-group col">
									<div
										className="input-group input-group-sm date"
										id="datetimepicker-cn-exp"
										data-target-input="nearest"
									>
										<DatePicker
											className="form-control datetimepicker-input"
											selected={moment(this.props.values.applyDate, 'YYYY-MM-DD').toDate()}
											minDate={startDate}
											onChange={(date) => {
												this.handlChangeStartDateBillCycle(date);
												this.setDate(date) ;}}
											placeholderText="MM/DD/YYYY"
											name="expirationDate"
											popperPlacement="bottom-end"
											ref={(c) => this._calendar = c}
										/>
									</div>
								</div>
								{/*        <div className="dropdown-item">
                                <button type="button"
                                        className="btn btn-outline-secondary btn-block btn-cn-exp-date-clear"
                                        onClick={this.handleClearExpirationDate}>Save
                                </button>
                            </div> */}
							</div>
						</div>

						: ''
					}

					<div className="services-summary-body">
						{values.plans.sort((a, b) => a.planId - b.planId).map((plan) => (
							<div className="plan-wrapper" data-plan-type="wireless" key={plan.planId}>

								<h4>{plan.planDescription}</h4>

								{!isEmpty(plan.servicelines) && plan.servicelines.map((serviceline, index) => (
									<div className="serviceline-wrapper" key={index}>

										<h5 className="clearfix">
											<span className="badge badge-line-type">{serviceline.serviceLineRelation}</span>
											<span className="float-right">{serviceline.number}</span>
										</h5>

										<div className="plan-section" data-opt-pmnt-type="pmnt-recurring">

											<h5>Recurring</h5>

											{serviceline.features && serviceline.features.filter((x) => x.featureType !== 'ACTIVATION').map((feature, inx, arr) => (
											<div className="package-wrapper" key={inx}>
												<div className={"package-option" + (isSummaryCollapsed === false ? ' just-added' : ' d-none')}>
													<span className="po-title">
														{feature.featurePackageDescription
															? feature.description
																? ReactHtmlParser(`${feature.featurePackageDescription}: ${feature.description}`)
																: ReactHtmlParser(`${feature.featurePackageDescription}`)
															: ReactHtmlParser(`${feature.description}`)}
													</span>
													<span className="po-price">
                                                        {feature.featureVariabledQuantity
															? feature.recurringMonthlyPrice &&
															("$" + (feature.featureVariabledQuantity * Number(feature.recurringMonthlyPrice)).toFixed(2).replace(/\d(?=(\d{3}) +\.)/g, '$&,') + "/mo")
															: feature.recurringMonthlyPrice &&
															("$" + Number(feature.recurringMonthlyPrice).toFixed(2).replace(/\d(?=(\d{3}) +\.)/g, '$&,') + "/mo")
														}
													</span>
												</div>
											</div>
											))}

											<p className="services-price-subtotal">
												Subtotal:&nbsp;<span className="service-price">
													${serviceline.features && serviceline.features.reduce((acc, currentValue, index) => {
														if (currentValue.featureType !== 'ACTIVATION') {
															if (currentValue.featureVariabledQuantity) {
																if (currentValue.recurringMonthlyPrice) {
																	acc += currentValue.featureVariabledQuantity *
																		currentValue.recurringMonthlyPrice;
																}
															}
															else if (currentValue.recurringMonthlyPrice) {
																acc += Number(currentValue.recurringMonthlyPrice);
															}
														}
														return Number(acc);
													}, 0).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}/mo
												</span>
											</p>

										</div>
										<div className="plan-section" data-opt-pmnt-type="pmnt-onetime">

											<h5>Onetime</h5>

											{serviceline.features && serviceline.features.filter((x) => x.featureType === 'ACTIVATION').map((feature, inx, arr) => (
											<div className="package-wrapper" key={inx}>
												<div className={"package-option" + (isSummaryCollapsed === false ? ' just-added' : ' d-none')}>
													<span className="po-title">
														{feature.featurePackageDescription
															? feature.description
																? ReactHtmlParser(`${feature.featurePackageDescription}: ${feature.description}`)
																: ReactHtmlParser(`${feature.featurePackageDescription}`)
															: ReactHtmlParser(`${feature.description}`)}
													</span>
													<span className="po-price">
														${Number(feature.oneTimePrice).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
													</span>
												</div>
											</div>
											))}

											<p className="services-price-subtotal">
												Subtotal:&nbsp;<span className="service-price">
													${serviceline.features && serviceline.features.reduce((acc, currentValue, index) => {
														if (currentValue.featureType === 'ACTIVATION' && currentValue.oneTimePrice !== undefined)
															acc += Number(currentValue.oneTimePrice);
													return Number(acc);
													}, 0)
													.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
												</span>
											</p>

										</div>
										{serviceline.devices && serviceline.devices.length > 0 ?
										<div className="plan-section" data-opt-pmnt-type="pmnt-equipment">

												<h5>Equipment</h5>

												<div className="package-wrapper">
													{serviceline.devices && serviceline.devices.map((device, inx, arr) => (
														<div
															className={"package-option" + (isSummaryCollapsed === false ? ' just-added' : ' d-none')}
															key={inx}>
													<span className="po-title">
														{ReactHtmlParser(device.itemDescription)}
													</span>
															<span className="po-price">
														{!isEmpty(device.itemPrice)
															? ('$' + device.itemPrice.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'))
															: ''}
													</span>
														</div>
													))}
												</div>

											<p className="services-price-subtotal">
												Subtotal:&nbsp;<span className="service-price">
													${serviceline.devices ?(
													serviceline.devices.reduce((acc, currentValue, index) => {
														if (currentValue.itemPrice !== undefined)
															acc += currentValue.itemPrice;
														return acc;
													}, 0)
														.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')) :
												(0).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
												</span>
											</p>

										</div>
											: ''
										}

									</div>
								))}
							</div>
						))}
					</div>

					<div className="services-summary-footer">

						<p>Grand Total</p>

						<p className="services-price-total">
							Recurring:{' '}
							<span className="service-price">
								${values.plans.reduce((acc, currentValue, index) => {
									currentValue.servicelines.map((serviceline) => {
										serviceline.features && serviceline.features.map((feature) => {
											if (feature.featureType !== 'ACTIVATION') {
												if (feature.featureVariabledQuantity) {
													if (feature.recurringMonthlyPrice) {
														acc += feature.featureVariabledQuantity *
															feature.recurringMonthlyPrice;
													}
												}
												else if (feature.recurringMonthlyPrice) {
													acc += Number(feature.recurringMonthlyPrice);
												}
											}
										});
									});
									return Number(acc);
								}, 0).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}/mo
							</span>
						</p>

						<p className="services-price-total">
							OneTime:{' '}
							<span className="service-price">
								${values.plans.reduce((acc, currentValue, index) => {
									currentValue.servicelines.map((serviceline) => {
										serviceline.features && serviceline.features.map((feature) => {
											if (feature.featureType === 'ACTIVATION' && feature.oneTimePrice !== undefined) {
												acc += Number(feature.oneTimePrice);
											}
										});
									});
									return acc;
								}, 0)
								.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
							</span>
						</p>

						<p className="services-price-total">
							Equipment:{' '}
							<span className="service-price">
								${values.plans.reduce((acc, currentValue, index) => {
									currentValue.servicelines.map((serviceline) => {
										if (typeof serviceline.devices !== 'undefined' && serviceline.devices != null) {
											serviceline.devices.map((device) => {
												if (device.itemPrice !== undefined) {
													acc += device.itemPrice;
												}
											});
										}
									});
									return acc;
								}, 0)
								.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
							</span>
						</p>

					</div>
				</div>
			</div>
		);
	}
}

export default SummaryContainer;
