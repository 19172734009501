import axiosInstance from '../../utils/axios-instance';
import { actionCreator, createRequestTypes } from '../../utils/helpers';

export const SET_DASHBOARD_ORDERS = createRequestTypes('SET_DASHBOARD_ORDERS');
export const EXECUTE_DASHBOARD_ORDER = createRequestTypes('EXECUTE_DASHBOARD_ORDER');
export const SET_SERVICEORDER_INFORMATION = createRequestTypes('');
export const UPDATE_ORDER_SALES_AGENT = createRequestTypes('UPDATE_ORDER_SALES_AGENT');
export const CREATE_SERVICE_CHANGE_DEVICE = createRequestTypes('CREATE_SERVICE_CHANGE_DEVICE');
export const CHANGE_SERVICE_LINE = createRequestTypes('CHANGE_SERVICE_LINE');
export const MANUALLY_CONFIRM_TASK = createRequestTypes('MANUALLY_CONFIRM_TASK');

export const getDashboardOrders = (username) => async (dispatch) => {
	let params = {
		createdBy: username
	};

	dispatch(actionCreator.request(SET_DASHBOARD_ORDERS));
	try {
		const response = await axiosInstance.get(`/order/list`, { params: params });
		dispatch(actionCreator.success(SET_DASHBOARD_ORDERS, response.data.orders));
	} catch (err) {
		dispatch(actionCreator.failure(SET_DASHBOARD_ORDERS, 'error'));
	}
};

export const emptyDashboardOrders = () => (dispatch) => {
	dispatch(actionCreator.empty(SET_DASHBOARD_ORDERS));
};



export const executeOrder = (orderId, orderFilters) => async (dispatch) => {
	const data = {serviceOrders: [{serviceOrderId: 0, variables: {}}]};
	dispatch(actionCreator.request(EXECUTE_DASHBOARD_ORDER));
	try {
		const response = await axiosInstance.post(`/order/${orderId}/startall`, data);
		dispatch(actionCreator.success(EXECUTE_DASHBOARD_ORDER, response.data));
		if (orderFilters) {
			dispatch(getFilteredOrders(orderFilters));
		}
		return response.data;
	} catch (err) {
		dispatch(actionCreator.failure(EXECUTE_DASHBOARD_ORDER));
		return err.response.data;
	}
};

export const getFilteredOrders = (filters) => async (dispatch) => {
	dispatch(actionCreator.request(SET_DASHBOARD_ORDERS));
	try {
		const response = await axiosInstance.get(`/orders`, { params: filters });
		dispatch(actionCreator.success(SET_DASHBOARD_ORDERS, response.data.orders));
	} catch (err) {
		dispatch(actionCreator.failure(SET_DASHBOARD_ORDERS, 'error'));
	}
};



export const getOrderById = async (orderId) => {
	try {
		const response = await axiosInstance.get(`/order/${orderId}`);
		return response.data;
	} catch (err) {
		return err.response.data;
	}
};

export const getLogs = async (serviceOrderId) => {
	try {
		const response = await axiosInstance.get(`/order/serviceorder/${serviceOrderId}/workflow/state`);
		return response.data;
	} catch (err) {
		return err.response.data;
	}
};

export const getServiceOrderInformation = async (serviceorderId) => {
	try {
		const response = await axiosInstance.get(`/order/serviceorder/${serviceorderId}`);
		return response.data.serviceOrder;
	} catch (err) {
		console.log(err);
	}
};

export const updateOrderSalesAgent = (orderId, data) => async (dispatch) => {
	try {
		const response = await axiosInstance.patch(`/order/sales-agent/${orderId}`, data);
		dispatch(actionCreator.success(UPDATE_ORDER_SALES_AGENT, response.data));
		return response.data;
	} catch (err) {
		dispatch(actionCreator.failure(UPDATE_ORDER_SALES_AGENT, 'error'));
	}
};

export const createOrderChangeDevice = (data) => async (dispatch) => {
	dispatch(actionCreator.request(CREATE_SERVICE_CHANGE_DEVICE));
	try {
		const response = await axiosInstance.post(`/order/changedevice`, data);
		dispatch(actionCreator.success(CREATE_SERVICE_CHANGE_DEVICE, response.data));
		return response.data;
	} catch (err) {
		dispatch(actionCreator.failure(CREATE_SERVICE_CHANGE_DEVICE));
		return err.response.data;
	}
};

export const changeServiceLine = (data) => async (dispatch) => {
	dispatch(actionCreator.request(CHANGE_SERVICE_LINE));
	try {
		const response = await axiosInstance.post(`/order/changeline`, data);
		dispatch(actionCreator.success(CHANGE_SERVICE_LINE, response.data));
		return response.data;
	} catch (err) {
		dispatch(actionCreator.failure(CHANGE_SERVICE_LINE));
		return err.response.data;
	}
};

export const manuallyTriggerReceiveTask = (serviceOrderId, workflowReceiveTaskRequest) => async (dispatch) => {
	dispatch(actionCreator.request(MANUALLY_CONFIRM_TASK));
	try {
		const response = await axiosInstance.post(`/order/serviceorder/${serviceOrderId}/manuallyTriggerReceiveTask`,  workflowReceiveTaskRequest);
		dispatch(actionCreator.success(MANUALLY_CONFIRM_TASK, response.data));
		return response.data;
	} catch (err) {
		dispatch(actionCreator.failure(MANUALLY_CONFIRM_TASK));
		return err.response.data;
	}
};
