import { SET_ACCOUNT_NOTES, SET_NOTE_CATEGORIES, EMPTY_ACCOUNT_NOTES  } from '../../actions/accountDetailsActions/accountNotes.actions';

let initialState = {
	notes: [],
	noteCategories: []
};

export const accountNotes = (state = initialState, action) => {
	switch (action.type) {
		case SET_ACCOUNT_NOTES.SUCCESS:
			return {
				...state,
				notes: action.payload
			}
		case SET_NOTE_CATEGORIES.SUCCESS:
			return {
				...state,
				noteCategories: action.payload
			}
		case EMPTY_ACCOUNT_NOTES:
			return {
				...initialState
			}
		default:
			return state;
	}
};
