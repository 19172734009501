import React, { Component } from 'react';
import { connect } from 'react-redux';
import {Form, Formik} from "formik";
import {PulseLoader} from "react-spinners";
import {isEmpty} from "lodash";
import * as Yup from 'yup';

import {createLoadingSelector, getProvisioning} from "../../../selectors";
import {
	clearProvisioningActions,
	getProvisioningActions,
} from "../../../actions/provisioning.actions";
import StepActions from "../../../camvio-wizard/StepActions";

const validationSchema = Yup.object().shape({
	provisioningAction: Yup.object().nullable().required('Please select an action'),
});

const initialValues = {
}

class Actions extends Component {

	componentDidMount() {
		if (!this.props.stepState.provisioningActions) {
			this.loadProvisioningActions();
		}
	}

	loadProvisioningActions = () => {
		this.props.getProvisioningActions();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		const { setStepState } = this.props;

		if (prevProps.isProvisioningActionsLoading && !this.props.isProvisioningActionsLoading) {
			setStepState({ provisioningActions: this.props.provisioningActions });
		}
	}

	createValuesBundle = (values) => ({
		provisioningAction: values.provisioningAction
	})

	createFormValuesBundle = (values) => ({
		...values,
	})

	handleNext = (values) => {
		const { next } = this.props;
		if (!next) {
			return;
		}

		next({
			values: this.createValuesBundle(values),
			formValues: this.createFormValuesBundle(values)
		});
	}

	handlePrevious = (values) => {
		const { previous } = this.props;
		if (!previous) {
			return;
		}

		previous({
			formValues: this.createFormValuesBundle(values),
		});
	}

	handleOnSubmit = (values, actions) => {
		this.handleNext(values);
	}

	renderContent = ({values, errors, setFieldValue}) => {
		const { isProvisioningActionsLoading, stepState } = this.props;
		const { provisioningActions } = stepState;

		return isProvisioningActionsLoading? <PulseLoader /> :
			<>
				{provisioningActions && provisioningActions.map((at, index) => (
					<div className="form-check checkbox-slider checkbox-slider--b-flat" key={index}>
						<label>
							<input
								type="checkbox"
								name='provisioningAction'
								value={values.provisioningAction}
								checked={values.provisioningAction && values.provisioningAction.value === at.value}
								onChange={() => setFieldValue('provisioningAction', at)}
							/>
							<span>{at.description}</span>
						</label>
					</div>
				))}
				{errors.provisioningAction &&
					<div className="invalid-feedback">{errors.provisioningAction}</div>
				}
			</>;
	}

	render() {
		const { stepFormValues, previous, next } = this.props;

		return (
			<Formik
				validationSchema={validationSchema}
				onSubmit={this.handleOnSubmit}
				initialValues={isEmpty(stepFormValues)? initialValues : stepFormValues}
				render={(formProps) => (
					<Form onSubmit={formProps.handleSubmit} className="cmv-form" autoComplete="off">

						{this.renderContent(formProps)}

						<StepActions
							previous={previous && (() => this.handlePrevious(formProps.values))}
							next={next && formProps.submitForm} />
					</Form>
				)}
			/>
		);
	}
}


const selectProvisioningActionsLoading = createLoadingSelector(['@provisioning/ACTIONS']);

const mapStateToProps = (state) => {
	const provisioningActions = getProvisioning(state).provisioningActions,
		isProvisioningActionsLoading = selectProvisioningActionsLoading(state);

	return {
		provisioningActions,
		isProvisioningActionsLoading,
	};
};

const mapDispatchToProps = {
	getProvisioningActions,
	clearProvisioningActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(Actions);