import React, { Component } from 'react';
import SummaryContainer from '../../../../components/UI/NewOrder/SummaryContainer';
import ServicesPlan from '../../../../components/UI/NewOrder/ServicesPlan';
import Loader from '../../../../components/Loader';

class Services extends Component {
	componentDidMount() {
		// const { currentLocation, accountInfo, values } = this.props;
		// values.plans.forEach((element) => {
		// 	this.props.getPlansDetails(accountInfo.id, currentLocation.id, element.planId).then((response) => {
		// 		let findIndex = this.props.values.plans.findIndex((x) => x.planId === response.id);
		// 		let servicelines = [];
		// 		for (let index = 0; index < response.requiredLines; index++) {
		// 			let currentServiceLine = {
		// 				features: [],
		// 				num: '',
		// 				serviceLineRelation: index === 0 ? 'Main' : 'Required'
		// 			};
		// 			servicelines.push(currentServiceLine);
		// 		}
		// 		this.props.setFieldValue(`plans.${findIndex}.index`, findIndex);
		// 		this.props.setFieldValue(`plans.${findIndex}.servicelines`, servicelines);
		// 		this.props.setFieldValue(`plans.${findIndex}.requiredLines`, response.requiredLines);
		// 		this.props.setFieldValue(`plans.${findIndex}.additionalLines`, response.additionalLines);
		// 	});
		// });
	}

	componentDidUpdate() {}

	render() {
		const { plansDetails, values, isSummaryFullWith } = this.props;

		
		if (plansDetails.length === 0) {
			return <Loader />;
		}
		return (
			<div className="tab-pane active">
				<div className="row">
					<div
						className={
							isSummaryFullWith === true ? (
								'col-lg-9 col-xl-8 services-available d-none'
							) : (
								'col-lg-9 col-xl-8 services-available'
							)
						}
					>
						{/* {values.plans.map((currentPlan, index) => (
							<ServicesPlan planInfo={currentPlan} {...this.props} key={index} planData={plansDetails[index]}/>
						))} */}
						{plansDetails
							.sort((a, b) => a.id - b.id)
							.map((plan, index) => (
								<ServicesPlan
									planInfo={plan}
									key={plan.id}
									planIndex={values.plans.findIndex((x) => x.planId === plan.id)}
									{...this.props}
								/>
							))}
					</div>

					<SummaryContainer {...this.props} tab='Services'/>
				</div>
				
			</div>
		);
	}
}

export default Services;
