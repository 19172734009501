import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {hideModal} from '../../../actions/modal.actions';
import {getUserSummary} from '../../../actions/user.actions';
import {getAccountDetails, getUser, getModalData, getAdmin, createLoadingSelector} from '../../../selectors';
import {Field, Formik} from 'formik';
import {
    getSystemLocations,
    getSystemUserRoles,
    getSystemUserGroups,
    addSystemUser,
    updateSystemUser,
    getPartners,
    getSystemUser,
    clearSystemUser
} from '../../../actions/admin.actions';
import * as Yup from 'yup';
import isEmpty from "../../../utils/helpers";
import FormSelect from "../../../components/UI/FormSelect";
import FormikInput from "../../../components/Common/Inputs/FormikInput";
import TextInput from "../../../components/UI/TextInput";
import Loader from "../../../components/Loader";
import {ClipLoader} from "react-spinners";
import {css} from "@emotion/core";
import {clearViewAs} from "../../../actions/viewAs.actions";


const PARTNERS_SEARCH_LIMIT = 10;

const PARTNERS_SEARCH_INFO = "Maximum of " + PARTNERS_SEARCH_LIMIT + " results are given as options and it searches by the following criteria on typing:\n" +
    "* Empty - no filtering is applied.\n" +
    "* ID (integer) - searching by partner id.\n" +
    "* Text - searching by a partial match in name and description.\n";

const asOptions = (source, mapper) => {
    if (!Array.isArray(source)) {
        return [];
    }

    return source.map(mapper);
}

const rolesAsOptions = (roles) => asOptions(roles, role => {
    return {
        label: role.description,
        value: role.id
    };
});

const locationsAsOptions = (locations) => asOptions(locations, location => {
    return {
        label: location.description,
        value: location.id
    }
});

const partnersAsOptions = (partners) => asOptions(partners, partner => {
    return {
        label: partner.name,
        value: partner.id
    };
});

const groupsAsOptions = (groups) => asOptions(groups, group => {
    return {
        label: group.description,
        value: group
    };
});

const excludeLocation = (locations, locationIdToBeExcluded) => {
    return !isEmpty(locations) && locations.filter(loc => loc.id !== locationIdToBeExcluded) || [];
}

const findMainLocationId = (locations) => {
    const mainLocation = locations && locations.find(location => location.main === true);
    if (mainLocation) {
        return mainLocation.id;
    }
}

const findPartnerById = (partners, partnerId) => {
    return partners && partners.find(partner => partner.id === partnerId);
}

class AddSystemUserModal extends Component {

    state = {
        alertMessage: '',
        partnerSearchTem: null,
        belongsToPartner: false,
        selectedPartner: null,
        partnerOptions: [],
        locationOptions: [],
        systemUserLoader: false
    };

    componentDidMount() {
        this.props.getSystemLocations();
        this.props.getSystemUserRoles();
        this.props.getSystemUserGroups();

        const {canViewPartners, modalProps} = this.props;
        const partner = canViewPartners;
        if(modalProps.value) {
            this.props.getSystemUser(modalProps.value).then((response) => {
                if (partner && response.data.partner) {
                    this.setState({selectedPartner: response.data.partner, belongsToPartner: true}, () => this.reloadPartners());
                } else {
                    this.reloadLocations();
                }
                this.setState({
                    systemUserLoader: true
                })
            })
        }else{
            this.reloadLocations();
            this.setState({
                systemUserLoader: true
            })
        }
    }

    componentWillUnmount() {
        this.props.clearSystemUser();
    }

    systemUserSchema = () => {
        let validationSchema = {
            firstName: Yup.string().required('Required'),
            lastName: Yup.string().required('Required'),
            systemUserRoleId: Yup.string().nullable().required('Required').min(1, "Invalid user role id"),
            email: Yup.string().email('Invalid email format').required('Required'),
            mobileNumber: Yup.number().typeError("Please enter a valid phone number"),
            mainLocationId: Yup.number().typeError("Required").required("Required").min(1, "Invalid main location id"),
            partnerId: Yup.number().nullable().typeError("Invalid partner id").min(1, "Invalid partner id")
        };

        if (this.props.modalProps.type === 'create') {
            validationSchema = {
                ...validationSchema,
                password: Yup.string().nullable().min(6, 'Min 6 char').required('Required'),
            };
        } else {
            validationSchema = {
                ...validationSchema,
                password: Yup.string().nullable().test('edit-min-check', 'Min 6 char', (value) => {
                    return isEmpty(value) || (value.length >= 6);
                }),
            };
        }

        return Yup.object().shape(validationSchema);
    }

    handleOnPartnerChange = setFieldValue => (options, newValue) => {
        const { partnerOptions } = this.state;

        setFieldValue("mainLocationId", null);
        setFieldValue("additionalLocationIds", []);

        const selectedPartner = findPartnerById(partnerOptions, newValue);

        this.setState({selectedPartner}, () => this.reloadLocations());
    }

    handleOnBelongsToPartnerChange = (belongsToPartner, setFieldValue) => {
        setFieldValue("partnerId", null);
        setFieldValue("mainLocationId", null);
        setFieldValue("additionalLocationIds", []);

        this.setState({belongsToPartner: belongsToPartner, selectedPartner: null}, () => {
            if (belongsToPartner) {
                this.reloadPartners();
            } else {
                this.reloadLocations();
            }
        });
    }

    handlePartnerSearchChange = (value) => {
        if (this.state.partnerSearchTem === value) {
            return;
        }

        this.setState({partnerSearchTem: value}, () => {
            if (this.searchChangeTimeout) {
                clearTimeout(this.searchChangeTimeout);
            }

            this.searchChangeTimeout = setTimeout(() => {
                this.reloadPartners();
            }, 300);
        });
    };

    reloadLocations = () => {
        const { systemLocations, getSystemLocations } = this.props;
        const { partnerOptions, selectedPartner, belongsToPartner } = this.state;

        if (belongsToPartner) {
            if (selectedPartner) {
                const partner = findPartnerById(partnerOptions, selectedPartner.id);
                if (partner) {
                    this.setState({locationOptions: partner.locations})
                } else {
                    this.setState({locationOptions: []});
                }
            } else {
                this.setState({locationOptions: []});
            }
        } else if (!isEmpty(systemLocations)) {
            this.setState({locationOptions: systemLocations});
        } else {
            getSystemLocations().then(() => this.setState({locationOptions: this.props.systemLocations}));
        }
    }

    reloadPartners = () => {
        this.props.getPartners(PARTNERS_SEARCH_LIMIT, this.state.partnerSearchTem).then(() => {
            const {selectedPartner} = this.state;
            const partnerResults = [...this.props.partners];

            if (selectedPartner) {
                const partner = partnerResults.find(partner => partner.id === selectedPartner.id);
                if (!partner) {
                    partnerResults.push(selectedPartner);
                }
            }

            this.setState({partnerOptions: partnerResults}, () => this.reloadLocations());
        });
    }

    formSubmit = (values, actions) => {
        const { additionalLocationIds, mainLocationId } = values;
        const { currentUser } = this.props;
        const submitData = {...values};

        const locations = additionalLocationIds && additionalLocationIds
                .map(locationId => {return {id: locationId, main: false}}) || [];

        submitData.locations = locations.concat({id: mainLocationId, main: true});

        // prepare user groups
        submitData.userGroupIds = values.userGroups.map(userGroup => userGroup.id);

        // prepare partner
        if (!submitData.belongsToPartner) {
            submitData.partnerId = null;
        }

        delete submitData.additionalLocationIds;
        delete submitData.mainLocationId;
        delete submitData.userGroups;
        delete submitData.belongsToPartner;

        this.setState({alertMessage: ''}, () => {
            const method = this.props.modalProps.type === 'create' ? this.props.addSystemUser : this.props.updateSystemUser;

            method(submitData).then((response) => {
                if (!response.data) {
                    actions.setSubmitting(false);
                    this.setState({alertMessage: 'Unknown error. Please try again later'});
                    return;
                }

                if (!response.data.success) {
                    actions.setSubmitting(false);
                    this.setState({alertMessage: response.data.error.message});
                    return;
                }

                this.props.hideModal();
                this.props.modalProps.reloadFunction(true);

                if (values.id === currentUser.id) {
                    this.props.getUserSummary();
                    this.props.clearViewAs();
                }
            });
        });
    }

    scrollDropdownIntoView = (e) => {
        const elementClicked = e.target.nodeName;
        const boundingElement = e.currentTarget;
        const modalBody = document.getElementsByClassName('modal-body')[0];

        if (elementClicked !== 'LABEL') {

            setTimeout(function () {

                // Scroll down if the bottom is hidden...
                if (boundingElement.getBoundingClientRect().bottom > modalBody.getBoundingClientRect().bottom) {

                    // ... and the top won't end up hidden by scrolling down
                    if (boundingElement.getBoundingClientRect().height < modalBody.getBoundingClientRect().height) {

                        // Scroll down till bottom of element reaches bottom of screen
                        boundingElement.scrollIntoView({block: "end"});
                    }
                }
            }, 200);
        }
    }

    render() {
        const { alertMessage, locationOptions, partnerOptions, systemUserLoader } = this.state;
        const { modalProps, systemUserRoles, systemUserGroups, locationsLoader,
                canViewPartners, partnersLoader, systemUser } = this.props;

        const initialUserLocations = systemUser && systemUser.locations || [];
        const initialMainLocationId = findMainLocationId(initialUserLocations);
        const initialAdditionalLocations = excludeLocation(initialUserLocations, initialMainLocationId);

        return (
            <Fragment>
                <div className="modal" style={{display: 'block'}} tabIndex="-1" role="dialog">
                    {!systemUserLoader &&
                    <Loader />
                    }
                    {systemUserLoader &&
                    <Formik
                        initialValues={{
                            id: systemUser && systemUser.id || null,
                            username: systemUser &&  systemUser.username || '',
                            systemUserRoleId: systemUser && systemUser.systemUserRoleId || null,
                            active: modalProps.type === 'create' ? true: systemUser && systemUser.active,
                            lastIpAddress: systemUser &&  systemUser.lastIpAddress || null,
                            mainLocationId: initialMainLocationId,
                            additionalLocationIds: initialAdditionalLocations.map(location => location.id),
                            userGroups: systemUser && systemUser.userGroups || [],
                            firstName: systemUser && systemUser.firstName || '',
                            lastName: systemUser && systemUser.lastName || '',
                            email: systemUser &&  systemUser.email || '',
                            mobileNumber: systemUser &&  systemUser.mobileNumber || '',
                            password: '',
                            belongsToPartner: canViewPartners && (systemUser && systemUser.partner != undefined) ? true : false,
                            partnerId: canViewPartners &&  (systemUser && systemUser.partner != undefined) ? (systemUser.partner.id || null) : null
                        }}
                        validationSchema={this.systemUserSchema()}
                        onSubmit={this.formSubmit}
                        render={({
                                     handleChange,
                                     handleSubmit,
                                     handleBlur,
                                     values,
                                     errors,
                                     touched,
                                     isSubmitting,
                                     setFieldValue
                                 }) => (
                            <form onSubmit={handleSubmit}>
                                <div className="modal-dialog">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title">
                                                {modalProps.type === 'create' ? 'Add User' : 'Edit User'}
                                            </h5>
                                            <button onClick={this.props.hideModal} type="button" className="close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div className="modal-body form-horizontal">
                                            {!isEmpty(alertMessage) && (
                                                <div className="alert alert-inline alert-danger alert-dismissible">
                                                    <p className="mb-0">{alertMessage}</p>
                                                </div>
                                            )}
                                            <div className="form-group">
												<span className="h-check">
													<label>Active&nbsp;</label>
													<div className="form-check checkbox-slider checkbox-slider--b-flat">
														<label>
															<input
                                                                name="active"
                                                                type="checkbox"
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                checked={values.active}
                                                            />
															<span>&nbsp;</span>
														</label>
													</div>
												</span>
                                            </div>
                                            <div className="form-group">
                                                <TextInput
                                                    label="First name"
                                                    name="firstName"
                                                    value={values.firstName}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    errors={errors}
                                                    touched={touched}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <TextInput
                                                    label="Last name"
                                                    name="lastName"
                                                    value={values.lastName}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    errors={errors}
                                                    touched={touched}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <TextInput
                                                    label="Email"
                                                    name="email"
                                                    value={values.email}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    errors={errors}
                                                    touched={touched}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <TextInput
                                                    label="Mobile Number (optional)"
                                                    name="mobileNumber"
                                                    value={values.mobileNumber}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    errors={errors}
                                                    touched={touched}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <TextInput
                                                    label="Username (optional)"
                                                    name="username"
                                                    placeholder="If not provided, system generates username"
                                                    value={values.username}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    errors={errors}
                                                    touched={touched}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <TextInput
                                                    label="Password"
                                                    name="password"
                                                    placeholder={modalProps.type === 'edit' ? 'If provided, it overwrites the current password' : ''}
                                                    value={values.password}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    errors={errors}
                                                    touched={touched}
                                                    type="password"
                                                    autocomplete="new-password"
                                                />
                                            </div>
                                            <div className="form-group">
                                                <div onClick={this.scrollDropdownIntoView.bind(this)}>
                                                    <FormSelect
                                                        title="User Role"
                                                        fieldName="systemUserRoleId"
                                                        placeholder="Select one..."
                                                        options={rolesAsOptions(systemUserRoles)}
                                                        setFieldValue={setFieldValue}
                                                        value={values.systemUserRoleId}
                                                        onBlur={handleBlur}
                                                        errors={errors}
                                                        touched={touched}
                                                    />
                                                </div>
                                            </div>
                                            {canViewPartners &&
                                            <div className="form-group">
                                                    <span className="h-check">
                                                        <label>Belongs to Partner&nbsp;</label>
                                                        <div
                                                            className="form-check checkbox-slider checkbox-slider--b-flat">
                                                            <label>
                                                                <input
                                                                    name="belongsToPartner"
                                                                    type="checkbox"
                                                                    onBlur={handleBlur}
                                                                    onChange={event => {
                                                                        handleChange(event);
                                                                        this.handleOnBelongsToPartnerChange(event.target.checked, setFieldValue);
                                                                    }}
                                                                    checked={values.belongsToPartner}
                                                                />
                                                                <span>&nbsp;</span>
                                                            </label>
                                                        </div>
                                                    </span>
                                            </div>
                                            }

                                            {canViewPartners && values.belongsToPartner &&
                                            <div className="form-group position-relative">
                                                <div onClick={this.scrollDropdownIntoView.bind(this)}>
                                                    <FormSelect
                                                        title="Partner"
                                                        // info={PARTNERS_SEARCH_INFO}
                                                        fieldName="partnerId"
                                                        setFieldValue={setFieldValue}
                                                        options={partnersAsOptions(partnerOptions)}
                                                        value={values.partnerId}
                                                        onBlur={handleBlur}
                                                        errors={errors}
                                                        touched={touched}
                                                        isLoading={partnersLoader}
                                                        onInputChange={this.handlePartnerSearchChange}
                                                        onPostChange={this.handleOnPartnerChange(setFieldValue)}
                                                    />
                                                </div>
                                            </div>
                                            }

                                            <div className="form-group">
                                                <div onClick={this.scrollDropdownIntoView.bind(this)}>
                                                    <FormSelect
                                                        title="Main Location"
                                                        fieldName="mainLocationId"
                                                        options={locationsAsOptions(locationOptions)}
                                                        setFieldValue={setFieldValue}
                                                        value={values.mainLocationId}
                                                        onBlur={handleBlur}
                                                        errors={errors}
                                                        touched={touched}
                                                        isLoading={locationsLoader}
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div onClick={this.scrollDropdownIntoView.bind(this)}>
                                                    <FormSelect
                                                        title="Additional Locations (optional)"
                                                        fieldName="additionalLocationIds"
                                                        options={locationsAsOptions(excludeLocation(locationOptions, values.mainLocationId))}
                                                        setFieldValue={setFieldValue}
                                                        value={values.additionalLocationIds}
                                                        isMulti={true}
                                                        onBlur={handleBlur}
                                                        errors={errors}
                                                        touched={touched}
                                                        isLoading={locationsLoader}
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div onClick={this.scrollDropdownIntoView.bind(this)}>
                                                    <FormSelect
                                                        title="Groups (optional)"
                                                        fieldName="userGroups"
                                                        options={groupsAsOptions(systemUserGroups)}
                                                        setFieldValue={setFieldValue}
                                                        value={values.userGroups}
                                                        isMulti={true}
                                                        onBlur={handleBlur}
                                                        errors={errors}
                                                        touched={touched}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="modal-footer">
                                            <button onClick={this.props.hideModal} className="btn" type="button">
                                                Cancel
                                            </button>
                                            <button disabled={isSubmitting} type="submit" className="btn btn-primary">
                                                {modalProps.type === 'create' ? 'Add User' : 'Edit User'}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        )}
                    />}}
                </div>
                <div className="modal-backdrop show" tabIndex="1"/>
            </Fragment>
        );
    }
}


const partnersLoadingSelector = createLoadingSelector(['GET_PARTNERS']);
const locationsLoadingSelector = createLoadingSelector(['GET_PARTNERS', 'GET_SYSTEM_LOCATIONS']);

const mapStateToProps = (state) => {
    const systemLocations = getAdmin(state).systemLocations,
        locationsLoader = locationsLoadingSelector(state),
        modalProps = getModalData(state).modalProps,
        systemUser = getAdmin(state).systemUser,
        systemUserRoles = getAdmin(state).systemUserRoles,
        systemUserGroups = getAdmin(state).systemUserGroups,
        partners = getAdmin(state).partners,
        partnersLoader = partnersLoadingSelector(state),
        userPermission = getUser(state).userPermission,
        currentUser = getUser(state).user;

    const settingsPermissions =
        userPermission.find((x) => x.name === 'ADMN') &&
        userPermission.find((x) => x.name === 'ADMN').permissions;

    const canViewPartners = settingsPermissions && settingsPermissions.includes("ADMN_PARTNER_DETAIL");

    return {
        systemLocations,
        locationsLoader,
        modalProps,
        systemUser,
        systemUserRoles,
        systemUserGroups,
        canViewPartners,
        partners,
        partnersLoader,
        currentUser
    };
};

const mapDispatchToProps = {
    getSystemLocations,
    hideModal,
    getSystemUserRoles,
    getSystemUserGroups,
    addSystemUser,
    updateSystemUser,
    getPartners,
    getUserSummary,
    clearViewAs,
    getSystemUser,
    clearSystemUser
};

export default connect(mapStateToProps, mapDispatchToProps)(AddSystemUserModal);

