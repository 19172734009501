import React, {Component} from "react";
import Wizard from "../../camvio-wizard/Wizard";
import Step from "../../camvio-wizard/Step";
import { connect } from 'react-redux';

import {
    ActionNotSupported,
    Actions,
    Addresses,
    Confirmation,
    ConfirmationTest,
    PlansAndProducts,
    Provisioning,
    ProvisioningChange,
    ServiceQuery
} from "./Steps";

const STEPS = {
    ACTIONS: 'actions-step',
    ADDRESS_STEP: 'address-step',
    PLANS_AND_PRODUCTS: 'plans-and-products-step',
    PROVISIONING: 'provisioning-step',
    PROVISIONING_CHANGE: 'provisioning-change-step',
    CONFIRMATION: 'confirmation-step',
    ACTION_NOT_SUPPORTED: 'action-not-supported-step',
    CONFIRMATION_TEST: 'confirmation-test-step',
    SERVICE_QUERY: 'service-query-step',
    STATUS: 'status-step',
}

class ManualProvisioningWizard extends Component {

    componentDidMount() {
        document.title = 'Provisioning Tool - camvio.cloud';
        this.props.changeNavBarType('lock', 'manualProvisioningWizard', 'Provisioning Tool');
    }

    render() {
        const { history } = this.props;

        return (
            <Wizard name='ManualProvisioningWizard'>
                <Step name={STEPS.ACTIONS}
                      title='Actions'
                      component={Actions}
                      nextHint={STEPS.PLANS_AND_PRODUCTS}
                      next={({provisioningAction}) => {
                          switch (provisioningAction.actionType) {
                              case 'ACTIVATE':
                                  return STEPS.PLANS_AND_PRODUCTS;
                              case 'QUERY':
                                  return STEPS.SERVICE_QUERY;
                              case 'DEACTIVATE':
                              case 'SUSPEND':
                              case 'RESTORE':
                              case 'CHANGE':
                              case 'CHANGE_NUMBER':
                                  return STEPS.PROVISIONING_CHANGE;
                              default:
                                  return STEPS.ACTION_NOT_SUPPORTED;
                          }
                      }}
                />
                <Step name={STEPS.ACTION_NOT_SUPPORTED}
                      title='Action not supported'
                      component={ActionNotSupported}
                      previous={STEPS.ACTIONS}
                />
                <Step name={STEPS.ADDRESS_STEP}
                      title='Physical Address'
                      component={Addresses}
                      next={STEPS.PLANS_AND_PRODUCTS}
                />
                <Step name={STEPS.PLANS_AND_PRODUCTS}
                      title='Plans & Products'
                      component={PlansAndProducts}
                      next={STEPS.PROVISIONING}
                />
                <Step name={STEPS.PROVISIONING}
                      title='Provisioning'
                      component={Provisioning}
                      next={STEPS.CONFIRMATION}
                      nextBtnName='Provision'
                />
                <Step name={STEPS.PROVISIONING_CHANGE}
                      title='Provision'
                      component={ProvisioningChange}
                      next={STEPS.CONFIRMATION}
                      history={history}
                />
                <Step name={STEPS.CONFIRMATION}
                      title='Confirmation'
                      component={Confirmation}
                      history={history}
                />
                <Step name={STEPS.CONFIRMATION_TEST}
                      title='Just an empty step'
                      component={ConfirmationTest}
                      next={STEPS.CONFIRMATION}
                />

                <Step name={STEPS.SERVICE_QUERY}
                      title='Service Query'
                      component={ServiceQuery}
                      next={STEPS.STATUS}
                />
                <Step name={STEPS.STATUS}
                      title='Status'
                      component={Confirmation}
                />

            </Wizard>
        )
    }
}

const mapStateToProps = (state, props) => {
    return {
    };
};

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(ManualProvisioningWizard);