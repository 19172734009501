import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { statusColor } from '../../../../utils/ui';

const Account = ({ account, history, accountAccessModal, portalAccess }) => {
	const [ isShowingAccountId, showHideAccountId ] = useState(false);

	const user = account.systemUser;

	return (
		<div
			className="cmv-container-client"
			onClick={() => accountAccessModal(account.id)}
			key={account.id}
		>
			<div className="cmv-client-data-basic">
				<div className="cmv-client">
					<h2 className="cmv-client-name">{account.company ? account.company : account.name}</h2>
					<div className={`cmv-client-status cmv-client-status-${statusColor(account.tierLevel)}`}>
						<span className="status-text">{account.status}</span>
						<div className={`status-bar status-bar-${account.tierLevel * 20}`}>
							<div className="status-bar-separator" />
							<div className="status-bar-separator" />
							<div className="status-bar-separator" />
							<div className="status-bar-separator" />
						</div>
					</div>
				</div>
				<div className="cmv-client">
					<p className="cmv-client-address">
						<i className="fas fa-file-invoice-dollar" /> {account.billingAddress}
					</p>
				</div>
			</div>
			<div className="cmv-client-data-extended">
				<div className="row">
					<div className="col-md">
						<div className="card card-xs">
							<div className="card-header">
								<i className="fas fa-fw fa-hashtag" />
								<span className="d-none d-md-inline-block">
									{isShowingAccountId ? `Account id` : `Account #`}{' '}
								</span>
							</div>
							<div
								className="card-body"
								onClick={(e) => {
									e.stopPropagation();
									showHideAccountId((prevState) => !prevState);
								}}
							>
								<span
									className={
										isShowingAccountId ? 'date-toggable date-toggable-relative' : 'date-toggable'
									}
								>
									{isShowingAccountId ? account.id : account.number}
								</span>
							</div>
						</div>
					</div>
					<div className="col-md">
						<div className="card card-xs">
							<div className="card-header">
								<i className="fas fa-fw fa-list-alt" />
								<span className="d-none d-md-inline-block"> Account type</span>
							</div>
							<div className="card-body">{account.accountType}</div>
						</div>
					</div>
					<div className="col-md">
						<div className="card card-xs">
							<div className="card-header">
								<i className="fas fa-fw fa-history" />
								<span className="d-none d-md-inline-block"> Customer since</span>
							</div>
							<div className="card-body">
								{account.createdDate ? moment(account.createdDate).format('MM/DD/YYYY') : ''}
							</div>
						</div>
					</div>
					<div className="col-md">
						<div className="card card-xs">
							<div className="card-header">
								<i className="fas fa-fw fa-history" />
								<span className="d-none d-md-inline-block"> Portal Access </span>
							</div>
							<div className="card-body">
								{account.systemUser
									? account.systemUser.isActive
										? 'ACTIVE'
										: 'INACTIVE'
									: 'NOT REGISTERED'
								}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

Account.propTypes = {
	account: PropTypes.object,
	history: PropTypes.object,
	portalAccess: PropTypes.object,
};

export default Account;
