import {
	PROVISIONING_ACTIONS,
	PROVISIONING_MAP_LINK_LOCATION,
	PROVISIONING_PLAN_DETAILS,
	PROVISIONING_PLANS,
	PROVISIONING_SEARCH_SERIALIZED_ITEM,
	PROVISIONING_SERVICE_MODELS,
	PROVISIONING_QUEUE_GROUPS,
} from "../actions/provisioning.actions";

const initialState = {
	provisioningActions: null,
	serviceModels: null,
	plans: null,
	planDetails: null,
	validatedAddress: null,
	serializedItems: [],
	provisioningQueueGroups: [],
};

export const provisioningReducer = (state = initialState, action) => {
	switch (action.type) {
		case PROVISIONING_ACTIONS.SUCCESS:
			return {
				...state,
				provisioningActions: action.payload
			};
		case PROVISIONING_ACTIONS.EMPTY:
			return {
				...state,
				provisioningActions: null
			};
		case PROVISIONING_SERVICE_MODELS.SUCCESS:
			return {
				...state,
				serviceModels: action.payload
			};
		case PROVISIONING_SERVICE_MODELS.EMPTY:
			return {
				...state,
				serviceModels: null
			};
		case PROVISIONING_PLANS.SUCCESS:
			return {
				...state,
				plans: action.payload.plans,
				validatedAddress: action.payload.validatedAddress,
			};
		case PROVISIONING_PLANS.EMPTY:
			return {
				...state,
				planBundles: null,
				plans: null,
			};
		case PROVISIONING_PLAN_DETAILS.SUCCESS:
			return {
				...state,
				plansDetails: state.planDetails
			};
		case PROVISIONING_PLAN_DETAILS.EMPTY:
			return {
				...state,
				planDetails: null
			};
		case PROVISIONING_MAP_LINK_LOCATION.SUCCESS:
			return {
				...state,
				validatedAddress: action.payload.validatedAddress
			};
		case PROVISIONING_MAP_LINK_LOCATION.FAILURE:
			return {
				...state,
				validatedAddress: action.payload
			};
		case PROVISIONING_MAP_LINK_LOCATION.EMPTY:
			return {
				...state,
				validatedAddress: null
			}
		case PROVISIONING_SEARCH_SERIALIZED_ITEM.SUCCESS:
			return {
				...state,
				serializedItems: action.payload.serializedItems
			}
		case PROVISIONING_SEARCH_SERIALIZED_ITEM.EMPTY:
			return {
				...state,
				serializedItems: []
			}
		case PROVISIONING_QUEUE_GROUPS.SUCCESS:
			return {
				...state,
				provisioningQueueGroups: action.payload.provisioningQueueGroups
			}
		case PROVISIONING_QUEUE_GROUPS.EMPTY:
			return {
				...state,
				provisioningQueueGroups: []
			}
		default:
			return state;
	}
};
