import React, { Component, Fragment } from 'react';
import { hideModal } from '../../../actions/modal.actions';
import { getUser } from '../../../selectors';
import { connect } from 'react-redux';
import Select from 'react-select';
import { groupStyles } from '../../../utils/SelectStyles';
import { changeUserLocation } from '../../../actions/user.actions';

const transformLocation = (locations) => {

	if(locations.length === 0) return locations;
	let test = locations.map((location) => {
		return {
			id: location.id,
			value: location.name,
			label: location.description,
			main: location.main
		};
	});

	return test;
};


class LocationModal extends Component {

	state = {
		location: this.props.currentLocation || {}
	};

	componentDidUpdate(prevProps) {}

	changeLocationHandler = (location) => {
		this.setState({
			location: location
		});
	};

	changeLocation = () => {
		this.props.changeUserLocation(this.state.location);
		this.props.hideModal();
	};

	closeModal = () => {
		this.props.hideModal();
	};

	scrollDropdownIntoView = (e) => {
		const elementClicked = e.target.nodeName;
		const boundingElement = e.currentTarget;
		const modalBody = document.getElementsByClassName('modal-body')[0];

		if (elementClicked !== 'LABEL') {

			setTimeout(function () {

				// Scroll down if the bottom is hidden...
				if (boundingElement.getBoundingClientRect().bottom > modalBody.getBoundingClientRect().bottom) {

					// ... and the top won't end up hidden by scrolling down
					if (boundingElement.getBoundingClientRect().height < modalBody.getBoundingClientRect().height) {

						// Scroll down till bottom of element reaches bottom of screen
						boundingElement.scrollIntoView({block: "end"});
					}
				}
			}, 200);
		}
	}

	render() {
		const { location } = this.state;
		const { currentLocation, userSumary } = this.props;
		
		const transformLocations = transformLocation(userSumary.locations || []);

		return (
			<Fragment>
				<div className="modal" style={{ display: 'block' }} tabIndex="-1" role="dialog">
					<div className="modal-dialog" role="document">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">Change Location</h5>
								<button onClick={this.closeModal} type="button" className="close">
									<span>&times;</span>
								</button>
							</div>
							<div className="modal-body form-horizontal">
								<p className="lead">Your current location is:</p>
								<h4>
									{currentLocation.label}
									<span className="text-muted" />({currentLocation.value})
								</h4>
								<div className="form group">
									<div onClick={this.scrollDropdownIntoView}>
										<Select
											value={location}
											onChange={this.changeLocationHandler}
											options={transformLocations}
											styles={groupStyles}
										/>
									</div>
								</div>
							</div>
							<div className="modal-footer">
								<button onClick={this.closeModal} className="btn">
									Cancel
								</button>
								<button onClick={this.changeLocation} className="btn btn-primary">
									Change Location
								</button>
							</div>
						</div>
					</div>
				</div>
				<div className="modal-backdrop show" tabIndex="1" />
			</Fragment>
		);
	}
}

const mapStateToProps = (state) => {
	const userSumary = getUser(state).user,
		currentLocation = getUser(state).currentLocation;
	
	return {
		userSumary,
		currentLocation
	};
};

const mapDispatchToProps = {
	changeUserLocation,
	hideModal
};

export default connect(mapStateToProps, mapDispatchToProps)(LocationModal);
