import React, { Component, Fragment } from 'react';
import { hideModal } from '../../../actions/modal.actions';
import {getModalData, getUser, getUserPermission, getViewAs} from '../../../selectors';
import { connect } from 'react-redux';
import { changeUserLocation } from '../../../actions/user.actions';
import { Formik } from "formik";
import isEmpty from "../../../utils/helpers";
import FormSelect from "../../../components/UI/FormSelect";
import { setViewAsPartner, setViewAsLocation, clearViewAs } from "../../../actions/viewAs.actions";

import axiosInstance from "../../../utils/axios-instance";
import { get } from 'lodash';


const PARTNERS_SEARCH_LIMIT = 10;

const asOptions = (source, mapper) => {
	if (!Array.isArray(source)) {
		return [];
	}

	return source.map(mapper);
}

const locationsAsOptions = (locations) => asOptions(locations, location => {
	return {
		label: location.description,
		value: location.id
	}
});

const partnersAsOptions = (partners) => asOptions(partners, partner => {
	return {
		label: partner.name,
		value: partner.id
	};
});

const findPartnerById = (partners, partnerId) => {
	return partners && partners.find(partner => partner.id === partnerId);
}

class ViewAsPartnerModal extends Component {

	state = {
		alertMessage: '',
		partnerSearchTerm: null,
		belongsToPartner: true,
		selectedPartner: this.props.viewAsState.partner,
		partnerOptions: [],
		fetchingPartners: false,
	};

	componentDidMount() {
		this.reloadPartners();
	}

	closeModal = () => {
		this.props.hideModal();
	};

	reloadPartners = () => {
		if (!this.props.canViewPartnersPermission) {
			return;
		}

		this.setState({fetchingPartners: true, alertMessage: ''});
		const { partnerSearchTerm } = this.state;

		try {
			const queryParams = {limit: PARTNERS_SEARCH_LIMIT}

			if (!isEmpty(partnerSearchTerm)) {
				queryParams.query = partnerSearchTerm;
			}

			axiosInstance.get(`/admin/partners`, {params: {...queryParams}})
				.then(response => {
					const {selectedPartner} = this.state;
					const partnerResults = response.data.partners;

					if (selectedPartner) {
						const partner = partnerResults.find(partner => partner.id === selectedPartner.id);
						if (!partner) {
							partnerResults.push(selectedPartner);
						}
					}

					this.setState({
						fetchingPartners: false,
						partnerOptions: partnerResults
					})
				})
				.catch(errorResponse => {
					this.handlerErrorResponse(errorResponse);
				});
		} catch (err) {
			this.handlerErrorResponse(err);
		}
	}

	handlerErrorResponse = (errorResponse) => {
		this.setState({
			fetchingPartners: false,
			alertMessage: get(errorResponse, 'response.data.error.message', 'Something goes wrong'),
			partnerOptions: []
		})
	}

	handleOnPartnerChange = setFieldValue => (options, newValue) => {
		const selectedPartner = findPartnerById(this.state.partnerOptions, newValue);

		this.setState({selectedPartner});

		if (selectedPartner && selectedPartner.locations && selectedPartner.locations.length === 1) {
			setFieldValue("locationId", selectedPartner.locations[0].id);
		} else {
			setFieldValue("locationId", null);
		}
	}

	handleOnBelongsToPartnerChange = (belongsToPartner, setFieldValue) => {
		setFieldValue("partnerId", null);
		setFieldValue("locationId", null);

		this.setState({belongsToPartner: belongsToPartner, selectedPartner: null});
	}

	handlePartnerSearchChange = (value) => {
		if (this.state.partnerSearchTerm === value) {
			return;
		}

		this.setState({partnerSearchTerm: value}, () => {
			if (this.searchChangeTimeout) {
				clearTimeout(this.searchChangeTimeout);
			}

			this.searchChangeTimeout = setTimeout(() => {
				this.reloadPartners();
			}, 300);
		});
	};

	formSubmit = (values, actions) => {
		const { belongsToPartner, locationId, partnerId } = values;
		const { setViewAsPartner, setViewAsLocation, clearViewAs } = this.props;
		const { selectedPartner } = this.state;

		if (!belongsToPartner) {
			clearViewAs();

			this.props.hideModal();
			return;
		}

		let hasErrors = false;
		if (!partnerId) {
			actions.setFieldError("partnerId", "Required");
			hasErrors = true;
		}

		if (!locationId) {
			actions.setFieldError("locationId", "Required");
			hasErrors = true;
		}

		if (hasErrors) {
			actions.setSubmitting(false);
			return;
		}

		const selectedLocation = selectedPartner.locations.find(loc => loc.id === locationId);

		setViewAsPartner(selectedPartner);
		setViewAsLocation(selectedLocation);

		this.props.hideModal();
	};

	render() {

		const { alertMessage, selectedPartner, partnerOptions, belongsToPartner, fetchingPartners } = this.state;
		const { viewAsState, currentUserLocation, canViewPartnersPermission } = this.props;

		const locations = selectedPartner && selectedPartner.locations || [];

		return (
			<Fragment>
				<div className="modal" style={{display: 'block'}} tabIndex="-1" role="dialog">
					<Formik
						initialValues={{
							belongsToPartner: belongsToPartner,
							partnerId: viewAsState.partner && viewAsState.partner.id,
							locationId: viewAsState.partner && viewAsState.location && viewAsState.location.id,
						}}
						onSubmit={this.formSubmit}
						render={({handleChange, handleSubmit, handleBlur, values, errors, touched, isSubmitting, setFieldValue}) => (
							<form onSubmit={handleSubmit}>
								<div className="modal-dialog">
									<div className="modal-content" style={{minHeight: belongsToPartner? '500px': 0}}>
										<div className="modal-header">
											<h5 className="modal-title">
												VIEW AS PARTNER
											</h5>
											<button onClick={this.props.hideModal} type="button" className="close">
												<span aria-hidden="true">&times;</span>
											</button>
										</div>
										<div className="modal-body form-horizontal">
											{!isEmpty(alertMessage) && (
												<div className="alert alert-inline alert-danger alert-dismissible">
													<p className="mb-0">{alertMessage}</p>
												</div>
											)}

											{!canViewPartnersPermission &&
												<div className="alert alert-inline alert-warning">
													<p className="mb-0">You do not have permission to view partners</p>
												</div>
											}

											<div className="form-group">
                                                    <span className="h-check">
                                                        <label>{belongsToPartner? 'Enabled' : 'Disabled'} &nbsp;</label>
                                                        <div className="form-check checkbox-slider checkbox-slider--b-flat">
                                                            <label>
                                                                <input
																	name="belongsToPartner"
																	type="checkbox"
																	onBlur={handleBlur}
																	onChange={event => {
																		handleChange(event);
																		this.handleOnBelongsToPartnerChange(event.target.checked, setFieldValue);
																	}}
																	checked={values.belongsToPartner}
																/>
                                                                <span>&nbsp;</span>
                                                            </label>
                                                        </div>
                                                    </span>
											</div>

											{values.belongsToPartner &&
												<Fragment>
												<div className="form-group position-relative" >
													<FormSelect
														title="Available Partners"
														fieldName="partnerId"
														setFieldValue={setFieldValue}
														options={partnersAsOptions(partnerOptions)}
														value={values.partnerId}
														onBlur={handleBlur}
														errors={errors}
														touched={touched}
														isLoading={fetchingPartners}
														onInputChange={this.handlePartnerSearchChange}
														onPostChange={this.handleOnPartnerChange(setFieldValue)}
													/>
												</div>

												<div className="form-group">
													<FormSelect
														title="Set Location"
														fieldName="locationId"
														options={locationsAsOptions(locations)}
														setFieldValue={setFieldValue}
														value={values.locationId}
														onBlur={handleBlur}
														errors={errors}
														touched={touched}
														isLoading={fetchingPartners}
													/>
												</div>
												</Fragment>
											}
										</div>
										<div className="modal-footer">
											<button onClick={this.props.hideModal} className="btn" type="button">
												Cancel
											</button>
											<button disabled={isSubmitting} type="submit" className="btn btn-primary">
												Continue
											</button>
										</div>
									</div>
								</div>
							</form>
						)}
					/>}
				</div>
				<div className="modal-backdrop show" tabIndex="1"/>
			</Fragment>
		);
	}
}

const mapStateToProps = (state) => {
	const viewAsState = getViewAs(state),
		modalProps = getModalData(state).modalProps,
		currentUserLocation = getUser(state).currentLocation,
		canViewPartnersPermission = getUserPermission(state, 'ADMN', 'ADMN_PARTNER_DETAIL');
	
	return {
		viewAsState,
		modalProps,
		currentUserLocation,
		canViewPartnersPermission
	};
};

const mapDispatchToProps = {
	hideModal,
	changeUserLocation,
	setViewAsPartner,
	setViewAsLocation,
	clearViewAs
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewAsPartnerModal);
