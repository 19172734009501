import React, { Component } from 'react';
import FormSelect from '../../../components/UI/FormSelect';

class AccountTerms extends Component {
	componentDidMount() {
		const { getAccountTypeDetails, values } = this.props;
		getAccountTypeDetails(values.accountHolder.accountType);
	}

	componentDidUpdate(prevProps) {
		// This is for asign default values to accountTerms and billCycles
		const {setFieldValue, defaultAccountId, billCycles} = this.props;
		if(prevProps.defaultAccountId !== defaultAccountId){
			// setFieldValue('accountTermsInfo.accountTerm', defaultAccountId)
			if(Object.keys(billCycles).length > 0){
				setFieldValue('accountTermsInfo.billCycle', billCycles.find(x => x.isDefault === true).value, false)
			}
		}
	}
	render() {
		const {
			values,
			errors,
			touched,
			accountTerms,
			billCycles,
			setFieldTouched,
			setFieldValue,
			handleAccountTermDetails,
			accountTiers,
	
		} = this.props;

		return (
			<div className="tab-pane show active">
				<div className="form-row">
					<div className="form-group col-md-4">
						<FormSelect
							title="Account Terms"
							fieldName="accountTermsInfo.accountTerm"
							value={values.accountTermsInfo.accountTerm}
							onChange={(fieldName, value) => handleAccountTermDetails(value, setFieldValue, setFieldTouched)}
							onBlur={setFieldTouched}
							errors={errors}
							touched={touched}
							isMulti={false}
							options={accountTerms}
						/>
					</div>
					<div className="form-group col-md-4">
						<FormSelect
							title="Account Tier"
							fieldName="accountTermsInfo.accountTier"
							value={values.accountTermsInfo.accountTier}
							setFieldValue={setFieldValue}
							onBlur={setFieldTouched}
							errors={errors}
							touched={touched}
							isMulti={false}
							options={accountTiers}
							isDisabled={accountTiers.length === 0}
						/>
						{values.accountTermsInfo &&
						values.accountTermsInfo.accountTier && (
							<p className="new-acc-tier-deposit mt-1 mt-0">
								Required Security Deposit: <strong>${accountTiers.find((x) => x.value === values.accountTermsInfo.accountTier).deposit.toFixed(2)}</strong>
							</p>
						)}
					</div>
					<div className="form-group col-md-4">
						<FormSelect
							title="Billing Cycle"
							fieldName="accountTermsInfo.billCycle"
							value={values.accountTermsInfo.billCycle}
							setFieldValue={setFieldValue}
							onBlur={setFieldTouched}
							errors={errors}
							touched={touched}
							isMulti={false}
							options={billCycles}
						/>
					</div>
				</div>
			</div>
		);
	}
}

export default AccountTerms;
