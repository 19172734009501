import React, {Component, Fragment} from 'react';
import {Formik, Form} from 'formik';

import {connect} from 'react-redux';
import {hideModal} from "../../actions/modal.actions";
import {uploadWorkFlowBPMNFile} from "../../actions/workflow.actions";
import Dropzone from "react-dropzone";
import isEmpty from "../../utils/helpers";
import * as Yup from "yup";
import {toastr} from "react-redux-toastr";
import {getModalData} from "../../selectors";

const WorkflowScheme = Yup.object().shape({
    document: Yup.mixed().nullable().required('Required'),
});

class UploadWorkFlowModal extends Component {

    state = {
        isUploading: false,
        migrateWorkflows: false,
    }
    constructor(props) {
        super(props);
    }

    componentDidMount() {

    }

    componentWillUnmount() {
    }

    closeModal = () => {
        this.props.hideModal();
    };

    onCheckboxChange = (e) => {
        this.setState({
            migrateWorkflows: e.target.checked
        })
    };

     handleUploadWorkflow = (values) => {
         this.setState({
             isUploading: true
         })
         let bpmn = values.contents

         let processDefinition = {...this.props.modalProps};
         processDefinition["bpmn"] = bpmn;
         processDefinition["migrateOldWorkflows"] = this.state.migrateWorkflows;

         this.props.uploadWorkFlowBPMNFile(processDefinition).then(response =>{
            if(response.data && response.data.success){
                setTimeout(
                    function() {
                        toastr.success(response.data.message, { timeOut: 1000, position: 'top-center' });
                        this.props.hideModal();
                        window.location.reload();
                    }
                        .bind(this),
                    3000
                );
               //
            }else{
                const errorMessage = response.error.code + ":" + response.error.message;
                toastr.error(errorMessage, {timeOut: 0, position: 'top-center'});
                this.setState({
                    isUploading: false
                })
            }
         });

    }

    render() {
        const {
            isUploading,
        } = this.state;

        const {
             hideModal
        } = this.props;

        return (
            <Fragment>
                <div className="modal" style={{display: 'block'}} id="workflow-modal-upload" tabIndex="-1" role="dialog">
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">

                            <div className="modal-header">
                                <h5 className="modal-title">Upload Workflow</h5>
                                <button onClick={() => this.closeModal()} type="button" className="close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>

                            <Formik
                                initialValues={{
                                    document: '',
                                    contents: ''
                                }}
                                validationSchema={WorkflowScheme}
                                onSubmit={this.handleUploadWorkflow}
                                render={({handleChange, handleSubmit, values, errors, touched, isSubmitting, setFieldValue}) => (
                                    <div className="modal-body">
                                        <form className="documents-panel-form" id="mainForm" onSubmit={handleSubmit} style={{marginBottom: '0px'}}>
                                        <div className="form-check checkbox-slider checkbox-slider--b-flat">
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    onChange={this.onCheckboxChange}
                                                    name="accountNumber"
                                                    checked={this.state.migrateWorkflows}
                                                />
                                                <span>Migrate Old Workflows</span>
                                            </label>
                                        </div>
                                        <Dropzone
                                            multiple={false}
                                            disabled={isUploading}
                                            accept=".xml"
                                            onDrop={async ([file]) => {
                                                var reader = new FileReader();
                                                setFieldValue("document", file);
                                                reader.onload = function(e) {
                                                    setFieldValue("contents",e.target.result);
                                                };
                                                if(file != null) {
                                                    reader.readAsText(file);
                                                }
                                            }}
                                        >
                                            {({getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject}) => (
                                                <div {...getRootProps()}
                                                     className={"documents-panel-droparea" + (
                                                         isDragActive && isDragAccept ? " documents-panel-droparea-dragging-valid" :
                                                             isDragActive && isDragReject ? " documents-panel-droparea-dragging-invalid" :
                                                                 !isEmpty(values.document) ? " documents-panel-droparea-accepted" :
                                                                     errors.document && touched.document ? " documents-panel-droparea-required" :
                                                                         ""
                                                     ) + (
                                                         isUploading ? " documents-panel-droparea-disabled" : ""
                                                     )}
                                                     style={{marginBottom: '0px'}}
                                                >
                                                    <input {...getInputProps()} />
                                                    <i className="fas fa-upload" />
                                                    <p data-text={!isEmpty(values.document) ? values.document.name : "Drag and Drop Workflow or Click to Upload"} />
                                                </div>
                                            )}
                                            </Dropzone>
                                        </form>
                                    </div>
                                )}
                            />
                            <div className="modal-footer">
                                <button
                                    disabled={isUploading}
                                    type="submit" form="mainForm" className="btn btn-primary"
                                    id="btn-pmnt-submit">
                                    Upload Workflow
                                </button>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="modal-backdrop show" tabIndex="1"/>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    const modalProps = getModalData(state).modalProps;
    return {
        modalProps,
    };
};

const mapDispatchToProps = {
    uploadWorkFlowBPMNFile,
    hideModal
};

export default connect(mapStateToProps, mapDispatchToProps)(UploadWorkFlowModal);
