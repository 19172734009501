import React, { Component } from 'react';
import { connect } from 'react-redux';

class ConfirmationTest extends Component {

    componentDidMount() {
        this.handleNext()
    }

    handleNext = () => {
        const { next } = this.props;
        if (!next) {
            return;
        }

        next({
            values: {
                provisioningAction: {
                    actionType: 'ACTIVATE',
                    description: 'Activate',
                    value: 'activate',
                },
                provisioningGroupId: 32359, // 1718057, 9, 1718039
            }
        });
    }

    render() {
        return null;
    }
}

const mapStateToProps = (state, props) => {
    return {
    };
};

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmationTest);