import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {hideModal} from '../../../actions/modal.actions';
import FormikInput from "../../../components/Common/Inputs/FormikInput";
import {getAccountDetails, getModalData} from '../../../selectors';
import {Formik} from 'formik';
import * as Yup from "yup";
import {updateContactAddress} from "../../../actions/accountDetailsActions/security.actions";
import FormSelect from "../../../components/UI/FormSelect";
import isEmpty from "../../../utils/helpers";
import AccountHolderLoader from "../../../components/Loader/AccountHolderLoader";
import {generateFullZip} from "../../CreateAccount/utils";
import {debounce} from "lodash";
import {mailingAddressValidation, billingAddressValidation } from '../../../actions/createAccount.actions';

const zipRegex = /(^[A-Za-z0-9][A-Za-z0-9 ]*[A-Za-z0-9]$)/
const zip4Regex = /(^\d{4}$)/

const AddressValidationSchema = Yup.object().shape({
    address1: Yup.string().required('Required'),
    city: Yup.string().required('Required'),
    stateId: Yup.string().required('Required'),
    zipcode: Yup.string().matches(zipRegex, 'Zipcode must contain only letters, numbers, and spaces').required('Required'),
    zip4: Yup.string().matches(zip4Regex, 'Zip+4 code must contain exactly 4 digits'),
});

class AccountMailingAddressModal extends Component {

    state = {
        showAlertMessage: false,
        alertMessage: '',
        showSuggestedAddress: false,
        suggestedAddress: '',
        suggestedAddressError: '',
        addressValidationLoader: false,
        verified: this.props.modalProps.verified || false,
    };

    closeModal = () => {
        this.props.hideModal();
    };

    goBack = () => {
        this.props.hideModal();
    };

    getNumberType = (numbers, type) => {
        if (!numbers) {
            return undefined;
        }
        return numbers.find((x) => x.numberType === type);
    };

    getType = (object, type) => {
        if (!object) {
            return undefined;
        }

        return object.find((x) => x.type === type);
    };

    formSubmit = (values, actions) => {
        const {showAlertMessage, alertMessage} = this.state;
        const {modalProps} = this.props;
        const {accountId, countryStatesData} = this.props;
        this.setState({
            isInputDisabled: true
        });

        let updatedInitialValues = {...values};

        if (updatedInitialValues['stateId']) {
            updatedInitialValues['state'] = countryStatesData.find(
                (x) => x.id === updatedInitialValues['stateId']
            ).name;
        }

        if (updatedInitialValues['state']) {
            updatedInitialValues['stateId'] = countryStatesData.find(
                (x) => x.name === updatedInitialValues['state']
            ).id;
            updatedInitialValues['countryId'] = countryStatesData.find(
                (x) => x.name === updatedInitialValues['state']
            ).id;
        } else {
            updatedInitialValues['stateId'] = '';
            updatedInitialValues['countryId'] = '';
        }

        let verified = this.state.verified;

        let data = {
            address1: updatedInitialValues.address1 || '',
            contactAddressId: updatedInitialValues.contactAddressId || 0,
            address2: updatedInitialValues.address2 || '',
            address3: updatedInitialValues.address3 || '',
            type: updatedInitialValues.type || '',
            zipcode: updatedInitialValues.zipcode || '',
            zip4: updatedInitialValues.zip4 || '',
            city: updatedInitialValues.city || '',
            stateId: updatedInitialValues.stateId || '',
            countryId: updatedInitialValues.countryId || '',
            verified: verified
        };

        this.props.updateContactAddress('primary', accountId, modalProps.primaryContacts.id, data).then((response) => {
            if (response.success) {
                this.props.hideModal();
            } else {
                actions.setSubmitting(false);
                this.setState({alertMessage: response.error.message})
            }
        });
    }

    scrollDropdownIntoView = (e) => {
        const elementClicked = e.target.nodeName;
        const boundingElement = e.currentTarget;
        const modalBody = document.getElementsByClassName('modal-body')[0];

        if (elementClicked !== 'LABEL') {

            setTimeout(function () {

                // Scroll down if the bottom is hidden...
                if (boundingElement.getBoundingClientRect().bottom > modalBody.getBoundingClientRect().bottom) {

                    // ... and the top won't end up hidden by scrolling down
                    if (boundingElement.getBoundingClientRect().height < modalBody.getBoundingClientRect().height) {

                        // Scroll down till bottom of element reaches bottom of screen
                        boundingElement.scrollIntoView({block: "end"});
                    }
                }
            }, 200);
        }
    }

    closeAddressSuggestion = () => {
        this.setState({
            addressValidationLoader: false,
            showSuggestedAddress: false
        });
    };

    addressValidation = (values) => {
        if (!isEmpty(values) && !isEmpty(values.zipcode)) values.zip = values.zipcode;
        if (!isEmpty(values) && !isEmpty(values.stateId)){
            values.state = this.props.countryStatesData.find(
                (x) => x.id === values.stateId
            ).abbreviation;
        }
        if (values.type === 'MAILING') this.asyncMailingAddress(values);
        if (values.type === 'BILLING') this.asyncBillingAddress(values);
    };

    asyncMailingAddress = debounce((mailingAddress) => {
        this.setState({addressValidationLoader: true});

        this.props.mailingAddressValidation(mailingAddress).then((response) => {
            if (response.data.status === 'OK') {
                this.setState({
                    addressValidationLoader: false,
                    suggestedAddress: response.data,
                    suggestedAddressError: '',
                    showSuggestedAddress: true
                });
            } else if (response.data.status === 'ERROR') {
                this.setState({
                    addressValidationLoader: false,
                    suggestedAddressError: response.data.errorDescription,
                    showSuggestedAddress: true
                });
            }
        });
    }, 1500);

    asyncBillingAddress = debounce((billingAddress) => {
        this.setState({addressValidationLoader: true});

        this.props.billingAddressValidation(billingAddress).then((response) => {
            if (response.data.status === 'OK') {
                this.setState({
                    addressValidationLoader: false,
                    suggestedAddress: response.data,
                    suggestedAddressError: '',
                    showSuggestedAddress: true,
                });
            } else if (response.data.status === 'ERROR') {
                this.setState({
                    addressValidationLoader: false,
                    suggestedAddressError: response.data.errorDescription,
                    showSuggestedAddress: true,
                    verified: false
                });
            }
        });
    }, 1500);

    useSuggestedAddress = (setFieldValue, stateOptions) => {
        // Set fields
        Object.keys(this.state.suggestedAddress).forEach((element) => {
            setFieldValue(`${element}`, `${this.state.suggestedAddress[element]}`);
        });

        // Set state
        stateOptions.forEach((stateOption) => {
            if (stateOption.abbreviation == this.state.suggestedAddress.state) {
                setFieldValue(`stateId`, stateOption.value);
            }
        });

        // Set Zip
        setFieldValue(`zipcode`, `${this.state.suggestedAddress.zip}`);

        // Set Zip+4
        setFieldValue(`zip4`, (this.state.suggestedAddress.zip4 != undefined ?
            `${this.state.suggestedAddress.zip4}` : ''))

        // Hide suggested address and Verify Address
        this.setState({showSuggestedAddress: false,
            verified: true});
    };

    render() {
        const {
            alertMessage,
            showSuggestedAddress,
            suggestedAddress,
            suggestedAddressError,
            addressValidationLoader
        } = this.state;

        const {
            modalProps,
            countryStatesData
        } = this.props;

        const stateOptions = [];
        if (!isEmpty(countryStatesData)) {
            countryStatesData.map((states) => {
                stateOptions.push({label: states.name, value: states.id, abbreviation: states.abbreviation});
            })
        }

        let mailingAddress;
        if (modalProps.type === 'MAILING') {
            mailingAddress = this.getType(modalProps.primaryContacts.addresses, 'MAILING');
        } else {
            mailingAddress = this.getType(modalProps.primaryContacts.addresses, 'BILLING');
        }

        //let mailingAddress = this.getType(modalProps.addresses, 'MAILING');

        let stateId;

        if (mailingAddress && mailingAddress['state']) {
            stateId = countryStatesData.find(
                (x) => x.name === mailingAddress['state']
            ).id;

        } else {
            stateId = '';
        }

        // let billingAddress = this.getType(modalProps.addresses, 'BILLING');
        // let serviceAddress = this.getType(modalProps.addresses, 'SERVICE');
        //
        // let homeNumber = this.getNumberType(modalProps.numbers, 'HOME');
        // let mobileNumber = this.getNumberType(modalProps.numbers, 'MOBILE');
        // let workNumber = this.getNumberType(modalProps.numbers, 'WORK');
        // let faxNumber = this.getNumberType(modalProps.numbers, 'FAX');
        //
        // let personalEmail = this.getType(modalProps.emails, 'PERSONAL');
        // let businessEmail = this.getType(modalProps.emails, 'BUSINESS');

        return (
            <Fragment>
                <div className="modal" style={{display: 'block'}} tabIndex="-1" role="dialog">
                    <Formik
                        initialValues={{
                            address1: mailingAddress && mailingAddress.address1,
                            address2: mailingAddress && mailingAddress.address2,
                            address3: mailingAddress && mailingAddress.address3,
                            city: mailingAddress && mailingAddress.city,
                            contactAddressId: mailingAddress ? mailingAddress.id : 0,
                            type: modalProps.type,
                            zipcode: mailingAddress && mailingAddress.zipcode,
                            zip4: mailingAddress && mailingAddress.zip4,
                            country: mailingAddress && mailingAddress.country,
                            state: mailingAddress && mailingAddress.state,
                            stateId: stateId
                        }}
                        validationSchema={AddressValidationSchema}
                        onSubmit={this.formSubmit}
                        render={({handleChange, handleSubmit, handleBlur, values, errors, touched, isSubmitting, setFieldValue}) => (
                            <form onSubmit={handleSubmit}>
                                <div className="modal-dialog">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title">Edit {modalProps.type} Address</h5>
                                            <button onClick={this.closeModal} type="button" className="close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div className="modal-body">
                                            <div className="form-horizontal">

                                                {alertMessage && (
                                                    <div className="alert alert-inline alert-danger alert-dismissible">
                                                        <p className="mb-0">{alertMessage}</p>
                                                    </div>
                                                )}

                                                <FormikInput
                                                    wrapperClass="form-group"
                                                    label="Line 1"
                                                    type="text"
                                                    name={`address1`}
                                                    onBlur={handleBlur}
                                                    value={values.address1}
                                                    placeholder="Not provided"
                                                    errors={errors}
                                                    touched={touched}
                                                    onChange={handleChange}
                                                    onKeyUp={() => {this.addressValidation(values)}}
                                                />

                                                <FormikInput
                                                    wrapperClass="form-group"
                                                    label="Line 2"
                                                    type="text"
                                                    name={`address2`}
                                                    onBlur={handleBlur}
                                                    value={values.address2}
                                                    placeholder="Not provided"
                                                    errors={errors}
                                                    touched={touched}
                                                    onChange={handleChange}
                                                    onKeyUp={() => {this.addressValidation(values)}}
                                                />

                                                <FormikInput
                                                    wrapperClass="form-group"
                                                    label="Line 3"
                                                    type="text"
                                                    name={`address3`}
                                                    onBlur={handleBlur}
                                                    value={values.address3}
                                                    placeholder="Not provided"
                                                    errors={errors}
                                                    touched={touched}
                                                    onChange={handleChange}
                                                    onKeyUp={() => {this.addressValidation(values)}}
                                                />

                                                {/*<h5>Contact Numbers</h5>*/}

                                                <FormikInput
                                                    wrapperClass="form-group"
                                                    label="City"
                                                    type="text"
                                                    name={`city`}
                                                    onBlur={handleBlur}
                                                    value={values.city}
                                                    placeholder="Not provided"
                                                    errors={errors}
                                                    touched={touched}
                                                    onChange={handleChange}
                                                    onKeyUp={() => {this.addressValidation(values)}}
                                                />

                                                <div className="form-group">
                                                    <div onClick={this.scrollDropdownIntoView}>
                                                        <FormSelect
                                                            title="State"
                                                            fieldName="stateId"
                                                            placeholder="Select one..."
                                                            options={stateOptions}
                                                            setFieldValue={setFieldValue}
                                                            value={values.stateId}
                                                            onBlur={handleBlur}
                                                            errors={errors}
                                                            touched={touched}
                                                        />
                                                    </div>
                                                </div>

                                                <FormikInput
                                                    wrapperClass="form-group"
                                                    label="Zipcode"
                                                    type="text"
                                                    name={`zipcode`}
                                                    onBlur={handleBlur}
                                                    value={values.zipcode}
                                                    placeholder="Not provided"
                                                    errors={errors}
                                                    touched={touched}
                                                    onChange={handleChange}
                                                    onKeyUp={() => {this.addressValidation(values)}}
                                                />

                                                <FormikInput
                                                    wrapperClass="form-group"
                                                    label="Zip+4 code"
                                                    type="text"
                                                    name={`zip4`}
                                                    onBlur={handleBlur}
                                                    value={values.zip4}
                                                    placeholder="Not provided"
                                                    errors={errors}
                                                    touched={touched}
                                                    onChange={handleChange}
                                                    onKeyUp={() => {this.addressValidation(values)}}
                                                />

                                            </div>

                                            {addressValidationLoader ? (
                                                <AccountHolderLoader/>
                                            ) : (
                                                showSuggestedAddress && (
                                                    <Fragment>
                                                        {suggestedAddressError ? (
                                                            <div className="cmv-container-subpanel cmv-container-subpanel-suggested-address">
                                                                <h5>Address Error</h5>
                                                                <p>{suggestedAddressError}</p>{' '}
                                                                <div className="d-flex justify-content-end mt-1">
                                                                    <button
                                                                        onClick={this.closeAddressSuggestion}
                                                                        className="btn btn-outline-primary mr-1 btn-address-keep"
                                                                        type="button"
                                                                    >
                                                                        Keep Entered
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <div className="cmv-container-subpanel cmv-container-subpanel-suggested-address">
                                                                <h5>
                                                                    <i className="fas fa-database"/> Address Match
                                                                </h5>

                                                                <p>The following address was found in our database.</p>
                                                                <address>
                                                                    {suggestedAddress.address1} {suggestedAddress.address2}
                                                                    <br/>
                                                                    {suggestedAddress.city}, {suggestedAddress.state}{' '}
                                                                    {generateFullZip(suggestedAddress)}
                                                                </address>
                                                                <div className="d-flex justify-content-end mt-1">
                                                                    <button
                                                                        onClick={this.closeAddressSuggestion}
                                                                        className="btn btn-outline-primary mr-1 btn-address-keep"
                                                                        type="button"
                                                                    >
                                                                        Keep Entered
                                                                    </button>
                                                                    <button
                                                                        type="button"
                                                                        onClick={this.useSuggestedAddress.bind(this, setFieldValue, stateOptions)}
                                                                        className="btn btn-primary btn-address-use-suggested"
                                                                    >
                                                                        Use Suggested
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </Fragment>
                                                )
                                            )}

                                        </div>
                                        <div className="modal-footer">
                                            <button onClick={this.closeModal} className="btn" type="button">
                                                Cancel
                                            </button>
                                            <button disabled={isSubmitting || !isEmpty(errors)} type="submit" className="btn btn-primary">
                                                Edit Address
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        )}
                    />
                </div>
                <div className="modal-backdrop show" tabIndex="1"/>
            </Fragment>
        );
    }
}

const mapDispatchToProps = {
    hideModal,
    updateContactAddress,
    mailingAddressValidation,
    billingAddressValidation,
};

const mapStateToProps = (state) => {
    const modalProps = getModalData(state).modalProps,
        accountId = getAccountDetails(state).accountDetails.accountInfo.id,
        countryStatesData = getAccountDetails(state).security.countryStates;

    return {
        modalProps,
        accountId,
        countryStatesData
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountMailingAddressModal);
