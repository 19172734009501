import React, { Component } from 'react';
import Orders from './Orders/index';
import Quotes from './Quotes/index';

class OrdersAndQuotes extends Component {

	state = {
		collapsedTab: [ 'orders' ]
	};

	handleCollapse = (type) => {
		if (this.state.collapsedTab.includes(type)) {
			this.setState({
				collapsedTab: this.state.collapsedTab.filter((x) => x !== type)
			});
		}
		else {
			this.setState({
				collapsedTab: [ ...this.state.collapsedTab, type ]
			});
		}
	};

	render() {

		const {
			collapsedTab,
		} = this.state;

		return (
			<div className="tab-pane card-body fade active show">
				<div className="accordion">
					<div className="container-accordion-content container-client-orders">
						<div className={"collapse" + (collapsedTab.includes('orders') ? ' show' : '')}>

							{collapsedTab.includes('orders') &&
								<Orders
									{...this.props}
								/>
							}

						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default OrdersAndQuotes;
