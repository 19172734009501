import axiosInstance from '../utils/axios-instance';
import { actionCreator, createRequestTypes } from '../utils/helpers';

export const GET_INTEGRATION_SEARCH_ADDRESS = createRequestTypes('GET_INTEGRATION_SEARCH_ADDRESS');
export const GET_INTEGRATION_SEARCH_ADDRESS_MIN_CHARS = createRequestTypes('GET_INTEGRATION_SEARCH_ADDRESS_MIN_CHARS');

export const searchAddress = (q) => async (dispatch) => {
	dispatch(actionCreator.request(GET_INTEGRATION_SEARCH_ADDRESS));
	try {
		const params = {q};
		const response = await axiosInstance.get(`/api-integration/address/search`, {params});
		dispatch(actionCreator.success(GET_INTEGRATION_SEARCH_ADDRESS, response.data));
		return response.data;
	} catch (err) {
		let mockError = {
			message: 'Something goes wrong'
		}
		dispatch(actionCreator.failure(GET_INTEGRATION_SEARCH_ADDRESS, mockError));
		return err;
	}
};

export const getMinNumAddrSearchChars = () => async (dispatch) => {
	dispatch(actionCreator.request(GET_INTEGRATION_SEARCH_ADDRESS_MIN_CHARS));
	try {
		const response = await axiosInstance.get(`/api-integration/address/search/min-chars`);
		dispatch(actionCreator.success(GET_INTEGRATION_SEARCH_ADDRESS_MIN_CHARS, response.data));
		return response.data;
	} catch (err) {
		let mockError = {
			message: 'Something goes wrong'
		}
		dispatch(actionCreator.failure(GET_INTEGRATION_SEARCH_ADDRESS_MIN_CHARS, mockError));
		return err;
	}
};