import {actionCreator, createRequestTypes} from "../../utils/helpers";
import axiosInstance from "../../utils/axios-instance";

export const GET_ACCOUNT_CAPITAL_CREDIT = createRequestTypes('GET_ACCOUNT_CAPITAL_CREDIT');
export const UPDATE_ACCOUNT_CAPITAL_CREDIT = createRequestTypes('UPDATE_ACCOUNT_CAPITAL_CREDIT');
export const EMPTY_CAPITAL_CREDIT = 'EMPTY_CAPITAL_CREDIT';
export const GENERATE_MEMBER_NUMBER = createRequestTypes('GENERATE_MEMBER_NUMBER');

export const getAccountCapitalCredit = (accountId) => async (dispatch) => {
    dispatch(actionCreator.request(GET_ACCOUNT_CAPITAL_CREDIT));
    try {
        const response = await axiosInstance.get(`/account/${accountId}/capital-credit`);
        dispatch(actionCreator.success(GET_ACCOUNT_CAPITAL_CREDIT, response.data));
        return response;
    } catch (err) {
        dispatch(actionCreator.failure(GET_ACCOUNT_CAPITAL_CREDIT));
        return err.response;
    }
};

export const updateAccountCapitalCredit = (accountId, capitalCredit) => async (dispatch) => {
    dispatch(actionCreator.request(UPDATE_ACCOUNT_CAPITAL_CREDIT));
    try {
        const response = await axiosInstance.patch(`/account/${accountId}/capital-credit`, capitalCredit);
        dispatch(actionCreator.success(UPDATE_ACCOUNT_CAPITAL_CREDIT, response.data));
        return response;
    } catch (err) {
        dispatch(actionCreator.failure(UPDATE_ACCOUNT_CAPITAL_CREDIT));
        return err.response;
    }
};

export const emptyCapitalCredit = () => {
    return (dispatch) => {
        dispatch({ type: 'EMPTY_CAPITAL_CREDIT' });
    };
};

export const generateMemberNumber = (accountId) => async (dispatch) => {
    dispatch(actionCreator.request(GENERATE_MEMBER_NUMBER));
    try {
        const response = await axiosInstance.post(`/account/${accountId}/capital-credit/member-number/generate`);
        dispatch(actionCreator.success(GENERATE_MEMBER_NUMBER, response.data));
        return response;
    } catch (err) {
        dispatch(actionCreator.failure(GENERATE_MEMBER_NUMBER));
        return err.response;
    }
}