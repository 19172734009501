import {
    SET_FROM_SIGNUP_TO_SURVEY,
    SET_FROM_SURVEY_TO_SIGNUP
} from '../../actions/accountDetailsActions/accountStatusChange.actions';

let initialState = {
    accountSignUpStatus: {},
	accountSurveyStatus: {}
	
}

export const accountStatus = (state = initialState, action) => {
    switch(action.type) {
        case SET_FROM_SIGNUP_TO_SURVEY.SUCCESS:
				return {
					...state,
					accountSignUpStatus: action.payload,
					
				}
				case SET_FROM_SURVEY_TO_SIGNUP.SUCCESS:
					return {
						...state,
						accountSurveyStatus: action.payload,
					
					}
        default: return state
    }

    
}