import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { groupStyles } from '../../../../utils/SelectStyles';
import Button from '../../../../components/Common/Buttons/Button';

const option = [
	{ label: 'Serviceline', value: 'SERVICELINE', placeholder: 'Search account by Serviceline' },
	{ label: 'Account Number', value: 'ACCOUNT NUMBER', placeholder: 'Search account by Account Number' },
	{ label: 'Name', value: 'NAME', placeholder: 'Search account by Name' },
	{ label: 'Address', value: 'ADDRESS', placeholder: 'Search account by Address' },
	{ label: 'Equipment', value: 'EQUIPMENT', placeholder: 'Search account by Equipment' },
	{ label: 'Company', value: 'COMPANY', placeholder: 'Search account by Company' },
	{ label: 'Contact number', value: 'CONTACT NUMBER', placeholder: 'Search account by Contact Number' },
	{ label: 'Email', value: 'EMAIL', placeholder: 'Search account by Email' },
	{ label: 'Account Id', value: 'ACCOUNT ID', placeholder: 'Search account by Account Id' },
	{ label: 'Order Id', value: 'ORDER ID', placeholder: 'Search account by Order Id' },
	{ label: 'Service Order Id', value: 'SERVICE ORDER ID', placeholder: 'Search account by Service Order Id' }
	// { label: 'Username', value: 'USERNAME', placeholder: 'Search account by Company' },
	// { label: 'Date', value: 'CONTACTNUMBER', placeholder: 'Search account by Contact Number' }
];
const SearchBar = ({
	searchAccounts,
	changeSearchType,
	changeTerm,
	searchType,
	term,
	placeholder,
	getCustomersServedHistory,
	history,
	newAccountPermission,
	searchHistoryPermissions,
	isLoading,
}) => {
	return (
		<div className="cmv-container-dashboard-search">
			<div className="cmv-container-dashboard-filter">
				<div className="container">
					<div className="form-row">
						<div className="col-md-3">

							<Select
								id="searchType"
								name="searchType"
								value={searchType}
								onChange={changeSearchType}
								options={option}
								placeholder="Search"
								styles={groupStyles}
								isDisabled={isLoading}
							/>

						</div>
						<div className="col-md-9">
							<div className="input-group">

								<input
									type="text"
									id="term"
									name="term"
									className="form-control"
									placeholder={placeholder}
									onChange={changeTerm}
									value={term}
									autoComplete="off"
									disabled={isLoading}
									onKeyPress={(event) => {
										if (event.key === 'Enter') {searchAccounts()}
									}}
								/>

								<div className="input-group-append">
									{searchHistoryPermissions &&
									<Button
										type="button"
										className="btn btn-primary btn-show-cam-search-results"
										disabled={isLoading}
										onClick={searchAccounts}
									>
										<i className="fas fa-search" />
										<span>&nbsp;Search</span>
									</Button>
									}
								</div>

								<div className="input-group-append">
									<div className="btn-group">

										{searchHistoryPermissions &&
										<Button
											type="button"
											onClick={getCustomersServedHistory}
											className="btn btn-outline-secondary btn-show-cam-search-history"
											disabled={isLoading}
										>
											<i className="fas fa-history" />
											<span>&nbsp;History</span>
										</Button>
										}
										
										{newAccountPermission &&
										<Button
											type="button"
											onClick={() => history.push('/create-account')}
											className="btn btn-outline-secondary btn-new-account-wizard"
										>
											<i className="fas fa-plus" />
											<span>&nbsp;New Account</span>
										</Button>
										}
									
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

SearchBar.propTypes = {
	searchAccounts: PropTypes.func,
	changeSearchType: PropTypes.func,
	changeTerm: PropTypes.func,
	searchType: PropTypes.object,
	term: PropTypes.string,
	placeholder: PropTypes.string,
	getCustomersServedHistory: PropTypes.func,
	history: PropTypes.object,
	newAccountPermission: PropTypes.bool
};

export default SearchBar;
