import axiosInstance from '../utils/axios-instance';
import { actionCreator, createRequestTypes } from '../utils/helpers';

export const CSI_PROVIDER_TYPES = createRequestTypes('CSI_PROVIDER_TYPES');
export const CSI_SERVICE_TYPES = createRequestTypes('CSI_SERVICE_TYPES');
export const CSI_PRODUCT_TYPES = createRequestTypes('CSI_PRODUCT_TYPES');
export const TAXATION_ITEM_TAX_INFO = createRequestTypes('TAXATION_ITEM_TAX_INFO');
export const TAXATION_UPDATE_TAX_INFO = createRequestTypes('TAXATION_UPDATE_TAX_INFO');

// -- BILLING BADJES -- //

export const getCSIProviderTypes = () => async (dispatch) => {
    dispatch(actionCreator.request(CSI_PROVIDER_TYPES));
    try {
        const response = await axiosInstance.get(`/taxation/csi/providerTypes`);
        dispatch(actionCreator.success(CSI_PROVIDER_TYPES, response));
    } catch (err) {
        dispatch(actionCreator.failure(CSI_PROVIDER_TYPES));
    }
};

export const getCSIProductTypes = () => async (dispatch) => {
    dispatch(actionCreator.request(CSI_PRODUCT_TYPES));
    try {
        const response = await axiosInstance.get(`/taxation/csi/productTypes`);
        dispatch(actionCreator.success(CSI_PRODUCT_TYPES, response));
    } catch (err) {
        dispatch(actionCreator.failure(CSI_PRODUCT_TYPES));
    }
};

export const getCSIServiceTypes = () => async (dispatch) => {
    dispatch(actionCreator.request(CSI_SERVICE_TYPES));
    try {
        const response = await axiosInstance.get(`/taxation/csi/serviceTypes`);
        dispatch(actionCreator.success(CSI_SERVICE_TYPES, response));
    } catch (err) {
        dispatch(actionCreator.failure(CSI_SERVICE_TYPES));
    }
};

export const getItemTaxInfo = (id) => async (dispatch) => {
    dispatch(actionCreator.request(TAXATION_ITEM_TAX_INFO));
    try {
        const response = await axiosInstance.get(`taxation/item/${id}/taxInformation`);
        dispatch(actionCreator.success(TAXATION_ITEM_TAX_INFO, response.data));
        return response.data;
    } catch (err) {
        dispatch(actionCreator.failure(TAXATION_ITEM_TAX_INFO));
    }
};

export const updateTaxInfo = (value) => async (dispatch) => {
    dispatch(actionCreator.request(TAXATION_UPDATE_TAX_INFO));
    try {
        const response = await axiosInstance.post(`/taxation/item`, value);
        dispatch(actionCreator.success(TAXATION_UPDATE_TAX_INFO, response.data));
        return response.data;
    } catch (err) {
        dispatch(actionCreator.failure(TAXATION_UPDATE_TAX_INFO));
    }
};
