import * as Yup from 'yup';

export const validationSchema = (activeTab, addressType) => {
	if (activeTab === 0) {
		if(addressType == "manualAddress"){
			return Yup.object().shape({
				fullAddress:Yup.object().shape({
						address1: Yup.string().required('Address Line 1 is Required'),
						city: Yup.string().required('City is Required'),
						zip: Yup.string().required('Zip/Postal is Required')
					})
			});
		}
	} else if (activeTab === 1) {
		return Yup.object().shape({
			plans: Yup.array().min(1, 'Required')
		});
	} else if (activeTab === 2) {
		return Yup.object().shape({
			plans: Yup.array().of(
				Yup.object().shape({
					defaultSource: Yup.string(),
					// servicelines: Yup.array().when('defaultSource', {
					// 	is: (value) => value === 'number_inventory',
					// 	then: Yup.array().of(
					// 		Yup.object().shape({
					// 			number: Yup.string().required('Required'),
					// 			// devices: Yup.array().of(Yup.object()).min(1, 'Required')
					// 		})
					// 	)
					// })
					servicelines: Yup.array().of(
						Yup.object().shape({
							// number: Yup.string().when('defaultSource', {
							//     is: (value) => value === '',
							//     then: Yup.string().required('Required')
							// }),
							number: Yup.string().test('testova', 'Required', function (value) {
								if(typeof value === 'undefined'){
									return true
								}else {
									return value !== ''
								}
							})
						})
					)
				})
			)
		});
	} else if(activeTab === 3){
		return Yup.object().shape({
			plans: Yup.array().of(
				Yup.object().shape({
					servicelines: Yup.array().of(
						Yup.object().shape({
							features: Yup.array().of(
								Yup.object().shape({
									featureVariabledPrice: Yup.number().typeError('Please enter a number ')
								})
							)
						})
					)
				})
			)
		});
	}

	// discounts: Yup.array().when('showDiscounts', {
	//     is: true,
	//     then: Yup.array().of(
	//         Yup.object().shape({
	//             id: Yup.string().required('Required')
	//         })
	//     )
	// }),
};
