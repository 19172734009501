import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { hideModal } from '../../../actions/modal.actions';
import {
    getTroubleTicketsReportedIssues,
    emptyTroubleTicketsReportedIssues,
    createTroubleTicket
} from '../../../actions/accountDetailsActions/troubleTickets.actions';
import {createLoadingSelector, getAccountDetails, getModalData} from '../../../selectors/index';
import FormSelect from "../../../components/UI/FormSelect";
import * as Yup from "yup";
import {Formik} from "formik";
import isEmpty from "../../../utils/helpers";
import DatePicker from "react-datepicker";
import moment from "moment";

const TroubleTicketSchema = Yup.object().shape({
    lines: Yup.array().min(1, 'Required'),
    issue: Yup.string().required('Required'),
    phone: Yup.string().required('Required'),
    date: Yup.string().required('Required').test(
        'date',
        'You can\'t pick a due date in the past',
        (value) => {return !isEmpty(value) ? moment(value).isAfter(moment().subtract(1, "day")) : true}
    ),
    note: Yup.string().required('Required'),
});

class TroubleTicketModal extends Component {

    state = {
        activeServiceModels: [],
        activeServiceModelIcons: [],
        selectedServiceModels: [],
        selectedServiceModelIcons: [],
        selectedServiceLines: [],
        selectedReportedIssues: [],
        alertMessage: ''
    }

    componentDidMount() {
        const { accountInfo, serviceIcons } = this.props;

        const activeServiceModels = [];
        const activeServiceModelIds = [];
        const activeServiceModelIcons = [];

        for (const item of serviceIcons.filter((icon) => icon.hasService === true)) {

            activeServiceModels.push({id: item.id, icon: item.icon});
            activeServiceModelIds.unshift(item.id);

            if (!activeServiceModelIcons.includes(item.icon))
                activeServiceModelIcons.push(item.icon);
        }

        this.setState({
            activeServiceModels: activeServiceModels,
            activeServiceModelIcons: activeServiceModelIcons,
        }, () => {

            if (activeServiceModelIcons.length === 1) {
                this.handleServiceSelect(activeServiceModelIcons[0]);
            }

            this.props.getTroubleTicketsReportedIssues(activeServiceModelIds).then(() => {
                this.updateSelectedReportedIssues();
            });
        });
    }

    componentWillUnmount() {
        this.props.emptyTroubleTicketsReportedIssues();
    }

    closeModal = () => {
        this.props.hideModal();
    };

    updateSelectedServiceLines = () => {
        const {serviceLines} = this.props;

        let updatedSelectedServiceLines = [];

        if (serviceLines.length !== 0) {

            serviceLines.map((service) => {service.serviceLines.map((serviceLine) => {

                // if (isEmpty(this.state.selectedServiceModelIcons) || this.state.selectedServiceModelIcons.includes(service.serviceModelIcon)) {
                if (this.state.selectedServiceModelIcons.includes(service.serviceModelIcon)) {

                    let serviceLineTitle = "Serviceline #"+serviceLine.serviceLineId;

                    if (!isEmpty(serviceLine.number)) serviceLineTitle = serviceLine.number;
                    if (!isEmpty(serviceLine.address)) serviceLineTitle = serviceLine.address;
                    if (!isEmpty(serviceLine.number) && !isEmpty(serviceLine.address)) serviceLineTitle = serviceLine.number+' - '+serviceLine.address;

                    updatedSelectedServiceLines.unshift({
                        value: serviceLine.serviceLineId,
                        label: serviceLineTitle
                    });
                }
            })});

            this.setState({selectedServiceLines: updatedSelectedServiceLines})
        }
    }

    updateSelectedReportedIssues = () => {
        const {reportedIssues} = this.props;

        let updatedSelectedReportedIssues = [];

        if (reportedIssues.length !== 0) {

            reportedIssues.map((reportedIssue) => {

                // if (isEmpty(this.state.selectedServiceModels) || this.state.selectedServiceModels.some(element => element.id === reportedIssue.serviceModelId)) {
                if (this.state.selectedServiceModels.some(element => element.id === reportedIssue.serviceModelId)) {
                    updatedSelectedReportedIssues.unshift({
                        value: reportedIssue.id,
                        label: reportedIssue.description
                    });
                }
            });

            this.setState({selectedReportedIssues: updatedSelectedReportedIssues})
        }
    }

    handleServiceSelect = (activeServiceModelIcon) => {
        let updatedServiceModels = [];
        let updatedServiceModelIcons = this.state.selectedServiceModelIcons;

        // if this icon is selected
        if (this.state.selectedServiceModels.some(element => element.icon === activeServiceModelIcon)) {

            // keep the previously selected models with different icon
            this.state.selectedServiceModels.map((element) => {
                if (element.icon !== activeServiceModelIcon)
                    updatedServiceModels.push(element)
            })
        }
        // if this icon is NOT selected
        else {

            // keep all previously selected models
            updatedServiceModels = this.state.selectedServiceModels;

            // and add all available models with this icon
            this.state.activeServiceModels.some((element) => {
                if (element.icon === activeServiceModelIcon)
                    updatedServiceModels.push(element);
            })
        }

        if (updatedServiceModelIcons.some(element => element === activeServiceModelIcon)) {
            updatedServiceModelIcons.splice(
                updatedServiceModelIcons.findIndex(element => element === activeServiceModelIcon), 1);
        }
        else {
            updatedServiceModelIcons.push(activeServiceModelIcon);
        }

        this.setState({
                selectedServiceModels: updatedServiceModels,
                selectedServiceModelIcons: updatedServiceModelIcons,
            },
            () => {
                this.updateSelectedServiceLines();
                this.updateSelectedReportedIssues();
        })

    }

    scrollDropdownIntoView = (e) => {
        const elementClicked = e.target.nodeName;
        const boundingElement = e.currentTarget;
        const modalBody = document.getElementsByClassName('modal-body')[0];

        if (elementClicked !== 'LABEL') {

            setTimeout(function () {

                // Scroll down if the bottom is hidden...
                if (boundingElement.getBoundingClientRect().bottom > modalBody.getBoundingClientRect().bottom) {

                    // ... and the top won't end up hidden by scrolling down
                    if (boundingElement.getBoundingClientRect().height < modalBody.getBoundingClientRect().height) {

                        // Scroll down till bottom of element reaches bottom of screen
                        boundingElement.scrollIntoView({block: "end"});
                    }
                }
            }, 200);
        }
    }

    formSubmit = (values, actions) => {
     
        this.setState({alertMessage: ''}, () => {
            this.props.createTroubleTicket(values).then((response) => {

                if (!response) {
                    actions.setSubmitting(false);
                    this.setState({alertMessage: 'Unknown error. Please try again later'});
                    return;
                }
                
                if(!response.success) {
                    actions.setSubmitting(false);
                    this.setState({alertMessage: response.error.message});
                    return;
                }
                this.props.hideModal();

                if (this.props.modalProps.onCloseCallback) {
                    this.props.modalProps.onCloseCallback();
                }
            });
        });
    }
    
    render() {

        const {
            alertMessage,
            activeServiceModelIcons,
            selectedServiceModelIcons,
            selectedServiceLines,
            selectedReportedIssues,
        } = this.state;

        const {
            accountInfo,
            reportedIssuesLoader,
        } = this.props;

        return (
            <Fragment>
                <div className="modal" style={{ display: 'block' }} tabIndex="-1" role="dialog">

                    <Formik
                        initialValues={{
                            accountId: accountInfo.id,
                            lines: [],
                            issue: '',
                            phone: '',
                            date: '',
                            note: ''
                        }}
                        validationSchema={TroubleTicketSchema}
                        onSubmit={this.formSubmit}
                        render={({handleChange, handleSubmit, handleBlur, values, errors, touched, isSubmitting, setFieldValue}) => (
                            <form onSubmit={handleSubmit}>

                                <div className="modal-dialog" role="document">
                                    <div className="modal-content">
                                        <div className="modal-header">

                                            <h5 className="modal-title">Add Trouble Ticket</h5>
                                            <button type="button" className="close" onClick={this.closeModal}>
                                                <span aria-hidden="true">&times;</span>
                                            </button>

                                        </div>
                                        <div className="modal-body">
                                        {!isEmpty(alertMessage) && (
                                                <div className="alert alert-inline alert-danger alert-dismissible">
                                                    <p className="mb-0">{alertMessage}</p>
                                                </div>
                                            )} 
                                            <div className="form-group">
                                                <label>Select Service(s)</label>
                                                <div className="d-flex justify-content-around">
                                                    {activeServiceModelIcons.map((activeServiceModelIcon) => (
                                                        <button
                                                            className={"btn btn-icon " +
                                                                (selectedServiceModelIcons.some(element => element === activeServiceModelIcon) ? ' btn-icon-success' : '')
                                                            }
                                                            type="button"
                                                            disabled={reportedIssuesLoader && "disabled"}
                                                            onClick={() => {
                                                                this.handleServiceSelect(activeServiceModelIcon);
                                                                setFieldValue('lines', []);
                                                                setFieldValue('issue', []);
                                                            }}
                                                        >
                                                            <i className={activeServiceModelIcon} />
                                                        </button>
                                                    ))}
                                                </div>
                                            </div>

                                            <div className="form-group position-relative" >
                                                <div onClick={this.scrollDropdownIntoView}>
                                                    <FormSelect
                                                        title="Service Line(s)"
                                                        fieldName="lines"
                                                        placeholder="Select Serviceline"
                                                        setFieldValue={setFieldValue}
                                                        value={values.lines}
                                                        isMulti={true}
                                                        onBlur={handleBlur}
                                                        errors={errors}
                                                        touched={touched}
                                                        options={selectedServiceLines}
                                                    />
                                                </div>
                                            </div>

                                            <div className="form-group position-relative" >
                                                <div onClick={this.scrollDropdownIntoView}>
                                                    <FormSelect
                                                        title="Reported Issue"
                                                        fieldName="issue"
                                                        placeholder="What is the Issue"
                                                        setFieldValue={setFieldValue}
                                                        value={values.issue}
                                                        onBlur={handleBlur}
                                                        errors={errors}
                                                        touched={touched}
                                                        options={selectedReportedIssues}
                                                        isLoading={reportedIssuesLoader}
                                                    />
                                                </div>
                                            </div>

                                            <div className="form-group" >
                                                <label htmlFor="phone">Contact Phone Number</label>
                                                <input
                                                    className={"form-control" + (errors.phone && touched.phone ? ' is-invalid' : '')}
                                                    name="phone"
                                                    id="phone"
                                                    placeholder="Select Contact Number"
                                                    value={values.phone}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                />
                                                {errors.phone && touched.phone &&
                                                <div className="invalid-feedback">{errors.phone}</div>
                                                }
                                            </div>

                                            <div className="form-group" >
                                                <label htmlFor="date">Due Date</label>
                                                <div className="customDatePickerWidth">
                                                    <DatePicker
                                                        className={"form-control text-left" + (touched.date && errors.date ? " is-invalid" : "")}
                                                        fieldName="date"
                                                        dateFormat="MM/dd/yyyy"
                                                        placeholderText="Enter Due Date"
                                                        autoFocus={false}
                                                        showTimeSelect={false}
                                                        shouldCloseOnSelect={true}
                                                        popperPlacement={'top'}
                                                        selected={values.date !== '' ? values.date : null}
                                                        onChange={(date) => setFieldValue('date', new Date(date.getTime()))}
                                                        minDate={moment().toDate()}
                                                    />
                                                </div>
                                                {errors.date && touched.date &&
                                                <div className="invalid-feedback">{errors.date}</div>
                                                }
                                            </div>

                                            <div className="form-group position-relative" >
                                                <label htmlFor="note">Ticket Note</label>
                                                <textarea
                                                    className="form-control"
                                                    id="note"
                                                    name="note"
                                                    value={values.note}
                                                    onChange={handleChange}
                                                    placeholder="What is the Issue"
                                                />
                                                {errors.note && touched.note &&
                                                <div className="invalid-feedback">{errors.note}</div>
                                                }
                                            </div>

                                        </div>
                                        <div className="modal-footer">

                                            <button className="btn btn-primary"
                                                    type="submit"
                                            >
                                                Create
                                            </button>

                                        </div>
                                    </div>
                                </div>

                            </form>
                        )}
                    />

                </div>
                <div className="modal-backdrop show" tabIndex="1" />
            </Fragment>
        );
    }
}
//
// TroubleTicketModal.propTypes = {
//     title: PropTypes.string.isRequired,
//     text: PropTypes.string.isRequired
// };

const getLoaderReportedIssues = createLoadingSelector(['GET_TROUBLE_TICKETS_REPORTED_ISSUES']);

const mapStateToProps = (state) => {

    const accountInfo = getModalData(state).modalProps.accountInfo;
    const serviceIcons = getModalData(state).modalProps.serviceIcons;
    const serviceLines = getModalData(state).modalProps.serviceLines;
    const reportedIssuesLoader = getLoaderReportedIssues(state);
    const reportedIssues = getAccountDetails(state).troubleTickets.troubleTicketsReportedIssues;
    const modalProps = getModalData(state).modalProps;

    return {
        accountInfo,
        serviceIcons,
        serviceLines,
        reportedIssuesLoader,
        reportedIssues,
        modalProps
    };
};

const mapDispatchToProps = {
    hideModal,
    getTroubleTicketsReportedIssues,
    emptyTroubleTicketsReportedIssues,
    createTroubleTicket
};

export default connect(mapStateToProps, mapDispatchToProps)(TroubleTicketModal);
