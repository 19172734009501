import React, {Component} from 'react';
import moment from "moment";
import ReactHtmlParser from 'react-html-parser';
import ServiceWrapper from './ServiceWrapper';

class ServiceOrder extends Component {

	state = {
		showPlanNote: false,
	};

	handleShowHidePlanNote = () => {
		this.setState((prevState) => ({
			showPlanNote: !prevState.showPlanNote
		}));
	};

	render() {

		const {
			showPlanNote,
		} = this.state;

		const {
			serviceInfo,
			removeAll,
			toggleRemoveAll,
			formProps,
			changeServiceLineLoading,
		} = this.props;

		return (
			<div className="plan-wrapper plan-wrapper-expanded">
				<div className="plan-header">
					<span className="plan-title">
						<i className={serviceInfo.serviceModelIcon} /> {serviceInfo.planDescription}
						<p className="plan-subtitle plan-subtitle-aligned">
							<span>
								${serviceInfo.recurringCharge.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}/mo
								{serviceInfo.startDate && " | Started "+moment(serviceInfo.startDate).format('MM/DD/YYYY')}
							</span>
							<br/>
							{serviceInfo.address && serviceInfo.address}
						</p>
					</span>
					<span className="plan-toolbar">
						{serviceInfo.additionalLines + serviceInfo.requiredLines > 1 && (
						<span className="badge badge-outline-secondary">
							{serviceInfo.requiredLines} &#9679;{' '}
							{serviceInfo.additionalLines + serviceInfo.requiredLines} Max
						</span>
						)}
						<span className="badge badge-outline-success">{serviceInfo.planCategory}</span>
						{serviceInfo.planType !== "POSTPAID" &&
						<span className="badge badge-outline-info">{serviceInfo.planType}</span>
						}
						<span>
							<button
								type="button"
								onClick={this.handleShowHidePlanNote}
								className="btn btn-outline-secondary btn-sm btn-plan-details-toggler"
							>
								<i className="fas fa-info" />
							</button>
						</span>
						<span className="h-check ml-3">
							<label>Remove All&nbsp;&nbsp;</label>
							<div className="form-check checkbox-slider checkbox-slider--b-flat">
								<label>
									<input
										type="checkbox"
										name="removeAll"
										checked={removeAll}
										onChange={(e) => this.props.toggleRemoveAll(e.target.checked, formProps)}
										disabled={changeServiceLineLoading}
									/>
									<span>&nbsp;</span>
								</label>
							</div>
						</span>
					</span>
				</div>
				<div className="plan-body">

					<div className={showPlanNote ? 'plan-details' : 'plan-details collapse'}>
						<div className="plan-description">{ReactHtmlParser(serviceInfo.planNote)}</div>
					</div>

					<div className="services-wrapper">

						{serviceInfo.serviceLines.map((serviceLine, index) => (
						<ServiceWrapper
							key={serviceLine.serviceLineId}
							service={serviceLine}
							planId={serviceInfo.planId}
							formProps={formProps}
							toggleLine={this.props.toggleLine}
							serviceLinesCount={serviceInfo.serviceLines.length}
							changeServiceLineLoading={changeServiceLineLoading}
						/>
						))}

					</div>
				</div>
			</div>
		);
	}
}

export default ServiceOrder;
