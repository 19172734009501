import React, { Component } from 'react';
import {connect} from 'react-redux';
import {hideModal} from '../../../actions/modal.actions';
import {getPrimaryContacts} from "../../../actions/admin.actions";
import {sendRegistrationLink, updateUserAccess} from "../../../actions/accountDetailsActions/security.actions";
import {
    createLoadingSelector,
    getAccountDetails,
    getAdmin,
    getModalData,
    getUser,
    getUserPermission
} from '../../../selectors';
import ContactInput from "../../AccountDetails/Panels/RightPanel/Tabs/Security/Inputs/ContactInput";
import {toastr} from "react-redux-toastr";
import isEmpty, {getRefreshToken, getToken} from "../../../utils/helpers";
import Loader from "../../../components/Loader";

class CustomerPortalModal extends Component {

    state = {
        id: this.props.modalProps.primaryContact.userAccess ? this.props.modalProps.primaryContact.userAccess.id : '',
        userAccess: null,
        errors: [],
    }

    componentDidMount() {
        this.props.getPrimaryContacts(this.props.accountId).then(response => {

            if (response && response.data && response.data.contacts && response.data.contacts[0]) {

                if (!isEmpty(response.data.contacts[0].userAccess)) {
                    this.setState({userAccess: response.data.contacts[0].userAccess})
                }
                else {
                    this.props.showModal('SEND_REGISTRATION_LINK_MODAL', {
                        sendRegistrationLink: this.props.sendRegistrationLink
                    })
                }
            }
        });
    }

    handleCloseInput = () => {
        this.setState({
            isEditable: '',
            errors: {},
        });
    };

    handleEditField = (fieldName) => {
        let updatedErrors = this.state.errors;
        updatedErrors[fieldName] = "";

        this.setState({
            isEditable: fieldName,
            errors: updatedErrors,
        });
    };

    handleUpdateUserAccess = (e) => {

        let data = {
            active: e.target.checked
        };

        this.props.updateUserAccess(this.state.id, data).then((response) => {
            if (response.success) {
                toastr.success(response.message, { timeOut: 2000, position: 'top-center' });
                this.closeModal();
            }
            else {
                toastr.error("Failed to update user access", { timeOut: 2000, position: 'top-center' });
            }
        })
    };

    handleImpersonateUser = () => {
        const customerPortalBaseUrl = !isEmpty(this.props.customerPortalBaseUrl) ? this.props.customerPortalBaseUrl.value : '';
        const userId = this.state.id;
        const apiToken = encodeURIComponent(getToken());

        const newWindow = window.open(`${customerPortalBaseUrl}/impersonate/${userId}?apiToken=${apiToken}`, '_blank');

        if (newWindow) newWindow.opener = null;
    }

    closeModal = () => {
        if (this.props.modalProps.onCloseCallback) {
            this.props.modalProps.onCloseCallback();
        }
        this.props.hideModal();
    };

    render() {

        const {
            id,
            userAccess,
        } = this.state;

        const {
            userAccessUpdating,
            primaryContactsLoading,
            primaryContacts,
            canImpersonate,
            customerPortalBaseUrl
        } = this.props;

        return (
            <>
                <div className="modal" style={{display: 'block'}} tabIndex="-1" role="dialog">
                    <div className="modal-dialog">
                        <div className="modal-content">

                            <div className="modal-header">
                                <h5 className="modal-title">Customer Portal Access</h5>
                                <button onClick={this.closeModal} type="button" className="close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>

                            <div className="modal-body">

                                {primaryContactsLoading
                                ?
                                <Loader />
                                :
                                isEmpty(userAccess)
                                ?
                                <span className="form-header-toolbar">
                                    <button
                                        type="button"
                                        className="btn btn-xs btn-outline-secondary btn-cn-edit"
                                        onClick={() => this.props.showModal('SEND_REGISTRATION_LINK_MODAL', {
                                            sendRegistrationLink: this.props.sendRegistrationLink
                                        })}
                                    >
                                        Send Link
                                    </button>
                                </span>
                                :
                                <div className="form-subsection">

                                    <div className="form-group form-row">
                                        <div className="col-sm-5 col-xl-4">Username</div>
                                        <div className="col-sm-7 col-xl-8">{userAccess.username}</div>
                                    </div>

                                    {/*<ContactInput
                                        header="Username"
                                        isEditable={true}
                                        contactId={id}
                                        fieldName="username"
                                        currentObject={userAccess}
                                        handleCloseInput={this.handleCloseInput}
                                        handleEditField={this.handleEditField}
                                        handleUpdate={this.handleUpdateUserAccess}
                                        inputRef={(el) => (this.inputElement = el)}
                                        isDisabled={userAccessUpdating}
                                    />*/}

                                    <div className="form-row mb-1">
                                        <div className="col-sm-5 col-xl-4">Status</div>
                                        <div className="col-sm-7 col-xl-8">
                                            <div className="form-check checkbox-slider checkbox-slider--b-flat float-left">
                                                <label>
                                                    <input
                                                        id="enable-portal-access"
                                                        type="checkbox"
                                                        defaultChecked={userAccess.isActive}
                                                        onClick={(e) => this.handleUpdateUserAccess(e)}
                                                        disabled={userAccessUpdating}
                                                    />
                                                    <span>&nbsp;</span>
                                                </label>
                                            </div>
                                            {canImpersonate && !isEmpty(id) && !isEmpty(customerPortalBaseUrl) &&
                                                <button
                                                    className="btn btn-sm btn-primary float-right"
                                                    onClick={() => this.handleImpersonateUser()}
                                                >
                                                    Impersonate
                                                </button>
                                            }
                                        </div>
                                    </div>

                                    {userAccess.invalidLoginAttempts > 0 &&
                                    <div className="form-row mb-1">
                                        <div className="col-sm-5 col-xl-4">
                                            Failed Logins
                                        </div>
                                        <div className="col-sm-7 col-xl-8">
                                            <label>
                                                <span className={userAccess.invalidLoginAttempts >= 5
                                                    ? "text-danger font-weight-bold"
                                                    : "" }
                                                >
                                                    {userAccess.invalidLoginAttempts} / 5
                                                </span>
                                            </label>
                                        </div>
                                    </div>
                                    }

                                </div>
                                }

                            </div>

                            <div className="modal-footer">
                                <button onClick={this.closeModal} className="btn" type="button">
                                    Close
                                </button>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="modal-backdrop show" tabIndex="1"/>
            </>
        );
    }
}

const userAccessLoader = createLoadingSelector([ 'UPDATE_USER_ACCESS' ]);
const primaryContactsLoader = createLoadingSelector([ 'GET_PRIMARY_CONTACT' ]);

const mapStateToProps = (state) => {

    const modalProps = getModalData(state).modalProps;
    const accountId = getAccountDetails(state).accountDetails.accountInfo.id;
    const userAccessUpdating = userAccessLoader(state);
    const primaryContactsLoading = primaryContactsLoader(state);
    const primaryContacts = getAdmin(state).primaryContacts;
    const canImpersonate = getUserPermission(state, 'CAM', 'CAM_IMPERSONATE_CUSTOMER');
    const customerPortalBaseUrl = getUser(state).configuration.find((x) => x.key === 'portal.baseUrl') || '';

    return {
        modalProps,
        accountId,
        userAccessUpdating,
        primaryContactsLoading,
        primaryContacts,
        canImpersonate,
        customerPortalBaseUrl
    };
};

const mapDispatchToProps = {
    hideModal,
    getPrimaryContacts,
    updateUserAccess,
    sendRegistrationLink,
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerPortalModal);
