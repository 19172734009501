import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';

export class EmailInput extends Component{
	state = {
		emailVerificationSent: false
	};

	emailVerifyClick = () => {
		const {
			currentObject, handleEmailVerify
		} = this.props;

		this.setState({
			emailVerificationSent: true
		}, () => {
			handleEmailVerify(currentObject);
		})
	}

	render() {
		const {
			fieldName,
			isEditable,
			fieldType,
			currentObject,
			contactId,
			handleCloseInput,
			handleEditField,
			handleUpdate,
			inputRef,
			isDisabled,
			fieldError,
		} = this.props;

		return (
			<div className="form-group form-row">
				<div className="col-sm-5 col-xl-4">{fieldType}</div>
				<div className="col-sm-7 col-xl-8">
					{isEditable !== fieldName ? (
						<Fragment>

							{currentObject && (
								<button
									onClick={this.emailVerifyClick}
									type="button"
									className="btn btn-xs btn-verify-value"
									disabled={this.state.emailVerificationSent || currentObject.validated == true}
								>
									<i className={currentObject.validated
										? 'fas fa-check-circle text-success'
										: (this.state.emailVerificationSent
											? 'fas fa-spin fa-circle-notch text-warning'
											: 'fas fa-check text-gray-600')
									} />
								</button>
							)}

							<a
								onClick={() => handleEditField(fieldName)}
								data-type="text"
								className="editable-generic-text"
							>
								{currentObject ? currentObject.email ? currentObject.email : 'Not provided' : 'Not provided'}
							</a>

						</Fragment>
					) : (
						<>
							<div className="input-group input-group-editable">
								<input
									name={fieldName}
									className="form-control"
									defaultValue={currentObject ? currentObject.email : ''}
									ref={inputRef}
									disabled={isDisabled}
									autoComplete='off'
								/>
								<div className="input-group-append">
									{/* <button type="button" className="btn btn-editable-clear">
									<i className="fas fa-backspace" />
								</button> */}
									<button
										type="button"
										onClick={() => handleUpdate(fieldType, fieldName, currentObject, contactId)}
										className="btn btn-primary btn-editable-apply"
										disabled={isDisabled}
									>
										{!isDisabled ? (
											<i className="fas fa-check" />
										) : (
											<i className="fas fa-spin fa-circle-notch" />
										)}
									</button>
									<button
										type="button"
										onClick={handleCloseInput}
										className="btn btn-outline-secondary btn-editable-cancel"
										disabled={isDisabled}
									>
										<i className="fas fa-times" />
									</button>
								</div>
							</div>
							{fieldError &&
							<div className="invalid-feedback">{fieldError}</div>
							}
						</>
					)}
				</div>
			</div>
		);
	}
}

EmailInput.propTypes = {};

export default EmailInput;
