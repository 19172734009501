import axiosInstance from '../../utils/axios-instance';
import {actionCreator, createRequestTypes, getErrorMessage} from '../../utils/helpers';

export const GET_TROUBLE_TICKETS = createRequestTypes('GET_TROUBLE_TICKETS');
export const GET_TROUBLE_TICKETS_SERVICE_ICONS = createRequestTypes('GET_TROUBLE_TICKETS_SERVICE_ICONS');
export const GET_TROUBLE_TICKETS_SERVICE_LINES = createRequestTypes('GET_TROUBLE_TICKETS_SERVICE_LINES');
export const GET_TROUBLE_TICKETS_REPORTED_ISSUES = createRequestTypes('GET_TROUBLE_TICKETS_REPORTED_ISSUES');
export const CREATE_TROUBLE_TICKET = createRequestTypes('CREATE_TROUBLE_TICKET');
export const GET_TROUBLE_TICKET_NOTES = createRequestTypes('GET_TROUBLE_TICKET_NOTES');
export const CREATE_TROUBLE_TICKET_NOTE = createRequestTypes('CREATE_TROUBLE_TICKET_NOTE');
export const GET_TROUBLE_TICKET_ACCOUNT_INFO = createRequestTypes('GET_TROUBLE_TICKET_ACCOUNT_INFO');
export const GET_TROUBLE_TICKET_USER_GROUPS = createRequestTypes('GET_TROUBLE_TICKET_USER_GROUPS');
export const GET_TROUBLE_TICKET_TECHNICIANS = createRequestTypes('GET_TROUBLE_TICKET_TECHNICIANS');
export const GET_TROUBLE_TICKET_TIMESLOTS = createRequestTypes('GET_TROUBLE_TICKET_TIMESLOTS');
export const CREATE_SYSTEM_USER_CAPACITY = createRequestTypes('CREATE_SYSTEM_USER_CAPACITY');
export const DELETE_SYSTEM_USER_CAPACITY = createRequestTypes('DELETE_SYSTEM_USER_CAPACITY');
export const CREATE_SYSTEM_USER_CAPACITIES = createRequestTypes('CREATE_SYSTEM_USER_CAPACITIES');

export const getTroubleTickets = (params) => async (dispatch) => {
    
    dispatch(actionCreator.request(GET_TROUBLE_TICKETS));
    try {
        const response = await axiosInstance.get(`/trouble-tickets`, { params: params });
        dispatch(actionCreator.success(GET_TROUBLE_TICKETS, response.data.troubleTicketUserTasks));
        return response.data.troubleTicketUserTasks;
    } catch (err) {
        dispatch(actionCreator.failure(GET_TROUBLE_TICKETS, getErrorMessage(err)));
        return err.response.data;
    }
};

export const emptyTroubleTickets = () => (dispatch) => {
    dispatch(actionCreator.empty(GET_TROUBLE_TICKETS));
};

export const getTroubleTicketsServiceIcons = (accountId) => async (dispatch) => {
    dispatch(actionCreator.request(GET_TROUBLE_TICKETS_SERVICE_ICONS));
    try {
        const response = await axiosInstance.get(`/account/${accountId}/servicemodels`);
        dispatch(actionCreator.success(GET_TROUBLE_TICKETS_SERVICE_ICONS, response.data.serviceModels));
    } catch (err) {
        dispatch(actionCreator.failure(GET_TROUBLE_TICKETS_SERVICE_ICONS, getErrorMessage(err)));
        return err.response.data;
    }
};

export const emptyTroubleTicketsServiceIcons = () => (dispatch) => {
    dispatch(actionCreator.empty(GET_TROUBLE_TICKETS_SERVICE_ICONS));
};

export const getTroubleTicketsServiceLines = (accountId) => async (dispatch) => {
    dispatch(actionCreator.request(GET_TROUBLE_TICKETS_SERVICE_LINES));
    try {
        const response = await axiosInstance.get(`/account/${accountId}/servicelines?showHistoricalData=true`);
        dispatch(actionCreator.success(GET_TROUBLE_TICKETS_SERVICE_LINES, response.data.services));
        return response.data.services;

    } catch (err) {
        dispatch(actionCreator.failure(GET_TROUBLE_TICKETS_SERVICE_LINES, getErrorMessage(err)));
        return err.response.data;
    }
};

export const emptyTroubleTicketsServiceLines = () => (dispatch) => {
    dispatch(actionCreator.empty(GET_TROUBLE_TICKETS_SERVICE_LINES));
};

export const getTroubleTicketsReportedIssues = (serviceModelIds) => async (dispatch) => {
    dispatch(actionCreator.request(GET_TROUBLE_TICKETS_REPORTED_ISSUES));
    try {
        const response = await axiosInstance.get(`/trouble-ticket/reported-codes?service-model-id=${serviceModelIds}`);
        dispatch(actionCreator.success(GET_TROUBLE_TICKETS_REPORTED_ISSUES, response.data.reportedCodes));
        return response.data.reportedCodes;

    } catch (err) {
        dispatch(actionCreator.failure(GET_TROUBLE_TICKETS_REPORTED_ISSUES, getErrorMessage(err)));
        return err.response.data;
    }
};

export const emptyTroubleTicketsReportedIssues = () => (dispatch) => {
    dispatch(actionCreator.empty(GET_TROUBLE_TICKETS_REPORTED_ISSUES));
};

export const createTroubleTicket = (values) => async (dispatch) => {
    dispatch(actionCreator.request(CREATE_TROUBLE_TICKET));
    try {
        const response = await axiosInstance.post(`/trouble-ticket`, values);
        dispatch(actionCreator.success(CREATE_TROUBLE_TICKET, response.data));
        return response.data;

    } catch (err) {
        dispatch(actionCreator.failure(CREATE_TROUBLE_TICKET, getErrorMessage(err)));
        return err.response.data;
    }
};

export const getTroubleTicketNotes = (troubleTicketId) => async (dispatch) => {
    dispatch(actionCreator.request(GET_TROUBLE_TICKET_NOTES));
    try {
        const response = await axiosInstance.get(`/trouble-ticket/${troubleTicketId}/notes`);
        dispatch(actionCreator.success(GET_TROUBLE_TICKET_NOTES, response.data.troubleTicketNotes));
        return response.data.troubleTicketNotes;

    } catch (err) {
        dispatch(actionCreator.failure(GET_TROUBLE_TICKET_NOTES, getErrorMessage(err)));
        return err.response.data;
    }
};

export const emptyTroubleTicketNotes = () => (dispatch) => {
    dispatch(actionCreator.empty(GET_TROUBLE_TICKET_NOTES));
};

export const createTroubleTicketNote = (values) => async (dispatch) => {
    dispatch(actionCreator.request(CREATE_TROUBLE_TICKET_NOTE));
    try {
        const response = await axiosInstance.post(`/trouble-ticket/note`, values);
        dispatch(actionCreator.success(CREATE_TROUBLE_TICKET_NOTE, response.data));
        return response.data;

    } catch (err) {
        dispatch(actionCreator.failure(CREATE_TROUBLE_TICKET_NOTE, getErrorMessage(err)));
        return err.response.data;
    }
};

export const getTroubleTicketsAccountInfo = (id) => async (dispatch) => {
    dispatch(actionCreator.request(GET_TROUBLE_TICKET_ACCOUNT_INFO));
    try {
        const response = await axiosInstance.get(`/account/${id}`);
        dispatch(actionCreator.success(GET_TROUBLE_TICKET_ACCOUNT_INFO, response.data));
        return response.data;
    } catch (err) {
        dispatch(actionCreator.failure(GET_TROUBLE_TICKET_ACCOUNT_INFO), err.response.data.error);
        return err.response.data;
    }
};

export const getTroubleTicketUserGroups = () => async (dispatch) => {
    dispatch(actionCreator.request(GET_TROUBLE_TICKET_USER_GROUPS));
    try {
        const response = await axiosInstance.get(`/domain/systemusergroups`);
        dispatch(actionCreator.success(GET_TROUBLE_TICKET_USER_GROUPS, response.data.groups));
        return response.data.groups;
    } catch (err) {
        dispatch(actionCreator.failure(GET_TROUBLE_TICKET_USER_GROUPS), err.response.data.error);
        return err.response.data;
    }
};

export const getAllTechniciansByUserGroupName = (groupName, date) => async (dispatch) => {
    dispatch(actionCreator.request(GET_TROUBLE_TICKET_TECHNICIANS));
    try {
        const response = await axiosInstance.get(`/trouble-ticket/appointments/system-user-capacity/${groupName}/${date}`);
        
        dispatch(actionCreator.success(GET_TROUBLE_TICKET_TECHNICIANS, response.data.users));
        return response.data.users;
    } catch (err) {
        dispatch(actionCreator.failure(GET_TROUBLE_TICKET_TECHNICIANS), err.response.data.error);
        return err.response.data;
    }
};

export const getTimeslots = () => async (dispatch) => {
    dispatch(actionCreator.request(GET_TROUBLE_TICKET_TIMESLOTS));
    try {
        const response = await axiosInstance.get(`/trouble-ticket/appointments/timeslots`);
        dispatch(actionCreator.success(GET_TROUBLE_TICKET_TIMESLOTS, response.data.timeslots));
        return response.data.timeslots;
    } catch (err) {
        dispatch(actionCreator.failure(GET_TROUBLE_TICKET_TIMESLOTS), err.response.data.error);
        return err.response.data;
    }
};

export const createSystemUserCapacity = (data) => async (dispatch) => {
    dispatch(actionCreator.request(CREATE_SYSTEM_USER_CAPACITY));
    try {
        const response = await axiosInstance.post(`/trouble-ticket/appointments/system-user-capacity`, data);
        // dispatch(actionCreator.success(CREATE_SYSTEM_USER_CAPACITY, response.data.timeslots));
        return response.data;
    } catch (err) {
        dispatch(actionCreator.failure(CREATE_SYSTEM_USER_CAPACITY), err.response.data.error);
        return err.response.data;
    }
};

export const removeSystemUserCapacity = (data) => async (dispatch) => {
    dispatch(actionCreator.request(DELETE_SYSTEM_USER_CAPACITY));
    try {
        const response = await axiosInstance.post(`/trouble-ticket/appointments/system-user-capacity/remove`, data);
        // dispatch(actionCreator.success(CREATE_SYSTEM_USER_CAPACITY, response.data.timeslots));
        return response.data;
    } catch (err) {
        dispatch(actionCreator.failure(DELETE_SYSTEM_USER_CAPACITY), err.response.data.error);
        return err.response.data;
    }
};

export const createSystemUserCapacities = (data) => async (dispatch) => {
    dispatch(actionCreator.request(CREATE_SYSTEM_USER_CAPACITIES));
    
    try {
        const response = await axiosInstance.post(`/trouble-ticket/appointments/system-user-capacity/enable-all`, data);
        // dispatch(actionCreator.success(CREATE_SYSTEM_USER_CAPACITY, response.data.timeslots));
        return response.data;
    } catch (err) {
        dispatch(actionCreator.failure(CREATE_SYSTEM_USER_CAPACITIES), err.response.data.error);
        return err.response.data;
    }
};