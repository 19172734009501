import React, { Component, Fragment } from 'react';
import {Link} from "react-router-dom";
import {startPendingTasksCount} from "../../../../actions/accountDetailsActions/accountDetails.actions";

class Confirmation extends Component {
	state = {
		startOrderStatus: null,
		isOrderStartedLoading: false,
		tasksCountData : 0
	};

	startCurrentOrder = () => {
		const { updatedServiceInfoData, startOrder } = this.props;

		this.setState(
			{
				isOrderStartedLoading: true
			},
			() =>
				startOrder(updatedServiceInfoData.id).then((response) => {
					if (response.success) {
						this.setState({
							startOrderStatus: true,
							isOrderStartedLoading: false
						});
					startPendingTasksCount(updatedServiceInfoData.id).then((response) => {
						if (response.length > 0) {
							this.setState({
								tasksCountData: response.length
							});
						}
					});
					} else {
						this.setState({
							startOrderStatus: false,
							isOrderStartedLoading: false
						});
					}
				})
		);
	};

	render() {
		const { startOrderStatus, isOrderStartedLoading,tasksCountData } = this.state;
		const { updatedServiceInfoData, handleViewOrder } = this.props;

		return (
			<div className="tab-pane active">
				<p className="text-center text-success">
					<i className="far fa-4x fa-check-circle" />
				</p>
				<p className="text-center text-success lead">
					New order <span className="font-weight-bold">#{updatedServiceInfoData.id}</span> created
					succesfully.
				</p>

				<p className="text-center">
					<button
						className="btn btn-outline-success btn-lg mb-1"
						style={{ margin: '0px 5px' }}
						type="button"
						onClick={handleViewOrder}
					>
						View Order
					</button>
					<button
						onClick={this.startCurrentOrder}
						className={
							startOrderStatus === false ? (
								'btn btn-outline-success btn-lg mb-1 btn-order-view btn-outline-danger'
							) : (
								'btn btn-success btn-lg mb-1'
							)
						}
						type="button"
						disabled={startOrderStatus !== null || isOrderStartedLoading}
					>
						{startOrderStatus === null && !isOrderStartedLoading && 'Execute Order'}
						{startOrderStatus === true && (
							<Fragment>
								<i className="fas fa-check" /> ORDER STARTED SUCCESSFULLY!
							</Fragment>
						)}
						{startOrderStatus === false && (
							<Fragment>
								<i className="fas fa-exclamation-triangle" /> ORDER DID NOT START!
							</Fragment>
						)}
						{startOrderStatus !== null ||
							(isOrderStartedLoading && (
								<Fragment>
									<i className="fas fa-spin fa-circle-notch" /> ORDER EXECUTION IS RUNNING!
								</Fragment>
							))}
					</button>
					{startOrderStatus !== null && tasksCountData > 0 && (
						<Link
							to={{
								pathname: '/task-list',
								search: `?orderId=${updatedServiceInfoData.id}`
							}}
							className="btn btn-warning btn-lg mb-1"
							style={{ margin: '0px 5px' }}
						>
							<i className="fas fa-tools" />&nbsp;{tasksCountData} {tasksCountData > 1 ? 'Tasks' : 'Task'} Pending
						</Link>
					)}
				</p>
			</div>
		);
	}
}

export default Confirmation;
