import React, { Component, Fragment } from 'react';
import IdleTimer from 'react-idle-timer';
import { connect } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';

import { getUser, getGlobalError, getAuthData, getModalData, getErrorMessage } from '../../selectors';
import {
	getUserConfiguration,
	getUserSummary,
	getUserPermission,
	emptyUserPermission,
	emptyUserSummary,
	emptyErrorMessages
} from '../../actions/user.actions';
import { logout } from '../../actions/auth.actions';
import { showModal } from '../../actions/modal.actions';
import { showToastrError500 } from './utils';
import { isEmpty } from '../../utils/helpers';

import PrivateRoute from './PrivateRoute';

import NotFound from '../NotFound/NotFound';

import Loader from '../../components/Loader';

import { routes } from './routeConfig';

class MainLayout extends Component {
	constructor(props) {
		super(props);
		this.state = {
			routes: []
		};
		this.timer = null;
		this.idleTimer = null;
		this.onAction = this._onAction.bind(this);
		this.onActive = this._onActive.bind(this);
		this.onIdle = this._onIdle.bind(this);
	}

	componentDidMount() {
		const { isLoggedIn } = this.props;

		if (isLoggedIn) {
			this.props.getUserPermission();
			this.props.getUserConfiguration();
			this.props.getUserSummary().then((response) => {
				if (response.isTempPassword === true) {
					this.props.showModal('PASSWORD_MODAL');
				}
			});
		} else {
			this.props.history.push({
				pathname: '/login',
				state: { from: this.props.location }
			});
		}
	}

	componentDidUpdate(prevProps) {
		if (prevProps.isLoggedIn !== this.props.isLoggedIn && this.props.isLoggedIn === false) {
			this.props.logout(this.props.history);
		}

		if (this.props.globalError !== prevProps.globalError) {
			showToastrError500(this.props.globalError);
		}
	}

	componentWillUnmount() {
		this.props.emptyUserPermission();
		this.props.emptyUserSummary();
		this.props.emptyErrorMessages();
	}

	_onAction(e) {}

	_onActive(e) {}

	_onIdle(e) {
		this.props.logout(this.props.history);
	}

	render() {
		const {
			userConfiguration,
			userPermission,
			isShowModal,
			user,
			userSummaryError,
			userPermissionError
		} = this.props;

		let idleTime;

		if (!isEmpty(userConfiguration)) {
			let getIdleTime = userConfiguration.find((x) => x.key === 'ui.idle.timeout');
			idleTime = 1000 * 60 * Number(getIdleTime.value);
		}

		if (!isEmpty(userSummaryError) || !isEmpty(userPermissionError)) {
			this.props.logout();
		}

		if (isEmpty(user) || isEmpty(userPermission)) {
			return <Loader />;
		}

		return (
			<div className={isShowModal ? 'cmv-page modal-open' : 'cmv-page'}>
				{localStorage.getItem('stayIn') !== true && (
					<IdleTimer
						ref={(ref) => {
							this.idleTimer = ref;
						}}
						element={document}
						onActive={this.onActive}
						onIdle={this.onIdle}
						onAction={this.onAction}
						debounce={250}
						timeout={idleTime}
						// stopOnIdle={true}
					/>
				)}
				<Switch>
					<Route exact path={`/`} render={() => <Redirect to="/home" />} />
					{routes.map((route) => (
						<PrivateRoute
						key={route.url}
						path={route.url}
						component={route.component}
						layout={route.layout}
						permissions={route.permissions}
						{...this.props}
						/>
					))}
					<Route path="*" component={NotFound} {...this.props} />
				</Switch>
			</div>
		);
	}
}

const getUserSummaryError = getErrorMessage([ 'SET_USER_SUMMARY' ]);
const getUserPermissionError = getErrorMessage([ 'SET_USER_PERMISSION' ]);

const mapStateToProps = (state) => {
	const userConfiguration = getUser(state).configuration,
		userPermission = getUser(state).userPermission,
		user = getUser(state).user,
		isLoggedIn = getAuthData(state).isLoggedIn,
		globalError = getGlobalError(state).error,
		isShowModal = getModalData(state).showModal,
		userSummaryError = getUserSummaryError(state),
		userPermissionError = getUserPermissionError(state)
	return {
		isLoggedIn,
		userPermission,
		userConfiguration,
		globalError,
		isShowModal,
		user,
		userSummaryError,
		userPermissionError
	};
};

const mapDispatchToProps = {
	logout,
	showModal,
	getUserConfiguration,
	getUserSummary,
	getUserPermission,
	emptyUserPermission,
	emptyUserSummary,
	emptyErrorMessages
};

export default connect(mapStateToProps, mapDispatchToProps)(MainLayout);
