import { ACCOUNT_SEARCH } from '../actions/account-search'

let initialState = {
	accounts: []
};

export const accountSearchReducer = (state = initialState, action) => {
	switch (action.type) {
		case ACCOUNT_SEARCH.SUCCESS:
			return {
				...state,
				accounts: action.payload
			};
		case ACCOUNT_SEARCH.EMPTY:
			return {
				...state,
				accounts: []
			};
		default:
			return state;
	}
};
