import React, { Component, Fragment } from 'react';
import { connect } from "react-redux";
import { getModalData } from "../../../../selectors";
import { hideModal } from "../../../../actions/modal.actions";
import { deletePartner } from "../../../../actions/admin.actions";

class DeletePartnerModal extends Component {
    closeModal = () => {
        this.props.hideModal();
    };

    goBack = () => {
        this.props.deletePartner(this.props.modalProps.row.id).then((response) => {
            this.props.hideModal();
            this.props.modalProps.reloadPartners();
        })
    };

    render() {
        return (
            <Fragment>
                <div className="modal" style={{ display: 'block' }} tabIndex="-1" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">CONFIRM DELETING PARTNER</h5>
                                <button type="button" className="close" onClick={this.closeModal}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <p className="lead">Are you sure you want to delete this partner?</p>
                            </div>
                            <div className="modal-footer">
                                <button onClick={this.closeModal} className="btn">
                                    No
                                </button>
                                <button onClick={this.goBack} className="btn btn-primary">
                                    Yes
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-backdrop show" tabIndex="1" />
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    const modalProps = getModalData(state).modalProps;

    return {
        modalProps
    };
};

const mapDispatchToProps = {
    hideModal,
    deletePartner
};

export default connect(mapStateToProps, mapDispatchToProps)(DeletePartnerModal);