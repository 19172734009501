import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { hideModal } from '../../../actions/modal.actions';
import { createPaymentPromises } from '../../../actions/accountDetailsActions/billing.actions';
import { getAccountDetails, getUser, getModalData } from '../../../selectors';
import { Formik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';

const AdjustmentSchema = Yup.object().shape({
	days: Yup.number().typeError('Required').positive('Only positive').required('Required'),
	amount: Yup.number().typeError('Only digits').positive('Only positive amounts').required('Required'),
	note: Yup.string('Must be string').min(10, 'Minimum 10 character').required('Required')
});

class PaymentPromisesModal extends Component {
	state = {
		showAlertMessage: false,
		alertMessage: ''
	};

	componentDidMount() {}

	componentWillUnmount() {}

	formSubmit = (values, actions) => {
	
		this.props.createPaymentPromises(this.props.accountId, values).then((response) => {
			
			if (response.data && response.data.success) {
				this.props.hideModal();
			} else {
				actions.setSubmitting(false);
				this.setState({
					showAlertMessage: true,
					alertMessage: response.data.error && response.data.error.message
				});
			}
		});
	};

	render() {
		const { showAlertMessage, alertMessage } = this.state;
		const { accountBalance, defaultPaymentPromiseDays } = this.props;

		return (
			<Fragment>
				<div className="modal" style={{ display: 'block' }} tabIndex="-1" role="dialog">
					<Formik
						initialValues={{
							days: defaultPaymentPromiseDays || 0,
							amount: accountBalance > 0 ? accountBalance : 0,
							isForRestore: false,
							note: ''
						}}
						enableReinitialize
						validationSchema={AdjustmentSchema}
						onSubmit={this.formSubmit}
						render={({ handleChange, handleSubmit, handleBlur, values, errors, touched, isSubmitting }) => (
							<form onSubmit={handleSubmit}>
								<div className="modal-dialog">
									<div className="modal-content">
										<div className="modal-header">
											<h5 className="modal-title">ADD Payment Promise</h5>
											<button onClick={this.props.hideModal} type="button" className="close">
												<span aria-hidden="true">&times;</span>
											</button>
										</div>
										<div className="modal-body form-horizontal">
											{showAlertMessage === true && (
												<div className="alert alert-inline alert-danger alert-dismissible">
													<p className="mb-0">{alertMessage}</p>
												</div>
											)}

											<div className="form-group">
												<label>Days</label>
												<input
													className={
														errors.note && touched.note ? (
															'form-control is-invalid'
														) : (
															'form-control'
														)
													}
													type="number"
													value={values.days}
													onChange={handleChange}
													onBlur={handleBlur}
													name="days"
													min="0"
												/>
												{errors.days &&
												touched.days && <div className="invalid-feedback">{errors.days}</div>}
												<div className="feedback-container" style={{ position: 'relative' }}>
													<div
														className="text-warning"
														style={{
															position: 'absolute',
															whiteSpace: 'nowrap',
															fontSize: '0.9rem'
														}}
													>
														{values.days !== null &&
															`Promised until ${moment(moment(), 'MM/DD/YYYY')
																.add(values.days, 'days')
																.format('MM/DD/YYYY')}`}
													</div>
												</div>
											</div>

											<div className="form-group">
												<label>Amount</label>
												<div className="input-group">
													<div className="input-group-prepend">
														<div className="input-group-text">$</div>
													</div>
													<input
														className={
															errors.amount && touched.amount ? (
																'form-control is-invalid'
															) : (
																'form-control'
															)
														}
														type="text"
														value={values.amount}
														onChange={handleChange}
														onBlur={handleBlur}
														name="amount"
													/>
													{errors.amount &&
													touched.amount && (
														<div className="invalid-feedback">{errors.amount}</div>
													)}
												</div>
											</div>

											{/*
											-- Commented out cause there is no functionality yet --
											<div className="form-group">
												 <div className="form-group col-6 col-md-4 d-flex align-items-end">
												<div className="form-check checkbox-slider checkbox-slider--b-flat">
													<label>
														<input
															type="checkbox"
															checked={values.isForRestore}
															name="isForRestore"
															onChange={handleChange}
															onBlur={handleBlur}
														/>
														<span>Restore Suspended Servicelines</span>
													</label>
												</div>
												 </div>
											</div>
										*/}

											<div className="form-group">
												<label>Note</label>
												<textarea
													className={
														errors.note && touched.note ? (
															'form-control is-invalid'
														) : (
															'form-control'
														)
													}
													value={values.note}
													onChange={handleChange}
													onBlur={handleBlur}
													name="note"
												/>
												{errors.note &&
												touched.note && <div className="invalid-feedback">{errors.note}</div>}
											</div>
										</div>
										<div className="modal-footer">
											<button onClick={this.props.hideModal} className="btn" type="button">
												Cancel
											</button>
											<button disabled={isSubmitting} type="submit" className="btn btn-primary">
												ADD PAYMENT PROMISE
											</button>
										</div>
									</div>
								</div>
							</form>
						)}
					/>
				</div>
				<div className="modal-backdrop show" tabIndex="1" />
			</Fragment>
		);
	}
}

const mapStateToProps = (state) => {
	const accountId = getAccountDetails(state).accountDetails.accountInfo.id,
		accountBalance = getAccountDetails(state).billing.balance,
		defaultPaymentPromiseDays = getAccountDetails(state).accountDetails.accountInfo.defaultPaymentPromiseDays,
		modalProps = getModalData(state).modalProps;

	return {
		accountId,
		accountBalance,
		defaultPaymentPromiseDays,
		modalProps
	};
};

const mapDispatchToProps = {
	createPaymentPromises,
	hideModal
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentPromisesModal);
