import axiosInstance from '../utils/axios-instance';
import { actionCreator, createRequestTypes } from '../utils/helpers';
export const PRODUCT_PLAN = createRequestTypes('PRODUCT_PLAN');
export const INSTALLMENT_PLAN = createRequestTypes('INSTALLMENT_PLAN');
export const PRODUCT_PLAN_NOTE = createRequestTypes('PRODUCT_PLAN');
export const INSTALLMENT_PLAN_NOTE = createRequestTypes('INSTALLMENT_PLAN_NOTE');
export const PRODUCT_PLAN_FEATURES_PACKAGES = createRequestTypes('PRODUCT_PLAN_FEATURES_PACKAGES');
export const PRODUCT_FEATURE_PACKAGE = createRequestTypes('PRODUCT_FEATURE_PACKAGE');
export const PRODUCT_FEATURE_PACKAGE_NOTE = createRequestTypes('PRODUCT_FEATURE_PACKAGE_NOTE');
export const PRODUCT_FEATURE_PACKAGE_OPTION = createRequestTypes('PRODUCT_FEATURE_PACKAGE_OPTION');
export const PRODUCT_DOWNLOAD_FEATURE_PACKAGE = createRequestTypes('PRODUCT_DOWNLOAD_FEATURE_PACKAGE');
export const PRODUCT_FEATURE_NOTE = createRequestTypes('PRODUCT_FEATURE_NOTE');
export const PRODUCT_FEATURE = createRequestTypes('PRODUCT_FEATURE');
export const PRODUCT_ALL_FEATURES = createRequestTypes('PRODUCT_ALL_FEATURES');
export const PRODUCT_ALL_FEATURE_PACKAGES = createRequestTypes('PRODUCT_ALL_FEATURE_PACKAGES');
export const PRODUCT_FEATURE_TYPES = createRequestTypes('PRODUCT_FEATURE_TYPES');
export const PRODUCT_GENERAL_LEDGERS = createRequestTypes('PRODUCT_GENERAL_LEDGERS');
export const PRODUCT_RATE_CODES = createRequestTypes('PRODUCT_RATE_CODES');
export const PRODUCT_INSTALLMENT_PLAN_NOTE = createRequestTypes('PRODUCT_INSTALLMENT_PLAN_NOTE');
export const PRODUCT_ADJUSTMENT = createRequestTypes('PRODUCT_ADJUSTMENT');
export const PRODUCT_ITEM_NOTE = createRequestTypes('PRODUCT_PLAN');


/** Plan Functions **/

export const getPlan = (planId) => async (dispatch) => {
    dispatch(actionCreator.request(PRODUCT_PLAN));
    try {
        const response = await axiosInstance.get(`product/plan/${planId}`);
        dispatch(actionCreator.success(PRODUCT_PLAN, response.data));
        return response.data;
    } catch (err) {
        let mockError = {
            message: 'Something goes wrong'
        }
        dispatch(actionCreator.failure(PRODUCT_PLAN, mockError));
    }
};

export const addPlan = (planId, plan) => async (dispatch) => {
    dispatch(actionCreator.request(PRODUCT_PLAN));
    try {
        const response = await axiosInstance.post('product/plan', plan);
        dispatch(actionCreator.success(PRODUCT_PLAN, response.data));
        return response;
    } catch (err) {
        let mockError = {
            message: 'Something goes wrong'
        }
        dispatch(actionCreator.failure(PRODUCT_PLAN, mockError));
        return err.response;
    }
};


export const updatePlan = (planId, plan) => async (dispatch) => {
    dispatch(actionCreator.request(PRODUCT_PLAN));
    try {
        const response = await axiosInstance.patch(`product/plan/${planId}`, plan);
        dispatch(actionCreator.success(PRODUCT_PLAN, response.data));
        return response;
    } catch (err) {
        let mockError = {
            message: 'Something goes wrong'
        }
        dispatch(actionCreator.failure(PRODUCT_PLAN, mockError));
        return err.response;
    }
};

export const updatePlanNote = (planId, plan) => async (dispatch) => {
    dispatch(actionCreator.request(PRODUCT_PLAN_NOTE));

    try {
        const response = await axiosInstance.patch(`product/plan/${planId}/note`, plan);
        dispatch(actionCreator.success(PRODUCT_PLAN_NOTE, response.data));
        return response;
    } catch (err) {
        let mockError = {
            message: 'Something goes wrong'
        }
        dispatch(actionCreator.failure(PRODUCT_PLAN_NOTE, mockError));
        return err.response;
    }
};

export const updateItemNote = (itemId, item) => async (dispatch) => {
    dispatch(actionCreator.request(PRODUCT_ITEM_NOTE));
    try {
        const response = await axiosInstance.patch(`product/item/${itemId}/note`, item);
        dispatch(actionCreator.success(PRODUCT_ITEM_NOTE, response.data));
        return response;
    } catch (err) {
        let mockError = {
            message: 'Something goes wrong'
        }
        dispatch(actionCreator.failure(PRODUCT_ITEM_NOTE, mockError));
        return err.response;
    }
};

export const updatePlanFeaturesAndPackages = (planId, planFeaturesAndPackages) => async (dispatch) => {
    dispatch(actionCreator.request(PRODUCT_PLAN_FEATURES_PACKAGES));
    try {
        const response = await axiosInstance.post(`product/plan/${planId}/featuresPackages`, planFeaturesAndPackages);
        dispatch(actionCreator.success(PRODUCT_PLAN_FEATURES_PACKAGES, response.data));
        return response.data;
    } catch (err) {
        let mockError = {
            message: 'Something goes wrong'
        }
        dispatch(actionCreator.failure(PRODUCT_PLAN_FEATURES_PACKAGES, mockError));
    }
};

export const clearPlan = () => async (dispatch) => {
    dispatch(actionCreator.clear(PRODUCT_PLAN))
}

export const clearPlanFeaturesAndPackages = () => async (dispatch) => {
    dispatch(actionCreator.clear(PRODUCT_PLAN_FEATURES_PACKAGES))
}

/**Installmentplan Functions **/
export const getInstallmentPlan = (planId) => async (dispatch) => {
    dispatch(actionCreator.request(INSTALLMENT_PLAN));
    try {
        const response = await axiosInstance.get(`/product/installmentPlan/${planId}`);
        dispatch(actionCreator.success(INSTALLMENT_PLAN, response.data));
        return response.data;
    } catch (err) {
        let mockError = {
            message: 'Something goes wrong'
        }
        dispatch(actionCreator.failure(INSTALLMENT_PLAN, mockError));
    }
};

export const updateInstallmentPlanNote = (installmentPlanId, installmentPlan) => async (dispatch) => {
    dispatch(actionCreator.request(INSTALLMENT_PLAN_NOTE));

    try {
        installmentPlan={...installmentPlan,type:"Installment Plan"};
        const response = await axiosInstance.patch(`product/item/${installmentPlanId}/note`, installmentPlan);
        dispatch(actionCreator.success(INSTALLMENT_PLAN_NOTE, response.data));
        return response;
    } catch (err) {
        let mockError = {
            message: 'Something goes wrong'
        }
        dispatch(actionCreator.failure(INSTALLMENT_PLAN_NOTE, mockError));
        return err.response;
    }
};

export const deleteInstallmentPlan = (id) => async (dispatch) => {
    dispatch(actionCreator.request(INSTALLMENT_PLAN));
    try {
        const response = await axiosInstance.delete(`/product/installmentPlan/${id}`);
        dispatch(actionCreator.success(INSTALLMENT_PLAN, response.data));
        return response.data;
    } catch (err) {
        dispatch(actionCreator.failure(INSTALLMENT_PLAN));
        return err.response.data;
    }
};

export const clearInstallmentPlan = () => async (dispatch) => {
    dispatch(actionCreator.clear(INSTALLMENT_PLAN))
}

/** Adjustment Functions **/

export const addAdjustment = (adjustment) => async (dispatch) => {
    dispatch(actionCreator.request(PRODUCT_ADJUSTMENT));

    try {
        const response = await axiosInstance.post('/product/adjustment', adjustment);
        dispatch(actionCreator.success(PRODUCT_ADJUSTMENT, response.data));
        return response.data;
    } catch (err) {
        let mockError = {
            message: 'Something goes wrong'
        }
        dispatch(actionCreator.failure(PRODUCT_ADJUSTMENT, mockError));
        return err.response;
    }
};

export const updateAdjustment = (adjustment) => async (dispatch) => {
    dispatch(actionCreator.request(PRODUCT_ADJUSTMENT));
    try {
        const response = await axiosInstance.patch('/product/adjustment', adjustment);
        dispatch(actionCreator.success(PRODUCT_ADJUSTMENT, response.data));
        return response.data;
    } catch (err) {
        let mockError = {
            message: 'Something goes wrong'
        }
        dispatch(actionCreator.failure(PRODUCT_ADJUSTMENT, mockError));
        return err.response;
    }
};

export const updatedAdjustmentComment = (value) => async (dispatch) => {
    dispatch(actionCreator.request(PRODUCT_ADJUSTMENT));
    try {
        const response = await axiosInstance.patch('product/adjustment/comment', value);
        dispatch(actionCreator.success(PRODUCT_ADJUSTMENT, response.data));
        return response.data;
    } catch (err) {
        let mockError = {
            message: 'Something goes wrong'
        }
        dispatch(actionCreator.failure(PRODUCT_ADJUSTMENT, mockError));
    }
};

export const getAdjustment = (adjustmentId) => async (dispatch) => {
    dispatch(actionCreator.request(PRODUCT_ADJUSTMENT));
    try {
        const response = await axiosInstance.get(`/product/adjustment/${adjustmentId}`);
        dispatch(actionCreator.success(PRODUCT_ADJUSTMENT, response.data));;
        return response.data;
    } catch (err) {
        let mockError = {
            message: 'Something goes wrong'
        }
        dispatch(actionCreator.failure(PRODUCT_ADJUSTMENT, mockError));
    }
};

export const deleteAdjustment = (adjustmentId) => async (dispatch) => {
    dispatch(actionCreator.request(PRODUCT_ADJUSTMENT));
    try {
        const response = await axiosInstance.delete(`product/adjustment//${adjustmentId}`);
        dispatch(actionCreator.success(PRODUCT_ADJUSTMENT, response.data));
        return response.data;
    } catch (err) {
        dispatch(actionCreator.failure(PRODUCT_ADJUSTMENT));
        return err.response.data;
    }
};


/** Feature Package Functions **/

export const getFeaturePackage = (id) => async (dispatch) => {
    dispatch(actionCreator.request(PRODUCT_FEATURE_PACKAGE));
    try {
        const response = await axiosInstance.get(`product/feature-package/${id}`);
        dispatch(actionCreator.success(PRODUCT_FEATURE_PACKAGE, response.data));
        return response.data;
    } catch (err) {
        let mockError = {
            message: 'Something goes wrong'
        }
        dispatch(actionCreator.failure(PRODUCT_FEATURE_PACKAGE, mockError));
    }
};

export const deleteFeaturePackage = (id) => async (dispatch) => {
    dispatch(actionCreator.request(PRODUCT_FEATURE_PACKAGE));
    try {
        const response = await axiosInstance.delete(`product/feature-package/${id}`);
        dispatch(actionCreator.success(PRODUCT_FEATURE_PACKAGE, response.data));
        return response.data;
    } catch (err) {
        dispatch(actionCreator.failure(PRODUCT_FEATURE_PACKAGE));
       return err.response.data;
    }
};

export const addFeaturePackage = (featurePackage) => async (dispatch) => {
    dispatch(actionCreator.request(PRODUCT_FEATURE_PACKAGE));

    try {
        const response = await axiosInstance.post('/product/feature-package', featurePackage);
        dispatch(actionCreator.success(PRODUCT_FEATURE_PACKAGE, response.data));
        return response.data;
    } catch (err) {
        let mockError = {
            message: 'Something goes wrong'
        }
        dispatch(actionCreator.failure(PRODUCT_FEATURE_PACKAGE, mockError));
        return err.response;
    }
};

export const updateFeaturePackage = (featurePackage) => async (dispatch) => {
    dispatch(actionCreator.request(PRODUCT_FEATURE_PACKAGE));
    try {
        const response = await axiosInstance.patch('/product/feature-package', featurePackage);
        dispatch(actionCreator.success(PRODUCT_FEATURE_PACKAGE, response.data));
        return response.data;
    } catch (err) {
        let mockError = {
            message: 'Something goes wrong'
        }
        dispatch(actionCreator.failure(PRODUCT_FEATURE_PACKAGE, mockError));
        return err.response;
    }
};

export const updateFeaturePackageNote = (value) => async (dispatch) => {
    dispatch(actionCreator.request(PRODUCT_FEATURE_PACKAGE_NOTE));
    try {
        const response = await axiosInstance.patch('product/feature-package/note', value);
        dispatch(actionCreator.success(PRODUCT_FEATURE_PACKAGE_NOTE, response.data));
        return response.data;
    } catch (err) {
        let mockError = {
            message: 'Something goes wrong'
        }
        dispatch(actionCreator.failure(PRODUCT_FEATURE_PACKAGE_NOTE, mockError));
    }
};

export const getFeaturePackageOption = (id) => async (dispatch) => {
    dispatch(actionCreator.request(PRODUCT_FEATURE_PACKAGE_OPTION));
    try {
        const response = await axiosInstance.get(`product/feature-package-option/${id}`);
        dispatch(actionCreator.success(PRODUCT_FEATURE_PACKAGE_OPTION, response.data));
        return response.data;
    } catch (err) {
        let mockError = {
            message: 'Something goes wrong'
        }
        dispatch(actionCreator.failure(PRODUCT_FEATURE_PACKAGE_OPTION, mockError));
    }
};

export const updateFeaturePackageOptionNote = (id) => async (dispatch) => {
    dispatch(actionCreator.request(PRODUCT_FEATURE_PACKAGE_OPTION));
    try {
        const response = await axiosInstance.get(`/feature-package-option/${id}/note`);
        dispatch(actionCreator.success(PRODUCT_FEATURE_PACKAGE_OPTION, response.data));
        return response.data;
    } catch (err) {
        let mockError = {
            message: 'Something goes wrong'
        }
        dispatch(actionCreator.failure(PRODUCT_FEATURE_PACKAGE_OPTION, mockError));
    }
};

export const getAllFeaturePackages = () => async (dispatch) => {
    dispatch(actionCreator.request(PRODUCT_ALL_FEATURE_PACKAGES));

    try {
        const response = await axiosInstance.get('product/feature-packages');
        dispatch(actionCreator.success(PRODUCT_ALL_FEATURE_PACKAGES, response.data));
        return response.data;
    } catch (err) {
        let mockError = {
            message: 'Something goes wrong'
        }
        dispatch(actionCreator.failure(PRODUCT_ALL_FEATURE_PACKAGES, mockError));
    }
}

export const downloadFeaturePackages = (fileType, tableRequest) => async (dispatch) => {
    dispatch(actionCreator.request(PRODUCT_DOWNLOAD_FEATURE_PACKAGE));
    try {
        const response = await axiosInstance.post(`/ui/feature-packages/${fileType}/download`, tableRequest, {
            responseType: 'blob'
        });
        dispatch(actionCreator.success(PRODUCT_DOWNLOAD_FEATURE_PACKAGE, response));
        return response;
    } catch (err) {
        let mockError = {
            message: 'Something goes wrong'
        }
        dispatch(actionCreator.failure(PRODUCT_DOWNLOAD_FEATURE_PACKAGE, mockError));
    }
};

export const clearFeaturePackage = () => async (dispatch) => {
    dispatch(actionCreator.clear(PRODUCT_FEATURE_PACKAGE))

}

/** Feature  Functions **/


export const getAllFeatures = () => async (dispatch) => {
    dispatch(actionCreator.request(PRODUCT_ALL_FEATURES));

    try {
        const response = await axiosInstance.get('product/features');
        dispatch(actionCreator.success(PRODUCT_ALL_FEATURES, response.data));
        return response.data;
    } catch (err) {
        let mockError = {
            message: 'Something goes wrong'
        }
        dispatch(actionCreator.failure(PRODUCT_ALL_FEATURES, mockError));
    }
}

export const getFeature = (id) => async (dispatch) => {
    dispatch(actionCreator.request(PRODUCT_FEATURE));

    try {
        const response = await axiosInstance.get(`/product/feature/${id}`);
        dispatch(actionCreator.success(PRODUCT_FEATURE, response.data));;
        return response.data;
    } catch (err) {
        let mockError = {
            message: 'Something goes wrong'
        }
        dispatch(actionCreator.failure(PRODUCT_FEATURE, mockError));
    }
};

export const addFeature = (feature) => async (dispatch) => {
    dispatch(actionCreator.request(PRODUCT_FEATURE));

    try {
        const response = await axiosInstance.post('/product/feature', feature);
        dispatch(actionCreator.success(PRODUCT_FEATURE, response.data));
        return response.data;
    } catch (err) {
        let mockError = {
            message: 'Something goes wrong'
        }
        dispatch(actionCreator.failure(PRODUCT_FEATURE, mockError));
        return err.response;
    }
};

export const deleteFeature = (id) => async (dispatch) => {
    dispatch(actionCreator.request(PRODUCT_FEATURE_PACKAGE));
    try {
        const response = await axiosInstance.delete(`/product/feature/${id}`);
        dispatch(actionCreator.success(PRODUCT_FEATURE_PACKAGE, response.data));
        return response.data;
    } catch (err) {
        dispatch(actionCreator.failure(PRODUCT_FEATURE_PACKAGE));
        return err.response.data;
    }
};

export const updateFeature = (feature) => async (dispatch) => {
    dispatch(actionCreator.request(PRODUCT_FEATURE));
    try {
        const response = await axiosInstance.patch('/product/feature', feature);
        dispatch(actionCreator.success(PRODUCT_FEATURE, response.data));
        return response.data;
    } catch (err) {
        let mockError = {
            message: 'Something goes wrong'
        }
        dispatch(actionCreator.failure(PRODUCT_FEATURE, mockError));
        return err.response;
    }
};

export const updateFeatureNote = (feature) => async (dispatch) => {
    dispatch(actionCreator.request(PRODUCT_FEATURE_NOTE));

    try {
        const response = await axiosInstance.patch('/product/featureNote', feature);
        dispatch(actionCreator.success(PRODUCT_FEATURE_NOTE, response.data));
        return response;
    } catch (err) {
        let mockError = {
            message: 'Something goes wrong'
        }
        dispatch(actionCreator.failure(PRODUCT_FEATURE_NOTE, mockError));
        return err.response;
    }
};


export const getFeatureTypes = () => async (dispatch) => {
    dispatch(actionCreator.request(PRODUCT_FEATURE_TYPES));
    try {
        const response = await axiosInstance.get(`/product/featureTypes`);
        dispatch(actionCreator.success(PRODUCT_FEATURE_TYPES, response.data));
        return response;
    } catch (err) {
        let mockError = {
            message: 'Something goes wrong'
        }
        dispatch(actionCreator.failure(PRODUCT_FEATURE_TYPES, mockError));
        return err;
    }
};

export const getGeneralLedgers = () => async (dispatch) => {
    dispatch(actionCreator.request(PRODUCT_GENERAL_LEDGERS));
    try {
        const response = await axiosInstance.get(`/product/generalLedgers`);
        dispatch(actionCreator.success(PRODUCT_GENERAL_LEDGERS, response.data));
        return response;
    } catch (err) {
        let mockError = {
            message: 'Something goes wrong'
        }
        dispatch(actionCreator.failure(PRODUCT_GENERAL_LEDGERS, mockError));
        return err;
    }
};
export const getRateCodes = () => async (dispatch) => {
    dispatch(actionCreator.request(PRODUCT_RATE_CODES));
    try {
        const response = await axiosInstance.get(`product/rateCodes`);
        dispatch(actionCreator.success(PRODUCT_RATE_CODES, response.data));
        return response;
    } catch (err) {
        let mockError = {
            message: 'Something goes wrong'
        }
        dispatch(actionCreator.failure(PRODUCT_RATE_CODES, mockError));
        return err;
    }
};

export const clearFeature = () => async (dispatch) => {
    dispatch(actionCreator.clear(PRODUCT_FEATURE))
}
