import { combineReducers } from 'redux';
import { authReducer } from './auth.reducer';
import { dashboardReducer } from './dashboard.reducer';
import { loadingReducer } from './loading.reducer';
import { errorReducer } from './error.reducer';
import { modalReducer } from './modal.reducer';
import { accountSearchReducer } from './accountSearch.reducer';
import { accountDetailsReducer } from './AccountDetailsReducer/index';
import { reducer as toastrReducer } from 'react-redux-toastr';
import { userReducer } from './user.reducer';
import { createAccountReducer } from './createAccount.reducer';
import { globalErrorReducer } from './globalError.reducer';
import { createServiceRequestReducer } from './createServiceRequest.reducer';
import { taskDetailsReducer } from './taskDetails.reducer';
import { tasksListReducer } from './tasksList.reducer';
import { sideBarReducer } from './sideBar.reducer';
import { taskFormReducers } from './taskFormReducers/index';
import { adminReducer } from './admin.reducer';
import { uiControllerReducer } from "./uiController.reducer";
import { viewAsReducer } from './viewAsReducer';
import { domainReducer } from "./domain.reducer";
import { technicianAppointmentReducer } from "./technicianAppointment.reducer";
import { globalFlagsReducer } from "./globalFlags.reducer";
import { provisioningReducer } from "./provisioning.reducer";
import { wizardsReducer } from "../camvio-wizard/reducer";
import { appointmentAreasReducer } from './appointmentAreas.reducer';
import { billingJobsReducer } from "./billingJobs.reducer";
import { autoPayJobsReducer} from "./autoPayJobs.reducer";
import { workflowReducer } from './workflow.reducer';
import { appointmentsReducer } from './appointments.reducer';
import {accountPlanReducer} from "./accountPlan.reducer";
import { paymentProviderReducer } from "./paymentProvider.reducer";
import { productsReducer } from "./products.reducer";
import { taxationReducer } from "./taxation.reducer";

import { AUTH_LOGOUT, AUTH } from '../actions/auth.actions';

const appReducer = combineReducers({
	toastr: toastrReducer,
	auth: authReducer,
	dashboard: dashboardReducer,
	errors: errorReducer,
	loading: loadingReducer,
	modals: modalReducer,
	accountSearch: accountSearchReducer,
	accountDetails: accountDetailsReducer,
	user: userReducer,
	createAccount: createAccountReducer,
	globalError: globalErrorReducer,
	createServiceRequest: createServiceRequestReducer,
	taskDetails: taskDetailsReducer,
	tasksList: tasksListReducer,
	sideBar: sideBarReducer,
	taskForm: taskFormReducers,
	admin: adminReducer,
	uiController: uiControllerReducer,
	products: productsReducer,
	viewAs: viewAsReducer,
	domain: domainReducer,
	technicianAppointment: technicianAppointmentReducer,
	globalFlags: globalFlagsReducer,
	appointment: appointmentAreasReducer,
	workflow: workflowReducer,
	provisioning: provisioningReducer,
	wizards: wizardsReducer,
	billingJobs: billingJobsReducer,
	autoPayJobs: autoPayJobsReducer,
	appointments: appointmentsReducer,
	accountPlan: accountPlanReducer,
	paymentProviders: paymentProviderReducer,
	taxation: taxationReducer
});

export const rootReducer = (state, action) => {
	if (action.type === AUTH_LOGOUT.SUCCESS) {
		state = undefined;
	}

	// This prevent race conditions when user log-out and log-in with other account
	if (action.type === AUTH.SUCCESS) {
		const { auth } = state;
		state = { auth };
	}

	return appReducer(state, action);
};

