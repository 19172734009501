import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { hideModal } from '../../../actions/modal.actions';
import { getFeature, deleteFeature } from "../../../actions/products.action"
import {getAdmin, getModalData, getProducts} from "../../../selectors";
import Loader from "../../../components/Loader";
import {toastr} from "react-redux-toastr";

class DeleteFeatureModal extends Component {
    state = {
        isFeatureLoading: true
    };

    componentDidMount() {
        const {modalProps} = this.props;
        if(modalProps.value) {
            this.props.getFeature(modalProps.value).then((response) =>{
                this.setState({
                    isFeatureLoading: false
                })
            })
        }else{
            this.setState({
                isFeatureLoading: false
            })
        }
    }

    closeModal = () => {
        this.props.hideModal();
    };

    deleteFeature = () => {
        const {modalProps} = this.props;
        this.props.deleteFeature(modalProps.value).then((response) => {
            this.props.hideModal();
            modalProps.reloadFunction(true);
            if(response && response.success){
                toastr.success(response.message, { timeOut: 4000, position: 'top-center' });
            }else{
                toastr.error(response.error.message, { timeOut: 4000, position: 'top-center' });
            }
        })
    };

    render() {
        return (
            <Fragment>
                <div className="modal" style={{ display: 'block' }} tabIndex="-1" role="dialog">
                    {this.state.isFeatureLoading &&
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button onClick={this.props.hideModal} type="button" className="close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <Loader/>
                            <div className="modal-footer">
                            </div>
                        </div>
                    </div>
                    }
                    {!this.state.isFeatureLoading &&
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">CONFIRM DELETING FEATURE</h5>
                                <button type="button" className="close" onClick={this.closeModal}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <p className="lead">You are about to delete the feature</p>
                                <div style={{
                                    backgroundColor: '#e9ecef',
                                    padding: '10px', borderRadius: '5px'
                                }}>
                                    <span><i
                                        className={this.props.feature.serviceModelIcon ?
                                            this.props.feature.serviceModelIcon : ''}/>&nbsp;{this.props.feature.name}</span>
                                    <br/>
                                    <span style={{fontSize: ".75rem"}}>{this.props.feature.description}</span>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button onClick={this.closeModal} className="btn">
                                    CANCEL
                                </button>
                                <button onClick={this.deleteFeature} className="btn btn-danger">
                                    DELETE
                                </button>
                            </div>
                        </div>
                    </div>
                    }
                </div>
                <div className="modal-backdrop show" tabIndex="1" />
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    const modalProps = getModalData(state).modalProps,
        feature = getProducts(state).feature;

    return {
        modalProps,
        feature
    };
};

const mapDispatchToProps = {
    hideModal,
    getFeature,
    deleteFeature
};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteFeatureModal);
