import React from 'react';
import PropTypes from 'prop-types';
import {statusColor} from '../../utils/ui';

const Account = ({ account, history }) => {
	return (
		<div
			className="cmv-container-client"
			onClick={() => history.push(`/account-details/${account.id}`)}
			key={account.id}
		>
			<div className="cmv-client-data-basic">
				<div className="cmv-client">
					<h2 className="cmv-client-name">{account.name}</h2>
					<div className={`cmv-client-status cmv-client-status-${statusColor(account.tierLevel)}`}>
						<span className="status-text">{account.status}</span>
						<div className={`status-bar status-bar-${account.tierLevel * 20}`}>
							<div className="status-bar-separator" />
							<div className="status-bar-separator" />
							<div className="status-bar-separator" />
							<div className="status-bar-separator" />
						</div>
					</div>
				</div>
				<p className="cmv-client-address">{account.billingAddress}</p>
			</div>
			<div className="cmv-client-data-extended">
				<div className="row">
					<div className="col-md">
						<div className="card card-xs">
							<div className="card-header">
								<i className="fas fa-fw fa-hashtag" />
								<span className="d-none d-md-inline-block"> Account #</span>
							</div>
							<div className="card-body">{account.number}</div>
						</div>
					</div>
					<div className="col-md">
						<div className="card card-xs">
							<div className="card-header">
								<i className="fas fa-fw fa-list-alt" />
								<span className="d-none d-md-inline-block"> Account type</span>
							</div>
							<div className="card-body">{account.accountType}</div>
						</div>
					</div>
					<div className="col-md">
						<div className="card card-xs">
							<div className="card-header">
								<i className="fas fa-fw fa-key" />
								<span className="d-none d-md-inline-block"> security</span>
							</div>
							<div className="card-body">{account.securityPhrase}</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

Account.propTypes = {
	account: PropTypes.object,
	history: PropTypes.object
};

export default Account;
