import React, {Component, Fragment} from "react";
import Note from "./Note";
import { getServiceOrderNotes, postServiceOrderNote, deleteServiceOrderNote } from "../../../../../actions/serviceOrdersNotes.actions";
import { getUserPermission } from "../../../../../selectors";
import { connect } from "react-redux";
import {toastr} from "react-redux-toastr";
import Loader from "../../../../../components/Loader";
import isEmpty from "../../../../../utils/helpers";

const extractErrorMessage = (response, defaultError) => {
    if (response.data && response.data.error && response.data.error.message) {
        return response.data.error.message;
    }

    if (response.message) {
        return response.message;
    }

    return defaultError || "Something went wrong";
}

class NoteList extends Component {
    state = {
        noteList: [],
        noteText: '',
        isInputValid: true,
        isFetched: false,
        isNoteAdding: false,
        isNoteDeleting: false
    };

    componentDidMount() {
        this.fetchServiceOrderNotes();
    }

    fetchServiceOrderNotes = () => {
        const { serviceOrderId } = this.props;
        this.props.getServiceOrderNotes(serviceOrderId)
            .then((response) => {
                if (response && response.data) {
                    this.setState({
                        noteList: response.data,
                        isFetched: true
                    });
                } else {
                    this.setState({
                        isFetched: true
                    });
                }
        });
    };

    isOwnNote = ((note) => {
        return this.props.currentUserId === note.createdById
    });

    canDeleteNote = ((note) => {
        return this.props.canDeleteAnyNotes ||
            (this.props.canDeleteOwnNotes && this.isOwnNote(note))
    });

    inputChangeHandler = ((event) => {
       const noteText = event.target.value;
       (!isEmpty(noteText) && !this.state.isInputValid)
           ? this.setState({noteText: noteText, isInputValid: true })
           : this.setState({noteText: noteText })
    })

    addNoteHandler = (() => {
        const noteText = this.state.noteText.trim();
        const { serviceOrderId } = this.props;

        if(isEmpty(noteText)) {
            this.setState({ isInputValid: false });
            return;
        }

        this.setState({isNoteAdding: true});

        this.props.postServiceOrderNote(serviceOrderId, noteText).then((response) => {

            this.setState({isNoteAdding: false});

            if(response.status === 201) {
                toastr.success('Note has been added successfully', { timeOut: 1000, position: 'top-center' });
                const newNote = response.data;
                const noteList = [...this.state.noteList];
                noteList.push(newNote);
                this.setState({noteList: noteList, noteText: ''});
            } else {
                const errorMessage = extractErrorMessage(response, 'Could note add note');
                toastr.error(errorMessage, { timeOut: 1000, position: 'top-center' });
            }
        });
    });

    deleteNoteHandler = ((noteId) => {
        const { serviceOrderId } = this.props;

        this.setState({isNoteDeleting: noteId});

        this.props.deleteServiceOrderNote(serviceOrderId, noteId).then((response) => {

            this.setState({isNoteDeleting: false});

            if (response.status == 200) {
                toastr.success('Note has been deleted successfully', {timeOut: 1000, position: 'top-center'});
                const noteList = [...this.state.noteList]
                const noteIndex = noteList.findIndex(note => {
                    return note.id === noteId
                });
                noteList.splice(noteIndex, 1);
                this.setState({noteList: noteList});
            } else {
                const errorMessage = extractErrorMessage(response, 'Could note delete note');
                toastr.error(errorMessage, {timeOut: 1000, position: 'top-center'});
            }
        });
    });

    render() {

        const { noteList, noteText, isFetched, isInputValid, isNoteAdding, isNoteDeleting } = this.state;
        const { canAddNotes } = this.props;

        const inputClass = isInputValid ? 'form-control' : 'form-control is-invalid';

        const addNote = canAddNotes
            ?   (<div className="form-group mb-o">
                    <div className="input-group input-group-sm">
                        <input type="text"
                               className={inputClass}
                               onChange={this.inputChangeHandler}
                               value={noteText} />
                        <div className="input-group-append">
                            <button className="btn btn-outline-secondary"
                                    onClick={this.addNoteHandler}
                                    disabled={isNoteAdding}
                                    type="button">
                                {isNoteAdding && <i className="fas fa-fw fa-spin fa-spinner"></i>}
                                {" Add Note"}
                            </button>
                        </div>
                        {!isInputValid && (<div className="invalid-feedback">Required</div>) }
                    </div>
                </div>)
            : null;

        return (
            <Fragment>

                {!isFetched &&
                <Loader/>
                }

                <div className="row">
                    <div className="col-12">
                        <div className="mini-notes-wrapper">
                            <ul className="mini-notes mini-notes-h-constrained">

                                {isFetched && isEmpty(noteList) &&
                                <p>No notes found.</p>
                                }

                                {noteList.map(note =>
                                    <Note
                                        key={note.id}
                                        note={note}
                                        isOwnNote={this.isOwnNote(note)}
                                        canDeleteNote={this.canDeleteNote(note)}
                                        deleteNoteHandler={this.deleteNoteHandler.bind(this, note.id)}
                                        isNoteDeleting={isNoteDeleting}
                                    />
                                )}

                            </ul>

                            {addNote}

                        </div>
                    </div>
                </div>

            </Fragment>
        );
    }

}

const mapStateToProps = (state) => {

    const canAddNotes = getUserPermission(state, 'CAM', 'CAM_SERVICEORDER_NOTES_ADD');
    const canDeleteAnyNotes = getUserPermission(state, 'CAM', 'CAM_SERVICEORDER_NOTES_DEL_ANY');
    const canDeleteOwnNotes = getUserPermission(state, 'CAM', 'CAM_SERVICEORDER_NOTES_DEL');
    const currentUserId = state.user.user.id;
    const isNoteDeleting = state.isNoteDeleting;

    return {
        currentUserId,
        canAddNotes,
        canDeleteAnyNotes,
        canDeleteOwnNotes,
        isNoteDeleting
    };
};

const mapDispatchToProps = {
    getServiceOrderNotes,
    postServiceOrderNote,
    deleteServiceOrderNote
};

export default connect(mapStateToProps, mapDispatchToProps)(NoteList);
