import React, { Component, Fragment } from 'react';
import { hideModal } from '../../../actions/modal.actions';
import { changeAccountStatus } from '../../../actions/accountDetailsActions/accountStatusChange.actions';
import { connect } from 'react-redux';
import {createLoadingSelector, getModalData} from '../../../selectors';

class AccountStatusChangeModal extends Component {

    closeModal = () => {
        this.props.hideModal()
    }

    onAccountStatusChangeClickHandler = () => {
        const { accountInfo, statusTransition, onAccountStatusChange } = this.props.modalProps;

        this.props.changeAccountStatus(accountInfo.id, statusTransition.toStatus).then(response => {
            if (response.data && response.data.success) {
                onAccountStatusChange();
                this.closeModal();
            }
        })
    }

    render() {
        const { statusTransition } = this.props.modalProps;
        const { changeAccountStatusLoader } = this.props;

        return (
            <Fragment>
                <div className="modal" style={{ display: 'block' }} tabIndex="-1" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">
                                    {statusTransition.name} ACCOUNT
								</h5>
                            </div>
                            <div className="modal-body form-horizontal">
                                You are about to update the account status from {statusTransition.fromStatus} to {statusTransition.toStatus}
                            </div>
                            <div className="modal-footer">
                                <button onClick={this.closeModal} className="btn">
                                    CANCEL
								</button>
                                <button
                                    onClick={this.onAccountStatusChangeClickHandler}
                                    type="submit"
                                    className="btn btn-primary">
                                    CONTINUE
                                    {changeAccountStatusLoader &&
                                        <i className="fas fa-fw fa-spin fa-spinner" style={{marginLeft: '5px'}}/>}
								</button>

                            </div>
                        </div>
                    </div>
				</div>
                <div className="modal-backdrop show" tabIndex="1" />
            </Fragment>
        )
    }

}

const changeAccountStatusLoaderSelector = createLoadingSelector(['CHANGE_ACCOUNT_STATUS']);

const mapStateToProps = (state) => {
    const modalProps = getModalData(state).modalProps;
    const changeAccountStatusLoader = changeAccountStatusLoaderSelector(state);
    return {
        modalProps,
        changeAccountStatusLoader
    }
};

const mapDispatchToProps = {
    changeAccountStatus,
    hideModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountStatusChangeModal);