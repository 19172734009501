import React, { Component } from 'react';
import { connect } from 'react-redux';
import Schedule from "./Schedule";
import {hideModal, showModal} from '../../actions/modal.actions';

class ScheduleCapacity extends Component {

    componentDidMount() {
        document.title = 'Capacity Configuration - camvio.cloud';
        this.props.changeNavBarType('default', 'Capacity Configuration');
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isShowAccessModal !== this.props.isShowAccessModal && this.props.isShowAccessModal === true) {
        }
    }

    componentWillUnmount() {
        this.props.hideModal();
    }



    render() {
        const { history, isShowAccessModal } = this.props;

        if (
            isShowAccessModal === false &&
            history.location.state &&
            history.location.state.serviceOrderData
        ) {
            return null;
        }

        return (
            <div className="cmv-container cmv-container-dashboard cmv-container-client-selected">
                <Schedule/>
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {
    };
};

const mapDispatchToProps = {
    showModal,
    hideModal
};

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleCapacity);
