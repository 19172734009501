import React, {Component, Fragment} from 'react';
import {Search} from 'react-bootstrap-table2-toolkit';
import {connect} from 'react-redux';
import {showModal} from '../../actions/modal.actions';
import Loader from '../../components/Loader';
import {isEmpty} from '../../utils/helpers';
import {getSystemUsers, clearSystemUsers, getSystemUserRoles} from '../../actions/admin.actions'
import { downloadSystemUsers, getSystemUsersUi } from '../../actions/uiController.actions'
import {createLoadingSelector, getAdmin, getUser, getUiController} from "../../selectors";
import CamvioTable from "../../components/UI/CamvioTable/CamvioTable";
import Select from "react-select";
import {groupStyles} from "../../utils/SelectStyles";
import {debounce} from "lodash";
import Button from "../../components/Common/Buttons/Button";

class SystemUsers extends Component {

    state = {
        loadingSearch: true,
        isLoading: false,
        isDownloading: false,
        searchTerm: '',
        oldSearchTerm: '',
        showDownloadDropdown: false,
        tableRequest: {
            filterList: [],
            pagination: {
                limit: 20,
                offset: 0
            },
            sorting: {
                columnName: "su.id",
                sortParam: "asc"
            }
        },
        resultsNumber: 20,
        systemUsersList: [],
        sortColumn: '',
        sortDirection: '',
        noMoreResults: true,
        showAdditionalSearchOptions: false,
        systemUserRoleFilter: [{name: "sur.name", value: "All", label: "All Roles"}],
    };

    componentDidMount() {
        this.reloadSystemUsers();
        this.props.getSystemUserRoles();
    }

    componentWillUnmount() {
        this.props.clearSystemUsers();
    }

    toggleFilter = () => {
        this.setState({showAdditionalSearchOptions: !this.state.showAdditionalSearchOptions});
    }

    setSystemUserRoleSearch = (value) => {
        this.setState({
            systemUserRoleFilter: [{
                name: "sur.name",
                value: value.value,
                label: value.label
            }]
        })
        this.debounceHandleInputChange(value);
    };

    searchSystemUsers = () => {
        let tableRequest = this.state.tableRequest;
        let pagination = tableRequest.pagination;
        pagination.offset = 0;

        this.setState({
            tableRequest: tableRequest,
            systemUsersList: [],
            loadingSearch: true
        });
        this.reloadSystemUsers();
    }

    reloadSystemUsers = (clearList) => {

        if (clearList) {
            let pagination = this.state.tableRequest.pagination;
            pagination.limit += pagination.offset;
            pagination.offset = 0;
            this.setState({
                systemUsersList: [],
            })
        }

        this.setState({isLoading: true, loadingSearch: true}, () => {
            this.props.getSystemUsersUi(this.state.tableRequest).then(response => {
                if(response.rows && !isEmpty(response.rows)){
                    response.rows.map(systemUsers => {
                        this.state.systemUsersList.push(systemUsers);
                    })
                    this.checkResultsLength();
                }
                this.setState({isLoading: false, loadingSearch: false});
            });
        });
    }

    toggleDownloadDropdown = () => {
        this.setState({showDownloadDropdown: !this.state.showDownloadDropdown});
    }

    sortSystemUsers = (name, order) => {
        let tableRequest = this.state.tableRequest;
        let sorting = tableRequest.sorting;
        let sortDirection = order;
        if (this.state.sortDirection != order) {
            sorting.columnName = name;
            sorting.sortParam = order;
        }else{
            sorting.columnName = name;
            if(sorting.sortParam == "asc"){
                sorting.sortParam = "desc";
                sortDirection = "desc"
            }else{
                sorting.sortParam = "asc";
                sortDirection = "asc"
            }
        }

        //Reset pagination
        let pagination = tableRequest.pagination;
        pagination.limit += pagination.offset;
        pagination.offset = 0;

        this.setState({
            tableRequest: tableRequest,
            loadingSearch: true,
            featurePackageList: [],
            sortColumn: name,
            sortDirection: sortDirection
        });
        this.reloadSystemUsers(true);
    }

    showAdditional = () => {
        let tableRequest = this.state.tableRequest;
        let pagination = tableRequest.pagination;
        pagination.offset = pagination.limit + pagination.offset;
        pagination.limit = parseInt(this.state.resultsNumber);

        this.setState({tableRequest: tableRequest});
        this.reloadSystemUsers();
    }

    checkResultsLength = () => {
        if (this.state.systemUsersList && this.state.systemUsersList.length < this.props.systemUsersSize) {
            this.setState({
                noMoreResults: false
            })
        } else {
            this.setState({
                noMoreResults: true
            })
        }
    }

    handleResultNumberChange = (e) => {
        let {value} = e.target;
        this.setState({
            resultsNumber: value,
        });
    };

    debounceHandleInputChange = event => {
        let name = event.name || event.target.name;
        let value = event.value || event.target.value;
        this.handleInputChange(name,value);
    }

    handleInputChange = debounce((fieldName, value) => {
        let tableRequest = this.state.tableRequest;
        let filterList = tableRequest.filterList;
        let containsFilter = true;

        if(filterList.length == 0 && value.replace(/\s/g, '').length != 0){
            if(value != "All") {
                filterList.push({
                    columnName: fieldName,
                    value: value
                })
            }
        }else{
            for(let index = 0; index < filterList.length; index++) {
                if (filterList[index].columnName == fieldName) {
                    containsFilter = true;
                    if(value.length == 0 || value == "All"){
                        filterList.splice(index, 1);
                    }else{
                        filterList[index].value = value;
                    }
                    break;
                }else{
                    containsFilter = false;
                }
            }
        }
        if(!containsFilter){
            filterList.push({
                columnName: fieldName,
                value: value
            })
        }
        tableRequest.filterList = filterList;

        this.setState({tableRequest: tableRequest});

    });

    getUserRoles = () => {
        let userRoles = [{
            name: "sur.name",
            value: "All",
            label: "All"
        }]

        this.props.systemUserRoles && this.props.systemUserRoles.map((role) => {
            userRoles.push({
                name: "sur.name",
                value: role.name,
                label: role.description
            });
        });
        return userRoles;
    }


    downloadTable = (value) => {
        let tableRequest = this.state.tableRequest;
        tableRequest.pagination = {};

        this.setState({
            tableRequest: tableRequest,
            isDownloading: true
        });

        this.props.downloadSystemUsers(value, this.state.tableRequest).then((response) => {
            if (response && response.status === 201 && response.data) {
                let name = "";
                let link = document.createElement('a');
                let fileUrl = URL.createObjectURL(new Blob([response.data]));
                link.setAttribute('href', fileUrl);
                if(response.headers["content-disposition"] != undefined) {
                    name = response.headers["content-disposition"].split("=")[1];
                }else{
                    let today = new Date()
                    name = "system_users-" +  today.toISOString().split('T')[0] + "." + value;
                }
                link.setAttribute('download',name);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                this.setState({
                    isDownloading: false,
                    showDownloadDropdown: false
                });
            }
        });
    };

    render() {

        const {
            resultsNumber,
            searchTerm,
            showDownloadDropdown,
            systemUsersList,
            isLoading,
            sortColumn,
            sortDirection,
            isDownloading,
            showAdditionalSearchOptions
        } = this.state;

        const {
            systemUsers,
            systemUsersLoader,
            canAdd,
            canEdit,
            canDelete,
            canManageTokens,
            systemUsersUiHeaders,
            systemUserRoles,
            systemUsersUiRows,
        } = this.props;


        return (
            <Fragment>
                <div className="tab-pane camvio-table-search">
                    <div className="cmv-container-dashboard-search">
                        <div className="cmv-container-dashboard-filter">
                            <div className="container">
                                <div className="form-row">
                                    <div className="col-md-12">
                                        <div className="input-group">
                                            <input
                                                placeholder={"Name, Username, or Email..."}
                                                type="text"
                                                className="form-control"
                                                autoComplete="off"
                                                id="search"
                                                name="search"
                                                onChange={this.debounceHandleInputChange}
                                                onKeyPress={(event) => {
                                                    if (event.key === 'Enter') {
                                                        this.searchSystemUsers()
                                                    }
                                                }}
                                            />
                                            <div className="input-group-append">
                                                <Button
                                                    type="button"
                                                    className="btn btn-primary btn-show-cam-search-results"
                                                    disabled={this.state.loadingSearch || isDownloading}
                                                    onClick={() => {
                                                        this.searchSystemUsers();
                                                    }}
                                                >
                                                    <i className="fas fa-search"/>
                                                    <span>&nbsp;Search</span>
                                                </Button>
                                            </div>

                                            <div className="input-group-append">
                                                <div className="btn-group">
                                                    <Button
                                                        type="button"
                                                        onClick={this.toggleFilter}
                                                        className="btn btn-outline-secondary"
                                                    >
                                                        {showAdditionalSearchOptions ?
                                                            <i className="fas fa-solid fa-ban"/>
                                                            :
                                                            <i className="fas fa-solid fa-filter"/>
                                                        }
                                                        <span>&nbsp;Filters</span>
                                                    </Button>

                                                    <Button
                                                        type="button"
                                                        className="btn btn-outline-secondary btn-new-account-wizard"
                                                        onClick={() => this.props.showModal('ADD_SYSTEM_USER_MODAL', {
                                                            type: 'create',
                                                            reloadFunction: this.reloadSystemUsers(true)
                                                        })}
                                                        disabled={systemUsersLoader || isDownloading}
                                                    >
                                                        <i className="fas fa-plus"/>
                                                        <span>&nbsp;New User</span>
                                                    </Button>
                                                    <div className="dropdown">
                                                        <button
                                                            type="button"
                                                            className="btn btn-outline-secondary dropdown-toggle"
                                                            type="button"
                                                            onClick={this.toggleDownloadDropdown}
                                                            disabled={this.state.loadingSearch || isDownloading}
                                                        >
                                                            <i className="fas fa-download"/>
                                                            <span
                                                                className="camvio-table-search-download">&nbsp;Download</span>
                                                        </button>
                                                        <div
                                                            className={"dropdown-menu dropdown-menu-right" + (showDownloadDropdown && !isDownloading ? " show" : "")}>
                                                            <button
                                                                type="button"
                                                                value={"XLS"}
                                                                className="dropdown-item"
                                                                onClick={(e) => {
                                                                    this.downloadTable("XLS")
                                                                }}
                                                            >
                                                                As XLS
                                                            </button>
                                                            <button
                                                                type="button"
                                                                className="dropdown-item"
                                                                onClick={(e) => {
                                                                    this.downloadTable("CSV")
                                                                }}
                                                            >
                                                                As CSV
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {showAdditionalSearchOptions &&
                        <div className="cmv-container-dashboard-filter">
                            <div className="container">
                                <div className="camvioTable-plan-extra-filters">
                                    <div className="search">
                                        <Select
                                            className="extra-filter-select"
                                            id="userRole"
                                            name="sur.name"
                                            onChange={this.setSystemUserRoleSearch}
                                            styles={groupStyles}
                                            options={this.getUserRoles()}
                                            value={this.state.systemUserRoleFilter}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        }
                    </div>
                    {(this.state.loadingSearch || isDownloading) &&
                    <Loader />
                    }

                    {!systemUsersLoader && isEmpty(systemUsersList) &&
                    <div className="container">
                        <p>No data found</p>
                    </div>
                    }
                    {!isEmpty(systemUsersList) && !this.state.loadingSearch && !isDownloading &&
                    <CamvioTable
                        headers={systemUsersUiHeaders}
                        rows={systemUsersList}
                        loader={isLoading}
                        reloadFunction={this.reloadSystemUsers}
                        sortFunction={this.sortSystemUsers}
                        canEdit={canEdit}
                        canDelete={canDelete}
                        canManageTokens={canManageTokens}
                        showModal={this.props.showModal}
                        sortColumn={sortColumn}
                        sortDirection={sortDirection}
                    />
                    }
                </div>
                {!this.state.noMoreResults &&
                <div className="row" style={{marginTop: '2%'}}>
                    <div className="col-sm-12">
                        <div className="search" style={{float: "right"}}>
                            <button
                                className="btn btn-primary"
                                disabled={systemUsersLoader || this.state.noMoreResults || isDownloading}
                                onClick={this.showAdditional}
                            >
                                Show Additional
                            </button>
                            &nbsp;
                            &nbsp;
                            <select
                                className="custom-select"
                                value={resultsNumber}
                                onChange={this.handleResultNumberChange}
                                disabled={systemUsersLoader || this.state.noMoreResults || isDownloading}
                            >
                                <option label="20" value="20"/>
                                <option label="30" value="30"/>
                                <option label="40" value="40"/>
                                <option label="50" value="50"/>
                            </select>
                        </div>
                    </div>
                </div>
                }
            </Fragment>
        );
    }
}

const loader = createLoadingSelector(['GET_SYSTEM_USERS_UI']);

const mapStateToProps = (state) => {
    const systemUsersUiHeaders = getUiController(state).systemUsersUi.headers,
        systemUsersUiRows = getUiController(state).systemUsersUi.rows,
        systemUsersSize = getUiController(state).systemUsersUi.size,
        systemUserRoles = getAdmin(state).systemUserRoles,
        systemUsersLoader = loader(state),
        userPermission = getUser(state).userPermission;

    const settingsPermissions =
        userPermission.find((x) => x.name === 'ADMN') &&
        userPermission.find((x) => x.name === 'ADMN').permissions;
    const canEdit = settingsPermissions && settingsPermissions.includes("ADMN_USER_EDIT");
    const canDelete = settingsPermissions && settingsPermissions.includes("ADMN_USER_DEL");
    const canAdd = settingsPermissions && settingsPermissions.includes("ADMN_USER_ADD");
    const canManageTokens = settingsPermissions && settingsPermissions.includes("CREATE_API_TOKEN");
    const canViewPartners = settingsPermissions && settingsPermissions.includes("ADMN_PARTNER_DETAIL");

    // Add new actions to the IF statement in render(), enabling the "ACTIONS" table column for users with rights
    return {
        systemUsersUiHeaders,
        systemUsersUiRows,
        systemUsersSize,
        systemUserRoles,
        systemUsersLoader,
        canEdit,
        canDelete,
        canAdd,
        canManageTokens,
        canViewPartners
    };
};

const mapDispatchToProps = {
    getSystemUsers,
    getSystemUsersUi,
    getSystemUserRoles,
    downloadSystemUsers,
    showModal,
    clearSystemUsers,
};

export default connect(mapStateToProps, mapDispatchToProps)(SystemUsers);
