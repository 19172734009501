import {
	GET_ACTIVE_SERVICE_AGREEMENTS,
	GET_SERVICE_AGREEMENT, GET_SERVICE_AGREEMENT_FOR_SERVICE_LINE
} from '../../actions/taskFormActions/serviceAgreement.actions';

const initialState = {
	serviceAgreements: [],
	servideAgreement: null,
	serviceAgreementForServiceLine: {}
};

export const serviceAgreementReducer = (state = initialState, action) => {
	switch (action.type) {
		case GET_ACTIVE_SERVICE_AGREEMENTS.SUCCESS:
			return {
				...state,
				serviceAgreements: action.payload
			};
		case GET_SERVICE_AGREEMENT.SUCCESS:
			return {
				...state,
				serviceAgreement: action.payload
			};
		case GET_SERVICE_AGREEMENT_FOR_SERVICE_LINE.SUCCESS:
			return {
				...state,
				serviceAgreementForServiceLine: {
					...state.serviceAgreementForServiceLine,
						[action.payload.serviceLineId]: action.payload.serviceAgreement
				}
			};
		case GET_SERVICE_AGREEMENT_FOR_SERVICE_LINE.CLEAR:
			return {
				...state,
				serviceAgreementForServiceLine: {
					...state.serviceAgreementForServiceLine,
						[action.payload.serviceLineId]: null
				}
			};
		case GET_SERVICE_AGREEMENT.CLEAR:
			return {
				...state,
				serviceAgreement: null
			};
		default:
			return state;
	}
};
