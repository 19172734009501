import {
	GET_SYSTEM_USER,
	GET_SYSTEM_USERS,
	GET_SYSTEM_LOCATIONS,
	GET_SYSTEM_USER_ROLES,
	GET_SYSTEM_USER_GROUPS,
	GET_SYSTEM_USER_ACCESS_TOKENS,
	GET_PARTNERS,
	GENERATE_TOKEN,
	GET_PRIMARY_CONTACT,
	GET_ACCOUNT_DISTRIBUTION_METHOD,
	GET_PROVIDERS,
	GET_GENERAL_LEDGERS,
	GET_GENERAL_LEDGER_SUB_ACCOUNTS
} from '../actions/admin.actions';

let initialState = {
	systemUsers: [],
	systemUsersUi: [],
	systemLocations: [],
	systemUserRoles: [],
	systemUserGroups: [],
	systemUserAccessTokens: [],
	partners: [],
	generatedToken: null,
	primaryContacts: null,
	distributionMethod: null,
	autoPayJobsUi: [],
	providers: [],
	generalLedgers: [],
	generalLedgerSubAccounts: []
};

export const adminReducer = (state = initialState, action) => {
	switch (action.type) {
		case GET_SYSTEM_USER.SUCCESS:
			return {
				...state,
				systemUser: action.payload
			}
		case GET_SYSTEM_USER.CLEAR:
			return {
				...state,
				systemUser: undefined
			}
		case GET_SYSTEM_USERS.SUCCESS:
			return {
				...state,
				systemUsers: action.payload
			}
		case GET_SYSTEM_USERS.CLEAR:
			return {
				...state,
				systemUsers: []
			}
		case GET_SYSTEM_LOCATIONS.SUCCESS:
			return {
				...state,
				systemLocations: action.payload
			}
		case GET_SYSTEM_USER_ROLES.SUCCESS:
			return {
				...state,
				systemUserRoles: action.payload
			}
		case GET_SYSTEM_USER_GROUPS.SUCCESS:
			return {
				...state,
				systemUserGroups: action.payload
			}
		case GET_SYSTEM_USER_ACCESS_TOKENS.SUCCESS:
			return {
				...state,
				systemUserAccessTokens: action.payload
			}
		case GET_SYSTEM_USER_ACCESS_TOKENS.CLEAR:
			return {
				...state,
				systemUserAccessTokens: []
			}

			// Partners
		case GET_PARTNERS.SUCCESS:
			return {
				...state,
				partners: action.payload
			}
		case GET_PARTNERS.CLEAR:
			return {
				...state,
				partners: []
			}

			// Generate token
		case GENERATE_TOKEN.SUCCESS:
			return {
				...state,
				generatedToken: action.payload.token
			}
		case GENERATE_TOKEN.CLEAR:
			return {
				...state,
				generatedToken: null
			}

			// Primary contact
		case GET_PRIMARY_CONTACT.SUCCESS:
			return {
				...state,
				primaryContacts: action.payload
			}

			// Paperless
		case GET_ACCOUNT_DISTRIBUTION_METHOD.SUCCESS:
			return {
				...state,
				distributionMethod: action.payload.distributionMethods[0]
			}
		case GET_PROVIDERS.SUCCESS:
			return {
				...state,
				providers: action.payload.providers
			}
		case GET_GENERAL_LEDGERS.SUCCESS:
			return {
				...state,
				generalLedgers: action.payload
			}
		case GET_GENERAL_LEDGER_SUB_ACCOUNTS.SUCCESS:
			return {
				...state,
				generalLedgerSubAccounts: action.payload
			}
		default:
			return state;
	}
};
