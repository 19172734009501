import React, {Component, Fragment} from 'react'
import {
    createLoadingSelector,
    getAccountDetails,
    getUser,
    getUserPermission
} from "../../../../../../selectors";
import {
    getBillingSettings
} from "../../../../../../actions/accountDetailsActions/billing.actions";
import {showModal} from "../../../../../../actions/modal.actions";
import {connect} from "react-redux";
import Loader from '../../../../../../components/Loader';
import {
    emptyCapitalCredit,
    getAccountCapitalCredit
} from "../../../../../../actions/accountDetailsActions/capitalCreditActions";
import {getAccountInformation} from "../../../../../../actions/accountDetailsActions/accountInformation.actions"

class Administration extends Component {

    componentDidMount() {
        this.reloadBillingSettings();
        this.reloadAccountInformation();
        this.getCapitalCredit();
    }

    componentWillUnmount() {
        this.props.emptyCapitalCredit();
    }

    reloadBillingSettings = () => {
        const {accountId} = this.props;
        this.props.getBillingSettings(accountId);
    }

    reloadAccountInformation = () => {
        const {accountId} = this.props;
        this.props.getAccountInformation(accountId);
    }

    getCapitalCredit = () => {
        const {accountId} = this.props;
        this.props.getAccountCapitalCredit(accountId);
    }

    render() {

        const {
            accountBillingSettings,
            accountId,
            accountTypeId,
            showModal,
            billingSettingsLoader,
            capitalCreditLoader,
            canEditAccount,
            canViewBillingSettings,
            capitalCredit,
            canViewCapitalCredit,
            canEditCapitalCredit,
            accountInformationLoader,
            accountInformation,
        } = this.props;

        return (
            <div className="tab-pane fade show active" id="account-administration">
                <div className="container-client-users">
                    <div className="cmv-form cmv-form-client-users">


                        {(billingSettingsLoader || accountInformationLoader || capitalCreditLoader)
                        ?
                        <Loader/>
                        :
                        <>

                            {canViewBillingSettings &&
                            <div className="form-subsection">

                                <div className="form-header">
                                    <span className="form-header-title">
                                        <h6>Billing Settings</h6>
                                    </span>
                                    {canEditAccount &&
                                    <span className="form-header-toolbar">
                                        <button
                                            className="btn btn-xs btn-outline-secondary btn-cn-edit"
                                            onClick={() => showModal('BILLING_SETTINGS_MODAL', {
                                                accountBillingSettings,
                                                type: 'BILLING_SETTINGS',
                                                onCloseCallback: this.reloadBillingSettings
                                            })}
                                            type="button"
                                        >
                                            Edit
                                        </button>
                                    </span>
                                    }
                                </div>

                                {accountBillingSettings && accountBillingSettings.partner &&
                                <div className="form-group form-row">
                                    <div className="col-sm-5 col-xl-4">Partner</div>
                                    <div className="col-sm-7 col-xl-8">
                                        {accountBillingSettings.partner.name}
                                    </div>
                                </div>
                                }

                                {accountBillingSettings && accountBillingSettings.type &&
                                <div className="form-group form-row">
                                    <div className="col-sm-5 col-xl-4">Type</div>
                                    <div className="col-sm-7 col-xl-8">
                                        {accountBillingSettings.type.description}
                                    </div>
                                </div>
                                }

                                {accountBillingSettings && accountBillingSettings.billCycle &&
                                <div className="form-group form-row">
                                    <div className="col-sm-5 col-xl-4">Bill Cycle</div>
                                    <div className="col-sm-7 col-xl-8">
                                        {accountBillingSettings.billCycle.billCycle}
                                    </div>
                                </div>
                                }

                                {accountBillingSettings && accountBillingSettings.terms &&
                                <div className="form-group form-row">
                                    <div className="col-sm-5 col-xl-4">Terms</div>
                                    <div className="col-sm-7 col-xl-8">
                                        {accountBillingSettings.terms.description}
                                    </div>
                                </div>
                                }

                                {accountBillingSettings && accountBillingSettings.accountTier &&
                                <div className="form-group form-row">
                                    <div className="col-sm-5 col-xl-4">Risk Level</div>
                                    <div className="col-sm-7 col-xl-8">
                                        {accountBillingSettings.accountTier.description}
                                    </div>
                                </div>
                                }

                                {accountBillingSettings && accountBillingSettings.revenueLocation &&
                                <div className="form-group form-row">
                                    <div className="col-sm-5 col-xl-4">Revenue Location</div>
                                    <div className="col-sm-7 col-xl-8">
                                        {accountBillingSettings.revenueLocation.description}
                                    </div>
                                </div>
                                }

                            </div>
                            }

                            {canEditAccount && accountInformation.accountManagerEnabled &&
                            <div className="form-subsection">

                                <div className="form-header">
                                    <span className="form-header-title">
                                        <h6>Account Information</h6>
                                    </span>
                                    {canEditAccount &&
                                    <span className="form-header-toolbar">
                                        <button
                                            className="btn btn-xs btn-outline-secondary btn-cn-edit"
                                            onClick={() => showModal('ACCOUNT_INFORMATION_MODAL', {
                                                accountInformation,
                                                type: 'ACCOUNT_INFORMATION',
                                                onCloseCallback: this.reloadAccountInformation
                                            })}
                                            type="button"
                                        >
                                            Edit
                                        </button>
                                    </span>
                                    }
                                </div>

                                {accountInformation &&
                                <div className="form-group form-row">
                                    <div className="col-sm-5 col-xl-4">Account Manager</div>
                                    <div className="col-sm-7 col-xl-8">
                                        {accountInformation.currentAccountManager ? accountInformation.currentAccountManager.firstName + " " + accountInformation.currentAccountManager.lastName : "Not Provided"}
                                    </div>
                                </div>
                                }

                            </div>
                            }

                            {canViewCapitalCredit &&
                            <div className="form-subsection">

                                <div className="form-header">
                                    <span className="form-header-title">
                                        <h6>Capital Credit</h6>
                                    </span>
                                    {canEditCapitalCredit &&
                                    <span className="form-header-toolbar">
                                        <button
                                            className="btn btn-xs btn-outline-secondary btn-cn-edit"
                                            onClick={() =>
                                                showModal('CAPITAL_CREDIT_MODAL', {
                                                    accountId,
                                                    accountTypeId,
                                                    capitalCredit
                                            })}
                                            type="button"
                                        >
                                            Edit
                                        </button>
                                    </span>
                                    }
                                </div>

                                {capitalCredit &&
                                <div className="form-group form-row">
                                    <div className="col-sm-5 col-xl-4">Member number</div>
                                    <div className="col-sm-7 col-xl-8">
                                        {capitalCredit.memberNumber || 'Not provided'}
                                    </div>
                                </div>
                                }

                            </div>
                            }

                        </>
                        }

                    </div>
                </div>
            </div>
        )
    }
}

const billingSettingsLoaderSelector = createLoadingSelector(['GET_BILLING_SETTINGS']);
const accountInformationLoaderSelector = createLoadingSelector(['GET_ACCOUNT_INFORMATION']);
const capitalCreditLoaderSelector = createLoadingSelector(['GET_ACCOUNT_CAPITAL_CREDIT']);

const mapStateToProps = (state) => {
    const accountId = getAccountDetails(state).accountDetails.accountInfo.id,
        accountTypeId = getAccountDetails(state).accountDetails.accountInfo.accountType.id,
        accountBillingSettings = getAccountDetails(state).billing.billingSettings,
        userPermission = getUser(state).userPermission,
        capitalCredit = getAccountDetails(state).capitalCredit.capitalCredit,
        accountInformation = getAccountDetails(state).accountInformation.accountInformation;

    const billingSettingsLoader = billingSettingsLoaderSelector(state);
    const accountInformationLoader = accountInformationLoaderSelector(state);
    const capitalCreditLoader = capitalCreditLoaderSelector(state);

    const webMenuPermissions =
        userPermission.find((x) => x.name === 'WEB_MENU') &&
        userPermission.find((x) => x.name === 'WEB_MENU').permissions;

    const camPermissions =
        userPermission.find((x) => x.name === 'CAM') &&
        userPermission.find((x) => x.name === 'CAM').permissions;

    const canEditAccount = camPermissions && camPermissions.includes("CAM_ACC_EDIT");
    const canViewBillingSettings = webMenuPermissions && webMenuPermissions.includes("SHOW_BILLING_SETTINGS");

    const canViewCapitalCredit = getUserPermission(state, 'ADMN', 'ADMN_CAPITAL_CREDIT_VIEW');
    const canEditCapitalCredit = getUserPermission(state, 'ADMN', 'ADMN_CAPITAL_CREDIT_MANAGE');

    return {
        accountId,
        accountTypeId,
        accountBillingSettings,
        billingSettingsLoader,
        accountInformationLoader,
        accountInformation,
        capitalCreditLoader,
        canEditAccount,
        canViewBillingSettings,
        capitalCredit,
        canViewCapitalCredit,
        canEditCapitalCredit
    };
};

const mapDispatchToProps = {
    getBillingSettings,
    showModal,
    getAccountCapitalCredit,
    emptyCapitalCredit,
    getAccountInformation,
};

export default connect(mapStateToProps, mapDispatchToProps)(Administration);