import React, {Component, Fragment} from 'react';
import {get} from 'lodash';
import {isEmpty} from '../../../utils/helpers';
import {getInventoryNumbers} from '../../../actions/accountDetailsActions/newOrder.actions';
import {
    getActiveServiceAgreements
} from "../../../actions/taskFormActions/serviceAgreement.actions";
import {connect} from "react-redux";
import {getTaskForm} from "../../../selectors";

class ServicesServiceLine extends Component {
    state = {
        showSelectType: '',
        numberCategoriesId: '',
        number: '',
        inventory: [],
        deviceGroupId:
                 this.props.lineInfo.devices && this.props.lineInfo.devices.length > 0
                 ? Number(this.props.lineInfo.devices[0].deviceGroupId)
                : '',
        devices: this.props.lineInfo.devices|| [],
        serviceAgreementId: this.props.lineInfo.serviceAgreementId
    };

    componentDidMount() {
        const {planInfo} = this.props;

        if (!isEmpty(planInfo.numberCategories)) {
            this.setState({
                numberCategoriesId: planInfo.numberCategories[0].id
            });
        }

        this.props.getActiveServiceAgreements();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.numberCategoriesId !== this.state.numberCategoriesId) {
            const {planInfo} = this.props;

            getInventoryNumbers(this.state.numberCategoriesId, 100, planInfo.serviceModel.id).then((response) => {
                this.setState({
                    inventory: response,
                    number: response[0].number
                });
            });
        }

    }

    addNumberToServiceLine = () => {
        const {lineIndex, planIndex} = this.props;

        this.props.setFieldValue(`plans.${planIndex}.servicelines.${lineIndex}.number`, this.state.number);

        this.setState({
            showSelectType: ''
        });
    };

    showHidePhoneNumber = () => {
        this.setState({
            showSelectType: this.state.showSelectType === 'phoneNumber' ? '' : 'phoneNumber'
        });
    };

    handleChangeCategories = (e) => {
        let {value, name} = e.target;

        // getInventoryNumbers(id, 100, planInfo.serviceModel.id)
        // 	.then((inventory) => {
        // 		console.log(inventory);
        // 	})

        this.setState({
            [name]: value
        });
    };

    showHideEquipment = () => {
        this.setState({
            showSelectType: this.state.showSelectType === 'equipment' ? '' : 'equipment'
        });
    };

    showHideServiceAgreement = () => {
        this.setState({
            showSelectType: this.state.showSelectType === 'serviceAgreement' ? '' : 'serviceAgreement'
        });
    };

    onSelectHandler = (e) => {
        const {planInfo} = this.props;

        let deviceGroupId = Number(e.target.value);
        let updatedDevices = [];

        if(deviceGroupId != 0) {
            let findDevice = planInfo.equipment.find((x) => x.deviceGroupId === deviceGroupId);

            findDevice.deviceProfiles.forEach((element) => {
                if (isEmpty(element.possibleItems)) {
                    return;
                }
               let currentDevice = {
                    deviceGroupId: deviceGroupId,
                    deviceProfileId: element.id,
                    itemId: element.possibleItems[0].id,
                    itemDescription: element.possibleItems[0].description,
                    itemPrice: element.possibleItems[0].itemProperties[0].listPrice
                };
                updatedDevices.push(currentDevice);
            });
        }

        this.setState({
            deviceGroupId: Number(e.target.value),
            devices: updatedDevices
        });
    };

    onSelectServiceAgreement = (e) => {
        this.setState({
            serviceAgreementId: e.target.value
        });
    };

    handleDeviceChange = (e, device, index) => {
        let itemId = Number(e.target.value);
        let selectedItem = device.possibleItems.find((x) => x.id === itemId);

        let currentDevice = {
            deviceGroupId: this.state.deviceGroupId,
            deviceProfileId: device.id,
            itemId: selectedItem.id,
            itemDescription: selectedItem.description,
            itemPrice: selectedItem.itemProperties[0].listPrice
        };

        let updatedDevices = [...this.state.devices];
        updatedDevices[index] = currentDevice;


        this.setState({
            devices: updatedDevices
        });
    };

    cancel = () => {
        this.setState({
            showSelectType: ''
        });
    };

    saveEquipment = () => {
        const {lineIndex, planIndex} = this.props;
        let devices = this.state.devices;

        if (isEmpty(devices)) {
            const {planInfo} = this.props;
            if (planInfo.equipment.length !== 0 &&
                planInfo.equipment.find((x) => x.deviceGroupId === this.state.deviceGroupId)) {
                planInfo.equipment
                    .find((x) => x.deviceGroupId === this.state.deviceGroupId)
                    .deviceProfiles.forEach((device, index) => {
                    if (!isEmpty(device.possibleItems)) {
                        let selectedItem = device.possibleItems[0];
                        devices.push({
                            deviceGroupId: this.state.deviceGroupId,
                            deviceProfileId: device.id,
                            itemId: selectedItem.id,
                            itemDescription: selectedItem.description,
                            itemPrice: selectedItem.itemProperties[0].listPrice
                        });
                    }
                });
            }
        }

        this.props.setFieldValue(`plans.${planIndex}.servicelines.${lineIndex}.devices`, devices);

        this.setState({
            showSelectType: ''
        });
    };

    saveServiceAgreement = () => {
        const {lineIndex, planIndex} = this.props;

        this.props.setFieldValue(`plans.${planIndex}.servicelines.${lineIndex}.serviceAgreementId`, this.state.serviceAgreementId);

        this.setState({
            showSelectType: ''
        });
    };

    clearEquipment = () => {
        const {lineIndex, planIndex} = this.props;

        this.props.setFieldValue(`plans.${planIndex}.servicelines.${lineIndex}.devices`, []);

        this.setState({
            showSelectType: '',
            devices: []
        });
    };

    render() {
        const {showSelectType} = this.state;
        const {
            lineInfo,
            lineIndex,
            handleChange,
            planIndex,
            values,
            planInfo,
            handleBlur,
            errors,
            touched,
            serviceAgreements
        } = this.props;

        const serviceAgreement = serviceAgreements.find((sa) => String(sa.id) === String(lineInfo.serviceAgreementId));
        return (
            <Fragment>
                <div className="form-group serviceline-group form-row">
                    <div className="col-12 col-md">
                        {planInfo.serviceModel.directorySystemNumType.defaultSource === 'number_inventory' ? (
                            <div className="form-group form-row">
                                <label
                                    htmlFor=""
                                    className="col-12 col-sm-5 col-form-label col-form-label-sm text-right"
                                >
                                    {lineInfo.serviceLineRelation}
                                </label>

                                <div className="col-12 col-sm-7">
                                    <div className="input-group input-group-sm">
                                        <input
                                            type="text"
                                            name={`plans.${planIndex}.servicelines.${lineIndex}.number`}
                                            className="form-control"
                                            placeholder={lineInfo.serviceLineRelation}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            // value={get(values, `values.plans.${planIndex}.servicelines.${lineIndex}.num`)}
                                            value={values.plans[planIndex].servicelines[lineIndex].number}
                                        />
                                        <div className="input-group-append">
                                            <button
                                                type="button"
                                                className="btn btn-primary btn-search-line"
                                                onClick={this.showHidePhoneNumber}
                                            >
                                                <i className="fas fa-ellipsis-h"/>
                                                <span className="d-md-none">&nbsp;Number</span>
                                            </button>
                                        </div>
                                    </div>
                                    {get(errors, `plans.${planIndex}.servicelines.${lineIndex}.number`) &&
                                    get(touched, `plans.${planIndex}.servicelines.${lineIndex}.number`) && (
                                        <small className="form-text text-danger text-right">
                                            {get(errors, `plans.${planIndex}.servicelines.${lineIndex}.number`)}
                                        </small>
                                    )}
                                </div>
                            </div>
                        ) : (
                            <p className="col-form-label col-form-label-sm text-right">
                                {lineInfo.serviceLineRelation}
                            </p>
                        )}
                    </div>

                    {!isEmpty(planInfo.equipment) && (
                        <div className="col-12 col-md">
                            <div className="form-group form-row">
                                <div className="col-12 col-md-9 order-last order-md-first text-right">
                                    {this.state.devices && this.state.devices.map((device, index) => (
                                        device.itemDescription != '' ? (<label
                                            key={index}
                                            className={`col-form-label col-form-label-sm serviceline-inventory equipment-accepted-${index +
                                            1}`}
                                        >
                                            {device.itemDescription} {device.itemPrice >= 0 ? (
                                            '($' +
                                                device.itemPrice.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') +
                                            ')'

                                        ) : ('')
                                        }
                                            &ensp;
                                        </label>
                                            ) : ('')
                                    ))}
                                </div>
                                <div className="col-12 col-md-3 order-first order-md-last text-right">
                                    <button
                                        type="button"
                                        className="btn btn-sm btn-primary btn-equipment-add"
                                        onClick={this.showHideEquipment}
                                    >
                                        <i className="fas fa-ellipsis-h"/>
                                        <span className="d-md-none">&nbsp;Equipment</span>
                                    </button>
                                </div>
                            </div>
                            {get(errors, `plans.${planIndex}.servicelines.${lineIndex}.devices`) &&
                            get(touched, `plans.${planIndex}.servicelines.${lineIndex}.devices`) && (
                                <small className="form-text text-danger text-right">
                                    Please select equipment
                                    {/*{get(errors, `plans.${planIndex}.servicelines.${lineIndex}.devices`)}*/}
                                </small>
                            )}
                        </div>
                    )}

                    <div className="col-12 col-md">
                        <div className='form-group form-row'>
                            <div className='col-12 col-md-9 order-last order-md-first text-right'>
                                <label
                                    className='col-form-label col-form-label-sm serviceline-inventory equipment-accepted-1'>
                                    {serviceAgreement && (serviceAgreement.description)}
                                </label>
                            </div>
                            <div className='col-12 col-md-3 order-first order-md-last text-right'>
								<button type="button" className="btn btn-sm btn-primary btn-equipment-add" onClick={this.showHideServiceAgreement}>
                                    <i className="fas fa-ellipsis-h"/>
                                </button>
                            </div>
                        </div>
                    </div>

                    {showSelectType === 'phoneNumber' && (
                        <div className="cmv-container-subpanel cmv-container-subpanel-dirnum">
                            <h5>
                                <i className="fas fa-database"/> Select Directory Number
                            </h5>
                            <div className="form-group">
                                <label htmlFor="">Directory Type</label>
                                <select
                                    name="numberCategoriesId"
                                    className="form-control"
                                    onChange={this.handleChangeCategories}
                                    value={this.state.numberCategoriesId}
                                >
                                    {planInfo.numberCategories.map((category) => (
                                        <option value={category.id}>{category.description}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="form-group">
                                <label htmlFor="">Available Numbers</label>
                                <select
                                    name="number"
                                    onChange={this.handleChangeCategories}
                                    value={this.state.number}
                                    className="form-control"
                                >
                                    {this.state.inventory.map((num) => (
                                        <option value={num.number}>{num.number}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="d-flex justify-content-end mt-1">
                                <button
                                    onClick={this.cancel}
                                    className="btn btn-outline-primary btn-dn-select-cancel mr-1"
                                    type="button"
                                >
                                    Cancel
                                </button>

                                <button
                                    onClick={this.addNumberToServiceLine}
                                    className="btn btn-primary btn-dn-select-accept"
                                    type="button"
                                >
                                    Submit
                                </button>
                            </div>
                        </div>
                    )}

                    {!isEmpty(planInfo.equipment) &&
                    showSelectType === 'equipment' && (
                        <div className="cmv-container-subpanel cmv-container-subpanel-equipment">
                            <h5>
                                <i className="fas fa-plus-square"/> Select equipment
                            </h5>
                            <div className="form-group">
                                <label htmlFor="">I Need</label>
                                <select
                                    className="form-control"
                                    onChange={this.onSelectHandler}
                                    value={this.state.deviceGroupId}
                                >
                                    <option value="">No Equipment</option>
                                    {planInfo.equipment.length !== 0 &&
                                    planInfo.equipment.map((equipment) => (
                                        <option value={equipment.deviceGroupId} key={equipment.deviceGroupId}>
                                            {equipment.description}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            {planInfo.equipment.length !== 0 &&
                            planInfo.equipment.find((x) => x.deviceGroupId === this.state.deviceGroupId) &&
                            planInfo.equipment
                                .find((x) => x.deviceGroupId === this.state.deviceGroupId)
                                .deviceProfiles.map((device, index) => (
                                <div className="form-group">
                                    <label htmlFor="">{device.description}</label>
                                    <select
                                        className="form-control"
                                        onChange={(e) => this.handleDeviceChange(e, device, index)}
                                        value={!isEmpty(this.state.devices[index]) ? this.state.devices[index].itemId : null}
                                    >
                                        <option value="" selected>Please select option...</option>
                                        {device.possibleItems.map((item) => (
                                            <option value={item.id}>
                                                {item.description} (${item.itemProperties[0].listPrice
                                                .toFixed(2)
                                                .replace(/\d(?=(\d{3})+\.)/g, '$&,')})
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            ))}

                            <div className="d-flex justify-content-end mt-1">
                                <button
                                    onClick={this.cancel}
                                    type="button"
                                    className="btn btn-outline-primary btn-dn-select-cancel mr-1"
                                >
                                    Cancel
                                </button>
                                <button
                                    onClick={this.clearEquipment}
                                    type="button"
                                    className="btn btn-outline-primary btn-equipment-clear mr-1"
                                >
                                    Clear
                                </button>
                                <button
                                    onClick={this.saveEquipment}
                                    type="button"
                                    className="btn btn-primary btn-equipment-accept"
                                >
                                    Submit
                                </button>
                            </div>
                        </div>
                    )}

                    {showSelectType === 'serviceAgreement' && (
                        <div className="cmv-container-subpanel cmv-container-subpanel-equipment">
                            <h5>
                                <i className="fas fa-handshake"/> Select Service Agreement
                            </h5>
                            <div className="form-group">
                                <label htmlFor="">Possible Agreements</label>
                                <select
                                    className="form-control"
                                    onChange={this.onSelectServiceAgreement}
                                    value={this.state.serviceAgreementId ? this.state.serviceAgreementId : ''}
                                >
                                    <option value="">Select</option>
                                    {serviceAgreements.map((serviceAgreement, index) => (
                                        <option key={index} value={serviceAgreement.id}>
                                            {serviceAgreement.description}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="d-flex justify-content-end mt-1">
                                <button
                                    onClick={this.cancel}
                                    type="button"
                                    className="btn btn-outline-primary btn-dn-select-cancel mr-1"
                                >
                                    Cancel
                                </button>
                                <button
                                    onClick={this.saveServiceAgreement}
                                    type="button"
                                    className="btn btn-primary btn-equipment-accept"
                                >
                                    Submit
                                </button>
                            </div>
                        </div>
                    )}
                </div>

                {/* {errors.plans[planIndex].servicelines[lineIndex].number && <p>{errors.plans[planIndex].servicelines[lineIndex].number}</p>} */}
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    let serviceAgreementReducer = getTaskForm(state).serviceAgreementReducer;

    return {
        serviceAgreements: serviceAgreementReducer.serviceAgreements,
    };
};

const mapDispatchToProps = {
    getActiveServiceAgreements
};

export default connect(mapStateToProps, mapDispatchToProps)(ServicesServiceLine);
