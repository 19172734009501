import React, { Component } from 'react';
import PropTypes from 'prop-types';
import NotificationCard from './NotificationCard';
import Loader from '../../../../../../components/Loader';

class NotificationCategories extends Component {
	render() {
		const {
			notificationCategoriesData,
			handleNotificationSubscribe,
			handleNotificationUnsubscribe,
			isNotificationCategoriesLoaded,
			notificationCategoriesLoader
		} = this.props;


		if (!isNotificationCategoriesLoaded && notificationCategoriesLoader) {
			return <Loader />;
		}

		return (
			<div className="container-client-notifications-settings">
				{notificationCategoriesData.map((category) => (
					<NotificationCard
						categoryInfo={category}
						key={category.id}
						handleNotificationSubscribe={handleNotificationSubscribe}
						handleNotificationUnsubscribe={handleNotificationUnsubscribe}
					/>
				))}
			</div>
		);
	}
}

NotificationCategories.propTypes = {
	notificationCategoriesData: PropTypes.array,
	notificationCategoriesLoader: PropTypes.bool,
	isNotificationCategoriesLoaded: PropTypes.bool,
	handleNotificationSubscribe: PropTypes.func,
	handleNotificationUnsubscribe: PropTypes.func,
};

NotificationCategories.defaultProps = {
	notificationCategoriesData: []
};

export default NotificationCategories;
