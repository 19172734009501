import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

const ContactInput = ({
    header,
    contactId,
	fieldName,
	isEditable,
	currentObject,
	handleCloseInput,
	handleEditField,
    handleUpdate,
    inputRef,
    isDisabled
}) => {
	return (
		<div className="form-group form-row">
			<div className="col-sm-5 col-xl-4">{header}</div>
			<div className="col-sm-7 col-xl-8">
				{isEditable !== fieldName ? (
					<Fragment>
						{fieldName === 'ssn' ? (
							<a
								onClick={() => {if (!isDisabled) handleEditField(fieldName)}}
								data-type="text"
								className={"editable-generic-text" + (isDisabled ? " editable-generic-text-disabled" : "")}
							>{currentObject ? currentObject['ssnHashed'] ? currentObject['ssnHashed'] : 'Not provided' : 'Not provided'}
							</a>
						) : (
							<a
								onClick={() => {if (!isDisabled) handleEditField(fieldName)}}
								data-type="text"
								className={"editable-generic-text" + (isDisabled ? " editable-generic-text-disabled" : "")}
							>{currentObject ? currentObject[fieldName] ? currentObject[fieldName] : 'Not provided' : 'Not provided'}
							</a>
						)}
					</Fragment>
				) : (
					<div className="input-group input-group-editable">
						<input
                            name={fieldName}
							className="form-control"
							defaultValue={currentObject ? currentObject[fieldName] : ''}
                            ref={inputRef}
                            disabled={isDisabled}
							autocomplete="off"
						/>
						<div className="input-group-append">
							{/* <button type="button" className="btn btn-editable-clear">
								<i className="fas fa-backspace" />
							</button> */}
							<button
								type="button"
								onClick={() => handleUpdate(fieldName, contactId, currentObject)}
                                className="btn btn-primary btn-editable-apply"
                                disabled={isDisabled}
							>
								{!isDisabled ? (
									<i className="fas fa-check" />
								) : (
									<i className="fas fa-spin fa-circle-notch" />
								)}
							</button>
							<button
								type="button"
								onClick={handleCloseInput}
                                className="btn btn-outline-secondary btn-editable-cancel"
                                disabled={isDisabled}
							>
								<i className="fas fa-times" />
							</button>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

ContactInput.propTypes = {};

export default ContactInput;
