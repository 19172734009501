import React, { Component } from 'react';
import { connect } from 'react-redux';
import Technicians from './Technicians';
import {hideModal, showModal} from '../../actions/modal.actions';

class TechnicianCapacity extends Component {
    componentDidMount() {
        document.title = 'Technician Capacity - camvio.cloud';
        this.props.changeNavBarType('default', 'Technician Configuration');
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isShowAccessModal !== this.props.isShowAccessModal && this.props.isShowAccessModal === true) {
        }
    }

    componentWillUnmount() {
        this.props.hideModal();
    }

    render() {
        const { accountInfo, history, accountInfoLoader, isShowAccessModal } = this.props;

		//If user is redirected from create-account page - show cpni pop-up
		if (
			isShowAccessModal === false &&
			history.location.state &&
			history.location.state.serviceOrderData
		) {
			return null;
        }
        
        return (
            <div className="cmv-container cmv-container-dashboard cmv-container-client-selected">
                <Technicians/>
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {
    };
};

const mapDispatchToProps = {
    showModal,
	hideModal
};

export default connect(mapStateToProps, mapDispatchToProps)(TechnicianCapacity);
