import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Tile from '../../../components/UI/Tile/Tile';

export class Tiles extends Component {

	render() {

		const { activeTile, handleTileChange, dashboardMenuCount, dashboardMenuCountLoading } = this.props;

		const urlTile = window.location.hash.split('#').slice(-1)[0];

		if (urlTile !== '' &&
			urlTile !== activeTile &&
			['tasks', 'customers', 'orders', 'tickets', 'appointments'].includes(urlTile)
		) {
			handleTileChange(urlTile);
		}

		return (
			<div className="cmv-container-dashboard-stats" id="home-tiles" role="tablist">

				<Tile
					tile="customers"
					activeTile={activeTile}
					handleTileChange={handleTileChange}
					cardTitle={`${dashboardMenuCount.customersServed || 0} Customers`}
					cardText="served in 24 hrs"
					cardIcon="fas fa-2x fa-user"
					loading={dashboardMenuCountLoading}
				/>

				<Tile
					tile="tasks"
					activeTile={activeTile}
					handleTileChange={handleTileChange}
					cardTitle={`${dashboardMenuCount.pendingTasks || 0} Tasks`}
					cardText="still incomplete"
					cardIcon="fas fa-2x fa-list-ul"
					loading={dashboardMenuCountLoading}
				/>

				<Tile
					tile="orders"
					activeTile={activeTile}
					handleTileChange={handleTileChange}
					cardTitle={`${dashboardMenuCount.pendingOrders || 0} Orders`}
					cardText="still pending"
					cardIcon="fas fa-2x fa-shopping-cart"
					loading={dashboardMenuCountLoading}
				/>

				<Tile
					tile="tickets"
					activeTile={activeTile}
					handleTileChange={handleTileChange}
					cardTitle={`${dashboardMenuCount.pendingTroubleTickets || 0 } Tickets`}
					cardText="still pending"
					cardIcon="fas fa-2x fa-check-square"
					loading={dashboardMenuCountLoading}
				/>

				<Tile
					tile="appointments"
					activeTile={activeTile}
					handleTileChange={handleTileChange}
					// cardTitle={`${dashboardMenuCount.pendingAppointments || 0} Appointments`}
					cardTitle={`Appointments`}
					cardText="upcoming"
					cardIcon="fas fa-2x fa-clock"
					loading={dashboardMenuCountLoading}
				/>

			</div>
		);
	}
}

Tiles.propTypes = {
	activeTile: PropTypes.string,
	handleTileChange: PropTypes.func,
	dashboardMenuCount: PropTypes.object
};

export default Tiles;
