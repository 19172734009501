import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { getModalData, getAccountDetails } from '../../../selectors';
import { hideModal } from '../../../actions/modal.actions';
import { createNote, editNote } from '../../../actions/accountDetailsActions/accountNotes.actions';
import { Formik } from 'formik';
import * as Yup from 'yup';

const NoteSchema = Yup.object().shape({
	note: Yup.string().required('Required'),
	noteCategoryId: Yup.string().required('Required')
});

class AccountNoteModal extends Component {
	state = {
		note: '',
		noteCategoryId: this.props.modalProps.noteCategoryId || '',
		noteCategories: this.props.noteCategories || '',
	};

	componentDidMount() {
		if (this.props.modalProps === 'editNote') {
		} else if (this.props.modalProps === 'createNote') {
		}
	}

	onHandleChange = (e) => {
		this.setState({
			[e.target.name]: e.target.value
		});
	};

	closeModal = () => {
		this.props.hideModal();
	};

	creteNewNote = (values) => {
		const { modalProps } = this.props;
		const noteData = {
			note: values.note,
			noteCategoryId: values.noteCategoryId
		};
		this.props.createNote(modalProps.accountId, noteData, modalProps.filters);
	};

	editNote = (values) => {
		const { modalProps } = this.props;
		const noteData = {
			id: modalProps.noteId,
			note: values.note,
			noteCategoryId: values.noteCategoryId
		};
		this.props.editNote(modalProps.accountId, noteData, modalProps.filters);
	};

	formSubmit = (values, actions) => {
		if (this.props.modalProps.type === 'createNote') {
			this.creteNewNote(values);
		} else {
			this.editNote(values);
		}
	};

	render() {
		const { modalProps, noteCategories } = this.props;

		return (
			<Fragment>
				<div className="modal" style={{ display: 'block' }} tabIndex="-1" role="dialog">
					<Formik
						initialValues={{
							note: modalProps.note || '',
							noteCategoryId: modalProps.defaultNote.id || '',
						}}
						validationSchema={NoteSchema}
						onSubmit={this.formSubmit}
						render={({ handleChange, handleSubmit, handleBlur, values, errors, touched, isSubmitting }) => (
							<form onSubmit={handleSubmit}>
								<div className="modal-dialog" role="document">
									<div className="modal-content">
										<div className="modal-header">
											<h5 className="modal-title">
												{modalProps.type === 'createNote' ? (
													'Add Customer Note'
												) : (
													'Edit Customer Note'
												)}
											</h5>
											<button type="button" className="close" onClick={this.closeModal}>
												<span aria-hidden="true">&times;</span>
											</button>
										</div>
										<div className="modal-body form-horizontal">
											<div className="form-group">
												<label>Note Category</label>
												<select
													className={
														errors.noteCategoryId && touched.noteCategoryId ? (
															'form-control is-invalid'
														) : (
															'form-control customer-notes-cat'
														)
													}
													value={values.noteCategoryId}
													onChange={handleChange}
													onBlur={handleBlur}
													name="noteCategoryId"
												>
													<option value={modalProps.defaultNote.id} key={modalProps.defaultNote.id}>
														{modalProps.defaultNote.value}
													</option>
													{noteCategories.map((category) => {
														if (category.type === 'ACCOUNT' && category.active === true
														&& category.isDefault != true) {
															return (
																<option value={category.id} key={category.id}>
																	{category.description}
																</option>
															);
														}
														return null    //<== add this
													})}
												</select>
												{errors.noteCategoryId &&
												touched.noteCategoryId && (
													<div className="invalid-feedback">{errors.note}</div>
												)}
											</div>
											<div className="form-group">
												<label>Note</label>
												<textarea
													className={
														errors.note && touched.note ? (
															'form-control is-invalid'
														) : (
															'form-control'
														)
													}
													name="note"
													onChange={handleChange}
													onBlur={handleBlur}
													value={values.note}
												/>
												{errors.note &&
												touched.note && <div className="invalid-feedback">{errors.note}</div>}
											</div>
										</div>
										<div className="modal-footer">
											<button onClick={this.closeModal} className="btn">
												CANCEL
											</button>
											{modalProps.type === 'createNote' ? (
												<button
													type="submit"
													className="btn btn-primary"
													disabled={isSubmitting}
												>
													ADD NOTE
												</button>
											) : (
												<button
													type="submit"
													className="btn btn-primary"
													disabled={isSubmitting}
												>
													UPDATE NOTE
												</button>
											)}
										</div>
									</div>
								</div>
							</form>
						)}
					/>
				</div>
				<div className="modal-backdrop show" tabIndex="1" />
			</Fragment>
		);
	}
}
const mapStateToProps = (state) => {
	const modalProps = getModalData(state).modalProps,
		noteCategories = getAccountDetails(state).accountNotes.noteCategories;

	return {
		modalProps,
		noteCategories
	};
};
const mapDispatchToProps = {
	hideModal,
	createNote,
	editNote
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountNoteModal);
