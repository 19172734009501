import React, { Component } from 'react';
import ServicesSummary from './ServiceSummary';

export class Summary extends Component {
	state = {
		showInformation: !false
	};

	showHideInformation = () => {
		this.setState((prevState) => ({
			showInformation: !prevState.showInformation
		}));
	};

	render() {
		const { showInformation } = this.state;

		return (
			<div className="order-summary">
				{/*<button
					className="btn btn-outline-secondary btn-block btn-order-summary-toggle mb-1"
					data-toggle="collapse"
					onClick={this.showHideInformation}
				>
					<i className="fas fa-list-ul" />&nbsp;Complete Order Information
				</button>*/}
				{showInformation && <ServicesSummary serviceOrderId={this.props.serviceOrderId} showGrandTotal={this.props.showGrandTotal} />}
			</div>
		);
	}
}

export default Summary;
