import React, { Component } from 'react';

class Confirmation extends Component {
	showCpniModal = (id) => {
		// this.props.showModal('ACCOUNT_ACCESS_MODAL', this.props.newAccountInfo.id);
		this.props.history.push(`/account-details/${this.props.newAccountInfo.id}`)
	};

	openNewServiceRequestWizard = () => {
		// Redirect user to account details and open create service request wizard
		// Adding state and catch it account details component did mount

		const { history, newAccountInfo } = this.props;
		let state = {
			openNewServiceWizard: true
		};
		history.push(`/account-details/${newAccountInfo.id}`, state);
	};

	render() {
		const {
			newAccountInfo,
			resetForm,
			resetTheWizard,
			showModal,
			continueWithServiceRequest,
			isServiceRequestStarted
		} = this.props;

		return (
			<div className="tab-pane show active">
				<p className="text-center text-success">
					<i className="far fa-4x fa-check-circle" />
				</p>
				<p className="text-center text-success lead">
					New account <span className="font-weight-bold">#{newAccountInfo.number}</span> created succesfully.
				</p>
				{isServiceRequestStarted === true ? (
					<p className="text-center">
						<button
							onClick={() => continueWithServiceRequest(newAccountInfo.id)}
							type="button"
							className="btn btn-success btn-lg mb-1"
						>
							CONTINUE WITH SERVICE REQUEST
						</button>
					</p>
				) : (
					<p className="text-center">
						{/* <button onClick={() => resetTheWizard(resetForm)} className="btn btn-outline-success btn-lg mb-1" type="button">Create New Account</button> */}
						<button
							onClick={this.showCpniModal}
							className="btn btn-success btn-lg mb-1"
							style={{ marginRight: '5px' }}
							type="button"
						>
							View Account Details
						</button>
						<button
							onClick={this.openNewServiceRequestWizard}
							type="button"
							className="btn btn-success btn-lg mb-1"
						>
							 NEW SERVICE REQUEST
						</button>
					</p>
				)}
			</div>
		);
	}
}

export default Confirmation;
