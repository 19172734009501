import {actionCreator, createRequestTypes} from '../utils/helpers';
import * as viewAsStorage from "../utils/viewAsStorage";

export const SET_VIEW_AS_PARTNER = createRequestTypes('SET_VIEW_AS_PARTNER');
export const SET_VIEW_AS_LOCATION = createRequestTypes('SET_VIEW_AS_LOCATION');

export const setViewAsPartner = (partner) => async (dispatch) => {
	dispatch(actionCreator.request(SET_VIEW_AS_PARTNER));
	try {
		viewAsStorage.setPartner(partner);
		dispatch(actionCreator.success(SET_VIEW_AS_PARTNER, partner));
		return partner;
	} catch (err) {
		let mockError = {
			message: 'Something goes wrong'
		}

		dispatch(actionCreator.failure(SET_VIEW_AS_PARTNER, mockError));
		return err;
	}
};

export const setViewAsLocation = (location) => async (dispatch) => {
	dispatch(actionCreator.request(SET_VIEW_AS_LOCATION));
	try {
		viewAsStorage.setLocation(location);
		dispatch(actionCreator.success(SET_VIEW_AS_LOCATION, location));
		return location;
	} catch (err) {
		let mockError = {
			message: 'Something goes wrong'
		}

		dispatch(actionCreator.failure(SET_VIEW_AS_LOCATION, mockError));
		return err;
	}
};

export const clearViewAs = () => async (dispatch) => {
	viewAsStorage.clearAll();
	await dispatch(actionCreator.success(SET_VIEW_AS_PARTNER, null));
	await dispatch(actionCreator.success(SET_VIEW_AS_LOCATION, null));
};
