import React, {Component} from 'react';
import {connect} from 'react-redux';
import {createLoadingSelector, getAccountDetails, getUserPermission} from "../../../../../../selectors";
import {getAllowedServiceLineStatusTransitions} from "../../../../../../actions/serviceLine.actions";
import {getServiceLineHistory} from '../../../../../../actions/accountDetailsActions/servicesAndEquipment.actions';
import {showModal} from "../../../../../../actions/modal.actions";
import {updateServicesUpdatedFlag} from "../../../../../../actions/globalFlag.actions";
import ServiceSummary from './ServiceSummary';
import isEmpty from "../../../../../../utils/helpers";
import moment from 'moment';
import Loader from "../../../../../../components/Loader";

export class ServiceWrapper extends Component {

    state = {
        service: this.props.service,
        isLoading: false,
        isExpanded: false,
        statusTransitions: null,
    }

    componentDidMount() {
        const {history, service} = this.props;

        // Reopen service line if going back from wizard
        if (history.location.state && history.location.state.serviceLineId === service.serviceLineId) {
            this.setState({isExpanded: true});

            // Wait for state update
            setTimeout(() => {

                // Scroll service line into view
                document.querySelectorAll('[data-line-id="'+service.serviceLineId+'"]')[0]
                    .scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});

                // Remove history state so we don't reopen/scroll again
                history.replace({state: undefined});

            }, 100);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // const {activeServiceLineDropdownId, service} = this.props;
        //
        // if (activeServiceLineDropdownId === service.serviceLineId && prevProps.activeServiceLineDropdownId !== activeServiceLineDropdownId) {
        //     this.reloadAllowedServiceLineStatusTransitions();
        // }

        // If we just expanded the service line
        if (this.state.isExpanded && prevState.isExpanded !== this.state.isExpanded) {

            // Load historical data if toggle is on
            if (this.props.showHistoricalData) {
                this.setState({isLoading: true});
                this.props.getServiceLineHistory(this.props.service.serviceLineId).then(response => {
                    this.setState({
                        isLoading: false,
                        service: response,
                    });
                });
            }
        }
    }

    reloadAllowedServiceLineStatusTransitions = () => {
        const {accountId, service} = this.props;

        this.props.getAllowedServiceLineStatusTransitions(accountId, service.serviceLineId).then(response => {
            const statusTransitions = response.data.statusTransitions;

            if (statusTransitions) {
                statusTransitions.sort((a, b) => {
                    let compRes = a.order - b.order;

                    if (compRes === 0) {
                        compRes = a.description.localeCompare(b.description);
                    }

                    return compRes;
                });
            }

            this.setState({statusTransitions});
        });
    }

    handleCollapse = () => {
        this.setState((prevState) => ({
            isExpanded: !prevState.isExpanded
        }));

        // Hide dropdown
        if (!isEmpty(this.props.activeServiceLineDropdownId)) {
            this.props.handleActiveDropDown('serviceLine', this.props.activeServiceLineDropdownId);
        }
    };

    onSuccessfulStatusChangeHandler = () => {
        this.props.updateServicesUpdatedFlag();
    }

    onFailedStatusChangeHandler = (error) => {
    }

    showModal = (statusTransition) => {
        if (statusTransition.disabled) {
            return;
        }

        const {showModal, accountId, service} = this.props;

        showModal('SERVICE_LINE_STATUS_CHANGE_MODAL', {
                accountId,
                serviceLineId: service.serviceLineId,
                statusTransition,
                onSuccess: this.onSuccessfulStatusChangeHandler,
                onFail: this.onFailedStatusChangeHandler,
            });
    }

    handleAddEquipment = () => {
        const {accountId, service, handleActiveDropDown} = this.props;

        // Hide dropdown
        handleActiveDropDown('serviceLine', service.serviceLineId);

        // Gather wizard data
        let data = {
            wizardType: 'addEquipment',
            wizardData: {accountId, serviceLineId: service.serviceLineId}
        };

        // Show wizard
        this.props.showAddEquipmentWizardHandler(data);
    }

    handleRemoveEquipment = () => {
        const {accountId, service, handleActiveDropDown} = this.props;

        // Hide dropdown
        handleActiveDropDown('serviceLine', service.serviceLineId);

        // Gather wizard data
        let data = {
            wizardType: 'removeEquipment',
            wizardData: {accountId, serviceLineId: service.serviceLineId, serviceDevices: service.serviceDevices}
        };

        // Show wizard
        this.props.showRemoveEquipmentWizardHandler(data);
    }

    handleSwapEquipment = () => {
        const {accountId, service, handleActiveDropDown} = this.props;

        // Hide dropdown
        handleActiveDropDown('serviceLine', service.serviceLineId);

        // Gather wizard data
        let data = {
            wizardType: 'swapEquipment',
            wizardData: {accountId, serviceLineId: service.serviceLineId, serviceDevices: service.serviceDevices}
        };

        // Show wizard
        this.props.showSwapEquipmentWizardHandler(data);
    }

    render() {

        const {
            service,
            isLoading,
            isExpanded,
            statusTransitions,
        } = this.state;

        const {
            showHistoricalData,
            serviceLinesCount,
            directorySystemNumType,
            accountPlanId,
            planId,
            handleActiveDropDown,
            activeServiceLineDropdownId,
            handleProductAndPackagesClick,
            serviceLineStatusTransitionsLoading,
            canAddDevices,
            canRemoveDevices,
            canEditServiceAddress,
            canViewCSIGeocode,
            canEditCSIGeocode
        } = this.props;

        let serviceAgreementPeriodPercents = 0;

        if (!isEmpty(service)) {

        if (service.serviceLineAgreement) {
            const totalPeriod = moment(service.serviceLineAgreement.startDate)
                .add(service.serviceLineAgreement.length, 'months')
                .diff(moment(service.serviceLineAgreement.startDate), 'weeks');
            const fromStartPeriod = moment()
                .diff(moment(service.serviceLineAgreement.startDate), 'weeks');
            serviceAgreementPeriodPercents = (fromStartPeriod / totalPeriod) * 100;

            if (serviceAgreementPeriodPercents === 0) {
                serviceAgreementPeriodPercents = 1;
            }
        }
        }

        return (
            <>
            {service &&
            <div className="service-wrapper" data-line-id={service.serviceLineId}>
                <div className="service-header">

					<span
                        className={'service-title service-body-toggler' + (isExpanded ? '' : ' service-body-closed')}
                        onClick={this.handleCollapse}
                    >
						{service.number || "Service Line"}
                        {" "}
                        {"("}
                        {service.startDate && moment(service.startDate).format('MM/DD/YYYY')}
                        {" - "}
                        {service.endDate ? moment(service.endDate).format('MM/DD/YYYY') : "Now"}
                        {")"}
					</span>

                    <span className="service-toolbar">
						<span className="badge badge-outline-secondary">
                            {serviceLinesCount > 1 && service.planRelation}
						</span>
						<span className={"badge" + (
						    service.status === "Active" ? " badge-success"
                                : service.status === "Deactivated" ? " badge-danger"
                                    : " badge-secondary"
                        )}>
                            {service.status}
						</span>
                        {service.status !== 'Deactivated' &&
						<span>
							<div className={"dropdown" + (activeServiceLineDropdownId === service.serviceLineId ? ' show' : '')}>
								<button
                                    onClick={() => handleActiveDropDown('serviceLine', service.serviceLineId)}
                                    onBlur={() => handleActiveDropDown('serviceLine', service.serviceLineId, true)}
                                    className="btn-badge badge badge-secondary dropdown-toggle"
                                    role="button"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                >

									<i className="fas fa-ellipsis-v"/>
                                    {/*<i className="fas fa-cog"/>*/}

								</button>
								<div
                                    className={'dropdown-menu dropdown-menu-right' + (activeServiceLineDropdownId === service.serviceLineId ? ' show' : '')}
                                    aria-labelledby="dropdownMenuLink"
                                >

									<button
                                        className="dropdown-item btn-change-oq-wizard"
                                        onMouseDown={() => {if (service.canEdit === true) {
                                            handleProductAndPackagesClick(planId, service.serviceLineId, service.planRelation, service.startDate)
                                        }}}
                                        disabled={!service.canEdit}
                                    >
										Change Features
									</button>

                                    {directorySystemNumType &&
                                    <button
                                        className="dropdown-item"
                                        disabled
                                    >
                                        Change {directorySystemNumType}
                                    </button>
                                    }

                                    <div className="dropdown-divider" />

                                    {/*{serviceLineStatusTransitionsLoading &&*/}
                                    {/*<button*/}
                                    {/*    className="dropdown-item"*/}
                                    {/*    disabled*/}
                                    {/*>*/}
                                    {/*    <i className="fas fa-fw fa-spin fa-spinner"/>*/}
                                    {/*</button>*/}
                                    {/*}*/}

                                    {/*{!serviceLineStatusTransitionsLoading && statusTransitions && statusTransitions.map(st =>*/}
                                    {/*<button*/}
                                    {/*    key={st.id}*/}
                                    {/*    className="dropdown-item"*/}
                                    {/*    onClick={() => this.showModal(st)}*/}
                                    {/*    disabled={st.disabled}*/}
                                    {/*>*/}
                                    {/*    {st.description}*/}
                                    {/*</button>*/}
                                    {/*)}*/}

                                    {/*<div className="dropdown-divider" />*/}

                                    {canAddDevices === true && (
                                        <button
                                            className="dropdown-item btn-change-oq-wizard"
                                            onMouseDown={this.handleAddEquipment}
                                            disabled={!service.canEdit}
                                        >
                                            Add Equipment
                                        </button>
                                    )}

                                    {canRemoveDevices === true && (
                                        <button className="dropdown-item btn-change-oq-wizard"
                                            onMouseDown={this.handleRemoveEquipment}
                                            disabled={!service.canEdit || isEmpty(service.serviceDevices)}
                                        >
                                            Remove Equipment
                                        </button>
                                    )}

                                    {(
                                        <button className="dropdown-item btn-change-oq-wizard"
                                            onMouseDown={this.handleSwapEquipment}
                                            disabled={!service.canEdit || isEmpty(service.serviceDevices)}
                                        >
                                            Swap Equipment
                                        </button>
                                    )}

								</div>
							</div>
						</span>
                        }
					</span>

                </div>
                {isExpanded && isLoading &&
                    <Loader />
                }

                {isExpanded && !isLoading &&
                <div className={isExpanded ? 'service-body' : 'service-body collapse'}>
                    {(service.address1 || canEditServiceAddress) &&
                    <div className="services-summary mt-0">
                        <div className="services-summary-body">
                            <div className="plan-wrapper">
                                <div className="serviceline-wrapper">
                                    <div className="plan-section">
                                        <h5>Address</h5>
                                        <div className="package-wrapper">
                                            <div className="package-option">
                                                <span className="po-title">{service.address1}
                                                    {service.address2 ? ", " + service.address2 : ''}
                                                    {service.city ? ", " + service.city : ''}
                                                    {service.state ? (service.state.abbreviation ? ", " + service.state.abbreviation : '') : ''}
                                                    {service.zipcode ? ", " + service.zipcode : ''}
                                                    {service.zipcode && service.zip4 ? "-" + service.zip4 : ''}
                                                </span>
                                                <span className="po-price"></span>
                                                {canEditServiceAddress === true && service.status == "Active" &&
                                                <button
                                                    onClick={() => this.props.showModal('SERVICE_ADDRESS_MODAL', {
                                                        title: "Edit Service Address",
                                                        id: service.serviceLineId,
                                                        addressId: service.serviceLineAddressId,
                                                        type: "SERVICE_LINE",
                                                        service,
                                                        canViewCSIGeocode,
                                                        canEditCSIGeocode,
                                                        isDisabled: !canEditServiceAddress
                                                    })}
                                                    className="btn btn-xs btn-outline-secondary btn-cn-edit">Edit</button>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    }

                    <div className="service-usage">
                        <div className="row">
                            <div className="col-lg">
                                <div className="cmv-progress-bar">

                                    <div className="progress-header">
										<span className="ph-left">
											<i className="far fa-fw fa-calendar-alt"/> Service Agreement
										</span>
                                        {service.serviceLineAgreement ?
                                            <span className="ph-right">
                                            {service.serviceLineAgreement.description},&nbsp;
                                                {moment(service.serviceLineAgreement.startDate)
                                                    .format('MM/DD/YYYY')} &mdash;
                                                {moment(service.serviceLineAgreement.startDate)
                                                    .add(service.serviceLineAgreement.length, 'months').format('MM/DD/YYYY')}
                                        </span>
                                            :
                                            <span className="ph-right">N/A</span>
                                        }
                                    </div>

                                    <div className="progress">
                                        {service.serviceLineAgreement && (
                                            <div
                                                className="progress-bar bg-info"
                                                role="progressbar"
                                                style={{width: serviceAgreementPeriodPercents + '%'}}
                                                aria-valuenow="4"
                                                aria-valuemin="0"
                                                aria-valuemax="100"
                                            />
                                        )}
                                    </div>

                                    <div className="progress-footer">
                                        {service.serviceLineAgreement && (
                                            <p className="mb-0 text-right">
                                                {moment(service.serviceLineAgreement.startDate)
                                                    .add(service.serviceLineAgreement.length, 'months')
                                                    .diff(moment(), 'weeks')
                                                } weeks remaining
                                            </p>
                                        )}
                                    </div>

                                </div>
                            </div>
                            <div className="col-lg">
                                <div className="cmv-progress-bar">
                                    <div className="progress-header">
                                        <span className="ph-left"><i className="far fa-fw fa-calendar-alt"/> Service Period</span>
                                        <span className="ph-right">Started {moment(service.startDate).format('MM/DD/YYYY')}</span>
                                    </div>
                                    <div className="progress">
                                        <div className="progress-bar bg-info" role="progressbar" style={{width: '100%'}} />
                                    </div>
                                    <div className="progress-footer">
                                        <p className="mb-0 text-right">{moment().diff(moment(service.startDate), 'weeks')} weeks ago</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <ServiceSummary
                        service={service}
                        showHistoricalData={showHistoricalData}
                    />

                </div>
                }

            </div>
            }
            </>
        );
    }
}

const getServiceLineStatusTransitionsLoader = createLoadingSelector(['GET_SERVICE_LINE_STATUS_TRANSITIONS']);

const mapStateToProps = (state) => {

    // const serviceLineStatusTransitionsLoading = getServiceLineStatusTransitionsLoader(state);
    const accountId = getAccountDetails(state).accountDetails.accountInfo.id;
    const canAddDevices = getUserPermission(state, 'OPER', 'OPER_SERVICELINE_ADD_DEVICES');
    const canRemoveDevices = getUserPermission(state, 'OPER', 'OPER_SERVICELINE_REMOVE_DEVICES');
    const canEditServiceAddress = getUserPermission(state, 'ADMN', 'ADMN_EDIT_SERVICE_ADDRESS');
    const canViewCSIGeocode = getUserPermission(state, 'ADMN', 'ADMN_VIEW_CSI_GEOCODE');
    const canEditCSIGeocode = getUserPermission(state, 'ADMN', 'ADMN_EDIT_CSI_GEOCODE');

    return {
        // serviceLineStatusTransitionsLoading,
        accountId,
        canAddDevices,
        canRemoveDevices,
        canEditServiceAddress,
        canViewCSIGeocode,
        canEditCSIGeocode
    };
};

const mapDispatchToProps = {
    getAllowedServiceLineStatusTransitions,
    showModal,
    updateServicesUpdatedFlag,
    getServiceLineHistory,
};

export default connect(mapStateToProps, mapDispatchToProps)(ServiceWrapper);