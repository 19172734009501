import React, { Component } from 'react';
import PropTypes from 'prop-types';
import NotificationSelectInput from './NotificationSelectInput';
import FullScreenLoader from '../../../../../../components/Loader/FullScreenLoader';

class NotificationCard extends Component {
	state = {
		isToggled: false,
		isDisabled: false
	};

	componentDidUpdate(prevProps) {
		if (prevProps.categoryInfo !== this.props.categoryInfo) {
			this.setState({
				isDisabled: false
			});
		}
	}

	handleToggle = () => {
		this.setState((prevState) => ({
			isToggled: !prevState.isToggled
		}));
	};

	disableForSending = () => {
		this.setState({
			isDisabled: true
		});
	};

	checkIsCategoryActive = () => {
		const { categoryInfo } = this.props;

		let isActive = false;

		if (categoryInfo.receivers.find((el) => el.active === true)) {
			isActive = true;
		}

		return isActive;
	};

	render() {
		const { isToggled, isDisabled } = this.state;
		const { categoryInfo, handleNotificationSubscribe, handleNotificationUnsubscribe } = this.props;

		return (
			<div className="card-toggable">
				<div className="card-toggable-header h-with-check">
					<span
						onClick={this.handleToggle}
						className={isToggled ? 'h-with-check-title toggled' : 'h-with-check-title'}
					>
						{categoryInfo.name}
					</span>
					<span className="h-check">
						<span className="notification-channel">
							{categoryInfo.receivers.map((reciver, index) => {
								if (reciver.active) {
									return (
										<span
											key={index}
											className="badge badge-outline-primary badge-tag badge-notification-sms"
										>
											<span>{reciver.type}</span>
											<button
												onClick={() => {
													handleNotificationUnsubscribe({
														notificationCategoryId: categoryInfo.id,
														type: reciver.type
													});
													this.disableForSending();
												}}
												disabled={isDisabled}
												className="btn-tag-remove"
											>
												<i className="fas fa-times" />
											</button>
										</span>
									);
								}

								return null;
							})}
						</span>
						<div className="form-check checkbox-slider checkbox-slider--b-flat">
							<label>
								<input
									type="checkbox"
									disabled={!this.checkIsCategoryActive() || isDisabled}
									checked={this.checkIsCategoryActive()}
									onChange={() => {
										handleNotificationUnsubscribe({
											notificationCategoryId: categoryInfo.id
										});
										setTimeout(() => {
											this.setState({
												isToggled: false
											});
										}, 500);
									}}
								/>
								<span>&nbsp;</span>
							</label>
						</div>
					</span>
				</div>
				<div className={isToggled ? 'card-toggable-body collapse show' : 'card-toggable-body collapse'}>
					{isToggled && (
						<div className="form-row">
							{categoryInfo.receivers.map((reciver, index) => (
								<NotificationSelectInput
									reciverInfo={reciver}
									key={index}
									categoryId={categoryInfo.id}
									handleNotificationSubscribe={handleNotificationSubscribe}
									handleNotificationUnsubscribe={handleNotificationUnsubscribe}
									isDisabled={isDisabled}
									disableForSending={this.disableForSending}
								/>
							))}
						</div>
					)}
				</div>
			</div>
		);
	}
}

NotificationCard.propTypes = {};

export default NotificationCard;
