import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {
	getAccountById,
	emptyAccountDetails,
	showAccountDetailsWizard,
	hideAccountDetailsWizard,
	getAccountPromises,
	emptyAccountPromises,
	showEditServiceRequestWizard
} from '../../actions/accountDetailsActions/accountDetails.actions';

import {
    getNotificationAccountAlert,
    updateNotificationAccountAlert
} from '../../actions/accountDetailsActions/notifications.actions';

import {getAccountDetails, createLoadingSelector, getModalData} from '../../selectors';
import {showModal, showAccessModal} from '../../actions/modal.actions';
import {isEmpty} from '../../utils/helpers';

import AccountInfo from './AccountInfo';
import Loader from '../../components/Loader';
import Panels from './Panels';
import NewOrderWizard from './NewOrderWizard';
import UpdateRequest from './UpdateRequestWizard';
import UpdateService from './UpdateServiceWizard';
import EquipmentAdd from './EquipmentAddWizard';
import EquipmentRemove from './EquipmentRemoveWizard';
import RemoveServiceLines from "./RemoveServiceLinesWizard";
import EquipmentSwap from "./SwapEquipmentWizard";

class AccountDetails extends Component {

	state = {
		documentTitleUpdated: false,
		documentTitle: 'Account Details - camvio.cloud',
		navbarTitle: 'Account Details',
		activeLeftPanelTab: ''
	}

	componentDidMount() {
		const { history } = this.props;

		// Change navbar type and color
		document.title = this.state.documentTitle;
		this.props.changeNavBarType('account', 'accountDetails', this.state.navbarTitle);

        //Check if access CPNI modal was open or not
        const accountId = Number(this.props.match.params.id);
        this.props.getNotificationAccountAlert(accountId);

        if (this.props.isShowAccessModal === false) {
            this.props.showModal('ACCOUNT_ACCESS_MODAL', accountId);
        }
        else {
            this.props.getAccountById(accountId);
            this.props.getAccountPromises(accountId);
        }

		// If user is coming from create new account wizard and want to open create service request wizard
		// we check it and fire action for open new order wizard
		if (history.location.state && history.location.state.openNewServiceWizard === true) {
			let data = {
				wizardType: 'serviceRequest',
				wizardData: {}
			};
			this.props.showAccountDetailsWizard(data);

			history.replace({state: undefined});
		}

		// Open specific tab in the left panel
		if (history.location.state && history.location.state.activeTab) {
			this.setState({
				activeLeftPanelTab: history.location.state.activeTab
			});
		}
	}

	componentDidUpdate(prevProps, prevState) {
		const { accountInfo, history } = this.props;

		// Generate page title
		if (this.state.documentTitleUpdated === false && accountInfo && accountInfo.number && accountInfo.primaryContact) {

			this.setState({
				documentTitleUpdated: true,
				documentTitle:
					'Acct# '+accountInfo.number+' - '+
					(accountInfo.primaryContact.company
						? (accountInfo.primaryContact.company)
						: (accountInfo.primaryContact.firstName+" "+accountInfo.primaryContact.lastName))
					+' - camvio.cloud'
			})
		}

		// Update page title
		if (this.state.documentTitle !== prevState.documentTitle) {
			document.title = this.state.documentTitle;
		}

		if (prevProps.isShowAccessModal !== this.props.isShowAccessModal) {

			const accountId = Number(this.props.match.params.id);
			if (this.props.isShowAccessModal) {
				this.props.getAccountById(accountId);
				this.props.getAccountPromises(accountId);
			}
			else {
				this.props.showModal('ACCOUNT_ACCESS_MODAL', accountId);
			}
		}

		// Open specific tab in the left panel
		if (prevProps.activeLeftPanel !== this.props.activeLeftPanel && this.props.activeLeftPanel !== '') {
			this.setState({
				activeLeftPanelTab: this.props.activeLeftPanel
			})
		}

		// Open specific tab in the left panel
		if (history.location.state && history.location.state.activeTab && this.state.activeLeftPanelTab !== history.location.state.activeTab) {
			this.setState({
				activeLeftPanelTab: history.location.state.activeTab
			});
		}
	}

	componentWillUnmount() {
		this.props.emptyAccountDetails();
		this.props.emptyAccountPromises();
		this.props.showAccessModal(false);
	}

	renderWizardContent = () => {

		const {
			accountInfo,
			userSummary,
			userConfiguration,
			currentLocation,
			changeNavBarType,
			accountDetailsWizardData: { wizardType }
		} = this.props;

		switch (wizardType) {

			// New Service Request Wizard
			case 'serviceRequest':
				return (
					<NewOrderWizard
						userSummary={userSummary}
						contactAddresses={accountInfo.primaryContact.contactAddresses}
						serviceOrderAddresses={accountInfo.serviceOrderAddressesList}
						currentLocation={currentLocation}
						changeNavBarType={changeNavBarType}
						accountInfo={accountInfo}
						userConfiguration={userConfiguration}
						showNewOrderWizardHandler={this.showNewOrderWizardHandler}
						accountStatus={accountInfo.status}
						documentTitle={this.state.documentTitle}
						navbarTitle={this.state.navbarTitle}
						{...this.props}
					/>
				);

			// Update Service Request Wizard
			case 'updateRequest':
				return (
					<UpdateRequest
						showNewOrderWizardHandler={this.showNewOrderWizardHandler}
						changeActiveLeftPanelHandler={this.changeActiveLeftPanelHandler}
						documentTitle={this.state.documentTitle}
						navbarTitle={this.state.navbarTitle}
						{...this.props}
					/>
				);

			// Update Service Order Wizard
			case 'updateService':
				return (
					<UpdateService
						changeActiveLeftPanelHandler={this.changeActiveLeftPanelHandler}
						documentTitle={this.state.documentTitle}
						navbarTitle={this.state.navbarTitle}
						{...this.props}
					/>
				);

			// Add Equipment Wizard
			case 'addEquipment':
				return (
					<EquipmentAdd
						changeActiveLeftPanelHandler={this.changeActiveLeftPanelHandler}
						documentTitle={this.state.documentTitle}
						navbarTitle={this.state.navbarTitle}
						{...this.props}
					/>
				);

			// Remove Equipment Wizard
			case 'removeEquipment':
				return (
					<EquipmentRemove
						changeActiveLeftPanelHandler={this.changeActiveLeftPanelHandler}
						documentTitle={this.state.documentTitle}
						navbarTitle={this.state.navbarTitle}
						{...this.props}
					/>
				);

			// Swap Equipment Wizard
			case 'swapEquipment':
				return (
					<EquipmentSwap
						changeActiveLeftPanelHandler={this.changeActiveLeftPanelHandler}
						documentTitle={this.state.documentTitle}
						navbarTitle={this.state.navbarTitle}
						{...this.props}
					/>
				);

			// Remove Service Lines Wizard
			case 'removeServiceLines':
				return (
					<RemoveServiceLines
						changeActiveLeftPanelHandler={this.changeActiveLeftPanelHandler}
						documentTitle={this.state.documentTitle}
						navbarTitle={this.state.navbarTitle}
						{...this.props}
					/>
				);

			default:
				return null;
		}
	}

	changeActiveLeftPanelHandler = (type) => {
		this.setState({
			activeLeftPanelTab: type
		})
	}

	showServiceUpdateRequestWizardHandler = (serviceOrder, order) => {
		let data = {
			wizardType: 'updateRequest',
			wizardData: {
				serviceOrder,
				order
			}
		};
		this.props.showEditServiceRequestWizard(data);
		this.setState({activeLeftPanelTab: 'ordersAndQuotes'});
	}

	showServiceRequestWizardHandler = (planId, serviceLineId, planRelation) => {
		let data = {
			wizardType: 'updateService',
			wizardData: {
				planId,
				serviceLineId,
				planRelation
			}
		};
		this.props.showAccountDetailsWizard(data);
		this.setState({activeLeftPanelTab: 'account360'});
	}

	showNewOrderWizardHandler = (value) => {
		if (value === true) {
			let data = {
				wizardType: 'serviceRequest',
				wizardData: {}
			};
			this.props.showAccountDetailsWizard(data);
			this.setState({activeLeftPanelTab: 'ordersAndQuotes'});

		} else {
			this.props.hideAccountDetailsWizard();
		}
	}

	showAddEquipmentWizardHandler = (data) => {
		this.props.showAccountDetailsWizard(data);
		this.setState({activeLeftPanelTab: 'serviceAndEquipment'});
	}

	showRemoveEquipmentWizardHandler = (data) => {
		this.props.showAccountDetailsWizard(data);
		this.setState({activeLeftPanelTab: 'serviceAndEquipment'});
	}

	showSwapEquipmentWizardHandler = (data) => {
		this.props.showAccountDetailsWizard(data);
		this.setState({activeLeftPanelTab: 'serviceAndEquipment'});
	}

	handleOnAccountStatusChange = () => {
		const accountId = Number(this.props.match.params.id);
		this.props.getAccountById(accountId);
	}

    //Remove conditional rendering based to accountInfoLoader - multiple rendering
    render() {
        const {activeLeftPanelTab} = this.state;
        const {accountInfo, accountInfoLoader, accountDetailsWizardData, accountPromises, accountAlert } = this.props;

		if (this.props.isShowAccessModal === false) {
			return null;
		}

		if (accountInfoLoader && isEmpty(accountInfo)) {
			return <Loader />;
		}

        return (
            <>
                <div className=
					{"cmv-container-client-selected cmv-container-client-selected-modern cmv-container-client-selected-full-height cmv-container-client-selected-account-details"
						+
					(!isEmpty(accountDetailsWizardData) ? ' cmv-container-client-selected-wizard' : '')}
                >
                    {!isEmpty(accountInfo) && !accountInfoLoader && (
                        <>

                            <AccountInfo
                                accountInfo={accountInfo}
                                showNewOrderWizard={!isEmpty(accountDetailsWizardData)}
                                accountPromises={accountPromises}
                                refreshAccountStatus={this.handleAccountStatusModal}
								onAccountStatusChange={this.handleOnAccountStatusChange}
                                accountAlert={accountAlert}
                            />

							{!isEmpty(accountDetailsWizardData) &&
							this.renderWizardContent()
							}

							{isEmpty(accountDetailsWizardData) &&
							<Panels
								showNewOrderWizardHandler={this.showNewOrderWizardHandler}
								showServiceRequestWizardHandler={this.showServiceRequestWizardHandler}
								showServiceUpdateRequestWizardHandler={this.showServiceUpdateRequestWizardHandler}
								showAddEquipmentWizardHandler={this.showAddEquipmentWizardHandler}
								showRemoveEquipmentWizardHandler={this.showRemoveEquipmentWizardHandler}
								showSwapEquipmentWizardHandler={this.showSwapEquipmentWizardHandler}
								activeLeftPanelTab={activeLeftPanelTab}
								{...this.props}
							/>
							}

						</>
					)}
				</div>
				
			</>
		);
	}
}

AccountDetails.propTypes = {
    accountInfo: PropTypes.object.isRequired,
    userSummary: PropTypes.object.isRequired,
    accountInfoLoader: PropTypes.bool,
    isShowAccessModal: PropTypes.bool,
    showNewOrderWizard: PropTypes.bool,
    currentLocation: PropTypes.object,
    changeNavBarType: PropTypes.func,
    getAccountById: PropTypes.func,
    emptyAccountDetails: PropTypes.func,
    showModal: PropTypes.func,
    showAccessModal: PropTypes.func
};

const loader = createLoadingSelector(['SET_ACCOUNT_DETAILS']);

const mapStateToProps = (state) => {
	const accountInfo = getAccountDetails(state).accountDetails.accountInfo,
		showNewOrderWizard = getAccountDetails(state).accountDetails.showNewOrderWizard,
		showUpdateServiceRequestWizard = getAccountDetails(state).accountDetails.showUpdateServiceRequestWizard,
		accountDetailsWizardData = getAccountDetails(state).accountDetails.accountDetailsWizardData,
		activeLeftPanel = getAccountDetails(state).accountDetails.activeLeftPanel,
		isShowAccessModal = getModalData(state).showAccessModal,
		accountInfoLoader = loader(state),
		accountPromises = getAccountDetails(state).accountDetails.accountPromises,
	    accountAlert = getAccountDetails(state).notifications.accountAlert;

    return {
        accountInfo,
        accountInfoLoader,
        isShowAccessModal,
        showNewOrderWizard,
        showUpdateServiceRequestWizard,
        accountDetailsWizardData,
        activeLeftPanel,
        accountPromises,
        accountAlert,
    };
};

const mapDispatchToProps = {
    getAccountById,
    emptyAccountDetails,
    showModal,
    showAccessModal,
    showAccountDetailsWizard,
    hideAccountDetailsWizard,
    getAccountPromises,
    emptyAccountPromises,
	showEditServiceRequestWizard,
    getNotificationAccountAlert,
    updateNotificationAccountAlert,
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountDetails);
