import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, {Search} from 'react-bootstrap-table2-toolkit';
import paginationFactory, {
    PaginationListStandalone,
    SizePerPageDropdownStandalone,
    PaginationProvider,
    PaginationTotalStandalone
} from 'react-bootstrap-table2-paginator';
import moment from 'moment';
import {connect} from 'react-redux';
import {getAdjustments, emptyAdjustments} from '../../../../../../actions/accountDetailsActions/billing.actions';
import {showModal} from '../../../../../../actions/modal.actions';
import {getUserPermission, getAccountDetails, createLoadingSelector} from '../../../../../../selectors';
import Loader from '../../../../../../components/Loader';
import {isEmpty} from '../../../../../../utils/helpers';

const {SearchBar} = Search;

class Adjustments extends Component {
    state = {};

    componentDidMount() {
        const {accountInfo} = this.props;
        this.props.getAdjustments(accountInfo.id);

    }

    componentWillUnmount() {
        this.props.emptyAdjustments();
        // this.props.emptyAdjustmentTypes();
        // this.props.emptyAccountServicelines();
    }

    showDeleteAdjustment = (adjustmentId) => {
        const {accountInfo} = this.props;
        const info = {
            accountId: accountInfo.id,
            itemId: adjustmentId,
            type: 'adjustment'
        };
        this.props.showModal('GENERIC_DELETE_BILING_MODAL', info);
    };

    dateFormater = (cell, row, rowIndex) => {
        return (
            <Fragment>
                {moment(cell).format('MM/DD/YYYY')} <span className="td-subtitle">{row.createdBy}</span>
            </Fragment>
        );
    };

    amountFormater = (cell, row, rowIndex) => {
        return (
            <Fragment>
                ${cell.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                <span className="td-subtitle">{row.serviceLineNum}</span>
            </Fragment>
        );
    };

    actionFormater = (cell, row, rowIndex) => {
        const {editAdjustmentPermission, deleteAdjustmentPermission} = this.props;
        return (
            <div className="tr-actions">
                {editAdjustmentPermission && (
                    <button
                        onClick={() => this.props.showModal('ADD_ADJUSTMENT_MODAL', {row, ...{type: 'edit'}})}
                        className="btn"
                        type="button"
                    >
                        <i className="fas fa-edit"/>
                    </button>
                )}

                {deleteAdjustmentPermission && (
                    <button onClick={() => this.showDeleteAdjustment(row.id)} className="btn" type="button">
                        <i className="fas fa-trash"/>
                    </button>
                )}
            </div>
        );
    };

    statusFormater = (cell, row, rowIndex) => {
        if (cell === 'PENDING') {
            return <span className="badge badge-warning">Pending</span>;
        } else if (cell === 'APPROVED') {
            return <span className="badge badge-success">Approved</span>;
        }
    };

    noteFormater = (cell, row, rowIndex) => {
        if (cell !== 'undefined') {
            return <div className="td-note">{cell}</div>;
        }
    };

    customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total">
			Showing {from} to {to} of {size} Results
		</span>
    );

    render() {
        const {adjustmentsData, adjustmentsLoader, addAdjustmentPermission} = this.props;

        if (isEmpty(adjustmentsData) && adjustmentsLoader) {
            return <Loader/>;
        }

        const options = {
            paginationSize: 4,
            custom: true,
            totalSize: this.props.adjustmentsData.length,
            pageStartIndex: 1,
            // alwaysShowAllBtns: true, // Always show next and previous button
            // withFirstAndLast: false, // Hide the going to First and Last page button
            // hideSizePerPage: true, // Hide the sizePerPage dropdown always
            hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
            firstPageText: 'First',
            prePageText: 'Previous',
            nextPageText: 'Next',
            lastPageText: 'Last',
            // nextPageTitle: 'First page',
            // prePageTitle: 'Pre page',
            // firstPageTitle: 'Next page',
            // lastPageTitle: 'Last page',
            showTotal: true,
            paginationTotalRenderer: this.customTotal,
            sizePerPageList: [
                {
                    text: '10',
                    value: 10
                },
                {
                    text: '25',
                    value: 25
                },
                {
                    text: '50',
                    value: 50
                },
                {
                    text: '100',
                    value: 100
                }
                // {
                // 	text: 'All',
                // 	value: this.props.adjustmentsData.length
                // }
            ] // A numeric array is also available. the purpose of above example is custom the text
        };

        const columns = [
            {
                dataField: 'adjustmentDatetime',
                text: 'Date',
                sort: true,
                formatter: this.dateFormater
            },
            {
                dataField: 'itemType',
                text: 'Type',
                sort: true
            },
            {
                dataField: 'itemDescription',
                text: 'Description',
                sort: true
            },
            {
                dataField: 'amount',
                text: 'Amount',
                sort: true,
                formatter: this.amountFormater,
                classes: 'h-align-r'
            },
            {
                dataField: 'status',
                text: '',
                sort: true,
                formatter: this.statusFormater
            },
            {
                dataField: 'actions',
                isDummyField: true,
                text: 'Actions',
                formatter: this.actionFormater,
                headerClasses: 'h-align-r'
            },
            {
                dataField: 'note',
                text: '',
                formatter: this.noteFormater,
                classes: (cell, row, rowIndex, colIndex) => {
                    if (row.note !== 'undefined') return 'with-note';
                    return '';
                }
            },
            {
                dataField: 'createdBy',
                text: '',
                hidden: true
            }
        ];

        const defaultSorted = [
            {
                dataField: 'adjustmentDatetime',
                order: 'desc'
            }
        ];

        const rowStyle = (row, rowIndex) => {
            if (row) {
                let classes = null;

                if (row.note) {
                    classes = 'has-note';
                }

                if (rowIndex % 2 === 0) {
                    classes += ' odd';
                } else {
                    classes += ' even';
                }

                return classes;
            }
        };

        return (
            <Fragment>
                <div className="cmv-container-table">
                    <PaginationProvider pagination={paginationFactory(options)}>
                        {({paginationProps, paginationTableProps}) => (
                            <Fragment>
                                <ToolkitProvider
                                    bootstrap4
                                    keyField="id"
                                    data={adjustmentsData}
                                    columns={columns}
                                    defaultSorted={defaultSorted}
                                    search
                                >
                                    {(props) => (
                                        <Fragment>
                                            <div className="row">
                                                <div className="col-sm-12 col-md-6">
                                                    <label>
                                                        Show <SizePerPageDropdownStandalone {...paginationProps} />{' '}
                                                        entries
                                                    </label>
                                                </div>
                                                <div className="col-sm-12 col-md-6" style={{textAlign: 'right'}}>
                                                    <label>
                                                        Search:{' '}
                                                        <SearchBar
                                                            className="form-control form-control-sm"
                                                            {...props.searchProps}
                                                        />
                                                        {addAdjustmentPermission && (
                                                            <button
                                                                onClick={() =>
                                                                    this.props.showModal('ADD_ADJUSTMENT_MODAL', {
                                                                        type: 'create',
                                                                        applyOnPeriod: 'endOfBillCycle',
                                                                    })}
                                                                className="btn btn-primary"
                                                                style={{margin: '5px'}}
                                                            >
                                                                <i className="fas fa-plus"/> New
                                                            </button>
                                                        )}
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <BootstrapTable
                                                    wrapperClasses="table-responsive react-table-layout"
                                                    classes="table table-striped cmv-table"
                                                    bordered={false}
                                                    rowClasses={rowStyle}
                                                    noDataIndication="No data found"
                                                    {...paginationTableProps}
                                                    {...props.baseProps}
                                                />
                                            </div>
                                            <div className="row" style={{marginTop: '10px'}}>
                                                <div className="col-sm-12 col-md-5">
                                                    <PaginationTotalStandalone {...paginationProps} />
                                                </div>
                                                <div className="col-sm-12 col-md-7">
                                                    <PaginationListStandalone {...paginationProps} />
                                                </div>
                                            </div>
                                        </Fragment>
                                    )}
                                </ToolkitProvider>
                            </Fragment>
                        )}
                    </PaginationProvider>
                </div>
            </Fragment>
        );
    }
}

Adjustments.propTypes = {
    accountInfo: PropTypes.object.isRequired
};

const loader = createLoadingSelector(['SET_ADJUSTMENTS']);

const mapStateToProps = (state) => {
    const adjustmentsData = getAccountDetails(state).billing.adjustments,
        adjustmentsLoader = loader(state),
        addAdjustmentPermission = getUserPermission(state, 'CAM', 'CAM_ACCT_EDIT_ADJUSTMENTS'),
        editAdjustmentPermission = getUserPermission(state, 'CAM', 'CAM_EDIT_PENDING_CREDIT_AND_CHARGES'),
        deleteAdjustmentPermission = getUserPermission(state, 'CAM', 'CAM_DELETE_PENDING_CREDIT_AND_CHARGES');

    return {
        adjustmentsData,
        adjustmentsLoader,
        addAdjustmentPermission,
        editAdjustmentPermission,
        deleteAdjustmentPermission
    };
};

const mapDispatchToProps = {
    getAdjustments,
    showModal,
    emptyAdjustments
    // emptyAdjustmentTypes,
    // emptyAccountServicelines
};

export default connect(mapStateToProps, mapDispatchToProps)(Adjustments);
