import React, { Component, Fragment } from 'react';
import {
	IncludedFeatures,
	IncludedPackages,
	MandatoryFeatures,
	MandatoryPackages,
	OptionalFeatures,
	OptionalPackages
} from './ProductsItems';

class ProductsServiceline extends Component {
	state = {
		isColapsed: true
	};

	showHideColaps = () => {
		this.setState((prevState) => ({
			isColapsed: !prevState.isColapsed
		}));
	};

	handleMandatoryPackages = (packageId, lineIndex, item) => {
		const { values, setFieldValue } = this.props;
		item['action'] = 'ENABLE';
		let updateFeatures = values.features.filter((feature) => feature.featurePackageId !== item.featurePackageId);

		updateFeatures = [ ...updateFeatures, item ];
		setFieldValue(`features`, updateFeatures);
	};

	handleOptionalPackagesCheckbox = (packageId, lineIndex, item) => {
		const { values, setFieldValue } = this.props;
		let updateFeatures;
		if (values.features.findIndex((feature) => feature.featurePackageId === packageId) !== -1) {
			updateFeatures = values.features.filter((feature) => feature.featurePackageId !== item.featurePackageId);
		} else {
			updateFeatures = [ ...values.features ];
			updateFeatures = [ ...updateFeatures, item ];
		}

		setFieldValue(`features`, updateFeatures);
	};

	handleOptionalFeature = (featureId, lineIndex, item) => {
		const { values, setFieldValue } = this.props;
		item['action'] = 'ENABLE';
		let updateFeatures;
		if (values.features.findIndex((feature) => feature.id === featureId) !== -1) {
			updateFeatures = values.features.filter((feature) => feature.id !== item.id);
		} else {
			updateFeatures = [ ...values.features ];
			updateFeatures = [ ...updateFeatures, item ];
		}

		setFieldValue(`features`, updateFeatures);
	};

	handleVariableFeatureQuantity = (item, quantity) => {
		const { values, setFieldValue } = this.props;

		// If quantity is positive
		if (parseInt(quantity) > 0) {

			// Get item action
			item['action'] = values.features.filter((feature) => feature.id === item.id)[0].action;

			// Pick current feature
			let updatedFeature = item;

			// Update quantity
			updatedFeature.featureVariabledQuantity = parseInt(quantity);

			// Pick all features except current
			let otherFeatures = values.features.filter((feature) => feature.id !== item.id);

			// Add updated feature
			let updatedFeatures = [...otherFeatures, updatedFeature];

			// Update form
			setFieldValue(`features`, updatedFeatures);
		}
	};

	handleVariableFeaturePrice = (item, price) => {
		const { values, setFieldValue } = this.props;

		item['action'] = values.features.filter((feature) => feature.id === item.id)[0].action;

		let newPrice = price;

		// If price is a valid number
		if (!isNaN(Number(newPrice))) {

			// If price includes decimal
			if (newPrice.includes('.')) {

				// Get cents
				let newPriceCents = price.split('.').pop();

				// Round to max two characters after the decimal point
				if (newPriceCents.length > 2) {
					newPrice = newPrice.slice(0, (price.indexOf(".")) + 3);
				}
			}

			// Pick current feature
			let updatedFeature = item;

			// Update activation price...
			if (item.featureType === 'ACTIVATION') {
				updatedFeature.oneTimePrice = newPrice;
				updatedFeature.featureVariabledPrice = newPrice;
			}
			// ... or monthly price
			else {
				updatedFeature.recurringMonthlyPrice = newPrice;
				updatedFeature.featureVariabledPrice = newPrice;
			}

			// Pick all features except current
			let otherFeatures = values.features.filter((feature) => feature.id !== item.id);

			// Add updated feature
			let updatedFeatures = [...otherFeatures, updatedFeature];

			// Update form
			setFieldValue(`features`, updatedFeatures);
		}
	};

	handleOptionalPackages = (packageId, lineIndex, item) => {
		const { values, setFieldValue } = this.props;

		let updateFeatures = values.features.filter(
			(feature) => feature.featurePackageId !== item.featurePackageId
		);

		updateFeatures = [ ...updateFeatures, item ];
		setFieldValue(`features`, updateFeatures);
	};

	linePlans = () => {
		const { planInfo, values, errors } = this.props;
		let line = [];

		if (planInfo.planRelation === 'Main') {
			if (planInfo.mainIncludedFeatures) {
				line.push(<IncludedFeatures planFeature={planInfo.mainIncludedFeatures} />);
			}
			if (planInfo.mainIncludedFeaturePackages) {
				line.push(<IncludedPackages planFeature={planInfo.mainIncludedFeaturePackages} />);
			}
			if (planInfo.mainMandatoryFeatures) {
				line.push(<MandatoryFeatures planFeature={planInfo.mainMandatoryFeatures} />);
			}
			if (planInfo.mainMandatoryFeaturePackages) {
				line.push(
					<MandatoryPackages
						features={values.features}
						planFeature={planInfo.mainMandatoryFeaturePackages}
						handleMandatoryPackages={this.handleMandatoryPackages}
					/>
				);
			}
			if (planInfo.mainOptionalFeatures) {
				line.push(
					<OptionalFeatures
						features={values.features}
						planFeature={planInfo.mainOptionalFeatures}
						handleOptionalFeature={this.handleOptionalFeature}
						handleVariableFeatureQuantity={this.handleVariableFeatureQuantity}
						handleVariableFeaturePrice={this.handleVariableFeaturePrice}
						errors={errors}
					/>
				);
			}
			if (planInfo.mainOptionalFeaturePackages) {
				line.push(
					<OptionalPackages
						features={values.features}
						planFeature={planInfo.mainOptionalFeaturePackages}
						handleOptionalPackages={this.handleOptionalPackages}
						handleOptionalPackagesCheckbox={this.handleOptionalPackagesCheckbox}
						errors={errors}
					/>
				);
			}
		} else if (planInfo.planRelation === 'Required') {
			if (planInfo.requiredIncludedFeatures) {
				line.push(<IncludedFeatures planFeature={planInfo.requiredIncludedFeatures} />);
			}
			if (planInfo.requiredIncludedFeaturePackages) {
				line.push(<IncludedPackages planFeature={planInfo.requiredIncludedFeaturePackages} />);
			}
			if (planInfo.requiredMandatoryFeatures) {
				line.push(
					<MandatoryFeatures features={values.features} planFeature={planInfo.requiredMandatoryFeatures} />
				);
			}
			if (planInfo.requiredMandatoryFeaturePackages) {
				line.push(
					<MandatoryPackages
						features={values.features}
						planFeature={planInfo.requiredMandatoryFeaturePackages}
						handleMandatoryPackages={this.handleMandatoryPackages}
					/>
				);
			}

			if (planInfo.requiredOptionalFeatures) {
				line.push(
					<OptionalFeatures
						features={values.features}
						planFeature={planInfo.requiredOptionalFeatures}
						handleOptionalFeature={this.handleOptionalFeature}
						handleVariableFeatureQuantity={this.handleVariableFeatureQuantity}
						handleVariableFeaturePrice={this.handleVariableFeaturePrice}
						errors={errors}
					/>
				);
			}
			if (planInfo.requiredOptionalFeaturePackages) {
				line.push(
					<OptionalPackages
						features={values.features}
						planFeature={planInfo.requiredOptionalFeaturePackages}
						handleOptionalPackages={this.handleOptionalPackages}
						handleOptionalPackagesCheckbox={this.handleOptionalPackagesCheckbox}
						errors={errors}
					/>
				);
			}
		} else if (planInfo.planRelation === 'Additional') {
			if (planInfo.additionalIncludedFeatures) {
				line.push(
					<IncludedFeatures features={values.features} planFeature={planInfo.additionalIncludedFeatures} />
				);
			}
			if (planInfo.additionalIncludedFeaturePackages) {
				line.push(
					<IncludedPackages
						features={values.features}
						planFeature={planInfo.additionalIncludedFeaturePackages}
					/>
				);
			}
			if (planInfo.additionalMandatoryFeatures) {
				line.push(
					<MandatoryFeatures features={values.features} planFeature={planInfo.additionalMandatoryFeatures} />
				);
			}
			if (planInfo.additionalMandatoryFeaturePackages) {
				line.push(
					<MandatoryPackages
						features={values.features}
						planFeature={planInfo.additionalMandatoryFeaturePackages}
						handleMandatoryPackages={this.handleMandatoryPackages}
					/>
				);
			}
			if (planInfo.additionalOptionalFeatures) {
				line.push(
					<OptionalFeatures
						features={values.features}
						planFeature={planInfo.additionalOptionalFeatures}
						handleOptionalFeature={this.handleOptionalFeature}
						handleVariableFeatureQuantity={this.handleVariableFeatureQuantity}
						handleVariableFeaturePrice={this.handleVariableFeaturePrice}
						errors={errors}
					/>
				);
			}
			if (planInfo.additionalOptionalFeaturePackages) {
				line.push(
					<OptionalPackages
						features={values.features}
						planFeature={planInfo.additionalOptionalFeaturePackages}
						handleOptionalPackages={this.handleOptionalPackages}
						handleOptionalPackagesCheckbox={this.handleOptionalPackagesCheckbox}
						errors={errors}
					/>
				);
			}
		}

		return line.map((item, index) => <Fragment key={index}>{item}</Fragment>);
	};

	render() {
		const { isColapsed } = this.state;
		const { planInfo } = this.props;

		return (
			<div className="serviceline-wrapper" data-servline-wrapper-id="sl001">
				<div className="serviceline-header">
					<h4
						className={isColapsed === true ? '' : 'collapsed'}
						onClick={this.showHideColaps}
						data-toggle="collapse"
					>
						<span className="badge badge-line-type">{planInfo.planRelation}</span>
						{/* <span className="ml-auto">{lineInfo.number}</span> */}
					</h4>
				</div>
				<div className={isColapsed === true ? 'serviceline-body collapse show' : 'serviceline-body collapse'}>
					{this.linePlans()}
				</div>
			</div>
		);
	}
}

export default ProductsServiceline;
