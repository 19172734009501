import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser';
class Bundle extends Component {
	state = {
		isDetailsOpen: false
	};

	showDetailsHandler = () => {
		this.setState((prevState) => ({
			isDetailsOpen: !prevState.isDetailsOpen
		}));
	};
	render() {
		const { currentItem, selectedItem, setSelectedItem } = this.props;
		const { isDetailsOpen } = this.state;

		// if (selectedItem !== '' && selectedItem !== currentItem.id) {
		// 	return null;
		// }

		return (
			<div
				className={
					selectedItem === currentItem.id ? (
						// 'card-horizontal card-bundle card-bundle-selected'
						'card-horizontal card-bundle'
					) : (
						'card-horizontal card-bundle'
					)
				}
			>
				<div className="card-body">
					<div className="bundle-wrapper">
						<div className="bundle-header">
							<span className="bundle-title">{currentItem.description}</span>
						</div>
						<div className="bundle-description">{ReactHtmlParser(currentItem.note)}</div>
						<div className="bundle-body">
							<div className="row">
								<div className="col-md">
									{currentItem.plans.map((plan, index) => (
										<i className={plan.serviceModel.icon} key={index} />
									))}
								</div>
								<div className="col-md-6">
									<p className="lead">
										{currentItem.plans.map((plan, index) => {
											return index === 0
												? plan.serviceModel.description
												: ' + ' + plan.serviceModel.description;
										})}
									</p>
								</div>
								<div className="col-md">
									{!currentItem.hideFromPrice&&<p className="bundle-price">
										From{' '}
										<span className="lead">
											${currentItem.fromPrice.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
										</span>{' '}
										per month
									</p>}
								</div>
							</div>
							<div className={isDetailsOpen ? 'bundle-details collapse show' : 'bundle-details collapse'}>
								<div className="row">
									{currentItem.plans.map((plan, index) => (
										<div className="col-md" key={index}>
											<h5>{plan.description}</h5>
											<p>{ReactHtmlParser(plan.note)}</p>
										</div>
									))}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="card-footer">
					<button
						className="btn btn-primary btn-select"
						onClick={() => setSelectedItem(currentItem, 'bundles')}
						type="submit"
					>
						{/* <i
							className={selectedItem === currentItem.id ? 'fas fa-check-circle' : 'fas fa-circle'}
						/> */}
						&nbsp;Select
					</button>
					<button className="btn btn-outline-primary" onClick={this.showDetailsHandler} type="button">
						Details
					</button>
				</div>
			</div>
		);
	}
}

export default Bundle;
