import React, {Component} from 'react';
import moment from "moment";

export class GroupDetails extends Component {

    render() {

        const {
            items,
        } = this.props;

        return (
            <div className="provisioning-group-details">

                {items.map(item =>
                <div>

                    <h6>
                        {item.provisioningInterfaceName}
                        <span
                            className={"provisioning-group-status " + (
                            item.status === "NEW" ? "bg-light border-success" :
                            item.status === "PROCESSING" ? "bg-light-with-pulse border-success" :
                            item.status === "PROCESSED" ? "bg-success" :
                            item.status === "WARNING" ? "bg-warning" :
                            item.status === "ERROR" ? "bg-danger" :
                            "bg-light"
                            )}
                        >
                            {item.status}
                        </span>
                    </h6>

                    {item.type &&
                    <p>Type: {item.type}</p>
                    }

                    {item.serviceLineFeatureName &&
                    <p>Feature: {item.serviceLineFeatureName}</p>
                    }

                    {item.provisioningCmdName &&
                    <p>Provisioning Cmd: {item.provisioningCmdName}</p>
                    }

                    {item.entryDateTime &&
                    <p>Entry Date: {moment(item.entryDateTime).format("MM/DD/YYYY @ h:mmA")}</p>
                    }

                    {item.finishedDateTime &&
                    <p>Finished Date: {moment(item.finishedDateTime).format("MM/DD/YYYY @ h:mmA")}</p>
                    }

                    {item.jsonRequest && <>
                        <p>Json Request</p>
                        <div className="cmv-container-log-viewer"><pre>{item.jsonRequest}</pre></div>
                        {/*<span className="d-block">[2021-08-12 08:44:16] receiveTask - Dummy receive task, TaskId: 1257703</span>*/}
                    </>
                    }
                    {item.jsonResponse && <>
                        <p>Json Response</p>
                        <div className="cmv-container-log-viewer"><pre>{item.jsonResponse}</pre></div>
                    </>
                    }

                </div>
                )}

            </div>
        );
    }
}

export default GroupDetails;
