import React from 'react';
import PropTypes from 'prop-types';

const Log = ({ logInfo }) => {
	return (
		<span className="d-block">
			[{logInfo.startTime}] {logInfo.activityType} - {logInfo.activityName}, TaskId: {logInfo.id}
			{logInfo.durationInMillis && `, Duration: ${logInfo.durationInMillis / 1000} s`}
		</span>
	);
};

Log.propTypes = {
	logInfo: PropTypes.object.isRequired
};

export default Log;
