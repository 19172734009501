import moment from 'moment';
import {isEmpty} from '../../../../utils/helpers';

export const parseDateTimeFormat = (date) => {
	const today = moment();
	const yesterday = moment().subtract(1, 'day');

	if (moment(date).isSame(today, 'day')) {
		return 'Today';
	} else if (moment(date).isSame(yesterday, 'day')) {
		return 'Yesterday';
	} else {
		return moment(date).format('MM/DD/YYYY');
	}
};

export const getformatedFilters = (filters, searchType, userId, searchServiceOrdersType) => {
	let updatedFilters;
	const serviceOrderType = getServiceOrderType(searchServiceOrdersType);
	if (searchType === 'userInput') {
		updatedFilters = Object.keys(filters).filter((x) => x === 'userInput').reduce((acc, current) => {
			return {
				...acc,
				[filters[current].option]: filters[current].value
			};
		}, {});

		if(updatedFilters['userInput'] !== 'orderId' && serviceOrderType !== null) {
			updatedFilters['serviceOrderType'] = serviceOrderType;
		}
	} else {
		updatedFilters = { ...filters };

		updatedFilters['completed'] = false;
		updatedFilters['incomplete'] = false;
		updatedFilters['notStarted'] = false;
		updatedFilters['createdBy'] = '';

		switch(searchType){
			case 'myOrders' :
				updatedFilters['createdBy'] = userId;
				break;
			case 'completed' :
				updatedFilters['completed'] = true;
				break;
			case 'incomplete' :
				updatedFilters['incomplete'] = true;
				break;
			case 'notStarted' :
				updatedFilters['notStarted'] = true;
				break;
			default :
				break;
		}

		if(!updatedFilters['completed'] && serviceOrderType !== null) {
			updatedFilters['serviceOrderType'] = serviceOrderType;
		}

		if (updatedFilters['fromDate'] !== '') {
			updatedFilters['fromDate'] = moment(updatedFilters['fromDate']).format('YYYY-MM-DD');
		}
		if (updatedFilters['toDate'] !== '') {
			updatedFilters['toDate'] = moment(updatedFilters['toDate']).format('YYYY-MM-DD');
		}

		for (let filter in updatedFilters) {
			if (isEmpty(updatedFilters[filter])) {
				delete updatedFilters[filter];
			}
		}

	}

	return updatedFilters;
};

const getServiceOrderType = (value) => {
	switch(value){
		case 'newService' :
			return 'NEW_SERVICE';
		case 'changeService' :
			return 'CHANGE_SERVICE';
		case 'newSignup' :
			return 'NEW_SIGNUP';
		case 'newSurvey' :
			return 'NEW_SURVEY';
		default :
			return null;
	}
}
