import React, { Component, Fragment } from 'react';
import moment from 'moment';
import { generateAddress } from '../../../utils/generateAddresses';
import {generateFullZip} from "../utils";

class Summary extends Component {
	render() {
		const {
			values,
			accountTypesOptions,
			accountTermsOptions,
			accountTiersOptions,
			billCyclesOptions,
			transformQuestionsOptions,
			taxCodesData
		} = this.props;

		const { mailingAddress } = values.accountHolder;
		const { billingAddress } = values.billingDetails;

		return (
			<div className="tab-pane show active">
				<div className="data-column-container">
					<div className="data-column data-column-summary">
						<h4>Account Holder</h4>
						<dl>
							<dt>Type</dt>
							<dd>
								{accountTypesOptions &&
									accountTypesOptions.find((x) => x.value === values.accountHolder.accountType).label}
							</dd>
							{values.accountTermsInfo.accountTerm && (
								<Fragment>
									<dt>Terms</dt>
									<dd>
										{accountTermsOptions &&
											accountTermsOptions.find(
												(x) => x.value === values.accountTermsInfo.accountTerm
											).label}
									</dd>
								</Fragment>
							)}
							{values.accountTermsInfo.accountTier && (
								<Fragment>
									<dt>Risk Level</dt>
									<dd>
										{accountTiersOptions &&
											`${accountTiersOptions.find(
												(x) => x.value === values.accountTermsInfo.accountTier
											).label}, Required Deposit: $${accountTiersOptions
												.find((x) => x.value === values.accountTermsInfo.accountTier)
												.deposit.toFixed(2)
												.replace(/\d(?=(\d{3})+\.)/g, '$&,')} `}
									</dd>
								</Fragment>
							)}
							<dt>Name</dt>
							<dd>{`${values.accountHolder.firstName} ${values.accountHolder.middleName} ${values
								.accountHolder.lastName}`}</dd>
							{values.accountHolder &&
							values.accountHolder.driverLicense && (
								<Fragment>
									<dt>Driver's license</dt>
									<dd>{values.accountHolder.driverLicense || 'N/A'}</dd>
								</Fragment>
							)}

							{values.accountHolder.company && (
								<Fragment>
									<dt>Company</dt>
									<dd>{values.accountHolder.company || 'N/A'}</dd>
								</Fragment>
							)}

							{values.accountHolder &&
							values.accountHolder.dateOfBirth && (
								<Fragment>
									<dt>Date of Birth</dt>
									<dd>
										{moment(values.accountHolder.dateOfBirth.toString()).format('MM/DD/YYYY') ||
											'N/A'}
									</dd>
								</Fragment>
							)}

							{/* <dd>{values.accountHolder.dateOfBirth || 'N/A'}</dd> */}
							{values.accountHolder &&
							values.accountHolder.mobileNumber &&
							values.accountHolder.mobileNumber.number && (
								<Fragment>
									<dt>Mobile Number</dt>
									<dd>
										{values.accountHolder.mobileNumber.verified && (
											<span className="badge-verified" />
										)}
										{values.accountHolder.mobileNumber.number || 'N/A'}
									</dd>
								</Fragment>
							)}

							{values.accountHolder &&
							values.accountHolder.businessNumber &&
							values.accountHolder.businessNumber.number && (
								<Fragment>
									<dt>Business Number</dt>
									<dd>
										{values.accountHolder.businessNumber.number || 'N/A'}
									</dd>
								</Fragment>
							)}

							{values.accountHolder &&
							values.accountHolder.homeNumber &&
							values.accountHolder.homeNumber.number && (
								<Fragment>
									<dt>Home Number</dt>
									<dd>{values.accountHolder.homeNumber.number || 'N/A'}</dd>
								</Fragment>
							)}

							{values.accountHolder &&
							values.accountHolder.personalEmail && (
								<Fragment>
									<dt>Personal Email</dt>
									<dd>{values.accountHolder.personalEmail || 'N/A'}</dd>
								</Fragment>
							)}

							{values.accountHolder.businessEmail && (
								<Fragment>
									<dt>Business Email</dt>
									<dd>{values.accountHolder.businessEmail || 'N/A'}</dd>
								</Fragment>
							)}

							{values.accountHolder.memberNumber && (
								<Fragment>
									<dt>Member Number</dt>
									<dd>{values.accountHolder.memberNumber || 'N/A'}</dd>
								</Fragment>
							)}

							{values &&
								values.accountHolder &&
								values.accountHolder.securities &&
								values.accountHolder.securities.map((security, inx) => {
									if (security.answer !== '' && security.questionId !== '') {
										return (
											<Fragment key={inx}>
												<dt>
													{transformQuestionsOptions &&
														transformQuestionsOptions.find(
															(x) => x.value === security.questionId
														).label}
												</dt>
												<dd>{security.answer}</dd>
											</Fragment>
										);
									}

									return null;
								})}
						</dl>

						<h4>Account Security</h4>
						<dl>
							<dt>Security Phrase</dt>
							<dd>{values.accountHolder.securityPhrase || ''}</dd>
						</dl>

						<h4>Billing Info</h4>
						<dl>
							{values.accountTermsInfo.billCycle && (
								<Fragment>
									<dt>Billing Cycle</dt>
									<dd>
										{billCyclesOptions &&
											billCyclesOptions.find((x) => x.value === values.accountTermsInfo.billCycle)
												.label}
									</dd>
								</Fragment>
							)}
							<dt>Billing Address</dt>
							<dd>
								{values.billingDetails.showBillingAddress ? (
									`${generateAddress(
										billingAddress.address1,
										billingAddress.address2,
										billingAddress.address3,
										billingAddress.city,
										billingAddress.state,
										generateFullZip(billingAddress)
									)}`
								) : (
									`${generateAddress(
										mailingAddress.address1,
										mailingAddress.address2,
										mailingAddress.address3,
										mailingAddress.city,
										mailingAddress.state,
										generateFullZip(mailingAddress)
									)}`
								)}
							</dd>
							<dt>AutoPay</dt>
							<dd>Not enabled</dd>
							{values.billingDetails.discounts &&
								values.billingDetails.discounts.length > 0 &&
								values.billingDetails.discounts.map((discount, index) => (
									<Fragment key={index}>
										<dt>{`Discounts ${index + 1}`}</dt>
										<dd>{`${discount.name}, ${moment(discount.validFrom).format(
											'MM/DD/YYYY'
										)} - ${moment(discount.validTo).format(
											'MM/DD/YYYY'
										)}, ${discount.percentage}%, $${discount.amount
											.toFixed(2)
											.replace(/\d(?=(\d{3})+\.)/g, '$&,')}${discount.retail === true &&
											', Retail'}${discount.service === true && ', Service'}`}</dd>
									</Fragment>
								))}
							{values.billingDetails.recurringCredits &&
								values.billingDetails.recurringCredits.length > 0 &&
								values.billingDetails.recurringCredits.map((credit, index) => (
									<Fragment key={index}>
										<dt>{`Recurring credit ${index + 1}`}</dt>
										<dd>{`${credit.name}, ${moment(credit.validFrom).format(
											'MM/DD/YYYY'
										)} - ${moment(credit.validTo).format('MM/DD/YYYY')}, $${credit.amount
											.toFixed(2)
											.replace(/\d(?=(\d{3})+\.)/g, '$&,')}, ${credit.isActive === true
											? 'Active'
											: 'Pending'}`}</dd>
									</Fragment>
								))}

							{values.billingDetails.taxExemptions &&
								values.billingDetails.taxExemptions.length > 0 &&
								values.billingDetails.taxExemptions.map((tax, index) => (
									<Fragment key={index}>
										<dt>{`Tax Exemption ${index + 1}`}</dt>
										<dd>
											{taxCodesData &&
												taxCodesData.find((x) => x.id === Number(tax.taxCodeId)).description}
											{`, ${tax.type}`}
										</dd>
									</Fragment>
								))}
						</dl>
					</div>
				</div>
			</div>
		);
	}
}

export default Summary;
