import React from "react";
import Autosuggest from "react-autosuggest";
import isEmpty from '../../../../../utils/helpers';
import {debounce} from "lodash";
import {createLoadingSelector} from "../../../../../selectors";
import {connect} from "react-redux";
import {searchAddress, getMinNumAddrSearchChars} from "../../../../../actions/apiIntegration.actions";
import {toastr} from "react-redux-toastr";
const getApiSuggestion = (suggestion) => suggestion;

const renderApiAddress = (suggestion) => (
    <div className="autocomplete-dropdown-container">
        <div>
            {suggestion.fullAddress}
        </div>
    </div>
);

class ApiAutoComplete extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: "",
            suggestions: [],
            // address: this.props.selectedAddress || "",
            unitNumber: this.props.selectedUnit || "",
            minNumAddrSearchChars: 3,
        };
        this.props.getMinNumAddrSearchChars()
            .then((response) => {
                this.setState({
                    minNumAddrSearchChars: response,
                });
            })
            .catch((error) => {
                toastr.error("Configuration Issue", {position: 'top-center' });
            });
        console.log(this.state.minNumAddrSearchChars);
    }

    onChange = (event, { newValue }) => {
        this.setState({
            value: !isEmpty(newValue.fullAddress)? newValue.fullAddress: newValue,
        });
    };

    handleSelect = (e, value) => {
        let address = "";

        if (!isEmpty(value.suggestionValue)) {
            address = value.suggestionValue;
        }
        else {
            address = value;
        }

        // this.setState({
        //     address: address
        // }, () => {
        if (!isEmpty(this.props.values['fullAddress'])) {
            address.address2 = this.props.values['fullAddress'].address2;
        }
        this.props.onAddressHandleChange(address, this.state.unitNumber, this.props.setFieldValue);
        // });
    };

    handleUnitChange = (event) => {
        this.setState({ unitNumber: event.target.value }, () => {
            let address = {}
            if (!isEmpty(this.props.values['fullAddress'])) {
                address = this.props.values['fullAddress'];
            }

            address.address2 = this.state.unitNumber;
            this.props.setFieldValue('fullAddress', address);

            if (this.unitChangeTimeout) {
                clearTimeout(this.unitChangeTimeout);
            }
            this.unitChangeTimeout = setTimeout(() => {
                if (!isEmpty(this.props.values['fullAddress'].fullAddress)) {
                    this.handleSelect(event, this.props.values['fullAddress']);
                }
            }, 500);

        });
    };

    onError = (status, clearSuggestions) => {
        this.props.onAddressHandleChange("error", "");
        clearSuggestions();
    };

    onSuggestionsFetchRequested = debounce(({value, reason}) => {
        if (reason === 'input-changed') {
            this.props.searchAddress(value)
                .then((response) => {
                    this.setState({
                        suggestions: response.addresses,
                    });
                })
                .catch((error) => {
                    toastr.error("Configuration Issue", {position: 'top-center' });
                });
        }
    }, 500);

    clearSuggestions = () => {
        this.setState({
            suggestions: [],
        });
    };

    shouldRenderSuggestions=(value)=>{
        const { minNumAddrSearchChars } = this.state;
        return value.trim().length >= minNumAddrSearchChars;
    }

    render() {
        const { suggestions } = this.state;
        const { touched, isLoadingAddresses } = this.props;

        const inputProps = {
            placeholder: "Start by typing an address",
            value: this.state.value,
            onChange: this.onChange,
        };
        return (
            <div className="form-group">
                <div className="container">
                    <div className="row">
                        <div className="col-md-9">
                            <div className="input-loader">
                                    <Autosuggest
                                        theme={{
                                            input: {
                                                width: "100%",
                                                display: "block",
                                                height: "calc(1.5em + 0.75rem + 2px)",
                                                padding: "0.375rem 0.75rem",
                                                fontSize: "1rem",
                                                fontWeight: 400,
                                                lineHeight: 1.5,
                                                color: "#495057",
                                                backgroundColor: "#fff",
                                                backgroundClip: "padding-box",
                                                border: "1px solid #adb5bd",
                                                borderRadius: "0.25rem",
                                            },
                                            suggestionsContainerOpen: {
                                                display: "block",
                                                top: "51px",
                                                width: "100%",
                                                border: "1px solid #aaa",
                                                backgroundColor: "#fff",
                                                fontSize: "16px",
                                                borderRadius: "0 0 4px 4px",
                                                zIndex: 2,
                                            },
                                            suggestionsList: {
                                                margin: 0,
                                                padding: 0,
                                                listStyleType: "none",
                                            },
                                            suggestion: {
                                                cursor: "pointer",
                                                padding: "10px 20px",
                                            },
                                            suggestionHighlighted: {
                                                backgroundColor: "#6ab3c1",
                                            },
                                        }}
                                        suggestions={suggestions}
                                        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                        onSuggestionsClearRequested={this.clearSuggestions}
                                        onSuggestionSelected={this.handleSelect}
                                        getSuggestionValue={getApiSuggestion}
                                        renderSuggestion={renderApiAddress}
                                        inputProps={inputProps}
                                        shouldRenderSuggestions={this.shouldRenderSuggestions}
                                        onError={this.onError}
                                    />
                                {isLoadingAddresses &&
                                <div className="input-loader-container">
                                    <span className="input-loader-dot" />
                                    <span className="input-loader-dot" />
                                    <span className="input-loader-dot" />
                                </div>
                                }
                            </div>
                        </div>
                        <div className="col-md-3">
                            <input
                                className={
                                    this.props.validatedAddress &&
                                    this.props.validatedAddress.error &&
                                    touched.address
                                        ? "form-control is-invalid"
                                        : "form-control"
                                }
                                value={this.state.unitNumber}
                                placeholder="Unit#"
                                onChange={this.handleUnitChange}
                            />
                        </div>
                    </div>
                    {this.props.validatedAddress && this.props.validatedAddress.error && (
                        <div className="row">
                            <div className="col-md-9">
                                <div className="invalid-feedback">
                                    {this.props.validatedAddress.error.message}
                                </div>
                            </div>
                        </div>
                    )}

                    {this.props.validatedAddress &&
                    !this.props.validatedAddress.error &&
                    !this.props.validatedAddress.allowedServiceOrderType && (
                        <div className="row">
                            <div className="col-md-9">
                                <div className="invalid-feedback">
                                    We are not currently offering service at this location.
                                </div>
                            </div>
                        </div>
                    )}

                    {this.props.validatedAddress &&
                    !this.props.validatedAddress.error &&
                    "NEW_SURVEY" ==
                    this.props.validatedAddress.allowedServiceOrderType && (
                        <div className="row">
                            <div className="col-md-9">
                                <div className="valid-feedback">
                                    We are currently collecting surveys for this address.
                                </div>
                            </div>
                        </div>
                    )}
                    {this.props.validatedAddress &&
                    !this.props.validatedAddress.error &&
                    "NEW_SIGNUP" ==
                    this.props.validatedAddress.allowedServiceOrderType && (
                        <div className="row">
                            <div className="col-md-9">
                                <div className="valid-feedback">
                                    We are currently collecting sign-ups for this address.
                                </div>
                            </div>
                        </div>
                    )}
                    {this.props.validatedAddress &&
                    this.props.validatedAddress.addressValuesToView && (
                        <div className="row">
                            <div className="col-md-9">
                                <div className="valid-feedback">
                                    {Object.keys(this.props.validatedAddress.addressValuesToView).map((key, i) => {
                                        return(
                                            <div>
                                                <span>{this.props.validatedAddress.addressValuesToView[key]}</span>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    )}
                    {this.props.validatedAddress &&
                    this.props.validatedAddress.zoneValuesToView && (
                        <div className="row">
                            <div className="col-md-9">
                                <div className="valid-feedback">
                                    {Object.keys(this.props.validatedAddress.zoneValuesToView).map((key, i) => {
                                        return(
                                            <div>
                                                <span>{this.props.validatedAddress.zoneValuesToView[key]}</span>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

const addressLoadingSelector = createLoadingSelector([ 'GET_INTEGRATION_SEARCH_ADDRESS' ]);

const mapStateToProps = (state) => {
    const isLoadingAddresses = addressLoadingSelector(state);

    return {
        isLoadingAddresses
    };
};

const mapDispatchToProps = {
    searchAddress,
    getMinNumAddrSearchChars
};
export default connect(mapStateToProps, mapDispatchToProps)(ApiAutoComplete);
