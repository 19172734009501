import moment from 'moment';
export const checkBalanceChart = (balanceInfo) => {
    if(balanceInfo.invoicesAmount === 0 && balanceInfo.accountCredit === 0){
        return [1, 0, 0]
    }else{
        return [0, balanceInfo.invoicesAmount, balanceInfo.accountCredit ]
    }
    // [0, accountInfo.invoicesAmount, accountInfo.pendingAdjustments, accountInfo.accountCredit ]
}


export const checkDepositChart = (depositBalance) => {
    if(depositBalance < 1){
        return [1, depositBalance]
    }else {
        return [0, depositBalance]
    }
}
export const checkInstallmentBalanceChart = (installmentPlans) => {
    let flag=false,balance=[];
    installmentPlans.map((plan)=>{
       if(plan.balance>0){
            balance.push(plan.balance);
            flag=true;
       }
    })
    if(flag){
        return balance;
    }
}
export const InstallmentBalanceChartLabels=(installmentPlans)=>{
    let labels=[];
    // `${plan.installmentPlanItem.description} ${moment(plan.createdDateTime).format('MM/DD/YYYY')} Balance`
    installmentPlans.map((plan)=>{
        plan.balance> 0 && plan.invoicedItem &&
        labels.push([`${plan.invoicedItem.description} ${moment(plan.createdDateTime).format('MM/DD/YYYY')}`,`${plan.installmentPlanItem&&plan.installmentPlanItem.name}`,`Amount: $${plan.amount}`,`Balance: $${plan.balance}`]);
    })
    return labels;
}

export const checkInstallmentCenter = (installmentPlans) => {
    let totalBal=0;
    installmentPlans.map((plan)=>{
        totalBal=totalBal+plan.balance;
    });
    return totalBal;
}

export const checkInvoicesChart = (invoicesAging) => {
    let isResultZero = true;
    Object.keys(invoicesAging).forEach((key) => {
        if(invoicesAging[key] !== 0){
            isResultZero = false
        }
    })
    return isResultZero === true ? [1, 0, 0, 0, 0, 0] : [0, invoicesAging.balance0, invoicesAging.balance30, invoicesAging.balance60, invoicesAging.balance90, invoicesAging.balance120]
}


export const checkInvoicesCenter = (invoicesAging) => {
    let isResultZero = true;
    Object.keys(invoicesAging).forEach((key) => {
        if(invoicesAging[key] !== 0){
            isResultZero = false
        }
    })
    return isResultZero === true ? 0 : invoicesAging.balance0 + invoicesAging.balance30 + invoicesAging.balance60 + invoicesAging.balance90 + invoicesAging.balance120
}