import React, { Component } from 'react';
import Quote from './Quote';
import Loader from '../../../../../../../components/Loader';
import { getQuotes, emptyQuotes } from '../../../../../../../actions/accountDetailsActions/ordersAndQuotes.actions';
import { connect } from 'react-redux';
import { getAccountDetails, createLoadingSelector } from '../../../../../../../selectors';
import { isEmpty } from '../../../../../../../utils/helpers';


class Orders extends Component {
	componentDidMount() {
		const { accountInfo } = this.props;
		this.props.getQuotes(accountInfo.id);
    }
    
    componentWillUnmount(){
        this.props.emptyQuotes()
    }

	render() {
		const { quotesData, quotesLoader } = this.props;

		if (isEmpty(quotesData) && quotesLoader) {
			return <Loader />;
		}

		if(isEmpty(quotesData)){
			return <p className="tab-pane-message-empty">No data found</p>
		}

		return <div>{quotesData.map((quote) => <Quote quoteInfo={quote} key={quote.id} />)}</div>;
	}
}

const loader = createLoadingSelector([ 'SET_QUOTES' ]);

const mapStateToProps = (state) => {
	const quotesData = getAccountDetails(state).ordersAndQuotes.quotes,
		quotesLoader = loader(state);

	return {
		quotesData,
		quotesLoader
	};
};

const mapDispatchToProps = {
	getQuotes,
	emptyQuotes
};

export default connect(mapStateToProps, mapDispatchToProps)(Orders);
