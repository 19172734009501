import {
	SET_SECURITY_PHRASE,
	SET_AUTHORIZED_CONTACTS,
	SET_PRIMARY_CONTACTS,
	SECURITY_COUNTRY_STATES,
	SET_UPDATE_PRIMARY_CONTACT,
	SET_ALL_CONTACTS
} from '../../actions/accountDetailsActions/security.actions';

let initialState = {
	securityPhrase: '',
	allContacts: [],
	authorizedContacts: [],
	primaryContacts: [],
	countryStates: [],
	succesfullUpdate: false
};

export const security = (state = initialState, action) => {
	switch (action.type) {
		case SET_ALL_CONTACTS.SUCCESS:
			return {
				...state,
				allContacts: action.payload
			}
		case SET_ALL_CONTACTS.EMPTY:
			return {
				...state,
				allContacts: []
			}
		case SET_SECURITY_PHRASE.SUCCESS:
			return {
				...state,
				securityPhrase: action.payload
			};
		case SET_SECURITY_PHRASE.EMPTY: {
			return {
				...state,
				securityPhrase: ''
			};
		}
		case SET_AUTHORIZED_CONTACTS.SUCCESS:
			return {
				...state,
				authorizedContacts: action.payload
			};
		case SET_AUTHORIZED_CONTACTS.EMPTY:
			return {
				...state,
				authorizedContacts: []
			};
		case SET_PRIMARY_CONTACTS.SUCCESS:
			return {
				...state,
				primaryContacts: action.payload
			};
		case SET_PRIMARY_CONTACTS.EMPTY:
			return {
				...state,
				primaryContacts: []
			};
		case SECURITY_COUNTRY_STATES.SUCCESS:
			return {
				...state,
				countryStates: action.payload
			}
		case SECURITY_COUNTRY_STATES.EMPTY:
			return {
				...state,
				countryStates: []
			}
		case SET_UPDATE_PRIMARY_CONTACT.SUCCESS:
			return {
				...state,
				succesfullUpdate: action.payload
			}
		default:
			return state;
	}
};
