import React, {Component} from 'react';
import {connect} from "react-redux";
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {getAppInfo} from "../../../../actions/sideBar.actions";
import {getSideBar, getUser, getUserPermission} from "../../../../selectors";
import isEmpty from "../../../../utils/helpers";
import {compact} from 'lodash'

class AdministrationModuleMenu extends Component {

    state = {
        activeSubMenuItem: '',
    }

    changeSubMenuItem = (subMenuItem) => {
        if (this.state.activeSubMenuItem === subMenuItem) {
            this.setState({activeSubMenuItem: ''});
        }
        else {
            this.setState({activeSubMenuItem: subMenuItem});
        }
    }

    hasPermission = permission => !isEmpty(this.props.permissions) && this.props.permissions.includes(permission);

    hasMenuPermission = permission => !isEmpty(this.props.userPermissionMenu) && this.props.userPermissionMenu.permissions.includes(permission);

    renderUserManagementMenu = () => {

        if (!this.hasPermission("ADMN_USER_DETAIL")) {
            return null;
        }

        const { activeMenu, appInfo, changeActiveMenu } = this.props;
        const { activeSubMenuItem } = this.state;

        return (
            <li key={'UserManagement'} className={activeMenu === 'User Management' ? 'sidebar-dropdown active' : 'sidebar-dropdown'}>
                <a onClick={() => changeActiveMenu('User Management')}>
                    <i className="fa fa-user-cog"/>
                    <span>User Management</span>
                </a>

                <div className="sidebar-submenu"
                     style={activeMenu === 'User Management' && !isEmpty(appInfo) ? {display: 'block'} : {display: 'none'}}>
                    <ul>
                        <li className="sidebar-nav-link">
                            <Link to={{ pathname: '/users-management' }}>Users</Link>
                        </li>
                        <li className="sidebar-nav-link">
                            <Link to={{ pathname: '/technician-capacity' }}>Technician Capacity</Link>
                        </li>
                    </ul>
                </div>
            </li>
        )
    }

    renderPartnerManagementMenu = () => {

        if (!this.hasMenuPermission("SHOW_MENU_ADMIN_PARTNER")) {
            return null;
        }

        const { activeMenu, appInfo, changeActiveMenu } = this.props;
        const { activeSubMenuItem } = this.state;

        return (
            // <li className={activeSubMenuItem === 'Partner Management' ? 'sidebar-dropdown active' : 'sidebar-dropdown'}>
            //     <a onClick={() => this.changeSubMenuItem('Partner Management')}>
            <li key={'PartnerManagement'} className={activeMenu === 'Partner Management' ? 'sidebar-dropdown active' : 'sidebar-dropdown'}>
                <a onClick={() => changeActiveMenu('Partner Management')}>
                    <i className="fa fa-users-cog"/>
                    <span>Partner Management</span>
                </a>

                <div className="sidebar-submenu"
                     style={activeMenu === 'Partner Management' && !isEmpty(appInfo) ? {display: 'block'} : {display: 'none'}}>
                    <ul>
                        <li className="sidebar-nav-link">
                            <Link to={{ pathname: '/partners-management' }}>Partners</Link>
                        </li>
                    </ul>
                </div>
            </li>
        )
    }

    renderAppointmentManagementMenu = () => {

        if (!this.hasPermission("ADMN_USER_DETAIL")) {
            return null;
        }

        const { activeMenu, appInfo, changeActiveMenu } = this.props;
        const { activeSubMenuItem } = this.state;

        return (
            <li key={"Appointment Management"} className={activeMenu === 'Appointment Management' ? 'sidebar-dropdown active' : 'sidebar-dropdown'}>
                <a onClick={() => changeActiveMenu('Appointment Management')}>
                    <i className="fas fa-clock"/>
                    <span>Appointment Management</span>
                </a>

                <div className="sidebar-submenu"
                     style={activeMenu === 'Appointment Management' && !isEmpty(appInfo) ? {display: 'block'} : {display: 'none'}}>
                    <ul>
                        <li className="sidebar-nav-link">
                            <Link to={{ pathname: '/appointment-management' }}>Appointment Areas</Link>
                        </li>
                    </ul>
                </div>
            </li>
        )
    }

    renderWorkflowManagementMenu = () => {

        if (!this.hasMenuPermission("SHOW_WORKFLOW_DEFINITIONS")) {
            return null;
        }

        const { activeMenu, appInfo, changeActiveMenu } = this.props;
        const { activeSubMenuItem } = this.state;

        return (
            <li key={"WorkflowManagementMenu"} className={activeMenu === 'Workflow Management' ? 'sidebar-dropdown active' : 'sidebar-dropdown'}>
                <a onClick={() => changeActiveMenu('Workflow Management')}>
                    <i className="fa fa-project-diagram"/>
                    <span>Workflow Management</span>
                </a>

                <div className="sidebar-submenu"
                     style={activeMenu === 'Workflow Management' && !isEmpty(appInfo) ? {display: 'block'} : {display: 'none'}}>
                    <ul>
                        <li className="sidebar-nav-link">
                            <Link to={{ pathname: '/workflow-definitions' }}>Workflow Definitions</Link>
                        </li>
                    </ul>
                </div>
            </li>
        )
    }

    renderProductCatalogMenu = () => {

        const { activeMenu, appInfo, changeActiveMenu } = this.props;
        const { activeSubMenuItem } = this.state;

        return (
            <li key={"Product Catalog"} className={activeMenu === 'Product Catalog' ? 'sidebar-dropdown active' : 'sidebar-dropdown'}>
                <a onClick={() => changeActiveMenu('Product Catalog')}>
                    <i className="fas fa-list"/>
                    <span>Product Catalog</span>
                </a>

                <div className="sidebar-submenu"
                     style={activeMenu === 'Product Catalog' && !isEmpty(appInfo) ? {display: 'block'} : {display: 'none'}}>
                    <ul>
                        <li className="sidebar-nav-link">
                            <Link to={{ pathname: '/plans' }}>Plans</Link>
                        </li>
                    </ul>
                    <ul>
                        <li className="sidebar-nav-link">
                            <Link to={{ pathname: '/features' }}>Features</Link>
                        </li>
                    </ul>
                    <ul>
                        <li className="sidebar-nav-link">
                            <Link to={{ pathname: '/feature-packages' }}>Feature Packages</Link>
                        </li>
                    </ul>
                </div>
            </li>
        )
    }

    renderInvoicingMenu = () => {

        // if (!this.hasMenuPermission("SHOW_WORKFLOW_DEFINITIONS")) {
        //     return null;
        // }

        const { activeMenu, appInfo, changeActiveMenu } = this.props;
        const { activeSubMenuItem } = this.state;

        return (
                this.props.canViewInstallmentPlans&&<li key={"invoicing"} className={activeMenu === 'invoicing' ? 'sidebar-dropdown active' : 'sidebar-dropdown'}>
                    <a onClick={() => changeActiveMenu('invoicing')}>
                        <i className="fa fa-project-diagram"/>
                        <span>Invoicing</span>
                    </a>
                    <div className="sidebar-submenu"
                         style={activeMenu === 'invoicing' && !isEmpty(appInfo) ? {display: 'block'} : {display: 'none'}}>
                        {this.props.canViewAdjustments &&
                            <ul>
                                <li className="sidebar-nav-link">
                                    <Link to={{pathname: '/adjustments'}}>Adjustments</Link>
                                </li>
                            </ul>
                        }
                        <ul>
                            <li className="sidebar-nav-link">
                                <Link to={{ pathname: '/installment-plans' }}>Installment Plans</Link>
                            </li>
                        </ul>
                    </div>
                </li>
        )
    }

    renderSubMenuItems = () => {
        let subMenuItems = compact([
            this.renderUserManagementMenu(),
            this.renderPartnerManagementMenu(),
            this.renderWorkflowManagementMenu(),
            this.renderAppointmentManagementMenu(),
            this.renderProductCatalogMenu(),
            this.renderInvoicingMenu()
        ]);

        return subMenuItems;
    }

    render() {

        const {activeMenu} = this.props;

        const subMenuItems = this.renderSubMenuItems();

        if (isEmpty(subMenuItems)) {
            return null;
        }

        return (
            <ul>
                <li className="header-menu">
                    <span>Administration</span>
                </li>
                {subMenuItems}
            </ul>
        );
    }
}

AdministrationModuleMenu.propTypes = {
    activeMenu: PropTypes.string.isRequired,
    changeActiveMenu: PropTypes.func.isRequired,
    permissions: PropTypes.array.isRequired,
};


const mapStateToProps = (state) => {

    const appInfo = getSideBar(state).appInfo;
    const userConfiguration = getUser(state).configuration;
    const canViewInstallmentPlans = getUserPermission(state, 'ADMN', 'ADMN_VIEW_INSTALLMENT_PLANS');
    const canViewAdjustments = getUserPermission(state, 'ADMN', 'ADMN_VIEW_ADJUSTMENT');
    let mappingName = '';

    if (userConfiguration) {
        mappingName = userConfiguration.find((x) => x.key === 'mapping.name')
            ? userConfiguration.find((x) => x.key === 'mapping.name').value
            : "";
    }

    return {
        appInfo,
        userConfiguration,
        mappingName,
        canViewInstallmentPlans,
        canViewAdjustments
    };
};

const mapDispatchToProps = {
    getAppInfo,
    getUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdministrationModuleMenu);