import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

const AddressInput = ({
	header,
	type,
	optionsData,
	fieldName,
	isEditable,
	fieldType,
	currentObject,
	contactId,
	handleCloseInput,
	handleEditField,
	handleUpdate,
	inputRef,
	isDisabled
}) => {
	return (
		<div className="form-group form-row">
			<div className="col-sm-5 col-xl-4">{header}</div>
			<div className="col-sm-7 col-xl-8">
				{isEditable !== fieldName ? (
					// <a onClick={() => handleEditField(fieldName)} data-type="text" className="editable-generic-text">
					// 	{currentObject ? currentObject[fieldType] ? currentObject[fieldType] : 'Not provided' : 'Not provided'}
					// </a>
					<div>
						{currentObject ? currentObject[fieldType] ? currentObject[fieldType] : 'Not provided' : 'Not provided'}
					</div>
				) : (
					<div className="input-group input-group-editable">
						{type === 'select' ? (
							<select
								name={fieldName}
								className="form-control"
								defaultValue={currentObject ? currentObject[fieldType] : ''}
								ref={inputRef}
								disabled={isDisabled}
							>
								{optionsData.map((state) => <option value={state.name}>{state.name}</option>)}
							</select>
						) : (
							<input
								name={fieldName}
								className="form-control"
								defaultValue={currentObject ? currentObject[fieldType] : ''}
								ref={inputRef}
								disabled={isDisabled}
							/>
						)}

						<div className="input-group-append">
							{/* <button type="button" className="btn btn-editable-clear">
								<i className="fas fa-backspace" />
							</button> */}
							<button
								type="button"
								onClick={() => handleUpdate(fieldType, contactId, currentObject)}
								className="btn btn-primary btn-editable-apply"
								disabled={isDisabled}
							>
								{!isDisabled ? (
									<i className="fas fa-check" />
								) : (
									<i className="fas fa-spin fa-circle-notch" />
								)}
							</button>
							<button
								type="button"
								onClick={handleCloseInput}
								className="btn btn-outline-secondary btn-editable-cancel"
								disabled={isDisabled}
							>
								<i className="fas fa-times" />
							</button>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

AddressInput.propTypes = {};

export default AddressInput;
