import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { hideModal } from '../../../actions/modal.actions';
import {getSupportedReversalTypes, reversePayment} from '../../../actions/accountDetailsActions/billing.actions';
import {createLoadingSelector, getAccountDetails, getAccountSearch, getModalData} from '../../../selectors';
import {Form, Formik} from 'formik';
import * as Yup from 'yup';
import { toastr } from "react-redux-toastr";
import { get, isEmpty } from 'lodash';
import FormSelect from "../../../components/UI/FormSelect";
import {emptyAccounts, getAccounts} from "../../../actions/account-search";
import Loader from "../../../components/Loader";

const ACCOUNT_SEARCH_LIMIT = 10;

const REVERSAL_TYPE = {
	MISAPPLIED_PAYMENT: 'MISAPPLIED_PAYMENT',
	INCORRECT_AMOUNT: 'INCORRECT_AMOUNT',
	DUPLICATE_PAYMENT: 'DUPLICATE_PAYMENT',
	RETURN_PAYMENT: 'RETURN_PAYMENT'
}

const REVERSE_RESULT = {
	NONE: 0,
	FAIL: 1,
	SUCCESS: 2,
}

const isReversalReferenceActive = reversalType => !!reversalType;

const validationSchema = Yup.object().shape(
	{
		reversalType: Yup.string().nullable().required('Required'),
		reversalReference: Yup.string().nullable().when('reversalType', {
			is: value => isReversalReferenceActive(value),
			then: Yup.string().nullable()
				.test('whitespaces test', 'Must not have leading or trailing whitespace',
				value => !value || value.trim().length === value.length)
				.min(1, 'Must be between 1 and 255 characters')
				.max(255, 'Must be between 1 and 255 characters')
		}),
		targetAccountId: Yup.number().nullable().when('reversalType', {
			is: (value) =>
				value === REVERSAL_TYPE.MISAPPLIED_PAYMENT,
			then: Yup.number().nullable()
				.required('Required')
				.positive('Invalid Account')
		}),
	}
);

const getErrorMessage = (resp) => {
	if (get(resp, 'response.status') === 500) {
		return;
	}

	const unknownError = 'Something went wrong';

	let errorMessage = get(resp, 'response.data.error.message', unknownError);

	if (errorMessage === unknownError) {
		errorMessage = get(resp, 'data.error.message', unknownError);
	}

	if (errorMessage === unknownError) {
		errorMessage = get(resp, 'error.message', unknownError);
	}

	return errorMessage;
}

class ReversePaymentModal extends Component {

	state = {
		reverseResult: REVERSE_RESULT.NONE,
		accountSearchTerm: '',
		selectedAccount: null,
	}

	componentDidMount() {
		const { payment, getSupportedReversalTypes, emptyAccounts } = this.props;
		getSupportedReversalTypes(payment.paymentId);
		emptyAccounts();
		this.reloadAccounts();
	}

	componentWillUnmount() {
		const { emptyAccounts } = this.props;

		emptyAccounts();
	}

	formSubmit = (values, {setSubmitting}) => {
		const { reversePayment, accountId, payment } = this.props;
		const { reversalType, reversalReference, targetAccountId, newAmount } = values;

		const data = { reversalType };

		// set reversalReference
		data.reversalReference = (reversalReference && reversalReference.length)? reversalReference : null;

		switch (reversalType) {
			case REVERSAL_TYPE.INCORRECT_AMOUNT:
				data.newAmount = newAmount;
				break;
			case REVERSAL_TYPE.MISAPPLIED_PAYMENT:
				data.targetAccountId = targetAccountId;
				break;
		}


		reversePayment(accountId, payment.paymentId, data)
			.then(resp => {
				if (resp && resp.success) {
					this.handleSuccess(resp, setSubmitting);
				} else {
					this.handleError(resp, setSubmitting);
				}
			})
			.catch(resp => this.handleError(resp, setSubmitting));
	};

	handleSuccess = (resp, setSubmitting) => {
		this.setState({reverseResult: REVERSE_RESULT.SUCCESS});
		// toastr.success("Payment reversed successfully", {position: 'top-center'});
		// hideModal();

		setSubmitting(false);
	}

	handleError = (resp, setSubmitting) => {
		this.setState({ reverseResult: REVERSE_RESULT.FAIL });

		const errorMessage = getErrorMessage(resp);
		// toastr.error(errorMessage, {position: 'top-center'});
		// console.error(resp);

		setSubmitting(false);
	}

	clearReverseResult = () => {
		this.setState({ reverseResult: REVERSE_RESULT.NONE });
	}

	reloadAccounts = () => {
		this.props.getAccounts('QUERY', this.state.accountSearchTerm, ACCOUNT_SEARCH_LIMIT);
	}

	handleAccountSearchChange = (value, {action}) => {
		if (this.state.accountSearchTerm === value || action !== 'input-change') {
			return;
		}

		this.setState({accountSearchTerm: value}, () => {
			if (this.searchChangeTimeout) {
				clearTimeout(this.searchChangeTimeout);
			}

			this.searchChangeTimeout = setTimeout(() => {
				this.reloadAccounts();
			}, 300);
		});
	}

	handleOnReverseTypeChange = setFieldValue => ({value}, newValue) => {
		if (newValue === REVERSAL_TYPE.MISAPPLIED_PAYMENT && value !== newValue) {
			this.props.emptyAccounts();
			this.reloadAccounts();
		} else if (value !== newValue) {
			setFieldValue('targetAccountId', null);
		}
	}

	handleOnReverseBtnClick = (handleSubmit, validateForm) => {
		validateForm().then(errors => {
			if (Object.keys(errors).length === 0 && errors.constructor === Object) {
				toastr.confirm("Are you sure you want to reverse this payment?", {
					cancelText: "Cancel",
					okText: "Confirm",
					onOk: handleSubmit
				})
			} else {
				handleSubmit();
			}
		});
	}

	render() {
		const { accountId, accounts, loadingAccounts, supportedReversalTypes, loadingSupportedReversalTypes } = this.props;
		const { reverseResult } = this.state;

		return (
			<Fragment>
				<div className="modal" style={{ display: 'block' }} tabIndex="-1"  role="dialog">
					<Formik
						initialValues={{
							reversalType: null,
							reversalReference: null,
							targetAccountId: null,
						}}
						validationSchema={validationSchema}
						onSubmit={this.formSubmit}
						render={
							({
								 setFieldValue,
								 handleChange,
								 handleSubmit,
								 handleBlur,
								 values,
								 errors,
								 touched,
								 isSubmitting,
								 validateForm,
							 }) => (

								<div className="modal-dialog" role="document">
									<div className="modal-content">
										<div className="modal-header">
											<h5 className="modal-title">
												Reverse Payment
											</h5>
											<button onClick={this.props.hideModal} type="button" className="close" disabled={isSubmitting}>
												<span aria-hidden="true">&times;</span>
											</button>
										</div>

										<div className="modal-body">

											<Form onSubmit={handleSubmit}
												  id="form-reverse-payment"
												  autoComplete="off"
												  className={"cmv-form" + (reverseResult === REVERSE_RESULT.SUCCESS ? " d-none" : "")}>

												<div className="form-section">
													<div className="form-row">
														<div className="form-group col-12">
															<FormSelect
																title="Reverse Reason"
																fieldName="reversalType"
																value={values.reversalType}
																setFieldValue={setFieldValue}
																onBlur={handleBlur}
																errors={errors}
																touched={touched}
																isLoading={loadingSupportedReversalTypes}
																isDisabled={isSubmitting}
																options={supportedReversalTypes.map(entry => ({
																	label: entry.description,
																	value: entry.typeName
																}))}
																onPostChange={this.handleOnReverseTypeChange(setFieldValue)}
															/>
														</div>
													</div>

													{isReversalReferenceActive(values.reversalType) &&
													<div className="form-row">
														<div className="form-group col-12">
															<label htmlFor="reversalReference">Reference</label>
															<div className="input-group">
																<input
																	type="text"
																	name="reversalReference"
																	disabled={isSubmitting}
																	className={
																		errors.reversalReference? (
																			'form-control is-invalid'
																		) : (
																			'form-control'
																		)
																	}
																	value={values.reversalReference}
																	onBlur={handleBlur}
																	onChange={handleChange}
																/>
																{errors.reversalReference && (
																	<div className="invalid-feedback">
																		{errors.reversalReference}
																	</div>
																)}
															</div>
														</div>
													</div>
													}

													{values.reversalType === REVERSAL_TYPE.MISAPPLIED_PAYMENT &&
													<div className="form-row">
														<div className="form-group col-12">
															<FormSelect
																title="Target Account"
																fieldName="targetAccountId"
																value={values.targetAccountId}
																setFieldValue={setFieldValue}
																onBlur={handleBlur}
																errors={errors}
																touched={touched}
																isLoading={loadingAccounts}
																isDisabled={isSubmitting}
																options={
																	accounts.filter(acc => acc.id !== accountId)
																		.map(acc => {
																			return {
																				label: `${acc.id} #${acc.number} - ${acc.name}`,
																				value: acc.id
																			}
																		})
																}
																onInputChange={this.handleAccountSearchChange}
															/>
														</div>
													</div>
													}
													{values.reversalType === REVERSAL_TYPE.INCORRECT_AMOUNT &&
													<div className="form-row">
														<label htmlFor="color">New Amount</label>
														<div className="input-group">
															<div className="input-group-prepend">
																<span className="input-group-text">$</span>
															</div>
															<input
																type="text"
																data-type="currency"
																name="newAmount"
																disabled={isSubmitting}
																className={
																	errors.newAmount && touched.newAmount ? (
																		'form-control text-right is-invalid'
																	) : (
																		'form-control text-right'
																	)
																}
																value={values.newAmount}
																data-amount={''}
																onBlur={handleBlur}
																onChange={handleChange}
																placeholder={'0.00'}
															/>
															{errors.newAmount &&
															touched.newAmount && (
																<div className="invalid-feedback">
																	{errors.newAmount}
																</div>
															)}
														</div>
													</div>
													}
												</div>
											</Form>

											<div className={"form-section form-section-customer-payment-management" + (reverseResult === REVERSE_RESULT.SUCCESS ? " form-section-customer-payment-management-success" : "")}>
												{isSubmitting && <Loader/>}

												{reverseResult === REVERSE_RESULT.SUCCESS &&
												<div className="form-section-customer-payment-management-message">
													<i className="far fa-check-circle text-success"/>
													<p>Payment reverse was successful, you can find details in the "Billing" tab.</p>
												</div>
												}

												{reverseResult === REVERSE_RESULT.FAIL &&
												<div className="form-section-customer-payment-management-message">
													<i className="far fa-times-circle text-danger" onClick={this.clearReverseResult} style={{cursor: 'pointer'}}/>
													<p>Payment reverse failed.</p>
												</div>
												}
											</div>
										</div>

										<div className="modal-footer">
											<button id="btn-reverse-cancel"
													onClick={this.props.hideModal} className="btn" type="button" disabled={isSubmitting}>
												{reverseResult !== REVERSE_RESULT.SUCCESS ? "Cancel" : "Close"}
											</button>
											{reverseResult !== REVERSE_RESULT.SUCCESS &&
											<button id="btn-reverse-submit"
													onClick={() => this.handleOnReverseBtnClick(handleSubmit, validateForm)}
													disabled={isSubmitting} className="btn btn-primary" type="button">
												Reverse
											</button>
											}
										</div>
									</div>
								</div>
							)}
					/>
				</div>
				<div className="modal-backdrop show" tabIndex="1" />
			</Fragment>
		);
	}
}

const supportedReversalTypesLoadingSelector = createLoadingSelector([ 'SET_SUPPORTED_REVERSAL_TYPES' ]);
const accountSearchLoader = createLoadingSelector([ 'ACCOUNT_SEARCH' ]);

const mapStateToProps = (state) => {
	const { accountId, payment } = getModalData(state).modalProps,
		supportedReversalTypes = getAccountDetails(state).billing.supportedReversalTypes,
		loadingSupportedReversalTypes = supportedReversalTypesLoadingSelector(state),
		accounts = getAccountSearch(state).accounts,
		loadingAccounts = accountSearchLoader(state);

	return {
		accountId,
		payment,
		supportedReversalTypes,
		loadingSupportedReversalTypes,
		accounts,
		loadingAccounts
	};
};

const mapDispatchToProps = {
	hideModal,
	reversePayment,
	getSupportedReversalTypes,
	getAccounts,
	emptyAccounts,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReversePaymentModal);
