import React, {Component, Fragment} from "react";
import {getModalData} from "../../../selectors";
import {hideModal} from "../../../actions/modal.actions";
import {connect} from "react-redux";
import {loadBillingJobStatusSummary, loadBillingJobStatusDetails} from "../../../actions/billingJobs.actions";
import Button from "../../../components/Common/Buttons/Button";

class BillingJobMonitorModal extends Component {
    state = {
        billingJob: null,
        statusDetails: null,
        refreshInterval: 0,
        pauseRefresh: false,
        testBillingJobLogDisplayed: false,
        billingApprovalJobLogDisplayed: false,
        invoiceGenerationJobLogDisplayed: false,
        testBillingLogMessagesResponse: null,
        billingApprovalLogMessagesResponse: null,
        invoiceGenerationLogMessagesResponse: null,
    }

    componentDidMount() {
        const {
            billingJob
        } = this.props.modalProps;

        this.props.loadBillingJobStatusSummary(billingJob.id).then(response => {
            this.setState(() => ({
                statusDetails: response,
            }));
            if ((response.testBillingTotalToComplete > 0) &&
                (response.testBillingNumCompleted === response.testBillingTotalToComplete) &&
                (response.billingApprovalTotalToComplete > 0) &&
                (response.billingApprovalNumCompleted === response.billingApprovalTotalToComplete) &&
                (response.invoiceGenerationTotalToComplete > 0) &&
                (response.invoiceGenerationNumCompleted === response.invoiceGenerationTotalToComplete)) {
                this.setState({refreshInterval: 0});
            } else {
                this.setState({refreshInterval: 15000});
                this.setTimer();
            }
        });
    }

    componentWillUnmount() {
        this.setState({refreshInterval: 0, pauseRefresh: true});
        this.clearTimer();
    }

    setTimer = () => {
        if (this.timerHandle) {
            return;
        }
        // Remember the timer handle
        this.timerHandle = setTimeout(() => {
            this.refreshBillingJobDetails();
            this.timerHandle = 0;
        }, this.state.refreshInterval);
    };

    clearTimer = () => {
        if (this.timerHandle) {
            clearTimeout(this.timerHandle);
            this.timerHandle = 0;
        }
    };

    closeModal = () => {
        this.props.hideModal();
    };

    handleShowBillingJobLog = (e) => {
        const {
            billingJob
        } = this.props.modalProps;
        const {
            refreshInterval,
            testBillingJobLogDisplayed,
            billingApprovalJobLogDisplayed,
            invoiceGenerationJobLogDisplayed
        } = this.state;

        let billingStep = null;
        if ((e.target.id === "testBillingLogDisplaySelector") || ((e.target.className !== undefined) && (e.target.className.includes("btn-test-billing-log-display")))) {
            if (!this.state.testBillingJobLogDisplayed) {
                // If the current state is false that means we selected to display the log messages and the fetch needs to occur
                billingStep = "testBilling";
            }
            this.setState({testBillingJobLogDisplayed: !this.state.testBillingJobLogDisplayed,});
        } else if ((e.target.id === "billingApprovalLogDisplaySelector") || ((e.target.className !== undefined) && (e.target.className.includes("btn-billing-approval-log-display")))) {
            if (!this.state.billingApprovalJobLogDisplayed) {
                billingStep = "billingApproval";
            }
            this.setState({billingApprovalJobLogDisplayed: !this.state.billingApprovalJobLogDisplayed,});
        } else if ((e.target.id === "invoiceGenerationLogDisplaySelector") || ((e.target.className !== undefined) && (e.target.className.includes("btn-invoice-generation-log-display")))) {
            if (!this.state.invoiceGenerationJobLogDisplayed) {
                billingStep = "invoiceGeneration";
            }
            this.setState({invoiceGenerationJobLogDisplayed: !this.state.invoiceGenerationJobLogDisplayed,});
        }
        setTimeout(() => {
            const {
                testBillingJobLogDisplayed,
                billingApprovalJobLogDisplayed,
                invoiceGenerationJobLogDisplayed
            } = this.state;

            if (billingStep != null) {
                this.props.loadBillingJobStatusDetails(billingJob.id, billingStep).then(response => {
                    if (billingStep === "testBilling") {
                        this.setState(() => ({testBillingLogMessagesResponse: response,}));
                    } else if (billingStep === "billingApproval") {
                        this.setState(() => ({billingApprovalLogMessagesResponse: response,}));
                    } else if (billingStep === "invoiceGeneration") {
                        this.setState(() => ({invoiceGenerationLogMessagesResponse: response,}));
                    }
                    if ((response.testBillingTotalToComplete > 0) &&
                        (response.testBillingNumCompleted === response.testBillingTotalToComplete) &&
                        (response.billingApprovalTotalToComplete > 0) &&
                        (response.billingApprovalNumCompleted === response.billingApprovalTotalToComplete) &&
                        (response.invoiceGenerationTotalToComplete > 0) &&
                        (response.invoiceGenerationNumCompleted === response.invoiceGenerationTotalToComplete)) {
                        this.setState({refreshInterval: 0});
                    } else {
                        if (refreshInterval > 0) {
                            this.setTimer();
                        }
                    }
                });
            }

            this.manageAutoRefresh((!(this.state.testBillingJobLogDisplayed || this.state.billingApprovalJobLogDisplayed || this.state.invoiceGenerationJobLogDisplayed)));
        }, 50);
    }

    manageAutoRefresh = (turnRefreshOn) => {
        if (turnRefreshOn) {
            this.setState({refreshInterval: 15000, pauseRefresh:false});
            this.refreshBillingJobDetails();
        } else {
            this.setState({refreshInterval: 0, pauseRefresh:true});
        }
    }

    refreshBillingJobDetails = () => {
        const {
            billingJob
        } = this.props.modalProps;
        const {
            pauseRefresh,
            refreshInterval,
        } = this.state;

        if (pauseRefresh) {
            return;
        }
        this.props.loadBillingJobStatusSummary(billingJob.id).then(response => {
            this.setState(() => ({
                statusDetails: response,
            }));
            if ((response.testBillingTotalToComplete > 0) &&
                (response.testBillingNumCompleted === response.testBillingTotalToComplete) &&
                (response.billingApprovalTotalToComplete > 0) &&
                (response.billingApprovalNumCompleted === response.billingApprovalTotalToComplete) &&
                (response.invoiceGenerationTotalToComplete > 0) &&
                (response.invoiceGenerationNumCompleted === response.invoiceGenerationTotalToComplete)) {
                this.setState({refreshInterval: 0});
            } else {
                if (refreshInterval > 0) {
                    this.setTimer();
                }
            }
        });
    }

    render() {
        const {
            statusDetails,
            refreshInterval,
            testBillingJobLogDisplayed,
            billingApprovalJobLogDisplayed,
            invoiceGenerationJobLogDisplayed,
            testBillingLogMessagesResponse,
            billingApprovalLogMessagesResponse,
            invoiceGenerationLogMessagesResponse,
        } = this.state;
        const { billingJob } = this.props.modalProps;

        return (
            <Fragment>
                <div className="modal" style={{ display: 'block' }} tabIndex="-1" role="dialog">
                    <div className="modal-dialog modal-xl" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Billing Progress</h5>
                                <button type="button" className="close" onClick={this.closeModal}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="order-service-order-wrapper">
                                    <div><h4>Billing Progress Job {billingJob.id}</h4>
                                        {refreshInterval > 0 &&
                                            <p className="billing-job-id">Refreshing every {refreshInterval/1000} seconds</p>
                                        }
                                        {refreshInterval === 0 &&
                                            <p className="billing-job-id">Job Completed</p>
                                        }
                                    </div>
                                    <div className="order-service-order-wrapper">
                                        <h5>Test Billing</h5>

                                            {statusDetails != null &&
                                                <>
                                                    <div>
                                                        <div className="row">
                                                            <div className="col-12 col-md-3 col-xxl-2">
                                                                {statusDetails.testBillingLastMessage != null &&
                                                                    <p className="billing-job-id">
                                                                        {statusDetails.testBillingLastMessage}
                                                                    </p>
                                                                }
                                                                {statusDetails.testBillingLastMessage == null &&
                                                                    <p className="billing-job-id">Not Started</p>
                                                                }
                                                            </div>

                                                            <div className="col-12 col-md-9 col-xxl-10">
                                                                <div className="billing-job-caption">
                                                                    <div className="billing-job-controls">
                                                                        {statusDetails.testBillingLastMessage != null &&
                                                                            <span className="d-flex text-muted"
                                                                                  title="Show Test Billing Log">
                                                                                <small className="d-md-inline-block ml-auto">
                                                                                    <Button
                                                                                        onClick={this.handleShowBillingJobLog}
                                                                                        className="btn btn-xs btn-order-log-toggle btn-test-billing-log-display"
                                                                                        type="button"

                                                                                    >
                                                                                        <i id="testBillingLogDisplaySelector"
                                                                                           className="fas fa-list-ul"/>
                                                                                    </Button>
                                                                                </small>
                                                                            </span>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {statusDetails.testBillingLastMessage != null &&
                                                        <>
                                                            <div className="progress">
                                                                {statusDetails.testBillingTotalToComplete > 0 &&
                                                                    <div
                                                                        className="progress-bar bg-success-with-pulse"
                                                                        role="progressbar"
                                                                        style={{width: 'calc(' + statusDetails.testBillingNumCompleted + '/' + statusDetails.testBillingTotalToComplete + '*100%)'}}
                                                                        aria-valuenow="100"
                                                                        aria-valuemin="0"
                                                                        aria-valuemax="100"
                                                                    >
                                                                        <span style={{whiteSpace: "nowrap"}}>
                                                                            {statusDetails.testBillingPercentComplete}%
                                                                        </span>
                                                                    </div>
                                                                }
                                                                {statusDetails.testBillingTotalToComplete === 0 &&
                                                                <div
                                                                    className="progress-bar bg-success-with-pulse"
                                                                    role="progressbar"
                                                                    style={{width: '2%'}}
                                                                    aria-valuenow="100"
                                                                    aria-valuemin="0"
                                                                    aria-valuemax="100"
                                                                >
                                                                        <span style={{whiteSpace: "nowrap"}}>
                                                                            {statusDetails.testBillingPercentComplete}%
                                                                        </span>
                                                                </div>
                                                                }
                                                            </div>

                                                            <div>
                                                                <span>Last Updated {statusDetails.testBillingLastUpdatedDate}ago</span>
                                                                <span className="float-right">
                                                                {statusDetails.testBillingTimeRemainingMessage != null && statusDetails.testBillingTotalToComplete > 0 &&
                                                                    <span>
                                                                        {statusDetails.testBillingTimeRemainingMessage}remaining
                                                                    </span>
                                                                }
                                                                {statusDetails.testBillingTimeRemainingMessage === null && statusDetails.testBillingTotalToComplete !== 0 && statusDetails.testBillingTotalToComplete === statusDetails.testBillingNumCompleted &&
                                                                    <span>Completed</span>
                                                                }
                                                            </span>
                                                            </div>
                                                        </>
                                                        }
                                                    </div>
                                                    {testBillingJobLogDisplayed && testBillingLogMessagesResponse &&
                                                        <div className="cmv-container-log-viewer cmv-container-order-log-viewer">
                                                            {testBillingLogMessagesResponse.statusList.map((log) => <span key={log.id}>{log.stringVal}<br/></span>)}
                                                        </div>
                                                    }
                                                </>
                                            }
                                            {statusDetails == null &&
                                                <div>
                                                    <div className="row">
                                                        <div className="col-12 col-md-3 col-xxl-2">
                                                            <p className="billing-job-id">Not Started</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>

                                        <div className="order-service-order-wrapper">
                                            <h5>Billing Approval</h5>

                                            {statusDetails != null &&
                                            <>
                                                <div>
                                                    <div className="row">
                                                        <div className="col-12 col-md-3 col-xxl-2">
                                                            {statusDetails.billingApprovalLastMessage != null &&
                                                                <p className="billing-job-id">
                                                                    {statusDetails.billingApprovalLastMessage}
                                                                </p>
                                                            }
                                                            {statusDetails.billingApprovalLastMessage == null &&
                                                                <p className="billing-job-id">Not Started</p>
                                                            }
                                                        </div>

                                                        <div className="col-12 col-md-9 col-xxl-10">
                                                            <div className="billing-job-caption">
                                                                <div className="billing-job-controls">
                                                                    {statusDetails.billingApprovalLastMessage != null &&
                                                                        <span className="d-flex text-muted"
                                                                              title="Show Approve Billing Log">
                                                                            <small className="d-md-inline-block ml-auto">
                                                                                <Button
                                                                                    onClick={this.handleShowBillingJobLog}
                                                                                    className="btn btn-xs btn-order-log-toggle btn-billing-approval-log-display"
                                                                                    type="button"
                                                                                >
                                                                                    <i id="billingApprovalLogDisplaySelector"
                                                                                       className="fas fa-list-ul"/>
                                                                                </Button>
                                                                            </small>
                                                                        </span>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {statusDetails.billingApprovalLastMessage != null &&
                                                    <>
                                                        <div className="progress">
                                                            {statusDetails.billingApprovalTotalToComplete > 0 &&
                                                                <div
                                                                    className="progress-bar bg-success-with-pulse"
                                                                    role="progressbar"
                                                                    style={{width: 'calc(' + statusDetails.billingApprovalNumCompleted + '/' + statusDetails.billingApprovalTotalToComplete + '*100%)'}}
                                                                    aria-valuenow="100"
                                                                    aria-valuemin="0"
                                                                    aria-valuemax="100"
                                                                >
                                                                    <span style={{whiteSpace: "nowrap"}}>
                                                                        {statusDetails.billingApprovalPercentComplete}%
                                                                    </span>
                                                                </div>
                                                            }
                                                            {statusDetails.billingApprovalTotalToComplete === 0 &&
                                                                <div
                                                                    className="progress-bar bg-success-with-pulse"
                                                                    role="progressbar"
                                                                    style={{width: '2%'}}
                                                                    aria-valuenow="100"
                                                                    aria-valuemin="0"
                                                                    aria-valuemax="100"
                                                                >
                                                                        <span style={{whiteSpace: "nowrap"}}>
                                                                            {statusDetails.billingApprovalPercentComplete}%
                                                                        </span>
                                                                </div>
                                                            }
                                                        </div>

                                                        <div>
                                                            <span>Last Updated {statusDetails.billingApprovalLastUpdatedDate}ago</span>
                                                            <span className="float-right">
                                                                {statusDetails.billingApprovalTimeRemainingMessage != null && statusDetails.billingApprovalTotalToComplete > 0 &&
                                                                    <span>
                                                                        {statusDetails.billingApprovalTimeRemainingMessage}remaining
                                                                    </span>
                                                                }
                                                                {statusDetails.billingApprovalTimeRemainingMessage === null && statusDetails.billingApprovalTotalToComplete !== 0 && statusDetails.billingApprovalTotalToComplete === statusDetails.billingApprovalNumCompleted &&
                                                                    <span>Completed</span>
                                                                }
                                                            </span>
                                                        </div>
                                                    </>
                                                    }
                                                </div>

                                                {billingApprovalJobLogDisplayed && billingApprovalLogMessagesResponse &&
                                                    <div className="cmv-container-log-viewer cmv-container-order-log-viewer">
                                                        {billingApprovalLogMessagesResponse.statusList.map((log) => <span key={log.id}>{log.stringVal}<br/></span>)}
                                                    </div>
                                                }
                                            </>
                                            }
                                            {statusDetails == null &&
                                                <div>
                                                    <div className="row">
                                                        <div className="col-12 col-md-3 col-xxl-2">
                                                            <p className="billing-job-id">Not Started</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>

                                        <div className="order-service-order-wrapper">
                                            <h5>Invoice Generation</h5>

                                            {statusDetails != null &&
                                            <>
                                                <div>
                                                    <div className="row">
                                                        <div className="col-12 col-md-3 col-xxl-2">
                                                            {statusDetails.invoiceGenerationLastMessage != null &&
                                                                <p className="billing-job-id">
                                                                    {statusDetails.invoiceGenerationLastMessage}
                                                                </p>
                                                            }
                                                            {statusDetails.invoiceGenerationLastMessage == null &&
                                                                <p className="billing-job-id">Not Started</p>
                                                            }
                                                        </div>

                                                        <div className="col-12 col-md-9 col-xxl-10">
                                                            <div className="billing-job-caption">
                                                                <div className="billing-job-controls">
                                                                    {statusDetails.invoiceGenerationLastMessage != null &&
                                                                        <span className="d-flex text-muted"
                                                                              title="Show Invoice Generation Log">
                                                                            <small className="d-md-inline-block ml-auto">
                                                                                <Button
                                                                                    onClick={this.handleShowBillingJobLog}
                                                                                    className="btn btn-xs btn-order-log-toggle btn-invoice-generation-log-display"
                                                                                    type="button"
                                                                                >
                                                                                    <i id="invoiceGenerationLogDisplaySelector"
                                                                                       className="fas fa-list-ul"/>
                                                                                </Button>
                                                                            </small>
                                                                        </span>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {statusDetails.invoiceGenerationLastMessage != null &&
                                                    <>
                                                        <div className="progress">
                                                            {statusDetails.invoiceGenerationTotalToComplete > 0 &&
                                                                <div
                                                                    className="progress-bar bg-success-with-pulse"
                                                                    role="progressbar"
                                                                    style={{width: 'calc(' + statusDetails.invoiceGenerationNumCompleted + '/' + statusDetails.invoiceGenerationTotalToComplete + '*100%)'}}
                                                                    aria-valuenow="100"
                                                                    aria-valuemin="0"
                                                                    aria-valuemax="100"
                                                                >
                                                                <span style={{whiteSpace: "nowrap"}}>
                                                                    {statusDetails.invoiceGenerationPercentComplete}%
                                                                </span>
                                                                </div>
                                                            }
                                                            {statusDetails.invoiceGenerationTotalToComplete === 0 &&
                                                                <div
                                                                    className="progress-bar bg-success-with-pulse"
                                                                    role="progressbar"
                                                                    style={{width: '2%'}}
                                                                    aria-valuenow="100"
                                                                    aria-valuemin="0"
                                                                    aria-valuemax="100"
                                                                >
                                                                    <span style={{whiteSpace: "nowrap"}}>
                                                                        {statusDetails.invoiceGenerationPercentComplete}%
                                                                    </span>
                                                                </div>
                                                            }
                                                        </div>
                                                        <div>
                                                            <span>Last Updated {statusDetails.invoiceGenerationLastUpdatedDate} ago</span>
                                                            <span className="float-right">
                                                                {statusDetails.invoiceGenerationTimeRemainingMessage != null && statusDetails.invoiceGenerationTotalToComplete > 0 &&
                                                                    <span>
                                                                        {statusDetails.invoiceGenerationTimeRemainingMessage}remaining
                                                                    </span>
                                                                }
                                                                {statusDetails.invoiceGenerationTimeRemainingMessage === null && statusDetails.invoiceGenerationTotalToComplete !== 0 && statusDetails.invoiceGenerationTotalToComplete === statusDetails.invoiceGenerationNumCompleted &&
                                                                    <span>Completed</span>
                                                                }
                                                            </span>
                                                        </div>
                                                    </>
                                                    }
                                                </div>

                                                {invoiceGenerationJobLogDisplayed && invoiceGenerationLogMessagesResponse &&
                                                    <div className="cmv-container-log-viewer cmv-container-order-log-viewer">
                                                        {invoiceGenerationLogMessagesResponse.statusList.map((log) => <span key={log.id}>{log.stringVal}<br/></span>)}
                                                    </div>
                                                }
                                            </>
                                            }
                                            {statusDetails == null &&
                                            <div>
                                                <div className="row">
                                                    <div className="col-12 col-md-3 col-xxl-2">
                                                        <p className="billing-job-id">Not Started</p>
                                                    </div>
                                                </div>
                                            </div>
                                            }

                                        </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button onClick={this.closeModal} className="btn">
                                    Close
                                </button>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-backdrop show" tabIndex="1" />
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    const modalProps = getModalData(state).modalProps;
    return {
        modalProps,
    };
};

const mapDispatchToProps = {
    loadBillingJobStatusSummary,
    loadBillingJobStatusDetails,
    hideModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(BillingJobMonitorModal);

