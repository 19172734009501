import React, { Component, Fragment } from 'react';
import ReactHtmlParser from 'react-html-parser';
import ServicesServiceLine from './ServicesServiceLine';
import { isEmpty } from '../../../utils/helpers';

class ServicesPlan extends Component {
	
	addLines = (e) => {
		const { planInfo, planIndex, values } = this.props;
		let value = e.target.value;
		const maxLines = planInfo.requiredLines + planInfo.additionalLines;
		const minLines = planInfo.requiredLines;
		const currentLinesNumber = values.plans[planIndex].servicelines.length;

		if (value > values.plans[planIndex].servicelines.length) {
			let updatedServicelines = values.plans[planIndex].servicelines;
			for (let index = 0; index < value - values.plans[planIndex].servicelines.length; index++) {
				let currentServiceLine = {};
				if (planInfo.serviceModel.directorySystemNumType.defaultSource === 'number_inventory') {
					currentServiceLine = {
						features: [],
						devices: undefined,
						number: '',
						serviceLineRelation: 'Additional'
					};
				} else {
					currentServiceLine = {
						features: [],
						devices: undefined,
						serviceLineRelation: 'Additional'
					};
				}

				// ADD DEVICES TO THE LINE

				if (!isEmpty(planInfo.serviceModel.defaultEquipment)) {
					planInfo.serviceModel.defaultEquipment.deviceProfiles.forEach((element) => {
						if (isEmpty(element.possibleItems)) {
							return;
						}
						let currentDevice = {
							deviceGroupId: planInfo.serviceModel.defaultEquipment.deviceGroupId,
							deviceProfileId: element.id,
							itemId: element.possibleItems[0].id,
							itemDescription: element.possibleItems[0].description,
							itemPrice: element.possibleItems[0].itemProperties[0].listPrice
						};
						currentServiceLine['devices'] = [];
						currentServiceLine['devices'].push(currentDevice);
					});
				}

				//ADD FEATURES TO THE LINE

				if (!isEmpty(planInfo.additionalIncludedFeatures)) {
					planInfo.additionalIncludedFeatures.forEach((element) => {
						let feature = {
							...element
						};
						currentServiceLine.features.push(feature);
					});
				}
				if (!isEmpty(planInfo.additionalIncludedFeaturePackages)) {
					planInfo.additionalIncludedFeaturePackages.forEach((element) => {
						let feature = {
							featurePackageId: element.featurePackageId,
							featurePackageDescription: element.description,
							featurePackageName: element.name,
							featurePackageNote: element.note,
							...element.featurePackageOptions[0]
						};

						currentServiceLine.features.push(feature);
					});
				}
				if (!isEmpty(planInfo.additionalMandatoryFeatures)) {
					planInfo.additionalMandatoryFeatures.forEach((element) => {
						let feature = {
							...element
						};
						currentServiceLine.features.push(feature);
					});
				}
				if (!isEmpty(planInfo.additionalMandatoryFeaturePackages)) {
					planInfo.additionalMandatoryFeaturePackages.forEach((element) => {
						let feature = {
							featurePackageId: element.featurePackageId,
							featurePackageDescription: element.description,
							featurePackageName: element.name,
							featurePackageNote: element.note,
							...element.featurePackageOptions[0]
						};

						currentServiceLine.features.push(feature);
					});
				}
				updatedServicelines = [ ...updatedServicelines, currentServiceLine ];
			}

			this.props.setFieldValue(`plans.${planIndex}.servicelines`, updatedServicelines);
		} else if (value < values.plans[planIndex].servicelines.length) {
			let updatedServicelines = values.plans[planIndex].servicelines.slice(0, value);

			this.props.setFieldValue(`plans.${planIndex}.servicelines`, updatedServicelines);
		} else {
		}
	};

	render() {
		const { planInfo, planIndex, values } = this.props;

		var serviceLineNumber = [];
		for (var i = planInfo.requiredLines; i <= planInfo.requiredLines + planInfo.additionalLines; i++) {
			let obj = { value: i, label: i };
			serviceLineNumber.push(obj);
		}

		return (
			<div className="row service-wrapper container-no-of-services">
				<div className="col-lg-4">
					<h4 className="mt-1">
						<i className={planInfo.serviceModel.icon} />&nbsp;{ReactHtmlParser(planInfo.description)}
					</h4>
					<p className="service-wrapper-description">{ReactHtmlParser(planInfo.note)}</p>
				</div>
				<div className="col-lg-8">
					<div className="form-group">
						<label htmlFor="" className="d-flex">
							Number of lines (max: {planInfo.requiredLines + planInfo.additionalLines})<span className="ml-auto">
								{/* <a href="#" className="ml-1 btn btn-xs btn-primary btn-bulk-add">
									Add Bulk
								</a> */}
							</span>
						</label>
						<div className="input-group">
							{/* <input
								type="number"
								id="servicelines-wireless"
								min={planInfo.requiredLines}
								max={planInfo.requiredLines + planInfo.additionalLines}
								className="form-control"
								value={values.plans[planIndex] && values.plans[planIndex].servicelines.length}
								onChange={this.addLines}
							/> */}

							<select
								className="form-control"
								value={values.plans[planIndex] && values.plans[planIndex].servicelines && values.plans[planIndex].servicelines.length}
								onChange={this.addLines}
								disabled={planInfo.additionalLines === 0}
							>
								{serviceLineNumber.map((lineNumber) => (
									<option value={lineNumber.value} key={lineNumber.value}>
										{lineNumber.label}
									</option>
								))}
							</select>
						</div>
					</div>
					<div className="container-servicelines">
						<div className="form-row form-row-header d-none d-md-flex">
							<div className="col">
								{planInfo.serviceModel.directorySystemNumType.defaultSource === 'number_inventory' ? (
									<Fragment>
										<p className="text-right">Phone Numbers</p>
										<p className="text-right mt-1">
											<small>Bring customer's number in or select from inventory</small>
										</p>
									</Fragment>
								) : (
									<Fragment>
										<p className="text-right">Lines</p>
									</Fragment>
								)}
							</div>
							{!isEmpty(planInfo.equipment) && (
								<div className="col">
									<p className="text-right">Equipment</p>
									<p className="text-right mt-1">
										<small>Change or select equipment</small>
									</p>
								</div>
							)}
							<div className="col">
								<p className="text-right">Agreement</p>
								<p className="text-right mt-1">
									<small>Terms & Conditions and Contract length</small>
								</p>
							</div>

						</div>
						{values.plans[planIndex] && values.plans[planIndex].servicelines &&
							values.plans[planIndex].servicelines.map((line, index) => (
								<ServicesServiceLine
									lineInfo={line}
									key={index}
									lineIndex={index}
									planIndex={planIndex}
									{...this.props}
								/>
							))}
					</div>
				</div>
			</div>
		);
	}
}

export default ServicesPlan;
