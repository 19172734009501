import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactHtmlParser from 'react-html-parser';
import ServiceWrapper from './ServiceWrapper';
import moment from "moment";
import {getAccountDetails} from "../../../../../../selectors";
import isEmpty from "../../../../../../utils/helpers";

class ServiceOrder extends Component {

	state = {
		isPlanNoteCollapsed: false,
		isServiceLinesCollapsed: false,
		isPaginationDropdownOpen: false,
		pageActiveNumber: 1,
		pageShowingFrom: 0,
		pageShowingTo: 9,
		pageItemsCount: 10,
	};

	handlePlanNoteCollapse = () => {
		this.setState((prevState) => ({
			isPlanNoteCollapsed: !prevState.isPlanNoteCollapsed
		}));
	};

	handleServiceLinesCollapse = () => {
		this.setState((prevState) => ({
			isServiceLinesCollapsed: !prevState.isServiceLinesCollapsed
		}));
	};

	handlePaginationDropdown = () => {

		this.setState((prevState) => ({
			isPaginationDropdownOpen: !prevState.isPaginationDropdownOpen
		}));
	}

	handleItemsPerPage = (items, itemsTotal) => {

		//	if items are more than we have
		if (items * this.state.pageActiveNumber > itemsTotal) {

			this.setState({
				isPaginationDropdownOpen: false,
				pageItemsCount: items,
				pageActiveNumber: Math.ceil(itemsTotal / items),
				pageShowingFrom: items * (Math.ceil(itemsTotal / items) - 1),
				pageShowingTo: (items * (Math.ceil(itemsTotal / items) - 1)) + items,
			});
		}
		else {

			this.setState({
				isPaginationDropdownOpen: false,
				pageItemsCount: items,
				pageShowingFrom: items * (this.state.pageActiveNumber - 1),
				pageShowingTo: (items * (this.state.pageActiveNumber - 1)) + items,
			});
		}
	}

	handlePageChange = (pageNumber, pagesTotal) => {

		this.setState({
			pageActiveNumber: pageNumber,
			pageShowingFrom: this.state.pageItemsCount * (pageNumber - 1),
			pageShowingTo: (this.state.pageItemsCount * pageNumber) - 1
		});
	};

	render() {

		const {
			isPlanNoteCollapsed,
			isServiceLinesCollapsed,
			pageActiveNumber,
			pageShowingFrom,
			pageShowingTo,
			pageItemsCount,
			isPaginationDropdownOpen
		} = this.state;

		const {
			searchWord,
			serviceInfo,
			activeServiceDropdown,
			handleActiveDropDown,
			activeServiceLineDropdownId,
			handleProductAndPackagesClick,
			history,
			handleRemoveServiceLinesClick,
			accountInfo,
			showHistoricalData,
		} = this.props;

		let pageItemsTotal = serviceInfo.serviceLines.length;
		let pagesTotal = Math.ceil(pageItemsTotal / pageItemsCount);

		let serviceLineNumberMatch = false;

		let hasActiveLines = false;
		let hasSuspendedLines = false;
		let hasDisconnectedLines = false;

		for (const serviceLine of serviceInfo.serviceLines) {

			if (serviceLine.status === 'Active') {
				hasActiveLines = true;
				break;
			}
		}

		return (
			<div
				className={"plan-wrapper"
				+ (isServiceLinesCollapsed ? "" : " plan-wrapper-expanded")
				+ (serviceInfo.endDate ? " plan-wrapper-historical" : "")
				}
			>
				<div className="plan-header">
					<span className="plan-title">
						<i className={serviceInfo.serviceModelIcon} /> {serviceInfo.planDescription}
						<p className="plan-subtitle plan-subtitle-aligned">
							<span>
								{isEmpty(serviceInfo.endDate) && <>
									${serviceInfo.recurringCharge.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}/mo
									{serviceInfo.startDate && " | Started "}
								</>}
								{serviceInfo.startDate && moment(serviceInfo.startDate).format('MM/DD/YYYY')}
								{serviceInfo.endDate && (" - " + moment(serviceInfo.endDate).format('MM/DD/YYYY'))}
							</span>
							<br/>
							{serviceInfo.address && serviceInfo.address}
						</p>
					</span>
					<span className="plan-toolbar">
						{isEmpty(serviceInfo.endDate) && serviceInfo.additionalLines + serviceInfo.requiredLines > 1 &&
						<span className="badge badge-outline-secondary">
							{serviceInfo.requiredLines} &#9679;{' '}
							{serviceInfo.additionalLines + serviceInfo.requiredLines} Max
						</span>
						}
						<span className="badge badge-outline-success">{serviceInfo.planCategory}</span>
						{serviceInfo.planType !== "POSTPAID" &&
							<span className="badge badge-outline-info">{serviceInfo.planType}</span>
						}
						<span>
							<button
								onClick={this.handlePlanNoteCollapse}
								className="btn btn-outline-secondary btn-sm btn-plan-details-toggler"
							>
								<i className="fas fa-info" />
							</button>
						</span>
						{isEmpty(serviceInfo.endDate) &&
						<span>
							<div className={activeServiceDropdown !== serviceInfo.accountPlanId ? 'dropdown show' : 'dropdown'}>
								<button
									className="btn btn-secondary dropdown-toggle btn-sm"
									role="button"
									id="plan1-actions"
									data-toggle="dropdown"
									aria-haspopup="true"
									aria-expanded="false"
									onClick={() => handleActiveDropDown('service', serviceInfo.accountPlanId)}
									onBlur={() => handleActiveDropDown('service', serviceInfo.accountPlanId, true)}
								>
									<i className="fas fa-cog" />
								</button>
								<div
									className={'dropdown-menu dropdown-menu-right' + (activeServiceDropdown !== serviceInfo.accountPlanId ? '' : ' show')}
									aria-labelledby="plan1-actions"
								>
									<a className="dropdown-item" disabled>Change Plan</a>
                                    <div className="dropdown-divider" />
									{serviceInfo.serviceLines.length < (1 + serviceInfo.requiredLines + serviceInfo.additionalLines) &&
									<a className="dropdown-item" disabled>Add Service Lines</a>
									}
									{hasActiveLines &&
									<a className="dropdown-item" onMouseDown={() => handleRemoveServiceLinesClick(accountInfo, serviceInfo)}>Remove Service Lines</a>
									}
									{hasActiveLines &&
									<a className="dropdown-item" disabled>Suspend Service Lines</a>
									}
									{hasSuspendedLines &&
									<a className="dropdown-item" disabled>Restore Service Lines</a>
									}
									{hasDisconnectedLines &&
									<a className="dropdown-item" disabled>Reconnect Service Lines</a>
									}
								</div>
							</div>
						</span>
						}
						<span>
							<button
								onClick={this.handleServiceLinesCollapse}
								className="btn btn-secondary btn-sm"
								role="button"
							>
								{serviceInfo.serviceLines && (
									pageItemsTotal + (pageItemsTotal > 1 ? ' lines ' : ' line ')
								)}
								<i className={"fas" + (isServiceLinesCollapsed ? " fa-angle-double-down" : " fa-angle-double-up")} />
							</button>
						</span>
					</span>
				</div>
				<div className="plan-body">

					<div className={isPlanNoteCollapsed ? 'plan-details' : 'plan-details collapse'}>
						<div className="plan-description">{ReactHtmlParser(serviceInfo.planNote)}</div>
					</div>

					<div className={"services-wrapper" + (isServiceLinesCollapsed ? " d-none" : "")}>

						<div className="pagination-custom">

							<div className="pagination-results">

								{/* check if we have a service line number match */}
								{serviceInfo.serviceLines.map((service, index) => (
									searchWord === service.number && (
										serviceLineNumberMatch = true
									)
								))}

								{serviceInfo.serviceLines.map((service, index) => (
									<Fragment>

										{/* if we have a service line number match ... */}
										{serviceLineNumberMatch ? (

											// ... only show matched line
											searchWord === service.number && (
												<ServiceWrapper
													key={service.serviceLineId}
													service={service}
													serviceLinesCount={pageItemsTotal}
													directorySystemNumType={serviceInfo.serviceModel.directorySystemNumType.description}
													accountPlanId={serviceInfo.accountPlanId}
													planId={serviceInfo.planId}
													handleActiveDropDown={handleActiveDropDown}
													activeServiceLineDropdownId={activeServiceLineDropdownId}
													handleProductAndPackagesClick={handleProductAndPackagesClick}
													showAddEquipmentWizardHandler={this.props.showAddEquipmentWizardHandler}
													showRemoveEquipmentWizardHandler={this.props.showRemoveEquipmentWizardHandler}
													showSwapEquipmentWizardHandler={this.props.showSwapEquipmentWizardHandler}
													showHistoricalData={showHistoricalData}
													history={history}
												/>
											)
										) : (

											 // ... otherwise show all items on current page
											index >= pageShowingFrom && index <= pageShowingTo && (
												<ServiceWrapper
													key={service.serviceLineId}
													service={service}
													serviceLinesCount={pageItemsTotal}
													directorySystemNumType={serviceInfo.serviceModel.directorySystemNumType.description}
													accountPlanId={serviceInfo.accountPlanId}
													planId={serviceInfo.planId}
													handleActiveDropDown={handleActiveDropDown}
													activeServiceLineDropdownId={activeServiceLineDropdownId}
													handleProductAndPackagesClick={handleProductAndPackagesClick}
													showAddEquipmentWizardHandler={this.props.showAddEquipmentWizardHandler}
													showRemoveEquipmentWizardHandler={this.props.showRemoveEquipmentWizardHandler}
													showSwapEquipmentWizardHandler={this.props.showSwapEquipmentWizardHandler}
													showHistoricalData={showHistoricalData}
													history={history}
												/>
											)
										)}

									</Fragment>
								))}

							</div>

							{!serviceLineNumberMatch && pagesTotal > 1 && (
								<div className="pagination-footer">

									<label>
										<span>Show </span>
										<span className={'react-bs-table-sizePerPage-dropdown dropdown' + (isPaginationDropdownOpen ? ' show' : '')}>

											<button
												id="pageDropDown"
												type="button"
												className={"btn btn-default btn-secondary dropdown-toggle" + (isPaginationDropdownOpen ? ' show' : '')}
												data-toggle="dropdown"
												aria-expanded="false"
												onClick={this.handlePaginationDropdown}
											>
												{pageItemsCount} <span><span className="caret"></span></span>
											</button>

											<div
												className={"dropdown-menu" + (isPaginationDropdownOpen ? ' show' : '')}
												role="menu"
												aria-labelledby="pageDropDown"
											>
												<button className="dropdown-item" onClick={() => this.handleItemsPerPage(10, pageItemsTotal)}>10</button>
												<button className="dropdown-item" onClick={() => this.handleItemsPerPage(25, pageItemsTotal)}>25</button>
												<button className="dropdown-item" onClick={() => this.handleItemsPerPage(50, pageItemsTotal)}>50</button>
												<button className="dropdown-item" onClick={() => this.handleItemsPerPage(100, pageItemsTotal)}>100</button>
											</div>

										</span>
										<span> entries</span>
									</label>

									<ul className="pagination">

										{/* Don't show First, Prev & first page buttons on first page */}
										{pageActiveNumber > 1 && ( <Fragment>
											<li className="page-item" title="first page">
												<button className="page-link" onClick={() => this.handlePageChange(1)}>First</button>
											</li>
											<li className="page-item" title="previous page">
												<button className="page-link" onClick={() => this.handlePageChange(pageActiveNumber - 1, pagesTotal)}>Previous</button>
											</li>
											<li className="page-item" title={pageActiveNumber - 1}>
												<button className="page-link" onClick={() => this.handlePageChange(pageActiveNumber - 1, pagesTotal)}>{pageActiveNumber - 1}</button>
											</li>
										</Fragment> )}

										{/* Show current page button */}
										<li className="page-item active" title={pageActiveNumber}>
											<button className="page-link">{pageActiveNumber}</button>
										</li>

										{/* Don't show next page button on next to last page */}
										{pageActiveNumber <= (pagesTotal - 1) && ( <Fragment>
											<li className="page-item" title={pageActiveNumber + 1}>
												<button className="page-link" onClick={() => this.handlePageChange(pageActiveNumber + 1, pagesTotal)}>{pageActiveNumber + 1}</button>
											</li>
										</Fragment> )}

										{/* Show third page button on first page if there is a third page */}
										{pageActiveNumber === 1 && pagesTotal > 2 && ( <Fragment>
											<li className="page-item" title={pageActiveNumber + 2}>
												<button className="page-link" onClick={() => this.handlePageChange(pageActiveNumber + 2, pagesTotal)}>{pageActiveNumber + 2}</button>
											</li>
										</Fragment> )}

										{/* Don't show Next & Last buttons on last page */}
										{pageActiveNumber < pagesTotal && ( <Fragment>
											<li className="page-item" title="next page">
												<button className="page-link" onClick={() => this.handlePageChange(pageActiveNumber + 1, pagesTotal)}>Next</button>
											</li>
											<li className="page-item" title="last page">
												<button className="page-link" onClick={() => this.handlePageChange(pagesTotal, pagesTotal)}>Last</button>
											</li>
										</Fragment> )}

									</ul>

								</div>
							)}

						</div>

					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	const accountInfo = getAccountDetails(state).accountDetails.accountInfo;

	return {
		accountInfo,
	};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ServiceOrder);
