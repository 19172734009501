import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {hideModal} from '../../../actions/modal.actions';
import { getSystemLocations } from '../../../actions/admin.actions';
import {    getModalData, getDomain, getAdmin, getProducts} from '../../../selectors';
import { getPlanCategories, getServiceModels, getPlanLocations } from "../../../actions/domain.actions";
import { addPlan, getPlan, clearPlan, updatePlan} from "../../../actions/products.action";
import {Formik} from 'formik';
import NumericInput from 'react-numeric-input';
import isEmpty from "../../../utils/helpers";
import FormSelect from "../../../components/UI/FormSelect";
import TextInput from "../../../components/UI/TextInput";
import DatePicker from "react-datepicker";
import Loader from "../../../components/Loader";
import * as Yup from "yup";
import {toastr} from "react-redux-toastr";

const asOptions = (source, mapper) => {
    if (!Array.isArray(source)) {
        return [];
    }

    return source.map(mapper);
}

const planCategoriesAsOptions = (planCategories) => asOptions(planCategories, planCategory => {
    return {
        label: planCategory.description,
        value: planCategory.id
    };
});

const serviceModelsAsOptions = (serviceModels) => asOptions(serviceModels, serviceModel => {
    return {
        label: serviceModel.description,
        value: serviceModel.id
    };
});

const locationsAsOptions = (locations) => asOptions(locations, location => {
    return {
        label: location.name,
        value: location.id
    }
});

const findCurrentLocationIds = (planLocations) => {
    let currentLocations = [];
    if(!isEmpty(planLocations)){
        planLocations.map((planLocation => {
            currentLocations.push(planLocation.location);
        }))
    }
    return currentLocations;
}


class AddPlanModal extends Component {

    state = {
        alertMessage: '',
        partnerSearchTem: null,
        belongsToPartner: false,
        selectedPartner: null,
        partnerOptions: [],
        locationOptions: [],
        isPlanLoading: true,
        requiredLines: 1,
        additionalLines: 0
    };

    componentDidMount() {
        this.props.clearPlan();
        this.props.getPlanCategories();
        this.props.getServiceModels();
        this.props.getSystemLocations();

        const {modalProps} = this.props;
        if(modalProps.value) {
            this.props.getPlan(modalProps.value).then((response) => {
                this.setState({
                    isPlanLoading: false,
                    requiredLines: response && response.requiredLines,
                    additionalLines: response && response.additionalLines
                })
            })
        }else{
            this.setState({
                isPlanLoading: false
            })
        }

    }

    componentWillUnmount() {
        this.props.clearPlan();
    }

    handleLinesChange = (target, value) => {
        if(value > 9999){
            value = 9999;
        }
        if(target == 'requiredLines'){
            this.setState({
                requiredLines: value
            });
        }else {
            this.setState({
                additionalLines: value
            })
        }
    }

    planSchema = () => {
        let validationSchema = {
            name: Yup.string().required('Required'),
            description: Yup.string().required('Required'),
            planCategoryId: Yup.string().required('Required').typeError('Required'),
            serviceModelId: Yup.string().required('Required').typeError('Required'),
            startDateTime: this.props.modalProps.type === 'create' ? Yup.date().required('Required').typeError('Required') : ''
        };

        return Yup.object().shape(validationSchema);
    }



    formSubmit = (values, actions) => {
        const { currentUser } = this.props;
        const submitData = {...values};

        submitData['newPlan'] = this.props.modalProps.type === 'create' ? true : false;
        submitData['requiredLines'] = this.state.requiredLines;
        submitData['additionalLines'] = this.state.additionalLines;


        this.setState({alertMessage: ''}, () => {
            const method = this.props.modalProps.type === 'create' ? this.props.addPlan : this.props.updatePlan;
            let planId = this.props.modalProps.value;
            method(planId, submitData).then((response) => {
                if (!response.data) {
                    actions.setSubmitting(false);
                    this.setState({alertMessage: 'Unknown error. Please try again later'});
                    return;
                }
                if (!response.data.success) {
                    actions.setSubmitting(false);
                    this.setState({alertMessage: response.data.error.message});
                    return;
                } else {
                    this.props.hideModal();
                    this.props.modalProps.reloadFunction(true);
                    toastr.success(response.data.message, {timeOut: 4000, position: 'top-center'});
                }

                this.props.hideModal();
                this.props.modalProps.reloadFunction(true);

            });
        });
    }

    scrollDropdownIntoView = (e) => {
        const elementClicked = e.target.nodeName;
        const boundingElement = e.currentTarget;
        const modalBody = document.getElementsByClassName('modal-body')[0];

        if (elementClicked !== 'LABEL') {

            setTimeout(function () {

                // Scroll down if the bottom is hidden...
                if (boundingElement.getBoundingClientRect().bottom > modalBody.getBoundingClientRect().bottom) {

                    // ... and the top won't end up hidden by scrolling down
                    if (boundingElement.getBoundingClientRect().height < modalBody.getBoundingClientRect().height) {

                        // Scroll down till bottom of element reaches bottom of screen
                        boundingElement.scrollIntoView({block: "end"});
                    }
                }
            }, 200);
        }
    }


    render() {
        const { alertMessage, locationOptions, partnerOptions, isPlanLoading } = this.state;
        const { modalProps, plan, planCategories, serviceModels, locationsLoader, systemLocations} = this.props;

        const initialUserLocations = plan && plan.planLocations || [];
        const currentLocationIds = findCurrentLocationIds(initialUserLocations);


        return (
            <Fragment>
                <div className="modal" style={{display: 'block'}} tabIndex="-1" role="dialog">
                    {isPlanLoading &&
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">
                                    {modalProps.type === 'create' ? 'Add Plan' : 'Edit Plan'}
                                </h5>
                                <button onClick={this.props.hideModal} type="button" className="close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <Loader />
                            <div className="modal-footer">
                                <button onClick={this.props.hideModal} className="btn" type="button">
                                    Cancel
                                </button>
                                <button type="submit" className="btn btn-primary">
                                    {modalProps.type === 'create' ? 'Add Plan' : 'Edit Plan'}
                                </button>
                            </div>
                        </div>
                    </div>
                    }
                    {!isPlanLoading &&
                    <Formik
                        initialValues={{
                            isActive: modalProps.type === 'create' ? true : plan && plan.active,
                            id: plan && plan.planId || null,
                            name: plan &&  plan.name || '',
                            description: plan && plan.description || '',
                            startDateTime: plan && plan.startDate || null,
                            endDateTime: plan && plan.endDate || null,
                            serviceModelId: plan && plan.serviceModelId || null,
                            planCategoryId: plan && plan.planCategoryId || null,
                            hideCustomerPortal: plan && plan.hideCustomerPortal != undefined ? plan.hideCustomerPortal: true,
                            hideFromPrice: plan && plan.hideFromPrice != undefined ? plan.hideFromPrice: false,
                            includeSurvey: plan && plan.includeSurvey != undefined ? plan.includeSurvey: true,
                            includeSignup: plan &&  plan.includeSignup != undefined ? plan.includeSignup: true,
                            includeService: plan && plan.includeService != undefined ? plan.includeService: true,
                            note: plan && plan.note || null,
                            locationIds: currentLocationIds.map(location => location.id) || null
                        }}
                        validationSchema={this.planSchema()}
                        onSubmit={this.formSubmit}
                        render={({
                                     handleChange,
                                     handleSubmit,
                                     handleBlur,
                                     values,
                                     errors,
                                     touched,
                                     isSubmitting,
                                     setFieldValue
                                 }) => (
                            <form onSubmit={handleSubmit}>
                                <div className="modal-dialog">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title">
                                                {modalProps.type === 'create' ? 'Add Plan' : 'Edit Plan'}
                                            </h5>
                                            <button onClick={this.props.hideModal} type="button" className="close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div className="modal-body form-horizontal">
                                            {!isEmpty(alertMessage) && (
                                                <div className="alert alert-inline alert-danger alert-dismissible">
                                                    <p className="mb-0">{alertMessage}</p>
                                                </div>
                                            )}
                                            <div className="form-group">
												<span className="h-check">
													<label>&nbsp;Active&nbsp;</label>
													<div className="form-check checkbox-slider checkbox-slider--b-flat">
														<label>
															<input
                                                                name="isActive"
                                                                type="checkbox"
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                checked={values.isActive}
                                                            />
															<span>&nbsp;</span>
														</label>
													</div>
												</span>
                                            </div>
                                            <div className="form-group">
                                                <TextInput
                                                    label="Name"
                                                    name="name"
                                                    value={values.name.toUpperCase()}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    errors={errors}
                                                    touched={touched}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <TextInput
                                                    label="Description"
                                                    name="description"
                                                    value={values.description}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    errors={errors}
                                                    touched={touched}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <div onClick={this.scrollDropdownIntoView.bind(this)}>
                                                    <FormSelect
                                                        title="Service Model"
                                                        fieldName="serviceModelId"
                                                        placeholder="Select one..."
                                                        options={serviceModelsAsOptions(serviceModels)}
                                                        setFieldValue={setFieldValue}
                                                        value={values.serviceModelId}
                                                        onBlur={handleBlur}
                                                        errors={errors}
                                                        touched={touched}
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div onClick={this.scrollDropdownIntoView.bind(this)}>
                                                    <FormSelect
                                                        title="Plan Category"
                                                        fieldName="planCategoryId"
                                                        placeholder="Select one..."
                                                        options={planCategoriesAsOptions(planCategories)}
                                                        setFieldValue={setFieldValue}
                                                        value={values.planCategoryId}
                                                        onBlur={handleBlur}
                                                        errors={errors}
                                                        touched={touched}
                                                    />
                                                </div>
                                            </div>

                                            <div className="form-group">
                                                <div onClick={this.scrollDropdownIntoView.bind(this)}>
                                                    <FormSelect
                                                        title="Locations"
                                                        fieldName="locationIds"
                                                        options={locationsAsOptions(systemLocations)}
                                                        setFieldValue={setFieldValue}
                                                        value={values.locationIds}
                                                        isMulti={true}
                                                        onBlur={handleBlur}
                                                        errors={errors}
                                                        touched={touched}
                                                        isLoading={locationsLoader}
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="date">Start Date</label>
                                                <div className="customDatePickerWidth">
                                                    <DatePicker
                                                       className={"form-control text-left" +
                                                       (errors.startDateTime && touched.startDateTime ? " is-invalid" : "")}
                                                        fieldName="startDateTime"
                                                        placeholderText={"Select Date"}
                                                        autoFocus={false}
                                                        shouldCloseOnSelect={true}
                                                        popperPlacement={'auto'}
                                                        selected={values.startDateTime}
                                                        dateFormat="MM/dd/yyyy"
                                                        onChange={(date) => setFieldValue('startDateTime', date)}
                                                       isClearable={true}
                                                    />
                                                    {errors.startDateTime && touched.startDateTime &&
                                                    <div className="invalid-feedback">{errors.startDateTime}</div>
                                                    }
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="date">End Date (optional)</label>
                                                <div className="customDatePickerWidth">
                                                    <DatePicker
                                                        className={"form-control text-left"}
                                                        fieldName="endDateTime"
                                                        placeholderText={"Select Date"}
                                                        autoFocus={false}
                                                        shouldCloseOnSelect={true}
                                                        popperPlacement={'auto'}
                                                        selected={values.endDateTime}
                                                        dateFormat="MM/dd/yyyy"
                                                        onChange={(date) => setFieldValue('endDateTime', date)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="form-check checkbox-slider checkbox-slider--b-flat">
                                                    <label>
                                                        <input
                                                            name="hideCustomerPortal"
                                                            type="checkbox"
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            checked={values.hideCustomerPortal}
                                                        />
                                                        <span>&nbsp;</span>
                                                    </label>
                                                    <label>Hide in Customer Portal</label>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="form-check checkbox-slider checkbox-slider--b-flat">
                                                    <label>
                                                        <input
                                                            name="hideFromPrice"
                                                            type="checkbox"
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            checked={values.hideFromPrice}
                                                        />
                                                        <span>&nbsp;</span>
                                                    </label>
                                                    <label>Hide "From" Price</label>
                                                </div>
                                            </div>
                                            <div className="form-group">
													<div className="form-check checkbox-slider checkbox-slider--b-flat">
														<label>
															<input
                                                                name="includeSignup"
                                                                type="checkbox"
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                checked={values.includeSignup}
                                                            />
															<span>&nbsp;</span>
														</label>
                                                        <label>Available in Signup</label>
													</div>
                                            </div>
                                            <div className="form-group">
                                                <div className="form-check checkbox-slider checkbox-slider--b-flat">
                                                    <label>
                                                        <input
                                                            name="includeSurvey"
                                                            type="checkbox"
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            checked={values.includeSurvey}
                                                        />
                                                        <span>&nbsp;</span>
                                                    </label>
                                                    <label>Available in Survey</label>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="form-check checkbox-slider checkbox-slider--b-flat">
                                                    <label>
                                                        <input
                                                            name="includeService"
                                                            type="checkbox"
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            checked={values.includeService}
                                                        />
                                                        <span>&nbsp;</span>
                                                    </label>
                                                    <label>Available in Service</label>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <NumericInput
                                                    style={{
                                                        input: {
                                                            width: '75px',
                                                            textAlign: 'center'
                                                        }
                                                    }}
                                                    min={1}
                                                    max={9999}
                                                    value={this.state.requiredLines}
                                                    onChange={(event) => this.handleLinesChange('requiredLines', event)}/>
                                                <span>&nbsp;</span>
                                                <label>Required Lines</label>
                                            </div>
                                            <div className="form-group">
                                                <NumericInput
                                                    style={{
                                                        input: {
                                                            width: '75px',
                                                            textAlign: 'center'
                                                        }
                                                    }}
                                                    min={0}
                                                    max={9999}
                                                    value={this.state.additionalLines}
                                                    onChange={(event) => this.handleLinesChange('additionalLines', event)}/>
                                                <span>&nbsp;</span>
                                                <label>Additional Lines</label>
                                            </div>
                                            <div className="form-group">
                                                <span>&nbsp;</span>
                                                {this.state.requiredLines + this.state.additionalLines}
                                                <span>&nbsp;</span>
                                                {(this.state.requiredLines + this.state.additionalLines) > 1 ?
                                                    <label>Total Lines</label>
                                                    :
                                                    <label>Total Line</label>
                                                }
                                            </div>
                                        </div>
                                        <div className="modal-footer">
                                            <button onClick={this.props.hideModal} className="btn" type="button">
                                                Cancel
                                            </button>
                                            <button disabled={isSubmitting} type="submit" className="btn btn-primary">
                                                {modalProps.type === 'create' ? 'Add Plan' : 'Edit Plan'}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        )}
                    />}
                </div>
                <div className="modal-backdrop show" tabIndex="1"/>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    const modalProps = getModalData(state).modalProps,
        systemLocations = getAdmin(state).systemLocations,
        planCategories = getDomain(state).planCategories,
        serviceModels = getDomain(state).serviceModels,
        plan = getProducts(state).plan


    return {
        modalProps,
        systemLocations,
        planCategories,
        serviceModels,
        plan
    };
};

const mapDispatchToProps = {
    getServiceModels,
    getPlan,
    getPlanCategories,
    getSystemLocations,
    clearPlan,
    addPlan,
    updatePlan,
    hideModal
};

export default connect(mapStateToProps, mapDispatchToProps)(AddPlanModal);

