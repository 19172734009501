import React, {Component, Fragment} from "react";
import {Formik} from "formik";
import isEmpty from "../../../../../../utils/helpers";
import {connect} from "react-redux";
import {hideModal} from "../../../../../../actions/modal.actions";
import {getAccountDetails, getModalData} from "../../../../../../selectors";
import FormikInput from "../../../../../../components/Common/Inputs/FormikInput";
import {updateServiceLineDeviceAttributes} from "../../../../../../actions/serviceLine.actions";
import {getServicesAndEquipment} from "../../../../../../actions/accountDetailsActions/servicesAndEquipment.actions";
import {toastr} from "react-redux-toastr";

class EditEquipmentModal extends Component {
    state = {
        device: null,
        alertMessage: '',
    }

    componentDidMount() {
    }

    closeModal = () => {
        this.props.hideModal();
    };

    formSubmit = (values, actions) => {
        const { device, service, showHistoricalData } = this.props.modalProps;
        const{ accountId } = this.props;
        let serviceDeviceNumsArg = [];
        values.serviceDeviceNums.forEach(svcDvcNum => {
            if (svcDvcNum.typeDefaultSource === 'number_inventory') {
                serviceDeviceNumsArg.push(svcDvcNum)
            }
        })

        this.props.updateServiceLineDeviceAttributes(service.serviceLineId, device.id, serviceDeviceNumsArg)
            .then(response => {
                if (response.data && response.data.success) {
                    toastr.success('Equipment attributes have been updated.', { timeOut: 2000, position: 'top-center' });
                    this.props.getServicesAndEquipment(accountId, showHistoricalData);
                    this.props.hideModal();
                } else {
                    this.setState({alertMessage: "Save failed.  Please try again and if necessary contact support."});
                }
            });
    }

    render() {
        const { alertMessage } = this.state;
        const { device } = this.props.modalProps;

        return (
            <Fragment>
                <div className="modal" style={{display: 'block'}} tabIndex="-1" role="dialog">
                    <Formik
                        initialValues={{
                            serviceDeviceNums: device.apiServiceDeviceNums
                            // id: autoPayJob && autoPayJob.id || null,
                        }}
                        // validationSchema={this.systemUserSchema()}
                        onSubmit={this.formSubmit}
                        render={({
                                     handleChange,
                                     handleSubmit,
                                     handleBlur,
                                     values,
                                     errors,
                                     touched,
                                     isSubmitting,
                                     setFieldValue
                                 }) => (
                            <form onSubmit={handleSubmit} className="cmv-form">
                                <div className="modal-dialog">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title">Edit Equipment</h5>
                                            <button onClick={this.closeModal} type="button" className="close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div className="modal-body">
                                            {!isEmpty(alertMessage) && (
                                                <div className="alert alert-inline alert-danger alert-dismissible">
                                                    <p className="mb-0">{alertMessage}</p>
                                                </div>
                                            )}
                                            <div className="form-horizontal">

                                                <fieldset>
                                                    <legend className="as-label">{device.deviceItemDescription}</legend>
                                                    {/*{device.apiServiceDeviceNums && device.apiServiceDeviceNums.map((num, index) =>*/}
                                                    {values.serviceDeviceNums && values.serviceDeviceNums.map((num, index) =>
                                                    <>
                                                        {num.typeDefaultSource !== "number_inventory" && (
                                                            <FormikInput
                                                                wrapperClass="form-group"
                                                                label={num.type}
                                                                type="text"
                                                                name={num.number}
                                                                // name={num.type}
                                                                onBlur={handleBlur}
                                                                value={num.number}
                                                                placeholder="Not provided"
                                                                errors={errors}
                                                                touched={touched}
                                                                onChange={handleChange}
                                                                disabled={isSubmitting || num.typeDefaultSource !== "number_inventory"}
                                                                // disabled={isSubmitting || modalProps.isDisabled}
                                                            />
                                                        )}
                                                        {num.typeDefaultSource === "number_inventory" && (
                                                            <FormikInput
                                                                wrapperClass="form-group"
                                                                label={num.type}
                                                                type="text"
                                                                name={`serviceDeviceNums.${index}.number`}
                                                                // name={num.type}
                                                                onBlur={handleBlur}
                                                                value={num.number}
                                                                placeholder="Not provided"
                                                                errors={errors}
                                                                touched={touched}
                                                                onChange={handleChange}
                                                                disabled={isSubmitting || num.typeDefaultSource !== "number_inventory"}
                                                                // disabled={isSubmitting || modalProps.isDisabled}
                                                            />
                                                        )}
                                                    </>
                                                    )}
                                                </fieldset>
                                            </div>
                                        </div>
                                        <div className="modal-footer">
                                            <button onClick={this.closeModal} className="btn" type="button">
                                                Cancel
                                            </button>
                                            <button disabled={isSubmitting} type="submit" className="btn btn-primary">
                                                Save
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        )}
                    />}
                </div>
                <div className="modal-backdrop show" tabIndex="1"/>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    const modalProps = getModalData(state).modalProps;
    const accountId = getAccountDetails(state).accountDetails.accountInfo.id;
    return {
        accountId,
        modalProps,
    };
};

const mapDispatchToProps = {
    hideModal,
    updateServiceLineDeviceAttributes,
    getServicesAndEquipment,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditEquipmentModal);