import React, {Component} from 'react';
import {isEmpty} from '../../../utils/helpers';

export class ServiceSummary extends Component {

    state = {
        isExpanded: null,
    }

    showPackageFeatures = () => {
        this.setState({isExpanded: !this.state.isExpanded});
    }

    render() {

        const {isExpanded} = this.state;

        const {service} = this.props;

        if (isEmpty(service)) {
            return null;
        }

        const recurringFeaturePackageFees = !isEmpty(service.activeFeaturePackages)?
            service.activeFeaturePackages.filter((x) => x.featureType !== 'ACTIVATION'): null;

        const recurringFeatureFees = !isEmpty(service.activeFeatures)?
            service.activeFeatures .filter((x) => x.featureType !== 'ACTIVATION'): null;

        const oneTimeFeaturePackageFees = !isEmpty(service.activeFeaturePackages)?
            service.activeFeaturePackages.filter((x) => x.featureType === 'ACTIVATION') : null;

        const oneTimeFeatureFees = !isEmpty(service.activeFeatures)?
            service.activeFeatures .filter((x) => x.featureType === 'ACTIVATION'): null;

        return (
            <div className="services-summary mt-0">
                <div className="services-summary-body">
                    <div className="plan-wrapper">
                        <div className="serviceline-wrapper">

                            {!isEmpty(recurringFeaturePackageFees) || !isEmpty(recurringFeatureFees) ? (
                                <div className="plan-section">

                                    <h5>
                                        Recurring
                                        <button
                                            type="button"
                                            className="btn btn-xs btn-secondary"
                                            onClick={this.showPackageFeatures}
                                        >
                                            <i className="fa fa-info" />
                                        </button>
                                    </h5>

                                    {!isEmpty(recurringFeaturePackageFees) && recurringFeaturePackageFees.map((feature, index) => (
                                    <div className="package-wrapper" key={index}>
                                        <div className="package-option">
                                            <span className="po-title">
                                                {feature.description &&
                                                `${feature.description.replace(/\s<br><(\w+)\b[^<>]*>[\s\S]*?<\/\1>/g, '')}:`}{' '}
                                                {feature.featurePackageOptions.map((featureAndPackageOptions) => {
                                                    return featureAndPackageOptions.description
                                                })}
                                            </span>
                                            <span className="po-price">
                                                ${feature.featurePackageOptions[0].recurringMonthlyPrice
                                                    .toFixed(2)
                                                    .replace(/\d(?=(\d{3})+\.)/g, '$&,')}/mo
                                            </span>

                                            {isExpanded &&
                                            <div className="container-list-bubble container-list-bubble-features">
                                                {feature.featurePackageOptions.map((options) => options.features.map((f) =>
                                                <dl>
                                                    <dt>{f.featureDescription}</dt>
                                                    <dd>{f.qty} &times; ${f.recurringMonthlyPrice}/mo</dd>
                                                </dl>
                                                ))}
                                            </div>
                                            }

                                        </div>
                                    </div>
                                    ))}

                                    {!isEmpty(recurringFeatureFees) && recurringFeatureFees.map((feature, index) => (
                                    <div className="package-wrapper" key={index}>
                                        <div className="package-option">
                                            <span className="po-title">
                                                {feature.featureDescription && feature.featureDescription}
                                            </span>
                                            <span className="po-price">
                                                {feature.qty > 1
                                                    ? <>Qty: {feature.qty}<span className="text-muted"> | </span></>
                                                    : ""}
                                                ${feature.recurringMonthlyPrice
                                                    .toFixed(2)
                                                    .replace(/\d(?=(\d{3})+\.)/g, '$&,')}/mo
                                            </span>
                                        </div>
                                    </div>
                                    ))}

                                </div>
                            ): null}

                            {!isEmpty(oneTimeFeaturePackageFees) || !isEmpty(oneTimeFeatureFees) ? (
                            <div className="plan-section">

                                <h5>Onetime</h5>

                                {!isEmpty(oneTimeFeaturePackageFees) && oneTimeFeaturePackageFees .map((feature, index) => (
                                <div className="package-wrapper" key={index}>
                                    <div className="package-option package-option-mandatory">
                                        <span className="po-title">
                                            {feature.description && `${feature.description}:`}{' '}
                                            {feature.note}
                                        </span>
                                        <span className="po-price">
                                            ${feature.featurePackageOptions[0].oneTimePrice
                                                .toFixed(2)
                                                .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                                        </span>
                                    </div>
                                </div>
                                ))}

                                {!isEmpty(oneTimeFeatureFees) && oneTimeFeatureFees.map((feature, index) => (
                                <div className="package-wrapper" key={index}>
                                    <div className="package-option package-option-mandatory">
                                        <span className="po-title">
                                            {feature.featureDescription && feature.featureDescription}
                                        </span>
                                        <span className="po-price">
                                            {feature.qty > 1
                                                ? <>Qty: {feature.qty}<span className="text-muted"> | </span></>
                                                : ""}
                                            ${feature.oneTimePrice
                                                .toFixed(2)
                                                .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                                        </span>
                                    </div>
                                </div>
                                ))}

                            </div>
                            ) : null }

                            {!isEmpty(service.serviceDevices) &&
                            <div className="plan-section">

                                <h5>Equipment</h5>

                                {service.serviceDevices.map((device, index) =>
                                <div className="package-option" key={index}>
                                    <span className="po-title">{device.deviceItemDescription ? device.deviceItemDescription : null}</span>

                                    <div className="container-list-bubble container-list-bubble-equipment">
                                        {device.apiServiceDeviceNums && device.apiServiceDeviceNums.map((num, index) =>
                                        <dl key={index}>
                                            <dt>{num.type}</dt>
                                            <dd>{num.number}</dd>
                                        </dl>
                                        )}
                                    </div>
                                </div>
                                )}

                            </div>
                            }

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ServiceSummary;
