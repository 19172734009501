import axiosInstance from '../utils/axios-instance';
import {actionCreator, createRequestTypes} from '../utils/helpers';

export const SET_TASKS_LIST = createRequestTypes('SET_TASKS_LIST');
export const TASKS_LIST_ASSIGN_TASK = createRequestTypes('TASKS_LIST_ASSIGN_TASK');
export const TASKS_LIST_UNASSIGN_TASK = createRequestTypes('TASKS_LIST_UNASSIGN_TASK');

export const getTasksByUsername = (username) => async (dispatch) => {
	dispatch(actionCreator.request(SET_TASKS_LIST));
	try {
		const response = await axiosInstance.get(`/order/usertasks?username=${username}`);
		dispatch(actionCreator.success(SET_TASKS_LIST, response.data.userTasks));
	} catch (err) {
		let mockError = {
			message: 'Not found'
		};
		dispatch(actionCreator.failure(SET_TASKS_LIST, mockError));
	}
};

export const getTasksByOrderId = (orderId) => async (dispatch) => {
	dispatch(actionCreator.request(SET_TASKS_LIST));
	try {
		const response = await axiosInstance.get(`/order/${orderId}/tasks`);
		dispatch(actionCreator.success(SET_TASKS_LIST, response.data));
	} catch (err) {
		let mockError = {
			message: 'Not found'
		};
		dispatch(actionCreator.failure(SET_TASKS_LIST, mockError));
	}
};

export const getTasksByServiceOrder = (serviceOrderId) => async (dispatch) => {
	dispatch(actionCreator.request(SET_TASKS_LIST));
	try {
		const response = await axiosInstance.get(`/order/serviceorder/${serviceOrderId}/tasks`);
		dispatch(actionCreator.success(SET_TASKS_LIST, response.data));
		return response.data
	} catch (err) {
		let mockError = {
			message: 'Not found'
		};
		dispatch(actionCreator.failure(SET_TASKS_LIST, mockError));
		return err;
	}
};

export const getTasksByTroubleTicketId = (troubleTicketId) => async (dispatch) => {
	dispatch(actionCreator.request(SET_TASKS_LIST));
	try {
		const response = await axiosInstance.get(`/order/troubleticket/${troubleTicketId}/tasks`);
		dispatch(actionCreator.success(SET_TASKS_LIST, response.data));
		return response.data
	} catch (err) {
		let mockError = {
			message: 'Not found'
		};
		dispatch(actionCreator.failure(SET_TASKS_LIST, mockError));
		return err;
	}
};


export const emptyTasksList = () => (dispatch) => {
	dispatch(actionCreator.empty(SET_TASKS_LIST))
}

export const assignTask = (data, serviceOrderId, apiCallTypeBy, reloadTasks) => async (dispatch) => {
	dispatch(actionCreator.request(TASKS_LIST_ASSIGN_TASK));
	try {
		const response = await axiosInstance.post(`/order/usertask/assign`, data);
		dispatch(actionCreator.success(TASKS_LIST_ASSIGN_TASK, response.data.users));

		if (reloadTasks !== false) {
			if (apiCallTypeBy === 'serviceOrderId') {dispatch(getTasksByServiceOrder(serviceOrderId))}
			else if(apiCallTypeBy === 'orderId') {dispatch(getTasksByOrderId(serviceOrderId))}
			else if(apiCallTypeBy === 'username') {dispatch(getTasksByUsername(serviceOrderId))}
		}
	} catch (err) {
		dispatch(actionCreator.failure(TASKS_LIST_ASSIGN_TASK));
	}
};

export const unassignTask = (data, serviceOrderId, apiCallTypeBy, reloadTasks) => async (dispatch) => {
	dispatch(actionCreator.request(TASKS_LIST_UNASSIGN_TASK));
	try {
		const response = await axiosInstance.post(`/order/usertask/unassign`, data);
		dispatch(actionCreator.success(TASKS_LIST_UNASSIGN_TASK, response.data.users));

		if (reloadTasks !== false) {
			if (apiCallTypeBy === 'serviceOrderId') {dispatch(getTasksByServiceOrder(serviceOrderId))}
			else if(apiCallTypeBy === 'orderId') {dispatch(getTasksByOrderId(serviceOrderId))}
			else if(apiCallTypeBy === 'username') {dispatch(getTasksByUsername(serviceOrderId))}
		}
	} catch (err) {
		dispatch(actionCreator.failure(TASKS_LIST_UNASSIGN_TASK));
	}
};


