import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { hideModal } from '../../../actions/modal.actions';
import { createAccountContact } from '../../../actions/accountDetailsActions/security.actions';
import { getAccountDetails, getUser, getModalData } from '../../../selectors';
import { Formik } from 'formik';
import * as Yup from 'yup';

import FormikInput from '../../../components/Common/Inputs/FormikInput';
import isEmpty from "../../../utils/helpers";
import axiosInstance from "../../../utils/axios-instance";

const ContactsSchema = Yup.object().shape({
	firstName: Yup.string().required('Required'),
	lastName: Yup.string().required('Required')
});

class AddContactUserModal extends Component {
	state = {
		showAlertMessage: false,
		alertMessage: ''
	};

	componentDidMount() {}

	componentWillUnmount() {}

	onHandleChangeApplyOn = (e) => {
		this.setState({
			applyOn: e.target.value
		});
	};

	formSubmit = (values, actions) => {
		const { accountId, createAccountContact } = this.props;

		let valuesForSend = { ...values };
		valuesForSend['emails'] = valuesForSend['emails'].filter((x) => x.email !== '');
		valuesForSend['numbers'] = valuesForSend['numbers'].filter((x) => x.number !== '');

		createAccountContact(accountId, valuesForSend).then((response) => {
			if (response.success) {
				this.props.hideModal();
			} else {
				actions.setSubmitting(false);
			}
		});
	};

	checkEmail = (status, setStatus, field, value) => {
		var resetStatus = !isEmpty(status)? status: {};

		delete resetStatus[field];

		setStatus(resetStatus);

		axiosInstance.get('/verification/email/valid', {params: {email: value}}).catch((error) => {
			const newStatus = {
				[field]: error.response.data.error.message,
				...resetStatus
			};
			setStatus(newStatus);
		});

	}

	render() {
		const { showAlertMessage, alertMessage } = this.state;
		const { hideModal } = this.props;

		return (
			<Fragment>
				<div className="modal" style={{ display: 'block' }} tabIndex="-1" role="dialog">
					<Formik
						initialValues={{
							firstName: '',
							lastName: '',
							isAuthorized: true,
							numbers: [
								{
									numberType: 'MOBILE',
									number: '',
									verified: false
								},
								{
									numberType: 'HOME',
									number: '',
									verified: false
								},

								{
									numberType: 'WORK',
									number: '',
									verified: false
								}
							],
							emails: [
								{
									type: 'PERSONAL',
									email: '',
									validated: false
								},
								{
									type: 'BUSINESS',
									email: '',
									validated: false
								}
							]
						}}
						validationSchema={ContactsSchema}
						onSubmit={this.formSubmit}
						render={({ handleChange, handleSubmit, handleBlur, values, errors, touched, isSubmitting, status, setStatus }) => (
							<form onSubmit={handleSubmit}>
								<div className="modal-dialog">
									<div className="modal-content">
										<div className="modal-header">
											<h5 className="modal-title">ADD USER</h5>
											<button onClick={hideModal} type="button" className="close">
												<span aria-hidden="true">&times;</span>
											</button>
										</div>
										<div className="modal-body form-horizontal">
											{showAlertMessage === true && (
												<div className="alert alert-inline alert-danger alert-dismissible">
													<p className="mb-0">{alertMessage}</p>
												</div>
											)}

											<h5 className="h-with-check">
												<span className="h-witch-check-title">User Details</span>
												<span className="h-check">
													{values.isAuthorized === true ? (
														<span>Authorized</span>
													) : (
														<span>Unauthorized</span>
													)}

													<div className="form-check checkbox-slider checkbox-slider--b-flat">
														<label>
															<input
																name="isAuthorized"
																type="checkbox"
																onBlur={handleBlur}
																onChange={handleChange}
																checked={values.isAuthorized}
															/>
															<span>&nbsp;</span>
														</label>
													</div>
												</span>
											</h5>

											<FormikInput
												wrapperClass="form-group"
												label="First Name"
												type="text"
												name={`firstName`}
												onChange={handleChange}
												onBlur={handleBlur}
												value={values.firstName}
												placeholder="First Name"
												errors={errors}
												touched={touched}
											/>

											<FormikInput
												wrapperClass="form-group"
												label="Last Name"
												type="text"
												name={`lastName`}
												onChange={handleChange}
												onBlur={handleBlur}
												value={values.lastName}
												placeholder="Last Name"
												errors={errors}
												touched={touched}
											/>

											<h5>Contact Numbers</h5>

											<FormikInput
												wrapperClass="form-group"
												label="Mobile"
												type="text"
												name={`numbers[0].number`}
												onChange={handleChange}
												onBlur={handleBlur}
												value={values.numbers[0].number}
												placeholder="Mobile Number (optional)"
												errors={errors}
												touched={touched}
											/>

											<FormikInput
												wrapperClass="form-group"
												label="Home"
												type="text"
												name={`numbers[1].number`}
												onChange={handleChange}
												onBlur={handleBlur}
												value={values.numbers[1].number}
												placeholder="Home Number (optional)"
												errors={errors}
												touched={touched}
											/>

											<FormikInput
												wrapperClass="form-group"
												label="Work"
												type="text"
												name={`numbers[2].number`}
												onChange={handleChange}
												onBlur={handleBlur}
												value={values.numbers[2].number}
												placeholder="Work Number (optional)"
												errors={errors}
												touched={touched}
											/>

											<h5>Emails</h5>

											<FormikInput
												wrapperClass="form-group"
												label="Personal"
												type="text"
												name={`emails[0].email`}
												onChange={handleChange}
												onBlur={(e) => {
													handleBlur(e);
													this.checkEmail(status, setStatus,'emails[0].email', e.target.value);
												}}
												value={values.emails[0].email}
												placeholder="Personal Email (optional)"
												errors={errors}
												touched={touched}
												status={status}
											/>

											<FormikInput
												wrapperClass="form-group"
												label="Business"
												type="text"
												name={`emails[1].email`}
												onChange={handleChange}
												onBlur={(e) => {
													handleBlur(e);
													this.checkEmail(status, setStatus,'emails[1].email', e.target.value);
												}}
												value={values.emails[1].email}
												placeholder="Business Email (optional)"
												errors={errors}
												touched={touched}
												status={status}
											/>
										</div>
										<div className="modal-footer">
											<button onClick={hideModal} className="btn" type="button">
												Cancel
											</button>
											<button disabled={isSubmitting} type="submit" className="btn btn-primary">
												ADD USER
											</button>
										</div>
									</div>
								</div>
							</form>
						)}
					/>
				</div>
				<div className="modal-backdrop show" tabIndex="1" />
			</Fragment>
		);
	}
}

const mapStateToProps = (state) => {
	const modalProps = getModalData(state).modalProps,
		accountId = getAccountDetails(state).accountDetails.accountInfo.id;

	return {
		modalProps,
		accountId
	};
};

const mapDispatchToProps = {
	hideModal,
	createAccountContact
};

export default connect(mapStateToProps, mapDispatchToProps)(AddContactUserModal);
