import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory, {
    PaginationListStandalone,
    SizePerPageDropdownStandalone,
    PaginationProvider,
    PaginationTotalStandalone
} from 'react-bootstrap-table2-paginator';
import moment from 'moment';
import { connect } from 'react-redux';
import {
    getStatusHistory,
    emptyStatusHistory,
    getTaxExemptions, emptyTaxExemptions
} from '../../../../../../actions/accountDetailsActions/billing.actions';
import { showModal } from '../../../../../../actions/modal.actions';
import Loader from '../../../../../../components/Loader';
import { isEmpty } from '../../../../../../utils/helpers';
import {createLoadingSelector, getAccountDetails} from "../../../../../../selectors";

const { SearchBar } = Search;
class StatusHistory extends Component {
    state = {};

    componentDidMount() {
        const { accountInfo } = this.props;
        this.props.getStatusHistory(accountInfo.id);
    }

    componentWillUnmount() {
        this.props.emptyStatusHistory();
    }

    statusFormater = (cell, row) => {
        if (row) {
            return (
                <Fragment>
                    {cell}
                </Fragment>
            );
        }
    };

    reasonFormater = (cell, row) => {

        if (row) {
            return (
                <Fragment>
                    {cell}
                </Fragment>
            );
        }
    };

    dateFormater = (cell, row) => {
        if (row) {
            return (
                <Fragment>
                    {moment(cell).format('MM/DD/YYYY')}
                </Fragment>
            );
        }
    };

    createdByFormater = (cell, row) => {


        if (row) {
            return (
                <Fragment>
                    {cell}
                </Fragment>
            );
        }
    };

    render() {
        const { statusHistoryData, statusHistoryLoader } = this.props;

        if (isEmpty(statusHistoryData) && statusHistoryLoader) {
            return <Loader />;
        }

        const options = {
            paginationSize: 4,
            custom: true,
            totalSize: statusHistoryData.length,
            pageStartIndex: 1,
            // alwaysShowAllBtns: true, // Always show next and previous button
            // withFirstAndLast: false, // Hide the going to First and Last page button
            // hideSizePerPage: true, // Hide the sizePerPage dropdown always
            hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
            firstPageText: 'First',
            prePageText: 'Previous',
            nextPageText: 'Next',
            lastPageText: 'Last',
            showTotal: true,
            paginationTotalRenderer: this.customTotal,
            sizePerPageList: [
                {
                    text: '10',
                    value: 10
                },
                {
                    text: '25',
                    value: 25
                },
                {
                    text: '50',
                    value: 50
                },
                {
                    text: '100',
                    value: 100
                },
                {
                    text: 'All',
                    value: statusHistoryData.length
                }
            ] // A numeric array is also available. the purpose of above example is custom the text
        };

        const columns = [
            {
                dataField: 'datetime',
                text: 'Date',
                sort: true,
                formatter: this.dateFormater
            },
            {
                dataField: 'type',
                text: 'Type',
                sort: true,
                formatter: this.reasonFormater
            },
            {
                dataField: 'description',
                text: 'Description',
                sort: true,
                formatter: this.statusFormater
            }
        ];

        const defaultSorted = [
            {
                dataField: 'datetime',
                order: 'desc'
            }
        ];

        return (
            <Fragment>
                <div className="cmv-container-table">
                    <PaginationProvider pagination={paginationFactory(options)}>
                        {({ paginationProps, paginationTableProps }) => (
                            <Fragment>
                                <ToolkitProvider
                                    bootstrap4
                                    keyField="id"
                                    data={statusHistoryData}
                                    columns={columns}
                                    defaultSorted={defaultSorted}
                                    search
                                >
                                    {(props) => (
                                        <Fragment>
                                            <div className="row">
                                                <div className="col-sm-12 col-md-6">
                                                    <label>
                                                        Show <SizePerPageDropdownStandalone {...paginationProps} />{' '}
                                                        entries
                                                    </label>
                                                </div>
                                                <div className="col-sm-12 col-md-6" style={{ textAlign: 'right' }}>
                                                    <label>
                                                        Search:{' '}
                                                        <SearchBar
                                                            className="form-control form-control-sm"
                                                            {...props.searchProps}
                                                        />
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <BootstrapTable
                                                    wrapperClasses="table-responsive react-table-layout"
                                                    classes="table table-striped cmv-table"
                                                    bordered={false}
                                                    noDataIndication="No data found"
                                                    {...paginationTableProps}
                                                    {...props.baseProps}
                                                />
                                            </div>
                                            <div className="row" style={{ marginTop: '10px' }}>
                                                <div className="col-sm-12 col-md-5">
                                                    <PaginationTotalStandalone {...paginationProps} />
                                                </div>
                                                <div className="col-sm-12 col-md-7">
                                                    <PaginationListStandalone {...paginationProps} />
                                                </div>
                                            </div>
                                        </Fragment>
                                    )}
                                </ToolkitProvider>
                            </Fragment>
                        )}
                    </PaginationProvider>
                </div>
            </Fragment>
        );
    }
}

StatusHistory.propTypes = {
    accountInfo: PropTypes.object.isRequired
};

const loader = createLoadingSelector([ 'SET_STATUS_HISTORY' ]);

const mapStateToProps = (state) => {
    const statusHistoryData = getAccountDetails(state).billing.statusHistories,
        statusHistoryLoader = loader(state);

    return {
        statusHistoryData,
        statusHistoryLoader
    };
};

const mapDispatchToProps = {
    getStatusHistory,
    showModal,
    emptyStatusHistory
};

export default connect(mapStateToProps, mapDispatchToProps)(StatusHistory);
