import React, { Component } from 'react';
import ReactHtmlParser from "react-html-parser";

class Package extends Component {
    state = {
		isDescriptionToggled: false
	};

	toggleDescription = (e) => {
		e.stopPropagation();
		this.setState((prevState) => ({
			isDescriptionToggled: !prevState.isDescriptionToggled
		}));
    };
    
	render() {
        const {isDescriptionToggled} = this.state;
		const { features, lineIndex, handleMandatoryPackages, packageOption, featurePackage } = this.props;
		
		return (
			<div
				className={
					features.findIndex(
						(x) => x.featurePackageOptionId === packageOption.featurePackageOptionId
					) !== -1 ? (
						'package-option package-option-exclusive package-option-selectable package-option-selected'
					) : (
						'package-option package-option-exclusive package-option-selectable'
					)
				}
				data-opt-id="pl001-mp001-op001"
				onClick={() =>
					handleMandatoryPackages(packageOption.featurePackageOptionId, lineIndex, {
						featurePackageId: featurePackage.featurePackageId,
						featurePackageDescription: featurePackage.description,
						featurePackageNote: featurePackage.note,
						action: 'ENABLE',
						...packageOption
					})}
			>
				<span className="po-title">{packageOption.description}</span>
				<span className="package-toolbar">
					<span className="po-price">
						${packageOption.recurringMonthlyPrice.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}/mo
					</span>
					<button onClick={this.toggleDescription} type="button" className="btn btn-xs btn-desc-toggler">
						<i className="fas fa-info-circle" />
					</button>
				</span>
                <div
						className={isDescriptionToggled ? 'po-description collapse show' : 'po-description collapse'}
						id="pl001-ifeat002-op001-desc"
					>
					{ReactHtmlParser(packageOption.note)}
					</div>
			</div>
		);
	}
}

export default Package;
