import React, {Component} from 'react'
import Appointment from "./Appointment";

class List extends Component {

    render() {

        const {
            appointments,
        } = this.props;

        return (
            <div className="cmv-container-appointment-list-search-results">

                <div className="appointment-list">
                    {appointments.map(appointment =>
                        <Appointment
                            key={appointment.id}
                            appointment={appointment}
                            {...this.props}
                        />
                    )}
                </div>

            </div>
        )
    }
}

export default List;
