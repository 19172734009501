import React from 'react';
import _ from 'lodash';

export const Step = (props) => {

    const {
        handlePrevious,
        handleNext,
        component: StepComponent,
    } = props;

    const componentProps = { ...props };
    _.unset(componentProps, ['handlePrevious', 'handleNext', 'component']);

    return (
        <div className="tab-content wizard-content">

            <StepComponent
                {...componentProps}
                previous={handlePrevious}
                next={handleNext}
            />

        </div>
    )
}

export default Step;
