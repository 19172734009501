import React, {Component} from "react";
import {showModal} from "../../../actions/modal.actions";
import {connect} from 'react-redux';
import { toastr } from 'react-redux-toastr';
import Loader from "../../../components/Loader";
import {Formik} from "formik";
import TextInput from "../../../components/UI/TextInput";
import axiosInstance from "../../../utils/axios-instance";
import { getUserPermission } from "../../../selectors";
import Select from 'react-select'
import { isEmpty } from "lodash";
import { formSelectStyles } from "../../../utils/SelectStyles";
class NotificationProperties extends Component {
    state={
        isLoading:false,
        dataLoaded:false,
        data:{},
        saveLoading:false,
        notificationType:{},
        notificationProperties:[]
    }

    componentDidMount() {
        document.title = 'Notification Properties - camvio.cloud';
        this.props.changeNavBarType('default', 'Notification Properties');
        const d={};
    }

    onConfigurationSaveBtnClick = (value,updatedProps) => {

        this.props.showModal('GENERIC_MODAL', {
            title: "Save Configuration",
            text: "Are you sure you want to make these changes?",
            size: 'lg',
            cancelText: "Cancel",
            okText: "Save",
            onOk: () => this.saveNotificationConfiguration(value),
            component: () => (
                <table className="table">
                    <thead>
                        <th>name</th>
                        <th>old value</th>
                        <th>new value</th>
                    </thead>
                    <tbody>
                    {updatedProps.map(prop =>
                        <tr>
                            <td>{prop.key}</td>
                            <td>{prop.oldVal}</td>
                            <td>{prop.newVal}</td>
                        </tr>
                    )}
                    </tbody>
                </table>
            )
        });
    }

    saveNotificationConfiguration = (values) => {
        let flag=false;
        this.state.notificationProperties.map((data)=>{
            if(data.value!==values[data.id]){
                this.setState({saveLoading:true})
                const obj={
                     "id": data.id,
                     "key": data.key,
                     "notificationType": data.notificationType,
                     "value": values[data.id]
                }
                axiosInstance.patch(`/notifications/properties`, obj).then((res)=>{
                    if(res.status===200){
                        const d={};
                        !flag&&toastr.success('Your Data is Updated', { timeOut: 3000, position: 'top-center' });
                        flag=true;
                        this.setState({isLoading:true});
                        axiosInstance.post(`/notifications/properties`,this.state.notificationType.value).then((res)=>{
                            this.setState({notificationProperties:res.data.notificationHandlerProperties,isLoading:false})
                            res.data.notificationHandlerProperties.length!==0&&res.data.notificationHandlerProperties.map((respo)=>d[respo.id]=respo.value)
                            d&&this.setState({data:d});
                        })
                    }
                    this.setState({saveLoading:false})
                });
            }
        })
    }

    onSetCurrentLocationBtnClick = (formProps) => {
        const d={};
        if(!isEmpty(formProps)){
            this.setState({isLoading:true})
            axiosInstance.post(`/notifications/properties`, `${formProps.value}`).then((res)=>{
                res.data.notificationHandlerProperties.length!==0&&this.setState({dataLoaded:true});
                this.setState({notificationProperties:res.data.notificationHandlerProperties,isLoading:false})
                res.data.notificationHandlerProperties.length!==0&&res.data.notificationHandlerProperties.map((respo)=>d[respo.id]=respo.value)
                d&&this.setState({data:d});
            })
        }else{
            toastr.error('Please select one option', { position: 'top-center' });
        }
    }

    render() {
        const {
            showConfig,editConfig,adminShowConfig
        } = this.props;
        return (
            <div className="cmv-container cmv-container-dashboard cmv-container-client-selected">
                {showConfig&&adminShowConfig?<Formik
                    initialValues={this.state.data}
                    enableReinitialize={true}
                    onSubmit={this.handleSubmit}
                    render={(formProps) => {
                        const {handleChange, handleSubmit, handleBlur, values, errors, touched, isSubmitting, setFieldValue, validateForm} = formProps;

                        const notificationProperties = this.state.notificationProperties;
                        const updatedProps = [];
                        notificationProperties &&
                        notificationProperties.forEach(origProp => {
                            const currProp = values[origProp.id];
                            if (currProp && currProp !== origProp.value) {
                                updatedProps.push({ key: origProp.key, oldVal: origProp.value, newVal: currProp });
                            }
                        });
                        return (
                    <>
                        <form onSubmit={handleSubmit}>
                            <div className="form-vertical">
                                    <div className='card-vertical card-plane'>
                                        <div className="form-group">
                                    <label htmlFor={"notificationType"}>Notification Type</label>
                                    <Select
                                        name={"notificationType"}
                                        options={[
                                            {
                                                label: "Email",
                                                value: 'EMAIL'
                                            },
                                            {
                                                label: "Slack",
                                                value: 'SLACK'
                                            },
                                            {
                                                label: "TwilioMsg",
                                                value: 'TWILIOMSG'
                                            }

                                    ]}
                                        isMulti={false}
                                        value={values.notificationType}
                                        isDisabled={false}
                                        onChange={(event)=>{this.setState({notificationType: event});this.onSetCurrentLocationBtnClick(event)}}
                                        placeholder={"Select one..."}
                                        styles={formSelectStyles}
                                    />
                                    {/* <div style={{textAlign: 'right',marginTop:'10px'}}>
                                        <button
                                            onClick={() => this.onSetCurrentLocationBtnClick(this.state.notificationType)}
                                            type="button"
                                            className="btn btn-primary">
                                            {this.state.isLoading &&
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" style={{marginRight: '5px'}}/>
                                            }
                                            Set As Current
                                        </button>
                                    </div> */}
                                </div>
                                        {this.state.isLoading ? <Loader />:this.state.notificationProperties.length>0?<div className='card-body'>
                                            <div className='plan-header plan-header-centered'>
                                                <span className="plan-title"><i className="fas fa-fw fa-cogs"/>Notification Properties</span>
                                            </div>
                                            <div className='plan-body'>
                                                <div className="form-group service-wrapper">
                                                    {this.state.notificationProperties && this.state.notificationProperties.map((prop, idx) => {
                                                        
                                                        return (
                                                            <div style={{display:'flex'}}>
                                                            
                                                                <TextInput
                                                                    label={prop.key}
                                                                    type="text"
                                                                    name={prop.id}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    value={values[prop.id]||''}
                                                                    disabled={!editConfig}
                                                                    autocomplete={false}
                                                                    
                                                                />
                                                             </div>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                            <div style={{textAlign: 'center'}}>
                                                <h3 style={{color:'lightgreen'}}>{this.state.conformation}</h3>
                                            </div>
                                            {editConfig&&<div style={{textAlign: 'right'}}>
                                                <button
                                                    onClick={() => {this.onConfigurationSaveBtnClick(values,updatedProps)}}
                                                    disabled={(this.state.editConfig&&this.state.saveLoading)||updatedProps.length==0}
                                                    type="button"
                                                    className="btn btn-primary">
                                                    {this.state.saveLoading&&
                                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" style={{marginRight: '5px'}}/>}
                                                    Save
                                                </button>
                                            </div>}
                                        </div>:
                                        <div className='card-vertical card-plane' style={{color:'#212529',textAlign:'center',marginTop:'30px'}}>
                                        <h2 style={{fontSize:'1.2em',color: '#343a40',fontWeight:'500'}}>No Data Found</h2>
                                    </div>

                                        }
                                    </div>
                                    
                            </div>
                        </form>
                    </>
                )}}/>:<h2 style={{textAlign:'center'}}>You don't have Access for this page</h2>}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const showConfig=getUserPermission(state, 'WEB_MENU', 'SHOW_NOTIFICATIONS_HANDLER_PROPERTIES'),
        editConfig=getUserPermission(state, 'ADMN', 'ADMN_EDIT_NOTIFICATIONS_HANDLER_PROPERTIES'),
        adminShowConfig=getUserPermission(state, 'ADMN', 'ADMN_VIEW_NOTIFICATIONS_HANDLER_PROPERTIES')


    return {
        showConfig,
        editConfig,
        adminShowConfig
    };
};

const mapDispatchToProps = {
    showModal
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationProperties);