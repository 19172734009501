import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {hideModal} from '../../../actions/modal.actions';
import FormSelect from "../../../components/UI/FormSelect";
import TextInput from "../../../components/UI/TextInput";
import {getProducts, getModalData, getDomain, getAdmin} from '../../../selectors';
import { getServiceModels } from "../../../actions/domain.actions";
import {getProviders, getSystemLocations, getGeneralLedgers, getGeneralLedgerSubAccounts} from "../../../actions/admin.actions";
import {addFeature, updateFeature, getFeature, clearFeature, getFeatureTypes, getRateCodes} from "../../../actions/products.action";
import {FieldArray, Formik} from 'formik';
import isEmpty from "../../../utils/helpers";
import Loader from "../../../components/Loader";
import * as Yup from "yup";
import {toastr} from "react-redux-toastr";

const asOptions = (source, mapper) => {
    if (!Array.isArray(source)) {
        return [];
    }

    return source.map(mapper);
};

const systemLocationAsOptions = (systemLocations) => asOptions(systemLocations, systemLocation => {
    return {
        label: systemLocation.description,
        value: systemLocation.id,
    }
});

const generalLedgersAsOptions = (generalLedgers) => asOptions(generalLedgers, generalLedger => {
    return {
        label: generalLedger.accountNumber,
        value: generalLedger.id,
    }
});

const generalLedgersSubAccountsAsOptions = (generalLedgerSubAccounts) => asOptions(generalLedgerSubAccounts, generalLedgerSubAccount => {
    return {
        label: generalLedgerSubAccount.subAccountNumber,
        value: generalLedgerSubAccount.id,
    }
});

const matchCurrentSelectedGeneralLedger = (generalLedgerSubAccounts, selectedId) => {
    if ((generalLedgerSubAccounts && generalLedgerSubAccounts != undefined)) {
        return generalLedgerSubAccounts.filter(generalLedgerSubAccount => generalLedgerSubAccount.generalLedgerId == selectedId);
    }
    return generalLedgerSubAccounts;
}

const removeSelectedLocations = (locations, ignoreIndex, selectedGeneralLedgers) => {
    let currentLocations = [];
    if(locations && locations != undefined && !isEmpty(selectedGeneralLedgers)) {
        selectedGeneralLedgers.map((selectedLocation, index) => {
            if(ignoreIndex != index) {
                currentLocations.push(selectedLocation.glLocationId);
            }
        });
        return locations.filter(location => !currentLocations.includes(location.id));
    }
    return locations;
}


const featureTypesAsOptions = (featureTypes) => asOptions(featureTypes, featureType => {
    return {
        label: featureType.description,
        value: featureType.id,
        class: featureType.classType
    }
});

const planTypesAsOptions = (planTypes) => asOptions(planTypes, planType => {
    return {
        label: planType,
        value: planType
    };
});

const serviceModelsAsOptions = (serviceModels) => asOptions(serviceModels, serviceModel => {
    return {
        label: serviceModel.description,
        value: serviceModel.id
    };
});

const rateCodesAsOptions = (rateCodes) => asOptions(rateCodes, rateCode => {
    return {
        label: rateCode.description,
        value: rateCode.id,
    }
});

const providersAsOptions = (providers) => asOptions(providers, provider => {
    return {
        label: provider.description,
        value: provider.id,
    }
});

const valuesAsOptions = (values) => asOptions(values, value => {
    return {
        label: value,
        value: value
    }
});


class AddFeatureModal extends Component {

    state = {
        alertMessage: '',
        isFeatureLoading: true,
        rateCodeRequired: false,
        generalLedgerDescription: '',
        generalLedgerSelected: false,
        selectedGeneralLedgers: [],
        selectedLocations: [],
        generalLedgersToDelete: []
    };

    componentDidMount() {
        this.props.clearFeature();
        this.props.getFeatureTypes();
        this.props.getGeneralLedgers()
        this.props.getGeneralLedgerSubAccounts();
        this.props.getServiceModels();
        this.props.getRateCodes();
        this.props.getProviders();
        this.props.getSystemLocations();

        const {modalProps} = this.props;
        let selectedGeneralLedgers = [{
            glLocationId: '',
            glSubId: '',
            glId: '',
            glDescription: ''
        }];
        if(modalProps.value) {
            this.props.getFeature(modalProps.value).then((response) => {
                let rateCodeRequired = false;
                if(response.featureClass != undefined && response.featureClass == "METERED"){
                    rateCodeRequired = true;
                }
                if(response.selectedGeneralLedgers){
                    selectedGeneralLedgers = response.selectedGeneralLedgers;
                }
                this.setState({
                    isFeatureLoading: false,
                    rateCodeRequired: rateCodeRequired,
                    selectedGeneralLedgers: selectedGeneralLedgers
                })
            })
        }else{
            this.setState({
                isFeatureLoading: false,
                selectedGeneralLedgers: selectedGeneralLedgers
            })
        }

    }

    componentWillUnmount() {
        this.props.clearFeature();
    }

    addAnotherGeneralLedger = (selectedGeneralLedgers) => {
        selectedGeneralLedgers.push({
            glLocationId: '',
            glSubId: '',
            glId: '',
            glDescription: ''
        });
        this.setState({
            selectedGeneralLedgers: selectedGeneralLedgers
        });
    }

    setFeatureClass = (value, fieldName, setFieldValue) => {
        let rateCodeRequired = false;
        if(value == "METERED"){
            rateCodeRequired = true;
        }
        setFieldValue(fieldName, value);
        this.setState({
            rateCodeRequired: rateCodeRequired
        })
    }

    setGeneralLedgerValue = (e, value, setFieldValue, index) => {
        setFieldValue(`selectedGeneralLedgers.${index}.glId`, value);
        if(this.props.generalLedgers != undefined){
            this.setGeneralLedgerDescription(value, setFieldValue, index);
            setFieldValue(`selectedGeneralLedgers.${index}.glSubId`, "");
        }
        this.setState({
            generalLedgerSelected: true
        })
    }

    setGeneralLedgerLocationValue = (e, value, setFieldValue, index) => {
        setFieldValue(`selectedGeneralLedgers.${index}.glLocationId`, value);
        this.setState({
            generalLedgerSelected: true
        })
    }

    setGeneralLedgerDescription = (value, setFieldValue, index) => {
        this.props.generalLedgers.map(ledger => {
            if(ledger.id == value){
                setFieldValue(`selectedGeneralLedgers.${index}.glDescription`, ledger.description);
            }
        })
    }

    handleRemoveGeneralLedger = (formProps, remove, index, setFieldValue) => {
        let generalLedgersToDelete = this.state.generalLedgersToDelete;
        if(formProps.values.selectedGeneralLedgers != undefined){
            let values = {...formProps.values};
            if(values.selectedGeneralLedgers[index].glSubId != undefined) {
                generalLedgersToDelete.push(values.selectedGeneralLedgers[index].id);
                this.setState({
                    generalLedgersToDelete: generalLedgersToDelete
                })
            }
            values.selectedGeneralLedgers.splice(index, 1);
            if(values.selectedGeneralLedgers.length == 0){
                values.selectedGeneralLedgers =  [{
                    glLocationId: '',
                    glSubId: '',
                    glId: '',
                    glDescription: ''
                }];
                this.setState({
                    generalLedgerSelected: false
                })
            }
            setFieldValue('selectedGeneralLedgers', values.selectedGeneralLedgers);
        }
    }

    buildGeneralLedgerTitle(selectedGeneralLedger) {
        let glString = "";
        if(selectedGeneralLedger.glLocationId){
            if(this.props.systemLocations != undefined){
                this.props.systemLocations.map(location => {
                    if(location.id == selectedGeneralLedger.glLocationId){
                        glString += location.name;
                    }
                })
            }
        }
        if(selectedGeneralLedger.glId){
            if(this.props.generalLedgers != undefined){
                this.props.generalLedgers.map(generalLedger => {
                    if(generalLedger.id == selectedGeneralLedger.glId){
                        glString += (":" + generalLedger.accountNumber);
                    }
                })
            }
        }
        if(selectedGeneralLedger.glSubId){
            if(this.props.generalLedgerSubAccounts != undefined){
                this.props.generalLedgerSubAccounts.map(generalLedgerSubAccount => {
                    if(generalLedgerSubAccount.id == selectedGeneralLedger.glSubId){
                        glString += ( "." + generalLedgerSubAccount.subAccountNumber) ;
                    }
                })
            }
        }
        return glString;
    }


    featureSchema = () => {
        let validationSchema = {
            name: Yup.string().required('Required'),
            description: Yup.string().required('Required'),
            price: Yup.string().required('Required'),
            planType: Yup.string().required('Required').typeError('Required'),
            serviceModelId : Yup.number().required('Required').typeError('Required'),
            featureTypeId : Yup.number().required('Required').typeError('Required'),
            featureClass : Yup.string().required('Required').typeError('Required'),
            rateCodeId: this.state.rateCodeRequired ? Yup.string().required('Required').typeError("Required") : '',
            selectedGeneralLedgers: this.state.generalLedgerSelected ? Yup.array(Yup.object().shape({
                glLocationId: Yup.number().required('Required').typeError('Required'),
                glId: Yup.number().required('Required').typeError('Required'),
                glSubId: Yup.number().required('Required').typeError('Required')
            })).min(1): '',
        };

        return Yup.object().shape(validationSchema);
    }



    formSubmit = (values, actions) => {
        const submitData = {...values};

        this.setState({alertMessage: ''}, () => {
            const method = this.props.modalProps.type === 'create' ? this.props.addFeature : this.props.updateFeature;

            method(submitData).then((response) => {
                if (!response) {
                    return;
                }
                //If any failures happen on edit or create, show the error, only refresh if edit
                if (!response.success) {
                    toastr.error(response.data.error.message, {timeOut: 8000, position: 'top-center'});
                    if (this.props.modalProps.type != 'create') {
                        this.setState({
                            isFeatureLoading: true
                        })
                        this.getFeature(this.props.modalProps.value);
                    }
                    return response.data.error;
                } else {
                    this.props.hideModal();
                    this.props.modalProps.reloadFunction(true);
                    toastr.success(response.message, {timeOut: 4000, position: 'top-center'});
                }
            });
        });
    }

    scrollDropdownIntoView = (e) => {
        const elementClicked = e.target.nodeName;
        const boundingElement = e.currentTarget;
        const modalBody = document.getElementsByClassName('modal-body')[0];

        if (elementClicked !== 'LABEL') {

            setTimeout(function () {

                // Scroll down if the bottom is hidden...
                if (boundingElement.getBoundingClientRect().bottom > modalBody.getBoundingClientRect().bottom) {

                    // ... and the top won't end up hidden by scrolling down
                    if (boundingElement.getBoundingClientRect().height < modalBody.getBoundingClientRect().height) {

                        // Scroll down till bottom of element reaches bottom of screen
                        boundingElement.scrollIntoView({block: "end"});
                    }
                }
            }, 200);
        }
    }


    render() {
        const { alertMessage, isFeatureLoading } = this.state;
        const { modalProps, featureTypes, feature, generalLedgers, rateCodes, serviceModels, providers, systemLocations, generalLedgerSubAccounts} = this.props;
        const planTypes = ["POSTPAID", "PREPAID"];

        return (
            <Fragment>
                <div className="modal" style={{display: 'block'}} tabIndex="-1" role="dialog">
                    {isFeatureLoading &&
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">
                                    {modalProps.type === 'create' ? 'Add Feature' : 'Edit Feature'}
                                </h5>
                                <button onClick={this.props.hideModal} type="button" className="close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <Loader />
                            <div className="modal-footer">
                                <button onClick={this.props.hideModal} className="btn" type="button">
                                    Cancel
                                </button>
                                <button type="submit" className="btn btn-primary">
                                    {modalProps.type === 'create' ? 'Add Feature' : 'Edit Feature'}
                                </button>
                            </div>
                        </div>
                    </div>
                    }
                    {!isFeatureLoading &&
                    <Formik
                        initialValues={{
                            id: feature && feature.id || null,
                            name: feature &&  feature.name || '',
                            description: feature && feature.description || '',
                            price: feature && feature.price || '0.00',
                            serviceModelId: feature && feature.serviceModelId || null,
                            planType: feature && feature.planType || null,
                            featureTypeId: feature && feature.featureTypeId || null,
                            featureClass: feature && feature.featureClass || null,
                            rateCodeId: feature && feature.rateCodeId || null,
                            providerId: feature && feature.providerId || null,
                            suppressZero: feature && feature.suppressZero || false,
                            variableQuantity: feature && feature.variableQuantity || false,
                            variablePrice: feature && feature.variablePrice || false,
                            hideCustomerPortal: feature && feature.hideCustomerPortal || false,
                            hideSignupPrice: feature && feature.hideSignupPrice || false,
                            hideSurveyPrice: feature && feature.hideSurveyPrice || false,
                            selectedGeneralLedgers: this.state.selectedGeneralLedgers,
                            generalLedgersToDelete: this.state.generalLedgersToDelete
                        }}
                        validationSchema={this.featureSchema()}
                        onSubmit={this.formSubmit}
                        render={(props) => {
                            const {
                                handleChange,
                                handleSubmit,
                                handleBlur,
                                values,
                                errors,
                                touched,
                                isSubmitting,
                                setFieldValue
                            } = props;
                            return(
                                <form onSubmit={handleSubmit}>
                                    <div className="modal-dialog modal-lg">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h5 className="modal-title">
                                                    {modalProps.type === 'create' ? 'Add Feature' : 'Edit Feature'}
                                                </h5>
                                                <button onClick={this.props.hideModal} type="button" className="close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div className="modal-body form-horizontal">
                                                {!isEmpty(alertMessage) && (
                                                    <div className="alert alert-inline alert-danger alert-dismissible">
                                                        <p className="mb-0">{alertMessage}</p>
                                                    </div>
                                                )}
                                                <div className="form-group">
                                                    <TextInput
                                                        label="Name"
                                                        name="name"
                                                        value={values.name.toUpperCase()}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        errors={errors}
                                                        touched={touched}
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <TextInput
                                                        label="Description"
                                                        name="description"
                                                        value={values.description}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        errors={errors}
                                                        touched={touched}
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <div onClick={this.scrollDropdownIntoView.bind(this)}>
                                                        <FormSelect
                                                            title="Service Model"
                                                            fieldName="serviceModelId"
                                                            placeholder="Select one..."
                                                            options={serviceModelsAsOptions(serviceModels)}
                                                            setFieldValue={setFieldValue}
                                                            value={values.serviceModelId}
                                                            onBlur={handleBlur}
                                                            isDisabled={(this.props.modalProps.type === 'create' ? false: true)}
                                                            errors={errors}
                                                            touched={touched}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <div onClick={this.scrollDropdownIntoView.bind(this)}>
                                                        <FormSelect
                                                            title="Plan Type"
                                                            fieldName="planType"
                                                            placeholder="Select one..."
                                                            options={planTypesAsOptions(planTypes)}
                                                            isDisabled={(this.props.modalProps.type === 'create' ? false: true)}
                                                            setFieldValue={setFieldValue}
                                                            value={values.planType}
                                                            onBlur={handleBlur}
                                                            errors={errors}
                                                            touched={touched}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <div onClick={this.scrollDropdownIntoView.bind(this)}>
                                                        <FormSelect
                                                            title="Feature Class"
                                                            fieldName="featureClass"
                                                            placeholder="Select one..."
                                                            setFieldValue={(fieldName, value) => this.setFeatureClass(value, fieldName, setFieldValue)}
                                                            value={values.featureClass}
                                                            options={valuesAsOptions(["METERED", "ONETIME", "RECURRING", "OPERATIONAL"])}
                                                            onBlur={handleBlur}
                                                            errors={errors}
                                                            touched={touched}
                                                            isDisabled={(this.props.modalProps.type === 'create' ? false: true)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <div onClick={this.scrollDropdownIntoView.bind(this)}>
                                                        <FormSelect
                                                            title="Feature Type"
                                                            fieldName="featureTypeId"
                                                            placeholder="Select one..."
                                                            options={featureTypesAsOptions(featureTypes).filter(function (featureTypeOption){
                                                                if(featureTypeOption.class === values.featureClass){
                                                                    return true;
                                                                }
                                                                return false;
                                                            })}
                                                            setFieldValue={setFieldValue}
                                                            value={values.featureTypeId}
                                                            onBlur={handleBlur}
                                                            errors={errors}
                                                            touched={touched}
                                                            isDisabled={(this.props.modalProps.type === 'create' ? false: true)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label>Price</label>
                                                    <div className="input-group">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text">$</span>
                                                        </div>
                                                        <input
                                                            style={{flex: 'auto'}}
                                                            type="text"
                                                            name="price"
                                                            disabled={isSubmitting}
                                                            value={values.price}
                                                            data-amount={''}
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            autoComplete={"off"}
                                                        />
                                                    </div>
                                                </div>
                                                {values.featureClass == "METERED" ?
                                                    <div className="form-group">
                                                        <div onClick={this.scrollDropdownIntoView.bind(this)}>
                                                            <FormSelect
                                                                title="Rate Code"
                                                                fieldName="rateCodeId"
                                                                placeholder="Select one..."
                                                                setFieldValue={setFieldValue}
                                                                value={values.rateCodeId}
                                                                options={rateCodesAsOptions(rateCodes)}
                                                                onBlur={handleBlur}
                                                                errors={errors}
                                                                touched={touched}
                                                                disabled={true}
                                                            />
                                                        </div>
                                                    </div>
                                                    : ''
                                                }
                                                <div className="form-group">
                                                    <div onClick={this.scrollDropdownIntoView.bind(this)}>
                                                        <FormSelect
                                                            title="Provider (optional)"
                                                            fieldName="providerId"
                                                            placeholder="Select one..."
                                                            setFieldValue={setFieldValue}
                                                            value={values.providerId}
                                                            options={providersAsOptions(providers)}
                                                            onBlur={handleBlur}
                                                            errors={errors}
                                                            touched={touched}
                                                            disabled={true}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-check checkbox-slider checkbox-slider--b-flat">
                                                    <label>
                                                        <input
                                                            name="suppressZero"
                                                            type="checkbox"
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            checked={values.suppressZero}
                                                        />
                                                        <span>&nbsp;</span>
                                                    </label>
                                                    <label>Suppress when zero charge on invoice</label>
                                                </div>
                                                <div className="form-check checkbox-slider checkbox-slider--b-flat">
                                                    <label>
                                                        <input
                                                            name="variableQuantity"
                                                            type="checkbox"
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            checked={values.variableQuantity}
                                                        />
                                                        <span>&nbsp;</span>
                                                    </label>
                                                    <label>Variable Quantity</label>
                                                </div>
                                                <div className="form-check checkbox-slider checkbox-slider--b-flat">
                                                    <label>
                                                        <input
                                                            name="variablePrice"
                                                            type="checkbox"
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            checked={values.variablePrice}
                                                        />
                                                        <span>&nbsp;</span>
                                                    </label>
                                                    <label>Variable Price</label>
                                                </div>
                                                <div className="form-check checkbox-slider checkbox-slider--b-flat">
                                                    <label>
                                                        <input
                                                            name="hideCustomerPortal"
                                                            type="checkbox"
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            checked={values.hideCustomerPortal}
                                                        />
                                                        <span>&nbsp;</span>
                                                    </label>
                                                    <label>Hide in Customer Portal</label>
                                                </div>
                                                <div className="form-check checkbox-slider checkbox-slider--b-flat">
                                                    <label>
                                                        <input
                                                            name="hideSignupPrice"
                                                            type="checkbox"
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            checked={values.hideSignupPrice}
                                                        />
                                                        <span>&nbsp;</span>
                                                    </label>
                                                    <label>Hide Price in Signup</label>
                                                </div>
                                                <div className="form-check checkbox-slider checkbox-slider--b-flat">
                                                    <label>
                                                        <input
                                                            name="hideSurveyPrice"
                                                            type="checkbox"
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            checked={values.hideSurveyPrice}
                                                        />
                                                        <span>&nbsp;</span>
                                                    </label>
                                                    <label>Hide price in Survey</label>
                                                </div>
                                                <span>&nbsp;</span>
                                                <label>General Ledgers (optional)</label>
                                                <FieldArray name="generalLedgers"
                                                            render={({remove, push}) => <>
                                                                {values.selectedGeneralLedgers && values.selectedGeneralLedgers.map((selectedGeneralLedger, index) =>
                                                                    <div className="" key={selectedGeneralLedger.id} style={{paddingBottom: '10px'}}>
                                                                        <div className="card card-sm">
                                                                            <div className="card-header" style={{paddingRight: '0px'}}>
                                                                                    <div className="col-md-9" style={{paddingLeft: '0px'}}>
                                                                                        <label style={{fontSize: "0.95rem"}}>
                                                                                            {(selectedGeneralLedger.glLocationId || selectedGeneralLedger.glId || selectedGeneralLedger.glSubId ?
                                                                                                this.buildGeneralLedgerTitle(selectedGeneralLedger)
                                                                                                :
                                                                                                "General Ledger"
                                                                                            )}
                                                                                        </label>
                                                                                    </div>
                                                                                    <div className="col-md-2" style={{textAlign: "end"}}>
                                                                                        {values.selectedGeneralLedgers.length > 1 || (
                                                                                            selectedGeneralLedger.glLocationId || selectedGeneralLedger.glId || selectedGeneralLedger.glSubId) ?
                                                                                            <button
                                                                                                onClick={() => this.handleRemoveGeneralLedger(props, remove, index, setFieldValue)}
                                                                                                className="btn btn-sm btn-danger"
                                                                                                type="button"
                                                                                            >
                                                                                                Remove
                                                                                            </button>
                                                                                            : ''
                                                                                        }
                                                                                    </div>
                                                                            </div>
                                                                            <div className="card-body">
                                                                                <div className="row">
                                                                                    <div className="form-group col-sm-4">
                                                                                        <div
                                                                                            onClick={this.scrollDropdownIntoView.bind(this)}>
                                                                                            <FormSelect
                                                                                                fieldName={`selectedGeneralLedgers.${index}.glLocationId`}
                                                                                                placeholder="Select Location"
                                                                                                options={systemLocationAsOptions(removeSelectedLocations(systemLocations, index, values.selectedGeneralLedgers))}
                                                                                                setFieldValue={(e, value) => this.setGeneralLedgerLocationValue(e, value, setFieldValue, index)}
                                                                                             //   setFieldValue={setFieldValue}
                                                                                                value={selectedGeneralLedger.glLocationId}
                                                                                                onBlur={handleBlur}
                                                                                                errors={errors}
                                                                                                touched={touched}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="form-group col-sm-4">
                                                                                        <div
                                                                                            onClick={this.scrollDropdownIntoView.bind(this)}>
                                                                                            <FormSelect
                                                                                                fieldName={`selectedGeneralLedgers.${index}.glId`}
                                                                                                placeholder="Select Account"
                                                                                                options={generalLedgersAsOptions(generalLedgers)}
                                                                                                setFieldValue={(e, value) => this.setGeneralLedgerValue(e, value, setFieldValue, index)}
                                                                                                value={selectedGeneralLedger.glId}
                                                                                                onBlur={handleBlur}
                                                                                                errors={errors}
                                                                                                touched={touched}
                                                                                            />
                                                                                        </div>
                                                                                        <span
                                                                                            className="text-muted">{selectedGeneralLedger.glDescription}</span>
                                                                                    </div>
                                                                                    <div className="form-group col-sm-4">
                                                                                        <div
                                                                                            onClick={this.scrollDropdownIntoView.bind(this)}>
                                                                                            <FormSelect
                                                                                                fieldName={`selectedGeneralLedgers.${index}.glSubId`}
                                                                                                placeholder="Select Sub Account"
                                                                                                options={generalLedgersSubAccountsAsOptions(matchCurrentSelectedGeneralLedger(generalLedgerSubAccounts, selectedGeneralLedger.glId))}
                                                                                                setFieldValue={setFieldValue}
                                                                                                value={selectedGeneralLedger.glSubId}
                                                                                                onBlur={handleBlur}
                                                                                                errors={errors}
                                                                                                touched={touched}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                                <span>&nbsp;</span>
                                                                <div className="clearfix">
                                                                    <button
                                                                        onClick={() => this.addAnotherGeneralLedger(values.selectedGeneralLedgers)}
                                                                        className="btn btn-sm btn-outline-secondary btn-discount-add float-right"
                                                                        type="button"
                                                                    >
                                                                        Add Another
                                                                    </button>
                                                                </div>

                                                            </>
                                                            }
                                                />
                                            </div>
                                            <div className="modal-footer">
                                                <button onClick={this.props.hideModal} className="btn" type="button">
                                                    Cancel
                                                </button>
                                                <button disabled={isSubmitting} type="submit" className="btn btn-primary">
                                                    {modalProps.type === 'create' ? 'Add Feature' : 'Edit Feature'}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            )}}
                    />}
                </div>
                <div className="modal-backdrop show" tabIndex="1"/>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    const modalProps = getModalData(state).modalProps,
        feature = getProducts(state).feature,
        featureTypes = getProducts(state).featureTypes,
        rateCodes = getProducts(state).rateCodes,
        serviceModels = getDomain(state).serviceModels,
        providers = getAdmin(state).providers,
        generalLedgers = getAdmin(state).generalLedgers,
        generalLedgerSubAccounts = getAdmin(state).generalLedgerSubAccounts,
        systemLocations = getAdmin(state).systemLocations

    return {
        modalProps,
        feature,
        featureTypes,
        rateCodes,
        serviceModels,
        providers,
        systemLocations,
        generalLedgers,
        generalLedgerSubAccounts
    };
};

const mapDispatchToProps = {
    getFeature,
    getFeatureTypes,
    getGeneralLedgers,
    getGeneralLedgerSubAccounts,
    getRateCodes,
    getServiceModels,
    getSystemLocations,
    clearFeature,
    addFeature,
    updateFeature,
    getProviders,
    hideModal
};

export default connect(mapStateToProps, mapDispatchToProps)(AddFeatureModal);