import axiosInstance from '../../utils/axios-instance';
import { actionCreator, createRequestTypes } from '../../utils/helpers';

export const SET_SERVICES_AND_EQUIPMENT = createRequestTypes('SET_SERVICES_AND_EQUIPMENT');
export const SET_SERVICES_ICONS = createRequestTypes('SET_SERVICES_ICONS');
export const GET_SERVICELINE_HISTORY = createRequestTypes('GET_SERVICELINE_HISTORY');

// export const getAccount360Badjes = (accountId) => async (dispatch) => {
// 	dispatch(actionCreator.request(SET_ACCOUNT360_BADJES));
// 	try {
// 		const response = await axiosInstance.get(``);
// 		dispatch(actionCreator.success(SET_ACCOUNT360_BADJES, response.data));
// 	} catch (err) {
// 		dispatch(actionCreator.failure(SET_ACCOUNT360_BADJES));
// 	}
// };

// export const emptyAccount360Badjes = () => (dispatch) => {
// 	dispatch(actionCreator.empty(SET_ACCOUNT360_BADJES));
// };

// SERVICES -- START //

export const getServicesAndEquipment = (accountId, showHistoricalData) => async (dispatch) => {
	dispatch(actionCreator.request(SET_SERVICES_AND_EQUIPMENT));

	const params = {}
	if (showHistoricalData) {
		params.showHistoricalData = showHistoricalData;
	}

	try {
		const response = await axiosInstance.get(`/account/${accountId}/servicelines`, {params});
		dispatch(actionCreator.success(SET_SERVICES_AND_EQUIPMENT, response.data.services));
		return response.data;
		
	} catch (err) {
		dispatch(actionCreator.failure(SET_SERVICES_AND_EQUIPMENT));
	}
};

export const emptyServicesAndEquipment = () => (dispatch) => {
	dispatch(actionCreator.empty(SET_SERVICES_AND_EQUIPMENT));
};


export const getServicesIcons = (accountId) => async (dispatch) => {
	dispatch(actionCreator.request(SET_SERVICES_ICONS));
	try {
		const response = await axiosInstance.get(`/account/${accountId}/servicemodels`);
		dispatch(actionCreator.success(SET_SERVICES_ICONS, response.data.serviceModels));
	} catch (err) {
		dispatch(actionCreator.failure(SET_SERVICES_ICONS));
	}
};


export const emptyServicesIcons = () => (dispatch) => {
	dispatch(actionCreator.empty(SET_SERVICES_ICONS));
};


export const getServiceLineHistory = (serviceLineId) => async (dispatch) => {
	dispatch(actionCreator.request(GET_SERVICELINE_HISTORY));
	let config = {
		historicalFeatures: true
	}
	try {
		const response = await axiosInstance.get(`/account/${serviceLineId}/historical`, {params: config});
		dispatch(actionCreator.success(GET_SERVICELINE_HISTORY, response.data));
		return response.data;
	} catch (err) {
		dispatch(actionCreator.failure(GET_SERVICELINE_HISTORY));
	}
};
