import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Doughnut } from 'react-chartjs-2';

export class GenericChart extends Component {
	render() {
		const { info } = this.props;
		const data = {
			labels: info.labels,
			datasets: [
				{
					data: info.data,
					// data: [1, 0, 0, 0],
					backgroundColor: info.backgroundColor,
					borderWidth: 1,
					weight: 15,
					// hoverBackgroundColor: ["#FF6384", "#36A2EB"]
				}
			]
		};

		const options = {
			elements: {
				center: {
					color: '#495057',
					text: `$${info.center.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}` //set as you wish
				}
			},
			animation: {
				duration: 400,
				easing: 'linear'
			},
			cutoutPercentage: 65,
			legend: {
				display: false
			},
			tooltips: {
				enabled: true,
				callbacks: {
					title: function(tooltipItem, data) {
						if(Array.isArray(data['labels'][tooltipItem[0]['index']])){
							return data['labels'][tooltipItem[0]['index']][1]!=='null'?`${data['labels'][tooltipItem[0]['index']][0]}
							${data['labels'][tooltipItem[0]['index']][1]}
							${data['labels'][tooltipItem[0]['index']][2]}
							${data['labels'][tooltipItem[0]['index']][3]}`:`${data['labels'][tooltipItem[0]['index']][0]}
							${data['labels'][tooltipItem[0]['index']][2]}
							${data['labels'][tooltipItem[0]['index']][3]}`
						}
						else if (data['labels'][tooltipItem[0]['index']] !== 'Default') {
							return data['labels'][tooltipItem[0]['index']] + ':';
						}
					},
					label: function(tooltipItem, data, index) {
						if(Array.isArray(data['labels'][0])){
							return '';
						}
						else if (tooltipItem['index'] === 0) {
							return '$0.00';
						} else {
							return (
								'$' +
								data['datasets'][0]['data'][tooltipItem['index']]
									.toFixed(2)
									.replace(/\d(?=(\d{3})+\.)/g, '$&,')
							);
						}
					}
				}
			}
		};

		return <Doughnut data={data} width={100} height={100} options={options} />;
	}
}

GenericChart.propTypes = {
	info: PropTypes.object.isRequired
};

export default GenericChart;
