import React, {Component} from 'react';
import {connect} from 'react-redux';
import {getOrderById} from "../../../../actions/accountDetailsActions/ordersAndQuotes.actions";

class Confirmation extends Component {

    state = {
        tasksCountData: 0,
    }

    componentDidMount() {
        this.props.getOrderById(this.props.orderId).then(response => {
            if (response && response.success && response.order && response.order.serviceOrders && response.order.serviceOrders[0]) {
                this.setState({tasksCountData: response.order.serviceOrders[0].activeUserTasks});
            }
        });
    }

    displayCurrentOrder = () => {
        const {orderId, hideAccountDetailsWizard, changeActiveLeftPanelHandler, history} = this.props;

        // Hide Wizard
        hideAccountDetailsWizard();

        changeActiveLeftPanelHandler('ordersAndQuotes');

        // Indicate Order Id
        history.replace({state: {
            activeTab: 'ordersAndQuotes',
            orderId: orderId,
        }});
    }

    render() {

        const {tasksCountData} = this.state;

        return (
            <div className="text-center">

                <p className="text-success"><i className="far fa-4x fa-check-circle" /></p>
                <p className="text-success lead">Service Line Remove Order #{this.props.orderId} created successfully.</p>

                <button
                    type="button"
                    className="btn btn-outline-success btn-lg mb-1"
                    style={{ margin: '0px 5px' }}
                    onClick={this.displayCurrentOrder}
                >
                    View Order
                </button>

                {tasksCountData > 0 && (
                    <button
                        onClick={this.displayCurrentOrder}
                        className="btn btn-warning btn-lg mb-1"
                        style={{ margin: '0px 5px' }}
                        type="button"
                    >
                        <i className="fas fa-tools" />&nbsp;{tasksCountData} {tasksCountData > 1 ? 'Tasks' : 'Task'} Pending
                    </button>
                )}

            </div>
        );
    }
}

const mapStateToProps = (state, props) => {
    const orderId = props.accumulatedValues.orderId;

    return {
        orderId,
    };
};

const mapDispatchToProps = {
    getOrderById,
};

export default connect(mapStateToProps, mapDispatchToProps)(Confirmation);