import React, { Component } from 'react';
import { connect } from 'react-redux';
import {Form, Formik} from "formik";
import {PulseLoader} from "react-spinners";
import {toastr} from "react-redux-toastr";

import StepActions from "../../../camvio-wizard/StepActions";
import {
    getProvisioningQueueGroup
} from "../../../actions/provisioning.actions";
import {getErrorMessage} from "../../../utils/helpers";

const PULL_STATUS = {
    IN_PROGRESS: 'IN_PROGRESS',
    COMPLETED: 'COMPLETED',
    ERROR: 'ERROR'
}

const PROCESS_STATUS = {
    NEW: "NEW",
    PROCESSING: "PROCESSING",
    PROCESSED: "PROCESSED",
    WARNING: "WARNING",
    ERROR: "ERROR",
    ON_HOLD: "ON_HOLD",
    CANCELED: "CANCELED",
    DEPRECATED: "DEPRECATED",
}

const isProcessStatusInProgress = (status) => {
    switch (status) {
        case PROCESS_STATUS.PROCESSED:
        case PROCESS_STATUS.ERROR:
        case PROCESS_STATUS.WARNING:
        case PROCESS_STATUS.CANCELED:
            return false;
        default:
            return true;
    }
}

const getStatusClass = (status) => {
    switch (status) {
        case PROCESS_STATUS.NEW:
            return "info";
        case PROCESS_STATUS.PROCESSING:
            return "primary";
        case PROCESS_STATUS.PROCESSED:
            return "success";
        case PROCESS_STATUS.WARNING:
            return "warning";
        case PROCESS_STATUS.ERROR:
            return "danger";
        case PROCESS_STATUS.DEPRECATED:
            return "dark";
        default:
            return "secondary";
    }
}

const getAlertStatusClass = (status) => {
    switch (status) {
        case PROCESS_STATUS.NEW:
            return "alert-info";
        case PROCESS_STATUS.PROCESSING:
            return "alert-primary";
        case PROCESS_STATUS.PROCESSED:
            return "alert-success";
        case PROCESS_STATUS.WARNING:
            return "alert-warning";
        case PROCESS_STATUS.ERROR:
            return "alert-danger";
        case PROCESS_STATUS.DEPRECATED:
            return "alert-dark";
        default:
            return "alert-secondary";
    }
}

const formatResponse = (response) => {
    if (!response) {
        return null;
    }

    // try to format it as JSON
    try {
        const parsedResponse = JSON.parse(response);
        return JSON.stringify(parsedResponse, null, 4);
    } catch (e) {
    }

    return response;
}

const propNameStyle = {
    fontWeight: "600",
}

const propValueStyle = {
    color: "#6c757d",
    fontFamily: `"Fira Sans Condensed", sans-serif`,
    fontWeight: "500",
}

const renderStatus = (status) => {
    return (
        <span className={"badge badge-" + getStatusClass(status)}>
            <span style={{textSize: "1.4em"}}>{status}</span>
            {isProcessStatusInProgress(status) &&
                <div className="spinner-border spinner-border-sm" role="status" style={{marginLeft: '5px'}}>
                    <span className="sr-only">Loading...</span>
                </div>
            }
        </span>
    )
}

class Confirmation extends Component {

    componentWillUnmount() {
        if (this.queueGroupPullTimeout) {
            clearTimeout(this.queueGroupPullTimeout);
        }
    }

    pullQueueGroupById = (queueGroupId) => {
        if (this.queueGroupPullTimeout) {
            clearTimeout(this.queueGroupPullTimeout);
        }

        this.queueGroupPullTimeout = setTimeout(() => {
            this.getProvisioningQueueGroupById(queueGroupId);
        }, 5000);
    }

    getProvisioningQueueGroupById = (queueGroupId) => {
        const { getProvisioningQueueGroup, setStepState } = this.props;

        setStepState({ pullStatus: PULL_STATUS.IN_PROGRESS });

        getProvisioningQueueGroup(queueGroupId).then(resp => {
            if (resp.success || resp.data.success) {
                const provisioningQueueGroup = resp.data.provisioningQueueGroup;
                const pullStatus = isProcessStatusInProgress(provisioningQueueGroup.status)? PULL_STATUS.IN_PROGRESS : PULL_STATUS.COMPLETED;

                setStepState({
                    pullStatus: pullStatus,
                    provisioningQueueGroup: resp.data.provisioningQueueGroup
                });

                if (pullStatus === PULL_STATUS.IN_PROGRESS) {
                    this.pullQueueGroupById(queueGroupId);
                }
            } else {
                toastr.error(getErrorMessage(resp).message, {timeOut: 5000, position: 'top-center'});
                setStepState({ pullStatus: PULL_STATUS.ERROR });
            }
        }).catch(error => {
            toastr.error(getErrorMessage(error).message, { timeOut: 5000, position: 'top-center' });
            setStepState({ pullStatus: PULL_STATUS.ERROR });
        })
    }

    createValuesBundle = (values) => ({})

    createFormValuesBundle = (values) => ({
        ...values,
    })

    handleNext = (values) => {
        const { next } = this.props;
        if (!next) {
            return;
        }

        next({
            values: this.createValuesBundle(values),
            formValues: this.createFormValuesBundle(values)
        });
    }

    handlePrevious = (values) => {
        const { previous } = this.props;
        if (!previous) {
            return;
        }

        previous({
            formValues: this.createFormValuesBundle(values),
        });
    }

    handleOnSubmit = (values, actions) => {
        this.handleNext(values);
    }

    renderSuccessfulSubmit = () => {
        const { pullStatus } = this.props.stepState;
        const { provisioningGroupId } = this.props;

        if (pullStatus) {
            return null;
        }

        return (
            <div className="form-section-customer-payment-management-message">
                <h4>Request Submitted Successfully</h4>
                <i className="far fa-check-circle text-success" />
                <p>Your request# is {provisioningGroupId}</p>
                <button onClick={() => this.getProvisioningQueueGroupById(provisioningGroupId)}
                        className="btn btn-outline-primary btn-next-tab ml-auto"
                        type="button">
                    Check Status
                </button>
            </div>
        );
    }

    renderPullStatus = () => {
        const { pullStatus, provisioningQueueGroup } = this.props.stepState;

        if (!pullStatus) {
            return null;
        }

        switch (pullStatus) {
            case PULL_STATUS.IN_PROGRESS:
                return (
                    <>
                        {!provisioningQueueGroup &&
                            <div style={{textAlign: "center"}}>
                                <PulseLoader />
                                <h4>Checking status...</h4>
                            </div>
                        }
                        {provisioningQueueGroup &&
                            <p>{this.renderProvisioningQueueGroup(provisioningQueueGroup)}</p>
                        }
                    </>
                )
            case PULL_STATUS.COMPLETED:
                return (
                    <>
                        <p>{this.renderProvisioningQueueGroup(provisioningQueueGroup)}</p>
                    </>
                )
            case PULL_STATUS.ERROR:
                return (
                    <>
                        <div className="form-section-customer-payment-management-message">
                            <i className="far fa-times-circle text-danger" />
                            <p>Pulling status error</p>
                        </div>
                    </>
                )
            default:
                return null;
        }
    }

    renderProvisioningQueueGroup = (queueGroup) => {

        return (
            <>
                <div className="card-bundle">
                    <div className="card-header"><h4>Info</h4></div>
                    <div className="card-body">
                        <div className="form-group">
                            <div>
                                <span style={propNameStyle}>DN:&nbsp;</span>
                                <code>{queueGroup.dn}</code>
                            </div>
                            <div>
                                <span style={propNameStyle}>Group ID:&nbsp;</span>
                                <code>{queueGroup.id}</code>
                            </div>
                            <div>
                                <span style={propNameStyle}>Status:&nbsp;</span>
                                {renderStatus(queueGroup.status)}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="card-bundle">
                    <div className="card-header"><h4>Numbers</h4></div>
                    <div className="card-body">
                        <div className="form-group">
                            {queueGroup.groupNumbers && queueGroup.groupNumbers.map(num => (
                                <div>
                                    <span style={propNameStyle}>{num.systemNumTypeName}:&nbsp;</span>
                                    {num.number}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                <div className="card-bundle">
                    <div className="card-header"><h4>Queues:</h4></div>
                    <div className="card-body">
                        {queueGroup.groupItems
                            .filter(queue => queue.status !== PROCESS_STATUS.CANCELED
                                && queue.status !== PROCESS_STATUS.DEPRECATED
                                && queue.status !== PROCESS_STATUS.NEW)
                            .map(queue => (
                                <div className="plan-wrapper" key={queue.provisioningInterfaceName}>
                                    <div className="serviceline-wrapper">
                                        <div className="serviceline-header">
                                            <h4>
                                                <span className="badge badge-line-type"><h5>{queue.provisioningInterfaceName}</h5></span>
                                            </h4>
                                        </div>
                                        <div className="serviceline-body">
                                            <div>
                                                <span style={propNameStyle}>Action:&nbsp;</span>
                                                <span style={propValueStyle}>{queue.type}</span>
                                            </div>
                                            <div>
                                                <span style={propNameStyle}>Status:&nbsp;</span>
                                                {renderStatus(queue.status)}
                                            </div>

                                            <div className="plan-section" >
                                                <h5>Request</h5>
                                                <div className="package-wrapper">
                                                    <pre>{formatResponse(queue.jsonRequest)}</pre>
                                                </div>
                                                <h5>Response</h5>
                                                <div className="package-wrapper">
                                                    <pre>{formatResponse(queue.jsonResponse)}</pre>
                                                </div>
                                                <h5>Status Description</h5>
                                                <div className="package-wrapper">
                                                    <div className={`alert ${getAlertStatusClass(queue.status)}`} role="alert">
                                                        <pre>{formatResponse(queue.statusDescription)}</pre>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        )}
                    </div>
                </div>
            </>
        );
    }

    renderContent = () => {
        if (!this.props.provisioningGroupId) {
            return <h4>Missing provisioning group</h4>
        }

        return (
            <>
                {this.renderSuccessfulSubmit()}
                {this.renderPullStatus()}
            </>
        )
    }

    render() {
        const { stepFormValues, previous, next, history, provisioningAction } = this.props;
        const { pullStatus } = this.props.stepState;

        return (
            <Formik
                onSubmit={this.handleOnSubmit}
                initialValues={stepFormValues}
                render={(formProps) => (
                    <Form onSubmit={formProps.handleSubmit} className="cmv-form" autoComplete="off">

                        {this.renderContent(formProps)}

                        <StepActions
                            previous={
                                (previous && (provisioningAction.actionType === 'QUERY' || pullStatus === PULL_STATUS.ERROR))
                                && (() => this.handlePrevious(formProps.values))}
                            next={(provisioningAction.actionType === 'QUERY' || pullStatus === PULL_STATUS.COMPLETED) && (() => history.push('/'))}
                            nextBtnName="Close"
                        />

                    </Form>
                )}
            />
        );
    }
}

const mapStateToProps = (state, props) => {
    const { provisioningAction, provisioningGroupId } = props.accumulatedValues;

    return {
        provisioningAction,
        provisioningGroupId,
    };
};

const mapDispatchToProps = {
    getProvisioningQueueGroup,
};

export default connect(mapStateToProps, mapDispatchToProps)(Confirmation);