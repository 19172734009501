import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {Formik} from 'formik';
import {hideModal} from '../../../actions/modal.actions';
import axiosInstance from "../../../utils/axios-instance";
import {
    getModalData
   
} from '../../../selectors';
import { get } from 'lodash';
import isEmpty from "../../../utils/helpers";
import FormSelect from "../../../components/UI/FormSelect";
import {
	updateOrderSalesAgent
} from '../../../actions/dashboardActions/orders.actions';

const salesAgentsAsOptions = (salesAgents) => asOptions(salesAgents, salesAgent => {
   
    return {
        label: salesAgent.firstName + " " + salesAgent.lastName,
        value: JSON.stringify(salesAgent)
    }
});

const asOptions = (source, mapper) => {
    if (!Array.isArray(source)) {
        return [];
    }

    return source.map(mapper);
} 

const findSalesAgentById = (salesAgents, id) => {
    return salesAgents && salesAgents.find(salesAgent => salesAgent.id === id);
}
const none = {id: -1, firstName: "None", lastName: "", username: "none"};

class SalesAgentModal extends Component {

    state = {
        alertMessage: '',
        resultsNumber: 20,
        searchTerm: '',
        selectedSalesAgent: this.props.modalProps.orderInfo ? this.props.modalProps.orderInfo.salesAgent ? JSON.stringify(this.props.modalProps.orderInfo.salesAgent) : JSON.stringify(none) : JSON.stringify(none),
        salesAgentOptions: [],
        fetchingSalesAgents: false
    };

    componentDidMount() {
        this.reloadSalesAgents();
    }

    reloadSalesAgents = () => {
        try {
			const getParams = {
                limit: this.state.resultsNumber,
                query: this.state.searchTerm
            };

			axiosInstance.get(`/order/sales-agents`, { params: getParams })
				.then(response => {   
                    
                    let {selectedSalesAgent} = this.state;
					const salesAgentsResults = response.data.salesAgents;
                    
                    selectedSalesAgent = JSON.parse(selectedSalesAgent)

					if (selectedSalesAgent) {
                        if(selectedSalesAgent.id = -1) {
                            salesAgentsResults.sort((a, b) => a.firstName.localeCompare(b.firstName));
                            
                            salesAgentsResults.unshift(none);
                        } else {
                            const systemUser = salesAgentsResults.find(systemUser => systemUser.id === selectedSalesAgent.id);
                            
                            if (!systemUser) {
                                salesAgentsResults.push(selectedSalesAgent);
                            }

                            salesAgentsResults.sort((a, b) => a.firstName.localeCompare(b.firstName));
                            salesAgentsResults.unshift(none);
                        }
                    }
                   
                    this.setState({
						salesAgentOptions: salesAgentsResults 
					})
				})
				.catch(errorResponse => {
					this.handlerErrorResponse(errorResponse);
				});
		} catch (err) {
			this.handlerErrorResponse(err);
        }
        
    }

    scrollDropdownIntoView = (e) => {
        const elementClicked = e.target.nodeName;
        const boundingElement = e.currentTarget;
        const modalBody = document.getElementsByClassName('modal-body')[0];

        if (elementClicked !== 'LABEL') {

            setTimeout(function () {
                if (boundingElement.getBoundingClientRect().bottom > modalBody.getBoundingClientRect().bottom) {
                    if (boundingElement.getBoundingClientRect().height < modalBody.getBoundingClientRect().height) {
                        boundingElement.scrollIntoView({block: "end"});
                    }
                }
            }, 200);
        }
    }

    handlerErrorResponse = (errorResponse) => {
		this.setState({
			alertMessage: get(errorResponse, 'response.data.error.message', 'Something goes wrong'),
			salesAgentOptions: []
		})
    }

    handleSalesAgentsSearchChange = (value) => {
        if (this.state.searchTerm === value) {
            return;
        }

        this.setState({searchTerm: value}, () => {
            if (this.searchChangeTimeout) {
                clearTimeout(this.searchChangeTimeout);
            }

            this.searchChangeTimeout = setTimeout(() => {
                this.reloadSalesAgents();
            }, 300);
        });
    };

	handleOnSalesAgentChange = setFieldValue => (options, newValue) => {
		const selectedSalesAgent = findSalesAgentById(this.state.salesAgentOptions, JSON.parse(newValue).id);

        this.setState({selectedSalesAgent: JSON.stringify(selectedSalesAgent)});
    }

    formSubmit = (values, actions) => {
        let data = {
            salesAgentId: JSON.parse(values.currentSalesAgent).id
        }

        this.setState({alertMessage: ''}, () => {
            this.props.updateOrderSalesAgent(values.orderId, data).then((response) => {
                
                if (!response) {
                    actions.setSubmitting(false);
                    this.setState({alertMessage: 'Unknown error. Please try again later'});
                    return;
                }

                if (!response.success) {
                    actions.setSubmitting(false);
                    this.setState({alertMessage: response.data.error.message});
                    return;
                }
                
                this.props.hideModal();
                if (this.props.modalProps.onCloseCallback) {
                    this.props.modalProps.onCloseCallback();
                }
            });
        });
    }

    render() {
       const orderId = this.props.modalProps.orderInfo.id;
       const { alertMessage} = this.state;
       const currentSalesAgent = this.state.selectedSalesAgent

        return (
        <Fragment>
            <div className="modal" style={{display: 'block'}} tabIndex="-1" role="dialog">
            <Formik
                    initialValues={{
                        orderId: orderId,
                        currentSalesAgent: currentSalesAgent
                    }}
                    onSubmit={this.formSubmit}
                    render={({handleSubmit, handleBlur, values, touched, isSubmitting, setFieldValue}) => (
                        <form onSubmit={handleSubmit}>
                            <div className="modal-dialog">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title">
                                            EDIT SALES AGENT
                                        </h5>
                                        <button onClick={this.props.hideModal} type="button" className="close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <div className="form-horizontal">

                                    {!isEmpty(alertMessage) && (
                                        <div className="alert alert-inline alert-danger alert-dismissible">
                                            <p className="mb-0">{alertMessage}</p>
                                        </div>
                                    )}                             
                                            <div className="form-group">
                                                <div onClick={this.scrollDropdownIntoView}>
                                                    <FormSelect
                                                        title="Sales Agent"
                                                        fieldName="currentSalesAgent"
                                                        options={salesAgentsAsOptions(this.state.salesAgentOptions)}
                                                        setFieldValue={setFieldValue}
                                                        value={values.currentSalesAgent}
                                                        onBlur={handleBlur}
                                                        touched={touched}
                                                        onInputChange={this.handleSalesAgentsSearchChange}
                                                        onPostChange={this.handleOnSalesAgentChange(setFieldValue)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button onClick={this.props.hideModal} className="btn" type="button">
                                            CANCEL
                                        </button>
                                        <button disabled={isSubmitting} type="submit" className="btn btn-primary">
                                            EDIT
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    )}
                />
            </div>
            <div className="modal-backdrop show" tabIndex="1"/>
        </Fragment>
        );
    }
}


const mapStateToProps = (state) => {
    const modalProps = getModalData(state).modalProps;
  
    return {
        modalProps
    };
};

const mapDispatchToProps = {
    hideModal,
    updateOrderSalesAgent
};

export default connect(mapStateToProps, mapDispatchToProps)(SalesAgentModal);

