import React, { Component, Fragment } from 'react';
import {showModal} from '../../../actions/modal.actions';
import {connect} from "react-redux";
import Loader from "../../Loader";

class TableRows extends Component {

    state = {
        showLogs: false,
    };

    showActionModal(modalName, value){
        let reloadFunction = this.props.reloadFunction;
        let noteFunction = this.props.noteFunction;
        this.props.showModal(modalName, {value, reloadFunction,noteFunction});
    }

    columnItemListFormatter = (columnRow, length) => {
        return columnRow.columnItemList.map((columnItem, index) => {
            if(length > 1 && index < 1){
                // We track index so the action column is not generated more than once
                return  (
                    <Fragment>
                        <div className="tr-actions">
                            {columnRow.columnItemList.map(columnItem =>
                                    <button
                                        className="btn"
                                        type="button"
                                        title={columnItem.name}
                                        onClick={() => this.showActionModal(columnItem.columnMetaDeta.columnName, columnItem.value)}
                                    >
                                        <i className={columnItem.icon}/>
                                    </button>

                            )}
                        </div>
                    </Fragment>
                );
            }else if(index < 1) {
                return (
                    columnItem&&columnItem.columnMetaDeta&&columnItem.columnMetaDeta.dataType != "boolean" ?
                    columnItem&&columnItem.columnMetaDeta&&columnItem.columnMetaDeta.dataType != "button" ?
                            //Information Columns
                            <Fragment>
                                {columnItem.bold == true ?
                                    <span>
                                            {columnItem.icon != null ?
                                                <span>
                                                <i className={columnItem.icon}/>
                                                    &nbsp;
                                                </span> : ''
                                            }
                                        {columnItem.style ?
                                            <span className={columnItem.style}>{columnItem.value}</span>
                                            :
                                            <span>
                                                {columnItem.value}
                                                {columnItem.type}
                                            </span>
                                        }
                                    </span>
                                    :
                                    (columnItem.value &&
                                        <span className="td-subtitle">
                                            <i className={columnItem.icon}/>
                                            {columnItem.value}
                                        </span>
                                    )
                                }
                            </Fragment>
                            :
                            //Action Column
                            <Fragment>
                                <div className="tr-actions">
                                    <button
                                        className="btn"
                                        type="button"
                                        onClick={() => this.showActionModal(columnItem.columnMetaDeta.columnName, columnItem.value)}
                                    >
                                        <i className={columnItem.icon}/>
                                    </button>
                                </div>
                            </Fragment>
                        :
                        //Status Column
                        <Fragment>
                                <span
                                    className={'badge' + (columnItem.value && columnItem.value == 'true' ? ' badge-green' : ' badge-secondary')}>
                                    {columnItem.value && columnItem.value == 'true' ? 'Active' : 'Inactive'}
                                </span>
                        </Fragment>
                );
            }
        })
    }

    columnFormatter = (column, length) => {
        return column.columnRowList.map((columnRow) => {
            return (
                <Fragment>
                    {this.columnItemListFormatter(columnRow, columnRow.columnItemList.length)}
                </Fragment>
            )
        })
    }

    rowFormatter = (row, rowIndex, headers) =>  {
        
        return row.columns.map((column, index) => {
            return (
                <td className={""} data-heading={headers[index].name}>
                    {this.columnFormatter(column, row.columns.length)}
                </td>
            )
        })
    }

    render() {

        const {
            rows,
            headers,
            showAdditionalLoading,
        } = this.props;         
        return (
                <tbody>
                    {rows.map((row, i) =>
                        <tr className={i % 2 === 0 ? 'tr-dark' : ''}>
                            {this.rowFormatter(row, i, headers)}
                        </tr>
                    )}
                </tbody>
        );
    }
}

const mapStateToProps = (state) => {
};

const mapDispatchToProps = {
    showModal
};

export default connect(mapStateToProps, mapDispatchToProps)(TableRows);
