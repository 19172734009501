import React, {Component} from 'react';
import {connect} from "react-redux";
import Loader from "../../components/Loader";
import isEmpty from "../../utils/helpers";
import {loadBillingJob} from "../../actions/billingJobs.actions";
import moment from "moment";

export class BillingJobDetails extends Component {

    state = {
        billingJob: null,
        isLoaded: false,
        isParametersExpanded: false,
    }

    componentDidMount() {
        this.props.loadBillingJob(this.props.id).then(response => {
            this.setState({
                isLoaded: true,
                billingJob: response,
            });
        });
    }

    showHideParameters = () => {
        this.setState(() => ({
            isParametersExpanded: !this.state.isParametersExpanded,
        }));
    }

    render() {

        const {
            isLoaded,
            isParametersExpanded,
            billingJob,
        } = this.state;

        return (
            <div>

                {!isLoaded
                ?
                <Loader />
                :
                isEmpty(billingJob)
                ?
                "No data found"
                :
                <>

                    <div className="billing-job-stages">

                        <div className="row">

                            <div className="col">
                                <div className="billing-job-stage">
                                    <h6>Test Billing</h6>
                                    <p>
                                        <span>Scheduled by: </span>
                                        <span>{billingJob.testedBy && billingJob.testedBy.firstName || "No data found"}</span>
                                    </p>
                                    <p>
                                        <span>Scheduled: </span>
                                        <span>{billingJob.testScheduled
                                            ? moment(billingJob.testScheduled).format("MM/DD/YYYY hh:mm:ss")
                                            : "No data found"}
                                        </span>
                                    </p>
                                    <p>
                                        <span>Started: </span>
                                        <span>{billingJob.testStarted
                                            ? moment(billingJob.testStarted).format("MM/DD/YYYY hh:mm:ss")
                                            : "No data found"}
                                        </span>
                                    </p>
                                    <p>
                                        <span>Finished: </span>
                                        <span>{billingJob.testFinished
                                            ? moment(billingJob.testFinished).format("MM/DD/YYYY hh:mm:ss")
                                            : "No data found"}
                                        </span>
                                    </p>
                                    {billingJob.testNote &&
                                    <p className="billing-job-stage-note">Note: {billingJob.testNote}</p>
                                    }
                                </div>
                            </div>

                            {billingJob.status && !billingJob.status.endsWith("REJECTED") &&
                            <div className="col">
                                <div className="billing-job-stage">
                                    <h6>Billing Approval</h6>
                                    <p>
                                        <span>Scheduled by: </span>
                                        <span>{billingJob.approveBy && billingJob.approveBy.firstName || "No data found"}</span>
                                    </p>
                                    <p>
                                        <span>Scheduled: </span>
                                        <span>{billingJob.approveScheduled
                                            ? moment(billingJob.approveScheduled).format("MM/DD/YYYY hh:mm:ss")
                                            : "No data found"}
                                        </span>
                                    </p>
                                    <p>
                                        <span>Started: </span>
                                        <span>{billingJob.approveStarted
                                            ? moment(billingJob.approveStarted).format("MM/DD/YYYY hh:mm:ss")
                                            : "No data found"}
                                        </span>
                                    </p>
                                    <p>
                                        <span>Finished: </span>
                                        <span>{billingJob.approveFinished
                                            ? moment(billingJob.approveFinished).format("MM/DD/YYYY hh:mm:ss")
                                            : "No data found"}
                                        </span>
                                    </p>
                                    {billingJob.approveNote &&
                                    <p className="billing-job-stage-note">Note: {billingJob.approveNote}</p>
                                    }
                                </div>
                            </div>
                            }

                            {billingJob.status && billingJob.status.endsWith("REJECTED") &&
                            <div className="col">
                                <div className="billing-job-stage">
                                    <h6>Billing Rejection</h6>
                                    <p>
                                        <span>Rejected by: </span>
                                        <span>{billingJob.rejectedBy && billingJob.rejectedBy.firstName || "No data found"}</span>
                                    </p>
                                    <p>
                                        <span>Rejected on: </span>
                                        <span>{billingJob.rejectedOn
                                            ? moment(billingJob.rejectedOn).format("MM/DD/YYYY hh:mm:ss")
                                            : "No data found"}
                                        </span>
                                    </p>
                                    {billingJob.rejectNote &&
                                    <p className="billing-job-stage-note">Note: {billingJob.rejectNote}</p>
                                    }
                                </div>
                            </div>
                            }

                            <div className="col">
                                <div className="billing-job-stage">
                                    <h6>Invoice Generation</h6>
                                    <p>
                                        <span>Scheduled by: </span>
                                        <span>{billingJob.generatedBy && billingJob.generatedBy.firstName || "No data found"}</span>
                                    </p>
                                    <p>
                                        <span>Scheduled: </span>
                                        <span>{billingJob.generateScheduled
                                            ? moment(billingJob.generateScheduled).format("MM/DD/YYYY hh:mm:ss")
                                            : "No data found"}
                                        </span>
                                    </p>
                                    <p>
                                        <span>Started: </span>
                                        <span>{billingJob.generateStarted
                                            ? moment(billingJob.generateStarted).format("MM/DD/YYYY hh:mm:ss")
                                            : "No data found"}
                                        </span>
                                    </p>
                                    <p>
                                        <span>Finished: </span>
                                        <span>{billingJob.generateFinished
                                            ? moment(billingJob.generateFinished).format("MM/DD/YYYY hh:mm:ss")
                                            : "No data found"}
                                        </span>
                                    </p>
                                    {billingJob.generateNote &&
                                    <p className="billing-job-stage-note">Note: {billingJob.generateNote}</p>
                                    }
                                </div>
                            </div>

                        </div>

                    </div>

                    <div className="billing-job-parameters">

                        <button
                            className="billing-job-parameters-toggle"
                            onClick={this.showHideParameters}
                        >
                            {isParametersExpanded ? "Hide" : "Show"} parameters
                        </button>

                        {isParametersExpanded &&
                        <div className="billing-job-parameters-content">
                            <h6>Parameters</h6>
                            <div className="row">
                                <div className="col">
                                    <p>
                                        <span>Bill Cycle</span>
                                        <span>{JSON.parse(billingJob.paramaters).billCycle|| "No data found"}</span>
                                    </p>
                                    <p>
                                        <span>Start of Cycle</span>
                                        <span>{billingJob.dataUsageEndDate
                                            ? moment(billingJob.dataUsageEndDate).format("MM/DD/YYYY")
                                            : "No data found"}
                                        </span>
                                    </p>
                                    <p>
                                        <span>Accounts</span>
                                        <span>{billingJob.processedAccounts || "No data found"}</span>
                                    </p>
                                </div>
                                <div className="col">
                                    <p>
                                        <span>Usage start</span>
                                        <span>{billingJob.dataUsageStartDate
                                            ? moment(billingJob.dataUsageStartDate).format("MM/DD/YYYY")
                                            : "No data found"}
                                        </span>
                                    </p>
                                    <p>
                                        <span>Usage end</span>
                                        <span>{billingJob.dataUsageEndDate
                                            ? moment(billingJob.dataUsageEndDate).format("MM/DD/YYYY")
                                            : "No data found"}
                                        </span>
                                    </p>
                                </div>
                                <div className="col">
                                </div>
                            </div>
                        </div>
                        }

                    </div>

                </>
                }

            </div>
        );
    }
}

const mapStateToProps = (state) => {

    return {
    };
};

const mapDispatchToProps = {
    loadBillingJob,
};

export default connect(mapStateToProps, mapDispatchToProps)(BillingJobDetails);
