import React, {Component} from 'react';
import {isEmpty} from '../../../../../../utils/helpers';
import moment from "moment";

export class ServiceSummary extends Component {

    render() {

        const {service} = this.props;

        if (isEmpty(service)) {
            return null;
        }

        let recurringFeaturePackageFees = null;
        let recurringFeatureFees = null;
        let oneTimeFeaturePackageFees = null;
        let oneTimeFeatureFees = null;

        recurringFeaturePackageFees = !isEmpty(service.activeFeaturePackages) ?
            service.activeFeaturePackages.filter((x) => x.featureType !== 'ACTIVATION') : null;

        recurringFeatureFees = !isEmpty(service.servicelineFeatures) ?
            service.servicelineFeatures.filter((x) => x.featureType !== 'ACTIVATION') : null;

        oneTimeFeaturePackageFees = !isEmpty(service.activeFeaturePackages) ?
            service.activeFeaturePackages.filter((x) => x.featureType === 'ACTIVATION') : null;

        oneTimeFeatureFees = !isEmpty(service.servicelineFeatures) ?
            service.servicelineFeatures.filter((x) => x.featureType === 'ACTIVATION') : null;

        return (
            <div className="services-summary mt-0">
                <div className="services-summary-body">
                    <div className="plan-wrapper">
                        <div className="serviceline-wrapper">

                            {service.servicelineNums &&
                            <div className="plan-section">
                                <h5>Numbers</h5>
                                {!isEmpty(service.servicelineNums) && service.servicelineNums.map((num) =>
                                    <div className="package-wrapper">
                                        <div className="package-option">
                                            <span className="po-title">{num.number}</span>
                                            <span className="po-price">{num.type}</span>
                                            {service.endDate &&
                                            <div className="po-date">
                                                {num.startDate && moment(num.startDate).format("MM/DD/YYYY")}
                                                {" - "}
                                                {num.endDate ? moment(num.endDate).format("MM/DD/YYYY") : "Now"}
                                            </div>
                                            }
                                        </div>
                                    </div>
                                )}
                            </div>
                            }

                            {!isEmpty(recurringFeaturePackageFees) || !isEmpty(recurringFeatureFees) ? (
                                <div className="plan-section">

                                    <h5>Recurring</h5>

                                    {!isEmpty(recurringFeaturePackageFees) &&
                                        recurringFeaturePackageFees.map((feature, index) => (
                                            <div className="package-wrapper" key={index}>
                                                <div className="package-option">
                                                    <span className="po-title">
                                                        {feature.description && `${feature.description.replace(/\s<br><(\w+)\b[^<>]*>[\s\S]*?<\/\1>/g, '')}:`}{' '}
                                                        {feature.featurePackageOptions.map((featureAndPackageOptions) => {
                                                            return featureAndPackageOptions.description
                                                        })}
                                                    </span>
                                                    <span className="po-price">
                                                        ${feature.featurePackageOptions[0].recurringMonthlyPrice
                                                            .toFixed(2)
                                                            .replace(/\d(?=(\d{3})+\.)/g, '$&,')}/mo
                                                    </span>
                                                </div>
                                            </div>
                                        )
                                    )}

                                    {!isEmpty(recurringFeatureFees) &&
                                        recurringFeatureFees.map((feature, index) => (
                                            <div className="package-wrapper" key={index}>
                                                <div className="package-option">
                                                    <span className="po-title">
                                                        {feature.featureDescription && feature.featureDescription}
                                                    </span>
                                                    <span className="po-price">
                                                        {feature.qty > 1
                                                            ? <>Qty: {feature.qty}<span className="text-muted"> | </span></>
                                                            : ""}
                                                        ${feature.recurringMonthlyPrice
                                                            .toFixed(2)
                                                            .replace(/\d(?=(\d{3})+\.)/g, '$&,')}/mo
                                                    </span>
                                                    {service.endDate &&
                                                    <div className="po-date">
                                                        {feature.startDate && moment(feature.startDate).format("MM/DD/YYYY")}
                                                        {" - "}
                                                        {feature.endDate ? moment(feature.endDate).format("MM/DD/YYYY") : "Now"}
                                                    </div>
                                                    }
                                                </div>
                                            </div>
                                        )
                                    )}

                                </div>
                            ): null}

                            {!isEmpty(oneTimeFeaturePackageFees) || !isEmpty(oneTimeFeatureFees) ? (
                            <div className="plan-section">

                                <h5>Onetime</h5>

                                {!isEmpty(oneTimeFeaturePackageFees) &&
                                    oneTimeFeaturePackageFees .map((feature, index) => (
                                        <div className="package-wrapper" key={index}>
                                            <div className="package-option package-option-mandatory">
                                                <span className="po-title">
                                                    {feature.description && `${feature.description}:`}{' '}
                                                    {feature.note}
                                                </span>
                                                <span className="po-price">
                                                    ${feature.featurePackageOptions[0].oneTimePrice
                                                        .toFixed(2)
                                                        .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                                                </span>
                                            </div>
                                        </div>
                                    )
                                )}

                                {!isEmpty(oneTimeFeatureFees) &&
                                    oneTimeFeatureFees.map((feature, index) => (
                                        <div className="package-wrapper" key={index}>
                                            <div className="package-option package-option-mandatory">
                                                <span className="po-title">
                                                    {feature.featureDescription && feature.featureDescription}
                                                </span>
                                                <span className="po-price">
                                                    {feature.qty > 1
                                                        ? <>Qty: {feature.qty}<span className="text-muted"> | </span></>
                                                        : ""}
                                                    ${feature.oneTimePrice
                                                        .toFixed(2)
                                                        .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                                                </span>
                                            </div>
                                            {service.endDate &&
                                            <div className="po-date">
                                                {feature.startDate && moment(feature.startDate).format("MM/DD/YYYY")}
                                                {" - "}
                                                {feature.endDate ? moment(feature.endDate).format("MM/DD/YYYY") : "Now"}
                                            </div>
                                            }
                                        </div>
                                    )
                                )}

                            </div>
                            ) : null }

                            {!isEmpty(service.serviceDevices) &&
                            <div className="plan-section">
                                <h5>Equipment</h5>
                                {service.serviceDevices.map((device) =>
                                    <div className="package-option">
                                        <span className="po-title">{device.deviceProfile}</span>
                                        {service.endDate &&
                                        <div className="po-date">
                                            {device.startDate && moment(device.startDate).format("MM/DD/YYYY")}
                                            {" - "}
                                            {device.endDate ? moment(device.endDate).format("MM/DD/YYYY") : "Now"}
                                        </div>
                                        }
                                        <div className="container-list-bubble">
                                            {device.apiServiceDeviceNums && device.apiServiceDeviceNums.map((num) =>
                                                <dl>
                                                    <dt>{num.type}</dt>
                                                    <dd>{num.number}</dd>
                                                </dl>
                                            )}
                                        </div>
                                    </div>
                                )}

                            </div>
                            }

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ServiceSummary;
