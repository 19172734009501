import React, { Component } from 'react';
import OptPackage from './Options/OptionPackage';

class OptionalPackages extends Component {
	render() {
		const { features, lineIndex, planFeature, handleOptionalPackagesCheckbox } = this.props;

		return (
			<div className="plan-section">
				<h5>Optional Packages</h5>
				{planFeature.map((featurePackage) => (
					<div className="package-wrapper" key={featurePackage.featurePackageId}>
						<div className="row">
							<div className="col col-md-5">
								<h6>
									<a
										onClick={() =>
											handleOptionalPackagesCheckbox(featurePackage.featurePackageId, lineIndex, {
												featurePackageId: featurePackage.featurePackageId,
												featurePackageDescription: featurePackage.description,
												featurePackageNote: featurePackage.note,
												action: 'ENABLE',
												...featurePackage.featurePackageOptions[0]
											})}
										className={
											features.findIndex(
												(x) => x.featurePackageId === featurePackage.featurePackageId
											) !== -1 ? (
												'btn-toggle-package package-enabled'
											) : (
												'btn-toggle-package'
											)
										}
									>
										{features.findIndex(
											(x) => x.featurePackageId === featurePackage.featurePackageId
										) !== -1 ? (
											<i className="fas fa-toggle-on" />
										) : (
											<i className="fas fa-toggle-off" />
										)}
										{featurePackage.description}
									</a>
								</h6>
								{featurePackage.note}
							</div>
							<div className="col col-md-7">
								{featurePackage.featurePackageOptions.map((packageOption) => (
									<OptPackage
										packageOption={packageOption}
										featurePackage={featurePackage}
										key={packageOption.featurePackageOptionId}
										{...this.props}
									/>
								))}
							</div>
						</div>
					</div>
				))}
			</div>
		);
	}
}

export default OptionalPackages;
