import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { hideModal } from '../../actions/modal.actions';
import { getModalData } from '../../selectors/index';

class GenericCloseModal extends Component {

	closeModal = () => {
		this.props.hideModal();
	};

	goBack = () => {
		const {history, modalProps, userSummary} = this.props;

		this.props.hideModal();

		// Go back to the explicitly provided URL
		if (modalProps.returnUrl) {
			history.push(modalProps.returnUrl);
		}

		// ... or go back to Account Details
		else if (history.location.state && history.location.state.returnUrl) {

			history.push(history.location.state.returnUrl, {
				activeTab:
					history.location.state.orderId ? 'ordersAndQuotes' :
						history.location.state.troubleTicketId ? 'troubleTickets'
							: '',
				orderId: history.location.state.orderId,
				serviceOrderId: history.location.state.serviceOrderId,
				troubleTicketId: history.location.state.troubleTicketId,
			});
		}

		// ... or go back to Dashboard
		else {
			let activeTile = history.location.state && history.location.state.activeTile;
			history.push('/home' + (activeTile ? ("#"+activeTile.tile) : ""), {activeTile});
		}
	};

	render() {
		const { modalProps } = this.props;
		
		return (
			<Fragment>
				<div className="modal" style={{ display: 'block' }} tabIndex="-1" role="dialog">
					<div className="modal-dialog" role="document">
						<div className="modal-content">

							<div className="modal-header">
								<h5 className="modal-title">{modalProps.title}</h5>
								<button type="button" className="close" onClick={this.closeModal}>
									<span aria-hidden="true">&times;</span>
								</button>
							</div>

							<div className="modal-body">
								<p className="lead">{modalProps.text}</p>
							</div>

							<div className="modal-footer">
								<button onClick={this.closeModal} className="btn">
									No
								</button>
								<button onClick={this.goBack} className="btn btn-primary">
									Yes
								</button>
							</div>

						</div>
					</div>
				</div>
				<div className="modal-backdrop show" tabIndex="1" />
			</Fragment>
		);
	}
}

GenericCloseModal.propTypes = {
	title: PropTypes.string.isRequired,
	text: PropTypes.string.isRequired
};

const mapStateToProps = (state) => {

	const modalProps = getModalData(state).modalProps;

	return {
		modalProps
	};
};

const mapDispatchToProps = {
	hideModal
};

export default connect(mapStateToProps, mapDispatchToProps)(GenericCloseModal);
