const PARTNER_KEY = 'viewas.partner';
const LOCATION_KEY = 'viewas.location';

const getJsonItem = (key) => {
	return localStorage.getItem(key) ? JSON.parse(localStorage.getItem(key)) : null;
}

const setJsonItem = (key, value) => {
	localStorage.setItem(key, JSON.stringify(value));
}

export const clearAll = () => {
	clearPartner();
	clearLocation();
}

export const getPartner = () => {
	return getJsonItem(PARTNER_KEY);
};

export const setPartner = (partner) => {
	setJsonItem(PARTNER_KEY, partner);
};

export const clearPartner = () => {
	localStorage.removeItem(PARTNER_KEY);
};

export const getLocation = () => {
	return getJsonItem(LOCATION_KEY);
};

export const setLocation = (originalLocation) => {
	setJsonItem(LOCATION_KEY, originalLocation);
};

export const clearLocation = () => {
	localStorage.removeItem(LOCATION_KEY);
};