import {
	GET_TROUBLE_TICKETS,
	GET_TROUBLE_TICKETS_SERVICE_ICONS,
	GET_TROUBLE_TICKETS_SERVICE_LINES,
	GET_TROUBLE_TICKETS_REPORTED_ISSUES,
	CREATE_TROUBLE_TICKET,
	GET_TROUBLE_TICKET_NOTES,
	CREATE_TROUBLE_TICKET_NOTE,
	GET_TROUBLE_TICKET_ACCOUNT_INFO,
	GET_TROUBLE_TICKET_USER_GROUPS,
	GET_TROUBLE_TICKET_TECHNICIANS,
	GET_TROUBLE_TICKET_TIMESLOTS
} from '../../actions/accountDetailsActions/troubleTickets.actions';

let initialState = {
	troubleTickets: [],
	troubleTicketsServiceIcons: [],
	troubleTicketsServiceLines: [],
	troubleTicketsReportedIssues: [],
	troubleTicketsCreateTicket: [],
	troubleTicketsGetTicketNotes: [],
	troubleTicketsCreateTicketNote: [],
	troubleTicketsAccountInfo: [],
	troubleTicketsUserGroups: [],
	techniciansByUserGroup: [],
	timeslots: []
};

export const troubleTickets = (state = initialState, action) => {
	switch (action.type) {
		case GET_TROUBLE_TICKETS.SUCCESS:
			return {
				...state,
				troubleTickets: action.payload
			}
		case GET_TROUBLE_TICKETS.EMPTY:
			return {
				...state,
				troubleTickets: []
			}
		case GET_TROUBLE_TICKETS.FAILURE:
			return {
				...state,
				troubleTickets: []
			}
		case GET_TROUBLE_TICKETS_SERVICE_ICONS.SUCCESS:
			return {
				...state,
				troubleTicketsServiceIcons: action.payload
			}
		case GET_TROUBLE_TICKETS_SERVICE_ICONS.EMPTY:
			return {
				...state,
				troubleTicketsServiceIcons: []
			}
		case GET_TROUBLE_TICKETS_SERVICE_LINES.SUCCESS:
			return {
				...state,
				troubleTicketsServiceLines: action.payload
			}
		case GET_TROUBLE_TICKETS_SERVICE_LINES.EMPTY:
			return {
				...state,
				troubleTicketsServiceLines: []
			}
		case GET_TROUBLE_TICKETS_REPORTED_ISSUES.SUCCESS:
			return {
				...state,
				troubleTicketsReportedIssues: action.payload
			}
		case GET_TROUBLE_TICKETS_REPORTED_ISSUES.EMPTY:
			return {
				...state,
				troubleTicketsReportedIssues: []
			}
		case CREATE_TROUBLE_TICKET.SUCCESS:
			return {
				...state,
				troubleTicketsCreateTicket: []
			}
		case GET_TROUBLE_TICKET_NOTES.SUCCESS:
			return {
				...state,
				troubleTicketsGetTicketNotes: action.payload
			}
		case GET_TROUBLE_TICKET_NOTES.EMPTY:
			return {
				...state,
				troubleTicketsGetTicketNotes: []
			}
		case CREATE_TROUBLE_TICKET_NOTE.SUCCESS:
			return {
				...state,
				troubleTicketsCreateTicketNote: []
			}
		case GET_TROUBLE_TICKET_ACCOUNT_INFO.SUCCESS:
			return {
				...state,
				troubleTicketsAccountInfo: action.payload
			}
		case GET_TROUBLE_TICKET_USER_GROUPS.SUCCESS:
			return {
				...state,
				troubleTicketsUserGroups: action.payload
			}
		case GET_TROUBLE_TICKET_TECHNICIANS.SUCCESS:
			return {
				...state,
				techniciansByUserGroup: action.payload
			}
		case GET_TROUBLE_TICKET_TIMESLOTS.SUCCESS:
			return {
				...state,
				timeslots: action.payload
			}
		default:
			return state;
	}
};
