import { createStore, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
// import reducers from './reducers';
import { rootReducer } from './reducers';
import { checkAuthStatus } from './actions/auth.actions';

const initialState = {};

const enhancers = [ applyMiddleware(thunk) ];

if (process.env.NODE_ENV === 'development' && typeof window !== 'undefined') {
	const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;
	if (typeof devToolsExtension === 'function') {
		enhancers.push(devToolsExtension());
	}
}

const store = createStore(rootReducer, initialState, compose(...enhancers));

store.dispatch(checkAuthStatus());
export default store;
