import {
    SET_ORDERS,
	SET_QUOTES
} from '../../actions/accountDetailsActions/ordersAndQuotes.actions';

let initialState = {
    orders: [],
	quotes: []
};

export const ordersAndQuotes = (state = initialState, action) => {
	switch (action.type) {
		case SET_ORDERS.SUCCESS:
			return {
				...state,
				orders: action.payload
			}
		case SET_ORDERS.EMPTY: {
			return {
				...state,
				orders: []
			}
        }
		case SET_QUOTES.SUCCESS: {
			return {
				...state,
				quotes: action.payload
			}
		}
		case SET_QUOTES.EMPTY: {
			return{
				...state,
				quotes: []
			}
		}
		default:
			return state;
	}
};
