import React, {Component, Fragment, useState} from 'react';
import { connect } from 'react-redux';

import isEmpty from "../../../utils/helpers";

import { hideModal } from '../../../actions/modal.actions';
import { getModalData } from '../../../selectors';
import DatePicker from "react-datepicker";
import { patchServiceOrderTargetDateTime } from "../../../actions/admin.actions";
import moment from "moment";
import Loader from "../../../components/Loader";

class TargetDateTimeModal extends Component {

    state = {
        alertMessage: '',
        alertClass: '',
        targetDateTime: this.props.modalProps.targetDateTime? Date.parse(this.props.modalProps.targetDateTime) : null,
        isSubmittable: false,
        isLoading: false
    };

    isValidTargetDateTime = (newDateTime) => {
        return newDateTime === null ? true : moment(newDateTime).isAfter(moment());
    }

    clearAlertMessage = () => {
        this.setState({alertMessage: ''})
    }

    handleDateTimeChange = (newDateTime) => {
        if(newDateTime === null || this.isValidTargetDateTime(newDateTime)){
            this.setState({targetDateTime: newDateTime, alertMessage: '', isSubmittable: true});
        }
        else
        {
            this.setState({
                targetDateTime: this.props.modalProps.targetDateTime? Date.parse(this.props.modalProps.targetDateTime) : null,
                alertMessage: 'Invalid date or time selected',
                alertClass: 'alert-danger',
                isSubmittable: false})
        }
    }

    sendPatchRequest = () => {

        this.setState({isLoading: true});

        const serviceOrderId = this.props.modalProps.serviceOrderId;
        const newTargetDateTime = this.state.targetDateTime;

        this.props.patchServiceOrderTargetDateTime(serviceOrderId, newTargetDateTime).then((response) => {

            this.setState({isLoading: false});

            if (response.data.success) {
                this.setState({alertClass: 'alert-success', alertMessage: response.data.message});
            }

            if (!response.data) {
                this.setState({alertClass: 'alert-danger', alertMessage: 'Unknown error. Please try again later'});
                return;
            }

            if (!response.data.success) {

                if (response.data.error.code === "A00146") {
                    this.setState({alertsClass: 'alert-danger', alertMessage: "Invalid date time format"});
                    return;
                }

                if (response.data.error) {
                    this.setState({alertsClass: 'alert-danger', alertMessage: response.data.error.message});
                    return;
                }
            }

            this.props.modalProps.parentCallback();

            setTimeout(() => this.props.hideModal(), 1000);
        });
    };

    render() {
        const { alertMessage, isSubmittable, isLoading } = this.state;
        const alertClass = `alert alert-inline ${this.state.alertClass} alert-dismissible cmv-alert fade show`;

       return (
            <Fragment>
                <div className="modal" style={{display: 'block'}} tabIndex="-1" role="dialog">
                    <div className="modal-dialog">
                        <div className="modal-content" style={{minHeight: '450px'}}>

                            <div className="modal-header">
                                <h5 className="modal-title">
                                   Update Service Order
                                </h5>
                                <button onClick={this.props.hideModal} type="button" className="close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>

                            <div className="modal-body form-horizontal">

                                <div className="form-group">
                                    <div className="customDatePickerWidth">
                                        <DatePicker
                                            autoFocus
                                            shouldCloseOnSelect={false}
                                            popperPlacement={'auto'}
                                            selected={this.state.targetDateTime}
                                            onChange={this.handleDateTimeChange}
                                            isClearable
                                            showTimeSelect
                                            minDate={moment().toDate()}
                                            dateFormat="MM/dd/yyyy @ hh:mm aa"
                                            placeholderText="Select target date and time"
                                        />
                                    </div>
                                </div>

                                {!isEmpty(alertMessage) && (
                                    <div className={alertClass} role="alert">
                                        <p className="mb-0">{alertMessage}</p>
                                        <button onClick={this.clearAlertMessage}
                                                type="button" className="close"
                                                data-dismiss="alert"
                                                aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                )}

                                {isLoading &&
                                    <Loader />
                                }

                            </div>

                            <div className="modal-footer">
                                <button onClick={this.props.hideModal} className="btn" type="button">
                                    Cancel
                                </button>
                                <button disabled={!isSubmittable}
                                    onClick={this.sendPatchRequest}
                                    type="submit" className="btn btn-primary">
                                    Update Target Date
                                </button>
                            </div>

                        </div>
                    </div>
                    )}
                </div>
                <div className="modal-backdrop show" tabIndex="1"/>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    const modalProps = getModalData(state).modalProps;

    return {
        modalProps
    };
};

const mapDispatchToProps = {
    hideModal,
    patchServiceOrderTargetDateTime
};

export default connect(mapStateToProps, mapDispatchToProps)(TargetDateTimeModal);