import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import isEmpty from "../../../../utils/helpers";

const CamModuleMenu = ({activeMenu, changeActiveMenu, permissions}) => {

	return (
        <li
        onClick={() => changeActiveMenu('CAM')}
        className={activeMenu === 'CAM' ? 'sidebar-dropdown active' : 'sidebar-dropdown'}
    >
        <a>
            <i className="fa fa-users" />
            <span>Customer Care</span>
        </a>
        <div
            className="sidebar-submenu"
            style={activeMenu === 'CAM' ? { display: 'block' } : { display: 'none' }}
        >
            <ul>
                {/*{permissions.includes("CAM_ACC_DETAIL") &&  <li className="sidebar-nav-link">*/}
                {/*    <Link to={{ pathname: '/account-search' }}>Account Search</Link>*/}
                {/*</li>}*/}
                {!isEmpty(permissions) && permissions.includes("CAM_ACC_ADD") && <li className="sidebar-nav-link">
                    <Link to={{ pathname: '/create-account' }}>New Account</Link>
                </li>}
                <li className="sidebar-nav-link">
                    <Link to={{ pathname: '/service-request' }}>New Service Request</Link>
                </li>
            </ul>
        </div>
    </li>
	);
};

CamModuleMenu.propTypes = {
    activeMenu: PropTypes.string.isRequired,
    changeActiveMenu: PropTypes.func.isRequired,
    permissions: PropTypes.array.isRequired,
};

export default CamModuleMenu;
