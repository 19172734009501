import React from 'react';
import PropTypes from 'prop-types';

const AlertBox = ({ handleShowAlert, message }) => {
	return (
		<div className="container">
			<div className="alert alert-danger alert-dismissible cmv-alert fade show" role="alert">
				<h5 className="alert-heading">Account notice</h5>
				<hr />
				<p className="mb-0">{message}</p>
				<button
					onClick={handleShowAlert}
					type="button"
					className="close"
					data-dismiss="alert"
					aria-label="Close"
				>
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
		</div>
	);
};

AlertBox.propTypes = {
	handleShowAlert: PropTypes.func.isRequired,
	message: PropTypes.string.isRequired
};

export default AlertBox;
