import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {get} from 'lodash';
import {Form, Formik} from 'formik';
import {createLoadingSelector, getEditServiceRequest} from '../../../selectors';

import {connect} from 'react-redux';
import {
    patchServiceOrder,
    emptyMapLinkLocation,
    emptyNewOrder,
    emptyPlanDetails,
    getMapLinkLocation,
    getPlansAndBundles,
    getPlansDetails,
    startServiceOrder,
    setPlans
} from '../../../actions/accountDetailsActions/editServiceRequest.actions';
import {hideAccountDetailsWizard} from '../../../actions/accountDetailsActions/accountDetails.actions';
import {hideModal, showModal} from '../../../actions/modal.actions';

import {applyOnEnum, isEmpty} from '../../../utils/helpers';
import {validationSchema} from './validation';

import WizardButtons from './WizardButtons';
import NavTabs from './NavTabs';
import {Confirmation, Products, Services} from './Tabs';
import Loader from "../../../components/Loader";
import axiosInstance from "../../../utils/axios-instance";

class UpdateRequest extends Component {
    state = {
        initialValues: {
            accountId: this.props.accountInfo.id,
            createdBy: this.props.userSummary.id,
            name: this.props.accountDetailsWizardData.wizardData.order.name,
            note: this.props.accountDetailsWizardData.wizardData.order.note,
            address: '',
            plans: [...this.props.accountDetailsWizardData.wizardData.plans],
            plan: this.props.accountDetailsWizardData.wizardData.plan,
            source: 'CAMVIO-WEB',
            serviceOrderId: this.props.accountDetailsWizardData.wizardData.serviceOrder.id,
            type: this.props.accountDetailsWizardData.wizardData.serviceOrder.type,
            applyOn: this.props.accountDetailsWizardData.wizardData.serviceOrder.applyOn,
            applyDate: this.props.accountDetailsWizardData.wizardData.serviceOrder.applyDate
        },
        activeTab: 0,
        addressType: 'manualAddress',
        selectedAddress: this.props.accountDetailsWizardData.wizardData.serviceOrder.serviceAddress,
        selectedUnit: '',
        isSummaryFullWith: false,
        isSummaryCollapsed: false,
        collapsedMenuItem: 'planBundles',
        selectedItem: '',
        selectedType: '',
        isPlanAndBundlesExecuted: false,
        saveOrderInProgress: false,
        serviceOrder: this.props.accountDetailsWizardData.wizardData.serviceOrder,
        order: this.props.accountDetailsWizardData.wizardData.order
    };

    componentDidMount() {
        const {changeNavBarType} = this.props;

        // Change navbar type and color
        document.title = 'Edit Service Order - camvio.cloud';
        changeNavBarType('lock', 'newOrder', 'Edit Service Order');

        this.populateServiceAndLines();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.newOrderInfo !== this.props.newOrderInfo && !isEmpty(this.props.newOrderInfo)) {
            this.setState((prevState) => ({
                activeTab: prevState.activeTab + 1
            }));
            this.props.hideModal();
        }

        //if user click on prev button and he is on active tab === 2 - clear redux plan details

        // if(prevState.activeTab !== this.state.activeTab && prevState.activeTab === 2){
        // 	this.props.emptyPlanDetails();
        // }
    }

    componentWillUnmount() {
        const {changeNavBarType, emptyNewOrder, hideAccountDetailsWizard, documentTitle, navbarTitle} = this.props;

        // Change navbar type and color
        document.title = documentTitle;
        changeNavBarType('account', 'accountDetails', navbarTitle);

        emptyNewOrder();
        hideAccountDetailsWizard();
    }

    renderTabContent = (formProps) => {
        const {
            contactAddresses,
            planBundles,
            plans,
            plan,
            accountInfo,
            currentLocation,
            plansDetails,
            newOrderInfo,
            startServiceOrder,
            isOrderStartedData,
            tasksCountData,
            validatedAddress,
            loaderPlansAndBundles,
            loaderMapLinkLocation
        } = this.props;
        const {
            addressType,
            activeTab,
            isSummaryFullWith,
            isSummaryCollapsed,
            selectedAddress,
            selectedUnit
        } = this.state;

        let tabContent;
        switch (activeTab) {
            case 0:
                //check editServiceRequest.reducer got plan from there
                tabContent = (
                    <Services
                        plansDetails={plansDetails}
                        currentLocation={currentLocation}
                        accountInfo={accountInfo}
                        getPlansDetails={this.props.getPlansDetails}
                        isSummaryFullWith={isSummaryFullWith}
                        isSummaryCollapsed={isSummaryCollapsed}
                        handleFullWithSummary={this.handleFullWithSummary}
                        handleSummaryCollapsed={this.handleSummaryCollapsed}
                        {...formProps}
                    />
                );
                break;
            case 1:
                tabContent = (
                    <Products
                        plansDetails={plansDetails}
                        accountInfo={accountInfo}
                        isSummaryFullWith={isSummaryFullWith}
                        isSummaryCollapsed={isSummaryCollapsed}
                        handleFullWithSummary={this.handleFullWithSummary}
                        handleSummaryCollapsed={this.handleSummaryCollapsed}
                        validatedAddress={validatedAddress}
                        {...formProps}
                    />
                );
                break;
            case 2:
                var orderType = newOrderInfo.type;

                if (this.props.validatedAddress) {
                    switch (this.props.validatedAddress.allowedServiceOrderType) {
                        case 'NEW_SURVEY':
                            orderType = 'survey';
                            break;
                        case 'NEW_SIGNUP':
                            orderType = 'signup';
                            break;
                    }
                }

                tabContent = (
                    <Confirmation
                        newOrderInfo={{...newOrderInfo, type: orderType}}
                        showNewOrderWizardHandler={this.props.showNewOrderWizardHandler}
                        resetWizard={this.resetWizard}
                        startServiceOrder={startServiceOrder}
                        isOrderStartedData={isOrderStartedData}
                        tasksCountData={tasksCountData}
                        {...formProps}
                    />
                );
                break;
            default:
                tabContent = <Loader/>;
                break;
        }

        return tabContent;
    };

    setCollapsedMenuItem = (menuItem) => {
        this.setState({
            collapsedMenuItem: this.state.collapsedMenuItem === menuItem ? '' : menuItem
        });
    };

    selectPlansItems = (item, type) => {
        if (this.state.isPlanAndBundlesExecuted === true) {
            this.props.emptyPlanDetails();
        }
        this.setState((prevState) => ({
            selectedItem: this.state.selectedItem === item.id ? '' : item.id,
            selectedType: this.state.selectedItem === item.id ? '' : type,
            isPlanAndBundlesExecuted: prevState.isPlanAndBundlesExecuted === true && false
        }));
    };

    populateServiceAndLines = () => {
        const {currentLocation, accountInfo, getPlansDetails, setPlans} = this.props;
        const plans = [...this.props.accountDetailsWizardData.wizardData.plans];
        const serviceOrderId = this.props.accountDetailsWizardData.wizardData.serviceOrder.id;

        setPlans(plans).then(() => {
			plans.forEach((element) => {
				getPlansDetails(accountInfo.id, currentLocation.id, element.id, serviceOrderId);
			});
        });

        this.setState((prevState) => ({
            isPlanAndBundlesExecuted: true
        }));
    };

    // ADDRESSES TAB <== START ==>

    onChangeAddressType = (addressType) => {
        if (addressType !== this.state.addressType) {
            if (addressType === 'noAddress') {
            } else {
                this.props.emptyMapLinkLocation();
                this.setState({
                    addressType: addressType,
                    selectedAddress: '',
                    selectedUnit: ''
                });
            }
        }
    };

    // onAddressHandleChange = (address, setFieldValue) => {
    // 	if (address === 'error') {
    // 		setFieldValue('address', '');
    // 		// setFieldError('address', 'We currently do not offer any service at this address.')
    // 		// setFieldTouched('address', true)
    // 	} else {
    // 		const addressRequest = {
    // 			address: address
    // 		};

    // 		this.props.getMapLinkLocation(this.props.currentLocation.id, addressRequest);
    // 	}
    // };

    handleFullWithSummary = () => {
        this.setState((prevState) => ({
            isSummaryFullWith: !prevState.isSummaryFullWith
        }));
    };

    handleSummaryCollapsed = () => {
        this.setState((prevState) => ({
            isSummaryCollapsed: !prevState.isSummaryCollapsed
        }));
    };

    resetWizard = (resetForm, setTouched) => {
        this.props.emptyNewOrder();
        resetForm(this.state.initialValues);
        setTouched({});

        this.setState({
            activeTab: 0,
            addressType: 'manualAddress',
            selectedAddress: '',
            selectedUnit: '',
            isSummaryFullWith: false,
            isSummaryCollapsed: false,
            collapsedMenuItem: 'planBundles',
            selectedItem: '',
            selectedType: '',
            isPlanAndBundlesExecuted: false
        });
    };

    prevStep = () => {
        this.setState((prevState) => ({
            activeTab: prevState.activeTab - 1
        }));
    };

    next = (values, actions) => {
        const {activeTab} = this.state;
        if (activeTab === 0) {
            actions.setSubmitting(false);
            this.setState((prevState) => ({
                activeTab: prevState.activeTab + 1
            }));
        } else if (activeTab === 1) {
            this.props.patchServiceOrder(values);
            this.setState((prevState) => ({
                saveOrderInProgress: true
            }));
        }
    };

    // ADDRESSES <== END ==>

    render() {
        const {activeTab, saveOrderInProgress} = this.state;
        const {showNewOrderWizardHandler, showServiceUpdateRequestWizardHandler, accountStatus, validatedAddress, showModal, loaderMapLinkLocation, errors} = this.props;

        return (
            <div className="cmv-wizard">
                <div className="wizard-container">
                    <div className="container">
                        <NavTabs activeTab={activeTab}/>
                        <Formik
                            initialValues={this.state.initialValues}
                            validationSchema={validationSchema(activeTab)}
                            onSubmit={this.next}
                            render={(props) => (
                                <Form onSubmit={props.handleSubmit} className="cmv-form" autoComplete="off">
                                    <div className="tab-content wizard-content">{this.renderTabContent(props)}</div>
                                    <WizardButtons
                                        values={props.values}
                                        activeTab={activeTab}
                                        accountStatus={accountStatus}
                                        showNewOrderWizardHandler={showNewOrderWizardHandler}
                            //            showServiceUpdateRequestWizardHandler={showServiceUpdateRequestWizardHandler}
                                        validatedAddress={validatedAddress}
                                        showModal={showModal}
                                        saveOrderInProgress={saveOrderInProgress}
                                        loaderMapLinkLocation={loaderMapLinkLocation}
                                        prevStep={this.prevStep}
                                        errors={props.errors}
                                    />
                                </Form>
                            )}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

UpdateRequest.propTypes = {
    currentLocation: PropTypes.object,
    changeNavBarType: PropTypes.func,
    planBundles: PropTypes.array,
    plans: PropTypes.array
};
const getLoaderPlansAndBundles = createLoadingSelector(['PLANS_AND_BUNDLES']);
const getLoaderMapLinkLocation = createLoadingSelector(['MAP_LINK_LOCATION']);

const mapStateToProps = (state) => {
    const planBundles = getEditServiceRequest(state).planBundles,
        plans = getEditServiceRequest(state).plans,
        plan = getEditServiceRequest(state).plan,
        plansDetails = getEditServiceRequest(state).plansDetails,
        newOrderInfo = getEditServiceRequest(state).newOrderInfo,
        isOrderStartedData = getEditServiceRequest(state).isOrderStarted,
        tasksCountData = getEditServiceRequest(state).tasksCount,
        validatedAddress = getEditServiceRequest(state).validatedAddress,
        loaderPlansAndBundles = getLoaderPlansAndBundles(state),
        loaderMapLinkLocation = getLoaderMapLinkLocation(state);

    return {
        planBundles,
        plans,
        plan,
        plansDetails,
        newOrderInfo,
        isOrderStartedData,
        tasksCountData,
        validatedAddress,
        loaderPlansAndBundles,
        loaderMapLinkLocation
    };
};

const mapDispatchToProps = {
    patchServiceOrder,
    getPlansAndBundles,
    getPlansDetails,
    emptyNewOrder,
    emptyPlanDetails,
    showModal,
    hideModal,
    startServiceOrder,
    hideAccountDetailsWizard,
    getMapLinkLocation,
    emptyMapLinkLocation,
    setPlans
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateRequest);
