import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import Card from './Card';
import { isEmpty } from '../../utils/helpers';
import moment from 'moment';
import {connect} from "react-redux";
import Notes from "../../containers/AccountDetails/Panels/LeftPanel/Tabs/TroubleTickets/Notes";

class TroubleTicketInfoBox extends Component {

	state = {
		isNotesVisible: false,
	};

	componentDidMount() {
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
	}

	componentWillUnmount() {
	}

	showHideNotes = () => {
		this.setState((prevState) => ({
			isNotesVisible: !prevState.isNotesVisible,
		}));
	};

	render() {

		const {
			isNotesVisible,
		} = this.state;

		const {
			troubleTicketInfo,
			hideCardsContainer,
			hideCardsTaskDetails,
		} = this.props;

		if (isEmpty(troubleTicketInfo)) {
			return null;
        }

		return (!hideCardsContainer &&
			<div className="cmv-container-client cmv-container-client-modern">
				<div className="cmv-client-data-extended trouble-ticket-wrapper">
					<div className="container">

						<Card
							header="Reported Code"
							body={troubleTicketInfo.reportedCode ? troubleTicketInfo.reportedCode : ''}
							icon="fas fa-fw fa-hashtag"
						/>

						<Card
							header="Contact phone number"
							body={troubleTicketInfo.contactPhoneNumber ? troubleTicketInfo.contactPhoneNumber : ''}
							icon="fas fa-fw fa-hashtag"
						/>
						<Card
							header="Due date"
							body={troubleTicketInfo.troubleDueDate? moment(troubleTicketInfo.troubleDueDate).format('MM/DD/YYYY') : ''}
							icon="fas fa-fw fa-hashtag"
						/>

					</div>
					<div className="container">

						<div className="ticket-notes">

							<button
								className="btn btn-outline-secondary btn-block btn-order-summary-toggle"
								data-toggle="collapse"
								onClick={this.showHideNotes}
							>
								<i className="fas fa-list-ul" />&nbsp;Notes
							</button>

							{isNotesVisible &&
							<Notes troubleTicketId={troubleTicketInfo.id} />
							}

						</div>

					</div>
				</div>
			</div>
		);
	}
}

TroubleTicketInfoBox.propTypes = {
	troubleTicketInfo: PropTypes.object.isRequired,
	hideCardsContainer: PropTypes.bool,
	showStatusDropdown: PropTypes.bool,
	handleStatusDropDown: PropTypes.func,
	isStatusDropDownOpen: PropTypes.bool,
	showModal: PropTypes.func,
	accountAllowedStatusTransitions: PropTypes.array,
	accountAllowedStatusTransitionsLoading: PropTypes.bool,
};

const mapStateToProps = (state) => {
	
	return {
		
	};
};

const mapDispatchToProps = {
	
};

export default connect(mapStateToProps, mapDispatchToProps)(TroubleTicketInfoBox);
