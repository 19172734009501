import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser';
import IncludedPackage from './Options/IncludedPackage';
class IncludedPackages extends Component {
	render() {
		const { planFeature, accountInitialStatus } = this.props;

		return (
			<div className="plan-section">
				<h5>Included Packages</h5>
				{planFeature.map((featurePackage, index) => (
					<div className="package-wrapper" key={index}>
						<div className="row">
							<div className="col col-md-5">
								<h6>{ReactHtmlParser(featurePackage.featurePackageOptions[0].description)}</h6>
							</div>
							<div className="col col-md-7">
								<IncludedPackage featurePackage={featurePackage} accountInitialStatus={accountInitialStatus} />
							</div>
						</div>
					</div>
				))}
			</div>
		);
	}
}

export default IncludedPackages;
