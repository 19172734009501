import {
	SET_PENDING_ORDERS,
	SET_SERVICES,
	SET_ACCOUNT360_BADJES,
	SET_ACCOUNT360_SERVICE_ICONS
} from '../../actions/accountDetailsActions/account360.actions';

let initialState = {
	account360Badjes: {},
	services: [],
	pendingOrders: [],
	serviceIcons: []
};

export const account360 = (state = initialState, action) => {
	switch (action.type) {
		case SET_ACCOUNT360_BADJES.SUCCESS:
			return {
				...state,
				account360Badjes: action.payload
			}
		case SET_ACCOUNT360_BADJES.EMPTY:
			return {
				...state,
				account360Badjes: {}
			}
		case SET_SERVICES.SUCCESS:
			return {
				...state,
				services: action.payload
			}
		case SET_SERVICES.EMPTY:
			return {
				...state,
				services: []
			}
		case SET_PENDING_ORDERS.SUCCESS:
			return {
				...state,
				pendingOrders: action.payload
			}
		case SET_PENDING_ORDERS.EMPTY: {
			return {
				...state,
				pendingOrders: []
			}
		}
		case SET_ACCOUNT360_SERVICE_ICONS.SUCCESS: {
			return {
				...state,
				serviceIcons: action.payload
			}
		}
		case SET_ACCOUNT360_SERVICE_ICONS.EMPTY: {
			return {
				...state,
				serviceIcons: []
			}
		}
		default:
			return state;
	}
};
