export const applyOnEnum = Object.freeze({
	ORDER_COMPLETION : 'Order completion',
	START_OF_BILLCYCLE : 'Start of bill cycle',
	NEXT_BILLCYCLE : 'Next bill cycle',
	APPLY_ON_DATE : 'Select a date'
});

export const provisioningActionTypeEnum = Object.freeze({
	ACTIVATE: "Activate",
	SUSPEND: "Suspend",
	RESTORE: "Restore",
	DEACTIVATE: "Deactivate",
	QUERY: "Query",
	RELEASE: "Release",
	DELETE: "Delete",
	ADD: "Add",
	PORT_OUT: "Port Out",
	CHANGE: "Change Device",
	CHANGE_NUMBER: "Change Number",
	ADD_DEVICE: "Add Device",
	ADD_SERVICE: "Add Service",
	DEACTIVATE_DEVICE: "Deactivate Device",
	DEACTIVATE_SERVICE: "Deactivate Service",
});

export const provisioningStatusEnum = Object.freeze({
	NEW: "New",
	PROCESSING: "Processing",
	PROCESSED: "Processed",
	WARNING: "Warning",
	ERROR: "Error",
	ON_HOLD: "On Hold",
	CANCELED: "Canceled",
	DEPRECATED: "Deprecated",
});

// Helper function to create simple action
export const action = (type, payload = {}) => {
	return { type, ...payload };
};

export const verboseAction = (type, payload = {}) => {
	return {
		type: type,
		payload: { ...payload }
	};
};

export const setCurrentUser = (authResponse) => {
	let userLocalStorage = {...authResponse}
    localStorage.setItem("currentUser", JSON.stringify(userLocalStorage));
};

export const getToken = () => {
	return localStorage.getItem('currentUser') ? JSON.parse(localStorage.getItem('currentUser'))['token'] : '';
};

export const getRefreshToken = () => {
	return localStorage.getItem('currentUser') ? JSON.parse(localStorage.getItem('currentUser'))['refreshToken'] : '';
};

export const getLocation = () => {
	return localStorage.getItem('location') ? JSON.parse(localStorage.getItem('location')) : '';
};

const REQUEST = 'REQUEST';
const SUCCESS = 'SUCCESS';
const FAILURE = 'FAILURE';
const EMPTY = 'EMPTY';
const CLEAR = 'CLEAR'

export const createRequestTypes = (base) => {
	return [ REQUEST, SUCCESS, FAILURE, CLEAR, EMPTY ].reduce((acc, type) => {
		acc[type] = `${base}_${type}`;
		return acc;
	}, {});
};

export const actionCreator = {
	request: (type) => action(type.REQUEST),
	success: (type, payload) => action(type.SUCCESS, { payload }),
	failure: (type, error) => verboseAction(type.FAILURE, error),
	empty: (type) => action(type.EMPTY),
	clear: (type) => action(type.CLEAR)
};

export const transformFeature = (feature, featurePackageOption) => {
	return {
		featurePackageId: feature.featurePackageId,
		featurePackageDescription: feature.description,
		featurePackageName: feature.name,
		featurePackageNote: feature.note,
		...featurePackageOption
	};
}

export const generateId =  () => {
	// Math.random should be unique because of its seeding algorithm.
	// Convert it to base 36 (numbers + letters), and grab the first 9 characters
	// after the decimal.
	return (Date.now().toString(36) + Math.random().toString(36).substr(2, 5)).toUpperCase()
};

export const isEmpty = (value) =>
	value === undefined ||
	value === null ||
	(typeof value === 'object' && Object.keys(value).length === 0) ||
	(typeof value === 'string' && value.trim().length === 0);

export default isEmpty;



export const getErrorMessage = (error) => {
	let mockErrorMessage = {};
	if (error && error.response && error.response.data.error) {
		if (error.response.data.error.code) {
			if (window.errorCodes && window.errorCodes.hasOwnProperty(error.response.data.error.code)) {
				if (window.errorCodes[error.response.data.error.code] !== '') {
					mockErrorMessage.message = window.errorCodes[error.response.data.error.code];
				} else {
					mockErrorMessage.message = error.response.data.error.message
						? error.response.data.error.message
						: 'Something goes wrong';
				}
			} else {
				mockErrorMessage.message = error.response.data.error.message ? error.response.data.error.message : 'Something goes wrong';
			}
		}
	} else {
		mockErrorMessage.message = 'Something goes wrong';
	}

	return mockErrorMessage;
};

// export const getErrorMessage = (error, language) => {
// 	let mockErrorMessage = {};
// 	if (error.data.error) {
// 		if (error.data.error.code) {
// 			if (window.errorCodes[language].hasOwnProperty(error.data.error.code)) {
// 				if (window.errorCodes[language][error.data.error.code] !== '') {
// 					mockErrorMessage.message = window.errorCodes[language][error.data.error.code];
// 				} else {
// 					mockErrorMessage.message = error.data.error.message
// 						? error.data.error.message
// 						: 'Something goes wrong';
// 				}
// 			} else {
// 				mockErrorMessage.message = error.data.error.message ? error.data.error.message : 'Something goes wrong';
// 			}
// 		}
// 	} else {
// 		mockErrorMessage.message = 'Something goes wrong';
// 	}

// 	return mockErrorMessage;
// };