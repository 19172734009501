import React, { Component } from 'react';
import { parseDateTimeFormat } from '../utils';

class Quote extends Component {
	state = {
		isServiceCollapsed: false
	};

	componentDidMount() {
		// this.props.getOrders()
	}

	// handlerServiceCollapsed = () => {
	// 	this.setState((prevState) => ({
	// 		isServiceCollapsed: !prevState.isServiceCollapsed
	// 	}));
	// };

	render() {
		const { isServiceCollapsed } = this.state;
		const { quoteInfo } = this.props;

		return (
			<div class="order-wrapper quote-wrapper">
				<div class="order-header row">
					<div class="col-12 col-sm-4 col-md-2">
						<div class="order-source">
							<span class="badge badge-order-source">{quoteInfo.source}</span>
						</div>
						<div class="order-date">
							<small>
								<span class="d-block text-muted">
									<span class="nowrap">
										<i class="fas fa-hashtag" />
										<span class="font-weight-bold">{quoteInfo.id}</span>
									</span>
									<span class="nowrap">
										&nbsp;<i class="fas fa-user" />&nbsp;{quoteInfo.createdBy}
									</span>
								</span>
								<span class="d-block text-muted nowrap">
									<i class="far fa-clock" />&nbsp;Created:&nbsp;<span class="font-weight-bold">
										{parseDateTimeFormat(quoteInfo.createdAt)}
									</span>
								</span>
							</small>
						</div>
					</div>
					<div class="col-12 col-sm-8 col-md-5">
						<div class="order-title">
							{/* {quoteInfo.quotePlans.map((quotePlan) => (
								<p class="d-block">
									<i class={quotePlan.icon} />&nbsp;{quotePlan.description}
								</p>
							))} */}
							<p class="d-block">{quoteInfo.name}</p>

							<span class="text-muted">{quoteInfo.serviceAddress}</span>
						</div>
					</div>
					<div class="col-12 col-md-4">
						<div class="order-toolbar">
							<div class="btn-group btn-group-sm">
								<button
									class="btn btn-warning"
									data-toggle="modal"
									data-target="#cmv-modal-quote-convert"
								>
									<i class="far fa-play-circle" /> Execute
								</button>
								<button class="btn btn-outline-secondary">
									<i class="fas fa-edit" />
								</button>
								<div class="btn-group" role="group">
									<button
										class="btn btn-outline-secondary dropdown-toggle"
										type="button"
										id="dropdownMenuButton"
										data-toggle="dropdown"
										aria-haspopup="true"
										aria-expanded="false"
									>
										<i class="fas fa-share" />
									</button>
									<div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
										<button class="dropdown-item">
											E-Mail
										</button>
										<button class="dropdown-item">
											Print
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-12 col-md-1">
						<div class="order-controls">
							<button
								onClick={this.handlerServiceCollapsed}
								class="btn btn-circle btn-order-collapse-toggle"
							>
								<i
									class={
										isServiceCollapsed === true ? (
											'fas fa-angle-double-down fa-angle-double-down, fa-angle-double-up'
										) : (
											'fas fa-angle-double-down'
										)
									}
								/>
							</button>
						</div>
					</div>
				</div>
				{isServiceCollapsed && (
					<div class="order-body collapse show">
						<div class="order-body-content">
							<div class="order-service-order-wrapper">
								<div class="order-body">
									<div class="row">
										<div class="col-12">
											<h6>Notes</h6>
											<div class="mini-notes-wrapper">
												<ul class="mini-notes mini-notes-h-constrained">
													<li class="mini-note">
														<div class="mini-note-body">
															<p>
																Order entered execution phase after customer
																confirmation.
															</p>
															<div class="footer">
																<small class="text-muted">
																	<i class="far fa-clock" />&nbsp;2/1/2019&nbsp;<i class="fas fa-user" />&nbsp;Melody
																	Sunshine
																</small>
															</div>
														</div>
													</li>

													<li class="mini-note">
														<div class="mini-note-body">
															<p>
																Customer called and ask to come back a different time.
															</p>
															<div class="footer">
																<small class="text-muted">
																	<i class="far fa-clock" />&nbsp;11/1/2018&nbsp;<i class="fas fa-user" />&nbsp;John
																	Wayne
																</small>
															</div>
														</div>
													</li>

													<li class="mini-note mini-note-current-user">
														<div class="mini-note-body">
															<p>Technician rescheduled in 1 hour.</p>
															<div class="footer">
																<small class="text-muted">
																	<i class="far fa-clock" />&nbsp;11/1/2018&nbsp;<i class="fas fa-user" />&nbsp;superadmin
																</small>
															</div>
														</div>
													</li>

													<li class="mini-note mini-note-current-user">
														<div class="mini-note-body">
															<p>
																Technician rescheduled for another 30 minutes, client
																apologised.
															</p>
															<div class="footer">
																<small class="text-muted">
																	<i class="far fa-clock" />&nbsp;11/1/2018&nbsp;<i class="fas fa-user" />&nbsp;superadmin
																</small>
															</div>
														</div>
													</li>

													<li class="mini-note">
														<div class="mini-note-body">
															<p>
																The existing copper line did not work, I had to replace
																the entire line.
															</p>
															<div class="footer">
																<small class="text-muted">
																	<i class="far fa-clock" />&nbsp;2/1/2018&nbsp;<i class="fas fa-user" />&nbsp;Melody
																	Sunshine
																</small>
															</div>
														</div>
													</li>
												</ul>
												<div class="form-group mb-o">
													<div class="input-group input-group-sm">
														<input
															type="text"
															class="form-control"
															placeholder="Add Service Order Note"
														/>
														<div class="input-group-append">
															<button class="btn btn-outline-secondary" type="button">
																Add Note
															</button>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>

									<div class="order-summary">
										<div class="services-summary">
											<div class="services-summary-body">
												<div class="plan-wrapper">
													<h4>Family Wireless Plan</h4>

													<div class="serviceline-wrapper">
														<h5 class="clearfix">
															<span class="badge badge-line-type">Main</span>
															<span class="float-right">949-293-0090</span>
														</h5>

														<div class="plan-section">
															<h5>Recurring</h5>

															<div class="package-wrapper">
																<div class="package-option">
																	<span class="po-title">
																		Unlimited Talk &amp; Text
																	</span>
																	<span class="po-price">$30.00/mo</span>
																</div>
																<div class="package-option">
																	<span class="po-title">Free Hotspots</span>
																	<span class="po-price">$0.00/mo</span>
																</div>
															</div>

															<div class="package-wrapper">
																<div class="package-option">
																	<span class="po-title">Shared Data: 5GB</span>
																	<span class="po-price">$30.00/mo</span>
																</div>
															</div>
															<div class="package-wrapper">
																<div class="package-option">
																	<span class="po-title">Voicemail: Standard</span>
																	<span class="po-price">$0.00/mo</span>
																</div>
															</div>

															<p class="services-price-subtotal">
																Subtotal:&nbsp;<span class="font-weight-bold">$49.99</span>
															</p>
														</div>

														<div class="plan-section">
															<h5>Onetime</h5>
															<div class="package-option package-option-mandatory">
																<span class="po-title">Activation Fee</span>
																<span class="po-price">$30.00</span>
															</div>

															<p class="services-price-subtotal">
																Subtotal:&nbsp;<span class="font-weight-bold">$30.00</span>
															</p>
														</div>

														<div class="plan-section">
															<h5>Equipment</h5>
															<div class="package-option">
																<span class="po-title">iPhone XS</span>
																<span class="po-price">$499.95</span>
															</div>
															<div class="package-option">
																<span class="po-title">LTE SIM Card</span>
																<span class="po-price">$0.00</span>
															</div>

															<p class="services-price-subtotal">
																Subtotal:&nbsp;<span class="font-weight-bold">$499.95</span>
															</p>
														</div>
													</div>

													<div class="serviceline-wrapper">
														<h5 class="clearfix">
															<span class="badge badge-line-type">Required</span>
															<span class="float-right">949-293-0091</span>
														</h5>

														<div class="plan-section">
															<h5>Recurring</h5>

															<div class="package-wrapper">
																<div class="package-option">
																	<span class="po-title">
																		Unlimited Talk &amp; Text
																	</span>
																	<span class="po-price">$30.00/mo</span>
																</div>
																<div class="package-option">
																	<span class="po-title">Free Hotspots</span>
																	<span class="po-price">$0.00/mo</span>
																</div>
															</div>

															<div class="package-wrapper">
																<div class="package-option">
																	<span class="po-title">Shared Data: 5GB</span>
																	<span class="po-price">$30.00/mo</span>
																</div>
															</div>
															<div class="package-wrapper">
																<div class="package-option">
																	<span class="po-title">Voicemail: Standard</span>
																	<span class="po-price">$0.00/mo</span>
																</div>
															</div>

															<p class="services-price-subtotal">
																Subtotal:&nbsp;<span class="font-weight-bold">$49.99</span>
															</p>
														</div>

														<div class="plan-section">
															<h5>Onetime</h5>
															<div class="package-option package-option-mandatory">
																<span class="po-title">Activation Fee</span>
																<span class="po-price">$30.00</span>
															</div>

															<p class="services-price-subtotal">
																Subtotal:&nbsp;<span class="font-weight-bold">$30.00</span>
															</p>
														</div>

														<div class="plan-section">
															<h5>Equipment</h5>
															<div class="package-option">
																<span class="po-title">iPhone XS</span>
																<span class="po-price">$499.95</span>
															</div>
															<div class="package-option">
																<span class="po-title">LTE SIM Card</span>
																<span class="po-price">$0.00</span>
															</div>

															<p class="services-price-subtotal">
																Subtotal:&nbsp;<span class="font-weight-bold">$499.95</span>
															</p>
														</div>
													</div>

													<div class="serviceline-wrapper">
														<h5 class="clearfix">
															<span class="badge badge-line-type">Additional 1</span>
															<span class="float-right">949-293-0092</span>
														</h5>

														<div class="plan-section">
															<h5>Recurring</h5>

															<div class="package-wrapper">
																<div class="package-option">
																	<span class="po-title">
																		Unlimited Talk &amp; Text
																	</span>
																	<span class="po-price">$30.00/mo</span>
																</div>
																<div class="package-option">
																	<span class="po-title">Free Hotspots</span>
																	<span class="po-price">$0.00/mo</span>
																</div>
															</div>

															<div class="package-wrapper">
																<div class="package-option">
																	<span class="po-title">Shared Data: 5GB</span>
																	<span class="po-price">$30.00/mo</span>
																</div>
															</div>
															<div class="package-wrapper">
																<div class="package-option">
																	<span class="po-title">Voicemail: Standard</span>
																	<span class="po-price">$0.00/mo</span>
																</div>
															</div>

															<p class="services-price-subtotal">
																Subtotal:&nbsp;<span class="font-weight-bold">$49.99</span>
															</p>
														</div>

														<div class="plan-section">
															<h5>Onetime</h5>
															<div class="package-option package-option-mandatory">
																<span class="po-title">Activation Fee</span>
																<span class="po-price">$30.00</span>
															</div>

															<p class="services-price-subtotal">
																Subtotal:&nbsp;<span class="font-weight-bold">$30.00</span>
															</p>
														</div>

														<div class="plan-section">
															<h5>Equipment</h5>
															<div class="package-option">
																<span class="po-title">iPhone XS</span>
																<span class="po-price">$499.95</span>
															</div>
															<div class="package-option">
																<span class="po-title">LTE SIM Card</span>
																<span class="po-price">$0.00</span>
															</div>

															<p class="services-price-subtotal">
																Subtotal:&nbsp;<span class="font-weight-bold">$499.95</span>
															</p>
														</div>
													</div>
												</div>
											</div>

											<div class="services-summary-footer">
												<p>Grand Total</p>
												<p class="services-price-total">
													Recurring: <span class="service-price">$199.99/mo</span>
												</p>
												<p class="services-price-total">
													Onetime: <span class="service-price">$99.99</span>
												</p>
												<p class="services-price-total">
													Equipment: <span class="service-price">$99.99</span>
												</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				)}
			</div>
		);
	}
}

export default Quote;
