import React, { Component } from 'react';
import { connect } from 'react-redux';

import {destroyWizard, initializeWizard, nextStep, previousStep, registerStep, setStepBaseState} from './actions';
import {NAME} from "./reducer";
import {Step} from "./Step";
import NavTabs from "./NavTabs";

class Wizard extends Component {

    componentDidMount () {
        const { children, initialize, register, name } = this.props;
        const possibleSteps = React.Children.toArray(children).filter(step => step.type === Step);
        const firstStep = possibleSteps.length ? (possibleSteps[0].props || {}).name : null;

        initialize(name, firstStep);

        possibleSteps.forEach(
            ({ props = {} }) => register(name, {
                name: props.name,
                title: props.title,
                previous: props.previous,
                next: props.next,
                finish: !props.next,
                nextHint: props.nextHint,
            })
        )
    }

    componentWillUnmount() {
        const { destroy, name } = this.props;
        destroy(name);
    }

    handlePrevious = (stepState) => {
        const { previous, name } = this.props;
        previous(name, stepState);
    }

    handleNext = (stepState) => {
        const { next, name } = this.props;
        next(name, stepState);
    }

    getCurrentStep = (currentStepConfiguration, stack) => {
        const { children, name, setStepBaseState, steps } = this.props;
        const possibleSteps = React.Children.toArray(children);
        const currentStep = possibleSteps.find(ps => ps.props.name === currentStepConfiguration.name);

        if (React.isValidElement(currentStep)) {
            const currentStepStackIndex = stack.findIndex(st => st.name === currentStepConfiguration.name);
            const currentStackStep = stack[currentStepStackIndex];
            const hasPrevious = currentStepConfiguration.previous || currentStepStackIndex > 0;
            const hasNext = !!currentStepConfiguration.next;
            const timestamp = Date.now();

            return React.cloneElement(currentStep, {
                stack,
                steps,
                accumulatedValues: currentStackStep.values.accumulated,
                handlePrevious: hasPrevious ? this.handlePrevious : null,
                handleNext: hasNext ? this.handleNext : null,
                nextHint: currentStepConfiguration.nextHint,
                setStepState: (state) => setStepBaseState(name, currentStackStep.name, { state }, timestamp),
                setStepFormValues: (formValues) => setStepBaseState(name, currentStackStep.name, { formValues }, timestamp),
                stepState: currentStackStep.state,
                stepFormValues: currentStackStep.formValues
            })
        }

        return null;
    }

    render () {
        const { currentStepConfiguration, stack = [], steps = {} } = this.props;

        return (
            <div className="cmv-wizard">
                <div className="wizard-container">

                    {/* Navigation */}
                    {currentStepConfiguration ? <NavTabs currentStepName={currentStepConfiguration.name} stack={stack} steps={steps} /> : null}

                    {/* Step */}
                    {currentStepConfiguration ? this.getCurrentStep(currentStepConfiguration, stack) : null}

                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    const reducerKey = ownProps.reducerKey || NAME;
    if (!(reducerKey in state)) {
        throw new Error(
            `${reducerKey} was not found in state but is required by Wizard.
            Please check your root reducer to ensure it has been setup correctly.`)
    }

    const wizardsState = state[reducerKey];
    const name = ownProps.name;
    const { currentStep, steps = {}, stack = [] } = wizardsState[name] || {};
    const currentStepConfiguration = steps[currentStep];

    return {
        name,
        currentStepConfiguration,
        stack,
        steps,
    }
}

const mapDispatchToProps = {
    initialize: initializeWizard,
    register: registerStep,
    previous: previousStep,
    next: nextStep,
    destroy: destroyWizard,
    setStepBaseState,
}

export default connect(mapStateToProps, mapDispatchToProps)(Wizard);