import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import LocationSearchInput from './LocationSearchInput';
import {generateAddressNew, generateFormatedAddress} from '../../../../../utils/generateAddresses';
import Iframe from 'react-iframe';
import isEmpty from '../../../../../utils/helpers';
import LoaderPulse from '../../../../../components/Loader/LoaderPulse';
import ManualAddressInput from "./ManualAddressInput";

class Addresses extends Component {
	render() {
		const {
			contactAddresses,
			onAddressHandleChange,
			addressType,
			onChangeAddressType,
			validatedAddress,
			selectedAddress,
			selectedUnit,
			loaderMapLinkLocation,
			countryStatesData,
			canEnterManualAddress
		} = this.props;

		let formatedAddresses = [];
		const stateOptions = [];
		let defaultState = "";
		if (!isEmpty(countryStatesData)) {
			countryStatesData.map((states) => {
				if (states.default) {
					defaultState = states;
				}
				stateOptions.push({label: states.name, value: states.id, abbreviation: states.abbreviation, country: states.countryCode});
			})
		}

		if (contactAddresses) {
			formatedAddresses = contactAddresses.map((address) => {
				return {
					value: generateFormatedAddress(address),
					label: `Use ${address.typeName} address - ${generateAddressNew(address)}`
				};
			});
		}

		return (
			<Fragment>
				<div className="form-check checkbox-slider checkbox-slider--b-flat">
					<label>
						<input
							type="checkbox"
							name="validateAddress"
							checked={addressType === 'validateAddress'}
							onChange={() => onChangeAddressType('validateAddress', this.props.setFieldValue)}
						/>
						<span>Validate Address</span>
					</label>
				</div>
				{addressType === 'validateAddress' && (
					<LocationSearchInput
						selectedAddress={selectedAddress}
						selectedUnit={selectedUnit}
						onAddressHandleChange={onAddressHandleChange}
						{...this.props}
					/>
				)}

				<div className="form-check checkbox-slider checkbox-slider--b-flat">
					<label>
						<input
							type="checkbox"
							name="noAddress"
							disabled={true}
							checked={addressType === 'noAddress'}
							onChange={() => onChangeAddressType('noAddress', this.props.setFieldValue)}
						/>
						<span>No address required</span>
					</label>
				</div>
				<div className="form-check checkbox-slider checkbox-slider--b-flat">
					<label>
						<input
							type="checkbox"
							name="manualAddress"
							checked={addressType === 'manualAddress'}
							disabled={!canEnterManualAddress}
							onChange={() => onChangeAddressType('manualAddress', this.props.setFieldValue)}
						/>
						<span>Enter Manually</span>
					</label>
				</div>
				{addressType === 'manualAddress' && (
					<ManualAddressInput
						onAddressHandleChange={onAddressHandleChange}
						stateOptions={stateOptions}
						defaultState={defaultState}
						{...this.props}
					/>
				)}

				{loaderMapLinkLocation && <LoaderPulse />}
				{addressType === 'validateAddress' && !loaderMapLinkLocation &&
				!isEmpty(validatedAddress) &&
				isEmpty(validatedAddress.error)  && (
					<Fragment>
						<div className="form-group" style={{ display: 'flex', flexDirection: 'row-reverse'}}>
								<button
									style={{
										marginBottom: '0.25rem',
									}}
									title="Show Mapping Information"
									type="button"
									className="btn btn-outline-secondary"
									onClick={() => this.props.showModal('SERVICE_ADDRESS_MODAL', {
										title: "Service Address Information",
										service: {
											address1: this.props.validatedAddress.contactAddress.address1 || '',
											address2: this.props.values.fullAddress.address2 || '',
											address3: this.props.values.fullAddress.address3 || '',
											city: this.props.validatedAddress.contactAddress.city || '',
											latitude: this.props.validatedAddress.contactAddress.latitude || '',
											longitude: this.props.validatedAddress.contactAddress.longitude || '',
											mappingAddressId: this.props.values.fullAddress.mappingAddressId || this.props.validatedAddress.contactAddress.mappingAddressId || '',
											mappingAreaId: this.props.validatedAddress.contactAddress.mappingAreaId || '',
											mappingRefArea1: this.props.validatedAddress.contactAddress.mappingRefArea1 || '',
											state: this.props.validatedAddress.contactAddress.systemState,
											zipcode: this.props.validatedAddress.contactAddress.zipcode || '',
											mappingNote: this.props.values.fullAddress.mappingNote || this.props.validatedAddress.contactAddress.mappingNote || '',
											externalUrl: this.props.validatedAddress.externalUrl || '',
											qualifiedServices: this.props.validatedAddress.qualifiedServices || '',
											zoneValues: this.props.validatedAddress.zoneValuesToSave,
											addressValues: this.props.validatedAddress.addressValuesToSave,
										},
										canViewCSIGeocode: false,
										canEditCSIGeocode: false,
										isDisabled: true
									})}
								>
									<i className="fas fa-map" />
								</button>
						</div>
					<div className="customer-new-service-location-map">
						<Iframe
							url={validatedAddress.embedUrl}
							width="100%"
							height="100%"
							id="myId"
							className="location-map"
							display="initial"
							position="relative"
							style={{ height: `30vh` }}
						/>
					</div>
					</Fragment>
				)}
			</Fragment>
		);
	}
}

Addresses.propTypes = {
	// contactAddresses: PropTypes.array.isRequired,
	// selectedAddress: PropTypes.string.isRequired,
	// coordinates: PropTypes.object.isRequired,
	onAddressHandleChange: PropTypes.func,
	onSubmitAddresses: PropTypes.func,
	addressType: PropTypes.string.isRequired,
	onChangeAddressType: PropTypes.func,
	validatedAddress: PropTypes.object.isRequired
};

export default Addresses;
