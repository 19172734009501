import moment from 'moment';
import {isEmpty} from '../../../../utils/helpers';

export const getFormattedFilters = (filters) => {
    let parameters = {};

    if (filters['limit'] !== '') {
        parameters['limit'] = filters.limit;
    }

    if (filters['fromDate'] !== '') {
        parameters['fromDate'] = moment(filters['fromDate']).format('YYYY-MM-DD');
    }

    if (filters['toDate'] !== '') {
        parameters['toDate'] = moment(filters['toDate']).format('YYYY-MM-DD');
    }

    if (!isEmpty(filters['assignee']) && !isEmpty(filters['assignee'].value)) {
        parameters['assignedTo'] = filters.assignee.value;
    }

    if (!isEmpty(filters['area']) && !isEmpty(filters['area'].value)) {
        parameters['areaId'] = filters.area.value;
    }

    if (!isEmpty(filters['type']) && !isEmpty(filters['type'].value)) {
        parameters['type'] = filters.type.value;
    }

    return parameters;
}
