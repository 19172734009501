import React, {Component} from 'react';
import Account from './Account';
import PropTypes from 'prop-types';

export class AccountsList extends Component {

	render() {

		const {
			accounts,
			isFetched,
			history,
		} = this.props;

		let portalAccess = 0;

		if (isFetched && accounts.length === 0) {
			return <p>No results found</p>
		}

		function getPortalAccess(account) {
			return 3;
		}

		const accountsData = accounts.map((account) => (
			portalAccess = getPortalAccess(account),
			<Account
				key={account.id}
				account={account}
				history={history}
				portalAccess={1}
			/>
		));

		return (
			<div className="container cmv-container-cam-search-results">
				{accounts.length > 0 && accountsData}
			</div>
		)
	}
}

AccountsList.propTypes = {
	accounts: PropTypes.array,
	history: PropTypes.object,
	isFetched: PropTypes.bool
};

export default AccountsList;
