import React, {Component, Fragment} from 'react';
import {Formik, Form} from 'formik';
import {toastr} from "react-redux-toastr";

import {connect} from 'react-redux';
import {hideModal} from '../../../../actions/modal.actions';
import {
    getUser,
    getAccountDetails,
    createLoadingSelector,
    getUserPermission,
    getModalData, getPaymentProviders
} from '../../../../selectors';

import {
    getUnpaidInvoices,
    emptyUnpaidInvoices,
    paymentByCheck,
    paymentByCash,
    paymentByCredit,
    paymentByWireTransfer,
    submitExternalPayment,
    paymentByToken,
    getMyPaymentMethods,
    createPaymentMethodForm,
    getInstallmentPlans,
    payInstallmentPlan
} from '../../../../actions/accountDetailsActions/payNow.actions';

import {actionCreator, isEmpty} from '../../../../utils/helpers';
import PayNowTable from './PayNowTable';
import PayInstallmentPlanTable from "./PayInstallmentPlanTable";
import {PaymentForm, Checking, Credit} from './PaymentMethod';
import * as Yup from 'yup';

import Loader from '../../../../components/Loader';
import {
    emptyAccountCreditBalance, getAccountBalance,
    getAccountCreditBalance, getDueDetails
} from "../../../../actions/accountDetailsActions/billing.actions";
import FormikInput from "../../../../components/Common/Inputs/FormikInput";
import {getCurrentPaymentProviderId, getCurrentPaymentProviderSupportsPaymentMethodStorage} from "../../../../actions/paymentProvider.actions";


const PAYMENT_METHOD = {
    NONE: 'NONE',
    CREDIT_CARD: 'CC',
    ACH: 'ACH',
    CHECK: 'CHECK',
    CASH: 'CASH',
    ACCOUNT_CREDIT: 'ACCOUNT_CREDIT',
    CHECKING: 'CHECKING',
    WIRE_TRANSFER: 'WIRE_TRANSFER',
    EXTERNAL: 'EXTERNAL',
    MANAGE_PAYMENT_METHODS: 'MANAGE_PAYMENT_METHODS',
}

const PAYMENT_OPTION = {
    ALL_UNPAID_INVOICES: 'ALL_UNPAID_INVOICES',
    SPECIFIC_INVOICES: 'SPECIFIC_INVOICES',
    CREDIT: 'CREDIT',
    DEPOSIT: 'DEPOSIT',
    INSTALLMENT_PLAN: 'INSTALLMENT_PLAN',
}

const PAYMENT_RESULT = {
    NONE: 0,
    FAIL: 1,
    SUCCESS: 2,
    GENERIC: 3,
};

const UNPAID_INVOICES_STATE = {
    NOT_LOADED: 0,
    LOADING: 1,
    LOADED: 2,
}

const PayNowSchema = Yup.object().shape({
    paymentOption: Yup.string().required('Required'),
    paymentMethod: Yup.string().required('Required'),
    paymentAmount: Yup.number().transform(function (value, originalValue) {
        return Number(originalValue.replace(/,/g, ''));
    }).required('Required')
        .typeError('Invalid Payment Amount')
        .test(
            'is-valid-amount',
            'Invalid Payment Amount',
            function(value) {
                const { paymentMethod } = this.parent;
                if (paymentMethod === 'MANAGE_PAYMENT_METHODS') {
                    return true;
                }
                if ((value + '').match(/^(\d+\.\d{0,2}|\d+\.?|\.\d{1,2})$/gm) !== null) {
                    return value > 0;
                }
            }),
    paymentReference: Yup.string().when('paymentMethod', {
        is: (value) =>
            value === PAYMENT_METHOD.CHECK
            || value === PAYMENT_METHOD.WIRE_TRANSFER
            || value === PAYMENT_METHOD.EXTERNAL,
        then: Yup.string()
            .required('Required')
            .test('empty-test', 'Invalid value', value => !isEmpty(value))
    }),
    selectedInvoices: Yup.array().when('paymentOption', {
        is: (value) => value === PAYMENT_OPTION.SPECIFIC_INVOICES,
        then: Yup.array().required('Required').min(1)
    }),

    selectedInstallmentPlan: Yup.array().when('paymentOption', {
        is: (value) => value === PAYMENT_OPTION.INSTALLMENT_PLAN,
        then: Yup.array().required('Required').min(1)
    })
});


class PayNowModal extends Component {
    state = {
        activeDropdown: null,
        paymentResult: PAYMENT_RESULT.NONE,
        unpaidInvoicesState: UNPAID_INVOICES_STATE.NOT_LOADED,
        transactionResult: null,
        paymentMethods: null,
        payProviderSupportsPaymentMethodStorage: false,
        defaultSubmitButtonText: "SUBMIT PAYMENT",
        currentSubmitButtonText: "SUBMIT PAYMENT",
        paymentMethodsLoading: false,
        installmentPlans: null,
        loadingInstallmentPlans: false,
        showInstallmentPlanToggle: false,
        loadingPayNowModal: true
    };

    constructor(props) {
        super(props);

        this.paymentFormRef = React.createRef();
        this.paymentMethodFormRef = React.createRef();
    }

    componentDidMount() {
        const { accountInfo,
            getAccountBalance, accountBalance, accountBalanceLoading,
            getDueDetails, amountDue, amountDueLoading } = this.props;

        this.props.getAccountCreditBalance(accountInfo.id);
        this.props.getCurrentPaymentProviderId();
        this.props.getInstallmentPlans(this.props.accountInfo.id).then((installmentPlans) => {
            if(installmentPlans && !isEmpty(installmentPlans)){
                installmentPlans.map(installmentPlan => {
                    if(installmentPlan.balance > 0){
                        this.setState({
                            showInstallmentPlanToggle: true,
                        });
                    }
                })
            }
            this.setState({
                loadingPayNowModal: false
            })
        });

        if (!amountDueLoading && !amountDue) {
            getDueDetails(accountInfo.id);
        }

        if (!accountBalanceLoading && !accountBalance) {
            getAccountBalance(accountInfo.id);
        }
        this.props.getCurrentPaymentProviderSupportsPaymentMethodStorage().then(resp => {
            this.state.payProviderSupportsPaymentMethodStorage = resp.data.result;
            this.refreshPayProviderStoredPaymentMethods();
        });
    }

    refreshPayProviderStoredPaymentMethods() {
        if (this.state.payProviderSupportsPaymentMethodStorage) {
            this.setState({paymentMethodsLoading: true})
            this.props.getMyPaymentMethods(this.props.accountInfo.id)
                .then((response) => {
                    this.setState({paymentMethods: response})
                    this.setState({paymentMethodsLoading: false})
                });
        }
    }

    componentWillUnmount() {
        this.props.emptyUnpaidInvoices();
        this.props.emptyAccountCreditBalance();
    }

    closeModal = () => {
        this.props.hideModal();

        if (this.props.modalProps.onCloseCallback) {
            this.props.modalProps.onCloseCallback();
        }
    };

    handlePaymentMethodChange = (e, values, setFieldValue, setValues) => {
        const selectedPaymentMethod = e.target.value;

        this.setState(prevState => (prevState.paymentResult !== PAYMENT_RESULT.NONE) ? {paymentResult: PAYMENT_RESULT.NONE} : null);

        if (selectedPaymentMethod === PAYMENT_METHOD.ACCOUNT_CREDIT
            && (values.paymentOption === PAYMENT_OPTION.CREDIT
                || values.paymentOption === PAYMENT_OPTION.DEPOSIT)) {

            setFieldValue('paymentOption', PAYMENT_OPTION.ALL_UNPAID_INVOICES);
        } else if (selectedPaymentMethod === PAYMENT_METHOD.MANAGE_PAYMENT_METHODS) {
            this.setState({currentSubmitButtonText: "MANAGE PAYMENT METHODS"});
        } else {
            this.setState({currentSubmitButtonText: this.state.defaultSubmitButtonText});
        }

        setFieldValue('paymentReference', '');
        setFieldValue('paymentMethod', selectedPaymentMethod);
    };

    handlePaymentOptions = (type, values, setFieldValue) => {
        if (type === PAYMENT_OPTION.SPECIFIC_INVOICES) {
            delete values.selectedInstallmentPlan;
            delete values.selectedInstallmentPlanId;
            setFieldValue('selectedInvoices', []);
            if (this.state.unpaidInvoicesState === UNPAID_INVOICES_STATE.NOT_LOADED) {
                this.setState({
                        unpaidInvoicesState: UNPAID_INVOICES_STATE.LOADING
                    },
                    () => this.props.getUnpaidInvoices(this.props.accountInfo.id)
                        .then(() => this.setState({unpaidInvoicesState: UNPAID_INVOICES_STATE.LOADED})));
            }
        } else if (type === PAYMENT_OPTION.INSTALLMENT_PLAN) {
            delete values.selectedInvoices;
            setFieldValue('selectedInstallmentPlan', '');
            this.setState({loadingInstallmentPlans: true});
            this.props.getInstallmentPlans(this.props.accountInfo.id).then((response) => {
                this.setState({installmentPlans: response, loadingInstallmentPlans: false});
            });
        } else {
            delete values.selectedInvoices;
            delete values.selectedInstallmentPlan;
            delete values.selectedInstallmentPlanId;
        }

        setFieldValue('paymentOption', type);
    };

    genericPayment = (setFieldValue, setSubmitting, referenceId) => {
        this.setState({paymentResult: PAYMENT_RESULT.GENERIC});
        setFieldValue('paymentMethod', PAYMENT_METHOD.NONE);
        setFieldValue('paymentOption', PAYMENT_OPTION.ALL_UNPAID_INVOICES);
        if (!isEmpty(referenceId)) {setFieldValue('paymentReferenceId', referenceId)}
        setSubmitting(false);
    };

    genericFormPayment(setFieldValue, referenceId, setSubmitting, transactionResult) {
        this.setState({transactionResult}, () => this.genericPayment(setFieldValue, setSubmitting, referenceId));
    }

    successfulPayment = (setFieldValue, setSubmitting, transactionResult) => {
        this.setState({paymentResult: PAYMENT_RESULT.SUCCESS, transactionResult: transactionResult});
        setFieldValue('paymentMethod', PAYMENT_METHOD.NONE);
        setFieldValue('paymentOption', PAYMENT_OPTION.ALL_UNPAID_INVOICES);
        if (transactionResult != null) {
            setFieldValue('paymentReferenceId', transactionResult.referenceId);
        } else {
            setFieldValue('paymentReferenceId', null);
        }
        setSubmitting(false);
    };

    successfulFormPayment(setFieldValue, referenceId, setSubmitting, transactionResult, values) {
        this.setState({transactionResult}, () => this.successfulPayment(setFieldValue, setSubmitting, referenceId));
    }

    failedPayment = (setFieldValue, setSubmitting, transactionResult) => {
        this.setState({paymentResult: PAYMENT_RESULT.FAIL, transactionResult: transactionResult});
        setFieldValue('paymentMethod', PAYMENT_METHOD.NONE);
        setFieldValue('paymentOption', PAYMENT_OPTION.ALL_UNPAID_INVOICES);
        setSubmitting(false);
    };

    failedFormPayment = (setFieldValue, setSubmitting, transactionResult) => {
        this.setState({transactionResult}, () => this.failedPayment(setFieldValue, setSubmitting));
    }

    getTransactionResultMessages = () => {
        const { transactionResult } = this.state;
        const sentences = [];

        if (transactionResult == null) {
            return sentences;
        }

        if (!isEmpty(transactionResult.responseText)) {
            sentences.push("Response: " + transactionResult.responseText + ".");
        }

        if (!isEmpty(transactionResult.statusText)) {
            sentences.push("Response: " + transactionResult.statusText + ".");
        }

        if (!isEmpty(transactionResult.authCode)) {
            sentences.push("Auth code: " + transactionResult.authCode + ".");
        }

        if (!isEmpty(transactionResult.transactionId)) {
            let transactionId = transactionResult.transactionId;
            if (transactionId.length > 10) {
                transactionId = "..." + transactionId.substring(transactionId.length - 10);
            }

            sentences.push("Transaction ID: " + transactionId + ".");
        }

        return sentences;
    }

    clearPaymentResult = () => {
        this.setState({paymentResult: PAYMENT_RESULT.NONE, transactionResult: null});
    }

    renderPaymentMethod = (creditBalance, values, errors, touched, setFieldValue, handleChange, handleBlur, setSubmitting, isSubmitting) => {
        const {accountInfo} = this.props;

        switch (values.paymentMethod) {
            case PAYMENT_METHOD.ACH:
            case PAYMENT_METHOD.CREDIT_CARD:
                return <PaymentForm ref={this.paymentFormRef}
                                    accountInfo={accountInfo}
                                    paymentMethod={values.paymentMethod}
                                    genericPaymentHandler={(referenceId, transactionResult) => this.genericFormPayment(setFieldValue, referenceId, setSubmitting, transactionResult)}
                                    successfulPayment={(referenceId, transactionResult) => this.successfulFormPayment(setFieldValue, referenceId, setSubmitting, transactionResult, values)}
                                    failedPayment={(transactionResult) => {this.failedFormPayment(setFieldValue, setSubmitting, transactionResult)}}
                />
            case PAYMENT_METHOD.CHECK:
            case PAYMENT_METHOD.EXTERNAL:
            case PAYMENT_METHOD.WIRE_TRANSFER:
                const label = values.paymentMethod === PAYMENT_METHOD.CHECK && 'Check Number' || 'Reference';
                return <FormikInput
                    wrapperClass="form-group"
                    label={label}
                    type="text"
                    name="paymentReference"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.paymentReference}
                    placeholder={label}
                    errors={errors}
                    touched={touched}
                    disabled={isSubmitting}
                />
            case PAYMENT_METHOD.CHECKING:
                return <Checking
                    values={values}
                />
            case PAYMENT_METHOD.ACCOUNT_CREDIT:
                return <Credit
                    values={values}
                    accountCredit={creditBalance.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                />
            case PAYMENT_METHOD.MANAGE_PAYMENT_METHODS:
                return <PaymentForm ref={this.paymentFormRef}
                                    accountInfo={accountInfo}
                                    paymentMethod={values.paymentMethod}
                                    refreshPayProviderStoredPaymentMethods={() => this.refreshPayProviderStoredPaymentMethods()}
                                    genericPaymentHandler={(referenceId, transactionResult) => this.genericFormPayment(setFieldValue, referenceId, setSubmitting, transactionResult)}
                                    successfulPayment={(referenceId, transactionResult) => this.successfulFormPayment(setFieldValue, referenceId, setSubmitting, transactionResult)}
                                    failedPayment={(transactionResult) => {this.failedFormPayment(setFieldValue, setSubmitting, transactionResult)}}
                />
            default:
                return null;
        }
    };

    handleSubmit = (values, { setSubmitting, setFieldValue, setFieldError}) => {
        const { accountInfo, user, creditBalance } = this.props;
        const { paymentResult } = this.state;
        const { paymentMethod } = values;
        const paymentAmount = Number(values.paymentAmount.replace(/,/g, ''));

        const data = {
            locationId: user.currentLocation.id,
            selectedInvoices: values.selectedInvoices,
            paymentAmount: paymentAmount,
            paymentOption: values.paymentOption,
            accountInfo: accountInfo
        };
        if(values.selectedInstallmentPlan){
            data["selectedInstallmentPlan"] = values.selectedInstallmentPlan[0] ? values.selectedInstallmentPlan[0].id : '';
        }

        switch (paymentMethod) {
            case PAYMENT_METHOD.CHECK:
                data.checkNumber = values.paymentReference;

                this.props.paymentByCheck(accountInfo.id, data).then((response) => {
                    if (response.success) {
                        this.successfulPayment(setFieldValue, setSubmitting, response.id);
                    } else {
                        console.error(response);
                        this.failedPayment(setFieldValue, setSubmitting);
                    }
                }).catch(e => {
                    console.error(e);
                    this.failedPayment(setFieldValue, setSubmitting);
                });
                break;

            case PAYMENT_METHOD.WIRE_TRANSFER:
                data.referenceNumber = values.paymentReference;

                this.props.paymentByWireTransfer(accountInfo.id, data).then((response) => {
                    if (response.success) {
                        this.successfulPayment(setFieldValue, setSubmitting,  response.id);
                    } else {
                        console.error(response);
                        this.failedPayment(setFieldValue, setSubmitting);
                    }
                }).catch(e => {
                    console.error(e);
                    this.failedPayment(setFieldValue, setSubmitting);
                });
                break;

            case PAYMENT_METHOD.EXTERNAL:
                data.reference = values.paymentReference;

                this.props.submitExternalPayment(accountInfo.id, data).then((response) => {
                    if (response.success) {
                        this.successfulPayment(setFieldValue, setSubmitting,  response.id);
                    } else {
                        console.error(response);
                        this.failedPayment(setFieldValue, setSubmitting);
                    }
                }).catch(e => {
                    console.error(e);
                    this.failedPayment(setFieldValue, setSubmitting);
                });
                break;

            case PAYMENT_METHOD.CASH:
                this.props.paymentByCash(accountInfo.id, data).then((response) => {
                    if (response.success) {
                        this.successfulPayment(setFieldValue, setSubmitting, response.id);
                    } else {
                        console.error(response);
                        this.failedPayment(setFieldValue, setSubmitting);
                    }
                }).catch(e => {
                    console.error(e);
                    this.failedPayment(setFieldValue, setSubmitting);
                });
                break;

            case PAYMENT_METHOD.ACCOUNT_CREDIT:
                if (!creditBalance || creditBalance < paymentAmount) {
                    setFieldError("paymentAmount", "Invalid payment amount");
                    setSubmitting(false);
                    return;
                }

                this.props.paymentByCredit(accountInfo.id, data).then((response) => {
                    if (response.success) {
                        this.successfulPayment(setFieldValue, setSubmitting, response.id);
                    } else {
                        console.error(response);
                        this.failedPayment(setFieldValue, setSubmitting);
                    }
                }).catch(e => {
                    console.error(e);
                    this.failedPayment(setFieldValue, setSubmitting);
                });
                break;

            case PAYMENT_METHOD.CREDIT_CARD:
                data.paymentFormType = 'CC';
                this.paymentFormRef.current.submitPaymentForm(data);
                break;

            case PAYMENT_METHOD.ACH:
                data.paymentFormType = 'ACH';
                this.paymentFormRef.current.submitPaymentForm(data);
                break;

            case PAYMENT_METHOD.MANAGE_PAYMENT_METHODS:
                data.submitType = 'managePaymentMethods';
                this.paymentFormRef.current.renderManagePaymentMethodForm(accountInfo, data);
                setSubmitting(false);
                break;

            default:
                // If this is not a valid paymentMethod, assume it is a token and try to submit using it.
                // When using a tokenized payment to Authorize.Net or similar payment providers in the future, the value of paymentMethod is the token value
                toastr.confirm("Are you sure you want to proceed with making the payment?.", {
                    cancelText: "Cancel",
                    okText: "Proceed",
                    onOk: () => {
                        data.token = paymentMethod
                        // if (this.state.paymentMethods) {
                        //     // Iterate over paymentMethods array to find the one selected so we can set the displayCardNumber properly as required by Authorize.Net
                        //     {this.state.paymentMethods.map((myPaymentMethod) => (
                        //         (myPaymentMethod.token == data.cardToken ? data.displayCardNumber = myPaymentMethod.cardNumber : data.displayCardNumber = data.displayCardNumber)
                        //     ))}
                        // }
                        this.props.paymentByToken(data).then((response) => {
                            if (response.status === 'USED') {
                                this.successfulPayment(setFieldValue, setSubmitting, response);
                            } else if (response.status === 'FAIL') {
                                this.failedPayment(setFieldValue, setSubmitting, response);
                            } else {
                                this.genericPayment(setFieldValue, setSubmitting, response.referenceId);
                            }
                        }).catch(e => {
                            console.error(e);
                            this.failedPayment(setFieldValue, setSubmitting);
                        });
                        //setSubmitting(false);
                    },
                    onCancel: () => {
                        setSubmitting(false);
                    }
                });
        }

    };

    render() {
        const {
            unpaidInvoicesData, creditBalanceLoading, hideModal, setFieldValue,
            accountBalance, accountBalanceLoading,
            amountDue, amountDueLoading,
            loadingInvoices,
            canSubmitRefPayments,
            canManagePaymentMethods,
            currentProviderId
        } = this.props;

        const { paymentResult, payProviderSupportsPaymentMethodStorage, installmentPlans, loadingInstallmentPlans } = this.state;
        const creditBalance = this.props.creditBalance || 0;

        return (
            <Fragment>
                <div className="modal" style={{display: 'block'}} id="cmv-modal-pay-now" tabIndex="-1" role="dialog">
                    <Formik
                        initialValues={{
                            paymentOption: PAYMENT_OPTION.ALL_UNPAID_INVOICES,
                            paymentAmount: accountBalance && accountBalance > 0 ? accountBalance.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : '0.00',
                            paymentMethod: '',
                            paymentReference: '',
                        }}
                        enableReinitialize
                        validationSchema={PayNowSchema}
                        onSubmit={this.handleSubmit}
                        render={(props) => {
                            const {
                                values,
                                touched,
                                errors,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                isSubmitting,
                                setFieldValue,
                                setValues,
                                setSubmitting,
                                setIn
                            } = props;
                            return (
                                <div className="modal-dialog modal-lg" role="document">
                                    <div className="modal-content">

                                        <div className="modal-header">
                                            <h5 className="modal-title">Pay Now</h5>
                                            <button onClick={() => this.closeModal()} type="button" className="close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        {this.state.loadingPayNowModal &&
                                            <Loader/>
                                        }
                                        {!this.state.loadingPayNowModal &&
                                        <div className="modal-body">
                                            <Form
                                                onSubmit={handleSubmit}
                                                id="mainForm"
                                                autoComplete="off"
                                                className={"cmv-form" + (paymentResult === PAYMENT_RESULT.SUCCESS ? " d-none" : "")}
                                            >
                                                <div className="form-section">
                                                    <div className="form-check checkbox-slider checkbox-slider--b-flat">
                                                        <label>
                                                            <input
                                                                type="radio"
                                                                name="paymentOption"
                                                                disabled={isSubmitting}
                                                                onChange={() =>
                                                                    this.handlePaymentOptions(
                                                                        PAYMENT_OPTION.ALL_UNPAID_INVOICES,
                                                                        values,
                                                                        setFieldValue
                                                                    )}
                                                                checked={values.paymentOption === PAYMENT_OPTION.ALL_UNPAID_INVOICES}
                                                            />
                                                            <span>
																All Unpaid Invoices
																<p className="text-muted text-xs mb-0">
																	Pay open invoice balance first, then pay as Account Credit
																</p>
															</span>
                                                        </label>
                                                    </div>
                                                    <div className="form-check checkbox-slider checkbox-slider--b-flat">
                                                        <label>
                                                            <input
                                                                type="radio"
                                                                name="paymentOption"
                                                                disabled={isSubmitting}
                                                                onChange={() =>
                                                                    this.handlePaymentOptions(
                                                                        PAYMENT_OPTION.SPECIFIC_INVOICES,
                                                                        values,
                                                                        setFieldValue
                                                                    )}
                                                                checked={values.paymentOption === PAYMENT_OPTION.SPECIFIC_INVOICES}
                                                            />
                                                            <span>
																Specific Invoices
																<p className="text-muted text-xs mb-0">
																	Choose to pay invoices, then pay as Account Credit
																</p>
															</span>
                                                        </label>
                                                    </div>
                                                    {values.paymentOption === PAYMENT_OPTION.SPECIFIC_INVOICES && (
                                                        <PayNowTable
                                                            isSubmitting={isSubmitting}
                                                            invoicesData={unpaidInvoicesData}
                                                            setFieldValue={setFieldValue}
                                                            values={values}
                                                            loadingInvoices={loadingInvoices}
                                                        />
                                                    )}

                                                    <div className="form-check checkbox-slider checkbox-slider--b-flat">
                                                        <label>
                                                            <input
                                                                type="radio"
                                                                name="paymentOption"
                                                                disabled={isSubmitting || values.paymentMethod === PAYMENT_METHOD.ACCOUNT_CREDIT}
                                                                onChange={() =>
                                                                    this.handlePaymentOptions(
                                                                        PAYMENT_OPTION.CREDIT,
                                                                        values,
                                                                        setFieldValue
                                                                    )}
                                                                checked={values.paymentOption === PAYMENT_OPTION.CREDIT}
                                                            />
                                                            <span>
																Credit Account
																<p className="text-muted text-xs mb-0">
																	Pay as Account Credit
																</p>
															</span>
                                                        </label>
                                                    </div>

                                                    <div className="form-check checkbox-slider checkbox-slider--b-flat">
                                                        <label>
                                                            <input
                                                                type="radio"
                                                                name="paymentOption"
                                                                disabled={isSubmitting || values.paymentMethod === PAYMENT_METHOD.ACCOUNT_CREDIT}
                                                                onChange={() =>
                                                                    this.handlePaymentOptions(
                                                                        PAYMENT_OPTION.DEPOSIT,
                                                                        values,
                                                                        setFieldValue
                                                                    )}
                                                                checked={values.paymentOption === PAYMENT_OPTION.DEPOSIT}
                                                            />
                                                            <span>
																Deposit Payment
																<p className="text-muted text-xs mb-0">
																	Pay as Account Deposit
																</p>
															</span>
                                                        </label>
                                                    </div>

                                                    {this.state.showInstallmentPlanToggle &&
                                                    <div className="form-check checkbox-slider checkbox-slider--b-flat">
                                                        <label>
                                                            <input
                                                                type="radio"
                                                                name="paymentOption"
                                                                disabled={isSubmitting || values.paymentMethod === PAYMENT_METHOD.ACCOUNT_CREDIT}
                                                                onChange={() =>
                                                                    this.handlePaymentOptions(
                                                                        PAYMENT_OPTION.INSTALLMENT_PLAN,
                                                                        values,
                                                                        setFieldValue
                                                                    )}
                                                                checked={values.paymentOption === PAYMENT_OPTION.INSTALLMENT_PLAN}
                                                            />
                                                            <span>
                                                                    Installment Plan
                                                                    <p className="text-muted text-xs mb-0">
                                                                        Pay off installment plan
                                                                    </p>
                                                                </span>
                                                        </label>
                                                    </div>
                                                    }
                                                    {values.paymentOption === PAYMENT_OPTION.INSTALLMENT_PLAN && (
                                                        <PayInstallmentPlanTable
                                                            isSubmitting={isSubmitting}
                                                            installmentPlans={installmentPlans}
                                                            setFieldValue={setFieldValue}
                                                            values={values}
                                                            loadingInstallmentPlans={loadingInstallmentPlans}
                                                        />
                                                    )}

                                                </div>

                                                <div className="form-section row bg-gray-200 mt-2 mb-2">
                                                    <div className="col-5 col-sm-7 col-lg-8">
                                                        <p className="text-xl text-right mb-0">Balance:</p>
                                                    </div>
                                                    <div className="col-7 col-sm-5 col-lg-4">
                                                        <p className="font-weight-bold text-xl text-right mb-0">
															<span className="topay-balance">
                                                                {accountBalanceLoading ?
                                                                    <i className="fas fa-fw fa-spin fa-spinner"/>
                                                                    : ('$' + accountBalance.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'))
                                                                }
															</span>
                                                        </p>
                                                    </div>
                                                    <div className="w-100"/>
                                                    <div className="col-5 col-sm-7 col-lg-8">
                                                        <p className="text-right mb-0">Amount Due:</p>
                                                    </div>
                                                    <div className="col-7 col-sm-5 col-lg-4">
                                                        <p className="font-weight-bold text-right mb-0">
															<span className="topay-amdue">
                                                                {amountDueLoading ?
                                                                    <i className="fas fa-fw fa-spin fa-spinner"/>
                                                                    : ('$' + amountDue.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'))
                                                                }
															</span>
                                                        </p>
                                                    </div>
                                                </div>

                                                <div className="form-section form-section-customer-payment-content">
                                                    <div className="form-section form-section-customer-payment-options">
                                                        <div className="row">
                                                            <div className="form-group col-sm-8">
                                                                <label>Payment Method</label>
                                                                {creditBalanceLoading || this.state.paymentMethodsLoading &&
                                                                <i className="fas fa-fw fa-spin fa-spinner"
                                                                   style={{marginLeft: '5px'}}/>
                                                                }
                                                                {!creditBalanceLoading && !this.state.paymentMethodsLoading &&
                                                                <select
                                                                    name="paymentMethod"
                                                                    value={values.paymentMethod}
                                                                    disabled={isSubmitting}
                                                                    onChange={(e) =>
                                                                        this.handlePaymentMethodChange(
                                                                            e,
                                                                            values,
                                                                            setFieldValue,
                                                                            setValues
                                                                        )}
                                                                    id="sel-customer-pmnt-method"
                                                                    className={
                                                                        errors.paymentMethod &&
                                                                        touched.paymentMethod ? (
                                                                            'form-control is-invalid'
                                                                        ) : (
                                                                            'form-control'
                                                                        )
                                                                    }
                                                                >
                                                                    <option value="">Please choose payment method
                                                                    </option>
                                                                    <option value={PAYMENT_METHOD.CHECK}
                                                                            disabled={!canSubmitRefPayments}>
                                                                        Check
                                                                    </option>
                                                                    <option value={PAYMENT_METHOD.CASH}
                                                                            disabled={!canSubmitRefPayments}>
                                                                        Cash
                                                                    </option>
                                                                    {payProviderSupportsPaymentMethodStorage && !isEmpty(this.state.paymentMethods) &&
                                                                    <>
                                                                        {this.state.paymentMethods.map((payMethod) => (
                                                                            <option
                                                                                value={payMethod.token}>{payMethod.paymentInformation}{payMethod.autoPay ? ' (AutoPay)' : ''}</option>
                                                                        ))}
                                                                    </>
                                                                    }
                                                                    {!payProviderSupportsPaymentMethodStorage && currentProviderId &&
                                                                    <>
                                                                        <option value={PAYMENT_METHOD.CREDIT_CARD}>Debit
                                                                            / Credit Card
                                                                        </option>
                                                                        <option value={PAYMENT_METHOD.ACH}>Checking /
                                                                            Savings Account
                                                                        </option>
                                                                    </>
                                                                    }
                                                                    <option value={PAYMENT_METHOD.WIRE_TRANSFER}
                                                                            disabled={!canSubmitRefPayments}>
                                                                        Wire
                                                                    </option>
                                                                    <option value={PAYMENT_METHOD.EXTERNAL}
                                                                            disabled={!canSubmitRefPayments}>
                                                                        External
                                                                    </option>
                                                                    {/*<option value={PAYMENT_METHOD.CHECKING}>Checking / Savings Account</option>*/}
                                                                    {payProviderSupportsPaymentMethodStorage &&
                                                                    <option
                                                                        value={PAYMENT_METHOD.MANAGE_PAYMENT_METHODS}
                                                                        disabled={!canManagePaymentMethods}>
                                                                        Manage Payment Methods
                                                                    </option>
                                                                    }
                                                                    <option value={PAYMENT_METHOD.ACCOUNT_CREDIT}
                                                                            disabled={(creditBalance > 0 ? false : true)}>
                                                                        Account Credit
                                                                        ${creditBalance.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                                                                    </option>
                                                                </select>
                                                                }
                                                                {errors.paymentMethod &&
                                                                touched.paymentMethod && (
                                                                    <div className="invalid-feedback">
                                                                        {errors.paymentMethod}
                                                                    </div>
                                                                )}
                                                            </div>
                                                            <div className="form-group col-sm-4">
                                                                <label>Payment Amount</label>
                                                                <div className="input-group">
                                                                    <div className="input-group-prepend">
                                                                        <span className="input-group-text">$</span>
                                                                    </div>
                                                                    <input
                                                                        type="text"
                                                                        data-type="currency"
                                                                        name="paymentAmount"
                                                                        disabled={isSubmitting}
                                                                        className={
                                                                            errors.paymentAmount && touched.paymentAmount ? (
                                                                                'form-control text-right is-invalid'
                                                                            ) : (
                                                                                'form-control text-right'
                                                                            )
                                                                        }
                                                                        value={values.paymentAmount}
                                                                        data-amount={''}
                                                                        onBlur={handleBlur}
                                                                        onChange={handleChange}
                                                                    />
                                                                    {errors.paymentAmount &&
                                                                    touched.paymentAmount && (
                                                                        <div className="invalid-feedback">
                                                                            {errors.paymentAmount}
                                                                        </div>
                                                                    )}

                                                                    <div
                                                                        className="w-100 d-flex justify-content-end flex-wrap">
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-sm btn-outline-secondary btn-pay-balance mt-1"
                                                                            disabled={isSubmitting}
                                                                            onClick={() =>
                                                                                (accountBalance > 0
                                                                                        ? setFieldValue('paymentAmount', accountBalance.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'))
                                                                                        : setFieldValue('paymentAmount', '0.00')
                                                                                )
                                                                            }
                                                                        >
                                                                            Pay Balance
                                                                        </button>
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-sm btn-outline-secondary btn-pay-amdue mt-1 ml-1"
                                                                            disabled={isSubmitting}
                                                                            onClick={() =>
                                                                                (amountDue > 0
                                                                                        ? setFieldValue('paymentAmount', amountDue.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'))
                                                                                        : setFieldValue('paymentAmount', '0.00')
                                                                                )
                                                                            }
                                                                        >
                                                                            Pay Amount Due
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </Form>

                                            <div
                                                className={"form-section form-section-customer-payment-management" + (paymentResult === PAYMENT_RESULT.SUCCESS ? " form-section-customer-payment-management-success" : "")}>

                                                {paymentResult === PAYMENT_RESULT.NONE && this.renderPaymentMethod(
                                                    creditBalance,
                                                    values,
                                                    errors,
                                                    touched,
                                                    setFieldValue,
                                                    handleChange,
                                                    handleBlur,
                                                    setSubmitting,
                                                    isSubmitting,
                                                )}

                                                {(values.paymentMethod !== PAYMENT_METHOD.CREDIT_CARD
                                                    && values.paymentMethod !== PAYMENT_METHOD.ACH
                                                    && values.paymentMethod !== PAYMENT_METHOD.NONE
                                                    && values.paymentMethod !== PAYMENT_METHOD.MANAGE_PAYMENT_METHODS)
                                                && isSubmitting && (
                                                    <Loader/>
                                                )}

                                                {paymentResult === PAYMENT_RESULT.SUCCESS &&
                                                <div className="form-section-customer-payment-management-message">
                                                    <i className="far fa-check-circle text-success"/>
                                                    <p>Payment was successful, you can find details in the "Billing"
                                                        tab.</p>
                                                    {this.getTransactionResultMessages().map(msg =>
                                                        <Fragment><span>{msg}</span><br/></Fragment>)}
                                                </div>
                                                }

                                                {paymentResult === PAYMENT_RESULT.FAIL &&
                                                <div className="form-section-customer-payment-management-message">
                                                    <i className="far fa-times-circle text-danger"
                                                       onClick={this.clearPaymentResult} style={{cursor: 'pointer'}}/>
                                                    <p>Transaction failed.</p>
                                                    {this.getTransactionResultMessages().map(msg =>
                                                        <Fragment><span>{msg}</span><br/></Fragment>)}
                                                </div>
                                                }

                                                {paymentResult === PAYMENT_RESULT.GENERIC &&
                                                <div className="form-section-customer-payment-management-message">
                                                    <i className="fas fa-info-circle text-muted"
                                                       onClick={this.clearPaymentResult} style={{cursor: 'pointer'}}/>
                                                    <br/>
                                                    {this.getTransactionResultMessages().map(msg =>
                                                        <Fragment><span>{msg}</span><br/></Fragment>)}
                                                </div>
                                                }

                                            </div>

                                        </div>
                                        }

                                        <div className="modal-footer">
                                            <button type="button" className="btn" onClick={() => this.closeModal()} disabled={isSubmitting}>
                                                {paymentResult !== PAYMENT_RESULT.SUCCESS ? "Cancel" : "Close"}
                                            </button>
                                            {paymentResult !== PAYMENT_RESULT.SUCCESS &&
                                            <button
                                                disabled={isSubmitting || this.state.loadingPayNowModal }
                                                type="submit" form="mainForm" className="btn btn-primary"
                                                id="btn-pmnt-submit">
                                                {this.state.currentSubmitButtonText}
                                            </button>
                                            }
                                        </div>

                                    </div>
                                </div>
                            );
                        }}
                    />
                </div>
                <div className="modal-backdrop show" tabIndex="1"/>
            </Fragment>
        );
    }
}

const accountCreditBalanceLoadingSelector = createLoadingSelector(['GET_ACCOUNT_CREDIT_BALANCE']);
const accountBalanceLoadingSelector = createLoadingSelector(['GET_ACCOUNT_BALANCE']);
const amountDueLoadingSelector = createLoadingSelector(['GET_AMOUNT_DUE']);
const invoicesLoadingSelector = createLoadingSelector(['SET_UNPAID_INVOICES']);

const mapStateToProps = (state) => {
    const modalProps = getModalData(state).modalProps;
    const accountInfo = getAccountDetails(state).accountDetails.accountInfo,
        user = getUser(state),
        unpaidInvoicesData = getAccountDetails(state).payNow.unpaidInvoices,
        creditBalance = getAccountDetails(state).billing.creditBalance,
        creditBalanceLoading = accountCreditBalanceLoadingSelector(state),
        amountDue = getAccountDetails(state).billing.amountDue,
        amountDueLoading = amountDueLoadingSelector(state),
        accountBalance = getAccountDetails(state).billing.balance,
        accountBalanceLoading = accountBalanceLoadingSelector(state),
        loadingInvoices = invoicesLoadingSelector(state),
        canSubmitRefPayments = getUserPermission(state, 'API', 'API_REF_PAYMENT'),
        canManagePaymentMethods = getUserPermission(state, 'API', 'API_MANAGE_PAYMENT_METHODS'),
        currentProviderId = getPaymentProviders(state).currentProviderId;

    return {
        modalProps,
        accountInfo,
        user,
        unpaidInvoicesData,
        creditBalance,
        creditBalanceLoading,
        amountDue,
        amountDueLoading,
        accountBalance,
        accountBalanceLoading,
        loadingInvoices,
        canSubmitRefPayments,
        canManagePaymentMethods,
        currentProviderId
    };
};

const mapDispatchToProps = {
    getCurrentPaymentProviderId,
    getUnpaidInvoices,
    emptyUnpaidInvoices,
    getAccountBalance,
    getDueDetails,
    getAccountCreditBalance,
    emptyAccountCreditBalance,
    hideModal,
    paymentByCheck,
    paymentByCash,
    paymentByCredit,
    paymentByWireTransfer,
    submitExternalPayment,
    getMyPaymentMethods,
    paymentByToken,
    createPaymentMethodForm,
    getInstallmentPlans,
    payInstallmentPlan,
    getCurrentPaymentProviderSupportsPaymentMethodStorage: getCurrentPaymentProviderSupportsPaymentMethodStorage,
};

export default connect(mapStateToProps, mapDispatchToProps)(PayNowModal);
