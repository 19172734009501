import React, { Component } from 'react';
import LeftPanel from './LeftPanel';
import RightPanel from './RightPanel';

export class Panels extends Component {
	state = {
		expandedPanel: '',
		isScrollToLeftPanel: true,
		switchTabInOtherPanel: '',
	};

	componentDidUpdate(prevProps, prevState) {
		if (prevState.isScrollToLeftPanel !== this.state.isScrollToLeftPanel) {
			if (this.state.isScrollToLeftPanel === true) {
				document.getElementById('leftPanel').scrollIntoView({ behavior: 'smooth' });
			} else {
				document.getElementById('rightPanel').scrollIntoView({ behavior: 'smooth' });
			}
		}
	}

	handleExpandPanel = (type) => {
		this.setState({
			expandedPanel: this.state.expandedPanel === type ? '' : type
		});
	};

	handleMobileViewScroll = () => {
		this.setState((prevState) => ({
			isScrollToLeftPanel: !prevState.isScrollToLeftPanel
		}));
	};

	handleSwitchTabInOtherPanel = (tab) => {
		this.setState({switchTabInOtherPanel: tab})
	}

	render() {
		const { expandedPanel, isScrollToLeftPanel, switchTabInOtherPanel } = this.state;

		return (
			<div className="cmv-container-panels">

				<div className="row">

					<LeftPanel
						expandedPanel={expandedPanel}
						handleExpandPanel={this.handleExpandPanel}
						switchTabInOtherPanel={switchTabInOtherPanel}
						handleSwitchTabInOtherPanel={this.handleSwitchTabInOtherPanel}
						{...this.props}
					/>

					<RightPanel
						expandedPanel={expandedPanel}
						handleExpandPanel={this.handleExpandPanel}
						switchTabInOtherPanel={switchTabInOtherPanel}
						handleSwitchTabInOtherPanel={this.handleSwitchTabInOtherPanel}
						{...this.props}
					/>

				</div>

				<button className={'btn-floater' + (isScrollToLeftPanel ? ' btn-floater-to-summary' : ' btn-floater-to-available')}
						onClick={this.handleMobileViewScroll}
				>
					<i className="fa fa-list" />
				</button>

			</div>
		);
	}
}

export default Panels;
