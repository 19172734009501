import React, {Component} from 'react';
import {connect} from 'react-redux';
import {createLoadingSelector, getAccountPlan} from '../../../../selectors';
import isEmpty, {applyOnEnum, generateId} from "../../../../utils/helpers";
import {changeServiceLine} from "../../../../actions/dashboardActions/orders.actions";
// import {clearAccountPlanSummary, getAccountPlanSummary} from "../../../../actions/accountPlan.actions";
import moment from "moment";
import DatePicker from "react-datepicker";
import {Form, Formik} from "formik";
import ServiceOrder from "../ServiceOrder";

class Lines extends Component {

    state = {
        removeAll: true,
        allLines: null,
        mainServiceLineId: null,
        isApplyOnVisible: false,
        minDate: this.props.wizardData.accountInfo && this.props.wizardData.accountInfo.billCycle
            ? this.convertDay(this.props.wizardData.accountInfo.billCycle)
            : this.convertDay((new Date()).getDate())
    }

    convertDay(startDayBillCycle){
        let localdate = new Date();
        let currentMonth = localdate.getMonth();
        let currentYear = localdate.getFullYear();
        let currentDay = localdate.getDate();
        if (startDayBillCycle <= currentDay) {
            return new Date(currentYear, currentMonth, startDayBillCycle);
        }
        else {
            return new Date(currentYear, currentMonth-1, startDayBillCycle);
        }
    }

    componentDidMount() {

        let lines = [];
        let mainServiceLineId;

        this.props.wizardData.service.serviceLines.map(serviceLine => {

            // Activate removal for all lines by default
            lines.push({
                id: serviceLine.serviceLineId,
                planRelation: serviceLine.planRelation,
                action: "REMOVE",
            });

            // Store Main line id
            if (serviceLine.planRelation === "Main") {
                mainServiceLineId = serviceLine.serviceLineId;
            }
        });

        this.setState({
            allLines: lines,
            mainServiceLineId: mainServiceLineId,
        });
    }

    toggleRemoveAll = (activate, formProps) => {
        this.setState({removeAll: activate});
        formProps.setFieldValue('lines', activate ? this.state.allLines : []);
    }

    toggleLine = (activate, formProps, service) => {

        // If user clicks Main line
        if (service.planRelation === "Main") {

            // Uncheck all other toggles
            this.toggleRemoveAll(activate, formProps);
        }

        // If user clicks non-Main line
        else {

            let lines = formProps.values.lines;

            // Check
            if (activate) {

                // Add line
                lines.push({
                    id: service.serviceLineId,
                    planRelation: service.planRelation,
                    action: "REMOVE",
                });

                // Store lines
                formProps.setFieldValue('lines', lines);
            }

            // Uncheck
            else {

                // Find line and remove it
                lines = lines.filter(line => line.id !== service.serviceLineId);

                // Store lines
                formProps.setFieldValue('lines', lines);
            }
        }
    }

    handleApplyOnDropdown = () => {
        this.setState((prevState) => ({
            isApplyOnVisible: !prevState.isApplyOnVisible
        }));
    }

    handleChangeApplyOn = (value, setFieldValue) => {
        setFieldValue('applyOn', value);
        setFieldValue('applyDate', null);
    }

    handleSetApplyDate = (date, setFieldValue) => {

        if (date instanceof Date) {
            date = moment(date).format('YYYY-MM-DD');
        }

        if (date) {
            setFieldValue('applyDate', date);
        }
    }

    setDate = () => {
        this._calendar.setOpen(true);
        this.setState({isApplyOnVisible: false})
    }

    handleOnSubmit = (values, actions) => {

        let changeLineRequest = values;

        if (isEmpty(changeLineRequest.applyDate)) {
            delete changeLineRequest.applyDate;
        }

        this.props.changeServiceLine(changeLineRequest).then(response => {
            if (response && response.success) {
                let updatedValues = values;
                updatedValues['orderId'] = response.id;
                this.handleNext({...updatedValues});
            }
        });
    }

    handleNext = (values) => {
        const { next } = this.props;

        if (!next) {
            return;
        }

        next({
            values: values,
        });
    }

    render() {

        const {
            removeAll,
            allLines,
            mainServiceLineId,
            isApplyOnVisible,
            minDate,
        } = this.state;

        const {
            changeServiceLineLoading,
            wizardData,
        } = this.props;

        return (
            <>
                {allLines !== null &&
                <Formik
                    onSubmit={this.handleOnSubmit}
                    initialValues={{
                        name: 'SERVICE_REMOVE_SERVICELINES_' + generateId(),
                        source: 'CAMVIO-WEB',
                        type: 'REMOVE_LINES',
                        accountId: wizardData.accountInfo.id,
                        // existingServiceLineId: '',
                        // note: '',
                        applyOn: Object.keys(applyOnEnum)[0],
                        applyDate: null,
                        lines: allLines,
                    }}
                    render={(formProps) => (
                        <Form onSubmit={formProps.handleSubmit} className="cmv-form" autoComplete="off">

                            <div className="services-summary-applyon">

                                <h4>Apply On:</h4>

                                <div className="d-flex">

                                    {formProps.values.applyOn === Object.keys(applyOnEnum)[3] &&
                                    <div className="input-group input-group-sm mr-2">
                                        <DatePicker
                                            className="form-control form-control-sm datetimepicker-input"
                                            minDate={minDate}
                                            selected={formProps.values.applyDate && moment(formProps.values.applyDate, 'YYYY-MM-DD').toDate()}
                                            onChange={(date) => {
                                                this.handleSetApplyDate(date, formProps.setFieldValue);
                                                this.setDate();
                                            }}
                                            placeholderText="MM/DD/YYYY"
                                            name="applyDate"
                                            popperPlacement="bottom-end"
                                            ref={(c) => this._calendar = c}
                                            autoFocus={true}
                                            disabled={changeServiceLineLoading}
                                        />
                                    </div>
                                    }

                                    <div className={"dropdown" + (isApplyOnVisible ? ' show' : '')}>

                                        <button
                                            type="button"
                                            onClick={this.handleApplyOnDropdown}
                                            className="btn btn-outline-secondary btn-sm dropdown-toggle"
                                            role="button"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                            disabled={changeServiceLineLoading}
                                        >
                                            {applyOnEnum.valueOf()[formProps.values.applyOn]}
                                        </button>

                                        <div className={"dropdown-menu dropdown-menu-right" + (isApplyOnVisible ? ' show' : '')}>

                                        <button type="button" className="dropdown-item"
                                                disabled={formProps.values.applyOn === Object.keys(applyOnEnum)[0]}
                                                onClick={() => {
                                                    this.handleApplyOnDropdown();
                                                    this.handleChangeApplyOn(Object.keys(applyOnEnum)[0], formProps.setFieldValue);
                                                }}>
                                            {applyOnEnum.ORDER_COMPLETION}
                                        </button>

                                        <button type="button" className="dropdown-item"
                                                disabled={formProps.values.applyOn === Object.keys(applyOnEnum)[1]}
                                                onClick={() => {
                                                    this.handleApplyOnDropdown();
                                                    this.handleChangeApplyOn(Object.keys(applyOnEnum)[1], formProps.setFieldValue);
                                                }}>
                                            {applyOnEnum.START_OF_BILLCYCLE}
                                        </button>

                                        <button type="button" className="dropdown-item"
                                                disabled={formProps.values.applyOn === Object.keys(applyOnEnum)[2]}
                                                onClick={() => {
                                                    this.handleApplyOnDropdown();
                                                    this.handleChangeApplyOn(Object.keys(applyOnEnum)[2], formProps.setFieldValue);

                                                }}>
                                            {applyOnEnum.NEXT_BILLCYCLE}
                                        </button>

                                        <button type="button" className="dropdown-item"
                                                disabled={formProps.values.applyOn === Object.keys(applyOnEnum)[3]}
                                                onClick={() => {
                                                    // this.handleSetApplyDate(minDate, formProps.setFieldValue);
                                                    this.handleApplyOnDropdown();
                                                    this.handleChangeApplyOn(Object.keys(applyOnEnum)[3], formProps.setFieldValue);
                                                }}>
                                            {applyOnEnum.APPLY_ON_DATE}
                                        </button>

                                    </div>

                                    </div>

                                </div>

                            </div>

                            <ServiceOrder
                                serviceInfo={wizardData.service}
                                removeAll={removeAll}
                                toggleRemoveAll={this.toggleRemoveAll}
                                toggleLine={this.toggleLine}
                                formProps={formProps}
                                changeServiceLineLoading={changeServiceLineLoading}
                            />

                            <div className="wizard-footer d-flex justify-content-between">
                                <button
                                    type="submit"
                                    className="btn btn-primary btn-next-tab ml-auto"
                                    onClick={formProps.handleSubmit}
                                    disabled={
                                        changeServiceLineLoading ||
                                        isEmpty(formProps.values.lines) ||
                                        (formProps.values.applyOn === Object.keys(applyOnEnum)[3] && isEmpty(formProps.values.applyDate))
                                    }
                                >
                                    {changeServiceLineLoading
                                        ? <i className="fas fa-fw fa-spin fa-spinner" style={{marginLeft: '5px'}} />
                                        : "REMOVE"
                                    }
                                </button>
                            </div>

                        </Form>
                    )}
                />
                }
            </>
        )
    }
}

// const accountPlanLoader = createLoadingSelector(['GET_ACCOUNT_PLAN_SUMMARY']);
const changeServiceLineLoader = createLoadingSelector(['CHANGE_SERVICE_LINE']);

const mapStateToProps = (state) => {

    // const accountPlanLoading = accountPlanLoader(state);
    const changeServiceLineLoading = changeServiceLineLoader(state);

    return {
        // accountPlanLoading,
        changeServiceLineLoading,
    }
};

const mapDispatchToProps = {
    changeServiceLine,
    // getAccountPlanSummary,
    // clearAccountPlanSummary,
};

export default connect(mapStateToProps, mapDispatchToProps)(Lines);