import React from 'react';

const Card = ({ icon, header, body, redText, showPromiseIcon }) => {
	return (
		<p>
			{/*<i className={icon} />*/}
			{header}: <span className={(redText ? 'text-danger' : '')}>{body}</span>
			{showPromiseIcon && (<i className="text-danger fas fa-praying-hands" />)}
		</p>
	);
};

export default Card;
