import React, { Component, Fragment } from 'react';

class WizardButtons extends Component {
	render() {
        const {activeTab, isServiceRequestStarted, setFieldValue, isSubmitting, prevStep, goBack} = this.props;

		return (
			<div className="wizard-footer d-flex justify-content-between">
				{activeTab < 3 &&
				activeTab !== 0 && (
					<button
						className="btn btn-outline-primary btn-prev-tab"
						type="button"
						onClick={prevStep}
						disabled={isSubmitting}
					>
						Previous
					</button>
				)}
				{activeTab < 2 && (
					<button
						className="btn btn-outline-primary btn-next-tab ml-auto"
						type="submit"
						disabled={isSubmitting}
					>
						Next
					</button>
				)}
				{activeTab === 2 && (
					<Fragment>
						<button
							className="btn btn-outline-primary btn-next-tab ml-auto"
							type="submit"
							onClick={() => setFieldValue('initialStatus', 'PROSPECT')}
							disabled={isSubmitting}
						>
							Create Prospect
						</button>
						<button
							className="btn btn-primary btn-next-tab btn-next-tab-alt ml-1"
							type="submit"
							onClick={() => setFieldValue('initialStatus', 'ACTIVE')}
							disabled={isSubmitting}
						>
							Create Account
						</button>
					</Fragment>
				)}
				{activeTab === 3 &&
				!isServiceRequestStarted && (
					<button
						className="btn btn-outline-primary btn-next-tab ml-auto"
						type="button"
						onClick={goBack}
					>
						Finish
					</button>
				)}
			</div>
		);
	}
}

export default WizardButtons;
