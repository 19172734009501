import {DESTROY, INITIALIZE, NEXT_STEP, PREVIOUS_STEP, REGISTER_STEP, SET_STEP_STATE} from "./actionTypes";
import {cloneDeep, isEqual} from 'lodash';

export const NAME = 'wizards';

const initialState = {}

const getCurrentStep = (name, state = {}) => {
    const wizardState = state[name] || {};
    const currentStep = wizardState.currentStep;
    return wizardState.steps[currentStep];
}

const computePreviousStep = (currentStep, oldStack = []) => {
    if (currentStep.previous) {
        return currentStep.previous;
    }

    const currentStepStackIndex = oldStack.findIndex(st => st.name === currentStep.name);
    if (currentStepStackIndex > 0) {
        return oldStack[currentStepStackIndex - 1].name;
    }

    return null;
}

const computeNextStep = (currentStep, values = {}, wizardState) => {
    if (!currentStep.next) {
        return null;
    } else if (typeof currentStep.next === 'function') {
        const stepFn = currentStep.next;
        return stepFn(values, wizardState)
    } else if (typeof currentStep.next === 'string') {
        return currentStep.next;
    }

    return null;
}

const rollbackStackTo = (stepName, oldStack = []) => {
    const newStack = [ ...oldStack ];

    while (newStack.length !== 0) {
        const lastStackStep = newStack.pop();
        if (lastStackStep.name === stepName) {
            return [ ...newStack, lastStackStep ];
        }
    }

    return newStack;
}

const updateState = (originalState, newState, merge) => {
    if (!newState && merge) {
        return originalState;
    }

    if (typeof newState === 'function') {
        newState = newState(originalState || {});
    } else if (typeof newState !== 'object') {
        console.error(`Invalid step state type`);
        return originalState;
    }

    if (merge) {
        return {
            ...originalState,
            ...newState,
        }
    }

    return { ...newState };
}

export const wizardsReducer = (state = initialState, action) => {
    if (!action) {
        return state;
    }

    switch (action.type) {
        case INITIALIZE.SUCCESS: {
            const { name, firstStep } = action.payload || {}
            return {
                ...state,
                [name]: {
                    currentStep: firstStep,
                    steps: {},
                    stack: [{
                        name: firstStep,
                        timestamp: Date.now(),
                        state: {},
                        formValues: {},
                        values: {
                            current: {},
                            previous: {},
                            accumulated: {},
                        }
                    }]
                }
            }
        }
        case REGISTER_STEP.SUCCESS: {
            const { name, step } = action.payload || {}

            return {
                ...state,
                [name]: {
                    ...state[name],
                    steps: {
                        ...state[name].steps,
                        [step.name]: { ...step }
                    }
                }
            }
        }
        case PREVIOUS_STEP.SUCCESS: {
            const payload = action.payload || {};
            const name = payload.name;
            const statePayload = payload.statePayload || {};
            const formValues = statePayload.formValues;
            const stepState = statePayload.state;

            const currentStep = getCurrentStep(name, state);
            const wizardState = state[name];
            const previousStep = computePreviousStep(currentStep, wizardState.stack);

            if (!previousStep) {
                console.error(`${name} could not progress because the previousStep was null`);
                return state;
            }

            const currentStackStep = wizardState.stack.find(st => st.name === currentStep.name);
            const updatedCurrentStackStep = {
                ...currentStackStep,
                state: updateState(currentStackStep.state, stepState, true),
                formValues: updateState(currentStackStep.formValues, formValues, true),
            }

            const updatedStack = wizardState.stack.map(st => st.name === updatedCurrentStackStep.name? updatedCurrentStackStep : st);

            return {
                ...state,
                [name]: {
                    ...wizardState,
                    currentStep: previousStep,
                    stack: updatedStack
                }
            }
        }
        case NEXT_STEP.SUCCESS: {
            const payload = action.payload || {};
            const name = payload.name;
            const statePayload = payload.statePayload || {};
            const values = cloneDeep(statePayload.values);
            const formValues = statePayload.formValues;
            const stepState = statePayload.state;

            const wizardState = state[name];

            let currentStep = getCurrentStep(name, state);
            if (currentStep.finish) {
                console.error(`${name} could not progress because it is finished`);
                return state;
            }

            const nextStep = computeNextStep(currentStep, values, wizardState);
            if (!nextStep || !wizardState.steps[nextStep]) {
                console.error(`${name} could not progress because the nextStep was null`);
                return state;
            }

            const currentStackStep = wizardState.stack.find(st => st.name === currentStep.name);

            const updatedCurrentStackStep = {
                ...currentStackStep,
                state: updateState(currentStackStep.state, stepState, true),
                formValues: updateState(currentStackStep.formValues, formValues, true),
                values: {
                    ...currentStackStep.values,
                    previous: currentStackStep.values.current,
                    current: values,
                }
            };

            let updatedStack = wizardState.stack.map(st => st.name === updatedCurrentStackStep.name? updatedCurrentStackStep : st);

            if (!isEqual(updatedCurrentStackStep.values.previous, updatedCurrentStackStep.values.current)) {
                updatedStack = rollbackStackTo(currentStep.name, updatedStack);

                updatedStack.push({
                    name: nextStep,
                    timestamp: Date.now(),
                    state: {},
                    formValues: {},
                    values: {
                        current: {},
                        previous: {},
                        accumulated: {
                            ...updatedCurrentStackStep.values.accumulated,
                            ...updatedCurrentStackStep.values.current,
                        },
                    }
                });
            } else if (updatedStack[updatedStack.length - 1] === currentStep.name) {
                updatedStack.push({
                    name: nextStep,
                    timestamp: Date.now(),
                    state: {},
                    formValues: {},
                    values: {
                        current: {},
                        previous: {},
                        accumulated: {
                            ...updatedCurrentStackStep.values.accumulated,
                            ...updatedCurrentStackStep.values.current,
                        },
                    }
                });
            }

            return {
                ...state,
                [name]: {
                    ...wizardState,
                    currentStep: nextStep,
                    stack: updatedStack,
                }
            }
        }
        case SET_STEP_STATE.SUCCESS: {
            const payload = action.payload || {};
            const name = payload.name;
            const stepName = payload.stepName;
            const timestamp = payload.timestamp;
            const statePayload = payload.statePayload || {};
            const formValues = statePayload.formValues;
            const stepState = statePayload.state;

            const wizardState = state[name];
            if (!wizardState) {
                return state;
            }

            const step = wizardState.steps[stepName] && {...wizardState.steps[stepName]};
            if (!step) {
                console.error(`Step not found with name ${stepName}`);
                return state;
            }

            const stackStep = wizardState.stack.find(st => st.name === stepName);
            if (!stackStep || stackStep.timestamp > timestamp) {
                return state;
            }

            const updatedStack = wizardState.stack.map(st => {
                if (st.name === stackStep.name) {
                    return {
                        ...stackStep,
                        state: updateState(stackStep.state, stepState, true),
                        formValues: updateState(stackStep.formValues, formValues, true),
                    }
                }

                return st;
            })

            return {
                ...state,
                [name]: {
                    ...wizardState,
                    stack: updatedStack,
                }
            }

        }
        case DESTROY.SUCCESS: {
            const { name } = action.payload || {};
            const newState = {...state};
            delete newState[name];
            return newState;
        }
        default:
            return state;
    }
}

export default wizardsReducer;