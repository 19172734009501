import React, { Component, Fragment } from 'react';
import ReactHtmlParser from 'react-html-parser';
import ProductsServiceline from './ProductsServiceline';

export class ProductsPlan extends Component {
	render() {
		const { planInfo, values, planIndex, errors, touched } = this.props;

		return (
			<Fragment>
				<span className="accordion-section-header">
					<i className={planInfo.serviceModel.icon} />&nbsp;{ReactHtmlParser(planInfo.description)}
				</span>
				<div className="plan-wrapper" data-plan-wrapper-id="pl001">
					{values.plans[planIndex] &&
						values.plans[planIndex].servicelines.map((line, index) => (
							<ProductsServiceline
								lineInfo={line}
								errors={errors}
								tocuhed={touched}
								key={index}
								lineIndex={index}
								planIndex={planIndex}
								{...this.props}
							/>
						))}
				</div>
			</Fragment>
		);
	}
}

export default ProductsPlan;
