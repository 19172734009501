import axiosInstance from '../utils/axios-instance';
import { actionCreator, createRequestTypes } from '../utils/helpers';

export const REPORT_CATEGORIES = createRequestTypes('REPORT_CATEGORIES');
export const APP_INFO = createRequestTypes('APP_INFO');
export const CREATE_ACCOUNT = createRequestTypes('CREATE_ACCOUNT');

export const getReports = () => async (dispatch) => {
	dispatch(actionCreator.request(REPORT_CATEGORIES));
	try {
		const response = await axiosInstance.get(`/report/reports`);
		dispatch(actionCreator.success(REPORT_CATEGORIES, response.data.categories));
	} catch (err) {
		const mockError = {
			message: 'Something goes wrong'
		};
		dispatch(actionCreator.failure(REPORT_CATEGORIES, mockError));
	}
};

export const getAppInfo = () => async (dispatch) => {
	dispatch(actionCreator.request(APP_INFO));
	try {
		const response = await axiosInstance.get(`/common/appinfo`);
		dispatch(actionCreator.success(APP_INFO, response.data));
	} catch (err) {
		const mockError = {
			message: 'Something goes wrong'
		};
		dispatch(actionCreator.failure(APP_INFO, mockError));
	}
};
