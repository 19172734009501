import {
	ACCOUNT_TYPES,
	SECURITY_QUESTIONS,
	COUNTRY_STATES,
	ACCOUNT_TYPE_DETAILS,
	ACCOUNT_TERM_DETAILS,
	CREATE_ACCOUNT,
	SET_ACCOUNT_TYPE,
	GET_DISCOUNTS,
	GET_RECURRING_CREDITS,
	GET_CREATE_ACCOUNT_TAX_CODES
} from '../actions/createAccount.actions';

let initialState = {
	newAccountInfo: {},
	accountTypes: [],
	securityQuestions: [],
	countryStates: [],
	accountTerms: [],
	billCycles: '',
	defaultAccountId: '',
	accountTiers: [],
	accountTypeValidationRules: {},
	discounts: [],
	recurringCredits: [],
	taxCodes: []
};

export const createAccountReducer = (state = initialState, action) => {
	switch (action.type) {
		case CREATE_ACCOUNT.SUCCESS:
			return {
				...state,
				newAccountInfo: action.payload
			};
		case ACCOUNT_TYPES.SUCCESS:
			return {
				...state,
				accountTypes: action.payload
			};
		case ACCOUNT_TYPES.EMPTY:
			return {
				...state,
				accountTypes: []
			};
		case SECURITY_QUESTIONS.SUCCESS:
			return {
				...state,
				securityQuestions: action.payload
			};
		case COUNTRY_STATES.SUCCESS:
			return {
				...state,
				countryStates: action.payload
			};
		case ACCOUNT_TYPE_DETAILS.SUCCESS:
			return {
				...state,
				accountTerms: action.payload.accountTerms,
				billCycles: action.payload.allowedBillCycles,
				defaultAccountId: action.payload.id
			};
		case ACCOUNT_TERM_DETAILS.SUCCESS:
			return {
				...state,
				accountTiers: action.payload.accountTiers || []
			};
		case SET_ACCOUNT_TYPE.SUCCESS:
			return {
				...state,
				accountTypeValidationRules: action.payload,
				accountTerms: action.payload.accountTerms,
				billCycles: action.payload.allowedBillCycles,
				defaultAccountId: action.payload.id
			};
		case GET_DISCOUNTS.SUCCESS:
			return {
				...state,
				discounts: action.payload
			};
		case GET_RECURRING_CREDITS.SUCCESS:
			return {
				...state,
				recurringCredits: action.payload
			};
		case GET_CREATE_ACCOUNT_TAX_CODES.SUCCESS:
			return {
				...state,
				taxCodes: action.payload
			}
		case CREATE_ACCOUNT.EMPTY:
			return {
				...state,
				...initialState
			};

		default:
			return state;
	}
};
