import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {getDashboardData, createLoadingSelector, getErrorMessage, getUserPermission} from '../../../../selectors';
import {getAccounts, emptyAccounts, getCustomersServedHistory} from '../../../../actions/dashboardActions/customers.actions';
import { showModal, showAccessModal } from '../../../../actions/modal.actions';
import Loader from '../../../../components/Loader';
import SearchBar from './SearchBar';
import AccountsList from './AccountsList';

class Customers extends Component {

	state = {
		searchType: {
			label: 'Serviceline',
			value: 'SERVICELINE'
		},
		placeholder: 'Search account by Serviceline',
		term: '',
		isFetched: false
	}

	componentDidMount() {

		const searchTypeCustomers = localStorage.getItem('searchTypeCustomers');

		if (searchTypeCustomers) {
			this.changeSearchType(JSON.parse(searchTypeCustomers));
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.accountsData !== this.props.accountsData) {
			this.setState({
				isFetched: true
			});
		}

		// Store search type if changed
		if (prevState.searchType !== this.state.searchType) {
			localStorage.setItem("searchTypeCustomers", JSON.stringify({
				label: this.state.searchType.label,
				value: this.state.searchType.value,
				placeholder: this.state.placeholder,
			}));
		}
	}

	componentWillUnmount() {
		this.props.emptyAccounts();
	}

	searchAccounts = () => {
		const searchType = this.state.searchType.value;
		const term = this.state.term;

		this.props.getAccounts(searchType, term);
	};

	changeSearchType = (type) => {
		this.setState({
			searchType: {
				value: type.value,
				label: type.label
			},
			placeholder: type.placeholder
		});
	};

	changeTerm = (e) => {
		this.setState({
			term: e.target.value
		});
	};

	accountAccessModal = (id) => {
		this.props.showAccessModal(true);
		this.props.showModal('ACCOUNT_ACCESS_MODAL', id);
	};

	render() {
		const { searchType, term, placeholder, isFetched } = this.state;
		const { dashboardMenuCount, accountsData, isLoading, getCustomersServedHistory, newAccountPermission, searchHistoryPermissions } = this.props;

		return (
			<>

				<div className="tab-pane-caption">
					<h1 className="tab-pane-title">{dashboardMenuCount.customersServed || 0} Customers</h1>
					<p className="tab-pane-text">served in 24 hours</p>
				</div>

				<SearchBar
					searchAccounts={this.searchAccounts}
					changeSearchType={this.changeSearchType}
					changeTerm={this.changeTerm}
					searchType={searchType}
					term={term}
					placeholder={placeholder}
					getCustomersServedHistory={getCustomersServedHistory}
					newAccountPermission={newAccountPermission}
					searchHistoryPermissions={searchHistoryPermissions}
					{...this.props}
				/>

				{isLoading ? (
					<Loader />
				) : (
					<AccountsList
						accounts={accountsData}
						{...this.props}
						isFetched={isFetched}
						accountAccessModal={this.accountAccessModal}
					/>
				)}

			</>
		);
	}
}

Customers.Customers = {
	accountsData: PropTypes.array.isRequired,
	isLoading: PropTypes.bool,
	getCustomersServed: PropTypes.func,
	getAccounts: PropTypes.func,
	emptyAccounts: PropTypes.func,
	showModal: PropTypes.func,
	showAccessModal: PropTypes.func
};

const accountSearchLoader = createLoadingSelector([ 'DASHBOARD_ACCOUNT_SEARCH' ]);
const getSearchError = getErrorMessage([ 'DASHBOARD_ACCOUNT_SEARCH' ]);

const mapStateToProps = (state) => {
	const accountsData = getDashboardData(state).accounts,
		isLoading = accountSearchLoader(state),
		searchError = getSearchError(state),
		newAccountPermission = getUserPermission(state, 'CAM', 'CAM_ACC_ADD'),
		searchHistoryPermissions = getUserPermission(state, 'CAM', 'CAM_ACC_DETAIL');

	return {
		accountsData,
		isLoading,
		searchError,
		newAccountPermission,
		searchHistoryPermissions
	};
};

const mapDispatchToProps = {
	getCustomersServedHistory,
	getAccounts,
	emptyAccounts,
	showModal,
	showAccessModal
};

export default connect(mapStateToProps, mapDispatchToProps)(Customers);
