import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import { getAccountInformation, updateAccountInformation } from '../../../actions/accountDetailsActions/accountInformation.actions'
import {
    getAdmin,
    getModalData,
    createLoadingSelector,
    getAccountDetails
} from '../../../selectors';
import {Formik} from 'formik';
import isEmpty from "../../../utils/helpers";
import FormSelect from "../../../components/UI/FormSelect";
import {hideModal} from '../../../actions/modal.actions';
import axiosInstance from "../../../utils/axios-instance";
import { get } from 'lodash';

const managersAsOptions = (managers) => asOptions(managers, manager => {
   
    return {
        label: manager.firstName + " " + manager.lastName,
        value: JSON.stringify(manager)
    }
});

const asOptions = (source, mapper) => {
    if (!Array.isArray(source)) {
        return [];
    }

    return source.map(mapper);
}

const findSystemUserById = (systemUsers, id) => {
    return systemUsers && systemUsers.find(systemUser => systemUser.id === id);
}
const none = {id: -1, firstName: "None", lastName: "", username: "none"};

class AccountInformationModal extends Component {

    state = {
        alertMessage: '',
        resultsNumber: 20,
        searchTerm: '',
        selectedSystemUser: this.props.modalProps.accountInformation.currentAccountManager ? JSON.stringify(this.props.modalProps.accountInformation.currentAccountManager) : JSON.stringify(none),
        systemUserOptions: this.props.systemUsers ? this.props.systemUsers : [],
        fetchingSystemUsers: false
    };

    componentDidMount() {
        this.reloadSystemUsers();
    }

    formSubmit = (values, actions) => {
        let data = {
            accountId: values.accountId,
            accountManagerId: JSON.parse(values.currentAccountManagerId).id
        }

        this.setState({alertMessage: ''}, () => {
            this.props.updateAccountInformation(data).then((response) => {
                if (!response.data) {
                    actions.setSubmitting(false);
                    this.setState({alertMessage: 'Unknown error. Please try again later'});
                    return;
                }

                if (!response.data.success) {
                    actions.setSubmitting(false);
                    this.setState({alertMessage: response.data.error.message});
                    return;
                }

                this.props.hideModal();
                if (this.props.modalProps.onCloseCallback) {
                    this.props.modalProps.onCloseCallback();
                }
            });
        });
    }

    scrollDropdownIntoView = (e) => {
        const elementClicked = e.target.nodeName;
        const boundingElement = e.currentTarget;
        const modalBody = document.getElementsByClassName('modal-body')[0];

        if (elementClicked !== 'LABEL') {

            setTimeout(function () {
                if (boundingElement.getBoundingClientRect().bottom > modalBody.getBoundingClientRect().bottom) {
                    if (boundingElement.getBoundingClientRect().height < modalBody.getBoundingClientRect().height) {
                        boundingElement.scrollIntoView({block: "end"});
                    }
                }
            }, 200);
        }
    }

    reloadSystemUsers = () => {
        try {
			const getParams = {
                limit: this.state.resultsNumber,
                query: this.state.searchTerm
            };

			axiosInstance.get(`/admin/account-managers`, { params: getParams })
				.then(response => {   
                    let {selectedSystemUser} = this.state;
					const systemUserResults = response.data.accountManagers;
                    
                    selectedSystemUser = JSON.parse(selectedSystemUser)
                    
					if (selectedSystemUser) {
                        if(selectedSystemUser.id = -1) {
                            systemUserResults.sort((a, b) => a.firstName.localeCompare(b.firstName));
                            
                            systemUserResults.unshift(none);
                        } else {
                            const systemUser = systemUserResults.find(systemUser => systemUser.id === selectedSystemUser.id);
                            
                            if (!systemUser) {
                                systemUserResults.push(selectedSystemUser);
                            }

                            systemUserResults.sort((a, b) => a.firstName.localeCompare(b.firstName));
                            systemUserResults.unshift(none);
                        }

                    }
                   
                    this.setState({
						systemUserOptions: systemUserResults 
					})
				})
				.catch(errorResponse => {
					this.handlerErrorResponse(errorResponse);
				});
		} catch (err) {
			this.handlerErrorResponse(err);
        }
        
    }

    handlerErrorResponse = (errorResponse) => {
        this.setState({
			alertMessage: get(errorResponse, 'response.data.error.message', 'Something goes wrong'),
			systemUserOptions: []
		})
    }
    
    handleSystemUsersSearchChange = (value) => {
        if (this.state.searchTerm === value) {
            return;
        }

        this.setState({searchTerm: value}, () => {
            if (this.searchChangeTimeout) {
                clearTimeout(this.searchChangeTimeout);
            }

            this.searchChangeTimeout = setTimeout(() => {
                this.reloadSystemUsers();
            }, 300);
        });
    };

	handleOnSystemUserChange = setFieldValue => (options, newValue) => {
		const selectedSystemUser = findSystemUserById(this.state.systemUserOptions, JSON.parse(newValue).id);

        this.setState({selectedSystemUser: JSON.stringify(selectedSystemUser)});
    }
    
    render() {
        const { alertMessage} = this.state;
        const { accountId } = this.props;
        
        return (
            <Fragment>
            <div className="modal" style={{display: 'block'}} tabIndex="-1" role="dialog">

                <Formik
                    initialValues={{
                        accountId: accountId,
                        currentAccountManagerId: this.state.selectedSystemUser
                    }}
                    onSubmit={this.formSubmit}
                    render={({handleSubmit, handleBlur, values, touched, isSubmitting, setFieldValue}) => (
                        <form onSubmit={handleSubmit}>
                            <div className="modal-dialog">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title">
                                            EDIT ACCOUNT INFORMATION
                                        </h5>
                                        <button onClick={this.props.hideModal} type="button" className="close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <div className="form-horizontal">

                                            {!isEmpty(alertMessage) && (
                                                <div className="alert alert-inline alert-danger alert-dismissible">
                                                    <p className="mb-0">{alertMessage}</p>
                                                </div>
                                            )}                             

                                            <div className="form-group">
                                                <div onClick={this.scrollDropdownIntoView}>
                                                    <FormSelect
                                                        title="Account Manager"
                                                        fieldName="currentAccountManagerId"
                                                        options={managersAsOptions(this.state.systemUserOptions)}
                                                        setFieldValue={setFieldValue}
                                                        value={values.currentAccountManagerId}
                                                        onBlur={handleBlur}
                                                        touched={touched}
                                                        onInputChange={this.handleSystemUsersSearchChange}
                                                        onPostChange={this.handleOnSystemUserChange(setFieldValue)}
                                                    />
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button onClick={this.props.hideModal} className="btn" type="button">
                                            CANCEL
                                        </button>
                                        <button disabled={isSubmitting} type="submit" className="btn btn-primary">
                                            EDIT ACCOUNT
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    )}
                />
            </div>
            <div className="modal-backdrop show" tabIndex="1"/>
        </Fragment>
        );
    }
}

const accountInformationLoadingSelector = createLoadingSelector([ 'GET_ACCOUNT_INFORMATION' ]);

const mapStateToProps = (state) => {
   const accountInformation = getAdmin(state).accountInformation,
   modalProps = getModalData(state).modalProps,
   accountInformationLoader = accountInformationLoadingSelector(state),
   accountId = getAccountDetails(state).accountDetails.accountInfo.id

    return {
        accountId,
        modalProps,
        accountInformation,
        accountInformationLoader
    };
};

const mapDispatchToProps = {
    getAccountInformation,
    hideModal,
    updateAccountInformation
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountInformationModal);

