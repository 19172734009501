import axiosInstance from '../utils/axios-instance';
import { actionCreator, createRequestTypes } from '../utils/helpers';

export const SET_USER_SUMMARY = createRequestTypes('SET_USER_SUMMARY');
export const SET_USER_CONFIGURATION = createRequestTypes('SET_USER_CONFIGURATION');
export const SET_USER_LOCATION = createRequestTypes('SET_USER_LOCATION');
export const SET_USER_PERMISSION = createRequestTypes('SET_USER_PERMISSION');

export const getUserSummary = () => async (dispatch) => {
	dispatch(actionCreator.request(SET_USER_SUMMARY));
	try {
		const response = await axiosInstance.get(`/user/summary`);
		dispatch(actionCreator.success(SET_USER_SUMMARY, response.data.user));
		return response.data.user;
	} catch (err) {
		let mockError = {
			message: 'Something goes wrong'
		}

		dispatch(actionCreator.failure(SET_USER_SUMMARY, mockError));
		return err;
	}
};

export const emptyUserSummary = () => (dispatch) => {
	dispatch(actionCreator.empty(SET_USER_SUMMARY))
}

export const getUserConfiguration = () => async (dispatch) => {
	try {
		const response = await axiosInstance.get(`/domain/configuration`);
		dispatch(actionCreator.success(SET_USER_CONFIGURATION, response.data));
	} catch (err) {
		let mockError = {
			message: 'Something goes wrong'
		}

		dispatch(actionCreator.failure(SET_USER_CONFIGURATION, mockError));
		return err;
	}
};


export const getUserPermission = () => async (dispatch) => {
	dispatch(actionCreator.request(SET_USER_PERMISSION));
	try {
		const response = await axiosInstance.get(`/user/permissions`);
		dispatch(actionCreator.success(SET_USER_PERMISSION, response.data.modulePermissions));
	} catch (err) {
		let mockError = {
			message: 'Something goes wrong'
		}
		dispatch(actionCreator.failure(SET_USER_PERMISSION, mockError));
		return err;
	}
};


export const emptyUserPermission = () => (dispatch) => {
	dispatch(actionCreator.empty(SET_USER_PERMISSION))
}


export const changeUserLocation = (location) => {
	return (dispatch) => {
		// sessionStorage.setItem('location', JSON.stringify(location));
		localStorage.setItem('location', JSON.stringify(location));
		dispatch(actionCreator.success(SET_USER_LOCATION, location));
	};
};

export const changeUserPassword = async (newPassword) => {
	try {
		const response = await axiosInstance.post(`/user/changepassword`, newPassword);
		return response.data;
	} catch (err) {
		return err.response;
	}
};



export const emptyErrorMessages = () => (dispatch) => {
	dispatch(actionCreator.clear(SET_USER_SUMMARY))
	dispatch(actionCreator.clear(SET_USER_PERMISSION))
}
