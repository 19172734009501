import React, { Component } from 'react'
import DatePicker from "react-datepicker";
import moment from "moment";
import {isEmpty} from "../../../../../../utils/helpers";
import Notification from "./Notification";

export class NotificationFiltersBox extends Component {

    state = {
        isFilterDropDownOpen: false,
        filters: {
            accountId: this.props.accountId,
            fromDate: '',
            toDate: '',
            limit: 10
        },
        filterType: 'limit',
        filterValue: '10'
    };

    componentDidMount() {
        let copyFilters = { ...this.state.filters };

        this.setState(
           {
                 filters: copyFilters
              },
           () => this.sendGetAccountNotifications()
        );
    }

    handleClickDropdown = () => {
        this.setState((prevState) => ({
            isFilterDropDownOpen: !prevState.isFilterDropDownOpen
        }));
    };

    handleLimitFilter = (limit) => {
        let updateFilter = { ...this.state.filters };
        updateFilter['limit'] = limit;
        // remove date value
        updateFilter['toDate'] = '';
        updateFilter['fromDate'] = '';

        this.setState(
            {
                filters: updateFilter,
                isFilterDropDownOpen: false,
                filterType: 'limit',
                filterValue: limit
            },
            () => this.sendGetAccountNotifications()
        );
    };

    handleLastDaysFilter = (lastDays) => {
        let fromDate = moment().subtract(lastDays, 'days').toDate();
        let toDate = moment().toDate();

        let updateFilter = { ...this.state.filters };
        updateFilter['fromDate'] = fromDate;
        updateFilter['toDate'] = toDate;
        updateFilter['limit'] = 10;

        this.setState(
            {
                filters: updateFilter,
                isFilterDropDownOpen: false,
                filterType: 'lastDays',
                filterValue: lastDays
            },
            () => this.sendGetAccountNotifications()
        );
    };

    handleDateFilter = (value, name) => {
        let updateFilter = { ...this.state.filters };
        updateFilter[name] = value;
        if (value === null){
            updateFilter[name] = '';
        }
        updateFilter['limit'] = 10;

        this.setState(
            {
                filters: updateFilter,
                isFilterDropDownOpen: false,
                filterType: 'dateFromTo'
            },
            () => this.sendGetAccountNotifications()
        );
    };

    readableDateFormat = () => {
        const { filters } = this.state;
        let parseDateFormat = '';
        parseDateFormat += filters.fromDate && moment(filters.fromDate).format('MM/DD/YYYY');
        parseDateFormat += filters.toDate && ' - ' + moment(filters.toDate).format('MM/DD/YYYY');

        return parseDateFormat;
    };

    sendGetAccountNotifications = () => {
        let copyFilters = { ...this.state.filters };

        if (copyFilters['fromDate'] !== '') {
            copyFilters['fromDate'] = moment(copyFilters['fromDate']).format('YYYY-MM-DD');
        }
        if (copyFilters['toDate'] !== '') {
            copyFilters['toDate'] = moment(copyFilters['toDate']).format('YYYY-MM-DD');
        }

        for (let filter in copyFilters) {
            if (isEmpty(copyFilters[filter])) {
                delete copyFilters[filter];
            }
        }
        this.props.getAccountNotifications(this.props.accountId, copyFilters);
    };

    render() {
        const { filters, isFilterDropDownOpen, filterType } = this.state;
        const { notifications, showModal } = this.props;

        return (
            <div className="container-client-notifications-history">
            <div className="tab-pane-toolbar">
                <div className="dropdown dropdown-cn-timefilter">
                    <button
                        onClick={this.handleClickDropdown}
                        className={
                            isFilterDropDownOpen ? (
                                'btn btn-outline-secondary dropdown-toggle show'
                            ) : (
                                'btn btn-outline-secondary dropdown-toggle'
                            )
                        }
                        type="button"
                    >
                        {filterType === 'limit' && `Last ${this.state.filterValue} Notifications`}
                        {filterType === 'dateFromTo' && this.readableDateFormat()}
                        {filterType === 'lastDays' && `Last ${this.state.filterValue} days`}
                    </button>
                    <div
                        className={
                            isFilterDropDownOpen ? (
                                'dropdown-menu dropdown-menu-right show'
                            ) : (
                                'dropdown-menu dropdown-menu-right'
                            )
                        }
                    >
                        <button onClick={() => this.handleLimitFilter(10)} className="dropdown-item">
                            Last 10 Notifications
                        </button>
                        <button onClick={() => this.handleLimitFilter(20)} className="dropdown-item">
                            Last 20 Notifications
                        </button>
                        <button onClick={() => this.handleLimitFilter(50)} className="dropdown-item">
                            Last 50 Notifications
                        </button>
                        <button onClick={() => this.handleLimitFilter(100)} className="dropdown-item">
                            Last 100 Notifications
                        </button>
                        <div className="dropdown-divider" />
                        <button onClick={() => this.handleLastDaysFilter(30)} className="dropdown-item">
                            Last 30 Days
                        </button>
                        <button onClick={() => this.handleLastDaysFilter(60)} className="dropdown-item">
                            Last 60 Days
                        </button>
                        <button onClick={() => this.handleLastDaysFilter(90)} className="dropdown-item">
                            Last 90 Days
                        </button>
                        <div className="dropdown-divider" />
                        <h6 className="dropdown-header">From</h6>

                        <div className="form-group col">
                            <div
                                className="input-group input-group-sm date"
                                id="datetimepicker-notif-filter-1"
                                data-target-input="nearest"
                            >
                                <DatePicker
                                    className="form-control datetimepicker-input"
                                    selected={filters.fromDate}
                                    onChange={(value) => this.handleDateFilter(value, 'fromDate')}
                                    placeholderText="MM/DD/YYYY"
                                    name="fromDate"
                                   popperPlacement="top-end"
                                   popperModifiers={{
                                       offset: {
                                           enabled: true,
                                           offset: "5px, 10px"
                                       },
                                       preventOverflow: {
                                           enabled: true,
                                           escapeWithReference: false,
                                           boundariesElement: "viewport"
                                       }
                                   }}
                                />
                            </div>
                        </div>

                        <h6 className="dropdown-header">To</h6>

                        <div className="form-group col">
                            <div
                                className="input-group input-group-sm date"
                                id="datetimepicker-notif-filter-2"
                                data-target-input="nearest"
                            >
                                <DatePicker
                                    className="form-control datetimepicker-input"
                                    selected={filters.toDate}
                                    onChange={(value) => this.handleDateFilter(value, 'toDate')}
                                    placeholderText="MM/DD/YYYY"
                                    name="toDate"
                                    popperPlacement="top-end"
                                    popperModifiers={{
                                        offset: {
                                            enabled: true,
                                            offset: "5px, 10px"
                                        },
                                        preventOverflow: {
                                            enabled: true,
                                            escapeWithReference: false,
                                            boundariesElement: "viewport"
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="dropdown-divider" />
                <div className="w-100" />
            </div>
               <ul className="client-notes client-notes-no-img client-notes-h-constrained">
                  {notifications.map((notification) => (
                     <Notification key={notification.id} notificationInfo={notification} showModal={showModal} />
                  ))}
               </ul>
        </div>
        )
    }
}

export default NotificationFiltersBox
