import React, { Component, Fragment } from 'react';
import {connect} from "react-redux";
import {getReports} from '../../actions/sideBar.actions';
import {getReportUrl} from '../../actions/commonRequest';
import {getSideBar} from "../../selectors";
import isEmpty from "../../utils/helpers";
import Iframe from 'react-iframe';
import Loader from '../../components/Loader';

class Reports extends Component {

	state = {
		reportName: null,
		reportUrl: '',
		isFetching: true,
		errorMessage: ''
	};

	componentDidMount() {
		this.fetchReport();
	}

	componentDidUpdate(prevProps) {

		if (!this.state.reportName) {
			this.setPageTitle();
		}
		if (prevProps.location !== this.props.location) {
			this.setPageTitle();
			this.fetchReport();
		}
	}

	setPageTitle = () => {

		let pageTitle = "Reports";

		if (!isEmpty(this.props.reportCategories)) {

			this.props.reportCategories.map(category => category.reports.map(report => {

				if (report.id == this.props.match.params.id) {

					pageTitle = report.name;
					this.setState({reportName: pageTitle});
				}
			}));
		}
		else {
			this.props.getReports();
		}

		// Change navbar type and color
		document.title = 'Reports - camvio.cloud';
		this.props.changeNavBarType('report', 'report', pageTitle);
	}

	fetchReport = () => {
		const reportId = Number(this.props.match.params.id);
		this.setState(
			{
				isFetching: true
			},
			() => {
				getReportUrl(reportId).then((response) => {
					if (response.success) {
						this.setState({
							reportUrl: response.embedUrl,
							isFetching: false,
							errorMessage: ''
						});
					} else {
						this.setState({
							reportUrl: '',
							isFetching: false,
							errorMessage: response.error.message
						});
					}
				});
			}
		);
	};


	render() {
		const { reportUrl, isFetching, errorMessage } = this.state;

		if (isFetching) {
			return <Loader />;
		}

		if (errorMessage) {
			return <p>{errorMessage}</p>;
		}

		return (
			<Fragment>
				{reportUrl.length > 0 && (
					<Iframe
						url={reportUrl}
						width="100%"
						height="100%"
						id="myId"
						className="embedded-report-container"
						display="initial"
						position="relative"
						scrolling="no"
					/>
				)}
			</Fragment>
		);
	}
}

const mapStateToProps = (state) => {

	const reportCategories = getSideBar(state).reportCategories;

	return {
		reportCategories,
	};
};

const mapDispatchToProps = {
	getReports,
};

export default connect(mapStateToProps, mapDispatchToProps)(Reports);
