import React, {Component} from 'react';
import {connect} from 'react-redux';
import { hideModal, showModal } from '../../actions/modal.actions';
import AutoPayJobsDisplay from "./AutoPayJobsDisplay";

class AutoPayJobs extends Component {

    componentDidMount() {
        // Change navbar type and color
        document.title = 'AutoPay Jobs - camvio.cloud';
        this.props.changeNavBarType('default', 'AutoPay Jobs');
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isShowAccessModal !== this.props.isShowAccessModal && this.props.isShowAccessModal === true) {
        }
    }

    componentWillUnmount() {
        this.props.hideModal();
    }

    render() {
        return (
            <div className="cmv-container cmv-container-dashboard cmv-container-client-selected">
                {/*<AccountInfoBox accountInfo={accountInfo} hideCardsContainer={true} />*/}
                <AutoPayJobsDisplay/>
            </div>
        );
    }
}

const mapStateToProps = (state) => {

    return {
    };
};

const mapDispatchToProps = {
    showModal,
    hideModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(AutoPayJobs);
