import axiosInstance from '../utils/axios-instance';
import {actionCreator, createRequestTypes} from '../utils/helpers';

export const GET_WORKFLOW_ORDERS = createRequestTypes('GET_WORKFLOW_ORDERS');
export const GET_WORKFLOW_EVENTS = createRequestTypes('GET_WORKFLOW_EVENTS');
export const GET_WORKFLOW_TRANSITIONS = createRequestTypes('GET_WORKFLOW_TRANSITIONS');
export const GET_WORKFLOW_IMAGE = createRequestTypes('GET_WORKFLOW_IMAGE');
export const GET_WORKFLOW_LIST = createRequestTypes('GET_WORKFLOW_LIST');
export const GET_WORKFLOW_STATUSES = createRequestTypes('GET_WORKFLOW_STATUSES');
export const GET_WORKFLOW_BPMN_FILE = createRequestTypes('GET_WORKFLOW_BPMN_FILE');
export const UPDATE_WORKFLOW_BPMN_FILE = createRequestTypes('UPDATE_WORKFLOW_BPMN_FILE');

export const getWorkflowOrders = () => async (dispatch) => {
	dispatch(actionCreator.request(GET_WORKFLOW_ORDERS));
	try {
		const response = await axiosInstance.get(`/workflow/serviceorderworkflow`);
		dispatch(actionCreator.success(GET_WORKFLOW_ORDERS, response.data));
		return response.data;
	} catch (err) {
		let mockError = {message: 'Something goes wrong'}
		dispatch(actionCreator.failure(GET_WORKFLOW_ORDERS, mockError));
	}
}

export const clearWorkflowOrders = () => async (dispatch) => {
	dispatch(actionCreator.clear(GET_WORKFLOW_ORDERS))
}

export const getWorkflowEvents = () => async (dispatch) => {
	dispatch(actionCreator.request(GET_WORKFLOW_EVENTS));
	try {
		const response = await axiosInstance.get(`/workflow/workflowstartevent`);
		dispatch(actionCreator.success(GET_WORKFLOW_EVENTS, response.data));
		return response.data;
	} catch (err) {
		let mockError = {message: 'Something goes wrong'}
		dispatch(actionCreator.failure(GET_WORKFLOW_EVENTS, mockError));
	}
}

export const clearWorkflowEvents = () => async (dispatch) => {
	dispatch(actionCreator.clear(GET_WORKFLOW_EVENTS))
}

export const getWorkflowTransitions = () => async (dispatch) => {
	dispatch(actionCreator.request(GET_WORKFLOW_TRANSITIONS));
	try {
		const response = await axiosInstance.get(`/workflow/status-transition`);
		dispatch(actionCreator.success(GET_WORKFLOW_TRANSITIONS, response.data));
		return response.data;
	} catch (err) {
		let mockError = {message: 'Something goes wrong'}
		dispatch(actionCreator.failure(GET_WORKFLOW_TRANSITIONS, mockError));
	}
}

export const clearWorkflowTransitions = () => async (dispatch) => {
	dispatch(actionCreator.clear(GET_WORKFLOW_TRANSITIONS))
}

export const getWorkflowImage = (processDefinitionId) => async (dispatch) => {
	dispatch(actionCreator.request(GET_WORKFLOW_IMAGE));
	try {
		const response = await axiosInstance.get(`/workflow/${processDefinitionId}/image`, {responseType: 'blob'});
		dispatch(actionCreator.success(GET_WORKFLOW_IMAGE, response));
		return response;
	} catch (err) {
		let mockError = {message: 'Something goes wrong'}
		dispatch(actionCreator.failure(GET_WORKFLOW_IMAGE, mockError));
		return err.response;
	}
}

export const getWorkFlowBPMNFile = (processDefinitionId) => async (dispatch) => {
	dispatch(actionCreator.request(GET_WORKFLOW_BPMN_FILE));
	try {
		const response = await axiosInstance.get(`/workflow/${processDefinitionId}/download`, {responseType: 'blob'});
		dispatch(actionCreator.success(GET_WORKFLOW_BPMN_FILE, response));
		return response;
	} catch (err) {
		let mockError = {message: 'Something goes wrong'}
		dispatch(actionCreator.failure(GET_WORKFLOW_BPMN_FILE, mockError));
		return err.response;
	}
}

export const uploadWorkFlowBPMNFile = (processDefinition) => async (dispatch) => {
	dispatch(actionCreator.request(UPDATE_WORKFLOW_BPMN_FILE));
	try {
		const response = await axiosInstance.post(`/workflow/processdefinition/bpmn`, processDefinition);
		dispatch(actionCreator.empty(UPDATE_WORKFLOW_BPMN_FILE));
		return response;
	} catch (err) {
		let mockError = {message: err.response.data.error.message};
		dispatch(actionCreator.failure(UPDATE_WORKFLOW_BPMN_FILE, mockError));
		return err.response.data;
	}
}

export const getWorkflowList = () => async (dispatch) => {
	dispatch(actionCreator.request(GET_WORKFLOW_LIST));
	try {
		const response = await axiosInstance.get(`/workflow/processdefinition/list`);
		dispatch(actionCreator.success(GET_WORKFLOW_LIST, response.data));
		return response.data;
	} catch (err) {
		let mockError = {message: 'Something goes wrong'}
		dispatch(actionCreator.failure(GET_WORKFLOW_LIST, mockError));
	}
}

export const clearWorkflowList = () => async (dispatch) => {
	dispatch(actionCreator.clear(GET_WORKFLOW_LIST))
}

export const getWorkflowStatuses = () => async (dispatch) => {
	dispatch(actionCreator.request(GET_WORKFLOW_STATUSES));
	try {
		const response = await axiosInstance.get(`/workflow/workflowdefinitionstatus`);
		dispatch(actionCreator.success(GET_WORKFLOW_STATUSES, response.data));
		return response.data;
	} catch (err) {
		let mockError = {message: 'Something goes wrong'}
		dispatch(actionCreator.failure(GET_WORKFLOW_STATUSES, mockError));
	}
}

export const clearWorkflowStatuses = () => async (dispatch) => {
	dispatch(actionCreator.clear(GET_WORKFLOW_STATUSES))
}
