import React, { Component } from 'react';
import { ClipLoader } from 'react-spinners';
import { css } from '@emotion/core';

const override = css`
display: block;
margin: 0 auto;
border-color: red;
`;

class FullScreenLoader extends Component {
	render() {
		return (
			<div className="fullScreenLoader">
				<ClipLoader css={override} sizeUnit={'px'} size={80} color={'#6ab3c1'} loading={true} />
			</div>
		);
	}
}

export default FullScreenLoader;
