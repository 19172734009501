import React from 'react';
import { get } from 'lodash';

const FormikInput = ({
	type,
	name,
	label,
	isRequired,
	errors,
	touched,
	placeholder,
	wrapperClass,
	value,
	onChange,
	status,
	...props
}) => {
	return (
		<div className={wrapperClass}>
			<label htmlFor={name}>{label}</label>

			<input
				className={
					get(errors, `${name}`) && get(touched, `${name}`) ? 'form-control is-invalid' : 'form-control'
				}
				name={name}
				placeholder={placeholder}
				type={type}
				value={value}
				onChange={onChange}
				autoComplete="new-password"
				{...props}
			/>
			{get(errors, `${name}`) &&
			get(touched, `${name}`) && <div className="invalid-feedback">{get(errors, `${name}`)}</div>}
			{get(status, `${name}`) && <div className="invalid-feedback">{get(status, `${name}`)}</div>}
		</div>
	);
};

export default FormikInput;
