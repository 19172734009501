import React from 'react';
import {get} from 'lodash';

const TextInput = ({ type, name, label, isRequired, errors, touched, placeholder, divClass, value, disabled, autocomplete, onChange, onBlur, status, ...props }) => {
	// let notificationType=label;
	// if (label !== undefined) {
	// 	notificationType=label.split('#')[1];
	// }
	return (
		<div className={`form-group col-xs ${divClass}`} style={{width:'100%'}}>
			{label &&
			<label htmlFor={name}>
				{label}
				{/* <span className="client-note-toolbar" style={{marginLeft:'10px'}}>
                    <span className="badge badge-outline-primary">{notificationType}</span>
                </span> */}
				{isRequired && <span className="text-danger">*</span>}
			</label>
			}

			<input
				className={(get(status, `${name}`) || get(errors, `${name}`)) && get(touched, `${name}`)
					? 'form-control is-invalid'
					: 'form-control'}
				id={name}
				name={name}
				placeholder={placeholder}
				type={type}
				value={value}
				onChange={onChange}
				onBlur={onBlur}
				disabled={disabled}
				autoComplete={`${autocomplete?autocomplete:"off"}`}
				{...props}
			/>

			{get(status, `${name}`)
			? <div className="invalid-feedback">{get(status, `${name}`)}</div>
			: (get(errors, `${name}`) && get(touched, `${name}`)
			&& <div className="invalid-feedback">{get(errors, `${name}`)}</div>
			)}

		</div>
	);
};

export default TextInput;
