import { SET_VIEW_AS_PARTNER, SET_VIEW_AS_LOCATION } from '../actions/viewAs.actions';
import { getLocation, getPartner } from "../utils/viewAsStorage";

let initialState = {
	partner: getPartner(),
	location: getLocation(),
};

export const viewAsReducer = (state = initialState, action) => {
	switch (action.type) {

		case SET_VIEW_AS_PARTNER.SUCCESS:

			const s = {
				...state,
				partner: action.payload
			};

			return s;

		case SET_VIEW_AS_LOCATION.SUCCESS:
			return {
				...state,
				location: action.payload
			};

		default:
			return state;
	}
};
