import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser';
import Notes from './../../../../../../Dashboard/Content/Orders/Notes/Notes';
import Logs from './Logs';
import StackedBar from '../../../../../../../components/UI/OrdersAndQuotes/StackedBar';
import { parseDateTimeFormat } from '../utils';
import Summary from '../../../../../../Dashboard/Content/Orders/Summary/Summary'
import TargetDateTime from "../../../../../../../components/UI/TargetDateTime";
import { getUserPermission } from "../../../../../../../selectors";
import { connect } from "react-redux";
import { showModal } from "../../../../../../../actions/modal.actions";
import { manuallyTriggerReceiveTask} from "../../../../../../../actions/dashboardActions/orders.actions";
import {toastr} from "react-redux-toastr";
import Tasks from "./Tasks";
import isEmpty from "../../../../../../../utils/helpers";

export class Service extends Component {

	state = {
		isServiceExpanded: false,
		isDescriptionExpanded: false,
		isNotesExpanded: false,
		isTasksExpanded: false,
		isLogVisible: false,
		isDropDownMenuVisible: false,
		isServiceOrderCancelled: false,
		isServiceOrderCompleted: false,
		isEditButtonClicked: false,
		isCancelButtonClicked: false,
		isManuallyConfirmButtonClicked: false
	};

	componentDidMount() {
		const {history, order, serviceInfo, isStarted, isCompleted} = this.props;

		// Handle cancelled order
		if (this.props.serviceInfo.workflowStatusChain[0].groupState === "CANCELLED") {
			this.setState({isServiceOrderCancelled: true})
		}

		// Handle completed order
		if (this.props.serviceInfo.hasOwnProperty('workflowEndDate')) {
			this.setState({isServiceOrderCompleted: true})
		}

		// Reopen tasks if going back from task details
		if (history.location.state && history.location.state.serviceOrderId === serviceInfo.id) {

			this.setState({isTasksExpanded: true});

			// Wait for state update
			setTimeout(() => {

				// Scroll service order into view
				document.querySelectorAll('[data-serviceorder-id="'+serviceInfo.id+'"]')[0]
					.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});

				// Remove history state so we don't reopen/scroll again
				history.replace({state: undefined});

			}, 100);
		}

		// Open all tasks if coming here from New Order Wizard
		if (history.location.state && history.location.state.orderId === order.id) {

			if (isStarted && !isCompleted) {
				this.setState({isTasksExpanded: true});
			}

			// Remove history state so we don't reopen tasks again
			history.replace({state: undefined});
		}
	}

	handlerLogShowHide = () => {
		this.setState((prevState) => ({
			isLogVisible: !prevState.isLogVisible
		}));
	};

	handlerServiceExpand = () => {
		this.setState((prevState) => ({
			isServiceExpanded: !prevState.isServiceExpanded,
			isDescriptionExpanded: false,
			isTasksExpanded: false,
		}));
	};

	handlerDescriptionExpand = () => {
		this.setState((prevState) => ({
			isServiceExpanded: false,
			isDescriptionExpanded: !prevState.isDescriptionExpanded,
			isNotesExpanded: false,
		}));
	};

	handlerNotesExpand = () => {
		this.setState((prevState) => ({
			isServiceExpanded: false,
			isDescriptionExpanded: false,
			isNotesExpanded: !prevState.isNotesExpanded,
		}));
	};

	handlerTasksExpand = () => {
		this.setState((prevState) => ({
			isTasksExpanded: !prevState.isTasksExpanded,
			isServiceExpanded: false
		}));
	};

	handleEdit = () => {
		const {order, serviceInfo} = this.props;

		// Disable buttons
		this.setState({isEditButtonClicked: true});

		// Show wizard
		this.props.showServiceUpdateRequestWizardHandler(serviceInfo, order);
	};

	handleCancelServiceOrder = () => {
		const {order, serviceInfo} = this.props;

		toastr.confirm("Are you sure you want to cancel Service Order #" + serviceInfo.id + "?", {
			cancelText: "No",
			okText: "Yes",
			onOk: () => {

				// Disable buttons
				this.setState({isCancelButtonClicked: true});

				// Cancel Service Order
				this.props.cancelServiceOrder(serviceInfo.id, order.id).then(response => {
					this.setState({isCancelButtonClicked: false});

					if (response.success) {
						toastr.success(
							"Successfully canceled Service Order #"+serviceInfo.id,
							{timeOut: 2000, position: 'top-center'}
						);
						this.props.parentCallback();
					}
					else {
						toastr.error(
							"There was an error trying to cancel Service Order #"+serviceInfo.id,
							{timeOut: 2000, position: 'top-center'}
						);
					}
				}).catch(error => {
					this.setState({isCancelButtonClicked: false});
					toastr.error(
						"There was an error trying to cancel Service Order #"+serviceInfo.id,
						{timeOut: 2000, position: 'top-center'}
					);
				});
			}});
	};

	handleManuallyConfirmTask = (workflowTaskId) => {
		let { serviceInfo, order, history } = this.props

		toastr.confirm("Are you sure you want to manually confirm this task ?", {
			cancelText: "No",
			okText: "Yes",
			onOk: () => {

				// Disable button
				this.setState({isManuallyConfirmButtonClicked: true});

				let workflowReceiveTaskRequest = {
					workflowTaskId : workflowTaskId
				}

				this.props.manuallyTriggerReceiveTask(serviceInfo.id, workflowReceiveTaskRequest).then(response => {
					this.setState({isManuallyConfirmButtonClicked: false});

					if (response.success) {
						toastr.success(
							"Successfully confirmed task",
							{timeOut: 2000, position: 'top-center'}
						);
						history.replace({state: {
								activeTab: 'ordersAndQuotes',
								orderId: order.id,
							}});
						this.props.parentCallback();
					}
					else if(response.error) {
						toastr.error(
							response.error.message,
							{timeOut: 2000, position: 'top-center'}
						);
					}
				}).catch(error => {
					this.setState({isManuallyConfirmButtonClicked: false});
					toastr.error(
						"There was an error trying to manually confirm this task",
						{timeOut: 2000, position: 'top-center'}
					);
				});
			}});

	}

	handleDropDownMenu = () => {
		this.setState((prevState) => ({
			isDropDownMenuVisible: !prevState.isDropDownMenuVisible
		}));
	};

	renderIconStatus = (statuses) => {
		let icon;
		let findProcessingStatus = statuses.find((x) => x.groupState === 'PROCESSING');
		let findCancelledStatus = statuses.find((x) => x.groupState === 'CANCELLED');
		if (findProcessingStatus) {
			if (findProcessingStatus.currentStatus.processingStatus === 'ERROR') {
				icon = <i className="fas fa-exclamation-triangle text-danger" />;
			} else {
				icon = <i className="fas fa-list-ul" />;
			}
		}
		else if (findCancelledStatus) {
			icon = <i className="fas fa-ban text-danger" />;
		}
		else {
			icon = <i className="fas fa-list-ul" />;
		}

		return icon;
	};

	renderManualConfirmationButton = (statuses) => {
		let icon;
		let findProcessingStatus = statuses.find((x) => x.groupState === 'PROCESSING');
		if (findProcessingStatus) {
			if (findProcessingStatus.receiveTask && findProcessingStatus.receiveTask == true) {
				icon = <button
					title="Show Service Order Tasks"
					onClick={() => this.handleManuallyConfirmTask(findProcessingStatus.workflowTaskId)}
					className="btn btn-warning"
					disabled={this.state.isManuallyConfirmButtonClicked}
				>
					<i className={this.state.isManuallyConfirmButtonClicked ? "fas fa-spin fa-spinner" : "fas fa-tools" }/>
					&nbsp;Confirm Manually
				</button>
			}
		}
		return icon;
	};


	checkIfExist = (serviceLines, type) => {
		let isExist = false;
		serviceLines.forEach((element) => {
			if (element[type] && element[type].length > 0) {
				isExist = true;
			}
		});
		return isExist;
	};

	render() {

		const {
			isServiceExpanded,
			isDescriptionExpanded,
			isNotesExpanded,
			isTasksExpanded,
			isServiceOrderCancelled,
			isServiceOrderCompleted,
			isLogVisible,
			isDropDownMenuVisible,
			isEditButtonClicked,
			isCancelButtonClicked,
		} = this.state;

		const {
			isCancelled,
			isCompleted,
			isExecuteButtonClicked,
			serviceInfo,
			canUpdateTargetDateTime,
			canCancelServiceOrders,
			parentCallback,
			order,
			canEditServiceAddress
		} = this.props;

		return (
			<div className="order-service-order-wrapper" data-serviceorder-id={serviceInfo.id}>
				<div className="order-header">

					<div className="order-service-order-status">

						<StackedBar statuses={serviceInfo.workflowStatusChain} />

						<span className="d-flex text-muted">
							{/* <small className="d-md-inline-block">
								<i className="far fa-clock" /> Completed in 2 hours
							</small> */}
							<small className="d-md-inline-block ml-auto">
								{serviceInfo.workflowDescription}{' '}
								<button onClick={this.handlerLogShowHide} className="btn btn-xs btn-order-log-toggle">
									{this.renderIconStatus(serviceInfo.workflowStatusChain)}
								</button>
							</small>
						</span>

					</div>

					{isLogVisible === true &&
					<Logs serviceOrderId={serviceInfo.id} />
					}

					<div className="row">
						<div className="col-12 col-sm-4 col-md-2">

							<div className="order-info">
								<TargetDateTime targetDateTime={serviceInfo.targetDateTime} />
								<span>
									{'Updated: '}
									<span className="font-weight-bold">
										{serviceInfo.modifiedDateTime && parseDateTimeFormat(serviceInfo.modifiedDateTime)}
									</span>
								</span>
								<span className="font-weight-bold">SO#{serviceInfo.id}</span>
								&nbsp;{'| '}
								{serviceInfo.modifiedBy}
							</div>

						</div>
						<div className="col-12 col-sm-8 col-md-6">

							<div className="order-title">
								<p><i className={serviceInfo.icon} />&nbsp;{serviceInfo.description}</p>
								<span className="text-muted">{serviceInfo.serviceAddress}</span>
							</div>

						</div>
						<div className="col-12 col-md-4">

							<div className="order-toolbar">
								<div className="btn-group btn-group-sm">

									{!isServiceOrderCancelled && serviceInfo.activeUserTasks > 0 && (
									<button
										title="Show Service Order Tasks"
										onClick={this.handlerTasksExpand}
										className="btn btn-warning"
									>
										<i className={isTasksExpanded === true ? 'fas fa-angle-double-up' : 'fas fa-angle-double-down'} />
										&nbsp;{serviceInfo.activeUserTasks} {serviceInfo.activeUserTasks > 1 ? 'Tasks' : 'Task'}
									</button>
									)}

									{/*<button className="btn btn-outline-secondary" disabled="">*/}
									{/*	<i className="fas fa-edit" />*/}
									{/*</button>*/}
									{/*<div className={isDropDownMenuVisible === true ? 'btn-group show' : 'btn-group'}>*/}
									{/*	<button*/}
									{/*		className="btn btn-outline-secondary dropdown-toggle"*/}
									{/*		type="button"*/}
									{/*		onClick={this.handleDropDownMenu}*/}
									{/*	>*/}
									{/*		<i className="fas fa-share" />*/}
									{/*	</button>*/}
									{/*	<div*/}
									{/*		className={*/}
									{/*			isDropDownMenuVisible === true ? (*/}
									{/*				'dropdown-menu dropdown-menu-right show'*/}
									{/*			) : (*/}
									{/*				'dropdown-menu dropdown-menu-right'*/}
									{/*			)*/}
									{/*		}*/}
									{/*		aria-labelledby="dropdownMenuButton"*/}
									{/*	>*/}
									{/*		<a className="dropdown-item">E-Mail</a>*/}
									{/*		<a className="dropdown-item">Print</a>*/}
									{/*	</div>*/}
									{/*</div>*/}
									{this.renderManualConfirmationButton(serviceInfo.workflowStatusChain)}
									{canUpdateTargetDateTime &&
									<button
										title="Update Target Date"
										className="btn btn-outline-secondary"
										disabled={
											isCancelled ||
											isCompleted ||
											isExecuteButtonClicked ||
											isEditButtonClicked ||
											isCancelButtonClicked ||
											isServiceOrderCancelled ||
											isServiceOrderCompleted
										}
										onClick={() =>
											this.props.showModal('TARGET_DATE_MODAL', {
												serviceOrderId: serviceInfo.id,
												targetDateTime: serviceInfo.targetDateTime,
												parentCallback: parentCallback
											})}
									>
										<i className="far fa-calendar-check"/>
									</button>
									}

									<button
										title="Edit Service Order"
										onClick={this.handleEdit}
										className="btn btn-outline-secondary"
										disabled={
											isCancelled ||
											isCompleted ||
											isExecuteButtonClicked ||
											isEditButtonClicked ||
											isCancelButtonClicked ||
											isServiceOrderCancelled ||
											isServiceOrderCompleted
										}
									>
										<i className={isEditButtonClicked ? "fas fa-spin fa-spinner" : "fas fa-edit"} />
									</button>

									{canCancelServiceOrders &&
									<button
										title="Cancel Service Order"
										className="btn btn-outline-secondary"
										disabled={
											isCancelled ||
											isCompleted ||
											isExecuteButtonClicked ||
											isEditButtonClicked ||
											isCancelButtonClicked ||
											isServiceOrderCancelled ||
											isServiceOrderCompleted
										}
										onClick={this.handleCancelServiceOrder}
									>
										<i className={isCancelButtonClicked ? "fas fa-spin fa-spinner" : "fas fa-ban"} />
									</button>
									}
									<button
										title="Show Mapping Information"
										className="btn btn-outline-secondary"
										onClick={() => this.props.showModal('SERVICE_ADDRESS_MODAL', {
											title: "Service Address Information",
											id: serviceInfo.id || '',
											addressId: serviceInfo.addressId || '',
											type: "SERVICE_ORDER",
											canViewCSIGeocode: false,
											canEditCSIGeocode: false,
											isDisabled: !canEditServiceAddress,
											service:{
												address1: serviceInfo.address1 || '',
												address2: serviceInfo.address2 || '',
												address3: serviceInfo.address3 || '',
												city: serviceInfo.city || '',
												latitude: serviceInfo.latitude || '',
												longitude: serviceInfo.longitude || '',
												mappingAddressId: serviceInfo.mappingAddressId || '',
												mappingAreaId: serviceInfo.mappingAreaId || '',
												mappingRefArea1: serviceInfo.mappingRefArea1 || '',
												state: serviceInfo.state || '',
												zipcode: serviceInfo.zipcode || '',
												mappingNote: serviceInfo.mappingNote || '',
												addressValues: serviceInfo.savedAddressZoneValues || ''
											},
											onCloseCallback: () => {
												this.props.parentCallback();
											}
										})}
									>
										<i className="fas fa-map" />
									</button>

									<button
										className="btn btn-outline-secondary"
										onClick={this.handlerDescriptionExpand}
									>
										<i className="fas fa-info"/>
									</button>

									<button
										className="btn btn-outline-secondary"
										onClick={this.handlerNotesExpand}
									>
										<i className="fas fa-file-signature"/>
									</button>

									<button
										title="Show Service Order Details"
										onClick={this.handlerServiceExpand}
										className="btn btn-outline-secondary btn-order-substep-collapse-toggle"
									>
										<i className={isServiceExpanded === true ? 'fas fa-angle-double-up' : 'fas fa-angle-double-down'} />
									</button>

								</div>
							</div>
						</div>

					</div>
				</div>

				{isServiceExpanded &&
				<Summary
					serviceOrderId={serviceInfo.id}
					showGrandTotal={false}
				/>
				}

				{isDescriptionExpanded &&
				<div className="order-body collapse show">
					<div className="row">

						<div className={"col-12" + (serviceInfo.serviceLines.length === 0 ? '' : ' col-sm-4')}>
							<h6>{ReactHtmlParser(serviceInfo.planDescription)}</h6>
							<p>{ReactHtmlParser(serviceInfo.planNote)}</p>
						</div>

						{serviceInfo.serviceLines && this.checkIfExist(serviceInfo.serviceLines, 'numbers') &&
						<div className="col-12 col-sm-4">
							<h6>Numbers</h6>
							{serviceInfo.serviceLines.map((serviceLine, index) => {
								if (serviceLine.numbers) {
									return (
										<p key={index}>
											{serviceLine.numbers.map((number, index, arr) => {
												if (index !== arr.length - 1) {
													return `${number} | `;
												} else {
													return `${number}`;
												}
											})}
										</p>
									);
								}
							})}
						</div>
						}

						{serviceInfo.serviceLines && this.checkIfExist(serviceInfo.serviceLines, 'equipmentItems') &&
						<div className="col-12 col-sm-4">
							<h6>Equipment</h6>
							{serviceInfo.serviceLines.map((serviceLine, index) => {
								if (serviceLine.equipmentItems) {
									return (
										<p key={index}>
											{serviceLine.equipmentItems.map((equipment, index, arr) => {
												if (index !== arr.length - 1) {
													return `${equipment} | `;
												} else {
													return `${equipment}`;
												}
											})}
										</p>
									);
								}
							})}
						</div>
						}

					</div>
				</div>
				}

				{isNotesExpanded &&
				<Notes serviceOrderId={serviceInfo.id} />
				}

				{!isServiceOrderCompleted && !isServiceOrderCancelled && isTasksExpanded &&
				<Tasks
					accountId={order.accountId}
					order={order}
					serviceInfo={serviceInfo}
					serviceOrderId={serviceInfo.id}
					history={this.props.history}
				/>
				}

			</div>
		);
	}
}

const mapStateToProps = (state) => {
	const canUpdateTargetDateTime = getUserPermission(state, 'ADMN', 'ADMN_MANAGE_TARGET_DATE');
	const canEditServiceAddress = getUserPermission(state, 'ADMN', 'ADMN_EDIT_SERVICE_ADDRESS');

	return {
		canUpdateTargetDateTime,
		canEditServiceAddress
	};
};

const mapDispatchToProps = {
	showModal,
	manuallyTriggerReceiveTask
};

export default connect(mapStateToProps, mapDispatchToProps)(Service);
