import React, { Component } from 'react';
import { getLogs } from '../../../../actions/dashboardActions/orders.actions';
import Log from '../../../../components/UI/Log';
class Logs extends Component {
	state = {
		stateChain: [],
		time: 10000
	};

	getLogsData = (serviceOrderId) => {
		getLogs(serviceOrderId).then((response) => {
			if (response.success) {
				this.setState({
					stateChain: response.stateChain
				});
			}
		});
	};

	componentDidMount() {
		const { serviceOrderId } = this.props;

		this.getLogsData(serviceOrderId);
	}

	componentWillUnmount() {
		clearInterval(this.interval);
	}

	render() {
		const { stateChain } = this.state;
		if (stateChain.length === 0) {
			return null;
		}

		return (
			<div className="cmv-container-log-viewer cmv-container-order-log-viewer">
				{stateChain.map((log) => <Log logInfo={log}/>)}
			</div>
		);
	}
}

export default Logs;
