import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getAccountDetails, createLoadingSelector } from '../../../../../../selectors';
import {
	getAllContacts,
	getSecurityPhrase,
	updateSecurityPhrase,
	updateAccountContact,
	updateContactAddress,
	getCountryStates,
	emptySecurityPhrase,
	updateContactNumber,
	updateContactEmail,
	updateUserAccess,
	emptyAllContacts,
	sendContactEmailVerification,
	sendRegistrationLink,
} from '../../../../../../actions/accountDetailsActions/security.actions';
import { showModal } from '../../../../../../actions/modal.actions';

import AccountForm from './AccountForm';
import AuthorizedUsersForm from './UsersForm';
import isEmpty from '../../../../../../utils/helpers';
import Loader from '../../../../../../components/Loader';

export class Security extends Component {
	constructor(props) {
		super(props);

		// Create the ref
		// this.inputElement = React.createRef();
		this.state = {};
	}

	componentDidMount() {
		const { accountId } = this.props;
		this.props.getAllContacts(accountId);
		this.props.getSecurityPhrase(accountId);
		// this.props.getPrimaryContacts(accountId);
		// this.props.getAuthorizedContacts(accountId);
		this.props.getCountryStates();
	}

	componentWillUnmount() {
		this.props.emptySecurityPhrase();
		this.props.emptyAllContacts();
		// this.props.emptyPrimaryContacts();
		// this.props.emptyAuthorizedContacts();
	}

	// handleEditField = (fieldName) => {
	// 	this.setState({
	// 		isEditable: fieldName
	// 	});
	// };

	// handleChangeInput = (fieldName) => {

	// 	console.log(this.inputElement)
	// 	let updatedInitialValues = { ...this.state.initialValues };
	// 	updatedInitialValues[this.inputElement.name] = this.inputElement.value;

	// 	this.setState({
	// 		initialValues: updatedInitialValues,
	// 		isEditable: ''
	// 	});
	// };

	// handleChangeInput = (updatedInitialValues) => {
	// 	this.setState({
	// 		initialValues: updatedInitialValues,
	// 		isEditable: ''
	// 	});
	// };

	// handleCloseInput = () => {
	// 	this.setState({
	// 		isEditable: ''
	// 	});
	// };

	render() {
		const {
			accountId,
			primaryContacts,
			usersContactsData,
			securityPhraseData,
			countryStatesData,
			primaryContactsLoader,
			showModal,
			updateSecurityPhrase,
			updateAccountContact,
			updateContactAddress,
			updateContactEmail,
			updateUserAccess,
			updateContactNumber,
			sendContactEmailVerification,
			sendRegistrationLink,
			userPermission
		} = this.props;

		if (isEmpty(primaryContacts)) {
			return <Loader />;
		}

		return (
			<div className="tab-pane fade show active" id="account-users">
				<div className="container-client-users">
					<form className="cmv-form cmv-form-client-users">
						<AccountForm
							updateSecurityPhrase={updateSecurityPhrase}
							updateAccountContact={updateAccountContact}
							updateContactAddress={updateContactAddress}
							updateContactNumber={updateContactNumber}
							updateContactEmail={updateContactEmail}
							updateUserAccess={updateUserAccess}
							countryStatesData={countryStatesData}
							primaryContacts={primaryContacts}
							securityPhraseData={securityPhraseData}
							accountId={accountId}
							sendContactEmailVerification={sendContactEmailVerification}
							sendRegistrationLink={sendRegistrationLink}
							// handleEditField={this.handleEditField}
							// handleChangeInput={this.handleChangeInput}
							// handleCloseInput={this.handleCloseInput}
							// inputRef={(el) => (this.inputElement = el)}
							showModal={showModal}
							userPermission={userPermission}/>
						<AuthorizedUsersForm
							updateAccountContact={this.props.updateAccountContact}
							updateContactAddress={this.props.updateContactAddress}
							updateContactNumber={this.props.updateContactNumber}
							updateContactEmail={this.props.updateContactEmail}
							updateUserAccess={this.props.updateUserAccess}
							usersContactsData={usersContactsData}
							accountId={accountId}
							showModal={showModal}
						/>
					</form>
				</div>
			</div>
		);
	}
}

const getPrimaryContactsLoader = createLoadingSelector([ 'SET_ALL_CONTACTS' ]);

const mapStateToProps = (state) => {
	const accountId = getAccountDetails(state).accountDetails.accountInfo.id,
		primaryContactData = getAccountDetails(state).security.primaryContacts[0],
		authorizedContactData = getAccountDetails(state).security.authorizedContacts,
		securityPhraseData = getAccountDetails(state).security.securityPhrase,
		countryStatesData = getAccountDetails(state).security.countryStates,
		primaryContactsLoader = getPrimaryContactsLoader(state),
		primaryContacts = getAccountDetails(state).security.allContacts.find((contact) => contact.primary === true),
		usersContactsData = getAccountDetails(state).security.allContacts.filter((contact) => contact.primary === false);

	return {
		accountId,
		primaryContacts,
		usersContactsData,
		primaryContactData,
		authorizedContactData,
		securityPhraseData,
		countryStatesData,
		primaryContactsLoader
	};
};

const mapDispatchToProps = {
	getAllContacts,
	getSecurityPhrase,
	updateSecurityPhrase,
	updateAccountContact,
	updateContactAddress,
	getCountryStates,
	emptySecurityPhrase,
	updateContactNumber,
	updateContactEmail,
	updateUserAccess,
	showModal,
	emptyAllContacts,
	sendContactEmailVerification,
	sendRegistrationLink
};

export default connect(mapStateToProps, mapDispatchToProps)(Security);
