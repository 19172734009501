import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { hideModal } from '../../../actions/modal.actions';
import { hideAccountDetailsWizard } from '../../../actions/accountDetailsActions/accountDetails.actions';

class CloseAccountDetailsWizard extends Component {

    closeModal = () => {
		this.props.hideModal();
    };

    goBack = () => {
        this.props.hideModal();
		this.props.hideAccountDetailsWizard()
    }
    
	render() {
       
		return (
			<Fragment>
				<div className="modal" style={{ display: 'block' }} tabIndex="-1" role="dialog">
					<div className="modal-dialog" role="document">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">CONFIRM CLOSE WIZARD</h5>
								<button type="button" className="close" onClick={this.closeModal}>
									<span aria-hidden="true">&times;</span>
								</button>
							</div>
							<div className="modal-body">
								<p className="lead">Are you sure you want to cancel this wizard?</p>
							</div>
							<div className="modal-footer">
								<button onClick={this.closeModal} className="btn">
									No
								</button>
								<button onClick={this.goBack} className="btn btn-primary">
									Yes
								</button>
							</div>
						</div>
					</div>
				</div>
				<div className="modal-backdrop show" tabIndex="1" />
			</Fragment>
		);
	}
}

const mapDispatchToProps = {
	hideModal,
	hideAccountDetailsWizard
}


export default connect(null, mapDispatchToProps)(CloseAccountDetailsWizard);
