import React, { Component } from 'react'
import Order from './Order';
import Loader from '../../../../components/Loader';
import { isEmpty } from '../../../../utils/helpers';

class OrderResults extends Component {
    render() {
        const { userOrdersData, orderLoader, isFetched, showModal } = this.props;

		if (orderLoader && !isFetched) {
			return <Loader />;
		}

		if (isEmpty(userOrdersData) && isFetched) {
            return <div className={'container'}><p>No data found</p></div>;
        }
        
        
        return (
            <div className="cmv-container-order-search-results">
                {userOrdersData.map((order) =>
                    <Order
                        orderInfo={order}
                        key={order.id}
                        hasCollapsedIcon={true}
                        showModal={showModal}
                        {...this.props}
                    />)}
            </div>
        )
    }
}

export default OrderResults
