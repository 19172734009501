import axiosInstance from '../../utils/axios-instance';
import { actionCreator, createRequestTypes } from '../../utils/helpers';

export const GET_ACTIVE_SERVICE_AGREEMENTS = createRequestTypes('GET_ACTIVE_SERVICE_AGREEMENTS');
export const GET_SERVICE_AGREEMENT = createRequestTypes('GET_SERVICE_AGREEMENT');
export const GET_SERVICE_AGREEMENT_FOR_SERVICE_LINE = createRequestTypes('GET_SERVICE_AGREEMENT_FOR_SERVICE_LINE');

export const getActiveServiceAgreements = () => async (dispatch) => {
	dispatch(actionCreator.request(GET_ACTIVE_SERVICE_AGREEMENTS));
	try {
		const response = await axiosInstance.get(`/domain/serviceagreements`);
		dispatch(actionCreator.success(GET_ACTIVE_SERVICE_AGREEMENTS, response.data.serviceAgreements));
	} catch (err) {
		let mockError = {
			message: 'Not found'
		};
		dispatch(actionCreator.failure(GET_ACTIVE_SERVICE_AGREEMENTS, mockError));
	}
};

export const getServiceAgreement = (serviceAgreementId) => async (dispatch) => {
	dispatch(actionCreator.request(GET_SERVICE_AGREEMENT));
	try {
		const response = await axiosInstance.get(`/domain/serviceagreement/${serviceAgreementId}`);
		dispatch(actionCreator.success(GET_SERVICE_AGREEMENT, response.data.serviceAgreement));
	} catch (err) {
		let mockError = {
			message: 'Not found'
		};
		dispatch(actionCreator.failure(GET_SERVICE_AGREEMENT, mockError));
	}
};

export const getServiceAgreementForServiceLine = (serviceAgreementId, serviceLineId) => async (dispatch) => {
	dispatch(actionCreator.request(GET_SERVICE_AGREEMENT_FOR_SERVICE_LINE));
	try {
		if (!serviceAgreementId || serviceAgreementId === '') {
			dispatch(actionCreator.success(GET_SERVICE_AGREEMENT_FOR_SERVICE_LINE, {serviceLineId: serviceLineId, serviceAgreement: null}));
			return;
		}
		const response = await axiosInstance.get(`/domain/serviceagreement/${serviceAgreementId}`);
		dispatch(actionCreator.success(GET_SERVICE_AGREEMENT_FOR_SERVICE_LINE, {serviceLineId: serviceLineId, serviceAgreement: response.data.serviceAgreement}));
	} catch (err) {
		let mockError = {
			message: 'Not found'
		};
		dispatch(actionCreator.failure(GET_SERVICE_AGREEMENT_FOR_SERVICE_LINE, mockError));
	}
};

export const clearServiceAgreementForServiceLine = (serviceLineId) => async (dispatch) => {
	dispatch(actionCreator.clear(GET_SERVICE_AGREEMENT_FOR_SERVICE_LINE, {serviceLineId: serviceLineId}));
}

export const clearServiceAgreement = () => async (dispatch) => {
	dispatch(actionCreator.clear(GET_SERVICE_AGREEMENT));
}

