import React, { Component, Fragment } from 'react';
import Logs from '../../../AccountDetails/Panels/LeftPanel/Tabs/OrdersAndQuotes/Orders/Logs';
import PropTypes from 'prop-types';
import moment from 'moment';
import Select from 'react-select';
import { groupStyles } from '../../../../utils/SelectStyles';
import { Link } from 'react-router-dom';
import { isEmpty } from '../../../../utils/helpers';
import TargetDateTime from "../../../../components/UI/TargetDateTime";

class TableRow extends Component {

	state = {
		showLogs: false,
	};

	handleShowHideLogs = () => {
		this.setState((prevState) => ({
			showLogs: !prevState.showLogs
		}));
	};

	priorityColor = (priority) => {
		switch (priority) {
			case 'HIGH ':
				return 'badge badge-danger';
			case 'MEDIUM':
				return 'badge badge-info';
			case 'LOW ':
			case 'NEW':
				return 'badge badge-success';
			default:
				return '';
		}
	};

	render() {

		const {
			rowClass,
			tableHeadings,
			taskInfo,
			order,
			systemUsersData,
			handleShowHideDropdown,
			showTaskDropDownById,
			handleAssignTaskToUsername,
			showOrderDetails,
			accountId,
		} = this.props;

		let transformedValue;

		if (systemUsersData.length > 0) {
			transformedValue = systemUsersData.map((systemUser) => {
				return {
					value: systemUser.username,
					label: systemUser.firstName + ' ' + systemUser.lastName
				};
			});
		}

		if (isEmpty(taskInfo)) {
			return null;
		}

		const hasAppointmentDetails = taskInfo.appointmentDetails && taskInfo.appointmentDetails.apptDate && taskInfo.appointmentDetails.apptTimeSlotFrom && taskInfo.appointmentDetails.apptTimeSlotTo;

		return (

			<Fragment>
				<tr className={rowClass}>
					<td className="" data-heading={tableHeadings[0]}>

						{taskInfo.name}

						{/* Show order details only if we're not in Account Details Orders / Tickets */}
						{showOrderDetails !== false &&
						<span className="td-subtitle">

							{taskInfo.endDateTime == null
								? <> Task: {taskInfo.id} | Created: {moment(taskInfo.createdDateTime).format('MM/DD/YYYY')} </>
								: <> Task: {taskInfo.id} | Completed: {moment(taskInfo.endDateTime).format('MM/DD/YYYY')} </>
							}

							{taskInfo.serviceOrderInfo && taskInfo.serviceOrderInfo.orderId && <>
								<br/>
								Order:&nbsp;{taskInfo.serviceOrderInfo.orderId}
							</> }

						</span>
						}

					</td>

					{/* Show Details Column only if we're not in Account Details Orders / Tickets */}
					{showOrderDetails !== false &&
					<td className="" data-heading={tableHeadings[1]}>

						{taskInfo && taskInfo.serviceOrderInfo && taskInfo.serviceOrderInfo.description}

						{taskInfo && taskInfo.troubleTicketDetails && taskInfo.troubleTicketDetails.reportedCode}

						<span className="td-subtitle">
							{taskInfo.serviceOrderInfo && taskInfo.serviceOrderInfo.serviceOrderId && (
								'SO: ' + taskInfo.serviceOrderInfo.serviceOrderId + ' | '
							)}
							{taskInfo.troubleTicketDetails && taskInfo.troubleTicketDetails.id && (
								'TT: ' + taskInfo.troubleTicketDetails.id + ' | '
							)}
							Created: {moment(taskInfo.serviceOrderInfo && taskInfo.serviceOrderInfo.createdDateTime).format('MM/DD/YYYY')}
							<br/>
							{taskInfo.customerName && (
								taskInfo.customerName
							)}
							<br/>
							{taskInfo.address1 && (
								taskInfo.address1
							)}

						</span>

						{taskInfo && taskInfo.serviceOrderInfo && taskInfo.serviceOrderInfo.targetDateTime &&
						<span className="td-subtitle">
							<TargetDateTime targetDateTime={taskInfo.serviceOrderInfo.targetDateTime} />
						</span>
						}

					</td>
					}

					<td className="" data-heading={tableHeadings[2]}>
						{taskInfo.endDateTime != null
						?
							<span className='badge-inline badge badge-success'>Done</span>
						:
							<>
							<span className={this.priorityColor(taskInfo.priority)}>{taskInfo.priority}</span>
							<span className="td-subtitle">
								{!hasAppointmentDetails && taskInfo.serviceOrderInfo && taskInfo.serviceOrderInfo.serviceOrderId && (
									taskInfo.dueDate ? `Due: ${moment(taskInfo.dueDate).format('MM/DD/YYYY')}` : 'No due date'
								)}
								{!hasAppointmentDetails && taskInfo.troubleTicketDetails && taskInfo.troubleTicketDetails.id && (
									taskInfo.troubleTicketDetails.troubleDueDate ? `Due: ${moment(taskInfo.troubleTicketDetails.troubleDueDate).format('MM/DD/YYYY')}` : 'No due date'
								)}
								{hasAppointmentDetails && <>
									<i className="fas fa-clock"/>
									{moment(taskInfo.appointmentDetails.apptDate).format('MM/DD/YYYY')}
									{' '}
									{moment(taskInfo.appointmentDetails.apptTimeSlotFrom).format('h:mm A')}
									{' - '}
									{moment(taskInfo.appointmentDetails.apptTimeSlotTo).format('h:mm A')}
								</>}
							</span>
							</>
						}
					</td>

					<td className="" data-heading={tableHeadings[3]}>

						{taskInfo.assignee ? taskInfo.assignee : <br />}
						{taskInfo.candidateUserGroups && (
							<span className="td-subtitle">
								{taskInfo.candidateUserGroups.map((candidate, index, arr) => {
									if (index !== arr.length - 1) {
										return `${candidate}; `;
									} else {
										return candidate;
									}
								})}
							</span>
						)}

					</td>

					<td className="" data-heading={tableHeadings[4]}>

						{(taskInfo.endDateTime != null && taskInfo.endDateTime !== "") ? "" :

						<div className="tr-actions">

							<div className={showTaskDropDownById === taskInfo.id ? 'dropdown show' : 'dropdown'}>
								<button
									className="btn btn-lg dropdown-toggle"
									type="button"
									id="dropdownMenuButton"
									data-toggle="dropdown"
									aria-haspopup="true"
									aria-expanded={showTaskDropDownById === taskInfo.id}
									onClick={() => handleShowHideDropdown(taskInfo.id)}
								>
									<i className="fas fa-user-plus"/>
								</button>
								<div
									className={
										showTaskDropDownById === taskInfo.id ? (
											'dropdown-menu dropdown-menu-with-select dropdown-menu-right show'
										) : (
											'dropdown-menu dropdown-menu-with-select dropdown-menu-right'
										)
									}
									aria-labelledby="dropdownMenuButton"
								>
									<button
										onClick={() => handleAssignTaskToUsername('claim', taskInfo.id)}
										className="dropdown-item"
									>
										Claim
									</button>
									<button
										onClick={() => handleAssignTaskToUsername('unassign', taskInfo.id)}
										className="dropdown-item"
									>
										Unassign
									</button>
									<div className="dropdown-divider"/>
									<h6 className="dropdown-header">Assign To User</h6>
									<div className="dropdown-item">
									{/* <select className="task-list-available-users form-control">
										<option >Amir Heydrakhan</option>
										<option>John Davidson</option>
										<option>Melody Sunshine</option>
									</select> */}
										<Select
											// value={selectedEntities}
											onChange={(value) =>
												handleAssignTaskToUsername('username', taskInfo.id, value)}
											options={transformedValue}
											styles={groupStyles}
											placeholder="Select"
										/>
									</div>
									{/* <div className="dropdown-divider" />
								<h6 className="dropdown-header">Assign To Group</h6>
								<div className="dropdown-item">
									<select id="" name="" className="task-list-available-groups form-control">
										<option selected>Installation Group</option>
										<option>Scheduling Group</option>
									</select>
								</div> */}
								</div>
							</div>

							{/*<button onClick={this.handleShowHideLogs} className="btn btn-lg btn-row-collapse-toggle">
							<i className="fas fa-fw fa-list" />
							</button>*/}

							{/*<button className="btn btn-lg">
							<i className="fas fa-fw fa-share" />
							</button>*/}

							{this.props.handleShowTaskDetails
							?
							<div className="btn btn-lg" onClick={() => this.props.handleShowTaskDetails(taskInfo)}>
								<i className="fas fa-fw fa-chevron-circle-right"/>
							</div>
							:
							<Link
								className="btn btn-lg"
								to={{
									pathname: `/task-details/${taskInfo.id}`,
									state: {
										returnUrl: !isEmpty(accountId) ? `/account-details/${accountId}` : '',
										orderId: this.props.order && this.props.order.id,
										serviceOrderId: taskInfo.serviceOrderInfo && taskInfo.serviceOrderInfo.serviceOrderId,
										troubleTicketId: this.props.troubleTicketId,
									}
								}}>
								<i className="fas fa-fw fa-chevron-circle-right"/>
							</Link>
							}

						</div>

						}

					</td>

				</tr>

				{this.state.showLogs && <Logs serviceOrderId={taskInfo.serviceOrderInfo.serviceOrderId} />}

			</Fragment>
		);
	}
}

TableRow.propTypes = {
	tableHeadings: PropTypes.array.isRequired,
	taskInfo: PropTypes.object.isRequired,
	handleShowHideDropdown: PropTypes.func,
	showTaskDropDownById: PropTypes.string
};

export default TableRow;
