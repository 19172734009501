import React, {Component} from 'react';
import {connect} from 'react-redux';
import {createLoadingSelector} from '../../../../selectors';
import {Form, Formik} from "formik";
import isEmpty, {generateId} from "../../../../utils/helpers";
import {createOrderChangeDevice} from "../../../../actions/dashboardActions/orders.actions";

class Provisioning extends Component {

    state = {
        deviceProfile: null,
    }

    handleDeviceSelectChange = (formProps, selectedDevice) => {
        let device = formProps.values.devices.find((d) => d.id === selectedDevice.id);
        const devices = formProps.values.devices;

        if (!isEmpty(device)) {
            formProps.setFieldValue("devices", devices.filter(d => d.id !== device.id));
            return;
        }

        const numbers = {};
        selectedDevice.apiServiceDeviceNums.forEach(num => {
            numbers[num.typeId] = num.number;
        });

        device = {
            id: selectedDevice.id,
            deviceGroupId: selectedDevice.deviceGroupId,
            deviceProfileId: selectedDevice.deviceProfileId,
            itemId: selectedDevice.deviceItemId,
            action: 'REMOVE',
            numbers: numbers
        };

        devices.push(device);
        formProps.setFieldValue("devices", devices);
    }

    renderDeviceSelect = (formProps, device) => {
        return (
            <>
                <div className="form-group">
                    <span className="h-check">
                        <label>{device.deviceItemDescription}&nbsp;</label>
                        <div className="form-check checkbox-slider checkbox-slider--b-flat">
                            <label>
                                <input
                                    type="checkbox"
                                    name={`device_select_${device.id}`}
                                    checked={!isEmpty(formProps.values.devices.find(d => d.id === device.id))}
                                    onChange={(event) => this.handleDeviceSelectChange(formProps, device)}
                                />
                                <span>&nbsp;</span>
                            </label>
                        </div>
                    </span>
                </div>

                <fieldset>

                    <legend>Device Numbers</legend>

                    {device.apiServiceDeviceNums.map(number => (
                        <p className="row">
                            <div className="col-sm-6"><b>{number.type}</b></div>
                            <div className="col-sm-6">{number.number}</div>
                        </p>
                    ))}

                </fieldset>

                {/*{errors.deviceGroupId &&*/}
                {/*<div className="invalid-feedback">{errors.deviceGroupId}</div>*/}
                {/*}*/}

            </>
        );
    }

    handleOnSubmit = (values, actions) => {

        this.props.createOrderChangeDevice(values).then(response => {
            if (response && response.success) {
                this.handleNext({...values});
            }
        });
    }

    handleNext = (values) => {
        const { next } = this.props;

        if (!next) {
            return;
        }

        next({
            values: values,
        });
    }

    isSubmitting = () => {
        return this.props.orderCreationLoader === true;
    }

    render() {

        const {
            orderCreationLoader,
            wizardData,
        } = this.props;

        return (
            <>
                <Formik
                    onSubmit={this.handleOnSubmit}
                    initialValues={{
                        id: null,
                        name: 'SERVICE_CHANGE_DEVICE_' + generateId(),
                        accountId: wizardData.accountId,
                        existingServiceLineId: wizardData.serviceLineId,
                        source: 'CAMVIO-WEB',
                        type: 'REMOVE_DEVICES',
                        devices: []
                    }}
                    render={(formProps) => (
                        <Form onSubmit={formProps.handleSubmit} className="cmv-form" autoComplete="off">

                            {wizardData.serviceDevices.map(device =>
                                this.renderDeviceSelect(formProps, device)
                            )}

                            <div className="wizard-footer d-flex justify-content-between">
                                <button
                                    type="submit"
                                    className="btn btn-primary btn-next-tab ml-auto"
                                    onClick={formProps.handleSubmit}
                                    disabled={this.isSubmitting() || isEmpty(formProps.values.devices)}
                                >
                                    {orderCreationLoader
                                        ? <i className="fas fa-fw fa-spin fa-spinner" style={{marginLeft: '5px'}} />
                                        : "REMOVE"
                                    }
                                </button>
                            </div>

                        </Form>
                    )}
                />
            </>
        )
    }
}

const orderCreationSelector = createLoadingSelector(['CREATE_SERVICE_CHANGE_DEVICE']);

const mapStateToProps = (state) => {

    const orderCreationLoader = orderCreationSelector(state);

    return {
        orderCreationLoader,
    }
};

const mapDispatchToProps = {
    createOrderChangeDevice,
};

export default connect(mapStateToProps, mapDispatchToProps)(Provisioning);