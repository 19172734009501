import React, {Component, Fragment} from "react";
import isEmpty from "../../../../../utils/helpers";
import { parseDateWithFormat } from "../../../../../utils/dateTimeParseUtils";

const Note = (props) => {

    const { note, isOwnNote, canDeleteNote, deleteNoteHandler, isNoteDeleting } = props;

    if (isEmpty(note)) {
        return null;
    }

    return (
        <Fragment>
            <li className={"mini-note" + (isOwnNote ? " mini-note-current-user" : "")}>
                <div className="mini-note-body">

                    <p>{note.note}</p>

                    {canDeleteNote && (isNoteDeleting === note.id
                            ? <i className="mini-note-delete fas fa-fw fa-spin fa-spinner"></i>
                            : <i className="mini-note-delete mini-note-delete-button far fa-times-circle" onClick={deleteNoteHandler}/>
                    )}

                    <div className="footer">
                        <small className="text-muted">
                            <i className="far fa-clock"/>&nbsp;{parseDateWithFormat(note.createdDateTime)}&nbsp;
                            <i className="fas fa-user"/>&nbsp;{note.createdByName}
                        </small>
                    </div>

                </div>
            </li>
        </Fragment>
    )
}

export default Note
