import React, { Component, Fragment } from 'react';
import axiosInstance from '../../../../../../utils/axios-instance';
import PropTypes from 'prop-types';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory, {
	PaginationListStandalone,
	SizePerPageDropdownStandalone,
	PaginationProvider,
	PaginationTotalStandalone
} from 'react-bootstrap-table2-paginator';
import moment from 'moment';
import { connect } from 'react-redux';
import { getInvoices, emptyInvoices } from '../../../../../../actions/accountDetailsActions/billing.actions';
import { showModal } from '../../../../../../actions/modal.actions';
import {getAccountDetails, createLoadingSelector, getUserPermission} from '../../../../../../selectors';

import Loader from '../../../../../../components/Loader';
import { isEmpty } from '../../../../../../utils/helpers';
import {toastr} from "react-redux-toastr";

const { SearchBar } = Search;

class Invoices extends Component {

	state = {
		activeDropdown: null,
		isPDFLoading: false
	};

	activeDropDownHandler = (id) => {
		this.setState({
			activeDropdown: this.state.activeDropdown === id ? '' : id
		});
	};

	componentDidMount() {
		const { accountInfo } = this.props;
		this.props.getInvoices(accountInfo.id);
	}

	componentWillUnmount() {
		this.props.emptyInvoices();
	}

	showPdf = (id) => {

		this.setState({isPDFLoading: id});

		axiosInstance
			.get(`/account/invoice/${id}/pdf`, {
				// method: 'GET',
				responseType: 'blob' //Force to receive data in a Blob Format
				// responseType: 'arraybuffer'
			})
			.then((response) => {
				this.setState({isPDFLoading: false});

				// Create a Blob from the PDF Stream
				const file = new Blob([ response.data ], { type: 'application/pdf' });

				if (window.navigator.msSaveOrOpenBlob) {
					window.navigator.msSaveOrOpenBlob(file, 'my.pdf');
				} else if (navigator.userAgent.match('FxiOS')) {
					alert('Cannot display on FF iOS');
				} else if (navigator.userAgent.match('CriOS')) {
					let reader = new FileReader();
					reader.onloadend = function() {
						window.open(reader.result);
					};
					reader.readAsDataURL(file);
				} else if (navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPhone/i)) {
					let url = window.URL.createObjectURL(file);
					window.location.href = url;
				} else {
					// Build a URL from the file
					const fileURL = URL.createObjectURL(file);

					// Open the URL on new Window
					let newWindow = window.open(fileURL, '_blank');

					// Alert the user of blocked popup
					if (!newWindow || newWindow.closed || typeof newWindow.closed == 'undefined') {

						let browser = 'Your web browser';

						if (navigator.userAgent.toLowerCase().indexOf('chrome/') > -1) browser = 'Chrome';
						else if (navigator.userAgent.toLowerCase().indexOf('safari/') > -1) browser = 'Safari';
						else if (navigator.userAgent.toLowerCase().indexOf('firefox/') > -1) browser = 'Firefox';

						toastr.error(browser + ' blocked the PDF Invoice window from opening. Please allow pop ups and try again.', {timeOut: 0, position: 'top-center'});
					}
				}
			})
			.catch((error, response) => {
				this.setState({isPDFLoading: false});

				if (error.response && error.response.data) {
					error.response.data.text().then(response => {
						const resp = JSON.parse(response);

						if (resp.error.code === 'INV0001') {
							const errorMessage = resp.error.message;
							toastr.error(errorMessage, {timeOut: 0, position: 'top-center'});
						}
					});
				}
			});
	};

	typeFormater = (cell, row, rowIndex) => {
		if (row) {
			return (
				<Fragment>
					{ cell && row.testInvoice && 'Test' || cell}
					<span className="td-subtitle">#{row.number}</span>
				</Fragment>
			);
		}
	};

	invoiceDateFormater = (cell, row, rowIndex) => {
		if (row) {
			return (
				<Fragment>
					{moment(cell).format('MM/DD/YYYY')}{' '}
					{row.type === 'SERVICE' && (
						<span className="td-subtitle">
							{moment(row.periodFrom).format('MM/DD')}-{moment(row.periodTo).format('MM/DD')}
						</span>
					)}
				</Fragment>
			);
		}
	};

	amountFormater = (cell, row, rowIndex) => {
		if (row) {
			return <Fragment>${cell.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</Fragment>;
		}
	};

	dueDateFormater = (cell, row, rowIndex) => {
		if (row) {
			return <Fragment>{moment(cell).format('MM/DD/YYYY')}</Fragment>;
		}
	};

	lastPaymentFormater = (cell, row, rowIndex) => {
		if (row) {
			return (
				<Fragment>
					{cell && moment(cell).format('MM/DD/YYYY')}
					<span className="td-subtitle">
						{row.lastPaymentAmount &&
							`$${row.lastPaymentAmount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`}
					</span>
				</Fragment>
			);
		}
	};

	actionFormater = (cell, row, rowIndex, formatExtraData) => {
		return (
			<div className="tr-actions">

				<button className="btn"
						disabled={formatExtraData.isPDFLoading === row.id}
						onClick={() => this.showPdf(row.id)}
				>
					{formatExtraData.isPDFLoading === row.id
						? <i className="fas fa-fw fa-spin fa-spinner" />
						: <i className="fas fa-eye" />
					}
				</button>

				{/*<div*/}
				{/*	className={row.id === formatExtraData.id ? 'dropdown show' : 'dropdown'}*/}
				{/*	onClick={() => this.activeDropDownHandler(row.id)}*/}
				{/*	onBlur={() => this.setState({activeDropdown: ''})}*/}
				{/*>*/}
				{/*	<button className="btn dropdown-toggle" type="button" disabled={row.testInvoice}>*/}
				{/*		<i className="fas fa-share" />*/}
				{/*	</button>*/}
				{/*	{!row.testInvoice && (*/}
				{/*	<div*/}
				{/*		className={*/}
				{/*			row.id === formatExtraData.id ? (*/}
				{/*				'dropdown-menu dropdown-menu-right show'*/}
				{/*			) : (*/}
				{/*				'dropdown-menu dropdown-menu-right'*/}
				{/*			)*/}
				{/*		}*/}
				{/*	>*/}
				{/*		<a className="dropdown-item">Send by E-mail</a>*/}
				{/*		<a className="dropdown-item">Print</a>*/}
				{/*	</div> )}*/}
				{/*</div>*/}

				{/*<button className="btn" disabled={row.type !== 'Retail' || row.testInvoice}>*/}
				{/*	<i className="fas fa-backspace" />*/}
				{/*</button>*/}

				{/*<button className="btn" disabled={row.balance === 0 || row.testInvoice}>*/}
				{/*	<i className="fas fa-credit-card" />*/}
				{/*</button>*/}

			</div>
		);
	};

	balanceFormater = (cell, row) => {
		if (row && !row.testInvoice) {
			if (cell === 0) {
			
				return <span className="badge badge-success">Paid</span>;
			} else if (cell < row.amount) {
				return (
					<Fragment>
						<span className="badge badge-warning">Partially unpaid</span>
						<span className="td-subtitle">
							Balance: ${cell.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
						</span>
					</Fragment>
				);
			} else {
				return (
					<Fragment>
						<span className="badge badge-warning">Unpaid</span>
						<span className="td-subtitle">
							Balance: ${cell.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
						</span>
					</Fragment>
				);
			}
		}
	};

	customTotal = (from, to, size) => (
		<span className="react-bootstrap-table-pagination-total">
			Showing {from} to {to} of {size} entries
		</span>
	);

	render() {
		const { addAdjustmentPermission, invoicesData, invoicesLoader } = this.props;

		if (invoicesLoader) {
			return <Loader />;
		}

		const options = {
			paginationSize: 4,
			custom: true,
			totalSize: this.props.invoicesData.length,
			pageStartIndex: 1,
			// alwaysShowAllBtns: true, // Always show next and previous button
			// withFirstAndLast: false, // Hide the going to First and Last page button
			// hideSizePerPage: true, // Hide the sizePerPage dropdown always
			hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
			firstPageText: 'First',
			prePageText: 'Previous',
			nextPageText: 'Next',
			lastPageText: 'Last',
			// nextPageTitle: 'First page',
			// prePageTitle: 'Pre page',
			// firstPageTitle: 'Next page',
			// lastPageTitle: 'Last page',
			showTotal: true,
			paginationTotalRenderer: this.customTotal,
			sizePerPageList: [
				{
					text: '10',
					value: 10
				},
				{
					text: '25',
					value: 25
				},
				{
					text: '50',
					value: 50
				},
				{
					text: '100',
					value: 100
				}
				// {
				// 	text: 'All',
				// 	value: this.props.invoicesData.length
				// }
			] // A numeric array is also available. the purpose of above example is custom the text
		};

		const columns = [
			{
				dataField: 'type',
				text: 'Type',
				sort: true,
				formatter: this.typeFormater
			},
			{
				dataField: 'number',
				hidden: true
			},
			{
				dataField: 'invoiceDate',
				text: 'Date',
				sort: true,
				formatter: this.invoiceDateFormater
			},

			{
				dataField: 'amount',
				text: 'Current Charges',
				sort: true,
				formatter: this.amountFormater,
				classes: 'h-align-r'
			},
			{
				dataField: 'dueDate',
				text: 'Due Date',
				sort: true,
				formatter: this.dueDateFormater
			},
			{
				dataField: 'lastPaymentDate',
				text: 'LAST PMNT',
				sort: true,
				formatter: this.lastPaymentFormater
			},
			{
				dataField: 'balance',
				text: '',
				sort: true,
				formatter: this.balanceFormater
			},
			{
				dataField: 'actions',
				text: 'Actions',
				isDummyField: true,
				formatter: this.actionFormater,
				formatExtraData: {
					id: this.state.activeDropdown,
					isPDFLoading: this.state.isPDFLoading
				},
				headerClasses: 'h-align-r'
			}
		];

		const defaultSorted = [
			{
				dataField: 'invoiceDate',
				order: 'desc'
			}
		];

		return (
			<Fragment>
				<div className="cmv-container-table cmv-container-table-invoices">
					<PaginationProvider pagination={paginationFactory(options)}>
						{({ paginationProps, paginationTableProps }) => (
							<Fragment>
								<ToolkitProvider
									bootstrap4
									keyField="id"
									data={invoicesData}
									columns={columns}
									defaultSorted={defaultSorted}
									search
								>
									{(props) => (
										<Fragment>
											<div className="row">
												<div className="col-sm-12 col-md-6">
													<label>
														Show <SizePerPageDropdownStandalone {...paginationProps} />{' '}
														entries
													</label>
												</div>
												<div className="col-sm-12 col-md-6" style={{ textAlign: 'right' }}>
													<label>
														Search:{' '}
														<SearchBar
															className="form-control form-control-sm"
															{...props.searchProps}
														/>
														{addAdjustmentPermission && (
															<button
																onClick={() =>
																	this.props.showModal('ADD_IMMEDIATE_ADJUSTMENTS_MODAL', {
																		onCloseCallback: () => {
																			this.props.getInvoices(this.props.accountInfo.id);
																		}
																	})}
																className="btn btn-primary"
																style={{margin: '5px'}}
															>
																<i className="fas fa-plus"/> New
															</button>
														)}
													</label>
												</div>
											</div>
											<div className="row">
												<BootstrapTable
													wrapperClasses="table-responsive react-table-layout"
													classes="table table-striped cmv-table"
													bordered={false}
													noDataIndication="No data found"
													{...paginationTableProps}
													{...props.baseProps}
												/>
											</div>
											<div className="row" style={{ marginTop: '10px' }}>
												<div className="col-sm-12 col-md-5">
													<PaginationTotalStandalone {...paginationProps} />
												</div>
												<div className="col-sm-12 col-md-7">
													<PaginationListStandalone {...paginationProps} />
												</div>
											</div>
										</Fragment>
									)}
								</ToolkitProvider>
							</Fragment>
						)}
					</PaginationProvider>
				</div>
			</Fragment>
		);
	}
}

Invoices.propTypes = {
	accountInfo: PropTypes.object.isRequired
};

const loader = createLoadingSelector([ 'SET_INVOICES' ]);

const mapStateToProps = (state) => {

	const
		addAdjustmentPermission = getUserPermission(state, 'CAM', 'CAM_ACCT_EDIT_ADJUSTMENTS'),
		invoicesData = getAccountDetails(state).billing.invoices,
		invoicesLoader = loader(state);

	return {
		addAdjustmentPermission,
		invoicesData,
		invoicesLoader
	};
};

const mapDispatchToProps = {
	getInvoices,
	showModal,
	emptyInvoices
};

export default connect(mapStateToProps, mapDispatchToProps)(Invoices);
