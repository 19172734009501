import React, {Component} from 'react';
import {connect} from 'react-redux';
import ServiceSummary from './ServiceSummary';
import moment from 'moment';
import isEmpty from "../../../utils/helpers";

export class ServiceWrapper extends Component {

    state = {
        isExpanded: false,
    }

    handleCollapse = () => {
        this.setState((prevState) => ({
            isExpanded: !prevState.isExpanded
        }));
    }

    render() {

        const {
            isExpanded,
        } = this.state;

        const {
            service,
            serviceLinesCount,
            formProps,
            changeServiceLineLoading,
        } = this.props;

        let serviceAgreementPeriodPercents = 0;

        if (service.serviceLineAgreement) {
            const totalPeriod = moment(service.serviceLineAgreement.startDate)
                .add(service.serviceLineAgreement.length, 'months')
                .diff(moment(service.serviceLineAgreement.startDate), 'weeks');
            const fromStartPeriod = moment()
                .diff(moment(service.serviceLineAgreement.startDate), 'weeks');
            serviceAgreementPeriodPercents = (fromStartPeriod / totalPeriod) * 100;

            if (serviceAgreementPeriodPercents === 0) {
                serviceAgreementPeriodPercents = 1;
            }
        }

        return (
            <div className="service-wrapper">
                <div className="service-header">

					<span
                        className={'service-title service-body-toggler' + (isExpanded ? '' : ' service-body-closed')}
                        onClick={this.handleCollapse}
                    >
						{service.number || "Service Line"}
					</span>

                    <span className="service-toolbar">
						<span className="badge badge-outline-secondary">{serviceLinesCount > 1 && service.planRelation}</span>
						<span className="badge badge-success">{service.status}</span>
                        <span className="h-check ml-3">
                            <label>Remove&nbsp;</label>
                            <div className="form-check checkbox-slider checkbox-slider--b-flat">
                                <label>
                                    <input
                                        type="checkbox"
                                        name={`serviceLine_${service.serviceLineId}`}
                                        checked={!isEmpty(formProps.values.lines.find(l => l.id === service.serviceLineId))}
                                        onChange={(e) => this.props.toggleLine(e.target.checked, formProps, service)}
                                        disabled={
                                            changeServiceLineLoading ||
                                            (service.planRelation !== "Main" && !isEmpty(formProps.values.lines.find(l => l.planRelation === "Main")))
                                        }
                                    />
                                    <span>&nbsp;</span>
                                </label>
                            </div>
                        </span>
					</span>

                </div>

                <div className={isExpanded ? 'service-body' : 'service-body collapse'}>

                    {service.address &&
                    <div className="services-summary mt-0">
                        <div className="services-summary-body">
                            <div className="plan-wrapper">
                                <div className="serviceline-wrapper">
                                    <div className="plan-section">
                                        <h5>Address</h5>
                                        <div className="package-wrapper">
                                            <div className="package-option">
                                                <span className="po-title">{service.address}
                                                    {service.address2 ? ", " + service.address2 : ''}
                                                    {service.city ? ", " + service.city : ''}
                                                    {service.state ? (service.state.abbreviation ? ", " + service.state.abbreviation : '') : ''}
                                                    {service.zipcode ? ", " + service.zipcode : ''}
                                                    {service.zipcode && service.zip4 ? "-" + service.zip4 : ''}
                                                </span>
                                                <span className="po-price"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    }

                    <div className="service-usage">
                        <div className="row">
                            <div className="col-lg">
                                <div className="cmv-progress-bar">

                                    <div className="progress-header">
										<span className="ph-left">
											<i className="far fa-fw fa-calendar-alt"/> Service Agreement
										</span>
                                        {service.serviceLineAgreement ?
                                        <span className="ph-right">
                                            {service.serviceLineAgreement.description},&nbsp;
                                            {moment(service.serviceLineAgreement.startDate)
                                                .format('MM/DD/YYYY')} &mdash;
                                            {moment(service.serviceLineAgreement.startDate)
                                                .add(service.serviceLineAgreement.length, 'months').format('MM/DD/YYYY')}
                                        </span>
                                        :
                                        <span className="ph-right">N/A</span>
                                        }
                                    </div>

                                    <div className="progress">
                                        {service.serviceLineAgreement && (
                                        <div
                                            className="progress-bar bg-info"
                                            role="progressbar"
                                            style={{width: serviceAgreementPeriodPercents + '%'}}
                                            aria-valuenow="4"
                                            aria-valuemin="0"
                                            aria-valuemax="100"
                                        />
                                        )}
                                    </div>

                                    <div className="progress-footer">
                                        {service.serviceLineAgreement &&
                                        <p className="mb-0 text-right">
                                            {moment(service.serviceLineAgreement.startDate)
                                                .add(service.serviceLineAgreement.length, 'months')
                                                .diff(moment(), 'weeks')
                                            } weeks remaining
                                        </p>
                                        }
                                    </div>

                                </div>
                            </div>
                            <div className="col-lg">
                                <div className="cmv-progress-bar">
                                    <div className="progress-header">
										<span className="ph-left"><i className="far fa-fw fa-calendar-alt"/> Service Period</span>
                                        <span className="ph-right">Started {moment(service.startDate).format('MM/DD/YYYY')}</span>
                                    </div>
                                    <div className="progress">
                                        <div className="progress-bar bg-info" role="progressbar" style={{width: '100%'}} />
                                    </div>
                                    <div className="progress-footer">
                                        <p className="mb-0 text-right">{moment().diff(moment(service.startDate), 'weeks')} weeks ago</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <ServiceSummary service={service} />

                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
};

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(ServiceWrapper);