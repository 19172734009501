import React, {Component, Fragment} from 'react';
import Select from 'react-select';
import {formSelectStyles} from '../../utils/SelectStyles';
import {get} from 'lodash';
import isEmpty from "../../utils/helpers";

class FormSelect extends Component {
    handleChange = value => {
        // this is going to call setFieldValue and manually update values.topcis
        this.props.onChange(this.props.fieldName, value.value);
    };

    handleBlur = () => {
        // this is going to call setFieldTouched and manually update touched.topcis
        this.props.onBlur(this.props.type, true);
    };

    // Change type with name like TextInput
    render() {
        const {options, isMulti, value, placeholder, title, info, errors, touched, fieldName, isDisabled, isLoading,
            setFieldValue, onChange, onInputChange, onPostChange} = this.props;

        const getValue = () => {
            if (!isEmpty(options)) {
                return  isMulti
                    ? options.filter(option => option.value.id ? value.find(curVal => curVal.id === option.value.id) : value.indexOf(option.value) >= 0)
                    : options.find(option => option.value === value) || "";
            } else {
                return isMulti ? [] : ("");
            }
        };

        const onChangeInternal = (option) => {
            if (!isEmpty(onChange)) {
                onChange(fieldName, option.value);
                return;
            }

            const newValue = isMulti
                ? (option).map((item: Option) => item.value)
                : (option).value;

            setFieldValue(
                fieldName,
                newValue
            );

            if (!isEmpty(onPostChange)) {
                onPostChange(options, newValue);
            }
        };

        return (
            <Fragment>

                {title &&
                <label htmlFor={fieldName}>{title}</label>
                }

                {info &&
                <i className="fas fa-fw fa-info-circle text-info" data-toggle="tooltip" data-html="true" title={info} />
                }

                <Select
                    inputId={fieldName}
                    name={fieldName}
                    options={options}
                    isMulti={isMulti}
                    onChange={onChangeInternal}
                    onInputChange={onInputChange}
                    onBlur={this.handleBlur}
                    value={getValue()}
                    placeholder={placeholder}
                    styles={formSelectStyles}
                    isDisabled={isDisabled}
                    isLoading={isLoading}
                    className={get(errors, `${fieldName}`) && get(touched, `${fieldName}`) && "is-invalid" || ""}
                />

                {get(errors, `${fieldName}`) && get(touched, `${fieldName}`) && (
                    <div className="invalid-feedback">{get(errors, `${fieldName}`)}</div>
                )}

            </Fragment>
        );
    }
}


export default FormSelect