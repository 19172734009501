import React, { Component, Fragment } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import moment from 'moment';
import { connect } from 'react-redux';
import { showModal } from '../../actions/modal.actions';
import { getSystemUsers, clearSystemUsers } from '../../actions/admin.actions'
import { getTroubleTicketUserGroups, createSystemUserCapacity, removeSystemUserCapacity, createSystemUserCapacities } from '../../actions/accountDetailsActions/troubleTickets.actions'
import { getUser, getAccountDetails } from "../../selectors";
import DatePicker from "react-datepicker";
import axiosInstance from '../../utils/axios-instance';
import { Formik } from 'formik';

class Technicians extends Component {

    state = {
        techniciansByUserGroup: [],
        timeslots: [],
        selectedDate: '',
        selectedUserGroup: ''
    };

    componentDidMount() {
        this.reloadUserGroups();
    }

    componentWillUnmount() {
        this.props.clearSystemUsers();
    }

    createSystemUserCapacity = (data) => {
        //{"systemUserId":7,"username":"amir","fromToTime":"08:00 - 10:00","timeslotId":1,"systemUserCapacityId":null}
        const payload = { userCapacity: { id: data.systemUserId, timeslotId: data.timeslotId }, date: this.state.selectedDate };
        try {
            this.props.createSystemUserCapacity(payload).then((response) => {
                this.setState({
                    techniciansByUserGroup: this.state.techniciansByUserGroup.map(el => (el.systemUserId === data.systemUserId && el.timeslotId === data.timeslotId ? { ...el, systemUserCapacityId: response.context } : el))
                });
            });
        } catch (err) {
            this.reloadTechnicians(this.state.selectedUserGroup, this.state.selectedDate);
        }

    }

    removeSystemUserCapacity = (data) => {
        try {
            this.props.removeSystemUserCapacity(data.systemUserCapacityId).then((response) => {
                this.setState({
                    techniciansByUserGroup: this.state.techniciansByUserGroup.map(el => (el.systemUserCapacityId === data.systemUserCapacityId ? { ...el, systemUserCapacityId: null } : el))
                });
            });
        } catch (err) {
            this.reloadTechnicians(this.state.selectedUserGroup, this.state.selectedDate);
        }
    }

    enableAll = () => {
        if (this.state.techniciansByUserGroup.length !== 0 && this.state.selectedDate && this.state.selectedDate !== '') {
                const data = this.state.techniciansByUserGroup.reduce((filtered, t) => {
                    if (t.systemUserCapacityId === null) {
                        filtered.push({ date: this.state.selectedDate, userCapacity: { id: t.systemUserId, timeslotId: t.timeslotId } });
                    }
                    return filtered;
                }, []);

                try {
                    this.props.createSystemUserCapacities(data).then(() => {
                        this.reloadTechnicians(this.state.selectedUserGroup, this.state.selectedDate);  
                    });
                } catch (err) {
                }
        }
    }

    reloadTechnicians = (groupName, date) => {
        try {
            this.setState({ techniciansByUserGroup: [] });
            if (groupName && date && groupName !== '' && date !== '') {
                axiosInstance.get(`/trouble-ticket/appointments/system-user-capacity/${groupName}/${date}`).then((response) => {
                    this.setState({ techniciansByUserGroup: response.data.users });
                });
            }
        } catch (err) {
        }
    }

    reloadUserGroups = () => {
        this.props.getTroubleTicketUserGroups();
    }

    userNameFormatter = (cell, row, rowIndex) => {
        return (
            <Fragment>
                <i className="fas fa-fw fa-user" />
                {cell}
            </Fragment>
        );
    };

    timeslotFormatter = (cell, row, rowIndex) => {
        return (
            <Fragment>
                {cell}
            </Fragment>
        );
    };

    actionsFormatter = (cell, row, rowIndex) => {
        return (
            <Fragment>
                <div className="tr-actions">
                    <div className="form-check checkbox-slider checkbox-slider--b-flat">
                        <label>
                            <input
                                type="checkbox"
                                name="isWorking"
                                checked={cell !== null}
                                onChange={(e) => {
                                    if (e.target.checked === true) {
                                        this.createSystemUserCapacity(row);
                                    }
                                    if (e.target.checked === false) {
                                        this.removeSystemUserCapacity(row);
                                    }
                                }}
                            />
                            {<span></span>}
                        </label>
                    </div>
                </div>
            </Fragment>
        );
    };

    render() {
        const columns = [
            {
                dataField: 'username',
                text: 'User name',
                sort: true,
                formatter: this.userNameFormatter
            },
            {
                dataField: 'fromToTime',
                text: 'Timeslot',
                sort: true,
                formatter: this.timeslotFormatter
            },
            {
                dataField: 'systemUserCapacityId',
                text: 'Working',
                formatter: this.actionsFormatter,
                align: 'right',
                headerAlign: 'right',
            }

        ];

        const rowStyle = (row, rowIndex) => {
            if (row) {
                let classes = null;

                if (row.note) {
                    classes = 'has-note';
                }

                if (rowIndex % 2 === 0) {
                    classes += ' odd';
                } else {
                    classes += ' even';
                }

                return classes;
            }
        };

        return (
            <Fragment>
                <div className="cmv-container-table">
                    <Fragment>
                        <Formik
                            initialValues={{
                                technicians: [],
                                date: ''
                            }}  
                            render={({ handleSubmit, handleBlur, values, touched, setFieldValue, errors }) => (
                                <form onSubmit={handleSubmit}>
                                    <ToolkitProvider
                                        bootstrap4
                                        keyField="id"
                                        data={this.state.techniciansByUserGroup}
                                        columns={columns}
                                        search
                                    >
                                        {(props) => (
                                            <Fragment>
                                                <div className="row">
                                                    <div className="col-sm-4">
                                                        <div className="form-group">
                                                            <select
                                                                className={
                                                                    errors.time_slot_id && touched.time_slot_id ? (
                                                                        'form-control is-invalid'
                                                                    ) : (
                                                                            'form-control customer-notes-cat'
                                                                        )
                                                                }
                                                                placeholder="Select user group"
                                                                value={values.time_slot_id}
                                                                onChange={(event) => {
                                                                    this.setState({ selectedUserGroup: event.target.value });
                                                                    this.reloadTechnicians(event.target.value, this.state.selectedDate);
                                                                }}
                                                                onBlur={handleBlur}
                                                                name="time_slot_id"
                                                            >
                                                                <option value="">Please select group</option>

                                                                {this.props.systemUserGroups && this.props.systemUserGroups.map((userGroup) => {
                                                                    return (
                                                                        <option value={userGroup.name} key={userGroup.name}>
                                                                            {userGroup.description}
                                                                        </option>
                                                                    );
                                                                    return null    //<== add this
                                                                })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-4">
                                                        <div className="form-group" >
                                                            <div className="customDatePickerWidth">
                                                                <DatePicker
                                                                    className={"form-control text-left" + (touched.date && errors.date ? " is-invalid" : "")}
                                                                    fieldName="date"
                                                                    dateFormat="MM/dd/yyyy"
                                                                    placeholderText="Enter Date"
                                                                    autoFocus={false}
                                                                    showTimeSelect={false}
                                                                    shouldCloseOnSelect={true}
                                                                    popperPlacement={'top'}
                                                                    selected={values.date !== '' ? values.date : null}
                                                                    onChange={(date) => {
                                                                        setFieldValue("date", date);
                                                                        const selectedDate = moment(date).format("YYYY-MM-DD");

                                                                        this.setState({ selectedDate: selectedDate });
                                                                        this.reloadTechnicians(this.state.selectedUserGroup, selectedDate);
                                                                    }
                                                                    }
                                                                    minDate={moment().toDate()}
                                                                />
                                                            </div>
                                                            {errors.date && touched.date &&
                                                                <div className="invalid-feedback">{errors.date}</div>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-4">
                                                        <div className="form-group" >
                                                            <button
                                                                onClick={this.enableAll}
                                                                className="btn btn-primary float-right"
                                                            >
                                                                ENABLE ALL
														</button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <BootstrapTable
                                                        wrapperClasses="table-responsive react-table-layout"
                                                        classes="table table-striped cmv-table"
                                                        bordered={false}
                                                        rowClasses={rowStyle}
                                                        noDataIndication="No data found"
                                                        {...props.baseProps}
                                                    />
                                                </div>
                                            </Fragment>
                                        )}
                                    </ToolkitProvider>
                                </form>
                            )}
                        />
                    </Fragment>
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    const userPermission = getUser(state).userPermission;

    const settingsPermissions =
        userPermission.find((x) => x.name === 'ADMN') &&
        userPermission.find((x) => x.name === 'ADMN').permissions;
    const canEdit = settingsPermissions && settingsPermissions.includes("ADMN_USER_EDIT");
    const canDelete = settingsPermissions && settingsPermissions.includes("ADMN_USER_DEL");
    const canAdd = settingsPermissions && settingsPermissions.includes("ADMN_USER_ADD");
    const canManageTokens = settingsPermissions && settingsPermissions.includes("CREATE_API_TOKEN");
    const canViewPartners = settingsPermissions && settingsPermissions.includes("ADMN_PARTNER_DETAIL");

    const systemUserGroups = getAccountDetails(state).troubleTickets.troubleTicketsUserGroups;

    return {
        canEdit,
        canDelete,
        canAdd,
        canManageTokens,
        canViewPartners,
        systemUserGroups
    };
};

const mapDispatchToProps = {
    getSystemUsers,
    showModal,
    clearSystemUsers,
    getTroubleTicketUserGroups,
    createSystemUserCapacity,
    removeSystemUserCapacity,
    createSystemUserCapacities
};

export default connect(mapStateToProps, mapDispatchToProps)(Technicians);
