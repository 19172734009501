import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {showModal, hideModal} from '../../../actions/modal.actions';
import { getSystemLocations } from '../../../actions/admin.actions';
import { getProducts, getModalData, getDomain, getAdmin} from '../../../selectors';
import { getPlanCategories, getServiceModels } from "../../../actions/domain.actions";
import { addFeaturePackage, updateFeaturePackage, getFeaturePackage,
    clearFeaturePackage, getAllFeatures, getAllFeaturePackages, getFeaturePackageOption} from "../../../actions/products.action";
import {FieldArray, Formik} from 'formik';
import NumericInput from 'react-numeric-input';
import {isEmpty} from "../../../utils/helpers";
import FormSelect from "../../../components/UI/FormSelect";
import TextInput from "../../../components/UI/TextInput";
import DatePicker from "react-datepicker";
import Loader from "../../../components/Loader";
import * as Yup from "yup";
import {toastr} from "react-redux-toastr";
import JoditEditor from "jodit-react";

const asOptions = (source, mapper) => {
    if (!Array.isArray(source)) {
        return [];
    }

    return source.map(mapper);
}

const featurePackagesAsOptions = (featurePackages) => asOptions(featurePackages, featurePackage => {
        return {
            label: featurePackage.description + " (" + featurePackage.name + ") " + (featurePackage.price != undefined ? "($" + featurePackage.price + ")" : ""),
            value: featurePackage.featurePackageId
        };
});

const removeCurrentPackage = (featurePackages, modalProps) => {
    //If we are editing an existing featurePackage, compared the package id to all
    // featurePackages and filter it out
    if(modalProps.type != 'create') {
        if ((featurePackages && featurePackages != undefined)) {
            return featurePackages.filter(featureOrPackage => featureOrPackage.featurePackageId != modalProps.value);
        }
    }
    return featurePackages;
}

const featuresAsOptions = (features) => asOptions(features, feature => {
    return {
        label: feature.description + " (" + feature.name + ") ($" + feature.price + ")",
        value: feature.id
    }
});

const currentSelectedFeatures = (packageOption) => {
    if(packageOption.features != undefined) {
        return packageOption.features.map(feature => feature.id)
    }
    return [];
}

const matchServiceModel = (featureOrPackages, serviceModelId) => {
    if(serviceModelId && !isEmpty(featureOrPackages)) {
        return featureOrPackages.filter(featureOrPackage => featureOrPackage.serviceModelId == serviceModelId);
    }else{
        return [];
    }
}

const serviceModelsAsOptions = (serviceModels) => asOptions(serviceModels, serviceModel => {
    return {
        label: serviceModel.description,
        value: serviceModel.id
    };
});

const planTypesAsOptions = (planTypes) => asOptions(planTypes, planType => {
    return {
        label: planType,
        value: planType
    };
});

const findCurrentLocationIds = (planLocations) => {
    let currentLocations = [];
    if(!isEmpty(planLocations)){
        planLocations.map((planLocation => {
            currentLocations.push(planLocation.location);
        }))
    }
    return currentLocations;
}


class AddFeaturePackageModal extends Component {

    state = {
        alertMessage: '',
        partnerSearchTem: null,
        belongsToPartner: false,
        selectedPartner: null,
        partnerOptions: [],
        locationOptions: [],
        isPackageLoading: true,
        requiredLines: 1,
        additionalLines: 0,
        showPackageOptions: true,
        showFeatureAndPackages: false,
        showOverWritePrice: false,
        minimumSelection: 0,
        maximumSelection: 0,
        showPackageOptionNote: false,
        packageOptions: [],
        featurePackageOptionsToDelete: [],
        submitForm: false,
        disablePackageSwitch: false
    };

    componentDidMount() {
        const {modalProps} = this.props;
        this.props.clearFeaturePackage();
        this.props.getAllFeatures();
        this.props.getServiceModels();
        this.props.getAllFeaturePackages();

        if(modalProps.value) {
            this.getFeaturePackageByProps(modalProps);
        }else{
            this.setState({
                isPackageLoading: false
            })
        }
        if(modalProps.type === 'create'){
            this.setState({
                packageOptions: [{
                    name: '',
                    description: '',
                    features: [],
                    order: 0,
                    showNote: false,
                    hideCustomerPortal: false
                }]
            })
        }else{
            if(!isEmpty(this.state.packageOptions.length)||
                (this.props.featurePackage.featurePackageFeatures.length > 0 ||
                    this.props.featurePackage.featurePackagePackages.length > 0 )){
                this.setState({
                    disablePackageSwitch: true
                })
            }
            this.setState({
                showPackageOptions: false,
                showFeatureAndPackages: true,
            })
        }
    }

    componentWillUnmount() {
        this.props.clearFeaturePackage();
    }

    getFeaturePackageByProps(modalProps) {
        this.props.getFeaturePackage(modalProps.value).then((response) => {
            if(response && (response.featurePackageFeatures || response.featurePackagePackages)){
                this.setState({
                    minimumSelection: response && response.minimumSelection,
                    maximumSelection: response && response.maximumSelection,
                    showOverWritePrice: response && response.price ? true : false,
                    isPackageLoading: false
                })
           }else if(response && (response.featurePackageOptions)){
                this.setState({
                    showPackageOptions: !this.state.showPackageOptions,
                    showFeatureAndPackages: !this.state.showFeatureAndPackages,
                    packageOptions: response.featurePackageOptions.sort((packageOption1, packageOption2) => (packageOption1.order > packageOption2.order) ? 1 : -1)
                })
            }

            this.setState({
                isPackageLoading: false,
            });
        })
    }

    handleRemovePackageOption = (formProps, remove, index, setFieldValue) => {
        let featurePackageOptionsToDelete = this.state.featurePackageOptionsToDelete;
        if(formProps.values.packageOptions != undefined){
            let values = {...formProps.values};
            if(values.packageOptions[index].featurePackageOptionId != undefined) {
                featurePackageOptionsToDelete.push(values.packageOptions[index].featurePackageOptionId);
                this.setState({
                    featurePackageOptionsToDelete: featurePackageOptionsToDelete
                })
            }
            values.packageOptions.splice(index, 1);
            for(let packageOptionIndex = index; packageOptionIndex < values.packageOptions.length; packageOptionIndex++){
                values.packageOptions[packageOptionIndex].order -= 1;
            }
            setFieldValue('packageOptions', values.packageOptions);
        }
    }

    handleMovePackageOption = (formProps, move, index, setFieldValue) => {
        if(formProps.values.packageOptions != undefined){
            let values = {...formProps.values};
            if(move == "UP"){
                let movingIndex = values.packageOptions[index];
                movingIndex.order -= 1;
                values.packageOptions[index - 1].order += 1;
                values.packageOptions[index] = values.packageOptions[index - 1];
                values.packageOptions[index - 1] = movingIndex;
                setFieldValue('packageOptions', values.packageOptions);
            }
            if(move == "DOWN"){
                let movingIndex = values.packageOptions[index];
                movingIndex.order += 1;
                values.packageOptions[index + 1].order -= 1;
                values.packageOptions[index] = values.packageOptions[index + 1];
                values.packageOptions[index + 1] = movingIndex;
            }
            setFieldValue('packageOptions', values.packageOptions);
        }
    }

    handleSetFeatures = (fieldName, value, packageOption, index) => {
        let packageOptions = this.state.packageOptions;
        if(!isEmpty(this.props.features) && value.length > 0){
            let features = [];
            this.props.features.map(feature => {
                if(value.includes(feature.id)){
                    features.push(feature);
                    packageOption.features = features;
                }
            })
            packageOptions[index] = packageOption;
        }else{
            packageOption.features = [];
            packageOptions[index] = packageOption;
        }
        this.setState({
            packageOptions: packageOptions
        })
    }

    toggleOptionsChange = () => {
        this.setState({
            showPackageOptions: !this.state.showPackageOptions,
            showFeatureAndPackages: !this.state.showFeatureAndPackages
        });
    }

    toggleShowOverwritePrice = () => {
        this.setState({
            showOverWritePrice: !this.state.showOverWritePrice,
        });
    }

    getFeatureForSubmit = (featureIds) => {
        let features = [];
        this.props.features.map(feature => {
            if(featureIds.includes(feature.id)){
                features.push(feature);
            }
        })
        return features;
    }

    getPackagesForSubmit = (packageIds) => {
        let packages = [];
        this.props.featurePackages.map(featurePackage => {
            if(packageIds.includes(featurePackage.featurePackageId)){
                packages.push(featurePackage);
            }
        })
        return packages;
    }

    addAnotherPackageOption = (packageOptions) => {
        let order = 0;
        if(packageOptions && !isEmpty(packageOptions)) {
            order = this.generateOrder(packageOptions);
        }
        packageOptions.push({
            name: '',
            description: '',
            features: [],
            order: order,
            showNote: false,
            note: '',
            hideCustomerPortal: false
        });
        this.setState({
            packageOptions: packageOptions
        });
    }


    formSubmit = (values, actions) => {
        if(this.state.submitForm) {
            const submitData = {...values};
            if (this.state.showPackageOptions) {
                submitData['featurePackageOptions'] = submitData.packageOptions;
                submitData['featurePackageOptionsToDelete'] = this.state.featurePackageOptionsToDelete;
            }
            if (this.state.showFeatureAndPackages) {
                submitData['featurePackageFeatures'] = this.getFeatureForSubmit(submitData.featurePackageFeatures);
                submitData['featurePackagePackages'] = this.getPackagesForSubmit(submitData.featurePackagePackages);
                submitData['minimumSelection'] = this.state.minimumSelection;
                submitData['maximumSelection'] = this.state.maximumSelection;
            }
            if (this.state.showOverWritePrice == false) {
                submitData['price'] = null;
            }
            const method = this.props.modalProps.type === 'create' ? this.props.addFeaturePackage : this.props.updateFeaturePackage;

            method(submitData).then((response) => {
                if (!response) {
                    return;
                }
                //If any failures happen on edit or create, show the error, only refresh if edit
                if (!response.success) {;
                    toastr.error(response.data.error.message, {timeOut: 8000, position: 'top-center'});
                    if (this.props.modalProps.type != 'create') {
                        this.setState({
                            isPackageLoading: true
                        })
                        this.getFeaturePackageByProps(this.props.modalProps);
                    }
                    return response.data.error;
                } else {
                    this.props.hideModal();
                    this.props.modalProps.reloadFunction(true);
                    toastr.success(response.message, {timeOut: 4000, position: 'top-center'});
                }
            });
        }
    }

    scrollDropdownIntoView = (e) => {
        const elementClicked = e.target.nodeName;
        const boundingElement = e.currentTarget;
        const modalBody = document.getElementsByClassName('modal-body')[0];

        if (elementClicked !== 'LABEL') {

            setTimeout(function () {

                // Scroll down if the bottom is hidden...
                if (boundingElement.getBoundingClientRect().bottom > modalBody.getBoundingClientRect().bottom) {

                    // ... and the top won't end up hidden by scrolling down
                    if (boundingElement.getBoundingClientRect().height < modalBody.getBoundingClientRect().height) {

                        // Scroll down till bottom of element reaches bottom of screen
                        boundingElement.scrollIntoView({block: "end"});
                    }
                }
            }, 200);
        }
    }

    loadPackagePackages = (featurePackage) => {
        let packagePackages = [];
        if(featurePackage && !isEmpty(featurePackage.featurePackagePackages)){
            featurePackage.featurePackagePackages.map(childPackage => {
                packagePackages.push(childPackage.featurePackageId)
            })
        }
        return packagePackages;
    }

    loadPackageFeatures = (featurePackage) => {
        let features = [];
        if(featurePackage && !isEmpty(featurePackage.featurePackageFeatures)){
            featurePackage.featurePackageFeatures.map(feature => {
                features.push(feature.id)
            })
        }
        return features;
    }

    generateOrder = (packageOptions) => {
        if(packageOptions != undefined && packageOptions.length > 0){
            return packageOptions[packageOptions.length - 1].order + 1;
        }
        return 0;
    }

    handleSelectionChange = (target, value) => {
        if(target == 'minimumSelection'){
            this.setState({
                minimumSelection: value
            });
        }else {
            this.setState({
                maximumSelection: value
            })
        }
    }

    optionNote = (isSubmit, value) => {
        if (isSubmit) {
            this.props.updateFeaturePackageNote(value).then((response) => {
                if (response && response.data.success) {
                    toastr.success(response.data.message,
                        {timeOut: 2000, position: 'top-center'}
                    );
                } else {
                    toastr.error("Failed to update feature package note",
                        {timeOut: 2000, position: 'top-center'});
                }
            });
        } else {
            return Promise.resolve(this.props.getFeaturePackageOption(value));
        }
    }

    featurePackageSchema = () => {
        let validationSchema = {
            name: Yup.string().required('Required'),
            description: Yup.string().required('Required'),
            planType: Yup.string().required('Required'),
            serviceModelId : Yup.number().required('Required').typeError('Required'),
            price: this.state.showOverWritePrice ? Yup.number().required('Required').typeError('Required') : '',

            //Check if showPackageOptions is true
            packageOptions: this.state.showPackageOptions ? Yup.array(Yup.object().shape({
                name: Yup.string().required('Required'),
                description: Yup.string().required('Required'),
                features: Yup.array().min(1, 'Required'),
            })).min(1) : ''
        };

        return Yup.object().shape(validationSchema);
    }

    showPackageOptionNote(packageOption, index){
        if(packageOption.showNote != undefined) {
            packageOption["showNote"] = !packageOption.showNote;
        }else{
            packageOption["showNote"] = true;
        }
        let packageOptions = {...this.state.packageOptions}
        packageOptions[index] = packageOption;
        this.setState({packageOptions: packageOptions})
    }

    toggleHideCustomerPortal = (packageOption, index) =>{
        if(packageOption.hideCustomerPortal != undefined) {
            packageOption["hideCustomerPortal"] = !packageOption.hideCustomerPortal;
        }else{
            packageOption["hideCustomerPortal"] = true
        }
        let packageOptions = {...this.state.packageOptions}
        packageOptions[index] = packageOption;
        this.setState({packageOptions: packageOptions})
    }

    updateSubmitFormState = (values, errors, touched) => {
        if(isEmpty(errors) && !isEmpty(touched)) {
            this.setState({
                submitForm: true
            }, () => {
                this.formSubmit(values);
            });
        }
    }


    render() {
        const { alertMessage, isPackageLoading, showPackageOptions, showFeatureAndPackages, showOverWritePrice, disablePackageSwitch } = this.state;
        const { modalProps, featurePackage, featurePackages, serviceModels, locationsLoader, features} = this.props;
        const planTypes = ["POSTPAID", "PREPAID"];

        return (
            <Fragment>
                <div className="modal" style={{display: 'block'}} tabIndex="-1" role="dialog">
                    {isPackageLoading &&
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">
                                    {modalProps.type === 'create' ? 'Add Feature Package' : 'Edit Feature Package'}
                                </h5>
                                <button onClick={this.props.hideModal} type="button" className="close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <Loader />
                            <div className="modal-footer">
                                <button onClick={this.props.hideModal} className="btn" type="button">
                                    Cancel
                                </button>
                                <button type="submit" className="btn btn-primary">
                                    {modalProps.type === 'create' ? 'Add Feature Package' : 'Edit Feature Package'}
                                </button>
                            </div>
                        </div>
                    </div>
                    }
                    {!isPackageLoading &&
                    <Formik
                        initialValues={{
                            status: modalProps.type === 'create' ? true : featurePackage && featurePackage.status,
                            featurePackageId: featurePackage && featurePackage.featurePackageId || null,
                            name: featurePackage &&  featurePackage.name || '',
                            description: featurePackage && featurePackage.description || '',
                            planType: featurePackage && featurePackage.planType || '',
                            serviceModelId: featurePackage && featurePackage.serviceModelId || null,
                            note: featurePackage && featurePackage.note || null,
                            featurePackagePackages: this.loadPackagePackages(featurePackage),
                            featurePackageFeatures: this.loadPackageFeatures(featurePackage),
                            packageOptions: this.state.packageOptions,
                            price: featurePackage && featurePackage.price || 0.00
                        }}
                        validationSchema={this.featurePackageSchema()}
                        onSubmit={this.formSubmit}
                        render={(props) => {

                            const {
                                setFieldValue,
                                handleChange,
                                handleSubmit,
                                handleBlur,
                                values,
                                errors,
                                touched,
                                isSubmitting,
                            } = props;
                            return(
                                <form onSubmit={handleSubmit}>
                                <div className="modal-dialog modal-lg">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title">
                                                {modalProps.type === 'create' ? 'Add Feature Package' : 'Edit Feature Package'}
                                            </h5>
                                            <button onClick={this.props.hideModal} type="button" className="close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div className="modal-body form-horizontal">
                                            {!isEmpty(alertMessage) && (
                                                <div className="alert alert-inline alert-danger alert-dismissible">
                                                    <p className="mb-0">{alertMessage}</p>
                                                </div>
                                            )}
                                            <div className="form-group">
												<span className="h-check">
													<label>&nbsp;Active&nbsp;</label>
													<div className="form-check checkbox-slider checkbox-slider--b-flat">
														<label>
															<input
                                                                name="status"
                                                                type="checkbox"
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                checked={values.status}
                                                            />
															<span>&nbsp;</span>
														</label>
													</div>
												</span>
                                            </div>
                                            <div className="form-group">
                                                <TextInput
                                                    label="Name"
                                                    name="name"
                                                    value={values.name.toUpperCase()}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    errors={errors}
                                                    touched={touched}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <TextInput
                                                    label="Description"
                                                    name="description"
                                                    value={values.description}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    errors={errors}
                                                    touched={touched}
                                                />
                                            </div>
                                            <div className="form-group">
                                            <div onClick={this.scrollDropdownIntoView.bind(this)}>
                                                    <FormSelect
                                                        title="Service Model"
                                                        fieldName="serviceModelId"
                                                        placeholder="Select one..."
                                                        options={serviceModelsAsOptions(serviceModels)}
                                                        setFieldValue={setFieldValue}
                                                        value={values.serviceModelId}
                                                        onBlur={handleBlur}
                                                        isDisabled={(this.props.modalProps.type === 'create' ? false: true)}
                                                        errors={errors}
                                                        touched={touched}
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div onClick={this.scrollDropdownIntoView.bind(this)}>
                                                    <FormSelect
                                                        title="Plan Type"
                                                        fieldName="planType"
                                                        placeholder="Select one..."
                                                        options={planTypesAsOptions(planTypes)}
                                                        isDisabled={(this.props.modalProps.type === 'create' ? false: true)}
                                                        setFieldValue={setFieldValue}
                                                        value={values.planType}
                                                        onBlur={handleBlur}
                                                        errors={errors}
                                                        touched={touched}
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="form-check checkbox-slider checkbox-slider--b-flat">
                                                    <label>
                                                        <input
                                                            name="showPackageOptions"
                                                            type="checkbox"
                                                            onBlur={handleBlur}
                                                            onChange={this.toggleOptionsChange}
                                                            checked={this.state.showPackageOptions}
                                                            disabled={disablePackageSwitch}
                                                        />
                                                        <span>&nbsp;</span>
                                                    </label>
                                                    <label>Include Package Options</label>
                                                </div>
                                                <div className="form-check checkbox-slider checkbox-slider--b-flat">
                                                    <label>
                                                        <input
                                                            name="showFeaturesAndPackages"
                                                            type="checkbox"
                                                            onBlur={handleBlur}
                                                            onChange={this.toggleOptionsChange}
                                                            checked={this.state.showFeatureAndPackages}
                                                            disabled={disablePackageSwitch}
                                                        />
                                                        <span>&nbsp;</span>
                                                    </label>
                                                    <label>Include Feature & Packages</label>
                                                </div>
                                            </div>
                                            {showPackageOptions &&
                                            <div className="form-group">
                                                <FieldArray name="packageOptions"
                                                            render={({remove, push}) => <>
                                                                {values.packageOptions && values.packageOptions.map((packageOption, index) =>
                                                                    <div className="" key={packageOption.id} style={{paddingBottom: '10px'}}>
                                                                            <div className="card card-sm">
                                                                                <div className="card-header" style={{paddingRight: '0px'}}>
                                                                                    <div className="row" style={{width: '100%'}}>
                                                                                        <div className="col-md-4">
                                                                                            <label style={{fontSize: "0.95rem"}}>Package Option #{index + 1}</label>
                                                                                        </div>
                                                                                        {(index !== 0 || values.packageOptions.length > 1) && (
                                                                                        <div className="form-group col-md-8" style={{textAlign: "right", paddingRight: '0px'}}>
                                                                                            {(index != 0) && (
                                                                                                <button
                                                                                                    onClick={() => this.handleMovePackageOption(props, "UP", index, setFieldValue)}
                                                                                                    className="btn btn-sm btn-primary"
                                                                                                    type="button"
                                                                                                >
                                                                                                    Move Up
                                                                                                </button>
                                                                                            )}
                                                                                            {(index != values.packageOptions.length - 1) && (
                                                                                                <>
                                                                                                    &nbsp;
                                                                                                <button
                                                                                                    onClick={() => this.handleMovePackageOption(props, "DOWN", index, setFieldValue)}
                                                                                                    className="btn btn-sm btn-primary"
                                                                                                    type="button"
                                                                                                >
                                                                                                    Move Down
                                                                                                </button>
                                                                                                </>
                                                                                            )}
                                                                                            &nbsp;
                                                                                            <button
                                                                                                onClick={() => this.handleRemovePackageOption(props, remove, index, setFieldValue)}
                                                                                                className="btn btn-sm btn-danger"
                                                                                                type="button"
                                                                                            >
                                                                                                Remove
                                                                                            </button>
                                                                                        </div>
                                                                                        )}
                                                                                    </div>
                                                                                </div>
                                                                                <div className="card-body">
                                                                                    <div className="row">
                                                                                        <div className="form-group col-sm-4">
                                                                                            <TextInput
                                                                                                label="Option Name"
                                                                                                name={`packageOptions.${index}.name`}
                                                                                                value={packageOption.name.toUpperCase()}
                                                                                                onChange={handleChange}
                                                                                                onBlur={handleBlur}
                                                                                                errors={errors}
                                                                                                touched={touched}
                                                                                            />
                                                                                        </div>
                                                                                        <div className="form-group col-sm-7">
                                                                                            <TextInput
                                                                                                label="Option Description"
                                                                                                name={`packageOptions.${index}.description`}
                                                                                                value={packageOption.description}
                                                                                                onChange={handleChange}
                                                                                                onBlur={handleBlur}
                                                                                                errors={errors}
                                                                                                touched={touched}
                                                                                            />
                                                                                        </div>
                                                                                        <div className="form-group col-sm-1 note-btn">
                                                                                            <button
                                                                                                className="btn"
                                                                                                type="button"
                                                                                                onClick={() => this.showPackageOptionNote(packageOption, index)}
                                                                                            >
                                                                                                <i className="fas fa-fw fa-info-circle text-info"/>
                                                                                            </button>
                                                                                        </div>
                                                                                    </div>
                                                                                    {this.state.packageOptions && this.state.packageOptions[index].showNote &&
                                                                                    <div className="form-group">
                                                                                        <label>Detailed Description</label>
                                                                                        <JoditEditor
                                                                                            name={"note"}
                                                                                            value={packageOption.note}
                                                                                            config={{
                                                                                                buttons: ['bold', 'italic', 'underline', 'strikethrough',
                                                                                                    'ul', 'ol', 'font', 'fontsize', 'brush', 'paragraph', 'superscript', 'subscript',
                                                                                                    'image', 'video', 'file', 'hr', 'table', 'link', 'symbol', 'indent',
                                                                                                    'source', 'fullsize', 'preview'
                                                                                                ],
                                                                                                readonly: false,
                                                                                                toolbarAdaptive: false,
                                                                                                showCharsCounter: false,
                                                                                                showWordsCounter: false,
                                                                                                showXPathInStatusbar: false,
                                                                                                style: {
                                                                                                    background: 'white',
                                                                                                }
                                                                                            }}
                                                                                            tabIndex={1} // tabIndex of textarea
                                                                                            onBlur={newContent => {
                                                                                                packageOption.note = newContent
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                    }
                                                                                    <div className="form-group">
                                                                                        <div onClick={this.scrollDropdownIntoView.bind(this)}>
                                                                                            <FormSelect
                                                                                                title="Selected Features"
                                                                                                fieldName={`packageOptions.${index}.features`}
                                                                                                options={featuresAsOptions(matchServiceModel(features, values.serviceModelId))}
                                                                                                setFieldValue={(fieldName, value) => this.handleSetFeatures(fieldName, value, packageOption, index)}
                                                                                                value={currentSelectedFeatures(this.state.packageOptions[index])}
                                                                                                isMulti={true}
                                                                                                onBlur={handleBlur}
                                                                                                errors={errors}
                                                                                                touched={touched}
                                                                                                isLoading={locationsLoader}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="form-group">
                                                                                        <div className="form-check checkbox-slider checkbox-slider--b-flat">
                                                                                            <label>
                                                                                                <input
                                                                                                    name="showPackageOptions"
                                                                                                    type="checkbox"
                                                                                                    onBlur={handleBlur}
                                                                                                    onChange={() => this.toggleHideCustomerPortal(packageOption, index)}
                                                                                                    checked={packageOption.hideCustomerPortal}
                                                                                                    value={this.state.packageOptions[index].hideCustomerPortal}
                                                                                                />
                                                                                                <span>&nbsp;</span>
                                                                                            </label>
                                                                                            <label>Hide in Customer Portal</label>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                    </div>
                                                                )}

                                                                <span>&nbsp;</span>
                                                                <div className="clearfix">
                                                                    <button
                                                                        onClick={() => this.addAnotherPackageOption(values.packageOptions)}
                                                                        className="btn btn-sm btn-outline-secondary btn-discount-add float-right"
                                                                        type="button"
                                                                    >
                                                                        {this.state.packageOptions.length > 0 ? "Add Another" : "Add Package Option"}
                                                                    </button>
                                                                </div>

                                                            </>
                                                            }
                                                />
                                            </div>
                                            }
                                            {showFeatureAndPackages &&
                                                <Fragment>
                                                    <div className="form-group">
                                                        <div onClick={this.scrollDropdownIntoView.bind(this)}>
                                                            <FormSelect
                                                                title="Selected Features"
                                                                fieldName="featurePackageFeatures"
                                                                options={featuresAsOptions(matchServiceModel(features, values.serviceModelId))}
                                                                setFieldValue={setFieldValue}
                                                                value={values.featurePackageFeatures}
                                                                isMulti={true}
                                                                onBlur={handleBlur}
                                                                errors={errors}
                                                                touched={touched}
                                                                isLoading={locationsLoader}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <div onClick={this.scrollDropdownIntoView.bind(this)}>
                                                            <FormSelect
                                                                title="Selected Packages"
                                                                fieldName="featurePackagePackages"
                                                                options={featurePackagesAsOptions(matchServiceModel(removeCurrentPackage(featurePackages,this.props.modalProps), values.serviceModelId))}
                                                                setFieldValue={setFieldValue}
                                                                value={values.featurePackagePackages}
                                                                isMulti={true}
                                                                onBlur={handleBlur}
                                                                errors={errors}
                                                                touched={touched}
                                                                isLoading={locationsLoader}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <NumericInput
                                                            style={{
                                                                input: {
                                                                    width: '50px',
                                                                    textAlign: 'center'
                                                                }
                                                            }}
                                                            min={0}
                                                            max={99}
                                                            value={this.state.minimumSelection}
                                                            onChange={(event) => this.handleSelectionChange('minimumSelection', event)}/>
                                                        <span>&nbsp;</span>
                                                        <label style={{
                                                            paddingLeft: '10px'}}>Minimum Selection</label>
                                                    </div>
                                                    <div className="form-group">
                                                        <NumericInput
                                                            style={{
                                                                input: {
                                                                    width: '50px',
                                                                    textAlign: 'center'
                                                                }
                                                            }}
                                                            min={0}
                                                            max={99}
                                                            value={this.state.maximumSelection}
                                                            onChange={(event) => this.handleSelectionChange('maximumSelection', event)}/>
                                                        <span>&nbsp;</span>
                                                        <label style={{
                                                            paddingLeft: '10px'}}>Maximum Selection</label>
                                                    </div>
                                                    <div className="form-group">
                                                        <div className="form-check checkbox-slider checkbox-slider--b-flat">
                                                            <label>
                                                                <input
                                                                    name="includeSignup"
                                                                    type="checkbox"
                                                                    onBlur={handleBlur}
                                                                    onChange={this.toggleShowOverwritePrice}
                                                                    checked={this.state.showOverWritePrice}
                                                                />
                                                                <span>&nbsp;</span>
                                                            </label>
                                                            <label >Overwrite Pricing</label>
                                                        </div>
                                                    </div>
                                                    {showOverWritePrice &&
                                                    <div className="form-group">
                                                        <div className="input-group">
                                                            <div className="input-group-prepend">
                                                                <span className="input-group-text">$</span>
                                                            </div>
                                                            <input
                                                                type="text"
                                                                name="price"
                                                                disabled={isSubmitting}
                                                                value={values.price}
                                                                data-amount={''}
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                autoComplete={"off"}
                                                            />
                                                        </div>
                                                    </div>
                                                    }
                                                </Fragment>
                                                }
                                        </div>
                                        <div className="modal-footer">
                                            <button onClick={this.props.hideModal} className="btn" type="button">
                                                Cancel
                                            </button>
                                            <button disabled={this.state.submitForm}
                                                    onClick={() => this.updateSubmitFormState(values, errors, touched)} type="submit" className="btn btn-primary">
                                                {modalProps.type === 'create' ? 'Add Feature Package' : 'Edit Feature Package'}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            )}}
                    />}})
                </div>
                <div className="modal-backdrop show" tabIndex="1"/>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    const modalProps = getModalData(state).modalProps,
        featurePackage = getProducts(state).featurePackage,
        features = getProducts(state).features,
        featurePackages = getProducts(state).featurePackages,
        systemLocations = getAdmin(state).systemLocations,
        planCategories = getDomain(state).planCategories,
        serviceModels = getDomain(state).serviceModels


    return {
        modalProps,
        featurePackage,
        features,
        featurePackages,
        systemLocations,
        planCategories,
        serviceModels
    };
};

const mapDispatchToProps = {
    getServiceModels,
    getAllFeatures,
    getAllFeaturePackages,
    getFeaturePackage,
    getPlanCategories,
    getSystemLocations,
    clearFeaturePackage,
    addFeaturePackage,
    updateFeaturePackage,
    getFeaturePackageOption,
    hideModal,
    showModal
};

export default connect(mapStateToProps, mapDispatchToProps)(AddFeaturePackageModal);

