import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {
    CamModuleMenu,
    DashboardModuleMenu,
    OperModuleMenu,
    ReportModuleMenu,
    SettingModuleMenu,
    AdministrationModuleMenu,
    OperationModuleMenu,
} from './MenuModules';

class SideBar extends Component {

    state = {
        activeMenu: '',
        selectedOption: null,
    };

    changeActiveMenu = (menu) => {
        this.setState({
            activeMenu: this.state.activeMenu !== menu ? menu : ''
        });
    };

    renderMainMenu = (menuItem) => {
        const {activeMenu} = this.state;
        const {userPermission, dashboardMenuCount, reportCategories} = this.props;

        let modulePermisions;

        switch (menuItem) {
            case 'SHOW_MENU_CAM':
                modulePermisions =
                    userPermission.find((x) => x.name === 'CAM') &&
                    userPermission.find((x) => x.name === 'CAM').permissions;
                return (
                    <CamModuleMenu
                        key={'CamModuleMenu'}
                        activeMenu={activeMenu}
                        changeActiveMenu={this.changeActiveMenu}
                        permissions={modulePermisions}
                    />
                );
            // case 'SHOW_MENU_DASH':
            // 	modulePermisions =
            // 		userPermission.find((x) => x.name === 'DASH') &&
            // 		userPermission.find((x) => x.name === 'DASH').permissions;
            // 	return (
            // 		<DashboardModuleMenu
            //          key={'DashboardModuleMenu'}
            // 			activeMenu={activeMenu}
            // 			changeActiveMenu={this.changeActiveMenu}
            // 			permissions={modulePermisions}
            // 		/>
            // 	);
            case 'SHOW_MENU_OPR':
                modulePermisions =
                    userPermission.find((x) => x.name === 'OPER') &&
                    userPermission.find((x) => x.name === 'OPER').permissions;
                return (
                    <OperModuleMenu
                        key={'OperModuleMenu'}
                        activeMenu={activeMenu}
                        changeActiveMenu={this.changeActiveMenu}
                        permissions={modulePermisions}
                        dashboardMenuCount={dashboardMenuCount}
                    />
                );
            default:
                return null;
        }
    };

    render() {

        const {activeMenu} = this.state;
        const {userPermission, userSummary, reportCategories} = this.props;

        const userPermissionMenu = userPermission.find((permision) => permision.name === 'WEB_MENU');

        const settingsPermissions =
            userPermission.find((x) => x.name === 'ADMN') &&
            userPermission.find((x) => x.name === 'ADMN').permissions;

        return (
            <nav id="sidebar" className="sidebar-wrapper show">
                <div className="sidebar-content">

                    <div className="sidebar-menu">

                        <ul>
                            <li className="header-menu">
                                <span>Main</span>
                            </li>
                            <li className="active">
                                <Link to={{pathname: '/home'}}>
                                    <i className="fa fa-home"/>
                                    <span>Home</span>
                                </Link>
                            </li>
                            {userPermissionMenu.permissions.map((menu) => this.renderMainMenu(menu))}
                        </ul>

                        {userPermissionMenu.permissions.includes('SHOW_MENU_ADMIN') && (
                        <OperationModuleMenu
                            activeMenu={activeMenu}
                            changeActiveMenu={this.changeActiveMenu}
                            permissions={settingsPermissions}
                            userPermissionMenu={userPermissionMenu}
                        />
                        )}

                        {userPermissionMenu.permissions.includes('SHOW_MENU_ADMIN') && (
                        <AdministrationModuleMenu
                            key={'AdministrationModuleMenu'}
                            activeMenu={activeMenu}
                            changeActiveMenu={this.changeActiveMenu}
                            permissions={settingsPermissions}
                            userPermissionMenu={userPermissionMenu}
                        />
                        )}

                        {userPermissionMenu.permissions.includes('SHOW_MENU_REP') &&
                        <ReportModuleMenu
                            key={'ReportModuleMenu'}
                            activeMenu={activeMenu}
                            changeActiveMenu={this.changeActiveMenu}
                            reportCategories={reportCategories}
                        />
                        }

                        <SettingModuleMenu
                            key={'SettingModuleMenu'}
                            activeMenu={activeMenu}
                            changeActiveMenu={this.changeActiveMenu}
                            permissions={settingsPermissions}
                            userPermission={userPermissionMenu}
                        />

                    </div>

                    <div className="sidebar-footer">
                        <p className="sidebar-version">v 6.38.10</p>
                    </div>

                </div>
            </nav>
        );
    }
}

SideBar.propTypes = {
    userSummary: PropTypes.object,
    userPermission: PropTypes.array.isRequired,
};

export default SideBar;
