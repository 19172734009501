import React, { Component, Fragment } from 'react';
import NavTabs from './NavTabs';
import { Formik, Form } from 'formik';
import { validationSchema } from './validation';
import { AccountHolder, AccountTerms, BillingDetails, Confirmation, Summary } from './Tabs/index';
import {
	getAccountTypes,
	getSecurityQuestions,
	getCountryStates,
	getAccountTypeDetails,
	getAccountTermDetails,
	createNewAccount,
	clearCreateAccount,
	setAccountType,
	mailingAddressValidation,
	billingAddressValidation,
	existingAccountValidation,
	sendAuthCode,
	verifyAuthCode,
	getAccountDiscounts,
	getAccountRecurringCredits,
	getAccountTaxCodes,
	ssnEvaluation,
	getAccountContactMemberNumber
} from '../../actions/createAccount.actions';
import { connect } from 'react-redux';
import { getCreateAccount, getUser, createLoadingSelector } from '../../selectors';
import {
	initialValues,
	addressValues,
	isEmpty,
	getTransformedAccountTerms,
	getTransformedAccountTier,
	getTransformedCicle,
	getTransformAccountTypes,
	getTransformCountryStates,
	getTransformQuestions
} from './utils';
import { showModal } from '../../actions/modal.actions';
import { debounce } from 'lodash';
import WizardButtons from './WizardButtons';
import { toastr } from "react-redux-toastr";

class createAccount extends Component {
	state = {
		activeTab: 0,
		isActive: false,
		accountHolderRequiredFields: {},
		accountHolderEnableFields: {},
		accountHolderOptionalFields: {},
		suggestedMailingAddress: { ...addressValues },
		suggesteBillingAddress: { ...addressValues },
		// showSuggestedExistingAccounts: [],
		selectedExistingAccount: {},
		suggestedMailingAddressError: '',
		suggestedBillingAddressError: '',
		showSuggestedMailingAddress: false,
		showSuggestedBillingAddress: false,
		showSuggestedExistingAccounts: false,
		showVerificationMobileNumber: false,
		showEvaluationStatus: false,
		evaluationData: {},
		authCode: '',
		authCodeRequest: false,
		isAuthCodeValid: null,
		// authCodeInvalid: false,
		initialValues: { ...initialValues, locationId: this.props.locationId },
		isServiceRequestStarted: false,
		enableMemberNumberGeneration: false
	};

	componentDidMount() {
		const { history } = this.props;

		//Check if the user is redirected from create service order
		if (history.location.state && history.location.state.serviceOrderData) {
			this.setState({
				isServiceRequestStarted: true
			});
		}

		// Change navbar type and color
		document.title = 'Create Account - camvio.cloud';
		this.props.changeNavBarType('lock', 'createAccount', 'Create Account');

		this.props.getAccountTypes();
		this.props.getSecurityQuestions();
		this.props.getCountryStates();
	}

	componentDidUpdate(prevProps) {
		if (prevProps.locationId !== this.props.locationId) {
			let updatedInitialValues = { ...this.state.initialValues };
			updatedInitialValues.locationId = this.props.locationId;
			this.setState({ initialValues: updatedInitialValues });
		}
	}

	componentWillUnmount() {
		this.props.clearCreateAccount();
	}

	renderTabContent = (props) => {
		const {
			newAccountInfo,
			accountTypes,
			securityQuestions,
			countryStates,
			accountTerms,
			billCycles,
			accountTiers,
			defaultAccountId,
			typeAccountLoader,
			mailingAddressLoader,
			billingAddressLoader,
			existingAccountsLoader,
			authCodeLoader,
			evaluationLoader,
			authCodeVerifyLoader,
			showModal,
			discounts,
			recurringCredits,
			taxCodesData,
			getAccountContactMemberNumber
		} = this.props;

		const { enableMemberNumberGeneration } = this.state;

		//Transform data for react-select in object type = {value: '', label: ''}
		const transformedAccountTerms = getTransformedAccountTerms(accountTerms);
		const transformedAccountTier = getTransformedAccountTier(accountTiers);
		const transformedCicle = getTransformedCicle(billCycles);
		const transformAccountTypes = getTransformAccountTypes(accountTypes);
		const transformCountryStates = getTransformCountryStates(countryStates);
		const transformQuestions = getTransformQuestions(securityQuestions);

		let tabContent;
		switch (this.state.activeTab) {
			case 0:
				let validatedAdrress = {};
				if (!isEmpty(this.props.history.location.state) &&
					!isEmpty(this.props.history.location.state.serviceOrderData)) {
					validatedAdrress = this.props.history.location.state.serviceOrderData.validatedAddress;
				}
				tabContent = (
					<AccountHolder
						accountTypeChangeHandler={this.accountTypeChangeHandler}
						questionsOptions={transformQuestions}
						accountTypesOptions={transformAccountTypes}
						countryStatesOptions={transformCountryStates}
						// accountTypeValidationRules={accountTypeValidationRules}
						typeAccountLoader={typeAccountLoader}
						mailingAddressValidation={this.mailingAddressValidation}
						showSuggestedMailingAddress={this.state.showSuggestedMailingAddress}
						suggestedMailingAddress={this.state.suggestedMailingAddress}
						suggestedMailingAddressError={this.state.suggestedMailingAddressError}
						useSuggestedMailingAddress={this.useSuggestedMailingAddress}
						mailingAddressLoader={mailingAddressLoader}
						existingAccountsLoader={existingAccountsLoader}
						accountHolderEnableFields={this.state.accountHolderEnableFields}
						accountHolderRequiredFields={this.state.accountHolderRequiredFields}
						accountHolderOptionalFields={this.state.accountHolderOptionalFields}
						existingAccountValidation={this.existingAccountValidation}
						showSuggestedExistingAccounts={this.state.showSuggestedExistingAccounts}
						suggestedExistingAccounts={this.state.suggestedExistingAccounts}
						handleExistingAccountChange={this.handleExistingAccountChange}
						selectedExistingAccount={this.state.selectedExistingAccount}
						closeSuggestion={this.closeSuggestion}
						onSubmitAuthCode={this.onSubmitAuthCode}
						onSubmitVerifyAuthCode={this.onSubmitVerifyAuthCode}
						showVerificationMobileNumber={this.state.showVerificationMobileNumber}
						onChangeAuthCodeHandler={this.onChangeAuthCodeHandler}
						authCode={this.state.authCode}
						authCodeLoader={authCodeLoader}
						authCodeVerifyLoader={authCodeVerifyLoader}
						isAuthCodeValid={this.state.isAuthCodeValid}
						resetAuthCodeState={this.resetAuthCodeState}
						accountTermsOptions={transformedAccountTerms}
						handleAccountTermDetails={this.handleAccountTermDetails}
						accountTiers={transformedAccountTier}
						showEvaluationStatus={this.state.showEvaluationStatus}
						handleEvaluateStatusChange={this.handleEvaluateStatusChange}
						evaluationLoader={evaluationLoader}
						evaluationData={this.state.evaluationData}
						resetEvaluateStatus={this.resetEvaluateStatus}
						continueWithServiceRequest={this.continueWithServiceRequest}
						isServiceRequestStarted={this.state.isServiceRequestStarted}
						getAccountContactMemberNumber={getAccountContactMemberNumber}
						enableMemberNumberGeneration={enableMemberNumberGeneration}
						validatedAddress={validatedAdrress}
						{...props}
					/>
				);
				break;
			case 12:
				tabContent = (
					<AccountTerms
						getAccountTypeDetails={this.props.getAccountTypeDetails}
						accountTerms={transformedAccountTerms}
						billCycles={transformedCicle}
						handleAccountTermDetails={this.handleAccountTermDetails}
						accountTiers={transformedAccountTier}
						defaultAccountId={defaultAccountId}
						{...props}
					/>
				);
				break;
			case 1:
				tabContent = (
					<BillingDetails
						countryStatesOptions={transformCountryStates}
						showBillingAddress={this.showBillingAddress}
						isBillingAddress={this.state.isBillingAddress}
						billingAddressValidation={this.billingAddressValidation}
						showSuggestedBillingAddress={this.state.showSuggestedBillingAddress}
						suggestedBillingAddress={this.state.suggestedBillingAddress}
						suggestedBillingAddressError={this.state.suggestedBillingAddressError}
						useSuggestedBillingAddress={this.useSuggestedBillingAddress}
						billingAddressLoader={billingAddressLoader}
						closeSuggestion={this.closeSuggestion}
						getDiscounts={this.getDiscounts}
						getRecurringCredits={this.getRecurringCredits}
						discounts={discounts}
						recurringCredits={recurringCredits}
						handleDiscountChange={this.handleDiscountChange}
						billCycles={transformedCicle}
						taxCodesData={taxCodesData}
						getTaxCodes={this.getTaxCodes}
						{...props}
					/>
				);
				break;
			case 2:
				tabContent = (
					<Summary
						accountTypesOptions={transformAccountTypes}
						accountTermsOptions={transformedAccountTerms}
						accountTiersOptions={transformedAccountTier}
						transformQuestionsOptions={transformQuestions}
						billCyclesOptions={transformedCicle}
						taxCodesData={taxCodesData}
						{...props}
					/>
				);
				break;
			case 3:
				tabContent = (
					<Confirmation
						newAccountInfo={newAccountInfo}
						history={this.props.history}
						resetTheWizard={this.resetTheWizard}
						showModal={showModal}
						continueWithServiceRequest={this.continueWithServiceRequest}
						isServiceRequestStarted={this.state.isServiceRequestStarted}
						{...props}
					/>
				);
				break;
			default:
				tabContent = <AccountHolder />;
				break;
		}

		return tabContent;
	};

	continueWithServiceRequest = (accountId) => {
		const { history } = this.props;
		let accountData = {
			accountId
		};

		let data = { ...history.location.state.serviceOrderData.formValues, ...accountData };

		const serviceOrderData = {
			formValues: data,
			isServiceOrderStarted: true,
			planValues: history.location.state.serviceOrderData.planValues,
			validatedAddress: history.location.state.serviceOrderData.validatedAddress
		};

		history.push('/service-request', { serviceOrderData });
	};

	// ACCOUNT HOLDER TAB - START //

	onSubmitAuthCode = (number) => {
		let data = {
			phoneNumber: number,
			requester: 'alex'
		};
		// if there is no error - show field for verification
		this.props.sendAuthCode(data).then((response) => {
			// To Do - need to catch exeption - error
			this.setState({
				showVerificationMobileNumber: true,
				isAuthCodeValid: null,
				authCode: ''
			});
		});
	};

	onChangeAuthCodeHandler = (e) => {
		this.setState({
			authCode: e.target.value
		});
	};

	onSubmitVerifyAuthCode = (number, setFieldValue) => {
		let data = {
			authcode: this.state.authCode,
			phoneNumber: number,
			requester: 'alex'
		};
		this.props.verifyAuthCode(data).then((response) => {
			if (response.data.code === 200) {
				this.setState({ isAuthCodeValid: true });
				setFieldValue('accountHolder.mobileNumber.verified', true);
			}
			if (response.data.error) {
				this.setState({ isAuthCodeValid: false });
				setFieldValue('accountHolder.mobileNumber.verified', false);
			}
		});
	};

	// Reset auth code state if user start type in mobile phone number input field
	resetAuthCodeState = (setFieldValue) => {
		this.setState({
			showVerificationMobileNumber: false,
			isAuthCodeValid: null,
			authCode: ''
		});
		setFieldValue('accountHolder.mobileNumber.verified', false);
	};

	handleEvaluateStatusChange = (ssn, accountTermId, setFieldValue) => {
		this.setState(
			(prevState) => ({
				showEvaluationStatus: !prevState.showEvaluationStatus
			}),
			() =>
				this.props.ssnEvaluation(ssn, accountTermId).then((response) => {
					if (response.success) {
						this.setState({
							evaluationData: response.accountTier
						});
						setFieldValue('accountTermsInfo.accountTier', response.accountTier.id);
					}
				})
		);
	};

	resetEvaluateStatus = (setFieldValue) => {
		if (this.state.showEvaluationStatus === true) {
			this.setState({
				showEvaluationStatus: false,
				evaluationData: {}
			});
			setFieldValue('accountTermsInfo.accountTier', '');
		}
	};

	// ACCOUNT HOLDER TAB - ENDS //

	// BILLING DETAILS TAB - START //

	getDiscounts = (accountTypeId) => {
		this.props.getAccountDiscounts(accountTypeId, this.props.locationId);
	};

	getRecurringCredits = () => {
		this.props.getAccountRecurringCredits();
	};

	getTaxCodes = () => {
		this.props.getAccountTaxCodes();
	};

	// BILLING DETAILS TAB - ENDS //

	handleExistingAccountChange = (value) => {
		this.setState({
			selectedExistingAccount: value
		});
	};

	existingAccountValidation = (props) => {
		if (props.errors.accountHolder && !props.errors.accountHolder.firstName && !props.errors.accountHolder.lastName) {
			this.asyncExistingAccountValidation(props.values.accountHolder);
		}
	};

	billingAddressValidation = (props) => {
		if (
			isEmpty(props.errors) ||
			isEmpty(props.errors.billingDetails) ||
			isEmpty(props.errors.billingDetails.billingAddress)
		) {
			this.asyncBillingAddress(props.values.billingDetails.billingAddress, props.setFieldError);
		}
	};

	mailingAddressValidation = (props) => {
		if (
			isEmpty(props.errors) ||
			isEmpty(props.errors.accountHolder) ||
			isEmpty(props.errors.accountHolder.mailingAddress)
		) {
			this.asyncMailingAddress(props.values.accountHolder.mailingAddress, props.setFieldError);
		}
	};

	asyncExistingAccountValidation = debounce((accountHolderInfo) => {
		this.props.existingAccountValidation(accountHolderInfo).then((response) => {
			if (response.data && response.data.length > 0) {
				// Add default selected value
				let suggestedAccounts = response.data;
				let defaultSuggestedExistingAccount = suggestedAccounts[0];
				defaultSuggestedExistingAccount['value'] = suggestedAccounts[0].id;
				defaultSuggestedExistingAccount['label'] = `${suggestedAccounts[0].name} [${suggestedAccounts[0]
					.number}][${suggestedAccounts[0].status}] @ ${suggestedAccounts[0].billingAddress
					? suggestedAccounts[0].billingAddress
					: ''}`;

				this.setState({
					showSuggestedExistingAccounts: true,
					suggestedExistingAccounts: suggestedAccounts,
					selectedExistingAccount: defaultSuggestedExistingAccount
				});
			} else {
				this.setState({
					showSuggestedExistingAccounts: false,
					suggestedExistingAccounts: []
				});
			}
		});
	}, 2000);

	asyncMailingAddress = debounce((mailingAddress, setFieldError) => {
		this.props.mailingAddressValidation(mailingAddress).then((response) => {
			if (response.data.status === 'OK') {
				this.setState({
					suggestedMailingAddress: response.data,
					suggestedMailingAddressError: '',
					showSuggestedMailingAddress: true
				});
			} else if (response.data.status === 'ERROR') {
				// setFieldError('accountHolder.mailingAddress.address1', 'Invalid address')
				this.setState({
					suggestedMailingAddressError: response.data.errorDescription,
					showSuggestedMailingAddress: true
				});
			}
		});
	}, 1500);

	asyncBillingAddress = debounce((billingAddress, setFieldError) => {
		this.props.billingAddressValidation(billingAddress).then((response) => {
			if (response.data.status === 'OK') {
				this.setState({
					suggestedBillingAddress: response.data,
					suggestedBillingAddressError: '',
					showSuggestedBillingAddress: true
				});
			} else if (response.data.status === 'ERROR') {
				// setFieldError('accountHolder.mailingAddress.address1', 'Invalid address')
				this.setState({
					suggestedBillingAddressError: response.data.errorDescription,
					showSuggestedBillingAddress: true
				});
			}
		});
	}, 1500);

	useSuggestedMailingAddress = (setFieldValue) => {
		Object.keys(this.state.suggestedMailingAddress).forEach((element) => {
			if (!isEmpty(this.state.suggestedMailingAddress[element])) {
				setFieldValue(`accountHolder.mailingAddress.${element}`, `${this.state.suggestedMailingAddress[element]}`);
			}
		});

		this.setState({
			showSuggestedMailingAddress: false
		});
	};

	useSuggestedBillingAddress = (setFieldValue) => {
		setFieldValue('billingDetails.billingAddress', {});
		Object.keys(this.state.suggestedBillingAddress).forEach((element) => {
			setFieldValue(`billingDetails.billingAddress.${element}`, `${this.state.suggestedBillingAddress[element]}`);
		});

		this.setState({
			showSuggestedBillingAddress: false
		});
	};

	closeSuggestion = (suggestionType) => {
		this.setState({ [suggestionType]: false });
	};

	showBillingAddress = (e, setFieldValue, values, setTouched) => {
		if (e.target.checked === true) {
			setFieldValue('billingDetails.showBillingAddress', true);
			setFieldValue('billingDetails.billingAddress', addressValues);
		} else {
			setFieldValue('billingDetails.showBillingAddress', false);
			delete values.billingDetails.billingAddress;
			// setTouched({});
		}
	};

	handleAccountTermDetails = (value, manualTierSelector, setFieldValue, setFieldTouched) => {
		this.setState({
			showEvaluationStatus: false,
			evaluationData: {}
		});
		this.props.getAccountTermDetails(value).then((response) => {
			if (response.manualTierSelector === true) {
				setFieldValue('accountTermsInfo.manualTierSelector', true);
			} else if (response.manualTierSelector === false) {
				setFieldValue('accountTermsInfo.manualTierSelector', false);
			} else {
				setFieldValue('accountTermsInfo.manualTierSelector', 'none');
			}
		});
		setFieldValue('accountTermsInfo.accountTerm', value);
		setFieldValue('accountTermsInfo.accountTier', '');

		// setFieldTouched('accountTermsInfo.accountTier', false, false) - tova ne beshe vklu4eno
	};

	accountTypeChangeHandler = (fieldName, value, setFieldValue, setTouched, resetForm, setValues) => {
		resetForm(this.state.initialValues);
		setTouched({});

		setFieldValue(fieldName, value);

		// temporary for - input error problem
		this.setState({
			accountHolderEnableFields: {},
			showSuggestedMailingAddress: false,
			showSuggestedExistingAccounts: false
		});

		this.props.setAccountType(value).then((response) => {
			// this.props.getAccountTypeDetails(value);
			// take response from selected dropdown for accounType and transformed to required and enabled fields
			const fields = response.fields;
			// take enabled fields for accountHolder
			// let enabledFields = getEnabledFields(response);
			let enableFieldsState = {};
			// add them to form
			fields.forEach((element) => {
				// mobileNumber and HomeNumber are objects, this is the reason for if-else
				if (element.name === 'mobileNumber' || element.name === 'homeNumber' || element.name === 'businessNumber') {
					setFieldValue(`accountHolder.${element.name}`, {
						number: '',
						verified: false
					});
				} else {
					setFieldValue(`accountHolder.${element.name}`, '');
				}

				enableFieldsState[element.name] = true;
			});
			// take required fields for accountholder

			let requiredFields = fields.filter((x) => x.required === true).reduce((acc, element) => {
				acc[element.name] = element.name;
				return acc;
			}, {});

			let optionalFields = fields.filter((x) => x.required === false).reduce((acc, element) => {
				acc[element.name] = true;
				return acc;
			}, {});

			// enable - disable bill cycle in billing details

			if (response.enableServiceBill === true) {
				let billCycles = response.allowedBillCycles.split(',');
				let defaultBillCycle = Number(
					billCycles
						.find((x) => {
							return x.includes('*');
						})
						.replace('*', '')
				);

				setFieldValue('billingDetails.showBillingCycle', true);
				setFieldValue('accountTermsInfo.billCycle', defaultBillCycle);
			} else {
				let billCycles = response.allowedBillCycles.split(',');
				let defaultBillCycle = Number(
					billCycles
						.find((x) => {
							return x.includes('*');
						})
						.replace('*', '')
				);

				setFieldValue('billingDetails.showBillingCycle', false);
				setFieldValue('accountTermsInfo.billCycle', defaultBillCycle);
			}

			this.setState({
				accountHolderRequiredFields: requiredFields,
				accountHolderEnableFields: enableFieldsState,
				accountHolderOptionalFields: optionalFields
			});
		});
	};

	goBack = () => {
		if (this.props.history.length === 2) {
			this.props.history.push('/');
		} else {
			this.props.history.goBack();
		}
	};

	prevStep = () => {
		this.setState((prevState) => ({
			activeTab: prevState.activeTab - 1
		}));
	};

	nextStep = (values, setting, status) => {
		const { activeTab, accountHolderEnableFields, accountHolderOptionalFields } = this.state;

		if (activeTab === 2) {
			setting.setSubmitting(true);

			// potensial values mutation
			if (values.billingDetails.showBillingAddress === false) {
				values.billingDetails.billingAddress = values.accountHolder.mailingAddress;
			}

			// let removeEmptySecurities = values.accountHolder.securities.filter((x) => {
			// 	return x.answer !== '' && x.questionId !== '';
			// });
			// values.accountHolder.securities = removeEmptySecurities;

			this.props.createNewAccount(values).then((response) => {
				if (response.status === 201 || response.status === 200) {
					this.setState((prevState) => ({
						activeTab: prevState.activeTab + 1
					}));
				} else {
					if (response.response.status === 409 && response.response.data && response.response.data.error.code === 'C00231') {
						this.setState({enableMemberNumberGeneration: true})
					}
					const errorMessage = this.getErrorMessage(response, 'Could not create account');
					toastr.error(errorMessage, { timeOut: 1000, position: 'top-center' });
				}
				setting.setSubmitting(false);
			})
			.catch((err) => {
				setting.setSubmitting(false);
			});
		}
		else {

			// If we have checked personal and business emails for errors
			if (this.refs.AccountForm.state.status !== undefined) {

				// And we have no stored errors
				if (isEmpty(this.refs.AccountForm.state.status)) {

					// Go to next step
					this.setState((prevState) => ({
						activeTab: prevState.activeTab + 1
					}));
				}
			}

			// Or...
			else if (
				// both personal & business emails are enabled & optional
				(accountHolderEnableFields.personalEmail === true &&
				accountHolderOptionalFields.personalEmail === true &&
				accountHolderEnableFields.businessEmail === true &&
				accountHolderOptionalFields.businessEmail === true)
				||
				// personal email enabled & optional, business email disabled
				(accountHolderEnableFields.personalEmail === true &&
				accountHolderOptionalFields.personalEmail === true &&
				isEmpty(accountHolderEnableFields.businessEmail))
				||
				// personal email disabled, business email enabled & optional
				(isEmpty(accountHolderEnableFields.personalEmail) &&
				accountHolderEnableFields.businessEmail === true &&
				accountHolderOptionalFields.businessEmail === true)
				||
				// personal and business are disabled
				(isEmpty(accountHolderEnableFields.personalEmail) &&
				isEmpty(accountHolderEnableFields.businessEmail))
			) {

				// Go to next step
				this.setState((prevState) => ({
					activeTab: prevState.activeTab + 1
				}));
			}

			setting.setSubmitting(false);
		}
	};

	getErrorMessage = (response, defaultError) => {
		if (response.response && response.response.data && response.response.data.error && response.response.data.error.message) {
			return response.response.data.error.message;
		}

		if (response.message) {
			return response.message;
		}

		return defaultError || "Something went wrong";
	}

	render() {
		const {
			activeTab,
			accountHolderRequiredFields,
			isServiceRequestStarted
		} = this.state;

		return (
			<div className="cmv-wizard">
				<div className="wizard-container wizard-container-md">
					<div className="container">
						<NavTabs activeTab={activeTab} changeTabHandler={this.changeTabHandler} />
						<Formik
							ref={'AccountForm'}
							initialValues={this.state.initialValues}
							validationSchema={validationSchema(
								accountHolderRequiredFields,
								activeTab,
							)}
							onSubmit={this.nextStep}
							render={(props) => (
								<Form onSubmit={props.handleSubmit} className="cmv-form" autoComplete="off">
									<div className="tab-content wizard-content">{this.renderTabContent(props)}</div>
									<WizardButtons
										activeTab={activeTab}
										isServiceRequestStarted={isServiceRequestStarted}
										setFieldValue={props.setFieldValue}
										isSubmitting={props.isSubmitting}
										prevStep={this.prevStep}
										goBack={this.goBack}
									/>
								</Form>
							)}
						/>
					</div>
				</div>
			</div>
		);
	}
}
const setTypeAccountLoader = createLoadingSelector([ 'SET_ACCOUNT_TYPE' ]);
const setMailingAddressLoader = createLoadingSelector([ 'MAILING_ADDRESS_VALIDATION' ]);
const setBillingAddressLoader = createLoadingSelector([ 'BILLING_ADDRESS_VALIDATION' ]);
const setExistingAccountsLoader = createLoadingSelector([ 'EXISTING_ACCOUNT_VALIDATION' ]);
const setAuthCodeLoader = createLoadingSelector([ 'SEND_AUTH_CODE' ]);
const setAuthCodeVerify = createLoadingSelector([ 'VERIFY_AUTH_CODE' ]);
const setEvaluationLoader = createLoadingSelector([ 'SSN_EVALUATE' ]);

const mapStateToProps = (state) => {
	const newAccountInfo = getCreateAccount(state).newAccountInfo,
		accountTypes = getCreateAccount(state).accountTypes,
		securityQuestions = getCreateAccount(state).securityQuestions,
		countryStates = getCreateAccount(state).countryStates,
		accountTerms = getCreateAccount(state).accountTerms,
		billCycles = getCreateAccount(state).billCycles,
		accountTiers = getCreateAccount(state).accountTiers,
		defaultAccountId = getCreateAccount(state).defaultAccountId,
		discounts = getCreateAccount(state).discounts,
		recurringCredits = getCreateAccount(state).recurringCredits,
		taxCodesData = getCreateAccount(state).taxCodes,
		locationId = getUser(state).currentLocation.id,
		typeAccountLoader = setTypeAccountLoader(state),
		mailingAddressLoader = setMailingAddressLoader(state),
		billingAddressLoader = setBillingAddressLoader(state),
		existingAccountsLoader = setExistingAccountsLoader(state),
		authCodeLoader = setAuthCodeLoader(state),
		authCodeVerifyLoader = setAuthCodeVerify(state),
		evaluationLoader = setEvaluationLoader(state);

	return {
		newAccountInfo,
		accountTypes,
		securityQuestions,
		countryStates,
		accountTerms,
		billCycles,
		accountTiers,
		defaultAccountId,
		locationId,
		typeAccountLoader,
		mailingAddressLoader,
		billingAddressLoader,
		existingAccountsLoader,
		authCodeLoader,
		authCodeVerifyLoader,
		discounts,
		recurringCredits,
		evaluationLoader,
		taxCodesData
	};
};

const mapDispatchToProps = {
	getAccountTypes,
	getSecurityQuestions,
	getCountryStates,
	getAccountTypeDetails,
	getAccountTermDetails,
	createNewAccount,
	clearCreateAccount,
	setAccountType,
	mailingAddressValidation,
	billingAddressValidation,
	existingAccountValidation,
	showModal,
	sendAuthCode,
	verifyAuthCode,
	getAccountDiscounts,
	getAccountRecurringCredits,
	getAccountTaxCodes,
	ssnEvaluation,
	getAccountContactMemberNumber
};

export default connect(mapStateToProps, mapDispatchToProps)(createAccount);
