import React from 'react';
import Select from 'react-select';
import { groupStyles } from '../../utils/SelectStyles';

const option = [
	{ label: 'Serviceline', value: 'SERVICELINE', placeholder: 'Search account by Serviceline' },
	{ label: 'Account Number', value: 'ACCOUNT NUMBER', placeholder: 'Search account by Account Number' },
	{ label: 'Name', value: 'NAME', placeholder: 'Search account by Name' },
	{ label: 'Address', value: 'ADDRESS', placeholder: 'Search account by Address' },
	{ label: 'Company', value: 'COMPANY', placeholder: 'Search account by Company' },
	{ label: 'Contact number', value: 'CONTACT NUMBER', placeholder: 'Search account by Contact Number' },
	{ label: 'Email', value: 'EMAIL', placeholder: 'Search account by Email' },
	// { label: 'Username', value: 'USERNAME', placeholder: 'Search account by Company' },
	// { label: 'Date', value: 'CONTACTNUMBER', placeholder: 'Search account by Contact Number' }
];

const SearchBar = ({ searchAccounts, changeSearchType, changeTerm, searchType, term, placeholder }) => {
	return (
		<div className="cmv-container-cam-client-search">
			<div className="container">
				<div className="row">
					<div className="col-md-3">
						<Select
							value={searchType}
							onChange={changeSearchType}
							options={option}
							placeholder="Search"
							styles={groupStyles}
						/>
					</div>
					<div className="col-md-9">
						<div className="input-group">
							<input
								type="text"
								className="form-control"
								placeholder={placeholder}
								value={term}
								onChange={changeTerm}
							/>
							<div className="input-group-append">
								<button
									onClick={searchAccounts}
									className="btn btn-primary btn-show-cam-search-results"
									type="button"
									disabled={term === ''}
								>
									<i className="fas fa-search" />
									<span className="d-none d-md-inline-block">&nbsp;Search</span>
								</button>
							</div>
							{/* <div className="input-group-append">
								<div className="btn-group">
									<button
										className="btn btn-outline-secondary btn-show-cam-search-history"
										type="button"
									>
										<i className="fas fa-history" />
										<span className="d-none d-md-inline-block">&nbsp;History</span>
									</button>
									<button className="btn btn-outline-secondary" type="button">
										<i className="fas fa-plus" />
										<span className="d-none d-md-inline-block">&nbsp;New</span>
									</button>
								</div>
							</div> */}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SearchBar;
