import axiosInstance from '../utils/axios-instance';
import isEmpty, {actionCreator, createRequestTypes, getErrorMessage} from '../utils/helpers';

export const PAYMENT_PROVIDER_FIND_CURRENT_PROVIDER_ID = createRequestTypes('@paymentProvider/FIND_CURRENT_PROVIDER_ID');
export const PAYMENT_PROVIDER_FIND_ALL_IDS = createRequestTypes('@paymentProvider/FIND_ALL_IDS');
export const PAYMENT_PROVIDER_FIND_PROVIDER = createRequestTypes('@paymentProvider/FIND_PROVIDER');
export const PAYMENT_PROVIDER_PATCH_PROVIDER = createRequestTypes('@paymentProvider/PATCH_PROVIDER');
export const PAYMENT_PROVIDER_FIND_ALL = createRequestTypes('@paymentProvider/FIND_ALL');

export const getCurrentPaymentProviderId = () => async (dispatch) => {
	dispatch(actionCreator.request(PAYMENT_PROVIDER_FIND_CURRENT_PROVIDER_ID));
	try {
		const response = await axiosInstance.get(`/payment-provider/current/id`);

		dispatch(actionCreator.success(PAYMENT_PROVIDER_FIND_CURRENT_PROVIDER_ID, response.data.id));

		return response;
	} catch (err) {
		dispatch(actionCreator.failure(PAYMENT_PROVIDER_FIND_CURRENT_PROVIDER_ID, getErrorMessage(err)));

		throw err;
	}
}

export const setCurrentProviderById = (providerId) => async (dispatch) => {
	dispatch(actionCreator.request(PAYMENT_PROVIDER_FIND_CURRENT_PROVIDER_ID));
	try {
		const response = await axiosInstance.put(`/payment-provider/current/${providerId}`);

		dispatch(actionCreator.success(PAYMENT_PROVIDER_FIND_CURRENT_PROVIDER_ID, providerId));

		return response;
	} catch (err) {
		dispatch(actionCreator.failure(PAYMENT_PROVIDER_FIND_CURRENT_PROVIDER_ID, getErrorMessage(err)));

		throw err;
	}
}

export const clearCurrentPaymentProviderId = () => (dispatch) => {
	dispatch(actionCreator.empty(PAYMENT_PROVIDER_FIND_CURRENT_PROVIDER_ID));
}

export const findProviderIds = () => async (dispatch) => {
	dispatch(actionCreator.request(PAYMENT_PROVIDER_FIND_ALL_IDS));
	try {
		const response = await axiosInstance.get(`/payment-providers/id`);

		dispatch(actionCreator.success(PAYMENT_PROVIDER_FIND_ALL_IDS, response.data.ids));

		return response;
	} catch (err) {
		dispatch(actionCreator.failure(PAYMENT_PROVIDER_FIND_ALL_IDS, getErrorMessage(err)));

		throw err;
	}
}

export const clearProviderIds = () => (dispatch) => {
	dispatch(actionCreator.empty(PAYMENT_PROVIDER_FIND_ALL_IDS));
}

export const getPaymentProviderById = (providerId) => async (dispatch) => {
	dispatch(actionCreator.request(PAYMENT_PROVIDER_FIND_PROVIDER));
	try {
		const response = await axiosInstance.get(`/payment-provider/${providerId}`);

		dispatch(actionCreator.success(PAYMENT_PROVIDER_FIND_PROVIDER, response.data));

		return response;
	} catch (err) {
		dispatch(actionCreator.failure(PAYMENT_PROVIDER_FIND_PROVIDER, getErrorMessage(err)));

		throw err;
	}
}

export const clearPaymentProviderById = (providerId) => (dispatch) => {
	dispatch(actionCreator.empty(PAYMENT_PROVIDER_FIND_PROVIDER), providerId);
}

export const clearPaymentProviders = () => (dispatch) => {
	dispatch(actionCreator.empty(PAYMENT_PROVIDER_FIND_ALL));
}

export const patchPaymentProvider = (providerId, providerPatch) => async (dispatch) => {
	dispatch(actionCreator.request(PAYMENT_PROVIDER_PATCH_PROVIDER));
	try {
		const response = await axiosInstance.patch(`/payment-provider/${providerId}`, providerPatch);

		dispatch(actionCreator.success(PAYMENT_PROVIDER_PATCH_PROVIDER, response.data));

		return response;
	} catch (err) {
		dispatch(actionCreator.failure(PAYMENT_PROVIDER_PATCH_PROVIDER, getErrorMessage(err)));

		throw err;
	}
}

export const getCurrentPaymentProviderSupportsPaymentMethodStorage = () => async (dispatch) => {
	dispatch(actionCreator.request(PAYMENT_PROVIDER_FIND_CURRENT_PROVIDER_ID));
	try {
		const response = await axiosInstance.get(`/payment-provider/current/supports-payment-method-storage`);
		return response;
	} catch (err) {
		dispatch(actionCreator.failure(PAYMENT_PROVIDER_FIND_CURRENT_PROVIDER_ID));

		throw err;
	}
};

