import React, {Component, Fragment} from 'react';
import {Route, Switch, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import ReactGA from 'react-ga';
import Login from './containers/Login/Login';
import MainLayout from './containers/Layout/MainLayout';
import ResetPassword from './containers/ResetPassword/ResetPassword';
import ForgotenPassword from './containers/ResetPassword/ForgotenPassword';
import VerifyEmail from './containers/Verifications/VerifyEmail';
import CacheBuster from "./CacheBuster";
import SuccessPayment from "./containers/Static/SuccessPayment";
import FailPayment from "./containers/Static/FailPayment";

class App extends Component {
    componentDidMount() {
        ReactGA.pageview(window.location.pathname);
    }

    render() {
        return (
            <CacheBuster>
                {({loading, isLatestVersion, refreshCacheAndReload}) => {
                    if (loading) return null;
                    if (!loading && !isLatestVersion) {
                        // You can decide how and when you want to force reload
                        refreshCacheAndReload();
                    }

                    return (
                        <Fragment>
                            <Switch>
                                <Route path="/login" render={(props) => <Login {...props} />}/>
                                <Route path="/resetpassword" render={(props) => <ResetPassword {...props} />}/>
                                <Route path="/forgotenpassword" render={(props) => <ForgotenPassword {...props} />}/>
                                <Route path="/verifyemail" render={(props) => <VerifyEmail {...props} />}/>
                                <Route path="/success-payment" render={(props) => <SuccessPayment {...props} />}/>
                                <Route path="/fail-payment" render={(props) => <FailPayment {...props} />}/>
                                <Route path="/" render={(props) => <MainLayout {...props} />}/>
                            </Switch>
                        </Fragment>
                    );
                }}
            </CacheBuster>
        );
    }
}

const mapDispatchToProps = {};

export default withRouter(connect(null, mapDispatchToProps)(App));
