import React, { Component } from 'react';

class Feature extends Component {
	state = {
		isDescriptionToggled: false
	};

	toggleDescription = (e) => {
		e.stopPropagation();
		this.setState((prevState) => ({
			isDescriptionToggled: !prevState.isDescriptionToggled
		}));
	};

	render() {
		const { isDescriptionToggled } = this.state;
		const { feature } = this.props;

		return (
			<div className="package-option package-option-mandatory">
				<span className="po-title">{feature.description}</span>
				<span className="package-toolbar">
					<span className="po-price">
						${feature.featureType === 'ACTIVATION' ? (
							feature.oneTimePrice.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
						) : (
							feature.recurringMonthlyPrice.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') + '/mo'
						)}
					</span>
					<button onClick={this.toggleDescription} type="button" className="btn btn-xs btn-desc-toggler">
						<i className="fas fa-info-circle" />
					</button>
				</span>
				<div
					className={isDescriptionToggled ? 'po-description collapse show' : 'po-description collapse'}
					id="pl001-ifeat002-op001-desc"
				>
					{feature.note}
				</div>
			</div>
		);
	}
}

export default Feature;
