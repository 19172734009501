import axiosInstance from '../../utils/axios-instance';
import { actionCreator, createRequestTypes } from '../../utils/helpers';

export const UPDATE_ACCOUNT_INFORMATION = createRequestTypes('UPDATE_ACCOUNT_INFORMATION');
export const GET_ACCOUNT_INFORMATION = createRequestTypes('GET_ACCOUNT_INFORMATION');

export const updateAccountInformation = (accountInformation) => async (dispatch) => {
	dispatch(actionCreator.request(UPDATE_ACCOUNT_INFORMATION));
	try {
		const response = await axiosInstance.patch(`/admin/account-information`, accountInformation);
		dispatch(actionCreator.success(UPDATE_ACCOUNT_INFORMATION, response.data));
		return response;
	} catch (err) {
		let mockError = {
			message: 'Something goes wrong'
		}
		dispatch(actionCreator.failure(UPDATE_ACCOUNT_INFORMATION, mockError));
		return err.response;
	}
};

export const getAccountInformation = (accountId) => async (dispatch) => {
	dispatch(actionCreator.request(GET_ACCOUNT_INFORMATION));
	try {
        const response = await axiosInstance.get(`/admin/account-information/${accountId}`);
        dispatch(actionCreator.success(GET_ACCOUNT_INFORMATION, response.data));
	} catch (err) {
		dispatch(actionCreator.failure(GET_ACCOUNT_INFORMATION));
	}
};