import React, { Component, Fragment } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, {Search} from 'react-bootstrap-table2-toolkit';
import moment from 'moment';
import { connect } from 'react-redux';
import { showModal } from '../../actions/modal.actions';
import Loader from '../../components/Loader';
import {isEmpty} from '../../utils/helpers';
import { getPartners, clearPartners } from '../../actions/admin.actions'
import { createLoadingSelector, getAdmin, getUser } from "../../selectors";

const {SearchBar} = Search;

class Partners extends Component {

    state = {
        resultsNumber: 20,
        searchTerm: ''
    };

    componentDidMount() {
        this.reloadPartners();
    }

    componentWillUnmount() {
        this.props.clearPartners();
    }

    reloadPartners = () => {
        this.props.getPartners(this.state.resultsNumber, this.state.searchTerm);
    }

    nameFormatter = (cell, row, rowIndex) => {
        return (
            <Fragment>
                {row.name ? (row.name) : null}
                <span className="td-subtitle">
                    {row.description ? (row.description) : null}
                </span>
            </Fragment>
        );
    }

    statusFormatter = (cell, row, rowIndex) => {
        return (
            <Fragment>

                <span className={'badge' + (row.active ? ' badge-green' : ' badge-secondary')}>
                    {row.active ? 'Active' : 'Inactive'}
                </span>

                {!isEmpty(row.locations) && (
                    <span className="td-subtitle">
                        <i className="fas fa-fw fa-map-marker"/>
                        {row.locations.map((location, index) => {
                            let txt = location.name;
                            if (index > 0) {
                                txt = ', ' + txt;
                            }
                            return txt;
                        })}
                    </span>
                )}

            </Fragment>
        );
    }

    actionsFormatter = (cell, row, rowIndex) => {
        const {canEdit, canDelete} = this.props;
        return (
            <Fragment>
                <div className="tr-actions">
                    {canEdit && (
                        <button
                            onClick={() => this.props.showModal('ADD_PARTNER_MODAL', {row, ...{type: 'edit', reloadPartners: this.reloadPartners}})}
                            className="btn"
                            type="button"
                        >
                            <i className="fas fa-edit"/>
                        </button>
                    )}
                    {canDelete && false && ( // TODO: remove false in order to enable delete
                        <button
                            onClick={() => this.props.showModal('DELETE_PARTNER_MODAL', {row, ...{type: 'delete', reloadPartners: this.reloadPartners}})}
                            className="btn"
                            type="button"
                        >
                            <i className="fas fa-trash"/>
                        </button>
                    )}
                </div>
            </Fragment>
        );
    }

    handleResultNumberChange = (e) => {
        let {value, name} = e.target;

        // getInventoryNumbers(id, 100, planInfo.serviceModel.id)
        // 	.then((inventory) => {
        // 		console.log(inventory);
        // 	})

        this.setState({
            resultsNumber: value
        }, () => {
            this.reloadPartners();
        });
    }

    handleSearchChange = (event) => {
        this.setState({searchTerm: event.target.value}, () => {
            if (this.searchChangeTimeout) {
                clearTimeout(this.searchChangeTimeout);
            }

            this.searchChangeTimeout = setTimeout(() => {
                this.reloadPartners();
            }, 300);
        });
    }

    render() {
        const { partners, partnersLoader, canAdd, canEdit, canDelete } = this.props;
        const { resultsNumber, searchTerm } = this.state;

        const columns = [
            {
                text: 'Name',
                dataField: 'name',
                formatter: this.nameFormatter
            },
            {
                text: 'Status',
                dataField: 'status',
                formatter: this.statusFormatter
            }
        ];

        // Add ACTIONS column if user has rights
        if (canEdit || canDelete) {

            columns.push({
                text: 'Actions',
                dataField: 'actions',
                formatter: this.actionsFormatter,
                align: 'right',
                headerAlign: 'right',
            });
        }

        const defaultSorted = [
            {
                dataField: 'id',
                order: 'desc'
            }
        ];

        const rowStyle = (row, rowIndex) => {
            if (row) {
                let classes = null;

                if (row.note) {
                    classes = 'has-note';
                }

                if (rowIndex % 2 === 0) {
                    classes += ' odd';
                } else {
                    classes += ' even';
                }

                return classes;
            }
        };

        return (
            <Fragment>
                <div className="cmv-container-table">
                    <Fragment>
                        <ToolkitProvider
                            bootstrap4
                            keyField="id"
                            data={partners}
                            columns={columns}
                            defaultSorted={defaultSorted}
                            >

                            {(props) => (
                                <Fragment>
                                    <div className="row">
                                        <div className="col-sm-5">

                                            <div className="controls">
                                                <label>Show</label>
                                                <select
                                                    className="custom-select"
                                                    value={resultsNumber}
                                                    onChange={this.handleResultNumberChange}
                                                    disabled={partnersLoader}
                                                >
                                                    <option label="20" value="20"/>
                                                    <option label="30" value="30"/>
                                                    <option label="40" value="40"/>
                                                    <option label="50" value="50"/>
                                                </select>
                                                <label>entries</label>
                                            </div>

                                        </div>
                                        <div className="col-sm-7">

                                            <div className="search">

                                                <label htmlFor="partnerSearch" className="col-form-label">Search</label>

                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="partnerSearch"
                                                    value={searchTerm}
                                                    onChange={this.handleSearchChange}
                                                    disabled={partnersLoader}
                                                />

                                                {canAdd && (
                                                    <button
                                                        className="btn btn-primary"
                                                        onClick={() => this.props.showModal('ADD_PARTNER_MODAL', {
                                                            type: 'create',
                                                            reloadPartners: this.reloadPartners
                                                        })}
                                                        disabled={partnersLoader}
                                                    >
                                                        <i className="fas fa-plus"/> New
                                                    </button>
                                                )}

                                            </div>

                                        </div>
                                    </div>
                                    <div className="row">
                                        {partnersLoader ? (
                                            <div className="col">
                                                <Loader/>
                                            </div>
                                        ) : (
                                            <BootstrapTable
                                                wrapperClasses="table-responsive react-table-layout"
                                                classes="table table-striped cmv-table"
                                                bordered={false}
                                                rowClasses={rowStyle}
                                                hover
                                                noDataIndication="No data found"
                                                {...props.baseProps}
                                            />
                                        )}
                                    </div>
                                </Fragment>
                            )}
                        </ToolkitProvider>
                    </Fragment>
                </div>
            </Fragment>
        );
    }
}

const loader = createLoadingSelector(['GET_PARTNERS']);

const mapStateToProps = (state) => {
    const partners = getAdmin(state).partners,
        partnersLoader = loader(state),
        userPermission = getUser(state).userPermission;

    const settingsPermissions =
        userPermission.find((x) => x.name === 'ADMN') &&
        userPermission.find((x) => x.name === 'ADMN').permissions;
    const canEdit = settingsPermissions && settingsPermissions.includes("ADMN_PARTNER_EDIT");
    const canDelete = settingsPermissions && settingsPermissions.includes("ADMN_PARTNER_DEL");
    const canAdd = settingsPermissions && settingsPermissions.includes("ADMN_PARTNER_ADD");

    // Add new actions to the IF statement enabling the "ACTIONS" table column for users with rights
    return {
        partners,
        partnersLoader,
        canEdit,
        canDelete,
        canAdd
    };
};

const mapDispatchToProps = {
    getPartners,
    showModal,
    clearPartners
};

export default connect(mapStateToProps, mapDispatchToProps)(Partners);
