import React, { Component } from 'react';
import Account from './Account';

export class AccountsList extends Component {
	render() {
		const { accounts, isFetched, history, searchError, accountAccessModal } = this.props;

		if (isFetched && accounts.length === 0) {
			return <div className={'container'}><p>No results found</p></div>;
		}

		if (searchError.length !== 0) {
			return <div className={'container'}><p>No results found</p></div>;
		}

		const accountsData = accounts.map((account) => (
			<Account account={account} key={account.id} history={history} accountAccessModal={accountAccessModal} />
		));

		return (
			<div className="cmv-container-dashboard-recent">
				<div className="cmv-container-cam-search-results">{accounts.length > 0 && accountsData}</div>
			</div>
		);
	}
}

export default AccountsList;
