import {
	SET_TASKS_LIST
} from '../actions/tasksList.actions';

const initialState = {
    tasksList: []
};

export const tasksListReducer = (state = initialState, action) => {
	switch (action.type) {

		case SET_TASKS_LIST.SUCCESS:
			return {
				...state,
				tasksList: action.payload
			};
		case SET_TASKS_LIST.EMPTY:
			return {
				...state,
				tasksList: []
			};
		default:
			return state;
	}
};
