export const initialValues = {
	accountNumber: false,
	status: false,
	name: false,
	company: false,
	securityPhrase: false,
	balance: false,
	billingAddress: false,
	mailingAddress: false,
	emails: false,
	dateOfBirth: false,
	ssn: false,
	taxId: false,
	driverLicense: false,
	authCode: false,
	lastPayment: false,
	userName: false,
	serviceAddress: false,
	cardOnFile: false,
	passcode: false
};

export const transformNote = (note) => {
	switch (note) {
		case 'accountNumber':
			return 'Account#';
		case 'dateOfBirth':
			return 'Date of Birth';
		case 'billingAddress':
			return 'Billing Address';
		case 'mailingAddress':
			return 'Mailing Address';
		case 'securityPhrase':
			return 'Security Phrase';
		case 'serviceAddress':
			return 'Service Address';
		case 'taxId':
			return 'TaxID';
		case 'driverLicense':
			return 'Driver License';
		case 'ssn':
			return 'SSN';
		case 'authCode':
			return 'Auth Code'
		default:
			return note.charAt(0).toUpperCase() + note.slice(1);
	}
};

