import React, { Component } from 'react';
import { ClipLoader } from 'react-spinners';
import { css } from '@emotion/core';

const override = css`
	display: block;
	margin: 0 auto;
	border-color: red;
`;

class Loader extends Component {
	render() {
		return (
			<div style={{ textAlign: 'center', marginTop: '30px', marginBottom: '30px' }}>
				<ClipLoader css={override} sizeUnit={'px'} size={80} color={'#6ab3c1'} loading={true} />
			</div>
		);
	}
}

export default Loader;
