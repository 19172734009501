import React, { Component } from 'react';
import Feature from './Options/Feature';
class IncludedFeatures extends Component {
	render() {
		const { planFeature } = this.props;

		return (
			<div className="plan-section">
				<h5>Included Features</h5>
				<div className="package-wrapper" data-package-wrapper-id="pl001-ifeat001">
					{planFeature.map((feature, index) => <Feature feature={feature} key={index} />)}
				</div>
			</div>
		);
	}
}

export default IncludedFeatures;
