import React, { Component } from 'react';
import { getLogs } from '../../../../../../../actions/accountDetailsActions/ordersAndQuotes.actions';
import Log from '../../../../../../../components/UI/Log';
class Logs extends Component {
	state = {
		stateChain: [],
		time: 5000,
		isFetched: true
	};

	getLogsData = (serviceOrderId) => {
		getLogs(serviceOrderId).then((response) => {
			if (response.success) {
				this.setState({
					stateChain: response.stateChain,
					isFetched: false
				});
			}
		});
	};

	componentDidMount() {
		const { serviceOrderId } = this.props;

		this.getLogsData(serviceOrderId);
		// this.interval = setInterval(() => this.getLogsData(serviceOrderId), this.state.time);
	}

	componentWillUnmount() {
		// clearInterval(this.interval);
	}

	render() {
		const { stateChain } = this.state;
		if (stateChain.length === 0) {
			return null;
		}

		return (
			<tr className="tr-log">
				<td colSpan="100">
					<div className="cmv-container-log-viewer cmv-container-order-log-viewer">
						{stateChain.map((log) => <Log logInfo={log} />)}
					</div>
				</td>
			</tr>
		);
	}
}

export default Logs;
