import React, {Component} from 'react';

class FailPayment extends Component {
	render() {

		return (
			<div className="cmv-page cmv-page-login">
				<div className="page-wrapper">
					<div className="page-content">
						<main>
							<div className="col col-sm-6 col-md-5 col-xl-3">
								<div className="card-login">
									<div className="card-login-header text-center">
										<div className="brand-container" />
										<h3>There was a problem.</h3>
										<p>Close page and go back.</p>
										<div className="lds-dual-ring" />
									</div>
								</div>
							</div>
						</main>
					</div>
				</div>
			</div>
		);
	}
}

export default FailPayment;
