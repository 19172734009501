import {
    PATCH_SERVICE_ORDER,
    PLANS_AND_BUNDLES,
    PLANS_DETAILS,
    EMPTY_NEW_ORDER,
    SERVICE_REQUEST_START_ORDER,
    SERVICE_REQUEST_PENDING_TASKS_COUNT,
    MAP_LINK_LOCATION,
    PLAN_DETAILS
} from '../../actions/accountDetailsActions/editServiceRequest.actions';
import {merge} from "lodash";
import {isEmpty, transformFeature} from "../../utils/helpers";

let initialState = {
    newOrderInfo: {},
    planBundles: [],
    plans: [],
    plansDetails: [],
    isOrderStarted: null,
    tasksCount: 0,
    validatedAddress: null
};

export const editServiceRequest = (state = initialState, action) => {
    switch (action.type) {
        case PATCH_SERVICE_ORDER.SUCCESS:
            return {
                ...state,
                newOrderInfo: action.payload
            };
        case PLANS_AND_BUNDLES.SUCCESS:
            return {
                ...state,
                planBundles: action.payload.planBundles,
                plans: action.payload.plans
            };
        case PLAN_DETAILS.SUCCESS:
            return {
                ...state,
                plans: action.payload
            }
        case PLANS_DETAILS.SUCCESS:
            let findIndex = state.plans.findIndex((x) => x.id === action.payload.id);
            let servicelines = [];

            if (!isEmpty(action.payload.serviceOrderPlan.servicelines)) {

                for (let index = 0; index < action.payload.serviceOrderPlan.servicelines.length; index++) {
                    let currentServiceLine = {...action.payload.serviceOrderPlan.servicelines[index]};

                    // Process all features
                    let serviceLineFeatures = [];
                    const featuresCount = currentServiceLine.features ? currentServiceLine.features.length : 0;

                    for (let featureIndex = 0; featureIndex < featuresCount; featureIndex++) {

                        /* mainIncludedFeatures */
                        if (action.payload.mainIncludedFeatures) {
                            const feature = action.payload.mainIncludedFeatures.find(element => element.id === currentServiceLine.features[featureIndex].id);
                            if (feature) {serviceLineFeatures.push(feature); continue;}
                        }

                        /* mainOptionalFeatures */
                        if (action.payload.mainOptionalFeatures) {
                            const feature = action.payload.mainOptionalFeatures.find(element => element.id === currentServiceLine.features[featureIndex].id);
                            if (feature) {serviceLineFeatures.push(feature); continue;}
                        }

                        /* requiredOptionalFeatures */
                        if (action.payload.requiredOptionalFeatures) {
                            const feature = action.payload.requiredOptionalFeatures.find(element => element.id === currentServiceLine.features[featureIndex].id);
                            if (feature) {serviceLineFeatures.push(feature); continue;}
                        }

                        /* mainMandatoryFeatures */
                        if (action.payload.mainMandatoryFeatures) {
                            const feature = action.payload.mainMandatoryFeatures.find(element => element.id === currentServiceLine.features[featureIndex].id);
                            if (feature) {serviceLineFeatures.push(feature); continue;}
                        }

                        /* requiredIncludedFeatures */
                        if (action.payload.requiredIncludedFeatures) {
                            const feature = action.payload.requiredIncludedFeatures.find(element => element.id === currentServiceLine.features[featureIndex].id);
                            if (feature) {serviceLineFeatures.push(feature); continue;}
                        }

                        /* requiredMandatoryFeatures */
                        if (action.payload.requiredMandatoryFeatures) {
                            const feature = action.payload.requiredMandatoryFeatures.find(element => element.id === currentServiceLine.features[featureIndex].id);
                            if (feature) {serviceLineFeatures.push(feature); continue;}
                        }

                        /* additionalIncludedFeatures */
                        if (action.payload.additionalIncludedFeatures) {
                            const feature = action.payload.additionalIncludedFeatures.find(element => element.id === currentServiceLine.features[featureIndex].id);
                            if (feature) {serviceLineFeatures.push(feature); continue;}
                        }

                        /* additionalMandatoryFeatures */
                        if (action.payload.additionalMandatoryFeatures) {
                            const feature = action.payload.additionalMandatoryFeatures.find(element => element.id === currentServiceLine.features[featureIndex].id);
                            if (feature) {serviceLineFeatures.push(feature); continue;}
                        }

                        /* additionalOptionalFeatures */
                        if (action.payload.additionalOptionalFeatures) {
                            const feature = action.payload.additionalOptionalFeatures.find(element => element.id === currentServiceLine.features[featureIndex].id);
                            if (feature) {serviceLineFeatures.push(feature); continue;}
                        }



                        /* mainIncludedFeaturePackages */
                        if (action.payload.mainIncludedFeaturePackages) {
                            const feature = action.payload.mainIncludedFeaturePackages.find(element => element.featurePackageId === currentServiceLine.features[featureIndex].featurePackageId);
                            if (feature) {
                                const featurePackageOption = feature.featurePackageOptions.find(element => element.featurePackageOptionId === currentServiceLine.features[featureIndex].featurePackageOptionId);
                                if (featurePackageOption) {serviceLineFeatures.push(transformFeature(feature, featurePackageOption)); continue;}
                            }
                        }

                        /* mainMandatoryFeaturePackages */
                        if (action.payload.mainMandatoryFeaturePackages) {
                            const feature = action.payload.mainMandatoryFeaturePackages.find(element => element.featurePackageId === currentServiceLine.features[featureIndex].featurePackageId);
                            if (feature) {
                                const featurePackageOption = feature.featurePackageOptions.find(element => element.featurePackageOptionId === currentServiceLine.features[featureIndex].featurePackageOptionId);
                                if (featurePackageOption) {serviceLineFeatures.push(transformFeature(feature, featurePackageOption)); continue;}
                            }
                        }

                        /* mainOptionalFeaturePackages */
                        if (action.payload.mainOptionalFeaturePackages) {
                            const feature = action.payload.mainOptionalFeaturePackages.find(element => element.featurePackageId === currentServiceLine.features[featureIndex].featurePackageId);
                            if (feature) {
                                const featurePackageOption = feature.featurePackageOptions.find(element => element.featurePackageOptionId === currentServiceLine.features[featureIndex].featurePackageOptionId);
                                if (featurePackageOption) {serviceLineFeatures.push(transformFeature(feature, featurePackageOption)); continue;}
                            }
                        }

                        /* requiredIncludedFeaturePackages */
                        if (action.payload.requiredIncludedFeaturePackages) {
                            const feature = action.payload.requiredIncludedFeaturePackages.find(element => element.featurePackageId === currentServiceLine.features[featureIndex].featurePackageId);
                            if (feature) {
                                const featurePackageOption = feature.featurePackageOptions.find(element => element.featurePackageOptionId === currentServiceLine.features[featureIndex].featurePackageOptionId);
                                if (featurePackageOption) {serviceLineFeatures.push(transformFeature(feature, featurePackageOption)); continue;}
                            }
                        }

                        /* additionalIncludedFeaturePackages */
                        if (action.payload.additionalIncludedFeaturePackages) {
                            const feature = action.payload.additionalIncludedFeaturePackages.find(element => element.featurePackageId === currentServiceLine.features[featureIndex].featurePackageId);
                            if (feature) {
                                const featurePackageOption = feature.featurePackageOptions.find(element => element.featurePackageOptionId === currentServiceLine.features[featureIndex].featurePackageOptionId);
                                if (featurePackageOption) {serviceLineFeatures.push(transformFeature(feature, featurePackageOption)); continue;}
                            }
                        }

                        /* requiredMandatoryFeaturePackages */
                        if (action.payload.requiredMandatoryFeaturePackages) {
                            const feature = action.payload.requiredMandatoryFeaturePackages.find(element => element.featurePackageId === currentServiceLine.features[featureIndex].featurePackageId);
                            if (feature) {
                                const featurePackageOption = feature.featurePackageOptions.find(element => element.featurePackageOptionId === currentServiceLine.features[featureIndex].featurePackageOptionId);
                                if (featurePackageOption) {serviceLineFeatures.push(transformFeature(feature, featurePackageOption)); continue;}
                            }
                        }

                        /* requiredOptionalFeaturePackages */
                        if (action.payload.requiredOptionalFeaturePackages) {
                            const feature = action.payload.requiredOptionalFeaturePackages.find(element => element.featurePackageId === currentServiceLine.features[featureIndex].featurePackageId);
                            if (feature) {
                                const featurePackageOption = feature.featurePackageOptions.find(element => element.featurePackageOptionId === currentServiceLine.features[featureIndex].featurePackageOptionId);
                                if (featurePackageOption) {serviceLineFeatures.push(transformFeature(feature, featurePackageOption)); continue;}
                            }
                        }

                        /* additionalMandatoryFeaturePackages */
                        if (action.payload.additionalMandatoryFeaturePackages) {
                            const feature = action.payload.additionalMandatoryFeaturePackages.find(element => element.featurePackageId === currentServiceLine.features[featureIndex].featurePackageId);
                            if (feature) {
                                const featurePackageOption = feature.featurePackageOptions.find(element => element.featurePackageOptionId === currentServiceLine.features[featureIndex].featurePackageOptionId);
                                if (featurePackageOption) {serviceLineFeatures.push(transformFeature(feature, featurePackageOption)); continue;}
                            }
                        }

                        /* additionalOptionalFeaturePackages */
                        if (action.payload.additionalOptionalFeaturePackages) {
                            const feature = action.payload.additionalOptionalFeaturePackages.find(element => element.featurePackageId === currentServiceLine.features[featureIndex].featurePackageId);
                            if (feature) {
                                const featurePackageOption = feature.featurePackageOptions.find(element => element.featurePackageOptionId === currentServiceLine.features[featureIndex].featurePackageOptionId);
                                if (featurePackageOption) {serviceLineFeatures.push(transformFeature(feature, featurePackageOption)); continue;}
                            }
                        }

                    }

                    // Update serviceline features
                    currentServiceLine.features = serviceLineFeatures;

                    // Process all devices
                    const numberOfDevices = currentServiceLine.devices ? currentServiceLine.devices.length : 0;
                    for (let deviceIndex = 0; deviceIndex < numberOfDevices; deviceIndex++) {
                        if (!action.payload.equipment) {
                            continue;
                        }
                        const device = action.payload.equipment.find(element => element.deviceGroupId === currentServiceLine.devices[deviceIndex].deviceGroupId);

                        if (!device) {
                            continue;
                        }

                        const deviceProfile = device.deviceProfiles.find(element => element.id === currentServiceLine.devices[deviceIndex].deviceProfileId);
                        if (!deviceProfile) {
                            continue;
                        }

                        const deviceItem = deviceProfile.possibleItems.find(element => element.id === currentServiceLine.devices[deviceIndex].itemId);
                        if (!deviceItem) {
                            continue;
                        }

                        currentServiceLine.devices[deviceIndex] = {
                            deviceGroupId: device.deviceGroupId,
                            deviceProfileId: deviceProfile.id,
                            itemId: deviceItem.id,
                            itemDescription: deviceItem.description,
                            itemPrice: deviceItem.itemProperties[0].listPrice,
                            itemListPrice: deviceItem.itemProperties[0].listPrice
                        };
                    }

                    servicelines.push(currentServiceLine);
                }
            }

            servicelines.map((line, index) => {
                servicelines[index] = merge({}, line, action.payload.serviceOrderPlan.servicelines[index]);
                if (servicelines[index].features) {
                    servicelines[index].features.map(feature => {
                        if (feature.featureVariabledPrice)
                            feature.recurringMonthlyPrice = feature.featureVariabledPrice;
                    })
                }
            });

            servicelines.sort((a, b) => {
                return parseInt(a.number) - parseInt(b.number);
            });

            servicelines.sort((a, b) => {
                if (b.serviceLineRelation === "Main") {
                    return 1;
                }
                else if (a.serviceLineRelation === "Main") {
                    return -1;
                }
                return 0;
            });

            state.plans[findIndex]['index'] = findIndex;
            state.plans[findIndex]['servicelines'] = servicelines;
            state.plans[findIndex]['requiredLines'] = action.payload.requiredLines;
            state.plans[findIndex]['additionalLines'] = action.payload.additionalLines;

            return {
                ...state,
                plansDetails: [...state.plansDetails, action.payload]
            };
        case PLANS_DETAILS.EMPTY:
            return {
                ...state,
                plansDetails: []
            };
        case SERVICE_REQUEST_START_ORDER.SUCCESS:
            return {
                ...state,
                isOrderStarted: action.payload
            };
        case SERVICE_REQUEST_START_ORDER.EMPTY:
            return {
                ...state,
                isOrderStarted: null
            };
        case SERVICE_REQUEST_PENDING_TASKS_COUNT.SUCCESS:
            return {
                ...state,
                tasksCount: action.payload
            };
        case 'EMPTY_NEW_ORDER':
            return {
                ...initialState
            };
        case MAP_LINK_LOCATION.SUCCESS:
            return {
                ...state,
                validatedAddress: action.payload.validatedAddress
            };
        case MAP_LINK_LOCATION.FAILURE:
            return {
                ...state,
                validatedAddress: action.payload
            };
        case MAP_LINK_LOCATION.EMPTY:
            return {
                ...state,
                validatedAddress: null
            }
        default:
            return state;
    }
};
