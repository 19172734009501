import React, { Component } from 'react';

class StackedBar extends Component {
	generateStatuses = (statuses) => {};
	render() {
		const { statuses } = this.props;
		return (
			<div className="stacked-bar-graph">
				{statuses.map((status, index) => {
					if (status.groupState === 'PROCESSED' ) {
						return <span className="bg-success" key={index}>{status.lastGroupStatus}</span>;
					}
					else if (status.groupState === 'PROCESSING' ||  status.groupState === 'CANCELLED') {
						if (status.currentStatus.processingStatus === 'ERROR' || status.currentStatus.processingStatus === 'CANCEL') {
							return <span className="bg-danger bg-danger-with-pulse" key={index}>{status.currentStatus.name}...</span>;
						} else {
							return <span className="bg-success bg-success-with-pulse" key={index}>{status.currentStatus.name}...</span>;
						}
					}
					else if (status.groupState === 'TO_BE_PROCESSED') {
						return <span className="bg-gray-300" key={index}>{status.groupName}</span>;
					}

					return null;
				})}
			</div>
		);
	}
}

export default StackedBar;
