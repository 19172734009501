import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory, {
	PaginationListStandalone,
	SizePerPageDropdownStandalone,
	PaginationProvider,
	PaginationTotalStandalone
} from 'react-bootstrap-table2-paginator';
import moment from 'moment';
import { connect } from 'react-redux';
import {
	getPayments,
	emptyPayments
} from '../../../../../../actions/accountDetailsActions/billing.actions';
import { showModal } from '../../../../../../actions/modal.actions';
import {getAccountDetails, createLoadingSelector, getUserPermission} from '../../../../../../selectors';
import Loader from '../../../../../../components/Loader';
import { isEmpty } from '../../../../../../utils/helpers';
import PaymentDetails from './PaymentDetails';
import axiosInstance from "../../../../../../utils/axios-instance";
import {toastr} from "react-redux-toastr";

class Payments extends Component {

	state = { expanded: [] }


	handleViewPaymentReceipt = (paymentReceiptId) => {
		this.setState({loadingPaymentReceipt: paymentReceiptId});

		axiosInstance
			.get(`/account/payment-receipt/${paymentReceiptId}/pdf`, {
				// method: 'GET',
				responseType: 'blob' //Force to receive data in a Blob Format
				// responseType: 'arraybuffer'
			})
			.then((response) => {
				this.setState({loadingPaymentReceipt: false});

				// Create a Blob from the PDF Stream
				const file = new Blob([ response.data ], { type: 'application/pdf' });

				if (window.navigator.msSaveOrOpenBlob) {
					window.navigator.msSaveOrOpenBlob(file, 'my.pdf');
				} else if (navigator.userAgent.match('FxiOS')) {
					alert('Cannot display on FF iOS');
				} else if (navigator.userAgent.match('CriOS')) {
					let reader = new FileReader();
					reader.onloadend = function() {
						window.open(reader.result);
					};
					reader.readAsDataURL(file);
				} else if (navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPhone/i)) {
					let url = window.URL.createObjectURL(file);
					window.location.href = url;
				} else {
					// Build a URL from the file
					const fileURL = URL.createObjectURL(file);

					// Open the URL on new Window
					let newWindow = window.open(fileURL, '_blank');

					// Alert the user of blocked popup
					if (!newWindow || newWindow.closed || typeof newWindow.closed == 'undefined') {

						let browser = 'Your web browser';

						if (navigator.userAgent.toLowerCase().indexOf('chrome/') > -1) browser = 'Chrome';
						else if (navigator.userAgent.toLowerCase().indexOf('safari/') > -1) browser = 'Safari';
						else if (navigator.userAgent.toLowerCase().indexOf('firefox/') > -1) browser = 'Firefox';

						toastr.error(browser + ' blocked the PDF Invoice window from opening. Please allow pop ups and try again.', {timeOut: 0, position: 'top-center'});
					}
				}
			})
			.catch((error, response) => {
				this.setState({loadingPaymentReceipt: false});

				if (error.response && error.response.data) {
					error.response.data.text().then(response => {
						const resp = JSON.parse(response);

						if (resp.error.code === 'INV0001') {
							const errorMessage = resp.error.message;
							toastr.error(errorMessage, {timeOut: 0, position: 'top-center'});
						}
					});
				}
			});
	};

	handleBtnClick = (paymentId) => {
		
		if (!this.state.expanded.includes(paymentId)) {
			this.setState(() => ({
				expanded: [ ...this.state.expanded, paymentId ]
			}));
		} else {
			this.setState(() => ({
				expanded: this.state.expanded.filter((x) => x !== paymentId)
			}));
		}
	};

	handleOnExpand = (row, isExpand, rowIndex, e) => {
		if (isExpand) {
			this.setState(() => ({
				expanded: [ ...this.state.expanded, row.paymentId ]
			}));
		} else {
			this.setState(() => ({
				expanded: this.state.expanded.filter((x) => x !== row.paymentId)
			}));
		}
	};

	componentDidMount() {
		const { accountInfo } = this.props;
		this.props.getPayments(accountInfo.id);
	}

	componentWillUnmount() {
		this.props.emptyPayments();
	}

	typeFormater = (cell, row, rowIndex) => {
		// check if it's a reverse payment
		if (!row.reversible && !row.reversePaymentId) {
			return <i style={{color: '#f48fb1'}} className="fas fa-undo" title='Reverse Payment' />;
		}

		if (cell === 'CHECK') {
			return <i className="fas fa-money-check-alt" title='Check' />;
		} else if (cell === 'CASH') {
			return <i className="fas fa-money-bill" title='Cash' />;
		} else if (cell === 'CREDIT_CARD') {
			return <i className="fas fa-credit-card" title='Credit Card' />;
		} else if (cell === 'ACH') {
			return <i className="fas fa-money-check" title='ACH' />;
		} else if (cell === 'WIRE_TRANSFER') {
			return <i style={{color: '#8bc34a'}} className="fas fa-dollar-sign" title='Wire Transfer' />;
		} else if (cell === 'EXTERNAL') {
			return <i className="fas fa-external-link-square-alt" title='External' />;
		} else if (cell === 'ACCOUNT_CREDIT') {
			return <i style={{color: '#f48fb1'}} className="fas fa-piggy-bank" title='Account Credit' />;
		} else {
			return <i className="fas fa-question" title='Unknown' />;
		}

	}

	dateFormater = (cell, row, rowIndex) => {
		if (row) {
			return (
				<Fragment>
					{moment(cell).format('MM/DD/YYYY')} <span className="td-subtitle">{row.createdBy}</span>
				</Fragment>
			);
		}
	};

	amountFormater = (cell, row, rowIndex) => {
		if (row) {
			return <Fragment>${cell.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</Fragment>;
		}
	};

	referenceFormater = (cell, row, rowIndex) => {
		if (row) {
			// check if it's a reverse payment
			if (!row.reversible && !row.reversePaymentId) {
				return row.reversalReference;
			}

			const transactionIdRef = row.transactionId && <span className="td-subtitle">{row.transactionId} {row.authCode != null ? <span className="td-subtitle">{row.authCode}</span> : ""}</span>

			return <Fragment>{cell}{transactionIdRef}</Fragment>;
		}
	};

	appliedFormater = (cell, row, rowIndex) => {
		if (row) {
			return <Fragment>{moment(cell).format('MM/DD/YYYY')}</Fragment>;
		}
	};

	actionFormater = (cell, row, rowIndex, { loadingPaymentReceipt }) => {
		const { accountInfo, showModal, canReversePayment } = this.props;

		return (
			<div className="tr-actions">
				{
					canReversePayment && row.reversible &&
					<button className="btn"
						onClick={() => showModal('REVERSE_PAYMENT_MODAL', {
									accountId: accountInfo.id,
									payment: row})}>

						<i className="fas fa-undo" />
					</button>
					|| row.reversePaymentId &&
					<button className="btn" disabled={true}>
						<i className="fas fa-undo" />
					</button>
				}

				<button className="btn"
						onClick={() => this.handleViewPaymentReceipt(row.paymentReceiptId)}
						disabled={!row.paymentReceiptId || loadingPaymentReceipt}>

					{(row.paymentReceiptId && loadingPaymentReceipt === row.paymentReceiptId)
						? <i className="fas fa-fw fa-spin fa-spinner" />
						: <i className="fas fa-fw fa-receipt" />
					}
				</button>

				<button className="btn" onClick={() => this.handleBtnClick(row.paymentId)}>
					<i className="fas fa-eye" />
				</button>

			</div>
		);
	};

	statusFormater = (cell, row, rowIndex) => {
		if (row) {
			if (cell === 'PENDING') {
				return <span className="badge badge-warning">Pending</span>;
			} else if (cell === 'APPROVED') {
				return <span className="badge badge-success">Approved</span>;
			}
		}
	};

	noteFormater = (cell, row, rowIndex) => {
		if (row) {
			if (cell !== 'undefined') {
				return <div className="td-note">{cell}</div>;
			}
		}
	};

	customTotal = (from, to, size) => (
		<span className="react-bootstrap-table-pagination-total">
			Showing {from} to {to} of {size} Results
		</span>
	);

	render() {
		const { paymentsData, paymentsLoader } = this.props;
		const { loadingPaymentReceipt } = this.state;

		if (isEmpty(paymentsData) && paymentsLoader) {
			return <Loader />;
		}

		const { SearchBar } = Search;

		const options = {
			paginationSize: 4,
			custom: true,
			totalSize: this.props.paymentsData.length,
			pageStartIndex: 1,
			// alwaysShowAllBtns: true, // Always show next and previous button
			// withFirstAndLast: false, // Hide the going to First and Last page button
			// hideSizePerPage: true, // Hide the sizePerPage dropdown always
			hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
			firstPageText: 'First',
			prePageText: 'Previous',
			nextPageText: 'Next',
			lastPageText: 'Last',
			// nextPageTitle: 'First page',
			// prePageTitle: 'Pre page',
			// firstPageTitle: 'Next page',
			// lastPageTitle: 'Last page',
			showTotal: true,
			paginationTotalRenderer: this.customTotal,
			sizePerPageList: [
				{
					text: '10',
					value: 10
				},
				{
					text: '25',
					value: 25
				},
				{
					text: '50',
					value: 50
				},
				{
					text: '100',
					value: 100
				}
				// {
				// 	text: 'All',
				// 	value: this.props.paymentsData.length
				// }
			] // A numeric array is also available. the purpose of above example is custom the text
		};

		const columns = [
			{
				dataField: 'type',
				text: 'Type',
				sort: true,
				formatter: this.typeFormater,
				classes: 'v-align-m"'
			},
			{
				dataField: 'createdDate',
				text: 'Date',
				sort: true,
				formatter: this.dateFormater
			},

			{
				dataField: 'amount',
				text: 'Amount',
				sort: true,
				formatter: this.amountFormater,
				classes: 'h-align-r'
			},
			{
				dataField: 'reference',
				text: 'Reference',
				sort: true,
				formatter: this.referenceFormater
			},
			{
				dataField: 'date',
				text: 'applied',
				sort: true,
				formatter: this.appliedFormater
			},
			{
				dataField: 'actions',
				isDummyField: true,
				text: 'Actions',
				headerClasses: 'h-align-r',
				formatter: this.actionFormater,
				formatExtraData: {
					loadingPaymentReceipt
				},
			},
			{
				dataField: 'paymentId',
				hidden: true
			}
		];

		const defaultSorted = [
			{
				dataField: 'adjustmentDatetime',
				order: 'desc'
			}
		];

		const rowClasses = (row, rowIndex) => {
			let classes = null;
		  
			if (this.state.expanded.includes(row.paymentId)) {
			  classes = 'tr-expanded';
			}
		  
			return classes;
		  };

		const expandRow = {
			renderer: (row) => <PaymentDetails accountInfo={this.props.accountInfo} paymentId={row.paymentId} key={row.paymentId}/>,

			expandByColumnOnly: true,
			expanded: this.state.expanded,
			onExpand: this.handleOnExpand
		};

		return (
			<Fragment>
				<div className="cmv-container-table">
					<PaginationProvider pagination={paginationFactory(options)}>
						{({ paginationProps, paginationTableProps }) => (
							<Fragment>
								<ToolkitProvider
									bootstrap4
									keyField="paymentId"
									data={paymentsData}
									columns={columns}
									defaultSorted={defaultSorted}
							
									search
								>
									{(props) => (
										<Fragment>
											<div className="row">
												<div className="col-sm-12 col-md-6">
													<label>
														Show <SizePerPageDropdownStandalone {...paginationProps} />{' '}
														entries
													</label>
												</div>
												<div className="col-sm-12 col-md-6" style={{ textAlign: 'right' }}>
													<label>
														Search:{' '}
														<SearchBar
															className="form-control form-control-sm"
															{...props.searchProps}
														/>
													</label>
												</div>
											</div>
											<div className="row">
												<BootstrapTable
													wrapperClasses="table-responsive react-table-layout"
													classes="table table-striped cmv-table"
													bordered={false}
													noDataIndication="No data found"
													keyField="paymentId"
													expandRow={expandRow}
													rowClasses={rowClasses}
													{...paginationTableProps}
													{...props.baseProps}
												/>
											</div>
											<div className="row" style={{ marginTop: '10px' }}>
												<div className="col-sm-12 col-md-5">
													<PaginationTotalStandalone {...paginationProps} />
												</div>
												<div className="col-sm-12 col-md-7">
													<PaginationListStandalone {...paginationProps} />
												</div>
											</div>
										</Fragment>
									)}
								</ToolkitProvider>
							</Fragment>
						)}
					</PaginationProvider>
				</div>
			</Fragment>
		);
	}
}

Payments.propTypes = {
	accountInfo: PropTypes.object.isRequired
};

const loader = createLoadingSelector([ 'SET_PAYMENTS' ]);

const mapStateToProps = (state) => {
	const paymentsData = getAccountDetails(state).billing.payments,
		paymentsLoader = loader(state),
		canReversePayment = getUserPermission(state, 'CAM', 'CAM_REVERSE_PAYMENT');

	return {
		paymentsData,
		paymentsLoader,
		canReversePayment
	};
};

const mapDispatchToProps = {
	getPayments,
	showModal,
	emptyPayments,
};

export default connect(mapStateToProps, mapDispatchToProps)(Payments);
