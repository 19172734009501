import React, {Component, Fragment} from 'react';
import { connect } from 'react-redux';
import {Form, Formik} from "formik";
import StepActions from "../../../camvio-wizard/StepActions";

class ActionTypeNotSupported extends Component {

    createValuesBundle = (values) => {
        return {
        };
    }

    createStateBundle = (values) => {
        return {
            formValues: {
                ...values
            }
        };
    }

    handleNext = (values) => {
        const { next } = this.props;
        if (!next) {
            return;
        }

        next(this.createValuesBundle(values), this.createStateBundle(values));
    }

    handlePrevious = (values) => {
        const { previous } = this.props;
        if (!previous) {
            return;
        }

        previous(this.createStateBundle(values));
    }

    handleOnSubmit = (values, actions) => {
        this.handleNext(values);
    }

    renderContent = (propValues) => {
        return <h1>Provisioning action not supported</h1>;
    }

    render() {
        const { stepState = {}, previous, next } = this.props;

        return (
            <Formik
                onSubmit={this.handleOnSubmit}
                initialValues={stepState.formValues || {}}
                render={(formProps) => (
                    <Form onSubmit={formProps.handleSubmit} className="cmv-form" autoComplete="off">

                        {this.renderContent(formProps)}

                        <StepActions
                            previous={previous && (() => this.handlePrevious(formProps.values))}
                            next={next && formProps.submitForm} />
                    </Form>
                )}
            />
        );
    }
}

export default ActionTypeNotSupported;