import React, {Component} from 'react';
import {isEmpty} from '../../../../utils/helpers';
import {Link} from 'react-router-dom';

export class ReportModuleMenu extends Component {

	render() {

		const {activeMenu, changeActiveMenu, reportCategories} = this.props;

		if (isEmpty(reportCategories)) {
			return null;
		}

		return (
			<ul>
				<li className="header-menu">
					<span>Reports</span>
				</li>
				{reportCategories.map((category) => (
					<li
						onClick={() => changeActiveMenu(category.name)}
						className={activeMenu === category.name ? 'sidebar-dropdown active' : 'sidebar-dropdown'}
						key={category.id}
					>
						<a>
							<i className="fa fa-chart-line" />
							<span>{category.name}</span>
						</a>
						<div
							className="sidebar-submenu"
							style={activeMenu === category.name ? { display: 'block' } : { display: 'none' }}
						>
							<ul>
								{!isEmpty(category.reports) &&
									category.reports.map((report) => (
										<li className="sidebar-nav-link" key={report.id}>
											<Link target={"_blank"} to={{ pathname: `/report/${report.id}` }}>
												{report.name}
											</Link>
										</li>
									))}
							</ul>
						</div>
					</li>
				))}
			</ul>
		);
	}
}

export default ReportModuleMenu;
