import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { hideModal } from '../../../actions/modal.actions';
import {createLoadingSelector, getModalData} from '../../../selectors/index';
import {getAccounts, emptyAccounts} from "../../../actions/account-search";
import {Formik} from "formik";
import Select from 'react-select';
import {groupStyles} from '../../../utils/SelectStyles';
import FormSelect from "../../../components/UI/FormSelect";
import DatePicker from "react-datepicker";
import moment from "moment";
import * as Yup from "yup";

class AddBillingJobModal extends Component {

    state = {
        selectedAccountsOptions: [],
    }

    componentWillUnmount() {
        this.props.emptyAccounts();
    }

    closeModal = () => {
        this.props.hideModal();
        this.props.modalProps.onCloseCallback();
    }

    loadAccountInfo = (values, setFieldValue) => (value, option) => {

        if (option && option.action === 'input-change') {

            setFieldValue('selectedAccountsInput', value);

            if (this['timeout']) {
                clearTimeout(this['timeout']);
            }

            this['timeout'] = setTimeout(() => {
                this.props.getAccounts('ACCOUNT NUMBER', value).then(response => {

                    if (response && response.length > 0) {

                        let updatedSelectedAccountsOptions = [];

                        response.map(user => {
                            updatedSelectedAccountsOptions.push({
                                label: user.number + ' - ' + user.systemUser.firstName + ' ' + user.systemUser.lastName,
                                value: user.number
                            });
                        });

                        this.setState({selectedAccountsOptions: updatedSelectedAccountsOptions});
                    }
                });
            }, 1000);
        }
    }

    addAccountNumber = (values, setFieldValue) => (options, action) => {

        if (action.action === 'select-option') {
            setFieldValue("selectedAccountsInput", '');
            setFieldValue(
                "selectedAccountsArray",
                [...values.selectedAccountsArray, {label: action.option.label, value: action.option.value}]
            );
        }

        if (action.action === 'remove-value') {
            setFieldValue(
                "selectedAccountsArray",
                [...values.selectedAccountsArray.filter(option => option.value !== action.removedValue.value)]
            );
        }
    }

    render() {

        const {
            selectedAccountsOptions,
        } = this.state;

        const {
            modalProps,
            accountLoading,
        } = this.props;

        return (
            <Fragment>
                <div className="modal" style={{display: 'block'}} tabIndex="-1" role="dialog">
                    <Formik
                        initialValues={{
                            billCycle: null,
                            billCycleStart: null,
                            billingPeriod: {label: 'Monthly', value: 'Monthly'},
                            generateInvoices: true,
                            allAccounts: true,
                            selectedTypesToggle: false,
                            selectedTypes: [],
                            selectedAccounts: false,
                            selectedAccountsInput: null,
                            selectedAccountsArray: [],
                            unbilledStart: null,
                            unbilledEnd: null,
                            note: null,
                            runDateTime: moment().toDate(),
                        }}
                        validationSchema={Yup.object().shape({
                            billCycle: Yup.string().typeError('Required').required('Required'),
                            billCycleStart: Yup.string().typeError('Required').required('Required'),
                            billingPeriod: Yup.string().typeError('Required').required('Required'),
                            generateInvoices: Yup.string().typeError('Required').required('Required'),
                            unbilledStart: Yup.string().typeError('Required').required('Required'),
                            unbilledEnd: Yup.string().typeError('Required').required('Required'),
                            runDateTime: Yup.string().typeError('Required').required('Required'),
                        })}
                        onSubmit={this.formSubmit}
                        render={({handleChange, handleSubmit, handleBlur, values, errors, touched, isSubmitting, setFieldValue}) => (
                            <form onSubmit={handleSubmit}>
                                <div className="modal-dialog">
                                    <div className="modal-content">

                                        <div className="modal-header">
                                            <h5 className="modal-title">
                                                New Billing Job
                                            </h5>
                                            <button onClick={this.props.hideModal} type="button" className="close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>

                                        <div className="modal-body form-horizontal">

                                            <div className="form-group">
                                                <FormSelect
                                                    title="Bill Cycles"
                                                    fieldName="billCycle"
                                                    placeholder="Select one..."
                                                    options={[
                                                        {label: 1, value: 1},
                                                        {label: 7, value: 7},
                                                        {label: 14, value: 14},
                                                        {label: 21, value: 21},
                                                        {label: 28, value: 28},
                                                        {label: 30, value: 30},
                                                        {label: 31, value: 31},
                                                    ]}
                                                    value={values.billCycle}
                                                    setFieldValue={setFieldValue}
                                                    onBlur={handleBlur}
                                                    errors={errors}
                                                    touched={touched}
                                                />
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="date">Start of Bill Cycle</label>
                                                <div className="customDatePickerWidth">
                                                    <DatePicker
                                                        className={"form-control text-left" + (touched.billCycleStart && errors.billCycleStart ? " is-invalid" : "")}
                                                        fieldName="billCycleStart"
                                                        dateFormat="yyyy-MM"
                                                        placeholderText={"Select Date"}
                                                        autoFocus={false}
                                                        showMonthYearPicker
                                                        shouldCloseOnSelect={true}
                                                        popperPlacement={'auto'}
                                                        selected={values.billCycleStart}
                                                        onChange={(date) => setFieldValue('billCycleStart', date)}
                                                    />
                                                </div>
                                                {touched.billCycleStart && errors.billCycleStart &&
                                                <div className="invalid-feedback">{errors.billCycleStart}</div>
                                                }
                                            </div>

                                            <div className="form-group">
                                                <label>Billing Period</label>
                                                <Select
                                                    name="billingPeriod"
                                                    options={[
                                                        {label: 'Monthly', value: 'Monthly'},
                                                    ]}
                                                    onChange={(value) => setFieldValue('billingPeriod', value)}
                                                    value={values.billingPeriod}
                                                    styles={groupStyles}
                                                />
                                                {touched.billingPeriod && errors.billingPeriod &&
                                                <div className="invalid-feedback">{errors.billingPeriod}</div>
                                                }
                                            </div>

                                            <div className="form-group">
												<span className="h-check">
													<label>{values.generateInvoices === false && "Do Not "}Generate Invoice Files&nbsp;</label>
													<div className="form-check checkbox-slider checkbox-slider--b-flat">
														<label>
															<input
                                                                name="generateInvoices"
                                                                type="checkbox"
                                                                onBlur={handleBlur}
                                                                onChange={() => setFieldValue('generateInvoices', !values.generateInvoices)}
                                                                checked={values.generateInvoices}
                                                            />
															<span>&nbsp;</span>
														</label>
													</div>
												</span>
                                            </div>

                                            <div className="form-group">
												<span className="h-check">
													<label>{values.allAccounts ? "All" : "Selected"} Accounts&nbsp;</label>
													<div className="form-check checkbox-slider checkbox-slider--b-flat">
														<label>
															<input
                                                                name="allAccounts"
                                                                type="checkbox"
                                                                onBlur={handleBlur}
                                                                onChange={() => setFieldValue('allAccounts', !values.allAccounts)}
                                                                checked={values.allAccounts}
                                                            />
															<span>&nbsp;</span>
														</label>
													</div>
												</span>
                                            </div>

                                            {!values.allAccounts && <>

                                                <div className="form-group">
                                                    <span className="h-check">
                                                        <label>Selected Types&nbsp;</label>
                                                        <div className="form-check checkbox-slider checkbox-slider--b-flat">
                                                            <label>
                                                                <input
                                                                    name="selectedTypesToggle"
                                                                    type="checkbox"
                                                                    onBlur={handleBlur}
                                                                    onChange={() => setFieldValue('selectedTypesToggle', !values.selectedTypesToggle)}
                                                                    checked={values.selectedTypesToggle}
                                                                />
                                                                <span>&nbsp;</span>
                                                            </label>
                                                        </div>
                                                    </span>
                                                </div>

                                                {values.selectedTypesToggle &&
                                                <div className="form-group">
                                                    <FormSelect
                                                        fieldName="selectedTypes"
                                                        setFieldValue={setFieldValue}
                                                        value={values.selectedTypes}
                                                        isMulti={true}
                                                        onBlur={handleBlur}
                                                        errors={errors}
                                                        touched={touched}
                                                        options={[
                                                            {label: "Residential", value: "Residential"},
                                                            {label: "Business", value: "Business"},
                                                            {label: "Enterprise", value: "Enterprise"},
                                                        ]}
                                                    />
                                                </div>
                                                }

                                                <div className="form-group">
                                                    <span className="h-check">
                                                        <label>Selected Accounts&nbsp;</label>
                                                        <div className="form-check checkbox-slider checkbox-slider--b-flat">
                                                            <label>
                                                                <input
                                                                    name="selectedAccounts"
                                                                    type="checkbox"
                                                                    onBlur={handleBlur}
                                                                    onChange={() => setFieldValue('selectedAccounts', !values.selectedAccounts)}
                                                                    checked={values.selectedAccounts}
                                                                />
                                                                <span>&nbsp;</span>
                                                            </label>
                                                        </div>
                                                    </span>
                                                </div>

                                                {values.selectedAccounts &&
                                                <div className="form-group">
                                                    <Select
                                                        id={'selectedAccountsInput'}
                                                        name={'selectedAccountsInput'}
                                                        placeholder={"Type account number..."}
                                                        options={selectedAccountsOptions}
                                                        inputValue={values.selectedAccountsInput}
                                                        value={values.selectedAccountsArray}
                                                        onInputChange={this.loadAccountInfo(values, setFieldValue)}
                                                        onChange={this.addAccountNumber(values, setFieldValue)}
                                                        isMulti
                                                        isClearable={false}
                                                        isLoading={accountLoading}
                                                        isDisabled={isSubmitting}
                                                        styles={groupStyles}
                                                    />
                                                </div>
                                                }

                                            </>}

                                            <div className="form-group">
                                                <label htmlFor="date">Unbilled Usage Start Date</label>
                                                <div className="customDatePickerWidth">
                                                    <DatePicker
                                                        className={"form-control text-left" + (touched.unbilledStart && errors.unbilledStart ? " is-invalid" : "")}
                                                        fieldName="unbilledStart"
                                                        placeholderText={"Select Date"}
                                                        autoFocus={false}
                                                        shouldCloseOnSelect={true}
                                                        popperPlacement={'auto'}
                                                        selected={values.unbilledStart}
                                                        maxDate={values.unbilledEnd}
                                                        dateFormat="yyyy/MM/dd"
                                                        onChange={(date) => setFieldValue('unbilledStart', date)}
                                                    />
                                                </div>
                                                {touched.unbilledStart && errors.unbilledStart &&
                                                <div className="invalid-feedback">{errors.unbilledStart}</div>
                                                }
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="date">Unbilled Usage End Date</label>
                                                <div className="customDatePickerWidth">
                                                    <DatePicker
                                                        className={"form-control text-left" + (touched.unbilledEnd && errors.unbilledEnd ? " is-invalid" : "")}
                                                        fieldName="unbilledEnd"
                                                        placeholderText={"Select Date"}
                                                        autoFocus={false}
                                                        shouldCloseOnSelect={true}
                                                        popperPlacement={'auto'}
                                                        selected={values.unbilledEnd}
                                                        minDate={values.unbilledStart}
                                                        dateFormat="yyyy/MM/dd"
                                                        onChange={(date) => setFieldValue('unbilledEnd', date)}
                                                    />
                                                </div>
                                                {touched.unbilledEnd && errors.unbilledEnd &&
                                                <div className="invalid-feedback">{errors.unbilledEnd}</div>
                                                }
                                            </div>

                                            <div className="form-group">
                                                <label>Note</label>
                                                <textarea
                                                    name="note"
                                                    className={"form-control" + (touched.note && errors.note ? " is-invalid" : "")}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.note}
                                                />
                                                {touched.note && errors.note &&
                                                <div className="invalid-feedback">{errors.note}</div>
                                                }
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="date">Run Date Time</label>
                                                <div className="customDatePickerWidth">
                                                    <DatePicker
                                                        className={"form-control text-left" + (touched.runDateTime && errors.runDateTime ? " is-invalid" : "")}
                                                        fieldName="runDateTime"
                                                        placeholderText={"Select Date"}
                                                        autoFocus={false}
                                                        shouldCloseOnSelect={true}
                                                        showTimeSelect
                                                        timeIntervals={60}
                                                        popperPlacement={'auto'}
                                                        selected={values.runDateTime}
                                                        minDate={moment().toDate()}
                                                        dateFormat="MMMM d, yyyy h:mm aa"
                                                        onChange={(date) => setFieldValue('runDateTime', date)}
                                                    />
                                                </div>
                                                {touched.runDateTime && errors.runDateTime &&
                                                <div className="invalid-feedback">{errors.runDateTime}</div>
                                                }
                                            </div>

                                        </div>

                                        <div className="modal-footer">
                                            <button onClick={this.props.hideModal} className="btn" type="button">
                                                Cancel
                                            </button>
                                            <button disabled={isSubmitting} type="submit" className="btn btn-primary">
                                                Save
                                            </button>
                                        </div>

                                    </div>
                                </div>
                            </form>
                        )}
                    />}
                </div>
                <div className="modal-backdrop show" tabIndex="1"/>
            </Fragment>
        );
    }
}

const accountLoader = createLoadingSelector(['ACCOUNT_SEARCH']);

const mapStateToProps = (state) => {

    const modalProps = getModalData(state).modalProps;
    const accountLoading = accountLoader(state);

    return {
        modalProps,
        accountLoading,
    };
};

const mapDispatchToProps = {
    hideModal,
    getAccounts,
    emptyAccounts,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddBillingJobModal);
