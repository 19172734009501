import {
    EMPTY_CAPITAL_CREDIT, GENERATE_MEMBER_NUMBER,
    GET_ACCOUNT_CAPITAL_CREDIT,
    UPDATE_ACCOUNT_CAPITAL_CREDIT
} from "../../actions/accountDetailsActions/capitalCreditActions";

let initialState = {
    capitalCredit: {}
};

export const capitalCredit = (state = initialState, action) => {
    switch (action.type) {
        case GET_ACCOUNT_CAPITAL_CREDIT.SUCCESS:
        case UPDATE_ACCOUNT_CAPITAL_CREDIT.SUCCESS:
        case GENERATE_MEMBER_NUMBER.SUCCESS:
            return {
                ...state,
                capitalCredit: action.payload
            }

        case EMPTY_CAPITAL_CREDIT:
            return {
                ...initialState
            };

        default:
            return state;
    }
}