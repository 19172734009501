import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory, {
	PaginationListStandalone,
	SizePerPageDropdownStandalone,
	PaginationProvider,
	PaginationTotalStandalone
} from 'react-bootstrap-table2-paginator';
import moment from 'moment';
import { connect } from 'react-redux';
import { getReccuringCredits, emptyReccuringCredits } from '../../../../../../actions/accountDetailsActions/billing.actions';
import { showModal } from '../../../../../../actions/modal.actions';
import { getAccountDetails, createLoadingSelector } from '../../../../../../selectors';
import Loader from '../../../../../../components/Loader';
import { isEmpty } from '../../../../../../utils/helpers';

const { SearchBar } = Search;

class ReccuringCredits extends Component {
	state = {};

	componentDidMount() {
		const { accountInfo } = this.props;
		this.props.getReccuringCredits(accountInfo.id);
	}

	componentWillUnmount() {
		this.props.emptyReccuringCredits();
	}

	showDeleteModal = (reccuringCreditId, description) => {
		const { accountInfo } = this.props;
		const info = {
			accountId: accountInfo.id,
			itemId: reccuringCreditId,
			description: description,
			type: 'reccuring'
		};
		this.props.showModal('GENERIC_DELETE_BILING_MODAL', info);
	};

	descriptionFormater = (cell, row) => {
		if (row) {
			return (
				<Fragment>
					{cell}
					<span className="td-subtitle">${row.amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</span>
				</Fragment>
			);
		}
	};

	updatedFormater = (cell, row) => {
		if (row) {
			return (
				<Fragment>
					{moment(cell).format('MM/DD/YYYY')} <span className="td-subtitle">{row.modifiedBy}</span>
				</Fragment>
			);
		}
	};

	actionFormater = (cell, row, rowIndex) => {
		return (
			<div className="tr-actions">
				<button
					onClick={() => this.props.showModal('RECCURING_CREDDINTS_MODAL', { row, ...{ type: 'edit' } })}
					className="btn"
					type="button"
				>
					<i className="fas fa-edit" />
				</button>
				<button onClick={() => this.showDeleteModal(row.id, row.description)} className="btn" type="button">
					<i className="fas fa-trash" />
				</button>
			</div>
		);
	};

	statusFormater = (cell, row) => {
		
		if (row) {
			if (moment() > moment(row.validTo)) {
				return <span className="badge badge-secondary">Expired</span>;
			} else if (cell === false) {
				return <span className="badge badge-warning">Pending</span>;
			} else {
				return <span className="badge badge-success">Approved</span>;
			}
		}
	};

	customTotal = (from, to, size) => (
		<span className="react-bootstrap-table-pagination-total">
			Showing {from} to {to} of {size} Results
		</span>
	);

	validFormater = (cell, row) => {
		if (row) {
			if (moment() > moment(row.validTo)) {
				let validTo = moment(row.validTo);
				let now = moment();

				let months = now.diff(validTo, 'months');
				validTo.add(months, 'months');

				let days = now.diff(validTo, 'days');
				validTo.add(days, 'days');

				return (
					<Fragment>
						Expired
						{months !== 0 && ` ${months} months and`}
						{days !== 0 && ` ${days} days ago`}
					</Fragment>
				);
			} else if (row.isActive === false) {
				return 'Not started yet';
			} else if (moment(row.validFrom) > moment()) {
				return 'Not started yet';
			} else if (moment(row.validFrom) < moment() && moment() < moment(row.validTo)) {
				let validTo = moment(row.validTo);
				let now = moment();

				let months = validTo.diff(now, 'months');
				now.add(months, 'months');

				let days = validTo.diff(now, 'days');
				now.add(days, 'days');

				return (
					<Fragment>
						{months !== 0 && `${months} months and `}
						{days !== 0 && `${days} days left`}
					</Fragment>
				);
			} else {
				return null;
			}
		}
	};

	render() {
		const { reccuringCreditsData, reccuringCreditsLoader } = this.props;

		if (isEmpty(reccuringCreditsData) && reccuringCreditsLoader) {
			return <Loader />;
		}

		const options = {
			paginationSize: 4,
			custom: true,
			totalSize: reccuringCreditsData.length,
			pageStartIndex: 1,
			// alwaysShowAllBtns: true, // Always show next and previous button
			// withFirstAndLast: false, // Hide the going to First and Last page button
			// hideSizePerPage: true, // Hide the sizePerPage dropdown always
			hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
			firstPageText: 'First',
			prePageText: 'Previous',
			nextPageText: 'Next',
			lastPageText: 'Last',
			// nextPageTitle: 'First page',
			// prePageTitle: 'Pre page',
			// firstPageTitle: 'Next page',
			// lastPageTitle: 'Last page',
			showTotal: true,
			paginationTotalRenderer: this.customTotal,
			sizePerPageList: [
				{
					text: '10',
					value: 10
				},
				{
					text: '25',
					value: 25
				},
				{
					text: '50',
					value: 50
				},
				{
					text: '100',
					value: 100
				},
				{
					text: 'All',
					value: reccuringCreditsData.length
				}
			] // A numeric array is also available. the purpose of above example is custom the text
		};

		const columns = [
			{
				dataField: 'description',
				text: 'Description',
				sort: true,
				formatter: this.descriptionFormater
			},
			{
				dataField: 'isActive',
				text: 'Status',
				sort: true,
				formatter: this.statusFormater
			},
			{
				dataField: '',
				text: 'Valid Period',
				sort: true,
				formatter: this.validFormater
			},
			{
				dataField: 'modifiedDateTime',
				text: 'UPDATED',
				sort: true,
				formatter: this.updatedFormater
			},

			{
				dataField: 'actions',
				isDummyField: true,
				text: 'Actions',
				formatter: this.actionFormater,
				headerClasses: 'h-align-r'
			}
		];

		const defaultSorted = [
			{
				dataField: 'adjustmentDatetime',
				order: 'desc'
			}
		];
		return (
			<Fragment>
				<div className="cmv-container-table">
					<PaginationProvider pagination={paginationFactory(options)}>
						{({ paginationProps, paginationTableProps }) => (
							<Fragment>
								<ToolkitProvider
									bootstrap4
									keyField="id"
									data={reccuringCreditsData}
									columns={columns}
									defaultSorted={defaultSorted}
									search
								>
									{(props) => (
										<Fragment>
											<div className="row">
												<div className="col-sm-12 col-md-6">
													<label>
														Show <SizePerPageDropdownStandalone {...paginationProps} />{' '}
														entries
													</label>
												</div>
												<div className="col-sm-12 col-md-6" style={{ textAlign: 'right' }}>
													<label>
														Search:{' '}
														<SearchBar
															className="form-control form-control-sm"
															{...props.searchProps}
														/>
														<button
															onClick={() =>
																this.props.showModal('RECCURING_CREDDINTS_MODAL', {
																	type: 'create'
																})}
															className="btn btn-primary"
															style={{ margin: '5px' }}
														>
															<i className="fas fa-plus" /> New
														</button>
													</label>
												</div>
											</div>
											<div className="row">
												<BootstrapTable
													wrapperClasses="table-responsive react-table-layout"
													classes="table table-striped cmv-table"
													bordered={false}
													noDataIndication="No data found"
													{...paginationTableProps}
													{...props.baseProps}
												/>
											</div>
											<div className="row" style={{ marginTop: '10px' }}>
												<div className="col-sm-12 col-md-5">
													<PaginationTotalStandalone {...paginationProps} />
												</div>
												<div className="col-sm-12 col-md-7">
													<PaginationListStandalone {...paginationProps} />
												</div>
											</div>
										</Fragment>
									)}
								</ToolkitProvider>
							</Fragment>
						)}
					</PaginationProvider>
				</div>
			</Fragment>
		);
	}
}

ReccuringCredits.propTypes = {
	accountInfo: PropTypes.object.isRequired
};

const loader = createLoadingSelector([ 'SET_RECCURING_CREDITS' ]);

const mapStateToProps = (state) => {
	const reccuringCreditsData = getAccountDetails(state).billing.reccuringCredits,
		reccuringCreditsLoader = loader(state);

	return {
		reccuringCreditsData,
		reccuringCreditsLoader
	};
};

const mapDispatchToProps = {
	getReccuringCredits,
	showModal,
	emptyReccuringCredits
};

export default connect(mapStateToProps, mapDispatchToProps)(ReccuringCredits);
