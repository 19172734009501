import React, {Component, Fragment} from 'react';
import {getTaskDetails, getTaskForm} from "../../../selectors";
import {
    getActiveServiceAgreements,
    getServiceAgreementForServiceLine,
    clearServiceAgreementForServiceLine
} from "../../../actions/taskFormActions/serviceAgreement.actions";
import {connect} from "react-redux";
import isEmpty from "../../../utils/helpers";
import ReactHtmlParser from "react-html-parser";

export class ServiceAgreement extends Component {
    state = {
        toggledLines: {}
    }

    componentDidMount() {
        const {serviceorderInfo, getActiveServiceAgreements} = this.props;
        getActiveServiceAgreements();

        if (serviceorderInfo.serviceLines) {
            serviceorderInfo.serviceLines.forEach((serviceLine) => {
                if (serviceLine.serviceAgreementId) {
                    this.props.getServiceAgreementForServiceLine(serviceLine.serviceAgreementId, serviceLine.id);
                }
            });
        }
    }

    handleServiceAgreementChange = (event, serviceLineId) => {
        const {setFieldValue, field, values} = this.props;

        const newValue = {
            ...values[field.id],
            [serviceLineId]: event.target.value
        }

        this.props.getServiceAgreementForServiceLine(event.target.value, serviceLineId).then((result) => {
            setFieldValue(`${field.id}`, newValue);
        });

    }

    toggleAgreement = (serviceLineId) => {
        this.setState({
            toggledLines: {
                ...this.state.toggledLines,
                [serviceLineId]: this.state.toggledLines[serviceLineId] ? false: true
            }
        });
    }

    render() {
        const {
            field, errors, touched, key, handleBlur, values, serviceAgreements,
            serviceAgreementForServiceLine, clearServiceAgreement, serviceorderInfo
        } = this.props;

        return (
            <div className="form-group">
                <label>{field.name}</label>

                {serviceorderInfo.serviceLines && serviceorderInfo.serviceLines.map((serviceLine) =>
                    <div className="card-toggable form-group">
                        <div className="card-toggable-header">
                            <span onClick={() => this.toggleAgreement(serviceLine.id)}
                                className={this.state.toggledLines[serviceLine.id]? 'h-title toggled': 'h-title'}>{serviceLine.number || serviceorderInfo.serviceAddress}</span>
                        </div>
                        <div className={this.state.toggledLines[serviceLine.id]? 'card-toggable-body collapse show': 'card-toggable-body collapse'}>
                            <select
                                className={
                                    errors[field.id] && touched[field.id] ? (
                                        'selectize-generic form-control is-invalid'
                                    ) : (
                                        'selectize-generic form-control'
                                    )
                                }
                                value={values[field.id] ? values[field.id][serviceLine.id] || '' : ''}
                                onChange={(event) => this.handleServiceAgreementChange(event, serviceLine.id)}
                                onBlur={handleBlur}
                                name={field.id}
                                autoComplete="off"
                            >
                                <option value="">Select</option>
                                {serviceAgreements.map((serviceAgreement, index) => (
                                    <option key={index} value={serviceAgreement.id}>
                                        {serviceAgreement.description}
                                    </option>
                                ))}
                            </select>

                            {errors[field.id] && touched[field.id] &&
                            <div className="invalid-feedback">{errors[field.id]}</div>}

                            <div className="p-3 bg-gray-200">
                                <div className="h-300">
                                    {serviceAgreementForServiceLine[serviceLine.id] && (ReactHtmlParser(serviceAgreementForServiceLine[serviceLine.id].agreementText))}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            // <div className="form-group" key={key}>
            //     <label>{field.name}</label>
            //
            //
            //         <div className='order-service-order-wrapper form-group'>
            //             <div className='mini-notes-wrapper'>
            //                 <div className='mini-notes mini-notes-h-constrained'>
            //                 {serviceAgreement && (ReactHtmlParser(serviceAgreement.agreementText))}
            //                 </div>
            //             </div>
            //         </div>
            //
            // </div>
        );
    }
}

const mapStateToProps = (state) => {
    let serviceAgreementReducer = getTaskForm(state).serviceAgreementReducer;

    return {
        serviceAgreements: serviceAgreementReducer.serviceAgreements,
        serviceAgreementForServiceLine: serviceAgreementReducer.serviceAgreementForServiceLine
    };
};

const mapDispatchToProps = {
    getActiveServiceAgreements,
    getServiceAgreementForServiceLine,
    clearServiceAgreementForServiceLine
};

export default connect(mapStateToProps, mapDispatchToProps)(ServiceAgreement);