import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser';

class IncludedPackage extends Component {
	state = {
		isDescriptionToggled: false
	};

	isPriceRendered = () => {
		const { accountInitialStatus, featurePackage } = this.props;


		if (accountInitialStatus === 'NEW_SIGNUP') {
			if (featurePackage.featurePackageOptions[0].features.some((item) => item.hideSignupPrice === true)) {
				return false;
			}
		} else if (accountInitialStatus === 'NEW_SURVEY') {
			if (featurePackage.featurePackageOptions[0].features.some((item) => item.hideSurveyPrice === true)) {
				return false;
			}
		}
		return true;
	};

	toggleDescription = (e) => {
		e.stopPropagation();
		this.setState((prevState) => ({
			isDescriptionToggled: !prevState.isDescriptionToggled
		}));
	};

	render() {
		const { isDescriptionToggled } = this.state;
		const { featurePackage } = this.props;

		return (
			<div className="package-option package-option-mandatory">
				<span className="po-title">{featurePackage.featurePackageOptions[0].description}</span>
				<span className="package-toolbar">
					<span className="po-price">
						{this.isPriceRendered() &&
							`${featurePackage.featurePackageOptions[0].recurringMonthlyPrice
								.toFixed(2)
								.replace(/\d(?=(\d{3})+\.)/g, '$&,')}/mo`}
					</span>
					<button type="button" className="btn btn-xs btn-desc-toggler" onClick={this.toggleDescription}>
						<i className="fas fa-info-circle" />
					</button>
				</span>
				<div
					className={isDescriptionToggled ? 'po-description collapse show' : 'po-description collapse'}
					id="pl001-ifeat002-op001-desc"
				>
					{ReactHtmlParser(featurePackage.featurePackageOptions[0].note)}
				</div>
			</div>
		);
	}
}

export default IncludedPackage;
