import React, { Component, Fragment } from 'react';

class Confirmation extends Component {

	startCurrentOrder = () => {
		const { newOrderInfo, startServiceOrder } = this.props;

		startServiceOrder(newOrderInfo.id);
	};

	displayCurrentOrder = () => {
		const { newOrderInfo, showNewOrderWizardHandler, history } = this.props;

		// Hide Wizard
		showNewOrderWizardHandler(false)

		// Indicate Order Id
		history.replace({state: {
			activeTab: 'ordersAndQuotes',
			orderId: newOrderInfo.id,
		}});
	}

	render() {
		const {
			newOrderInfo,
			showNewOrderWizardHandler,
			resetWizard,
			resetForm,
			setFieldTouched,
			isOrderStartedData,
			tasksCountData
		} = this.props;

        var orderTypeText = 'New quote';

		switch (newOrderInfo.type) {
            case 'prospect':
                orderTypeText = 'New prospect';
                break;
            case 'order':
                orderTypeText = 'New order';
                break;
            case 'survey':
                orderTypeText = 'New survey';
                break;
            case 'signup':
                orderTypeText = 'New signup';
                break;
        }

		return (
			<div className="tab-pane active">
				<p className="text-center text-success">
					<i className="far fa-4x fa-check-circle" />
				</p>
				<p className="text-center text-success lead">
					{orderTypeText}{' '}
					<span className="font-weight-bold">#{newOrderInfo.id}</span> created succesfully.
				</p>
				{newOrderInfo.type === 'order' ? (
					<Fragment>
						<p className="text-center">
							<button
								onClick={() => resetWizard(resetForm, setFieldTouched)}
								className="btn btn-outline-success btn-lg mb-1"
								type="button"
							>
								NEW SERVICE REQUEST
							</button>
							<button
								onClick={this.displayCurrentOrder}
								className="btn btn-outline-success btn-lg mb-1"
								style={{ margin: '0px 5px' }}
								type="button"
							>
								View Order
							</button>
							<button
								onClick={this.startCurrentOrder}
								className={
									isOrderStartedData === false ? (
										'btn btn-outline-success btn-lg mb-1 btn-order-view btn-outline-danger'
									) : (
										'btn btn-success btn-lg mb-1'
									)
								}
								type="button"
								disabled={isOrderStartedData !== null}
							>
								{isOrderStartedData === null && 'Execute Order'}
								{isOrderStartedData === true && (
									<Fragment>
										<i className="fas fa-check" /> Order started successfully
									</Fragment>
								)}
								{isOrderStartedData === false && (
									<Fragment>
										<i className="fas fa-exclamation-triangle" /> Order did not start
									</Fragment>
								)}
							</button>
							{tasksCountData > 0 && (
								<button
									onClick={this.displayCurrentOrder}
									className="btn btn-warning btn-lg mb-1"
									style={{ margin: '0px 5px' }}
									type="button"
								>
									<i className="fas fa-tools" />&nbsp;{tasksCountData} {tasksCountData > 1 ? 'Tasks' : 'Task'} Pending
								</button>
							)}
						</p>
					</Fragment>
				) : newOrderInfo.type === 'quote' && (
					<p className="text-center">
						<button
							onClick={() => resetWizard(resetForm, setFieldTouched)}
							className="btn btn-outline-success btn-lg mb-1"
							type="button"
						>
							NEW SERVICE REQUEST
						</button>
						<button
							onClick={() => showNewOrderWizardHandler(false)}
							className="btn btn-outline-success btn-lg mb-1"
							style={{ margin: '0px 5px' }}
							type="button"
						>
							View Quote
						</button>
						<button className="btn btn-success btn-lg mb-1" type="button">
							Convert to Order
						</button>
					</p>
				)}
			</div>
		);
	}
}

export default Confirmation;
