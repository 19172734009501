import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {
	getTroubleTickets,
	emptyTroubleTickets,
	getTroubleTicketsServiceIcons,
	emptyTroubleTicketsServiceIcons,
	getTroubleTicketsServiceLines,
	emptyTroubleTicketsServiceLines,
} from '../../../../../../actions/accountDetailsActions/troubleTickets.actions';
import {createLoadingSelector, getAccountDetails} from '../../../../../../selectors';
import {showModal} from '../../../../../../actions/modal.actions';
import Button from '../../../../../../components/Common/Buttons/Button';
import {isEmpty} from "../../../../../../utils/helpers";
import Loader from "../../../../../../components/Loader";
import Ticket from "./Ticket";

class TroubleTickets extends Component {

	state = {
		isLoaded: false,
	}

	componentDidMount() {
		this.props.emptyTroubleTickets();
		this.props.getTroubleTickets({accountId: this.props.accountInfo.id, completed: true}).then(response => {
			this.setState({isLoaded: true})
		});
		this.props.getTroubleTicketsServiceIcons(this.props.accountInfo.id);
		this.props.getTroubleTicketsServiceLines(this.props.accountInfo.id);
	}

	componentWillUnmount() {
		this.props.emptyTroubleTickets();
		this.props.emptyTroubleTicketsServiceIcons();
	}

	reloadTroubleTickets = () => {

		this.setState({isLoaded: false}, () => {

			setTimeout(() => {
				this.props.getTroubleTickets({accountId: this.props.accountInfo.id, completed: true}).then(response => {
					this.setState({isLoaded: true})
				});
			}, 1500);
		});
	}

	render() {

		const {
			isLoaded,
		} = this.state;

		const {
			accountInfo,
			ticketsLoader,
			tickets,
			serviceIconsLoader,
			serviceIcons,
			serviceLinesLoader,
			serviceLines,
		} = this.props;

		return (
			<div
				className="tab-pane card-body fade show active"
				id="troubleTickets"
				aria-labelledby="troubleTickets-tab"
				role="tabpanel"
			>

				{!serviceIconsLoader && !serviceLinesLoader && !isEmpty(serviceIcons) && !isEmpty(serviceLines) &&
					<div className="tab-pane-toolbar">

						<Button
							type="button"
							className="btn btn-outline-success btn-xl"
							onClick={() => this.props.showModal('TROUBLE_TICKET_MODAL', {
								accountInfo,
								serviceIcons,
								serviceLines,
								onCloseCallback: this.reloadTroubleTickets
							})}
						>
							<i className="fas fa-plus"/> Trouble Ticket
						</Button>

					</div>
				}

				{(!isLoaded || ticketsLoader || serviceIconsLoader || serviceLinesLoader) &&
				<Loader />
				}

				{isLoaded && !ticketsLoader && !serviceIconsLoader && !serviceLinesLoader && isEmpty(tickets) &&
				<p className="tab-pane-message-empty">No data found</p>
				}

				{isLoaded && !ticketsLoader && !serviceIconsLoader && !isEmpty(tickets) && !isEmpty(serviceLines) &&
				tickets.map(ticket =>
				 	<Ticket
						troubleTicketId={ticket.troubleTicketDetails.troubleTicketId}
						status={ticket.troubleTicketDetails.status}
						createdById={ticket.troubleTicketDetails.createdById}
						createdByName={ticket.troubleTicketDetails.createdByName}
						createdAt={ticket.troubleTicketDetails.createdAt}
						customerAccountInfo={accountInfo}
						reportedIssueId={ticket.troubleTicketDetails.reportedIssueId}
						reportedIssueDescription={ticket.troubleTicketDetails.reportedIssueDescription}
						contactPhoneNumber={ticket.troubleTicketDetails.contactPhoneNumber}
						dueDate={ticket.dueDate}
						latestNote={ticket.troubleTicketDetails.latestNote}
						pendingTasks={ticket.troubleTicketDetails.pendingTasks}
						serviceLines={ticket.troubleTicketDetails.serviceLines}
						allServices={serviceLines}
						serviceIcons={serviceIcons}
						serviceInfo={ticket}
						customerAccountId={ticket.troubleTicketDetails.customerAccountId}
						accountInfo={accountInfo}
						troubleTicketDetails={ticket.troubleTicketDetails}
						appointmentModalCallback={() => this.props.getTroubleTickets({accountId: accountInfo.id, completed: true})}
						history={this.props.history}
					/>
				)}

			</div>
		);
	}
}

TroubleTickets.propTypes = {
	accountInfo: PropTypes.object.isRequired,
	changeTabHandler: PropTypes.func,
};

const getLoaderTroubleTickets = createLoadingSelector(['GET_TROUBLE_TICKETS']);
const getLoaderServiceIcons = createLoadingSelector(['GET_TROUBLE_TICKETS_SERVICE_ICONS']);
const getLoaderServiceLines = createLoadingSelector(['GET_TROUBLE_TICKETS_SERVICE_LINES']);

const mapStateToProps = (state) => {

	const ticketsLoader = getLoaderTroubleTickets(state);
	const tickets = getAccountDetails(state).troubleTickets.troubleTickets;
	const serviceIconsLoader = getLoaderServiceIcons(state);
	const serviceIcons = getAccountDetails(state).troubleTickets.troubleTicketsServiceIcons;
	const serviceLinesLoader = getLoaderServiceLines(state);
	const serviceLines = getAccountDetails(state).troubleTickets.troubleTicketsServiceLines;

	return {
		ticketsLoader,
		tickets,
		serviceIconsLoader,
		serviceIcons,
		serviceLinesLoader,
		serviceLines,
	};
};


const mapDispatchToProps = {
	showModal,
	getTroubleTickets,
	emptyTroubleTickets,
	getTroubleTicketsServiceIcons,
	emptyTroubleTicketsServiceIcons,
	getTroubleTicketsServiceLines,
	emptyTroubleTicketsServiceLines,
};

export default connect(mapStateToProps, mapDispatchToProps)(TroubleTickets);
