import React from 'react';

const VariablePriceInput = ({feature, value, disabled, handleVariableFeaturePrice}) => {
    return (
        <div className="form-number"
             onClick={(e) => e.stopPropagation()}
        >
            <div className="input-group input-group-sm">
                <div className="input-group-prepend">
                    <span className="input-group-text">$</span>
                </div>
                <input
                    className="form-control"
                    type="text"
                    value={value}
                    disabled={disabled}
                    onChange={(e) => handleVariableFeaturePrice(feature, e.target.value)}
                />
            </div>
        </div>
    );
};

export default VariablePriceInput;
