/* CacheBuster component */
import React, {Fragment} from 'react';
global.appVersion = null;

const semverGreaterThan = (versionA, versionB) => {
    const versionsA = versionA.split(/\./g);

    const versionsB = versionB.split(/\./g);
    while (versionsA.length || versionsB.length) {
        const a = Number(versionsA.shift());

        const b = Number(versionsB.shift());
        // eslint-disable-next-line no-continue
        if (a === b) continue;
        // eslint-disable-next-line no-restricted-globals
        return a > b || isNaN(b);
    }
    return false;
}

export default class CacheBuster extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            isLatestVersion: false,
            refreshCacheAndReload: () => {
                console.log('Clearing cache and hard reloading...')
                if (caches) {
                    // Service worker cache should be cleared with caches.delete()
                    caches.keys().then(function(names) {
                        for (let name of names) caches.delete(name);
                    });
                }
                // delete browser cache and hard reload
                window.location.reload(true);
            }
        };
    }

    componentDidMount() {
        fetch('/meta.json')
            .then((response) => response.json())
            .then((meta) => {
                global.appVersion = meta.version;
                this.setState({loading: false, isLatestVersion: true});
            });

        this.versionInterval = setInterval(() => {
            fetch('/meta.json')
                .then((response) => response.json())
                .then((meta) => {
                    const latestVersion = meta.version;
                    const currentVersion = global.appVersion;

                    const shouldForceRefresh = semverGreaterThan(latestVersion, currentVersion);
                    if (shouldForceRefresh) {
                        console.log(`We have a new version - ${latestVersion}. Should force refresh`);
                        this.setState({loading: false, isLatestVersion: false});
                    } else {
                        console.log(`You already have the latest version - ${latestVersion}. No cache refresh needed.`);
                        this.setState({loading: false, isLatestVersion: true});
                    }
                });
        }, 120000);
    }

    componentWillUnmount() {
        clearInterval(this.versionInterval);
    }

    render() {
        const { loading, isLatestVersion, refreshCacheAndReload } = this.state;
        return this.props.children({ loading, isLatestVersion, refreshCacheAndReload });
    }
}