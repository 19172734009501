import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory, {
	PaginationListStandalone,
	SizePerPageDropdownStandalone,
	PaginationProvider,
	PaginationTotalStandalone
} from 'react-bootstrap-table2-paginator';
import moment from 'moment';
import { connect } from 'react-redux';
import {
	getInstallmentPlans,
	emptyInstallmentPlans
} from '../../../../../../actions/accountDetailsActions/billing.actions';
import {getAccountDetails, createLoadingSelector} from '../../../../../../selectors';
import Loader from '../../../../../../components/Loader';
import { isEmpty } from '../../../../../../utils/helpers';
import InstallmentDetails from './InstallmentDetails';

class InstallmentPlans extends Component {

	state = { expanded: [] }

	handleBtnClick = (paymentId) => {
		if (!this.state.expanded.includes(paymentId)) {
			this.setState(() => ({
				expanded: [ ...this.state.expanded, paymentId ]
			}));
		} else {
			this.setState(() => ({
				expanded: this.state.expanded.filter((x) => x !== paymentId)
			}));
		}
	};

	handleOnExpand = (row, isExpand, rowIndex, e) => {
		if (isExpand) {
			this.setState(() => ({
				expanded: [ ...this.state.expanded, row.paymentId ]
			}));
		} else {
			this.setState(() => ({
				expanded: this.state.expanded.filter((x) => x !== row.paymentId)
			}));
		}
	};

	componentDidMount() {
		const { accountInfo } = this.props;
		this.props.getInstallmentPlans(accountInfo.id);
	}

	componentWillUnmount() {
		this.props.emptyInstallmentPlans();
	}


	planFormatter = (cell, row, rowIndex) => {
		let invoicedItemName = row.invoicedItem ? row.invoicedItem.description : ''
		let installmentPlanName = row.installmentPlanItem ? row.installmentPlanItem.description : ''
		if(cell){
			return <div><span>{installmentPlanName}</span><span className="td-subtitle">{invoicedItemName}</span></div>;
		}
	}
	invoiceFormatter = (cell, row, rowIndex) => {
		if(row){
			return <div><span>#{row.invoiceNumber}</span><span className="td-subtitle">{row.servicelineNumber}</span></div>;
		}
	};
	dateFormatter =(cell,row,rowIndex)=>{
		if (row) {
			return (
				<Fragment>
					{moment(cell).format('MM/DD/YYYY')}
				</Fragment>
			);
		}
	};

	balanceFormatter = (cell, row, rowIndex) => {
		if (row) {
			return <Fragment><span>${cell.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</span><span className="td-subtitle">of ${row.amount.toFixed(2)}</span></Fragment>;
		}
	};

	actionFormatter = (cell, row, rowIndex) => {

		return (
			<div className="tr-actions">

				<button className="btn" onClick={() => this.handleBtnClick(row.id)}>
					<i className="fas fa-eye" />
				</button>

			</div>
		);
	};


	customTotal = (from, to, size) => (
		<span className="react-bootstrap-table-pagination-total">
			Showing {from} to {to} of {size} Results
		</span>
	);

	render() {
		const { installmentData, installmentLoader } = this.props;
		const { loadingPaymentReceipt } = this.state;

		if (isEmpty(installmentData) && installmentLoader) {
			return <Loader />;
		}

		const { SearchBar } = Search;

		const options = {
			paginationSize: 4,
			custom: true,
			totalSize: this.props.installmentData.length,
			pageStartIndex: 1,
			hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
			firstPageText: 'First',
			prePageText: 'Previous',
			nextPageText: 'Next',
			lastPageText: 'Last',
			showTotal: true,
			paginationTotalRenderer: this.customTotal,
			sizePerPageList: [
				{
					text: '10',
					value: 10
				},
				{
					text: '25',
					value: 25
				},
				{
					text: '50',
					value: 50
				},
				{
					text: '100',
					value: 100
				}
			]
		};

		const columns = [
			{
				dataField: 'createdDateTime',
				text: 'DATE',
				sort: true,
				formatter: this.dateFormatter
			},
			{
				dataField: 'invoicedItem',
				text: 'DESCRIPTION',
				sort: true,
				formatter: this.planFormatter,
				classes: 'v-align-m"'
			},
			{
				dataField: 'invoicedItem',
				text: 'INVOICE',
				sort: true,
				formatter: this.invoiceFormatter
			},

			{
				dataField: 'balance',
				text: 'BALANCE',
				sort: true,
				formatter: this.balanceFormatter,
				//classes: 'h-align-l'
			},
			{
				dataField: 'actions',
				isDummyField: true,
				text: 'Actions',
				headerClasses: 'h-align-r',
				formatter: this.actionFormatter,
				formatExtraData: {
					loadingPaymentReceipt
				},
			},
			{
				dataField: 'installmentid',
				hidden: true
			}
		];

		const defaultSorted = [
			{
				dataField: 'createdDateTime',
				order: 'desc'
			}
		];

		const rowClasses = (row, rowIndex) => {
			let classes = null;
		  
			if (this.state.expanded.includes(row.id)) {
			  classes = 'tr-expanded';
			}
		  
			return classes;
		  };

		const expandRow = {
			renderer: (row) => <InstallmentDetails accountInfo={this.props.accountInfo} installmentId={row.id} key={row.id}/>,

			expandByColumnOnly: true,
			expanded: this.state.expanded,
			onExpand: this.handleOnExpand
		};

		return (
			<Fragment>
				<div className="cmv-container-table">
					<PaginationProvider pagination={paginationFactory(options)}>
						{({ paginationProps, paginationTableProps }) => (
							<Fragment>
								<ToolkitProvider
									bootstrap4
									keyField="id"
									data={installmentData}
									columns={columns}
									defaultSorted={defaultSorted}
							
									search
								>
									{(props) => (
										<Fragment>
											<div className="row">
												<div className="col-sm-12 col-md-6">
													<label>
														Show <SizePerPageDropdownStandalone {...paginationProps} />{' '}
														entries
													</label>
												</div>
												<div className="col-sm-12 col-md-6" style={{ textAlign: 'right' }}>
													<label>
														Search:{' '}
														<SearchBar
															className="form-control form-control-sm"
															{...props.searchProps}
														/>
													</label>
												</div>
											</div>
											<div className="row">
												<BootstrapTable
													wrapperClasses="table-responsive react-table-layout"
													classes="table table-striped cmv-table"
													bordered={false}
													noDataIndication="No data found"
													keyField="id"
													expandRow={expandRow}
													rowClasses={rowClasses}
													{...paginationTableProps}
													{...props.baseProps}
												/>
											</div>
											<div className="row" style={{ marginTop: '10px' }}>
												<div className="col-sm-12 col-md-5">
													<PaginationTotalStandalone {...paginationProps} />
												</div>
												<div className="col-sm-12 col-md-7">
													<PaginationListStandalone {...paginationProps} />
												</div>
											</div>
										</Fragment>
									)}
								</ToolkitProvider>
							</Fragment>
						)}
					</PaginationProvider>
				</div>
			</Fragment>
		);
	}
}

InstallmentPlans.propTypes = {
	accountInfo: PropTypes.object.isRequired
};

const loader = createLoadingSelector([ 'SET_INSTALLMENTPLAN' ]);

const mapStateToProps = (state) => {
	const installmentData = getAccountDetails(state).billing.installmentplans,
		installmentLoader = loader(state);
	return {
		installmentData,
		installmentLoader
	};
};

const mapDispatchToProps = {
	getInstallmentPlans,
	emptyInstallmentPlans,
};

export default connect(mapStateToProps, mapDispatchToProps)(InstallmentPlans);
