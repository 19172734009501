import moment from 'moment';
import {isEmpty} from '../../../../utils/helpers';

export const getFormattedFilters = (filters, value) => {
	let updatedFilters;

	updatedFilters = { ...filters };

	if ( value === 'completed') {
		updatedFilters['completed'] =  true;
	} else if ( value === 'incomplete' ) {
		updatedFilters['incomplete'] =  true;
	}

	if (updatedFilters['fromDate'] !== '') {
		updatedFilters['fromDate'] = moment(updatedFilters['fromDate']).format('YYYY-MM-DD');
	}

	if (updatedFilters['toDate'] !== '') {
		updatedFilters['toDate'] = moment(updatedFilters['toDate']).format('YYYY-MM-DD');
	}

	for (let filter in updatedFilters) {

		if (isEmpty(updatedFilters[filter])) {
			delete updatedFilters[filter];
		}
	}

	return updatedFilters;
};