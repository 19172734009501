import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import Loader from "../../components/Loader";
import {Formik} from "formik";
import isEmpty from "../../utils/helpers";
import TextInput from "../../components/UI/TextInput";
import {hideModal} from "../../actions/modal.actions";
import {getAdmin, getModalData } from "../../selectors";
import { getAutoPayJob, abortAutoPayJob } from "../../actions/autoPayJobs-actions";
import moment from "moment";

class AbortAutoPayJobModal extends Component {

    state = {
        alertMessage: '',
        autoPayJobsLoader: false,
        autoPayJob: null,
        selectedDate: null,
    };

    componentDidMount() {
        const {modalProps} = this.props;
        if(modalProps.value) {
            console.log(modalProps.value);
            this.props.getAutoPayJob(modalProps.value).then((response) => {
                this.setState({
                    autoPayJobsLoader: true,
                    autoPayJob: response
                })
            })
        }else{
            this.setState({
                autoPayJobsLoader: true,
                autoPayJob: null
            })
        }
    }

    componentWillUnmount() {
    }

    handleDateTimeChange = (newDateTime) => {
        this.setState({selectedDate: newDateTime});
    }

    formSubmit = (values, actions) => {
        const { selectedDate } = this.state;
        const submitData = {...values};

        this.setState({alertMessage: ''}, () => {
            this.props.abortAutoPayJob(submitData.id).then((response) => {
                if (!response.data) {
                    actions.setSubmitting(false);
                    this.setState({alertMessage: 'Unknown error. Please try again later'});
                    return;
                }

                if (!response.data.success) {
                    actions.setSubmitting(false);
                    this.setState({alertMessage: response.data.message});
                    return;
                }

                this.props.hideModal();
                this.props.modalProps.reloadFunction(true);
            });
        });
    }

    render() {
        const { alertMessage, autoPayJobsLoader, autoPayJob, selectedDate } = this.state;
        const { modalProps } = this.props;

        return (
            <Fragment>
                <div className="modal" style={{display: 'block'}} tabIndex="-1" role="dialog">
                    {!autoPayJobsLoader &&
                    <Loader />
                    }
                    {autoPayJobsLoader &&
                    <Formik
                        initialValues={{
                            id: autoPayJob && autoPayJob.id || null,
                            scheduledDate: autoPayJob &&  autoPayJob.scheduledDate || '',
                            notes: autoPayJob && autoPayJob.notes || null,
                            createdDate: autoPayJob && autoPayJob.createdDate || null,
                            createdBy: autoPayJob && autoPayJob.createdBy || null
                        }}
                        // validationSchema={this.systemUserSchema()}
                        onSubmit={this.formSubmit}
                        render={({
                                     handleChange,
                                     handleSubmit,
                                     handleBlur,
                                     values,
                                     errors,
                                     touched,
                                     isSubmitting,
                                     setFieldValue
                                 }) => (
                            <form onSubmit={handleSubmit}>
                                <div className="modal-dialog">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title">Abort AutoPay Job</h5>
                                            <button onClick={this.props.hideModal} type="button" className="close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div className="modal-body form-horizontal">
                                            {!isEmpty(alertMessage) && (
                                                <div className="alert alert-inline alert-danger alert-dismissible">
                                                    <p className="mb-0">{alertMessage}</p>
                                                </div>
                                            )}
                                            <div className="form-group">
                                                Are you sure you want to abort the job scheduled for {values.scheduledDate ? moment(values.scheduledDate).format(moment(values.scheduledDate).hour() === 0 ? "MM/DD/YYYY" : "MM/DD/YYYY @ h:00 A") : "Unknown Scheduled Date"}?
                                            </div>
                                        </div>
                                        <div className="modal-footer">
                                            <button onClick={this.props.hideModal} className="btn" type="button">
                                                Cancel
                                            </button>
                                            <button disabled={isSubmitting} type="submit" className="btn btn-primary">
                                                Abort AutoPay Job
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        )}
                    />}}
                </div>
                <div className="modal-backdrop show" tabIndex="1"/>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    const modalProps = getModalData(state).modalProps,
        autoPayJob = getAdmin(state).autoPayJob;

    return {
        modalProps,
        autoPayJob,
    };
};

const mapDispatchToProps = {
    hideModal,
    getAutoPayJob,
    abortAutoPayJob,
};

export default connect(mapStateToProps, mapDispatchToProps)(AbortAutoPayJobModal);