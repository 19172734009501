import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';

class Confirmation extends Component {
	startCurrentOrder = () => {
		const { newOrderInfo, startServiceOrder } = this.props;

		startServiceOrder(newOrderInfo.id);
	};

	render() {
		const {
			newOrderInfo,
			showNewOrderWizardHandler,
			resetWizard,
			resetForm,
			setFieldTouched,
			isOrderStartedData,
			tasksCountData
		} = this.props;

        var orderTypeText = 'Service order';

		return (
			<div className="tab-pane active">
				<p className="text-center text-success">
					<i className="far fa-4x fa-check-circle" />
				</p>
				<p className="text-center text-success lead">
					{orderTypeText}{' '}
					<span className="font-weight-bold">#{newOrderInfo.id}</span> updated successfully.
				</p>
			</div>
		);
	}
}

export default Confirmation;
