export const initialValues = {
	accountHolder: {
		accountType: null,
		firstName: '',
		lastName: '',
		company: '',
		mailingAddress: {
			address1: '',
			address2: '',
			address3: '',
			state: '',
			city: '',
			zip: '',
			zip4: null
		},
		middleName: '',
		securities: [
			{
				answer: '',
				questionId: ''
			},
			{
				answer: '',
				questionId: ''
			},
			{
				answer: '',
				questionId: ''
			}
		],
		securityPhrase: ''
	},
	accountTermsInfo: {
		accountTerm: '',
		accountTier: '',
		billCycle: ''
	},
	billingDetails: {
		showBillingAddress: false,
		showDiscounts: false,
		showRecurringCredits: false,
		showTaxExemptions: false
	},
	initialStatus: '',
	locationId: '',
};

export const addressValues = {
	address1: '',
	address2: '',
	address3: '',
	city: '',
	state: '',
	zip: '',
	zip4: null
};

export const getTransformedAccountTerms = (accountTerms) => {
	return accountTerms.map((term) => {
		return {
			value: term.id,
			label: term.description,
			manualTierSelector: true
		};
	});
};

export const getTransformedAccountTier = (accountTiers) => {
	return accountTiers.map((tier) => {
		return {
			value: tier.id,
			label: tier.description,
			deposit: tier.deposit
		};
	});
};

export const getTransformedCicle = (billCycles) => {
	return billCycles.split(',').map((cicle) => {
		let removeAsterix = cicle.replace('*', '');
		return {
			value: Number(removeAsterix),
			label: removeAsterix,
			isDefault: cicle.includes('*')
		};
	});
};

export const getTransformAccountTypes = (accountTypes) => {
	return accountTypes.map((type) => {
		return {
			value: type.id,
			label: type.description
		};
	});
};

export const getTransformCountryStates = (countryStates) => {
	return countryStates.map((state) => {
		return {
			value: state.abbreviation,
			label: state.name
		};
	});
};

export const getTransformQuestions = (securityQuestions) => {
	return securityQuestions.map((question) => {
		return {
			value: question.id,
			label: question.question
		};
	});
};

export const isEmpty = (obj) => {
	if (obj === undefined) {
		return true;
	}
	for (var key in obj) {
		if (obj.hasOwnProperty(key)) return false;
	}
	return true;
};

export const firstLetterToLower = (string) => {
	if (string === 'SSN') {
		return 'ssn';
	}
	return string.charAt(0).toLowerCase() + string.slice(1);
};

export const getEnabledFields = (response) => {
	let enabledFields = Object.keys(response).reduce((acc, type) => {
		if (type.includes('enable') && response[type] === true) {
			let removeEnable = firstLetterToLower(type.replace('enable', ''));
			acc[removeEnable] = '';
		}
		return acc;
	}, {});

	return enabledFields;
};

export const getRequiredFields = (response) => {
	let requiredFields = Object.keys(response).reduce((acc, type) => {
		if (type.includes('require') && response[type] === true) {
			let removeEnable = firstLetterToLower(type.replace('require', ''));
			acc[removeEnable] = removeEnable;
		}
		return acc;
	}, {});

	return requiredFields;
};

export const generateFullZip = (address) => {
	return address? ((address.zip? address.zip : '') + (isEmpty(address.zip4)? '' : '-' + address.zip4)) : '';
}

export const setServiceAddressAsBilling = (setFieldValue, setFieldTouched, address, enable) => {

	if (enable) {
		setFieldValue("accountHolder.mailingAddress.address1", address.address1 ? address.address1 : '');
		setFieldValue("accountHolder.mailingAddress.address2", address.address2 ? address.address2 : '');
		setFieldValue("accountHolder.mailingAddress.address3", address.address3 ? address.address3 : '');
		setFieldValue("accountHolder.mailingAddress.state", (address.systemState && address.systemState.abbreviation) ? address.systemState.abbreviation : '');
		setFieldValue("accountHolder.mailingAddress.city", address.city ? address.city : '');
		setFieldValue("accountHolder.mailingAddress.zip", address.zipcode ? address.zipcode : '');
		setFieldValue("accountHolder.mailingAddress.zip4", address.zip4 ? address.zip4 : '');
		setFieldValue("accountHolder.mailingAddress.latitude", address.latitude ? address.latitude : '');
		setFieldValue("accountHolder.mailingAddress.longitude", address.longitude ? address.longitude : '');
	}
	else {
		setFieldValue("accountHolder.mailingAddress.address1", "");
		setFieldValue("accountHolder.mailingAddress.address2", "");
		setFieldValue("accountHolder.mailingAddress.address3", "");
		setFieldValue("accountHolder.mailingAddress.state", "");
		setFieldValue("accountHolder.mailingAddress.city", "");
		setFieldValue("accountHolder.mailingAddress.zip", "");
		setFieldValue("accountHolder.mailingAddress.zip4", "");
		setFieldValue("accountHolder.mailingAddress.latitude", "");
		setFieldValue("accountHolder.mailingAddress.longitude", "");
		setFieldTouched("accountHolder.mailingAddress", false);
	}
}
