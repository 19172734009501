import {actionCreator, createRequestTypes} from "../utils/helpers";
import axiosInstance from "../utils/axios-instance";

export const GET_SERVICE_ORDER_NOTES = createRequestTypes('GET_SERVICE_ORDER_NOTES');
export const POST_SERVICE_ORDER_NOTE = createRequestTypes('POST_SERVICE_ORDER_NOTE');
export const DELETE_SERVICE_ORDER_NOTE = createRequestTypes('DELETE_SERVICE_ORDER_NOTE');

export const getServiceOrderNotes = (serviceOrderId) => async (dispatch) => {
    dispatch(actionCreator.request(GET_SERVICE_ORDER_NOTES));

    const apiUrl = `/order/serviceorder/${serviceOrderId}/notes`;

    try {
        const response = await axiosInstance.get(apiUrl);
        dispatch(actionCreator.success(GET_SERVICE_ORDER_NOTES, response.data));
        return response;
    } catch (err) {
        let mockError = {
            message: 'Something went wrong'
        }
        dispatch(actionCreator.failure(GET_SERVICE_ORDER_NOTES, mockError));
        return err.response;
    }
};

export const postServiceOrderNote = (serviceOrderId, note) => async (dispatch) => {
    dispatch(actionCreator.request(POST_SERVICE_ORDER_NOTE));
    let serviceOrderNoteRequest = {
        note: note
    }
    try {
        const response = await axiosInstance.post(`/order/serviceorder/${serviceOrderId}/note`, serviceOrderNoteRequest);
        dispatch(actionCreator.success(POST_SERVICE_ORDER_NOTE, response.data));
        return response;
    } catch (err) {
        let mockError = {
            message: 'Something went wrong'
        }
        dispatch(actionCreator.failure(POST_SERVICE_ORDER_NOTE, mockError));
        return err.response;
    }
};

export const deleteServiceOrderNote = (serviceOrderId, noteId) => async (dispatch) => {
    dispatch(actionCreator.request(DELETE_SERVICE_ORDER_NOTE));

    const apiUrl = `/order/serviceorder/${serviceOrderId}/note/${noteId}`;

    try {
        const response = await axiosInstance.delete(apiUrl);
        dispatch(actionCreator.success(DELETE_SERVICE_ORDER_NOTE, response.data));
        return response;
    } catch (err) {
        let mockError = {
            message: 'Something went wrong'
        }
        dispatch(actionCreator.failure(DELETE_SERVICE_ORDER_NOTE, mockError));
        return err.response;
    }
};