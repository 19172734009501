import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store';
import { createBrowserHistory } from 'history';
// import history from './utils/history';
import ReactGA from 'react-ga';
// import './styles.scss';
import ReduxToastr from 'react-redux-toastr';
import App from './App';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-datepicker/dist/react-datepicker.css';
import './resources/styles/bootstrap/bootstrap.scss';
import './resources/styles/bootstrap/bootstrap-grid.scss';
import './resources/styles/bootstrap/bootstrap-reboot.scss';
import './resources/styles/components/index.scss';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';

import * as serviceWorker from './serviceWorker';

const history = createBrowserHistory();

ReactGA.initialize(window.googleAnalytics);

history.listen((location) => {
	ReactGA.set({ page: location.pathname });
	ReactGA.pageview(location.pathname);
});

ReactDOM.render(
	<Provider store={store}>
		<BrowserRouter history={history}>
			<ReduxToastr />
			<App />
		</BrowserRouter>
	</Provider>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
