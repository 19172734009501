import React, { Component, Fragment } from 'react';
import ProductsServiceline from './ProductsServiceline';

export class ProductsPlan extends Component {
	render() {
		const { planInfo } = this.props;
		
		return (
			<Fragment>
				<span className="accordion-section-header">
					<i className={planInfo.serviceModel.icon} />&nbsp;{planInfo.description}
				</span>
				<div className="plan-wrapper" data-plan-wrapper-id="pl001">
					<ProductsServiceline {...this.props} />
				</div>
			</Fragment>
		);
	}
}

export default ProductsPlan;
