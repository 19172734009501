import React, { Component, Fragment } from 'react';
import { hideModal } from '../../../actions/modal.actions';
import { changeAccountStatus } from '../../../actions/accountDetailsActions/accountStatusChange.actions';
import { connect } from 'react-redux';
import {createLoadingSelector, getModalData} from '../../../selectors';
import {triggerServiceLineStatusTransition} from "../../../actions/serviceLine.actions";

class ServiceLineStatusChangeModal extends Component {

    closeModal = () => {
        this.props.hideModal()
    }

    onServiceLineStatusChangeClickHandler = () => {
        const { accountId, serviceLineId, statusTransition, onSuccess, onFail } = this.props.modalProps;

        this.props.triggerServiceLineStatusTransition(accountId, serviceLineId, statusTransition.toStatus)
            .then(response => {
                if (response.data && response.data.success) {
                    if (onSuccess) {
                        onSuccess();
                        this.closeModal();
                    }
                }

                if (onFail) {
                    onFail(response);
                }
            }).catch(ex => {
                if (onFail) {
                    onFail(ex);
                }

                console.error(ex);
            });
    }

    render() {
        const { statusTransition } = this.props.modalProps;
        const { changeServiceLineStatusLoader } = this.props;

        return (
            <Fragment>
                <div className="modal" style={{ display: 'block' }} tabIndex="-1" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">
                                    {statusTransition.description} Service Line
								</h5>
                            </div>
                            <div className="modal-body form-horizontal">
                                You are about to update the service line status from {statusTransition.fromStatus} to {statusTransition.toStatus}
                            </div>
                            <div className="modal-footer">
                                <button onClick={this.closeModal} className="btn">
                                    CANCEL
								</button>
                                <button
                                    onClick={this.onServiceLineStatusChangeClickHandler}
                                    type="submit"
                                    className="btn btn-primary">
                                    CONTINUE
                                    {changeServiceLineStatusLoader &&
                                        <i className="fas fa-fw fa-spin fa-spinner" style={{marginLeft: '5px'}}/>}
								</button>

                            </div>
                        </div>
                    </div>
				</div>
                <div className="modal-backdrop show" tabIndex="1" />
            </Fragment>
        )
    }

}

const changeServiceLineStatusLoaderSelector = createLoadingSelector(['TRIGGER_SERVICE_LINE_STATUS_TRANSITION']);

const mapStateToProps = (state) => {
    const modalProps = getModalData(state).modalProps;
    const changeServiceLineStatusLoader = changeServiceLineStatusLoaderSelector(state);
    return {
        modalProps,
        changeServiceLineStatusLoader
    }
};

const mapDispatchToProps = {
    changeAccountStatus,
    triggerServiceLineStatusTransition,
    hideModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(ServiceLineStatusChangeModal);