import React, {Component} from 'react';
import {connect} from 'react-redux';
import {getTasksByServiceOrder, getTasksByTroubleTicketId, emptyTasksList} from '../../../../../../../actions/tasksList.actions';
import {getSystemUsers, emptySystemUsers} from '../../../../../../../actions/dashboardActions/tasks.actions';
import {assignTask, unassignTask} from '../../../../../../../actions/tasksList.actions';
import {getUser, getDashboardData} from '../../../../../../../selectors';
import TasksResults from '../../../../../../Dashboard/Content/Tasks/TasksResults';
import isEmpty from '../../../../../../../utils/helpers';

export class Tasks extends Component {

	state = {
		tasksListData: null,
		isLoading: true,
		showTaskDropDownById: '',
		isFetched: false,
	};

	componentDidMount() {

		// Load Service Order Tasks
		this.loadTasks();

		// Get first name and last name for the assign in dropdown
		if (!isEmpty(this.props.user.userGroups)) {
			let userGroups = this.props.user.userGroups.map((userGroup) => userGroup.name).join(',');
			let systemUsersGroups = {userGroups: userGroups};
			this.props.getSystemUsers(systemUsersGroups);
		}
	}

	componentWillUnmount() {
		this.props.emptySystemUsers();
		this.props.emptyTasksList();
	}

	loadTasks = () => {
		const {serviceOrderId, troubleTicketId} = this.props;

		// Load service order tasks
		if (!isEmpty(serviceOrderId)) {

			this.props.getTasksByServiceOrder(serviceOrderId).then(response => {

				this.setState({
					tasksListData: response,
					isLoading: false,
				});
			});
		}

		// Load trouble ticket tasks
		if (!isEmpty(troubleTicketId)) {

			this.props.getTasksByTroubleTicketId(troubleTicketId).then(response => {

				this.setState({
					tasksListData: response,
					isLoading: false,
				});
			});
		}
	}

	handleShowHideDropdown = (id) => {
		this.setState({
			showTaskDropDownById: this.state.showTaskDropDownById === id ? '' : id
		});
	};

	handleAssignTaskToUsername = (type, taskId, dropdownValue) => {
		const {serviceOrderId, user, assignTask, unassignTask} = this.props;

		this.setState({isLoading: true});

		let apiCallParams = serviceOrderId;

		if (type === 'claim') {
			const data = {taskId, username: user.username};
			assignTask(data, apiCallParams, 'serviceOrderId', false).then(() => {
				this.loadTasks();
			});
		}
		else if (type === 'unassign') {
			const data = {taskId};
			unassignTask(data, apiCallParams, 'serviceOrderId', false).then(() => {
				this.loadTasks();
			});
		}
		else if (type === 'username') {
			const data = {taskId, username: dropdownValue.value};
			assignTask(data, apiCallParams, 'serviceOrderId', false).then(() => {
				this.loadTasks();
			});
		}

		this.setState({showTaskDropDownById: ''});
	};

	render() {
		const {tasksListData, isLoading, showTaskDropDownById, isFetched} = this.state;
		const {systemUsersData} = this.props;

		return (
			<div className="cmv-container cmv-container-user-tasks">

				<TasksResults
					userTasksData={tasksListData}
					systemUsersData={systemUsersData}
					taskLoader={isLoading}
					isFetched={isFetched}
					showTaskDropDownById={showTaskDropDownById}
					showOrderDetails={false}
					handleShowHideDropdown={this.handleShowHideDropdown}
					handleAssignTaskToUsername={this.handleAssignTaskToUsername}
					accountId={this.props.accountId}
					order={this.props.order}
					serviceInfo={this.props.serviceInfo}
					serviceOrderId={this.props.serviceOrderId}
					troubleTicketId={this.props.troubleTicketId}
					history={this.props.history}
				/>

			</div>
		);
	}
}

const mapStateToProps = (state) => {

	const systemUsersData = getDashboardData(state).systemUsers;
	const user = getUser(state).user;

	return {
		systemUsersData,
		user,
	};
};

const mapDispatchToProps = {
	getTasksByServiceOrder,
	getTasksByTroubleTicketId,
	emptyTasksList,
	getSystemUsers,
	emptySystemUsers,
	assignTask,
	unassignTask,
};

export default connect(mapStateToProps, mapDispatchToProps)(Tasks);
