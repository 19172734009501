import React, {Component, Fragment} from 'react';
import { connect } from 'react-redux';
import {formSelectStyles, groupStyles} from "../../../utils/SelectStyles";
import Select from "react-select";
import {get, isEmpty} from "lodash";
import * as Yup from 'yup';
import Loader from "../../../components/Loader";
import {
    getInterfaces,
    getServiceModels,
    submitProvisioning
} from "../../../actions/provisioning.actions";
import {Form, Formik} from "formik";
import StepActions from "../../../camvio-wizard/StepActions";
import {createLoadingSelector, getUser} from "../../../selectors";
import {toastr} from "react-redux-toastr";
import {getErrorMessage} from "../../../utils/helpers";
import ReactHtmlParser from 'react-html-parser';
import {getNumberCategories, getInventoryNumbers} from "../../../actions/domain.actions";

const PROVISIONING_STATUS = {
    IN_PROGRESS: 'IN_PROGRESS',
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL'
}

const createValidationSchema = (props) => Yup.object().shape({
    serviceModelId: Yup.number().nullable().required("Required").positive("Required"),
    directoryNumber: Yup.string().nullable().required("Required").min(1, "Required"),
})

const initialValues = {
    interfaces: [],
}

const initialState = {
    showDirectoryNumSearch: false,
    selectedNumberCategoryId: -1,
    inventoryNumbers: [],
    selectedInventoryNumber: '',
}

class ServiceQuery extends Component {

    constructor(props) {
        super(props);

        this.state = { ...initialState };
    }

    componentDidMount() {
        const { serviceModels, provisioningInterfaces } = this.props.stepState;

        if (!provisioningInterfaces) {
            this.loadInterfaces();
        }

        if (!serviceModels) {
            this.loadServiceModels();
        }
    }

    loadServiceModels = () => {
        const { getServiceModels, setStepState } = this.props;

        setStepState({loadingServiceModels: true});

        getServiceModels().then(resp => {
            if (resp && resp.data && resp.data.success) {
                setStepState({
                    serviceModels: resp.data.serviceModels || [],
                    loadingServiceModels: false,
                });
            } else {
                setStepState({
                    loadingServiceModels: false,
                })
                toastr.error(getErrorMessage(resp).message, { timeOut: 3000, position: 'top-center' });
            }
        }).catch(error => {
            setStepState({
                loadingServiceModels: false,
            })
            toastr.error(getErrorMessage(error).message, { timeOut: 3000, position: 'top-center' });
            console.error(error);
        });
    }

    onServiceModelSelectHandler = (option, formProps) => {
        const { currentLocation, address } = this.props;
        const { setFieldValue } = formProps;

        const serviceModelId = option.value;

        setFieldValue('serviceModelId', serviceModelId);
        this.loadNumberCategories(serviceModelId, currentLocation.id);
    }

    renderServiceModelSelect = (formProps) => {
        const { serviceModels } = this.props.stepState;
        const { errors, values } = formProps;
        const { serviceModelId } = values;
        const options = [];

        if (serviceModels) {
            serviceModels.forEach(serviceModel => {
                options.push({
                    label: serviceModel.name + (serviceModel.description? ' ('  + serviceModel.description + ')' : ''),
                    value: serviceModel.id
                });
            });
        }

        const selectedServiceModelOption = options.find(opt => opt.value === serviceModelId);

        return (
            <div className="row">
                <div id="service-model" className="form-group col-sm-3">
                    <label>Service Model</label>
                    <Select
                        name='serviceModelId'
                        value={selectedServiceModelOption}
                        onChange={(option) => this.onServiceModelSelectHandler(option, formProps)}
                        options={options}
                        placeholder="Select Service Model"
                        styles={groupStyles}
                        {...formProps}
                    />
                    {errors.serviceModelId &&
                    <div className="invalid-feedback">{errors.serviceModelId}</div>
                    }
                </div>
            </div>
        );
    }

    loadNumberCategories = (serviceModelId, locationId) => {
        const { setStepState, getNumberCategories } = this.props;

        this.setState({
            selectedNumberCategoryId: null,
            inventoryNumbers: [],
            selectedInventoryNumber: '',
            showDirectoryNumSearch: false,
        });

        setStepState({
            numberCategories: [],
            loadingNumberCategories: true,
        })

        getNumberCategories(serviceModelId, locationId).then((resp) => {
            setStepState({
                numberCategories: resp.data || [],
                loadingNumberCategories: false,
            })
        }).catch(err => {
            setStepState({
                loadingNumberCategories: false,
            })
            toastr.error(getErrorMessage(err).message, { timeOut: 3000, position: 'top-center' });
            console.error(err);
        });
    }

    showHideDirectoryNumSearch = () => {
        this.setState(prevState => {
            return {
                showDirectoryNumSearch: !prevState.showDirectoryNumSearch
            }
        });
    }

    handleCategoryChange = (option, { values }) => {
        const categoryId = option.value;
        const { serviceModelId } = values;

        this.setState({
            selectedNumberCategoryId: categoryId,
        }, () => this.loadInventoryNumbers(serviceModelId));
    };

    loadInventoryNumbers = (serviceModelId, number) => {
        const { selectedNumberCategoryId } = this.state;
        const { getInventoryNumbers } = this.props;

        this.setState({
            inventoryNumbers: [],
            selectedInventoryNumber: '',
            loadingInventoryNumbers: true,
        }, () => {
            getInventoryNumbers({serviceModelId, categoryId: selectedNumberCategoryId, number, status: ['ASSIGNED', 'UNASSIGNED']}).then((response) => {
                const numbers = response.data;

                this.setState({
                    inventoryNumbers: numbers,
                    selectedInventoryNumber: Array.isArray(numbers) && numbers[0] && numbers[0].number || '',
                    loadingInventoryNumbers: false,
                })
            }).catch(err => {
                toastr.error(getErrorMessage(err).message, { timeOut: 3000, position: 'top-center' });
                console.error(err);
                this.setState({
                    inventoryNumbers: [],
                    selectedInventoryNumber: '',
                    loadingInventoryNumbers: false,
                })
            });
        })
    }

    handleInventoryNumberChange = (option) => {
        const number = option.value;

        this.setState({
            selectedInventoryNumber: number,
        })
    };

    handleInventoryNumberSelectCancel = () => {
        this.setState({
            showDirectoryNumSearch: false,
        })
    }

    handleInventoryNumberSelectSubmit = ({setFieldValue}) => {
        setFieldValue('directoryNumber', this.state.selectedInventoryNumber);

        this.setState({
            showDirectoryNumSearch: false,
        })
    }

    createNumberChangeHandler = ({values}) => (value, { action }) => {
        const categoryId = value;
        const { serviceModelId } = values;

        this.setState({ searchNumber: value }, () => {
            if (this.numberChangeTimeout) {
                clearTimeout(this.numberChangeTimeout);
            }

            this.numberChangeTimeout = setTimeout(() => {
                if (!isEmpty(this.state.searchNumber)) {
                    this.loadInventoryNumbers(serviceModelId, categoryId, this.state.searchNumber);
                }
            }, 500);
        });
    };

    renderDirectoryNumber = (formProps) => {
        const { selectedNumberCategoryId, inventoryNumbers, showDirectoryNumSearch, selectedInventoryNumber, loadingInventoryNumbers } = this.state;
        const { values, handleChange, handleBlur, errors, touched } = formProps;
        const { directoryNumber, serviceModelId } = values;
        const { numberCategories, loadingNumberCategories, serviceModels = [] } = this.props.stepState;

        const serviceModel = serviceModels.find(sm => sm.id === serviceModelId);

        const numberCategoryOptions = [];
        if (numberCategories) {
            numberCategories.forEach(category => numberCategoryOptions.push({
                label: category.description,
                value: category.id
            }));
        }
        const selectedCategoryOption = numberCategoryOptions.find(catOpt => catOpt.value === selectedNumberCategoryId);

        const inventoryNumberOptions = [];
        if (inventoryNumbers) {
            inventoryNumbers.forEach(i => inventoryNumberOptions.push({
                label: i.number,
                value: i.number
            }));
        }
        const selectedInventoryNumberOption = inventoryNumberOptions.find(numOpt => numOpt.value === selectedInventoryNumber) || null;
        const dnName = get(serviceModel, 'systemNumType.description', 'DN');
        return (
            <>
                <div className="row">
                    <div className="form-group col-12 col-sm-3">
                        <label>{dnName}</label>
                        <div className="input-group input-group-sm">
                            <input
                                type="text"
                                name="directoryNumber"
                                className="form-control"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={directoryNumber}
                                disabled={this.isSubmitting() || !serviceModel}
                            />
                            <div className="input-group-append">
                                {loadingNumberCategories &&
                                    <div className="spinner-border text-primary" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                ||
                                    <button
                                        type="button"
                                        className="btn btn-primary btn-search-line"
                                        onClick={this.showHideDirectoryNumSearch}
                                        disabled={this.isSubmitting() || !serviceModel}
                                    >
                                        <i className="fas fa-ellipsis-h"/>
                                        <span className="d-md-none">&nbsp;Number</span>
                                    </button>
                                }
                            </div>
                        </div>
                        {errors.directoryNumber && (
                            <small className="form-text text-danger text-left">
                                {errors.directoryNumber}
                            </small>
                        )}
                    </div>
                </div>

                {showDirectoryNumSearch
                && !this.isSubmitting() &&
                <div className="form-group form-row">
                    <div className="col-12 col-sm-3">
                        <div className="cmv-container-subpanel cmv-container-subpanel-dirnum">
                            <h5>
                                <i className="fas fa-database"/> Select {dnName}
                            </h5>
                            <div className="form-group">
                                <label>Directory Type</label>
                                <Select
                                    name="numberCategoryId"
                                    value={selectedCategoryOption}
                                    onChange={(option) => this.handleCategoryChange(option, formProps)}
                                    placeholder="Directory Type"
                                    styles={groupStyles}
                                    options={numberCategoryOptions}
                                />
                            </div>

                            <div className="form-group">
                                <label>Available Numbers</label>
                                <Select
                                    name="inventoryNumber"
                                    value={selectedInventoryNumberOption}
                                    onChange={this.handleInventoryNumberChange}
                                    onInputChange={this.createNumberChangeHandler(formProps)}
                                    placeholder="Number"
                                    styles={groupStyles}
                                    options={inventoryNumberOptions}
                                    isLoading={loadingInventoryNumbers}
                                />
                            </div>
                            <div className="d-flex justify-content-end mt-1">
                                <button
                                    onClick={this.handleInventoryNumberSelectCancel}
                                    className="btn btn-outline-primary btn-dn-select-cancel mr-1"
                                    type="button"
                                >
                                    Cancel
                                </button>

                                <button
                                    onClick={() => this.handleInventoryNumberSelectSubmit(formProps)}
                                    className="btn btn-primary btn-dn-select-accept"
                                    type="button"
                                >
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                }
            </>);
    }

    loadInterfaces = () => {
        const { getInterfaces, provisioningAction, setStepState } = this.props;

        getInterfaces(provisioningAction.actionType).then((resp) => {
            if (resp.data && resp.data.success) {
                setStepState({
                    provisioningInterfaces: resp.data.provisioningInterfaces.map(pi => ({
                        ...pi,
                        selected: false,
                    })),
                    allInterfacesSelected: true,
                })
            } else {
                toastr.error(getErrorMessage(resp).message, { timeOut: 3000, position: 'top-center' });
            }
        }).catch(error => {
            toastr.error(getErrorMessage(error).message, { timeOut: 3000, position: 'top-center' });
            console.error(error);
        });
    }

    toggleAllInterfaces = () => {
        this.props.setStepState((prevProps) => ({
            allInterfacesSelected: !prevProps.allInterfacesSelected
        }));
    }

    renderInterfaces = (formProps) => {
        const { isLoadingInterfaces, setStepState } = this.props;
        const { provisioningInterfaces = [], allInterfacesSelected } = this.props.stepState;

        if (isLoadingInterfaces) {
            return (
                <>
                    <h4>Provisioning interfaces</h4>
                    <Loader/>
                </>)
        }

        return <>
            {!provisioningInterfaces.length && (
                <span className="form-text text-danger text-left">
                    No provisioning interfaces found
                </span>
            ) ||
            (
                <>
                    <div className="form-check checkbox-slider checkbox-slider--b-flat">
                        <label>
                            <input
                                type="checkbox"
                                name={`allInterfacesSelected`}
                                value={allInterfacesSelected}
                                checked={allInterfacesSelected === true}
                                onChange={this.toggleAllInterfaces}
                                disabled={this.isSubmitting()}
                            />
                            <span>All Provisioning Interfaces</span>
                        </label>
                    </div>
                </>
            )}
            {!allInterfacesSelected && provisioningInterfaces.map((provInterface, index) => {
                return (
                    <div className="form-check checkbox-slider checkbox-slider--b-flat" key={index}>
                        <label>
                            <input
                                type="checkbox"
                                value={provInterface.selected}
                                checked={provInterface.selected}
                                disabled={this.isSubmitting()}
                                onChange={val => setStepState({
                                    provisioningInterfaces: provisioningInterfaces.map(pi => {
                                        if (pi.id === provInterface.id) {
                                            return {
                                                ...pi,
                                                selected: !provInterface.selected,
                                            }
                                        }

                                        return pi;
                                    }),
                                })}
                            />
                            <span>{provInterface.description}</span>
                        </label>
                    </div>
                )
            })}
        </>
    }

    getSelectedInterfaces = () => {
        const { provisioningInterfaces = [], allInterfacesSelected } = this.props.stepState;

        return provisioningInterfaces
            .filter(provInterface => allInterfacesSelected || provInterface.selected)
            .map(provInterface => provInterface.name);
    }

    createValuesBundle = ({ provisioningGroupId }) => {
        return {
            provisioningGroupId,
        };
    }

    createFormValuesBundle = (values) => ({
        ...values,
    })

    handleNext = (values) => {
        const { next } = this.props;
        if (!next) {
            return;
        }

        next({
            values: this.createValuesBundle(values),
            formValues: this.createFormValuesBundle(values),
        });
    }

    handlePrevious = (values) => {
        const { previous } = this.props;
        if (!previous) {
            return;
        }

        previous({
            formValues: this.createFormValuesBundle(values),
        });
    }

    handleOnSubmit = (values, actions) => {
        const { provisioningAction, setStepState, submitProvisioning } = this.props;
        const { directoryNumber } = values;

        if (this.isSubmitting()) {
            return;
        }

        setStepState({ provisioningStatus: PROVISIONING_STATUS.IN_PROGRESS });

        submitProvisioning(directoryNumber, provisioningAction.value, {
            interfaces: this.getSelectedInterfaces(),
        }).then(resp => {
            if (resp.success || resp.data.success) {
                const message = resp.message || resp.data.message || 'Query submitted successfully';
                toastr.success(`(groupId: ${resp.data.provisioningGroupId}) - ${message}`, { timeOut: 5000, position: 'top-center' });

                setStepState({ provisioningStatus: PROVISIONING_STATUS.SUCCESS });
                this.handleNext({...values, provisioningGroupId: resp.data.provisioningGroupId});
            } else {
                toastr.error(getErrorMessage(resp).message, {timeOut: 5000, position: 'top-center'});
                setStepState({ provisioningStatus: PROVISIONING_STATUS.FAIL });
            }
        }).catch(error => {
            toastr.error(getErrorMessage(error).message, { timeOut: 5000, position: 'top-center' });
            setStepState({ provisioningStatus: PROVISIONING_STATUS.FAIL });
        })
    }

    isSubmitting = () => {
        return this.props.stepState.provisioningStatus === PROVISIONING_STATUS.IN_PROGRESS;
    }

    renderContent = (formProps) => {

        return (
            <>
                <span className="accordion-section-header">
					<i className="fas fa-search" />&nbsp;Query
				</span>

                {this.renderServiceModelSelect(formProps)}
                {this.renderDirectoryNumber(formProps)}
                {this.renderInterfaces(formProps)}
            </>
        );
    }

    render() {
        const { stepFormValues, previous, next, stepState } = this.props;
        const { provisioningInterfaces = [] } = stepState;

        return (
            <Formik
                validationSchema={createValidationSchema(this.props)}
                onSubmit={this.handleOnSubmit}
                initialValues={isEmpty(stepFormValues)? initialValues : stepFormValues}
                render={(formProps) => (
                    <Form onSubmit={formProps.handleSubmit} className="cmv-form" autoComplete="off">

                        {this.renderContent(formProps)}

                        {!this.isSubmitting() &&
                        <StepActions
                            previous={previous && (() => this.handlePrevious(formProps.values))}
                            next={next && provisioningInterfaces.length && formProps.submitForm}
                            nextBtnName="Submit"/>
                        }
                    </Form>
                )}
            />
        );
    }
}

const selectInterfacesLoading = createLoadingSelector(['@provisioning/INTERFACES']);

const mapStateToProps = (state, props) => {
    const provisioningAction = props.accumulatedValues.provisioningAction,
        isLoadingInterfaces = selectInterfacesLoading(state),
        currentLocation = getUser(state).currentLocation;

    return {
        provisioningAction,
        isLoadingInterfaces,
        currentLocation,
    };
};

const mapDispatchToProps = {
    getServiceModels,
    getNumberCategories,
    getInventoryNumbers,
    getInterfaces,
    submitProvisioning,
};

export default connect(mapStateToProps, mapDispatchToProps)(ServiceQuery);