import React, { Component, Fragment } from 'react';
import SearchBar from './SearchBar';
import AccountsList from './AccountsList';
import Loader from '../../components/Loader';
import { createLoadingSelector, getAccountSearch } from '../../selectors';
import { connect } from 'react-redux';
import { getAccounts, emptyAccounts } from '../../actions/account-search';

class AccountSearch extends Component {
	state = {
		searchType: {
			label: 'Serviceline',
			value: 'SERVICELINE'
		},
		placeholder: 'Search for Customer by Serviceline',
		term: '',
		isFetched: false
	};

	componentDidUpdate(prevProps){
		if(prevProps.accountsData !== this.props.accountsData){
			this.setState({
				isFetched: true
			})
		}
	}
	componentWillUnmount() {
		this.props.emptyAccounts();
	}

	searchAccounts = () => {
		this.props.getAccounts(this.state.searchType.value, this.state.term);
	};

	changeSearchType = (type) => {
		this.setState({
			searchType: {
				value: type.value,
				label: type.label
			},
			placeholder: type.placeholder
		});
	};

	changeTerm = (e) => {
		this.setState({
			term: e.target.value
		});
	};

	render() {
		const { searchType, term, isFetched, placeholder } = this.state;
		const { isAccountsLoading, accountsData } = this.props;

		return (
			<Fragment>
				<SearchBar
					searchAccounts={this.searchAccounts}
					changeSearchType={this.changeSearchType}
					changeTerm={this.changeTerm}
					searchType={searchType}
					term={term}
					placeholder={placeholder}
				/>
				{isAccountsLoading ? <Loader /> : <AccountsList accounts={accountsData} isFetched={isFetched} {...this.props} />}
			</Fragment>
		);
	}
}


const accountSearchLoader = createLoadingSelector([ 'ACCOUNT_SEARCH' ]);

const mapStateToProps = (state) => {
	const accountsData = getAccountSearch(state).accounts,
		isAccountsLoading = accountSearchLoader(state);

	return {
		accountsData,
		isAccountsLoading
	};
};

const mapDispatchToProps = {
	getAccounts,
	emptyAccounts
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountSearch);
