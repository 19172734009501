import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { hideModal } from '../../../actions/modal.actions';
import {
    deleteSystemUserAccessToken, generateToken, clearGeneratedToken, getSystemUserAccessTokens,
    clearSystemUserAccessTokens, addSystemUserAccessToken, ADD_SYSTEM_USER_ACCESS_TOKEN
} from '../../../actions/admin.actions';
import { clearErrors } from "../../../actions/error.actions";
import {createLoadingSelector, getAdmin, getErrorMessage, getModalData} from "../../../selectors";
import { toastr } from 'react-redux-toastr';
import Loader from '../../../components/Loader';

import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import moment from "moment";
import isEmpty, {actionCreator} from "../../../utils/helpers";



const extractErrorMessage = (error) => {
    if (error.message) {
        return error.message;
    }

    if (error.data && error.data.error && error.data.error.message) {
        return error.data.error.message;
    }

    return "Something went wrong";
}


class ManageSystemUserAccessTokensModal extends Component {

    tokenDisplayFormatter = (cell, row, rowIndex) => {
        return (
            <Fragment>
            <span className="td-subtitle">
                **************************{row.tokenDisplay}
            </span>
            </Fragment>
        );
    };

    tokenAgeFormatter = (cell, row, rowIndex) => {
        const now = moment(new Date());
        const createdTime = moment(new Date(row.createdDateTime));
        const duration = moment.duration(now.diff(createdTime));
        const ageInDays = duration.asDays().toFixed();

        return (
            <Fragment>
            <span className="td-subtitle">
                {ageInDays} Day{+ageInDays === 1? '' : 's' }
            </span>
            </Fragment>
        );
    };

    actionsFormatter = (cell, row, rowIndex) => (
        <button
            onClick={() => this.showConfirmDelete(row)}
            className="btn"
            type="button">
            <i className="fas fa-trash"/>
        </button>
    );

    columns = [
        {
            dataField: 'tokenId',
            text: 'API Token',
            formatter: this.tokenDisplayFormatter
        },
        {
            dataField: 'createdDateTime',
            text: 'Age',
            formatter: this.tokenAgeFormatter
        },
        {
            dataField: 'actions',
            text: 'Actions',
            formatter: this.actionsFormatter,
            align: 'right',
            headerAlign: 'right',
        },
    ];

    componentDidMount() {
        let systemUserId = this.props.modalProps && this.props.modalProps.value || "";
        this.props.clearGeneratedToken();
        this.props.getSystemUserAccessTokens(systemUserId);
    }

    componentWillUnmount() {
        this.props.clearGeneratedToken();
        this.props.clearSystemUserAccessTokens();
        this.props.clearErrors();
    }

    deleteAccessToken = (token) => {
        this.props.clearErrors();
        let systemUserId = this.props.modalProps && this.props.modalProps.value || "";

        this.props.deleteSystemUserAccessToken(systemUserId, token.tokenId).then(result => {
            toastr.success(`token ****${token.tokenDisplay} deleted successfully`, { timeOut: 1000, position: 'top-center' });
            this.reloadAccessTokens();
        }).catch(error => {
            const errorMessage = extractErrorMessage(error);
            toastr.error(errorMessage, { timeOut: 1000, position: 'top-center' });
        });
    };

    showConfirmDelete = (token) => {
        toastr.confirm(
            <div>Are you sure you want to delete token ******{token.tokenDisplay}?</div>,
            {
                okText: "Yes",
                cancelText: "No",
                onOk: () => this.deleteAccessToken(token),
                onCancel: () => {},
            });
    };

    reloadAccessTokens = () => {
       let systemUserId = this.props.modalProps && this.props.modalProps.value || "";

        this.props.getSystemUserAccessTokens(systemUserId);
    };

    copyToClipboard = (event) => {
        if (isEmpty(this.props.adminProps.generatedToken)) {
            return;
        }

        this.tokenInput.select();
        document.execCommand('copy');
        event.target.focus();
        toastr.info("Copied to Clipboard", { timeOut: 1000, position: 'top-center' });
    };

    generateToken = () => {
        this.props.clearErrors();
        this.props.generateToken();
    };

    closeModal = () => {
        this.props.hideModal();
        this.props.modalProps.reloadFunction(true);
    };

    save = () => {
        this.props.clearErrors();

        let systemUserId = this.props.modalProps && this.props.modalProps.value || "";
        const { generatedToken : token } = this.props.adminProps;

        this.props.addSystemUserAccessToken(systemUserId, token).then(result => {
            toastr.success("Successfully saved", { timeOut: 1000, position: 'top-center' });
            this.props.hideModal();
            this.props.modalProps.reloadFunction(true);
        }).catch(error => {
            const errorMessage = extractErrorMessage(error);
            toastr.error(errorMessage, { timeOut: 1000, position: 'top-center' });
        });
    };

    render() {
        const { generatedToken, systemUserAccessTokens } = this.props.adminProps;
        const { accessTokensLoader, loadingTokensError, tokenGenerationError, tokenDeleteError, addTokenError} = this.props;

        return (
            <Fragment>
                <div className="modal" style={{ display: 'block' }} tabIndex="-1" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Manage API Tokens</h5>
                                <button type="button" className="close" onClick={this.closeModal}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="cmv-container-table">
                                    <div className="row">
                                        {tokenDeleteError && (
                                            <div className="col">
                                                <div className="alert alert-inline alert-danger alert-dismissible">
                                                    <p className="mb-0">{tokenDeleteError}</p>
                                                </div>
                                            </div>
                                        )}

                                        {loadingTokensError ? (
                                            <div className="col">
                                                <div className="alert alert-danger" role="alert">
                                                    {loadingTokensError}
                                                </div>
                                            </div>
                                            ) : (
                                            <ToolkitProvider
                                                bootstrap4
                                                keyField="tokenId"
                                                data={systemUserAccessTokens}
                                                columns={this.columns}>

                                                {(props) =>
                                                    <Fragment>
                                                        {accessTokensLoader ? (
                                                            <div className="col">
                                                                <Loader/>
                                                            </div>
                                                        ) : (
                                                            <BootstrapTable
                                                                wrapperClasses="table-responsive react-table-layout"
                                                                classes="table table-striped cmv-table"
                                                                bordered={false}
                                                                noDataIndication="No tokens"
                                                                {...props.baseProps} />
                                                        )}
                                                    </Fragment>
                                                }
                                            </ToolkitProvider>
                                        )}
                                    </div>
                                </div>


                                {!loadingTokensError && (
                                    <div className="input-group  mb-3">
                                        <div className="input-group-prepend">
                                            <button className="btn btn-outline-secondary"
                                                    type="button"
                                                    onClick={this.generateToken}>
                                                Generate Token
                                            </button>
                                        </div>

                                        <input type="text" className="form-control" value={generatedToken} readOnly
                                               ref={(tokenInput) => this.tokenInput = tokenInput} />

                                        <div className="input-group-append" onClick={this.copyToClipboard}>
                                            <button className="btn btn-outline-secondary" type="button"
                                                    disabled={isEmpty(generatedToken)}>
                                                <i className="far fa-clone" />
                                            </button>
                                        </div>
                                    </div>
                                )}

                                {(tokenGenerationError || addTokenError) && (
                                    <div className="alert alert-inline alert-danger alert-dismissible">
                                        <p className="mb-0">{tokenGenerationError || addTokenError}</p>
                                    </div>
                                )}

                            </div>
                            <div className="modal-footer">
                                <button onClick={this.closeModal} className="btn">
                                    Close
                                </button>

                                {!loadingTokensError && (
                                    <button onClick={this.save} className="btn btn-primary" disabled={tokenGenerationError || isEmpty(generatedToken)}>
                                        Save
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-backdrop show" tabIndex="1" />
            </Fragment>
        );
    }
}

const loader = createLoadingSelector(['GET_SYSTEM_USER_ACCESS_TOKENS']);
const createTokensLoadingErrorSelector = getErrorMessage(['GET_SYSTEM_USER_ACCESS_TOKENS']);
const createTokenGenerationErrorSelector = getErrorMessage(['GENERATE_TOKEN']);
const createTokenDeleteErrorSelector = getErrorMessage(['DELETE_SYSTEM_USER_ACCESS_TOKEN']);
const createAddTokenErrorSelector = getErrorMessage(['ADD_SYSTEM_USER_ACCESS_TOKEN']);

const mapStateToProps = (state) => {
    const modalProps = getModalData(state).modalProps;
    const adminProps = getAdmin(state)
    const accessTokensLoader = loader(state);
    const loadingTokensError = createTokensLoadingErrorSelector(state);
    const tokenGenerationError = createTokenGenerationErrorSelector(state);
    const tokenDeleteError = createTokenDeleteErrorSelector(state);
    const addTokenError = createAddTokenErrorSelector(state);

    return {
        modalProps,
        adminProps,
        accessTokensLoader,
        loadingTokensError,
        tokenGenerationError,
        tokenDeleteError,
        addTokenError
    };
};

const mapDispatchToProps = {
    hideModal,
    generateToken,
    clearGeneratedToken,
    addSystemUserAccessToken,
    getSystemUserAccessTokens,
    clearSystemUserAccessTokens,
    deleteSystemUserAccessToken,
    clearErrors,
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageSystemUserAccessTokensModal);
