import axiosInstance from '../../utils/axios-instance';
import { actionCreator, createRequestTypes } from '../../utils/helpers';

export const SET_SECURITY_PHRASE = createRequestTypes('SET_SECURITY_PHRASE');
export const SET_AUTHORIZED_CONTACTS = createRequestTypes('SET_AUTHORIZED_CONTACTS');
export const SET_PRIMARY_CONTACTS = createRequestTypes('SET_PRIMARY_CONTACTS');
export const SECURITY_COUNTRY_STATES = createRequestTypes('SECURITY_COUNTRY_STATES');
export const SET_UPDATE_PRIMARY_CONTACT = createRequestTypes('SET_UPDATE_PRIMARY_CONTACT');
export const CREATE_ACCOUNT_CONTACT = createRequestTypes('CREATE_ACCOUNT_CONTACT');
export const SET_ALL_CONTACTS = createRequestTypes('SET_ALL_CONTACTS');
export const SEND_REGISTRATION_LINK = createRequestTypes('SEND_REGISTRATION_LINK');
export const UPDATE_USER_ACCESS = createRequestTypes('UPDATE_USER_ACCESS');

export const getSecurityPhrase = (accountId) => async (dispatch) => {
	dispatch(actionCreator.request(SET_SECURITY_PHRASE));
	try {
		const response = await axiosInstance.get(`/account/${accountId}/security`);
		dispatch(actionCreator.success(SET_SECURITY_PHRASE, response.data.securityPhrase));
	} catch (err) {
		dispatch(actionCreator.failure(SET_SECURITY_PHRASE));
	}
};

export const emptySecurityPhrase = () => (dispatch) => {
	dispatch(actionCreator.empty(SET_SECURITY_PHRASE));
};

export const getAllContacts = (accountId) => async (dispatch) => {
	dispatch(actionCreator.request(SET_ALL_CONTACTS));
	try {
		const response = await axiosInstance.get(`/account/${accountId}/contacts`);
		dispatch(actionCreator.success(SET_ALL_CONTACTS, response.data.contacts));
	} catch (err) {
		dispatch(actionCreator.failure(SET_ALL_CONTACTS));
	}
};

export const emptyAllContacts = () => (dispatch) => {
	dispatch(actionCreator.empty(SET_ALL_CONTACTS));
};

export const updateSecurityPhrase = (accountId, data) => async (dispatch) => {
	// dispatch(actionCreator.request(SET_SECURITY_PHRASE));
	try {
		const response = await axiosInstance.patch(`/account/${accountId}/security`, data);
		dispatch(getSecurityPhrase(accountId));
		return response.data;
	} catch (err) {
		dispatch(actionCreator.failure(SET_SECURITY_PHRASE));
		return err.response;
	}
};

export const updateAccountContact = (type, accountId, contactId, data) => async (dispatch) => {
	// dispatch(actionCreator.request(SET_UPDATE_PRIMARY_CONTACT));
	try {
		const response = await axiosInstance.patch(`/account/${accountId}/contact/${contactId}`, data);
		// dispatch(actionCreator.success(SET_UPDATE_PRIMARY_CONTACT, response.data.success));
		dispatch(getAllContacts(accountId));
		return response.data;
	} catch (err) {
		dispatch(actionCreator.failure(SET_UPDATE_PRIMARY_CONTACT));
		return err.response.data;
	}
};

export const updateContactAddress = (type, accountId, contactId, data) => async (dispatch) => {
	// dispatch(actionCreator.request(SET_SECURITY_PHRASE));
	try {
		const response = await axiosInstance.patch(`/account/${accountId}/contact/${contactId}/address`, data);
		dispatch(getAllContacts(accountId));
		return response.data;
	} catch (err) {
		dispatch(actionCreator.failure(SET_SECURITY_PHRASE));
		return err.response.data;
	}
};

export const updateServiceLineAddress = (serviceLineId, serviceLineAddressId, data) => async (dispatch) => {
	try {
		const response = await axiosInstance.patch(`/account/service-line/${serviceLineId}/${serviceLineAddressId}/address`, data);
		return response.data;
	} catch (err) {
		dispatch(actionCreator.failure(SET_SECURITY_PHRASE));
		return err.response.data;
	}
};

export const updateContactEmail = (type, accountId, contactId, data) => async (dispatch) => {
	// dispatch(actionCreator.request(SET_SECURITY_PHRASE));
	try {
		const response = await axiosInstance.patch(`/account/${accountId}/contact/${contactId}/email`, data);
		if (type === 'primary') {
			dispatch(getAllContacts(accountId));
		} else {
			dispatch(getAllContacts(accountId));
		}
		return response.data;
	} catch (err) {
		dispatch(actionCreator.failure(SET_SECURITY_PHRASE));
		return err.response.data;
	}
};

export const updateUserAccess = (systemUserId, data) => async (dispatch) => {
	dispatch(actionCreator.request(UPDATE_USER_ACCESS));
	try {
		const response = await axiosInstance.patch(`/admin/account/${systemUserId}/useraccess/`, data);
		dispatch(actionCreator.success(UPDATE_USER_ACCESS, response.data));
		return response.data;
	} catch (err) {
		dispatch(actionCreator.failure(UPDATE_USER_ACCESS));
		return err.response.data;
	}
};

export const sendContactEmailVerification = (data) => async (dispatch) => {
	try {
		const response = await axiosInstance.get(`/verification/email/verify`, {params: data});
		dispatch(getAllContacts(data.accountId));
		return response.data;
	} catch (err) {
		dispatch(actionCreator.failure(SET_SECURITY_PHRASE));
		return err.response.data;
	}
};

export const sendRegistrationLink = (data) => async (dispatch) => {
	try {
		const response = await axiosInstance.get(`/verification/email/registration`, {params: data});
		dispatch(getAllContacts(data.accountId));
		return response.data;
	} catch (err) {
		dispatch(actionCreator.failure(SEND_REGISTRATION_LINK));
		return err.response.data;
	}
};

export const verifyContactEmail = (data) => async (dispatch) => {
	try {
		const response = await axiosInstance.get(`/verification/email/verify`, {params: data});
		return response.data;
	} catch (err) {
		return err.response.data;
	}
};

export const updateContactNumber = (type, accountId, contactId, data) => async (dispatch) => {
	// dispatch(actionCreator.request(SET_SECURITY_PHRASE));
	try {
		const response = await axiosInstance.patch(`/account/${accountId}/contact/${contactId}/number`, data);
		if (type === 'primary') {
			dispatch(getAllContacts(accountId));
		} else {
			dispatch(getAllContacts(accountId));
		}
		return response.data;
	} catch (err) {
		dispatch(actionCreator.failure(SET_SECURITY_PHRASE));
		return err.response.data;
	}
};

export const getCountryStates = () => async (dispatch) => {
	dispatch(actionCreator.request(SECURITY_COUNTRY_STATES));
	try {
		const response = await axiosInstance.get(`/domain/states`);
		dispatch(actionCreator.success(SECURITY_COUNTRY_STATES, response.data.states));
	} catch (err) {
		dispatch(actionCreator.failure(SECURITY_COUNTRY_STATES));
	}
};

export const emptyCountryStates = () => (dispatch) => {
	dispatch(actionCreator.empty(SECURITY_COUNTRY_STATES));
};

export const createAccountContact = (accountId, data) => async (dispatch) => {
	dispatch(actionCreator.request(CREATE_ACCOUNT_CONTACT));
	try {
		const response = await axiosInstance.post(`/account/${accountId}/contact/`, data);
		dispatch(actionCreator.success(CREATE_ACCOUNT_CONTACT, response.data));
		dispatch(getAllContacts(accountId))
		return response.data;
	} catch (err) {
		dispatch(actionCreator.failure(CREATE_ACCOUNT_CONTACT));
		return err.response;
	}
};

// export const getPrimaryContacts = (accountId) => async (dispatch) => {
// 	dispatch(actionCreator.request(SET_PRIMARY_CONTACTS));
// 	try {
// 		const response = await axiosInstance.get(`/account/${accountId}/contacts/primary`);
// 		dispatch(actionCreator.success(SET_PRIMARY_CONTACTS, response.data.contacts));
// 	} catch (err) {
// 		dispatch(actionCreator.failure(SET_PRIMARY_CONTACTS));
// 	}
// };

// export const emptyPrimaryContacts = () => (dispatch) => {
// 	dispatch(actionCreator.empty(SET_PRIMARY_CONTACTS));
// };

// export const getAuthorizedContacts = (accountId) => async (dispatch) => {
// 	dispatch(actionCreator.request(SET_AUTHORIZED_CONTACTS));
// 	try {
// 		const response = await axiosInstance.get(`/account/${accountId}/contacts/authorized`);
// 		dispatch(actionCreator.success(SET_AUTHORIZED_CONTACTS, response.data.contacts));
// 	} catch (err) {
// 		dispatch(actionCreator.failure(SET_AUTHORIZED_CONTACTS));
// 	}
// };

// export const emptyAuthorizedContacts = () => (dispatch) => {
// 	dispatch(actionCreator.empty(SET_AUTHORIZED_CONTACTS));
// };