import React, {Component} from 'react';
import {connect} from 'react-redux';
import {hideModal, showModal,} from '../../actions/modal.actions';
import {getProvisioningQueueGroups} from '../../actions/provisioning.actions'
import {createLoadingSelector, getProvisioning} from "../../selectors";
import Loader from "../../components/Loader";
import isEmpty, {provisioningActionTypeEnum, provisioningStatusEnum} from "../../utils/helpers";
import Group from "./Group";
import Select from 'react-select';
import {groupStyles} from "../../utils/SelectStyles";
import DatePicker from "react-datepicker";
import moment from "moment";
import {toastr} from "react-redux-toastr";
import {debounce} from "lodash";

class ProvisioningQueue extends Component {

	state = {
		isLoading: false,
		isFilterExpanded: false,
		search: {
			limit: 20,
			actionType: '',
			createdFrom: '', 	// yyyy-MM-dd hh:mm:ss
			createdTo: '', 		// yyyy-MM-dd hh:mm:ss
			status: '',
			userName: '',
			number: '',
		},
		entriesOptions: [
			{value: 20, label: '20', placeholder: ''},
			{value: 50, label: '50', placeholder: ''},
			{value: 100, label: '100', placeholder: ''},
			{value: 9999999, label: 'All', placeholder: ''}
		],
		actionTypesOptions: [
			{value: '', label: 'All Actions', placeholder: ''},
			{value: 'ACTIVATE', label: 'Activate', placeholder: ''},
			{value: 'ADD', label: 'Add', placeholder: ''},
			{value: 'ADD_DEVICE', label: 'Add Device', placeholder: ''},
			{value: 'ADD_SERVICE', label: 'Add Service', placeholder: ''},
			{value: 'CHANGE_NUMBER', label: 'Change Number', placeholder: ''},
			{value: 'DELETE', label: 'Delete', placeholder: ''},
			{value: 'DEACTIVATE_DEVICE', label: 'Deactivate Device', placeholder: ''},
			{value: 'DEACTIVATE_SERVICE', label: 'Deactivate Service', placeholder: ''},
			{value: 'PORT_OUT', label: 'Port Ou', placeholder: ''},
			{value: 'QUERY', label: 'Query', placeholder: ''},
			{value: 'RELEASE', label: 'Release', placeholder: ''},
			{value: 'RESTORE', label: 'Restore', placeholder: ''},
			{value: 'SUSPEND', label: 'Suspend', placeholder: ''}
		],
		statusesOptions: [
			{value: '', label: 'All Statuses', placeholder: ''},
			{value: 'NEW', label: 'New', placeholder: ''},
			{value: 'PROCESSING', label: 'Processing', placeholder: ''},
			{value: 'PROCESSED', label: 'Processed', placeholder: ''},
			{value: 'WARNING', label: 'Warning', placeholder: ''},
			{value: 'ERROR', label: 'Error', placeholder: ''},
			{value: 'ON_HOLD', label: 'On Hold', placeholder: ''},
			{value: 'DEPRECATED', label: 'Deprecated', placeholder: ''}
		]
	}

	componentDidMount() {

		// Change navbar type and color
		document.title = 'Provisioning Queue - camvio.cloud';
		this.props.changeNavBarType('default', 'Provisioning Queue');
	}

	toggleFilter = () => {
		this.setState({isFilterExpanded: !this.state.isFilterExpanded});
	}

	loadProvisioningQueue = (search) => {

		this.setState({isLoading: true}, () => {

			this.props.getProvisioningQueueGroups(search).then(response => {
				this.setState({isLoading: false});

				if (!response.success) {
					toastr.error(response.error.message, {timeOut: 2000, position: 'top-center'});
				}
			});
		});
	}

	debounceHandleInputChange = event => {
		this.handleInputChange(event.target.name,event.target.value);
	}

	handleInputChange = debounce((fieldName, value) => {
		switch(fieldName) {
			case 'number':
				this.state.search.number = value;
				break;
			case 'userName':
				this.state.search.userName = value;
				break;
			default:
				break;
		}
		this.loadProvisioningQueue(this.state.search);
	},1000)


	handleSelectChange = (fieldName, value) => {

		switch(fieldName) {
			case 'limit':
				this.state.search.limit = value.value;
				break;
			case 'actionType':
				this.state.search.actionType = value.value;
				break;
			case 'status':
				this.state.search.status = value.value;
				break;
			default:
				break;
		}

		// Reload Provisioning Queue
		this.loadProvisioningQueue(this.state.search);
	}

	handleDateChange = (fieldName,date) => {
		switch(fieldName) {
			case 'createdFrom':
				this.state.search.createdFrom = date ? moment(date).format("YYYY-MM-DD") : "";
				break;
			case 'createdTo':
				this.state.search.createdTo = date ? moment(date).format("YYYY-MM-DD") : "";
				break;
		}

		// Reload Provisioning Queue
		this.loadProvisioningQueue(this.state.search);
	};



	render() {

		const {
			isLoading,
			isFilterExpanded,
			search,
			entriesOptions,
			actionTypesOptions,
			statusesOptions
		} = this.state;

		const {
			provisioningQueueGroupsLoading,
			provisioningQueueGroups,
		} = this.props;

		return (
			<div className="cmv-container cmv-container-dashboard cmv-container-client-selected">
				<div className="cmv-container-table">
					<div className="provisioning">

						<div className="provisioning-filter">
							<div className={"controls" + (isFilterExpanded ? " controls-expanded" : "")}>

								<div className="form-group">
									<label htmlFor="number">Number</label>
									<input
										type="text"
										className="form-control"
										id="number"
										name="number"
										onChange={this.debounceHandleInputChange}
										disabled={isLoading}
									/>
								</div>

								<div className="form-group">
									<label htmlFor="createdFrom">From</label>
									<DatePicker
										type="date"
										className="form-control"
										placeholderText="MM/DD/YYYY"
										id="createdFrom"
										name="createdFrom"
										disabled={isLoading}
										isClearable={!isEmpty(this.state.search.createdFrom)}
										selected={this.state.search.createdFrom && moment(this.state.search.createdFrom).toDate()}
										maxDate={moment().toDate()}
										onChange={(date) => this.handleDateChange('createdFrom', date)}
										style={groupStyles}
									/>
								</div>

								<div className="form-group">
									<label htmlFor="createdTo">To</label>
									<DatePicker
										type="date"
										className="form-control"
										placeholderText="MM/DD/YYYY"
										id="createdTo"
										name="createdTo"
										disabled={isLoading}
										isClearable={!isEmpty(this.state.search.createdTo)}
										selected={this.state.search.createdTo && moment(this.state.search.createdTo).toDate()}
										maxDate={moment().toDate()}
										onChange={(date) => this.handleDateChange('createdTo', date)}
										style={groupStyles}
									/>
								</div>

								<div className="form-group">
									<label htmlFor="actionType">Action Type</label>
									<Select
										id="actionType"
										name="actionType"
										value={actionTypesOptions.filter(obj => obj.value === this.state.search.actionType)}
										onChange={(value) => this.handleSelectChange('actionType', value)}
										isDisabled={isLoading}
										styles={groupStyles}
										options={actionTypesOptions}
									/>
								</div>

								<div className="form-group">
									<label htmlFor="userName">Username</label>
									<input
										type="text"
										className="form-control"
										id="userName"
										name="userName"
										onChange={this.debounceHandleInputChange}
										disabled={isLoading}
										placeholder="Username"
									/>
								</div>

								<div className="form-group">
									<label htmlFor="status">Status</label>
									<Select
										id="status"
										name="status"
										value={statusesOptions.filter(obj => obj.value === this.state.search.status)}
										onChange={(value) => this.handleSelectChange('status', value)}
										isDisabled={isLoading}
										styles={groupStyles}
										options={statusesOptions}
									/>
								</div>

								<div className="form-group">
									<label htmlFor="limit">Entries</label>
									<Select
										id="limit"
										name="limit"
										value={entriesOptions.filter(obj => obj.value === this.state.search.limit)}
										onChange={(value) => this.handleSelectChange('limit', value)}
										isDisabled={isLoading}
										styles={groupStyles}
										options={entriesOptions}
									/>
								</div>

							</div>

							<button
								type="button"
								className="btn btn-sm btn-secondary float-right"
								onClick={this.toggleFilter}
							>
								{isFilterExpanded
									? <i className="fa fa-minus-circle" />
									: <i className="fa fa-plus-circle" />
								}
							</button>

						</div>

						{isLoading &&
						<Loader />
						}

						{!isLoading && isEmpty(provisioningQueueGroups) &&
						<p>No data found</p>
						}

						{!isLoading && !isEmpty(provisioningQueueGroups) && provisioningQueueGroups.map((provisioningQueueGroup) =>
						<Group
							key={provisioningQueueGroup.id}
							provisioningQueueGroup={provisioningQueueGroup}
						/>
						)}

					</div>
				</div>
			</div>
		);
	}
}

const loaderProvisioningQueueGroups = createLoadingSelector(['PROVISIONING_QUEUE_GROUPS']);

const mapStateToProps = (state) => {

	const provisioningQueueGroupsLoading = loaderProvisioningQueueGroups(state);
	const provisioningQueueGroups = getProvisioning(state).provisioningQueueGroups;

	return {
		provisioningQueueGroupsLoading,
		provisioningQueueGroups,
	};
};

const mapDispatchToProps = {
	showModal,
	hideModal,
	getProvisioningQueueGroups,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProvisioningQueue);
