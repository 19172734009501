import React, { Component } from 'react';
import { connect } from "react-redux";
import axiosInstance from "../../../utils/axios-instance";
import FormSelect from "../../../components/UI/FormSelect";
import { get, isEmpty } from 'lodash';

const techniciansAsOptions = (technicians) => asOptions(technicians, technician => {

    return {
        label: technician.firstName + " " + technician.lastName,
        value: JSON.stringify(technician)
    }
});

const asOptions = (source, mapper) => {
    if (!Array.isArray(source)) {
        return [];
    }

    return source.map(mapper);
}

const findTechnicianById = (technicians, id) => {
    return technicians && technicians.find(technician => technician.id === id);
}

export class Technician extends Component {
    state = {
        alertMessage: '',
        resultsNumber: 20,
        searchTerm: '',
        selectedTechnician: '{}',
        technicianOptions: [],
        fetchingTechnicians: false,
        appointment_date: this.props.formAttributes.fields.find(field => field.id == "technician_task_date")? this.props.formAttributes.fields.find(field => field.id == "technician_task_date").defaultValue: null,
        appointment_time_slot_id: this.props.formAttributes.fields.find(field => field.id == "technician_time_slot_id")? this.props.formAttributes.fields.find(field => field.id == "technician_time_slot_id").defaultValue: null,
        reportedCodeDuration: this.props.formAttributes.taskDuration,
        isOverbooking: this.props.formAttributes.fields.find(field => field.id == "technician_overbooking") ? this.props.formAttributes.fields.find(field => field.id == "technician_overbooking").defaultValue : false
    };

    componentDidMount() {
        this.reloadTechnicians();
    }

    reloadTechnicians = () => {
        try {
            const getParams = {
                limit: this.state.resultsNumber,
                query: this.state.searchTerm,
                date: this.state.appointment_date,
                time_slot_id: this.state.appointment_time_slot_id,
                reportedCodeDuration: this.state.reportedCodeDuration,
                overbooking: this.state.isOverbooking
            };
            
            axiosInstance.get(`/trouble-ticket/technicians`, { params: getParams })
                .then(response => {
                    
                    let { selectedTechnician } = this.state;
                    const technicianResults = response.data.technicians;

                    selectedTechnician = JSON.parse(selectedTechnician);
                    
                    if (!isEmpty(selectedTechnician)) {
                        const systemUser = technicianResults.find(systemUser => systemUser.id === selectedTechnician.id);

                        if (!systemUser) {
                            technicianResults.push(selectedTechnician);
                        }
                        technicianResults.sort((a, b) => a.firstName.localeCompare(b.firstName));
                    }
                    this.setState({
                        technicianOptions: technicianResults
                    })
                })
                .catch(errorResponse => {
                    this.handlerErrorResponse(errorResponse);
                });
        } catch (err) {
            this.handlerErrorResponse(err);
        }

    }

    scrollDropdownIntoView = (e) => {

    }

    handlerErrorResponse = (errorResponse) => {
        this.setState({
            alertMessage: get(errorResponse, 'response.data.error.message', 'Something goes wrong'),
            technicianOptions: []
        })
    }

    handleTechniciansSearchChange = (value) => {
        if (this.state.searchTerm === value) {
            return;
        }

        this.setState({ searchTerm: value }, () => {
            if (this.searchChangeTimeout) {
                clearTimeout(this.searchChangeTimeout);
            }

            this.searchChangeTimeout = setTimeout(() => {
                this.reloadTechnicians();
            }, 300);
        });
    };

    handleOnTechnicianChange = setFieldValue => (options, newValue) => {
        const selectedTechnician = findTechnicianById(this.state.technicianOptions, JSON.parse(newValue).id);

        this.setState({ selectedTechnician: JSON.stringify(selectedTechnician) });
    }

    render() {
        const {
            field,
            errors, touched, values, setFieldValue, handleBlur, formAttributes
        } = this.props;

        return (
            <div className="form-group" >
                <label>{field.name}</label>
                <div onClick={this.scrollDropdownIntoView}>
                    <FormSelect
                        fieldName="technician"
                        options={techniciansAsOptions(this.state.technicianOptions)}
                        setFieldValue={setFieldValue}
                        value={values.technician}
                        onBlur={handleBlur}
                        touched={touched}
                        onInputChange={this.handleTechniciansSearchChange}
                        onPostChange={this.handleOnTechnicianChange(setFieldValue)}
                    />
                </div>
                {errors.technician && touched.technician &&
                    <div className="invalid-feedback">{errors.technician}</div>
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => {

    return {

    };
};

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(Technician);