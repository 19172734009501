import React, { Component } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { resetTokenValidation, resetPassword } from '../../actions/auth.actions';

const newPasswordSchema = Yup.object().shape({
	newPassword: Yup.string().min(6, 'Min 6 character').required('Required'),
	repeatPassword: Yup.string().required('Required').test('passwords-match', 'Passwords must match', function(value) {
		return this.parent.newPassword === value;
	})
});

class ResetPassword extends Component {
	state = {
		showError: false,
		errorMessage: ''
	};

	componentDidMount() {
		const verificationToken = this.props.location.search.substring(7);
		this.props.resetTokenValidation(verificationToken).then((response) => {
			if (response.success === false) {
				this.props.history.push('/forgotenpassword', {
					message: 'Your new password has been saved.'
				});
			}
		});
	}

	resetPassword = (values, actions) => {
		const verificationToken = this.props.location.search.substring(7);
		this.props.resetPassword(values.newPassword, verificationToken).then((response) => {
			if (response.success) {
				this.props.history.push('/login', {
					succesfulResetPassword: true
				});
			} else {
				actions.resetForm();
			}
		});
	};

	render() {
		const { showError, errorMessage } = this.state;

		return (
			<div className="cmv-page cmv-page-login">
				<div className="page-wrapper">
					<div className="page-content">
						<main>
							<div className="col col-sm-6 col-md-5 col-xl-3">
								<div className="card-login">
									<div className="card-login-header">
										<div className="brand-container" />
										{!showError && <h3>Change Password</h3>}
										{showError && <h3>{errorMessage}</h3>}
										<div className="lds-dual-ring" />
									</div>
									{!showError && (
										<div className="card-login-body">
											<Formik
												initialValues={{
													newPassword: '',
													repeatPassword: ''
												}}
												validationSchema={newPasswordSchema}
												onSubmit={this.resetPassword}
												render={({
													handleChange,
													handleSubmit,
													handleBlur,
													values,
													errors,
													touched,
													isSubmitting
												}) => (
													<Form onSubmit={handleSubmit} className="needs-validation">
														<div className="form-label-group">
															<input
																type="password"
																className={
																	errors.newPassword && touched.newPassword ? (
																		'form-control is-invalid'
																	) : (
																		'form-control'
																	)
																}
																placeholder="New Password"
																id="newPassword"
																name="newPassword"
																value={values.newPassword}
																onChange={handleChange}
																onBlur={handleBlur}
															/>
															<label htmlFor="newPassword">New Password</label>
															{errors.newPassword &&
															touched.newPassword && (
																<div className="invalid-feedback">
																	{errors.newPassword}
																</div>
															)}
														</div>
														<div className="form-label-group">
															<input
																type="password"
																className={
																	errors.repeatPassword && touched.repeatPassword ? (
																		'form-control is-invalid'
																	) : (
																		'form-control'
																	)
																}
																placeholder="Repeat Password"
																name="repeatPassword"
																id="repeatPassword"
																value={values.repeatPassword}
																onChange={handleChange}
																onBlur={handleBlur}
															/>
															<label htmlFor="repeatPassword">Repeat password</label>
															{errors.repeatPassword &&
															touched.repeatPassword && (
																<div className="invalid-feedback">
																	{errors.repeatPassword}
																</div>
															)}
														</div>
														<div className="form-group">
															<button
																disabled={isSubmitting}
																type="submit"
																className="btn btn-block btn-primary"
															>
																Save Changes
															</button>
														</div>
													</Form>
												)}
											/>
										</div>
									)}
								</div>
							</div>
						</main>
					</div>
				</div>
			</div>
		);
	}
}

ResetPassword.propTypes = {};

const mapStateToProps = (state) => {
	return {};
};

const mapDispatchToProps = {
	resetTokenValidation,
	resetPassword
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
