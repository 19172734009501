import React, { Component } from 'react';
import DatePicker from "react-datepicker";
import moment from "moment";

export class NotificationAlertBox extends Component {

	state = {
		isExpirationDateOpen: false,
		expirationDate: this.props.notificationAlert.expirationDateTime ? new Date(this.props.notificationAlert.expirationDateTime) : '',
		alertMessage: this.props.notificationAlert.message ? this.props.notificationAlert.message : '',
		expirationDateButtonText: this.props.notificationAlert.expirationDateTime ? `Expires on ${moment(this.props.notificationAlert.expirationDateTime).format('MM/DD/YYYY')}` : "Set Expiration Date"
	};

	handleClickDropdownExpirationDate = () => {
		this.setState((prevState) => ({
			isExpirationDateOpen: !prevState.isExpirationDateOpen
		}));
		this.openDatepicker();
	};

	openDatepicker = () => this._calendar.setOpen(true);

	handleExpDate = (date, name) => {
		this.setState({
			[name]: date,
			expirationDateButtonText: `Expires on ${moment(date).format('MM/DD/YYYY')}`,
			isExpirationDateOpen: false
		});

	};

	handleClearExpirationDate = () => {
		this.setState({
			expirationDateButtonText: "Set Expiration Date",
			expirationDate: null,
			isExpirationDateOpen: false
		});
	};

	handleClearAlertMessage = () => {
		this.setState({
			alertMessage: ''
		});
	};

	handleChangeAlertMessage = (event) => {
		this.setState({
			alertMessage: event.target.value
		});
	};

	formSubmit = () => {
		const { accountId, updateNotificationAccountAlert } = this.props;
		const {expirationDate, alertMessage} = this.state;

		let accountAlert = {
			expirationDateTime: alertMessage.length === 0 ? null : expirationDate,
			message: alertMessage.length === 0 ? null : alertMessage
		};

		if (alertMessage.length === 0) {
			this.handleClearExpirationDate();
		}

		updateNotificationAccountAlert(accountId, accountAlert);
	};

	render() {
		const {isExpirationDateOpen, alertMessage, expirationDate, expirationDateButtonText} = this.state;
		return (
			<div className="container-client-notifications-account-alert">
				<div className="form-group">
					<label>Account Alert</label>
					<textarea
						className="form-control"
						id="account-alert-text-input"
						value= {alertMessage}
						onChange={(event) => this.handleChangeAlertMessage(event)}
					/>

				</div>
				<div className="form-group d-flex">
					<button className="btn btn-outline-primary ml-auto btn-account-alert-clear" onClick={this.handleClearAlertMessage}>Clear</button>
					<div className={
						isExpirationDateOpen ? (
							'dropdown dropdown-cn-expiration ml-1 show'
						) : (
							'dropdown dropdown-cn-expiration ml-1'
						)
					}>
						<button onClick={this.handleClickDropdownExpirationDate}  className="btn btn-outline-primary dropdown-toggle" type="button" data-toggle="dropdown"
								id="btn-cn-expiration" aria-expanded={isExpirationDateOpen} > {expirationDateButtonText}
						</button>
						<div className={
								 isExpirationDateOpen ? (
									 'dropdown-menu dropdown-menu-right show'
								 ) : (
									 'dropdown-menu dropdown-menu-right'
								 )
							 }>
							<h6 className="dropdown-header">The alert will expire on</h6>
							<div className="form-group col">
								<div
									className="input-group input-group-sm date"
									id="datetimepicker-cn-exp"
									data-target-input="nearest"
								>
									<DatePicker
										className="form-control datetimepicker-input"
										selected={expirationDate ? expirationDate : new Date() }
										onChange={(date) => this.handleExpDate(date, 'expirationDate')}
										placeholderText="MM/DD/YYYY"
										name="expirationDate"
										ref={(c) => this._calendar = c}
									/>
								</div>
							</div>
							<div className="dropdown-item">
								<button type="button"
										className="btn btn-outline-secondary btn-block btn-cn-exp-date-clear" onBlur={this.handleClearExpirationDate} onClick={this.handleClearExpirationDate}>Clear
								</button>
							</div>
						</div>
					</div>
					<button type="submit" className="btn btn-primary ml-1 btn-account-alert-save" onClick={this.formSubmit}>Save</button>
				</div>
			</div>
		);
	}
}

export default NotificationAlertBox;
