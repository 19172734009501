import React, {Component} from 'react';
import moment from "moment";

class TableRow extends Component {

    render() {

        const {
            id,
            fromTime,
            toTime,
            rowClass,
            selectedDates,
            setFieldValue,
            values,
            isSubmitting,
        } = this.props;

        return (
            <tr className={rowClass}>

                <td>
                    <span>{moment(fromTime, 'HH:mm:ss').format('h:mm A')}</span>
                    <span>{moment(toTime, 'HH:mm:ss').format('h:mm A')}</span>
                </td>

                {selectedDates.map((selectedDate, i) => <>
                    <td>
                        <input
                            type="text"
                            placeholder={0}
                            onChange={(e) => {
                                if (!isNaN(e.target.value) && !e.target.value.includes('.') && !e.target.value.includes(',')) {
                                    setFieldValue("capacity.slot" + id + ".day" + (i + 1), parseInt(e.target.value, 10) || 0)
                                }
                            }}
                            name={"capacity.slot" + id + ".day" + (i+1)}
                            value={values.capacity["slot" + id] && values.capacity["slot" + id]["day" + (i+1)]}
                            className={"form-control"}
                            disabled={isSubmitting || (moment(selectedDate).diff(moment(), 'days') < 0)}
                        />
                    </td>
                </>)}

            </tr>
        );
    }
}

export default TableRow;
