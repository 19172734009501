import * as Yup from 'yup';

export const validationSchema = (accountHolderRequiredFields, activeTab) => {
	// Dynamic validation
	// let test = {
	// 	test: Yup.string().required('Required'),
	// 	proba: Yup.string().required('Required'),
	// }

	Yup.addMethod(Yup.object, 'uniqueProperty', function(propertyName, secondProperty, message) {
		return this.test('unique', message, function(value) {
			if (!value || (!value[propertyName] && !value) || !value[secondProperty]) {
				return true;
			}

			const { path } = this;
			const options = [ ...this.parent ];
			const currentIndex = options.indexOf(value);

			const subOptions = options.slice(0, currentIndex);

			if (
				subOptions.some(
					(option) =>
						option[propertyName] === value[propertyName] && option[secondProperty] === value[secondProperty]
				)
			) {
				throw this.createError({
					path: `${path}.${propertyName}`,
					message
				});
			}

			return true;
		});
	});

	if (activeTab === 0) {
		return Yup.object().shape({
			accountHolder: Yup.object().shape({
				accountType: Yup.number().typeError('Required').required('Required'),
				securityPhrase: Yup.string().min(5, 'Min 5 char').required('Required'),
				firstName: Yup.string().min(1, 'Too Short!').max(50, 'Too Long!').required('Required'),
				lastName: Yup.string().min(1, 'Too Short!').max(50, 'Too Long!').required('Required'),
				mailingAddress: Yup.object().shape({
					address1: Yup.string().required('Required'),
					city: Yup.string().required('Required'),
					zip: Yup.string().required('Required')
				}),

				// securities: Yup.array().of(
				// 	Yup.object().shape({
				// 		questionId: Yup.string().test('testova', 'Required', function(value) {
				// 			const index = parseInt(this.path.split('[')[1].split(']')[0], 10);
				// 			if (index === 0) {
				// 				return value !== '';
				// 			} else {
				// 				return true;
				// 			}
				// 		}),
				// 		answer: Yup.string().test('testova', 'Required', function(value) {
				// 			const index = parseInt(this.path.split('[')[1].split(']')[0], 10);
				// 			if (index === 0) {
				// 				return value !== '';
				// 			} else {
				// 				return true;
				// 			}
				// 		})
				// 	})
				// ),
				// Conditional Validation
				[accountHolderRequiredFields.businessEmail]:
					accountHolderRequiredFields.businessEmail &&
					Yup.string().email('Invalid email format').required('Required'),
				[accountHolderRequiredFields.personalEmail]:
					accountHolderRequiredFields.personalEmail &&
					Yup.string().email('Invalid email format').required('Required'),
				[accountHolderRequiredFields.driverLicense]:
					accountHolderRequiredFields.driverLicense && Yup.string().min(5, 'Min 5 char').required('Required'),
				[accountHolderRequiredFields.taxId]:
					accountHolderRequiredFields.taxId && Yup.string().min(5, 'Min 5 char').required('Required'),
				[accountHolderRequiredFields.dateOfBirth]:
					accountHolderRequiredFields.dateOfBirth && Yup.string().required('Required'),
				[accountHolderRequiredFields.homeNumber]:
					accountHolderRequiredFields.homeNumber &&
					Yup.object().shape({
						number: Yup.string().min(2, 'Min 2 char').required('Required'),
						verified: Yup.boolean()
					}),
				[accountHolderRequiredFields.mobileNumber]:
					accountHolderRequiredFields.mobileNumber &&
					Yup.object().shape({
						number: Yup.string().min(2, 'Min 2 char').required('Required'),
						verified: Yup.boolean()
					}),
				[accountHolderRequiredFields.businessNumber]:
					accountHolderRequiredFields.businessNumber &&
					Yup.object().shape({
						number: Yup.string().min(2, 'Min 2 char').required('Required'),
						verified: Yup.boolean()
					}),
            	[accountHolderRequiredFields.memberNumber]:
				accountHolderRequiredFields.memberNumber &&
				Yup.string()
					.min(1, 'Min 1 char')
					.max(45, 'Max 45 chars')
					.required('Required'),
				// ...test

				// lastName: Yup.string().test('testova', 'Testov mesidg', function(value) {
				// 	return value === 'test';
				// }),
			}),
			accountTermsInfo: Yup.object().shape({
				accountTerm: Yup.string().required('Required'),
				accountTier: Yup.string().when('manualTierSelector', {
					is: true,
					then: Yup.string().required('Required')
				})
			})
		});
	} else if (activeTab === 1) {
		return Yup.object().shape({
			billingDetails: Yup.object().shape({
				showBillingAddress: Yup.boolean(),
				showDiscounts: Yup.boolean(),
				showRecurringCredits: Yup.boolean(),
				billingAddress: Yup.object().when('showBillingAddress', {
					is: true,
					then: Yup.object({
						address1: Yup.string().required('Required'),
						city: Yup.string().required('Required'),
						zip: Yup.string().required('Required')
					})
				}),

				discounts: Yup.array().when('showDiscounts', {
					is: true,
					then: Yup.array().of(
						Yup.object().shape({
							id: Yup.string().required('Required')
						})
					)
				}),

				recurringCredits: Yup.array().when('showRecurringCredits', {
					is: true,
					then: Yup.array().of(
						Yup.object().shape({
							id: Yup.string().required('Required')
						})
					)
				}),

				taxExemptions: Yup.array().when('showTaxExemptions', {
					is: true,
					then: Yup.array().of(
						Yup.object()
							.shape({
								taxCodeId: Yup.string().required('Required'),
								type: Yup.string().required('Required')
							})
							.uniqueProperty(
								'taxCodeId',
								'type',
								'Tax exemption with the same tax code and type already exists!'
							)
					)
				})
			})
		});
	}
};

// const firstStep = Yup.object().shape({
// 	accountHolder: Yup.object().shape({
// 		securityPhrase: Yup.string().min(5, 'Min 5 char').required('Required'),
// 		accountType: Yup.number().typeError('Required').required('Required'),
// 		firstName: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Required'),
// 		middleName: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Required'),
// 		lastName: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Required'),
// 		personalEmail: Yup.string().when('accountType', {
// 			is: 1,
// 			then: Yup.string().email('Invalid email format').required('Required')
// 		}),
// 		dateOfBirth: Yup.string().when('accountType', {
// 			is: 1,
// 			then: Yup.string().required('Required')
// 		}),
// 		taxId: Yup.string().when('accountType', {
// 			is: 2,
// 			then: Yup.string().min(5, 'Min 5 char').required('Required')
// 		}),
// 		businessEmail: Yup.string().when('accountType', {
// 			is: 2,
// 			then: Yup.string().email('Invalid email format').required('Required')
// 		}),
// 		mailingAddress: Yup.object().shape({
// 			address1: Yup.string().min(5, 'Min 5 char').required('Required'),
//       		address2: Yup.string().min(5, 'Min 5 char').required('Required'),
//       		address3: Yup.string().min(5, 'Min 5 char').required('Required'),
//     		city: Yup.string().min(5, 'Min 5 char').required('Required'),
//       		state: Yup.number().typeError('Required').required('Required'),
//       		zipcode: Yup.string().min(5, 'Min 5 char').required('Required'),
// 		})

// 	})

// });

// const secondStep = Yup.object().shape({
// 	accountTermsInfo: Yup.object().shape({
// 		accountTerm: Yup.number().typeError('Required').required('Required'),
// 		accountTier: Yup.number().typeError('Required').required('Required'),
// 		billCycle: Yup.number().typeError('Required').required('Required')
// 	})
// });

// const thirdStep = Yup.object().shape({
// 	billingDetails: Yup.object().shape({
// 		showBillingAddress: Yup.boolean(),
// 		billingAddress: Yup.object().when('showBillingAddress', {
// 			is: true,
// 			then: Yup.object({
// 				address1: Yup.string().min(5, 'Min 5 char').required('Required'),
// 				address2: Yup.string().min(5, 'Min 5 char').required('Required'),
// 				address3: Yup.string().min(5, 'Min 5 char').required('Required'),
// 				city: Yup.string().min(5, 'Min 5 char').required('Required'),
// 				state: Yup.string().required('Required'),
// 				zipcode: Yup.string().min(5, 'Min 5 char').required('Required'),
// 			  })
// 		})

// 	})
// });

// export const schemaArray = [firstStep, secondStep, thirdStep];
