import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import Loader from "../../components/Loader";
import {Formik} from "formik";
import isEmpty from "../../utils/helpers";
import TextInput from "../../components/UI/TextInput";
import {hideModal} from "../../actions/modal.actions";
import {getAdmin, getModalData } from "../../selectors";
import { getAutoPayJob, addAutoPayJob, updateAutoPayJob } from "../../actions/autoPayJobs-actions";
import moment from "moment";
import DatePicker from "react-datepicker";

class AddAutoPayJobModal extends Component {

    state = {
        alertMessage: '',
        autoPayJobsLoader: false,
        autoPayJob: null,
        selectedDate: null,
    };

    componentDidMount() {
        const {modalProps} = this.props;
        if(modalProps.value) {
            this.props.getAutoPayJob(modalProps.value).then((response) => {
                var scheduledDateVal = new Date(response.scheduledDate.year, (response.scheduledDate.monthValue-1), response.scheduledDate.dayOfMonth, response.scheduledDate.hour, response.scheduledDate.minute);
                this.setState({
                    autoPayJobsLoader: true,
                    autoPayJob: response,
                    selectedDate: scheduledDateVal
                })
            })
        }else{
            this.setState({
                autoPayJobsLoader: true,
                autoPayJob: null
            })
        }
    }

    handleDateTimeChange = (newDateTime) => {
        this.setState({selectedDate: newDateTime});
    }

    formSubmit = (values, actions) => {
        const { selectedDate } = this.state;
        const submitData = {...values};

        this.setState({alertMessage: ''});
        if ((selectedDate === null) && ((submitData.scheduledDate === null) || (submitData.scheduledDate === ""))) {
            this.setState({alertMessage: "You must select a date and time"});
            actions.setSubmitting(false);
        } else {
            this.setState({alertMessage: ''}, () => {
                const method = this.props.modalProps.type === 'create' ? this.props.addAutoPayJob : this.props.updateAutoPayJob;

                if (selectedDate != null) {
                    submitData.scheduledDate = selectedDate;
                }
                method(submitData).then((response) => {
                    if (!response.data) {
                        actions.setSubmitting(false);
                        this.setState({alertMessage: 'Unknown error. Please try again later'});
                        return;
                    }

                    if (!response.data.success) {
                        actions.setSubmitting(false);
                        this.setState({alertMessage: response.data.message});
                        return;
                    }

                    this.props.hideModal();
                    this.props.modalProps.reloadFunction(true);
                });
            });
        }
    }

    render() {
        const { alertMessage, autoPayJobsLoader, autoPayJob, selectedDate } = this.state;
        const { modalProps } = this.props;
        const filterPassedTime = (time) => {
            const currentDate = new Date();
            const selectedDateTime = new Date(time);

            return currentDate.getTime() < selectedDateTime.getTime();
        };
        return (
            <Fragment>
                <div className="modal" style={{display: 'block'}} tabIndex="-1" role="dialog">
                    {!autoPayJobsLoader &&
                    <Loader />
                    }
                    {autoPayJobsLoader &&
                    <Formik
                        initialValues={{
                            id: autoPayJob && autoPayJob.id || null,
                            scheduledDate: autoPayJob &&  autoPayJob.scheduledDate || '',
                            notes: autoPayJob && autoPayJob.notes || null,
                            createdDate: autoPayJob && autoPayJob.createdDate || null,
                            createdBy: autoPayJob && autoPayJob.createdBy || null
                        }}
                        onSubmit={this.formSubmit}
                        render={({
                                     handleChange,
                                     handleSubmit,
                                     handleBlur,
                                     values,
                                     errors,
                                     touched,
                                     isSubmitting,
                                     setFieldValue
                                 }) => (
                            <form onSubmit={handleSubmit}>
                                <div className="modal-dialog">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title">
                                                {modalProps.type === 'create' ? 'Add AutoPay Job' : 'Edit AutoPay Job'}
                                            </h5>
                                            <button onClick={this.props.hideModal} type="button" className="close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div className="modal-body form-horizontal">
                                            {!isEmpty(alertMessage) && (
                                                <div className="alert alert-inline alert-danger alert-dismissible">
                                                    <p className="mb-0">{alertMessage}</p>
                                                </div>
                                            )}
                                            <div className="form-group form-group-appointment">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder={selectedDate ? moment(selectedDate).format(selectedDate.hour === 0 ? "MMMM D, YYYY" : "MMMM D, YYYY @ h:00 A") : "Select Auto Pay Job Scheduled Date and Time"}
                                                    disabled
                                                />
                                            </div>

                                            <div className="form-group d-flex justify-content-center">
                                                <div className="customDatePickerWidth">
                                                    <DatePicker
                                                        inline
                                                        autoFocus
                                                        popperPlacement={'auto'}
                                                        isClearable
                                                        disabledKeyboardNavigation
                                                        showTimeSelect
                                                        shouldCloseOnSelect={false}
                                                        placeholderText={"Select Auto Pay Job Scheduled Date and Time"}
                                                        selected={selectedDate ? Date.parse(selectedDate) : null}
                                                        onChange={(date) => this.handleDateTimeChange(date) }
                                                        includeDates={ null }
                                                        includeTimes={ null }
                                                        timeIntervals={60}
                                                        minDate={moment().toDate()}
                                                        filterTime={filterPassedTime}
                                                        dateFormat="MMMM d, yyyy h:mm aa"
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <TextInput
                                                    label="Notes"
                                                    name="notes"
                                                    value={values.notes}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    errors={errors}
                                                    touched={touched}
                                                />
                                            </div>
                                            {modalProps.type !== 'create' &&
                                                <>
                                                    <div className="form-group">
                                                        <TextInput
                                                            label="Id"
                                                            name="id"
                                                            value={values.id}
                                                            disabled={true}
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <TextInput
                                                            label="Created Date"
                                                            name="createdDate"
                                                            value={String(values.createdDate.monthValue).padStart(2, '0') + "/" + String(values.createdDate.dayOfMonth).padStart(2, "0") + "/" + values.createdDate.year}
                                                            disabled={true}
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <TextInput
                                                            label="Created By"
                                                            name="createdBy"
                                                            value={values.createdBy}
                                                            disabled={true}
                                                        />
                                                    </div>
                                                </>
                                            }

                                        </div>
                                        <div className="modal-footer">
                                            <button onClick={this.props.hideModal} className="btn" type="button">
                                                Cancel
                                            </button>
                                            <button disabled={isSubmitting} type="submit" className="btn btn-primary">
                                                {modalProps.type === 'create' ? 'Add AutoPay Job' : 'Save AutoPay Job'}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        )}
                    />}}
                </div>
                <div className="modal-backdrop show" tabIndex="1"/>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    const modalProps = getModalData(state).modalProps,
        autoPayJob = getAdmin(state).autoPayJob;

    return {
        modalProps,
        autoPayJob,
    };
};

const mapDispatchToProps = {
    hideModal,
    addAutoPayJob,
    getAutoPayJob,
    updateAutoPayJob,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddAutoPayJobModal);