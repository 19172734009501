import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {hideModal} from '../../../actions/modal.actions';
import {getModalData} from '../../../selectors';
import {executeOrder} from '../../../actions/dashboardActions/orders.actions';
import {toastr} from "react-redux-toastr";
import isEmpty from "../../../utils/helpers";

class BulkStartOrdersModal extends Component {

    state = {
        isLoading: false,
        isFinished: false,
        isStopping: false,
        currentOrderId: null,
        ordersStarted: 0,
        errorCount: 0,
    }

    startOrders = (isLoading) => {

        if (isLoading) {
            toastr.confirm("Are you sure you want to stop order execution?", {
                cancelText: "No",
                okText: "Yes",
                onOk: () => {
                    this.setState({isStopping: true});
                }
            });
        }
        else {
            this.setState({isLoading: true});

            this.startNextOrder();
        }
    }

    startNextOrder = () => {
        const {currentOrderId, ordersStarted, errorCount} = this.state;
        const {bulkSelectionOrders} = this.props.modalProps;

        // If user has not stopped execution and there are more orders
        if (!this.state.isStopping && ordersStarted < bulkSelectionOrders.length) {

            let newOrderId = currentOrderId
                ? bulkSelectionOrders[bulkSelectionOrders.indexOf(currentOrderId) + 1]
                : bulkSelectionOrders[0];

            this.setState({currentOrderId: newOrderId}, () => {

                // Fire order request
                this.props.executeOrder(newOrderId).then(response => {

                    // Collect error count
                    if (isEmpty(response) || (response && !response.success)) {
                        this.setState({errorCount: errorCount + 1})
                    }

                    this.setState({ordersStarted: ordersStarted + 1});

                    // Start next order if user has not stopped execution
                    if (!this.state.isStopping) {
                        this.startNextOrder();
                    }
                    // Close modal if user has stopped execution
                    else {
                        this.closeModal();
                    }
                });
            });
        }

        // If user has not stopped execution and there are no more orders
        if (!this.state.isStopping && ordersStarted === bulkSelectionOrders.length) {
            this.setState({
                isLoading: false,
                isFinished: true,
            });
        }
    }

    closeModal = () => {
        this.props.hideModal();

        if (this.props.modalProps.onCloseCallback) {
            this.props.modalProps.onCloseCallback();
        }
    };

    render() {

        const {
            isLoading,
            isFinished,
            isStopping,
            currentOrderId,
            ordersStarted,
            errorCount,
        } = this.state;

       const {
           bulkSelectionOrders,
       } = this.props.modalProps;

        return (
        <Fragment>
            <div className="modal" style={{display: 'block'}} tabIndex="-1" role="dialog">
                <div className="modal-dialog">
                    <div className="modal-content">

                        <div className="modal-header">
                            <h5 className="modal-title">
                                BULK START ORDERS
                            </h5>
                            <button onClick={isFinished ? this.closeModal : this.props.hideModal} disabled={isLoading} type="button" className="close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>

                        <div className="modal-body">

                            <h6>{bulkSelectionOrders.length} orders selected</h6>

                            {isFinished
                            ? <p>Bulk order execution completed.</p>
                            :
                            isLoading
                            ? <p>Executing order {currentOrderId}{isStopping && " and stopping further execution."}.</p>
                            : <p>Press START to begin executing the selected orders.</p>
                            }

                            {(isLoading || isFinished) &&
                            <div className="progress">
                                <div
                                    className="progress-bar bg-success-with-pulse"
                                    role="progressbar"
                                    style={{ width: 'calc('+ordersStarted+'/'+bulkSelectionOrders.length+'*100%)' }}
                                    aria-valuenow="100"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                >
                                    <span style={{whiteSpace: "nowrap"}}>
                                        {ordersStarted - errorCount}/{bulkSelectionOrders.length}
                                        &nbsp;&nbsp;
                                        {isLoading && <i className="fas fa-spinner fa-spin" />}
                                    </span>
                                </div>
                            </div>
                            }

                            {errorCount > 0 &&
                            <small className="text-danger">Could not start {errorCount} order{errorCount > 1 && "s"}.</small>
                            }

                        </div>

                        <div className="modal-footer">

                            {isFinished
                            ?
                            <button type="submit" className="btn" onClick={this.closeModal} >
                                Close
                            </button>
                            :
                            <button
                                type="submit"
                                className={"btn btn-" + (isLoading ? "danger" : "primary")}
                                onClick={() => this.startOrders(isLoading)}
                                disabled={isStopping}
                            >
                                {isLoading ? "STOP" : "START"}
                            </button>
                            }

                        </div>

                    </div>
                </div>

            </div>
            <div className="modal-backdrop show" tabIndex="1"/>
        </Fragment>
        );
    }
}


const mapStateToProps = (state) => {
    const modalProps = getModalData(state).modalProps;
  
    return {
        modalProps
    };
};

const mapDispatchToProps = {
    hideModal,
    executeOrder,
};

export default connect(mapStateToProps, mapDispatchToProps)(BulkStartOrdersModal);

