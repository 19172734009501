import { LOAD_AUTOPAY_JOBS} from "../actions/autoPayJobs-actions";
import {LOAD_BILLING_JOB, LOAD_BILLING_JOBS} from "../actions/billingJobs.actions";

let initialState = {
    autoPayJobs: [],
    autoPayJob: [],
};

export const autoPayJobsReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOAD_AUTOPAY_JOBS.SUCCESS:
            return {
                ...state,
                autoPayJobs: action.payload.autoPayJobs
            }
        case LOAD_AUTOPAY_JOBS.CLEAR:
            return {
                ...state,
                autoPayJobs: []
            }
        case LOAD_BILLING_JOB.SUCCESS:
            return {
                ...state,
                autoPayJob: action.payload
            }
        default:
            return state;
    }

}