import React, { Component } from 'react';
import NoteList from "./NoteList";

export class Notes extends Component {
    state = {
        showContent: !false
    };

    showHideContent = () => {
        this.setState((prevState) => ({
            showContent: !prevState.showContent
        }));
    };

    render() {
        const { showContent } = this.state;

        return (
            <div className="order-summary">
                {/*<button
                    className="btn btn-outline-secondary btn-block btn-order-summary-toggle mb-1"
                    data-toggle="collapse"
                    onClick={this.showHideContent}
                >
                    <i className="fas fa-list-ul" />&nbsp;Notes
                </button>*/}
                {showContent && <NoteList serviceOrderId={this.props.serviceOrderId} />}
            </div>
        );
    }
}

export default Notes;
