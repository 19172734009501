import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {showModal, hideModal} from '../../../actions/modal.actions';
import {getProducts, getModalData} from '../../../selectors';
import { getPlan, getAllFeatures, getAllFeaturePackages, updatePlanFeaturesAndPackages, clearPlanFeaturesAndPackages} from "../../../actions/products.action";
import {Formik} from 'formik';
import {isEmpty,generateId} from "../../../utils/helpers";
import FormSelect from "../../../components/UI/FormSelect";
import Loader from "../../../components/Loader";
import {toastr} from "react-redux-toastr";
import FormikInput from "../../../components/Common/Inputs/FormikInput";

const asOptions = (source, mapper) => {
    if (!Array.isArray(source)) {
        return [];
    }

    return source.map(mapper);
}

const matchServiceModel = (featureOrPackages, serviceModelId) => {
    if(serviceModelId && !isEmpty(featureOrPackages)) {
        return featureOrPackages.filter(featureOrPackage => featureOrPackage.serviceModelId == serviceModelId);
    }else{
        return [];
    }
}

const featuresAsOptions = (features) => asOptions(features, feature => {
    if(feature != undefined) {
        return {
            label: feature.description + " (" + feature.name + ") " + (feature.price != undefined ? "($" + feature.price + ")" : ""),
            value: feature.id
        };
    }
});

const packagesAsOptions = (packages) => asOptions(packages, featurePackage => {
    if(featurePackage != undefined) {
        return {
            label: featurePackage.description + " (" + featurePackage.name + ")",
            value: featurePackage.featurePackageId
        };
    }
});

const currentSelectedFeatures = (features, planOption) => {
    let selectedFeatures = [];
    if(features != undefined) {
        if(planOption == "isOptional"){
            features.map(feature => {
                let id = feature.id || feature.featurePackageId
                if(feature.planOption != undefined && feature.planOption.isOptional == true){
                        selectedFeatures.push(id);
                }
            })
            return selectedFeatures
        }
        if(planOption == "isIncluded"){
            features.map(feature => {
                let id = feature.id || feature.featurePackageId
                if(feature.planOption != undefined && feature.planOption.isIncluded == true){
                    selectedFeatures.push(id);
                }
            })
            return selectedFeatures
        }
        if(planOption == "isMandatory"){
            features.map(feature => {
                let id = feature.id || feature.featurePackageId
                if(feature.planOption != undefined && feature.planOption.isMandatory == true){
                    selectedFeatures.push(id);
                }
            })
            return selectedFeatures
        }
    }
    return [];
}

class EditPlanFeaturesAndPackagesModal extends Component {

    state = {
        alertMessage: '',
        isPlanLoading: true
    };

    componentDidMount() {
        const {modalProps} = this.props;
        this.props.clearPlanFeaturesAndPackages();
        this.props.getAllFeatures();
        this.props.getAllFeaturePackages();

        if(modalProps.value) {
            this.props.getPlan(modalProps.value).then((response) => {
                this.setState({
                    isPlanLoading: false,
                })
            })
        }else{
            this.setState({
                isPlanLoading: false
            })
        }
    }

    componentWillUnmount() {
      this.props.clearPlanFeaturesAndPackages();
    }



    formSubmit = (values, actions) => {
        if(values != undefined){
            let mainServiceLine = this.checkFeaturesPackagesForSubmit(values, values.mainServiceLine, "MAIN");
            let requiredLines = this.checkFeaturesPackagesForSubmit(values, values.requiredLines, "REQUIRED");
            let additionalLines = this.checkFeaturesPackagesForSubmit(values, values.additionalLines, "ADDITIONAL");
            const submitData = [mainServiceLine,requiredLines,additionalLines];
            this.props.updatePlanFeaturesAndPackages(this.props.modalProps.value, submitData).then((response) => {
                if (!response) {
                    actions.setSubmitting(false);
                    toastr.error("Failed to update Plan", {timeOut: 8000, position: 'top-center'});
                    return;
                }
                if (!response.success) {
                    actions.setSubmitting(false);
                    this.props.hideModal();
                    toastr.error(response.data.error.message, {timeOut: 8000, position: 'top-center'});
                } else {
                    this.props.hideModal();
                    toastr.success(response.message, {timeOut: 4000, position: 'top-center'});
                }
            });
        }
    }

    checkFeaturesPackagesForSubmit = (values, serviceLine, relation) => {
        let lineFeatures = [];
        let linePackages = [];
        if(serviceLine != undefined){
            //Check Features
            if(!isEmpty(serviceLine.includedFeatures)) {
                this.addFeaturesForSubmit(lineFeatures, values, serviceLine.includedFeatures, relation,
                    true, false, false);
            }
            if(!isEmpty(serviceLine.optionalFeatures)) {
                this.addFeaturesForSubmit(lineFeatures, values, serviceLine.optionalFeatures, relation,
                    false, true, false);
            }

            //Check Packages
            if(!isEmpty(serviceLine.includedPackages)) {
                this.addPackagesForSubmit(linePackages, values, serviceLine.includedPackages, relation,
                    true, false, false);
            }
            if(!isEmpty(serviceLine.optionalPackages)) {
                this.addPackagesForSubmit(linePackages, values, serviceLine.optionalPackages, relation,
                    false, true, false);
            }
            if(!isEmpty(serviceLine.mandatoryPackages)) {
                this.addPackagesForSubmit(linePackages ,values, serviceLine.mandatoryPackages, relation,
                    false, false, true);
            }
        }
        return {
            planFeatures: lineFeatures,
            planFeaturePackages: linePackages,
            serviceLineRelation: relation,
            planId: this.props.modalProps.value
        }
    }

    addFeaturesForSubmit = (lineFeatures, values, featuresArray, relation, isIncluded, isOptional, isMandatory) => {
        featuresArray.map(featureId => {
            if(featureId != undefined) {
                lineFeatures.push({
                    planId: values.id,
                    featureId: featureId,
                    isIncluded: isIncluded,
                    isOptional: isOptional,
                    isMandatory: isMandatory,
                    serviceLineRelation: relation
                })
            }
        })
    }

    addPackagesForSubmit = (linePackages, values, packagesArray, relation, isIncluded, isOptional, isMandatory) => {
        packagesArray.map(packageId => {
            if(packageId != undefined) {
                linePackages.push({
                    planId: values.id,
                    featurePackageId: packageId,
                    isIncluded: isIncluded,
                    isOptional: isOptional,
                    isMandatory: isMandatory,
                    serviceLineRelation: relation
                })
            }
        })
    }

    scrollDropdownIntoView = (e) => {
        const elementClicked = e.target.nodeName;
        const boundingElement = e.currentTarget;
        const modalBody = document.getElementsByClassName('modal-body')[0];

        if (elementClicked !== 'LABEL') {

            setTimeout(function () {

                // Scroll down if the bottom is hidden...
                if (boundingElement.getBoundingClientRect().bottom > modalBody.getBoundingClientRect().bottom) {

                    // ... and the top won't end up hidden by scrolling down
                    if (boundingElement.getBoundingClientRect().height < modalBody.getBoundingClientRect().height) {

                        // Scroll down till bottom of element reaches bottom of screen
                        boundingElement.scrollIntoView({block: "end"});
                    }
                }
            }, 200);
        }
    }

    getCurrentFeatures = (features, relation) => {
        let selectedFeatures = [];
        if(features != undefined) {
            features.map(feature => {
                if (feature.planOption.serviceLineRelation != undefined &&
                    feature.planOption.serviceLineRelation == relation) {
                    selectedFeatures.push(feature);
                }
            })
            return selectedFeatures
        }
        return [];
    }

    getCurrentPackages = (packages, relation) => {
        let selectedPackages = [];
        if(packages != undefined) {
            packages.map(featurePackage => {
                if (featurePackage.planOption.serviceLineRelation != undefined &&
                    featurePackage.planOption.serviceLineRelation == relation) {
                    selectedPackages.push(featurePackage);
                }
            })
            return selectedPackages
        }
        return [];
    }

    featurePackageSchema = () => {

    }

    render() {
        const { alertMessage, isPlanLoading, showPackageOptions, showFeatureAndPackages, showOverWritePrice } = this.state;
        const { modalProps, plan, featurePackages, features} = this.props;

        let currentMainFeatures, currentMainPackages, currentRequiredFeatures, currentRequiredPackages,
            currentAdditionalFeatures,   currentAdditionalPackages = "";
        if(plan != undefined) {
            currentMainFeatures = this.getCurrentFeatures(plan.features, "MAIN");
            currentMainPackages = this.getCurrentPackages(plan.featurePackages, "MAIN");
            currentRequiredFeatures = this.getCurrentFeatures(plan.features, "REQUIRED");
            currentRequiredPackages = this.getCurrentPackages(plan.featurePackages, "REQUIRED");
            currentAdditionalFeatures = this.getCurrentFeatures(plan.features, "ADDITIONAL");
            currentAdditionalPackages = this.getCurrentPackages(plan.featurePackages, "ADDITIONAL");
        }

        return (
            <Fragment>
                <div className="modal" style={{display: 'block'}} tabIndex="-1" role="dialog">
                    {isPlanLoading &&
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">
                                    {modalProps.type === 'create' ? 'Add Feature Package' : 'Edit Feature Package'}
                                </h5>
                                <button onClick={this.props.hideModal} type="button" className="close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <Loader/>
                            <div className="modal-footer">
                                <button onClick={this.props.hideModal} className="btn" type="button">
                                    Cancel
                                </button>
                                <button type="submit" className="btn btn-primary">
                                    {modalProps.type === 'create' ? 'Add Feature Package' : 'Edit Feature Package'}
                                </button>
                            </div>
                        </div>
                    </div>
                    }
                    {!isPlanLoading &&
                    <Formik
                        initialValues={{
                            id: plan && plan.planId || null,
                            features: plan && plan.features || [],
                            featurePackages: plan && plan.featurePackages || [],
                            mainServiceLine: {
                                includedFeatures: currentSelectedFeatures(currentMainFeatures,"isIncluded") || [],
                                optionalFeatures: currentSelectedFeatures(currentMainFeatures,"isOptional") || [],
                                includedPackages: currentSelectedFeatures(currentMainPackages,"isIncluded") || [],
                                mandatoryPackages: currentSelectedFeatures(currentMainPackages,"isMandatory") || [],
                                optionalPackages: currentSelectedFeatures(currentMainPackages,"isOptional") || [],
                            },
                            requiredLines: {
                                includedFeatures: currentSelectedFeatures(currentRequiredFeatures,"isIncluded") || [],
                                optionalFeatures: currentSelectedFeatures(currentRequiredFeatures,"isOptional") || [],
                                includedPackages: currentSelectedFeatures(currentRequiredPackages,"isIncluded") || [],
                                mandatoryPackages: currentSelectedFeatures(currentRequiredPackages,"isMandatory") || [],
                                optionalPackages: currentSelectedFeatures(currentRequiredPackages,"isOptional") || [],
                            },
                            additionalLines: {
                                includedFeatures: currentSelectedFeatures(currentAdditionalFeatures,"isIncluded") || [],
                                optionalFeatures: currentSelectedFeatures(currentAdditionalFeatures,"isOptional") || [],
                                includedPackages: currentSelectedFeatures(currentAdditionalPackages,"isIncluded") || [],
                                mandatoryPackages: currentSelectedFeatures(currentAdditionalPackages,"isMandatory") || [],
                                optionalPackages: currentSelectedFeatures(currentAdditionalPackages,"isOptional") || [],
                            }
                        }}
                        validationSchema={this.featurePackageSchema()}
                        onSubmit={this.formSubmit}
                        render={(props) => {

                            const {
                                setFieldValue,
                                handleChange,
                                handleSubmit,
                                handleBlur,
                                values,
                                errors,
                                touched,
                                isSubmitting,
                            } = props;

                            return (
                                <form className="cmv-form" onSubmit={handleSubmit}>
                                    <div className="modal-dialog modal-md">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h5 className="modal-title">
                                                    Features & Packages
                                                </h5>
                                                <button onClick={this.props.hideModal} type="button" className="close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div className="modal-body">
                                                {!isEmpty(alertMessage) && (
                                                    <div className="alert alert-inline alert-danger alert-dismissible">
                                                        <p className="mb-0">{alertMessage}</p>
                                                    </div>
                                                )}
                                                <div className="order-service-order-wrapper">
                                                    <div><h4><i className={plan.serviceModelIcon}/>&nbsp;{plan.name}</h4>
                                                        <p className="billing-job-id">{plan.description}</p>
                                                    </div>
                                                </div>
                                                <div className="form-horizontal">
                                                    {/* Main ServiceLine */}
                                                    <fieldset>
                                                        <legend className="as-label">Main Serviceline</legend>
                                                        <div onClick={this.scrollDropdownIntoView.bind(this)}>
                                                            <FormSelect
                                                                title="Included Features"
                                                                fieldName={"mainServiceLine.includedFeatures"}
                                                                options={featuresAsOptions(matchServiceModel(features,plan.serviceModelId))}
                                                                setFieldValue={setFieldValue}
                                                                value={values.mainServiceLine.includedFeatures}
                                                                isMulti={true}
                                                                errors={errors}
                                                                touched={touched}
                                                                onBlur={handleBlur}
                                                            />
                                                        </div>
                                                        <span>&nbsp;</span>
                                                        <div onClick={this.scrollDropdownIntoView.bind(this)}>
                                                            <FormSelect
                                                                title="Optional Features"
                                                                fieldName={"mainServiceLine.optionalFeatures"}
                                                                options={featuresAsOptions(matchServiceModel(features,plan.serviceModelId))}
                                                                setFieldValue={setFieldValue}
                                                                value={values.mainServiceLine.optionalFeatures}
                                                                isMulti={true}
                                                                errors={errors}
                                                                touched={touched}
                                                                onBlur={handleBlur}
                                                            />
                                                        </div>
                                                        <span>&nbsp;</span>
                                                        <div onClick={this.scrollDropdownIntoView.bind(this)}>
                                                            <FormSelect
                                                                title="Included Packages"
                                                                fieldName={"mainServiceLine.includedPackages"}
                                                                options={packagesAsOptions(matchServiceModel(featurePackages,plan.serviceModelId))}
                                                                setFieldValue={setFieldValue}
                                                                value={values.mainServiceLine.includedPackages}
                                                                isMulti={true}
                                                                errors={errors}
                                                                touched={touched}
                                                                onBlur={handleBlur}
                                                            />
                                                        </div>
                                                        <span>&nbsp;</span>
                                                        <div onClick={this.scrollDropdownIntoView.bind(this)}>
                                                            <FormSelect
                                                                title="Mandatory Packages"
                                                                fieldName={"mainServiceLine.mandatoryPackages"}
                                                                options={packagesAsOptions(matchServiceModel(featurePackages,plan.serviceModelId))}
                                                                setFieldValue={setFieldValue}
                                                                value={values.mainServiceLine.mandatoryPackages}
                                                                isMulti={true}
                                                                errors={errors}
                                                                touched={touched}
                                                                onBlur={handleBlur}
                                                            />
                                                        </div>
                                                        <span>&nbsp;</span>
                                                        <div onClick={this.scrollDropdownIntoView.bind(this)}>
                                                            <FormSelect
                                                                title="Optional Packages"
                                                                fieldName={"mainServiceLine.optionalPackages"}
                                                                options={packagesAsOptions(matchServiceModel(featurePackages,plan.serviceModelId))}
                                                                setFieldValue={setFieldValue}
                                                                value={values.mainServiceLine.optionalPackages}
                                                                isMulti={true}
                                                                errors={errors}
                                                                touched={touched}
                                                                onBlur={handleBlur}
                                                            />
                                                        </div>
                                                        <span>&nbsp;</span>
                                                    </fieldset>
                                                    {/* Required Lines */}
                                                    {(plan.requiredLines && plan.requiredLines > 1) ?
                                                        <fieldset>
                                                            <legend className="as-label">Required Lines</legend>
                                                            <div onClick={this.scrollDropdownIntoView.bind(this)}>
                                                                <FormSelect
                                                                    title="Included Features"
                                                                    fieldName={"requiredLines.includedFeatures"}
                                                                    options={featuresAsOptions(matchServiceModel(features,plan.serviceModelId))}
                                                                    setFieldValue={setFieldValue}
                                                                    value={values.requiredLines.includedFeatures}
                                                                    isMulti={true}
                                                                    errors={errors}
                                                                    touched={touched}
                                                                    onBlur={handleBlur}
                                                                />
                                                            </div>
                                                            <span>&nbsp;</span>
                                                            <div onClick={this.scrollDropdownIntoView.bind(this)}>
                                                                <FormSelect
                                                                    title="Optional Features"
                                                                    fieldName={"requiredLines.optionalFeatures"}
                                                                    options={featuresAsOptions(matchServiceModel(features,plan.serviceModelId))}
                                                                    setFieldValue={setFieldValue}
                                                                    value={values.requiredLines.optionalFeatures}
                                                                    isMulti={true}
                                                                    errors={errors}
                                                                    touched={touched}
                                                                    onBlur={handleBlur}
                                                                />
                                                            </div>
                                                            <span>&nbsp;</span>
                                                            <div onClick={this.scrollDropdownIntoView.bind(this)}>
                                                                <FormSelect
                                                                    title="Included Packages"
                                                                    fieldName={"requiredLines.includedPackages"}
                                                                    options={packagesAsOptions(matchServiceModel(featurePackages,plan.serviceModelId))}
                                                                    setFieldValue={setFieldValue}
                                                                    value={values.requiredLines.includedPackages}
                                                                    isMulti={true}
                                                                    errors={errors}
                                                                    touched={touched}
                                                                    onBlur={handleBlur}
                                                                />
                                                            </div>
                                                            <span>&nbsp;</span>
                                                            <div onClick={this.scrollDropdownIntoView.bind(this)}>
                                                                <FormSelect
                                                                    title="Mandatory Packages"
                                                                    fieldName={"requiredLines.mandatoryPackages"}
                                                                    options={packagesAsOptions(matchServiceModel(featurePackages,plan.serviceModelId))}
                                                                    setFieldValue={setFieldValue}
                                                                    value={values.requiredLines.mandatoryPackages}
                                                                    isMulti={true}
                                                                    errors={errors}
                                                                    touched={touched}
                                                                    onBlur={handleBlur}
                                                                />
                                                            </div>
                                                            <span>&nbsp;</span>
                                                            <div onClick={this.scrollDropdownIntoView.bind(this)}>
                                                                <FormSelect
                                                                    title="Optional Packages"
                                                                    fieldName={"requiredLines.optionalPackages"}
                                                                    options={packagesAsOptions(matchServiceModel(featurePackages,plan.serviceModelId))}
                                                                    setFieldValue={setFieldValue}
                                                                    value={values.requiredLines.optionalPackages}
                                                                    isMulti={true}
                                                                    errors={errors}
                                                                    touched={touched}
                                                                    onBlur={handleBlur}
                                                                />
                                                            </div>
                                                            <span>&nbsp;</span>
                                                        </fieldset>
                                                        : ''
                                                    }
                                                    {/* Additional Lines */}
                                                    {(plan.additionalLines && plan.additionalLines > 0) ?
                                                        <fieldset>
                                                            <legend className="as-label">Additional Lines</legend>
                                                            <div onClick={this.scrollDropdownIntoView.bind(this)}>
                                                                <FormSelect
                                                                    title="Included Features"
                                                                    fieldName={"additionalLines.includedFeatures"}
                                                                    options={featuresAsOptions(matchServiceModel(features,plan.serviceModelId))}
                                                                    setFieldValue={setFieldValue}
                                                                    value={values.additionalLines.includedFeatures}
                                                                    isMulti={true}
                                                                    errors={errors}
                                                                    touched={touched}
                                                                    onBlur={handleBlur}
                                                                />
                                                            </div>
                                                            <span>&nbsp;</span>
                                                            <div onClick={this.scrollDropdownIntoView.bind(this)}>
                                                                <FormSelect
                                                                    title="Optional Features"
                                                                    fieldName={"additionalLines.optionalFeatures"}
                                                                    options={featuresAsOptions(matchServiceModel(features,plan.serviceModelId))}
                                                                    setFieldValue={setFieldValue}
                                                                    value={values.additionalLines.optionalFeatures}
                                                                    isMulti={true}
                                                                    errors={errors}
                                                                    touched={touched}
                                                                    onBlur={handleBlur}
                                                                />
                                                            </div>
                                                            <span>&nbsp;</span>
                                                            <div onClick={this.scrollDropdownIntoView.bind(this)}>
                                                                <FormSelect
                                                                    title="Included Packages"
                                                                    fieldName={"additionalLines.includedPackages"}
                                                                    options={packagesAsOptions(matchServiceModel(featurePackages,plan.serviceModelId))}
                                                                    setFieldValue={setFieldValue}
                                                                    value={values.additionalLines.includedPackages}
                                                                    isMulti={true}
                                                                    errors={errors}
                                                                    touched={touched}
                                                                    onBlur={handleBlur}
                                                                />
                                                            </div>
                                                            <span>&nbsp;</span>
                                                            <div onClick={this.scrollDropdownIntoView.bind(this)}>
                                                                <FormSelect
                                                                    title="Mandatory Packages"
                                                                    fieldName={"additionalLines.mandatoryPackages"}
                                                                    options={packagesAsOptions(matchServiceModel(featurePackages,plan.serviceModelId))}
                                                                    setFieldValue={setFieldValue}
                                                                    value={values.additionalLines.mandatoryPackages}
                                                                    isMulti={true}
                                                                    errors={errors}
                                                                    touched={touched}
                                                                    onBlur={handleBlur}
                                                                />
                                                            </div>
                                                            <span>&nbsp;</span>
                                                            <div onClick={this.scrollDropdownIntoView.bind(this)}>
                                                                <FormSelect
                                                                    title="Optional Packages"
                                                                    fieldName={"additionalLines.optionalPackages"}
                                                                    options={packagesAsOptions(matchServiceModel(featurePackages,plan.serviceModelId))}
                                                                    setFieldValue={setFieldValue}
                                                                    value={values.additionalLines.optionalPackages}
                                                                    isMulti={true}
                                                                    errors={errors}
                                                                    touched={touched}
                                                                    onBlur={handleBlur}
                                                                />
                                                            </div>
                                                            <span>&nbsp;</span>
                                                        </fieldset>

                                                        : ''
                                                    }
                                                </div>
                                            </div>
                                            <div className="modal-footer">
                                                <button onClick={this.props.hideModal} className="btn" type="button">
                                                    Cancel
                                                </button>
                                                <button disabled={isSubmitting} type="submit"
                                                        className="btn btn-primary">
                                                    Save
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            )
                        }}
                    />
                    }
                </div>
                <div className="modal-backdrop show" tabIndex="1"/>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    const modalProps = getModalData(state).modalProps,
        features = getProducts(state).features,
        featurePackages = getProducts(state).featurePackages,
        plan = getProducts(state).plan


    return {
        modalProps,
        features,
        featurePackages,
        plan
    };
};

const mapDispatchToProps = {
    getAllFeatures,
    getAllFeaturePackages,
    updatePlanFeaturesAndPackages,
    clearPlanFeaturesAndPackages,
    getPlan,
    hideModal,
    showModal
};

export default connect(mapStateToProps, mapDispatchToProps)(EditPlanFeaturesAndPackagesModal);

