import axiosInstance from '../../utils/axios-instance';
import {actionCreator, createRequestTypes, getErrorMessage} from '../../utils/helpers';
import {GET_ACCOUNT_NOTIFICATIONS} from "./notifications.actions";

export const SET_ACCOUNT_DETAILS = createRequestTypes('SET_ACCOUNT_DETAILS');
export const SHOW_NEW_ORDER_WIZARD = 'SHOW_NEW_ORDER_WIZARD';
export const SHOW_ACCOUNT_DETAILS_WIZARD = createRequestTypes('SHOW_ACCOUNT_DETAILS_WIZARD');
export const SET_ACCOUNT_PROMISES = createRequestTypes('SET_ACCOUNT_PROMISES');
export const CHANGE_ACCOUNT_STATUS = createRequestTypes('CHANGE_ACCOUNT_STATUS');
export const GET_ACCOUNT_ALLOWED_STATUS_TRANSITIONS = createRequestTypes('GET_ACCOUNT_ALLOWED_STATUS_TRANSITIONS');
export const AUTOPAY = createRequestTypes('AUTOPAY');
export const AUTOPAY_AVAILABLE_CC = createRequestTypes('AUTOPAY_AVAILABLE_CC');
export const GET_SERVICEORDER_ADDRESS = createRequestTypes('GET_SERVICEORDER_ADDRESS');
export const SERVICELINE_ADDRESS = createRequestTypes('SERVICELINE_ADDRESS');

export const getAccountById = (id) => async (dispatch) => {
	dispatch(actionCreator.request(SET_ACCOUNT_DETAILS));
	try {
		const response = await axiosInstance.get(`/account/${id}`);
		dispatch(actionCreator.success(SET_ACCOUNT_DETAILS, response.data));
		return response.data;
	} catch (err) {
		dispatch(actionCreator.failure(SET_ACCOUNT_DETAILS), err.response.data.error);
		return err.response.data.error;
	}
};

export const changeAccountStatus = (status) => async (dispatch) => {
	dispatch(actionCreator.success(CHANGE_ACCOUNT_STATUS, status));
};

export const emptyAccountDetails = () => (dispatch) => {
	dispatch(actionCreator.empty(SET_ACCOUNT_DETAILS));
};

// CPNI POP-UP - HOME SCREEN <== START ==>
export const getCpniById = async (id) => {
	try {
		const response = await axiosInstance.get(`/account/${id}/cpni_open/`);
		return response.data.account_info;
	} catch (err) {}
};

export const sendAccessNote = async (id, note) => {
	try {
		const response = await axiosInstance.post(`/account/${id}/note/`, note);
		return response;
	} catch (err) {}
};
// CPNI POP-UP - HOME SCREEN <== END ==>



export const showEditServiceRequestWizard = (data) => async (dispatch) => {
	try {
		const planId = data.wizardData.serviceOrder.planId;
		const serviceOrderId = data.wizardData.serviceOrder.id;
		const response = await axiosInstance.get(`/order/plan/${planId}/${serviceOrderId}`);
		dispatch(actionCreator.success(SHOW_ACCOUNT_DETAILS_WIZARD,
			{
				...data,
				wizardData: {
					...data.wizardData,
					plans: response.data.plans
				}
			}
			));
	} catch (err) {
		dispatch(actionCreator.failure(SHOW_ACCOUNT_DETAILS_WIZARD, data));
	}
}

export const showAccountDetailsWizard = (data) => (dispatch) => {
	dispatch(actionCreator.success(SHOW_ACCOUNT_DETAILS_WIZARD, data));
}


export const hideAccountDetailsWizard = () => (dispatch) => {
	dispatch(actionCreator.empty(SHOW_ACCOUNT_DETAILS_WIZARD));
};


export const startOrder = async (orderId) => {
	const data = {
		serviceOrders: [
			{
				serviceOrderId: 0,
				variables: {}
			}
		]
	};

	try {
		const response = await axiosInstance.post(`/order/${orderId}/startall`, data);
		return response.data;
	} catch (err) {
		return err.response.data;
	}
};

export const startPendingTasksCount = async (orderId) => {
	try {
		const response = await axiosInstance.get(`/order/${orderId}/tasks`);
		return response.data;
	} catch (err) {
		return err.response.data;
	}
};

export const getAccountPromises = (accountId) => async (dispatch) => {
	dispatch(actionCreator.request(SET_ACCOUNT_PROMISES));
	try {
		const response = await axiosInstance.get(`/account/${accountId}/paymentpromises`);
		dispatch(actionCreator.success(SET_ACCOUNT_PROMISES, response.data.paymentPromises));
	} catch (err) {
		dispatch(actionCreator.failure(SET_ACCOUNT_PROMISES));
	}
};


export const emptyAccountPromises = () => (dispatch) => {
	dispatch(actionCreator.empty(SET_ACCOUNT_PROMISES));
};

export const getAccountAllowedStatusTransitions = (accountId) => async (dispatch) => {
	dispatch(actionCreator.request(GET_ACCOUNT_ALLOWED_STATUS_TRANSITIONS));

	try {
		const response = await axiosInstance.get(`/account/${accountId}/allowed-transitions`);

		dispatch(actionCreator.success(GET_ACCOUNT_ALLOWED_STATUS_TRANSITIONS, response.data.statusTransitions));

		return response;
	} catch(err) {
		dispatch(actionCreator.failure(GET_ACCOUNT_ALLOWED_STATUS_TRANSITIONS, getErrorMessage(err)));

		return err;
	}
};


export const emptyMyAutoPayInfo = () => (dispatch) => {
	dispatch(actionCreator.empty(AUTOPAY));
};

export const getMyAutoPayInfo = (accountId) => async (dispatch) => {
	dispatch(actionCreator.request(AUTOPAY));
	try {
		const response = await axiosInstance.get(`/autopay/${accountId}`);
		dispatch(actionCreator.success(AUTOPAY, response.data));
		return response.data;
	} catch (err) {
		let mockError = {message: err.response.data.error.message};
		dispatch(actionCreator.failure(AUTOPAY, mockError));
		return err.response.data;
	}
};

export const getAutoPayAvailableCC = (accountId) => async (dispatch, getState) => {
	dispatch(actionCreator.request(AUTOPAY_AVAILABLE_CC));
	try {
		const response = await axiosInstance.get(`/autopay/${accountId}/payment-methods`);
		dispatch(actionCreator.success(AUTOPAY_AVAILABLE_CC, response.data.availableCards));
		return response.data.availableCards;
	} catch (err) {
		dispatch(actionCreator.failure(AUTOPAY_AVAILABLE_CC));
	}
};

export const disableAutoPay = (info) => async (dispatch, getState) => {
	dispatch(actionCreator.request(AUTOPAY));
	try {
		const response = await axiosInstance.post(`/autopay/disable`, info);
		dispatch(actionCreator.empty(AUTOPAY));
		return response;
	} catch (err) {
		let mockError = {message: err.response.data.error.message};
		dispatch(actionCreator.failure(AUTOPAY, mockError));
		return err.response.data;
	}
};

export const enableAutoPayACH = (info) => async (dispatch, getState) => {
	dispatch(actionCreator.request(AUTOPAY));
	try {
		const response = await axiosInstance.post(`/autopay/ach`, info);
		dispatch(actionCreator.empty(AUTOPAY));
		return response.data;
	} catch (err) {
		let mockError = {message: err.response.data.error.message};
		dispatch(actionCreator.failure(AUTOPAY, mockError));
		return err.response.data;
	}
};

export const enableAutoPayCC = (info) => async (dispatch, getState) => {
	dispatch(actionCreator.request(AUTOPAY));
	try {
		const response = await axiosInstance.post(`/autopay/cc`, info);
		dispatch(actionCreator.success(AUTOPAY), response.data);
		return response.data;
	} catch (err) {
		let mockError = {message: err.response.data.error.message};
		dispatch(actionCreator.failure(AUTOPAY, mockError));
		return err.response.data;
	}
};
export const enableAutoPayTokenPaymentMethod = (info) => async (dispatch, getState) => {
	dispatch(actionCreator.request(AUTOPAY));
	if (info.token === '') {
		dispatch(actionCreator.failure(AUTOPAY, "Please select a paymente method"));
		return "Please select a paymente method";
	}
	try {
		const response = await axiosInstance.post(`/autopay/token-payment-method`, info);
		dispatch(actionCreator.empty(AUTOPAY));
		return response.data;
	} catch (err) {
		let mockError = {message: err.response.data.error.message};
		dispatch(actionCreator.failure(AUTOPAY, mockError));
		return err.response.data;
	}
};

export const getServiceOrderAddressInformation = (serviceorderId) => async (dispatch) => {
	dispatch(actionCreator.request(GET_SERVICEORDER_ADDRESS));
	try {
		const response = await axiosInstance.get(`/order/serviceorder/${serviceorderId}/address`);
		dispatch(actionCreator.success(GET_SERVICEORDER_ADDRESS, response.data));
		return response.data;
	} catch (err) {
		dispatch(actionCreator.failure(GET_SERVICEORDER_ADDRESS), err.response.data.error);
		return err.response.data.error;
	}
};

export const getServiceLineAddressInformation = (servicelineId) => async (dispatch) => {
	dispatch(actionCreator.request(SERVICELINE_ADDRESS));
	try {
		const response = await axiosInstance.get(`/account/service-line/${servicelineId}/address`);
		dispatch(actionCreator.success(SERVICELINE_ADDRESS, response.data));
		return response.data;
	} catch (err) {
		dispatch(actionCreator.failure(SERVICELINE_ADDRESS), err.response.data.error);
		return err.response.data.error;
	}
};

export const validateServiceLineAddressInformation = (address) => async (dispatch) => {
	dispatch(actionCreator.request(SERVICELINE_ADDRESS));
	try {
		const response = await axiosInstance.post(`/account/service-line/validate/address`, address);
		dispatch(actionCreator.success(SERVICELINE_ADDRESS, response.data));
		return response.data;
	} catch (err) {
		dispatch(actionCreator.failure(SERVICELINE_ADDRESS), err.response.data.error);
		return err.response.data.error;
	}
};
