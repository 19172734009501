import React, { Component, Fragment } from 'react';
import moment from 'moment';
import { isEmpty } from '../../../../../../utils/helpers';
import ContactInput from './Inputs/ContactInput';
import NumberInput from './Inputs/NumberInput';
import EmailInput from './Inputs/EmailInput';

export class UsersForm extends Component {

	constructor(props) {
		super(props);

		this.inputElement = React.createRef();

		this.state = {
			selectedAccount: '',
			isEditable: '',
			isToggled: false,
			isInputDisabled: false,
			isCheckboxDisabled: false,
			errors: {},
		};
	}

	handleSelectAccount = (e) => {
		this.setState({
			selectedAccount: e.target.value
		});
	};

	handleEditField = (fieldName) => {
		let updatedErrors = this.state.errors;
		updatedErrors[fieldName] = "";

		this.setState({
			isEditable: fieldName,
			errors: updatedErrors,
		});
	};

	handleCloseInput = () => {
		this.setState({
			isEditable: '',
			errors: {},
		});
	};

	handleToggle = () => {
		this.setState((prevState) => ({
			isToggled: !prevState.isToggled
		}));
	};

	handleChangeCurrentInput = (fieldName) => {
		let updatedInitialValues = { ...this.props.initialValues };
		updatedInitialValues[fieldName] = this.refs[fieldName].value;

		// this.setState({
		// 	initialValues: updatedInitialValues,
		// 	isEditable: ''
		// });

		this.props.handleChangeInput(updatedInitialValues);
	};

	handleUpdateContact = (fieldName, contactId, currentObject, inputType) => {
		const { accountId } = this.props;
	
		if(inputType === 'checkbox'){
			this.setState({
				isCheckboxDisabled: true
			});
		}else {
			this.setState({
				isInputDisabled: true
			});
		}
		

		let updatedInitialValues = { ...currentObject };
		// updatedInitialValues[fieldName] = this.refs[fieldName].value;
		updatedInitialValues[fieldName] =
			inputType === 'checkbox' ? this.inputElement.checked : this.inputElement.value;

		let data = {
			company: updatedInitialValues.company && updatedInitialValues.company,
			dateOfBirth:
				updatedInitialValues.dateOfBirth && moment(updatedInitialValues.dateOfBirth).format('YYYY-MM-DD'),

			driverLicense: updatedInitialValues.driverLicense && updatedInitialValues.driverLicense,
			firstName: updatedInitialValues.firstName && updatedInitialValues.firstName,
			lastName: updatedInitialValues.lastName && updatedInitialValues.lastName,
			middleName: updatedInitialValues.middleName && updatedInitialValues.middleName,
			ssn: updatedInitialValues.ssn && updatedInitialValues.ssn,
			authorized: updatedInitialValues.authorized && updatedInitialValues.authorized,
			// taxId: updatedInitialValues.taxId && updatedInitialValues.taxId,
			title: updatedInitialValues.title && updatedInitialValues.title
		};

		this.props.updateAccountContact('authorized', accountId, contactId, data).then((response) => {
			if (response.success) {
				// this.setState({
				// 	isInputDisabled: false
				// });
				if(inputType === 'checkbox'){
					this.setState({
						isCheckboxDisabled: false
					});
				}else {
					this.setState({
						isInputDisabled: false
					});
				}
				this.handleCloseInput();
			} else {
				// this.setState({
				// 	isInputDisabled: false
				// });
				if(inputType === 'checkbox'){
					this.setState({
						isCheckboxDisabled: false
					});
				}else {
					this.setState({
						isInputDisabled: false
					});
				}
			}
		});
	};

	handleUpdateNumber = (fieldValue, fieldName, numbersField, contactId) => {
		const { accountId } = this.props;
		let data = {};

		this.setState({
			isInputDisabled: true
		});

		if (numbersField) {
			data = {
				numberType: fieldValue,
				number: this.inputElement.value,
				verified: numbersField.verified
			};
		} else {
			data = {
				numberType: fieldValue,
				number: this.inputElement.value,
				verified: false
			};
		}

		this.props.updateContactNumber('autorized', accountId, contactId, data).then((response) => {
			if (response.success) {
				this.setState({
					isInputDisabled: false
				});
				this.handleCloseInput();
			} else {
				this.setState({
					isInputDisabled: false
				});
			}
		});
	};

	handleUpdateEmail = (fieldValue, fieldName, emailObject, contactId) => {
		const { accountId, primaryContactData, updateContactEmail } = this.props;
		let data = {};

		let updatedErrors = this.state.errors;
		updatedErrors[fieldName] = "";

		this.setState({
			isInputDisabled: true,
			errors: updatedErrors,
		});

		if (emailObject) {
			data = {
				type: fieldValue,
				email: this.inputElement.value,
				validated: emailObject.validated
			};
		}
		else {
			data = {
				type: fieldValue,
				email: this.inputElement.value,
				validated: false
			};
		}

		updateContactEmail('autorized', accountId, contactId, data).then((response) => {
			if (response.success) {
				this.setState({
					isInputDisabled: false
				});
				this.handleCloseInput();
			} else {

				let updatedErrors = this.state.errors;

				if (response && response.error && response.error.message) {
					updatedErrors[fieldName] = response.error.message || "There was an error.";
				}

				this.setState({
					isInputDisabled: false,
					errors: updatedErrors,
				});
			}
		});
	};

	handleUpdatePrimaryAddress = (fieldName, contactId, addressFields) => {
		const { accountId, countryStatesData } = this.props;

		this.setState({
			isInputDisabled: true
		});

		let updatedInitialValues = { ...addressFields };
		updatedInitialValues[fieldName] = this.inputElement.value;
		updatedInitialValues['contactAddressId'] = updatedInitialValues['id'];

		if (updatedInitialValues['state']) {
			updatedInitialValues['stateId'] = countryStatesData.find(
				(x) => x.name === updatedInitialValues['state']
			).id;
			updatedInitialValues['countryId'] = countryStatesData.find(
				(x) => x.name === updatedInitialValues['state']
			).id;
		} else {
			updatedInitialValues['stateId'] = '';
			updatedInitialValues['countryId'] = '';
		}

		let data = {
			address1: updatedInitialValues.address1 || '',
			contactAddressId: updatedInitialValues.id,
			address2: updatedInitialValues.address2 || '',
			address3: updatedInitialValues.address3 || '',
			type: updatedInitialValues.type || '',
			zipcode: updatedInitialValues.zipcode || '',
			city: updatedInitialValues.city || '',
			stateId: updatedInitialValues.stateId || '',
			countryId: updatedInitialValues.countryId || ''
		};

		this.props.updateContactAddress('primary', accountId, contactId, data).then((response) => {
			if (response.success) {
				this.setState({
					isInputDisabled: false
				});
				this.handleCloseInput();
			} else {
				this.setState({
					isInputDisabled: false
				});
			}
		});
	};

	handleUpdateSecurityPhrase = () => {
		const { accountId } = this.props;
		let securityPhraseValue = this.refs.securityPhrase.value;

		let data = {
			securityPhrase: securityPhraseValue
		};

		this.props.updateSecurityPhrase(accountId, data);
		this.handleCloseInput();
	};

	getNumberType = (numbers, type) => {
		if (!numbers) {
			return undefined;
		}
		return numbers.find((x) => x.numberType === type);
	};

	getType = (addresses, type) => {
		if (!addresses) {
			return undefined;
		}

		return addresses.find((x) => x.type === type);
	};

	render() {
		const { selectedAccount, isToggled, isEditable, isInputDisabled, isCheckboxDisabled} = this.state;
		const { usersContactsData, showModal } = this.props;
		let activeAuthorizedUser,
			mailingAddress,
			billingAddress,
			homeNumber,
			mobileNumber,
			workNumber,
			faxNumber,
			personalEmail,
			businessEmail;

		if (usersContactsData) {
			activeAuthorizedUser =
				usersContactsData.find((x) => x.id === Number(this.state.selectedAccount)) || usersContactsData[0];
			if (activeAuthorizedUser) {
				// mailingAddress = this.getType(activeAuthorizedUser.addresses, 'MAILING');
				// billingAddress = this.getType(activeAuthorizedUser.addresses, 'BILLING');

				homeNumber = this.getNumberType(activeAuthorizedUser.numbers, 'HOME');
				mobileNumber = this.getNumberType(activeAuthorizedUser.numbers, 'MOBILE');
				workNumber = this.getNumberType(activeAuthorizedUser.numbers, 'WORK');
				faxNumber = this.getNumberType(activeAuthorizedUser.numbers, 'FAX');

				personalEmail = this.getType(activeAuthorizedUser.emails, 'PERSONAL');
				businessEmail = this.getType(activeAuthorizedUser.emails, 'BUSINESS');
			}
		}

		return (
			<div className="form-section card-toggable">
				<div className="card-toggable-header">
					<span onClick={this.handleToggle} className={isToggled ? 'h-title toggled' : 'h-title'}>
						Users
					</span>
				</div>
				<div className={isToggled ? 'card-toggable-body collapse show' : 'card-toggable-body collapse'}>
					<div className="form-group input-group">
						<select
							className="selectize-generic form-control"
							value={selectedAccount}
							onChange={this.handleSelectAccount}
						>
							{usersContactsData.map((contact) => (
								<option value={contact.id}>
									{contact.firstName} {contact.lastName} {contact.authorized && `(Authorized)`}
								</option>
							))}
						</select>
						<div className="input-group-append">
							<button
								onClick={() => showModal('ADD_CONTACT_USER_MODAL')}
								type="button"
								className="btn btn-primary"
							>
								+New user
							</button>
						</div>
					</div>
					{activeAuthorizedUser && (
						<Fragment>
							<div className="form-subsection">

								<div className="form-header">
									<span className="form-header-title">
										<h6>User</h6>
									</span>
									<span className="form-header-toolbar">
										<span className="h-check">
											<span>
												{activeAuthorizedUser.authorized ? 'Authorized' : 'Unauthorized'}{' '}
												{isCheckboxDisabled && <i className="fas fa-spin fa-circle-notch"/>}
											</span>
											<div className="form-check checkbox-slider checkbox-slider--b-flat">
												<label>
													<input
														name="authorized"
														checked={activeAuthorizedUser.authorized}
														ref={(el) => (this.inputElement = el)}
														// disabled={isCheckboxDisabled}
														id="enable-billing-address"
														type="checkbox"
														onChange={() =>
															this.handleUpdateContact(
																'authorized',
																activeAuthorizedUser.id,
																activeAuthorizedUser,
																'checkbox'
															)}
														// checked={activeAuthorizedUser.authorized}
														// readOnly={true}
													/>
													<span>&nbsp;</span>
												</label>
											</div>
										</span>
									</span>
								</div>

								<ContactInput
									header="First Name"
									isEditable={isEditable}
									contactId={activeAuthorizedUser.id}
									fieldName="firstName"
									currentObject={activeAuthorizedUser}
									handleCloseInput={this.handleCloseInput}
									handleEditField={this.handleEditField}
									handleUpdate={this.handleUpdateContact}
									inputRef={(el) => (this.inputElement = el)}
									isDisabled={isInputDisabled}
								/>

								<ContactInput
									header="Last Name"
									isEditable={isEditable}
									contactId={activeAuthorizedUser.id}
									fieldName="lastName"
									currentObject={activeAuthorizedUser}
									handleCloseInput={this.handleCloseInput}
									handleEditField={this.handleEditField}
									handleUpdate={this.handleUpdateContact}
									inputRef={(el) => (this.inputElement = el)}
									isDisabled={isInputDisabled}
								/>

								<ContactInput
									header="Company"
									isEditable={isEditable}
									contactId={activeAuthorizedUser.id}
									fieldName="company"
									currentObject={activeAuthorizedUser}
									handleCloseInput={this.handleCloseInput}
									handleEditField={this.handleEditField}
									handleUpdate={this.handleUpdateContact}
									inputRef={(el) => (this.inputElement = el)}
									isDisabled={isInputDisabled}
								/>

								{/* <ContactInput
									header="TaxId"
									isEditable={isEditable}
									contactId={activeAuthorizedUser.id}
									fieldName="taxId"
									currentObject={activeAuthorizedUser}
									handleCloseInput={this.handleCloseInput}
									handleEditField={this.handleEditField}
									handleUpdate={this.handleUpdateContact}
									inputRef={(el) => (this.inputElement = el)}
									isDisabled={isInputDisabled}
								/> */}

								<ContactInput
									header="Driver's License"
									isEditable={isEditable}
									contactId={activeAuthorizedUser.id}
									fieldName="driverLicense"
									currentObject={activeAuthorizedUser}
									handleCloseInput={this.handleCloseInput}
									handleEditField={this.handleEditField}
									handleUpdate={this.handleUpdateContact}
									inputRef={(el) => (this.inputElement = el)}
									isDisabled={isInputDisabled}
								/>
							</div>

							<div className="form-subsection">
								<h6>Contact Numbers</h6>
								<NumberInput
									fieldName="numbers.home"
									fieldType="HOME"
									contactId={activeAuthorizedUser.id}
									numberObject={homeNumber}
									handleEditField={this.handleEditField}
									isEditable={isEditable}
									isDisabled={isInputDisabled}
									handleCloseInput={this.handleCloseInput}
									handleUpdatePrimaryNumbers={this.handleUpdateNumber}
									inputRef={(el) => (this.inputElement = el)}
								/>

								<NumberInput
									fieldName="numbers.mobile"
									fieldType="MOBILE"
									contactId={activeAuthorizedUser.id}
									numberObject={mobileNumber}
									handleEditField={this.handleEditField}
									isEditable={isEditable}
									isDisabled={isInputDisabled}
									handleCloseInput={this.handleCloseInput}
									handleUpdatePrimaryNumbers={this.handleUpdateNumber}
									inputRef={(el) => (this.inputElement = el)}
								/>

								<NumberInput
									fieldName="numbers.work"
									fieldType="WORK"
									contactId={activeAuthorizedUser.id}
									numberObject={workNumber}
									handleEditField={this.handleEditField}
									isEditable={isEditable}
									isDisabled={isInputDisabled}
									handleCloseInput={this.handleCloseInput}
									handleUpdatePrimaryNumbers={this.handleUpdateNumber}
									inputRef={(el) => (this.inputElement = el)}
								/>

								<NumberInput
									fieldName="numbers.fax"
									fieldType="FAX"
									contactId={activeAuthorizedUser.id}
									numberObject={faxNumber}
									handleEditField={this.handleEditField}
									isEditable={isEditable}
									isDisabled={isInputDisabled}
									handleCloseInput={this.handleCloseInput}
									handleUpdatePrimaryNumbers={this.handleUpdateNumber}
									inputRef={(el) => (this.inputElement = el)}
								/>
							</div>

							<div className="form-subsection">
								<h6>Emails</h6>
								<EmailInput
									fieldName="emails.personal"
									fieldType="PERSONAL"
									fieldError={this.state.errors['emails.personal']}
									contactId={activeAuthorizedUser.id}
									currentObject={personalEmail}
									handleEditField={this.handleEditField}
									isEditable={isEditable}
									isDisabled={isInputDisabled}
									handleCloseInput={this.handleCloseInput}
									handleUpdate={this.handleUpdateEmail}
									inputRef={(el) => (this.inputElement = el)}
								/>
								<EmailInput
									fieldName="emails.business"
									fieldType="BUSINESS"
									fieldError={this.state.errors['emails.business']}
									contactId={activeAuthorizedUser.id}
									currentObject={businessEmail}
									handleEditField={this.handleEditField}
									isEditable={isEditable}
									isDisabled={isInputDisabled}
									handleCloseInput={this.handleCloseInput}
									handleUpdate={this.handleUpdateEmail}
									inputRef={(el) => (this.inputElement = el)}
								/>
							</div>

							<div className="form-subsection">
								<h6>User Access</h6>

								<ContactInput
									header="Username"
									isEditable={isEditable}
									contactId={activeAuthorizedUser.id}
									fieldName="username"
									currentObject={activeAuthorizedUser.userAccess}
									handleCloseInput={this.handleCloseInput}
									handleEditField={this.handleEditField}
									handleUpdate={this.handleUpdateUserAccess}
									inputRef={(el) => (this.inputElement = el)}
									isDisabled={isInputDisabled}
								/>

								<div className="form-row mb-1">
									<div className="col-sm-5 col-xl-4">Status</div>
									<div className="col-sm-7 col-xl-8">
										<div class="form-check checkbox-slider checkbox-slider--b-flat">
											<label>
												<input
													id="enable-billing-address"
													type="checkbox"
													checked={
														activeAuthorizedUser.userAccess &&
														activeAuthorizedUser.userAccess.isActive
													}
													readOnly={true}
												/>
												<span>&nbsp;</span>
											</label>
										</div>
									</div>
								</div>
							</div>
						</Fragment>
					)}
				</div>
			</div>
		);
	}
}

export default UsersForm;
