import React from 'react';

const Footer = () => {
	return (
		<footer className="cmv-footer">
			<div className="cmv-copyrights">{window.translations['en-us'].footerMessage}</div>
		</footer>
	);
};

export default Footer;
