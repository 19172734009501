import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {hideModal} from '../../../actions/modal.actions';
import FormikInput from "../../../components/Common/Inputs/FormikInput";
import {getAccountDetails, getModalData} from '../../../selectors';
import {Form, Formik} from 'formik';
import * as Yup from "yup";
import isEmpty from "../../../utils/helpers";
import {
    getAccountDistributionMethod,
    getPrimaryContacts,
    updateAccountDistributionMethod
} from "../../../actions/admin.actions";
import Loader from "../../../components/Loader";
import {toastr} from "react-redux-toastr";
import {get} from 'lodash';

const DISTRIBUTION_METHOD = {
    EMAIL: 'EMAIL',
    PAPERLESS: 'PAPERLESS',
    PAPER: 'PAPER'
}

const DISTRIBUTION_METHOD_DESCRIPTION = {
    EMAIL: 'Customer will receive email with PDF Invoice attachment',
    PAPERLESS: 'Customer will receive email letting them know invoice is available in Customer Portal',
    PAPER: 'Customer will receive a paper invoice'
}

const paperlessValidationSchema = Yup.object().shape({
    method: Yup.string().required('Required'),
    isNewEmail: Yup.boolean().required('Required'),
    email: Yup.string()
        .when(['method', 'isNewEmail'], {
            is: (method, isNewEmail) =>
                (method === DISTRIBUTION_METHOD.EMAIL || method === DISTRIBUTION_METHOD.PAPERLESS) && isNewEmail,
            then: Yup.string().email('Invalid email').required("Email is required")
        })
});

class PaperlessModal extends Component {

    state = {
        currentDistributionOption: null,
        currentEmail: null,
    };

    componentDidMount() {
        this.getCurrentOption();
        this.getEmail();
    }

    closeModal = () => {
        this.props.hideModal();
    };

    getEmail = () => {
        const { accountId } = this.props;
        this.props.getPrimaryContacts(accountId).then((response) => {
            if (response) {
                if (response.status === 200) {
                    const emails = response.data.contacts[0].emails;
                    const currentEmail = emails.find((x) => x.type === 'DISTRIBUTION')
                                    || emails.find((x) => x.type === 'PERSONAL')
                                    || emails.find((x) => x.type === 'BUSINESS')
                                    || emails.find((x) => x.type === 'OTHER')
                                    || {};
                    this.setState({ currentEmail: currentEmail })
                }
                else {
                    const errorMessage = this.getErrorMessage(response, 'Could not load contact email');
                    toastr.error(errorMessage, { timeOut: 1000, position: 'top-center' });
                }
            }
        })
    }

    getCurrentOption = () => {
        const { accountId } = this.props;
        this.props.getAccountDistributionMethod(accountId).then((response) => {
            if (response.status === 200) {
                const distributionMethods = response.data.distributionMethods;
                this.setState({currentDistributionOption: distributionMethods.length ? distributionMethods[0] : {}})
            } else {
                const errorMessage = this.getErrorMessage(response, 'Could not load distribution options');
                toastr.error(errorMessage, { timeOut: 1000, position: 'top-center' });
            }
        })
    }

    getErrorMessage = (response, defaultError) => {
        if (response.data && response.data.error && response.data.error.message) {
            return response.data.error.message;
        }

        if (response.message) {
            return response.message;
        }

        return defaultError || "Something went wrong";
    }

    handlePaperlessOptionChange = (setFieldValue, option) => {
        setFieldValue('paperlessOption', option.distributionMethod);
        setFieldValue('method', option.distributionMethod.method);
        setFieldValue('email', '');
        setFieldValue('isNewEmail', false);
    }

    handleFormSubmit = (values, actions) => {
        const method = values.paperlessOption;
        const currentEmail = this.state.currentEmail;
        const { accountId } = this.props;
        const email = isEmpty(values.email) || !values.isNewEmail ? currentEmail.email : values.email;
        const validated = email.toUpperCase() === currentEmail.email.toUpperCase() ? currentEmail.validated : false;
        const data = { distributionMethod: method, email: { email: email, type: 'DISTRIBUTION', validated: validated } };
        this.props.updateAccountDistributionMethod(accountId, data)
            .then((response) => {
                if(response.status === 200) {
                    toastr.success('Distribution method has been updated', { timeOut: 1000, position: 'top-center' });
                    this.props.hideModal();
                    if (this.props.modalProps.onCloseCallback) {
                        this.props.modalProps.onCloseCallback();
                    }
                } else {
                    actions.setSubmitting(false);
                    const errorMessage = this.getErrorMessage(response, 'Could not save distribution method');
                    toastr.error(errorMessage, { timeOut: 1000, position: 'top-center' });
                }
        });
    }

    render() {

        const {
            currentDistributionOption,
            currentEmail
        } = this.state;

        const {
            myDistributionMethods
        } = this.props.modalProps

        if (!isEmpty(myDistributionMethods)) {

            // Move paper to first place
            myDistributionMethods.unshift(
                myDistributionMethods.splice(
                    myDistributionMethods.findIndex(e => e.distributionMethod.name === 'paper')
                    , 1)[0])
        }

        return (
            <Fragment>
                <div className="modal" style={{display: 'block'}} tabIndex="-1" role="dialog">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">

                                <h5 className="modal-title">Paperless</h5>

                                <button onClick={this.closeModal} type="button" className="close">
                                    <span aria-hidden="true">&times;</span>
                                </button>

                            </div>

                            {currentEmail === null || currentDistributionOption === null
                            ?
                            <div className="modal-body">
                                 <Loader/>
                            </div>
                            :
                            <Formik
                                initialValues={{
                                    paperlessOption: currentDistributionOption || {},
                                    method: currentDistributionOption.method,
                                    isNewEmail: false,
                                    email: '',
                                }}
                                validationSchema={paperlessValidationSchema}
                                onSubmit={this.handleFormSubmit}
                                render={({handleChange, handleSubmit, handleBlur, values, errors, touched, isSubmitting, setFieldValue, status, setStatus}) => (
                                    <form onSubmit={handleSubmit}>

                                        <div className="modal-body">
                                            <div className="form-horizontal">
                                                <div className="form-section">

                                                    {myDistributionMethods.map((option) => (
                                                    <Fragment key={option.id}>

                                                        <div className="form-check checkbox-slider checkbox-slider--b-flat">
                                                            <label>
                                                                <input type="radio"
                                                                       name="paperlessOption"
                                                                       disabled={isSubmitting}
                                                                       onChange={() => {
                                                                           this.handlePaperlessOptionChange(setFieldValue, option);
                                                                       }}
                                                                       checked={values.paperlessOption.name === option.distributionMethod.name}
                                                                />
                                                                <span>
                                                                    {option.distributionMethod.description}
                                                                    <p className="text-muted text-xs mb-0">{DISTRIBUTION_METHOD_DESCRIPTION[option.distributionMethod.name.toUpperCase()]}</p>
                                                                    {option.price !== 0 && " ($"+option.price.toFixed(2)+")"}
                                                                </span>
                                                            </label>
                                                        </div>

                                                        {(option.distributionMethod.method.toUpperCase() === DISTRIBUTION_METHOD.EMAIL ||
                                                        option.distributionMethod.method.toUpperCase() === DISTRIBUTION_METHOD.PAPERLESS) &&
                                                        values.paperlessOption.name === option.distributionMethod.name && (
                                                        <div className={"form-section form-section-expanded form-section-customer-payment-invoices" + (isSubmitting ? " form-section-customer-payment-invoices-disabled" : "")}>
                                                            <div className="container">

                                                                <br/>

                                                                {currentEmail.email !== '' &&
                                                                <>
                                                                    <div className="form-row">
                                                                        <div className="col-sm-4"><strong>Email</strong></div>
                                                                        <div className="col-sm-8 text-sm-right">{currentEmail.email}</div>
                                                                    </div>
                                                                    <hr/>
                                                                </>
                                                                }

                                                                <FormikInput
                                                                    wrapperClass="form-group"
                                                                    label="New Email"
                                                                    type="text"
                                                                    id={"email-"+option.distributionMethod.name}
                                                                    name={"email-"+option.distributionMethod.name}
                                                                    onChange={(e) =>{
                                                                        handleChange(e);
                                                                        setFieldValue('email', e.target.value);
                                                                        setFieldValue('isNewEmail', !isEmpty(e.target.value));
                                                                    }}
                                                                    onBlur={handleBlur}
                                                                    placeholder="Enter new email..."
                                                                    touched={touched}
                                                                    status={status}
                                                                />

                                                                {get(errors, `email`) && get(touched, `email-${option.distributionMethod.name}`) &&
                                                                <div className="invalid-feedback">{get(errors, `email`)}</div>
                                                                }

                                                                {get(status, `email`) &&
                                                                <div className="invalid-feedback">{get(status, `email`)}</div>
                                                                }

                                                            </div>
                                                        </div>
                                                        )}
                                                    </Fragment>
                                                    ))}

                                                </div>
                                            </div>
                                        </div>

                                        <div className="modal-footer">

                                            <button
                                                className="btn"
                                                type="button"
                                                onClick={this.closeModal}
                                            >
                                                Close
                                            </button>

                                            <button
                                                className="btn btn-primary"
                                                type="submit"
                                                disabled={isSubmitting}
                                            >
                                                Save Changes
                                            </button>

                                        </div>

                                    </form>
                                )}
                            />
                            }

                        </div>
                    </div>
                </div>
                <div className="modal-backdrop show" tabIndex="1"/>
            </Fragment>
        );
    }
}

const mapDispatchToProps = {
    hideModal,
    getAccountDistributionMethod,
    getPrimaryContacts,
    updateAccountDistributionMethod,
};

const mapStateToProps = (state) => {
    const modalProps = getModalData(state).modalProps,
        accountId = getAccountDetails(state).accountDetails.accountInfo.id;

    return {
        modalProps,
        accountId,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PaperlessModal);
