import React from 'react';
import ReactHtmlParser from 'react-html-parser';

const Plan = ({ currentItem, selectedItem, setSelectedItem }) => {
	// if (selectedItem !== '' && selectedItem !== currentItem.id) {
	// 	return null;
	// }
	return (
		<div
			className={
				selectedItem === currentItem.id ? (
					'card-horizontal card-bundle'
				) : (
					// 'card-horizontal card-bundle card-bundle-selected'
					'card-horizontal card-bundle'
				)
			}
		>
			<div className="card-body">
				<div className="bundle-wrapper">
					<div className="bundle-header">
						<span className="bundle-title">{currentItem.description}</span>
					</div>
					<div className="bundle-description">{ReactHtmlParser(currentItem.note)}</div>
					<div className="bundle-body">
						<div className="row">
							<div className="col-md">
								<i className={currentItem.serviceModel.icon} />
							</div>
							<div className="col-md-6">
								<p className="lead">{currentItem.serviceModel.description}</p>
							</div>
							<div className="col-md">
								{!currentItem.hideFromPrice&&<p className="bundle-price">
									From{' '}
									<span className="lead">
										${currentItem.fromPrice.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
									</span>{' '}
									per month
								</p>}
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="card-footer">
				<button
					onClick={() => setSelectedItem(currentItem, 'plans')}
					className="btn btn-primary btn-select"
					type="submit"
				>
					{/* <i
						className={selectedItem === currentItem.id ? 'fas fa-check-circle' : 'fas fa-circle'}
					/> */}
					&nbsp;Select
				</button>
			</div>
		</div>
	);
};

export default Plan;
