import axiosInstance from '../../utils/axios-instance';
import { actionCreator, createRequestTypes } from '../../utils/helpers';

export const SET_ORDERS = createRequestTypes('SET_ORDERS');
export const SET_ORDER = createRequestTypes('SET_ORDER');
export const SET_LOGS = createRequestTypes('SET_LOGS');
export const SET_NOTES = createRequestTypes('SET_NOTES');
export const ADD_NOTE = createRequestTypes('ADD_NOTE');
export const DELETE_NOTE = createRequestTypes('DELETE_NOTE');
export const SET_QUOTES = createRequestTypes('SET_QUOTES');
export const EXECUTE_ORDER = createRequestTypes('EXECUTE_ORDER');
export const CANCEL_SERVICE_ORDER = createRequestTypes('CANCEL_SERVICE_ORDER');

// ORDERS -- START //
export const getOrders = (accountId) => async (dispatch) => {
	dispatch(actionCreator.request(SET_ORDERS));
	try {
		const response = await axiosInstance.get(`/account/${accountId}/orders/`);
		dispatch(actionCreator.success(SET_ORDERS, response.data.orders));
	} catch (err) {
		dispatch(actionCreator.failure(SET_ORDERS));
	}
};

export const getOrderById = (orderId) => async (dispatch) => {
	dispatch(actionCreator.request(SET_ORDER));
	try {
		const response = await axiosInstance.get(`/order/${orderId}`);
		dispatch(actionCreator.success(SET_ORDER, response.data));
		return response.data;
	} catch (err) {
		dispatch(actionCreator.failure(SET_ORDER));
		return err.response.data;
	}
};

export const executeOrder = (orderId, accountId) => async (dispatch) => {
	const data = {
		serviceOrders: [
			{
				serviceOrderId: 0,
				variables: {}
			}
		]
	};

	dispatch(actionCreator.request(EXECUTE_ORDER));
	try {
		const response = await axiosInstance.post(`/order/${orderId}/startall`, data);
		dispatch(actionCreator.success(EXECUTE_ORDER, response.data));
		dispatch(getOrders(accountId));
		return response.data;
	} catch (err) {
		dispatch(actionCreator.failure(EXECUTE_ORDER));
		return err.response.data;
	}
};

export const cancelServiceOrder = (serviceOrderId, orderId) => async (dispatch) => {
	dispatch(actionCreator.request(CANCEL_SERVICE_ORDER));
	try {
		const response = await axiosInstance.patch(`/order/${serviceOrderId}/cancel`, orderId);
		dispatch(actionCreator.success(CANCEL_SERVICE_ORDER, response.data));
		return response.data;
	} catch (err) {
		dispatch(actionCreator.failure(CANCEL_SERVICE_ORDER));
		return err.response.data;
	}
};

export const getLogs = async (serviceOrderId) => {
	try {
		const response = await axiosInstance.get(`/order/serviceorder/${serviceOrderId}/workflow/state`);
		return response.data;
	} catch (err) {
		return err.response.data;
	}
};

export const getNotes = (accountId) => async (dispatch) => {
	dispatch(actionCreator.request(SET_NOTES));
	try {
		const response = await axiosInstance.get(``);
		if (response.data.success === true) {
			dispatch(actionCreator.success(SET_NOTES, response.data));
		}
	} catch (err) {
		dispatch(actionCreator.failure(SET_NOTES));
	}
};

export const addNote = (accountId) => async (dispatch) => {
	dispatch(actionCreator.request(ADD_NOTE));
	try {
		const response = await axiosInstance.get(``);
		if (response.data.success === true) {
			dispatch(actionCreator.success(ADD_NOTE, response.data));
		}
	} catch (err) {
		dispatch(actionCreator.failure(ADD_NOTE));
	}
};

export const deleteNote = (accountId) => async (dispatch) => {
	dispatch(actionCreator.request(DELETE_NOTE));
	try {
		const response = await axiosInstance.get(``);
		if (response.data.success === true) {
			dispatch(actionCreator.success(DELETE_NOTE, response.data));
		}
	} catch (err) {
		dispatch(actionCreator.failure(DELETE_NOTE));
	}
};

export const emptyOrders = () => (dispatch) => {
	dispatch(actionCreator.empty(SET_ORDERS));
};

export const emptyOrder = () => (dispatch) => {
	dispatch(actionCreator.empty(SET_ORDER));
};

export const emptyLogs = () => (dispatch) => {
	dispatch(actionCreator.empty(SET_LOGS));
};

export const emptyNotes = () => (dispatch) => {
	dispatch(actionCreator.empty(SET_NOTES));
};

// ORDERS -- END //

// QUOTES -- START //

export const getQuotes = (accountId) => async (dispatch) => {
	dispatch(actionCreator.request(SET_QUOTES));
	try {
		const response = await axiosInstance.get(`/account/${accountId}/quotes/`);
		dispatch(actionCreator.success(SET_QUOTES, response.data.quotes));
	} catch (err) {
		dispatch(actionCreator.failure(SET_QUOTES));
	}
};

export const emptyQuotes = () => (dispatch) => {
	dispatch(actionCreator.empty(SET_QUOTES));
};

// QUOTES -- END //
