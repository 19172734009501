import React, {Component} from 'react';
import {connect} from 'react-redux';
import {hideModal} from '../../../actions/modal.actions';
import {getModalData} from "../../../selectors";

class HtmlNotificationModal extends Component {

	closeModal = () => {
		this.props.hideModal();
	};

	render() {

		const { notificationSubject } = this.props.modalProps;

		return (
			<>
				<div className="modal" style={{ display: 'block' }} tabIndex="-1" role="dialog">
					<div className="modal-dialog modal-dialog-wide" role="document">
						<div className="modal-content">

							<div className="modal-header">
								<h5 className="modal-title">{notificationSubject}</h5>
								<button onClick={this.closeModal} type="button" className="close">
									<span>&times;</span>
								</button>
							</div>

							<div className="modal-body modal-body-full">

								<iframe
									ref={"iframe"}
									srcDoc={this.props.modalProps.notificationContent}
									onLoad={() => {
										this.refs.iframe.style.height = this.refs.iframe.contentWindow.document.body.parentElement.getBoundingClientRect().height + "px"
									}}
								/>

							</div>

							<div className="modal-footer">
								<button onClick={this.closeModal} className="btn btn-primary">
									Close
								</button>
							</div>

						</div>
					</div>
				</div>
				<div className="modal-backdrop show" tabIndex="1" />
			</>
		);
	}
}

const mapStateToProps = (state) => {
	const modalProps = getModalData(state).modalProps;

	return {
		modalProps,
	}
};

const mapDispatchToProps = {
	hideModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(HtmlNotificationModal);
