import SideBarLayout from './SideBarLayout/SideBarLayout';
import NoSideBarLayout from './NoSideBarLayout/NoSideBarLayout';

import Dashboard from '../Dashboard';
import AccountSearch from '../AccountSearch';
import AccountDetails from '../AccountDetails';
import TaskDetails from '../TaskDetails';
import CreateAccount from '../CreateAccount';
import CreateServiceRequest from '../CreateServiceRequest';
import TasksList from '../TasksList';
import Reports from '../Reports';
import UserManagement from '../UsersManagement';
import TechnicianCapacity from '../TechnicianCapacity'
import ScheduleCapacity from '../ScheduleCapacity'
import PartnerManagement from "../PartnersManagement";
import AppointmentManagement from "../AppointmentManagement";
import WorkflowDefinitions from "../WorkflowDefinitions";
import BillingJobs from "../BillingJobs";
import AutoPayJobs from "../AutoPayJobs";
import ManualProvisioningWizard from "../ManualProvisioningWizard";
import ProvisioningQueue from "../ProvisioningQueue";
import PaymentProviderSettings from "../Settings/PaymentProviderSettings";
import ProductPlans from "../Products/Plans";
import FeaturePackages from "../Products/FeaturePackages";
import Features from "../Products/Features";
import InstallmentPlans from '../Products/InstallmentPlans';
import Adjustments from "../Products/Adjustments/";
import PortalConfiguration from '../Settings/PortalConfiguration';
import NotificationProperties from '../Settings/NotificationProperties';

const components = {
	home: {
		component: Dashboard,
		url: '/home',
		title: 'Home',
		layout: SideBarLayout,
		permissions: {
			menuPermission: 'SHOW_MENU_CAM',
			moduleName: 'CAM',
			permission: 'CAM_ACC_DETAIL'
		},
		module: 'CAM'
	},
	accountSearch: {
		component: AccountSearch,
		url: '/account-search',
		title: 'Account Search',
		layout: SideBarLayout,
		permissions: {
			menuPermission: 'SHOW_MENU_CAM',
			moduleName: 'CAM',
			permission: 'CAM_ACC_DETAIL'
		},
		module: 'CAM'
	},
	accountDetails: {
		component: AccountDetails,
		url: '/account-details/:id',
		title: 'Account Details',
		layout: NoSideBarLayout,
		permissions: {
			menuPermission: 'SHOW_MENU_CAM',
			moduleName: 'CAM',
			permission: 'CAM_ACC_DETAIL'
		},
		module: 'CAM'
	},
	taskDetails: {
		component: TaskDetails,
		url: '/task-details/:id',
		title: 'Task Details',
		layout: NoSideBarLayout,
		permissions: {
			menuPermission: 'NONE',
			moduleName: '',
			permission: ''
		},
		module: 'CAM'
	},
	taskList: {
		component: TasksList,
		url: '/task-list',
		title: 'Task List',
		layout: SideBarLayout,
		permissions: {
			menuPermission: 'NONE',
			moduleName: '',
			permission: ''
		},
		module: 'CAM'
	},
	createAccount: {
		component: CreateAccount,
		url: '/create-account',
		title: 'Create Account',
		layout: NoSideBarLayout,
		permissions: {
			menuPermission: 'SHOW_MENU_CAM',
			moduleName: 'CAM',
			permission: 'CAM_ACC_ADD'
		},
		module: 'CAM'
	},
	serviceRequest: {
		component: CreateServiceRequest,
		url: '/service-request',
		title: 'Service Request',
		layout: NoSideBarLayout,
		permissions: {
			menuPermission: 'NONE',
			moduleName: '',
			permission: ''
		},
		module: 'CAM'
	},
	reports: {
		component: Reports,
		url: '/report/:id',
		title: 'Reports',
		layout: NoSideBarLayout,
		permissions: {
			menuPermission: 'NONE',
			moduleName: '',
			permission: ''
		},
		module: 'REPO'
	},
	showUsers: {
		component: UserManagement,
		url: '/users-management',
		title: 'Users Management',
		layout: SideBarLayout,
		permissions: {
			menuPermission: 'SHOW_MENU_ADMIN',
			moduleName: 'ADMN',
			permission: 'ADMN_USER_DETAIL'
		},
		module: 'CAM'
	},
	showPartners: {
		component: PartnerManagement,
		url: '/partners-management',
		title: 'Partners Management',
		layout: SideBarLayout,
		permissions: {
			menuPermission: 'SHOW_MENU_ADMIN',
			moduleName: 'ADMN',
			permission: 'ADMN_PARTNER_DETAIL'
		},
		module: 'CAM'
	},
	showTechnicianCapacity: {
		component: TechnicianCapacity,
		url: '/technician-capacity',
		title: 'Technician Capacity',
		layout: SideBarLayout,
		permissions: {
			menuPermission: 'SHOW_MENU_ADMIN',
			moduleName: 'ADMN',
			permission: 'ADMN_USER_DETAIL'
		},
		module: 'CAM'
	},
	showScheduleCapacity: {
		component: ScheduleCapacity,
		url: '/capacity-configuration',
		title: 'Capacity Configuration',
		layout: SideBarLayout,
		permissions: {
			menuPermission: 'SHOW_MENU_ADMIN',
			moduleName: 'ADMN',
			permission: 'ADMN_USER_DETAIL'
		},
		module: 'CAM'
	},
	showAppointmentMapping: {
		component: AppointmentManagement,
		url: '/appointment-management',
		title: 'Appointment Management',
		layout: SideBarLayout,
		permissions: {
			menuPermission: 'SHOW_MENU_ADMIN',
			moduleName: 'ADMN',
			permission: 'ADMN_USER_DETAIL'
		},
		module: 'CAM'
	},
	showWorkflowDefinitions: {
		component: WorkflowDefinitions,
		url: '/workflow-definitions',
		title: 'Workflow Definitions',
		layout: SideBarLayout,
		permissions: {
			menuPermission: 'SHOW_WORKFLOW_DEFINITIONS',
			moduleName: 'ADMN',
			permission: 'ADMN_VIEW_WORKFLOW_DEFINITIONS'
		},
		module: 'CAM'
	},
	showPlans: {
		component: ProductPlans,
		url: '/plans',
		title: 'Plans',
		layout: SideBarLayout,
		permissions: {
			menuPermission: 'SHOW_PLANS',
			moduleName: 'ADMN',
			permission: 'ADMN_PLAN_DETAIL'
		},
		module: 'CAM'
	},
	showFeatures: {
		component: Features,
		url: '/features',
		title: 'Features',
		layout: SideBarLayout,
		permissions: {
			menuPermission: 'NONE',
			moduleName: '',
			permission: ''
		},
		module: 'CAM'
	},
	showFeaturePackages: {
		component: FeaturePackages,
		url: '/feature-packages',
		title: 'Feature Packages',
		layout: SideBarLayout,
		permissions: {
			menuPermission: 'SHOW_FEATURE_PACKAGES',
			moduleName: 'ADMN',
			permission: 'ADMN_FEATURE_PACKAGE_DETAIL'
		},
		module: 'CAM'
	},
	showInstallmentPlans: {
		component: InstallmentPlans,
		url: '/installment-plans',
		title: 'Installment Plans',
		layout: SideBarLayout,
		permissions: {
			menuPermission: 'SHOW_MENU_INSTALLMENT_PLANS',
			moduleName: 'ADMN',
			permission: 'ADMN_VIEW_INSTALLMENT_PLANS'
		},
		module: 'CAM'
	},
	billingJobs: {
		component: BillingJobs,
		url: '/billing-jobs',
		title: 'Billing Jobs',
		layout: SideBarLayout,
		permissions: {
			menuPermission: 'SHOW_BILLING_JOBS',
			moduleName: 'ADMN',
			permission: 'ADMN_VIEW_BILLING_JOBS'
		},
		module: 'CAM'
	},
	autoPayJobs: {
		component: AutoPayJobs,
		url: '/autopay-jobs',
		title: 'AutoPay Jobs',
		layout: SideBarLayout,
		permissions: {
			menuPermission: 'SHOW_AUTOPAY_JOBS',
			moduleName: 'ADMN',
			permission: 'ADMN_VIEW_AUTOPAY_JOBS'
		},
		module: 'CAM'
	},
	manualProvisioning: {
		component: ManualProvisioningWizard,
		url: '/provisioning-tool',
		title: 'Manual Provisioning',
		layout: NoSideBarLayout,
		permissions: {
			menuPermission: 'SHOW_MANUAL_PROVISIONING',
			moduleName: 'OPER',
			permission: 'OPER_MANUAL_PROVISIONING'
		},
		module: 'CAM'
	},
	provisioningQueue: {
		component: ProvisioningQueue,
		url: '/provisioning-queue',
		title: 'Provisioning Queue',
		layout: SideBarLayout,
		permissions: {
			menuPermission: 'NONE',
			moduleName: '',
			permission: ''
		},
		module: 'CAM'
	},
	showProviderSettingsMapping: {
		component: PaymentProviderSettings,
		url: '/provider-settings',
		title: 'Provider Settings',
		layout: SideBarLayout,
		permissions: {
			menuPermission: 'NONE',
			moduleName: 'ADMN',
			permission: 'ADMN_MANAGE_PAYMENT_PROVIDERS'
		},
		module: 'CAM'
	},
	showPortalConfiguration: {
		component: PortalConfiguration,
		url: '/portal-configuration',
		title: 'Portal Configuration',
		layout: SideBarLayout,
		permissions: {
			menuPermission: 'SHOW_PORTAL_CONFIG',
			moduleName: 'ADMN',
			permission: 'ADMN_VIEW_PORTAL_CONFIG'
		},
		module: 'CAM'
	},
	showNotificationProperties: {
		component: NotificationProperties,
		url: '/notification-properties',
		title: 'Notification Properties',
		layout: SideBarLayout,
		permissions: {
			menuPermission: 'SHOW_NOTIFICATIONS_HANDLER_PROPERTIES',
			moduleName: 'ADMN',
			permission: 'ADMN_VIEW_NOTIFICATIONS_HANDLER_PROPERTIES'
		},
		module: 'CAM'
	},
	showAdjustments: {
		component: Adjustments,
		url: '/adjustments',
		title: 'Adjustments',
		layout: SideBarLayout,
		permissions: {
			menuPermission: 'SHOW_ADJUSTMENT',
			moduleName: 'ADMN',
			permission: 'ADMN_VIEW_ADJUSTMENT'
		},
		module: 'CAM'
	},
};

export const routes = [ ...Object.values(components) ];

export const hasAccessPermission = (userPermissions, componentPermission) => {
	if (componentPermission.menuPermission === 'NONE') {
		return true;
	}

	if (
		userPermissions.find((x) => x.name === 'WEB_MENU') &&
		userPermissions.find((x) => x.name === 'WEB_MENU').permissions.includes(componentPermission.menuPermission) &&
		userPermissions.find((x) => x.name === componentPermission.moduleName) &&
		userPermissions
			.find((x) => x.name === componentPermission.moduleName)
			.permissions.includes(componentPermission.permission)
	) {
		return true;
	}

	return false;
};