import React, { Component } from 'react';
import moment from "moment";
import { connect } from "react-redux";
import DatePicker from "react-datepicker";
import axiosInstance from "../../../utils/axios-instance";
import { getUser } from "../../../selectors";

export class TaskDateTimeSlot extends Component {
    state = {
        availableDateTimeSlots: [],
        capacity: this.props.formAttributes.taskDuration,
        availableDateSlots: [],
        availableTimeSlots: [],
        overbookingEnabled: false,
        availableForOverbookingDateSlots: [],
        allTimeslots: []
    }

    componentDidMount() {
        this.reloadAllTimeslots();
        this.reloadAvailableSlots();
    }

    reloadAvailableSlots = () => {
        try {
            axiosInstance.get(`/trouble-ticket/appointments/available-dates/${this.state.capacity}`)
                .then(response => {
                    this.setState({
                        availableDateTimeSlots: response.data.slots,
                        availableDateSlots: response.data.slots.map(entry => {
                            return moment(entry.capacityDate, 'YYYY-MM-DD').toDate();
                        }),
                        availableForOverbookingDateSlots: response.data.availableForOverbooking.map(entry => {
                            return moment(entry, 'YYYY-MM-DD').toDate();
                        })
                    })
                })
                .catch(errorResponse => {
                    this.handlerErrorResponse(errorResponse);
                });
        } catch (err) {
            this.handlerErrorResponse(err);
        }
    }

    reloadAllTimeslots = () => {
        try {
            axiosInstance.get(`/trouble-ticket/appointments/timeslots`)
                .then(response => {
                    this.setState({
                        allTimeslots: response.data.timeslots
                    })
                })
                .catch(errorResponse => {
                    this.handlerErrorResponse(errorResponse);
                });
        } catch (err) {
            this.handlerErrorResponse(err);
        }
    }

    handleDateChange = (date) => {
        this.setState({ availableTimeSlots: [] });
        let timeslots = [];
        if (this.state.availableDateTimeSlots) {
            const filteredByDate = this.state.availableDateTimeSlots.filter((dateTimeSlot) => {
                const tempDate = new Date(dateTimeSlot.capacityDate);
                return date.getFullYear() === tempDate.getFullYear() && date.getDate() === tempDate.getDate() && date.getMonth() === tempDate.getMonth();
            })

            timeslots = filteredByDate.filter((v, i, a) => a.findIndex(t => (t.timeSlotId === v.timeSlotId)) === i);

            this.setState({ availableTimeSlots: timeslots });
        }
    }

    render() {
        const {
            errors, touched, values, setFieldValue, handleBlur
        } = this.props;
        
        
        return (
            <div className="form-group" >
                {this.props.userRole && (this.props.userRole == "ADMIN" || this.props.userRole == "SUPER_ADMIN") &&
                    <div className="form-check checkbox-slider checkbox-slider--b-flat">
                        <label className="w-100">
                            <input
                                type="checkbox"
                                name="is_overbooking"
                                id="overbooking"
                                checked={this.state.overbookingEnabled}
                                onClick={(e) => {
                                    if(this.state.overbookingEnabled) {
                                        setFieldValue('is_overbooking', false);
                                        this.setState({overbookingEnabled: false});
                                    } else {
                                        setFieldValue('is_overbooking', true);
                                        this.setState({overbookingEnabled: true})
                                    }
                                }}
                            />
                            <span>Overbook</span>
                        </label>
                    </div>
                }
                <label>Date</label>
                <div className="customDatePickerWidth">
                    {this.props.userRole && (this.props.userRole == "ADMIN" || this.props.userRole == "SUPER_ADMIN") && this.state.overbookingEnabled && 
                    <DatePicker
                        className={"form-control text-left" + (touched.task_date && errors.task_date ? " is-invalid" : "")}
                        fieldName="task_date"
                        dateFormat="yyyy/MM/dd"
                        placeholderText="Enter Task Date"
                        autoFocus={false}
                        showTimeSelect={false}
                        shouldCloseOnSelect={true}
                        popperPlacement={'top'}
                        selected={values.task_date !== '' ? values.task_date : null}
                        onChange={(task_date) => {
                            const normalizedDate = new Date(task_date.getTime());
                            this.handleDateChange(normalizedDate);
                            this.setState({ selectedDate: normalizedDate });
                            setFieldValue('task_date', normalizedDate);
                        }
                        }
                        minDate={moment().toDate()}
                        includeDates={this.state.availableForOverbookingDateSlots}
                    />
                    }
                    {!this.state.overbookingEnabled && 
                    <DatePicker
                        className={"form-control text-left" + (touched.task_date && errors.task_date ? " is-invalid" : "")}
                        fieldName="task_date"
                        dateFormat="yyyy/MM/dd"
                        placeholderText="Enter Task Date"
                        autoFocus={false}
                        showTimeSelect={false}
                        shouldCloseOnSelect={true}
                        popperPlacement={'top'}
                        selected={values.task_date !== '' ? values.task_date : null}
                        onChange={(task_date) => {
                            const normalizedDate = new Date(task_date.getTime());
                            this.handleDateChange(normalizedDate);
                            this.setState({ selectedDate: normalizedDate });
                            setFieldValue('task_date', normalizedDate);
                        }
                        }
                        minDate={moment().toDate()}
                        includeDates={this.state.availableDateSlots}
                    /> 
                    }
                </div>
                {errors.task_date && touched.task_date &&
                    <div className="invalid-feedback">{errors.task_date}</div>
                }

                <label>Timeslot</label>
                {this.props.userRole && (this.props.userRole == "ADMIN" || this.props.userRole == "SUPER_ADMIN") && this.state.overbookingEnabled &&
                <select
                    className={
                        errors.time_slot_id && touched.time_slot_id ? (
                            'form-control is-invalid'
                        ) : (
                                'form-control customer-notes-cat'
                            )
                    }
                    placeholder="Please select time"
                    value={values.time_slot_id}
                    onChange={(event) => {
                        const time_slot = this.state.allTimeslots.find(dts => dts.id == event.target.value);

                        setFieldValue('time_slot', time_slot.fromTime + " - " + time_slot.toTime);
                        setFieldValue('time_slot_id', event.target.value);
                    }}
                    onBlur={handleBlur}
                    name="time_slot_id"
                >
                    <option value="">Please select time</option>

                    {this.state.allTimeslots.map((timeSlot) => {
                        return (
                            <option value={timeSlot.id} key={timeSlot.id}>
                                {timeSlot.fromTime + " - " + timeSlot.toTime}
                            </option>
                        );
                    })}
                </select>
                }
                
                {!this.state.overbookingEnabled &&
                <select
                    className={
                        errors.time_slot_id && touched.time_slot_id ? (
                            'form-control is-invalid'
                        ) : (
                                'form-control customer-notes-cat'
                            )
                    }
                    placeholder="Please select time"
                    value={values.time_slot_id}
                    onChange={(event) => {
                        const time_slot = this.state.availableDateTimeSlots.find(dts => dts.timeSlotId == event.target.value);

                        setFieldValue('time_slot', time_slot.fromTime + " - " + time_slot.toTime);
                        setFieldValue('time_slot_id', event.target.value);
                    }}
                    onBlur={handleBlur}
                    name="time_slot_id"
                >
                    <option value="">Please select time</option>

                    {this.state.availableTimeSlots.map((dateTimeSlot) => {
                        return (
                            <option value={dateTimeSlot.timeSlotId} key={dateTimeSlot.timeSlotId}>
                                {dateTimeSlot.fromTime + " - " + dateTimeSlot.toTime}
                            </option>
                        );
                    })}
                </select>
                }
                {errors.time_slot_id &&
                    touched.time_slot_id && (
                        <div className="invalid-feedback">{errors.time_slot_id}</div>
                    )}
            </div>
        );

    }
}

const mapStateToProps = (state) => {
    const userRole = getUser(state).user.systemUserRole.name;

    return {
        userRole
    };
};

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(TaskDateTimeSlot);