import React, { Component } from 'react';
import PropTypes from 'prop-types';

class NotificationSelectInput extends Component {
	state = {
		inputValue: this.props.reciverInfo.options && this.props.reciverInfo.options.find((x) => x.active === true)
			? this.props.reciverInfo.options.find((x) => x.active === true).value
			: ''
	};

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.reciverInfo.active !== this.props.reciverInfo.active && this.props.reciverInfo.active === false) {
			this.setState({
				inputValue: ''
			});
		}
	}

	handleInputChange = (e) => {
		const { reciverInfo, handleNotificationSubscribe, handleNotificationUnsubscribe, categoryId } = this.props;
		this.props.disableForSending();

		const value = e.target.value;

		if (value !== '') {
			handleNotificationSubscribe({
				notificationCategoryId: categoryId,
				type: reciverInfo.type,
				receiver: e.target.value
			});
		} else {
			handleNotificationUnsubscribe({
				notificationCategoryId: categoryId,
				type: reciverInfo.type
			});
		}

		this.setState({
			inputValue: e.target.value
		});
	};

	render() {
		const { inputValue } = this.state;
		const { reciverInfo, isDisabled } = this.props;

		return (
			<div className="form-group col-sm-6">
				<label>{reciverInfo.type}</label>
				<small className="form-text text-muted mt-0 mb-1">
					<span>
						Last used:{' '}
						{reciverInfo.options && reciverInfo.options.find((x) => x.active === true) &&
							reciverInfo.options.find((x) => x.active === true).value}
					</span>
					<span className="font-weight-bold notification-channel-last-sms" />
				</small>
				<select
					className="form-control form-control-sm notification-channel-sms"
					onChange={this.handleInputChange}
					value={inputValue}
					disabled={isDisabled}
				>
					<option value="">Please Choose</option>
					{reciverInfo.options && reciverInfo.options.map((option) => (
						<option value={option.value} key={option.id}>
							{option.value}
						</option>
					))}
				</select>
			</div>
		);
	}
}

NotificationSelectInput.propTypes = {
	reciverInfo: PropTypes.object
};

export default NotificationSelectInput;
