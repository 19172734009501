import React, {Component} from 'react';

export class GroupNumbers extends Component {

    render() {

        const {
            numbers,
        } = this.props;

        return (
            <div className="provisioning-group-numbers">
                <div className="row">
                    {numbers.map(number =>
                        <div className="col" key={number.id}>
                            <h6>{number.systemNumTypeName}</h6>
                            <p>{number.number}</p>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default GroupNumbers;
