import React, {Component, Fragment} from 'react';
import TextInput from '../../../../components/UI/TextInput';
import FormSelect from '../../../../components/UI/FormSelect';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import AccountHolderLoader from '../../../../components/Loader/AccountHolderLoader';
import {statusColor} from '../../../../utils/ui';
import {formSelectStyles} from '../../../../utils/SelectStyles';
import {Link} from 'react-router-dom';
import {debounce, get} from 'lodash';
import moment from 'moment';
import axiosInstance from "../../../../utils/axios-instance";
import isEmpty from "../../../../utils/helpers";
import {generateFullZip, setServiceAddressAsBilling} from "../../utils";

const extractValidationError = (error) => {
    const validationError = get(error, 'response.data.error.message');
    if (validationError) {
        return validationError;
    }

    if (error.message) {
        return "Validation error: " + error.message;
    }

    console.error("Unexpected validation error", error);

    return "Unexpected validation error";
}

class DynamicForm extends Component {

    state = {
        serviceAddressAsBilling: false
    }

    componentDidMount() {
        const { accountHolderEnableFields, accountHolderRequiredFields} = this.props;
        if (accountHolderEnableFields.memberNumber && accountHolderRequiredFields.memberNumber) {
            this.generateAccountContactMemberNumber();
        }
    }

    generateAccountContactMemberNumber = () => {
        const memberNumber = this.props.values.memberNumber;
        if(!memberNumber) {
            const setFieldValue = this.props.setFieldValue;
            this.props.getAccountContactMemberNumber()
                .then((response) => {
                    if(response && response.status === 200) {
                        setFieldValue('accountHolder.memberNumber', response.data.memberNumber);
                    }
                })
        }
    }

    validateEmail = (field, value) => {
        let currentStatus = !isEmpty(this.props.status) ? this.props.status : {};

        // Clear field status, but retain key to prevent form submission before validation
        this.props.setStatus({
            ...currentStatus,
            [field]: '',
        });

        // If field is not empty
        if (value !== "") {

            // Validate email
            if (field === 'accountHolder.personalEmail') this.asyncPersonalEmail(field, value);
            if (field === 'accountHolder.businessEmail') this.asyncBusinessEmail(field, value);
        }
        // If field is empty
        else {

            // Remove field from status to unlock form submission if field is optional
            delete currentStatus[field]

            this.props.setStatus({
                ...currentStatus,
            });
        }
    }

    asyncPersonalEmail = debounce((field, value) => {

        axiosInstance.get('/verification/email/valid', {params: {email: value}})
            .then((response) => {

                let currentStatus = this.props.status;
                delete currentStatus[field];
                this.props.setStatus(currentStatus);
            })
            .catch((error) => {

                let currentStatus = this.props.status;
                this.props.setStatus({
                    ...currentStatus,
                    [field]: extractValidationError(error),
                });
            });
    }, 500);

    asyncBusinessEmail = debounce((field, value) => {

        axiosInstance.get('/verification/email/valid', {params: {email: value}})
            .then((response) => {

                let currentStatus = this.props.status;
                delete currentStatus[field];
                this.props.setStatus(currentStatus);
            })
            .catch((error) => {

                let currentStatus = this.props.status;
                this.props.setStatus({
                    ...currentStatus,
                    [field]: extractValidationError(error),
                });
            });
    }, 500);

    render() {

        const {
            serviceAddressAsBilling
        } = this.state;

        const {
            values,
            handleChange,
            handleBlur,
            errors,
            touched,
            setFieldTouched,
            setFieldValue,
            countryStatesOptions,
            questionsOptions,
            mailingAddressValidation,
            useSuggestedMailingAddress,
            showSuggestedMailingAddress,
            suggestedMailingAddress,
            suggestedMailingAddressError,
            mailingAddressLoader,
            accountHolderEnableFields,
            accountHolderOptionalFields,
            existingAccountValidation,
            showSuggestedExistingAccounts,
            suggestedExistingAccounts,
            handleExistingAccountChange,
            selectedExistingAccount,
            existingAccountsLoader,
            closeSuggestion,
            authCode,
            onSubmitAuthCode,
            onSubmitVerifyAuthCode,
            showVerificationMobileNumber,
            onChangeAuthCodeHandler,
            authCodeLoader,
            authCodeVerifyLoader,
            isAuthCodeValid,
            resetAuthCodeState,
            accountTiers,
            showEvaluationStatus,
            handleEvaluateStatusChange,
            evaluationLoader,
            evaluationData,
            resetEvaluateStatus,
            continueWithServiceRequest,
            isServiceRequestStarted,
            status,
            validatedAddress,
        } = this.props;

        return (
            <div className="form-new-account-type-home">

                <h4>
                    <i className="fas fa-fw fa-user-lock"/>&nbsp; Account Security
                </h4>

                <div className="form-group">
                    <label htmlFor="accountHolder.securityPhrase">
                        Account Security Phrase
                    </label>
                    <input
                        type="text"
                        value={values.accountHolder.securityPhrase}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        id="accountHolder.securityPhrase"
                        name="accountHolder.securityPhrase"
                        className={
                            errors.accountHolder &&
                            touched.accountHolder &&
                            errors.accountHolder.securityPhrase &&
                            touched.accountHolder.securityPhrase ? (
                                'form-control is-invalid'
                            ) : (
                                'form-control'
                            )
                        }
                        placeholder="Account Security Phrase"
                    />
                    {errors.accountHolder &&
                    touched.accountHolder &&
                    errors.accountHolder.securityPhrase &&
                    touched.accountHolder.securityPhrase && (
                        <div className="invalid-feedback">{errors.accountHolder.securityPhrase}</div>
                    )}
                </div>

                <h4>
                    <i className="fas fa-fw fa-user"/>&nbsp; Account Holder
                </h4>

                {existingAccountsLoader ? (
                    <AccountHolderLoader/>
                ) : (
                    showSuggestedExistingAccounts && (
                        <div className="cmv-container-subpanel cmv-container-subpanel-suggested-account">

                            <h5><i className="fas fa-database"/> Account Data Match</h5>

                            <p>The following was found in our database:</p>

                            <Select
                                options={suggestedExistingAccounts && suggestedExistingAccounts.map((account) => {
                                    return {
                                        value: account.id,
                                        label: `${account.name} [${account.number}][${account.status}] @ ${account.billingAddress ? account.billingAddress : ''}`,
                                        ...account
                                    };
                                })}
                                onChange={handleExistingAccountChange}
                                value={selectedExistingAccount}
                                styles={formSelectStyles}
                            />

                            <div className="container-matched-data">
                                <div className="data-column-container">
                                    <div className="data-column data-column-full-width">
                                        <dl>
                                            <dt>Account #</dt>
                                            <dd>
                                                {selectedExistingAccount.number || 'N/A'}
                                                <div className={`cmv-client-status cmv-client-status-${statusColor(selectedExistingAccount.tierLevel)}`}>
													<span className="status-text">{selectedExistingAccount.status}</span>
                                                    <div className={`status-bar status-bar-${selectedExistingAccount.tierLevel * 20}`}/>
                                                </div>
                                            </dd>
                                            <dt>Account Type</dt>
                                            <dd>{selectedExistingAccount.accountType || 'N/A'}</dd>
                                            <dt>Account Holder</dt>
                                            <dd>{selectedExistingAccount.name || 'N/A'}</dd>
                                            <dt>Billing Address</dt>
                                            <dd>{selectedExistingAccount.billingAddress || 'N/A'}</dd>
                                            <dt>Security Phrase</dt>
                                            <dd>{selectedExistingAccount.securityPhrase || 'N/A'}</dd>
                                            <dt>Company</dt>
                                            <dd>{selectedExistingAccount.company || 'N/A'}</dd>
                                        </dl>
                                    </div>
                                </div>
                            </div>

                            <div className="d-flex justify-content-end mt-1">

                                <a
                                    onClick={() => closeSuggestion('showSuggestedExistingAccounts')}
                                    className="btn btn-outline-primary mr-1 btn-account-keep"
                                >
                                    Dismiss
                                </a>

                                {isServiceRequestStarted && (
                                    <button
                                        className="btn btn-primary"
                                        onClick={() => continueWithServiceRequest(selectedExistingAccount.id)}
                                    >
                                        Select Account
                                    </button>
                                )}

                                {!isServiceRequestStarted && (
                                    <Link
                                        to={{pathname: `/account-details/${selectedExistingAccount.id}`}}
                                        className="btn btn-primary"
                                    >
                                        View Account
                                    </Link>
                                )}

                            </div>
                        </div>
                    )
                )}

                <div className="form-row">

                    {accountHolderEnableFields.firstName && (
                        <div className="form-group col-xs col-md-3">
                            <label htmlFor="accountHolder.firstName">First Name</label>
                            <input
                                className={'form-control' + (get(errors, `accountHolder.firstName`) && get(touched, `accountHolder.firstName`) ? ' is-invalid' : '')}
                                id="accountHolder.firstName"
                                name="accountHolder.firstName"
                                placeholder={'First Name' + (accountHolderOptionalFields.firstName ? ' (optional)' : '')}
                                type="text"
                                value={values.accountHolder.firstName}
                                onChange={(e) => {
                                    setFieldValue(e.target.name, e.target.value);
                                    setTimeout(() => existingAccountValidation(this.props), 0);
                                }}
                                onBlur={handleBlur}
                            />
                            {get(errors, `accountHolder.firstName`) &&
                            get(touched, `accountHolder.firstName`) && (
                                <div className="invalid-feedback">{get(errors, `accountHolder.firstName`)}</div>
                            )}
                        </div>
                    )}

                    {accountHolderEnableFields.middleName && (
                        <TextInput
                            type="text"
                            divClass="col-md-3"
                            placeholder={'Middle Name' + (accountHolderOptionalFields.middleName ? ' (optional)' : '')}
                            label="Middle Name"
                            id="accountHolder.middleName"
                            name="accountHolder.middleName"
                            value={values.accountHolder.middleName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            touched={touched}
                            errors={errors}
                        />
                    )}

                    {accountHolderEnableFields.lastName && (
                        <div className="form-group col-xs col-md-3">
                            <label htmlFor="accountHolder.lastName">Last Name</label>
                            <input
                                className={'form-control' + (get(errors, `accountHolder.lastName`) && get(touched, `accountHolder.lastName`) ? ' is-invalid' : '')}
                                id="accountHolder.lastName"
                                name="accountHolder.lastName"
                                placeholder={'Last Name' + (accountHolderOptionalFields.lastName ? ' (optional)' : '')}
                                type="text"
                                value={values.accountHolder.lastName}
                                onChange={(e) => {
                                    setFieldValue(e.target.name, e.target.value);
                                    setTimeout(() => existingAccountValidation(this.props), 0);
                                }}
                                onBlur={handleBlur}
                            />
                            {get(errors, `accountHolder.lastName`) &&
                            get(touched, `accountHolder.lastName`) && (
                                <div className="invalid-feedback">{get(errors, `accountHolder.lastName`)}</div>
                            )}
                        </div>
                    )}

					{accountHolderEnableFields.company && (
                        <TextInput
                            type="text"
                            divClass="col-md-3"
                            placeholder={'Company' + (accountHolderOptionalFields.company ? ' (optional)' : '')}
                            label="Company"
                            id="accountHolder.company"
                            name="accountHolder.company"
                            value={values.accountHolder.company}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            touched={touched}
                            errors={errors}
                        />
                    )}

                </div>

                <div className="form-row">

                    {accountHolderEnableFields.driverLicense && (
                        <TextInput
                            type="text"
                            divClass="col-md-6"
                            placeholder={'Driver License' + (accountHolderOptionalFields.driverLicense ? ' (optional)' : '')}
                            label="Driver License"
                            id="accountHolder.driverLicense"
                            name="accountHolder.driverLicense"
                            value={values.accountHolder.driverLicense}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            touched={touched}
                            errors={errors}
                        />
                    )}

                    {accountHolderEnableFields.dateOfBirth && (
                        <div className="form-group col-md-6">
                            <label htmlFor="accountHolder.dateOfBirth">Date of Birth</label>
                            <DatePicker
                                type="date"
                                className={'form-control' + (get(errors, `accountHolder.dateOfBirth`) && get(touched, `accountHolder.dateOfBirth`) ? ' is-invalid' : '')}
                                placeholderText={'MM/DD/YYYY' + (accountHolderOptionalFields.dateOfBirth ? ' (optional)' : '')}
                                id="accountHolder.dateOfBirth"
                                name="accountHolder.dateOfBirth"
                                selected={values.accountHolder.dateOfBirth}
                                maxDate={moment().toDate()}
                                onChange={(values) => setFieldValue('accountHolder.dateOfBirth', values)}
                                onBlur={handleBlur}
                            />
                            {get(errors, `accountHolder.dateOfBirth`) &&
                            get(touched, `accountHolder.dateOfBirth`) && (
								<div className="invalid-feedback">
									{get(errors, `accountHolder.dateOfBirth`)}
								</div>
							)}
                        </div>
                    )}

                </div>

                <div className="form-row">

                    {accountHolderEnableFields.mobileNumber && (
                        <div className="form-group col-xs col-md-6">
                            <label htmlFor="accountHolder.mobileNumber.number">Mobile Phone</label>
                            <div className="input-group">

                                <input
                                    type="text"
                                    id="accountHolder.mobileNumber.number"
                                    name="accountHolder.mobileNumber.number"
                                    value={values.accountHolder.mobileNumber.number}
                                    className={'form-control' + (get(errors, `accountHolder.mobileNumber.number`) && get(touched, `accountHolder.mobileNumber.number`) ? ' is-invalid' : '')}
                                    placeholder={'Mobile Phone' + (accountHolderOptionalFields.mobileNumber ? ' (optional)' : '')}
                                    onChange={(values) => {
                                        handleChange(values);
                                        resetAuthCodeState(setFieldValue);
                                    }}
                                    onBlur={handleBlur}
                                />
                                {get(errors, `accountHolder.mobileNumber.number`) &&
                                get(touched, `accountHolder.mobileNumber.number`) && (
                                    <div className="invalid-feedback">
                                        {get(errors, `accountHolder.mobileNumber.number`)}
                                    </div>
                                )}

                                <div
                                    className={
                                        showVerificationMobileNumber &&
                                        !authCodeLoader &&
                                        !authCodeVerifyLoader &&
                                        isAuthCodeValid === null ? (
                                            'input-group-append input-authcode-input'
                                        ) : (
                                            'input-group-append input-authcode-input d-none'
                                        )
                                    }
                                >
                                    <div className="input-group">
                                        <div className="input-group-prepend">
											<span className="input-group-text">
												<i className="fas fa-key"/>
											</span>
                                        </div>
                                        <input
                                            type="text"
                                            name="authCode"
                                            value={authCode}
                                            onChange={onChangeAuthCodeHandler}
                                            className="form-control w-120px authcode-key-phone"
                                            placeholder="AuthCode"
                                        />
                                    </div>
                                </div>

                                <div
                                    className={
                                        showVerificationMobileNumber &&
                                        !authCodeLoader &&
                                        !authCodeVerifyLoader &&
                                        isAuthCodeValid === null ? (
                                            'input-group-append input-authcode-validate'
                                        ) : (
                                            'input-group-append input-authcode-validate d-none'
                                        )
                                    }
                                >
                                    <button
                                        className="btn btn-outline-secondary btn-authcode-validate"
                                        type="button"
                                        onClick={() =>
                                            onSubmitVerifyAuthCode(
                                                values.accountHolder.mobileNumber.number,
                                                setFieldValue
                                            )}
                                    >
                                        <i className="fas fa-check"/> Verify
                                    </button>
                                </div>

                                <div className="input-group-append input-authcode-status">
                                    {authCodeLoader && (
                                        <span className="input-group-text">
											<i className="fas fa-circle-notch fa-spin ml-1 mr-1"/>&nbsp;Requesting
										</span>
                                    )}
                                    {authCodeVerifyLoader && (
                                        <span className="input-group-text">
											<i className="fas fa-circle-notch fa-spin ml-1 mr-1"/>&nbsp;Verifying...
										</span>
                                    )}
                                    {isAuthCodeValid === true &&
                                    !authCodeLoader && (
                                        <span className="input-group-text text-success">
											<i className="fas fa-check"/>&nbsp;Valid
										</span>
                                    )}

                                    {isAuthCodeValid === false &&
                                    !authCodeLoader && (
                                        <span className="input-group-text text-danger">
											<i className="fas fa-exclamation-triangle"/>&nbsp;Invalid
										</span>
                                    )}
                                </div>

                                <div
                                    className={
                                        !showVerificationMobileNumber && !authCodeLoader && !authCodeVerifyLoader ? (
                                            'input-group-append input-authcode-send'
                                        ) : (
                                            'input-group-append input-authcode-send d-none'
                                        )
                                    }
                                >
                                    <button
                                        className="btn btn-outline-secondary btn-authcode-send"
                                        type="button"
                                        disabled={values.accountHolder.mobileNumber.number.length === 0}
                                        onClick={() => onSubmitAuthCode(values.accountHolder.mobileNumber.number)}
                                    >
                                        <i className="fas fa-paper-plane"/> Send
                                    </button>
                                </div>

                                <div
                                    className={
                                        showVerificationMobileNumber &&
                                        !authCodeLoader &&
                                        !authCodeVerifyLoader &&
                                        isAuthCodeValid !== true ? (
                                            'input-group-append input-authcode-send'
                                        ) : (
                                            'input-group-append input-authcode-send d-none'
                                        )
                                    }
                                >
                                    <button
                                        className="btn btn-outline-secondary btn-authcode-send"
                                        type="button"
                                        disabled={values.accountHolder.mobileNumber.number.length === 0}
                                        onClick={() => onSubmitAuthCode(values.accountHolder.mobileNumber.number)}
                                    >
                                        <i className="fas fa-paper-plane"/> Resend
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}

                    {accountHolderEnableFields.businessNumber && (
                        <div className="form-group col-xs col-md-6">
                            <label htmlFor="accountHolder.businessNumber.number">Business Number</label>
                            <input
                                type="text"
                                id="accountHolder.businessNumber.number"
                                name="accountHolder.businessNumber.number"
                                className={'form-control' + (get(errors, `accountHolder.businessNumber.number`) && get(touched, `accountHolder.businessNumber.number`) ? ' is-invalid' : '')}
                                placeholder={'Business Number' + (accountHolderOptionalFields.businessNumber ? ' (optional)' : '')}
                                value={values.accountHolder.businessNumber.number}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            {get(errors, `accountHolder.businessNumber.number`) &&
                            get(touched, `accountHolder.businessNumber.number`) && (
                                <div className="invalid-feedback">{get(errors, `accountHolder.businessNumber.number`)}</div>
                            )}
                        </div>
                    )}

                    {accountHolderEnableFields.homeNumber && (
                        <div className="form-group col-xs col-md-6">
                            <label htmlFor="accountHolder.homeNumber.number">Home Phone</label>
                            <input
                                type="text"
                                id="accountHolder.homeNumber.number"
                                name="accountHolder.homeNumber.number"
                                className={'form-control' + (get(errors, `accountHolder.homeNumber.number`) && get(touched, `accountHolder.homeNumber.number`) ? ' is-invalid' : '')}
                                placeholder={'Home Phone' + (accountHolderOptionalFields.homeNumber ? ' (optional)' : '')}
                                value={values.accountHolder.homeNumber.number}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            {get(errors, `accountHolder.homeNumber.number`) &&
                            get(touched, `accountHolder.homeNumber.number`) && (
                                <div className="invalid-feedback">{get(errors, `accountHolder.homeNumber.number`)}</div>
                            )}
                        </div>
                    )}

                </div>

                <div className="form-row">

                    {accountHolderEnableFields.ssn &&
                    (values.accountTermsInfo.manualTierSelector === false || values.accountTermsInfo.manualTierSelector === 'none') && (
                        <div className="form-group col-12 col-md-6">
                            <label htmlFor="accountHolder.ssn">SSN</label>
                            <div className="input-group">
                                <input
                                    type="text"
                                    id="accountHolder.ssn"
                                    name="accountHolder.ssn"
                                    className={'form-control' + (get(errors, `accountHolder.ssn`) && get(touched, `accountHolder.ssn`) ? ' is-invalid' : '')}
                                    placeholder={'SSN' + (accountHolderOptionalFields.ssn ? ' (optional)' : '')}
                                    value={values.accountHolder.ssn}
                                    onChange={(values) => {
                                        handleChange(values);
                                        resetEvaluateStatus(setFieldValue);
                                    }}
                                    onBlur={handleBlur}
                                />
                                {get(errors, `accountHolder.ssn`) &&
                                get(touched, `accountHolder.ssn`) && (
                                    <div className="invalid-feedback">{get(errors, `accountHolder.ssn`)}</div>
                                )}
                                {values.accountTermsInfo.manualTierSelector === false && (
                                    <div className="input-group-append">
										<span
                                            className={
                                                showEvaluationStatus === true ? (
                                                    'input-group-text new-acc-tier-auto-status'
                                                ) : (
                                                    'input-group-text new-acc-tier-auto-status d-none'
                                                )
                                            }
                                        >
											{evaluationLoader && (
                                                <Fragment>
                                                    <i className="fas fa-spin fa-circle-notch mr-1"/>&nbsp;Evaluating
                                                </Fragment>
                                            )}
                                            {!evaluationLoader && (
                                                <span className="text-success">
													{evaluationData.description && evaluationData.description}, Required
													Deposit: ${evaluationData.deposit !== undefined &&
                                                evaluationData.deposit
                                                    .toFixed(2)
                                                    .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
												</span>
                                            )}
										</span>
                                        <button
                                            onClick={() =>
                                                handleEvaluateStatusChange(
                                                    values.accountHolder.ssn,
                                                    values.accountTermsInfo.accountTerm,
                                                    setFieldValue
                                                )}
                                            className={
                                                showEvaluationStatus === true ? (
                                                    'btn btn-lg btn-outline-secondary d-none'
                                                ) : (
                                                    'btn btn-lg btn-outline-secondary'
                                                )
                                            }
                                            type="button"
                                            disabled={values.accountHolder.ssn.length === 0}
                                        >
                                            <i className="fas fa-paper-plane"/> Evaluate
                                        </button>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}

                </div>

                {values.accountTermsInfo.manualTierSelector === true && (
                    <div className="form-row">

                        <div className="form-group col-12 col-md-6">
                            <label htmlFor="accountHolder.ssn">SSN</label>
                            <input
                                type="text"
                                id="accountHolder.ssn"
                                name="accountHolder.ssn"
                                className={'form-control' + (get(errors, `accountHolder.ssn`) && get(touched, `accountHolder.ssn`) ? ' is-invalid' : '')}
                                placeholder={'SSN' + (accountHolderOptionalFields.ssn ? ' (optional)' : '')}
                                value={values.accountHolder.ssn}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            {get(errors, `accountHolder.ssn`) &&
                            get(touched, `accountHolder.ssn`) && (
                                <div className="invalid-feedback">{get(errors, `accountHolder.ssn`)}</div>
                            )}
                        </div>

                        <div className="form-group col-12 col-md-6">
                            <FormSelect
                                title="Risk Level"
                                fieldName="accountTermsInfo.accountTier"
                                value={values.accountTermsInfo.accountTier}
                                setFieldValue={setFieldValue}
                                onBlur={setFieldTouched}
                                errors={errors}
                                touched={touched}
                                isMulti={false}
                                options={accountTiers}
                                isDisabled={accountTiers.length === 0}
                            />
                            {values.accountTermsInfo &&
                            values.accountTermsInfo.accountTier && (
                                <p className="new-acc-tier-deposit mt-1 mt-0">
                                    Required Security Deposit:{' '}
                                    <strong>
                                        ${accountTiers
                                        .find((x) => x.value === values.accountTermsInfo.accountTier)
                                        .deposit.toFixed(2)}
                                    </strong>
                                </p>
                            )}
                        </div>

                    </div>
                )}

                <div className="form-row">
                    {accountHolderEnableFields.taxId && (
                        <TextInput
                            type="text"
                            divClass="col-md-6"
                            placeholder={'Tax ID' + (accountHolderOptionalFields.taxId ? ' (optional)' : '')}
                            label="Tax Id"
                            id="accountHolder.taxId"
                            name="accountHolder.taxId"
                            value={values.accountHolder.taxId}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            touched={touched}
                            errors={errors}
                        />
                    )}
                </div>

                <div className="form-row">
                    {accountHolderEnableFields.memberNumber && (
                        <div className="form-group col-xs col-md-6">
                            <label htmlFor="accountHolder.memberNumber">Member Number</label>
                            <div className="input-group">
                                <input
                                    type="text"
                                    className={'form-control' + (get(errors, `accountHolder.memberNumber`) ? ' is-invalid': '')}
                                    placeholder={'Member Number' + (accountHolderOptionalFields.memberNumber ? ' (optional)' : '')}
                                    id="accountHolder.memberNumber"
                                    name="accountHolder.memberNumber"
                                    value={values.accountHolder.memberNumber}
                                    disabled={true}
                                />
                                {accountHolderOptionalFields.memberNumber && (
                                <div className="input-group-append">
                                    <button
                                        type="button"
                                        className="btn btn-outline-secondary"
                                        onClick={() => this.generateAccountContactMemberNumber()}
                                        disabled={values.accountHolder.memberNumber}
                                    >
                                        <i className="fas fa-paper-plane"/> Generate
                                    </button>
                                </div>
                                )}
                            </div>
                        </div>
                    )}
                </div>

                <div className="form-row">

                    {accountHolderEnableFields.personalEmail && (
                        <TextInput
                            type="email"
                            divClass="col-md-6"
                            placeholder={'Personal Email' + (accountHolderOptionalFields.personalEmail ? ' (optional)' : '')}
                            label="Personal Email"
                            id="accountHolder.personalEmail"
                            name="accountHolder.personalEmail"
                            value={values.accountHolder.personalEmail}
                            touched={touched}
                            errors={errors}
                            onChange={(e) => {
                                this.validateEmail('accountHolder.personalEmail', e.target.value);
                                setFieldValue('accountHolder.personalEmail', e.target.value);
                            }}
                            onBlur={handleBlur}
                            status={status}
                        />
                    )}

                    {accountHolderEnableFields.businessEmail && (
                        <TextInput
                            type="email"
                            divClass="col-md-6"
                            placeholder={'Business Email' + (accountHolderOptionalFields.businessEmail ? ' (optional)' : '')}
                            label="Business Email"
                            id="accountHolder.businessEmail"
                            name="accountHolder.businessEmail"
                            value={values.accountHolder.businessEmail}
                            touched={touched}
                            errors={errors}
                            onChange={(e) => {
                                this.validateEmail('accountHolder.businessEmail', e.target.value);
                                setFieldValue('accountHolder.businessEmail', e.target.value);
                            }}
                            onBlur={handleBlur}
                            status={status}
                        />
                    )}

                </div>

                <fieldset>

                    <legend className="as-label">Billing Address</legend>

                    {validatedAddress && validatedAddress.formatedAddress && validatedAddress.contactAddress &&
                    <div className="form-check checkbox-slider checkbox-slider--b-flat">
                        <label>
                            <input
                                type="checkbox"
                                checked={serviceAddressAsBilling}
                                onChange={() => {
                                    this.setState({serviceAddressAsBilling: !serviceAddressAsBilling}, () => {
                                        setServiceAddressAsBilling(setFieldValue, setFieldTouched, validatedAddress.contactAddress, this.state.serviceAddressAsBilling)
                                        setTimeout(() => mailingAddressValidation(this.props), 0);
                                    })
                                }}
                            />
                            <span>Use Service Address ({validatedAddress.formatedAddress})</span>
                        </label>
                    </div>
                    }

                    <div className="form-row">
                        <div className="col-xs col-md-6">

                            <div className="form-group">
                                <input
                                    type="text"
                                    className={'form-control' + (get(errors, `accountHolder.mailingAddress.address1`) && get(touched, `accountHolder.mailingAddress.address1`) ? ' is-invalid' : '')}
                                    name="accountHolder.mailingAddress.address1"
                                    placeholder="Line 1"
                                    value={values.accountHolder.mailingAddress.address1}
                                    onChange={(e) => {
                                        setFieldValue('accountHolder.mailingAddress.zip4', null);
                                        setFieldValue(e.target.name, e.target.value);
                                        setTimeout(() => mailingAddressValidation(this.props), 0);
                                    }}
                                    onBlur={handleBlur}
                                />
                                {get(errors, `accountHolder.mailingAddress.address1`) &&
                                get(touched, `accountHolder.mailingAddress.address1`) && (
                                    <div className="invalid-feedback">
                                        {get(errors, `accountHolder.mailingAddress.address1`)}
                                    </div>
                                )}
                            </div>

                            <div className="form-group">
                                <input
                                    type="text"
                                    className={'form-control' + (get(errors, `accountHolder.mailingAddress.address2`) && get(touched, `accountHolder.mailingAddress.address2`) ? ' is-invalid' : '')}
                                    name="accountHolder.mailingAddress.address2"
                                    placeholder="Line 2 (optional)"
                                    value={values.accountHolder.mailingAddress.address2}
                                    onChange={(e) => {
                                        setFieldValue(e.target.name, e.target.value);
                                        setTimeout(() => mailingAddressValidation(this.props), 0);
                                    }}
                                    onBlur={handleBlur}
                                />
                                {get(errors, `accountHolder.mailingAddress.address2`) &&
                                get(touched, `accountHolder.mailingAddress.address2`) && (
                                    <div className="invalid-feedback">
                                        {get(errors, `accountHolder.mailingAddress.address2`)}
                                    </div>
                                )}
                            </div>

                            <div className="form-group">
                                <input
                                    type="text"
                                    className={'form-control' + (get(errors, `accountHolder.mailingAddress.address3`) && get(touched, `accountHolder.mailingAddress.address3`) ? ' is-invalid' : '')}
                                    name="accountHolder.mailingAddress.address3"
                                    placeholder="Line 3 (optional)"
                                    value={values.accountHolder.mailingAddress.address3}
                                    onChange={(e) => {
                                        setFieldValue(e.target.name, e.target.value);
                                        setTimeout(() => mailingAddressValidation(this.props), 0);
                                    }}
                                    onBlur={handleBlur}
                                />
                                {get(errors, `accountHolder.mailingAddress.address3`) &&
                                get(touched, `accountHolder.mailingAddress.address3`) && (
                                    <div className="invalid-feedback">
                                        {get(errors, `accountHolder.mailingAddress.address3`)}
                                    </div>
                                )}
                            </div>

                        </div>
                        <div className="col-xs col-md-6">

                            <div className="form-group">
                                <input
                                    type="text"
                                    className={'form-control' + (get(errors, `accountHolder.mailingAddress.city`) && get(touched, `accountHolder.mailingAddress.city`) ? ' is-invalid' : '')}
                                    name="accountHolder.mailingAddress.city"
                                    placeholder="City"
                                    value={values.accountHolder.mailingAddress.city}
                                    onChange={(e) => {
                                        setFieldValue(e.target.name, e.target.value);
                                        setTimeout(() => mailingAddressValidation(this.props), 0);
                                    }}
                                    onBlur={handleBlur}
                                />
                                {get(errors, `accountHolder.mailingAddress.city`) &&
                                get(touched, `accountHolder.mailingAddress.city`) && (
                                    <div className="invalid-feedback">
                                        {get(errors, `accountHolder.mailingAddress.city`)}
                                    </div>
                                )}
                            </div>

                            <div className="form-group">
                                <FormSelect
                                    fieldName="accountHolder.mailingAddress.state"
                                    errors={errors}
                                    touched={touched}
                                    isMulti={false}
                                    options={countryStatesOptions}
                                    placeholder="State (optional)"
                                    value={values.accountHolder.mailingAddress.state}
                                    onChange={(fieldName, value) => {
                                        setFieldValue(fieldName, value);
                                        setTimeout(() => mailingAddressValidation(this.props), 0);
                                    }}
                                    onBlur={setFieldTouched}
                                />
                            </div>

                            <div className="form-group">
                                <input
                                    type="text"
                                    className={'form-control' + (get(errors, `accountHolder.mailingAddress.zip`) && get(touched, `accountHolder.mailingAddress.zip`) ? ' is-invalid' : '')}
                                    name="accountHolder.mailingAddress.zip"
                                    placeholder="Zip"
                                    value={values.accountHolder.mailingAddress.zip}
                                    onChange={(e) => {
                                        setFieldValue('accountHolder.mailingAddress.zip4', null);
                                        setFieldValue(e.target.name, e.target.value);
                                        setTimeout(() => mailingAddressValidation(this.props), 0);
                                    }}
                                    onBlur={handleBlur}
                                />
                                {get(errors, `accountHolder.mailingAddress.zip`) &&
                                get(touched, `accountHolder.mailingAddress.zip`) && (
                                    <div className="invalid-feedback">
                                        {get(errors, `accountHolder.mailingAddress.zip`)}
                                    </div>
                                )}
                            </div>

                        </div>
                    </div>

                    {mailingAddressLoader ? (
                        <AccountHolderLoader/>
                    ) : (
                        showSuggestedMailingAddress && (
                            <Fragment>
                                {suggestedMailingAddressError ? (
                                    <div className="cmv-container-subpanel cmv-container-subpanel-suggested-address">
                                        <h5>Address Error</h5>
                                        <p>{suggestedMailingAddressError}</p>{' '}
                                        <div className="d-flex justify-content-end mt-1">
                                            <button
                                                onClick={() => closeSuggestion('showSuggestedMailingAddress')}
                                                className="btn btn-outline-primary mr-1 btn-address-keep"
                                                type="button"
                                            >
                                                Keep Entered
                                            </button>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="cmv-container-subpanel cmv-container-subpanel-suggested-address">
                                        <h5><i className="fas fa-database"/> Address Match</h5>
                                        <p>The following address was found in our database.</p>
                                        <address>
                                            {suggestedMailingAddress.address1} {suggestedMailingAddress.address2}
                                            <br/>
                                            {suggestedMailingAddress.city}, {suggestedMailingAddress.state}{' '}
                                            {generateFullZip(suggestedMailingAddress)}
                                        </address>
                                        <div className="d-flex justify-content-end mt-1">
                                            <button
                                                onClick={() => closeSuggestion('showSuggestedMailingAddress')}
                                                className="btn btn-outline-primary mr-1 btn-address-keep"
                                                type="button"
                                            >
                                                Keep Entered
                                            </button>
                                            <button
                                                type="button"
                                                onClick={() => useSuggestedMailingAddress(setFieldValue)}
                                                className="btn btn-primary btn-address-use-suggested"
                                            >
                                                Use Suggested
                                            </button>
                                        </div>
                                    </div>
                                )}
                            </Fragment>
                        )
                    )}
                </fieldset>

                <fieldset>

                    <legend className="as-label">Security Questions and Answers</legend>

                    <div className="form-row">

                        <div className="form-group col-xs col-lg-6">
                            <FormSelect
                                fieldName="accountHolder.securities[0].questionId"
                                value={values.accountHolder.securities[0].questionId}
                                setFieldValue={setFieldValue}
                                onBlur={setFieldTouched}
                                errors={errors}
                                touched={touched}
                                isMulti={false}
                                options={questionsOptions}
                                placeholder="Security Question 1 (optional)"
                            />
                        </div>

                        <TextInput
                            type="text"
                            divClass="col-lg-6"
                            name="accountHolder.securities[0].answer"
                            value={values.accountHolder.securities[0].answer}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            touched={touched}
                            errors={errors}
                            placeholder="Security Answer 1 (optional)"
                        />

                    </div>
                    <div className="form-row">

                        <div className="form-group col-xs col-lg-6">
                            <FormSelect
                                fieldName="accountHolder.securities[1].questionId"
                                value={values.accountHolder.securities[1].questionId}
                                setFieldValue={setFieldValue}
                                onBlur={setFieldTouched}
                                errors={errors}
                                touched={touched}
                                isMulti={false}
                                options={questionsOptions}
                                placeholder="Security Question 2 (optional)"
                            />
                        </div>

                        <TextInput
                            type="text"
                            divClass="col-lg-6"
                            name="accountHolder.securities[1].answer"
                            value={values.accountHolder.securities[1].answer}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            touched={touched}
                            errors={errors}
                            placeholder="Security Answer 2 (optional)"
                        />

                    </div>
                    <div className="form-row">

                        <div className="form-group col-xs col-lg-6">
                            <FormSelect
                                fieldName="accountHolder.securities[2].questionId"
                                value={values.accountHolder.securities[2].questionId}
                                setFieldValue={setFieldValue}
                                onBlur={setFieldTouched}
                                errors={errors}
                                touched={touched}
                                isMulti={false}
                                options={questionsOptions}
                                placeholder="Security Question 3 (optional)"
                            />
                        </div>

                        <TextInput
                            type="text"
                            divClass="col-lg-6"
                            name="accountHolder.securities[2].answer"
                            value={values.accountHolder.securities[2].answer}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            touched={touched}
                            errors={errors}
                            placeholder="Security Answer 3 (optional)"
                        />

                    </div>
                </fieldset>

            </div>
        );
    }
}

export default DynamicForm;
