import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { generateAddressNew } from '../../utils/generateAddresses';
import { isEmpty } from '../../utils/helpers';
import { getAccountAllowedStatusTransitions } from '../../actions/accountDetailsActions/accountDetails.actions';
import { createLoadingSelector, getAccountDetails } from '../../selectors/';
import { showModal } from '../../actions/modal.actions';
import AlertBox from '../../components/UI/AlertBox';
import AccountInfoBox from '../../components/UI/AccountInfoBox';
import moment from "moment";

class AccountInfo extends Component {

	state = {
		isAlertBoxShowing: true,
		isStatusDropDownOpen: false,
		statusString: this.props.accountInfo.status,
		width: 0
	};

	componentDidMount() {
		this.updateWindowDimensions();
		window.addEventListener('resize', this.updateWindowDimensions);

		this.props.getAccountAllowedStatusTransitions(this.props.accountInfo.id);
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.updateWindowDimensions);
	}

	updateWindowDimensions = () => {
		this.setState({ width: window.innerWidth});
	}

	handleShowAlert = () => {
		this.setState({
			isAlertBoxShowing: false
		});
	}

	handleStatusDropDown = (forceClose) => {
		this.setState((prevState) => ({
			isStatusDropDownOpen: forceClose ? false : !prevState.isStatusDropDownOpen
		}));
	}

	handleShowModal = (statusTransition) => {
		const { showModal, accountInfo } = this.props;

		showModal('ACCOUNT_STATUS_CHANGE_MODAL', {
			accountInfo,
			statusTransition,
			onAccountStatusChange: this.props.onAccountStatusChange
		});
	}

	handleAlertExpired = (date) => {
		if (date && moment(date).isBefore(moment())) {
			return false;
		}
		return true;
	}

	render() {

		const {
			isAlertBoxShowing,
			isStatusDropDownOpen,
		} = this.state;

		const {
			accountInfo,
			showNewOrderWizard,
			accountPromises,
			accountAlert,
			accountAllowedStatusTransitions,
			accountAllowedStatusTransitionsLoader
		} = this.props;

		if (isEmpty(accountInfo)) {
			return null;
		}

		const address =
			accountInfo.primaryContact &&
			accountInfo.primaryContact.contactAddresses &&
			accountInfo.primaryContact.contactAddresses.find((x) => x.type === 'BILLING');

		let addressInfo = '';

		if (address) {
			addressInfo = generateAddressNew(address);
		}

		return (
			<Fragment>

				{isAlertBoxShowing &&
				accountAlert.message &&
				accountAlert.message !== 'NONE' &&
				accountAlert.message.length > 0 &&
				this.handleAlertExpired(accountAlert.expirationDateTime) && (
				<AlertBox
					handleShowAlert={this.handleShowAlert}
					message={accountAlert.message}
				/>
				)}

				<AccountInfoBox
					accountInfo={accountInfo}
					hideCardsContainer={showNewOrderWizard}
					showStatusDropdown={true}
					accountPromises={accountPromises}
					handleStatusDropDown={this.handleStatusDropDown}
					isStatusDropDownOpen={isStatusDropDownOpen}
					showModal={this.handleShowModal}
					accountAllowedStatusTransitions={accountAllowedStatusTransitions}
					accountAllowedStatusTransitionsLoading={accountAllowedStatusTransitionsLoader}
					width={this.state.width}
				/>

			</Fragment>
		);
	}
}

AccountInfo.propTypes = {
	accountInfo: PropTypes.object.isRequired,
	showModal: PropTypes.func,
	updateAccountStatus: PropTypes.func,
};

const getAccountAllowedStatusTransitionsLoader = createLoadingSelector(['GET_ACCOUNT_ALLOWED_STATUS_TRANSITIONS']);

const mapStateToProps = (state) => {

	const accountAllowedStatusTransitionsLoader = getAccountAllowedStatusTransitionsLoader(state);
	const accountAllowedStatusTransitions = getAccountDetails(state).accountDetails.accountAllowedStatusTransitions;

	return {
		accountAllowedStatusTransitions,
		accountAllowedStatusTransitionsLoader,
	}
}

const mapDispatchToProps = {
	getAccountAllowedStatusTransitions,
	showModal,
}
export default connect(mapStateToProps, mapDispatchToProps)(AccountInfo);
