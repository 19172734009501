import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser';
class MandatoryPackage extends Component {
    state = {
		isDescriptionToggled: false
    };
    

    isPriceRendered = () => {
		const { accountInitialStatus, packageOption } = this.props;

		if(packageOption.features != undefined) {
			if (accountInitialStatus === 'NEW_SIGNUP') {
				if (packageOption.features.some((x) => x.hideSignupPrice === true)) {
					return false;
				}
			} else if (accountInitialStatus === 'NEW_SURVEY') {
				if (packageOption.features.some((x) => x.hideSurveyPrice === true)) {
					return false;
				}
			}
		}
		return true;
	};

	toggleDescription = (e) => {
		e.stopPropagation();
		this.setState((prevState) => ({
			isDescriptionToggled: !prevState.isDescriptionToggled
		}));
    };
    
	render() {
        const {isDescriptionToggled} = this.state;
		const { lineInfo, lineIndex, handleMandatoryPackages, packageOption, featurePackage } = this.props;
		return (
			<div
				className={
					lineInfo.features.findIndex(
						(x) => x.featurePackageOptionId === packageOption.featurePackageOptionId
					) !== -1 ? (
						'package-option package-option-exclusive package-option-selectable package-option-selected'
					) : (
						'package-option package-option-exclusive package-option-selectable'
					)
				}
				data-opt-id="pl001-mp001-op001"
				onClick={() =>
					handleMandatoryPackages(packageOption.featurePackageOptionId, lineIndex, {
						featurePackageId: featurePackage.featurePackageId,
						featurePackageDescription: featurePackage.description,
						featurePackageName: featurePackage.name,
						featurePackageNote: featurePackage.note,
						...packageOption
					})}
			>
				<span className="po-title">{packageOption.description}</span>
				<span className="package-toolbar">
					<span className="po-price">
                        {this.isPriceRendered() &&
									`$${packageOption.recurringMonthlyPrice
										.toFixed(2)
										.replace(/\d(?=(\d{3})+\.)/g, '$&,')}/mo`}
					</span>
					<button onClick={this.toggleDescription} type="button" className="btn btn-xs btn-desc-toggler">
						<i className="fas fa-info-circle" />
					</button>
				</span>
                <div
						className={isDescriptionToggled ? 'po-description collapse show' : 'po-description collapse'}
						id="pl001-ifeat002-op001-desc"
					>
						{ReactHtmlParser(packageOption.note)}
					</div>
			</div>
		);
	}
}

export default MandatoryPackage;
