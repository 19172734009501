import moment from 'moment';
import {isEmpty} from '../../../../utils/helpers';

export const getFormattedFilters = (filters, searchType, searchLabel) => {
	let updatedFilters;

	updatedFilters = { ...filters };

	if (searchLabel === 'My Tasks') {
		updatedFilters['myTasks'] = true;
	}

	else if (searchLabel === 'My Group Tasks') {
		updatedFilters['myGroupTasks'] = true;
	}

	else if (searchLabel === 'Unclaimed Tasks') {
		updatedFilters['unclaimedTask'] = true;
	}

	else if (searchType === 'accountId') {
		updatedFilters['accountNumber'] = filters.searchId;
	}

	else if (searchType === 'orderId') {
		updatedFilters['orderId'] = filters.searchId;
	}

	else if (searchType === 'description') {
		updatedFilters['description'] = filters.searchId;
	}

	else if (searchType === 'username') {
		updatedFilters['username'] = filters.searchId;
	}

	delete updatedFilters['searchId'];

	if (updatedFilters['fromDate'] !== '') {
		updatedFilters['fromDate'] = moment(updatedFilters['fromDate']).format('YYYY-MM-DD');
	}

	if (updatedFilters['toDate'] !== '') {
		updatedFilters['toDate'] = moment(updatedFilters['toDate']).format('YYYY-MM-DD');
	}

	if (['My Tasks', 'My Group Tasks', 'Unclaimed Tasks'].includes(searchLabel)) {
		updatedFilters['complete'] = false;
	} else {
		updatedFilters['complete'] = '';
	}

	if ('Completed Tasks' === searchLabel) {
		updatedFilters['complete'] = true;
	}

	for (let filter in updatedFilters) {

		if (isEmpty(updatedFilters[filter])) {
			delete updatedFilters[filter];
		}
	}

	return updatedFilters;
};