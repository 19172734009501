import React, { Component } from 'react';
import AccountInfoBox from '../../components/UI/AccountInfoBox';
import { connect } from 'react-redux';
import { hideModal, showModal } from '../../actions/modal.actions';
import { createLoadingSelector, getAccountDetails } from '../../selectors';
import Partners from "./Partners";
import Loader from "../../components/Loader";

class PartnerManagement extends Component {

	componentDidMount() {

		// Change navbar type and color
		document.title = 'Partners - camvio.cloud';
		this.props.changeNavBarType('default', 'Partners');
	}

	componentDidUpdate(prevProps) {
		if (prevProps.isShowAccessModal !== this.props.isShowAccessModal && this.props.isShowAccessModal === true) {
		}
	}

	componentWillUnmount() {
		this.props.hideModal();
	}

	render() {
		const { accountInfo, history, accountInfoLoader, isShowAccessModal } = this.props;

		//If user is redirected from create-account page - show cpni pop-up
		if (
			isShowAccessModal === false &&
			history.location.state &&
			history.location.state.serviceOrderData
		) {
			return null;
		}

		return (
			<div className="cmv-container cmv-container-dashboard cmv-container-client-selected">
				{/*{accountInfoLoader ?
					<Loader/> :
					<AccountInfoBox accountInfo={accountInfo} hideCardsContainer={true}/>}*/}
				<Partners />
			</div>
		);
	}
}

// const loader = createLoadingSelector([ 'SET_ACCOUNT_DETAILS' ]);

const mapStateToProps = (state) => {
	// const accountInfo = getAccountDetails(state).accountDetails.accountInfo;
	// const accountInfoLoader = loader(state);
	//  showNewOrderWizard = getAccountDetails(state).accountDetails.showNewOrderWizard,
	// 	isShowAccessModal = getModalData(state).showAccessModal,

	return {
		// accountInfo,
		// accountInfoLoader
	};
};

const mapDispatchToProps = {
	showModal,
	hideModal
};

export default connect(mapStateToProps, mapDispatchToProps)(PartnerManagement);
