import axiosInstance from '../../utils/axios-instance';
import { actionCreator, createRequestTypes } from '../../utils/helpers';

export const SET_ACCOUNT360_BADJES = createRequestTypes('SET_ACCOUNT360_BADJES');
export const SET_PENDING_ORDERS = createRequestTypes('SET_PENDING_ORDERS');
export const SET_SERVICES = createRequestTypes('SET_SERVICES');
export const SET_ACCOUNT360_SERVICE_ICONS = createRequestTypes('SET_ACCOUNT360_SERVICE_ICONS');

export const getAccount360Badjes = (accountId) => async (dispatch) => {
	dispatch(actionCreator.request(SET_ACCOUNT360_BADJES));
	try {
		const response = await axiosInstance.get(`/account/${accountId}/counts/summaryobjects`);
		dispatch(actionCreator.success(SET_ACCOUNT360_BADJES, response.data.summaryObjects));
	} catch (err) {
		let mockError = {
			message: 'Something goes wrong'
		}
		dispatch(actionCreator.failure(SET_ACCOUNT360_BADJES, mockError));
	}
};

export const emptyAccount360Badjes = () => (dispatch) => {
	dispatch(actionCreator.empty(SET_ACCOUNT360_BADJES));
};

// SERVICES -- START //

export const getAccount360Services = (accountId) => async (dispatch) => {
	dispatch(actionCreator.request(SET_SERVICES));

	const getParams = {};

	try {
		const response = await axiosInstance.get(`/account/${accountId}/servicelines`);
		dispatch(actionCreator.success(SET_SERVICES, response.data.services));
		return response.data.services;
	} catch (err) {
		let mockError = {
			message: 'Something goes wrong'
		}
		dispatch(actionCreator.failure(SET_SERVICES, mockError));
	}
};

export const emptyAccount360Services = () => (dispatch) => {
	dispatch(actionCreator.empty(SET_SERVICES));
};

// SERVICES -- END //

// ORDERS -- START //
export const getAccount360PendingOrders = (accountId, orderStatus) => async (dispatch) => {
	dispatch(actionCreator.request(SET_PENDING_ORDERS));

	const getParams = {
		accountId,
		orderStatus
	};

	try {
		const response = await axiosInstance.get(`/order/list`, { params: getParams });
		dispatch(actionCreator.success(SET_PENDING_ORDERS, response.data.orders));
		
	} catch (err) {
		let mockError = {
			message: 'Something goes wrong'
		}
		dispatch(actionCreator.failure(SET_PENDING_ORDERS, mockError));
	}
};

export const emptyAccount360Orders = () => (dispatch) => {
	dispatch(actionCreator.empty(SET_PENDING_ORDERS));
};


export const getAccount360ServiceIcons = (accountId) => async (dispatch) => {
	dispatch(actionCreator.request(SET_ACCOUNT360_SERVICE_ICONS));
	try {
		const response = await axiosInstance.get(`/account/${accountId}/servicemodels`);
		dispatch(actionCreator.success(SET_ACCOUNT360_SERVICE_ICONS, response.data.serviceModels));
	} catch (err) {
		let mockError = {
			message: 'Something goes wrong'
		}
		dispatch(actionCreator.failure(SET_ACCOUNT360_SERVICE_ICONS, mockError));
	}
};


export const emptyAccount360ServiceIcons = () => (dispatch) => {
	dispatch(actionCreator.empty(SET_ACCOUNT360_SERVICE_ICONS));
};