import React, { Component } from 'react';
import moment from 'moment';
import { getInstallmentPlanDetails } from '../../../../../../actions/accountDetailsActions/billing.actions';
import Loader from '../../../../../../components/Loader';

class InstallmentDetails extends Component {
	state = {
		invoiceData: [],
		isLoading: true
	};
	componentDidMount() {
		const { accountInfo, installmentId } = this.props;
		getInstallmentPlanDetails(accountInfo.id, installmentId).then((response) => {
			console.log(response);
			if (response.status===200) {
				this.setState({
					invoiceData: response.data,
					isLoading: false
				});
			} else {
				this.setState({
					isLoading: false
				});
			}
		});
	}

	render() {
		const { invoiceData, isLoading } = this.state;

		if (isLoading) {
			return <Loader />;
		}

		if (invoiceData.length===0) {
			return <p style={{display:'flex', justifyContent:'center',alignItems:'center',width:'100%'}}>No Invoice data found</p>;
		}

		return (
			<div class="cmv-datarow-container">
				{invoiceData.length>0 && (
					<div class="cmv-datarow-wrapper">
						<div class="row cmv-datarow-header">
							<div class="col">Invoice #</div>
							<div class="col">Transaction</div>
							<div class="col text-right">Amount</div>
						</div>
						{invoiceData.map((invoice, index) => (
							<div className="row cmv-datarow" key={index}>
								<div className="col"><span>{invoice.invoice.number}</span><span className="td-subtitle">{invoice.invoice.type}</span></div>
								<div className="col">{moment(invoice.invoice.invoiceDate).format('MM/DD/YYYY')}</div>
								<div className="col cmv-datarow-col-number">
									${invoice.amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
								</div>
							</div>
						))}
					</div>
				)}

			</div>
		);
	}
}

export default InstallmentDetails;
