import axiosInstance from '../utils/axios-instance';
import {actionCreator, createRequestTypes} from '../utils/helpers';

export const ACCOUNT_SEARCH = createRequestTypes('ACCOUNT_SEARCH');


export const getAccounts = (searchType, term, limit) => async (dispatch) => {
    dispatch(actionCreator.request(ACCOUNT_SEARCH))
	try {
    	const params = {searchType, term}
    	if (limit) {
    		params.limit = limit;
		}

		const response = await axiosInstance.get(`/accounts`, {params});
		dispatch(actionCreator.success(ACCOUNT_SEARCH, response.data));
		return response.data;
	} catch (err) {
        dispatch(actionCreator.failure(ACCOUNT_SEARCH))
    }
};

export const emptyAccounts = () => (dispatch) => {
	dispatch(actionCreator.empty(ACCOUNT_SEARCH));
}