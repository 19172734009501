import axiosInstance from '../../utils/axios-instance';
import { actionCreator, createRequestTypes } from '../../utils/helpers';
import { getAccountPromises } from './accountDetails.actions';
import {paymentSubmitted} from "../globalFlag.actions";

export const SET_BILLING_BADJES = createRequestTypes('SET_BILLING_BADJES');
export const SET_BALANCES_INFO = createRequestTypes('SET_BALANCES_INFO');
export const SET_ADJUSTMENTS = createRequestTypes('SET_ADJUSTMENTS');
export const DELETE_ADJUSTMENTS = createRequestTypes('DELETE_ADJUSTMENTS');
export const SET_ADJUSTMENT_TYPE = createRequestTypes('SET_ADJUSTMENT_TYPE');
export const SET_ACCOUNT_SERVICELINES = createRequestTypes('SET_ACCOUNT_SERVICELINES');
export const CREATE_IMMEDIAT_ADJUSTMENT = createRequestTypes('CREATE_IMMEDIAT_ADJUSTMENT');
export const CREATE_IMMEDIATE_ADJUSTMENTS = createRequestTypes('CREATE_IMMEDIATE_ADJUSTMENTS');
export const CREATE_PENDING_ADJUSTMENT = createRequestTypes('CREATE_PENDING_ADJUSTMENT');
export const SET_RECCURING_CREDITS = createRequestTypes('SET_RECCURING_CREDITS');
export const CREATE_RECCURING_CREDIT = createRequestTypes('CREATE_RECCURING_CREDIT');
export const UPDATE_RECCURING_CREDIT = createRequestTypes('UPDATE_RECCURING_CREDIT');
export const DELETE_RECCURING_CREDITS = createRequestTypes('DELETE_RECCURING_CREDITS');

export const SET_DISCOUNTS = createRequestTypes('SET_DISCOUNTS');
export const CREATE_DISCOUNT = createRequestTypes('CREATE_DISCOUNT');
export const UPDATE_DISCOUNT = createRequestTypes('UPDATE_DISCOUNT');
export const DELETE_DISCOUNT = createRequestTypes('DELETE_DISCOUNT');
export const SET_DISCOUNT_TYPES = createRequestTypes('SET_DISCOUNT_TYPES');
export const SET_RECCURING_CREDIT_TYPES = createRequestTypes('SET_RECCURING_CREDIT_TYPES');

export const SET_INVOICES = createRequestTypes('SET_INVOICES');
export const SET_PAYMENTS = createRequestTypes('SET_PAYMENTS');
export const SET_INSTALLMENTPLAN = createRequestTypes('SET_INSTALLMENTPLAN');
export const REVERSE_PAYMENT = createRequestTypes('REVERSE_PAYMENT');
export const SET_SUPPORTED_REVERSAL_TYPES = createRequestTypes('SET_SUPPORTED_REVERSAL_TYPES');

export const SET_PAYMENT_PROMISES = createRequestTypes('SET_PAYMENT_PROMISES');
export const CREATE_PAYMENT_PROMISES = createRequestTypes('CREATE_PAYMENT_PROMISES');

export const SET_TAX_EXEMPTIONS = createRequestTypes('SET_TAX_EXEMPTIONS');
export const CREATE_TAX_EXEMPTION = createRequestTypes('CREATE_TAX_EXEMPTION');
export const UPDATE_TAX_EXEMPTION = createRequestTypes('UPDATE_TAX_EXEMPTION');
export const DELETE_TAX_EXEMPTION = createRequestTypes('DELETE_TAX_EXEMPTION');
export const SET_TAX_CODES = createRequestTypes('SET_TAX_CODES');

export const SET_STATUS_HISTORY = createRequestTypes('SET_STATUS_HISTORY');

// export const SET_PAYMENT_DETAILS = createRequestTypes('SET_PAYMENT_DETAILS');

export const GET_BILLING_SETTINGS = createRequestTypes('GET_BILLING_SETTINGS');
export const UPDATE_BILLING_SETTINGS = createRequestTypes('UPDATE_BILLING_SETTINGS');
export const GET_ACCOUNT_BALANCE = createRequestTypes('GET_ACCOUNT_BALANCE');
export const GET_ACCOUNT_CREDIT_BALANCE = createRequestTypes('GET_ACCOUNT_CREDIT_BALANCE');
export const GET_DUE_DATE = createRequestTypes('GET_DUE_DATE');
export const GET_AMOUNT_DUE = createRequestTypes('GET_AMOUNT_DUE');

export const CALCULATE_TAXES = createRequestTypes('CALCULATE_TAXES')

// -- BILLING BADJES -- //

export const getBillingBadjes = (accountId) => async (dispatch) => {
	dispatch(actionCreator.request(SET_BILLING_BADJES));
	try {
		const response = await axiosInstance.get(`/account/${accountId}/counts/billingobjects`);
		dispatch(actionCreator.success(SET_BILLING_BADJES, response.data.billingObjects));
	} catch (err) {
		dispatch(actionCreator.failure(SET_BILLING_BADJES));
	}
};

export const emptyBillingBadjes = () => (dispatch) => {
	dispatch(actionCreator.empty(SET_BILLING_BADJES));
};

// -- BILLING BADJES END --//

// -- BALANCES TAB -- //

export const getBalancesInfo = (id) => async (dispatch) => {
	dispatch(actionCreator.request(SET_BALANCES_INFO));
	try {
		const response = await axiosInstance.get(`/account/${id}/balance/details`);
		dispatch(actionCreator.success(SET_BALANCES_INFO, response.data));
	} catch (err) {
		dispatch(actionCreator.failure(SET_BALANCES_INFO), err.response.data.error);
	}
};

export const emptyBalancesInfo = () => (dispatch) => {
	dispatch(actionCreator.empty(SET_BALANCES_INFO));
};
// -- BALANCES TAB --//

// -- ADJUSTMENTS TAB -- //

export const getAdjustments = (accountId) => async (dispatch) => {
	dispatch(actionCreator.request(SET_ADJUSTMENTS));
	try {
		const response = await axiosInstance.get(`account/${accountId}/adjustments/pending`);
		dispatch(actionCreator.success(SET_ADJUSTMENTS, response.data.pendingAdjustments))
		return response.data;
	} catch (err) {
		dispatch(actionCreator.failure(SET_ADJUSTMENTS));
	}
};

export const createImmediatAdjustment = (accountId, data) => async (dispatch) => {
	dispatch(actionCreator.request(CREATE_IMMEDIAT_ADJUSTMENT));
	try {
		const response = await axiosInstance.post(`/account/${accountId}/adjustment/immediate`, data);
		dispatch(getAccountBalance(accountId));
		dispatch(getBillingBadjes(accountId));
		return response;
	} catch (err) {
		dispatch(actionCreator.failure(CREATE_IMMEDIAT_ADJUSTMENT));
		return err.response;
	}
};

export const createImmediateAdjustments = (accountId, data) => async (dispatch) => {
	dispatch(actionCreator.request(CREATE_IMMEDIATE_ADJUSTMENTS));
	try {
		const response = await axiosInstance.post(`/account/${accountId}/adjustments/immediate`, data);
		if (response.data.success) {
			dispatch(getAccountBalance(accountId));
			dispatch(getBillingBadjes(accountId));
		}
		return response;
	} catch (err) {
		dispatch(actionCreator.failure(CREATE_IMMEDIATE_ADJUSTMENTS));
		return err.response;
	}
};

export const createPendingAdjustment = (accountId, data) => async (dispatch) => {
	dispatch(actionCreator.request(CREATE_PENDING_ADJUSTMENT));
	try {
		const response = await axiosInstance.post(`/account/${accountId}/adjustment/pending`, data);
		dispatch(getAdjustments(accountId));
		dispatch(getAccountBalance(accountId));
		dispatch(getBillingBadjes(accountId));

		return response;
	} catch (err) {
		dispatch(actionCreator.failure(CREATE_PENDING_ADJUSTMENT));
		return err.response;
	}
};

export const updatePendingAdjustment = (accountId, amount, adjustmentId) => async (dispatch) => {
	dispatch(actionCreator.request(CREATE_PENDING_ADJUSTMENT));
	try {
		const response = await axiosInstance.patch(`/account/${accountId}/adjustments/pending/${adjustmentId}`, amount);
		if (response.data.success) {
			dispatch(getAdjustments(accountId));
			dispatch(getAccountBalance(accountId));
			dispatch(getBillingBadjes(accountId));
		}

		return response;
	} catch (err) {
		dispatch(actionCreator.failure(CREATE_PENDING_ADJUSTMENT));
		return err.response;
	}
};

export const deleteAdjustment = (accountId, adjustmentId) => async (dispatch) => {
	dispatch(actionCreator.request(DELETE_ADJUSTMENTS));
	try {
		const response = await axiosInstance.delete(`/account/${accountId}/adjustments/pending/${adjustmentId}`);
		if (response.data.success) {
			dispatch(getAdjustments(accountId));
			dispatch(getAccountBalance(accountId));
			dispatch(getBillingBadjes(accountId));
		}
		return response;
	} catch (err) {
		dispatch(actionCreator.failure(DELETE_ADJUSTMENTS));
		return err.response;
	}
};

export const getAdjustmentTypes = () => async (dispatch) => {
	dispatch(actionCreator.request(SET_ADJUSTMENT_TYPE));
	try {
		const response = await axiosInstance.get('/domain/items/adjustment');
		if (response.data.success) {
			dispatch(actionCreator.success(SET_ADJUSTMENT_TYPE, response.data.items));
		}
	} catch (err) {
		dispatch(actionCreator.failure(SET_ADJUSTMENT_TYPE));
	}
};

export const getAccountServicelines = (accountId) => async (dispatch) => {
	dispatch(actionCreator.request(SET_ACCOUNT_SERVICELINES));
	try {
		const response = await axiosInstance.get(`/account/${accountId}/servicelines/numbers`);
		dispatch(actionCreator.success(SET_ACCOUNT_SERVICELINES, response.data.servicelines));
	} catch (err) {
		dispatch(actionCreator.failure(SET_ACCOUNT_SERVICELINES));
	}
};

export const emptyAdjustments = () => (dispatch) => {
	dispatch(actionCreator.empty(SET_ADJUSTMENTS));
};

export const emptyAdjustmentTypes = () => (dispatch) => {
	dispatch(actionCreator.empty(SET_ADJUSTMENT_TYPE));
};

export const emptyAccountServicelines = () => (dispatch) => {
	dispatch(actionCreator.empty(SET_ACCOUNT_SERVICELINES));
};

// -- ADJUSTMENTS TAB - ENDS -- //

// -- INVOICES TAB -- START -- //

export const getInvoices = (accountId) => async (dispatch) => {
	dispatch(actionCreator.request(SET_INVOICES));
	try {
		const response = await axiosInstance.get(`account/${accountId}/invoices`);
		dispatch(actionCreator.success(SET_INVOICES, response.data.invoices));
	} catch (err) {
		dispatch(actionCreator.failure(SET_INVOICES));
	}
};

export const emptyInvoices = () => (dispatch) => {
	dispatch(actionCreator.empty(SET_INVOICES));
};

// -- INVOICES TAB -- END -- //

// -- PAYMENT TAB -- START --//

export const getPayments = (accountId) => async (dispatch) => {
	dispatch(actionCreator.request(SET_PAYMENTS));
	try {
		const response = await axiosInstance.get(`account/${accountId}/payments`);
		dispatch(actionCreator.success(SET_PAYMENTS, response.data.payments));
	} catch (err) {
		dispatch(actionCreator.failure(SET_PAYMENTS));
	}
};
export const getInstallmentPlans = (accountId) => async (dispatch) => {
	dispatch(actionCreator.request(SET_INSTALLMENTPLAN));
	try {
		const response = await axiosInstance.get(`/account/${accountId}/installmentPlan`);
		dispatch(actionCreator.success(SET_INSTALLMENTPLAN, response.data));
	} catch (err) {
		dispatch(actionCreator.failure(SET_INSTALLMENTPLAN));
	}
};

export const getPaymentDetails = async (accountId, paymentId) => {
	// dispatch(actionCreator.request(SET_PAYMENT_DETAILS));
	try {
		const response = await axiosInstance.get(`/account/${accountId}/payment/${paymentId}`);
		return response;
		// dispatch(actionCreator.success(SET_PAYMENT_DETAILS, response.data.paymentDetails));
	} catch (err) {
		return err.response;
		// dispatch(actionCreator.failure(SET_PAYMENT_DETAILS));
	}
};
export const getInstallmentPlanDetails = async (accountId, installmentPlanId) => {
	// dispatch(actionCreator.request(SET_PAYMENT_DETAILS));
	try {
		const response = await axiosInstance.get(`/account/${accountId}/installmentPlan/${installmentPlanId}`);
		return response;
		// dispatch(actionCreator.success(SET_PAYMENT_DETAILS, response.data.paymentDetails));
	} catch (err) {
		return err.response;
		// dispatch(actionCreator.failure(SET_PAYMENT_DETAILS));
	}
};

export const emptyPayments = () => (dispatch) => {
	dispatch(actionCreator.empty(SET_PAYMENTS));
};
export const emptyInstallmentPlans = () => (dispatch) => {
	dispatch(actionCreator.empty(SET_INSTALLMENTPLAN));
};

export const reversePayment = (accountId, paymentId, data) => async (dispatch) => {
	dispatch(actionCreator.request(REVERSE_PAYMENT));
	try {
		const response = await axiosInstance.post(`/account/${accountId}/payment/${paymentId}/reverse`, data);
		dispatch(actionCreator.success(REVERSE_PAYMENT, response.data));
		await paymentSubmitted()(dispatch);
		return response.data;
	} catch (err) {
		dispatch(actionCreator.failure(REVERSE_PAYMENT));

		return err;
	}
};

export const emptySupportedReversalTypes = () => async (dispatch) => {
	dispatch(actionCreator.empty(SET_SUPPORTED_REVERSAL_TYPES));
};

export const getSupportedReversalTypes = (paymentId) => async (dispatch) => {
	dispatch(actionCreator.request(SET_SUPPORTED_REVERSAL_TYPES));
	try {
		const response = await axiosInstance.get(`/domain/payment/${paymentId}/reversal-types`);
		dispatch(actionCreator.success(SET_SUPPORTED_REVERSAL_TYPES, response.data.reversalTypes));
		return response.data;
	} catch (err) {
		dispatch(actionCreator.failure(SET_SUPPORTED_REVERSAL_TYPES));

		return err;
	}
};

// export const emptyPaymentDetails = () => (dispatch) => {
// 	dispatch(actionCreator.empty(SET_PAYMENT_DETAILS));
// };

// -- PAYMENT TAB -- END -- //

// -- RECCURING CREDITS -- START --//

export const getReccuringCredits = (accountId) => async (dispatch) => {
	dispatch(actionCreator.request(SET_RECCURING_CREDITS));
	try {
		const response = await axiosInstance.get(`/account/${accountId}/credits/recurring`);
		dispatch(actionCreator.success(SET_RECCURING_CREDITS, response.data.recurringCredits));
	} catch (err) {
		dispatch(actionCreator.failure(SET_RECCURING_CREDITS));
	}
};

export const createReccuringCredit = (accountId, data) => async (dispatch) => {
	dispatch(actionCreator.request(CREATE_RECCURING_CREDIT));
	try {
		const response = await axiosInstance.post(`/account/${accountId}/credit/recurring`, data);

		dispatch(getReccuringCredits(accountId));
		dispatch(getBillingBadjes(accountId));

		return response;
	} catch (err) {
		dispatch(actionCreator.failure(CREATE_RECCURING_CREDIT));
		return err.response;
	}
};

export const updateReccuringCredit = (accountId, accountRecurringCreditId, data) => async (dispatch) => {
	dispatch(actionCreator.request(UPDATE_RECCURING_CREDIT));
	try {
		const response = await axiosInstance.patch(
			`/account/${accountId}/credit/recurring/${accountRecurringCreditId}`,
			data
		);

		dispatch(getReccuringCredits(accountId));
		dispatch(getBillingBadjes(accountId));

		return response;
	} catch (err) {
		dispatch(actionCreator.failure(UPDATE_RECCURING_CREDIT));
		return err.response;
	}
};

export const deleteReccuringCredits = (accountId, accountRecurringCreditId) => async (dispatch) => {
	dispatch(actionCreator.request(DELETE_RECCURING_CREDITS));
	try {
		const response = await axiosInstance.delete(
			`/account/${accountId}/credit/recurring/${accountRecurringCreditId}`
		);

		dispatch(getReccuringCredits(accountId));
		dispatch(getBillingBadjes(accountId));

		return response;
	} catch (err) {
		dispatch(actionCreator.failure(DELETE_RECCURING_CREDITS));
		return err.response;
	}
};

export const getReccuringCreditsTypes = () => async (dispatch) => {
	dispatch(actionCreator.request(SET_RECCURING_CREDIT_TYPES));
	try {
		const response = await axiosInstance.get(`/domain/recurringcredits`);
		dispatch(actionCreator.success(SET_RECCURING_CREDIT_TYPES, response.data));
	} catch (err) {
		dispatch(actionCreator.failure(SET_RECCURING_CREDIT_TYPES));
	}
};

export const emptyReccuringCredits = () => (dispatch) => {
	dispatch(actionCreator.empty(SET_RECCURING_CREDITS));
};

export const emptyReccuringCreditsTypes = () => (dispatch) => {
	dispatch(actionCreator.empty(SET_RECCURING_CREDIT_TYPES));
};

// -- RECCURING CREDITS -- END --//

// -- DISCOUNTS -- START -- //

export const getDiscounts = (accountId) => async (dispatch) => {
	dispatch(actionCreator.request(SET_DISCOUNTS));
	try {
		const response = await axiosInstance.get(`/account/${accountId}/discounts`);
		dispatch(actionCreator.success(SET_DISCOUNTS, response.data.discounts));
	} catch (err) {
		dispatch(actionCreator.failure(SET_DISCOUNTS));
	}
};

export const createDiscount = (accountId, data) => async (dispatch) => {
	dispatch(actionCreator.request(CREATE_DISCOUNT));
	try {
		const response = await axiosInstance.post(`/account/${accountId}/discount`, data);

		dispatch(getDiscounts(accountId));
		dispatch(getBillingBadjes(accountId));

		return response;
	} catch (err) {
		dispatch(actionCreator.failure(CREATE_DISCOUNT));
		return err.response;
	}
};

export const updateDiscount = (accountId, accountDiscountId, data) => async (dispatch) => {
	dispatch(actionCreator.request(UPDATE_DISCOUNT));
	try {
		const response = await axiosInstance.patch(`/account/${accountId}/discount/${accountDiscountId}`, data);

		dispatch(getDiscounts(accountId));
		dispatch(getBillingBadjes(accountId));

		return response;
	} catch (err) {
		dispatch(actionCreator.failure(UPDATE_DISCOUNT));
		return err.response;
	}
};

export const deleteDiscount = (accountId, accountDiscountId) => async (dispatch) => {
	dispatch(actionCreator.request(DELETE_DISCOUNT));
	try {
		const response = await axiosInstance.delete(`/account/${accountId}/discount/${accountDiscountId}`);

		dispatch(getDiscounts(accountId));
		dispatch(getBillingBadjes(accountId));

		return response;
	} catch (err) {
		dispatch(actionCreator.failure(DELETE_DISCOUNT));
		return err.response;
	}
};

export const getDiscountTypes = (accountTypeId, locationId) => async (dispatch) => {
	dispatch(actionCreator.request(SET_DISCOUNT_TYPES));
	try {
		const response = await axiosInstance.get(`/domain/discounts/${accountTypeId}/${locationId}`);
		dispatch(actionCreator.success(SET_DISCOUNT_TYPES, response.data));
	} catch (err) {
		dispatch(actionCreator.failure(SET_DISCOUNT_TYPES));
	}
};

export const emptyDiscounts = () => (dispatch) => {
	dispatch(actionCreator.empty(SET_DISCOUNTS));
};

export const emptyDiscountsTypes = () => (dispatch) => {
	dispatch(actionCreator.empty(SET_DISCOUNT_TYPES));
};

// -- DISCOUNTS -- END -- //

// -- PAYMENT PROMISES -- START -- //

export const getPaymentPromises = (accountId) => async (dispatch) => {
	dispatch(actionCreator.request(SET_PAYMENT_PROMISES));
	try {
		const response = await axiosInstance.get(`/account/${accountId}/paymentpromises`);
		dispatch(actionCreator.success(SET_PAYMENT_PROMISES, response.data.paymentPromises));
	} catch (err) {
		dispatch(actionCreator.failure(SET_PAYMENT_PROMISES));
	}
};

export const createPaymentPromises = (accountId, data) => async (dispatch) => {
	dispatch(actionCreator.request(CREATE_PAYMENT_PROMISES));
	try {
		const response = await axiosInstance.post(`/account/${accountId}/paymentpromise`, data);
		dispatch(actionCreator.success(CREATE_PAYMENT_PROMISES, response.data));

		dispatch(getPaymentPromises(accountId));
		dispatch(getBillingBadjes(accountId));
		dispatch(getAccountPromises(accountId));

		return response;
	} catch (err) {
		dispatch(actionCreator.failure(CREATE_PAYMENT_PROMISES));
		return err.response;
	}
};

export const emptyPaymentPromises = () => (dispatch) => {
	dispatch(actionCreator.empty(SET_PAYMENT_PROMISES));
};

// -- PAYMENT PROMISES -- END -- //

// -- Tax Exemptions -- START -- //

export const getTaxExemptions = (accountId) => async (dispatch) => {
	dispatch(actionCreator.request(SET_TAX_EXEMPTIONS));
	try {
		const response = await axiosInstance.get(`/account/${accountId}/taxexemptions`);
		dispatch(actionCreator.success(SET_TAX_EXEMPTIONS, response.data.taxExemptions));
	} catch (err) {
		dispatch(actionCreator.failure(SET_TAX_EXEMPTIONS));
	}
};

export const createTaxExemption = (accountId, data) => async (dispatch) => {
	dispatch(actionCreator.request(CREATE_TAX_EXEMPTION));
	try {
		const response = await axiosInstance.post(`/account/${accountId}/taxexemption`, data);

		dispatch(getTaxExemptions(accountId));
		dispatch(getBillingBadjes(accountId));

		return response;
	} catch (err) {
		dispatch(actionCreator.failure(CREATE_TAX_EXEMPTION));
		return err.response;
	}
};

export const updateTaxExemption = (accountId, taxExemptionId, data) => async (dispatch) => {
	dispatch(actionCreator.request(UPDATE_TAX_EXEMPTION));
	try {
		const response = await axiosInstance.patch(`/account/${accountId}/taxexemption/${taxExemptionId}`, data);

		dispatch(getTaxExemptions(accountId));
		dispatch(getBillingBadjes(accountId));

		return response;
	} catch (err) {
		dispatch(actionCreator.failure(UPDATE_TAX_EXEMPTION));
		return err.response;
	}
};

export const deleteTaxExemption = (accountId, taxExemptionId) => async (dispatch) => {
	dispatch(actionCreator.request(DELETE_TAX_EXEMPTION));
	try {
		const response = await axiosInstance.delete(`/account/${accountId}/taxexemption/${taxExemptionId}`);

		dispatch(getTaxExemptions(accountId));
		dispatch(getBillingBadjes(accountId));

		return response;
	} catch (err) {
		dispatch(actionCreator.failure(DELETE_TAX_EXEMPTION));
		return err.response;
	}
};

export const getTaxCodes = (accountId) => async (dispatch) => {
	dispatch(actionCreator.request(SET_TAX_CODES));
	try {
		const response = await axiosInstance.get(`domain/taxcodes`);
		dispatch(actionCreator.success(SET_TAX_CODES, response.data.taxCodes));
	} catch (err) {
		dispatch(actionCreator.failure(SET_TAX_CODES));
	}
};

export const emptyTaxExemptions = () => (dispatch) => {
	dispatch(actionCreator.empty(SET_TAX_EXEMPTIONS));
};

export const emptyTaxCodes = () => (dispatch) => {
	dispatch(actionCreator.empty(SET_TAX_CODES));
};

// -- Tax Exemptions -- END -- //

// -- Account History -- START -- //

export const getStatusHistory = (accountId) => async (dispatch) => {
	dispatch(actionCreator.request(SET_STATUS_HISTORY));
	try {
		const response = await axiosInstance.get(`/account/${accountId}/statushistory`);
		dispatch(actionCreator.success(SET_STATUS_HISTORY, response.data.statusHistories));
	} catch (err) {
		dispatch(actionCreator.failure(SET_STATUS_HISTORY));
	}
};

export const emptyStatusHistory = () => (dispatch) => {
	dispatch(actionCreator.empty(SET_STATUS_HISTORY));
};
// -- Account History -- End -- //

export const getBillingSettings = (accountId) => async (dispatch) => {
	dispatch(actionCreator.request(GET_BILLING_SETTINGS));
	try {
		const response = await axiosInstance.get(`/account/${accountId}/billing-settings`);
		dispatch(actionCreator.success(GET_BILLING_SETTINGS, response.data));
	} catch (err) {
		dispatch(actionCreator.failure(GET_BILLING_SETTINGS));
	}
};

export const updateBillingSettings = (accountId, billingSettings) => async (dispatch) => {
	dispatch(actionCreator.request(UPDATE_BILLING_SETTINGS));

	try {
		const response = await axiosInstance.patch(`/account/${accountId}/billing-settings`, billingSettings);
		dispatch(actionCreator.success(UPDATE_BILLING_SETTINGS, response.data));
		return response;
	} catch (err) {
		let mockError = {
			message: 'Something goes wrong'
		}
		dispatch(actionCreator.failure(UPDATE_BILLING_SETTINGS, mockError));
		return err.response;
	}
};

export const getAccountBalance = (accountId) => async (dispatch) => {
	dispatch(actionCreator.request(GET_ACCOUNT_BALANCE));
	try {
		const response = await axiosInstance.get(`/account/${accountId}/balance`);
		dispatch(actionCreator.success(GET_ACCOUNT_BALANCE, response.data.balance));
	} catch (err) {
		dispatch(actionCreator.failure(GET_ACCOUNT_BALANCE));
	}
};

export const emptyAccountBalance = () => async (dispatch) => {
	dispatch(actionCreator.empty(GET_ACCOUNT_BALANCE));
};

export const getAccountCreditBalance = (accountId) => async (dispatch) => {
	dispatch(actionCreator.request(GET_ACCOUNT_CREDIT_BALANCE));
	try {
		const response = await axiosInstance.get(`/account/${accountId}/credit/balance`);
		dispatch(actionCreator.success(GET_ACCOUNT_CREDIT_BALANCE, response.data.balance));
	} catch (err) {
		dispatch(actionCreator.failure(GET_ACCOUNT_CREDIT_BALANCE));
	}
};

export const emptyAccountCreditBalance = () => async (dispatch) => {
	dispatch(actionCreator.empty(GET_ACCOUNT_CREDIT_BALANCE));
};

export const getDueDetails = (accountId) => async (dispatch) => {
	dispatch(actionCreator.request(GET_DUE_DATE));
	dispatch(actionCreator.request(GET_AMOUNT_DUE));
	try {
		const response = await axiosInstance.get(`/account/${accountId}/due-details`);
		dispatch(actionCreator.success(GET_DUE_DATE, response.data.dueDate));
		dispatch(actionCreator.success(GET_AMOUNT_DUE, response.data.amountDue));
	} catch (err) {
		dispatch(actionCreator.failure(GET_DUE_DATE));
		dispatch(actionCreator.failure(GET_AMOUNT_DUE));
	}
};

export const emptyDueDetails = () => async (dispatch) => {
	dispatch(actionCreator.empty(GET_DUE_DATE));
	dispatch(actionCreator.empty(GET_AMOUNT_DUE));
};

export const calculateTaxes = (accountId, payload) => async (dispatch) => {
	dispatch(actionCreator.request(CALCULATE_TAXES));
	try {
		const response = await axiosInstance.post(`taxation/account/${accountId}/calculate`, payload);
		dispatch(actionCreator.success(CALCULATE_TAXES));
		return response;
	} catch (err) {
		dispatch(actionCreator.failure(CALCULATE_TAXES));
		return err.response;
	}
};
