import {INITIALIZE, REGISTER_STEP, PREVIOUS_STEP, NEXT_STEP, DESTROY, SET_STEP_STATE} from './actionTypes';
import {actionCreator} from "../utils/helpers";

export const initializeWizard = (name, firstStep) => (dispatch) => {
    dispatch(actionCreator.success(INITIALIZE, {name, firstStep}));
}

export const registerStep = (name, step) => (dispatch) => {
    dispatch(actionCreator.success(REGISTER_STEP, {name, step}));
}

export const previousStep = (name, statePayload) => (dispatch) => {
    dispatch(actionCreator.success(PREVIOUS_STEP, {name, statePayload}));
}

export const nextStep = (name, statePayload) => (dispatch) => {
    dispatch(actionCreator.success(NEXT_STEP, {name, statePayload}));
}

export const setStepBaseState = (name, stepName, statePayload, timestamp = Date.now()) => (dispatch) => {
    dispatch(actionCreator.success(SET_STEP_STATE, {name, stepName, statePayload, timestamp}));
}

export const destroyWizard = (name) => (dispatch) => {
    dispatch(actionCreator.success(DESTROY, {name}));
}