import axiosInstance from '../../utils/axios-instance';
import {actionCreator, createRequestTypes, getErrorMessage} from '../../utils/helpers';

export const GET_ACCOUNT_DOCUMENTS = createRequestTypes('GET_ACCOUNT_DOCUMENTS');
export const CREATE_ACCOUNT_DOCUMENT = createRequestTypes('CREATE_ACCOUNT_DOCUMENT');
export const DELETE_ACCOUNT_DOCUMENT = createRequestTypes('DELETE_ACCOUNT_DOCUMENT');

export const getAccountDocuments = (accountId) => async (dispatch) => {
    dispatch(actionCreator.request(GET_ACCOUNT_DOCUMENTS));
    try {
        const response = await axiosInstance.get(`/account/${accountId}/documents`);
        dispatch(actionCreator.success(GET_ACCOUNT_DOCUMENTS, response.data.documents));
        return response.data.documents;
    } catch (err) {
        dispatch(actionCreator.failure(GET_ACCOUNT_DOCUMENTS, getErrorMessage(err)));
        return err.response.data;
    }
};

export const emptyAccountDocuments = () => (dispatch) => {
    dispatch(actionCreator.empty(GET_ACCOUNT_DOCUMENTS));
};

export const createAccountDocument = (accountId, formData) => async (dispatch) => {
    dispatch(actionCreator.request(CREATE_ACCOUNT_DOCUMENT));
    try {
        const response = await axiosInstance.post(`/account/${accountId}/document`, formData,
            {headers: {'Content-Type': 'multipart/form-data'}}
        );
        dispatch(actionCreator.success(CREATE_ACCOUNT_DOCUMENT, response.data));
        return response.data;

    } catch (err) {
        dispatch(actionCreator.failure(CREATE_ACCOUNT_DOCUMENT, getErrorMessage(err)));
        return err.response.data;
    }
};

export const deleteAccountDocument = (accountId, documentId) => async (dispatch) => {
    dispatch(actionCreator.request(DELETE_ACCOUNT_DOCUMENT));
    try {
        const response = await axiosInstance.delete(`/account/${accountId}/documents/${documentId}/delete_document`);
        dispatch(actionCreator.success(DELETE_ACCOUNT_DOCUMENT, response.data));
        return response.data;

    } catch (err) {
        dispatch(actionCreator.failure(DELETE_ACCOUNT_DOCUMENT, getErrorMessage(err)));
        return err.response.data;
    }
};
