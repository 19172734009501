import axiosInstance from '../../utils/axios-instance';
import {actionCreator, createRequestTypes, getErrorMessage} from '../../utils/helpers';

export const CREATE_ORDER = createRequestTypes('CREATE_ORDER');
export const PLANS_AND_BUNDLES = createRequestTypes('PLANS_AND_BUNDLES');
export const PLANS_DETAILS = createRequestTypes('PLANS_DETAILS');
export const SERVICE_EQUIPMENT = createRequestTypes('SERVICE_EQUIPMENT');
export const EMPTY_NEW_ORDER = createRequestTypes('EMPTY_NEW_ORDER');
export const START_ORDER = createRequestTypes('START_ORDER');

export const SERVICE_REQUEST_START_ORDER = createRequestTypes('SERVICE_REQUEST_START_ORDER');
export const SERVICE_REQUEST_PENDING_TASKS_COUNT = createRequestTypes('SERVICE_REQUEST_PENDING_TASKS_COUNT');

export const MAP_LINK_LOCATION = createRequestTypes('MAP_LINK_LOCATION');

export const createNewOrder = (data) => async (dispatch) => {
	dispatch(actionCreator.request(CREATE_ORDER));
	try {
		const response = await axiosInstance.post(`/order/newservice`, data);

		let responseData = { ...response.data };
		responseData['type'] = 'order';
		dispatch(actionCreator.success(CREATE_ORDER, responseData));
		return response;
	} catch (err) {
		dispatch(actionCreator.failure(CREATE_ORDER));
		return err;
	}
};

export const createNewQuote = (data) => async (dispatch) => {
	dispatch(actionCreator.request(CREATE_ORDER));
	try {
		const response = await axiosInstance.post(`/quote`, data);
		let responseData = { ...response.data };
		responseData['type'] = 'quotes';
		dispatch(actionCreator.success(CREATE_ORDER, responseData));
		return response;
	} catch (err) {
		dispatch(actionCreator.failure(CREATE_ORDER));
		return err;
	}
};

export const getPlansAndBundles = (locationId, address) => async (dispatch) => {
	dispatch(actionCreator.request(PLANS_AND_BUNDLES));
	try {
		const response = await axiosInstance.post(`/order/plans/${locationId}`, address);

		dispatch(actionCreator.success(PLANS_AND_BUNDLES, response.data));
	} catch (err) {
		let mockError = {
			message: 'Not found'
		};
		dispatch(actionCreator.failure(PLANS_AND_BUNDLES, mockError));
	}
};

export const getPlansDetails = (accountId, locationId, planId) => async (dispatch) => {
	let data = {
		accountId,
		locationId,
		planId,
		fromCustomerPortal: false
	};

	dispatch(actionCreator.request(PLANS_DETAILS));
	try {
		const response = await axiosInstance.get(`/domain/plan`, { params: data });
		let planDetails = { ...response.data };

		let serviceModelId = {
			serviceModelId: response.data.serviceModel.id
		};

		let numberCategoriesData = {
			serviceModelId: response.data.serviceModel.id,
			locationId: locationId
		};

		const equipment = await axiosInstance.get(`/domain/serviceequipment`, { params: serviceModelId });
		const numberCategories = await axiosInstance.get(`/domain/numbercategories`, { params: numberCategoriesData });

		planDetails['equipment'] = equipment.data;
		planDetails['numberCategories'] = numberCategories.data;

		dispatch(actionCreator.success(PLANS_DETAILS, planDetails));
		return planDetails;
	} catch (err) {
		dispatch(actionCreator.failure(PLANS_DETAILS, getErrorMessage(err)));
	}
};

export const getServiceEquipment = (serviceModelId) => async (dispatch) => {
	let data = {
		serviceModelId
	};

	dispatch(actionCreator.request(SERVICE_EQUIPMENT));
	try {
		const response = await axiosInstance.get(`/domain/serviceequipment`, { params: data });
		dispatch(actionCreator.success(SERVICE_EQUIPMENT, response.data));
	} catch (err) {
		let mockError = {
			message: 'Not found'
		};
		dispatch(actionCreator.failure(SERVICE_EQUIPMENT, mockError));
	}
};

export const getInventoryNumbers = async (categoryId, limit, serviceModelId) => {
	let data = {
		categoryId,
		limit,
		serviceModelId
	};

	try {
		const response = await axiosInstance.get(`/domain/inventorynumbers`, { params: data });
		return response.data;
	} catch (err) {
		// console.log(err.response)
	}
};

export const startOrder = async (orderId) => {
	const data = {
		serviceOrders: [
			{
				serviceOrderId: 0,
				variables: {}
			}
		]
	};

	try {
		const response = await axiosInstance.post(`/order/${orderId}/startall`, data);
		return response.data;
	} catch (err) {
		return err.response.data;
	}
};

export const emptyPlanDetails = () => {
	return (dispatch) => {
		dispatch(actionCreator.empty(PLANS_DETAILS));
	};
};
export const emptyNewOrder = () => {
	return (dispatch) => {
		dispatch({ type: "EMPTY_NEW_ORDER" });
	};
};





export const startServiceOrder = (orderId) => async (dispatch) => {
	dispatch(actionCreator.request(SERVICE_REQUEST_START_ORDER))
	const data = {
		serviceOrders: [
			{
				serviceOrderId: 0,
				variables: {}
			}
		]
	};

	try {
		const response = await axiosInstance.post(`/order/${orderId}/startall`, data);
		dispatch(actionCreator.success(SERVICE_REQUEST_START_ORDER, true))
		dispatch(getPendingTasksCount(orderId))
		return response.data;
	} catch (err) {
		dispatch(actionCreator.failure(SERVICE_REQUEST_START_ORDER, 'Error'))
		return err.response.data;
	}
};

export const getPendingTasksCount = (orderId) => async (dispatch) =>  {
	dispatch(actionCreator.request(SERVICE_REQUEST_PENDING_TASKS_COUNT))
	try {
		const response = await axiosInstance.get(`/order/${orderId}/tasks`);
		dispatch(actionCreator.success(SERVICE_REQUEST_PENDING_TASKS_COUNT, response.data.length))
		return response.data;
	} catch (err) {
		dispatch(actionCreator.failure(SERVICE_REQUEST_PENDING_TASKS_COUNT, 'Error'))
		return err.response;
	}
};

export const getMapLinkLocation = (locationId, address) => async (dispatch) => {
	dispatch(actionCreator.request(MAP_LINK_LOCATION));
	try {
		const response = await axiosInstance.post(`/order/map/embed/${locationId}`, address);

		dispatch(actionCreator.success(MAP_LINK_LOCATION, response.data));
	} catch (err) {
		let mockError = {
			error: err.response.data.error,
		};
		dispatch(actionCreator.failure(MAP_LINK_LOCATION, mockError));
	}
};


export const emptyMapLinkLocation = () => {
	return (dispatch) => {
		dispatch(actionCreator.empty(MAP_LINK_LOCATION));
	};
};