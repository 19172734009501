import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import {
	deleteAdjustment,
	deleteReccuringCredits,
	deleteDiscount,
	deleteTaxExemption
} from '../../../actions/accountDetailsActions/billing.actions';
import { hideModal } from '../../../actions/modal.actions';
import { getModalData } from '../../../selectors';

class DeleteAdjustmentModal extends Component {
	deleteCurrentAdjustment = () => {
		const { accountId, itemId, type } = this.props.modalProps;
		if (type === 'adjustment') {
			this.props.deleteAdjustment(accountId, itemId).then((response) => {
				if (response.data.success) {
					this.props.hideModal();
				}
			});
		} else if (type === 'reccuring') {
			this.props.deleteReccuringCredits(accountId, itemId).then((response) => {
				if (response.data.success) {
					this.props.hideModal();
				}
			});
		} else if (type === 'discount') {
			this.props.deleteDiscount(accountId, itemId).then((response) => {
				if (response.data.success) {
					this.props.hideModal();
				}
			});
		} else if (type === 'taxExemption') {
			this.props.deleteTaxExemption(accountId, itemId).then((response) => {
				if (response.data.success) {
					this.props.hideModal();
				}
			});
		}
	};

	formatText = () => {
		let text = '';
		switch (this.props.modalProps.type) {
			case 'reccuring':
				return (text = 'Reccuring Credit');
			case 'adjustment':
				return (text = 'Adjustment');
			case 'discount':
				return (text = 'Discounts');
			case 'taxExemptions':
				return (text = 'Tax Exemption');
			default:
				return text;
		}
	};
	render() {
		return (
			<Fragment>
				<div className="modal" style={{ display: 'block' }} tabIndex="-1" role="dialog">
					<div className="modal-dialog" role="document">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">Confirm Delete {this.formatText()}</h5>
								<button onClick={this.props.hideModal} type="button" className="close">
									<span aria-hidden="true">&times;</span>
								</button>
							</div>
							<div className="modal-body">
								{this.props.modalProps.type === 'reccuring' ? (
									<p className="lead">
										You are about to remove the recurring credit ${this.props.modalProps.description}{' '}
										for the current account, do you want to continue?`}?
									</p>
								) : (
									<p className="lead">Are you sure you want to delete this {this.formatText()}</p>
								)}
							</div>
							<div className="modal-footer">
								<button onClick={this.props.hideModal} type="button" className="btn">
									No
								</button>
								<button
									onClick={this.deleteCurrentAdjustment}
									type="button"
									className="btn btn-primary"
								>
									Yes
								</button>
							</div>
						</div>
					</div>
				</div>
				<div className="modal-backdrop show" tabIndex="1" />
			</Fragment>
		);
	}
}

const mapStateToProps = (state) => {
	const modalProps = getModalData(state).modalProps;
	return {
		modalProps
	};
};

const mapDispatchToProps = {
	hideModal,
	deleteAdjustment,
	deleteDiscount,
	deleteReccuringCredits,
	deleteTaxExemption
};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteAdjustmentModal);
