import axiosInstance from '../../utils/axios-instance';
import {actionCreator, createRequestTypes, getErrorMessage} from '../../utils/helpers';

export const SET_FROM_SIGNUP_TO_SURVEY = createRequestTypes('SET_FROM_SIGNUP_TO_SURVEY');
export const SET_FROM_SURVEY_TO_SIGNUP = createRequestTypes('SET_FROM_SURVEY_TO_SIGNUP');
export const CHANGE_ACCOUNT_STATUS = createRequestTypes('CHANGE_ACCOUNT_STATUS');

export const convertFromSignupToSurvey = (id, updateServiceOrders) => async (dispatch) => {
    dispatch(actionCreator.request(SET_FROM_SIGNUP_TO_SURVEY));

    try {
        const response = await axiosInstance.get(`/account/${id}/signuptosurvey`, {params: {updateServiceOrders}});
        dispatch(actionCreator.success(SET_FROM_SIGNUP_TO_SURVEY))
        return response.data;
        /* if(response.data.success === true) {
            console.log(this.props)
        } */
    } catch(err) {
        let mockError = {
            message: err.response.data.error.message
        };
        dispatch(actionCreator.failure(SET_FROM_SIGNUP_TO_SURVEY, mockError))
    }
}

export const convertFromSurveyToSignUp = (id, updateServiceOrders) => async (dispatch) => {
	dispatch(actionCreator.request(SET_FROM_SURVEY_TO_SIGNUP));

	try {
		const response = await axiosInstance.get(`/account/${id}/surveytosignup`, {params:{updateServiceOrders}});
        dispatch(actionCreator.success(SET_FROM_SURVEY_TO_SIGNUP));
        return response.data
	} catch(err) {
		let mockError = {
            message: err.response.data.error.message
		};
		dispatch(actionCreator.failure(SET_FROM_SURVEY_TO_SIGNUP, mockError));
		return err.response.data;
	}
}

export const changeAccountStatus = (accountId, toStatus) => async (dispatch) => {
    dispatch(actionCreator.request(CHANGE_ACCOUNT_STATUS));

    try {
        const response = await axiosInstance.post(`/account/${accountId}/status-transition?toStatus=${toStatus}`);
        dispatch(actionCreator.success(CHANGE_ACCOUNT_STATUS));

        return response;
    } catch(err) {
        dispatch(actionCreator.failure(CHANGE_ACCOUNT_STATUS, getErrorMessage(err)));

        return  err;
    }
}