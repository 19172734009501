import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
    getAccountDocuments,
    deleteAccountDocument,
} from '../../../../../../actions/accountDetailsActions/documents.actions';
import {toastr} from "react-redux-toastr";
import moment from 'moment';
import axiosInstance from "../../../../../../utils/axios-instance";

export class Document extends Component {

    state = {
        isLoading: false,
        isDeleting: false,
    }

    componentDidMount() {

    }

    componentDidUpdate() {

    }

    handleViewDocument = () => {
        const {accountId, documentId, documentName} = this.props;

        this.setState({isLoading: true});

        axiosInstance
            .get(`/account/${accountId}/documents/${documentId}`, {
                responseType: 'blob' //Force to receive data in a Blob Format
            })
            .then((response) => {

                this.setState({isLoading: false});

                // Create a Blob from the File Stream
                const file = new Blob([ response.data ], { type: response.data.type });

                if (window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(file, documentName);
                } else if (navigator.userAgent.match('FxiOS')) {
                    alert('Cannot display on FF iOS');
                } else if (navigator.userAgent.match('CriOS')) {
                    let reader = new FileReader();
                    reader.onloadend = function() {
                        window.open(reader.result);
                    };
                    reader.readAsDataURL(file);
                } else if (navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPhone/i)) {
                    let url = window.URL.createObjectURL(file);
                    window.location.href = url;
                } else {
                    // Build a URL from the file
                    const fileURL = URL.createObjectURL(file);

                    // Open the URL on new Window
                    let newWindow = window.open(fileURL, '_blank');

                    // Alert the user of blocked popup
                    if (!newWindow || newWindow.closed || typeof newWindow.closed == 'undefined') {

                        let browser = 'Your web browser';

                        if (navigator.userAgent.toLowerCase().indexOf('chrome/') > -1) browser = 'Chrome';
                        else if (navigator.userAgent.toLowerCase().indexOf('safari/') > -1) browser = 'Safari';
                        else if (navigator.userAgent.toLowerCase().indexOf('firefox/') > -1) browser = 'Firefox';

                        toastr.error(browser + ' blocked the file window from opening. Please allow pop ups and try again.', {timeOut: 0, position: 'top-center'});
                    }
                }
            })
            .catch((error, response) => {
                this.setState({isLoading: false});

                if (error.response && error.response.data) {
                    error.response.data.text().then(response => {
                        const resp = JSON.parse(response);
                        let errorMessage = 'Something went wrong';
                        if (resp.error.message) errorMessage = resp.error.message;
                        toastr.error(errorMessage, { timeOut: 2000, position: 'top-center' });
                    });
                }

            });
    }

    handleDeleteDocument = () => {
        const {accountId, documentId, documentName} = this.props;

        toastr.confirm("Are you sure you want to delete "+documentName+"?", {
            cancelText: "Cancel",
            okText: "Confirm",
            onOk: () => {
                this.setState({isDeleting: true});

                this.props.deleteAccountDocument(accountId, documentId).then(response => {
                    this.setState({isDeleting: false});

                    if (response.success === true) {
                        this.props.getAccountDocuments(accountId);
                        toastr.success(documentName+' deleted successfully', { timeOut: 2000, position: 'top-center' });
                    } else {
                        let errorMessage = 'Something went wrong';
                        if (response && response.error && response.error.message) errorMessage = response.error.message;
                        toastr.error(errorMessage, { timeOut: 2000, position: 'top-center' });
                    }
                });
            },
            onCancel: () => {
            }
        });
    }

    render() {

        const {
            isLoading,
            isDeleting,
        } = this.state;

        const {
            accountId,
            documentId,
            documentName,
            documentDate,
            documentDescription,
        } = this.props;

        return (
            <div className="form-group">
                <div className="form-row">
                    <div className="col">
                        <p className="documents-panel-name">{documentDescription || "No description available"}</p>
                    </div>
                    <div className="col-auto">
                        <button type="button" className="btn btn-outline-primary"
                                disabled={isLoading}
                                onClick={() => this.handleViewDocument()}
                        >
                            {isLoading
                                ? <i className="fas fa-fw fa-spin fa-spinner" />
                                : <i className="fas fa-fw fa-eye" />
                            }
                        </button>
                        <button type="button" className="btn btn-outline-danger"
                                disabled={isDeleting}
                                onClick={() => this.handleDeleteDocument()}
                        >
                            {isDeleting
                                ? <i className="fas fa-fw fa-spin fa-spinner" />
                                : <i className="fas fa-fw fa-trash-alt" />
                            }
                        </button>
                    </div>
                </div>
                <div className="form-row">
                    <div className="col">
                        <p className="documents-panel-details">
                            <span><i className="fas fa-calendar-alt" />{moment(documentDate).format("MM/DD/YYYY")}</span>
                            {documentName &&
                            <span><i className="fas fa-info-circle"/>{documentName}</span>
                            }
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = {
    getAccountDocuments,
    deleteAccountDocument,
};

export default connect(mapStateToProps, mapDispatchToProps)(Document);
