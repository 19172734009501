import React, {Component} from 'react';
import moment from "moment";
import {connect} from "react-redux";
import DatePicker from "react-datepicker";

export class ClearDate extends Component {

    render() {
        const {
            field,
            errors, touched, values, setFieldValue
        } = this.props;
        
        return (
                <div className="form-group" >
                    <label>{field.name}</label> 
                    <div className="customDatePickerWidth">
                        <DatePicker
                            className={"form-control text-left" + (touched.clear_date && errors.clear_date ? " is-invalid" : "")}
                            fieldName="clear_date"
                            dateFormat="MM/dd/yyyy"
                            placeholderText="Enter Task Date"
                            autoFocus={false}
                            showTimeSelect={false}
                            shouldCloseOnSelect={true}
                            popperPlacement={'top'}
                            selected={values.clear_date !== '' ? values.clear_date : null}
                            onChange={(clear_date) => setFieldValue('clear_date', clear_date)}
                            minDate={moment().toDate()}
                        />
                    </div>
                    {errors.clear_date && touched.clear_date &&
                    <div className="invalid-feedback">{errors.clear_date}</div>
                    }
                </div>
                );
            }
        }

const mapStateToProps = (state) => {

    return {
        
    };
};

const mapDispatchToProps = {
    
};

export default connect(mapStateToProps, mapDispatchToProps)(ClearDate);