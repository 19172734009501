import React from 'react';
import { parseDateTimeWithFormat } from "../../utils/dateTimeParseUtils";

const TargetDateTime = ({targetDateTime}) => {
	return (targetDateTime?
			<span className="text-muted ">
				<i className="far fa-calendar-check" />
				&nbsp;
				{parseDateTimeWithFormat(targetDateTime)}
			</span>
			: null);
};

export default TargetDateTime;
