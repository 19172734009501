import React, {Component} from 'react';
import {connect} from 'react-redux';
import {hideModal, showModal,} from '../../actions/modal.actions';
import {
	clearWorkflowEvents,
	clearWorkflowList,
	clearWorkflowOrders,
	clearWorkflowStatuses,
	clearWorkflowTransitions,
	getWorkflowEvents,
	getWorkflowList,
	getWorkflowOrders,
	getWorkflowStatuses,
	getWorkflowTransitions,
} from '../../actions/workflow.actions'
import {createLoadingSelector, getWorkflows,} from "../../selectors";
import Loader from "../../components/Loader";
import isEmpty from "../../utils/helpers";
import Workflow from "./Workflow";

class WorkflowDefinitions extends Component {

	state = {
		isLoaded: false,
		resultsNumber: "",
		searchTerm: '',
		workflows: null,
		workflowsSorted: null,
		workflowsFiltered: null,
	}

	componentDidMount() {

		// Change navbar type and color
		document.title = 'Workflows - camvio.cloud';
		this.props.changeNavBarType('default', 'Workflows');

		// Load workflows
		this.props.getWorkflowOrders();
		this.props.getWorkflowEvents();
		this.props.getWorkflowTransitions();
		this.props.getWorkflowStatuses();
		this.props.getWorkflowList().then(response => {

			// Create object with keys to easily pull workflow info
			this.sortWorkflows(response.processDefinitions)
		});
	}

	componentDidUpdate(prevState) {

		if (
			!this.state.isLoaded &&
			!this.props.workflowOrdersLoading &&
			!this.props.workflowEventsLoading &&
			!this.props.workflowTransitionsLoading &&
			!this.props.workflowListLoading &&
			!this.props.workflowStatusesLoading
			&& !isEmpty(this.state.workflowsSorted)
		) {
			this.setState({isLoaded: true});

			// Combine workflows to allow display count limit and search
			this.combineWorkflows();
		}
	}

	componentWillUnmount() {
		this.props.clearWorkflowOrders();
		this.props.clearWorkflowEvents();
		this.props.clearWorkflowTransitions();
		this.props.clearWorkflowList();
		this.props.clearWorkflowStatuses();
	}

	sortWorkflows = (workflows) => {

		let workflowsSorted = {};

		// Create object with keys to easily pull workflow info
		workflows.map(workflow => {
			if (!workflowsSorted.hasOwnProperty(workflow.key)) {
				workflowsSorted[workflow.key] = workflow;
			}
		});

		this.setState({workflowsSorted: workflowsSorted})
	}

	combineWorkflows = () => {

		const {
			workflowsSorted,
		} = this.state;

		const {
			workflowOrders,
			workflowEvents,
			workflowTransitions,
			workflowStatuses,
		} = this.props;

		if (!isEmpty(workflowsSorted)) {

			let workflows = [];

			if (!isEmpty(workflowOrders)) {
				workflowOrders.map((workflow) =>
					workflows.push({
						key: workflow.id,
						workflow: workflow,
						workflowDetails: workflowsSorted[workflow.workflowName],
						workflowStatuses: workflowStatuses,
						workflowType: "Service Order",
						description: workflowsSorted[workflow.workflowName] && workflowsSorted[workflow.workflowName].name,
					})
				);
			}

			if (!isEmpty(workflowEvents)) {
				workflowEvents.map((workflow) =>
					workflows.push({
						key: workflow.id,
						workflow: workflow,
						workflowDetails: workflowsSorted[workflow.workflowName],
						workflowStatuses: workflowStatuses,
						workflowType: (workflow.className && workflow.className.substr(workflow.className.lastIndexOf(".") + 1)) + " " + workflow.type,
						description: workflowsSorted[workflow.workflowName] && workflowsSorted[workflow.workflowName].description,
					})
				);
			}

			if (!isEmpty(workflowTransitions)) {
				workflowTransitions.map((workflow) =>
					workflows.push({
						key: workflow.id,
						workflow: workflow,
						workflowDetails: workflowsSorted[workflow.workflowName],
						workflowStatuses: workflowStatuses,
						workflowType: (workflow.className && workflow.className.substr(workflow.className.lastIndexOf(".") + 1)) + " " + workflow.name,
						description: workflowsSorted[workflow.workflowName] && workflowsSorted[workflow.workflowName].description,
					})
				);
			}

			// Store workflows
			this.setState({workflows: workflows}, () => {

				//	Get initial filtered workflows
				this.filterWorkflows();
			});
		}
	}

	filterWorkflows = () => {

		const {
			searchTerm,
			resultsNumber,
			workflows,
		} = this.state;

		if (!isEmpty(workflows)) {

			let workflowsFiltered = workflows;

			// Filter by current search term
			if (!isEmpty(searchTerm)) {
				workflowsFiltered = workflowsFiltered.filter(workflow =>
					workflow.description && workflow.description.toUpperCase().includes(searchTerm.trim().toUpperCase())
				);
			}

			// Get appropriate number of results
			if (!isEmpty(resultsNumber)) {
				workflowsFiltered = workflowsFiltered.slice(0, resultsNumber)
			}

			// Display filtered areas
			this.setState({
				workflowsFiltered: workflowsFiltered
			});
		}
	}

	handleResultNumberChange = (e) => {
		let {value} = e.target;

		this.setState({
			resultsNumber: value
		}, () => {
			this.filterWorkflows();
		});
	}

	handleSearchChange = (event) => {
		let updatedSearchTerm = event.target.value;

		if (this.state.searchTerm !== updatedSearchTerm) {

			this.setState({
				searchTerm: updatedSearchTerm
			}, () => {

				if (this.searchChangeTimeout) {
					clearTimeout(this.searchChangeTimeout);
				}

				this.searchChangeTimeout = setTimeout(() => {
					this.filterWorkflows();
				}, 300);
			});
		}
	}

	render() {

		const {
			isLoaded,
			resultsNumber,
			searchTerm,
			workflows,
			workflowsFiltered,
		} = this.state;

		return (
			<div className="cmv-container cmv-container-dashboard cmv-container-client-selected">
				<div className="cmv-container-table">

					<div className="row">
						<div className="col-sm-5">

							<div className="controls">
								<label>Show</label>
								<select
									className="custom-select"
									value={resultsNumber}
									onChange={this.handleResultNumberChange}
									disabled={!isLoaded}
								>
									<option label="20" value="20">20</option>
									<option label="50" value="50">50</option>
									<option label="100" value="100">100</option>
									<option label="All" value="">All</option>
								</select>
								<label>entries</label>
							</div>

						</div>
						<div className="col-sm-7">

							<div className="search">

								<label htmlFor="workflowSearch" className="col-form-label">Search</label>

								<input
									type="text"
									className="form-control"
									id="workflowSearch"
									value={searchTerm}
									onChange={this.handleSearchChange}
									disabled={!isLoaded}
								/>

							</div>
						</div>
					</div>

					{!isEmpty(workflowsFiltered) && workflowsFiltered.map((workflow) =>
					<Workflow
						key={workflow.key}
						workflow={workflow.workflow}
						workflowDetails={workflow.workflowDetails}
						workflowStatuses={workflow.workflowStatuses}
						workflowType={workflow.workflowType}
						description={workflow.description}
					/>
					)}

					{!isLoaded &&
					<Loader />
					}

					{isLoaded && isEmpty(workflows) &&
					<p>No data found</p>
					}

				</div>
			</div>
		);
	}
}

const loaderOrders = createLoadingSelector(['GET_WORKFLOW_ORDERS']);
const loaderEvents = createLoadingSelector(['GET_WORKFLOW_EVENTS']);
const loaderTransitions = createLoadingSelector(['GET_WORKFLOW_TRANSITIONS']);
const loaderList = createLoadingSelector(['GET_WORKFLOW_LIST']);
const loaderStatuses = createLoadingSelector(['GET_WORKFLOW_STATUSES']);

const mapStateToProps = (state) => {

	const workflowOrdersLoading = loaderOrders(state);
	const workflowEventsLoading = loaderEvents(state);
	const workflowTransitionsLoading = loaderTransitions(state);
	const workflowListLoading = loaderList(state);
	const workflowStatusesLoading = loaderStatuses(state);

	const workflowOrders = getWorkflows(state).workflowOrders;
	const workflowEvents = getWorkflows(state).workflowEvents;
	const workflowTransitions = getWorkflows(state).workflowTransitions;
	const workflowList = getWorkflows(state).workflowList;
	const workflowStatuses = getWorkflows(state).workflowStatuses;

	return {
		workflowOrdersLoading,
		workflowEventsLoading,
		workflowTransitionsLoading,
		workflowListLoading,
		workflowStatusesLoading,
		workflowOrders,
		workflowEvents,
		workflowTransitions,
		workflowList,
		workflowStatuses,
	};
};

const mapDispatchToProps = {
	showModal,
	hideModal,
	getWorkflowOrders,
	clearWorkflowOrders,
	getWorkflowEvents,
	clearWorkflowEvents,
	getWorkflowTransitions,
	clearWorkflowTransitions,
	getWorkflowList,
	clearWorkflowList,
	getWorkflowStatuses,
	clearWorkflowStatuses,
};

export default connect(mapStateToProps, mapDispatchToProps)(WorkflowDefinitions);
