import React, { Component } from 'react';
import FormSelect from '../../../../components/UI/FormSelect';
import DynamicForm from './DynamicForm';

class AccountHolder extends Component {
	render() {
		const {
			values,
			errors,
			touched,
			setFieldTouched,
			setFieldValue,
			accountTypeChangeHandler,
			handleAccountTermDetails,
			setTouched,
			accountTypesOptions,
			accountTermsOptions,
			typeAccountLoader,
			resetForm,
		} = this.props;

		return (
			<div className="tab-pane show active">
				<h4>
					<i className="fas fa-fw fa-book" />&nbsp; Account
				</h4>
				<div className="form-row">
					<div className="form-group col-md-6">
						<FormSelect
							title="Account Type"
							fieldName="accountHolder.accountType"
							value={values.accountHolder.accountType}
							onChange={(fieldName, value) =>
								accountTypeChangeHandler(fieldName, value, setFieldValue, setTouched, resetForm)}
							onBlur={setFieldTouched}
							errors={errors}
							touched={touched}
							isMulti={false}
							options={accountTypesOptions}
						/>
					</div>
					<div className="form-group col-md-6">
						<FormSelect
							title="Account Terms"
							fieldName="accountTermsInfo.accountTerm"
							value={values.accountTermsInfo.accountTerm}
							onChange={(fieldName, value) =>
								handleAccountTermDetails(
									value,
									accountTermsOptions.find((x) => x.value === value).manualTierSelector,
									setFieldValue,
									setFieldTouched
								)}
							onBlur={setFieldTouched}
							errors={errors}
							touched={touched}
							isMulti={false}
							options={accountTermsOptions}
							isDisabled={typeAccountLoader}
						/>
					</div>
				</div>

				{values.accountTermsInfo && values.accountTermsInfo.accountTerm && <DynamicForm {...this.props} />}
			</div>
		);
	}
}

export default AccountHolder;
