import React, {Component, Fragment} from 'react';
import {Search} from 'react-bootstrap-table2-toolkit';
import {connect} from 'react-redux';
import {showModal} from '../../../actions/modal.actions';
import Loader from '../../../components/Loader';
import {isEmpty} from '../../../utils/helpers';
import { getProductFeaturesUi } from "../../../actions/uiController.actions";
import { downloadProductFeatures } from '../../../actions/uiController.actions';
import { getServiceModels } from "../../../actions/domain.actions";
import {
    createLoadingSelector,
    getDomain,
    getProduct,
    getProducts,
    getUiController,
    getUser,
    getUserPermission
} from "../../../selectors";
import { getFeature, updateFeatureNote, getFeatureTypes } from "../../../actions/products.action";
import CamvioTable from "../../../components/UI/CamvioTable/CamvioTable";
import {debounce} from "lodash";
import Button from "../../../components/Common/Buttons/Button";
import {toastr} from "react-redux-toastr";
import Select from "react-select";
import {groupStyles} from "../../../utils/SelectStyles";

class Features extends Component {

    state = {
        loadingSearch: true,
        isLoading: false,
        isDownloading: false,
        searchTerm: '',
        oldSearchTerm: '',
        showDownloadDropdown: false,
        tableRequest: {
            filterList: [],
            pagination: {
                limit: 20,
                offset: 0
            },
            sorting: {
                columnName: "f.id",
                sortParam: "asc"
            }
        },
        resultsNumber: 20,
        productFeaturesList: [],
        sortColumn: '',
        sortDirection: '',
        noMoreResults: true,
        showAdditionalSearchOptions: false,
        serviceModelFilter: [{name: "f.service_model_id", value: "All", label: "All Services"}],
        featureTypesFilter: [{name: "f.feature_type_id", value: "All", label: "All Feature Types"}],
        featureTypeClassesFilter: [{name: "ft.class", value: "All", label: "All Feature Classes"}],
        // planCategoriesFilter: [{name: "p.category_id", value: "All", label: "All Categories"
        // }],
        // planStatusFilter: [{name: "p.is_active", value: "true", label: "Active"}],
    };

    componentDidMount() {
        this.props.getFeatureTypes();
        this.props.getServiceModels();
        this.reloadFeatures();
    }

    componentWillUnmount() {
        //  this.props.clearPlans();
    }

    searchProductFeatures = () => {
        let tableRequest = this.state.tableRequest;
        let pagination = tableRequest.pagination;
        pagination.offset = 0;

        this.setState({
            tableRequest: tableRequest,
            loadingSearch: true
        });
        this.reloadFeatures(true);
    }

    reloadFeatures = (clearList) => {
        if (clearList) {
            let pagination = this.state.tableRequest.pagination;
            pagination.limit += pagination.offset;
            pagination.offset = 0;
            this.setState({
                productFeaturesList: []
            })
        }
        this.setState({isLoading: true, loadingSearch: true}, () => {
            this.props.getProductFeaturesUi(this.state.tableRequest).then(response => {
                if (response.rows && !isEmpty(response.rows)) {
                    response.rows.map(features => {
                        this.state.productFeaturesList.push(features);
                    })
                    this.checkResultsLength();
                }
                this.setState({isLoading: false, loadingSearch: false});
            });
            console.log(this.state.productFeaturesList);
        });
    }

    toggleDownloadDropdown = () => {
        this.setState({showDownloadDropdown: !this.state.showDownloadDropdown});
    }

    sortFeatures = (name, order) => {
        let tableRequest = this.state.tableRequest;
        let sorting = tableRequest.sorting;
        let sortDirection = order;
        if (this.state.sortDirection != order) {
            sorting.columnName = name;
            sorting.sortParam = order;
        }else{
            sorting.columnName = name;
            if(sorting.sortParam == "asc"){
                sorting.sortParam = "desc";
                sortDirection = "desc"
            }else{
                sorting.sortParam = "asc";
                sortDirection = "asc"
            }
        }

        //Reset pagination
        let pagination = tableRequest.pagination;
        pagination.limit += pagination.offset;
        pagination.offset = 0;

        this.setState({
            tableRequest: tableRequest,
            loadingSearch: true,
            productFeaturesList: [],
            sortColumn: name,
            sortDirection: sortDirection
        });
        this.reloadFeatures(true);
    }

    showAdditional = () => {
        let tableRequest = this.state.tableRequest;
        let pagination = tableRequest.pagination;
        pagination.offset = pagination.limit + pagination.offset;
        pagination.limit = parseInt(this.state.resultsNumber);

        this.setState({tableRequest: tableRequest});
        this.reloadFeatures(false);
    }

    checkResultsLength = () => {
        if (this.state.productFeaturesList && this.state.productFeaturesList.length < this.props.productFeaturesSize) {
            this.setState({
                noMoreResults: false
            })
        } else {
            this.setState({
                noMoreResults: true
            })
        }
    }

    handleResultNumberChange = (e) => {
        let {value} = e.target;
        this.setState({
            resultsNumber: value,
        });
    };

    setServiceModelSearch = (value) => {
        this.setState({
            serviceModelFilter: [{
                name: "f.service_model_id",
                value: value.value,
                label: value.label
            }]
        })
        this.debounceHandleInputChange(value);
    };

    setServiceModels = () => {
        let serviceModels = [{
            name: "f.service_model_id",
            value: "All",
            label: "All Services"
        }]

        this.props.serviceModels && this.props.serviceModels.map((serviceModel) => {
            serviceModels.push({
                name: "f.service_model_id",
                value: serviceModel.id,
                label: serviceModel.description
            });
        });
        return serviceModels;
    }

    setFeatureTypeSearch = (value) => {
        this.setState({
            featureTypesFilter: [{
                name: "f.feature_type_id",
                value: value.value,
                label: value.label
            }]
        })
        this.debounceHandleInputChange(value);
    };

    setFeatureTypes = () => {
        let featureTypes = [{
            name: "f.feature_type_id",
            value: "All",
            label: "All Feature Types"
        }]

        this.props.featureTypes && this.props.featureTypes.map((featureType) => {
            featureTypes.push({
                name: "f.feature_type_id",
                value: featureType.id,
                label: featureType.description
            });
        });
        return featureTypes;
    }

    setFeatureTypeClassSearch = (value) => {
        this.setState({
            featureTypeClassesFilter: [{
                name: "ft.class",
                value: value.value,
                label: value.label
            }]
        })
        this.debounceHandleInputChange(value);
    };

    setFeatureTypeClasses = () => {
        let featureTypeClasses = [{
            name: "ft.class",
            value: "All",
            label: "All Feature Classes"
        }]
        let featureTypeClassesOptions = ["METERED", "ONETIME", "RECURRING", "OPERATIONAL"]

        featureTypeClassesOptions.map((featureTypeClass) => {
            featureTypeClasses.push({
                name: "ft.class",
                value: "'"+featureTypeClass+"'",
                label: featureTypeClass
            });
        });
        return featureTypeClasses;
    }

    debounceHandleInputChange = event => {
        let name = event.name || event.target.name;
        let value = event.value || event.target.value;
        this.handleInputChange(name, value);
    }

    handleInputChange = debounce((fieldName, value) => {
        let tableRequest = this.state.tableRequest;
        let filterList = tableRequest.filterList;
        let containsFilter = true;

        if (filterList.length == 0) {
            if (isNaN(value)) {
                if (value.replace(/\s/g, '').length != 0) {
                    if (value != "All") {
                        filterList.push({
                            columnName: fieldName,
                            value: value
                        })
                    }
                }
            } else {
                filterList.push({
                    columnName: fieldName,
                    value: value
                })
            }
        } else {
            for (let index = 0; index < filterList.length; index++) {
                if (filterList[index].columnName == fieldName) {
                    containsFilter = true;
                    if (value.length == 0 || value == "All") {
                        filterList.splice(index, 1);
                    } else {
                        filterList[index].value = value;
                    }
                    break;
                } else {
                    containsFilter = false;
                }
            }
        }
        if (!containsFilter) {
            filterList.push({
                columnName: fieldName,
                value: value
            })
        }
        tableRequest.filterList = filterList;

        this.setState({tableRequest: tableRequest});

    });

    featureNote = (isSubmit, value) => {
        if (isSubmit) {
            this.props.updateFeatureNote(value).then((response) => {
                if (response && response.data.success) {
                    toastr.success(response.data.message,
                        {timeOut: 2000, position: 'top-center'}
                    );
                } else {
                    toastr.error("Failed to update plan note",
                        {timeOut: 2000, position: 'top-center'});
                }
            });
        } else {
            return Promise.resolve(this.props.getFeature(value));
        }
    }


    downloadTable = (value) => {
        let tableRequest = this.state.tableRequest;
        tableRequest.pagination = {};

        this.setState({
            tableRequest: tableRequest,
            isDownloading: true
        });

        this.props.downloadProductFeatures(value, this.state.tableRequest).then((response) => {
            if (response && response.status === 201 && response.data) {
                let name = "";
                let link = document.createElement('a');
                let fileUrl = URL.createObjectURL(new Blob([response.data]));
                link.setAttribute('href', fileUrl);
                if (response.headers["content-disposition"] != undefined) {
                    name = response.headers["content-disposition"].split("=")[1];
                } else {
                    let today = new Date()
                    name = "product_features" + today.toISOString().split('T')[0] + "." + value;
                }
                link.setAttribute('download', name);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                this.setState({
                    isDownloading: false,
                    showDownloadDropdown: false
                });
            }
        });
    };

    toggleFilter = () => {
        this.setState({showAdditionalSearchOptions: !this.state.showAdditionalSearchOptions});
    }


    render() {

        const {
            resultsNumber,
            searchTerm,
            showDownloadDropdown,
            productFeaturesList,
            isLoading,
            sortColumn,
            sortDirection,
            isDownloading,
            showAdditionalSearchOptions
        } = this.state;

        const {
            productFeaturesHeaders,
            canAddNewFeature
        } = this.props;


        return (
            <Fragment>
                <div className="tab-pane camvio-table-search" style={{borderRadius: '0.5rem'}}>
                    <div className="cmv-container-dashboard-search">
                        <div className="cmv-container-dashboard-filter">
                            <div className="container">
                                <div className="form-row">
                                    <div className="col-md-12">
                                        <div className="input-group">
                                            <input
                                                placeholder={"Name or Description..."}
                                                type="text"
                                                className="form-control"
                                                autoComplete="off"
                                                id="name"
                                                name="f.name"
                                                onChange={this.debounceHandleInputChange}
                                                onKeyPress={(event) => {
                                                    if (event.key === 'Enter') {
                                                        this.searchProductFeatures()
                                                    }
                                                }}
                                            />
                                            <div className="input-group-append">
                                                <Button
                                                    type="button"
                                                    className="btn btn-primary btn-show-cam-search-results"
                                                    disabled={this.state.loadingSearch || isDownloading}
                                                    onClick={() => {
                                                        this.searchProductFeatures();
                                                    }}
                                                >
                                                    <i className="fas fa-search" />
                                                    <span>&nbsp;Search</span>
                                                </Button>
                                            </div>

                                            <div className="input-group-append">
                                                <div className="btn-group">
                                                    <Button
                                                        type="button"
                                                        onClick={this.toggleFilter}
                                                        className="btn btn-outline-secondary"
                                                    >
                                                        {showAdditionalSearchOptions ?
                                                            <i className="fas fa-solid fa-ban"/>
                                                            :
                                                            <i className="fas fa-solid fa-filter"/>
                                                        }
                                                        <span>&nbsp;Filters</span>
                                                    </Button>

                                                    {canAddNewFeature &&
                                                    <Button
                                                        type="button"
                                                        className="btn btn-outline-secondary btn-new-account-wizard"
                                                        onClick={() => this.props.showModal('ADD_FEATURE_MODAL', {
                                                            type: 'create',
                                                            reloadFunction: this.reloadFeatures
                                                        })}
                                                        disabled={this.state.loadingSearch || isDownloading}
                                                    >
                                                        <i className="fas fa-plus"/>
                                                        <span>&nbsp;New Feature</span>
                                                    </Button>
                                                    }
                                                    <div className="dropdown">
                                                        <button
                                                            type="button"
                                                            className="btn btn-outline-secondary dropdown-toggle"
                                                            onClick={this.toggleDownloadDropdown}
                                                            style={{borderLeft: "none"}}
                                                            disabled={this.state.loadingSearch || isDownloading}
                                                        >
                                                            <i className="fas fa-download"/>
                                                            <span className="camvio-table-search-download">&nbsp;Download</span>
                                                        </button>
                                                        <div
                                                            className={"dropdown-menu dropdown-menu-right" + (showDownloadDropdown && !isDownloading ? " show" : "")}>
                                                            <button
                                                                type="button"
                                                                value={"XLS"}
                                                                className="dropdown-item"
                                                                onClick={(e) => {
                                                                    this.downloadTable("XLS")
                                                                }}
                                                            >
                                                                As XLS
                                                            </button>
                                                            <button
                                                                type="button"
                                                                className="dropdown-item"
                                                                onClick={(e) => {
                                                                    this.downloadTable("CSV")
                                                                }}
                                                            >
                                                                As CSV
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {showAdditionalSearchOptions &&
                        <div className="cmv-container-dashboard-filter">
                            <div className="container">
                                <div className="camvioTable-plan-extra-filters">
                                    <div className="search">
                                        <Select
                                            className="extra-filter-select"
                                            id="serviceModel"
                                            name="f.service_model_id"
                                            onChange={this.setServiceModelSearch}
                                            styles={groupStyles}
                                            options={this.setServiceModels()}
                                            value={this.state.serviceModelFilter}
                                        />
                                        &nbsp;
                                        <Select
                                            className="extra-filter-select"
                                            id="featureTypeClass"
                                            name="ft.class"
                                            onChange={this.setFeatureTypeClassSearch}
                                            styles={groupStyles}
                                            options={this.setFeatureTypeClasses()}
                                            value={this.state.featureTypeClassesFilter}
                                        />
                                        &nbsp;
                                        <Select
                                            className="extra-filter-select"
                                            id="featureType"
                                            name="f.feature_type_id"
                                            onChange={this.setFeatureTypeSearch}
                                            styles={groupStyles}
                                            options={this.setFeatureTypes()}
                                            value={this.state.featureTypesFilter}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        }
                    </div>
                    {(this.state.loadingSearch || isDownloading) &&
                    <Loader/>
                    }

                    {!this.state.loadingSearch && isEmpty(productFeaturesList) &&
                    <div className="container">
                        <p>No data found</p>
                    </div>
                    }
                    {!isEmpty(productFeaturesList) && !this.state.loadingSearch && !isDownloading &&
                    <CamvioTable
                        headers={productFeaturesHeaders}
                        rows={productFeaturesList}
                        loader={isLoading}
                        reloadFunction={this.reloadFeatures}
                        sortFunction={this.sortFeatures}
                        showModal={this.props.showModal}
                        noteFunction={this.featureNote}
                        sortColumn={sortColumn}
                        sortDirection={sortDirection}
                    />
                    }
                </div>
                {!this.state.noMoreResults &&
                <div className="row" style={{marginTop: '2%'}}>
                    <div className="col-sm-12">
                        <div className="search" style={{float: "right"}}>
                            <button
                                className="btn btn-primary"
                                disabled={this.state.loadingSearch || this.state.noMoreResults || isDownloading}
                                onClick={this.showAdditional}
                            >
                                Show Additional
                            </button>
                            &nbsp;
                            &nbsp;
                            <select
                                className="custom-select"
                                value={resultsNumber}
                                onChange={this.handleResultNumberChange}
                                disabled={this.state.loadingSearch || this.state.noMoreResults || isDownloading}
                            >
                                <option label="20" value="20"/>
                                <option label="30" value="30"/>
                                <option label="40" value="40"/>
                                <option label="50" value="50"/>
                            </select>
                        </div>
                    </div>
                </div>
                }
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    const productFeaturesHeaders = getUiController(state).featuresUi.headers,
        productFeaturesRow = getUiController(state).featuresUi.rows,
        productFeaturesSize = getUiController(state).featuresUi.size,
        featureTypes = getProducts(state).featureTypes,
        serviceModels = getDomain(state).serviceModels,
        canAddNewFeature = getUserPermission(state, 'ADMN', 'ADMN_FEATURE_ADD');

    // Add new actions to the IF statement in render(), enabling the "ACTIONS" table column for users with rights
    return {
        productFeaturesHeaders,
        productFeaturesRow,
        productFeaturesSize,
        featureTypes,
        canAddNewFeature,
        serviceModels
    };
};

const mapDispatchToProps = {
    getProductFeaturesUi,
    getFeatureTypes,
    getServiceModels,
    downloadProductFeatures,
    showModal,
    getFeature,
    updateFeatureNote
};

export default connect(mapStateToProps, mapDispatchToProps)(Features);
