import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import {getUser, getUserPermission, getViewAs} from '../../../selectors';
import { showModal } from '../../../actions/modal.actions';
import { connect } from 'react-redux';
import {getUserSummary} from "../../../actions/user.actions";
import {Link} from "react-router-dom";

class Header extends Component {
	state = {
		dropdown: '',
		isOpen: false
	};

	onFocuschangeActiveDropDown = (e, name) => {
		this.setState((prevProps) => ({
			dropdown: name,
			isOpen: !prevProps.isOpen
		}));
	};

	onBlurchangeActiveDropDown = (e, name) => {
		this.setState((prevProps) => ({
			dropdown: '',
			isOpen: false
		}));
	};

	changePassword = () => {
		this.props.showModal('PASSWORD_MODAL');
	};

	signOutModal = () => {
		this.props.showModal('SIGNOUT_MODAL');
	};

	changeLocationModal = () => {
		this.props.showModal('LOCATION_MODAL');
	};

	viewAsPartnerModal = () => {
		this.props.showModal('VIEW_AS_PARTNER_MODAL');
	}

	render() {

		const {
			sideBarToggleHandler,
			hideAccountModalHandler,
			showAccountModal,
			userSummary,
			currentLocation,
			navbarTitle,
			newAccountPermission,
			canViewAsPartnerPermission,
			viewAsState,
			currentUser
		} = this.props;

		return (
			<header className="cmv-header cmv-header-sidebar">
				<nav className={'navbar navbar-expand cmv-navbar' + (showAccountModal ? ' cmv-navbar-locked' : '')}>

					<Link className={'navbar-logo'} to={{ pathname: '/home' }}>
						<img src="/logo.png" alt="Camvio Logo"/>
					</Link>

					{!showAccountModal ? (
						<Fragment>
							<a onClick={sideBarToggleHandler} className="btn-circle btn-toggle-sidebar">
								<i className="fas fa-bars" />
							</a>
							<span className="navbar-text navbar-title">{navbarTitle}</span>
						</Fragment>
					) : (
						<Fragment>
							<a onClick={hideAccountModalHandler} className="btn-circle btn-close-modal">
								<i className="fas fa-bars fa-angle-left fa-times" />
							</a>
							<span className="navbar-text">New Account</span>
						</Fragment>
					)}

					<ul className="navbar-nav">

						<li className="nav-item dropdown"
							onClick={(e) => this.onFocuschangeActiveDropDown(e, 'first')}
							onBlur={(e) => this.onBlurchangeActiveDropDown(e, 'first')}
						>
							<a className="nav-link dropdown-toggle" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" tabIndex="1">
								<i className="fas fa-plus" />
								<span className="nav-link-caption">
									<span className="nav-link-title">New</span>
								</span>
							</a>
							<div className={'dropdown-menu dropdown-menu-right' + (this.state.dropdown === 'first' && this.state.isOpen ? ' show' : '')} aria-labelledby="navbarDropdownMenuLink">
								<h6 className="dropdown-header">New</h6>
								<div className="dropdown-divider" />
								{newAccountPermission &&
								<button className="dropdown-item btn-new-account-wizard" onMouseDown={() => this.props.history.push('/create-account')}>Account</button>
								}
								<button className="dropdown-item" onMouseDown={() => this.props.history.push('/service-request')}>Service Request</button>
							</div>
						</li>

						{(viewAsState.partner && canViewAsPartnerPermission) &&
						<li className="nav-item"
							onClick={this.viewAsPartnerModal}
						>
							<a className="nav-link dropdown-toggle" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" tabIndex="2">
								<i className="fas fa-map-marker-alt"/>
								<span className="nav-link-caption">
									<span className="nav-link-title">{viewAsState.location && viewAsState.location.name}</span>
									<span className="nav-link-text">{viewAsState.partner && viewAsState.partner.name}</span>
								</span>
							</a>
						</li>
						}

						{!viewAsState.partner &&
						<li className="nav-item dropdown"
							onClick={(e) => this.onFocuschangeActiveDropDown(e, 'second')}
							onBlur={(e) => this.onBlurchangeActiveDropDown(e, 'second')}
						>
							<a className="nav-link dropdown-toggle" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" tabIndex="2">
								<i className="fas fa-map-marker-alt"/>
								<span className="nav-link-caption">
									<span className="nav-link-title">{currentLocation.value}</span>
								</span>
							</a>
							<div className={'dropdown-menu dropdown-menu-right' + (this.state.dropdown === 'second' && this.state.isOpen ? ' show' : '')} aria-labelledby="navbarDropdownMenuLink">
								<h6 className="dropdown-header">{currentLocation.label}</h6>
								<div className="dropdown-divider" />
								<button className="dropdown-item" onMouseDown={this.changeLocationModal}>Change Location</button>
								{canViewAsPartnerPermission &&
								<button className="dropdown-item" onMouseDown={this.viewAsPartnerModal}>View as Partner</button>
								}
							</div>
						</li>
						}

						<li className="nav-item dropdown"
							onClick={(e) => this.onFocuschangeActiveDropDown(e, 'third')}
							onBlur={(e) => this.onBlurchangeActiveDropDown(e, 'third')}
						>
							<a className="nav-link dropdown-toggle" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" tabIndex="3">
								<i className="fas fa-user" />
								<span className="nav-link-caption">
									<span className="nav-link-title">{userSummary.firstName ? userSummary.firstName : ''}{' '}{userSummary.lastName ? userSummary.lastName : ''}</span>
									<span className="nav-link-text">{userSummary.systemUserRole.description ? userSummary.systemUserRole.description : ''}</span>
								</span>
							</a>
							<div className={'dropdown-menu dropdown-menu-right' + (this.state.dropdown === 'third' && this.state.isOpen ? ' show' : '')} aria-labelledby="navbarDropdownMenuLink">
								<h6 className="dropdown-header">
									{userSummary.firstName ? userSummary.firstName : ''}{' '}
									{userSummary.lastName ? userSummary.lastName : ''}{', '}
									{userSummary.systemUserRole.description ? userSummary.systemUserRole.description : ''}
								</h6>
								<div className="dropdown-divider" />
								<button className="dropdown-item" onMouseDown={this.changePassword}>Change Password</button>
								<button className="dropdown-item" onMouseDown={this.signOutModal}>Sign Out</button>
							</div>
						</li>

					</ul>

				</nav>
			</header>
		);
	}
}

Header.propTypes = {
	sideBarToggleHandler: PropTypes.func,
	hideAccountModalHandler: PropTypes.func,
	showAccountModal: PropTypes.bool,
	userSummary: PropTypes.object,
	currentLocation: PropTypes.object
};

const mapStateToProps = (state) => {
	const newAccountPermission = getUserPermission(state, 'CAM', 'CAM_ACC_ADD'),
		canViewAsPartnerPermission = getUserPermission(state, 'WEB_MENU', 'VIEW_AS_PARTNER'),
		viewAsState = getViewAs(state),
		currentUser = getUser(state).user;

	return {
		newAccountPermission,
		canViewAsPartnerPermission,
		viewAsState,
		currentUser
	};
};

const mapDispatchToProps = {
	showModal
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
