import React, {Component} from 'react';
import {connect} from 'react-redux';
import {loadBillingJobs, clearBillingJobs} from "../../actions/billingJobs.actions";
import {createLoadingSelector, getBillingJobs} from "../../selectors";
import Loader from "../../components/Loader";
import isEmpty from "../../utils/helpers";
import {BillingJob} from "./BillingJob";
import { showModal } from '../../actions/modal.actions';

class BillingJobs extends Component {

	componentDidMount() {

		// Change navbar type and color
		document.title = 'Billing Jobs - camvio.cloud';
		this.props.changeNavBarType('default', 'Billing Jobs');

		// Load billing jobs
		this.props.loadBillingJobs();
	}

	componentWillUnmount() {
		this.props.clearBillingJobs();
	}

	render() {

		const {
			billingJobsLoading,
			billingJobs,
		} = this.props;

		return (
			<div className="cmv-container cmv-container-dashboard cmv-container-client-selected">
				<div className="cmv-container-table">

					<div className="row">
						<div className="col-sm-5">

							<div className="controls">
							</div>

						</div>
						<div className="col-sm-7">

							<div className="search">

								<button
									className="btn btn-primary"
									onClick={() => this.props.showModal('ADD_BILLING_JOB_MODAL', {
										onCloseCallback: this.props.loadBillingJobs
									})}
									disabled={billingJobsLoading}
								>
									<i className="fas fa-plus"/> New
								</button>

							</div>

						</div>
					</div>

					{!isEmpty(billingJobs) && billingJobs.map((billingJob) =>
					<BillingJob
						key={billingJob.id}
						billingJob={billingJob}
						showModal={this.props.showModal}
					/>
					)}

					{billingJobsLoading &&
					<Loader />
					}

					{!billingJobsLoading && isEmpty(billingJobs) &&
					<p>No data found</p>
					}

				</div>
			</div>
		);
	}
}

const loaderJobs = createLoadingSelector(['LOAD_BILLING_JOBS']);

const mapStateToProps = (state) => {

	const billingJobsLoading = loaderJobs(state);
	const billingJobs = getBillingJobs(state).billingJobs;

	return {
		billingJobsLoading,
		billingJobs,
	};
};

const mapDispatchToProps = {
	loadBillingJobs,
	clearBillingJobs,
	showModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(BillingJobs);
