import React, {Component, Fragment} from 'react';
import { connect } from 'react-redux';
import {formSelectStyles, groupStyles} from "../../../utils/SelectStyles";
import Select from "react-select";
import _ from "lodash";
import * as Yup from 'yup';
import {getInventoryNumbers} from "../../../actions/accountDetailsActions/newOrder.actions";
import Loader from "../../../components/Loader";
import {
    getDeviceProfileNums, getInterfaces, getSerializedItemNumbers, submitProvisioning
} from "../../../actions/provisioning.actions";
import {Form, Formik} from "formik";
import StepActions from "../../../camvio-wizard/StepActions";
import {createLoadingSelector, getUser} from "../../../selectors";
import {toastr} from "react-redux-toastr";
import {getErrorMessage} from "../../../utils/helpers";
import ReactHtmlParser from 'react-html-parser';

const PROVISIONING_STATUS = {
    IN_PROGRESS: 'IN_PROGRESS',
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL'
}

const createValidationSchema = (props) => Yup.object().shape({
    directoryNumber: Yup.string().nullable().required("Required").min(1, "Required"),
    deviceGroupId: Yup.number().nullable().required("Required").positive("Required"),
})

const initialValues = {
    interfaces: [],
}

const initialState = {
    showDirectoryNumSearch: false,
    selectedNumberCategoryId: -1,
    inventoryNumbers: [],
    selectedInventoryNumber: '',
    profileNumTypesLoadingState: {},
    profileSerializedItemState: {},
}

class Provisioning extends Component {

    constructor(props) {
        super(props);

        this.state = { ...initialState };
    }

    setProfileNumTypesLoading(deviceProfileId, loading) {
        this.setState(prevState => ({
            profileNumTypesLoadingState: {
                ...prevState.profileNumTypesLoadingState,
                [deviceProfileId]: loading,
            }
        }));
    }

    getSerializedItemStateId = (deviceProfileId, numType) => {
        if (!deviceProfileId || deviceProfileId < 1) {
            throw new Error("deviceProfileId must be a positive integer");
        }

        if (_.isEmpty(numType)) {
            throw new Error("numType must not be null");
        }

        return `${deviceProfileId}_${numType}`;
    }

    getSerializedItemState = (state, stateId) => {
        return state.profileSerializedItemState[stateId] || {};
    }

    setProfileSerializedItemNumOptionsLoading(deviceProfileId, numType, loading) {
        const itemStateId = this.getSerializedItemStateId(deviceProfileId, numType);

        this.setState(prevState => {
            const itemState = this.getSerializedItemState(prevState, itemStateId);

            return {
                profileSerializedItemState: {
                    ...prevState.profileSerializedItemState,
                    [itemStateId]: {
                        ...itemState,
                        loadingOptions: loading,
                        options: loading? [] : itemState.options,
                    }
                }
            }
        })
    }

    setProfileSerializedItemNumOptions(deviceProfileId, numType, numOptions) {
        const itemStateId = this.getSerializedItemStateId(deviceProfileId, numType);

        this.setState(prevState => {
            const itemState = this.getSerializedItemState(prevState, itemStateId)

            return {
                profileSerializedItemState: {
                    ...prevState.profileSerializedItemState,
                    [itemStateId]: {
                        ...itemState,
                        loadingOptions: false,
                        options: numOptions,
                    }
                }
            }
        })
    }

    loadNumOptions = (number, deviceProfileId, numType, onSuccess, onFail) => {
        const { currentLocation, getSerializedItemNumbers } = this.props;
        this.setProfileSerializedItemNumOptionsLoading(deviceProfileId, numType, true);

        getSerializedItemNumbers(number, numType, currentLocation.id, 10).then(resp => {
            if (_.get(resp.data, 'success')) {
                const options = resp.data.serializedItemNumbers;
                this.setProfileSerializedItemNumOptions(deviceProfileId, numType, options);
                if (onSuccess) {
                    onSuccess(options);
                }
            }
        }).catch(err => {
            this.setProfileSerializedItemNumOptionsLoading(deviceProfileId, numType, false);
            console.error(err);
        })
    }

    getDeviceGroups = () => {
        const { plan } = this.props;

        return plan && plan.equipment || [];
    }

    showHideDirectoryNumSearch = () => {
        this.setState(prevState => {
            return {
                showDirectoryNumSearch: !prevState.showDirectoryNumSearch
            }
        });
    }

    handleCategoryChange = (option) => {
        const categoryId = option.value;
        const serviceModelId = _.get(this.props.plan, 'serviceModel.id');

        this.setState({
            selectedNumberCategoryId: null,
            inventoryNumbers: [],
            selectedInventoryNumber: '',
        });

        getInventoryNumbers(categoryId, 100, serviceModelId).then((response) => {
            this.setState({
                selectedNumberCategoryId: categoryId,
                inventoryNumbers: response,
                selectedInventoryNumber: Array.isArray(response) && response[0] && response[0].number || '',
            })
        });
    };

    handleInventoryNumberChange = (option) => {
        const number = option.value;

        this.setState({
            selectedInventoryNumber: number,
        })
    };

    handleInventoryNumberSelectCancel = () => {
        this.setState({
            showDirectoryNumSearch: false,
        })
    }

    handleInventoryNumberSelectSubmit = ({setFieldValue}) => {
        setFieldValue('directoryNumber', this.state.selectedInventoryNumber);

        this.setState({
            showDirectoryNumSearch: false,
        })
    }

    renderDirectoryNumber = (formProps) => {
        const { selectedNumberCategoryId, inventoryNumbers, showDirectoryNumSearch, selectedInventoryNumber } = this.state;
        const { values, handleChange, handleBlur, errors, touched } = formProps;
        const { directoryNumber } = values;
        const { plan } = this.props;

        const numberCategoryOptions = [];
        if (plan.numberCategories) {
            plan.numberCategories.forEach(category => numberCategoryOptions.push({
                label: category.description,
                value: category.id
            }));
        }
        const selectedCategoryOption = numberCategoryOptions.find(catOpt => catOpt.value === selectedNumberCategoryId);

        const inventoryNumberOptions = [];
        if (inventoryNumbers) {
            inventoryNumbers.forEach(i => inventoryNumberOptions.push({
                label: i.number,
                value: i.number
            }));
        }
        const selectedInventoryNumberOption = inventoryNumberOptions.find(numOpt => numOpt.value === selectedInventoryNumber) || null;

        return (
            <>
                <div className="row">
                    <div className="form-group col-12 col-sm-3">
                        <label>{plan.serviceModel.directorySystemNumType.description}</label>
                        <div className="input-group input-group-sm">
                            <input
                                type="text"
                                name="directoryNumber"
                                className="form-control"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={directoryNumber}
                                disabled={this.isSubmitting()}
                            />
                            {plan.serviceModel.directorySystemNumType.defaultSource === 'number_inventory' &&
                                <div className="input-group-append">
                                    <button
                                        type="button"
                                        className="btn btn-primary btn-search-line"
                                        onClick={this.showHideDirectoryNumSearch}
                                        disabled={this.isSubmitting()}
                                    >
                                        <i className="fas fa-ellipsis-h"/>
                                        <span className="d-md-none">&nbsp;Number</span>
                                    </button>
                                </div>
                            }
                        </div>
                        {errors.directoryNumber && (
                            <small className="form-text text-danger text-left">
                                {errors.directoryNumber}
                            </small>
                        )}
                    </div>
                </div>

            {showDirectoryNumSearch
                && !this.isSubmitting() &&
                <div className="form-group form-row">
                    <div className="col-12 col-sm-3">
                        <div className="cmv-container-subpanel cmv-container-subpanel-dirnum">
                            <h5>
                                <i className="fas fa-database"/> Select {plan.serviceModel.directorySystemNumType.description}
                            </h5>
                            <div className="form-group">
                                <label>Directory Type</label>
                                <Select
                                    name="numberCategoryId"
                                    value={selectedCategoryOption}
                                    onChange={this.handleCategoryChange}
                                    placeholder="Directory Type"
                                    styles={groupStyles}
                                    options={numberCategoryOptions}
                                />
                            </div>

                            <div className="form-group">
                                <label>Available Numbers</label>
                                <Select
                                    name="inventoryNumber"
                                    value={selectedInventoryNumberOption}
                                    onChange={this.handleInventoryNumberChange}
                                    placeholder="Number"
                                    styles={groupStyles}
                                    options={inventoryNumberOptions}
                                />
                            </div>
                            <div className="d-flex justify-content-end mt-1">
                                <button
                                    onClick={this.handleInventoryNumberSelectCancel}
                                    className="btn btn-outline-primary btn-dn-select-cancel mr-1"
                                    type="button"
                                >
                                    Cancel
                                </button>

                                <button
                                    onClick={() => this.handleInventoryNumberSelectSubmit(formProps)}
                                    className="btn btn-primary btn-dn-select-accept"
                                    type="button"
                                >
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            }
            </>);
    }

    onEquipmentChangeHandler = (option, { setFieldValue, values }) => {
        const { provisioningAction, setStepState } = this.props;
        const { deviceGroupId } = values;
        const selectedDeviceGroupId = option.value;

        if (deviceGroupId === selectedDeviceGroupId) {
            return;
        }

        setStepState({provisioningInterfaces: [], loadingProvisioningInterfaces: false});

        // reset device profiles
        const deviceGroup = this.getDeviceGroups().find(dg => dg.deviceGroupId === selectedDeviceGroupId);

        const deviceProfiles = deviceGroup.deviceProfiles
            .map(dp => ({
                ...dp,
                numbers: {
                    required: null,
                    logical: null,
                }
            }));

        setFieldValue('deviceGroupId', selectedDeviceGroupId);
        setFieldValue(`deviceProfiles`, deviceProfiles);

        this.loadInterfaces(provisioningAction.actionType, deviceProfiles.map(dp => dp.name));

        this.setState({
            profileNumTypesLoadingState: {},
            profileSerializedItemState: {}
        });
    }

    renderEquipmentSelect = (formProps) => {
        const { errors, touched, values } = formProps;
        const { deviceGroupId } = values;

        const deviceGroups = this.getDeviceGroups();
        const options = [];

        if (deviceGroups) {
            deviceGroups.forEach(dg => {
                options.push({
                    label: dg.name + " (" + dg.description + ")",
                    value: dg.deviceGroupId
                });
            });
        }

        const selectedDeviceGroup = options.find(opt => opt.value === deviceGroupId);

        return (
            <div className="row">
                <div id="equipment-select" className="form-group col-12 col-sm-3">
                    <label>Equipment</label>
                    <Select
                        name="deviceGroupId"
                        value={selectedDeviceGroup}
                        onChange={(option) => this.onEquipmentChangeHandler(option, formProps)}
                        options={options}
                        placeholder="Equipment"
                        styles={groupStyles}
                        isDisabled={this.isSubmitting()}
                    />
                    {errors.deviceGroupId &&
                        <div className="invalid-feedback">{errors.deviceGroupId}</div>
                    }
                </div>
            </div>
        );
    }

    getSerializedItemNumSearchChangeHandler = (fieldPath, setFieldValue, deviceProfileId, num) => (value, { action }) => {
        if (action !== 'input-change') {
            return;
        }

        setFieldValue(`${fieldPath}.value`, value);

        const itemStateId = this.getSerializedItemStateId(deviceProfileId, num.name);
        const itemState = this.getSerializedItemState(this.state, itemStateId);

        if (itemState.searchTerm === value) {
            return;
        }

        this.setState(prevState => ({
            profileSerializedItemState: {
                ...prevState.profileSerializedItemState,
                [itemStateId]: {
                    ...itemState,
                    searchTerm: value,
                }
            }
        }), () => {
            const itemTimeoutName = `${itemStateId}_timeout`;
            const itemNumSearchTimeOut = this[itemTimeoutName];
            if (itemNumSearchTimeOut) {
                clearTimeout(itemNumSearchTimeOut);
            }

            this[itemTimeoutName] = setTimeout(() => {
                this.loadNumOptions(value, deviceProfileId, num.name);
            }, 300);
        });
    };

    getOnItemValueChangeHandler = ({values, setFieldValue}, deviceProfileId) => ({value: item}) => {
        const deviceProfiles = values.deviceProfiles.map(deviceProfile => {
            if (deviceProfile.id !== deviceProfileId) {
                return deviceProfile;
            }

            return {
                ...deviceProfile,
                numbers: {
                    ...deviceProfile.numbers,
                    required: deviceProfile.numbers.required.map(num => {
                        if (num.name === item.systemNumType.name) {
                            return {
                                ...num,
                                value: item.number,
                            }
                        }

                        const suggestion = item.suggestions.find(s => s.systemNumType.name === num.name);
                        if (suggestion) {
                            return {
                                ...num,
                                value: suggestion.number,
                            }
                        }

                        return num;
                    })
                }
            }
        })

        setFieldValue('deviceProfiles', deviceProfiles);
    }

    getOnItemFocusHandler = (item, setFieldValue, fieldPath, deviceProfileId) => (option) => {
        if (item.focused) {
            return;
        }

        setFieldValue(`${fieldPath}.focused`, true);
        this.loadNumOptions(item.value, deviceProfileId, item.name);
    }

    renderProfiles = (formProps) => {
        const { deviceProfiles = [] } = formProps.values;

        return deviceProfiles.map(deviceProfile => this.renderProfile(deviceProfile, formProps));
    }

    renderProfile = (deviceProfile, formProps) => {
        const { values, setFieldValue, handleChange, handleBlur, errors, touched } = formProps;
        const { getDeviceProfileNums, provisioningAction } = this.props;
        const { profileNumTypesLoadingState } = this.state;
        const { deviceProfiles } = values;

        const profileNums = deviceProfile.numbers;

        const hasNums = (Array.isArray(profileNums.required) && Array.isArray(profileNums.logical));
        const shouldLoadNums = !hasNums && !profileNumTypesLoadingState[deviceProfile.id];

        const deviceProfileIndex = deviceProfiles.findIndex(dp => dp.id === deviceProfile.id);
        const numbersPath = `deviceProfiles[${deviceProfileIndex}].numbers`;

        if (shouldLoadNums) {
            this.setProfileNumTypesLoading(deviceProfile.id, true);

            getDeviceProfileNums(deviceProfile.name, provisioningAction.actionType).then(nums => {
                if (nums.success === false) {
                    toastr.error(getErrorMessage(nums).message, { timeOut: 3000, position: 'top-center' });
                }

                const requiredNums = (nums.requiredNumTypes || [])
                    .map(numType => {
                        return {
                            ...numType,
                            value: '',
                        };
                    });

                const logicalNums = (nums.logicalNumTypes || [])
                    .map(numType => {
                        return {
                            ...numType,
                            value: '',
                        };
                    });

                setFieldValue(numbersPath, {
                    required: requiredNums,
                    logical: logicalNums,
                });

                this.setProfileNumTypesLoading(deviceProfile.id, false);
            }).catch(error => {
                toastr.error(getErrorMessage(error).message, { timeOut: 3000, position: 'top-center' });
                console.error(error);

                setFieldValue(numbersPath, {
                    required: [],
                    logical: []
                });

                this.setProfileNumTypesLoading(deviceProfile.id, false);
                console.error(error);
            });
        }

        const isLoadingNums = shouldLoadNums || !hasNums || !!profileNumTypesLoadingState[deviceProfile.id];
        const requiredNums = profileNums.required;
        const logicalNums = profileNums.logical;

        return (
            <div className="plan-wrapper">
                <div className="serviceline-wrapper">
                    <div className="serviceline-header">
                        <h4>
                            <span className="badge badge-line-type">{deviceProfile.description}</span>
                        </h4>
                    </div>
                    <div className="serviceline-body">
                        {isLoadingNums && <Loader/> ||
                            <>
                            <div className="plan-section">
                                <h5>Required Numbers</h5>
                                <div className="package-wrapper">
                                    {requiredNums.map((num, index) => {
                                        const itemStateId = this.getSerializedItemStateId(deviceProfile.id, num.name);
                                        const itemState = this.getSerializedItemState(this.state, itemStateId)
                                        const fieldPath = `${numbersPath}.required[${index}]`;
                                        const options = _.get(itemState, 'options', []).map(option => ({
                                            label: option.number,
                                            value: option,
                                        }));

                                        const selectedOption =
                                            options.find(option => option.value === num.value)
                                            || {label: num.value, value: num.value};

                                        return <div className="row" key={num.name}>
                                            <div className="form-group col-12 col-sm-3">
                                                <div className="form-group position-relative">
                                                    <label>{num.name}</label>
                                                    <Select
                                                        options={options}
                                                        onInputChange={this.getSerializedItemNumSearchChangeHandler(fieldPath, setFieldValue, deviceProfile.id, num)}
                                                        onChange={this.getOnItemValueChangeHandler(formProps, deviceProfile.id)}
                                                        value={selectedOption}
                                                        placeholder="Number"
                                                        styles={formSelectStyles}
                                                        isLoading={itemState.loadingOptions}
                                                        onFocus={this.getOnItemFocusHandler(num, setFieldValue, fieldPath, deviceProfile.id)}
                                                        className={_.get(errors, fieldPath) && _.get(touched, fieldPath) && "is-invalid" || ""}
                                                        isDisabled={this.isSubmitting()}
                                                    />
                                                    {_.get(errors, fieldPath) && _.get(touched, fieldPath) && (
                                                        <div className="invalid-feedback">{_.get(errors, fieldPath)}</div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    })}
                                </div>
                            </div>
                            <div className="plan-section">
                                <h5>Logical Numbers</h5>
                                <div className="package-wrapper">
                                    {logicalNums.map((num, index) =>
                                        <div className="row">
                                            <div className="form-group col-12 col-sm-3">
                                                <label>{num.name}</label>
                                                <div className="input-group input-group-sm">
                                                    <input
                                                        type="text"
                                                        name={`${numbersPath}.logical[${index}].value`}
                                                        className="form-control"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={num.value}
                                                        disabled={this.isSubmitting()}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            </>
                        }
                    </div>
                </div>
            </div>
        );
    }

    loadInterfaces = (actionType, deviceProfiles) => {
        const { getInterfaces, setStepState } = this.props;

        setStepState({ loadingProvisioningInterfaces: true });
        getInterfaces(actionType, deviceProfiles).then((resp) => {
            if (resp.data && resp.data.success) {
                setStepState({
                    provisioningInterfaces: resp.data.provisioningInterfaces.map(pi => ({
                        ...pi,
                        selected: false,
                    })),
                    allInterfacesSelected: true,
                    loadingProvisioningInterfaces: false,
                })
            } else {
                setStepState({ loadingProvisioningInterfaces: false });
                toastr.error(getErrorMessage(resp).message, { timeOut: 3000, position: 'top-center' });
            }
        }).catch(error => {
            setStepState({ loadingProvisioningInterfaces: false });
            toastr.error(getErrorMessage(error).message, { timeOut: 3000, position: 'top-center' });
            console.error(error);
        });
    }

    toggleAllInterfaces = () => {
        this.props.setStepState((prevProps) => ({
            allInterfacesSelected: !prevProps.allInterfacesSelected
        }));
    }

    renderInterfaces = (formProps) => {
        const { setStepState } = this.props;
        const { provisioningInterfaces = [], allInterfacesSelected, loadingProvisioningInterfaces } = this.props.stepState;

        if (loadingProvisioningInterfaces) {
            return (
                <>
                    <h4>Provisioning interfaces</h4>
                    <Loader/>
                </>)
        }

        return <>
            {!provisioningInterfaces.length && (
                <span className="form-text text-danger text-left">
                    No provisioning interfaces found
                </span>
            ) ||
            (
                <>
                    <div className="form-check checkbox-slider checkbox-slider--b-flat">
                        <label>
                            <input
                                type="checkbox"
                                name={`allInterfacesSelected`}
                                value={allInterfacesSelected}
                                checked={allInterfacesSelected === true}
                                onChange={this.toggleAllInterfaces}
                                disabled={this.isSubmitting()}
                            />
                            <span>All Provisioning Interfaces</span>
                        </label>
                    </div>
                </>
            )}
            {!allInterfacesSelected && provisioningInterfaces.map((provInterface, index) => {
                return (
                    <div className="form-check checkbox-slider checkbox-slider--b-flat" key={index}>
                        <label>
                            <input
                                type="checkbox"
                                value={provInterface.selected}
                                checked={provInterface.selected}
                                disabled={this.isSubmitting()}
                                onChange={val => setStepState({
                                    provisioningInterfaces: provisioningInterfaces.map(pi => {
                                        if (pi.id === provInterface.id) {
                                            return {
                                                ...pi,
                                                selected: !provInterface.selected,
                                            }
                                        }

                                        return pi;
                                    }),
                                })}
                            />
                            <span>{provInterface.description}</span>
                        </label>
                    </div>
                )
            })}
        </>
    }

    getSelectedFeatures = () => {
        const { plan, provisioningAction } = this.props;

        const features = [];
        if (provisioningAction.actionType !== 'ACTIVATE') {
            return features;
        }

        for (const serviceLine of plan.servicelines) {
            for (const feature of serviceLine.features) {
                if (feature.featurePackageId) {
                    for (const packageFeature of feature.features) {
                        features.push(packageFeature.name);
                    }

                    continue;
                }

                features.push(feature.name);
            }
        }

        return features;
    }

    convertDeviceProfilesToApiDevices = (deviceProfiles) => {
        return deviceProfiles.map(dp => ({
            deviceProfile: dp.name,
            serviceDeviceNums: dp.numbers.required.map(num => ({
                type: num.name,
                number: num.value,
            }))
        }));
    }

    getLogicalNums = (deviceProfiles) => {
        const logicalNums = [];

        // make them optional
        deviceProfiles.forEach(dp =>
            dp.numbers.logical.forEach(num => {
                if (!_.isEmpty(num.value) && num.value !== '') {
                    logicalNums.push({type: num.name, number: num.value});
                }
            })
        );

        return logicalNums;
    }

    getSelectedInterfaces = () => {
        const { provisioningInterfaces = [], allInterfacesSelected } = this.props.stepState;

        return provisioningInterfaces
            .filter(provInterface => allInterfacesSelected || provInterface.selected)
            .map(provInterface => provInterface.name);
    }

    createValuesBundle = ({ provisioningGroupId }) => {
        return {
            provisioningGroupId,
        };
    }

    createFormValuesBundle = (values) => ({
        ...values,
    })

    handleNext = (values) => {
        const { next } = this.props;
        if (!next) {
            return;
        }

        next({
            values: this.createValuesBundle(values),
            formValues: this.createFormValuesBundle(values),
        });
    }

    handlePrevious = (values) => {
        const { previous } = this.props;
        if (!previous) {
            return;
        }

        previous({
            formValues: this.createFormValuesBundle(values),
        });
    }

    handleOnSubmit = (values, actions) => {
        const { provisioningAction, setStepState, submitProvisioning } = this.props;
        const { directoryNumber, deviceProfiles } = values;

        if (this.isSubmitting()) {
            return;
        }

        setStepState({ provisioningStatus: PROVISIONING_STATUS.IN_PROGRESS });

        submitProvisioning(directoryNumber, provisioningAction.value, {
            features: this.getSelectedFeatures(),
            interfaces: this.getSelectedInterfaces(),
            devices: this.convertDeviceProfilesToApiDevices(deviceProfiles),
            logicalNumbers: this.getLogicalNums(deviceProfiles),
        }).then(resp => {
            if (resp.success || resp.data.success) {
                const message = resp.message || resp.data.message || 'Provisioning submitted successfully';
                toastr.success(message, { timeOut: 0, position: 'top-center' });

                setStepState({ provisioningStatus: PROVISIONING_STATUS.SUCCESS });
                this.handleNext({...values, provisioningGroupId: resp.data.provisioningGroupId});
            } else {
                toastr.error(getErrorMessage(resp).message, { timeOut: 0, position: 'top-center' });
                setStepState({ provisioningStatus: PROVISIONING_STATUS.FAIL });
            }
        }).catch(error => {
            toastr.error(getErrorMessage(error).message, { timeOut: 0, position: 'top-center' });
            setStepState({ provisioningStatus: PROVISIONING_STATUS.FAIL });
            console.error(error);
        })
    }

    isSubmitting = () => {
        return this.props.stepState.provisioningStatus === PROVISIONING_STATUS.IN_PROGRESS;
    }

    renderContent = (formProps) => {
        const { plan } = this.props;

        return (
            <>
                <span className="accordion-section-header">
					<i className={plan.serviceModel.icon} />&nbsp;{ReactHtmlParser(plan.description)}
				</span>

                {this.renderEquipmentSelect(formProps)}
                {this.renderDirectoryNumber(formProps)}
                {this.renderProfiles(formProps)}
                {this.renderInterfaces(formProps)}
            </>
        );
    }

    render() {
        const { stepFormValues, previous, next, stepState } = this.props;
        const { provisioningInterfaces = [] } = stepState;

        return (
            <Formik
                validationSchema={createValidationSchema(this.props)}
                onSubmit={this.handleOnSubmit}
                initialValues={_.isEmpty(stepFormValues)? initialValues : stepFormValues}
                render={(formProps) => (
                    <Form onSubmit={formProps.handleSubmit} className="cmv-form" autoComplete="off">

                        {this.renderContent(formProps)}

                        {!this.isSubmitting() &&
                            <StepActions
                                previous={previous && (() => this.handlePrevious(formProps.values))}
                                next={next && provisioningInterfaces.length && formProps.submitForm}
                                nextBtnName="Submit"/>
                        }
                    </Form>
                )}
            />
        );
    }
}

const selectInterfacesLoading = createLoadingSelector(['@provisioning/INTERFACES']);

const mapStateToProps = (state, props) => {
    const plan = _.get(props.accumulatedValues, 'plan'),
        provisioningAction = _.get(props.accumulatedValues, 'provisioningAction'),
        isLoadingInterfaces = selectInterfacesLoading(state),
        currentLocation = getUser(state).currentLocation;

    return {
        plan,
        provisioningAction,
        isLoadingInterfaces,
        currentLocation,
    };
};

const mapDispatchToProps = {
    getDeviceProfileNums,
    getInterfaces,
    getSerializedItemNumbers,
    submitProvisioning,
};

export default connect(mapStateToProps, mapDispatchToProps)(Provisioning);