import React, { Component } from 'react';
import IncPackage from './Options/IncludedPackage';
class IncludedPackages extends Component {
	render() {
		const { planFeature } = this.props;

		return (
			<div className="plan-section">
				<h5>Included Packages</h5>
				{planFeature.map((featurePackage, index) => (
					<div className="package-wrapper" key={index}>
						<div className="row">
							<div className="col col-md-5">
								<h6>{featurePackage.featurePackageOptions[0].description}</h6>
							</div>
							<div className="col col-md-7">
								<IncPackage featurePackage={featurePackage} />
							</div>
						</div>
					</div>
				))}
			</div>
		);
	}
}

export default IncludedPackages;
