import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser';
class Feature extends Component {
	state = {
		isDescriptionToggled: false
	};

	isPriceRendered = () => {
		const { accountInitialStatus, feature } = this.props;
		if (accountInitialStatus === 'NEW_SIGNUP') {
			if (feature.hideSignupPrice) {
				return false;
			}
		} else if (accountInitialStatus === 'NEW_SURVEY') {
			if (feature.hideSurveyPrice) {
				return false;
			}
		}
		return true;
	};

	toggleDescription = (e) => {
		e.stopPropagation();
		this.setState((prevState) => ({
			isDescriptionToggled: !prevState.isDescriptionToggled
		}));
	};

	render() {
		const { isDescriptionToggled } = this.state;
		const { feature } = this.props;

	return (
			<div className="package-option package-option-mandatory">
				<span className="po-title">{feature.description}</span>
				<span className="package-toolbar">
					<span className="po-price">
						{this.isPriceRendered() === true ? feature.featureType === 'ACTIVATION' ? (
							`$${feature.oneTimePrice.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`
						) : (
							`$${feature.recurringMonthlyPrice.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}` +
							'/mo'
						) : null}
					</span>
					<button onClick={this.toggleDescription} type="button" className="btn btn-xs btn-desc-toggler">
						<i className="fas fa-info-circle" />
					</button>
				</span>
				<div
					className={isDescriptionToggled ? 'po-description collapse show' : 'po-description collapse'}
					id="pl001-ifeat002-op001-desc"
				>
					{ReactHtmlParser(feature.note)}
				</div>
			</div>
		);
	}
}

export default Feature;
