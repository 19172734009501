import React, {Component} from "react";
import {connect} from "react-redux";
import {
    getTroubleTicketNotes,
    createTroubleTicketNote,
} from "../../../../../../actions/accountDetailsActions/troubleTickets.actions";
import {parseDateWithFormat} from "../../../../../../utils/dateTimeParseUtils";
import Loader from "../../../../../../components/Loader";
import isEmpty from "../../../../../../utils/helpers";
import {toastr} from "react-redux-toastr";
import {Formik} from "formik";
import * as Yup from "yup";

const TroubleTicketNoteSchema = Yup.object().shape({
    note: Yup.string().required('Required'),
});

class Notes extends Component {

    state = {
        ticketNotes: [],
        isLoading: false,
        isAdding: false
    };

    componentDidMount() {
        this.loadNotes();
    }

    loadNotes = () => {
        this.setState({isLoading: true});

        this.props.getTroubleTicketNotes(this.props.troubleTicketId).then((response) => {
            this.setState({isLoading: false, ticketNotes: response});
        })
    };

    addNoteHandler = (values) => {
        this.setState({ isAdding: true });

        this.props.createTroubleTicketNote(values).then((response) => {
            this.setState({isAdding: false});
            if (response.success) {
                this.loadNotes();
                toastr.success('Note has been added successfully', { timeOut: 1000, position: 'top-center' });
            } else {
                let errorMessage = 'Something went wrong';
                if (response && response.error && response.error.message) errorMessage = response.error.message;
                toastr.error(errorMessage, { timeOut: 1000, position: 'top-center' });
            }
        });
    };

    render() {

        const {
            isLoading,
            isAdding,
            ticketNotes,
        } = this.state;

        const {
            accountId,
            troubleTicketId,
        } = this.props;
        
        return (
            <div className="mini-notes-wrapper">

                {isLoading &&
                <Loader/>
                }

                {!isLoading && isEmpty(ticketNotes) &&
                <p>No notes found.</p>
                }

                {!isLoading && !isEmpty(ticketNotes) &&
                <ul className="mini-notes mini-notes-h-constrained">
                    {ticketNotes.map((note) => (
                        <li className={"mini-note" + (note.createdById === accountId ? " mini-note-current-user" : "")}>
                            <div className="mini-note-body">

                                <p>{note.note}</p>

                                <div className="footer">
                                    <small className="text-muted">
                                        <i className="far fa-clock"/>&nbsp;{parseDateWithFormat(note.createdDateTime)}&nbsp;
                                        <i className="fas fa-user"/>&nbsp;{note.createdByName}
                                    </small>
                                </div>

                            </div>
                        </li>
                    ))}
                </ul>
                }

                <Formik
                    initialValues={{
                        troubleTicketId: troubleTicketId,
                        note: ''
                    }}
                    validationSchema={TroubleTicketNoteSchema}
                    onSubmit={this.addNoteHandler}
                    render={({handleChange, handleSubmit, values, errors, touched}) => (
                        <form onSubmit={handleSubmit}>
                            <div className="form-group mb-0">
                                <div className="input-group input-group-sm">

                                    <input type="text"
                                           id="note"
                                           name="note"
                                           className={'form-control' + (errors.note && touched.note ? ' is-invalid' : '')}
                                           disabled={isAdding}
                                           value={values.note}
                                           onChange={handleChange}
                                           autoComplete={'off'}
                                    />

                                    <div className="input-group-append">
                                        <button className="btn btn-outline-secondary"
                                                disabled={isAdding}
                                                type="submit"
                                        >
                                            {isAdding && <i className="fas fa-fw fa-spin fa-spinner"/>}
                                            {" Add Note"}
                                        </button>
                                    </div>

                                    {errors.note && touched.note &&
                                    <div className="invalid-feedback">Required</div>
                                    }

                                </div>
                            </div>
                        </form>
                    )}
                />

            </div>
        );
    }
}

const mapStateToProps = (state) => {

    const accountId = state.user.user.id;

    return {
        accountId,
    };
};

const mapDispatchToProps = {
    getTroubleTicketNotes,
    createTroubleTicketNote,
};

export default connect(mapStateToProps, mapDispatchToProps)(Notes);
