import React, { Component } from 'react';
import { connect } from 'react-redux';
import { hideModal } from '../../../actions/modal.actions';
import { getUser } from '../../../selectors';
import { Formik, Form } from 'formik';
import { object, ref, string } from 'yup';
import { changeUserPassword } from '../../../actions/user.actions';
import { isEmpty } from '../../../utils/helpers';

const transformErrorsFromMyApi = (error) => {
	switch (error.code) {
		case 'A001251':
			return { field: 'currentPassword', message: error.message };
		case 'A001261':
			return { field: 'newPassword', message: error.message };
		case 'A00125':
			return { field: 'currentPassword', message: error.message };
		case 'A001262':
			return { field: 'currentPassword', message: error.message };
		default:
			return '';
	}
};
class PasswordModal extends Component {
	closeModal = () => {
		this.props.hideModal();
	};

	changePassword = () => {};

	handleSubmit = ({ currentPassword, newPassword, setSubmitting, resetForm, setFieldError, setStatus }) => {
		let objectForSend = {
			currentPassword,
			newPassword
		};

		changeUserPassword(objectForSend).then((response) => {
			if (isEmpty(response.error)) {
				this.props.hideModal();
				
			} else {
				setSubmitting(false);
				let errorInfo = transformErrorsFromMyApi(response.error);
				setFieldError(errorInfo.field, errorInfo.message);
				
			}
		});
	};

	render() {
		const { userSummary } = this.props;
		return (
			<Formik
				initialValues={{
					currentPassword: '',
					newPassword: '',
					confirmPass: ''
				}}
				validationSchema={object().shape({
					currentPassword: string().min(6, 'Min 6 character').required('Current password is required'),
					newPassword: string().min(6, 'Min 6 character').required('New password is required'),
					confirmPass: string()
						.oneOf([ ref('newPassword') ], 'Passwords do not match')
						.required('Password is required')
				})}
				onSubmit={({ currentPassword, newPassword }, { setSubmitting, resetForm, setFieldError, setStatus }) =>
					this.handleSubmit({
						currentPassword,
						newPassword,
						setSubmitting,
						resetForm,
						setFieldError,
						setStatus
					})}
				render={(props) => {
					const {
						values,
						touched,
						errors,
						handleChange,
						handleBlur,
						handleSubmit,
						isSubmitting
					} = props;
					return (
						<Form onSubmit={handleSubmit} autoComplete="off">
							<div
								className="modal"
								id="cmv-modal-user-change-pwd"
								style={{ display: 'block' }}
								tabIndex="-1"
								role="dialog"
							>
								<div className="modal-dialog" role="document">
									<div className="modal-content">
										<div className="modal-header">
											<h5 className="modal-title">
												{userSummary.isTempPassword === false ? (
													'CHANGE PASSWORD'
												) : (
													'CHANGE TEMP PASSWORD'
												)}
											</h5>
											<button type="button" className="close">
												<span onClick={this.closeModal}>&times;</span>
											</button>
										</div>
										<div className="modal-body form-horizontal">
											<p className="lead">You are currently signed in as:</p>
											<h4>
												{`${userSummary.firstName
													? userSummary.firstName
													: ''} ${userSummary.lastName ? userSummary.lastName : ''}`}
												<span className="text-muted" />
												{`(${userSummary.username})`}
											</h4>
											<div className="form-group">
												<label htmlFor="currentPassword">Current Password</label>
												<input
													type="password"
													name="currentPassword"
													value={values.currentPassword}
													className={
														errors.currentPassword && touched.currentPassword ? (
															'form-control is-invalid'
														) : (
															'form-control'
														)
													}
													onChange={handleChange}
													onBlur={handleBlur}
												/>
												{errors.currentPassword &&
												touched.currentPassword && (
													<div className="invalid-feedback">{errors.currentPassword}</div>
												)}
											</div>
											<div className="form-group">
												<label htmlFor="new-pwd">New Password</label>
												<input
													type="password"
													name="newPassword"
													value={values.newPassword}
													className={
														errors.newPassword && touched.newPassword ? (
															'form-control is-invalid'
														) : (
															'form-control'
														)
													}
													onChange={handleChange}
													onBlur={handleBlur}
												/>
												<div>
													<div className="progress">
														<div className="progress-bar" />
													</div>
												</div>
												{errors.newPassword &&
												touched.newPassword && (
													<div className="invalid-feedback">{errors.newPassword}</div>
												)}
											</div>
											<div className="form-group">
												<label htmlFor="confirm_password">Confirm Password</label>
												<input
													type="password"
													name="confirmPass"
													value={values.confirmPass}
													className={
														errors.confirmPass && touched.confirmPass ? (
															'form-control is-invalid'
														) : (
															'form-control'
														)
													}
													onChange={handleChange}
													onBlur={handleBlur}
												/>
												{errors.confirmPass &&
												touched.confirmPass && (
													<div className="invalid-feedback">{errors.confirmPass}</div>
												)}
											</div>
										</div>
										<div className="modal-footer">
											<button type="button" onClick={this.closeModal} className="btn">
												Cancel
											</button>
											<button className="btn btn-primary" type="submit" disabled={isSubmitting}>
												Change Password
											</button>
										</div>
									</div>
								</div>
							</div>
							<div className="modal-backdrop show" tabIndex="1" />
						</Form>
					);
				}}
			/>
		);
	}
}

const mapStateToProps = (state) => {
	const userSummary = getUser(state).user;

	return {
		userSummary
	};
};

const mapDispatchToProps = {
	hideModal
};

export default connect(mapStateToProps, mapDispatchToProps)(PasswordModal);
