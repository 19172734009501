import moment from 'moment';

export const parseDateTimeFormat = (date) => {
    const today = moment();
    const yesterday = moment().subtract(1, 'day');

    if(moment(date).isSame(today, 'day')){
        return 'Today'
    }else if(moment(date).isSame(yesterday, 'day')){
        return 'Yesterday'
    }else {
        return moment(date).format('MM/DD/YYYY')
    }
}