import {
	SET_NOTIFICATION_CATEGORIES,
	UPDATE_NOTIFICATION_CATEGORIES,
	GET_NOTIFICATION_ACCOUNT_ALERT,
	UPDATE_NOTIFICATION_ACCOUNT_ALERT,
	GET_ACCOUNT_NOTIFICATIONS
} from '../../actions/accountDetailsActions/notifications.actions';

let initialState = {
	notificationCategories: [],
	accountAlert: {},
	notifications: []
};

export const notifications = (state = initialState, action) => {
	switch (action.type) {
		case SET_NOTIFICATION_CATEGORIES.SUCCESS:
			return {
				...state,
				notificationCategories: action.payload
			};
		case SET_NOTIFICATION_CATEGORIES.EMPTY:
			return {
				...state,
				notificationCategories: []
			};

		case UPDATE_NOTIFICATION_CATEGORIES.SUCCESS:
			return {
				...state,
				notificationCategories: state.notificationCategories.map(
					(item) => (item.id !== action.payload.id ? item : { ...item, ...action.payload })
				)
			};

		case GET_NOTIFICATION_ACCOUNT_ALERT.SUCCESS:
			return {
				...state,
				accountAlert: action.payload
			};

		case GET_ACCOUNT_NOTIFICATIONS.SUCCESS:
			return {
				...state,
				// accountNotifications: action.payload.notificationQueues
				notifications: action.payload
			};

		case GET_ACCOUNT_NOTIFICATIONS.FAILURE:
			return {
				...state,
				notifications: []
			};

		case UPDATE_NOTIFICATION_ACCOUNT_ALERT.SUCCESS:
			return {
				...state,
				accountAlert: action.payload
			};

		default:
			return state;
	}
};
