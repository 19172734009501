import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from "react-router-dom";

class Confirmation extends Component {

    render() {

        const {
            wizardData,
            history,
            hideAccountDetailsWizard,
        } = this.props;

        let tasksCountData = 0;

        return (
            <div className="text-center">

                <p className="text-success"><i className="far fa-4x fa-check-circle" /></p>
                <p className="text-success lead">Equipment swapped successfully.</p>

                <button
                    type="button"
                    className="btn btn-outline-success btn-lg mb-1"
                    style={{ margin: '0px 5px' }}
                    onClick={() => {
                        hideAccountDetailsWizard();
                        history.replace({state: {serviceLineId: wizardData.serviceLineId}});
                    }}
                >
                    View Service
                </button>

                {tasksCountData > 0 && (
                    <Link
                        to={{
                            pathname: '/task-list',
                            // search: `?orderId=${newOrderInfo.id}`
                        }}
                        className="btn btn-warning btn-lg mb-1"
                        style={{ margin: '0px 5px' }}
                    >
                        <i className="fas fa-tools" />&nbsp;{tasksCountData} {tasksCountData > 1 ? 'Tasks' : 'Task'} Pending
                    </Link>
                )}

            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
    };
};

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(Confirmation);