import {actionCreator, createRequestTypes} from '../utils/helpers';

export const SET_PAYMENT_SUBMITTED = createRequestTypes('SET_PAYMENT_SUBMITTED');
export const SET_SERVICES_UPDATED = createRequestTypes('UPDATE_SERVICES_UPDATED_FLAG');

export const paymentSubmitted = () => async (dispatch) => {
	dispatch(actionCreator.success(SET_PAYMENT_SUBMITTED));
}

export const updateServicesUpdatedFlag = () => async (dispatch) => {
	dispatch(actionCreator.success(SET_SERVICES_UPDATED));
}