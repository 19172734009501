import * as Yup from 'yup';

export const validationSchema = (activeTab) => {
    if (activeTab === 0) {
        return Yup.object().shape({
             features: Yup.array().of(
                Yup.object().shape({
                    featureVariabledPrice: Yup.number().typeError('Please enter a number ')
                })
             )
        });
    } else if (activeTab === 1) {

    } else if (activeTab === 2) {
        return Yup.object().shape({
            plans: Yup.array().of(
                Yup.object().shape({
                    defaultSource: Yup.string(),
                    // servicelines: Yup.array().when('defaultSource', {
                    // 	is: (value) => value === 'number_inventory',
                    // 	then: Yup.array().of(
                    // 		Yup.object().shape({
                    // 			number: Yup.string().required('Required'),
                    // 			// devices: Yup.array().of(Yup.object()).min(1, 'Required')
                    // 		})
                    // 	)
                    // })
                    servicelines: Yup.array().of(
                        Yup.object().shape({
                            // number: Yup.string().when('defaultSource', {
                            //     is: (value) => value === '',
                            //     then: Yup.string().required('Required')
                            // }),
                            number: Yup.string().test('testova', 'Required', function (value) {
                                if (typeof value === 'undefined') {
                                    return true
                                } else {
                                    return value !== ''
                                }
                            }),
                            devices: Yup.array().test('proba', 'Required', function (value) {
                                if (typeof value === 'undefined') {
                                    return true
                                } else {
                                    return value.length > 0
                                }
                            })
                        })
                    )
                })
            )
        });
    }

    // discounts: Yup.array().when('showDiscounts', {
    //     is: true,
    //     then: Yup.array().of(
    //         Yup.object().shape({
    //             id: Yup.string().required('Required')
    //         })
    //     )
    // }),
};
