import React, { Component } from 'react';
import { connect } from "react-redux";
import FormSelect from "../../../components/UI/FormSelect";
import { get, isEmpty } from 'lodash';
import { createLoadingSelector, getAccountDetails } from '../../../selectors/index';

export class ResolutionCode extends Component {
    state = {
        selectedResolutionCodes: [],
        alertMessage: ''
    }

    scrollDropdownIntoView = (e) => {

    }

    handlerErrorResponse = (errorResponse) => {
        this.setState({
            alertMessage: get(errorResponse, 'response.data.error.message', 'Something goes wrong'),
            technicianOptions: []
        })
    }

    render() {
        const {
            field, errors, touched, values, setFieldValue, handleBlur, formAttributes
        } = this.props;
        const resolutionCodes = formAttributes.troubleTicketDetails ? formAttributes.troubleTicketDetails.resolutionCodeDetails ? formAttributes.troubleTicketDetails.resolutionCodeDetails.map(rc => {
            return {
                value: rc.id,
                label: rc.description
            }
        }) : [] : [];
        
        return (
            <div className="form-group" >
                <label>{field.name}</label>
                <div className="form-group position-relative" >
                    <div onClick={this.scrollDropdownIntoView}>
                        <FormSelect
                            fieldName="resolution_code"
                            placeholder="Resolution code"
                            setFieldValue={setFieldValue}
                            value={values.resolution_code}
                            onBlur={handleBlur}
                            errors={errors}
                            touched={touched}
                            options={resolutionCodes}
                        />
                    </div>
                </div>
                {/* {errors.resolution_code && touched.resolution_code &&
                    <div className="invalid-feedback">{errors.resolution_code}</div>
                } */}
            </div>
        );
    }
}

const mapStateToProps = (state) => {


    return {
    
    };
};

const mapDispatchToProps = {
    
};

export default connect(mapStateToProps, mapDispatchToProps)(ResolutionCode);