import React, {Component} from 'react';
import {connect} from "react-redux";
import {getWorkflowImage, getWorkFlowBPMNFile} from "../../actions/workflow.actions";
import {getUserPermission} from "../../selectors";
import {showModal} from "../../actions/modal.actions";
import WorkflowStatuses from './WorkflowStatuses';
import {toastr} from "react-redux-toastr";

export class Workflow extends Component {

    state = {
        isImageLoading: false,
        isImageVisible: false,
        imageUrl: null,
        isStatusesVisible: false,
        isDownloading: false
    }

    handleExpandWorkflow = () => {

        this.setState(() => ({
            isImageVisible: false,
            isStatusesVisible: !this.state.isStatusesVisible,
        }));
    }

    handleLoadImage = () => {

        if (!this.state.imageUrl) {
            this.setState({
                isImageLoading: true,
                isStatusesVisible: false,
            }, () => {

                this.props.getWorkflowImage(this.props.workflowDetails.id).then((response) => {

                    if (response && response.status === 200 && response.data) {
                        let imageUrl = URL.createObjectURL(response.data);

                        this.setState({
                            isImageLoading: false,
                            isImageVisible: true,
                            imageUrl: imageUrl,
                        });
                    }
                    else {
                        toastr.error("There was an error", { timeOut: 2000, position: 'top-center' });

                        this.setState({
                            isImageLoading: false,
                            isImageVisible: false,
                        });
                    }
                });
            });
        }
        else {
            this.setState({
                isImageVisible: !this.state.isImageVisible,
                isStatusesVisible: false,
            });
        }
    }

    handleDownloadBPMNFile = () => {
        this.setState({
            isDownloading: true
        });

        this.props.getWorkFlowBPMNFile(this.props.workflowDetails.id).then((response) => {
            if (response && response.status === 200 && response.data) {
                let link = document.createElement('a');
                let fileUrl = URL.createObjectURL(response.data);
                let fileName = this.props.workflowDetails.id.split(":");
                link.setAttribute('href', fileUrl);
                link.setAttribute('download',fileName[0] + ".bpmn20.xml");
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                this.setState({
                    isDownloading: false
                });
            }
        })
    }

    showUploadWorkflowModal = () => {
        let workflowInfo = {
            description: this.props.workflowDetails.description || '',
            key: this.props.workflowDetails.key || '',
            name: this.props.workflowDetails.name || '',
            resourceName: this.props.workflowDetails.resourceName || '',
            migrateOldWorkflows: false
        }
        this.props.showModal('UPLOAD_WORKFLOW_MODAL', workflowInfo);
    }

    openImageInNewTab = () => {
        window.open(this.state.imageUrl, '_blank');
    }

    render() {

        const {
            isImageLoading,
            isImageVisible,
            imageUrl,
            isStatusesVisible,
            isDownloading
        } = this.state;

        const {
            workflow,
            workflowType,
            workflowDetails,
            description,
            canUploadWorkflow
        } = this.props;

        return (
            <div className="workflow">

                <div className="workflow-header">
                    <div className="row">
                        <div className="col col-12 col-sm-4 col-md-3 col-xl-2">

                            {workflowType &&
                            <p className="workflow-type">{workflowType}</p>
                            }

                            {workflowDetails && workflowDetails.version &&
                            <p className="workflow-version"><i className="far fa-fw fa-clock"/> v.{workflowDetails.version}</p>
                            }

                        </div>
                        <div className="col col-12 col-sm-8 col-md-auto">

                            <div className="workflow-details">

                                <div className="workflow-info">

                                    {workflowType === "Service Order" && <>
                                        <span><span>Type:</span> {workflow.type || "N/A"}</span>
                                        <span><span>Source:</span> {workflow.source || "Any"}</span>
                                        <span><span>Service Model:</span> {workflow.serviceModel || "N/A"}</span>
                                        <span><span>Acct Type:</span> {workflow.accountType || "Any"}</span>
                                        <span><span>Acct Terms:</span> {workflow.accountTerms || "Any"}</span>
                                    </>
                                    }

                                    {workflow.fromStatus &&
                                    <span><span>From:</span> {workflow.fromStatus}</span>
                                    }
                                    {workflow.toStatus &&
                                    <span><span>To:</span> {workflow.toStatus}</span>
                                    }

                                    {workflow.values && workflow.values.map(value =>
                                        <span><span>{value.propertyName}:</span> {value.newValue}</span>
                                    )}

                                </div>

                                <h6>{description}</h6>
                                <p>{workflow.workflowName}</p>

                            </div>
                        </div>
                        <div className="col col-12 col-md-auto">
                            <div className="workflow-controls">
                                {workflowDetails && workflowDetails.id &&
                                <button
                                    className="btn btn-circle"
                                    onClick={this.handleLoadImage}
                                    disabled={isImageLoading}
                                >
                                    {isImageLoading
                                        ? <i className="fas fa-fw fa-spin fa-spinner"/>
                                        : isImageVisible
                                            ? <i className="fas fa-fw fa-eye-slash"/>
                                            : <i className="fas fa-fw fa-eye"/>
                                    }
                                </button>
                                }
                                {workflowDetails && workflowDetails.id &&
                                <button
                                    className="btn btn-circle"
                                    onClick={this.handleDownloadBPMNFile}
                                    disabled={isDownloading}
                                >
                                    {isDownloading
                                        ? <i className="fas fa-fw fa-spin fa-spinner"/>
                                        :
                                        < i className="fas fa-fw fa-download" />
                                    }
                                </button>
                                }
                                {workflowDetails && canUploadWorkflow &&
                                <button
                                    className="btn btn-circle"
                                    onClick={() => this.showUploadWorkflowModal()}
                                >
                                    <i className="fas fa-fw fa-upload" />
                                </button>
                                }
                                <button
                                    className="btn btn-circle"
                                    onClick={this.handleExpandWorkflow}
                                >
                                    <i className={"fas" + (isStatusesVisible ? " fa-angle-double-up" : " fa-angle-double-down")}/>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                {isImageVisible &&
                <div className="workflow-image" onClick={this.openImageInNewTab}>
                    <img src={imageUrl} alt={workflow.description} />
                </div>
                }

                {isStatusesVisible &&
                <WorkflowStatuses {...this.props} />
                }

            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const canUploadWorkflow = getUserPermission(state, 'ADMN', 'ADMN_UPLOAD_WORKFLOWS');
    return {
        canUploadWorkflow
    };
};

const mapDispatchToProps = {
    getWorkflowImage,
    getWorkFlowBPMNFile,
    showModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(Workflow);
