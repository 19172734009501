import {
	SET_ACCOUNT_DETAILS,
	SHOW_ACCOUNT_DETAILS_WIZARD,
	SET_ACCOUNT_PROMISES,
	CHANGE_ACCOUNT_STATUS,
	GET_ACCOUNT_ALLOWED_STATUS_TRANSITIONS,
	AUTOPAY,
	AUTOPAY_AVAILABLE_CC,
	GET_SERVICEORDER_ADDRESS, SERVICELINE_ADDRESS
} from '../../actions/accountDetailsActions/accountDetails.actions';

let initialState = {
	accountInfo: {},
	addressInformation: {},
	showAccountDetailsWizard: false,
	accountDetailsWizardData: {},
	accountPromises: [],
	accountAllowedStatusTransitions: [],
	myAutoPayInfo: [],
	myAutoPayAvailableCC: [],
};

export const accountDetails = (state = initialState, action) => {
	switch (action.type) {
		case SET_ACCOUNT_DETAILS.SUCCESS:
			return {
				...state,
				accountInfo: action.payload
			};
		case SET_ACCOUNT_DETAILS.EMPTY:
			return {
				...state,
				accountInfo: {}
			};
		case SHOW_ACCOUNT_DETAILS_WIZARD.SUCCESS:
			return {
				...state,
				showAccountDetailsWizard: true,
				accountDetailsWizardData: action.payload
			};
		case SHOW_ACCOUNT_DETAILS_WIZARD.EMPTY:
			return {
				...state,
				showAccountDetailsWizard: false,
				accountDetailsWizardData: {}
			};
		case SET_ACCOUNT_PROMISES.SUCCESS:
			return {
				...state,
				accountPromises: action.payload
			}
		case SET_ACCOUNT_PROMISES.EMPTY:
			return {
				...state,
				accountPromises: []
			}
		case CHANGE_ACCOUNT_STATUS.SUCCESS:
			return {
				...state,
				accountInfo: { ...state.accountInfo, status: action.payload }
			}
		case GET_ACCOUNT_ALLOWED_STATUS_TRANSITIONS.SUCCESS:
			return {
				...state,
				accountAllowedStatusTransitions: action.payload,
			}
		case AUTOPAY.SUCCESS:
			return {
				...state,
				myAutoPayInfo: action.payload
			};
		case AUTOPAY.EMPTY:
			return {
				...state,
				myAutoPayInfo: []
			};
		case AUTOPAY_AVAILABLE_CC.SUCCESS:
			return {
				...state,
				myAutoPayAvailableCC: action.payload
			};
		case GET_SERVICEORDER_ADDRESS.SUCCESS:
			return {
				...state,
				addressInformation: action.payload
			};
		case GET_SERVICEORDER_ADDRESS.EMPTY:
			return {
				...state,
				addressInformation: {}
			};
		case SERVICELINE_ADDRESS.SUCCESS:
			return {
				...state,
				addressInformation: action.payload
			};
		case SERVICELINE_ADDRESS.EMPTY:
			return {
				...state,
				addressInformation: {}
			};
		default:
			return state;
	}
};
