import React, { Component } from 'react';
import { getModalData } from '../../selectors';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as ModalType from './ModalTypes';

const MODAL_COMPONENTS = {
	SIGNOUT_MODAL: ModalType.SignoutModal,
	PASSWORD_MODAL: ModalType.PasswordModal,
	LOCATION_MODAL: ModalType.LocationModal,
	VIEW_AS_PARTNER_MODAL: ModalType.ViewAsPartnerModal,
	CLOSE_CREATE_ACCOUNT_MODAL: ModalType.CloseCreateAccountModal,
	ACCOUNT_ACCESS_MODAL: ModalType.AccountAccessModal,
	CLOSE_ACCOUNT_DETAILS_MODAL: ModalType.CloseAccountDetailsModal,
	ACCOUNT_NOTE_MODAL: ModalType.AccountNoteModal,
	ORDER_MODAL: ModalType.OrderModal,
	QUOTE_MODAL: ModalType.QuoteModal,
	ADD_ADJUSTMENT_MODAL: ModalType.AddAdjustmentModal,
	ADD_IMMEDIATE_ADJUSTMENTS_MODAL: ModalType.AddImmediateAdjustmentsModal,
	GENERIC_DELETE_BILING_MODAL: ModalType.GenericDeleteBillingModal,
	RECCURING_CREDDINTS_MODAL: ModalType.ReccuringCreditsModal,
	DISCOUNTS_MODAL: ModalType.DiscountsModal,
	CLOSE_SERVICE_REQUEST_MODAL: ModalType.CloseServiceRequestModal,
	GENERIC_CLOSE_MODAL: ModalType.GenericCloseModal,
	GENERIC_MODAL: ModalType.GenericModal,
	PAYMENT_PROMISES_MODAL: ModalType.PaymentPromisesModal,
	TAX_EXEMPTIONS_MODAL: ModalType.TaxExemptionsModal,
	CLOSE_ACCOUNT_DETAILS_WIZARD_MODAL: ModalType.CloseAccountDetailsWizardModal,
	PAY_NOW_MODAL: ModalType.PayNowModal,
	REVERSE_PAYMENT_MODAL: ModalType.ReversePaymentModal,
	QUOTE_AND_ACCOUNT_MODAL: ModalType.QuoteAndAccountModal,
	ADD_CONTACT_USER_MODAL: ModalType.AddContactUserModal,
	ACCOUNT_MODAL: ModalType.AccountModal,
	ACCOUNT_STATUS_CHANGE_MODAL: ModalType.AccountStatusChangeModal,
	SERVICE_LINE_STATUS_CHANGE_MODAL: ModalType.ServiceLineStatusChangeModal,
	ADD_SYSTEM_USER_MODAL: ModalType.AddSystemUserModal,
	ADD_PLAN_MODAL: ModalType.AddPlanModal,
	ADD_FEATURE_MODAL: ModalType.AddFeatureModal,
	ADD_INSTALLMENT_PLAN_MODAL: ModalType.AddInstallmentPlanModal,
	DELETE_SYSTEM_USER_MODAL: ModalType.DeleteSystemUserModal,
	MANAGE_SYSTEM_USER_ACCESS_TOKENS_MODAL: ModalType.ManageSystemUserAccessTokensModal,
	ADD_PARTNER_MODAL: ModalType.AddPartnerModal,
	DELETE_PARTNER_MODAL: ModalType.DeletePartnerModal,
	ACCOUNT_MAILING_ADDRESS_MODAL: ModalType.AccountMailingAddressModal,
	BILLING_SETTINGS_MODAL: ModalType.BillingSettingsModal,
	TARGET_DATE_MODAL: ModalType.TargetDateTimeModal,
	PAPERLESS_MODAL: ModalType.PaperlessModal,
	AUTOPAY_MODAL: ModalType.AutopayModal,
	SEND_REGISTRATION_LINK_MODAL: ModalType.SendRegistrationLinkModal,
	CAPITAL_CREDIT_MODAL: ModalType.CapitalCreditModal,
	ACCOUNT_INFORMATION_MODAL: ModalType.AccountInformationModal,
	SALES_AGENT_MODAL: ModalType.SalesAgentModal,
	BULK_START_ORDERS_MODAL: ModalType.BulkStartOrdersModal,
	TROUBLE_TICKET_MODAL: ModalType.TroubleTicketModal,
	TECHNICIAN_APPOINTMENT_MODAL: ModalType.TechnicianAppointmentModal,
	APPOINTMENT_MAPPING_MODAL: ModalType.AppointmentMappingModal,
	UPLOAD_WORKFLOW_MODAL: ModalType.UploadWorkFlowModal,
	CUSTOMER_PORTAL_MODAL: ModalType.CustomerPortalModal,
	SERVICE_ADDRESS_MODAL: ModalType.ServiceAddressModal,
	HTML_NOTIFICATION_MODAL: ModalType.HtmlNotificationModal,
	BARCODE_SCANNER_MODAL: ModalType.BarcodeScannerModal,
	ADD_BILLING_JOB_MODAL: ModalType.AddBillingJobModal,
	DETAILED_DESCRIPTION_MODAL: ModalType.DetailedDescriptionModal,
	BILLING_JOB_MONITOR_MODAL: ModalType.BillingJobMonitorModal,
	ADD_AUTOPAY_JOBS_MODAL: ModalType.AddAutoPayJobModal,
	ABORT_AUTOPAY_JOBS_MODAL: ModalType.AbortAutoPayJobModal,
	ADD_FEATURE_PACKAGE_MODAL : ModalType.AddFeaturePackageModal,
	EDIT_PLAN_FEATURES_PACKAGES_MODAL: ModalType.EditPlanFeaturesAndPackagesModal,
	DELETE_FEATURE_PACKAGE_MODAL: ModalType.DeleteFeaturePackageModal,
	EDIT_EQUIPMENT_MODAL : ModalType.EditEquipmentModal,
	PACKAGE_OPTION_DETAILED_DESCRIPTION_MODAL: ModalType.PackageOptionDetailedDescriptionModal,
	DELETE_FEATURE_MODAL: ModalType.DeleteFeatureModal,
	DELETE_INSTALLMENT_PLAN_MODAL: ModalType.DeleteInstallmentModal,
	EDIT_ADJUSTMENT_MODAL: ModalType.EditAdjustmentModal,
	DELETE_ADJUSTMENT_MODAL: ModalType.DeleteAdjustmentModal,
	EDIT_TAXES_MODAL: ModalType.EditTaxesModal
};

export class RootModal extends Component {
	render() {
		const { modalType } = this.props;

		if (!modalType) {
			return null;
		}

		const CurrentModal = MODAL_COMPONENTS[modalType];

		return <CurrentModal {...this.props} />;
	}
}

RootModal.propTypes = {
	modalType: PropTypes.string
}

const mapStateToProps = (state) => {
	const modalType = getModalData(state).modalType;

	return {
		modalType
	};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(RootModal);
