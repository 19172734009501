import React, {Component, Fragment} from 'react';
import * as Yup from 'yup';
import { Formik } from "formik";
import isEmpty from "../../../utils/helpers";
import { hideModal } from "../../../actions/modal.actions";
import {getAccountTypeDetails} from "../../../actions/createAccount.actions";
import { connect } from "react-redux";
import {createLoadingSelector, getModalData} from "../../../selectors";
import {
    generateMemberNumber,
    updateAccountCapitalCredit
} from "../../../actions/accountDetailsActions/capitalCreditActions";
import Loader from "../../../components/Loader";
import { get } from "lodash";

class CapitalCreditModal extends Component {

    state = {
        alertMessage: '',
        alertClass: '',
        requireMemberNumber: null,
    };

    componentDidMount() {
        this.loadAccountType();
    }

    loadAccountType = () => {
        const { accountTypeId } = this.props.modalProps;
        this.props.getAccountTypeDetails(accountTypeId)
            .then((response) => {
                if(response && response.status === 200){
                    const requireMemberNumberField = response.data.account_type.fields.find(x => x.name === 'memberNumber');
                    this.setState({
                        requireMemberNumber: requireMemberNumberField ? requireMemberNumberField.required : false
                    });
                }
            });
    }

    generateMemberNumber = (setSubmitting) => {
        const { accountId } = this.props.modalProps;
        this.props.generateMemberNumber(accountId)
            .then((response) => {
                setSubmitting(true);
                this.handleResponse(response, setSubmitting);
            })
    }

    capitalCreditSchema = () => {
    const validationSchema = {
        requireMemberNumber: Yup.boolean(),
        memberNumber: Yup.string()
            .when("requireMemberNumber", {
                is: true,
                then  : Yup.string().nullable().required("Member number is required")
                                    .min(1, 'Member number cannot be empty')
                                    .max(45, 'Member number cannot be over 45 characters')},
            )
            .when("requireMemberNumber", {
                is: false,
                then  : Yup.string().nullable()
                                    .min(1, 'Member number cannot be empty')
                                    .max(45, 'Member number cannot be over 45 characters')},
            )
    };
        return Yup.object().shape(validationSchema);
    };

    formSubmit = (values, actions) => {
        const { accountId } = this.props.modalProps
        const { requireMemberNumber, ...submitData } = values;

        this.setState({alertMessage: '', alertClass: ''}, () => {

            this.props.updateAccountCapitalCredit(accountId, submitData).then((response) => {
                this.handleResponse(response, actions.setSubmitting);
            });
        });
    }

    handleResponse = (response, setSubmitting) => {
        if (response.status === 200) {
            const message = 'Capital credit updated successfully';
            this.setState({alertMessage: message, alertClass: 'alert-success'});
            setTimeout(() => this.props.hideModal(), 1000);
        }
        if (response.status !== 200) {
            if(response.data) {
                setSubmitting(false);
                this.setState({alertMessage: response.data.error.message, alertClass: 'alert-danger'});
            } else {
                setSubmitting(false);
                this.setState({alertMessage: 'Unknown error. Please try again later', alertClass: 'alert-danger'});
            }
        }
    }

    clearAlertMessage = () => {
        this.setState({alertMessage: ''})
    }

    render() {
        const { alertMessage, requireMemberNumber } = this.state;
        const alertClass = `alert alert-inline ${this.state.alertClass} alert-dismissible cmv-alert fade show`;
        const { modalProps, accountTypeDetailsLoader } = this.props;

        const { capitalCredit } = modalProps;
        const disableMemberNumberGeneration = !isEmpty(capitalCredit.memberNumber);

        return (
            <Fragment>
                {requireMemberNumber === null || accountTypeDetailsLoader ?
                    <div className="col">
                        <Loader/>
                    </div>
                    :
                    <div className="modal" style={{display: 'block'}} tabIndex="-1" role="dialog">
                        <Formik
                            initialValues={{
                                requireMemberNumber: requireMemberNumber,
                                memberNumber: capitalCredit.memberNumber || ''
                            }}
                            validationSchema={this.capitalCreditSchema()}
                            onSubmit={this.formSubmit}
                            render={({setSubmitting, handleSubmit, handleBlur, handleChange, values, errors, touched, isSubmitting}) => (
                                <form onSubmit={handleSubmit}>
                                    <div className="modal-dialog">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h5 className="modal-title">
                                                    EDIT CAPITAL CREDIT
                                                </h5>
                                                <button onClick={this.props.hideModal} type="button" className="close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div className="modal-body">
                                                <div className="form-horizontal">

                                                    {!isEmpty(alertMessage) && (
                                                        <div className={alertClass} role="alert">
                                                            <p className="mb-0">{alertMessage}</p>
                                                            <button onClick={this.clearAlertMessage}
                                                                    type="button" className="close"
                                                                    data-dismiss="alert"
                                                                    aria-label="Close">
                                                                <span aria-hidden="true">&times;</span>
                                                            </button>
                                                        </div>
                                                    )}

                                                    <div className="form-group">
                                                        <label htmlFor="">Member Number</label>
                                                        <div className="input-group">
                                                            <input
                                                                type="text"
                                                                name="memberNumber"
                                                                placeholder="Member Number"
                                                                value={values.memberNumber}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                className="form-control"
                                                            />
                                                            {!capitalCredit.memberNumber && isEmpty(values.memberNumber) && (
                                                                <div className="input-group-append">
                                                                    <button
                                                                        className="btn btn-outline-secondary"
                                                                        type="button"
                                                                        onClick={() => {this.generateMemberNumber(setSubmitting)}}
                                                                        disabled={disableMemberNumberGeneration}
                                                                    >
                                                                        <i className="fas fa-paper-plane"/> Generate
                                                                    </button>
                                                                </div>
                                                            )}
                                                            {get(errors, `memberNumber`) &&
                                                            get(touched, `memberNumber`) && (
                                                                <div className="invalid-feedback">{get(errors, `memberNumber`)}</div>
                                                            )}
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="modal-footer">
                                                <button onClick={this.props.hideModal} className="btn" type="button">
                                                    CANCEL
                                                </button>
                                                <button disabled={isSubmitting} type="submit"
                                                        className="btn btn-primary">
                                                    EDIT CAPITAL CREDIT
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            )}
                        />
                    </div>
                }
            <div className="modal-backdrop show" tabIndex="1"/>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    const accountTypeDetailsLoaderSelector = createLoadingSelector(['ACCOUNT_TYPE_DETAILS']);
    const modalProps = getModalData(state).modalProps;
    const accountTypeDetailsLoader = accountTypeDetailsLoaderSelector(state);

    return {
        modalProps,
        accountTypeDetailsLoader
    };
};

const mapDispatchToProps = {
    hideModal,
    getAccountTypeDetails,
    updateAccountCapitalCredit,
    generateMemberNumber,
};

export default connect(mapStateToProps, mapDispatchToProps)(CapitalCreditModal);