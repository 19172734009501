import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { hideModal } from '../../../actions/modal.actions';
import {
	getAdjustmentTypes,
	getAccountServicelines,
	createImmediatAdjustment,
	createPendingAdjustment,
	updatePendingAdjustment,
	emptyAccountServicelines,
	emptyAdjustmentTypes
} from '../../../actions/accountDetailsActions/billing.actions';
import { getAccountDetails, getUser, getModalData } from '../../../selectors';
import { Formik } from 'formik';
import * as Yup from 'yup';

const AdjustmentSchema = Yup.object().shape(
	{
		applyOn: Yup.string().required('Required'),
		amount: Yup.number().typeError('Only digits').positive('Only positive amounts').required('Required'),
		itemId: Yup.number('Must be number').required('Required'),
		note: Yup.string('Must be string').min(10, 'Minimum 10 character').required('Required')
	}
);

class AddAdjustmentModal extends Component {
	state = {
		showAlertMessage: false,
		alertMessage: ''
	};
	
	componentDidMount() {
		this.props.getAdjustmentTypes();
		this.props.getAccountServicelines(this.props.accountId);
	}

	componentWillUnmount() {
		this.props.emptyAdjustmentTypes();
		this.props.emptyAccountServicelines();
	}

	onHandleChangeApplyOn = (e) => {
		this.setState({
			applyOn: e.target.value
		});
	};

	formatedLabel = (values) => {
		const { adjustmentTypesData } = this.props;

		let adjustmentType = adjustmentTypesData.find((adjustment) => adjustment.id === Number(values.itemId));

		if (adjustmentType && adjustmentType.type === 'CREDIT') {
			return 'Credit Amount';
		} else {
			return 'Amount';
		}
	};

	showCreditAdjustmentType = () => {
		if(this.props.modalProps.row && this.props.modalProps.row.itemType === 'CREDIT'){
			return true;
		}
		return false;
	}

	showChargeAdjustmentType = () => {
		if(this.props.modalProps.row && this.props.modalProps.row.itemType === 'ADJ-CHARGES'){
			return true;
		}
		return false;
	}

	sortArray = (property) => {

		return function(a, b) {

			if (a[property] < b[property]) {
				return -1;
			}
			else if (a[property] > b[property]) {
				return 1;
			}
			else {
				return 0;
			}
		}
	}

	formSubmit = (values, actions) => {
		if (this.props.modalProps.type === 'create') {
			if (values.applyOn === 'immediately') {
				this.props.createImmediatAdjustment(this.props.accountId, values).then((response) => {
					if (response.data.success) {
						this.closeModal();
					} else {
						actions.setSubmitting(false);
						this.setState({
							showAlertMessage: true,
							alertMessage: response.data.error && response.data.error.message
						});
					}
				});
			} else if (values.applyOn === 'endOfBillCycle') {
				this.props.createPendingAdjustment(this.props.accountId, values).then((response) => {
					if (response.data.success) {
						this.props.hideModal();
					} else {
						actions.setSubmitting(false);
					}
				});
			}
		} else {
			const { accountId, modalProps } = this.props;

			const data = {};

			if (values.adjustmentType === 'CREDIT') {
				data.amount = Math.abs(values.amount);
			}
			else {
				data.amount = Math.abs(values.amount);
			}
			
			this.props.updatePendingAdjustment(accountId, data, modalProps.row.id).then((response) => {
				this.props.hideModal();
			});
		}
	};

	closeModal = () => {
		this.props.hideModal();

		if (this.props.modalProps.onCloseCallback) {
			this.props.modalProps.onCloseCallback();
		}
	};

	render() {
		const { showAlertMessage, alertMessage } = this.state;
		const { modalProps, adjustmentTypesData, adjustmentServicelinesData } = this.props;

		adjustmentTypesData.sort(this.sortArray("description"));

		let showCredit = this.showCreditAdjustmentType();
		let showCharge = this.showChargeAdjustmentType();

		return (
			<Fragment>
				<div className="modal" style={{ display: 'block' }} tabIndex="-1" role="dialog">
					<Formik
						initialValues={{
							adjustmentType: (modalProps.type === 'create'
								? 'CREDIT'
								: (modalProps.row && modalProps.row.itemType === 'CREDIT')
									? 'CREDIT'
									: 'ADJ-CHARGES'
							),
							amount: (modalProps.row && Math.abs(modalProps.row.amount)) || 0,
							itemId: (modalProps.row && modalProps.row.itemId) || '',
							locationId: this.props.currentLocationId,
							note: (modalProps.row && modalProps.row.note) || '',
							serviceLineId: (modalProps.row && modalProps.row.serviceLineId) || '',
							applyOn: this.props.modalProps.applyOnPeriod || 'immediately'
						}}
						validationSchema={AdjustmentSchema}
						onSubmit={this.formSubmit}
						render={({ setFieldValue, handleChange, handleSubmit, handleBlur, values, errors, touched, isSubmitting }) => (
							<form onSubmit={handleSubmit}>
								<div className="modal-dialog">
									<div className="modal-content">
										<div className="modal-header">
											<h5 className="modal-title">
												{modalProps.type === 'create' ? 'Add' : 'Edit'} Pending Adjustment
											</h5>
											<button onClick={this.props.hideModal} type="button" className="close">
												<span aria-hidden="true">&times;</span>
											</button>
										</div>
										<div className="modal-body form-horizontal">

											{showAlertMessage === true && (
												<div className="alert alert-inline alert-danger alert-dismissible">
													<p className="mb-0">{alertMessage}</p>
												</div>
											)}

											<div className="form-group d-flex justify-content-end">
												{(showCredit || (!showCredit && !showCharge)) &&
												<span className="h-check">
													<div className="form-check checkbox-slider checkbox-slider--b-flat">
														<label>
															<input
																name="adjustmentType"
																type="radio"
																onChange={() => {
																	setFieldValue('itemId', '');
																	setFieldValue('adjustmentType', 'CREDIT');
																	errors.itemId = false;
																	touched.itemId = false;
																}}
																checked={values.adjustmentType === 'CREDIT'}
																disabled={modalProps.type === 'edit'}
															/>
															<span className="ml-2">Credit</span>
														</label>
													</div>
												</span>
												}
												{(showCharge || (!showCredit && !showCharge)) &&
												<span className="h-check ml-4">
													<div className="form-check checkbox-slider checkbox-slider--b-flat">
														<label>
															<input
																name="adjustmentType"
																type="radio"
																onChange={() => {
																	setFieldValue('itemId', '');
																	setFieldValue('adjustmentType', 'ADJ-CHARGES');
																	errors.itemId = false;
																	touched.itemId = false;
																}}
																checked={values.adjustmentType === 'ADJ-CHARGES'}
																disabled={modalProps.type === 'edit'}
															/>
															<span className="ml-2">Charge</span>
														</label>
													</div>
												</span>
												}
											</div>

											<div className="form-group">
												<label>Adjustment Type</label>
												<select
													className={'form-control customer-adjustment-type' + (errors.itemId && touched.itemId ? ' is-invalid': '')}
													name="itemId"
													placeholder="Please select serviceline"
													value={values.itemId}
													onChange={handleChange}
													onBlur={handleBlur}
													disabled={modalProps.type === 'edit'}
												>
													<option value="">Please select Item</option>
													{adjustmentTypesData.map((type) => (
														((values.adjustmentType === '' || type.type === values.adjustmentType) &&
														<option value={type.id} key={type.id}>
															{type.description}
														</option>
														)
													))}
												</select>
												{errors.itemId &&
												touched.itemId && (
													<div className="invalid-feedback">{errors.itemId}</div>
												)}
											</div>
											<div className="form-group">
												<label>Serviceline (Optional)</label>
												<select
													className={
														errors.serviceLineId && touched.serviceLineId ? (
															'form-control customer-adjustment-serviceline is-invalid'
														) : (
															'form-control customer-adjustment-serviceline'
														)
													}
													placeholder="Please select serviceline"
													value={values.serviceLineId}
													onChange={handleChange}
													onBlur={handleBlur}
													name="serviceLineId"
													disabled={modalProps.type === 'edit'}
												>
													<option value="">Please select serviceline</option>
													{adjustmentServicelinesData.map((serviceline) => (
														<option value={serviceline.id} key={serviceline.id}>
															{serviceline.number}
														</option>
													))}
												</select>
											</div>
											{/*{modalProps.type === 'create' && (
												<div className="form-group">
													<label>Apply On</label>
													<select
														className="form-control customer-adjustment-apply"
														placeholder="Please select serviceline"
														value={values.applyOn}
														onChange={handleChange}
														onBlur={handleBlur}
														disabled={modalProps.type === 'edit' || modalProps.applyOnPeriod}
														name="applyOn"
													>
														<option value="immediately">Immediately</option>
														<option value="endOfBillCycle">End of Bill Cycle</option>
													</select>
													{errors.applyOn &&
													touched.applyOn && (
														<div className="invalid-feedback">{errors.applyOn}</div>
													)}
												</div>
											)}*/}

											<div className="form-group">
												<label>{this.formatedLabel(values)}</label>
												<div className="input-group">
													<div className="input-group-prepend">
														<div className="input-group-text">$</div>
													</div>
													<input
														type="text"
														className={
															errors.amount && touched.amount ? (
																'form-control is-invalid'
															) : (
																'form-control'
															)
														}
														placeholder="Amount"
														value={values.amount}
														onChange={handleChange}
														onBlur={handleBlur}
														name="amount"
													/>
													{errors.amount &&
													touched.amount && (
														<div className="invalid-feedback">{errors.amount}</div>
													)}
												</div>
												<p><small class="text-muted">Will be applied on the next bill run.</small></p>
											</div>
											<div className="form-group">
												<label>Note</label>
												<textarea
													className={
														errors.note && touched.note ? (
															'form-control is-invalid'
														) : (
															'form-control'
														)
													}
													value={values.note}
													onChange={handleChange}
													onBlur={handleBlur}
													name="note"
													disabled={modalProps.type === 'edit'}
												/>
												{errors.note &&
												touched.note && <div className="invalid-feedback">{errors.note}</div>}
											</div>
										</div>
										<div className="modal-footer">
											<button onClick={this.props.hideModal} className="btn" type="button">
												Cancel
											</button>
											<button disabled={isSubmitting} type="submit" className="btn btn-primary">
												{modalProps.type === 'create' ? 'Add Adjustment' : 'Edit Adjustment'}
											</button>
										</div>
									</div>
								</div>
							</form>
						)}
					/>
				</div>
				<div className="modal-backdrop show" tabIndex="1" />
			</Fragment>
		);
	}
}

const mapStateToProps = (state) => {
	const adjustmentTypesData = getAccountDetails(state).billing.adjustmentTypes,
		adjustmentServicelinesData = getAccountDetails(state).billing.accountServicelines,
		currentLocationId = getUser(state).currentLocation.id,
		accountId = getAccountDetails(state).accountDetails.accountInfo.id,
		modalProps = getModalData(state).modalProps;

	return {
		adjustmentTypesData,
		adjustmentServicelinesData,
		currentLocationId,
		accountId,
		modalProps
	};
};

const mapDispatchToProps = {
	getAdjustmentTypes,
	getAccountServicelines,
	createImmediatAdjustment,
	createPendingAdjustment,
	updatePendingAdjustment,
	hideModal,
	emptyAccountServicelines,
	emptyAdjustmentTypes
};

export default connect(mapStateToProps, mapDispatchToProps)(AddAdjustmentModal);
