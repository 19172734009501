import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
	getNotificationCategories,
	emptyNotificationCategories,
	subscribeForNotification,
	unsuscribeFromNotification,
	updateNotificationAccountAlert,
	getAccountNotifications
} from '../../../../../../actions/accountDetailsActions/notifications.actions';
import { getAccountDetails, createLoadingSelector } from '../../../../../../selectors';
import {showModal} from '../../../../../../actions/modal.actions';

import NotificationCard from './NotificationCard';
import NotificationAlertBox from './NotificationAlertBox';
import NotificationFiltersBox from './NotificationFiltersBox';
import NotificationCategories from './NotificationCategories';
import moment from "moment";

class Notifications extends Component {
	state = {
		isNotificationCategoriesLoaded: false
	};

	componentDidMount() {
		const { accountId } = this.props;
		this.props.getNotificationCategories(accountId);
	}

	componentDidUpdate(prevProps) {
		if (
			prevProps.notificationCategoriesData !== this.props.notificationCategoriesData &&
			this.state.isNotificationCategoriesLoaded === false
		) {
			this.setState({
				isNotificationCategoriesLoaded: true
			});
		}
	}

	componentWillUnmount() {
		this.props.emptyNotificationCategories();
	}

	handleNotificationSubscribe = (data) => {
		const { accountId } = this.props;
		this.props.subscribeForNotification(accountId, data);
	};

	handleNotificationUnsubscribe = (data) => {
		const { accountId } = this.props;
		this.props.unsuscribeFromNotification(accountId, data);
	};

	render() {
		const { isNotificationCategoriesLoaded } = this.state;
		const { notificationCategoriesData, notificationCategoriesLoader, accountId, updateNotificationAccountAlert, notifications, getAccountNotifications, notificationAlert, showModal } = this.props;

		return (
			<div
				className="tab-pane fade show active"
				id="account-notifications"
				role="tabpanel"
				aria-labelledby="account-notifications-tab"
			>
				<div className="container-client-notifications">
					<NotificationCategories
						notificationCategoriesData={notificationCategoriesData}
						handleNotificationSubscribe={this.handleNotificationSubscribe}
						handleNotificationUnsubscribe={this.handleNotificationUnsubscribe}
						isNotificationCategoriesLoaded={isNotificationCategoriesLoaded}
						notificationCategoriesLoader={notificationCategoriesLoader}
					/>

					<hr />

					<NotificationAlertBox
						accountId = {accountId}
						updateNotificationAccountAlert = {updateNotificationAccountAlert}
						notificationAlert = {notificationAlert}
					/>
					<hr />

					<NotificationFiltersBox
						notifications={notifications}
						getAccountNotifications={getAccountNotifications}
						accountId={accountId}
						showModal={showModal}
					/>
				</div>
			</div>
		);
	}
}

Notifications.propTypes = {
	accountId: PropTypes.number
};

const getNotificationCategoriesLoader = createLoadingSelector([ 'SET_NOTIFICATION_CATEGORIES' ]);

const mapStateToProps = (state, ownProps) => {
	const notificationCategoriesData = getAccountDetails(state).notifications.notificationCategories,
		notificationCategoriesLoader = getNotificationCategoriesLoader(state),
		notificationAlert = getAccountDetails(state).notifications.accountAlert,
		notifications = getAccountDetails(state).notifications.notifications;

	return {
		notificationCategoriesData,
		notificationCategoriesLoader,
		notificationAlert,
		notifications
	};
};

const mapDispatchToProps = {
	getNotificationCategories,
	emptyNotificationCategories,
	subscribeForNotification,
	unsuscribeFromNotification,
	updateNotificationAccountAlert,
	getAccountNotifications,
	showModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
