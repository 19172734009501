import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory, {
	PaginationListStandalone,
	SizePerPageDropdownStandalone,
	PaginationProvider,
	PaginationTotalStandalone
} from 'react-bootstrap-table2-paginator';
import moment from 'moment';
import { connect } from 'react-redux';
import { getPaymentPromises, emptyPaymentPromises } from '../../../../../../actions/accountDetailsActions/billing.actions';
import { showModal } from '../../../../../../actions/modal.actions';
import { getAccountDetails, createLoadingSelector } from '../../../../../../selectors';
import Loader from '../../../../../../components/Loader';
import { isEmpty } from '../../../../../../utils/helpers';

const { SearchBar } = Search;

class PaymentPromises extends Component {
	state = {};

	componentDidMount() {
		const { accountInfo } = this.props;
		this.props.getPaymentPromises(accountInfo.id);
	}

	componentWillUnmount() {
		this.props.emptyPaymentPromises();
	}

	showDeleteModal = (reccuringCreditId, description) => {
		const { accountInfo } = this.props;
		const info = {
			accountId: accountInfo.id,
			itemId: reccuringCreditId,
			description: description,
			type: 'reccuring'
		};
		this.props.showModal('GENERIC_DELETE_BILING_MODAL', info);
	};

	promisedUntilFormater = (cell, row) => {
		if (row) {
			if (moment() < moment(row.promisedUntil)) {
				return (
					<Fragment>
						{moment(cell).format('MM/DD/YYYY')}{' '}
						<span className="td-subtitle">
							Primised {moment(row.promisedUntil).diff(moment(), 'days')} days
						</span>
					</Fragment>
				);
			} else {
				return <Fragment>{moment(cell).format('MM/DD/YYYY')} </Fragment>;
			}
		}
	};
	amountFormater = (cell, row) => {
		if (row) {
			if(moment() < moment(row.promisedUntil)){
				return (
					<Fragment>
						${cell.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
						<span className="td-subtitle">{row.days} days left</span>
					</Fragment>
				);
			}else {
				return (
					<Fragment>
						${cell.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
					</Fragment>
				);
			}
			
		}
	};

	promisedDateFormater = (cell, row) => {
		if (row) {
			return (
				<Fragment>
					{moment(cell).format('MM/DD/YYYY')} <span className="td-subtitle">{row.createdBy}</span>
				</Fragment>
			);
		}
	};

	statusFormater = (cell, row) => {
		if (row) {
			if (moment() < moment(row.promisedUntil)) {
				return (
					<Fragment>
						<span className="badge badge-success">Active</span>
						<span className="td-subtitle" />
					</Fragment>
				);
			} else {
				return (
					<Fragment>
						<span className="badge badge-secondary">Inactive</span>
						<span className="td-subtitle" />
					</Fragment>
				);
			}
		}
	};

	noteFormater = (cell, row, rowIndex) => {
		if (cell !== 'undefined') {
			return <div className="td-note">{cell}</div>;
		}
	};

	customTotal = (from, to, size) => (
		<span className="react-bootstrap-table-pagination-total">
			Showing {from} to {to} of {size} Results
		</span>
	);

	render() {
		const { paymentPromisesData, paymentPromisesLoader } = this.props;

		if (isEmpty(paymentPromisesData) && paymentPromisesLoader) {
			return <Loader />;
		}

		const options = {
			paginationSize: 4,
			custom: true,
			totalSize: paymentPromisesData.length,
			pageStartIndex: 1,
			// alwaysShowAllBtns: true, // Always show next and previous button
			// withFirstAndLast: false, // Hide the going to First and Last page button
			// hideSizePerPage: true, // Hide the sizePerPage dropdown always
			hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
			firstPageText: 'First',
			prePageText: 'Previous',
			nextPageText: 'Next',
			lastPageText: 'Last',
			// nextPageTitle: 'First page',
			// prePageTitle: 'Pre page',
			// firstPageTitle: 'Next page',
			// lastPageTitle: 'Last page',
			showTotal: true,
			paginationTotalRenderer: this.customTotal,
			sizePerPageList: [
				{
					text: '10',
					value: 10
				},
				{
					text: '25',
					value: 25
				},
				{
					text: '50',
					value: 50
				},
				{
					text: '100',
					value: 100
				},
				{
					text: 'All',
					value: paymentPromisesData.length
				}
			] // A numeric array is also available. the purpose of above example is custom the text
		};

		const columns = [
			{
				dataField: 'promisedUntil',
				text: 'Promised Until',
				sort: true,
				formatter: this.promisedUntilFormater
			},
			{
				dataField: 'amount',
				text: 'Promised amount',
				sort: true,
				formatter: this.amountFormater
			},
			{
				dataField: '',
				text: '',
				formatter: this.statusFormater
			},
			{
				dataField: 'promisedDate',
				text: 'Promised Date',
				sort: true,
				formatter: this.promisedDateFormater,
				classes: 'h-align-r',
				headerClasses: 'h-align-r'
			},
			{
				dataField: 'note',
				text: '',
				formatter: this.noteFormater,
				classes: (cell, row, rowIndex, colIndex) => {
					if (row.note !== 'undefined') return 'with-note';
					return '';
				}
			},
		];

		const defaultSorted = [
			{
				dataField: 'promisedUntil',
				order: 'desc'
			}
		];

		const rowStyle = (row, rowIndex) => {
			if (row) {
				let classes = null;

				if (row.note) {
					classes = 'has-note';
				}

				if (rowIndex % 2 === 0) {
					classes += ' odd';
				} else {
					classes += ' even';
				}

				return classes;
			}
		};

		return (
			<Fragment>
				<div className="cmv-container-table">
					<PaginationProvider pagination={paginationFactory(options)}>
						{({ paginationProps, paginationTableProps }) => (
							<Fragment>
								<ToolkitProvider
									bootstrap4
									keyField="id"
									data={paymentPromisesData}
									columns={columns}
									defaultSorted={defaultSorted}
									search
								>
									{(props) => (
										<Fragment>
											<div className="row">
												<div className="col-sm-12 col-md-6">
													<label>
														Show <SizePerPageDropdownStandalone {...paginationProps} />{' '}
														entries
													</label>
												</div>
												<div className="col-sm-12 col-md-6" style={{ textAlign: 'right' }}>
													<label>
														<button
															onClick={() =>
																this.props.showModal('PAYMENT_PROMISES_MODAL', {
																	id: 'create'
																})}
															className="btn btn-primary"
															style={{ margin: '5px' }}
														>
															<i className="fas fa-plus" /> New
														</button>
													</label>
												</div>
											</div>
											<div className="row">
												<BootstrapTable
													wrapperClasses="table-responsive react-table-layout"
													classes="table table-striped cmv-table"
													bordered={false}
													noDataIndication="No data found"
													rowClasses={rowStyle}
													{...paginationTableProps}
													{...props.baseProps}
												/>
											</div>
											<div className="row" style={{ marginTop: '10px' }}>
												<div className="col-sm-12 col-md-5">
													<PaginationTotalStandalone {...paginationProps} />
												</div>
												<div className="col-sm-12 col-md-7">
													<PaginationListStandalone {...paginationProps} />
												</div>
											</div>
										</Fragment>
									)}
								</ToolkitProvider>
							</Fragment>
						)}
					</PaginationProvider>
				</div>
			</Fragment>
		);
	}
}

PaymentPromises.propTypes = {
	accountInfo: PropTypes.object.isRequired
};

const loader = createLoadingSelector([ 'SET_PAYMENT_PROMISES' ]);

const mapStateToProps = (state) => {
	const paymentPromisesData = getAccountDetails(state).billing.paymentPromises,
		paymentPromisesLoader = loader(state);

	return {
		paymentPromisesData,
		paymentPromisesLoader
	};
};

const mapDispatchToProps = {
	getPaymentPromises,
	showModal,
	emptyPaymentPromises
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentPromises);
