import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { getAuthData, getErrorMessage } from '../../selectors';
import { hideModal } from '../../actions/modal.actions';
import { login, logout, forgottenPassword } from '../../actions/auth.actions';
import { isEmpty } from '../../utils/helpers';
import Footer from '../Layout/Footer';

const loginSchema = Yup.object().shape({
	username: Yup.string().min(3, 'Min 3 characters').required('Required'),
	password: Yup.string().min(6, 'Min 6 characters').required('Required')
});

class Login extends Component {
	state = {
		showSuccessfullPasswordReset: false,
		showPermissionErrorMessage: false,
		isStaySignIn: false,
		passwordShown: false
	};

	// Password toggle handler
	togglePassword=() => {
		// When the handler is invoked
		// inverse the boolean state of passwordShown
		this.setState({passwordShown: !this.state.passwordShown});
	};
	componentDidMount() {
		if (this.props.location.state && this.props.location.state.permissionError) {
			this.setState({
				showPermissionErrorMessage: true
			});
		}
		if (this.props.isLoggedIn) {
			this.props.logout();
		}
		this.props.hideModal();
		if (!isEmpty(this.props.location.state)) {
			if (this.props.location.state.succesfulResetPassword === true) {
				this.setState({
					showSuccessfullPasswordReset: true
				});
			}
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.isLoggedIn !== this.props.isLoggedIn && this.props.isLoggedIn === true) {
			const location = this.props.history.location;

			if (location.state && location.state.from) {
				this.props.history.push(location.state.from);
			} else {
				this.props.history.push('/home');
			}
		}
	}

	handleStaySignIn = (e) => {
		this.setState({
			isStaySignIn: e.target.checked
		});
	};

	handleOnSubmit = (values, actions) => {
		if (this.state.isStaySignIn) {
			localStorage.setItem('stayIn', true);
		} else {
			localStorage.removeItem('stayIn');
		}

		this.props.login(values.username, values.password, this.props.history).then(() => {
			actions.setSubmitting(false);
		});
	};


	render() {
		const { showSuccessfullPasswordReset, showPermissionErrorMessage, isStaySignIn, passwordShown } = this.state;
		const { authErrorMessage } = this.props;




		return (
			<div className="cmv-page cmv-page-login">
				<div className="page-wrapper">
					<div className="page-content">
						<main>
							<div className="col col-sm-8 col-md-6 col-lg-5 col-xl-3">
								<div className="card-login">
									<div className="card-login-header">
										<div className="brand-container" />
										<h3>Sign In</h3>
										{showSuccessfullPasswordReset && <p>Your new password has been saved.</p>}
										{showPermissionErrorMessage && <p style={{ color: 'red' }}>Not enough permissions to access.</p>}
										<div className="lds-dual-ring" />
									</div>
									<div className="card-login-body">
										<Formik
											initialValues={{
												username: '',
												password: ''
											}}
											validationSchema={loginSchema}
											onSubmit={this.handleOnSubmit}
											render={({
												handleChange,
												handleSubmit,
												handleBlur,
												values,
												errors,
												touched,
												isSubmitting
											}) => (
												<Form onSubmit={handleSubmit} className="needs-validation">
													<div className="form-label-group">
														<input
															type="text"
															className={
																errors.username && touched.username ? (
																	'form-control is-invalid'
																) : (
																	'form-control'
																)
															}
															id="username"
															placeholder="Username or Email"
															name="username"
															value={values.username}
															onChange={handleChange}
															onBlur={handleBlur}
														/>
														<label htmlFor="username">Username or Email</label>

														{errors.username &&
														touched.username && (
															<div className="invalid-feedback">{errors.username}</div>
														)}
													</div>
													<div className="form-label-group">
														<input
															type={passwordShown ? "text" : "password"}
															className={
																errors.password && touched.password ? (
																	'form-control is-invalid'
																) : (
																	'form-control'
																)
															}
															id="password"
															placeholder="Password"
															name="password"
															value={values.password}
															onChange={handleChange}
															onBlur={handleBlur}
														/>
														<label htmlFor="password">Password</label>
														{errors.password &&
														touched.password && (
															<div className="invalid-feedback">{errors.password}</div>
														)}
													</div>

													{authErrorMessage.length > 0 && (
														<p style={{ color: 'red' }}>{authErrorMessage}</p>
													)}

													<div className="form-check checkbox-slider checkbox-slider--b-flat">
														<label>
															<input type="checkbox" onClick={this.togglePassword}/>
																<span>Show Password</span>
														</label>
													</div>
													<div className="form-group">
														<button
															type="submit"
															id="sendlogin"
															className="btn btn-block btn-primary"
															disabled={isSubmitting}
														>
															Sign In {isSubmitting && <i className="fas fa-fw fa-spin fa-spinner" />}
														</button>
													</div>

													<div className="form-group d-flex align-content-between flex-wrap">
														<a
															className="mt-1 mb-1"
															onClick={() => this.props.history.push('/forgotenpassword')}
														>
															Forgot password?
														</a>
													</div>

													{isMobile && (
														<div className="form-check checkbox-slider checkbox-slider-sm checkbox-slider--b-flat">
															<label>
																<input
																	type="checkbox"
																	name="noAddress"
																	checked={isStaySignIn}
																	onChange={this.handleStaySignIn}
																/>
																<span className="text-xs text-muted">
																	Stay signed in
																</span>
															</label>
														</div>
													)}

												</Form>
											)}
										/>
									</div>
								</div>
							</div>
						</main>
						<Footer />
					</div>
				</div>
			</div>
		);
	}
}

Login.propTypes = {
	isLoggedIn: PropTypes.bool,
	authErrorMessage: PropTypes.string
};

const getAuthError = getErrorMessage([ 'AUTH' ]);

const mapStateToProps = (state) => {
	const isLoggedIn = getAuthData(state).isLoggedIn,
		authErrorMessage = getAuthError(state);
	return {
		isLoggedIn,
		authErrorMessage
	};
};

const mapDispatchToProps = {
	login,
	logout,
	hideModal,
	forgottenPassword
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
