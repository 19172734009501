import {isEmpty} from "lodash";

let backendHost;

const hostname = window && window.location && window.location.hostname;

if(hostname === 'localhost') {
  backendHost = 'https://dev-api.camvio.cloud/aboss-api/rest/v1';
}else {
    let splittedHostname = hostname.split('.');
    splittedHostname[0] = splittedHostname[0] + '-api'
    let splitedHostnameToString = splittedHostname.join('.')
    backendHost = `https://${splitedHostnameToString}/aboss-api/rest/v1`;
}

if (!isEmpty(window.overrideBackendHost)) {
   backendHost = window.overrideBackendHost;
}

export const API_ROOT = `${backendHost}`;