import React, { Component } from 'react';
import OptionalFeature from './Options/OptionalFeature';

class OptionalFeatures extends Component {

	render() {

		const { planFeature, accountInitialStatus, errors, touched, lineInfo } = this.props;

		return (
			<div className="plan-section">
				<h5>Optional Features</h5>
				<div className="package-wrapper" data-package-wrapper-id="pl001-of001">
					{planFeature.map((feature) => (
						<OptionalFeature
							originalFeature={feature}
							lineFeature={lineInfo.features && lineInfo.features.filter((x) => x.id === feature.id)[0]}
							lineInfo={lineInfo}
							errors={errors}
							touched={touched}
							accountInitialStatus={accountInitialStatus}
							featureIndex={lineInfo.features ? lineInfo.features.findIndex((x) => x.id === feature.id) : -1}
							{...this.props}
						/>
					))}
				</div>
			</div>
		);
	}
}

export default OptionalFeatures;
