import axiosInstance from '../../utils/axios-instance';
import {actionCreator, createRequestTypes, getErrorMessage} from '../../utils/helpers';

export const SET_UPDATE_REQUEST_PLAN_DETAILS = createRequestTypes('SET_UPDATE_REQUEST_PLAN_DETAILS');
export const SET_UPDATE_REQUEST_FEATURES = createRequestTypes('SET_UPDATE_REQUEST_FEATURES');
export const SET_UPDATE_CHANGE_SERVICE = createRequestTypes('SET_UPDATE_CHANGE_SERVICE');

export const updateChangeService = (data) => async (dispatch) => {
	dispatch(actionCreator.request(SET_UPDATE_CHANGE_SERVICE));
	try {
		const response = await axiosInstance.post(`/order/changeservice`, data);

		dispatch(actionCreator.success(SET_UPDATE_CHANGE_SERVICE, response.data));
		return response.data;
	} catch (err) {
		let mockError = {
			message: 'Not found'
		};
		dispatch(actionCreator.failure(SET_UPDATE_CHANGE_SERVICE, mockError));
	}
};

export const getPlanDetails = (accountId, locationId, planId, serviceLineId) => async (dispatch) => {
	dispatch(actionCreator.request(SET_UPDATE_REQUEST_PLAN_DETAILS));
	let data = {
		accountId,
		planId,
		serviceLineId,
		fromCustomerPortal: false
	};
	try {
		const response = await axiosInstance.get(`/domain/plan`, { params: data });

		dispatch(actionCreator.success(SET_UPDATE_REQUEST_PLAN_DETAILS, response.data));
		return response.data;
	} catch (err) {
		dispatch(actionCreator.failure(SET_UPDATE_REQUEST_PLAN_DETAILS, getErrorMessage(err)));
	}
};

export const emptyPlanDetails = () => {
	return (dispatch) => {
		dispatch(actionCreator.empty(SET_UPDATE_REQUEST_PLAN_DETAILS));
	};
};



export const getFeatures = (accountId, servicelineId) => async (dispatch) => {
	dispatch(actionCreator.request(SET_UPDATE_REQUEST_FEATURES));
	try {
		const response = await axiosInstance.get(`/account/${accountId}/serviceline/${servicelineId}/features/active`);

		dispatch(actionCreator.success(SET_UPDATE_REQUEST_FEATURES, response.data.features));
		return response.data.features;
	} catch (err) {
		let mockError = {
			message: 'Not found'
		};
		dispatch(actionCreator.failure(SET_UPDATE_REQUEST_FEATURES, mockError));
	}
}

export const emptyFeatures = () => {
	return (dispatch) => {
		dispatch(actionCreator.empty(SET_UPDATE_REQUEST_FEATURES));
	};
};