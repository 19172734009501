import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { hideModal } from '../../../actions/modal.actions';
import {getModalData} from '../../../selectors/index';
import BarcodeScanner from "react-qr-barcode-scanner";
import Loader from "../../../components/Loader";

class BarcodeScannerModal extends Component {

    state = {
        videoStarted: false,
        barcodeValue: null,
        stopStream: false,
    }

    closeModal = () => {
        this.setState({stopStream: true}, () => {

            setTimeout(() => {
                this.props.hideModal();

                if (this.props.modalProps.onCloseCallback) {
                    this.props.modalProps.onCloseCallback(this.state.barcodeValue);
                }
            }, 0);
        });
    }

    render() {

        const {
            videoStarted,
            barcodeValue,
            stopStream,
        } = this.state;

        const {
            modalProps,
        } = this.props;

        return (
            <Fragment>
                <div className="modal" style={{display: 'block'}} tabIndex="-1" role="dialog">
                    <div className="modal-dialog">
                        <div className="modal-content">

                            <div className="modal-header">
                                <h5 className="modal-title">
                                    Scan Barcode
                                </h5>
                                <button onClick={this.closeModal} type="button" className="close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>

                            <div className="modal-body modal-body-full">

                                <div className="barcode-scanner">

                                    {!videoStarted
                                        ?
                                        <Loader />
                                        :
                                        <div className="barcode-scanner-overlay">
                                            {barcodeValue === null
                                                ?
                                                <p>Scanning Barcode...<i className="fa fa-spinner fa-spin" /></p>
                                                :
                                                <p><i className="fa fa-check-circle text-success" />Barcode Scanned: {barcodeValue}</p>
                                            }
                                        </div>
                                    }

                                    <div className={"barcode-scanner-video" + (videoStarted ? " barcode-scanner-video-started" : "")}>
                                        <BarcodeScanner
                                            delay={0}
                                            stopStream={stopStream}
                                            onUpdate={(err, result) => {
                                                this.setState({
                                                    videoStarted: true,
                                                    barcodeValue: result ? result.text : this.state.barcodeValue
                                                });
                                            }}
                                        />
                                    </div>

                                </div>


                            </div>

                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn"
                                    onClick={this.props.hideModal}
                                >
                                    Cancel
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={this.closeModal}
                                    disabled={barcodeValue === null}
                                >
                                    Accept Value
                                </button>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="modal-backdrop show" tabIndex="1"/>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    const modalProps = getModalData(state).modalProps

    return {
        modalProps
    };
};

const mapDispatchToProps = {
    hideModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(BarcodeScannerModal);
