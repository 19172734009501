import React, {Component, Fragment} from 'react';
import Bundle from '../../../../components/UI/NewOrder/Bundle';
import Plan from '../../../../components/UI/NewOrder/Plan';

import {isEmpty} from '../../../../utils/helpers';
import Loader from '../../../../components/Loader';
import {createLoadingSelector, getCreateServiceRequest, getDomain} from "../../../../selectors";
import {
    addPlans,
    createNewOrder, emptyMapLinkLocation,
    emptyNewOrder, emptyPlanDetails, getMapLinkLocation,
    getPlansAndBundles,
    getPlansDetails, populateMapLocation, startServiceOrder
} from "../../../../actions/createServiceRequest.actions";
import {hideModal, showModal} from "../../../../actions/modal.actions";
import {getTasksByOrderId} from "../../../../actions/tasksList.actions";
import {connect} from "react-redux";
import Select from "react-select";
import {groupStyles} from "../../../../utils/SelectStyles";
import {getPlanCategories} from "../../../../actions/domain.actions";

class BundlesAndPlans extends Component {

    componentDidMount() {
        this.props.getPlanCategories();
    }

    state = {
        selectedPlanCategoryId: null
    };

    setSelectedItem = (item, type) => {
        let allPlans = [];
        if (type === 'bundles') {
            item.plans.forEach((plan) => {
                let currentPlan = {
                    planBundleId: item.id,
                    planBundleDescription: item.description,
                    planBundleName: item.name,
                    planDescription: plan.description,
                    planId: plan.id,
                    planName: plan.name,
                    servicelines: []
                };

                allPlans.push(currentPlan);
            });
        } else if (type === 'plans') {
            let currentPlan = {
                planDescription: item.description,
                planId: item.id,
                planName: item.name,
                servicelines: []
            };

            allPlans.push(currentPlan);
        }

        this.props.setFieldValue('plans', allPlans);

        this.props.selectPlansItems(item, type);
    };

    renderBundles = () => {
        let showBundles = !isEmpty(this.props.planBundles);
        let showPlans = !isEmpty(this.props.plans);

        if (!showBundles) {
            return null;
        }

        return (
            <div className="accordion">
                <h3
                    className={showPlans ? this.props.collapsedMenuItem === 'planBundles' ? '' : 'collapsed' : ''}
                    onClick={() => this.props.setCollapsedMenuItem(showPlans ? 'planBundles' : null)}
                    data-toggle="collapse"
                >
                    Available Bundles
                </h3>
                <div id="available-bundles-content"
                     className={this.props.collapsedMenuItem === 'planBundles' || !showPlans ? 'collapse show' : 'collapse'}
                >
                    {this.props.planBundles.map((bundle) => {
                        // if (selectedType !== 'plans') {
                        return (
                            <Bundle
                                setSelectedItem={this.setSelectedItem}
                                selectedItem={this.props.selectedItem}
                                selectedType={this.props.selectedType}
                                currentItem={bundle}
                                key={bundle.id}
                            />
                        );
                        // }
                        return null;
                    })}
                </div>
            </div>
        );
    };

    renderPlans = () => {
        let showBundles = !isEmpty(this.props.planBundles);
        let showPlans = !isEmpty(this.props.plans);

        if (!showPlans) {
            return null;
        }

        return (
            <div className="accordion">
                <h3
                    className={showBundles ? this.props.collapsedMenuItem === 'plans' ? '' : 'collapsed' : ''}
                    onClick={() => this.props.setCollapsedMenuItem(showBundles ? 'plans' : null)}
                >
                    Available Plans
                </h3>
                <div id="available-plans-content"
                     className={this.props.collapsedMenuItem === 'plans' || !showBundles ? 'collapse show' : 'collapse'}
                >
                    {this.props.plans.map((plan) => {
                        // if (selectedType !== 'bundles') {
                        return (
                            <Plan
                                setSelectedItem={this.setSelectedItem}
                                selectedItem={this.props.selectedItem}
                                selectedType={this.props.selectedType}
                                currentItem={plan}
                                key={plan.id}
                            />
                        );
                        // }
                        return null;
                    })}
                </div>
            </div>
        );
    }

    changePlanCategory = (type) => {
        if (this.state.selectedPlanCategoryId === type.value) {
            return;
        }

        this.setState({
            selectedPlanCategoryId: type
        });
        this.searchPlansAndBundles(type.value);
    };

    renderPlanCategory = () => {
        const options = [];
        this.props.planCategories.forEach(category => {
            options.push({label: category.description, value: category.id});
        });

        return (
            <div id="plan-category-content" className="available-services-select">
                <label>Plan Category</label>
                <Select
                    value={this.state.selectedPlanCategoryId}
                    onChange={this.changePlanCategory}
                    options={options}
                    placeholder="Select Plan Category"
                    styles={groupStyles}
                />
            </div>
        );
    }

    searchPlansAndBundles = (planCategoryId) => {
        const {values} = this.props;

        const address = {
            fullAddress: values['fullAddress']
        };

        this.props.getPlansAndBundles(this.props.currentLocation.id, address, planCategoryId);
    };

    render() {
        const {
            planBundles,
            plans,
            validatedAddress,
            loaderPlansAndBundles,
            loaderPlanCategories,
            selectedAddress,
            addressType
        } = this.props;

        if (loaderPlanCategories) {
            return <Loader/>
        }

        let displayAddress = selectedAddress;
        if(addressType != "manualAddress"){
            displayAddress = validatedAddress.formatedAddress;
        }

        return (
            <div className="available-services" id="available-services">

                <h4 className="available-services-address">
                    <i className="fas fa-map-marked-alt" />&nbsp;{displayAddress}
                </h4>

                {this.renderPlanCategory()}

                {loaderPlansAndBundles === true
                ? <Loader/>
                : (isEmpty(planBundles) && isEmpty(plans) && this.state.selectedPlanCategoryId !== null)
                    ?
                    <div className="collapse show" id="available-plans-content">
                        <p>No record found for selected location and category.</p>
                    </div>
                    :
                    <>
                        {this.renderBundles()}
                        {this.renderPlans()}
                    </>
                }
            </div>
        )
    }
}

const getLoaderPlansAndBundles = createLoadingSelector(['PLANS_AND_BUNDLES']);
const getLoaderPlanCategories = createLoadingSelector(['GET_PLAN_CATEGORIES']);

const mapStateToProps = (state) => {
    const planBundles = getCreateServiceRequest(state).planBundles,
        plans = getCreateServiceRequest(state).plans,
        plansDetails = getCreateServiceRequest(state).plansDetails,
        validatedAddress = getCreateServiceRequest(state).validatedAddress,
        planCategories = getDomain(state).planCategories,
        loaderPlansAndBundles = getLoaderPlansAndBundles(state),
        loaderPlanCategories = getLoaderPlanCategories(state);

    return {
        planBundles,
        plans,
        plansDetails,
        validatedAddress,
        planCategories,
        loaderPlansAndBundles,
        loaderPlanCategories
    };
};

const mapDispatchToProps = {
    createNewOrder,
    getPlansAndBundles,
    getPlansDetails,
    emptyNewOrder,
    emptyPlanDetails,
    showModal,
    hideModal,
    addPlans,
    getTasksByOrderId,
    startServiceOrder,
    getMapLinkLocation,
    populateMapLocation,
    emptyMapLinkLocation,
    getPlanCategories
};

export default connect(mapStateToProps, mapDispatchToProps)(BundlesAndPlans);
