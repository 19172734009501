import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { getModalData, createLoadingSelector } from '../../../selectors';
import { hideModal } from '../../../actions/modal.actions';
import { createNewQuote } from '../../../actions/createServiceRequest.actions';

class QuoteModal extends Component {
	state = {
		quoteName: '',
		quoteNote: ''
	};

	onChangeHandler = (e) => {
		this.setState({
			[e.target.name]: e.target.value
		});
	};

	saveQuotes = () => {
		const orderData = this.props.modalProps;

		let updatedOrderData = { ...orderData };
		updatedOrderData['name'] = this.state.quoteName;
		updatedOrderData['note'] = this.state.quoteNote;
		updatedOrderData['address'] = this.props.modalProps.validatedAddress.addressSearchFormated;
		updatedOrderData['type'] = this.props.modalProps.validatedAddress.allowedServiceOrderType;
		updatedOrderData['validatedAddress'] = null;

		this.props.createNewQuote(updatedOrderData);
	};

	render() {
		return (
			<Fragment>
				<div className="modal show" tabIndex="-1" style={{ display: 'block' }}>
					<div className="modal-dialog" role="document">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">New Quote Details</h5>
								<button type="button" className="close" onClick={this.props.hideModal}>
									<span aria-hidden="true">&times;</span>
								</button>
							</div>
							<div className="modal-body cmv-form">
								<div className="form-group">
									<label>Quote Name</label>
									<input
										type="text"
										name="quoteName"
										className="form-control"
										placeholder="Quote Name"
										value={this.state.quoteName}
										onChange={this.onChangeHandler}
									/>
								</div>
								<div className="form-group">
									<label>Quote Note</label>
									<textarea
										name="quoteNote"
										className="form-control"
										placeholder="Quote Note"
										value={this.state.quoteNote}
										onChange={this.onChangeHandler}
									/>
								</div>
							</div>
							<div className="modal-footer">
								<button className="btn" onClick={this.props.hideModal}>
									Cancel
								</button>
								<button
									onClick={this.saveQuotes}
									className="btn btn-primary"
									disabled={this.props.newOrderLoader}
								>
									Save Quote
								</button>
							</div>
						</div>
					</div>
				</div>
				<div className="modal-backdrop show" tabIndex="1" />
			</Fragment>
		);
	}
}
const setNewOrderLoader = createLoadingSelector([ 'CREATE_ORDER' ]);

const mapStateToProps = (state) => {
	const modalProps = getModalData(state).modalProps,
		newOrderLoader = setNewOrderLoader(state);

	return {
		modalProps,
		newOrderLoader
	};
};
const mapDispatchToProps = {
	hideModal,
	createNewQuote
};

export default connect(mapStateToProps, mapDispatchToProps)(QuoteModal);
