import React, {Component, Fragment} from 'react';
import Services from './Services';
import { connect } from 'react-redux';
import { parseDateTimeFormat } from '../utils';
import TargetDateTime from "../../../../../../../components/UI/TargetDateTime";
import {showModal} from "../../../../../../../actions/modal.actions";
import {getOrders} from '../../../../../../../actions/accountDetailsActions/ordersAndQuotes.actions';
import { get } from 'lodash';
import moment from "moment";
import isEmpty from "../../../../../../../utils/helpers";

export class Order extends Component {

	state = {
		isCancelled: false,
		isCompleted: false,
		isServiceExpanded: false,
		isExecuteButtonClicked: false,
	};

	componentDidMount() {
		const {history, orderInfo} = this.props;

		// Indicate cancelled/completed tasks
		this.setState({
			isCancelled: this.props.orderInfo.status === 'CANCELLED',
			isCompleted: this.props.orderInfo.progressPercentage === 100,
		});

		// Open order if coming here from New Order / Task Details
		if (history.location.state && history.location.state.orderId === orderInfo.id) {
			this.setState({isServiceExpanded: true});
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.orderInfo !== this.props.orderInfo) {

			// Update isCancelled and isCompleted state
			this.setState({
				isCancelled: this.props.orderInfo.status === 'CANCELLED',
				isCompleted: this.props.orderInfo.progressPercentage === 100,
			});

			if (this.props.orderInfo.isStarted === true && this.state.isExecuteButtonClicked === true) {
				this.setState({isExecuteButtonClicked: false});
			}
		}
	}

	handlerServiceExpand = () => {
		this.setState((prevState) => ({
			isServiceExpanded: !prevState.isServiceExpanded
		}));
	};

	handleExecute = (orderId) => {
		const { accountInfo } = this.props;

		let wasServiceExpanded = this.state.isServiceExpanded;

		this.setState({isExecuteButtonClicked: true}, () => {
			this.props.executeOrder(orderId, accountInfo.id).then((response) => {

				if (response.success) {

					// Close order details so we can reload them
					this.setState({
						isServiceExpanded: false,
						isExecuteButtonClicked: false,
					}, () => {

						// Reopen and reload order details if they were previously open
						this.setState({isServiceExpanded: wasServiceExpanded})
					});
				}
				else {
					this.setState({isExecuteButtonClicked: false})
				}
			});
		});
	};

	renderColorStatus = (hasErrorStatus, percentage) => {
		if (hasErrorStatus) {
			if (percentage === 100) {
				return 'progress-bar bg-danger';
			} else {
				return 'progress-bar bg-danger bg-danger-with-pulse';
			}
		} else {
			if (percentage === 100) {
				return 'progress-bar bg-success';
			} else {
				return 'progress-bar bg-success bg-success-with-pulse';
			}
		}
	};

	getAppointmentDateTime = (appointment) => {
		return moment().year(appointment.date.year).dayOfYear(appointment.date.dayOfYear).format("MM/DD/YYYY")
			+ ' @ ' +
			moment().hour(appointment.timeslot.fromTime.substring(0, 2)).minutes(appointment.timeslot.fromTime.substring(3, 5)).format('h:mmA')
			+ '-' +
			moment().hour(appointment.timeslot.toTime.substring(0, 2)).minutes(appointment.timeslot.fromTime.substring(3, 5)).format('h:mmA')
	};

	render() {

		const {
			isCancelled,
			isCompleted,
			isServiceExpanded,
			isExecuteButtonClicked,
			targetDateTime,
		} = this.state;

		const {
			orderInfo,
			hasCollapsedIcon,
			showServiceUpdateRequestWizardHandler,
			parentCallback,
			accountInfo,
			canCancelServiceOrders,
		} = this.props;

		const appointment = orderInfo.appointments && orderInfo.appointments.length > 0 && orderInfo.appointments[0];

		return (
			<div className="order-wrapper" data-order-id={orderInfo.id}>

				<div className="order-header row">
					<div className="col-12 col-sm-5 col-md-3">

						<div className="order-source">{orderInfo.source}</div>

						<div className="order-info">
							<span className="font-weight-bold">
								<i className="fas fa-fw fa-hashtag" />
								{orderInfo.id}
							</span>
							<span title={orderInfo.createdBy}>
								<i className="fas fa-fw fa-user" />
								{orderInfo.createdBy}
							</span>
							<span title={"Created: " + parseDateTimeFormat(orderInfo.createdAt)}>
								<i className="far fa-fw fa-clock" />
								Created: <span className="font-weight-bold">{parseDateTimeFormat(orderInfo.createdAt)}</span>
							</span>
							<span className="order-sales-agent">
								<i className="fas fa-briefcase" />
								Sales Agent:{' '}
								<span
									className="font-weight-bold btn-outline-secondary"
									onClick={() => this.props.showModal('SALES_AGENT_MODAL', {
										orderInfo: orderInfo,
										accountInfo: accountInfo,
										onCloseCallback: () => this.props.parentCallback()
									})}
								>
									{orderInfo.salesAgent ? orderInfo.salesAgent.firstName + " " + orderInfo.salesAgent.lastName : "Not provided"}
								</span>
							</span>
						</div>


					</div>
					<div className="col-12 col-sm-7 col-md-5">

						<div className="order-title">

							<span className="order-title-address text-muted d-block mb-1">
								{appointment && <span><i className="fas fa-clock"/> {this.getAppointmentDateTime(appointment)} </span>}
							</span>

							{orderInfo.serviceOrdersInfo.map((serviceOrder, index) =>
							<div className="order-title-service" key={index}>
								<p className="d-block">
									<i className={serviceOrder.icon} />&nbsp;{serviceOrder.description}
								</p>
								<span className="order-title-date">
									<TargetDateTime targetDateTime={serviceOrder.targetDateTime} />
								</span>
							</div>
							)}

						</div>

					</div>
					<div className="col-7 col-md-3">

						<div className="order-status">

							<div className="progress">
								{isCancelled
								?
									<div
										className="progress-bar bg-danger"
										role="progressbar"
										style={{ width: '100%' }}
										aria-valuenow="100"
										aria-valuemin="0"
										aria-valuemax="100"
									>
										{orderInfo.status}
									</div>
								:
								orderInfo.isStarted === true
								?
									<div
										className={this.renderColorStatus(
											orderInfo.hasServiceOrdersInErrorStatus,
											orderInfo.progressPercentage
										)}
										role="progressbar"
										style={{ width: orderInfo.progressPercentage + '%' }}
										aria-valuenow={orderInfo.progressPercentage}
										aria-valuemin="0"
										aria-valuemax="100"
									>
										{orderInfo.status}
									</div>
								:
									<div
										className="progress-bar"
										role="progressbar"
										style={{ width: '100%' }}
										aria-valuenow="100"
										aria-valuemin="0"
										aria-valuemax="100"
									>
										<button
											onClick={() => this.handleExecute(orderInfo.id)}
											className="btn btn-outline-primary btn-order-start-wf"
											disabled={isExecuteButtonClicked || !orderInfo.canStart}
										>
											<i className="fas fa-play-circle" />&nbsp;Execute
										</button>
									</div>
								}
							</div>

							{isCancelled
							?
							<span className="text-muted">
								<i className="fas fa-fw fa-ban text-danger" /> 0% Complete
							</span>
							:
							orderInfo.isStarted === true
							?
								<span className="text-muted">
									<i className={orderInfo.hasServiceOrdersInErrorStatus === true
										? 'fas fa-fw fa-exclamation-triangle text-danger'
										: orderInfo.progressPercentage !== 100
											? 'fas fa-fw fa-spin fa-cog text-success'
											: null}
									/>
									&nbsp;
									{orderInfo.progressPercentage !== 100
										? (orderInfo.progressPercentage + '%')
										: <i className="fas fa-fw fa-check text-success" />
									}
									{' '}
									{!isEmpty(orderInfo.modifiedDateTime) && (
										<small>{moment(orderInfo.modifiedDateTime).format('MM/DD/YYYY @ h:mmA')}&nbsp;&nbsp;</small>
									)}
									{!isEmpty(orderInfo.modifiedBy) && (
										<><i className="fas fa-user"/>&nbsp;<small>{orderInfo.modifiedBy}</small></>
									)}
									{/* SHOW DATETIME AND TECHNICIAN WHO COMPLETED ORDER
							    	{orderInfo.progressPercentage !== 100
									?
									(orderInfo.progressPercentage + '% Complete')
									:
									<div className="order-info">
										<span className="font-weight-bold">
											<i className="fas fa-fw fa-check text-success" />{parseDateTimeFormat(orderInfo.createdAt)}
										</span>
										<span>
											<i className="fas fa-fw fa-user" />{orderInfo.createdBy}
										</span>
									</div>
								}
								*/}
								</span>
							:
							orderInfo.canStart
							?
							<span className="text-muted">
								<i className="fas fa-fw fa-spin fa-spinner"/>&nbsp;Not yet started
							</span>
							:
							<span className="text-muted">
								<i className="fas fa-fw fa-ban"/>&nbsp;Cannot start - missing configuration
							</span>
							}

						</div>

					</div>
					<div className="col-5 col-md-1">

						<div className="order-controls">

								<button
									className={"btn btn-circle" + (appointment ? " active" : "")}
									disabled={isExecuteButtonClicked || isCancelled || isCompleted}
									onClick={() =>
										this.props.showModal('TECHNICIAN_APPOINTMENT_MODAL', {
											appointment_type: "ORDER",
											id: orderInfo.id,
											appointments: orderInfo.appointments,
											onCloseCallback: () => this.props.parentCallback()
										})
									}
								>
									<i className="far fa-clock"/>
								</button>

							{hasCollapsedIcon &&
								<button
									onClick={this.handlerServiceExpand}
									className="btn btn-circle btn-order-collapse-toggle"
								>
									<i className={isServiceExpanded === true ? 'fas fa-angle-double-up' : 'fas fa-angle-double-down'} />
								</button>
							}

						</div>

					</div>
				</div>

				{isServiceExpanded && hasCollapsedIcon &&
				<Services
					orderId={orderInfo.id}
					isStarted={orderInfo.isStarted}
					isCompleted={isCompleted}
					isExecuteButtonClicked={isExecuteButtonClicked}
					canCancelServiceOrders={canCancelServiceOrders}
					cancelServiceOrder={this.props.cancelServiceOrder}
					showServiceUpdateRequestWizardHandler={showServiceUpdateRequestWizardHandler}
					parentCallback={parentCallback}
					history={this.props.history}
				/>
				}

			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {};
};

const mapDispatchToProps = {
	showModal,
	getOrders,
};

export default connect(mapStateToProps, mapDispatchToProps)(Order);
