import React, {Component} from 'react';
import {connect} from 'react-redux';
import GroupNumbers from "./GroupNumbers";
import GroupDetails from "./GroupDetails";
import moment from "moment";
import {getProvisioningQueueGroup} from "../../actions/provisioning.actions";
import {getErrorMessage} from "../../utils/helpers";
import {toastr} from "react-redux-toastr";
import Loader from "../../components/Loader";
import _ from "lodash";

export class Group extends Component {

    state = {
        isNumbersVisible: false,
        isDetailsVisible: false,
        groupDetails: null,
    }

    toggleNumbers = () => {
        if (!this.state.isNumbersVisible && _.isNull(this.state.groupDetails)) {
            this.getProvisioningQueueGroupById(this.props.provisioningQueueGroup.id);
        }

        this.setState(() => ({
            isDetailsVisible: false,
            isNumbersVisible: !this.state.isNumbersVisible,
        }));
    }

    toggleDetails = () => {
        if (!this.state.isDetailsVisible) {
            this.getProvisioningQueueGroupById(this.props.provisioningQueueGroup.id);
        }

        this.setState(() => ({
            isNumbersVisible: false,
            isDetailsVisible: !this.state.isDetailsVisible,
        }));
    }

    getProvisioningQueueGroupById = (queueGroupId) => {
        const { getProvisioningQueueGroup } = this.props;

        if (this.state.loadingGroupDetails) {
            return;
        }

        this.setState({
            loadingGroupDetails: true,
        }, () =>
            getProvisioningQueueGroup(queueGroupId).then(resp => {
                if (resp.success || resp.data.success) {
                    this.setState({
                        groupDetails: resp.data.provisioningQueueGroup,
                        loadingGroupDetails: false,
                    });
                } else {
                    toastr.error(getErrorMessage(resp).message, {timeOut: 5000, position: 'top-center'});
                    this.setState({
                        loadingGroupDetails: false,
                    });
                }
            }).catch(error => {
                toastr.error(getErrorMessage(error).message, { timeOut: 5000, position: 'top-center' });
                this.setState({
                    loadingGroupDetails: false,
                });
            })
        );
    }

    renderGroupNumbers = () => {
        if (!this.state.isNumbersVisible) {
            return null;
        }

        if (this.state.loadingGroupDetails) {
            return <Loader />;
        }

        return this.state.groupDetails && <GroupNumbers numbers={this.state.groupDetails.groupNumbers}/>
    }

    renderGroupDetails = () => {
        if(!this.state.isDetailsVisible) {
            return null;
        }

        if (this.state.loadingGroupDetails) {
            return <Loader />;
        }

       return this.state.groupDetails && <GroupDetails items={this.state.groupDetails.groupItems}/>
    }

    render() {

        const {
            isNumbersVisible,
            isDetailsVisible,
        } = this.state;

        const {
            provisioningQueueGroup,
        } = this.props;

        return (
            <div className="provisioning-group">

                <div className="provisioning-group-header">
                    <div className="row">
                        <div className="col col-12 col-sm-4 col-md-3 col-xl-2">

                            <p className="provisioning-group-id">{provisioningQueueGroup.id}</p>

                            <p
                                className="provisioning-group-detail"
                                title={provisioningQueueGroup.createdBy}
                            >
								<i className="fas fa-fw fa-user" />
                                {provisioningQueueGroup.createdBy}
							</p>

                            <p
                                className="provisioning-group-detail"
                               title={moment(provisioningQueueGroup.createdDateTime).format("MM/DD/YYYY @ h:mmA")}
                            >
								<i className="far fa-fw fa-clock" />
                                {moment(provisioningQueueGroup.createdDateTime).format("MM/DD/YYYY @ h:mmA")}
							</p>

                        </div>
                        <div className="col col-12 col-sm-8 col-md-auto">

                            <h6 className="provisioning-group-title">
                                {provisioningQueueGroup.type}
                                <span
                                    className={"provisioning-group-status " + (
                                    provisioningQueueGroup.status === "NEW" ? "bg-light border-success" :
                                    provisioningQueueGroup.status === "PROCESSING" ? "bg-light-with-pulse border-success" :
                                    provisioningQueueGroup.status === "PROCESSED" ? "bg-success" :
                                    provisioningQueueGroup.status === "WARNING" ? "bg-warning" :
                                    provisioningQueueGroup.status === "ERROR" ? "bg-danger" :
                                    "bg-light"
                                    )}
                                >
                                    {provisioningQueueGroup.status}
                                </span>

                            </h6>
                            <p className="provisioning-group-number">
                                {provisioningQueueGroup.dn}
                            </p>

                        </div>
                        <div className="col col-12 col-md-auto">
                            <div className="provisioning-group-controls">

                                <button
                                    className="btn btn-circle"
                                    onClick={this.toggleNumbers}
                                >
                                    < i className="fas fa-fw fa-hashtag" />
                                </button>

                                <button
                                    className="btn btn-circle"
                                    onClick={this.toggleDetails}
                                >
                                    <i className={"fas" + (isDetailsVisible ? " fa-angle-double-up" : " fa-angle-double-down")}/>
                                </button>

                            </div>
                        </div>
                    </div>
                </div>

                {this.renderGroupNumbers()}
                {this.renderGroupDetails()}

            </div>
        );
    }
}

const mapStateToProps = (state) => {

    return {
    };
};

const mapDispatchToProps = {
    getProvisioningQueueGroup,
};

export default connect(mapStateToProps, mapDispatchToProps)(Group);
