import React, {Component} from 'react';
import BillingJobDetails from './BillingJobDetails';
import {connect} from "react-redux";
import Button from "../../components/Common/Buttons/Button";
import {showModal} from "../../actions/modal.actions";
import moment from "moment";

export class BillingJob extends Component {

    state = {
        isJobExpanded: false,
    }

    handleExpandJob = () => {

        this.setState(() => ({
            isJobExpanded: !this.state.isJobExpanded,
        }));
    }

    displayBillingJobMonitorModal = () => {
        this.props.showModal('BILLING_JOB_MONITOR_MODAL', {
            billingJob: this.props.billingJob,
        });

    }

    render() {
        const {
            isJobExpanded,
        } = this.state;

        const {
            billingJob,
        } = this.props;

        return (
            <div className="billing-job">

                <div className="billing-job-header">
                    <div className="row">
                        <div className="col-12 col-md-3 col-xxl-2">

                            <p className="billing-job-id">
                                <i className="fa fa-hashtag"/> {billingJob.id || "N/A"}
                            </p>

                            <p className="billing-job-date">
                                Invoice Date
                                <span>
                                    {billingJob.invoiceDate
                                        ? moment(billingJob.invoiceDate).format("MM/DD/YYYY")
                                        : "N/A"
                                    }
                                </span>
                            </p>

                        </div>
                        <div className="col-12 col-md-9 col-xxl-10">

                            <div className="billing-job-caption">

                                <div className="billing-job-progress">
                                    <h6 className={"billing-job-progress-status " + (
                                        billingJob.status === "TESTBILLING_SCHEDULED" ? "bg-light-with-pulse border-success"
                                        : billingJob.status === "TESTBILLING_STARTED" ? "bg-light-with-pulse border-success"
                                        : billingJob.status === "TESTBILLING_FINISHED" ? "bg-light border-success"
                                        : billingJob.status === "TESTBILLING_REJECTED" ? "bg-light"
                                        : billingJob.status === "TESTBILLING_CANCELED" ? "bg-light"
                                        : billingJob.status === "APPROVEBILLING_SCHEDULED" ? "bg-success-with-pulse"
                                        : billingJob.status === "APPROVEBILLING_STARTED" ? "bg-success-with-pulse"
                                        : billingJob.status === "APPROVEBILLING_FINISHED" ? "bg-success"
                                        : billingJob.status === "BILLING_FAILED" ? "bg-danger"
                                        : "")
                                    }>
                                        {billingJob.status.toLowerCase().replace("_", " ")}
                                    </h6>
                                    {(billingJob.stage || billingJob.stageProgress) &&
                                    <p className="billing-job-progress-stage">
                                        {billingJob.stage && <span>{billingJob.stage}</span>}
                                        {billingJob.stageProgress && <span>{billingJob.stageProgress * 100}%</span>}
                                    </p>
                                    }
                                </div>

                                <div className="billing-job-details">
                                    <p className="billing-job-details-accounts">
                                        {billingJob.processedAccounts
                                            ? billingJob.processedAccounts +
                                                (billingJob.processedAccounts > 1 ? " Accounts" : " Account")
                                            : "N/A Accounts"
                                        }
                                    </p>
                                    <p className="billing-job-details-amount">
                                        $ {billingJob.totalInvoiceAmount || "N/A"}
                                    </p>
                                </div>

                                <div>
                                    <span className="d-flex text-muted">
                                        <small className="d-md-inline-block ml-auto">
                                            <Button
                                                onClick={this.displayBillingJobMonitorModal}
                                                className="btn btn-xs btn-order-log-toggle btn-circle"
                                                type="button"
                                            >
                                                <i className="fa fa-heartbeat"/>
                                            </Button>
                                        </small>
                                    </span>
                                </div>
                                <div className="billing-job-controls">
                                    <button
                                        className="btn btn-circle"
                                        onClick={this.handleExpandJob}
                                    >
                                        <i className={"fas" + (isJobExpanded ? " fa-angle-double-up" : " fa-angle-double-down")}/>
                                    </button>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>

                {isJobExpanded &&
                    <BillingJobDetails id={billingJob.id} />
                }

            </div>
        );
    }
}

const mapStateToProps = (state) => {

    return {
    };
};

const mapDispatchToProps = {
    showModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(BillingJob);
