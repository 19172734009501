import React, { Component, Fragment } from 'react';
import { Formik } from 'formik';
import moment from 'moment';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { hideModal } from '../../../actions/modal.actions';
import {
	getDiscountTypes,
	getAccountServicelines,
	createDiscount,
	updateDiscount,
	emptyDiscountsTypes
} from '../../../actions/accountDetailsActions/billing.actions';
import { getAccountDetails, getUser, getModalData } from '../../../selectors';
import axiosInstance from '../../../utils/axios-instance';

const DiscountSchema = Yup.object().shape({
	discountId: Yup.string().required('Required'),
	validFrom: Yup.date().required('Required'),
	validTo: Yup.date().required('Required')
});

class DiscountsModal extends Component {
	state = {
		showAlertMessage: false,
		alertMessage: '',
		initialValues: {
			discountId: '',
			validFrom: '',
			validTo: '',
			amount: '',
			percentage: '',
			retail: '',
			service: '',
			serviceLineId: '',
			validMonths: '',
			validDays: ''
		}
	};

	componentDidMount() {
		const { modalProps, accountTypeId, currentLocationId, accountId } = this.props;
		this.props.getDiscountTypes(accountTypeId, currentLocationId);
		this.props.getAccountServicelines(accountId);

		if (modalProps.type === 'edit') {
			axiosInstance
				.get(`/account/${accountId}/discount/${modalProps.row.id}`)
				.then((response) => {
					let discount = response.data.discount;
					let initialDataValues = {
						discountId: discount.discountId,
						description: discount.description,
						validFrom: moment(discount.validFrom).format('YYYY-MM-DD'),
						validTo: moment(discount.validTo).format('YYYY-MM-DD'),
						amount: discount.amount,
						percentage: discount.percentage,
						retail: discount.retail,
						service: discount.service,
						serviceLineId: discount.serviceLineId,
						validMonths: discount.validMonths,
						validDays: discount.validDays
					};
					this.setState({
						initialValues: initialDataValues
					});
				})
				.catch((err) => {
					console.log(err.response);
				});
		}
	}

	componentWillUnmount() {
		this.props.emptyDiscountsTypes();
	}

	handleDiscountTypeChange = (e, servicelineId, setValues) => {
		const value = e.target.value;
		const { discountTypesData } = this.props;

		if (value !== '') {
			let currentDiscount = discountTypesData.find((discount) => discount.id === Number(e.target.value));

			let datePeriod = {};
			datePeriod['validTo'] = moment()
				.add(currentDiscount.validDays ? currentDiscount.validDays : 0, 'days')
				.add(currentDiscount.validMonths, 'months')
				.format('YYYY-MM-DD');
			datePeriod['validFrom'] = moment().format('YYYY-MM-DD');

			let data = {
				discountId: currentDiscount.id,
				validFrom: datePeriod.validFrom,
				validTo: datePeriod.validTo,
				amount: currentDiscount.amount,
				percentage: currentDiscount.percentage,
				retail: currentDiscount.retail,
				service: currentDiscount.service,
				serviceLineId: servicelineId,
				validMonths: currentDiscount.validMonths
			};
			setValues(data);
		} else {
			let data = {
				discountId: null,
				validFrom: '',
				validTo: '',
				amount: null,
				percentage: null,
				retail: null,
				service: null,
				serviceLineId: servicelineId
			};
			setValues({ data });
		}
	};

	formSubmit = (values, actions) => {
		if (this.props.modalProps.type === 'create') {
			const data = {
				discountId: values.discountId,
				serviceLineId: values.serviceLineId !== 'all' ? values.serviceLineId : null,
				validFrom: values.validFrom,
				validTo: values.validTo
			};
			this.props.createDiscount(this.props.accountId, data).then((response) => {
				if (response.data.success) {
					this.props.hideModal();
				} else {
					actions.setSubmitting(false);
					this.setState({
						showAlertMessage: true,
						alertMessage: response.data.error && response.data.error.message
					});
				}
			});
		} else {
			const data = {
				discountId: values.discountId,

				validFrom: values.validFrom,
				validTo: values.validTo
			};

			if (values.serviceLineId !== 'All') {
				data['serviceLineId'] = values.serviceLineId;
			}

			this.props.updateDiscount(this.props.accountId, this.props.modalProps.row.id, data).then((response) => {
				if (response.data.success) {
					this.props.hideModal();
				} else {
					actions.setSubmitting(false);
					this.setState({
						showAlertMessage: true,
						alertMessage: response.data.error && response.data.error.message
					});
				}
			});
		}
	};

	render() {
		const { initialValues, showAlertMessage, alertMessage } = this.state;
		const { modalProps, discountTypesData, servicelinesData } = this.props;

		return (
			<Fragment>
				<div className="modal" style={{ display: 'block' }} tabIndex="-1" role="dialog">
					<Formik
						initialValues={initialValues}
						validationSchema={DiscountSchema}
						onSubmit={this.formSubmit}
						enableReinitialize
						render={({
							handleChange,
							handleSubmit,
							handleBlur,
							values,
							errors,
							touched,
							isSubmitting,
							setValues
						}) => (
							<form onSubmit={handleSubmit}>
								<div className="modal-dialog" role="document">
									<div className="modal-content">
										<div className="modal-header">
											<h5 className="modal-title">
												{modalProps.type === 'create' ? 'Add Discount' : 'Edit Discount'}
											</h5>
											<button onClick={this.props.hideModal} type="button" className="close">
												<span aria-hidden="true">&times;</span>
											</button>
										</div>
										<div className="modal-body cmv-form">
											{showAlertMessage === true && (
												<div className="alert alert-inline alert-danger alert-dismissible">
													<p className="mb-0">{alertMessage}</p>
												</div>
											)}
											{modalProps.type === 'create' ? (
												<div className="form-group">
													<label>Discount</label>
													<select
														className={
															errors.discountId && touched.discountId ? (
																'form-control selector-discounts is-invalid'
															) : (
																'form-control selector-discounts'
															)
														}
														placeholder="Please select serviceline"
														value={values.discountId}
														onChange={(e) =>
															this.handleDiscountTypeChange(
																e,
																values.serviceLineId,
																setValues
															)}
														onBlur={handleBlur}
														// disabled={modalProps.type === 'edit'}
														name="discountId"
													>
														<option value="">Please choose</option>
														{discountTypesData.map((type) => (
															<option value={type.id} key={type.id}>
																{type.description}
															</option>
														))}
													</select>
													{errors.discountId &&
													touched.discountId && (
														<div className="invalid-feedback">{errors.discountId}</div>
													)}
												</div>
											) : (
												<div className="form-group">
													<label>Discount</label>
													<select
														className={
															errors.discountId && touched.discountId ? (
																'form-control selector-discounts is-invalid'
															) : (
																'form-control selector-discounts'
															)
														}
														placeholder="Please select serviceline"
														value={values.discountId}
														disabled={true}
														name="discountId"
													>
														<option value={values.discountId}>{values.description}</option>
													</select>
													{errors.discountId &&
													touched.discountId && (
														<div className="invalid-feedback">{errors.discountId}</div>
													)}
												</div>
											)}

											<div className="form-row">
												<div className="form-group col">
													<label>Valid from</label>
													<input
														type="date"
														placeholder="Valid from"
														className={
															errors.validFrom && touched.validFrom ? (
																'form-control discount-from is-invalid'
															) : (
																'form-control discount-from'
															)
														}
														value={values.validFrom}
														onChange={handleChange}
														onBlur={handleBlur}
														name="validFrom"
													/>
													{errors.validFrom &&
													touched.validFrom && (
														<div className="invalid-feedback">{errors.validFrom}</div>
													)}
													{values.validFrom !== '' &&
													values.validTo !== '' &&
													values.validMonths > 0 &&
													Math.abs(moment(values.validTo).diff(values.validFrom, 'months')) >
														values.validMonths && (
														<div
															className="feedback-container"
															style={{ position: 'relative' }}
														>
															<div
																className="text-warning"
																style={{
																	position: 'absolute',
																	whiteSpace: 'nowrap',
																	fontSize: '0.8rem',
																	margin: '2px'
																}}
															>
																{`WARNING: The dates are outside the valid values ${values.validMonths} months`}
															</div>
														</div>
													)}
												</div>
												<div className="form-group col">
													<label>Valid to</label>
													<input
														type="date"
														placeholder="Valid to"
														className={
															errors.validTo && touched.validTo ? (
																'form-control discount-from is-invalid'
															) : (
																'form-control discount-from'
															)
														}
														value={values.validTo}
														onChange={handleChange}
														onBlur={handleBlur}
														name="validTo"
													/>
													{errors.validTo &&
													touched.validTo && (
														<div className="invalid-feedback">{errors.validTo}</div>
													)}
												</div>
											</div>

											<div className="form-row form-row-double">
												<div className="form-group col-6 col-md">
													<label>Percentage</label>
													<p className="form-control-plaintext font-weight-bold discount-perc">
														{values.percentage}%
													</p>
												</div>
												<div className="form-group col-6 col-md">
													<label>Amount</label>
													<p className="form-control-plaintext font-weight-bold discount-amo">
														${values.amount &&
															values.amount
																.toFixed(2)
																.replace(/\d(?=(\d{3})+\.)/g, '$&,')}
													</p>
												</div>
												<div className="form-group col-6 col-md d-flex align-items-end">
													<div className="form-check checkbox-slider checkbox-slider--b-flat">
														<label>
															<input
																type="checkbox"
																readOnly={true}
																// disabled={true}
																checked={values.service}
															/>
															<span>Service</span>
														</label>
													</div>
												</div>
												<div className="form-group col-6 col-md d-flex align-items-end">
													<div className="form-check checkbox-slider checkbox-slider--b-flat">
														<label>
															<input
																type="checkbox"
																disabled={true}
																checked={values.retail}
															/>
															<span>Retail</span>
														</label>
													</div>
												</div>
											</div>

											<div className="form-group">
												<label>Serviceline</label>
												<select
													className={
														errors.serviceLineId && touched.serviceLineId ? (
															'form-control customer-adjustment-serviceline is-invalid'
														) : (
															'form-control customer-adjustment-serviceline'
														)
													}
													placeholder="Please select serviceline"
													value={values.serviceLineId}
													onChange={handleChange}
													onBlur={handleBlur}
													// disabled={modalProps.type === 'edit'}
													name="serviceLineId"
												>
													<option value="All">ALL SERVICELINES</option>
													{servicelinesData.map((type) => (
														<option value={type.id} key={type.id}>
															{type.number}
														</option>
													))}
												</select>
												{errors.serviceLineId &&
												touched.serviceLineId && (
													<div className="invalid-feedback">{errors.serviceLineId}</div>
												)}
											</div>
										</div>
										<div className="modal-footer">
											<button onClick={this.props.hideModal} className="btn" type="button">
												Cancel
											</button>
											<button disabled={isSubmitting} type="submit" className="btn btn-primary">
												{modalProps.type === 'create' ? 'Add Discount' : 'Edit Discount'}
											</button>
										</div>
									</div>
								</div>
							</form>
						)}
					/>
				</div>
				<div className="modal-backdrop show" tabIndex="1" />
			</Fragment>
		);
	}
}

const mapStateToProps = (state) => {
	const discountTypesData = getAccountDetails(state).billing.discountTypes,
		servicelinesData = getAccountDetails(state).billing.accountServicelines,
		currentLocationId = getUser(state).currentLocation.id,
		accountTypeId = getAccountDetails(state).accountDetails.accountInfo.accountType.id,
		accountId = getAccountDetails(state).accountDetails.accountInfo.id,
		modalProps = getModalData(state).modalProps;

	return {
		discountTypesData,
		servicelinesData,
		currentLocationId,
		accountId,
		accountTypeId,
		modalProps
	};
};

const mapDispatchToProps = {
	createDiscount,
	updateDiscount,
	getDiscountTypes,
	getAccountServicelines,
	emptyDiscountsTypes,
	hideModal
};

export default connect(mapStateToProps, mapDispatchToProps)(DiscountsModal);
