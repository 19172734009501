import React, {Component} from "react";
import {connect} from 'react-redux';
import Wizard from "../../../camvio-wizard/Wizard";
import Step from "../../../camvio-wizard/Step";

import {
    RemoveEquipment,
    AddEquipment,
    Confirmation
} from "./Steps";

const STEPS = {
    REMOVE_EQUIPMENT: 'remove-equipment-step',
    ADD_EQUIPMENT: 'add-equipment-step',
    CONFIRMATION: 'confirmation-step',
}

class EquipmentSwap extends Component {

    componentDidMount() {
        document.title = 'Swap Equipment - camvio.cloud';
        this.props.changeNavBarType('lock', 'swapEquipmentWizard', 'Swap Equipment');
    }

    componentWillUnmount() {
        const {changeNavBarType, hideAccountDetailsWizard, documentTitle, navbarTitle} = this.props;

        // Change navbar type and color
        document.title = documentTitle;
        changeNavBarType('account', 'accountDetails', navbarTitle);

        hideAccountDetailsWizard();
    }

    render() {
        const { accountDetailsWizardData, history, hideAccountDetailsWizard } = this.props;

        return (
            <Wizard name='SwapEquipmentWizard'>

                <Step
                    wizardData={accountDetailsWizardData.wizardData}
                    name={STEPS.REMOVE_EQUIPMENT}
                    title='Remove Equipment'
                    component={RemoveEquipment}
                    next={STEPS.ADD_EQUIPMENT}
                    history={history}
                />

                <Step
                    wizardData={accountDetailsWizardData.wizardData}
                    name={STEPS.ADD_EQUIPMENT}
                    title='Add Equipment'
                    component={AddEquipment}
                    next={STEPS.CONFIRMATION}
                    history={history}
                />

                <Step
                    wizardData={accountDetailsWizardData.wizardData}
                    name={STEPS.CONFIRMATION}
                    title='Confirmation'
                    component={Confirmation}
                    history={history}
                    hideAccountDetailsWizard={hideAccountDetailsWizard}
                />

            </Wizard>
        )
    }
}

const mapStateToProps = (state, props) => {
    return {
    };
};

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(EquipmentSwap);