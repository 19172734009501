import { REPORT_CATEGORIES, APP_INFO } from '../actions/sideBar.actions';

let initialState = {
	reportCategories: [],
	appInfo: {}
};

export const sideBarReducer = (state = initialState, action) => {
	switch (action.type) {
		case REPORT_CATEGORIES.SUCCESS:
			return {
				...state,
				reportCategories: action.payload
			};

		case APP_INFO.SUCCESS:
			return {
				...state,
				appInfo: action.payload
			};

		default:
			return state;
	}
};
